<!--
    @name: widget-table
    @description：widget-table
    @author: ZengWei
    @date: 2022-03-25 09:40
-->
<template>
  <el-form
    ref="childForm"
    size="medium"
    :model="element"
    :class="{'form-parser':formData.tableStyle,'normal-parser': !formData.tableStyle}"
    :show-message="!formData.tableStyle"
    :label-width="formData.labelWidth + 'px'"
    :label-position="formData.labelPosition"
  >
    <div class="table-container">
      <table class="table-layout">
        <thead>
        <tr>
          <th v-for="(colItem, colIdx) in tableThData" :key="colIdx" :style="thWidth(colItem)"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(row, rowIdx) in element.config.widget.rows" :key="rowIdx">
          <template v-for="(widget, colIdx) in row.cols">
            <td
              v-if="!widget.merged"
              class="table-cell"
              :key="widget.id"
              :style="{width: widget.options.cellWidth + '!important' || '', height: widget.options.cellHeight + '!important' || ''}"
              :colspan="widget.options.colspan || 1" :rowspan="widget.options.rowspan || 1"
            >
              <el-row>
                <el-col
                  v-for="(item, index) in widget.widgetList"
                  :span="item.config.__config__.span"
                  :key="item.config.__config__.formId"
                >
                  <el-form-item
                    :prop="'config.widget.rows.' + rowIdx + '.cols.' + colIdx + '.widgetList.'+index+'.value'"
                    :rules="item.validate"
                    :label="showLabel(item)"
                  >
                    <InputItem
                      :element="item"
                      :disabled="canEditField(item.config.__config__.formId)"
                      :formData="formData"
                      :editFields="editFields"
                      :first-index="index"
                      :key="item.config.__config__.formId"
                      @trigger-active="triggerActive(arguments,element.config.__config__.formId,tabInd)"
                    >
                    </InputItem>
                  </el-form-item>
                </el-col>
              </el-row>
            </td>
          </template>
        </tr>
        </tbody>
      </table>
    </div>
  </el-form>
</template>

<script>
import formatElement from "@/custom-component/form/newParser/scripts/formatElement";

export default {
  name: "WidgetTable",
  components: {},
  props: ['element', 'disabled', 'displayData', 'formData', 'editFields'],
  data() {
    return {
      tableThData: [],
    }
  },
  computed: {
    thWidth(){
      return function (item) {
        if(item?.width){
          return {width: item.width+'px'}
        }
        return {}
      }
    },
    showLabel() {
      return function (item) {
        const config = item.config.__config__
        if (item.config.__ques__ || config.showLabel === undefined || config.showLabel === false)
          return "";
        return config.label;
      };
    },
    canEditField() {
      return function (formId) {
        const editFormId = Object.keys(this.editFields);
        if (editFormId.length > 0 && editFormId.includes(formId))
          return this.editFields[formId];
        return this.disabled;
      };
    },
    showHideField() {
      return function (config) {
        const formId = config.formId;
        const editFormId = Object.keys(this.editFields);
        if (editFormId.includes(formId)) return true;
        return config.addShow;
      };
    }
  },
  created() {
    this.formatTdElement()
    this.tableThData = this.element.config.widget.rows[0].cols
  },
  methods: {
    formatTdElement() {
      const rows = this.element.config.widget.rows;
      for (let row of rows) {
        for (let col of row.cols) {
          const data = []
          for (let item of col.widgetList) {
            if (item?.__config__?.tagIcon) {
              let itemData = formatElement(item,{},{},this.displayData);
              data.push(itemData)
            }
          }
          if (data.length) col.widgetList = data
        }
      }
    },
    validateFormInput(){
      this.$refs.childForm.validate((valid,object)=>{
        if(!valid){
          let msg = []
          for (const objKey in object) {
            for (const objKeyEle of object[objKey]) {
              msg.push(objKeyEle.message)
            }
          }
          throw new Error(msg.join(' && '))
        }
      })
    },
    triggerActive() {
    },
  },
}
</script>

<style lang="less" scoped>
div.table-container {
  //border: 1px dashed #336699;
  box-sizing: border-box;
  margin-bottom: 4px;
  table.table-layout {
    width: 100%;
    text-align: left;
    //border: 1px solid #c8ebfb;
    border-collapse: collapse;
    table-layout: fixed;

    :deep(td) {
      height: 48px;
      border: 1px solid #eaebed;
      padding: 10px 5px;
      display: table-cell;
    }

    .table-cell {
      //padding: 5px;
      //border: 1px dashed #336699;
      display: table-cell;
      position: relative;

      .drag-wrapper {
        width: 100%;
        height: 100%;
      }

      .draggable-div {
        position: relative;
        height: 100%;
      }

      .form-widget-list {
        border: 1px dashed #336699;
        margin: 3px;

        //min-height: 36px;
        height: 100%;

        /*position: absolute;*/
        /*top: 0;*/
        /*right: 0;*/
        /*bottom: 0;*/
        /*left: 0;*/
      }

      .table-cell-action {
        position: absolute;
        //bottom: -30px;
        bottom: 0;
        right: -2px;
        height: 28px;
        line-height: 28px;
        background: #409EFF;
        z-index: 999;

        i {
          font-size: 14px;
          color: #fff;
          margin: 0 5px;
          cursor: pointer;
        }
      }

      .table-cell-handler {
        position: absolute;
        top: -2px;
        //bottom: -24px;  /* 拖拽手柄位于组件下方，有时无法正常拖动，原因未明？？ */
        left: -2px;
        height: 22px;
        line-height: 22px;
        background: #409EFF;
        z-index: 9;

        i {
          font-size: 14px;
          font-style: normal;
          color: #fff;
          margin: 4px;
          cursor: default; //cursor: move;
        }
      }

    }

    .form-widget-list {
      border: 1px dashed #336699;
      min-height: 36px;
    }
  }
}

.table-container.selected {
  outline: 2px solid #409EFF !important;
}
</style>
