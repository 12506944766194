<template>
  <div class="container">
    <div
      class="header-container"
      v-if="judgingAPermission(['workbench.update'], [])"
    >
      <div
        class="edit display"
        @click="showEdit = !showEdit"
        v-show="!showEdit"
      >
        <i
          class="iconfont el-icon-edit"
          style="padding-right: 9px; box-sizing: border-box"
        ></i>
        编辑主页
      </div>
      <div class="edit display" @click="comfirm" v-show="showEdit">
        <i
          class="iconfont el-icon-upload"
          style="padding-right: 9px; box-sizing: border-box"
        ></i>
        保存主页
      </div>
      <div class="add display" @click="add" v-show="showEdit">
        <i
          class="iconfont el-icon-plus"
          style="padding-right: 9px; box-sizing: border-box"
        ></i>
        添加画板
      </div>
    </div>
    <div class="container-layout" v-loading='loading' >
      <div class="wrapper" style="position: relative">
        <div class="header" style="position: absolute; top: -18px; right: 0">
          <div class="head-wrap"></div>
        </div>
        <div class="grid-container">
          <grid-layout
            v-if="isInit"
            ref="gridlayout"
            :layout.sync="layout"
            :col-num="12"
            :row-height="itemHeight"
            :is-draggable="showEdit"
            :is-resizable="showEdit"
            :vertical-compact="true"
            :use-css-transforms="true"
            :margin="[16, 16]"
          >
            <!-- <grid-layout :layout.sync="layout"
                     :col-num="12"
                     :row-height="30"
                     :is-draggable="true"
                     :is-resizable="true"
                     :vertical-compact="true"
                     :use-css-transforms="true"
        > -->
            <grid-item
              v-for="(item, index) in layout"
              :key="item.i"
              :x="item.x"
              :y="item.y"
              :w="item.w"
              :h="item.h"
              :i="item.i"
            >
              <div
                class="drawing-board"
                @drop="onDropDown($event, item.id)"
                @dragover="onDragover"
              >
                <div
                  style="
                    position: absolute;
                    z-index: 99999;
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    flex-direction: row-reverse;
                  "
                >
                  <div class="panel-title">
                    <el-popover
                      v-show="showEdit"
                      placement="bottom-start"
                      width="120px"
                      trigger="hover"
                    >
                      <i
                        slot="reference"
                        class="el-icon-setting more-action"
                        style="font-size: 20px; margin-top: -9px; right: 5px"
                      ></i>
                      <div>
                        <div
                          v-if="item.pageData"
                          class="action-bar"
                          slot="edit"
                          @click="selectItem(index)"
                        >
                          <i class="iconfont el-icon-edit-outline"></i>编辑
                        </div>
                        <div class="action-bar">
                          <el-popconfirm
                            title="您确定要删除吗？"
                            @confirm="del(index)"
                          >
                            <span slot="reference">
                              <i class="iconfont iconwangpan-shanchu1x"></i>删除
                            </span>
                          </el-popconfirm>
                        </div>
                      </div>
                    </el-popover>
                  </div>
                  <div
                    v-show="showEdit"
                    style="
                      width: 20px;
                      height: 20px;
                      background-color: rgb(255, 255, 255);
                      position: absolute;
                      right: 18px;
                      top: 22px;
                    "
                  ></div>
                  <i
                    v-if="!item.pageData"
                    class="el-icon-plus"
                    style="font-size: 25px"
                    @click.stop="selectItem(index)"
                  ></i>
                </div>
                <component
                  :style="showEdit ? 'pointer-events: none;' : ''"
                  v-if="item.isWrite"
                  :is="item.pageData"
                  @toAppUrl='toAppUrl'
                >
                </component>
                <PageParser
                  v-if="!item.isWrite"
                  :pageUUID="item.pageData"
                  :style="showEdit ? 'pointer-events: none;' : ''"
                  :isNormal="true"
                >
                </PageParser>
              </div>
            </grid-item>
          </grid-layout>
        </div>
      </div>
      <el-drawer
        size="20%"
        title="选择项目"
        :visible.sync="drawer"
        style="z-index: 10000"
      >
        <Drawer
          :treeData="treeData"
          :layout="layout"
          :drawerIndex="drawerIndex"
          @back="back"
          @commit="getCommit"
          @reset="back"
        ></Drawer>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import { Popover, Popconfirm } from 'element-ui'
import Drawer from './GridDrawer'
import VueGridLayout from 'vue-grid-layout'
import PageParser from '@/components/parser/Index'
import componentList from '@/custom-component/component-list'
import {
  judgingAPermission,
  deepCopy,
  getComponentId,
  uniqid,
  isJSONStr,
} from '@/utils/tools'
import { dataInterface } from '@/apis/data'
import { mapState } from 'vuex';

const componentsList = require.context(
  '@/manage-views/views/workbench',
  true,
  /\.+vue$/
)
const customComponents = {}
componentsList.keys().forEach((fileName) => {
  let name = fileName.replace(/.vue/, '')
  name = name.replace(/\.\//, '')
  name = name.split('/')[1]
  customComponents[name] = componentsList(fileName).default
})

export default {
  name: 'Grid',
  components: {
    ...customComponents,
    Drawer,
    PageParser,
    'el-popover': Popover,
    'el-popconfirm': Popconfirm,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
  },
  props: {
    // 是否为预览
    isPreview: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      judgingAPermission:judgingAPermission,
      readOnly: true,
      optionUuid: null,
      keepId: null,
      treeData: [],
      drawerIndex: null,
      drawer: false,
      innerDrawer: false,
      pageData: null,
      showEdit: false,
      layout: [],
      // 手写页面数据 当新增手写页面时 需要在这里添加配置 uuid：手写页面name label：手写页面中文显示
      // 手写页面名称不能命名含有page！！！
      writeDatas: [
        { name: '待办事项', uuid: 'todoCom', label: '待办事项' },
        { name: '我的应用', uuid: 'apps', label: '我的应用' },
        { name: '通知公告', uuid: 'notice', label: '通知公告' },
        { name: '建设看板', uuid: 'statistic', label: '建设看板' },
        { name: '新闻动态', uuid: 'news', label: '新闻动态' },
      ],
      isInit: true,
      baseHeight: 792,
      itemHeight: 81,
      loading:false,
      defaultLayout:[
              {
                id: uniqid(),
                x: 0,
                y: 0,
                w: 4,
                h: 4,
                i: '0',
                pageData: 'news',
                isWrite: true,
              },
              {
                id: uniqid(),
                x: 0,
                y: 4,
                w: 4,
                h: 4,
                i: '1',
                pageData: 'notice',
                isWrite: true,
              },
              {
                id: uniqid(),
                x: 4,
                y: 0,
                w: 4,
                h: 4,
                i: '2',
                pageData: 'apps',
                isWrite: true,
              },
              {
                id: uniqid(),
                x: 4,
                y: 4,
                w: 4,
                h: 4,
                i: '3',
                pageData: 'todoCom',
                isWrite: true,
              },
              {
                id: uniqid(),
                x: 8,
                y: 0,
                w: 4,
                h: 8,
                i: '4',
                pageData: 'statistic',
                isWrite: true,
              },
            ]
      // gridHeight:''  // todo 是否需要储存保存者的高度？？
    }
  },
  created() {
    this.getAllPages()
    this.getData()
  },
  computed: {
    ...mapState(['saasTargetArchi'])
  },
  watch: {
    saasTargetArchi() {
      this.layout = []
      this.getData()
    }
  },
  mounted() {
    this.getHeight()
  },
  methods: {
    toAppUrl(item){
      this.$emit('toAppUrl',item)
    },
    getHeight(){
        const box = document.querySelector('.grid-container')
        let defaultHeight = 80
        let headerHeight = 32
        if (judgingAPermission(['workbench.update'], [])) {
          defaultHeight = 76
          headerHeight = 64
        }
        const height = box ? ((box.offsetHeight + headerHeight) / this.baseHeight) * defaultHeight : null
        if (!isNaN(height)) {
          this.itemHeight = height
        }
        this.isInit = true
    },

    getData() {
      this.loading = true
      let firm = {
        object_uuid: 'object62e22770a666e',
        view_uuid: 'view62e227954b0a9',
        __method_name__: 'dataList',
        transcode: 0,
      }
      dataInterface(firm)
        .then((res) => {
          if (!res || res.status !== 200) {
            //假如没数据 则变成默认数据
            this.layout = this.defaultLayout
            return false
          }
          if (res.data.data.data && res.data.data.data.length) {
            this.keepId = res.data.data.data[0].id
            const layout =
              res.data.data.data[0].layoutData ?? res.data.data.data[0].layout
            if (layout && layout.length) {
              this.layout = isJSONStr(layout) ? JSON.parse(layout) : layout
            }
          }
          if (!this.layout.length) {
            this.layout = this.defaultLayout
          }
          this.loading = false
        })
        .catch(() => {})
    },
    /**
     * 添加面板（计算当前layout的最后一位在最后一位后进行添加）
     */
    add() {
      let arr = []
      this.layout.forEach((r) => {
        arr.push(+r.i)
      })
      arr.sort((a, b) => {
        return a - b
      })
      const max = arr[arr.length - 1]
      const last = this.layout[this.layout.length - 1]
      let x = 0
      if (last.x < 9) {
        x = last.x + 3
      }
      const newPanel = {
        id: uniqid(),
        x: x,
        y: last.y + 6,
        w: 4,
        h: 4,
        i: '' + (max + 1),
      }
      this.layout.push(newPanel)
    },
    del(index) {
      this.layout.splice(index, 1)
    },
    /**
     * @desc: 放置拖放
     */
    onDropDown(e, gridItemId) {
      e.stopPropagation()
      e.preventDefault()

      if (this.isPreview) return
      const groupIndex = e.dataTransfer.getData('group-index')
      const comIndex = e.dataTransfer.getData('index')
      if (
        isNaN(groupIndex) ||
        isNaN(comIndex) ||
        !Array.isArray(componentList) ||
        !componentList.length
      ) {
        this.$message.error('获取组件失败！')
        return
      }
      const group = componentList[groupIndex]
      if (
        !group ||
        !group.componentList ||
        !Array.isArray(group.componentList)
      ) {
        this.$message.error('获取组件失败！')
        return
      }
      const component = deepCopy(group.componentList[comIndex])
      component.id = getComponentId(component.component)
      component.parentId = ''
      // 组件嵌套方式
      const target = e.target
      let containerId = null
      if (
        target instanceof SVGElement ||
        target?.className?.includes('normal')
      ) {
        console.log('放置到画布根节点上!')
      } else {
        // console.log('放置到容器中!1111111', target);
        // 1、当前放置位置为容器则在容器中追加
        // 2、获取当前放置节点的第一个祖先容器并放置
        const parentContainer = this.getParentContainer(target)
        if (parentContainer) {
          containerId = parentContainer.containerId
          component.parentId = containerId
        }
      }
      // 将组件写入GridItem
      component.gridItemUuid = gridItemId
      // 将组件写入到图层列表
      this.$store.commit('modifyComponent', { component, containerId })
      // 添加快照
      this.$store.commit('recordSnapshot')
      e.dataTransfer.setData('group-index', -1)
      e.dataTransfer.setData('index', -1)
    },
    /**
     * @desc: 拖动进入放置区域
     */
    onDragover(e) {
      e.stopPropagation()
      e.preventDefault()
      if (this.isPreview) return
      e.dataTransfer.dropEffect = 'copy'
    },
    /**
     * @desc: 获取距离当前节点最近的容器ID
     * @param {Object} target 当前节点
     * @return {String} 返回节点树上距离当前节点最近的容器ID
     */
    getParentContainer(target) {
      if (!target || typeof target !== 'object') return null
      if (target.dataset && target.dataset.id) {
        return {
          target,
          containerId: target.dataset.id,
        }
      }
      return this.getParentContainer(target.parentNode)
    },
    /**
     * 获取所有的页面数据 （select框内容）
     */
    getAllPages() {
      let option = [{ label: '选择项目' }]
      let param = {
        object_uuid: 'a4f016d6-c602-4492-8874-f088c3c0b3b9',
        page: 1,
        search: [
          {
            field_uuid: 'field61bc41f645e17',
            ruleType: 'like',
            value: 'second',
          },
        ],
        size: 20,
        transcode: 0,
        view_uuid: 'view61922b1881103',
        __method_name__: 'dataList',
      }
      dataInterface(param)
        .then((res) => {
          if (!res || res.status !== 200) {
            return false
          }
          let datas = res.data.data.data
          if (datas && datas.length) {
            datas.forEach((r) => {
              r.label = r.name
            })
          }
          option[0].children = res.data.data.data || []
          this.writeDatas.forEach((flag) => {
            option[0].children.push(flag)
          })
          this.treeData = option
        })
        .catch((err) => {
          console.log(err)
        })
    },
    /**
     * 保存数据到后端
     */
    comfirm() {
      if (this.keepId) {
        let firm = {
          object_uuid: 'object62e22770a666e',
          view_uuid: 'view62e227954b0a9',
          __method_name__: 'updateData',
          data_id: this.keepId,
          layoutData: JSON.stringify(this.layout),
          // gridHeight:this.gridHeight,
          transcode: 0,
        }
        dataInterface(firm).then((res) => {
          if (!res || res.status !== 200) {
            this.$message.success('出错了!')
            return false
          }
          this.$message.success('保存成功!')
        })
      } else {
        let firm = {
          object_uuid: 'object62e22770a666e',
          view_uuid: 'view62e227954b0a9',
          __method_name__: 'createData',
          layoutData: JSON.stringify(this.layout),
          // gridHeight:this.gridHeight,
          transcode: 0,
        }
        dataInterface(firm).then((res) => {
          if (!res || res.status !== 200) {
            this.$message.success('出错了!')
            return false
          }
          this.$message.success('保存成功!')
          this.getData()
        })
      }
      this.showEdit = false
    },
    selectItem(index) {
      if (!this.showEdit) return
      this.drawer = true
      this.drawerIndex = index
    },
    getCommit(draweruuid, val) {
      this.layout[this.drawerIndex].isWrite = val
      this.optionUuid = draweruuid
      this.layout[this.drawerIndex].pageData = draweruuid
      this.drawer = false
    },
    back() {
      this.drawer = false
    },
  },
}
</script>

<style lang="less" scoped>
:deep(.vue-grid-item){
  border: none !important;
}
.grid-container {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.footer {
  position: absolute;
  height: 40px;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 2;
}
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.display {
  display: flex;
  justify-content: center;
  align-items: center;
}
:deep(.normal) {
  border-radius: 8px;
}
:deep(.vue-grid-layout) {
  .vue-grid-item {
    border-radius: 8px;
    border: 1px solid #eeeeee;

    .panel-title {
      position: relative;
      line-height: 40px;
      text-align: left;
      text-indent: 10px;
      font-size: 14px;
      padding-right: 35px;

      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .more-action {
        position: absolute;
        top: 12px;
        right: 12px;
        cursor: pointer;
      }
    }

    .vue-resizable-handle {
      padding: 0 5px 5px 0;
    }

    &:hover {
      border: 1px solid #adbeff;
    }
  }

  .vue-grid-placeholder {
    background-color: #adbeff;
    border: 1px dashed #0000cd !important;
  }
}

.drawing-board {
  width: 100%;
  height: 100%;
  // pointer-events: none;
}

.action-bar {
  // align-items: center;
  border-radius: 6px;
  box-sizing: border-box;
  // display: flex;
  height: 100%;
  padding: 6px 8px 6px 12px;
  width: 100%;
  font-size: 14px;

  &:hover {
    background-color: #f2f5ff;
    color: #5b7af6;
  }
  .iconfont {
    margin-right: 8px;
    font-size: 16px;
  }
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .header-container {
    display: flex;
    flex-direction: row-reverse;
    padding-top: 5px;
    box-sizing: border-box;
    width: 100%;
    height: 32px;
    z-index: 1;
    color: #1e95d4;
    .edit {
      width: 126px;
      height: 32px;
      background: #ffffff;
      border-radius: 8px;
      margin-right: 16px;
    }
    .add {
      width: 112px;
      height: 32px;
      background: #ffffff;
      border-radius: 8px;
      margin-right: 16px;
    }
  }
  .container-layout {
    width: 100%;
    height: 100%;
    overflow-y: auto;

    .wrapper {
      width: 100%;
      height: 100%;
      overflow-y: auto;
    }

    .header {
      height: 35px;
      .head-wrap {
        display: flex;
        color: #ffffff;
        line-height: 50px;
        padding: 0 20px;
        justify-content: space-between;
      }
    }
  }
}
:deep(.vue-grid-layout .vue-grid-item:hover) {
  border: 1px solid #eee;
}
</style>
