/*
 * @LastEditTime: 2022-08-09 10:57:56
 * @Description: 菜单迁移的行为
 * @FilePath: /dataview-next/src/views/listViewer/actions/migratePageProject.js
 * @Date: 2022-02-23 17:28:44
 * @Author: lisushuang
 * @LastEditors: lisushuang
 */
/* eslint-disable */

import baseAction from "./baseAction";
import objects from "../../../api/objects";


export default class migratePageProject extends baseAction {

  constructor(config) {
    super()
    this.config = config
    this._solveConfig()
  }

  handler() {
    this._chooseOneServer().then(target => {
      this.getLoading()
      objects.mapi.common({
        "__method_name__": "dataInfo",
        "object_uuid": "object616639b6a1ebb",
        "ruuids": [
          { "relationship_uuid": "relationship6167efd77619b" }
        ],
        "search": [],
        "data_id": this.config.data_id,
        "transcode":0
      }).then(res => {
        if (res.data.code == 200) {
          objects.migrateMenuProject(target, {project_data:res.data.data}).then(res => {
            this.loading.close()
            if (res.data.code == 200) {
              this.instance.$message.success("操作成功")
            } else {
              this.instance.$message.error('操作失败')
            }
          }).catch(res => {
            this.loading.close()
            this.instance.$message.error('操作失败')
          })
        } else {
          this.instance.$message.error('操作失败')
          console.log(res)
        }
      }).catch(res => {
        this.loading.close()
        this.instance.$message.error('操作失败')
        console.log(res)
      })

    })
    // console.log(this.config)
  }

  _solveConfig() {
    // console.log(this.config)
  }

  /**
     * @description: 弹出服务器选择框
     * @param {String} doText 展示的数据
     * @return {Promise} resolve(serverInfo)
     */
  _chooseOneServer(doText = "迁移数据") {
    return new Promise((resolve, reject) => {
      const h = this.instance.$createElement
      const infos = this._solveProjectInfo()
      let value = ''
      let options = []
      infos.forEach((item, index) => {
        options.push(h('el-option', {
          props: {
            label: item.name,
            value: item
          }
        }))
      })
      // 渲染的select选择服务器
      let ok = h('el-select', {
        props: {
          value: value,
          name: 'test'
        },
        on: {
          input: (v) => {
            ok.componentInstance.selected.currentLabel = v.name
            value = v
          }
        },
        style: {
          width: '100%'
        }
      }, [options])
      this.instance.$msgbox({
        title: "请选择服务器" + doText,
        message: ok,
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            // instance.confirmButtonLoading = true;
            // instance.confirmButtonText = '执行中...';
            if (!value) {
              this.instance.$message.info('请选择一条数据')
              return
            }
            instance = null
            resolve(value)
            done()
          } else {
            instance.confirmButtonLoading = false;
            done();
          }
        }
      })
    })
  }

  /**
 * @description: 解析本地projectInfos 为选择框数据
 * @param {Boolean} exceptNow 是否排除当前所在仓库
 * @return {Array} projectDatas
 */
  _solveProjectInfo(exeptNow = false) {
    let info = localStorage.getItem('serverList', '[]')
    try {
      info = JSON.parse(info)
      let res = []
      info.forEach(item => {
        res.push({ name: item.name, value: item })
      });
      return res
    } catch (e) {
      return []
    }
  }
}
