/*
 * @Author: zyf
 * @Date: 2022-08-23 16:18:15
 * @LastEditTime: 2022-08-25 14:46:32
 * @Description: 
 * @LastEditors: zx
 */
import request from '@/apis/http/saasRequest.js'

// 绑定邮箱发送验证码
export function sendMailCode(data) {
  return request({
    url: 'api/saas/sendMailCode',
    method: 'post',
    data
  })
}

// 绑定邮箱
export function changeMail(data) {
  return request({
    url: 'api/saas/changeMail',
    method: 'post',
    data
  })
}

// 绑定手机发送验证码
export function sendPhoneCode(data) {
  return request({
    url: 'api/saas/sendPhoneCode',
    method: 'post',
    data
  })
}
// 绑定手机
export function changePhone1(data) {
  return request({
    url: 'api/saas/changePhone',
    method: 'post',
    data
  })
}

// 修改密码
export function changePassword(data) {
  return request({
    url: 'api/saas/changePassword',
    method: 'post',
    data,
  })
}

// 根据企业码加入公司
export function companyCodeApply(data) {
  return request({
    url: 'api/saas/companyCodeApply',
    method: 'post',
    data,
  })
}

// 第二屏
export function userUseCompany(data) {
  return request({
    url: 'api/saas/userUseCompany',
    method: 'post',
    data,
  })
}


// 第二屏
export function userUserProject(data) {
  return request({
    url: 'api/saas/userUserProject',
    method: 'post',
    data,
  })
}

// 得到组织架构
export function userUseCompanySelect() {
  return request({
    url: 'api/saas/userUseCompanySelect',
    method: 'post',
  })
}