<!--
 * @Description: 柱状图
 * @Author: luocheng
 * @Date: 2022-01-07 09:22:05
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2023-01-04 11:39:24
-->
<template>
	<div
		class="chart-bar"
		:style="{
			height: height + 'px'
		}"
		v-loading="getting"
		:element-loading-background="loadingBackColor"
	>
		<ChartContainer
			v-if="option"
			ref="chartInstance"
			class="chart-bar"
			:option="option"
		></ChartContainer>
		<el-empty
			description="暂无数据"
			v-else
		></el-empty>
	</div>
</template>

<script>
/* eslint-disable */
import mixin from './mixin';
// import eventBus from '@/plugins/eventBus';

export default {
	name: 'ChartBar',
	mixins: [mixin],
	data() {
		return {
			option: null,
			attributes: null
		};
	},
	created() {
		this.getOptions();
	},
	// mounted() {
	// 	this.$nextTick(() => {
	// 		eventBus.$on('refreshChart', () => {
	// 			this.option = null;
	// 			this.getOptions();
	// 		});
	// 		eventBus.$on('databaseTrigger', config => {
	// 			if (!config || !this.paramsConfig.length || !this.paramsConfig.find(ele => ele.componentId === config.componentId)) {
	// 				return;
	// 			}
	// 			this.option = null;
	// 			this.getOptions();
	// 		});
	// 	});
	// },
	methods: {
		/**
		 * @desc: 设置配置项
		 * @param {Array} data
		 */
		setOptions(data) {
			if (!data || !data.length) return false;
			const { attributes = {} } = this.chartData;
			this.attributes = attributes;
			const {
				showTitle = true,
				title = '',
				textColor = '',
				titleFontSize = 12,
				titleFontWeight = 400,
				titleTop = 'auto',
				titleBottom = 'auto',
				titleRight = 'auto',
				titleLeft = 'auto',
				showTooltip = true,
				showLegend = true,
				legendTextColor = '#000000',
				colorRange = [],
				barWidth = 20,
				showLine,
				showBarLabel = true,
				barChart = false,
				axisFontSize = 12,
				axisTextColor = '#ffffff',
				xAxisText = '',
				xAxisTextFontSize = 12,
				xAxisTextColor = '#ffffff',
				yAxisText = '',
				yAxisTextFontSize = 12,
				yAxisTextColor = '#ffffff',
				left = '10%',
				right = '10%',
				top = 60,
				bottom = 60,
				legendIcon = 'circle',
				showDataZoom = false,
				dataZoomBottom = 'auto',
				dataZoomStart = 0,
				dataZoomEnd = 100,
				legendOrient = 'vertical',
				legendType = 'plain',
				usePolar = false,
				minInterval = 0
			} = attributes;
			const { xData = [], series = [], names = '' } = data[0];
			const xAxis = {
				type: 'category',
				data: xData,
				name: xAxisText,
				nameTextStyle: {
					color: xAxisTextColor,
					fontSize: this.scaleSize(xAxisTextFontSize),
					align: 'right'
				},
				axisTick: {
					alignWithLabel: true,
					show: false
				},
				splitLine: {
					show: false //去掉网格线
				},
				axisLabel: {
					textStyle: {
						color: axisTextColor,
						fontSize: this.scaleSize(axisFontSize)
					},
					margin: 16 // 文案与坐标轴间距
				},
				axisLine: {
					show: true // 隐藏坐标轴
				}
			};
			const yAxis = [
				{
					type: 'value',
					name: yAxisText,
					nameTextStyle: {
						color: yAxisTextColor,
						fontSize: this.scaleSize(yAxisTextFontSize),
						align: 'center'
					},
					axisTick: {
						show: false // 隐藏刻度线
					},
					axisLine: {
						show: true // 隐藏坐标轴
					},
					splitLine: {
						show: true,
						lineStyle: {
							type: 'dashed',
							color: 'rgba(194, 197, 204, 0.1)'
						}
					},
					axisLabel: {
						show: true,
						textStyle: {
							color: axisTextColor,
							fontSize: this.scaleSize(axisFontSize)
						}
					}
				},
				{
					type: 'value',
					axisTick: {
						show: false // 隐藏刻度线
					},
					axisLine: {
						show: false // 隐藏坐标轴
					},
					splitLine: {
						show: true,
						lineStyle: {
							type: 'dashed',
							color: 'rgba(194, 197, 204, 0.1)'
						}
					},
					axisLabel: {
						show: false,
						textStyle: {
							color: axisTextColor,
							fontSize: this.scaleSize(axisFontSize)
						}
					}
				}
			];
			if(!isNaN(Number(minInterval))) {
				const number = Number(minInterval)
				yAxis.forEach(el => {
					el.minInterval = number
				})
			}
			this.option = {
				title: {
					show: showTitle,
					text: title,
					left: 'left',
					y: 'top',
					textStyle: {
						color: textColor,
						fontFamily: '微软雅黑',
						fontSize: this.scaleSize(titleFontSize || 12),
						fontWeight: titleFontWeight || 400,
						left: titleLeft,
						top: titleTop,
						bottom: titleBottom,
						right: titleRight
					}
				},
				tooltip: {
					show: showTooltip,
					trigger: 'axis',
					axisPointer: {
						type: 'line'
					}
				},
				legend: {
					show: showLegend,
					itemWidth: 10,
					itemHeight: 10,
					data: this.getLegendData(data[0], legendTextColor, showLine),
					icon: legendIcon,
					...this.getLegendPosition(attributes),
					type: legendType,
					orient: legendOrient,
				},
				color: colorRange || [],
				grid: {
					left,
					right,
					bottom,
					top,
					containLabel: true
				},
				dataZoom: [
					{
						type: 'slider',
						show: showDataZoom,
						backgroundColor: 'transparent',
						fillerColor: 'rgba(0, 0, 0, .1)',
						dataBackground: {
								areaStyle: {
									color: 'rgb(233, 236, 238)',
									opacity: 0.8,
									shadowColor: 'rgba(233, 236, 238, .1)'
								}
						},
						selectedDataBackground: {
								areaStyle: {
									color: 'rgb(233, 236, 238)',
									opacity: 0.8
								},
								shadowColor: 'rgba(233, 236, 238, 1)'
						},
						start: dataZoomStart || 0,
						end: dataZoomEnd || 100,
						bottom: dataZoomBottom,
					}
				],
				series: this.getSeries(series, this.scaleSize(barWidth))
			};
			// 使用极坐标
			if(usePolar) {
				this.option.polar = {
					radius: [30, '80%']
				}
				this.option.angleAxis = {
					max: Math.max(...this.option.series.map(el => Math.max(...el.data.map(ele => ele.value)))) * (4 / 3),
					startAngle: 90
				}
				this.option.radiusAxis = {
					type: 'category',
					data: yAxisText
				}
				this.option.tooltip.show = false
			} else {
				this.option.xAxis = barChart ? yAxis : xAxis
				this.option.yAxis = barChart ? xAxis : yAxis
			}
		},
		/**
		 * @desc: 获取series
		 * @param {Array} series
		 * @param {Number} barWidth
		 */
		getSeries(series, barWidth) {
			let s = [];
			if (!series) {
				return [];
			}
			const { attributes = {} } = this.chartData;
			const { XColorRange = [], distinguishColor = false, barChart = false, useGradient = false, gradientRange = [], usePolar = false } = attributes;
			for (let i = 0, len = series.length; i < len; i++) {
				const el = series[i];
				let data = el.data || [];
				if (distinguishColor || useGradient) {
					data = data.map((value, index) => {
						return {
							value,
							itemStyle: this.getItemStyle(distinguishColor, useGradient, barChart, index, i, XColorRange, gradientRange)
						}
					})
				}
				s.push({
					name: el.name,
					type: 'bar',
					barWidth: this.scaleSize(barWidth),
					coordinateSystem: usePolar ? 'polar' : 'cartesian2d',
					data,
					label: {
						show: this.attributes.showBarLabel,
						position: usePolar ? 'middle' : this.attributes.barChart ? 'right' : 'top',
						fontSize: this.scaleSize(12),
						color: this.attributes.textColor
					}
				});
			}
			// // 显示折线图
			// if (this.attributes.showLine && series.length > 1) {
			// 	const realy = series[1].data;
			// 	const { lineColorRange } = this.attributes;
			// 	let total = 0;
			// 	const lineData = [];
			// 	for (let i = 0; i < realy.length; i++) {
			// 		total += realy[i];
			// 		lineData.push(total);
			// 	}
			// 	s.push({
			// 		name: '累计',
			// 		type: 'line',
			// 		lineStyle: {
			// 			color: lineColorRange[0].color[0]
			// 		},
			// 		itemStyle: {
			// 			color: lineColorRange[0].color[0]
			// 		},
			// 		yAxisIndex: 1,
			// 		data: lineData
			// 	});
			// }
			return s;
		},
		/**
		 * @desc: 生成单项颜色, 渐变优先级高于区分主轴
		 */
		getItemStyle(distinguishColor, useGradient, barChart, index, i, XColorRange, gradientRange) {
			if (distinguishColor && useGradient) {
				return {
					color: {
						type: 'linear',
						x: barChart ? 0 : 0.5,
						y: barChart ? 0.5 : 1,
						x2: barChart ? 1 : 0.5,
						y2: barChart ? 0.5 : 0,
						colorStops: Array.isArray(gradientRange[index % gradientRange.length]) ?
						gradientRange[index % gradientRange.length].map((el, sindex) => {
							if(sindex === 0) {
								return ({
									offset: 0,
									color: el
								})
							}
							return ({
								offset: ( 1 / gradientRange[index % gradientRange.length].length) * (sindex + 1),
								color: el
							})
						}) : [],
						global: false // 缺省为 false
					}
				}
			} else if(useGradient) {
				return {
					color: {
						type: 'linear',
						x: barChart ? 0 : 0.5,
						y: barChart ? 0.5 : 1,
						x2: barChart ? 1 : 0.5,
						y2: barChart ? 0.5 : 0,
						colorStops: Array.isArray(gradientRange[i % gradientRange.length]) ?
						gradientRange[i % gradientRange.length].map((el, index) => {
							if(index === 0) {
								return ({
									offset: 0,
									color: el
								})
							}
							return ({
								offset: ( 1 / gradientRange[i % gradientRange.length].length) * (index + 1),
								color: el
							})
						}) : [],
						global: false // 缺省为 false
					}
				}
			} else {
				return ({
					color: XColorRange[index % XColorRange.length]
				})
			}
		},
		/**
		 * @desc: 获取图例数据
		 * @param {Object} dataFrom 配置数据
		 */
		getLegendData(dataFrom, color = '#000', showLine = false) {
			let s = [];
			if (!dataFrom || !dataFrom.names) {
				return [];
			}
			for (let i = 0; i < dataFrom.names.length; i++) {
				s.push({
					name: dataFrom.names[i],
					textStyle: {
						color,
						fontSize: this.scaleSize(12)
					}
				});
			}
			// 折线
			if (showLine && dataFrom.series.length > 1) {
				s.push({
					name: '累计',
					textStyle: {
						color,
						fontSize: this.scaleSize(12)
					}
				});
			}
			return s;
		}
	},
	// beforeDestroy() {
	// 	eventBus.$off('refreshChart');
  //   // eventBus.$off('databaseTrigger');
	// 	console.log('销毁', '0000000')
	// }
};
</script>

<style lang="less" scoped>
.chart-bar {
	height: 100%;
	width: 100%;
	:deep(.el-empty) {
		padding: 0;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
		.el-empty__image {
			width: 35%;
			max-width: 120px;
			min-width: 50px;
		}
	}
}
</style>
