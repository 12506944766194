/*
 * @Description: 用户信息相关
 * @Author: luocheng
 * @Date: 2021-09-06 14:50:54
 * @LastEditors: luocheng
 * @LastEditTime: 2021-10-28 18:02:12
 */
export default {
  state: {
    // 当前点击的架构
    cacheArchi: null,
    // 当前已选择的架构
    targetArchi: null
  },
  mutations: {
    /**
     * @desc: 点击架构
     * @param {Object} cacheArchi
     */
    setCacheArchi(state, cacheArchi) {
      state.cacheArchi = cacheArchi || null;
    },
    /**
     * @desc: 设置当前选中架构
     * @param {Object} targetArchi
     */
    setTargetArchi(state, targetArchi) {
      state.targetArchi = targetArchi || null;
      localStorage.setItem('targetArchi', targetArchi ? JSON.stringify(targetArchi) : '');
    }
  }
}