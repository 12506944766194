/*
 * @Description: 中控台组件
 * @Author: Shiltin
 * @Date: 2021-10-15 14:53:50
 * @LastEditors: Shiltin 18580045074@163.com
 * @LastEditTime: 2022-11-07 11:48:10
 */
// 中控台组件
const centerConsole = {
	type: 'console',
	title: '中控台组件',
	show: true,
	order: 9,
	componentList: [
		{
			component: 'ConsoleList',
			type: 'container',
			name: '下拉列表',
			icon: 'icon-liebiao',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			propValue: '',
			span: 24,
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
			},
			containerData: null,
			bindFieldConfig:{
				gisField:'',//gis绑定的字段
				bimField:'',//bim绑定的字段
			},
			actionConfig: {
				needAction: true,
				rowDetail: {
					use: true,
					useDepend: false,
					dependLogic: [],
					type: 'rowDetail',
					name: '详情',
					color: '#409EFF',
					btnType: 'text',
					btnSize: '',
					margin: 5,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				},
				rowEdit: {
					use: true,
					useDepend: false,
					dependLogic: [],
					type: 'rowEdit',
					name: '编辑',
					color: '#409EFF',
					btnType: 'text',
					btnSize: '',
					margin: 5,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 构限制
				},
				rowDel: {
					use: true,
					useDepend: false,
					dependLogic: [],
					type: 'rowDel',
					name: '删除',
					color: '#409EFF',
					btnType: 'text',
					btnSize: '',
					margin: 5,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				},
				location: {
					use: true,
					useDepend: false,
					dependLogic: [],
					type: 'location',
					name: '定位模型',
					color: '#409EFF',
					btnType: 'text',
					icon:'iconsaidi-neirong-dingwei',
					btnSize: '',
					margin: 5,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 构限制
				},
				bind:{
					use: true,
					useDepend: false,
					dependLogic: [],
					type: 'bind',
					name: '绑定模型',
					color: '#409EFF',
					icon: 'iconsaidi-neirong-bangding',
					btnType: 'text',
					btnSize: '',
					margin: 5,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 构限制
				},
				clearBind:{
					use: true,
					useDepend: false,
					dependLogic: [],
					type: 'clearBind',
					name: '取消绑定',
					icon:'iconsaidi-neirong-jiebang',
					color: '#409EFF',
					btnType: 'text',
					btnSize: '',
					margin: 5,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 构限制
				}
			},
			// 选中被用与操作的(需要保持住，因此需要单独字段进行保持)
			cacheData: null,
			style: {
				minHeight: '',
				width: '100%',
				height: '100%',
				overflowX: 'hidden',
				overflowY: 'auto',
			},
			behaviors: [
				{
					label: '删除数据',
					value: 'rowDel',
					desc: '删除数据参数配置名为data_id'
				},
				{
					label: '获取视点',
					value: 'onAddView',
					desc: '获取视点参数配置名为data_id'
				},
				{
					label: '回显视点',
					value: 'onShowView',
					desc: '视点回显参数配置事件名model_info'
				},
				{
					label: '构件着色',
					value: 'onSetColor',
					desc: '着色回显参数配置事件名分别为model_info、status'
				},
			]
		},
		{
      component: 'ConsoleFilter',
      type: 'form',
      name: '表单筛选',
      icon: 'icon-shaixuan',
      isLock: false, // 是否锁定
      needCommonStyle: true,
      propValue: '',
      span: 24,
      statusConfig: {
				displayShow: true,
        isShow: true, // 是否显示
        showButton: true, // 显示操作按钮
        quickSearch: false, // 快速搜索
      },
      style: {
        minHeight: 40,
        height: '',
        width: '',
        rotate: 0,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 15,
        paddingRight: 15,
      },
			filterForm:null,
      actionConfig: [
        {
          label: '查询',
          type: 'search',
          btnType: 'primary',
          icon: '',
          show: true,
          permission: [], // 权限
					archiLimit: [], // 架构限制
        },
        {
          label: '重置',
          type: 'reset',
          btnType: '',
          icon: '',
          show: true,
          permission: [], // 权限
					archiLimit: [], // 架构限制
        },
      ],
			behaviors: [
			]
    },
		{
			component: 'ConsoleForm',
			type: 'container',
			name: '表单容器',
			icon: 'icon-biaodanrongqi',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			span: 24,
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
				idUUID: '', // id uuid
				loading: false,
				stripe: false,
				border: false,
				size: '', // medium / small / mini
				rowKey: '',
				fit: true,
				showHeader: true,
				highlightCurrentRow: false,
				rowShowDetails: false,
				emptyText: '暂无数据',
				// 单元格属性
				align: 'center',
				useStyle: false,
				headerAlign: '',
				canSelect: false,
				showOrder: false,
				pagination: true,
				isTreeTable: false,
				children: '下级数据',
				defaultExpandAll: true,
				isNesting: false,
				nestingKey: '子表数据',
				nestingRowKey: '',
				nestingObjectUUID: '',
				useSort: false
			},
			propValue: '',
			style: {
				width: '',
				overflowX: 'hidden',
				overflowY: 'auto',
        blur: 'blur(4px)'
			},
			// 用于直接渲染的数据
			containerData: null,
			actionConfig: {
				needAction: true,
				location: {
					use: true,
					useDepend: false,
					dependLogic: [],
					type: 'location',
					name: '查看模型',
					color: '#409EFF',
					btnType: 'text',
					btnSize: '',
					margin: 5,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				},
				bind: {
					use: true,
					useDepend: false,
					dependLogic: [],
					type: 'bind',
					name: '绑定模型',
					color: '#409EFF',
					btnType: 'text',
					btnSize: '',
					margin: 5,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 构限制
				},
				rebind: {
					use: true,
					useDepend: false,
					dependLogic: [],
					type: 'rebind',
					name: '重绑',
					color: '#409EFF',
					btnType: 'text',
					btnSize: '',
					margin: 5,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				},
				clearAll: {
					use: true,
					useDepend: false,
					dependLogic: [],
					type: 'clearAll',
					name: '清除全部',
					color: '#409EFF',
					btnType: 'text',
					btnSize: '',
					margin: 5,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				},
				loadBaseModel: {
					use: true,
					useDepend: false,
					dependLogic: [],
					type: 'loadBaseModel',
					name: '加载原始模型',
					color: '#409EFF',
					btnType: 'text',
					btnSize: '',
					margin: 5,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				},
			},
			fieldConfig: [], // 字段配置列表
			contentConfig: {
				contentType: 'outForm', // 内容类型
				objectUUID: '', // 数据对象
				viewUUID: '', // 视图ID
				pageId: '', // 页面id
				formUUID: '', // 表单对象
				developPageRoute: '', // 手写页面路由
			},
			updateRulesConfig: {
				pattern: '',
				rules: []
			},
		},
		{
			component: 'ConsoleTypeTree',
			type: 'container',
			name: '分类选择',
			icon: 'iconyeqian',
			isLock: false, // 是否锁定
			needCommonStyle: false,
			style: {
				height: '100%',
				width: '100%',
				lineHeight: '',
				rotate: 0,
				marginTop: 0,
				marginBottom: 0,
				marginRight: 0,
				marginLeft: 0,
				paddingLeft: 0,
				paddingRight: 0,
				paddingTop: 0,
				paddingBottom: 0,
				color: '#fff'
			},
			actionConfig: {
				needAction: true,
				sureClick: {
					use: true,
					useDepend: false,
					dependLogic: [],
					type: 'sureClick',
					name: '确认选择',
					color: '#409EFF',
					btnType: 'text',
					btnSize: '',
					margin: 5,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				}
			},
			checkedParam:null,//选中的数据
			statusConfig: {
				isShow: true, // 是否显示
			},
		},
		{
			component: 'ConsoleSignCont',
			type: 'screenContainer',
			name: '标记容器',
			icon: 'icon-biaoji',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			span: 24,
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
				signType:'bim',//bim/gis
			},
			propValue: '',
			style: {
				width: '100%',
				height: '100%',
				minHeight: '100%',
				overflowX: 'hidden',
				overflowY: 'hidden'
			},
			// 用于直接渲染的数据
			containerData: null,
			specialStyle: {
				isCollector: false, // 是否设为收集者 flex 1
				flexDirection: 'column', // flex 主轴方向
				flexWrap: false, // 是否换行
			},
			behaviors: [
				{
					label: '隐藏全部',
					value: 'hideAll',
					desc: ''
				},
				{
					label: '显示单个',
					value: 'showSign',
					desc: '参数配置事件名分别是data_id、model_info、gis_info,后2个参数根据需求添加'
				},
				{
					label: '隐藏单个',
					value: 'hideSign',
					desc: '参数配置事件名分别是data_id'
				},
				// {
				// 	label: '移除单个',
				// 	value: 'removeSign',
				// 	desc: '参数配置事件名分别是data_id'
				// },
				{
					label: '标记连续显示/始终显示一个',
					value: 'onAccumulate',
					desc: '该行为可不添加参数'
				},
				{
					label: '缓存隐藏GIS标记',
					value: 'onHideGisSigns',
					desc: '该行为可不添加参数'
				},
				{
					label: '缓存隐藏BIM标记',
					value: 'onHideBimSigns',
					desc: '该行为可不添加参数'
				},
				{
					label: '显示类型下全部标记',
					value:'onShowTypeAllSigns',
					desc:'参数配置事件名分别是object_uuid、view_uuid、sign_uuid、sign_field'
				}
			]
		}
	]
};

export default centerConsole;