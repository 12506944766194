<!--
 * @Description: 基础树
 * @Author: luocheng
 * @Date: 2021-09-01 09:40:17
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-01-03 10:54:09
-->
<template>
	<div
		class="common-tree"
			v-if="initEnd && propObj"
	>
		<el-input
			v-if="statusConfig.showSearch"
			v-model="name"
			prefix-icon="el-icon-search"
			clearable
			:placeholder="statusConfig.placeholder"
			class="search"
			@input="onFilter"
		></el-input>
			<!-- :current-node-key="currentKey" -->
		<section class="check-all" v-if="statusConfig.showCheckAll">
			<el-checkbox v-model="checkAll" @change="onCheckAll">{{ statusConfig.checkAllText }}</el-checkbox>
		</section>
		<el-tree
			:data="treeData"
			@node-click="onNodeClick"
			:default-expand-all="statusConfig.defaultExpandAll === undefined || statusConfig.defaultExpandAll ? true : false"
			:expand-on-click-node="true"
			ref="treeContent"
			class="tree-content"
			:node-key="propObj.id || 'id'"
			:empty-text="statusConfig.emptyText"
			:lazy="statusConfig.lazy"
			:load="loadNode"
			highlight-current
			:check-on-click-node="true"
			:filter-node-method="filterNode"
			:show-checkbox="statusConfig.showCheckBox"
			:props="{
				children: propObj.children,
				label: function (data, node) {
					return data[propObj.label];
				},
				disabled: statusConfig.disabled
			}"
			@check="onGetCheckNodes"
		>
			<!-- isLeaf: function (data, node) {
					return !data[propObj.children] || !data[propObj.children].length;
				}, -->
				<!-- 此处不用全等是因为可能后端返回数据类型会不一致 -->
			<span
				class="custom-tree-node"
				slot-scope="{ node, data }"
				:class="{
					'current-custom-tree-node': currentKey && data[propObj.id] && currentKey == data[propObj.id]
				}"
			>
				<TreeIcon :config="element.iconConfig || {}"
					:rowData="data"
					:type="'pre'"
					:componentData="componentList || componentData"
				></TreeIcon>
				<span class="label">
					{{ data[propObj.label] || node.label }}
				</span>
        <span v-if="data.__number_count !== undefined && element.countNumberShow" class="tree-count-number" style="z-index: 100;">
          <span :style="{color:element.countNumberConfig.masterColorPosition == '前' ? element.countNumberConfig.masterColor : '' }">{{element.countNumberConfig.preText}} </span>
          <span :style="{color:element.countNumberConfig.masterColorPosition == '0' ? element.countNumberConfig.masterColor : '' }" v-if="data.__pre_number_count !== undefined && data.__pre_number_count !== null ">{{data.__pre_number_count}}</span>
          <span :style="{color:element.countNumberConfig.masterColorPosition == '/' ? element.countNumberConfig.masterColor : '' }" v-if="data.__pre_number_count !== undefined && data.__pre_number_count !== null">/</span>
          <span :style="{color:element.countNumberConfig.masterColorPosition == '1' ? element.countNumberConfig.masterColor : '' }">{{data.__number_count}}</span>
        </span>
				<template v-if="!statusConfig.hideActions && judgingArchiAPermission(actionLimit.archiLimit, actionLimit.permission)
					&& judgingUILogic(actionLimit.UILogic, componentList || componentData)
					">
					<el-button
						v-if="statusConfig.appendChild && validDomLogic('appendChild')"
						type="text"
						icon="el-icon-plus"
						class="action-btn plus-child"
						@click="doAction(actionConfig.find(ele => ele.key === 'appendChild'), $event, data)"
					></el-button>
					<span class="action-content">
						<!-- <el-button
							type="text"
							icon="el-icon-more"
							class="action-btn"
							@click="onAction(data)"
						>
						</el-button> -->
						<TreeIcon
							:config="element.iconConfig || {}"
							:rowData="data"
							:type="'action'"
							@click="onAction(data)"
							:componentData="componentList || componentData"
						></TreeIcon>
						<!-- 操作 -->
						<ul class="action-list">
							<!-- eslint-disable -->
							<li
								class="action-item"
								@click="doAction(item, $event, data)"
								v-for="(item, index) in actionConfig"
								v-if="item.type !== 'appendChild' && item.type !== 'rowClick' && validDomLogic(item.type)"
								:key="index"
								:style="{
									color: item.color
								}"
							>{{ item.name }}</li>
							<li
								v-if="judgingArchiAPermission(deleteLimit.archiLimit, deleteLimit.permission)
										&& judgingUILogic(deleteLimit.UILogic, componentList || componentData)"
								class="action-item remove"
								@click="onRemove(data, $event)"
							>删除</li>
						</ul>
					</span>
				</template>
			</span>
		</el-tree>
		<!-- 导出 -->
		<Spreadsheet v-if="showSheet && sheetFormData"
			:type="spreadsheetType"
			:excelUuid="exportConfig.exportTemplate"
			:objectUuid="exportConfig.objectUUID"
			:viewUuid="exportConfig.viewUUID"
			:dataIds="sheetFormData"
			:exportType="exportConfig.exportTypeSheet || 'pdf'"
			@exportSuccess="onExportSuccess"
			:viewSearch="exportViewSearch"
			:viewQuery="exportViewParams"
			@exportError="onExportError"
			:extraParams="exportParam || {}"
		></Spreadsheet>
	</div>
</template>

<script>
import { Tree } from 'element-ui';
import { getComponentById } from '@/utils/tools';
import { mapState } from 'vuex';
import { dataInterface } from '@/apis/data/index';
import eventBus from '@/plugins/eventBus';
import { judgingArchiAPermission, flatTree, judgingUILogic, initParams } from '@/utils/tools';
import Spreadsheet from '@/custom-component/common/Entry';
import treeCountMixin from "./treeCountMixin"
import TreeIcon from './TreeIcon';

export default {
	name: 'CommonTree',
	components: {
		'el-tree': Tree,
		Spreadsheet,
		TreeIcon
	},
  mixins:[treeCountMixin],
	props: {
		// 绑定的值
		element: {
			type: Object,
			default: () => {},
			required: true
		},
		// 渲染的数据
		containerData: {
			type: [Array, Object],
			required: true,
			default: () => []
		},
		isGroup: {
			type: Boolean,
		},
		groupComponents: {
			type: Array,
			default: () => []
		},
    componentList: {
      default: null
    }
	},
	inject: ['EDITOR_pageUUID'],
	data() {
		return {
			isInit: true,
			name: '',
			initEnd: false,
			// 当前节点
			currentKey: '',
			propObj: null,
			metadata: [],
			// 全选
			checkAll: false,
			// 树形图节点的扁平化ID
			flatNodes: [],
			renderEnd: false,
			// 导出配置
			exportConfig: null,
			sheetFormData: null,
			showSheet: false,
			spreadsheetType: 'export',
			exportViewParams: {},
			exportViewSearch: [],
			treeData: [],
			isFirstInit: true,
			oldContainerData: null,
			exportParam: {}
		};
	},
	mounted() {
		this.doDefaultClick();
		// 导出
		eventBus.$on('exportData', (exportConfig) => {
			if (!exportConfig || exportConfig.componentId !== this.element.id) return;
			this.exportConfig = exportConfig;
			this.doExport(exportConfig);
		});
	},
	computed: {
		...mapState(['componentData', 'subsidiaryComponentData']),
		// 数据源配置
		database() {
			return this.element && this.element.database;
		},
		// 渲染数据
		// treeData() {
		// 	if (this.element.valueOrign === 'fixed') {
		// 		// 固定值
		// 		return this.propValue || [];
		// 	} else if (this.element.valueOrign === 'database') {
		// 		// 来源数据仓库
		// 		if (!this.database || !this.database.containerKey) return [];
		// 		let container = getComponentById(this.componentList || this.componentData,
		// 			this.database.containerKey
		// 		);
		// 		if (!container && this.isGroup && this.groupComponents.length) {
		// 			container = getComponentById(this.groupComponents, this.database.containerKey);
		// 		}
		// 		if (
		// 			!container ||
		// 			!container.containerData ||
		// 			!Array.isArray(container.containerData)
		// 		) {
		// 			return [];
		// 		}
    //     return this.sendNumberDataToTree(container.containerData)
    //     // return container.containerData
		// 		// return container.containerData;
		// 	}
		// 	return [];
		// },
		// 组件配置
		statusConfig() {
			return this.element ? this.element.statusConfig || {} : {};
		},
		// 字段列表
		fieldList() {
			const list =
				this.element &&
				this.element.database &&
				this.element.database.fieldList;
			if (!list || !Array.isArray(list) || !list.length) return [];
			return list;
		},
		// 操作配置
		actionConfig() {
			return this.element && this.element.actionConfig;
		},
		// 整体操作前权限控制
		actionLimit() {
			return this.element?.actionLimit || {
				permission: [], // 权限
				archiLimit: [], // 架构限制
			}
		},
		// 删除权限控制
		deleteLimit() {
			return this.element?.deleteLimit || {
				permission: [], // 权限
				archiLimit: [], // 架构限制
			}
		},
		// 默认展开
		defaultSelectAll() {
			return this.statusConfig?.defaultSelectAll;
		},
		customComponentList() {
			if (this.EDITOR_pageUUID) {
				return this.subsidiaryComponentData?.[this.EDITOR_pageUUID]?.componentData || [];
			}
			return this.componentData || [];
		}
	},
	watch: {
		countDataList: {
			handler() {
				this.isFirstInit = true;
				this.treeData = this.getTreeData();
				this.doDefaultClick();
			},
			immediate: false,
			deep: true
		},
		// 修复子节点不显示问题<配置项延迟生效>
		propObj(newVal) {
			if (newVal) {
				this.initEnd = true;
				if (this.isFirstInit) {
					this.treeData = this.getTreeData();
					this.doDefaultClick();
				}
			}
		},
		database: {
			handler() {
				let container = getComponentById(this.componentList || this.componentData, this.database.containerKey);
				if (!container && this.isGroup && this.groupComponents.length) {
					container = getComponentById(this.groupComponents, this.database.containerKey);
				}
				this.metadata = container?.metadata || {};
				// 兼容复制出来配置的情况
				this.element.metadata= this.metadata;
				if (this.isFirstInit) {
					this.treeData = this.getTreeData();
					this.doDefaultClick();
				}
			},
			deep: true,
			immediate: true
		},
		metadata: {
			handler() {
			if ((!this.propObj || this.propObj.children ==='children_data') && this.metadata) {
				const result = {};
				const { label, children } = this.statusConfig;
				for (let key in this.metadata) {
					if (this.metadata[key] === label) {
						result.label = key;
					}
					if (this.metadata[key] === children) {
						result.children = key;
					}
					if (this.metadata[key] === 'parent_id') {
						result.parentId = key;
					}
					if (this.metadata[key] === 'id') {
						result.id = key;
					}
					if (this.metadata[key] === 'type') {
						result.type = key;
					}
					this.propObj = this.propObj || result;
				}
				this.propObj = result;
			}
		},

		deep: true,
		immediate: true
		},
		treeData: {
			handler(newVal, oldVal) {
        
				if (newVal === oldVal) return;
        this.initNumberCount()
				// 数据改变保持筛选 本地筛选
				if (this.statusConfig.showSearch && this.name) {
					this.$nextTick(() => {
						this.onFilter();
					})
				}
				if (JSON.stringify(newVal) !== JSON.stringify(oldVal) || !newVal || !newVal.length) {
					if (
						this.treeData &&
						Array.isArray(this.treeData) &&
						this.treeData.length
					) {
						this.onNodeClick(this.treeData[0]);
					} else {
						this.onNodeClick(null);
						if (oldVal && oldVal.length) {
							// 刷新数据
							this.$store.commit('modifyComponent', {
								component: {
									...this.element,
									resolveData: {}
								},
								containerId: null,
								isModify: true,
								pageUUID: this.EDITOR_pageUUID
							});
              console.log(newVal)
							eventBus.$emit('databaseTrigger', {
								componentId: this.element.id,
								isSearch: true,
								action: 'any',
								output: {}
							});
						}
					}
				}
				// 扁平化获取树形图节点
				if (this.statusConfig.showCheckBox && this.statusConfig.showCheckAll) {
					this.flatNodes = flatTree(this.treeData, this.propObj.children || 'children') || [];
				}
			},
			deep: true,
			immediate: true
		},
		// 默认展开
		defaultSelectAll: {
			handler(newVal, oldVal) {
				if (newVal === oldVal) return;
				this.$nextTick(() => {
					const checkNodes = this.$refs.treeContent.getCheckedNodes();
					if (checkNodes && checkNodes.length !== this.flatNodes.length && !this.renderEnd) {
						this.checkAll = true;
						this.renderEnd = true;
						this.onCheckAll(true)
					}
				});
			},
			immediate: true,
			deep: true
		},
		componentData: {
			handler() {
				// 当依赖的树形图取值容器变化后需要刷新数据
				const { valueOrign } = this.element;
				if (valueOrign === 'database') {
					let container = getComponentById(this.componentList || this.componentData, this.database.containerKey);
					if (!container && this.isGroup && this.groupComponents.length) {
						container = getComponentById(this.groupComponents, this.database.containerKey);
					}
					const { containerData } = container;
					if (this.oldContainerData && containerData && JSON.stringify(this.oldContainerData) !== JSON.stringify(containerData)) {
						this.isFirstInit = true;
					}
				}
				if (this.isFirstInit) {
					this.treeData = this.getTreeData();
					this.doDefaultClick();
				}
			},
			immediate: true,
			deep: true
		},
		customComponentList: {
			handler() {
				// 当依赖的树形图取值容器变化后需要刷新数据
				const { valueOrign } = this.element;
				if (valueOrign === 'database') {
					let container = getComponentById(this.componentList || this.componentData, this.database.containerKey);
					if (!container && this.isGroup && this.groupComponents.length) {
						container = getComponentById(this.groupComponents, this.database.containerKey);
					}
					const { containerData } = container;
					if (this.oldContainerData && containerData && JSON.stringify(this.oldContainerData) !== JSON.stringify(containerData)) {
						this.isFirstInit = true;
					}
				}
				if (this.isFirstInit) {
					this.treeData = this.getTreeData();
					this.doDefaultClick();
				}
			},
			immediate: true,
			deep: true
		}
	},
	created() {
	},
	methods: {
		judgingArchiAPermission,
		judgingUILogic,
		/**
		 * @desc: 默认选中暴露数据，触发操作
		 */
		doDefaultClick() {
			this.$nextTick(() => {
				setTimeout(() => {
					if (
						this.treeData &&
						Array.isArray(this.treeData) &&
						this.treeData.length
					) {
						// TO DO  ....
					} else {
						this.onNodeClick(null, true);
					}
				}, 300);
			});
		},
		getTreeData() {
			if (this.element.valueOrign === 'fixed') {
				// 固定值
				this.isFirstInit = false;
				return this.propValue || [];
			} else if (this.element.valueOrign === 'database') {
				// 来源数据仓库
				if (!this.database || !this.database.containerKey) return [];
				let container = getComponentById(this.componentList || this.componentData,
					this.database.containerKey
				);
				if (!container && this.isGroup && this.groupComponents.length) {
					container = getComponentById(this.groupComponents, this.database.containerKey);
				}
				if (!Array.isArray(container?.containerData)) {
					return [];
				}
				const result = this.sendNumberDataToTree(container.containerData)
				if (JSON.stringify(result) !== JSON.stringify(container.containerData)) {
					this.oldContainerData = container.containerData;
					// 成功初始化了数据
					this.isFirstInit = false;
				}
				return result;
			}
			return [];
		},
		/**
		 * @desc: Dom渲染逻辑
		 */
		validDomLogic(key) {
			const btnConfig = this.actionConfig.find(ele => ele.key === key);
			if (!btnConfig) return false;
			return judgingArchiAPermission(btnConfig.archiLimit, btnConfig.permission) && judgingUILogic(btnConfig.UILogic, this.componentList || this.componentData);
		},
		/**
		 * @desc: 点击节点
		 * @param {Object} data 选中的节点对象
		 */
		onNodeClick(data) {
			// 如果是可选择则不再触发单击情况
			if (this.statusConfig.showCheckBox) return;
			const rowClick = this.element.actionConfig.find(ele => ele.key === 'rowClick');
			if (rowClick && rowClick.eventList && rowClick.eventList.length
				&& judgingArchiAPermission(this.actionLimit.archiLimit, this.actionLimit.permission)
				&& judgingUILogic(this.actionLimit.UILogic, this.componentList || this.componentData)
				&& this.validDomLogic('rowClick')
			) {
				// 触发行点击事件
				const comEvents = rowClick.eventList || [];
				for (let i = 0; i <comEvents.length; i++) {
					const { pattern, eventList = [], specialEventList = [] } = comEvents[i];
					if (pattern === undefined) {
						if (comEvents[i].key === 'click') {
								comEvents[i].effects.forEach((effect) => {
									this.$store.commit('triggerEvents', {
										config: {
											...comEvents[i],
											...effect
										},
										element: this.element
									});
								});
							}
					} else {
						const result = pattern === 'special' ? specialEventList : eventList
						result.forEach((ele) => {
							if (ele.key === 'click') {
								ele.effects.forEach((effect) => {
									this.$store.commit('triggerEvents', {
										config: {
											...ele,
										...effect
										},
										element: this.element
									});
								});
							}
						});
					}
				}
			}
			if (!this.propObj || !data) {
				return false;
			}
			this.currentKey = data?.[this.propObj.id];
			let resolveData = data;
			if (!data) {
				resolveData = {
					...this.element.metadata
				};
				this.$set(resolveData, this.propObj?.id, -1);
			}
			this.$store.commit('modifyComponent', {
				component: {
					...this.element,
					resolveData
				},
				containerId: null,
				isModify: true,
				pageUUID: this.EDITOR_pageUUID
			});
			setTimeout(() => {
				eventBus.$emit('databaseTrigger', {
					componentId: this.element.id,
					isSearch: true,
					action: 'any',
					output: resolveData
				});
			}, 10)
		},
		/**
		 * @desc: 懒加载方法
		 * @param {Object} node 节点对象
		 * @param {Function} resolve
		 */
		async loadNode(node, resolve) {
			if (this.isInit) {
				this.isInit = false;
				return resolve({});
			}
			const result = await this.getChildren(node);
			return resolve(result);
		},
		/**
		 * @desc: 设置label的说明方法
		 * @param {Object} data
		 */
		setLabel(data) {
			return data[this.propObj.label];
		},
		/**
		 * @desc: 懒加载子树节点
		 * @param {Object} node 节点
		 */
		getChildren(node) {
			const value = node.data[this.propObj.id];
			if (!value) return [];
			return new Promise((resolve, reject) => {
				dataInterface({
					__method_name__: 'dataList',
					search: [
						{
							field_uuid: this.propObj.parentId,
							ruleType: 'eq',
							value
						}
					],
					object_uuid: this.database.objectData.uuid,
					view_uuid: this.database.viewData.uuid
				})
					.then((res) => {
						if (res.status === 200 && res.data.code === 200 && res.data.data) {
							resolve(this.getListFromRes(res) || []);
						}
					})
					.catch((err) => {
						console.log(err);
						reject([]);
					});
			});
		},
		/**
		 * @desc: 根据请求返回获取列表结构
		 * @param {Object} res 请求返回的数据
		 */
		getListFromRes(res) {
			if (res.data && Array.isArray(res.data)) {
				return res.data;
			}
			if (res.data && typeof res === 'object') {
				return this.getListFromRes(res.data);
			}
			return [];
		},
		/**
		 * @desc: 触发过滤
		 */
		onFilter() {
			if (this.$refs && this.$refs.treeContent) {
				this.$refs.treeContent.filter(this.name);
			}
		},
		/**
		 * @desc: 前端过滤函数 暂时废弃后端筛选启用前端本地筛选
		 */
		filterNode(value, data) {
			if (!value) return true;
			return data[this.propObj.label].indexOf(value) !== -1;
		},
		/**
		 * @desc: 搜索sole
		 */
		onSearch() {
			// 前端筛选
      console.log(11)
			eventBus.$emit('databaseTrigger', {
				componentId: this.element.id,
				parentId: this.database.containerKey,
				action: 'list',
				isSearch: true,
        isInit: true,
				output: {
					search: [
						{
							field_uuid: this.propObj.label,
							ruleType: 'like',
							value: this.name || ''
						}
					]
				}
			});
		},
		/**
		 * @desc: 点击操作扩展小点
		 * @param {Object} data 所点击行的数据对象
		 */
		onAction() {
			// console.log(data, '---data');
		},
		/**
		 * @desc: 编辑
		 */
		onPlus(e) {
			e.preventDefault();
			e.stopPropagation();
			const eventObj = this.actionConfig.find((ele) => ele.key === 'add');
			const comEvents = eventObj.eventList || [];
			for (let i = 0; i <comEvents.length; i++) {
				const { pattern, eventList = [], specialEventList = [] } = comEvents[i];
				const result = pattern === 'special' ? specialEventList : eventList
				result.forEach((ele) => {
					if (ele.key === 'click') {
						ele.effects.forEach((effect) => {
							this.$store.commit('triggerEvents', {
								config: {
									...ele,
								...effect
								},
								element: this.element
							});
						});
					}
				});
			}
			return false;
		},
		/**
		 * @desc: 触发操作
		 * @param {Object} item 操作
		 * @param {Object} data 当前行数据
		 */
		doAction(item, e, data) {
      console.log(item,e,data)
			e.preventDefault();
			e.stopPropagation();
			this.onNodeClick(data);
			const comEvents = item.eventList || [];
			for (let i = 0; i < comEvents.length; i++) {
				const { pattern = undefined, eventList = [], specialEventList = [] } = comEvents[i];
				if (pattern === undefined) {
					if (comEvents[i].key === 'click') {
						comEvents[i].effects.forEach((effect) => {
							this.$store.commit('triggerEvents', {
								config: {
									...comEvents[i],
									...effect
								},
								element: this.element
							});
						});
					}
					break;
				}
				const result = pattern === 'special' ? specialEventList : eventList
				result.forEach((ele) => {
					if (ele.key === 'click') {
						ele.effects.forEach((effect) => {
							this.$store.commit('triggerEvents', {
								config: {
									...ele,
								...effect
								},
								element: this.element
							});
						});
					}
				});
			}
			return false;
		},
		/**
		 * @desc: 删除
		 */
		onRemove(data, e) {
      console.log(data)
			e.preventDefault();
			e.stopPropagation();
			if (!this.database?.objectData?.uuid ||!data) {
				return false;
			}
			this.$confirm('是否确认删除?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
        customClass: 'common-tree-message-box', // :deep不生效 在外层加class名防止影响其它页面元素
			})
				.then(() => {
					let idUUID = '';
					for (let key in this.metadata) {
						if (this.metadata[key] === 'id') {
							idUUID = key;
						}
					}
					dataInterface({
						__method_name__: 'deleteData',
						object_uuid: this.database.objectData.uuid,
						data_id: data && data[idUUID]
					})
						.then((res) => {
							if (res.status === 200 && res.data.code === 200) {
                this.onSearch()
                this.$message.success('删除成功')
							}
						})
						.catch((err) => {
							console.log(err);
						});
				})
				.catch(() => {});
			return false;
		},

		/**
		 * @desc: 切换树形图选中
		 * @param {Boolean} checkAll 是否全选
		 */
		onCheckAll(checkAll) {
			this.$nextTick(() => {
				if (this.$refs.treeContent) {
					this.$refs.treeContent.setCheckedNodes(checkAll ? this.flatNodes : []);
					setTimeout(() => {
						this.onResolveKeys(checkAll ? this.flatNodes : [])
					}, 0);
				}
			});
		},
		/**
		 * @desc: 获取当前选中节点
		 * @param {Array} checkedNodes 当前节点
		 * @param {Array} checkedKeys 选中的节点key列表
		 * @param {Array} halfCheckedNodes 半选节点
		 * @param {Array} halfCheckedKeys 班璇节点key 列表
		 */
		onGetCheckNodes(currentNode, result) {
			const { showCheckBox, showCheckAll } = this.statusConfig;
			if( !showCheckBox) return;
			// , halfCheckedKeys, halfCheckedNode
			const { checkedKeys, checkedNodes} = result;
			if (showCheckAll) {
				this.checkAll = this.flatNodes.length === checkedNodes.length;
			}
			// 将所有选中的数据暴露出去
			this.onResolveKeys(checkedKeys)
		},
		/**
		 * @desc: 选中框的情况将选中的id暴露并触发更新
		 * @param {Array} resolveData
		 */
		onResolveKeys(resolveData = {}) {
			this.$store.commit('modifyComponent', {
				component: {
					...this.element,
					resolveData
				},
				containerId: null,
				isModify: true,
				pageUUID: this.EDITOR_pageUUID
			});
			setTimeout(() => {
				// console.log('触发了-----')
				eventBus.$emit('databaseTrigger', {
					componentId: this.element.id,
					isSearch: true,
					action: 'any',
					output: resolveData
				});
			}, 0)
		},
		/**
		 * @desc: 导出
		 * @param {Object} config 配置
		 */
		async doExport(config) {
			const loading = this.$loading({
				text: '导出中....'
			});
			const { exportType = 'all' } = config;
			let idUUID = this.propObj.id || '';
			if (!idUUID) {
				loading.close();
				this.$message.error('导出失败！数据验证错误。');
				return;
			}
			/* eslint-disable */ 
			// exportType 导出配置 all 全部， targetRow 当前选中行
			// 最终导出数据
			// 导出当前点击行
			const { resolveData } = this.element;
			if (exportType === 'targetRow') {
				if (!resolveData) {
					loading.close();
					this.$message.error('暂无数据可供导出！');
					return;
				}
				// 本页
				this.sheetFormData = resolveData[idUUID];
			}else if (exportType === 'all') {
				this.sheetFormData = 'all';
			}
			if (!this.sheetFormData) {
				loading.close();
				this.$message.error('导出配置错误！');
				return false;
			}
			// 参数
			if (config?.params) {
				const { param = {} } = initParams(config.params, this.isGroup, this.componentList || this.componentData, this.groupComponents);
				this.exportParam = param
			}
			this.showSheet = true;
		},
		/**
		 * @desc: 导出成功
		 */
		onExportSuccess() {
			this.$loading().close();
			this.showSheet = false;
			this.$message.success('导出成功！');
			this.exportParam = {};
		},
		/**
		 * @desc: 导出错误
		 * @param {String} msg 错误信息
		 */
		onExportError(msg) {
			this.$loading().close();
			this.showSheet = false;
			this.$message.error(msg || '导出失败！');
			this.exportParam = {};
		},
	},
	beforeDestroy() {
		eventBus.$off('exportData');
	}
};
</script>
<style lang="less">
  .common-tree-message-box{
    border: none;
    border-radius: 10px;
    .el-message-box__header{
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    height: 48px;
    background: linear-gradient(90deg, #6292FF 0%, #DE93FF 84%, #CE92FF 91%, #C568FF 100%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 68px 0 24px;
    color: #fff;
    .el-message-box__title{
      color: #fff;
      font-size: 14px;
    }
    .el-message-box__headerbtn{
      top: 14px;
      right: 24px;
      .el-message-box__close{
        color: #fff;
          font-size: 20px;
      }
    }
  }
  }
</style>

<style lang="less" scoped>
.common-tree {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	border-radius: 4px;
	overflow-x: hidden;
	:deep(.el-tree-node) {
		width: 100%;
		position: relative;
	}
	:deep(.el-tree-node__children) {
		overflow: visible;
	}
	:deep(.el-tree-node__content) {
		height: 30px;
		>span{
			line-height: 30px;
		}
		.el-tree-node__expand-icon{
			z-index: 300;
		}
	}
	:deep(.el-tree--highlight-current
		.el-tree-node.is-current
		> .el-tree-node__content) {
		background-color: #e8f4ff;
		border-radius: 2px;
	}
	.el-tree-node > .el-tree-node__children {
		overflow: initial;
	}
	.search {
		margin-bottom: 5px;
	}
	.check-all{
		text-align: left;
		box-sizing: border-box;
		padding: 6px 24px;
		border-bottom: 1px solid #f2f3f5;
	}
	.tree-content {
		flex: 1;
		width: 100%;
		overflow: visible;
		overflow-y: auto;
	}
	.custom-tree-node {
		position: relative;
		width: calc(100% - 50px);
		display: flex;
		align-items: center;
		// justify-content: space-between;
		font-size: 14px;
		padding-right: 25px;
		&.current-custom-tree-node {
			position: relative;
			&::after{
				content: '';
				height: 100%;
				width: 100%;
				width: calc(100% + 60px);
				position: absolute;
				left: -60px;
				top: 0;
				background: #e8f4ff;
				// z-index: -1;
			}
      &:hover {
        .tree-count-number {
          display: none;
        }
      }
		}
		span {
			line-height: 26px;
		}
		.label {
			flex: 1;
			text-align: left;
			overflow-x: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			font-size: 14px;
			z-index: 100;
		}
		.plus-child {
			margin-right: 4px;
			z-index: 100;
			display: none;
		}
		.action-content {
			display: none;
			position: absolute;
			right: 3px;
			top: 0;
			padding: 0 2px;
			overflow: visible;
			z-index: 1000;
			&:hover {
				.action-list {
					display: block;
				}
			}
			.action-btn {
				color: @theme;
				padding: 0;
				&.plus-child{
					z-index: 10px;
				}
			}
			.action-list {
				position: absolute;
				background: #fff;
				display: none;
				right: 14px;
				top: 10px;
				width: auto;
				min-width: 60px;
				border: 1px solid @borderColor;
				border-radius: 4px;
				background: #fff;
				z-index: 1000;
				box-shadow: 5px 5px 20px 5px rgba(72, 134, 255, 0.1);
				.el-button--small,
				.el-button--small.is-round {
					padding: 0 !important;
				}
				.action-item {
					font-size: 12px;
					box-sizing: border-box;
					padding-top: 3px 5px;
					border-bottom: 1px solid @borderColor;
					line-height: 30px;
					color: @theme;
					font-family: PingFangSC, PingFangSC-Medium;
					text-align: center;
					&:last-of-type {
						border-bottom: none;
					}
					&:hover {
						background: #f2f3f5;
						font-weight: bold;
					}
					&.remove {
						color: @dangerColor;
					}
				}
			}
		}

		&:hover{
			.plus-child,.action-content{
				display: block;
			}
		}
	}
	.el-button--small, .el-button--small.is-round{
		padding: 4px 0;
		color: #0F62FE;
		line-height: 16px;
		font-weight: bold;
		font-size: 14px;
	}
  .el-tree-node__content {
    &:hover {
      .tree-count-number {
				display: none;
			}
    }
  }
}
</style>
