<template>
  <el-card :body-style="{ padding: '5px' }" class="w-full" :style="allStyle" v-loading="loading"
    element-loading-text="取值类加载中">
    <div v-if="showClose" class="close-container" style="background: #fff;height: 30px;text-align: right;">
      <span class="clickSpan" @click.stop="$emit('close')">关闭弹窗</span>
    </div>
    <p v-if="title != ''" :class="needTitle ? 'type-label-before-blue' : 'new-label'" style="margin-left: 10px">
      {{ title }}{{ !needTitle ? " 参数为一个取值类" : "" }}
      <span class="clickSpan" @click="showTemplateSelect = !showTemplateSelect">{{templateData.item_name ?
      templateData.item_name : '选择取值模板'}}</span>
    </p>
    <div style="padding-left: 20px;" v-show="showTemplateSelect">
      <p class="type-label-before-blue">取值模板选择 {{obuuid == 'global' ? '全局时禁用取值模板' : ''}}</p>
      <el-select v-model="templateData.dict_uuid" :disabled="obuuid == 'global'" placeholder="请选择分类"
        style="width: 100%;" @input="dictUuidChange">
        <el-option v-for="(item,index) in valueDicts" :key="index" :value="item.uuid" :label="item.name" />
      </el-select>
      <el-select @input="itemChange" :disabled="obuuid == 'global'" v-loading="getDictItemLoading"
        element-loading-spinner="el-icon-loading" v-model="templateData.item_uuid" placeholder="请选择模板"
        style="width: 100%;margin-top:20px">
        <el-option v-for="(item,index) in dictItems" :key="index" v-show="item.type == 6" :value="item.uuid"
          :label="item.name" />
      </el-select>
    </div>
    <p class="type-label-before-blue" style="padding-left: 20px;">
      取值类配置
      <span class="clickSpan" @click="showValueMain = !showValueMain">{{showValueMain ? '隐藏' : '显示'}}</span>
    </p>
    <div v-show="!showValueMain" class="clickSpan" style="text-align: center;float:none;font-weight: bold;"
      @click="showValueMain = true">取值配置已隐藏，点击显示</div>
    <el-form ref="extra" :model="form" :rules="rules" label-width="110px" v-show="showValueMain">
      <el-form-item :label="'取值类别'" prop="class">
        <el-select clearable filterable v-model="form.class" placeholder="请选择"
          @change="classChange($event, getValueClasses)">
          <!-- 全局时不允许调用普通取值类 -->
          <el-option v-for="(item, index) in getValueClasses" :key="item.class + index"
            v-show="obuuid !== 'global' || item.class.indexOf('-') > -1" :label="
              item.class.indexOf('-') > -1
                ? '[全局] ' + item.desc
                : '[普通] ' + item.desc
            " :value="item.class"></el-option>
        </el-select>
      </el-form-item>
      <div class="w-full" v-if="show">
        <div v-for="(val, index) in formTypeData" :key="index">
          <!-- 字段类型  type == field -->
          <el-form-item v-if="val.type == 'field'" :label="val.desc" :prop="val.required ? val.field : ''">
            <!-- 这里把修改字段后清空关联关系注释了，后头再看怎么修改 -->
            <!-- <el-select class="w-full" v-model="form[val.field]" placeholder="请选择" @change="delRelation()"> -->
            <!-- <el-select
              clearable
              class="w-full"
              v-model="form[val.field]"
              placeholder="请选择"
            >
              <el-option
                v-for="(item, index) in showFieldList"
                :key="index"
                :label="item.name"
                :value="item.uuid"
              >
              </el-option>
            </el-select>-->
            <field-select :object_uuid="obuuid" :needVirtual="
              val.needVirtual === undefined ? true : val.needVirtual
            " :effect="{
                value: form[val.effect],
                type: getEffectType(val.effect),
              }" v-model="form[val.field]" :fieldList="fieldList"></field-select>
          </el-form-item>
          <!-- 字段类型结束 -->

          <!-- 字典类型：选择当前对象的字典或者全局字典  type == dictionary -->
          <el-form-item v-else-if="val.type == 'dictionary' && dictionaryList.length != 0" :label="val.desc"
            :prop="val.required ? val.field : ''">
            <el-select clearable class="w-full" v-model="form[val.field]" placeholder="请选择">
              <el-option-group v-for="group in dictionaryList" :key="group.label" :label="group.label">
                <el-option v-for="item in group.options" :key="item.id" :label="item.name" :value="item.uuid">
                </el-option>
              </el-option-group>
            </el-select>
          </el-form-item>
          <!-- 字典类型结束 -->

          <!-- 关联关系类型 type == relationship -->
          <el-form-item v-else-if="val.type == 'relationship'" :label="val.desc" :prop="val.required ? val.field : ''">
            <RelationSelect :object_uuid="obuuid" :effect="{
              value: form[val.effect],
              type: getEffectType(val.effect),
            }" v-model="form[val.field]" />
          </el-form-item>
          <!-- 关联关系类型结束 -->

          <!-- 普通选择类型 type == select -->
          <el-form-item v-else-if="val.type == 'select' && !val.view_uuid" :label="val.desc"
            :prop="val.required ? val.field : ''">
            <el-select class="w-full" clearable v-model="form[val.field]" placeholder="请选择">
              <el-option v-for="(val, key, index) in val.options" :key="index" :label="val" :value="key"></el-option>
            </el-select>
          </el-form-item>
          <!-- 普通选择类型结束 -->
          <!-- 视图选择类型开始 -->
          <el-form-item v-else-if="val.type == 'select' && val.view_uuid" :label="val.desc"
            :prop="val.required ? val.field : ''">
            <view-data-select v-model="form[val.field]" :view_uuid="val.view_uuid"
              :label="val.label ? val.label : 'name'" :value_key="val.value_key ? val.value_key : 'id'" />
          </el-form-item>
          <!-- 视图选择类型结束 -->

          <!-- 开关 switch 类型 -->
          <el-form-item v-else-if="val.type == 'switch'" :label="val.desc == '抛出字典转换失败错误' ? '抛出错误' : val.desc"
            :prop="val.required ? val.field : ''">
            <el-switch v-model="form[val.field]"></el-switch>
          </el-form-item>
          <!-- 开关 switch 类型结束 -->

          <!-- 取值类 valueClass 类型 -->
          <ValueForm v-else-if="val.type == 'valueClass'" :formData="form[val.field]" :obuuid="obuuid"
            v-model="form[val.field]" realTime :title="val.desc" :needTitle="false"
            :dictionaryList="JSON.parse(JSON.stringify(dictionaryList))"
            :relationshipList="JSON.parse(JSON.stringify(showRelationList))"
            :fieldList="JSON.parse(JSON.stringify(fieldList))" />
          <!-- 取值类 valueClass 类型结束 -->

          <!-- 规则条件类型 rule 类型开始-->
          <tree-form v-else-if="val.type == 'rule'" ref="treeForm" :obuuid="obuuid" v-model="form[val.field]"
            :dictionaryList="JSON.parse(JSON.stringify(dictionaryList))"
            :relationshipList="JSON.parse(JSON.stringify(showRelationList))" :formData="
              form[val.field] && form[val.field] !== null
                ? [form[val.field]]
                : null
            " :fieldList="JSON.parse(JSON.stringify(fieldList))" />
          <!-- 规则条件类型 rule 类型结束 -->

          <!-- 对象取值 Object 类型开始 -->
          <!-- <b-select
            v-else-if="val.type == 'object'"
            :selectData="objectData"
            :label="val.desc"
            @on-change="changeObjectValue($event, val.field)"
            :backSelected="form != null ? form[val.field] : ''"
          />-->
          <el-form-item v-else-if="val.type == 'object'" :label="val.desc" :prop="val.required ? val.field : ''">
            <object-select v-model="form[val.field]"></object-select>
          </el-form-item>
          <!-- 对象取值 Object 类型结束 -->

          <!-- 视图 View 类型开始 -->
          <el-form-item v-else-if="val.type == 'view'" :label="val.desc" :prop="val.required ? val.field : ''">
            <view-select v-model="form[val.field]" :effect="{
              value: form[val.effect],
              type: getEffectType(val.effect),
            }" :object_uuid="null"></view-select>
          </el-form-item>
          <!-- 视图 view 类型结束 -->

          <!-- TIPS 类型开始 -->
          <el-form-item v-else-if="val.type == 'tips'" :label="val.desc">
            <el-alert effect="dark" :type="val.showType">
              <strong>{{ val.value }}</strong>
            </el-alert>
          </el-form-item>
          <!-- TIPS 类型结束 -->

          <!-- 多行文本 类型开始 -->
          <el-form-item v-else-if="val.type == 'multiline_text'" :label="val.desc"
            :prop="val.required ? val.field : ''">
            <el-input type="textarea" v-model="form[val.field]"></el-input>
          </el-form-item>
          <!-- 多行文本 类型结束 -->

          <!-- 其它类型全部用input -->
          <el-form-item v-else :label="val.desc" :prop="val.required ? val.field : ''">
            <el-input v-model="form[val.field]"></el-input>
          </el-form-item>
        </div>
      </div>
    </el-form>
  </el-card>
</template>

<script>
import object from "@/api/objects";
import ValueForm from "./valueForm";
import ViewSelect from "../../../components/metaSelect/ViewSelect.vue";
import FieldSelect from "../../../components/metaSelect/FieldSelect.vue";
import ObjectSelect from "../../../components/metaSelect/ObjectSelect.vue";
import RelationSelect from "../../../components/metaSelect/RelationSelect.vue";
import ViewDataSelect from "../../../components/metaSelect/ViewDataSelect.vue";
import { Card, OptionGroup, Alert } from "element-ui";

export default {
  name: "ValueForm",
  components: {
    ValueForm,
    ViewSelect,
    FieldSelect,
    ObjectSelect,
    RelationSelect,
    ViewDataSelect,
    'el-card': Card,
    'el-option-group': OptionGroup,
    'el-alert': Alert,
  },
  props: {
    formData: {
      type: [Object, String],
      default: () => {
        return null;
      },
    },
    obuuid: {
      type: String,
      default: "",
    },
    disableClass: {
      type: Array,
      default() {
        return [];
      },
    },
    needTitle: {
      type: Boolean,
      default: true,
    },
    realTime: {
      //改变值实时返回父级
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "取值配置",
    },
    // dictionaryList: {
    //   type: Array,
    //   default: () => [],
    // },
    fieldList: {
      type: Array,
      default: () => [],
    },
    relationshipList: {
      type: Array,
      default: () => [],
    },
    /* 是否显示关闭弹窗按钮 */
    showClose: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    formData: {
      handler(val) {
        if (val && val.class && val.class !== this.extra.class) {
          this.solveFormData(JSON.parse(JSON.stringify(val)));
        } else {
          this.loading = false;
          this.inited = true;
        }

        // if(!val){
        //   this.loading = false;
        //   this.extra = {
        //     class : "",
        //     payload : {}
        //   }
        //   this.inited = true
        // }
      },
      deep: true,
      immediate: true,
    },
    form: {
      handler(val) {
        if (this.realTime && val && this.extra && this.extra.class) {
          let tmp = JSON.parse(JSON.stringify(val));
          let tmp1 = JSON.parse(JSON.stringify(val));
          delete tmp.class;
          this.extra = {
            class: tmp1.class,
            payload: tmp,
          };
          if (this.templateData.dict_uuid) {
            this.extra.templateData = this.templateData;
          }
          // console.log(this.extra, '提交的数据')
          if( !this.inited ) return;
          this.$emit("change-data", this.extra);
          this.$emit("input", this.extra);
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      loading: false, //加载
      show: false,
      form: {},
      extra: {},
      rules: {
        class: [{ required: true, message: "请选择", trigger: "change" }],
      },
      formTypeData: [], //取值里inputs类别
      fieldTypes: object.fieldTypes,
      linkData: [], //关联Id
      validation: false,
      dateFormat: [], //日期格式
      requestData: true, //防止多次请求

      showFieldList: [],
      showRelationList: [],

      objectData: [],
      getValueClasses: [],
      dictionaryList: [],

      templateData: {
        dict_uuid: '',
        item_uuid: '',
        item_name: '',
      },

      showTemplateSelect: false,
      getDictItemLoading: false,
      valueDicts: [],
      dictItems: [],

      showValueMain: true,

      inited: false
    };
  },
  computed: {
    allStyle() {
      if (!this.needTitle) {
        return {
          // border: "1px solid black",
          padding: "10px",
          paddingTop: "0px",
          paddingLeft: "18px",
          width: "calc(100% - 30px)",
          textAlign: "left"
        };
      } else {
        return {
          textAlign: "left"
        };
      }
    },
  },
  methods: {
    changeRelation(uuid, data) {
      let arr = data.filter((v) => v.uuid == uuid);
      if (arr.length != 0) {
        if (arr[0].owner_uuid == this.obuuid) {
          this.form.field_uuid = arr[0].related_primary_uuid;
        } else {
          this.form.field_uuid = arr[0].owner_primary_uuid;
        }
        object.getFields(arr[0].related_uuid).then((res) => {
          this.showFieldList = res.data.data.data;
        });
      }
    },

    /**
     * @description: 获取字段的类型
     * @param {*} field 字段名称
     * @return {*}
     */
    getEffectType(field) {
      let type = "unknown";
      this.formTypeData.forEach((element) => {
        if (element.field == field) {
          type = element.type;
        }
      });
      return type;
    },

    /**
     * @description: 解析传入的formdata并进行一系列的处理
     * @param {*} formData
     * @return {*}
     */
    solveFormData(formData) {
      this.loading = true;
      let valueClasses = '';
      let obj = JSON.parse(JSON.stringify(formData));
      if (!obj || obj.class === undefined) {
        obj = { class: "", payload: { class: "" } };
      }
      this.formTypeData = [];
      console.log(obj, 'first input ')
      if (valueClasses && valueClasses.length != 0) {
        this.classChange(obj.class, valueClasses);
        this.$nextTick(() => {
          this.loading = false;
          this.form = obj.payload;
          this.form.class = obj.class;
          this.$nextTick(() => {
            this.inited = true;
          });
          if (obj.templateData) {
            this.templateData = obj.templateData;
            this.dictUuidChange(obj.templateData.dict_uuid);
            if (this.templateData.item_name) {
              this.showTemplateSelect = true
              this.showValueMain = false
            }
          }
        });
      } else {
        object
          .getValueFunctions()
          .then((res) => {
            if (res.data.code == 200) {
              // this.$store.commit("setValueClasses", res.data.data);
              this.getValueClasses = res.data.data;
              this.classChange(obj.class, res.data.data);
              this.$nextTick(() => {
                this.loading = false;
                this.form = obj.payload;
                this.form.class = obj.class;
                this.$nextTick(() => {
                  this.inited = true;
                });
                if (obj.templateData) {
                  this.templateData = obj.templateData;
                  this.dictUuidChange(obj.templateData.dict_uuid);
                  if (this.templateData.item_name) {
                    this.showTemplateSelect = true
                    this.showValueMain = false
                  }
                }
              });
            } else {
              this.loading = false;
            }
          })
          .catch(() => {
            this.loading = false;
          });
        this.show = true;
      }
    },

    changeObjectValue(data, item) {
      this.form[item] = data;
      this.changeFields(data);
      this.changeRelationList(data);
      // this.changeRelationList(data,{uuid:data})
    },

    changeRelationList(uuid) {
      object.getMyRelations(uuid).then((res) => {
        if (res.data.code == 200) {
          this.showRelationList = res.data.data.data;
        }
      });
    },

    changeFields(uuid) {
      object.getFields(uuid).then((res) => {
        if (res.data.code == 200) {
          this.showFieldList = res.data.data.data;
        }
      });
      return true;
    },

    delRelation() {
      this.form.relationship_uuid = "";
    },
    showOption(bol, data) {
      if (this.extra.class == "CurrentDateTime" && bol) {
        this.dateFormat = data.filter(
          (item) => item.class == "CurrentDateTime"
        )[0].inputs.format.options;
      }
    },
    classChange(type, arr) {
      if (type == undefined) {
        return false;
      }
      let inputs = arr.filter((v) => v.class == type)[0];
      if (inputs && inputs.inputs) {
        inputs = inputs.inputs;
      } else {
        inputs = [];
      }
      this.rules = {
        class: [{ required: true, message: "请选择", trigger: "change" }],
      };
      this.extra = { class: type, payload: {} };
      this.form = { class: type };
      let _this = this;
      _this.formTypeData = [];
      this.$nextTick(() => {
        for (let key in inputs) {
          if (inputs[key].required) {
            if (
              inputs[key].type == "select" ||
              inputs[key].type == "switch" ||
              inputs[key].type == "field" ||
              inputs[key].type == "dictionary" ||
              inputs[key].type == "relationship"
            ) {
              if (inputs[key].type != "switch") {
                _this.rules[key] = [
                  { required: true, message: "请选择", trigger: "change" },
                ];
              }
            } else {
              _this.rules[key] = [
                { required: true, message: "请输入", trigger: "blur" },
              ];
            }
          }
          let obj = JSON.parse(JSON.stringify(inputs[key]));
          _this.$set(_this.extra.payload, key, inputs[key].default);
          _this.$set(_this.form, key, inputs[key].default);
          _this.$set(obj, "field", key);
          _this.formTypeData.push(obj);
        }
      });
      this.show = true;
    },
    commit() {
      this.$refs.extra.validate((valid) => {
        if (valid) {
          let tmp = JSON.parse(JSON.stringify(this.form));
          let tmp1 = JSON.parse(JSON.stringify(this.form));
          delete tmp.class;
          this.extra = {
            class: tmp1.class,
            payload: tmp,
          };
          if (this.templateData.dict_uuid) {
            this.extra.templateData = this.templateData;
          }
          console.log(this.extra, '提交的数据')
          this.validation = true;
        } else {
          this.validation = false;
        }
      });
    },

    // 选择了全局字典
    dictUuidChange(value) {
      if (!value) return
      this.getDictItemLoading = true
      object.getDictDetail(value).then(res => {
        this.getDictItemLoading = false
        if (res.data.code == 200) {
          this.dictItems = res.data.data.item
        }
      }).catch(() => this.getDictItemLoading = false)
    },

    /**
     * @description: 选择了字典项，直接切换下面的取值类别数据，并保存名称
     */
    itemChange(value) {
      if (!value) return
      let dict_item = this.dictItems.filter(item => item.uuid == value)[0]
      if (!dict_item) return
      this.templateData.item_name = dict_item.name
      let dict_item_value = JSON.parse(dict_item.value);
      let dict = this.valueDicts.filter(item => item.uuid == this.templateData.dict_uuid)[0]
      if (dict) {
        this.templateData.item_name = dict.name + ' - ' + dict_item.name
      }
      this.solveFormData(dict_item_value)
    }
  },
  mounted() {
    object.mapi.getSimpleList(
      'object617fbedcbd613',
      'view628aee3576000'
    ).then(res => {
      if (res.data.code == 200) {
        this.valueDicts = res.data.data
      }
    })

    object.getAll(1, 2000).then((res) => {
      if (res.data.code == 200) {
        this.objectData = res.data.data.data;
      }
    });

    object.getValueFunctions().then(res => {
      this.getValueClasses = res.data.data
    })
    object.getDictsSelect(this.obuuid).then(res => {
      this.dictionaryList = res
    })
    this.showFieldList = this.fieldList;
    this.showRelationList = this.relationshipList;
  },
};
</script>

<style lang="less">
.close-container {
  background: rgb(255, 255, 255);
  height: 30px;
  text-align: right;
  line-height: 20px;
  font-size: 14px;
  font-weight: 600;
  padding: 0 10px;
  box-sizing: border-box;
}
.new-label {
  font-size: 14px;
  line-height: 40px;
  color: #606266;
  margin-left: 20px;
}

.clickSpan {
  cursor: pointer;
  color: #5a9cfe;
  float: right;
}
</style>