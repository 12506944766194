/*
 * @Author: Shiltin 18580045074@163.com
 * @Date: 2022-08-10 15:31:30
 * @LastEditors: zx
 * @LastEditTime: 2022-11-03 18:04:52
 * @FilePath: \dataview-next\src\custom-component\component-config\model.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
const modelConsole = {
	type: 'model',
	title: '模型组件',
	show: true,
	order: 8,
	componentList: [
		{
			component: 'ViewerBIM',
			type: 'container',
			name: 'BIM模型',
			icon: 'icon-moxing',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			propValue: '',
			span: 24,
			statusConfig: {
				displayShow: true,
				isShow: true // 是否显示
			},
			style: {
				height: '100%',
				width: '',
				rotate: 0,
				paddingTop: 0,
				paddingBottom: 0,
				paddingLeft: 0,
				paddingRight: 0
			},
			behaviors: [
			],
			actionConfig:{
				needAction: true,
			},
		},
		// {
		// 	component: 'BIMModelList',
		// 	type: 'BIMExtend',
		// 	name: 'BIM模型列表',
		// 	icon: 'icon-moxing',
		// 	isLock: false, // 是否锁定
		// 	needCommonStyle: false,
		// 	propValue: '',
		// 	span: 24,
		// 	statusConfig: {
		// 		displayShow: true,
		// 		isShow: true // 是否显示
		// 	},
		// 	style: {
		// 		height: '200',
		// 		width: '300',
		// 		rotate: 0,
		// 		paddingTop: 0,
		// 		paddingBottom: 0,
		// 		paddingLeft: 0,
		// 		paddingRight: 0
		// 	},
		// 	behaviors: [
		// 	],
		// 	actionConfig:{
		// 		needAction: false,
		// 		width: '',
		// 	},
		// },
		{
			component: 'ViewerGIS',
			type: 'ModelGIS',
			name: 'GIS模型',
			icon: 'icongis_changjing',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			propValue: '',
			span: 24,
			statusConfig: {
				displayShow: true,
				isShow: true // 是否显示
			},
			style: {
				height: '100%',
				width: '',
				rotate: 0,
				paddingTop: 0,
				paddingBottom: 0,
				paddingLeft: 0,
				paddingRight: 0
			},
			behaviors: [
			],
			actionConfig:{
				needAction: false,
			},
		},
		{
			component: 'viewerBIMModule',
			type: 'container',
			name: '模型功能容器',
			icon: 'icongis_changjing',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			propValue: '',
			span: 24,
			statusConfig: {
				displayShow: true,
				isShow: true // 是否显示
			},
			style: {
				height: '100%',
				width: '',
				rotate: 0,
				paddingTop: 0,
				paddingBottom: 0,
				paddingLeft: 0,
				paddingRight: 0
			},
			behaviors: [
			],
			actionConfig:{
				needAction: false,
				width: '',
			},
		},
		{
			component: 'ViewerGISModule',
			type: 'container',
			name: 'GIS功能容器',
			icon: 'icongis_changjing',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			propValue: '',
			span: 24,
			statusConfig: {
				displayShow: true,
				isShow: true // 是否显示
			},
			style: {
				height: '100%',
				width: '',
				rotate: 0,
				paddingTop: 0,
				paddingBottom: 0,
				paddingLeft: 0,
				paddingRight: 0
			},
			behaviors: [
			],
			actionConfig:{
				needAction: false,
				width: '',
			},
		},
	]
};

export default modelConsole;
