<template>
	<div class="temp-child-pages">
		<!-- 头部标题 -->
		<div
			class="module-title"
			v-loading="loading"
		>
			<div class="left-content">
				<div
					class="pic"
					v-if="module?.image"
				>
					<div
						class="iconfont-div"
						:style="'background:' + module.background"
					>
						<svg
							aria-hidden="true"
							class="iconfont-svg"
						>
							<use :xlink:href="module.icon.indexOf('#') === -1 ? '#' + module.icon : module.icon" />
						</svg>
					</div>
				</div>
				<div class="left-text">
					<h1 class="title">
						{{ module?.name }}<span class="cost-type">{{ module?.is_pay === 0 ? '免费' : '付费' }}</span>
					</h1>
					<p>{{ module.describe }}</p>
				</div>
				<div class="btn">
				<div
					v-if="module.num"
					class="foot-btn-install"
					style="justify-content: center"
				>
					安装中
					<el-progress
						:width="12"
						color="#fff"
						define-back-color="red"
						:stroke-width="2"
						type="circle"
						:percentage="module.num"
						:show-text="false"
					></el-progress>
				</div>
				<div
					v-else
					class="right-btn"
					@click.stop="toInstall()"
				>
					<span v-if="module.is_install === 1">打开应用</span>
					<span v-else>{{ module.is_pay === 0 ? '安装模块' : '试用模块' }}</span>
				</div>
			</div>
			</div>
			<!-- 菜单栏 -->
			<div class="menu-nav">
				<div class="menu">
					<div
						class="min-menu"
						v-if="module.saas_apps_detail"
					>
						<a
							class="menu-box"
							v-for="(item, index) in module.saas_apps_detail"
							@click.stop="setActive(item, index)"
							:class="{ activeMenu: isActive === index }"
							:key="index"
							>{{ item.name }}</a
						>
					</div>
				</div>
			</div>
		</div>
		<div class="content">
			<div v-html="content"></div>
		</div>
	</div>
</template>

<script>
import { Progress } from 'element-ui';
export default {
	props: {
		module: {
			type: Object,
			default: () => {}
		}
	},
	components: {
		'el-progress': Progress
	},
	data() {
		return {
			isActive: null,
			content: null,
			moduleName: null,
			img: null,
			dialogVisible: false,
			confirmDialog: false,
			num: 0,
			installAll: false,
			isComplete: false,
			loading: false
		};
	},
	mounted() {
		this.setActive('', 0);
		this.moduleName = this.$route.query.name || this.name;
		this.content = this.module.saas_apps_detail?.[0]?.content;
		console.log(this.content, '图片@@@')
		this.img = this.module.large_icon?.[0]?.path;
		this.installList = JSON.parse(sessionStorage.getItem('appInstallList'));
		if (this.installList && this.installList.includes(this.module.id)) {
			this.$set(this.module, 'num', 60);
			let timer = setInterval(() => {
				this.module.num += 0.15;
				if (this.module.num > 90 || this.module.num === 100) {
					window.clearInterval(timer);
				}
			}, 100);
		}
	},
	watch: {
		module(val) {
			if (val) {
				this.loading = false;
				this.content = val.saas_apps_detail?.[0]?.content;
				this.img = val.large_icon?.[0]?.path;
			}
		}
	},
	methods: {
		// 跳转到安装页面
		toInstall() {
			if (+this.module.is_install === 0 && +this.module.is_pay === 1) {
				window.location.href = 'https://www.bimcc.com/web-page/apply-demo';
				return;
			}
			this.$emit('toInstall');
		},
		setActive(item, index) {
			this.isActive = index;
			this.content = item.content;
		}
	}
};
</script>
<style lang="less">
.module-pages-message-box {
	border: none;
	border-radius: 10px;
	.el-message-box__header {
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		height: 48px;
		background: linear-gradient(90deg, #6292ff 0%, #de93ff 84%, #ce92ff 91%, #c568ff 100%);
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 68px 0 24px;
		color: #fff;
		.el-message-box__title {
			color: #fff;
			font-size: 14px;
		}
		.el-message-box__headerbtn {
			top: 14px;
			right: 24px;
			.el-message-box__close {
				color: #fff;
				font-size: 20px;
			}
		}
	}
}
</style>
<style lang="less" scoped>
.foot-btn-install {
	width: 107px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #a5c7fe;
	border-radius: 6px;
	font-weight: 500;
	font-size: 14px;
	line-height: 22px;
	color: #ffffff;
	:deep(.el-progress--circle) {
		margin-left: 5px;
		.el-progress-circle__track {
			stroke: #a5c7fe !important;
		}
	}
}
.temp-child-pages {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
  .content img{
    height:  auto !important;
  }
	// 面包屑
	.header-title {
		margin: 0px 0 22px;
		font-family: 'Source Han Sans CN';
		span {
			padding-right: 10px;
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			color: #697077;
		}
		span:hover {
			color: #337eff;
		}
		.module-name {
			line-height: 24px;
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			color: #121619;
		}
		.iconfont {
			padding-right: 10px;
			font-size: 13px;
			transform: matrix(0, 1, 1, 0, 0, 0);
		}
	}
	// 头部标题
	.module-title {
		display: flex;
		flex-direction: column;
		justify-content: center;
		display: flex;
		align-items: center;
		font-family: 'Source Han Sans CN';
		padding-top: 12px;
		max-width: 960px;
		.left-content {
			display: flex;
			align-items: center;
			.pic {
				width: 72px;
				display: flex;
				align-items: center;
				height: 72px;
				text-align: center;
			}
			.left-text {
				padding: 0 12% 0 20px;
				box-sizing: border-box;
				.title {
					display: flex;
					align-items: center;
					font-style: normal;
					font-weight: 500;
					font-size: 20px;
					line-height: 28px;
					color: #161c1f;

					.cost-type {
						width: 41px;
						height: 20px;
						margin-left: 8px;
						padding: 1px 8px;
						box-sizing: border-box;
						border-radius: 4px;
						background: #76abff;
						font-weight: 500;
						font-size: 12px;
						line-height: 18px;
						letter-spacing: 0.4px;
						color: #ffffff;
					}
				}
				p {
					margin-top: 4px;
					font-weight: 400;
					font-size: 14px;
					line-height: 22px;
					letter-spacing: 0.5px;
					color: #41474c;
				}
			}
			.btn {
				display: flex;
				flex: 1;
				justify-content: flex-end;
				.right-btn {
					padding: 10px 16px;
					border-radius: 4px;
					width: 64px;
					height: 18px;
					background-color: #337eff;
					transition: all 0.2ms ease;
					span {
						line-height: 18px;
						font-style: normal;
						font-weight: 500;
						font-size: 16px;
						color: #fff;
					}
				}
				.right-btn:hover {
					transform: scale(1.1);
				}
				.query {
					border: 1px solid #cacaca;
					border-radius: 4px;
					background: #fff;
					span {
						font-weight: 600;
						color: #121619;
					}
				}
			}
		}
		// 菜单栏
		.menu-nav {
			// width: 960px;
			width: 100%;
			font-family: 'Source Han Sans CN';
			.menu {
				display: flex;
				padding-top: 64px;
				// width: 63%;
				height: 60px;
				line-height: 60px;
				text-align: center;
				font-size: 18px;
				.min-menu {
					display: flex;
					text-align: left;
					.menu-box {
						display: inline-block;
						position: relative;
						font-weight: 500;
						font-size: 16px;
						line-height: 24px;
						letter-spacing: 0.5px;
						color: #161c1f;
					}
					.menu-box:hover {
						color: #337eff;
					}
					.activeMenu:after {
						position: absolute;
						left: 50%;
						top: 28px;
						width: 30px;
						height: 4px;
						border-radius: 2px;
						background-color: #337eff;
						content: '';
						transform: translate(-50%, -50%);
					}
				}
			}
		}
	}
	.content {
		padding-top: 30px;
		flex: 1;
		margin-left: -100px;
		width: 100%;
		overflow-y: auto;
		::v-deep img{
			width: 100%;
			object-fit: contain;
		}
	}
	.install-box {
		height: 400px;
		background: url(https://static.bimcc.com/openim/166547535420953C16D9B364974BCEE9F397F03DF6339.png) no-repeat;
		background-position: center 0;
		background-size: cover;
		font-family: 'Source Sans Pro';

		-webkit-background-size: cover;
		-o-background-size: cover;
		-moz-background-size: cover;

		.content {
			text-align: center;
			transform: translateY(192px);
			.install-num {
				padding-bottom: 50px;
				line-height: 30px;
				font-style: normal;
				font-weight: 600;
				font-size: 26px;
				color: #131314;
			}
			.text {
				padding-bottom: 20px;
				font-size: 30px;
				color: #f4f4f4;
			}
			.btn {
				border: 1px solid #d9d9d9;
				border-radius: 12px;
				width: 190px;
				height: 54px;
				background: #f4f4f4;
				font-size: 20px;
				color: #131314;
				&:hover {
					transform: scale(1.1);
				}
			}
		}
	}
	.iconfont-div {
		width: 72px;
		height: 72px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 14px;
		.iconfont-svg {
			width: 56px;
			height: 56px;
			background-color: transparent;
		}
	}

	.el-button {
		font-size: 16px;
	}
}
</style>
