<template>
  <div class="gradient-background" :style="style">
  </div>
</template>

<script>
import Minin from './mixin'
export default {
  name: "GradientBackground",
  mixins: [Minin],
  computed: {
    style() {
      let result = ''
      if (this.color.length) {
        if (this.direction === 'repeating-radial-gradient') {
          result = `repeating-radial-gradient(${this.color.join(',')})`
        } else if (this.direction) {
          result = `linear-gradient(${this.direction},${this.color.join(',')})`
        } else {
          result = `linear-gradient(${this.color.join(',')})`
        }
      }
      // console.log(`background-image: ${result}`)
      return `background-image: ${result}`
    }
  },
};
</script>

<style lang="less" scoped>
.gradient-background{
  height: 100%;
  width: 100%;
}
</style>
