<template>
  <div
    class="common-tabs"
    v-loading="loading"
  >
    <el-tabs
      v-model="activeName"
      @tab-click="onTabClick"
      :type="statusConfig.type"
      :tab-position="statusConfig.tabPosition || 'top'"
      :class="[statusConfig.type]"
    >
      <!-- 固定参数 -->
      <template v-if="tabOrigin === 'fixed'">
        <el-tab-pane
          :label="item.label"
          :name="item.type"
          v-for="(item, index) in tabConfig"
          :key="index"
        >
          <template
            v-if="useSolt"
            #label
          >
            <span :style="{ color: activeName === item.type ? activeColor : defaultColor }">
              {{ item.label }}
            </span>
          </template>
        </el-tab-pane>
      </template>
      <!-- 字典 -->
      <template v-if="tabOrigin === 'dictionary'">
        <el-tab-pane
          :label="item.value"
          :name="item.code"
          v-for="(item, index) in dictionaryList"
          :key="index"
        >
          <template
            v-if="useSolt"
            #label
          >
            <span :style="{ color: activeName === item.code ? activeColor : defaultColor }">
              {{ item.value }}
            </span>
          </template>
        </el-tab-pane>
      </template>
      <!-- 动态数据 -->
      <template v-if="tabOrigin === 'dynamic'">
        <el-tab-pane
          :label="item[dynamicConfig.labelKey]"
          :name="item[dynamicConfig.valueKey].toString()"
          v-for="(item, index) in dynamicTabs"
          :key="index"
        >
          <template
            v-if="useSolt"
            #label
          >
            <span
              :style="{ color: activeName === item[dynamicConfig.valueKey].toString() ? activeColor : defaultColor }"
            >
              {{ item[dynamicConfig.labelKey] }}
            </span>
          </template>
        </el-tab-pane>
      </template>
    </el-tabs>
  </div>
</template>

<script>
import { TabPane, Tabs } from 'element-ui';
import { dataInterface } from '@/apis/data';
import eventBus from '@/plugins/eventBus';
import { initParams, removeEventBus } from '@/utils/tools';
import { mapState } from 'vuex';

export default {
  name: 'CommonTabs',
  props: {
    element: {
      type: Object,
      required: false,
      default: () => {}
    },
    componentList: {
      default: null
    },
    // 是否为分组
    isGroup: {
      type: Boolean,
    },
    // 当前分组的组件数据
    groupComponents: {
      type: Array,
      default: () => []
    }
  },
  components: {
    'el-tabs': Tabs,
    'el-tab-pane': TabPane
  },
  inject: ['EDITOR_pageUUID'],
  data() {
    return {
      activeName: '',
      // 字典配置的列表
      dictionaryList: [],
      // 动态数据
      loading: false,
      dynamicTabs: [],
      // 固定参数
      fixedInit: false,
      oldDictionaryConfig: ''
    };
  },
  computed: {
    ...mapState(['componentData']),
    tabConfig() {
      return this.element && this.element.tabConfig;
    },
    // 组件配置
    statusConfig() {
      return this.element ? this.element.statusConfig || {} : {};
    },
    // 参数类型
    tabOrigin() {
      return (this.element && this.element.tabOrigin) || 'fixed';
    },
    // 字典配置
    dictionaryConfig() {
      return this.element && this.element.dictionaryConfig;
    },
    // 动态数据
    dynamicConfig() {
      return this.element && this.element.dynamicConfig;
    },
    useSolt() {
      return this.element && this.element.statusConfig.useSolt;
    },
    defaultColor() {
      return this.element && this.element.statusConfig.defaultColor;
    },
    activeColor() {
      return this.element && this.element.statusConfig.activeColor;
    }
  },
  watch: {
    // 固定参数
    tabConfig: {
      handler() {
        if (this.tabOrigin !== 'fixed' || !this.tabConfig?.length) {
          return;
        }
        for (let i = 0; i < this.tabConfig.length; i++) {
          if (this.tabConfig[i].isFirst) {
            this.activeName = this.tabConfig[i].type;
            if (!this.fixedInit) {
              this.fixedInit = true;
              this.onTabClick({ index: i });
            }
            return;
          }
        }
        // test 此处代码仍有待考证
        if (!this.activeName) {
          this.activeName = this.tabConfig[0].type;
        }
      },
      deep: true,
      immediate: true
    },
    // 字典
    dictionaryConfig: {
      handler() {
        if (
          this.tabOrigin !== 'dictionary' ||
          !this.dictionaryConfig ||
          this.oldDictionaryConfig === this.dictionaryConfig
        )
          return;
        this.oldDictionaryConfig = this.dictionaryConfig;
        this.getDictionaryList();
      },
      deep: true,
      immediate: true
    },
    // tab数据来源
    tabOrigin: {
      handler(newv, oldv) {
        // 动态数据
        if (newv !== oldv && this.tabOrigin === 'dynamic') {
          this.getTabList(this.dynamicConfig);
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    const databaseTrigger = {
      [this.element.id]: data => {
        if (this.tabOrigin !== 'dynamic') return;
        let { paramsConfig } = this.element.database;
        let isTarget = false;
        for (let i = 0; i < paramsConfig.length; i++) {
          if (paramsConfig[i].componentId === data.componentId) {
            isTarget = true;
          }
        }
        if (!isTarget && !data.isInit && !data.isUpdate) return;
        this.getTabList(this.dynamicConfig);
      }
    }
    eventBus.$on('databaseTrigger', databaseTrigger[this.element.id]);
  },
  methods: {
    /**
     * @description: 被点击时触发回调
     * @param {Object} tab
     */
    onTabClick(tab) {
      if (this.tabOrigin === 'fixed') {
        // 固定
        const index = +tab.index;
        const comEvents = this.tabConfig[index]?.eventList || [];
        for (let i = 0; i < comEvents.length; i++) {
          const { pattern, eventList = [], specialEventList = [] } = comEvents[i];
          if (pattern === undefined) {
            console.log('老版本----', comEvents[i]);
            if (comEvents[i].key === 'click') {
              comEvents[i].effects.forEach(effect => {
                this.$store.commit('triggerEvents', {
                  config: {
                    ...comEvents[i],
                    ...effect
                  },
                  element: this.element
                });
              });
            }
            break;
          }
          const result = pattern === 'special' ? specialEventList : eventList;
          result.forEach(ele => {
            if (ele.key === 'click') {
              ele.effects.forEach(effect => {
                this.$store.commit('triggerEvents', {
                  config: {
                    ...ele,
                    ...effect
                  },
                  element: this.element
                });
              });
            }
          });
        }
      }
      // 暴露出绑定的数据
      const index = +tab.index;
      let resolveData = null;
      // 暴露数据
      if (this.tabOrigin === 'fixed') {
        resolveData = {
          label: this.tabConfig[index].label,
          value: this.tabConfig[index].type
        };
      } else if (this.tabOrigin === 'dictionary') {
        resolveData = {
          label: this.dictionaryList[index].value,
          value: this.dictionaryList[index].code
        };
      } else if (this.tabOrigin === 'dynamic') {
        resolveData = {
          ...this.dynamicTabs?.[index],
          label: this.dynamicTabs?.[index]?.[this.dynamicConfig.labelKey],
          value: this.dynamicTabs?.[index]?.[this.dynamicConfig.valueKey]
        };
      }
      this.element.resolveData = resolveData;
      this.$store.commit('modifyComponent', {
        component: {
          ...this.element,
          resolveData
        },
        containerId: null,
        isModify: true,
        pageUUID: this.EDITOR_pageUUID
      });
      // 触发事件
      // event bus  挂载卸载问题
      setTimeout(() => {
        eventBus.$emit('databaseTrigger', {
          componentId: this.element.id,
          action: 'any',
          isSearch: true,
          output: resolveData
        });
      }, 0);
    },
    /**
     * @desc: 获取字典配置的列表
     */
    getDictionaryList() {
      dataInterface({}, `api/metadata/dictionary/${this.dictionaryConfig}`, 'GET')
        .then(res => {
          if (!res) return;
          this.dictionaryList = res.data.data.item || [];
          if (this.dictionaryList?.length) {
            this.activeName = this.dictionaryList[0].code || '';
          }
          this.onTabClick({ index: 0 });
        })
        .catch(err => {
          console.log(err);
        });
    },
    /**
     * @desc: 获取字段列表
     */
    getTabList(config) {
      const {
        search = [],
        param = {},
        canPost
      } = initParams(
        this.element?.database?.paramsConfig || [],
        this.isGroup,
        this.componentList || this.componentData,
        this.groupComponents
      );
      if (!canPost) {
        console.log('无法请求')
        return false;
      }
      this.loading = true;
      dataInterface({
        __method_name__: 'dataList',
        object_uuid: config.objectUUID,
        view_uuid: config.viewUUID,
        search,
        ...param
      })
        .then(res => {
          this.loading = false;
          if (res.status !== 200 || !res?.data?.data) return;
          const data = res.data.data;
          this.dynamicTabs = Array.isArray(data) ? data : data.data || [];
          if (this.dynamicTabs && this.dynamicTabs.length) {
            this.activeName =
              (this.dynamicTabs?.[0]?.[this.dynamicConfig.valueKey] &&
                this.dynamicTabs?.[0]?.[this.dynamicConfig.valueKey].toString()) ||
              '';
          }
          this.onTabClick({ index: 0 });
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
        });
    }
  },
  beforeDestroy() {
    if (!this.EDITOR_pageUUID) {
     removeEventBus('databaseTrigger', this.element.id);
    }
  }
};
</script>

<style lang="less" scoped>
.common-tabs {
  width: 100%;
  overflow: hidden;
  :deep(.el-tabs) {
    &.block {
      background: rgba(239, 241, 246, 0.8);
      // box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.08), 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
      padding: 12px 0;
      .el-tabs__header {
        margin-bottom: 0;
        .el-tabs__nav-wrap::after {
          height: 0;
        }
        .el-tabs__nav-wrap {
          .el-tabs__nav-scroll {
            box-sizing: border-box;
            padding: 0 12px;
            .el-tabs__nav {
              .el-tabs__item {
                height: 32px;
                background: #fff;
                margin-right: 12px;
                box-sizing: border-box;
                padding: 0 20px;
                border-radius: 4px;
                line-height: 32px;
                &:last-child {
                  margin: 0;
                }
              }
            }
          }
        }
      }
    }
    &.primary-block {
      padding: 12px 0;
      .el-tabs__header {
        margin-bottom: 0;
        .el-tabs__nav-wrap::after {
          height: 0;
        }
        .el-tabs__nav-wrap {
          .el-tabs__nav-scroll {
            box-sizing: border-box;
            padding: 0 12px;
            .el-tabs__nav {
              .el-tabs__item {
                height: 32px;
                background: #fff;
                margin-right: 12px;
                box-sizing: border-box;
                padding: 0 20px;
                border-radius: 4px;
                line-height: 32px;
                &:last-child {
                  margin: 0;
                }
              }
              .is-active {
                background: #409eff;
              }
            }
          }
        }
      }
    }
  }
  .el-tabs__content {
    display: none;
  }
}
</style>
