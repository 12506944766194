<!--
    @name: widget-drawingList
    @description：widget-drawingList
    @author: ZengWei
    @date: 2022-03-29 10:58
-->
<template>
  <ChooseDrawing
    ref="drawingListRef"
    @set-data="setData"
    :search="searchData"
    :drawingData="element.value && element.value != null? element.value: []">
    <template v-slot:showType>
      <el-button
        style="width: 100%"
        @click="openModelDrawer('drawingListRef')"
        size="medium"
        type="primary"
        v-html="element.value && element.value != null && element.value.length != 0
          ? '已选择': '请选择'"
      ></el-button>
    </template>
  </ChooseDrawing>
</template>

<script>
import ChooseDrawing from "@/components/bindDrawing/ButtonType.vue";
import eventBus from "@/plugins/eventBus";
import {getSearchData} from "@/custom-component/form/newParser/scripts/tools";
export default {
  name: "WidgetDrawingList",
  components: {ChooseDrawing},
  mixins: [],
  props: ['element', 'disabled', 'displayData'],
  data() {
    return {
      searchData: []
    }
  },
  created() {
    this.initSearch();
  },
  mounted() {
    eventBus.$on('TRIGGER_selectChange',(formId)=>{
      const eleConf = this.element.config.__config__;
      const filterRule = eleConf?.filterRule || []
      const formIdArr = filterRule.filter(item => item.valueType === 2)
      const compFormId = Array.from(formIdArr,item => item.value)
      if(compFormId.includes(formId)){
        this.initSearch();
      }
    })
  },
  methods: {
    initSearch(){
      const filterOpen = this.element.config.__config__?.openFilter ?? false
      const filterRule = this.element.config.__config__?.filterRule ?? []
      if(filterOpen && filterRule.length>0){
        this.searchData = getSearchData(filterRule,this.parser.getFormIdValue);
      }
    },
    openModelDrawer(name) {
      this.$nextTick(()=>{
        this.$refs[name].show = true;
      })
    },
    setData(data){
      console.log(data,'数据')
      this.element.value = data;
    },
  },
}
</script>

<style scoped>

</style>
