<template>
  <div class="notice-edit-main" v-if="drawers">
    <el-dialog
      :visible.sync="drawers"
      :before-close="onClose"
      append-to-body
    >
    <template slot="title">
      <div class="avue-crud__dialog__header">
          <span>
            通知公告详情
          </span>
        <div class="avue-crud__dialog__menu" @click="dialogFull? dialogFull=false: dialogFull=true">
          <i class="el-icon-full-screen"></i>
        </div>
      </div>
    </template>
      <div class="form-class">
        <el-form 
          ref='form' 
          :model='form'  
          label-position="top">
          <el-form-item label="公告标题">{{form.name}}
          </el-form-item>
          <el-form-item label="内容" prop='content'>
            <div v-html="form.content"></div>
          </el-form-item>
        </el-form>
        
      </div>
      <div slot="footer">
        <el-button size="mini" @click="onClose">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { deepClone } from '@topology/core';
export default {
  name:'Detail',
  components: {  
  },
  props: {
    drawer:{
      type:Boolean,
      default:false,
    },
    data:{
      type:Object,
      default:() => {}
    }
  },
  data() {
    return {
      drawers:false,
      form:{},
      options:[{
        value: '通知',
        label: '通知'
      }, {
        value: '公告',
        label: '公告'
      }],
    };
  },
  watch: {
    drawer(newVal){
      this.drawers = newVal;
    },
    data(newVal){
      if(newVal && newVal.id){
        this.form = deepClone(newVal);
      }
    }
  },
  methods: {
    /**
     * @desc:关闭
     */
    onClose() {
      this.form = {};
      this.drawers = false;
      this.$emit('drawerClose');
    },
  },
};
</script>
<style lang="less">
.notice-edit-main{
  color: #444;
  text-align: left;
  .form-class{
    padding: 0px 24px 50px 24px;
    font-size: 14px;
    line-height: 1.5;
  }
  .el-form-item__label{
    padding: 0 0 8px !important;
    width:100% !important;
    text-align: left;
  }
  .bottom-btn{
    margin:0;
    position: absolute;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: right;
    right: 0;
    background: #fff;
    border-radius: 0 0 4px 4px;
    display: flex;
    justify-content: flex-end;
    padding-right: 43px;
  }
}
</style>

<style lang="less" scoped>
@import "../../../../css/manageAdd.less";
</style>