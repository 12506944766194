<template>
  <div class="drawer">
    <div class="gridTree">
      <el-tree
        :data="treeData"
        @node-click="triggerEvent"
        :default-expand-all="true"
      ></el-tree>
    </div>
    <div class="show">
      <PageParser v-if="!isWrite" :pageUUID="pageData"></PageParser>
      <component v-if="isWrite" :is="pageData"></component>
    </div>
    <footer class="footer">
      <el-row type="flex" justify="center">
        <el-col :span="12" class="flex"> </el-col>
        <el-col :span="12" class="flex">
          <el-button @click="reset">取消</el-button>
          <el-button type="primary" @click="commit">确定</el-button>
        </el-col>
      </el-row>
    </footer>
  </div>
</template>

<script>
import {Tree} from 'element-ui'
import PageParser from '@/components/parser/Index';
const componentsList = require.context("@/manage-views/views/workbench", true, /\.+vue$/);
const customComponents = {};
componentsList.keys().forEach((fileName) => {
  let name = fileName.replace(/.vue/, "");
  name = name.replace(/\.\//, "");
  name = name.split("/")[1];
  customComponents[name] = componentsList(fileName).default;
});

export default {
  name: "GridDrawer",
  components: {
    ...customComponents,
    PageParser,
    'el-tree': Tree
  },
  props: {
    treeData: {
      type: Array,
      default: () => [],
    },
    layout: {
      type: Array,
      default: () => [],
    },
    drawerIndex: {
      type: Number,
      required: false,
    },
  },

  watch: {
    drawerIndex() {
      this.getData();
    },
  },

  data() {
    return {
      isWrite: false, // 是否是手写页面
      pageData: null, // 页面名称（手写页面） 页面数据（配置页面）
    };
  },

  mounted() {
    this.getData();
  },

  methods: {
    getData() {
      this.isWrite = this.layout[this.drawerIndex].isWrite;
      this.pageData = this.layout[this.drawerIndex].pageData;
    },
    triggerEvent(row) {
      this.pageData = row.uuid || '';
      // 判断是否为配置页面  （手写页面不含page）
      if (this.pageData && this.pageData.includes("page")) {
        this.isWrite = false;
      } else {
        this.isWrite = true;
      }
    },
    reset() {
      this.pageData = null;
      this.$emit("reset");
    },
    commit() {
      this.$message.success("选择项目成功!");
      this.$emit("commit", this.pageData, this.isWrite);
    },
  },
};
</script>

<style lang="less" scoped>
.drawer {
  width: 100%;
  height: 100%;
}
.show{
  max-height: 400px;
  width: 100%;
  overflow: auto;
}
.footer {
  position: absolute;
  height: 40px;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 2;
}
.gridTree {
  height: 350px;
}
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
