/*
 * @Description: 快照
 * @Author: luocheng
 * @Date: 2021-08-10 16:45:55
 * @LastEditors: luocheng
 * @LastEditTime: 2021-08-12 15:37:48
 */
import store from './index';
import { deepCopy } from '@/utils/tools';

export default {
	state: {
		// 快照列表
		recordData: [],
		// 快照索引
		recordIndex: -1
	},
	mutations: {
		/**
		 * @desc: 返回上一步
		 */
		prevStep(state) {
			if (state.recordIndex >= 0) {
				state.recordIndex--;
				store.commit('setComponentData', deepCopy(state.recordData[state.recordIndex]));
			}
		},
		/**
		 * @desc: 下一步
		 */
		nextStep(state) {
			if (state.recordIndex < state.recordData.length - 1) {
				state.recordIndex++;
				store.commit('setComponentData', deepCopy(state.recordData[state.recordIndex]));
			}
		},
		/**
		 * @desc: 添加快照
		 */
		recordSnapshot(state) {
			// 添加新的快照
			state.recordData[++state.recordIndex] = deepCopy(state.componentData);
			// 在 撤销过程中，添加新的快照时，要将它后面的快照清理掉
			if (state.recordIndex < state.recordData.length - 1) {
				state.recordData = state.recordData.slice(0, state.recordIndex + 1);
			}
		}
	}
};
