<!--
 * @Author: your name
 * @Date: 2021-12-02 15:32:01
 * @LastEditTime: 2022-10-28 11:45:04
 * @LastEditors: Shiltin 18580045074@163.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \nanhu-micro\src\views\components\bindModel\index.vue
-->
<template>
  <div class="lat-choose-cont">
			<div class="header absolute">
				<div class="relative">
					<el-page-header
						class="page-header"
						content="获取经纬度"
						@back="back"
					/>
					<el-button
						class="absolute"
						size="mini"
						style="top: 8px; right: 10px"
						type="primary"
						@click="commit()"
					>
						确定
					</el-button>
				</div>
			</div>
      <div id="map2dCont" class="w-full relative h-full" >
				<div  class ="absolute choose-detail">
					<div v-if="latLng&&latLng!==null">
						<p>经度：{{latLng.lng}} 纬度：{{latLng.lat}}</p>
						<p>已选区域：{{info.area}}</p>
						<p>详细地址：{{info.fullName}}</p>
					</div>
					<p v-else>请选择坐标点并点击右侧"获取坐标"获取信息</p>
				</div>
      </div>
			<i v-if="modelData!=null" :style="{ top: chooseIcon.top, left: chooseIcon.left, zIndex: 100,color:'blue' }" class="icongis_dingwei iconfont absolute" ></i>
  </div>
</template>

<script>
import map from '@/assets/js/map2d.js'
import axiosRequest from '@/api/mapi.js'
import { PageHeader } from 'element-ui'
let map2d
export default {
  name: 'Bind',
  components: {
		'el-page-header':PageHeader
	},
  props: {
		modelData:{
			type:Object,
			default:null
		}
  },
	data() {
    return {
			latLng:null,
			info:{
				area:'',
				fullName:''
			},
			mapSize:4,
			chooseIcon:{
				top:0,
				left:0,
				lat:'',
				lng:''
			}
    }
	},
	watch: {
			mapSize: {
					handler: function () {
							this.listen()
					},
					deep: true,
					immediate: true,
			},
	},
	methods: {
		back () {
			this.$emit('back')
		},
		commit () {
			if(this.latLng === null){
				this.$message.warning("请选择坐标点");
			}else{
				this.$emit("set-data", this.latLng, this.info)
			}
		},
		getInfo(val){
			this.latLng = { lat: val.lat, lng: val.lng }
			const url = `https://api.tianditu.gov.cn/geocoder?postStr={'lon':${val.lng},'lat':${val.lat},'ver':1}&type=geocode&tk=c5aaa7bebd44c40e6e03f73df75582d2`
			axiosRequest.outRequest(url).then((res) => {
				if (res &&res.data && res.data.result && res.data.result.addressComponent) {
					const data = res.data.result.addressComponent
					this.info.area = data.province + '/' + data.city + '/' + data.county
					this.info.fullName = data.province + data.city + data.county + data.address
				}
			})
		},
		// 监听模型是否加载完毕
    async listen () {
      if (map2d && map2d !== null) {
				this.$nextTick(async()=>{
					this.mapSize = await map2d.getZoom()
					const posi = await map2d.latLngToContainerPoint({ lat: this.chooseIcon.lat, lng: this.chooseIcon.lng })
					if(posi && posi.x && posi.y){
						this.chooseIcon.top = posi.y - 22 + "px";
						this.chooseIcon.left = posi.x - 22 + "px";
					}
				})
      }
    },
	},
	mounted(){
		map2d =new map.Viewer2DGIS('map2dCont','20191204141039-1089838992-5de74ddfe3a2c.gislayer2d')
		window.map2d = map2d
    map2d.on('viewerPreLoad', () => {
      map2d.initionMap({ isFence: false, isSearch: false, isMeasure: false,isLayer:false})
    })
		map2d.on('viewerLoaded',()=>{
				map2d.setWidgetDisplay('ViewerGIS_stamp', true)
        //初始化加载完成后，可以监听画点结果
        map2d.on('stampCoordinates', (val)=>{
            console.log('画点获取坐标结果', val);
						this.getInfo(val)
        });
				if(this.modelData!=null && this.modelData.lat && this.modelData.lng){
					this.chooseIcon.lat = this.modelData.lat
					this.chooseIcon.lng = this.modelData.lng
					this.listen()
					this.getInfo(this.modelData)
					map2d.setView(this.modelData,16)
					map2d.on('move', async() => {
						this.listen()
					})
					map2d.emitMove()
				}

    });

	}
}
</script>
<style lang="less">
.lat-choose-cont {
    width: 100%;
    height: 100%;
		position:relative;
    overflow: hidden;
		.header{
			height: 45px;
			width: 100%;
			top: 0;
			left: 0;
			z-index: 100;
			background: #fff;
			.page-header {
					line-height: 45px;
					padding-left: 15px;
					border-bottom: 1px solid #dcdfe6;
			}
		}
		.el-page-header__content {
        font-size: 14px !important;
    }
    .el-page-header__left {
        color: #409eff;
        animation: fade 1500ms infinite;
        -webkit-animation: fade 1500ms infinite;
    }
    @keyframes fade {
        from {
            opacity: 1;
        }
        50% {
            opacity: 0.4;
        }
        to {
            opacity: 1;
        }
    }

    @-webkit-keyframes fade {
        from {
            opacity: 1;
        }
        50% {
            opacity: 0.4;
        }
        to {
            opacity: 1;
        }
    }
		.flex1{
			flex: 1;
		}
		.relative {
        position: relative;
    }
    .absolute {
        position: absolute;
    }
		.zIndexUp{
			z-index: 100;
			position: absolute
		}
		.zIndexDown{
			z-index: 0;
			position: absolute
		}
		.choose-detail{
			left:0px;
			top:45px;
			padding: 10px;
			background: #f5f5f5;
			opacity: 0.8;
		}
}

</style>
