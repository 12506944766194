<!--
 * @Author: zx
 * @Date: 2022-08-18 16:46:18
 * @LastEditTime: 2022-12-27 16:11:10
 * @Description:
 * @LastEditors: 朱廷果 1028509503@qq.com
-->
<template>
  <div class="menu-list" v-if="showMenuItem" :style="'width:' + width + 'px'">
    <p class="menu-list-name">
      <i :class="`mainicon iconfont ${currentMenu.icon}`"></i>
      {{ currentMenu.name }}
    </p>
    <div class="menu-list-content">
      <el-menu
        :default-openeds="openeds"
        v-if="currentMenu && currentMenu.children && currentMenu.children.length && showMenuItem"
        :default-active="defaultActive"
        class="el-menu-vertical-demo"
        @open="handleOpen"
        @close="handleClose">
        <div 
          v-for="(menu) in currentMenu.children"
          :key="menu.name + menu.id"
        >
          <el-submenu v-if="menu.type==1" :index="menu.id + ''">
            <template slot="title">
              <svg 
                aria-hidden="true"
                class="color-icon">
                <use xlink:href="#iconwenjianjia2" />
              </svg>
              <!-- <i class="iconfont iconwenjianjia2" style="margin-right: 10px;"></i> -->
              <span slot="title">{{ menu.name }}</span>
            </template>
            <el-menu-item
              :index="menuItem.id + ''"
              :class="itemPath.includes(menuItem.path)?'active':'menu-item'"
              v-for="(menuItem) in menu.children"
              :key="menuItem.id + ''"
              @click="jumpMenu(menuItem)"
            >
              <i :class="'iconfont ' + menuItem.icon"></i>
              <div slot="title">{{ menuItem.name }}</div>
            </el-menu-item>
          </el-submenu>
          <el-menu-item
            v-else
            style="padding-left: 12px !important"
            :index="menu.id + ''"
            class="menu-item"
            :class="itemPath.includes(menu.path)?'active':''"
            @click="jumpMenu(menu)"
          >
            <i :class="'iconfont ' + menu.icon"></i>
            <div slot="title">{{ menu.name }}</div>
          </el-menu-item>
        </div>
      </el-menu>
    </div>
  </div>
</template>
<script> 
import { Menu, MenuItem, Submenu } from "element-ui";
export default {
  components:{
    'el-menu': Menu,
    'el-menu-item': MenuItem,
    'el-submenu': Submenu,
  },
  props:{
    currentMenu: {
      type: Object,
      default: () => {
        return {}
      },
			required: true
    },
    currentRouteName: {
      type: String || Number,
      default: ''
    }
  },
  watch: {
    currentMenu(val) {
      console.log(val)
      if (val.name !== 'Home') {
        if (this.currentMenu && this.currentMenu.children && this.currentMenu.children.length) {
          this.defaultActive = ''
          this.showMenuItem = true
          this.changeRoute(this.currentRouteName)
        }
      }
    },
    currentRouteName(val) {
      this.changeRoute(val)
    },
    $route(){
      this.itemPath = window.location.href
    }
  },
  data() {
    return {
      defaultActive: '',
      showMenuItem: false,
      width: 180,
      itemPath:'',
      userPath:'',
      openeds:[]
    }
  },
  created() {
    this.showMenuItem = true
    this.changeRoute(this.currentRouteName)
    this.itemPath = window.location.href
    console.log(this.currentMenu)
    if(this.currentMenu.children && this.currentMenu.children.length) {
      this.currentMenu.children.forEach(item=>{
        this.openeds.push(item.id+'')
      })
    }
    console.log(this.openeds)
  },
  methods:{
    changeRoute(val) {
      this.currentMenu.children.forEach((element) => {
        if (+element.id === +val) {
          this.defaultActive = element.id + ''
        }
        if (element.children && element.children.length) {
          element.children.forEach(item => {
            if (+item.id === +val) {
              this.defaultActive = item.id + ''
            }
          });
        }
      });
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    jumpMenu(data) {
      this.$router.push({ path: data.path })
      if (this.currentMenu?.route && this.currentMenu.route === '/stystem') {
        this.$store.commit('setStystemItem', data)
      } else {
        this.itemPath = data.path
        this.$store.commit('setManageItem', data)
      }
      this.userPath = data.path
      this.$emit('currentMenuList', data)
    },
    closeMenuItemClick() {
      this.width = 0
    },
    showMenuItemClick() {
      this.width = 180
    }
  }
}
</script>
<style lang='less' scoped>
:deep(.el-submenu__title){
  height:32px;
  line-height: 32px;
  border-radius: 6px;
  margin-bottom: 4px;
  padding-left: 12px !important;
  display: flex;
  align-items: center;
  span{
    vertical-align: bottom;
  }
}
:deep(.el-submenu__title):hover{
  background: #F4F6FA;
}
:deep(.el-menu-item):hover{
  background: #F4F6FA;
}
.menu-list {
  width: 180px;
  height: 100%;
  transition: width 0.3s;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .el-menu-vertical-demo {
    border-right: none;
    width: 100%;
    overflow: hidden;
    .color-icon{
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
  }
  .menu-list-name {
    height: 56px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 20px;
    box-sizing: border-box;
    gap: 8px;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // white-space: nowrap;
    // vertical-align: middle;
    border-bottom: 1px solid #F6F8F9;
    color: #161C1F;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */

    letter-spacing: 0.5px;
    .mainicon{
      // margin-left: 20px;
      // margin-right: 5px;
      width: 24px;
      height: 24px;
      padding: 3px 0px;
      box-sizing: border-box;
      background: linear-gradient(180deg, #5ADFFA 0%, #459CFF 100%);
      border-radius: 4.5px;
      color: #fff;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
  }
  .menu-list-content {
    flex: 1;
    overflow-y: auto;
    padding: 8px 8px 0 8px;
    box-sizing: border-box;
    .active{
        background: #337EFF !important;
        border-radius: 6px;
        color: #fff;
        display: flex;
        align-items: center;  
        border-radius: 6px;
        width: 100%;
        padding:0 ;
        box-sizing: border-box;
        padding-left: 28px !important;
        height: 32px;
        line-height: 32px;
        text-align: left;
        min-width: 0;
        margin-bottom: 4px;
        i{
          margin-right: 10px;
          color: #fff !important;
        }

    }
    .active:hover{
      background: #337EFF !important;
      border-radius: 6px;
      color: #fff;
    }
    .menu-item {
      display: flex;
      align-items: center;
      border-radius: 6px;
      width: 100%;
      padding:0 ;
      padding-left: 28px !important;
      box-sizing: border-box;
      height: 32px;
      line-height: 32px;
      text-align: left;
      min-width: 0;
      margin-bottom: 4px;

      i {
        margin-right: 10px;
        color: #2A2F3D;
      }
      div {
        // font-size: 15px;
        text-overflow: ellipsis;
        white-space: nowrap;
        // height: 32px;
        font-size: 14px;
        font-family: PingFangSC-Regular,PingFang SC;
        font-weight: 400;
        display: inline-block;
        // line-height: 29px;
      }
    }
  }
}
</style>
