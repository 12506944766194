<!--
 * @Description: 全局消息表单弹窗
 * @Author: luocheng
 * @Date: 2021-12-20 19:09:02
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2022-10-18 11:08:28
 * @FilePath: /dataview-next/src/global/components/FormDialog.vue
-->
<template>
  <div class="global-dialog">
    <el-dialog
      v-loading="loading"
      custom-class="global-dialog-overscroll"
      :visible.sync="showDialog"
      :before-close="onFormSubmit"
      width="60%"
      append-to-body
      top="0">
      <!-- 自定义标题头 -->
      <template #title>
        <div class="custom-dialog-header">
          <div>
            {{ title }}
          </div>
        </div>
      </template>

      <!--自定义表单详情-->
      <Detail
        v-if="selfForm"
        :data-id="data_id"
        :module-uuid="form_uuid"
        :sideShow="true">
      </Detail>
      <PcParser
        v-else-if="prepared"
        ref="dialogForm"
        :form-data="formDesignData"
        :displayData="displayData"
        :disabled="disabled"
        :editFields="editFields"
        :needFlow="needFlow"
        :moduleUuid="formUUID"
        :needParentFlow="needParentFlow"
        :formMode="requestType"
        :nowArchiType="archi_type"
				@on-close="onEndFlow"
        @submit="onFormSubmit"
      >
      </PcParser>
    </el-dialog>
  </div>
</template>

<script>
import Detail from '@/manage-views/views/application/myApplication/formDataList/components/Detail';
import PcParser from "@/custom-component/form/newParser/PcParserNew";
import { transFieldsUUid, dataInterface } from "@/apis/data/index";
import eventBus from '@/plugins/eventBus';

export default {
  name: "GlobalDialog",
  components: {
    PcParser,
    Detail
  },
  props: {
    form_uuid: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: true,
    },
    data_id:{
      type:Number,
      default:0
    },
    title:{
      type:String,
      default:'提示'
    },
    tag:{
      type:String,
      default:''
    },
    archi_type:{
      type:String,
      default:''
    },
  },
  data() {
    return {
      showDialog: false,
      displayData: null,
      editFields: {},
      formDisabled: "dataInfo",
      formDesignData: {},
      // 流程
      needFlow: true,
      needParentFlow: false,
      formUUID: "",
      requestType: "detail",
      loading: true,
      prepared: false,
      selfForm: false,
    };
  },
  mounted() {
    this.formUUID = this.form_uuid;
    if(this.form_uuid.indexOf("formModule") > -1 ){
      // todo 自定义表单过来的通知
      this.showDialog = true;
      this.selfForm = true;
      this.loading = false;
      return;
    }

    // 以下是对象表单的通知
    this.showDialog = true;
    this.loading = true;
    transFieldsUUid("", this.formUUID,{tag: this.tag})
      .then((res) => {
        if (res.data.code == 200) {
          // this.needFlow = !!res.data.data.need_flow;
          // this.needParentFlow = !!res.data.data.need_parent_flow;
          // 编辑
          // 获取已经有的数据进行绑定
          let config = res.data.data;
          let ruuids = [];
          for (let i = 0; i < config.fields.length; i++) {
            let item = config.fields[i];
            if (item.__config__.tagIcon == "form") {
              ruuids.push({ relationship_uuid: item.__vModel__ });
            }
          }
          dataInterface({
            data_id: this.data_id,
            object_uuid: this.formUUID,
            view_uuid: "",
            ruuids,
            __method_name__: "dataInfo",
            __now_archi_type:this.archi_type,
            tag:this.tag
          }).then((res1) => {
            let data = res1.data.data;
            this.formDesignData = res.data.data;
            // console.log(this.formDesignData)
            this.displayData = data;
            this.editFields = data?.editFields || {};
            this.loading = false;
            this.prepared = true
            // this.showDialog = true;
          });
        }
      })
      .catch((err) => {
        console.error(err);
        this.loading = false;
      });
  },
  methods: {
    /**
     * @desc: 表单提交
     */
    onFormSubmit() {
      this.showDialog = false;
      this.loading = false;
      this.$emit('close');
    },
    /**
     * @desc: 操作完毕关闭弹窗
     */
    onEndFlow() {
      this.showDialog = false;
      this.loading = false;
      this.$emit('close');
      // 更新状态列表
      eventBus.$emit('updateTodoList');
    },
  },
};
</script>

<style lang="less" scoped>
.global-dialog-overscroll{
  margin-top:0 !important;
}

:deep(.global-dialog-overscroll){
  max-height: 80%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-radius: 8px 8px 6px 6px;
  box-sizing: border-box;
  padding: 0;

  .el-dialog__header{
    border-bottom: 1px solid #f2f3f5;
    padding: 0;
    background: transparent;
    .custom-dialog-header{
      height: 48px;
      background: linear-gradient(90deg, #6292FF 0%, #DE93FF 84%, #CE92FF 91%, #C568FF 100%);
      border-radius: 8px 8px 0px 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 68px 0 24px;
      color: #fff;
      .action-row{
        display: flex;
        .action-item{
          color: #fff;
          font-size: 20px;
          & + .action-item{
            margin-left: 24px;
          }
        }
      }
    }
    .el-dialog__headerbtn{
      top: 14px;
      right: 24px;
      .el-dialog__close{
        color: #fff;
        font-size: 20px;
      }
    }
  }

  .el-dialog__body {
    box-sizing: border-box;
    flex: 1;
    width: 100%;
    padding: 0px;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;

    .pcparser-warp{
      padding: 0 10px;
      box-sizing: border-box;
      flex: 1;
      width: 100%;
      overflow-y: hidden;
      display: flex;
      flex-direction: column;
    }
  }
}

</style>
