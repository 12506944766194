/*
 * @Description: 工具函数
 * @Author: luocheng
 * @Date: 2021-08-10 16:41:38
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2023-01-04 11:37:27
 */
import Vue from 'vue';
import Cookies from 'js-cookie';
import httpConfig from '@/apis/http/config';
import store from '@/store';
import eventBus from '@/plugins/eventBus';

const vm = new Vue();
/**
 * @desc: 深拷贝
 * @param {Object} target 需要被拷贝的对象
 * @return {Object} 输出对象
 */
export function deepCopy(target) {
	if (typeof target == 'object') {
		const result = Array.isArray(target) ? [] : {};
		for (const key in target) {
			if (typeof target[key] == 'object' && target[key]) {
				result[key] = deepCopy(target[key]);
			} else {
				result[key] = target[key];
			}
		}
		return result;
	}
	return target;
}

/**
 * @desc: 生成组件Id name + Timestamp
 * @param {String} name 组件代号(类型)
 * @param {String} isBach 是否批量操作
 */
export function getComponentId(name, isBach = false) {
	return `${name || 'component'}-${new Date().getTime()}${isBach ? '-' + Math.random().toFixed(5) * 100000 : ''}`;
}

/**
 * @desc: 元素选择器
 * @param {String} 选择器字符串
 */
export function $(selector) {
	return document.querySelector(selector);
}

/**
 * @desc: 将数组中某个下标元素移动到指定下标
 * @param {Array} arr 数组
 * @param {Number} i 被移动
 * @param {Number} j 需要移动到的位置
 */
export function arrSwap(arr, i, j) {
	const temp = arr[i];
	arr[i] = arr[j];
	Vue.set(arr, i, arr[j]);
	Vue.set(arr, j, temp);
}

/**
 * @desc: 节流函数
 * @param {Function} func 需要执行的放
 * @param {number} wait 等待的时长(毫秒)
 */
export function throttle(fn, delay = 1000) {
	let prevTime = Date.now();
	return function () {
		let curTime = Date.now();
		if (curTime - prevTime > delay) {
			fn.apply(this, arguments);
			prevTime = curTime;
		}
	};
}

/**
 * @desc: 防抖
 * @param {Function} func
 * @param {Number} delay 延迟时长 (毫秒)
 */
export function debounce(func, delay) {
	let timeout;
	return function () {
		let context = this; // 指向全局
		let args = arguments;
		if (timeout) {
			clearTimeout(timeout);
		}
		timeout = setTimeout(() => {
			func.apply(context, args); // context.func(args)
		}, delay);
	};
}

/**
 * @desc: 扁平化树形数据
 * @param {Array} arr 需要扁平化的数据
 * @param {String} childKey 存放后代节点的key
 * @param {Object} config 输出结果的配置  label&value的对应key
 * @param {Array} result 结果数组
 */
export function flatTree(treeData, childKey, config, excludes = [], result = []) {
	if (treeData && Array.isArray(treeData) && treeData.length) {
		treeData.forEach((ele) => {
			// 排除
			if (!excludes || !excludes.length || !excludes.includes(ele.id)) {
				if (config) {
					result.push({
						...ele,
						label: ele[config.label],
						value: ele[config.value]
					});
				} else {
					result.push(ele);
				}
			}
			if (ele[childKey] && Array.isArray(ele[childKey])) {
				result = result.concat(flatTree(ele[childKey], childKey, config, excludes, []));
			}
		});
	}
	return result;
}

/**
 * @desc: 根据ID获取组件对象
 * @param {Array} componentList 组件列表
 * @param {String} comId 组件ID
 * @return {Object} 组件对象/null
 */
export function getComponentById(componentList = [], comId) {
	for (let i = 0, len = componentList.length; i < len; i++) {
		const item = componentList[i];
		if (item.id === comId) {
			return item;
		}
		if (item.children && Array.isArray(item.children) && item.children.length) {
			const result = getComponentById(item.children, comId) || null;
			if (result) {
				return result;
			}
		}
	}
	return null;
}

/**
 * @desc: 获取兄弟节点列表
 * @param {Array} arr 组件列表
 * @param {Object} target 组件对象
 * @return {Array} 兄弟节点列表
 */
export function getSiblings(arr, target) {
	if (arr.find((ele) => ele.id === target.id)) return arr;
	for (let i = 0; i < arr.length; i++) {
		const item = arr[i];
		if (item.children) {
			if (item.children.find((ele) => ele.id === target.id)) {
				return item.children;
			}
			const result = getSiblings(item.children, target);
			if (result) {
				return result;
			}
		}
	}
	return null;
}

export const TOKEN_KEY = 'bimcctoken';
/**
 * @desc: 设置token
 */
export const setToken = (token) => {
	Cookies.set(TOKEN_KEY, token, { expires: httpConfig.cookieExpires || 1 });
	if (httpConfig.realUrl == 'http://mobile.wj.bimcc.net') {
		Cookies.set(TOKEN_KEY, token, { expires: httpConfig.cookieExpires || 1, path: '/', domain: 'mobile.wj.bimcc.net' });
	}
};

/**
 * @desc: 获取token
 */
export const getToken = () => {
	const token = Cookies.get(TOKEN_KEY);
	if (token != undefined && token != '') {
		return token;
	}
	return '';
};

export const DEV_TOKEN_KEY = 'dev_metadatatoken';
/**
 * @desc: 设置dev_token
 */
export const setDevToken = (token) => {
	Cookies.set(DEV_TOKEN_KEY, token, { expires: httpConfig.cookieExpires || 1 });
};

/**
 * @desc: 获取dev_token
 */
export const getDevToken = () => {
	const token = Cookies.get(DEV_TOKEN_KEY);
	if (token != undefined && token != '') {
		return token;
	}
	return '';
};

/**
 * @desc: 获取用户的IP
 */
export const getUserIP = () => {
	let MyPeerConnection = window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection;
	let pc = new MyPeerConnection({
		iceServers: []
	});
	let noop = () => {
	};
	let localIPs = {};
	let ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g;
	let iterateIP = (ip) => {
		if (!localIPs[ip]) {
			localStorage.setItem('innerIp', ip)
		}
		localIPs[ip] = true;
	};
	pc.createDataChannel('');
	pc.createOffer().then((sdp) => {
		sdp.sdp.split('\n').forEach(function (line) {
			if (line.indexOf('candidate') < 0) return;
			line.match(ipRegex).forEach(iterateIP);
		});
		pc.setLocalDescription(sdp, noop, noop);
	}).catch((reason) => {
		console.log(reason);
	});
	pc.onicecandidate = (ice) => {
		if (!ice || !ice.candidate || !ice.candidate.candidate || !ice.candidate.candidate.match(ipRegex)) return;
		ice.candidate.candidate.match(ipRegex).forEach(iterateIP);
	};
}

/**
 * @desc: 获取当前登录的组织架构
 * @param {String} key 需要获取的子属性
 * @return {Object|String|Any}
 */
export const getTargetArchi = (key = '') => {
	let targetArchi = localStorage.getItem('targetArchi');
	if (!targetArchi) return '';
	targetArchi = JSON.parse(targetArchi);
	if (key && typeof key === 'string' && typeof targetArchi === 'object') {
		return targetArchi[key] || undefined;
	}
	return targetArchi;
}

/**
 * @desc: 获取用户信息
 * @param {String} key 需要获取的子属性
 * @return {Object|String|Any}
 */
export const getUserInfo = (key = '') => {
	let userInfo = localStorage.getItem('userInfo');
	if (!userInfo) return '';
	userInfo = JSON.parse(userInfo);
	if (key && typeof key === 'string' && typeof userInfo === 'object') {
		return userInfo[key] || undefined;
	}
	return userInfo;
}

/**
 * @description 格式化时间
 * @param time
 * @param cFormat
 * @returns {string|null}
 */
export function parseTime(time, cFormat) {
	if (arguments.length === 0) {
		return null
	}
	const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
	let date
	if (typeof time === 'object') {
		date = time
	} else {
		if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
			time = parseInt(time)
		}
		if (typeof time === 'number' && time.toString().length === 10) {
			time = time * 1000
		}
		date = new Date(time)
	}
	const formatObj = {
		y: date.getFullYear(),
		m: date.getMonth() + 1,
		d: date.getDate(),
		h: date.getHours(),
		i: date.getMinutes(),
		s: date.getSeconds(),
		a: date.getDay(),
	}
	return format.replace(/{([ymdhisa])+}/g, (result, key) => {
		let value = formatObj[key]
		if (key === 'a') {
			return ['日', '一', '二', '三', '四', '五', '六'][value]
		}
		if (result.length > 0 && value < 10) {
			value = '0' + value
		}
		return value || 0
	})
}

/**
 * @desc: 判断字符串是否为JSON字符串
 * @param {any} str
 * @return {Boolean}
 */
export const isJSONStr = str => {
	if (typeof str == 'string') {
		try {
			const obj = JSON.parse(str);
			if (typeof obj == 'object' && obj) {
				return true;
			} else {
				return false;
			}
		} catch (e) {
			// console.log('error：'+str+'!!!'+e);
			return false;
		}
	}
}

/**
 * 获取当前日期时间
 * @param type 1:yyyy-MM-dd 2:yyyy-MM-dd HH:ii:ss 3:HH:ii:ss
 * @return {string}
 */
export const getNowTime = (type = 1) => {
	let yy = new Date().getFullYear();
	let mm = new Date().getMonth() + 1;
	let dd = new Date().getDate();
	let hh = new Date().getHours();
	let ii = new Date().getMinutes() < 10
		? '0' + new Date().getMinutes()
		: new Date().getMinutes();
	let ss = new Date().getSeconds() < 10
		? '0' + new Date().getSeconds()
		: new Date().getSeconds();

	if (type === 1) {
		return yy + '-' + mm + '-' + dd;
	} else if (type === 2) {
		return yy + '-' + mm + '-' + dd + ' ' + hh + ':' + ii + ':' + ss;
	} else {
		return hh + ':' + ii + ':' + ss;
	}
}


/**
 * @desc: 获取URL参数
 * @param {STring} queryName 获取url的参数名
 * @return {*}
 */
export const getQueryValue = (queryName = '') => {
	if (!queryName || typeof queryName !== 'string') return '';
	const reg = new RegExp("(^|&)" + queryName + "=([^&]*)(&|$)", "i");
	const r = window.location.search.substr(1).match(reg);
	if (r) {
		return decodeURI(r[2]);
	}
	return '';
}

/**
 * desc: 捕获async/await 错误
 * 返回一个Promise且值是一个数组，数组之中有两个元素，如果索引为0的元素不为空值，则说明报错，如果索引0的元素为空值说明成功
 * @param { Promise } 传进去的请求函数
 * @param { Object= } errorExt - 拓展错误对象
 * @return { Promise } 返回一个Promise
 * 使用方法 const [resError, res] = await to(request('/basic/login', {
		username: 'admin',
		password: '123456'
	}))
 */
export const getAwaitError = (promise, errorExt) => {
	return promise
		.then(data => [null, data])
		.catch(err => {
			if (errorExt) {
				const parsedError = Object.assign({}, err, errorExt);
				return [parsedError, undefined];
			}
			return [err, undefined];
		});
};


/**
 * @desc: 判断当前架构和权限配置是否允许操作
 * 当前架构在架构限制列表之一 且 当前用户具有权限限制列表其中之一，同时满足， 有限判断架构信息
 * @param {Array} archiLimit 架构限制列表
 * @param {Array} permissions 权限列表
 * @return {Boolean}
 */
// eslint-disable-next-line no-unused-vars
export const judgingArchiAPermission = (archiLimit = [], permissions = [], boo = false) => {
	// 暂时没有架构 可能未来需要
	// 用于兼容admin 
	const userId = getUserInfo('id');
	if (+userId === 1) return true;
	if ((!archiLimit || !archiLimit.length) && (!permissions || !permissions.length)) {
		return true;
	}
	const targetArchi = getTargetArchi('archiType');
	if (!targetArchi || (archiLimit && archiLimit.length && !archiLimit.includes(targetArchi)) && !boo) {
		return false;
	}
	if (!permissions || !permissions.length) {
		return true;
	}
	// 权限配置
	let userRoles = JSON.parse(localStorage.getItem('userRoles'));
	if (!userRoles) {
		return false;
	}
	let userRoleList = userRoles.toString().split(',');
	if (!userRoleList || !userRoleList.length) {
		return false;
	}
	for (let i = 0, len = userRoleList.length; i < len; i++) {
		if (permissions.includes(userRoleList[i])) {
			return true;
		}
	}
	return false;
}
export const judgingAPermission = (permissions = []) => {
	const archiLimit = []
	// const boo = true 
	// 暂时没有架构 可能未来需要
	// 用于兼容admin 
	const userId = getUserInfo('id');
	if (+userId === 1) return true;
	if ((!archiLimit || !archiLimit.length) && (!permissions || !permissions.length)) {
		return true;
	}
	// const targetArchi = getTargetArchi('archiType');
	// if ((archiLimit && archiLimit.length && !archiLimit.includes(targetArchi)) && !boo) {
	// 	return false;
	// }
	if (!permissions || !permissions.length) {
		return true;
	}
	// 权限配置
	let userRoles = JSON.parse(localStorage.getItem('userRoles'));
	if (!userRoles) {
		return false;
	}
	let userRoleList = userRoles.toString().split(',');
	if (!userRoleList || !userRoleList.length) {
		return false;
	}
	for (let i = 0, len = userRoleList.length; i < len; i++) {
		if (permissions.includes(userRoleList[i])) {
			return true;
		}
	}
	return false;
}

/**
 * @desc: 判断UI渲染逻辑
 * @param {null/ Object} UILogic 逻辑配置
 * @param {Array} componentData 组件数据
 * @param {Object} mapData 映射值
 */
export const judgingUILogic = (UILogic, componentData, mapData = null) => {
	if (!UILogic || !UILogic.enable) return true;
	const { logicConfigs } = UILogic;
	const logicList = [];
	for (let i = 0; i < logicConfigs.length; i++) {
		const item = logicConfigs[i];
		const { dataAttr, field, itemLogic, logicRule, type, value, valueType,
			componentId,
			dataOrigin,
			valueField,
			urlParamsKey,
			unitType,
			unitLogic,
			mapField
		} = item
		// 逻辑关系
		let itemBoolean = true;
		let logicValue = null;
		if (type === 'userInfo') {
			// 用户信息
			const userInfo = localStorage.getItem('userInfo') && JSON.parse(localStorage.getItem('userInfo'));
			logicValue = userInfo && userInfo[field];
			if (dataAttr === 'length') {
				logicValue = logicValue.length;
			}
		} else if (type === 'framework') {
			// 架构信息
			const targetArchi = localStorage.getItem('targetArchi') && JSON.parse(localStorage.getItem('targetArchi'));
			logicValue = targetArchi && targetArchi[field];
			if (dataAttr === 'length') {
				logicValue = logicValue.length;
			}
		} else if (type === 'component') {
			// 组件取值
			let originComponent = getComponentById(componentData, componentId);
			if (dataOrigin === 'resolveData') {
				// 暴露值
				logicValue = originComponent?.resolveData?.[valueField]
			} else {
				// componentData 绑定值
				logicValue = originComponent.propValue;
			}
		} else if (type === 'url') {
			// URL参数
			logicValue = getQueryValue(urlParamsKey);
		} else if (type === 'enterpriseType') {
			// 五方
			logicValue = unitType || [];
		} else if (type === 'mapValue') {
			// 映射值
			logicValue = mapData?.renderData?.[mapField];
		}
		// 逻辑关系
		if (type !== 'enterpriseType') {
			if (logicRule === '===') {
				if (valueType === 'string') {
					itemBoolean = logicValue === value;
				} else if (valueType === 'number') {
					itemBoolean = +logicValue === +value;
				} else if (valueType === 'date') {
					itemBoolean = new Date(logicValue).getTime() === value;
				}
			} else if (logicRule === '!=') {
				if (valueType === 'string') {
					itemBoolean = logicValue !== value;
				} else if (valueType === 'number') {
					itemBoolean = logicValue !== +value;
				} else if (valueType === 'date') {
					itemBoolean = new Date(logicValue).getTime() !== value;
				}
			} else if (logicRule === '>') {
				if (valueType === 'number') {
					itemBoolean = logicValue > +value;
				} else if (valueType === 'date') {
					itemBoolean = new Date(logicValue).getTime() > value;
				}
			} else if (logicRule === '<') {
				if (valueType === 'number') {
					itemBoolean = logicValue < +value;
				} else if (valueType === 'date') {
					itemBoolean = new Date(logicValue).getTime() < value;
				}
			} else if (logicRule === '<=') {
				if (valueType === 'number') {
					itemBoolean = logicValue <= +value;
				} else if (valueType === 'date') {
					itemBoolean = new Date(logicValue).getTime() <= value;
				}
			} else if (logicRule === '>=') {
				if (valueType === 'number') {
					itemBoolean = logicValue >= +value;
				} else if (valueType === 'date') {
					itemBoolean = new Date(logicValue).getTime() >= value;
				}
			}
		} else {
			// 五方逻辑判断
			const targetUnitTypes = getTargetArchi('external_company_type') || [];
			const unitArr = targetUnitTypes.map(ele => +ele.code);
			// 包含一个  in
			// 完全相等  equal
			// 全不包含  none
			if (unitLogic === 'in') {
				itemBoolean = false;
				for (let j = 0; j < logicValue.length; j++) {
					if (unitArr.includes(+logicValue[j])) {
						itemBoolean = true;
						break;
					}
				}
			} else if (unitLogic === 'equal') {
				itemBoolean = true;
				for (let j = 0; j < logicValue.length; j++) {
					if (!unitArr.includes(+logicValue[j])) {
						itemBoolean = false;
						break;
					}
				}
			} else if (unitLogic === 'none') {
				itemBoolean = true;
				for (let j = 0; j < logicValue.length; j++) {
					if (unitArr.includes(+logicValue[j])) {
						itemBoolean = false;
						break;
					}
				}
			}
		}
		logicList.push({
			itemBoolean,
			itemLogic
		});
	}
	if (logicList.length === 1) {
		return logicList[0].itemBoolean;
	}
	// 组合逻辑判断
	let prevLogicBoolean = null;
	for (let j = 1; j < logicList.length; j++) {
		const prev = logicList[j - 1];
		const prevItemLogic = prev.itemLogic;
		if (prevItemLogic === 'and') {
			if (j === 1) {
				prevLogicBoolean = prev.itemBoolean && logicList[j].itemBoolean
			} else {
				prevLogicBoolean = prevLogicBoolean && logicList[j].itemBoolean;
			}
			if (!prevLogicBoolean) {
				return false;
			}
		} else if (prevItemLogic === 'or') {
			if (j === 1) {
				prevLogicBoolean = prev.itemBoolean || logicList[j].itemBoolean
			} else {
				prevLogicBoolean = prevLogicBoolean || logicList[j].itemBoolean
			}
			if (!prevLogicBoolean) {
				return false;
			}
		}
	}
	return true;
};

/**
 * @desc: 通过配置的模板获取跳转链接
 * @param {String} temp	模版
 * @param {Object} rowData 普通取值对象
 */
export const getLinkByTemplate = (temp, rowData = {}) => {
	return temp.replace(/{([\s\S]*?)}/g, (_, name) => {
		if (name === 'hosts') {
			return window.location.origin;
		}
		if (name === 'token') {
			return getToken();
		}
		// 普通参数
		return rowData[name];
	});
}

// 检查后端返回数据是否包含分页
export const checkPaginate = (data) => {
	if (Reflect.has(data, 'current_page')) {
		return true;
	}
	return false;
}

/**
 * @desc: 重新生成组件ID（复制组合）
 */
export const changeComponentsId = (arr = []) => {
	return arr.map(ele => {
		let { children, component } = ele;
		if (children && Array.isArray(children) && children.length) {
			children = changeComponentsId(children);
		}
		return {
			...ele,
			children,
			id: getComponentId(component, true)
		}
	})
}

/**
 * @desc: 获取请求参数
 * @param {Array} paramsConfig 参数配置
 * @param {Boolean} isGroup 是否为分组
 * @param {Array} componentData 组件数据
 * @param {Array} groupComponents 分组数据
 * @return {Object}
 */
export const initParams = (paramsConfig = [], isGroup = false, componentData = [], groupComponents = []) => {
	let canPost = true;
	if (!paramsConfig || !paramsConfig.length) return { canPost };
	let search = [];
	const param = {};
	const { _PageCustomStatus = {}, _APPCustomStatus = {} } = store?.state || {};
	for (let i = 0; i < paramsConfig.length; i++) {
		const ele = paramsConfig[i];
		let originComponent = getComponentById(componentData, ele.componentId);
		if (!originComponent && isGroup && groupComponents.length) {
			originComponent = getComponentById(groupComponents, ele.componentId);
		}
		if (ele.key !== 'search') {
			if (ele.sourceType === 'url') {
				// url参数
				const queryVal = getQueryValue(ele.urlParamsKey);
				vm.$set(param, ele.key, queryVal);
				if (ele.isRequired && !queryVal) {
					canPost = false;
					break;
				}
			} else if (!ele.sourceType) {
				// 来源于组件
				// 兼容三端组件！！！ 如果不存在三端组件那么将不验证其值是否存在(南华专用)
				// 存在暴露单条数据和数据列表的情况
				const { archiLimit = [], permission = [], UILogic = [] } = originComponent || {};
				if ((!originComponent
					|| !originComponent?.statusConfig?.isShow
					|| !judgingArchiAPermission(archiLimit, permission)
					|| !judgingUILogic(UILogic)
				) &&
					ele.componentId.indexOf('CommonTree-') === 0) {
					canPost = true;
					continue;
				}
				if (ele.comValueType === 'selectList') {
					// 当前选中的列表(特殊的如甘特图，列表之类)
					if (ele.isRequired && (!originComponent || !originComponent.resolveSelectData)) {
						canPost = false;
						break;
					}
					const { resolveSelectData } = ele;
					vm.$set(param, ele.key, resolveSelectData || []);
					if (ele.isRequired && (!resolveSelectData || !Array.isArray(resolveSelectData) || !resolveSelectData.length)) {
						canPost = false;
						break;
					}
				} else {
					// 常规单条数据
					// 验证必填
					if (ele.isRequired && (!originComponent || !originComponent.resolveData)) {
						canPost = false;
						break;
					} else if (originComponent && originComponent.resolveData) {
						const queryVal = originComponent && originComponent.resolveData[ele.paramKey];
						vm.$set(param, ele.key, queryVal);
						if (ele.isRequired && !queryVal) {
							canPost = false;
							break;
						}
					}
				}
			} else if (ele.sourceType === 'system') {
				// 系统及架构参数 用户信息，架构信息
				let systemObject = null;
				if (ele.systemKey) {
					if (ele.systemKey === 'userInfo') {
						systemObject = getUserInfo()
					} else if (ele.systemKey === 'targetArchi') {
						systemObject = getTargetArchi()
					}
				}
				let systemValue = '';
				if (systemObject && ele.systemCode) {
					systemValue = systemObject[ele.systemCode];
				}
				vm.$set(param, ele.key, systemValue);
				if (ele.isRequired && (!systemObject || !systemValue)) {
					canPost = false;
					break;
				}
			} else if (ele.sourceType === 'fixed') {
				// 固定参数
				vm.$set(param, ele.key, ele.fixedValue || '');
			} else if (ele.sourceType === 'pageStatus') {
				// 页面状态
				const statusCodeValue = _PageCustomStatus[ele.statusCode] || _APPCustomStatus[ele.statusCode];
				vm.$set(param, ele.key, statusCodeValue || '');
			}
		} else {
			if (originComponent && originComponent.resolveData && Array.isArray(originComponent.resolveData)) {
				search = search.concat(originComponent.resolveData);
			}
		}
	}
	return {
		search,
		param,
		canPost
	};
}
/**
 * @desc: 验证配置的事件逻辑
 * @param {Array} logics
 * @param {Array} componentData
 * @param {Object} rowData 行数据
 */
export const judgingEventLogic = (logics, componentData, rowData) => {
	if (!logics || !Array.isArray(logics) || !logics.length) return true;
	let logicBoolean = true;
	for (let i = 0; i < logics.length; i++) {
		const item = logics[i];
		// type: 'component/ system
		// dataAttr: "value"
		// dataField: "field61a885dcdbd42"
		// dataKey: "resolveData"
		// itemLogic: "and"
		// logicRule: "!="
		// target: "CommonTree-1642581368412"
		// type: "component" 暂未启用
		// value: "version"
		// valueType: "string"
		const { type, dataAttr, dataField, dataKey, itemLogic, logicRule, value, valueType, valueObj, valueKey } = item;
		let resolveData = null;
		// console.log(type, '-----type 取值类型')
		if (type === 'component') {
			// 对象参数
			const resolveTargetKey = item.target;
			if (!dataKey || !resolveTargetKey || !valueType || !logicRule || !dataAttr) {
				break;
			}
			const resolveTarget = getComponentById(componentData, resolveTargetKey);
			if (!resolveTarget) {
				break;
			}
			if (!resolveTarget.statusConfig.isShow) {
				logicBoolean = false;
				break;
			}
			if (dataKey === 'propValue') {
				resolveData = resolveTarget.propValue;
			} else if (dataAttr === 'value') {
				resolveData = resolveTarget.resolveData?.[dataField];
			} else if (dataAttr === 'length') {
				resolveData = resolveTarget.resolveData.length;
			}
		} else if (type === 'system') {
			// 前端取值对象
			let result = sessionStorage.getItem(valueObj);
			if (!result) {
				result = localStorage.getItem(valueObj);
			}
			try {
				const value = JSON.parse(result)?.[valueKey];
				if (dataAttr === 'value') {
					resolveData = value;
				} else if (dataAttr === 'length') {
					resolveData = value.length;
				}
			} catch (err) {
				console.log(err);
			}
		} else if (type === 'inner') {
			// 组件内部取值
			resolveData = rowData?.[dataField]
		}
		// 判断关系
		let booleanItem = false;
		if (logicRule === '===') {
			if (valueType === 'string') {
				booleanItem = resolveData === value;
			} else if (valueType === 'number') {
				booleanItem = +resolveData === +value;
			} else if (valueType === 'date') {
				booleanItem = (new Date(resolveData)).getTime() === +value;
			}
		} else if (logicRule === '!=') {
			if (valueType === 'string') {
				booleanItem = resolveData !== value;
			} else if (valueType === 'number') {
				booleanItem = +resolveData !== +value;
			} else if (valueType === 'date') {
				booleanItem = (new Date(resolveData)).getTime() !== +value;
			}
		} else if (logicRule === '>') {
			if (valueType === 'number') {
				booleanItem = +resolveData > +value;
			} else if (valueType === 'date') {
				booleanItem = (new Date(resolveData)).getTime() > +value;
			}
		} else if (logicRule === '<') {
			if (valueType === 'number') {
				booleanItem = +resolveData < +value;
			} else if (valueType === 'date') {
				booleanItem = (new Date(resolveData)).getTime() < +value;
			}
		} else if (logicRule === '<=') {
			if (valueType === 'number') {
				booleanItem = +resolveData <= +value;
			} else if (valueType === 'date') {
				booleanItem = (new Date(resolveData)).getTime() <= +value;
			}
		} else if (logicRule === '>=') {
			if (valueType === 'number') {
				booleanItem = +resolveData >= +value;
			} else if (valueType === 'date') {
				booleanItem = (new Date(resolveData)).getTime() >= +value;
			}
		}
		if (i === 0) {
			logicBoolean = booleanItem;
		} else {
			if (itemLogic === 'and') {
				logicBoolean = logicBoolean && booleanItem;
			} else if (itemLogic === 'or') {
				logicBoolean = logicBoolean || booleanItem;
			}
		}
	}
	return logicBoolean;
}

/**
* @desc: 获取状态取值
* @param {Object} config 状态配置
* @param {Array} componentData 组件数据
*/
export const getStatusVal = (config, componentData = []) => {
	if (!config) return {};
	const {
		type = '',
		fixedValue = '',
		componentId = '',
		componentValueOrigin,
		resolveKey = ''
	} = config;
	if (type === 'fixed') {
		return {
			type,
			value: fixedValue
		}
	}
	// 组件来源‘
	const component = getComponentById(componentData, componentId);
	if (!component) return {};
	if (componentValueOrigin === 'propValue') {
		return {
			type,
			value: component?.propValue
		}
	} else if (componentValueOrigin === 'resolveData') {
		const resolveData = component?.resolveData;
		return {
			type,
			value: resolveData?.[resolveKey]
		}
	}
	return {};
}

/**
 * @desc: 根据
 * @param {Object} config 下载配置
 */
export const downloadFile = (config) => {
	const { url } = config;
	if (!url) return '';
	const aDom = document.createElement('a');
	aDom.href = url;
	const arr = url.toString().split('/') || [];
	aDom.download = arr[arr.length ? arr.length - 1 : 0];
	aDom.style.display = 'none';
	aDom.click();
}


/**
 * @desc: 后端事件附加操作
 * @param {Array} configList 配置列表
 */
export const doEEActionHandle = (configList) => {
	if (!configList || !Array.isArray(configList) || !configList.length) return;
	configList.forEach(ele => {
		const { action = '', payload = {} } = ele;
		if (action === 'downloadFile') {
			// 下载文件
			downloadFile(payload);
		} else if (action === 'exportSheetData') {
			// 导出数据
			eventBus.$emit('EDITOR_EEExport', payload)
		}
	});
}


/**
 * @desc: 数组去重
 * @param {Array} arr 待去重数组
 * @return {Array}
 */
export const unique = (arr) => {
	if (!arr || !Array.isArray(arr)) return [];
	let res = new Set();
	return arr.filter((item) => {
		let id = JSON.stringify(item);
		if (res.has(id)) {
			return false;
		} else {
			res.add(id);
			return true;
		}
	})
}

//传入url路径以及文件名即可
export const downloadFileBlob = async (url, fileName) => {
	const res = await getBolb(url)
	saveAs(res, fileName)
}

function getBolb(url) {
	return new Promise(resolve => {
		const xhr = new XMLHttpRequest()
		xhr.open('GET', url, true)
		xhr.responseType = 'blob'
		xhr.onload = () => {
			if (xhr.status === 200) {
				resolve(xhr.response)
			}
		}
		xhr.send()
	})
}

function saveAs(blob, filename) {
	if (window.navigator.msSaveOrOpenBlob) {
		navigator.msSaveBlob(blob, filename)
	} else {
		const link = document.createElement('a')
		const body = document.querySelector('body')

		link.href = window.URL.createObjectURL(blob)
		link.download = filename

		// fix Firefox
		link.style.display = 'none'
		body.appendChild(link)

		link.click()
		body.removeChild(link)

		window.URL.revokeObjectURL(link.href)
	}
}

/**
 * @desc: 补零
 * @param {Number/Number} val 时间补零
 * @return {String/Number}
 */
export const addZero = (val) => {
	if (isNaN(+val)) return val;
	if (val >= 0 && val < 10) {
		return `0${+val}`;
	}
	return val;
}

/**
 * @desc: 格式化时间
 * @param {Number} time 时间戳
 * @param {String} type 类型
 */
export const formatTime = (time, type = 'YMD') => {
	let result = '';
	try {
		const obj = new Date(time);
		const year = obj.getFullYear();
		const month = addZero(obj.getMonth() + 1);
		const date = addZero(obj.getDate());
		switch (type) {
			case 'YMD':
				result = `${year}-${month}-${date}`;
				break;
			default:
				break;
		}
	} catch (err) {
		console.log(err, '--formatTime--err')
	}
	return result;
}

/**
 * @desc: 获取company_id
 */
export const getCompanyId = () => {
	const CompanyId = localStorage.getItem('company_id');
	if (CompanyId != undefined && CompanyId != '') {
		return CompanyId;
	}
	return '';
};

/**
 * @desc: 得到一个唯一的id 用于重绘组件
 */
export const uniqid = () => {
	var d = new Date().getTime();
	if (window.performance && typeof window.performance.now === "function") {
		d += performance.now(); //use high-precision timer if available
	}
	var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		var r = (d + Math.random() * 16) % 16 | 0;    // d是随机种子
		d = Math.floor(d / 16);
		return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
	});
	return uuid;
}
/**
 * @desc: 根据id查找找id全称
 */
export function findModelComponentId(componentList = [], field) {
	for (let i = 0, len = componentList.length; i < len; i++) {
		const item = componentList[i];
		if (item.id.includes(field)) {
			return item.id;
		}
		if (item.children && Array.isArray(item.children) && item.children.length) {
			const result = findModelComponentId(item.children, field) || null;
			if (result) {
				return result;
			}
		}
	}
	return null;
}

/**
 * @description: 移除事件总线（排除重复移除）
 * @param {String/Array} eventKey 事件总线名称
 * @param {String} funcName 当前组件方法名
 */
export function removeEventBus(eventKeys, funcName) {
	if (Array.isArray(eventKeys)) {
		eventKeys.forEach(ele => {
			const eventList = eventBus?._events?.[ele] || [];
			for (let i = 0; i < eventList.length; i++) {
				if (eventList[i].name === funcName) {
					eventBus._events.databaseTrigger.splice(i, 1);
					break;
				}
			}
		})
	} else {
		const eventList = eventBus?._events?.[eventKeys] || [];
		for (let i = 0; i < eventList.length; i++) {
			if (eventList[i].name === funcName) {
				eventBus._events.databaseTrigger.splice(i, 1);
				break;
			}
		}
	}
}