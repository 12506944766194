<template>
  <div class="punch-in-statistics-com">
		<div :id="key" class="punch-in-statistics-gis">
    </div>
    <div class="item" v-for="item in dataList" @mousewheel="(e) => { handleMousewhell(e, item) }" @click.stop="handleItemClick(item)" :key="item.id" :style="{ top: item.top, left: item.left}">
      <img src="../../assets/images/dataview/label_bg@2x.png" />
      <p class="name">{{ item.name }}</p>
      <p class="row-2">
        <span class="label">{{ label }}</span>
        <span class="num">{{ item.num }}</span>
      </p>
    </div>
  </div>
</template>
<script>
import Minin from './mixin';
import { dataInterface } from '@/apis/data/index';
import eventBus from '@/plugins/eventBus';
import { getComponentById, initParams, uniqid } from '@/utils/tools';
import Viewer from '@/libs/viewer2DGIS.min.js';
export default {
  name: 'PunchinStatistics',
  mixins: [Minin],
  data() {
    return {
      data: [],
      dataList: [],
      map2d: null,
      key: uniqid(),
      mapZoom: 4
    }
  },
  computed: {
    // 数据仓库配置
		database() {
			return this.element && this.element.database;
		},
    label() {
      return this.element?.statusConfig?.label || '在场监理';
    },
    isDark() {
      const V = this.element?.statusConfig?.isDark;
      return V;
    }
  },
  watch: {
    data: {
      handler() {
        this.setDataList();
      },
      immediate: true
    },
    mapZoom: {
      handler() {
        this.setDataList();
      },
      immediate: true
    }
  },
  created() {
		if (this.database && !this.hasComponentParam()) {
			const { search = [], param = {}, canPost } = initParams(this.element?.database?.paramsConfig || [], this.isGroup, this.componentData, this.groupComponents);
			if (!canPost) return;
			this.getList(this.database, search, param);
		}
		this.metadata = this.element.metadata || [];
	},
  mounted() {
		// 监听请求
		// 配置关联参数的容器才需要监听
    const databaseTrigger = {
      [this.element.id]: (data) => {
        if (data.parentId && data.parentId !== this.element.id) return false;
        // 配置时候触发请求
        if (data.componentId === this.element.id && data.isInit) {
          const { search = [], param = {}, canPost } = initParams(this.element?.database?.paramsConfig || [], this.isGroup, this.componentData, this.groupComponents);
          if (!canPost) return;
          this.getList(this.element.database, search, param);
          return;
        }
        // 点击操作时候不更新数据
        if (data.noUpdate) return;
        const { paramsConfig } = this.element.database;
        if (!paramsConfig || !paramsConfig.length) return;
        // 以下步骤是为了避免有多个来源的search需要进行differ 避免检索结果错误情况
        let { search = [], param = {}, canPost } = initParams(this.element?.database?.paramsConfig || [], this.isGroup, this.componentData, this.groupComponents);
        if (!canPost) return;
        let isTarget = false;
        paramsConfig.forEach((ele) => {
          if (ele.componentId === data.componentId) {
            isTarget = true;
          }
        });
        if (!isTarget && !data.isUpdate) return;
        this.param = param;
        this.getList(this.element.database, search, param);
      }
    }
		eventBus.$on('databaseTrigger', databaseTrigger[this.element.id]);
    this.initMap2d();
	},
  methods: {
    initMap2d() {
      // 如果Viewer2DGIS存在
      if(Viewer.Viewer2DGIS) {
        this.map2d = new Viewer.Viewer2DGIS(this.key, '20191204141039-1089838992-5de74ddfe3a2c.gislayer2d');
        this.map2d.on('viewerPreLoad', () => {//加载完成
          // 使用地图
          this.useMap2d();
        })
      }
    },
    useMap2d() {
      // 初始化GIS模块
      this.map2d.initionMap({ isFence: false, isSearch: false, isMeasure: false, isLayer: false });
      // 设置深色模式
      if(this.isDark) {
        this.map2d.setImagery('streets_geoq_midnight');
      }
      this.map2d.on('move', () => {
        this.listenerHandle();
      })
      this.setDataList();
      this.map2d.emitMove();
      // 更新标记
    },
    async listenerHandle() {
      const map2D = this.map2d;
      if(map2D) {
        this.mapZoom = await map2D.getZoom();
        this.setDataList();
      }
    },
    async setDataList() {
      const sourceData = this.data;
      const mapZoom = this.mapZoom;
      const list = [];
      for (let i = 0; i < sourceData.length; i++) {
        const el = sourceData[i];
        if(mapZoom > 1 && this.map2d) {
          const point = await this.map2d.latLngToContainerPoint({ lat: el.latitude, lng: el.longitude });
          if(point) {
            list.push({
              id: el.id,
              latitude: el.latitude,
              longitude: el.longitude,
              left: point.x + 'px',
              top: point.y + 'px',
              num: el.num,
              name: el.name
            });
          }
        }
      }
      this.dataList = list;
    },
    handleItemClick(item) {
      if(this.map2d && item.latitude && item.longitude) {
        this.map2d.setView([item.latitude, item.longitude], 15);
      }
    },
    handleMousewhell(e, item) {
      if(this.timer) { return } else {
        this.timer = true;
        if(e.deltaY > 0) {
          if(this.mapZoom > 4) {
            this.map2d.setView([item.latitude, item.longitude], this.mapZoom - 1);
          }
        } else {
          if(this.mapZoom < 18) {
            this.map2d.setView([item.latitude, item.longitude], this.mapZoom + 1);
          }
        }
        setTimeout(() => {
          this.timer = null;
        }, 100)
      }
    },
    /**
		 * @desc: 获取渲染列表
		 * @param {Object} database 数据配置对象
		 * @param {Array} search 搜索
		 */
		getList(database, search = [], params = {}) {
			this.search = Array.isArray(search) ? search : [];
			this.sourceData = {};
			if (!this.validDatabase(database)) return;
			this.loading = true;
			const paramsObj = {
				...params
			};
			// console.log('获取渲染列表______表格容器', params, paramsObj);
			// 配置
			let __method_name__ = 'dataList';
			const mapping = database.mapping;
			let configObj = null;
			if (mapping === 'object') {
				configObj = {
					__method_name__,
					object_uuid: database.objectData.uuid,
					view_uuid: database.viewData.uuid,
					transcode: 0,
					...paramsObj,
					search
				};
			} else if (mapping === 'relation') {
				__method_name__ = 'relationList';
				configObj = {
					__method_name__: 'relationList',
					object_uuid: database.objectData.uuid,
					relationship_uuid: database.relationData.uuid,
					...paramsObj,
					search
				};
			}
			// 获取表格数据
			dataInterface(configObj)
				.then((res) => {
					if (res && res.status === 200) {
            // 验证数据格式
						if (res.data && Array.isArray(res.data.data) && res.data.data.length) {
              const data = res.data.data;
              const hash = {};
              data.forEach(el => {
                const org_data = Array.isArray(el.org_data) && el.org_data.length ? el.org_data[0] : {};
                if(!el.archi_type) return
                if(hash[el.archi_type]) {
                  hash[el.archi_type].num += 1;
                  if(!hash[el.archi_type].latitude || !hash[el.archi_type].latitude) {
                    hash[el.archi_type].latitude = org_data?.lnglat?.latitude || '';
                    hash[el.archi_type].latitude = org_data?.lnglat?.longitude || '';
                  }
                } else {
                  hash[el.archi_type] = {
                    id: el.archi_type,
                    num: 1,
                    latitude: org_data?.lnglat?.latitude || '',
                    longitude: org_data?.lnglat?.longitude || '',
                    name: org_data?.project_name || '',
                  };
                }
              })
              const list = [];
              for (const key in hash) {
                if (Object.hasOwnProperty.call(hash, key)) {
                  const element = hash[key];
                  list.push(element);
                }
              }
              this.data = list;
						} else {
              this.data = [];
            }
          }
				})
				.catch((err) => {
					console.log(err);
				});
		},
    /**
		 * @desc: 验证
		 * @param {Object} database 数据仓库的绑定
		 */
		validDatabase(database) {
			if (!database || typeof database !== 'object') return false;
			if (!database.objectData) return false;
			if (!database.viewData && !database.relationData) return false;
			return true;
		},
    /**
		 * @desc: 判断是否存在依赖其他组件的取值
		 */
		hasComponentParam() {
			if (!this.database.paramsConfig || !this.database.paramsConfig.length) {
				return false;
			}
			for (let i = 0; i < this.database.paramsConfig.length; i++) {
				const { componentId = '', key = '', sourceType = '' } = this.database.paramsConfig[i];
				if ((key !== 'search' || !componentId.includes('CommonForm')) && sourceType !== 'url') {
					// componentId.includes('CommonTableContainer')兼容跨页请求的动态判定
					// 补充不同架构问题
					if (!componentId.includes('CommonTableContainer') && this.paramsSameArchi(componentId)) {
						return true;
					}
				}
			}
			return false;
		},
    /**
		 * @desc: 判断依赖的参数是否在当前架构下启用(@凌志华树形图架构限制)
		 * @param {String} componentId
		 * @return {Boolean}
		 */
		paramsSameArchi(componentId) {
			const comp = getComponentById(this.componentData, componentId);
			if (!comp) return false;
			const targetArchi = this.$GetTargetArchi('archiType');
			if (comp && comp.archiLimit && comp.archiLimit.length && comp.archiLimit.includes(targetArchi)) {
				return true;
			}
			return false;
		},
  }
}
</script>
<style lang="less" scoped>
  .punch-in-statistics-com{
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    .punch-in-statistics-gis{
      width: 100%;
      height: 100%;
    }
    .item{
      position: absolute;
      transform-origin: 0% 100%;
      transform: translateY(-100%) scale(0.5);
      box-sizing: border-box;
      width: 338px;
      height: 456px;
      img{
        top: 0;
        left: 0;
        position: absolute;
      }
      .name{
        position: absolute;
        top: 59px;
        left: 83px;
        width: 190px;
        height: 34px;
        line-height: 34px;
        color: #000;
        font-size: 24px;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .row-2{
        position: absolute;
        top: 120px;
        left: 0px;
        width: 100%;
        font-size: 26px;
        text-align: center;
        .label{
          color: #ccc;
        }
        .num{
          margin-left: 20px;
          font-weight: 600;
        }
      }
    }
  }
</style>