/* eslint-disable */

export const baseUrl = () => {
	return 'https://saas-api.bimcc.net/';
};

export const getToken = () => {
	let mockUser = localStorage.getItem('mockUserInfo')
	if (mockUser && JSON.parse(mockUser)) {
		mockUser = JSON.parse(mockUser)
		if (mockUser.token) {
			return mockUser.token
		}
	}
	return '';
};

/**
 * @desc: 解析时间
 * @param {*}
 * @return {*}
 */
export const parseTime = (dateObj) => {
	return dateObj && dateObj instanceof Date ? `${dateObj.getFullYear()}-${dateObj.getMonth() + 1}-${dateObj.getDate()}` : '';
}


export const formSrc = () => {
	return '/localForm'
	return process.env.NODE_ENV === 'development' ? '/localForm' : '/pc/designForm'
};

export const uniqid = () => {
	var d = new Date().getTime();
	if (window.performance && typeof window.performance.now === "function") {
		d += performance.now();
	}
	var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		var r = (d + Math.random() * 16) % 16 | 0;    // d是随机种子
		d = Math.floor(d / 16);
		return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
	});
	return uuid;
};


export function reduceData(goalData, resultData = []) {
	for (let item of goalData){
		if(
				['el-switch','el-select','el-radio-group'].includes(item.__config__.tag) &&
				item.__config__.interact.effect !== ''
		){
			let itemData = {
				formId: item.__config__.formId,
				defaultVal: item.__config__.defaultValue,
				interact: item.__config__.interact,
			}
			resultData.push(itemData)
		}
		if(item.__config__.children && item.__config__.children.length>0){
			reduceData(item.__config__.children,resultData)
		}
	}
	return resultData
}


/**
 * 获取当前日期时间
 * @param type 1:yyyy-MM-dd 2:yyyy-MM-dd HH:ii:ss 3:HH:ii:ss
 * @return {string}
 */
export const getNowTime= (type = 1) => {
	let yy = new Date().getFullYear();
	let mm = new Date().getMonth() + 1;
	let dd = new Date().getDate();
	let hh = new Date().getHours();
	let ii = new Date().getMinutes() < 10
		? '0' + new Date().getMinutes()
		: new Date().getMinutes();
	let ss = new Date().getSeconds() < 10
		? '0' + new Date().getSeconds()
		: new Date().getSeconds();

	if (type === 1){
		return yy + '-' + mm + '-' + dd;
	} else if(type === 2){
		return yy + '-' + mm + '-' + dd + ' ' + hh + ':' + ii + ':' + ss;
	} else {
		return hh + ':' + ii + ':' + ss;
	}
}
