<!--
 * @Author: lisushuang
 * @Date: 2021-11-17 11:33:59
 * @LastEditors: luocheng
 * @LastEditTime: 2022-04-13 19:54:12
 * @Description: 表单独立存在功能的抽象组件
 * @FilePath: /dataview-viewer-test/src/custom-component/form/FormOnlyOne.vue
-->

<template>
  <div class="page scroller" v-loading="loading" style="min-height: 200px">
    <div v-if="!showError">
      <el-button
      type="primary"
      @click="btnClick"
      style="float: right; margin-right: 20px; width: 80px"
      >{{ formDisabled ? "编辑" : "提交" }}</el-button
    >
    <PcParser
      :displayData="showData"
      :form-data="formDesignData"
      ref="dialogForm"
      v-if="info_id && showData"
      @submit="onFormSubmit"
      :disabled="formDisabled"
      style="margin-top: 10px; padding-right: 20px"
    />
    </div>

    <div v-else>
      错误的条件配置，请检查！
    </div>

  </div>
</template>

<script>
import { dataInterface, transFieldsUUid } from "@/apis/data/index";
import PcParser from '@/custom-component/form/newParser/PcParser';
import valueMixin from "./formParser/scripts/formValueFunctions"

export default {
  // 公司概况页面
  name: "FormOnlyOne",
  components: { PcParser },
  mixins:[valueMixin],

  props: {
    // object uuid
    objectUuid: {
      type: String,
      default: "",
    },
    // view uuid
    viewUuid: {
      type: String,
      default: "",
    },

    // form uuid
    formUuid: {
      type: String,
      default: "",
    },

    // allow auto Create
    AutoCreate: {
      type: Boolean,
      default: true,
    },

    // 查找数据时的额外条件，会自动当成字典在新增时写入
    moreCondition: {
      type: Array,
      default() {
        return []
      },
    },

    // 自动创建时的附加字典
    moreCreateDicts: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      info_id: 0, // 查到的数据id
      showData: null, // 表单展示的数据
      isEdit: true, // 是否在编辑中
      formDesignData: {}, // 表单自定义数据
      loading: true, // loading 状态
      formDisabled: true, // 表单是否禁止填报
      showError:false
    };
  },
  methods: {
    /**
     * @description: 获取当前所属数据id
     * @param {*}
     * @return {*}
     */
    getNowCompanyId() {
      let id = this.$GetTargetArchi();
      if (id.archiType == "project") {
        id = id.company_id;
      } else {
        id = id.id;
      }
      return id;
    },

    /**
     * @description: 根据条件查找数据
     * @param {*} company_id
     * @return {*}
     */
    findInfo(conditions = "") {
      if(this.showError){
        this.showErrorMsg("错误的配置");
        return
      }
      if (!conditions) {
        this.showErrorMsg("无效的数据，请稍后再试");
        return;
      }
      dataInterface({
        __method_name__: "dataList",
        object_uuid: this.objectUuid,
        view_uuid: this.viewUuid,
        transcode: 0,
        search: conditions,
      })
        .then((res) => {
          if (res.data.code == 200) {
            // 有数据
            if (
              res.data.data.data.length > 0 &&
              res.data.data.data[0] &&
              res.data.data.data[0].id
            ) {
              this.info_id = res.data.data.data[0].id;
              this.initFormDesign();
            } else {
              // 没数据就要新增
              this.createNewInfo();
            }
          }
        })
        .catch(() => {
          this.showErrorMsg();
        });
    },

    /**
     * @description: 显示错误消息
     * @param {*} msg 显示的消息
     * @return {*}
     */
    showErrorMsg(msg = "请求数据失败，请稍后再试") {
      this.$message.error(msg);
    },

    /**
     * @description: 新增一条数据
     * @param {*} company_id
     * @return {*}
     */
    createNewInfo() {
      let data = {
        __method_name__: "createData",
        object_uuid: this.objectUuid,
        transcode: 0,
      };
      this.moreCondition.forEach((item) => {
        data[item.code] = item.value;
      });
      this.moreCreateDicts.forEach((item) => {
        data[item.code] = item.value;
      });
      dataInterface(data)
        .then((res) => {
          if (res.data.code == 200) {
            this.info_id = res.data.data.id;
            this.initFormDesign();
          } else {
            this.showErrorMsg("新增数据失败");
          }
        })
        .catch(() => {
          this.showErrorMsg();
        });
    },

    /**
     * @description: 接收自定义表单抛出的事件
     * @param {*} formData 自定义表单抛出的已验证的数据
     * @return {*}
     */
    onFormSubmit(formData) {
      this.loading = true;
      let data = {
        __method_name__: "updateData",
        data_id: this.info_id,
        object_uuid: this.objectUuid,
        transcode:0,
        ...formData,
      };
      let conditions = this.getConditions()
      conditions.forEach((item) => {
        data[item.code] = item.value;
      });
      this.moreCreateDicts.forEach((item) => {
        data[item.code] = item.value;
      });
      dataInterface(data).then((res) => {
        this.loading = false;
        this.formDisabled = true;
        if (res.data.code == 200) {
          this.$message.success("保存成功");
        }
      });
    },

    /**
     * @description: 解析条件数据
     * @param {*}
     * @return {*}
     */
    getConditions() {
      if(this.moreCondition.length == 0){
        return [{
            code: "archiID",
            ruleType: "eq",
            value: this._getCompanyIdForced(),
          }]
      }else{
        let conditions = []
        let keys = Object.keys(this.functionValues)
        this.moreCondition.forEach((item) => {
          if(item.code !== undefined && item.value !== undefined){
            conditions.push({
              code:item.code,
              ruleType : item.ruleType ? item.ruleType : 'eq',
              value : keys.indexOf(item.value) > -1 ? this.functionValues[item.value] : item.value
            })
          }
        })
        if(conditions.length){
          this.showError = true
        }else{
          this.showError = false
        }
        return this.moreCondition;
      }
    },

    /**
     * @description: 初始化表单数据
     * @param {*}
     * @return {*}
     */
    initFormDesign() {
      transFieldsUUid(this.formUuid ? this.formUuid : this.objectUuid).then(
        (res) => {
          if (res.data.code == 200) {
            this.formDesignData = res.data.data;
            this.loading = false;
            dataInterface({
              __method_name__: "dataInfo",
              data_id: this.info_id,
              object_uuid: this.objectUuid,
              view_uuid: this.viewUuid,
            }).then((res) => {
              this.showData = res.data.data;
            });
          }
        }
      );
    },

    /**
     * @description: 提交按钮点击，切换disable状态，看情况触发表单提交行为
     * @param {*}
     * @return {*}
     */
    btnClick() {
      if (this.formDisabled) {
        this.formDisabled = false;
      } else {
        this.$refs.dialogForm.handleSubmit();
      }
    },
  },
  mounted() {
    this.findInfo(this.getConditions());
  },
};
</script>

<style lang="less" scoped>
.page {
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  padding: 15px;
}
:deep(.page) {
  .type-label {
    border-left: 3px solid #007fe0 !important;
    padding: 0 10px;
    line-height: 1;
    font-size: 14px;
    margin: 0 0 16px;
    font-weight: 600;
  }
}
</style>
