/*
 * @Description: Bim方法
 * @Author: Shiltin
 * @Date: 2022-06-01 09:59:26
 * @LastEditors: Shiltin 18580045074@163.com
 * @LastEditTime: 2022-08-02 10:19:59
 */
let viewerBim = null;

export default {
  data() {
    return {
      // 是否初始化
      inited: false,
      // 尝试次数
      times: 0,
      //已加载的模型数据
      loadedModelIds: [],
      //初始化状态
      initState: "",
      //默认加载模型
      defaultModel: [],
      //右侧模型树显示数据
      modelTreeData: [],
      lockMouse: false,
      zoom: 1,
      firstLevel: 1,
    };
  },
  methods: {
    /**
     * @desc: 初始化模型
     */
    initModel() {
			console.log(123456)
      if (!this.validConfig()) {
        // 配置错误的情况直接放弃初始化
        this.inited = true;
        return;
      }
      const domId = this.element.id;
      if (
        !domId ||
        !document.querySelector(`#${domId}`) ||
        !window.BIMCC ||
        !window.BIMCC.Viewer
      ) {
        this.times++;
        return;
      }
      // 应用ID "bdafce12a2d9465d89821ec14214a382"
      // 应用签名 "3EYcdAjbnWxP"
      // this.defaultModel = [{model_id:'4d0ed584ef65434786786419005def5d',dbids:[]},{model_id:'d1a2579f34b042738f485aa327be9e8d',dbids:[]}]
      const defaultModel = this.modelConfig.modelId.value; //模型
      if (
        Object.prototype.toString.call(defaultModel) === "[object String]" &&
        defaultModel.includes("model_id")
      ) {
        this.defaultModel = this.strToJson(defaultModel);
      }
      viewerBim = new window.BIMCC.Viewer(domId, {});
      viewerBim.clearUI();
      let param = {
        appId: this.modelConfig.appId,
        appSecret: this.modelConfig.appSecret,
        modelList: this.modelTreeData,
        themeColor: this.modelConfig.toolbarBg
          ? this.modelConfig?.toolbarBg
          : "#FFFFFF",
        activeColor: "#0076ff",
        textColor: this.modelConfig.toolbarCol
          ? this.modelConfig?.toolbarCol
          : "#121619",
        removeBackground: !this.modelConfig.showModelBg ? true : false,
      };
      if (+this.modelConfig.showToolbar === 0) {
        param.UIType = "UI.None";
      }
      if (+this.modelConfig.showToolbar === 1) {
        param.UIType = "UI.Base";
      }
      viewerBim.initModelSelect(this.modelTreeData, param);
      window.viewerBim = viewerBim;
      if (this.defaultModel.length) {
        this.addModels(this.defaultModel);
      }
      //监听模型加载
      viewerBim.on("Viewer.loadedModel", async () => {
				console.log(viewerBim,'viewerBim')
        let data = await viewerBim.getModelIdList();
        this.loadedModelIds = data;
        this.isLoadModel = false;
        this.$emit("changeModelIds", data);
        //背景色
				if (this.modelConfig?.modelBg && this.modelConfig.showModelBg) {
          let str = this.modelConfig.modelBg.includes("rgba")
            ? this.modelConfig.modelBg.replace("rgba(", "")
            : "";
          let bgCol = str.replace(")", "").split(",");
          viewerBim.setBackgroundColor(bgCol[0], bgCol[1], bgCol[2]);
        }
				console.log(this.modelConfig,'this.modelConfig')
      });
      //监听模型卸载
      viewerBim.on("Viewer.unloadModel", async () => {
        let data = await viewerBim.getModelIdList();
        this.$emit("changeModelIds", data);
      });
      //选中构件构件
      viewerBim.on("Viewer.onSelect", async (selections) => {
        this.$emit("changeCompanents", selections);
      });
      // 鼠标摁下
      viewerBim.on("Viewer.MouseDown", async () => {
        this.lockMouse = true;
      });
      // 鼠标松开
      viewerBim.on("Viewer.MouseUp", async () => {
        this.lockMouse = false;
      });
      //监听相机旋转
      viewerBim.on("Viewer.cameraChange", (res) => {
        //zoom值计算
        let tier = res.position;
        let dis = tier.x * tier.x + tier.y * tier.y + tier.z * tier.z;
        if (this.firstLevel == 1 && dis) {
          this.firstLevel = dis;
        } else {
          let a = this.firstLevel / dis;
          if (a < 1) {
            this.zoom = a.toFixed(1);
          } else {
            this.zoom = 1;
          }
        }
        for (let i in this.aiotSignData) {
          let item = this.aiotSignData[i].data;
          if (item.length) {
            item.map(async (val) => {
              val.screenPointInfo = await viewerBim.worldToScreen(
                val.worldPointInfo
              );
            });
          }
        }
      });
      this.inited = true;
    },
    /**
     * @desc: 手动加载模型
     * param: arr参数均为[{model_id:xxx,dbids:[]}] 格式
     */
    addModels(arr) {
      if (arr && arr.length != 0) {
        if (this.loadedModelIds.length == 0) {
          let bol = false;
          arr.forEach((item) => {
            if (!bol) {
              // 加载第一个模型
              bol = true;
              viewerBim.on("viewerLoaded", async () => {
                if (item.dbids && item.dbids.length != 0) {
                  viewerBim.fitToView([item]);
                  viewerBim.select([item]);
                }
              });
              this.initFirstModel(item.model_id, arr.length == 0);
            } else {
              let timer = setInterval(() => {
                if (this.initState === "done") {
                  clearInterval(timer);
                  viewerBim.on("Viewer.loadedModel", () => {
                    if (item.dbids && item.dbids.length != 0) {
                      viewerBim.fitToView([item]);
                      viewerBim.select([item]);
                    }
                  });
                  this.isLoadModel = true;
                  viewerBim.loadModel(item.model_id);
                }
              }, 200);
            }
          });
        } else {
          arr.forEach((item) => {
            viewerBim.on("Viewer.loadedModel", () => {
              if (item.dbids && item.dbids.length != 0) {
                viewerBim.fitToView([item]);
                viewerBim.select([item]);
              }
            });
            this.isLoadModel = true;
            viewerBim.loadModel(item.model_id);
          });
        }
      }
    },
    //初始化加载第一个模型
    initFirstModel(id, bol) {
			console.log(this.modelConfig,5555)
      this.isLoadModel = true;
      if (viewerBim) {
        viewerBim.clearUI();
        let param = {
          appId: this.modelConfig.appId,
          appSecret: this.modelConfig.appSecret,
					themeColor: this.modelConfig.toolbarBg?this.modelConfig?.toolbarBg:'#FFFFFF',
					activeColor: '#0076ff',
					textColor: this.modelConfig.toolbarCol?this.modelConfig?.toolbarCol:'#121619',
          removeBackground: !this.modelConfig.showModelBg ? true : false,
        };
        if (+this.modelConfig.showToolbar === 0) {
          param.UIType = "UI.None";
        }
        if (+this.modelConfig.showToolbar === 1) {
          param.UIType = "UI.Base";
        }
        viewerBim.init(id, param);
        //加载模型监听
        viewerBim.on("viewerLoaded", async () => {
          //模型背景色
          if (this.modelConfig?.modelBg && this.modelConfig.showModelBg) {
            let str = this.modelConfig.modelBg.includes("rgba")
              ? this.modelConfig.modelBg.replace("rgba(", "")
              : "";
            let bgCol = str.replace(")", "").split(",");
            viewerBim.setBackgroundColor(bgCol[0], bgCol[1], bgCol[2]);
          }
          this.initState = "done";
          this.isFirstLoad = false;
          if (bol) {
            this.isLoadModel = false;
          }
          let data = await viewerBim.getModelIdList();
          this.loadedModelIds = data;
          this.$emit("changeModelIds", data);
        });
      }
    },
    //获取构件属性
    async getProperties(dbid, modelId) {
      if (viewerBim != null) {
        let data = await viewerBim.getProperties(dbid, modelId);
        data.model_id = modelId;
        return data;
      }
    },
    //卸载模型 id：model_id
    minusModel(id) {
      if (viewerBim != null) {
        viewerBim.unloadModel(id);
      }
    },
    //添加模型 id：model_id
    addModel(id) {
      if (viewerBim != null) {
        viewerBim.loadModel(id);
      }
    },
    /**
     * @desc: 选中构件
     * param: data参数均为[{model_id:xxx,dbids:[]}] 格式
     */
    selectModel(data) {
      if (viewerBim != null) {
        viewerBim.select(data);
      }
    },
    //缩放到构件位置
    fitToModel(data) {
      if (viewerBim != null) {
        viewerBim.fitToView(data);
      }
    },
    //隔离方法
    isolate(data) {
      if (viewerBim != null) {
        viewerBim.isolate(data);
      }
    },
    //隔离全部
    hideAll() {
      if (this.loadedModelIds.length !== 0) {
        this.loadedModelIds.forEach((item) => {
          viewerBim.setOptions("ghosting", true);
          viewerBim.hideModel(item);
        });
      }
    },
    //显示全部
    showAll() {
      if (this.loadedModelIds.length !== 0) {
        this.loadedModelIds.forEach((item) => {
          viewerBim.setOptions("ghosting", false);
          viewerBim.showAll(item);
          viewerBim.clearColorAll(item);
        });
      }
    },
    //获取视点列表
    async getViewList() {
      if (viewerBim != null) {
        let data = await viewerBim.getViewStateList();
        return data;
      }
    },
    //添加视点
    async addView() {
      if (viewerBim != null) {
        const res = await viewerBim.addViewState();
        if (res) {
          this.$emit("setViewData", res);
        }
      }
    },
    //更新视点
    async updateView(data) {
      if (viewerBim != null) {
        await viewerBim.updateViewState({
          id: data.id,
          name: data.name,
        });
        return true;
      }
    },
    //视点回显
    setViewState(data) {
      if (viewerBim != null) {
        viewerBim.viewViewState(data);
      }
    },
    //设置主题色
    setTheme(data) {
      if (viewerBim != null) {
        viewerBim.setTheme(data);
      }
    },
    //构件着色
    setCol(data, col) {
      if (viewerBim != null) {
        data.forEach((val) => {
          viewerBim.setColor(val.dbids, col, val.model_id);
        });
      }
    },
    //进度模拟生长效果相关的方法
    // obj参数：{opacity:0.5,color:#999999,model:模型id,speed:100}
    async createShadowMesh(dbids, obj) {
      if (viewerBim != null) {
        let growId = await viewerBim.createShadowMesh(dbids, obj);
        return growId;
      }
    },
    // 播放生长进度
    playShadowMeshGrow(id) {
      if (viewerBim != null) {
        viewerBim.playShadowMeshGrow(id);
      }
    },
    // 暂停生长进度
    stopShadowMeshGrow(id) {
      if (viewerBim != null) {
        viewerBim.stopShadowMeshGrow(id);
      }
    },
    //隐藏结束生长进度
    displayShadowMesh(id, bol) {
      if (viewerBim != null) {
        viewerBim.displayShadowMesh(id, bol);
      }
    },
  },
};
