<template>
  <div class="currency-component-container" :style="style">
    {{ timeString }}
  </div>
</template>

<script>
import Minin from './mixin'
export default {
  name: 'TimeCom',
  mixins: [Minin],
  computed: {
    style() {
      return {
        fontSize: this.fontSize,
        textAlign: this.textAlign || '',
        lineHeight: this.lineHeight || '',
        fontWeight: this.bold,
        fontStyle: this.italic,
        // fontFamily: this.fontFamily,
      }
    }
  },
  data() {
    return {
      date: new Date(),
      timeString: '',
      timer: null
    }
  },
  created() {
    this.timer = setInterval(() => {
      this.date = new Date()
      this.updateTimeString()
    }, 1000)
  },
  beforeDestroy() {
    // 销毁前清空计时器
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
  methods: {
    // 更新时间
    updateTimeString() {
      let h = this.date.getHours()
      let m = this.date.getMinutes()
      let s = this.date.getSeconds()
      h = this.fillZero(h)
      m = this.fillZero(m)
      s = this.fillZero(s)
      this.timeString = `${h}:${m}:${s}`
    },
    // 补零
    fillZero(val) {
      if (!isNaN(val) && val < 10 && val >= 0) {
        return `0${val}`
      }
      return val
    },
  }
}
</script>

<style lang="less" scoped>
  .currency-component-container{
    width: 100%;
    height: 100%;
  }
</style>