/*
 * @Author: 朱廷果 1028509503@qq.com
 * @Date: 2022-09-20 09:56:40
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2022-12-05 11:49:06
 * @FilePath: \dataview-next\src\api\saasManage.js
 * @Description: 后台管理系统页面的手写接口
 */
import { getCompanyId } from "@/utils/tools";
import request from '@/apis/http/saasRequest.js';
const baseUrl = 'api/saas/admin/'
const baseUrl1 = 'api/saas/'
/**
 * @return  各种下拉选择框的数据
 */
// 用户管理组织架构数据
export const getDeptSelect = () => {
  return request({
    url: baseUrl + 'dept/select',
    method: 'post',
  })
}
// 用户管理角色数据
export const getRoleSelect = () => {
  return request({
    url: baseUrl + 'role/select',
    method: 'post',

  })
}
// 用户管理岗位数据
export const getPostSelect = () => {
  return request({
    url: baseUrl + 'post/select',
    method: 'post',
  })
}
// 菜单管理部门数据
export const getMenuSelect = (data) => {
  return request({
    url: baseUrl + 'menu/select',
    method: 'post',
    data: {
      is_button:data
    }
  })
}
/**
 *
 * @param {*}
 * @returns 模板详情的增删改查
 */
 export const getTemplate = (data) => {
  return request({
    url: baseUrl + 'template',
    method: 'get',
    params: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

export const addTemplate = (data) => {
  return request({
    url: baseUrl + 'template',
    method: 'post',
    data: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

export const editTemplate = (data, id) => {
  return request({
    url: baseUrl + 'template/' + id,
    method: 'put',
    data: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

export const detTemplate = (data) => {
  return request({
    url: baseUrl + 'template/batchDelete',
    method: 'post',
    data: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

/**
 *
 * @param {*}
 * @returns 字典管理的增删改查
 */
 export const getDict = (data) => {
  return request({
    url: baseUrl + 'dict',
    method: 'get',
    params: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

export const addDict = (data) => {
  return request({
    url: baseUrl + 'dict',
    method: 'post',
    data: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

export const editDict = (data, id) => {
  return request({
    url: baseUrl + 'dict/' + id,
    method: 'put',
    data: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

export const detDict = (data) => {
  return request({
    url: baseUrl + 'dict/batchDelete' ,
    method: 'post',
    data: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

/**
 *
 * @param {*}
 * @returns  参数管理的增删改查
 */
export const getConfig = (data) => {
  return request({
    url: baseUrl + 'config',
    method: 'get',
    params: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

export const addConfig = (data) => {
  return request({
    url: baseUrl + 'config',
    method: 'post',
    data: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

export const editConfig = (data, id) => {
  return request({
    url: baseUrl + 'config/' + id,
    method: 'put',
    data: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

export const detConfig = (data) => {
  return request({
    url: baseUrl + 'config/batchDelete' ,
    method: 'post',
    data: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

// 用户端的数据
export const adminSetting = (data) => {
  return request({
    url: baseUrl + 'config/adminSetting',
    method: 'post',
    data: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

export const configSelect = (data) => {
  return request({
    url: baseUrl + 'config/select',
    method: 'post',
    data: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

export const importSetting = (data) => {
  return request({
    url: baseUrl + 'config/importSetting',
    method: 'post',
    data: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

/**
 *
 * @param {*}
 * @returns 企业管理的增删改查
 */
 export const getComp = (data) => {
  return request({
    url: baseUrl + 'comp',
    method: 'get',
    params: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

export const addComp = (data) => {
  return request({
    url: baseUrl + 'comp',
    method: 'post',
    data: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

export const editComp = (data, id) => {
  return request({
    url: baseUrl + 'comp/' + id,
    method: 'put',
    data: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

export const detComp = (data) => {
  return request({
    url: baseUrl + 'comp/batchDelete' ,
    method: 'post',
    data: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

/**
 *
 * @param {*}
 * @returns 用户管理的增删改查
 */
 export const getUser = (data) => {
  return request({
    url: baseUrl + 'user',
    method: 'get',
    params: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

export const addUser = (data) => {
  return request({
    url: baseUrl + 'user',
    method: 'post',
    data: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

export const editUser = (data, id) => {
  return request({
    url: baseUrl + 'user/' + id,
    method: 'put',
    data: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

export const detUser = (data) => {
  return request({
    url: baseUrl + 'user/batchDelete' ,
    method: 'post',
    data: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

/**
 *
 * @param {*}
 * @returns 菜单管理的增删改查
 */
 export const getMenu = (data) => {
  return request({
    url: baseUrl + 'menu',
    method: 'get',
    params: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

export const addMenu = (data) => {
  return request({
    url: baseUrl + 'menu',
    method: 'post',
    data: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

export const editMenu = (data, id) => {
  return request({
    url: baseUrl + 'menu/' + id,
    method: 'put',
    data: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

export const detMenu = (data) => {
  return request({
    url: baseUrl + 'menu/batchDelete' ,
    method: 'post',
    data: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

/**
 *
 * @param {*}
 * @returns 角色管理的增删改查
 */
export const getRole = (data) => {
  return request({
    url: baseUrl + 'role',
    method: 'get',
    params: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

// 添加用户时得到树结构
export const getDepartmentUser = (params) => {
  return request({
    url: baseUrl + 'user/departmentUser',
    method: 'post',
    params
  })
}

// 添加岗位数据
export const getJobList = (params) => {
  return request({
    url: baseUrl + 'post',
    method: 'get',
    params
  })
}

export const addRole = (data) => {
  return request({
    url: baseUrl + 'role',
    method: 'post',
    data: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

export const addRoleOperation = (data) =>{
  return request({
    url: baseUrl + 'roleOperation',
    method: 'post',
    data: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

export const editRole = (data, id) => {
  return request({
    url: baseUrl + 'role/' + id,
    method: 'put',
    data: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

export const detRole = (data) => {
  return request({
    url: baseUrl + 'role/batchDelete' ,
    method: 'post',
    data: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

export const detRoleUser = (id) => {
  return request({
    url: baseUrl + 'roleUserDelete/' + id ,
    method: 'delete',
  })
}
export const detMenus = (id) => {
  return request({
    url: baseUrl + 'menu/' + id ,
    method: 'delete',
  })
}
/**
 *
 * @param {*}
 * @returns 通知公告的增删改查
 */
export const getNotice = (data) => {
  return request({
    url: baseUrl + 'notice',
    method: 'get',
    params: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

export const addNotice = (data) => {
  return request({
    url: baseUrl + 'notice',
    method: 'post',
    data: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

export const editNotice = (data, id) => {
  return request({
    url: baseUrl + 'notice/' + id,
    method: 'put',
    data: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

export const detNotice = (data) => {
  return request({
    url: baseUrl + 'notice/batchDelete' ,
    method: 'post',
    data: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

/**
 * @param {*}
 * @returns 操作日志的查
 */
 export const getOperateLog= (data) => {
  return request({
    url: baseUrl + 'operateLog',
    method: 'get',
    params: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

/**
 * @param {*}
 * @returns 操作日志的查
 */
export const getLoginLog= (data) => {
  console.log(data)
  console.log(getCompanyId())
  return request({
    url: baseUrl + 'loginLog',
    method: 'get',
    params: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

/**
 * @param {*}
 * @returns 操作日志的查
 */
 export const getProbation = (data) => {
  return request({
    url: baseUrl + 'probation',
    method: 'get',
    params: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

/**
 * @param {*}
 * @returns 菜单授权的增删改查查
 */
 export const getSysAuth = (data) => {
  return request({
    url: baseUrl + 'menuRoleList',
    method: 'get',
    params: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

export const editMenuOperation = (data) => {
  return request({
    url: baseUrl + 'menuOperation',
    method: 'post',
    data: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

/**
 * @param {*}
 * @returns 租户管理的查
 */
 export const getVersion = (data) => {
  return request({
    url: baseUrl + 'versions',
    method: 'get',
    params: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

/**
 *
 * @param {*}
 * @returns 通知公告的增删改查
 */
 export const getDept = (data) => {
  return request({
    url: baseUrl + 'dept',
    method: 'get',
    params: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

export const addDept = (data) => {
  return request({
    url: baseUrl + 'dept',
    method: 'post',
    data: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

export const editDept = (data, id) => {
  return request({
    url: baseUrl + 'dept/' + id,
    method: 'put',
    data: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

export const detDept = (data) => {
  return request({
    url: baseUrl + 'dept/batchDelete' ,
    method: 'post',
    data: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

/**
 *
 * @param {*}
 * @returns 通知公告的增删改查
 */
 export const getMapList = (data) => {
  return request({
    url: baseUrl + 'projectMapList',
    method: 'get',
    data: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

export const editMapList = (data, id) => {
  return request({
    url: baseUrl + 'projectMapUpdate/' + id,
    method: 'put',
    data: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

/**
 *
 * @param {*}
 * @returns 岗位管理的增删改查
 */
export const getPost = (data) => {
  return request({
    url: baseUrl + 'post',
    method: 'get',
    params: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

export const addPost = (data) => {
  return request({
    url: baseUrl + 'post',
    method: 'post',
    data: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

export const editPost = (data, id) => {
  return request({
    url: baseUrl + 'post/' + id,
    method: 'put',
    data: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

export const detPost = (data) => {
  return request({
    url: baseUrl + 'post/batchDelete' ,
    method: 'post',
    data: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

/**
 * 工作台接口
 */

// 我的应用
export const getSaasHomeApp= () => {
  return request({
    url: 'api/saas/saasHomeApp',
    method: 'post',
  })
}

export const getSaasHomeNotice= () => {
  return request({
    url: 'api/saas/saasHomeNotice',
    method: 'post',
  })
}

export const companyCodeUse = (data) => {
  return request({
    url: 'api/saas/companyCodeUse' ,
    method: 'post',
    data: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

export const userVersionApply = () =>{
  return request({
    url: 'api/saas/admin/userVersionApply' ,
    method: 'post',
    data: {
      company_id:getCompanyId()
    }
  })
}

/**
 * 新闻动态增删改查
 */
 export const getNews = (data) => {
  return request({
    url: baseUrl + 'news',
    method: 'get',
    params: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

export const addNews = (data) => {
  return request({
    url: baseUrl + 'news',
    method: 'post',
    data: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

export const editNews = (data, id) => {
  return request({
    url: baseUrl + 'news/' + id,
    method: 'put',
    data: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

export const detNews = (data) => {
  return request({
    url: baseUrl + 'news/batchDelete' ,
    method: 'post',
    data: {
      ...data,
      company_id:getCompanyId()
    }
  })
}
/**
 * @desc :个人中心的接口
 */

// 我管理的企业列表
 export const myManageCompany = () => {
  return request({
    url: baseUrl1 + 'myManageCompany',
    method: 'post',
    data: {
      company_id:getCompanyId()
    }
  })
}
// 我申请的企业列表
export const myApplyCompany = () => {
  return request({
    url: baseUrl1 + 'myApplyCompany',
    method: 'post',
    data: {
      company_id:getCompanyId()
    }
  })
}
// 我的用户信息
export const myUserInfo = () => {
  return request({
    url: baseUrl1 + 'myUserInfo',
    method: 'post',
    data: {
      company_id:getCompanyId()
    }
  })
}
// 企业配置信息
export const myCompanyInfo = () => {
  return request({
    url: baseUrl1 + 'myCompanyInfo',
    method: 'post',
    data: {
      company_id:getCompanyId()
    }
  })
}