<!--
 * @Author: zx
 * @Date: 2022-08-05 10:04:08
 * @LastEditTime: 2022-09-22 10:02:50
 * @Description: 
 * @LastEditors: luocheng
-->
<template>
    <div class="date-picker">
        <div class="header"> 
            <div class="left">
              <p @click="left" class="m-r-15"><i class="iconfont iconc-chevron-left"></i></p>
              <p>{{selectTitle}}</p>
              <p @click="right" class="m-l-15"><i class="iconfont iconxiangyoujiantou2"></i></p>
            </div>
            <div class="right">
              <p class="circle-common" 
                :style="tab.type===selectTab?'background: #2c374d;':''"
                v-for="(tab,index) in tabList" :key="'tab'+index"
                @click="changeTab(index)"
              >{{tab.title}}</p>
            </div>
        </div>
        <div class="content">
            <template v-if="showFlag=='day'">
                <van-row type="flex" justify="center">
                    <van-col span="3" class="week-name" v-for="(name,index) in weekNames" :key="'week'+index">
                        <p :style="index===0||index===6?'color:#A1A5AD;':'color:#25282E;'">{{name}}</p>
                    </van-col>
                </van-row>
                <van-divider style="border-color:#C2C5CC;margin: unset;"/>
                <div v-for="(item,index) in calendatArr" :key="'day'+index" class="day-list-div">
                  <van-row type="flex" justify="center" class="day-liat-row" v-if="(index!==5 || (index===5 && item.length && item[0].month==curMonth))">
                    <van-col @click="checkDateFun(sub)" span="3" class="day-name" v-for="(sub,index) in item" :key="'sub_day'+index">
                        <p :class="['day-name-p',{ circle:sub.date==today, checkedCircle:sub.date==selectDate }]" v-show="sub.month==curMonth">{{sub.day}}</p>
                        <p v-if="sub.month==curMonth" class="gray-font">
                          <span v-if="calendarStyle === 'number'">{{ sub.num }}</span>
                          <span v-else-if="calendarStyle === 'numberTotal'">{{ sub.num }}/{{ sub.total_num }}</span>
                          <span v-else-if="calendarStyle === 'status'">
                            <span class="circle-p" :style="!sub.num? 'background: #BDC0C7': ''"></span>
                          </span>
                        </p>
                    </van-col>
                  </van-row>
                  <van-divider v-if="(index!==5 || (index===5 && item.length && item[0].month==curMonth))" style="border-color:#C2C5CC;margin: unset;"/>
                </div>
            </template>
            <template v-else>
                <div class="month">
                    <van-row type="flex" justify="center" style="padding: 16px 0;">
                        <van-col :style="todayMonth==month.key && todayYear==curYear?'background-color: #2196f3 !important;color:#fff;':''" 
                            @click="changeCurMonth(month)" class="month-name" span="8" v-for="(month,index) in monthArr" :key="'month'+index">
                            {{month.name}}
                        </van-col>
                    </van-row>
                </div>
            </template>
        </div>
    </div>
</template>
<script>
import { reactive,toRefs,onMounted, computed, watch } from 'vue'
import { Row, Col, Divider, Notify } from 'vant'
import eventBus from '@/plugins/eventBus';
import { getComponentById, getLinkByTemplate, doEEActionHandle, judgingEventLogic } from '@/utils/tools';
import useStore from '@/store'
import dayjs from "dayjs"
import { dataInterface } from '@/apis/data';
import isoWeek from "dayjs/plugin/isoWeek";
dayjs.extend(isoWeek);

export default {
    components: {
      'van-row': Row,
      'van-col': Col,
      'van-divider': Divider,
    },
    props:{
      element: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    emits:['check-date','changeUserDataList','change-tab'],
    setup(props,ctx){
        const store = useStore
        const componentData = computed(() => {
          return store.state['componentData']
        })
        const statusConfig = computed(() => {
          return props.element && props.element.statusConfig;
        })
        let state=reactive({
            metadata: [],
            selectTitle:'2021年10月1日',
            weekNames:['日','一','二','三','四','五','六'],
            curYear:'',
            curMonth:'',
            curDay:'',
            showFlag:'day',//当前选择标志，day选择日期，month选择月份
            /* 保存今天的年月日 */
            todayYear:'',
            todayMonth:'',
            todayDay:'',
            today:'',
            monthArr:[
                {key:'01',name:'1月'},
                {key:'02',name:'2月'},
                {key:'03',name:'3月'},
                {key:'04',name:'4月'},
                {key:'05',name:'5月'},
                {key:'06',name:'6月'},
                {key:'07',name:'7月'},
                {key:'08',name:'8月'},
                {key:'09',name:'9月'},
                {key:'10',name:'10月'},
                {key:'11',name:'11月'},
                {key:'12',name:'12月'},
            ],
            calendatArr:[],//当前月所有日期数组
            selectDate:'',
            userInfo: localStorage.getItem('userInfo'),
            tabList:[],
            selectTab:'',//记录选中的tab key
            calendarStyle:'number',// 日历显示样式（数量number/数量及总数numberTotal/填写状态status）
        })

        /* 日期赋予初值 */
        state.today = dayjs().format('YYYY-MM-DD')
        state.curYear = dayjs().format('YYYY')
        state.todayYear = dayjs().format('YYYY')
        state.curMonth = dayjs().format('MM')
        state.todayMonth = dayjs().format('MM')
        state.curDay = dayjs().format('DD')
        state.todayDay = dayjs().format('DD')
        watch(
          () => props.element,
          (val) => {
            if (val) {
              getNumbers()
            }
          }
        )
        let methods={
            /*  showFlag==month 时，减少一年每次
                showFlag==day 时，减少一月
            */
            left(){
                if(state.showFlag=="month"){
                    state.curYear = dayjs(dayjs(state.curYear+state.curMonth+state.curDay).valueOf() - 31536000000).format('YYYY') 
                }else{
                    let month = state.curMonth - 1
                    if(month<1){
                        state.curYear = state.curYear - 1
                        state.curMonth = 12
                    }else{
                        state.curMonth = month
                    }
                    if(parseInt(month)<10 && month!=0){
                        state.curMonth = '0' + state.curMonth
                    }
                }
                methods.getWeekDayArr()
                methods.changeSelectTitle()
                methods.changeUserDataList()
            },
             /*  showFlag==month 时，增加一年每次
                showFlag==day 时，增加一月
            */
            right(){
                if(state.showFlag=="month"){
                    state.curYear = dayjs(dayjs(state.curYear+state.curMonth+state.curDay).valueOf() + 31536000000).format('YYYY') 
                }else{
                    let month = parseInt(state.curMonth) + 1
                    if(month>12){
                        state.curYear = parseInt(state.curYear) + 1
                        state.curMonth = 1
                    }else{
                        state.curMonth = month
                    }
                }
                
                methods.getWeekDayArr()
                methods.changeSelectTitle()
                methods.changeUserDataList()
            },
            changeUserDataList(){
                let date = state.curMonth<10 && state.curMonth.toString().length===1?`${state.curYear}-0${state.curMonth}-01`:`${state.curYear}-${state.curMonth}-01`
                ctx.emit('changeUserDataList',date)
            },
            changeSelectTitle(){
                let month = state.curMonth
                if(state.curMonth<10 && JSON.stringify(state.curMonth).length>1){
                    month = state.curMonth.substr(1,1)
                }
                if(state.showFlag=='day'){
                    state.selectTitle = `${state.curYear}年${month}月`
                }else{
                    state.selectTitle = `${state.curYear}年`
                }
            },
            /* 获取选中年月的全月日期 */
            getWeekDayArr(){        
                let date=state.curYear +'-'+ state.curMonth +'-' + '01'
                // 获取当月的第一天
                let currentFirstDay = date
                
                // 获取第一天是星期几
                let weekDay = dayjs(date).isoWeekday();
                
                // 用当月的第一天减去 周几前面几天 这样就能得到上个月开始的天数
                let startTime = dayjs(currentFirstDay).valueOf() - (weekDay) * 24 * 60 * 60 * 1000;
                // 然后得到所有的日期
                let calendatArr=[]
                for (let i = 0; i < 42; i++) {
                    let day = dayjs(new Date(startTime + i * 24 * 60 * 60 * 1000)).format('DD')
                    calendatArr.push({
                        day: day<10?day.substr(1,1)+ '' :day+ '' ,
                        year: dayjs(new Date(startTime + i * 24 * 60 * 60 * 1000)).format('YYYY') ,
                        month: dayjs(new Date(startTime + i * 24 * 60 * 60 * 1000)).format('MM') ,
                        date: dayjs(new Date(startTime + i * 24 * 60 * 60 * 1000)).format('YYYY-MM-DD') ,
                        num:0,
                    })
                }
                state.calendatArr=[]
                for(var i=0;i<calendatArr.length;i+=7){
                    state.calendatArr.push(calendatArr.slice(i,i+7));
                }
                getNumbers()
            },
            /* 选择月 */
            changeCurMonth({key}){
                state.curMonth = key
                methods.getWeekDayArr()
            },
            checkDateFun(item){
                state.selectDate = item.date
                const weekArr = [ '星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期天' ]
                store.commit('modifyComponent', {
                  component: {
                    ...props.element,
                    resolveData: {
                      date: state.selectDate,
                      num: item.num,
                      total_num: item.total_num,
                      dateArr: [state.selectDate + ' 00:00:00', state.selectDate + ' 23:59:59'],
                      dateTime: state.selectDate + ' 12:00:00',
                      weekDay: weekArr[dayjs(state.selectDate).isoWeekday() - 1]
                    }
                  },
                  containerId: null,
                  isModify: true
                });
                const eventList = props.element.actionConfig.rowClick.eventList;
                if (eventList && eventList.length) {
                  eventList.forEach((el) => {
                    el.eventList.forEach(ele => {
                      /* 事件触发处理 */
                      doActionItem(ele, {});
                    })
                  });
                }
            }
        }
        /* 匹配数据 */
        const matchDate = (data) =>{
            if(data && data.length){
                for(let i=0; i<state.calendatArr.length; i++){
                    for(let k=0; k<state.calendatArr[i].length; k++){
                        let item = state.calendatArr[i][k]
                        item.num = 0
                        for(let j=0; j<data.length; j++){
                            let sub = data[j] 
                            if(dayjs(sub.fill_in_time).format('YYYY-MM-DD') === dayjs(item.date).format('YYYY-MM-DD')){
                                item.num = item.num + 1
                                item.user_id = sub.user_id
                            }
                        }
                    }
                }
            }
        }
        const changeTab = (ind) => {
          console.log(state.tabList[ind], 'state.tabList[ind]')
          state.selectTab = state.tabList[ind].type
          state.calendarStyle = state.tabList[ind].styleConfig
          const index = +ind;
          const comEvents = state.tabList[index]?.eventList || [];
          for (let i = 0; i <comEvents.length; i++) {
            const { pattern, eventList = [], specialEventList = [] } = comEvents[i];
            console.log(pattern, 'pattern')
            console.log(props.element, 'element')
            if (pattern === undefined) {
              doActionItem(comEvents[i], {})
              break;
            }
            const result = pattern === 'special' ? specialEventList : eventList
            result.forEach((ele) => {
              doActionItem(ele, {});
            });
          }
          let resolveData = null;
          resolveData = {
            label: state.tabList[index].title,
            value: state.tabList[index].type
          };
          props.element.resolveData = resolveData;
          store.commit('modifyComponent', {
            component: {
              ...props.element,
              resolveData
            },
            containerId: null,
            isModify: true
          });
          // 触发事件
          // event bus  挂载卸载问题
          setTimeout(() => {
            eventBus.$emit('databaseTrigger', {
              componentId: props.element.id,
              action: 'any',
              output: resolveData
            });
          }, 0);
        
        }
        /* 事件触发 */
        const doActionItem = (ele, output = {}) => {
          if (ele.key === 'click') {
            // 跳转页面
            if (ele.actionType === 'jumpPage') {
              if (ele.linkType === 'projectPage') {
                const query = {};
                ele.queryList.forEach((queryItem) => {
                  let component = getComponentById(componentData, queryItem.componentId)
                  query[queryItem.key] = component.resolveData[queryItem.feild]
                });
              } else if (ele.linkType === 'outPage') {
                window.open(ele.linkUrl);
              } else if (ele.linkType === 'custom') {
                const customLink = getLinkByTemplate(ele.linkTemplate)
                window.open(customLink);
              } else if (ele.linkType === 'customForm') {
                const params = {}
                if(ele.customFormLink) {
                  if(ele.customFormLink && Array.isArray(ele.customFormLink.params)) {
                    ele.customFormLink.params.map(param => {
                      if (param.component) {
                        let component = getComponentById(componentData, param.component);
                        params[param.key] = component.resolveData[param.value]
                      }
                    })
                  }
                  const { object_uuid, view_uuid, title } = ele.customFormLink
                  if ( !object_uuid || !view_uuid || !title ) {
                    Notify({ type: 'danger', message: '表单参数配置缺失!' });
                    return
                  }
                }
              } else if (ele.linkType === 'pageH5') {
                Notify({ type: 'success', message: '跳转h5页面!' });
              }
            } else if (ele.actionType === 'eeAction') {
							doEEAction(ele, output);
            } else {
            // 页面事件
              ele.effects.forEach((effect) => {
                let logicBoolean = true
                if (effect.logics.length) {
                  logicBoolean = judgingEventLogic(effect.logics, componentData || []);
                }
                if (logicBoolean) {
                  store.commit('triggerEvents', {
                    config: {
                      ...ele,
                      ...effect
                    },
                    element: props.element
                  })
                }
              });
            }
          }
        }
        /**
         * @desc: 触发后端事件
         */
        const doEEAction = (btnConfig, rowData = {}) => {
          if (btnConfig && btnConfig.actionType === 'eeAction') {
            const { objectUUID, viewUUID, eventName } = btnConfig;
            if (!objectUUID || !viewUUID || !eventName) {
              Notify({ type: 'danger', message: '请正确配置事件!' });
              return false;
            }
            const idUUID = getIdUUID();
            const data_id = rowData?.[idUUID];
            if (!data_id) {
              Notify({ type: 'danger', message: '事件未配置触发对象!' });
              return false;
            }
            dataInterface({
              __method_name__: 'customEventCall',
              object_uuid: btnConfig.objectUUID,
              view_uuid: btnConfig.viewUUID,
              data_id,
              event: btnConfig.eventName
            }).then((res) => {
              if (res.status === 200 && res.data.code === 200) {
                Notify({ type: 'succes', message: '操作成功！!' });
                doEEActionHandle(res.data?.__adds__);
              }
            }).catch((err) => {
              console.log(err, '00000后端事件错误');
            });
          }
        }
        /**
         * @desc: 获取数据主键，ID UUID
         */
        const getIdUUID = () => {
          let idUUID = statusConfig.idUUID || '';
          if (!idUUID) {
            for (let key in state.metadata) {
              if (state.metadata[key] === 'id') {
                idUUID = key;
              }
            }
          }
          return idUUID;
        }
        /**
         * @description: 生成请求数据
         * @param{Array} dateList 日期数组
         * @return {Object} 全局moreAction请求数据
         */
        const generateNumberData = (arr) => {
          let data = [];
          arr.forEach(item => {
              let tmp = {
                "__method_name__": 'globalFunctionCall',
                "funcName": "ViewAggregate",
                "payload": {
                  "view_uuid": props.element.countConfig?.view_uuid,
                  "aggregate": "4",
                  "_is_cache": false,
                  "need_search" : true,
                },
                "typeName": "PublicFunc",
                "type": "value"
              }
              if(props.element.countConfig?.field_uuid){
                tmp.search = [
                  { field_uuid: props.element.countConfig?.field_uuid, ruleType: 'between', value: [item.date +' 00:00:00', item.date + ' 23:59:59'] },
                ]
              }else{
                tmp.search = [
                  { code: 'created_at', ruleType: 'between', value: [item.date +' 00:00:00', item.date + ' 23:59:59'] },
                ]
              }
              data.push(tmp)
              tmp = JSON.parse(JSON.stringify(tmp));
              tmp.__show_pre__ = 1
              data.push(tmp)
          })
          let params = {
            __method_name__: 'moreAction',
            data: data
          }
          return params;
        }
        /**
         * @description: 获取日历相关的数字数据 
         * @return {Void}
         */    
        const getNumbers = () => {
          if (!props.element.countConfig || !props.element.countConfig.view_uuid) {
            return
          }
          const arr = []
          // 获取当前月的起始时间中止时间
          let startDate= state.curYear +'-'+ state.curMonth +'-' + '01'
          const curMonth = state.curYear +'-'+ state.curMonth
          for (let i = 0; i < dayjs(curMonth).daysInMonth(); i++) {
              let day = dayjs(new Date(Date.parse(startDate) + i * 24 * 60 * 60 * 1000)).format('DD')
              arr.push({
                  day: day<10?day.substr(1,1)+ '' :day+ '' ,
                  year: dayjs(new Date(Date.parse(startDate) + i * 24 * 60 * 60 * 1000)).format('YYYY') ,
                  month: dayjs(new Date(Date.parse(startDate) + i * 24 * 60 * 60 * 1000)).format('MM') ,
                  date: dayjs(new Date(Date.parse(startDate) + i * 24 * 60 * 60 * 1000)).format('YYYY-MM-DD') ,
                  num:0,
              })
          }
          let params = generateNumberData(arr)
          dataInterface(params).then(res => {
            if(res.data.code == 200){
              arr.forEach((element,index) => {
                if (index === 0) {
                  element.num = res.data.data[1]
                  element.total_num = res.data.data[0]
                } else {
                  element.num = res.data.data[index * 2 + 1]
                  element.total_num = res.data.data[index * 2]
                }
              });
              state.calendatArr.forEach(element => {
                element.forEach(item => {
                  arr.forEach(ite => {
                    if (item.date === ite.date) {
                      item.num = ite.num
                      item.total_num = ite.total_num
                    }
                  });
                });
              });
            }
          })
        }
        onMounted(()=>{
            methods.changeSelectTitle()
            methods.getWeekDayArr()
            if (props.element?.tabConfig?.tabs?.length) {
              state.tabList = props.element.tabConfig.tabs
              for (let index = 0; index < state.tabList.length; index++) {
                const element = state.tabList[index];
                if (element.isDefault) {
                  state.selectTab = element.type
                  state.calendarStyle = element.styleConfig
                  console.log(state.calendarStyle, 'state.calendarStyle')
                }
              }
            }
            state.metadata = props.element.metadata || [];
        })
        return {
            ...toRefs(state),
            ...methods,
            matchDate,
            changeTab
        }
    }

}
</script>
<style lang="less" scoped>
.date-picker{
    background: #fff;
    .header{
        display: flex;
        padding: 12px 16px;
        justify-content: space-between;
        .left{
          width: 200px;
          display: flex;
          align-items: center;
          p{
            font-weight: bolder;
            display: flex;
            align-items: center;
          }
          .m-l-15{
            margin-left: 15px;
          }
          .m-r-15{
            margin-right: 15px;
          }
        }
        .right{
          display: flex;
          .circle-common{
            width: 36px;
            height: 36px;
            text-align: center;
            line-height: 36px;
            background: #BDC0C7;
            border-radius: 50%;
            color: #fff;
            margin: 0 8px;
            font-size: 12px;
            font-weight: 500;
          }
        }
    }
    .gray-font{
      font-size: 12px;
      font-weight: 500;
      text-align: center;
      color: #a1a5ad;
      .circle-p{
        display: inline-block;
        width: 6px;
        height: 6px;
        background: #07d18c;
        border-radius: 50%;
      }
    }
    .content{
        .circle{
            color: #F10823!important;
        }
        .checkedCircle{
            // background-color: #2196f3;
            color: #fff!important;
            // border: 1px solid #2196f3;
            // border-radius: 50%;
            width: 28px;
            height: 26px;
            background: #2c374d;
            border-radius: 6px;
        }
        .week-name{
            color: rgba(0,0,0,0.38);
            font-size: 12px;
            padding: 8px 0;
            text-align: center;
        }
        .day-name{
            height: 50px;
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 12px;
            text-align: center;
        }
        .day-name-p{
            width: 32px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            font-size: 18px;
            font-weight: 500;
            text-align: center;
            color: #25282e;
        }
        .month{
            .month-name{
                text-align: center;
                padding: 10px 0;
                font-size: 12px;
            }
        }
    }
    .me-circle{
        height: 41px;
        display: flex;
        align-items: center;
        justify-content: center;
      .circle-div{
        width: 70px;
        display: flex;
        align-items: center;
      }
      .circle{
        width: 12px;
        height: 6px;
        background: #07d18c;
        border-radius: 3px;
        margin-right: 10px;
      }
      .font-p{
        font-size: 13px;
        font-weight: 400;
        color: #7d818a;
      }
    }
}
</style>