<!--
 * @Author: zqy
 * @Date: 2022-06-17 10:31:36
 * @LastEditTime: 2022-12-06 11:43:22
 * @Description: 
 * @LastEditors: zx
-->
<template>
  <div class="add-drawer">
    <iframe 
      id="mobileiAddframe"
      :src="iframeSrc" 
      frameborder="0"
      style="width:100%;height:100%;"
    >
    </iframe>
  </div>
</template>
<script>
import { defineComponent, nextTick, onMounted, onUnmounted, reactive, toRefs } from 'vue'
import eventBus from '@/plugins/eventBus'
import { getToken } from '@/utils/tools';

export default defineComponent({
  props:{
    addConfig:{
      type:Object,
      default: () => {
        return {}
      }
    },
  },
  setup(props) {
    const state = reactive({
      iframeSrc:'https://nh.bimcc.net/h5/formRender/add'
    })
    const close = (flag)=>{
      console.log(flag, '移动端关闭');
      eventBus.$emit('closeAddFormDrawer',flag);
    }
    const iframePostMessage = (e)=>{
      if (e.data && e.data.type == 'mobileForm') {
        const params = Object.assign({},{
          type: 'mobileiAddframe',
          token: getToken(),
          userInfo: localStorage.getItem('userInfo'),
          targetArchi: localStorage.getItem('targetArchi'),
          myCompany: localStorage.getItem('myCompany'),
          myProjects: localStorage.getItem('myProjects'),
          params: props.addConfig.paramsObject ? JSON.stringify(props.addConfig.paramsObject) : null,
          fillData: props.addConfig.fillDataObject ? JSON.stringify(props.addConfig.fillDataObject) : null
        },props.addConfig)
        if (props.addConfig.type === 'add') {
          params.type = 'mobileiAddframe'
        }
        if (params.paramsObject) {
          delete params.paramsObject
        }
        if (params.fillDataObject) {
          delete params.fillDataObject
        }
        console.log(params, 'params')
        document.getElementById('mobileiAddframe').contentWindow.postMessage(params,'*');
      }else if(e.data && e.data.type == 'closeAddDrawer'){
        close(e.data.flag)
      }
    }
    onMounted(()=>{
      nextTick(()=>{
        window.addEventListener('message', iframePostMessage) 
      })
    })
    onUnmounted(()=>{
      window.removeEventListener("message",iframePostMessage); // 移除
    })
    return {
      close,
      ...toRefs(state)
    }
  }
})
</script>
<style lang="less" scoped>
::-webkit-scrollbar { width: 0 !important }
.add-drawer{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 100;
}
</style>
