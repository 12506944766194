/*
 * @Author: zx
 * @Date: 2022-08-17 15:32:43
 * @LastEditTime: 2023-01-17 11:19:58
 * @Description: saas系统基础路由（登录、架构等页面路由）
 * @LastEditors: zqy
 */
let baseRoute = [
	// {
	// 	path: '/',
	// 	meta: {
	// 		title: '筑云saas平台'
	// 	},
	// 	redirect: {
  //     name: 'HomePage'
  //   }
	// },
  // 登录
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/manage-views/views/login/secondEdition/index.vue'),
    meta: {
      title: '登录'
    }
  },
  {
    path: '/share',
		meta: {
			title: '分享'
		},
		name: 'Share',
		component: () =>import('@/manage-views/components/share/Login'),
  },
  {
    path: '/shareDetail',
    name: 'shareDetail',
    component: () => import('@/manage-views/components/share/Index.vue'),
    meta: {
      title: '分享详情',
      icon: 'iconc-home'
    }
  },
  {
    path: '/appshareDetail',
    name: 'appshareDetail',
    component: () => import('@/manage-views/components/share/appShare/Index.vue'),
    meta: {
      title: '分享详情',
      icon: 'iconc-home'
    }
  },
  // 首页
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/manage-views/views/home/home'),
    meta: {
      title: '首页',
      icon: 'iconc-home'
    }
  },
    // 第二屏
  {
    path: '/second',
    name: 'Second',
    component: () => import('@/manage-views/views/personalCenter/components/selectRole/company.vue'),
    meta: {
      title: '选择企业'
    }
  },
  // etl 测试路由
  {
    path: '/testEtl',
    name: 'TestEtl',
    component: () => import('@/components/graph/ETL.vue'),
    meta: {
      title: '测试ETL'
    }
  },
]
const filesManage = require.context('@/router/manage', true, /\.js$/)
filesManage.keys().forEach(key => {
  const name = Object.keys(filesManage(key).default)
  name.forEach(element => {
    baseRoute = baseRoute.concat(filesManage(key).default[element])
  });
})
// const filesWebsite = require.context('@/router/website', true, /\.js$/)
// filesWebsite.keys().forEach(key => {
//   const name = Object.keys(filesWebsite(key).default)
//   name.forEach(element => {
//     baseRoute = baseRoute.concat(filesWebsite(key).default[element])
//   });
// })

baseRoute = baseRoute.filter((item) => {
  return !!item
})
export default baseRoute
