/*
 * @Description: 自定义配置页面状态
 * @Author: luocheng
 * @Date: 2022-06-14 16:22:36
 * @LastEditors: luocheng
 * @LastEditTime: 2022-06-16 09:51:37
 */
import Vue from 'vue';
const vm = new Vue();
export default {
  state: {
    _PageCustomStatus: {}, // 页面状态
    _APPCustomStatus: {} // 全局状态
  },
  mutations: {
    /**
     * @desc: 初始化页面状态
     */
    initPageCustomStatus(state, statusList) {
      for(let i = 0; i < statusList.length; i++) {
        const { code = '', useDefault = false, defaultValue, type } = statusList[i];
        if (+type === 1) {
          state._PageCustomStatus = {
            ...state._PageCustomStatus,
            [code]: useDefault ? defaultValue : undefined
          }
        } else if (+type === 2) {
          state._APPCustomStatus = {
            ...state._APPCustomStatus,
            [code]: useDefault ? defaultValue : undefined
          }
        }
      }
    },
    /**
     * @desc: 清除页面状态
     */
    clearPageCustomStatus(state) {
      vm.$set(state._PageCustomStatus, {});
    },
    /**
     * @desc: 改变页面状态
     */
    updatePageCustomStatus(state, { key, value }) {
      /* eslint-disable */ 
      const { _PageCustomStatus, _APPCustomStatus } = state;
      if (_PageCustomStatus.hasOwnProperty(key)) {
        // 页面状态
        state._PageCustomStatus[key] = value;
      } else if (_APPCustomStatus.hasOwnProperty(key)) {
        // 全局状态
        state._APPCustomStatus[key] = value;
      }
    }
  }
}