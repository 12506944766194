<!--
 * @Description: 首页
 * @Author: luocheng
 * @Date: 2021-09-13 13:55:25
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2022-12-30 15:27:36
-->
<template>
  <div class="app-box">
    <div class="container">
      <div class="header">
        <h4  class="title">我的应用</h4>
      </div>
      <div class="content" :class="{
        'grid-content': appConfig && Array.isArray(appConfig ) && appConfig.length
      }">
        <template>
          <!-- eslint-disable -->
          <div v-for="(items, index) in appConfig" :key="index" class="content-item-box" @click="handleAppClick(items)">
            <div class="content-item">
              <div class="content-icon">
                <div
                  class="header-img" 
                  :style="'background: ' + (items.background && items.background.indexOf('linear') !== -1 ? items.background : '')"
                >
                <svg 
                    v-if="items.icon && items.background"
                    aria-hidden="true"
                    class="color-icon">
                    <use :xlink:href="items.icon.indexOf('#')===-1? '#' + items.icon : items.icon" />
                  </svg>
                <div v-else-if="items.icon && items.color" style="background: transparent">
                  <i 
                    class="iconfont" 
                    :class="items.icon? items.icon : 'icon-a-5-1zhiliang'"
                    style="color:#ffe6de"
                  >
                  </i>
                </div>
                  <svg 
                    v-else
                    aria-hidden="true"
                    class="color-icon">
                    <use xlink:href="#icon-a-05jiluwendang" />
                  </svg>
                </div>
              </div>
              <div class="content-text">
                <p class="item-title">{{ items.name }}</p>
                <p class="item-desc" v-if="items.shortcut_desc">{{ items.remarks }}</p>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {getSaasHomeApp} from '@/api/saasManage'
import { infoList } from './config';
// import { mapState } from "vuex";

export default {
	name: 'myApp',
  components: {
  },
	props: {
		pageUUID: {
			type: String,
			required: false,
			default: ''
		}
	},
	data() {
		return {
			appConfig: [],
      infoList,
      loading: false
		};
	},
	created() {
    this.getShortcuts();
	},
	methods: {
    /**
     * @desc: 点击快捷方式(我的应用)
     * @param {Object} item
     */
    handleAppClick(item) {
      /* eslint-disable */
     this.$emit('toAppUrl',item)
      
    },
    handleInfoClick(item){
      const {
        path = '',
        query = {},
        isScreen = false
      } = item;
      if (!path) {
        this.$message.error('暂未配置目标页面!');
        return;
      }
      if (isScreen) {
        window.open(path.indexOf('http') === 0 ? path : window.origin + path);
        return;
      }
      this.$router.push({
        path,
        query
      })
    },
    /**
		 * @desc: 获取应用首页
		 * @param {Array} list route list
		 */
		getIndexPath(list = []) {
      // console.log(list, '----list---获取应用首页---')
			for (let i = 0; i < list.length; i++) {
				const { is_index, is_hide, children_list, menu_type } = list[i];
				if (is_index && !is_hide && (+menu_type === 0 || +menu_type === 4)) {
					return list[i];
				}
				if (children_list && children_list.length) {
					const result = this.getIndexPath(children_list);
					if (result) {
						return result;
					}
				}
			}
			return null;
		},
    /**
     * @desc: 获取分组数据
     * @param {String} groupPath
     * @param {Array} routeList
     */
    getGroupData(groupPath = '', list = []) {
      for (let i = 0; i < list.length; i++) {
				const { menu_type, menu_path, children_list = [] } = list[i];
				if (+menu_type === 2 && menu_path === groupPath) {
					return list[i];
				}
				if (children_list && children_list.length) {
					const result = this.getGroupData(groupPath, children_list);
					if (result) {
						return result;
					}
				}
			}
			return null;
    },
    /**
     * @desc: 获取分组中第一个可访问路由
     * @param {Array} list
     */
    getGroupIndexPath(list = []) {
      for (let i = 0; i < list.length; i++) {
				const { menu_type, children_list = [] } = list[i];
				if (+menu_type === 0 || +menu_type === 4) {
					return list[i];
				}
				if (children_list && children_list.length) {
					const result = this.getGroupIndexPath(children_list);
					if (result) {
						return result;
					}
				}
			}
			return null;
    },
    /**
     * @desc: 获取当前项目的快捷方式列表
     */
    getShortcuts() {
      this.loading = true;
      getSaasHomeApp().then(res => {
        this.appConfig = res?.data?.data || [];
        this.loading = false;
      }).catch(err => {
        console.log(err, '----err');
        this.loading = false;
      });
    },
    goManageCenter() {
			this.$store.commit('setShowAppCenter', true);
      this.$router.push({
        path: 'work-order',
        query: {
          menu_type: 4
        }
      });
    }
	}
};
</script>

<style lang="less" scoped>
.archi-label-img {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  box-sizing: border-box;
  margin-right: 6px;
  border-radius: 4px;
  background-color: #e8f4ff;
  border: 1px solid #dee1e6;
  img {
    width: 22px;
    height: 22px;
  }
  span {
    color: #409EFF;
    font-weight: 550;
    font-size: 18px;
  }
}
.app-box{
  height: 100%;
  width: 100%;
  background: #FFFFFF;
  border-radius: 8px;
  padding: 6px 20px 20px 20px;
  box-sizing: border-box;
  overflow: hidden;
  overflow-y: auto;
  
  .container{
    width: 100%;
    padding-top: 10px;
    .header{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .title{
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      color: #2A2F3D;
      letter-spacing: 1px;
    }
    .content{
      padding-top: 12px;
      &.grid-content{
        display: grid;
        grid-template-columns: repeat(3,31.5%);
        grid-column-gap: 16px;
        grid-row-gap: 16px;
      }
      .content-item-box{
        .content-item:hover {
          background-color: #F5F7FA;
        }
        .content-item{
          padding: 12px 21px 12px 12px;
          border-radius: 8px;
          border: 1px solid #F2F5FC;
          display: flex;
          align-items: center;
          cursor: pointer;
          .content-icon{
            height: 40px;
            width: 40px;
            line-height: 40px;
            box-sizing: border-box;
            text-align: center;
            display: flex;
            align-items: center;
            font-size: 36px;
            .iconfont-svg{
              display: block;
              height: 100%;
              width: 100%;
            }
            .header-img {
              width: 100%;
              height: 100%;
              border-radius: 4px;
              display: flex;
              justify-content: center;
              align-items: center;
              background: linear-gradient(180deg, #FFA557 0%, #FF5C5C 100%);
              i {
                font-size: 26px;
                margin-right: 0;
              }
              svg {
                width: 26px;
                height: 26px;
              }
            }
          }
          .item-icon-info{
           width:21px;
           height:21px;
           padding: 0 8px;
          }
          .content-text{
            box-sizing: border-box;
            flex: 1;
            padding-left: 12px;
            height: 100%;
            overflow: hidden;
            .item-title{
              height: 22px;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #272829;
              line-height: 22px;
            }
            .item-desc{
              height: 18px;
              width: 100%;
              box-sizing: border-box;
              box-sizing: border-box;
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #969799;
              line-height: 18px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .item-title,.item-desc{
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }
        .el-empty{
          margin: 0 auto;
          padding: 20px 0;
        }
      }
      
    }
  }
  .block{
    display: block;
  }
}
@media screen and (max-width: 1600px) {
.app-box{
  padding: 6px 24px 20px 10px;
 .container{
  padding-top: 12px;
   .content{
    &.grid-content{
        grid-template-columns: repeat(2,48%);
      }
    .content-item-box{
        .content-item{
          padding: 12px;
          .content-icon{
            font-size: 24px;
            i{
              display: block;
              height: 100%;
              line-height: inherit;
            }
            .iconfont-svg{
              display: block;
              height: 90%;
              width: 90%;
            }
          }
          .content-text{
            padding-left: 4px;
            .item-title{
              height: 22px;
            }
          }
        }
      }
     }
    }
  }
}
</style>

