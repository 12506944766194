<!--
 * @Author: your name
 * @Date: 2021-12-17 14:02:47
 * @LastEditTime: 2022-11-11 09:25:19
 * @LastEditors: zx
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \dataview-viewer-test\src\components\fileUpload\newFiles\index.vue
-->
<template>
	<div class="file-upload">
		<el-upload
			v-if="!disabled"
			:action="uploadURL"
			:before-upload="beforeUpload"
			class="upload-style"
			:data="{ token }"
			:multiple="multiple"
			:on-success="onSuccess"
			:show-file-list="uploading"
      :drag="drag"
			:headers="{
        metadatatoken: 'aba62ca1-c2ff-42af-9d3a-bbe5b02f7b06',
      }"
		>
			<el-button
         v-if="!drag"
				:icon="icon"
				type="default"
			>
				<i
					class="iconfont iconjijia_shangchuan"
					style="font-size: 16px"
				></i>
				{{ btnName }}
			</el-button>
      <div v-if="drag" class="drag-text">
        <i
          class="iconfont iconjijia_shangchuan"
          style="font-size: 24px; display: block;color: #5da6fa"
        />
        <p style="font-size: 14px; font-weight: 400">
          <span style="color: #202126">拖拽上传</span>
          <span style="color: #8a8f99; margin: 0 8px">或</span>
          <span style="color: #5da6fa">点击上传</span>
        </p>
      </div>
		</el-upload>
		<!-- <div class="upload-img-area">
			<template v-if="imageArr && imageArr.length">
				<div
					class="upload-img-item"
					v-for="(item, index) in imageArr"
					:key="index"
				>
					<el-image
						:src="url.toString().replace('/api', '') + item.path"
						@click="handleView(index)"
						:preview-src-list="previewList"
						:z-index="index"
					/>
					<div
						v-show="!disabled"
						class="del-image"
					>
						<i
							class="iconfont iconc-close"
							style="color: #fff; font-size: 18"
							@click.stop="handleRemoveImg(index)"
						/>
					</div>
				</div>
			</template>
		</div> -->
		
    <div class="upload-img-area">
      <div style="display:inline-block" v-for="(item, index) in imageArr" :key="index">
        <div class="upload-img-item">
          <el-image v-if="onlyImgUrl" :src="item" :fit="fit" :preview-src-list="previewList" @click="handleView(index)" />
          <el-image v-else :src="item.thumb_path" :fit="fit" :preview-src-list="previewList" @click="handleView(index)" />
          <div v-show="!disabled" class="del-image">
            <i
              class="iconfont iconshanchu"
              style="color: #fff; font-size: 18"
              @click.stop="handleRemoveImg(index)"
            />
          </div>
        </div>
      </div>
    </div>
		<div
			v-if="Array.isArray(fileArr) && fileArr.length"
			class="upload-files-area"
		>
      <div class="file-show">
        <div v-for="(item, index) in fileArr" :key="item.filepath" class="file-item">
          <div class="file-item-left">
            <svg aria-hidden="true" class="iconfont-svg">
              <use xlink:href="#iconfujian1" />
            </svg>
            {{ getFileName(item.filename) }}
          </div>
          <div class="file-item-right">
            <span class="file-item-view" @click="openFile(item)">
              预览
            </span>
            <a
              v-if="!disabled ? false : true"
              :download="item.name"
              :href="item.filepath"
              style="color: #087AFF"
            >
              下载
            </a>
            <span
              v-if="disabled ? false : true"
              class="file-item-del"
              @click="handleRemove(index)"
            >
              删除
            </span>
          </div>
          <div class="file-item-size">
            {{ getFileSize(item.filesize) }}
          </div>
        </div>
      </div>
		</div>
    <div v-if="disabled && (!fileArr || fileArr.length<=0) && (!imageArr || imageArr.length<=0)">
      无
    </div>
		<el-dialog
			v-model="dialogVisible"
			append-to-body
			title="查看大图"
		>
			<div style="width: 100%; height: 100%">
				<el-image
					fit="fill"
					:src="dialogImageUrl"
				/>
			</div>
		</el-dialog>
	</div>
</template>
<script>
import { parseTime, getToken } from '@/utils/tools';
import { baseUrl } from '@/apis/http/request';
import { Dialog, Image, Upload } from 'element-ui';

export default {
	props: {
		// 文件
		files: {
			type: Array,
			default() {
				return [];
			}
		},
		// 禁用
		disabled: {
			type: Boolean,
			default: false
		},
		// 按钮名
		btnName: {
			type: String,
			default: '上传文件'
		},
		// icon
		icon: {
			type: String,
			default: ''
		},
		// 是否多选
		multiple: {
			type: Boolean,
			default: true
		},
		// 允许上传的文件格式
		fileTypeList: {
			type: Array,
			default: () => []
		},
    // 文件名超出最长长度后中间显示省略号
    nameMaxLength: {
      type: Number,
      default: 20
    },
    // 拖拽上传
    drag: {
      type: Boolean,
      default: false
    },
    // 仅图片地址数组(全路径)
    onlyImgUrl: {
      type: Boolean,
      default: false
    }
	},
	components: {
		'el-dialog': Dialog,
		'el-upload': Upload,
		'el-image': Image,
	},
	// emits: ['extra-files'],
	data() {
		return {
			extra_file: [],
			uploading: false,
			currentUser: '', //当前用户
			imageArr: [], // 图片数组
			fileArr: [], //文件数组
			url: baseUrl,
			dialogVisible: false,
			dialogImageUrl: '',
			uploadURL: `${baseUrl}api/mapi?__method_name__=saasFile&token=${getToken()}`,
			token: getToken(),
			imageType: ['bmp', 'jpg', 'png', 'gif', 'jpeg', 'cdr', 'psd']
		};
	},
	computed: {
		// 预览列表
		previewList() {
			if (!this.imageArr || !Array.isArray(this.imageArr)) return [];
			if(this.onlyImgUrl) return this.imageArr
			return this.imageArr.map((ele) => {
				const isThumb =  ele.filepath.indexOf('_thumb') !== -1
				let str = '';
				if(isThumb) {
					let path = ele.filepath.split('_thumb');
					if (path[1] && path[1] !== 'undefined') {
						str = path[0] + '' + path[1];
					} else {
						str = path[0];
					}
				} else {
					str = ele.filepath
				}
				return str;
			});
			
		}
	},
	watch: {
		files: {
			handler(files) {
        if (this.onlyImgUrl) {
          this.imageArr = files
        } else {
					this.imageArr = [];
					this.fileArr = [];
					if (files && files.length !== 0) {
						files.map((item) => {
							if (typeof item === 'object') {
								if (
									this.imageType.indexOf(
										item.filepath
											.substring(item.filepath.lastIndexOf('.') + 1)
											.toLowerCase()
									) > -1
								) {
									this.imageArr.push(item);
								} else {
									this.fileArr.push(item);
								}
							} else {
								this.imageArr.push({
									name: '',
									upUser: '未知',
									upTime: parseTime(new Date()),
									path: item
								});
							}
						});
						this.extra_file = files;
					}
				}
			},
			immediate: true,
			deep: true
		},
		extra_file: {
			handler(data) {
				this.loading = false;
				this.$emit('extra-files', data, this.index);
			},
			immediate: true,
			deep: true
		}
	},
	methods: {
		/**
		 * @description 上传成功回调
		 * @param {*} response
		 * @param {*} file
		 * @param {*} fileList
		 */
		onSuccess(res) {
			this.uploading = false;
			if (!Array.isArray(this.extra_file)) {
				this.extra_file = [];
			}
			/* this.extra_file.push({
          name: file.name,
          upUser: this.currentUser,
          upTime: parseTime(new Date()),
          path: response.data.filepath,
        }) */

			this.uploading = false;
			if (this.extra_file == null) {
				this.extra_file = new Array();
			}
			//判断上传文件是否为图片
			res.data.upTime = parseTime(new Date());
			res.data.name = res.data.filename;
			if (this.imageType.indexOf(res.data.fileext.toLowerCase()) > -1) {
				res.data.path = res.data.thumb_path;
			} else {
				res.data.path = res.data.filepath;
			}
			this.extra_file.push(res.data);
		},
		/**
		 * @description 上传前置钩子
		 */
		beforeUpload(file) {
			this.uploading = true;
			if (this.fileTypeList.length > 0) {
				let arr = [];
				this.fileTypeList.forEach((item) => {
					if (file.name.indexOf(item) > -1) {
						arr.push(file.name);
					}
				});
				if (arr.length == 0) {
					this.$message.error(
						'请上传 ' + this.fileTypeList.join(',') + ' 格式的文件！'
					);
					return false;
				}
			}
		},
		/**
		 * @description 查看图片
		 * @param {Number} index
		 */
		handleView(index) {
			const isThumb =  this.imageArr[index].filepath.indexOf('_thumb') !== -1
			let str = '';
			if(isThumb) {
				let path = this.imageArr[index].filepath.split('_thumb');
				if (path[1] && path[1] !== 'undefined') {
					str = path[0] + '' + path[1];
				} else {
					str = path[0];
				}
			} else {
				str = this.imageArr[index].filepath
			}
			this.dialogImageUrl = str;
			this.dialogVisible = true;
			// 暂时没有实现
			// let arr=[]
			// this.imageArr.map(item=>{
			//     arr.push(item.path)
			// })
			// this.$store.commit('setImageUrls', {data: arr,index :index})
		},
		/**
		 * @description 查看文件预览
		 * @param {Object} row
		 */
		openFile(row) {
			if (
				['bmp', 'jpg', 'png', 'gif', 'jpeg', 'cdr', 'psd'].indexOf(
					row.path.substring(row.path.lastIndexOf('.') + 1).toLowerCase()
				) >= 0
			) {
				// this.$store.commit('setImageUrls', { data: [row.path] })
				this.dialogImageUrl = row.path;
				this.dialogVisible = true;
			} else if (
				['doc', 'docx', 'pdf', 'ppt', 'pptx', 'xlsx', 'xls', 'txt'].indexOf(
					row.path.substring(row.path.lastIndexOf('.') + 1).toLowerCase()
				) >= 0
			) {
        let url = 'https://weboffice.bimcc.net?furl='
				if (row.path.includes('https://')) {
					url = 'https://weboffice.bimcc.net?ssl=1&furl='
				}
				window.open(
					url + row.path
				)
			} else if (
				['mp4'].indexOf(
					row.path.substring(row.path.lastIndexOf('.') + 1).toLowerCase()
				) >= 0
			) {
				window.open(row.path);
			} else {
				this.$message.info('无法打开此格式的文件！');
			}
		},
		/**
		 * @description 移除图片
		 * @param {Number} index
		 */
		handleRemoveImg(index) {
			this.imageArr.splice(index, 1);
			this.extra_file = [].concat(this.imageArr, this.fileArr);
		},
		/**
		 * @description 移除文件
		 * @param {Number} index
		 */
		handleRemove(index) {
			this.fileArr.splice(index, 1);
			this.extra_file = [].concat(this.imageArr, this.fileArr);
		},
		/**
		 * @description 文件名字截取
		 * @param {String} name
		 */
		getFileName (name) {
      let str = ''
      if (name.length > this.nameMaxLength) {
        str = name.substring(0, 15) + '...' + name.substring(name.length - name.split('.')[1].length - 2, name.length - name.split('.')[1].length - 1) + '.' + name.split('.')[1]
      } else {
        str = name
      }
      return str
    },
    /**
     * @description 文件大小显示
     * @param {Number} fileSize
     */
    getFileSize (fileSize) {
      if (fileSize < 1024) {
        return fileSize + 'B'
      } else if (fileSize < (1024 * 1024)) {
        let temp = fileSize / 1024
        temp = temp.toFixed(2)
        return temp + 'KB'
      } else if (fileSize < (1024 * 1024 * 1024)) {
        let temp = fileSize / (1024 * 1024)
        temp = temp.toFixed(2)
        return temp + 'MB'
      } else {
        let temp = fileSize / (1024 * 1024 * 1024)
        temp = temp.toFixed(2)
        return temp + 'GB'
      }
    }
	}
};
</script>

<style lang="less" scoped>
.file-upload {
	width: 100%;
	height: auto;
	padding: 10px;
  box-sizing: border-box;
	.upload-style {
		height: auto;
		.drag-text {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
	.upload-img-area {
		width: 100%;
		height: auto;
		margin: 10px 0;
		.upload-img-item {
      display: inline-block;
      width: 100px;
      height: 100px;
      text-align: center;
      line-height: 100px;
      border: 1px solid transparent;
      border-radius: 4px;
      overflow: hidden;
      background: #fff;
      position: relative;
      margin-right: 4px;
			cursor: pointer;
			img {
				width: 100%;
				height: 100%;
				cursor: pointer;
			}
      &:hover .del-image {
        display: inline-block;
      }
      .del-image {
        display: none;
				position: absolute;
				bottom: 0;
				right: 0;
				width: 100%;
				background: #d3d4d5;
				color: #fff;
				line-height: 16px;
      }
		}
	}
	.upload-files-area {
		.file-name {
			width: 100%;
			display: inline-block;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			width: calc(100% - 23px);
			vertical-align: middle;
		}
    .file-show {
      font-size: 12px;
      .file-item {
        display: flex;
        height: 30px;
        line-height: 30px;
        justify-content: space-between;
        &:hover .file-item-left{
            color: #087AFF;
        }
        &:hover .file-item-size{
          display: none;
        }
        &:hover .file-item-right{
          display: flex;
          justify-content: flex-end;
        }
        .file-item-left{
          flex: 1;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          .iconfont-svg {
            width: 16px;
            height: 16px;
            position: relative;
            top: 4px;
            background-color: transparent;
            margin-right: 5px;
          }
        }
        .file-item-right {
          width:87px;
          display: none;
          .file-item-view {
            color: #087AFF;
            padding-right: 3px;
            cursor: pointer;
            &:hover {
              color: #5da6fa;
            }
          }
          a {
            padding-right: 3px;
            &:hover {
              color: #5da6fa;
            }
          }
          .file-item-del {
            color: #ff0000;
            cursor: pointer;
            &:hover {
              color: #f54f4f;
            }
          }
        }
      }
    }
	}
	.no-data {
		padding: 0 20px;
		color: #909399;
	}
}
</style>
