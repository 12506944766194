<!--
 * @Description: 网盘顶部
 * @Author: luocheng
 * @Date: 2022-03-02 09:45:14
 * @LastEditors: luocheng
 * @LastEditTime: 2022-09-23 15:55:42
-->
<template>
  <div class="disk-header">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item v-for="(path, index) in pathList" :key="index" @click.native="onPath(path)">
        {{ path.label }}
      </el-breadcrumb-item>
    </el-breadcrumb>
    <section class="actions" v-if="actionConfig">
      <!-- <el-button class="upload-btn">
        <i class="iconfont iconwangpan-shangchuan1x"></i>
        上传文件
        <div class="file-upload-box">
        </div>
      </el-button> -->
      <FileUpload @extra-files="onUploadSuccess"
        :btnName=" actionConfig.upload && actionConfig.upload.name || '上传文件'"
        :multiple="statusConfig.multiple || false"
        v-if="showAction('upload')"></FileUpload>
      <el-dropdown trigger="click" placement="bottom"  v-if="showAction('create')">
        <el-button type="primary">
          <i class="iconfont iconwangpan-xinzeng1x"></i>
          {{ actionConfig.create && actionConfig.create.name }}
        </el-button>
        <el-dropdown-menu slot="dropdown">
           <!-- <el-dropdown-item>
            <el-button type="text">
              <i class="iconfont iconword1x" style="color: rgb(45, 133, 254)"></i>
              <span>Word</span>
            </el-button>
          </el-dropdown-item>
           <el-dropdown-item>
            <el-button type="text">
              <i class="iconfont iconexcle1x" style="color: rgb(48, 165, 92)"></i>
              <span>Excel</span>
            </el-button>
          </el-dropdown-item>
           <el-dropdown-item>
            <el-button type="text">
              <i class="iconfont iconppt1x" style="color: rgb(246, 123, 30)"></i>
              <span>PPT</span>
            </el-button>
          </el-dropdown-item> -->
          <el-dropdown-item>
            <el-button type="text" @click="showCreate = true">
              <i class="iconfont iconwangpan-wenjianjia11x" style="color: rgb(249, 194, 10)"></i>
              <span>文件夹</span>
            </el-button>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <div  v-if="showAction('plus')">
        <el-button type="default" style="height:36px;margin-right:20px" @click="showPlusFile = true">
          {{  actionConfig.plus && actionConfig.plus.name }}
        </el-button>
      </div>
    </section>
    <section class="search">
      <el-input
        placeholder="搜索文件名称"
        suffix-icon="el-icon-search"
        clearable
				@change="onSearch"
        v-model="searchName">
      </el-input>
    </section>
    <section class="view-ways">
      <i class="iconfont"
        v-for="item in modelList" :key="item.type"
        :class="[item.class, activeModel === item.type ? 'active' : '']" @click="onChangeModel(item)">
      </i>
    </section>
    <!-- 添加文件 -->
    <PlusFile v-if="showPlusFile" v-model="showPlusFile"></PlusFile>
    <!-- 创建文件夹 -->
    <Rename v-model="showCreate" :type="'createFolder'" @nameResult="onCreateFold"></Rename>
  </div>
</template>

<script>
import { Breadcrumb, BreadcrumbItem, Dropdown, DropdownItem, DropdownMenu } from 'element-ui';
import FileUpload from './FileUpload';
import PlusFile from './PlusFile';
import Rename from './Rename';
import { judgingArchiAPermission } from '@/utils/tools';
import { dataInterface } from '@/apis/data';
import mixin from '../mixin';

export default {
  name: 'DiskHeader',
  mixins: [mixin],
  props: {
    pathList: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  components: {
    'el-breadcrumb': Breadcrumb,
    'el-breadcrumb-item': BreadcrumbItem,
    'el-dropdown': Dropdown,
    'el-dropdown-menu': DropdownMenu,
    'el-dropdown-item': DropdownItem,
    FileUpload,
    PlusFile,
    Rename,
  },
  inject: ['element', 'getParentId'],
  data() {
    return {
      searchName: '', // 搜索名称
      showPlusFile: false, // 添加文件
      showCreate: false, // 新增文件夹
      // 显示模式
      activeModel: 'table',
      modelList: [
        {
          type: 'table',
          class: 'iconwangpan-liebiao1x'
        },
        {
          type: 'folder',
          class: 'iconwangpan-pingpu1x'
        }
      ]
    }
  },
  computed: {
    actionConfig() {
      return this.element?.actionConfig;
    },
    database() {
      return this.element?.database;
    },
    parentId() {
      return this.getParentId();
    },
    statusConfig() {
      return this.element?.statusConfig;
    }
  },
  methods: {
    /**
     * @desc: 是否显示操作
     * @param {String} type 操作类型
     * @return {Boolean}
     */
    showAction(type) {
      const item = this.actionConfig[type];
      if (!item) return false;
      const { use, archiLimit = [], permission = [] } = item;
      if (!use) {
        return false;
      }
      return judgingArchiAPermission(archiLimit, permission);
    },
    /**
     * @desc: 搜索
     */
    onSearch() {
      this.$emit('filterFile', this.searchName);
    },
    /**
     * @desc: 上传成功
     * @param {Object} data 上传成功的返回数据
     */
    onUploadSuccess(data) {
      const { param = [], canPost = false } = this.getQuery(this.actionConfig?.upload?.paramsConfig || []);
      if (!canPost) {
        return false;
      }
      this.modifyData({
        __method_name__: 'createData',
        parent_id: this.parentId || 0,
        name: data.name,
        path: data.filepath,
        hash: data.file_md5,
        file_size: data.filesize,
        ext: data.fileext,
        type_id: 2,
        ...param
      });
    },
    /**
     * @desc: 切换模式
     * @param {Object} model 选中模式
     */
    onChangeModel(model) {
      this.activeModel = model.type;
      this.$emit('changeModel', this.activeModel);
    },
    /**
     * @desc: 点击面包屑
     */
    onPath(item) {
      this.$emit('changeBreadcrumb', item);
    },
    /**
     * @desc: 创建文件提交
     */
    onCreateFold(name) {
      const { param = [], canPost = false } = this.getQuery(this.actionConfig?.create?.paramsConfig || []);
      if (!canPost) {
        return false;
      }
      this.modifyData({
        __method_name__: 'createData',
        name,
        type_id: 1,
        ...param
      });
    },
    /**
     * @desc: 创建数据或编辑数据
     * @param {Object} params 参数
     */
    modifyData(params) {
      const { objectData } = this.database;
      if (!objectData) {
        this.$message.error('操作失败！');
        return;
      }
      const loading = this.$loading();
      dataInterface({
        ...params,
        object_uuid: objectData.uuid,
        parent_id: this.parentId || 0,
      }).then(res => {
        if (res.status === 200 && res.data.code === 200) {
          this.$message.success('操作成功！');
          this.showCreate = false;
          this.$emit('updateList');
        }
        loading.close();
      }).catch(err => {
        console.log(err);
        this.$message.error('操作失败！');
        loading.close();
      })
    }
  }
}
</script>

<style lang="less" scoped>
@height: 46px;
.disk-header{
  min-height: @height;
  width: 100%;
  padding-bottom: 10px;
  display: flex;
  // justify-content: space-between;
  box-sizing: border-box;
  flex-wrap: wrap;
  :deep(.el-breadcrumb){
    margin-bottom: 10px;
    // flex: 1;
    width: auto;
    margin-right: 20px;
    // flex: auto;
    .el-breadcrumb__item{
      line-height: 36px;
      span{
        color: #000;
        font-weight: bold;
      }
      &:last-of-type{
        span{
          color: #606266;
          font-weight: 400;
        }
      }
    }
    .el-breadcrumb__separator{
      margin: 0 4px;
    }
  }
  .actions{
    display: flex;
    // margin-right: 20px;
    margin-bottom: 10px;
    :deep(.el-dropdown) {
      margin: 0 20px;
    }
  }
  .search{
    :deep(.el-input__inner){
      width: 320px;
      height: 36px;
      line-height: 36px;
    }
  }
  .view-ways{
    display: flex;
    box-sizing: border-box;
    padding: 0 12px;
    i{
      height: 36px;
      box-sizing: border-box;
      padding: 0 8px;
      line-height: 36px;
      font-size: 16px;
      color: rgb(189, 192, 199);
      &.active{
        color: rgb(51, 51, 51);
        font-size: 18px;
      }
    }
  }
}
.el-button.el-button--text{
  display: flex;
  span{
    i{
      font-size: 20px;
    vertical-align: middle;
    }
    span{
      margin-left: 3px;
      font-size: 14px;
      color: #606266;
      vertical-align: middle;
    }
  }
}
</style>