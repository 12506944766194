<template>
  <section class="pages-box">
    <header class="theme">
      <h4>页面</h4>
    </header>
    <div class="effective-box">
      有效率
      <div class="effective">{{ effective }}%</div>
    </div>
    <div class="total">
      全部页面
      <div class="tol">{{ statisticsList.allPage??0 }}</div>
    </div>
    <div class="pages-echarts" id="pages"></div>
  </section>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "Pages",
  components: {},
  props: {
    // 统计数据
    statisticsList: {
      type: [Object, null],
      required: true,
      default: () => null,
    },
  },
  data() {
    return {
      effective: null,
      newData: null,
    };
  },
  watch: {
    statisticsList(newVal) {
      this.newData = newVal;
      this.histograms();
    },
  },
  created() {},
  mounted() {},
  methods: {
    //根据value值 从大到小排序
    compare(property) {
      return function (a, b) {
        let value1 = a[property];
        let value2 = b[property];
        return value1 - value2;
      };
    },
    //柱状图
    histograms() {
      this.effective = (this.statisticsList.allPage && this.statisticsList.validPage) ? ((this.statisticsList.validPage / this.statisticsList.allPage) * 100).toFixed(0) : 0;
      this.chartDom = document.getElementById("pages");
      this.myChartHistogram = echarts.init(this.chartDom);
      let pieChartData = [
        { value: this.statisticsList.validPage??0, name: "有效页面" },
        {
          value: (this.statisticsList.allPage && this.statisticsList.validPage) ? (this.statisticsList.allPage - this.statisticsList.validPage): 0,
          name: "普通页面",
        },
      ];
      this.option = {
        tooltip: {
          left: "left",
          trigger: "axis",
        },
        legend: {
          itemWidth: 14,
          itemHeight: 14,
          itemGap: 23.5,
          selectedMode: true,
          top: "21.9%",
          left: "0.5%",
          orient: "vertical",
          formatter: (name) => {
            let data = pieChartData;
            let target;
            for (let i = 0, l = data.length; i < l; i++) {
              if (data[i].name == name) {
                target = data[i].value;
              }
            }
            let arr = ["{a|" + name + "}", "{b|" + target + "}"];
            return arr.join("\n");
          },
          textStyle: {
            rich: {
              a: {
                fontSize: 14,
                lineHeight: 24,
                fontWeight: 400,
                fontStyle: "normal",
                fontFamily: "Source Han Sans CN",
                color: "#73767A",
                padding: [25, 0, 0, 20],
              },
              b: {
                color: "#000000",
                fontFamily: "Source Han Sans CN",
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: 18,
                lineHeight: 24,
                padding: [28, 0, 0, 20],
              },
            },
          },
        },
        grid: {
          left: "25%",
          right: "0",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: [
              "大屏",
              "H5页面",
              "中控台页面",
              "页面模板",
              "常规页面",
              "第二屏页面",
              "循环页面",
              "图表页面",
            ],
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#ADD4F9",
              },
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#333333",
                fontFamily: "Source Han Sans CN",
                fontStyle: "normal",
                fontWeight: 400,
                fontsize: 14,
                lineHeight: 24,
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "有效页面",
            type: "bar",
            barWidth: 12,
            barGap: "60%",
            barCategoryGap: "50%",
            itemStyle: {
              color: "#C6E2FF",
              barBorderRadius: [5, 5, 5, 5],
            },
            label: {
              normal: {
                show: true,
                position: "top",
              },
            },
            data: [
              this.statisticsList.validDataView??0,
              this.statisticsList.validH5View??0,
              this.statisticsList.validConsoleView??0,
              this.statisticsList.validTemplateView??0,
              this.statisticsList.validPageView??0,
              this.statisticsList.validSecondView??0,
              this.statisticsList.validCardView??0,
              this.statisticsList.validChartView??0,
            ],
          },
          {
            name: "普通页面",
            type: "bar",
            barWidth: 12,
            itemStyle: {
              color: "#409EFF",
              barBorderRadius: [5, 5, 5, 5],
            },
            label: {
              normal: {
                show: true,
                position: "top",
              },
            },
            data: [
              this.statisticsList.dataView??0,
              this.statisticsList.h5View??0,
              this.statisticsList.consoleView??0,
              this.statisticsList.templateView??0,
              this.statisticsList.pageView??0,
              this.statisticsList.secondView??0,
              this.statisticsList.cardView??0,
              this.statisticsList.chartView??0,
            ],
          },
        ],
      };
      this.option && this.myChartHistogram.setOption(this.option);
    },
  },
};
</script>

<style lang="less" scoped>
@media screen and (max-width: 1346px) {
  .effective-box {
    padding-left: 20px;
    box-sizing: border-box;
  }
  .total {
    padding-left: 22px;
    box-sizing: border-box;
  }
}
.pages-box {
  position: relative;
  width: 80.4%;
  height: 100%;
  padding: 16px 0 0 20px;
  box-sizing: border-box;
  background: #fff;
  .theme {
    position: absolute;
    top: 20px;
    left: 16px;
    color: rgb(68, 151, 254);
    font-size: 17px;
    font-weight: bold;
    line-height: 22px;
    text-align: left;
    h4 {
      padding-left: 10px;
      box-sizing: border-box;
    }
  }
  .pages-echarts {
    width: 100%;
    height: 100%;
  }
  .total {
    position: absolute;
    top: 193px;
    left: 14%;
    color: #666666;
    font-family: "Source Han Sans CN";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    .tol {
      color: #000000;
      font-family: "Source Han Sans CN";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
    }
  }
  .effective-box {
    position: absolute;
    top: 103px;
    left: 14%;
    color: #666666;
    font-family: "Source Han Sans CN";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    .effective {
      color: #95d475;
      font-family: "Source Han Sans CN";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
    }
  }
}
</style>
