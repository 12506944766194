<!--
    @name: widget-signature
    @description：widget-signature
    @author: ZengWei
    @date: 2022-04-11 18:29
-->
<template>
  <div>
    <template v-if="element.config.__config__.signType === 1">
      <div>
        <el-popover placement="right" width="100">
          <el-button slot="reference" @click="generateQrcode">
            <i class="iconfont iconfenbaoshenhe"></i>&nbsp;&nbsp;&nbsp;手写签名
          </el-button>
          <div style="text-align: center">
            <img v-if="qrCode" :src="qrCode" alt width="100" height="100" />
            <p>请打开手机扫一扫</p>
          </div>
        </el-popover>
        <el-button
          v-if="element.config.__config__.preSign"
          style="margin-left: 15px"
          type="text"
          @click="getUserSignature">
          使用预设签名
        </el-button>
      </div>
      <div class="pre-signature">
        <div class="pre-signature-item" v-for="(item,index) in element.value" :key="index">
          <i v-if="!disabled" class="iconfont icongis_shanchu remove" @click="removeSign(index)"></i>
          <el-image
            style="width: 100px; height: 100px;margin: 15px"
            :src="item.signature"
            fit="contain"
            :preview-src-list="[item.signature]">
          </el-image>
          <p style="text-align: center">{{item.datetime}}</p>
        </div>
      </div>
    </template>

    <template v-else>
      <div>
        <el-button type="primary" @click="viewSign">
          <i class="iconfont iconfenbaoshenhe"></i>&nbsp;&nbsp;&nbsp;使用签章
        </el-button>
        <div class="pre-signature">
          <div class="pre-signature-item" v-for="(item,index) in element.value" :key="index">
            <i v-if="!disabled" class="iconfont icongis_shanchu remove" @click="removeSign(index)"></i>
            <el-image
              style="width: 100px; height: 100px;margin: 5px"
              :src="item.signature"
              :preview-src-list="[item.signature]">
            </el-image>
            <p style="text-align: center">{{item.datetime.substr(0, 15)}}</p>
          </div>
        </div>
      </div>
    </template>

    <el-dialog
      title="我可用的签章"
      :custom-class="'common-dialog'"
      :visible.sync="dialogVisible"
      top="5vh"
      width="790px"
      append-to-body
      @close="beforeDialogClose"
    >
      <article class="dialog-main" v-loading="loading">
        <div class="sign-img" v-if="confirmType === 1">
          <div
            class="sign-img-item"
            v-for="(item,index) in sealList"
            :key="index"
            :class="{active: activeIndex === index}"
            @click="activeSign(item,index)">
            <el-image
              style="width: 100px; height: 100px"
              :src="item.picture">
            </el-image>
            <p class="sign-name">{{item.seal_name}}</p>
          </div>
        </div>
        <div style="height: 100%;text-align: center;display:flex;flex-direction: column;align-items: center;justify-content: center;" v-if="confirmType === 2 && element.config.__config__.validType === 3">
          <div class="info-wrap">
            <template v-if="!isScan && !faceVerify">
              <div class="qr-wrap">
                <img v-if="qrCode" :src="qrCode" alt width="150" height="150" />
                <div class="expired-info" v-if="qrStatus === -1">
                  <p>二维码已失效</p>
                  <br>
                  <p>
                    <el-button size="mini" type="primary" @click="generateQrcode(2)">
                      刷新
                    </el-button>
                  </p>
                </div>
              </div>
              <p style="margin-top: 15px">请打开手机扫一扫，进行人脸识别验证</p>
            </template>
            <template v-else-if="isScan && !faceVerify" >
              <p>
                <svg t="1669084680465" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="38559" width="200" height="200"><path d="M466.752 774.528a48.192 48.192 0 0 1-8.448-6.208l-190.72-190.848a48 48 0 1 1 67.84-67.84l145.088 145.088 235.136-407.232a48 48 0 1 1 83.136 48L531.84 757.76l-3.712 5.248-3.456 3.84-2.24 2.048-3.84 3.2-4.224 2.56-4.352 2.24-2.048 0.832a47.744 47.744 0 0 1-41.28-3.2z" fill="#1afa29" p-id="38560"></path></svg>
              </p>
              <p style="margin-top: 15px">扫码成功,请在手机上确认操作。</p>
            </template>
            <template v-else>
              <p>
                <svg t="1669084576819" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="38179" width="120" height="120"><path d="M512 128v64H192v640h640V512h64v384H128V128h384z m290.304 120.256l45.248 45.248-362.048 362.048-181.056-181.056 45.248-45.248 135.808 135.744 316.8-316.8z" fill="#1afa29" p-id="38180"></path></svg>
              </p>
              <p style="margin-top: 15px">验证成功,请点击确认进行下一步操作</p>
            </template>
          </div>
          
        </div>
        <div v-if="confirmType === 2 && element.config.__config__.validType === 2">
          <h5>手机短信验证码验证</h5>
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="76px">
            <el-form-item label="验证码：" prop="code" style="margin: 20px 0">
              <el-input v-model="ruleForm.code" style="width: 150px;margin-right: 25px"></el-input>
              <el-button>发送验证码</el-button>
            </el-form-item>
          </el-form>
        </div>
      </article>
      <div slot="footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" v-if="confirmType === 1" @click="confirm">下一步</el-button>
        <el-button type="primary" v-if="confirmType === 2" @click="confirmSign">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="手机短信验证码验证"
      :custom-class="'common-dialog'"
      :visible.sync="confirmVisible"
      width="378px"
      append-to-body
    >
      <article style="padding: 0 15px;">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="76px">
          <el-form-item label="验证码：" prop="code" style="margin: 20px 0">
            <el-input v-model="ruleForm.code" style="width: 150px;margin-right: 25px"></el-input>
            <el-button>发送验证码</el-button>
          </el-form-item>
        </el-form>
      </article>
      <div slot="footer">
        <el-button @click="confirmVisible = false">取消</el-button>
        <el-button type="primary" @click="confirmSign">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {Image,Popover} from "element-ui";
import { formRequest } from "@/apis/data/form";
import { baseUrl } from '@/apis/http/request';
import {getNowTime} from "@/utils/tools";

export default {
  name: "WidgetSignature",
  components: {
    'el-image': Image,
    'el-popover': Popover,
  },
  props: ['element', 'disabled', 'displayData'],
  data() {
    return {
      loading: false,
      qrCode: '',
      timer: null,
      qrToken: "",
      confirmType: 1,
      faceVerify: "", // 人脸认证标识
      dialogVisible: false,
      confirmVisible: false,
      activeIndex: '',
      activeData: '',
      sealList:[],
      isScan: false,
      qrStatus: 0, // 二维码状态 0 初始化，1已加载，-1 已过期
      ruleForm:{
        code: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入短信验证码', trigger: 'blur' },
        ]
      }
    }
  },
  mounted() {
  },
  methods: {
    activeSign(item,index){
      this.activeIndex = index
      const data = {
        signature: item.picture,
        datetime: getNowTime(2),
        type: 'seal'
      }
      if(item.jw_seal_id){
        data.jw_seal_id = item.jw_seal_id
      }
      this.activeData = data
    },
    viewSign(){
      this.dialogVisible = true
      this.loading = true
      this.confirmType = 1
      formRequest('get', 'api/useSignSeal/getUserSeal',{})
        .then(res=>{
          if(res.data.code === 200){
            const data = res.data.data
            for (let item of data){
              let seal = JSON.parse(item.picture)
              item.picture = seal[0].filepath
            }
            this.sealList = data
            this.loading = false
          }
        })
    },
    confirm(){
      const validType = this.element.config.__config__.validType
      if(validType === 1) {
        this.element.value.push(this.activeData)
        this.dialogVisible = false
      } else if([2,3].includes(validType)) {
        this.confirmType = 2
        if(validType === 3){
          this.generateQrcode(2)
        }
      }
    },
    confirmSign(){
      if(!this.faceVerify){
        this.$message.error('请完成人脸识别后，操作')
        return
      }
      if(this.faceVerify){
        this.activeData.face_tick = this.faceVerify
      }
      this.element.value = [this.activeData]
      this.dialogVisible = false
    },
    removeSign(index){
      this.element.value.splice(index,1)
    },
    getUserSignature(){
      const url = '/api/v4/user/signature'
      formRequest('get', url ,{})
        .then(res=>{
          if (res.data.code === 200) {
            if (res.data.data !== null || res.data.data !== '') {
              this.signImg = baseUrl+'/'+res.data.data;
              this.element.value.push(this.signImg);
            } else {
              this.$message.error('您暂未预设签名，请到个人中心设置签名后使用')
            }
          }
        })
    },
    generateQrcode(type = 1) {
      if(!this.qrCode){
        clearInterval(this.timer);
        const url = '/api/generate/signature'
        const data = {}
        if(type === 2) data.type = 2
        formRequest('post', url ,data)
          .then(res=>{
            if (res.data.code === 200) {
              this.qrCode = res.data.data.qrcode;
              this.qrToken = res.data.data.token;
              this.qrStatus = 1
              let that = this;
              this.timer = setInterval(() => {
                that.getAppSignature();
              }, 1000);
            }
          })
      } else {
        // 当第二次进入不会再轮询，所以这里处理一次，保险起见第二次进入依旧查询
        clearInterval(this.timer);
        let that = this;
        this.timer = setInterval(() => {
          that.getAppSignature();
        }, 1000);
      }
    },
    getAppSignature() {
      const data = {qrToken: this.qrToken}
      const url= '/api/generate/signature'
      formRequest('get', url, data)
        .then((res) => {
          if (res.data.code === 200) {
            // 某些标志位,判断二维码是否过期,这里暂时不处理
            // if () {
            //   clearInterval(this.timer);
            //   this.qrStatus = -1
            // }
            this.isScan = !!res.data.data.is_scan
            this.signImg = res.data.data.signature;
            if(res.data.data?.face_verify){
              this.faceVerify = res.data.data?.face_verify
              clearInterval(this.timer);
            }
            if (this.signImg !== null) {
              const data = {
                signature: this.signImg,
                datetime: getNowTime(2),
                type: 'signature'
              }
              this.element.value.push(data);
              clearInterval(this.timer);
            }
          }
        });
    },
    // 在弹窗结束前关掉定时函数
    beforeDialogClose() {
      if (this.timer) {
        clearInterval(this.timer);
      }
    }
  },
}
</script>

<style lang="less" scoped>
:deep(.common-dialog)  {
  .el-dialog__body{
    padding: 0;
  }
}

.dialog-main{
  padding: 0 15px;
  height: 500px;
  overflow-y: auto;
}

.sign-img{
  display: flex;
  flex-wrap: wrap;

  .sign-img-item{
    width: 100px;
    padding: 15px;
    margin: 10px;
    border: 1px solid #fff;
    border-radius: 5px;

    .sign-name{
      margin-top: 10px;
      text-align: center;
    }
  }

  .active{
    border: 1px solid #eeeeee !important;
    border-radius: 5px;
  }
}
.info-wrap{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .qr-wrap{
    width: 150px;
    height: 150px;
    position: relative;
    img{
      width: 100%;
      height: 100%;
    }
    .expired-info{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,.8);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: #fff;
    }
  }
}
.pre-signature{
  display: flex;
  flex-wrap: wrap;
}
.pre-signature-item{
  position: relative;
  margin: 8px;
}

.remove{
  position: absolute;
  right: 0;
  top: -8px;
  z-index:100;
}

</style>
