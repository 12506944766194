
<!--
    @name: skeleton
    @description：skeleton
    @author: ZengWei
    @date: 2021-11-25 13:40
-->
<template>
	<!-- eslint-disable -->
		<el-skeleton animated >
			<template slot="template">
				<div v-for="n in 3" :key="n" style="display: flex; align-items: center; justify-items: space-between;margin-top: 15px;">
					<el-skeleton-item variant="text" style="height:35px;width:120px;margin-right: 16px;" />
					<el-skeleton-item variant="text" style="height:35px;flex: 1;" />
				</div>
				<div style="display: flex; align-items: center; justify-items: space-between;margin-top: 15px;">
					<el-skeleton-item variant="text" style="height:35px;width:120px;margin-right: 16px;" />
					<el-skeleton-item variant="text" style="height:35px;width: 50%;" />
				</div>
				<el-skeleton-item v-for="n in 2" :key="n" variant="text" style="height:35px;margin-top:15px;width: 100%;" />
				<div style="display: flex; align-items: center; justify-items: space-between;margin-top: 15px;">
					<el-skeleton-item variant="text" style="height:35px;width:120px;margin-right: 16px;" />
					<el-skeleton-item variant="text" style="height:35px;width: 70%;" />
				</div>
				<el-skeleton-item v-for="n in 2" :key="n" variant="text" style="height:35px;margin-top:15px;width: 100%;" />
				<div style="margin-top: 15px">
					<el-skeleton-item variant="text" style="height:35px;width: 100%;" />
					<div v-for="n in 3" style="display: flex; align-items: center; justify-items: space-between;margin-bottom: 5px">
						<el-skeleton-item variant="text" style="height:35px;width:120px;margin-right: 16px;" />
						<el-skeleton-item variant="text" style="height:35px;width:30%;margin-right: 16px;" />
						<el-skeleton-item variant="text" style="height:35px;width:30%;margin-right: 16px;" />
						<el-skeleton-item variant="text" style="height:35px;width:20%;margin-right: 16px;" />
						<el-skeleton-item variant="text" style="height:35px;flex: 1;" />
					</div>
				</div>
				<div style="display: flex; align-items: center; justify-items: space-between;margin-top: 15px;">
					<el-skeleton-item variant="text" style="height:35px;width:120px;margin-right: 16px;" />
					<el-skeleton-item variant="text" style="height:35px;width: 38%;" />
				</div>
				<el-skeleton-item variant="text" style="height:35px;margin-top:15px;width: 100%;" />
			</template>
		</el-skeleton>
	</template>
	
	<script>
	import { Skeleton, SkeletonItem } from 'element-ui'
		export default {
			name: "skeleton",
			components: {
			'el-skeleton': Skeleton,
			'el-skeleton-item': SkeletonItem
		},
			props: {
				formData: {
					type:Object,
					default:()=>{
						return {}
					}
				}
			},
			data() {
				return {}
			},
			methods: {},
		}
	</script>
	
	<style scoped>
	
	</style>
	