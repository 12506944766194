<!--
 * @Author: your name
 * @Date: 2022-04-24 09:17:17
 * @LastEditTime: 2022-04-24 18:00:03
 * @LastEditors: Please set LastEditors
 * @Description: 绑定经纬度
 * @FilePath: \dataview-viewer-test\src\components\bindLatLng\ButtonType.vue
-->
<template>
    <!-- 绑定图纸 -->
    <div>
			<slot name="showType"></slot>
			<el-drawer
				custom-class="no-padding"
				:visible.sync="show"
				size="100%"
				append-to-body
				:with-header="false"
			>
					<BindLat :modelData="modelData" v-if="show"	@back="show = false"	@set-data="setData"	/>
			</el-drawer>
		</div>
</template>

<script>
import BindLat from "./index.vue";
import {Drawer} from "element-ui";
export default {
    name: "BindLatButton",
    components: { 
			BindLat,
			'el-drawer': Drawer,
		},
    props: {
			modelData:{
				type:Object,
				default:null
			}
    },
		data() {
			return {
				show: false
			};
		},
		methods: {
			setData(data,info) {
				this.$emit("set-data", data, info);
				this.show = false;
			}
		}
		
};
</script>
