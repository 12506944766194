/*
 * @Author: zx
 * @Date: 2022-09-21 10:36:55
 * @LastEditTime: 2022-09-27 09:51:53
 * @Description: 
 * @LastEditors: 朱廷果 1028509503@qq.com
 */
import Home from '@/manage-views/views/home/home'
const baseRoutes = [
  /* 数仓整个路由 */
  {
    path: '/dwh/objects',
    component: Home,
    children: [
      {
        path: '/dwh/objects/fields',
        name: 'fieldsManage',
        component: () => import('@/views/objects/fieldsManage.vue'),
        meta: {
          title: '对象字段管理器',
          icon: 'home',
        },
      },
      {
        path: '/dwh/objects/list',
        name: 'objectList',
        component: () => import('@/views/objects/list.vue'),
        meta: {
          title: '数据对象列表',
          icon: 'home',
        },
      },
      {
        path: '/dwh/objects/transit_out',
        name: 'transit_out_page',
        component: () => import('@/views/transit_page.vue')
      },
      {
        path: '/dwh/objects/apps',
        name: 'objectsApps',
        component: () => import('@/views/objects/apps.vue'),
        meta: {
          title: '数据对象列表',
          icon: 'home',
        },
      },
      {
        path: '/dwh/objects/page-editor',
        name: 'pageEditor',
        component: () => import('@/views/objects/pageEditor.vue'),
        meta: {
          title: '页面编辑器',
          icon: 'home',
        },
      },
      {
        path: '/dwh/objects/global-func',
        name: 'globalFuncSetting',
        component: () => import('@/views/objects/globalFunc.vue'),
        meta: {
          title: '全局函数配置',
          icon: 'home',
        },
      },
      {
        path: '/dwh/objects/add',
        name: 'objectAdd',
        component: () => import('@/views/objects/add.vue'),
        meta: {
          title: '数据对象列表',
          icon: 'home',
        },
      },
      {
        path: '/dwh/objects/detail',
        name: 'objectDetail',
        component: () => import('@/views/objects/detail.vue'),
        meta: {
          title: '数据对象详情',
          icon: 'home',
        },
      },
      {
        path: '/dwh/objects/design-form',
        name: 'designForm',
        component: () => import('@/views/objects/components/designForm.vue'),
        meta: {
          title: '可视化表单',
          icon: 'home',
        },
      },
      {
        path: '/dwh/objects/sub-forms',
        name: 'subForms',
        component: () => import('@/views/formDesign/subForm/index.vue'),
        meta: {
          title: '子表单管理',
          icon: 'home',
        },
      },
      {
        path: '/dwh/objects/valueclasses',
        name: 'valueClasses',
        component: () => import('@/views/objects/valueClasses.vue'),
        meta: {
          title: '取值类函数',
          icon: 'home',
        },
      },
      {
        path: '/dwh/objects/behaviorclasses',
        name: 'behaviorClasses',
        component: () => import('@/views/objects/behaviorClasses.vue'),
        meta: {
          title: '取值类函数',
          icon: 'home',
        },
      },
      {
        path: '/dwh/objects/timed-task',
        name: 'timedTask',
        component: () => import('@/views/objects/timedTask.vue'),
        meta: {
          title: '定时任务',
          icon: 'home',
        },
      },
    ],
  },
]
export default baseRoutes
