<!--
 * @Description: 环图
 * @Author: luocheng
 * @Date: 2022-01-10 18:48:33
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-01-03 10:00:45
-->
<template>
	<div
		class="chart-ring"
		:style="{
			height: height + 'px'
		}"
		v-loading="getting"
	>
		<ChartContainer
			v-if="option"
			ref="chartInstance"
			class="chart-pie"
			:option="option"
		></ChartContainer>
		<el-empty
			description="暂无数据"
			v-else
		></el-empty>
	</div>
</template>

<script>
/* eslint-disable */
import mixin from './mixin';
import eventBus from '@/plugins/eventBus';

export default {
	name: 'ChartRing',
	mixins: [mixin],
	data() {
		return {
			statisticalData: null,
			option: null
		};
	},
	created() {
		this.getOptions();
	},
	mounted() {
		this.$nextTick(() => {
			eventBus.$on('refreshChart', () => {
				this.option = null;
				this.getOptions();
			});
			eventBus.$on('databaseTrigger', config => {
				if (!config || !this.paramsConfig.length || !this.paramsConfig.find(ele => ele.componentId === config.componentId)) {
					return;
				}
				this.option = null;
				this.getOptions();
			});
		});
	},
	methods: {
		/**
		 * @desc: 设置配置项
		 * @param {*} data
		 * @return {*}
		 */
		setOptions(data) {
			const { attributes = {} } = this.chartData;
			const {
				showTitle = true,
				title = '',
				textColor = '',
				titleFontSize = 12,
				titleFontWeight = 400,
				showTooltip = true,
				showLegend = true,
				legendTextColor = '#000000',
				colorRange = [],
				lessColor = [],
				legendIcon = 'circle',
				withBorder = false,
        barWidth = 15,
				clockwise = false,
				centerFontColor = '#2EBF76',
				centerFontSize = 24,
				centerFontTop = 42,
				descType = 'percent',
				legendOrient = 'vertical',
				legendType = 'plain',
				titleTop = 'auto',
				titleBottom = 'auto',
				titleRight = 'auto',
				titleLeft = 'auto',
				left = '10%',
				right = '10%',
				top = 60,
				bottom = 60,
			} = attributes;
			let legendTop = '20%';
			const chartPie = this.$refs && this.$refs.chartInstance;
			if (chartPie) {
				legendTop = (chartPie.offsetHeight - data.length * (this.scaleSize(legendSize + 10))) / 2;
				if (legendTop < 10) {
					legendTop = 10;
				}
			}
			const descText = this.getDescText(descType, data);
			this.option = {
				title: [
					{
						show: showTitle,
						text: title,
						left: 'left',
						y: 'top',
						textStyle: {
							color: textColor,
							fontFamily: '微软雅黑',
							fontSize: this.scaleSize(titleFontSize || 12),
							fontWeight: titleFontWeight || 400,
							left: titleLeft,
							top: titleTop,
							bottom: titleBottom,
							right: titleRight
						}
					},
					{
						text: descText,
            x: "center",
            top: `${centerFontTop}%`,
            textStyle: {
              color: centerFontColor,
              fontFamily: "DIN, DIN-Medium",
              fontSize: this.scaleSize(centerFontSize),
              fontWeight: 500,
            },
					}
				],
				grid: {
					left,
					right,
					bottom,
					top,
					containLabel: true
				},
				tooltip: {
					show: showTooltip,
					trigger: 'axis',
					axisPointer: {
						type: 'line'
					},
					formatter: '{a} {c}'
				},
				legend: {
					show: showLegend,
					// orient: 'vertical',
					// right: 'left',
					// top: legendTop || '20%', // 改为通过计算上下居中
					// right: '5%',
					itemWidth: 10,
					itemHeight: 10,
					icon: legendIcon,
					textStyle: {
						color: legendTextColor,
						fontSize: this.scaleSize(12)
					},
					formatter: function (name) {
						return `${name} ${data.process ?? 0}`;
					},
					...this.getLegendPosition(attributes),
					type: legendType,
					orient: legendOrient,
				},
				angleAxis: {
					max: data.totalData ||  0,
					clockwise, // 逆时针
          show: false // 隐藏刻度线
				},
				radiusAxis: {
					type: "category",
					show: true,
					axisLabel: {
						show: false
					},
					axisLine: {
						show: false
					},
					axisTick: {
						show: false
					}
				},
				polar: {
					center: ["50%", "50%"],
					radius: "150%" //图形大小
				},
				color: colorRange || [],
				series: [
					{
						name: data?.names?.[0] || '',
						type: "bar",
						data: [data.process || 0],
						showBackground: true,
						backgroundStyle: {
								color: lessColor && lessColor.length
												? lessColor[0]
												: "rgba(0,0,0,0.45)",
								borderColor:
										colorRange && colorRange.length
												? colorRange[colorRange.length - 1]
												: "#00D6BF",
								borderWidth: withBorder ? 1 : 0
						},
						coordinateSystem: "polar",
						roundCap: true,
						barWidth,
						silent: true,
						itemStyle: {
							color: {
								x: 0,
								y: 0,
								x2: 0,
								y2: 1,
								type: "linear",
								global: false,
								colorStops: this.handleColorRange(colorRange)
							}
						},
					}
				]
			};
		},
		// 处理颜色
		handleColorRange(array) {
				if (Array.isArray(array) && array.length > 0) {
						let rate = Number(1 / (array.length - 1));
						let list = [];
						let offset = 0;
						array.forEach((item, index) => {
								if (index !== 0) {
										offset = offset + rate;
								}
								list.push({
										offset,
										color: item
								});
						});
						return list;
				} else {
						return [
								{ offset: 0, color: "#B0EFF7" },
								{ offset: 1, color: "#51E3F7" }
						];
				}
		},
		/**
		 * @desc: 获取描述文本
		 * @param {String} type 描述类型
		 * @param {Object} data 数据
		 */
		getDescText(type, data) {
			if (type === 'none') return '';
			if (type === 'percent') {
				if (+data.totalData === 0) return '0.00%';
				return `${((data.process / data.totalData) * 100).toFixed(2)}%`
			}
			if (type === 'completed_total') {
				return `${data.process}/${data.totalData}`
			}
			if (type === 'completed') {
				return data.process || 0;
			}
		}
	},
	beforeDestroy() {
		eventBus.$off('refreshChart');
    eventBus.$off('databaseTrigger');
		console.log('销毁', '1313131313')
	}
};
</script>

<style lang="less" scoped>
.chart-ring {
	height: 100%;
	width: 100%;
	:deep(.el-empty){
		padding: 0;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
		.el-empty__image{
			width: 35%;
			max-width: 120px;
			min-width: 50px;
		}
		.el-empty__description{
			margin-top: 15px;
		}
		.page-container p{
			color: #fff;
		}
	}
}
</style>
