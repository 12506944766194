import request from '@/apis/http/saasAuthRequest.js'
export const mapi = (data, url = 'api/mapi', method = 'post') => {
  return request({
    url: url,
    method: method,
    data
  })
}

export const konwledgeCollectionAPI = (data) => {
  return request({
    url: 'api/saas/fabulous',
    method: 'post',
    data
  })
}

export const KnowledgeList = (data) => {
  return request({
    url: 'api/saas/repositoryList',
    method: 'post',
    data
  })
}

export const knowledgeDetail = (data) => {
  return request({
    url: 'api/saas/repositoryDetail',
    method: 'post',
    data
  })
}

export const fileDetails = (data) => {
  return request({
    url: 'api/saas/fileDetail',
    method: 'post',
    data
  })
}

export const getKonwledgeDynamic = (data) => {
  return request({
    url: 'api/saas/repositoryRecord',
    method: 'post',
    data
  })
}

export const getFileDynamic = (data) => {
  return request({
    url: 'api/saas/fileRecord',
    method: 'post',
    data
  })
}

export const addKnowledge = (data) => {
  return request({
    url: 'api/saas/addKnowledge',
    method: 'post',
    data
  })
}

export const updateKnowledge = (data) => {
  return request({
    url: 'api/saas/updateKnowledge',
    method: 'post',
    data
  })
}

export const officialKnowledgeDetail = (data) => {
  return request({
    url: 'api/saas/officialRepositoryDetail',
    method: 'post',
    data
  })
}

export const officialKileDetails = (data) => {
  return request({
    url: 'api/saas/officialFileDetail',
    method: 'post',
    data
  })
}

export const getMyCollect = (data) => {
  return request({
    url: 'api/saas/myRepositoryCollect',
    method: 'post',
    data
  })
}

export const getMyRepository = (data) => {
  return request({
    url: 'api/saas/myRepository',
    method: 'post',
    data
  })
}

export const getUsers = (data) => {
  return request({
    url: 'api/saas/admin/user/departmentUser',
    method: 'post',
    data
  })
}

export const getMyfile = (data) => {
  return request({
    url: 'api/saas/myFile',
    method: 'post',
    data
  })
}

export const deleteKonwledge = (data) => {
  return request({
    url: 'api/saas/repositoryDel',
    method: 'post',
    data
  })
}

export const knowledgeType = {
  get(params) {
    return request({
      url: 'api/saas/admin/knowledgeType',
      method: 'get',
      params
    })
  },
  post(data) {
    return request({
      url: 'api/saas/admin/knowledgeType',
      method: 'post',
      data
    })
  },
  put(data) {
    if(!data.id) return
    return request({
      url: `api/saas/admin/knowledgeType/${data.id}`,
      method: 'put',
      data
    })
  },
  delete(data) {
    if(!data.id) return
    return request({
      url: `api/saas/admin/knowledgeType/${data.id}`,
      method: 'delete'
    })
  }
}

export const knowledgePage = {
  details(data) {
    if(!data.id) return
    return request({
      url: `api/saas/admin/KnowledgePage/${data.id}`,
      method: 'get',
    })
  },
  get(params) {
    return request({
      url: 'api/saas/admin/KnowledgePage',
      method: 'get',
      params
    })
  },
  post(data) {
    return request({
      url: 'api/saas/admin/KnowledgePage',
      method: 'post',
      data
    })
  },
  put(data) {
    if(!data.id) return
    return request({
      url: `api/saas/admin/KnowledgePage/${data.id}`,
      method: 'put',
      data
    })
  },
  delete(data) {
    if(!data.id) return
    return request({
      url: `api/saas/admin/KnowledgePage/${data.id}`,
      method: 'delete'
    })
  }
}

export const knowledgePageWebSite = {
  details(data) {
    if(!data.id) return
    return request({
      url: `api/saas/KnowledgePage/${data.id}`,
      method: 'get',
    })
  },
  get(params) {
    return request({
      url: 'api/saas/KnowledgePage',
      method: 'get',
      params
    })
  }
}


export const knowledgeComment = {
  websiteGet(params) {
    return request({
      url: 'api/saas/KnowledgeComment',
      method: 'get',
      params
    })
  },
  get(params) {
    return request({
      url: 'api/saas/admin/KnowledgeComment',
      method: 'get',
      params
    })
  },
  post(data) {
    return request({
      url: 'api/saas/admin/KnowledgeComment',
      method: 'post',
      data
    })
  },
  put(data) {
    if(!data.id) return
    return request({
      url: `api/saas/admin/KnowledgeComment/${data.id}`,
      method: 'put',
      data
    })
  },
  delete(data) {
    if(!data.id) return
    return request({
      url: `api/saas/admin/KnowledgeComment/${data.id}`,
      method: 'delete'
    })
  }
}

export const repositoryShareDetail = (data) => {
  return request({
    url: '/api/saas/repositoryShareDetail',
    method: 'post',
    data
  })
}

export const repositoryShare = (data) => {
  return request({
    url: '/api/saas/repositoryShare',
    method: 'post',
    data
  })
}

export const repositoryLoginDetail = (data) => {
  return request({
    url: '/api/saas/repositoryLoginDetail',
    method: 'post',
    data
  })
}

export const repositoryShareLogin  = (data) => {
  return request({
    url: '/api/saas/repositoryShareLogin ',
    method: 'post',
    data
  })
}

export const repositoryPage = (uuid,key) => {
  return request({
      url: 'api/saas/repositoryPage/' + uuid + '/' + key,
      method: 'get'
  })
}
export const repositoryPageWap = (uuid,key, params) => {
  return request({
      url: 'api/saas/repositoryPageWap/' + uuid + '/' + key,
      method: 'get',
      params
  })
}
export const postReq = (url, data, header = {}) => {
  return request({
    url,
    data,
    method: 'post',
    header
  })
}

export const createPostReq = (data, url = 'api/mapi', method = 'post') => request({
  url,
  method,
  data: method === 'post' ? data : {},
  params: method === 'post' ? {} : data
})