<!--
 * @Author: 冉桂精 156189868@qq.com
 * @Date: 2022-11-08 17:16:07
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2022-11-09 10:49:18
 * @FilePath: /dataview-next/src/custom-component/container/CommonPageContainer.vue
 * @Description: 配置页面容器
-->
<template>
  <div class="common-page-container">
    <PageParser :pageUUID="pageId" v-if="pageId"></PageParser>
  </div>
</template>

<script>
  export default {
    name: 'CommonPageContainer',
    props: {
      element: {
        type: Object,
        isRequired: true,
        default: () => {}
      }
    },
    computed: {
      pageId() {
        return this.element?.contentConfig?.pageId;
      }
    }
  }
</script>

<style lang="less" scoped>
.common-page-container {
  height: 100%;
  width: 100%;
}
</style>