/*
 * @Author: zx
 * @Date: 2022-05-31 14:14:04
 * @LastEditTime: 2022-12-07 09:10:27
 * @Description: 
 * @LastEditors: zx
 */
// 业务组件
const functionCom = {
	type: 'functionCom',
	title: '业务组件',
	show: true,
	componentList: [
		{
			component: 'RouteTop',
			type: 'function',
			name: '顶部路由',
			icon: 'icona-kanbantongjifill',
			isLock: false, // 是否锁定
			needCommonStyle: false,
			propValue: '标题',
			style: {
				height: 'auto',
				width: '100%',
				lineHeight: '',
				rotate: 0,
				marginTop: 0,
				marginBottom: 0,
				marginRight: 0,
				marginLeft: 0,
				paddingLeft: 0,
				paddingRight: 0,
				paddingTop: 0,
				paddingBottom: 0
			},
			actionConfig: {
				needAction: true,
				rowClick: {
					use: true,
					useDepend: false,
					dependLogic: [],
					type: 'rowClick',
					name: '返回点击',
					color: '#409EFF',
					btnType: 'text',
					btnSize: '',
					margin: 5,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				}
			},
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
				disabled: false,
        // 顶部路由对应的开关
        showTitle: true,
        showSearch: true,
        mobileSearchInput: true
			},
			titleConfig:{
				topTitleType:'onlyTitle',
        title:'标题',
        tabs:[
          {
            tab:'',
            title:'',
            targetComponent:'',
            isDefault:false
          }
        ]
			},
			tabConfig: [], // 固定参数
		},
		{
			component: 'MobileSelectTree',
			type: 'container',
			name: '树形选择',
			icon: 'iconbim_goujianliebiao',
			isLock: false, // 是否锁定
			needCommonStyle: false,
			style: {
				height: '100%',
				width: '100%',
				minHeight: '10',
				lineHeight: '',
				rotate: 0,
				marginTop: 0,
				marginBottom: 0,
				marginRight: 0,
				marginLeft: 0,
				paddingLeft: 0,
				paddingRight: 0,
				paddingTop: 0,
				paddingBottom: 0
			},
			actionConfig: {
				needAction: true,
				sureClick: {
					use: true,
					useDepend: false,
					dependLogic: [],
					type: 'sureClick',
					name: '确认选择',
					color: '#409EFF',
					btnType: 'text',
					btnSize: '',
					margin: 5,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				}
			},
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
			},
		},
		{
			component: 'ThreeTerminal',
			type: 'container',
			name: '三端选择',
			icon: 'iconbim_goujianliebiao',
			isLock: false, // 是否锁定
			needCommonStyle: false,
			style: {
				height: '100%',
				width: '100%',
				minHeight: '10',
				lineHeight: '',
				rotate: 0,
				marginTop: 0,
				marginBottom: 0,
				marginRight: 0,
				marginLeft: 0,
				paddingLeft: 0,
				paddingRight: 0,
				paddingTop: 0,
				paddingBottom: 0
			},
			actionConfig: {
				needAction: true,
				sureClick: {
					use: true,
					useDepend: false,
					dependLogic: [],
					type: 'sureClick',
					name: '确认选择',
					color: '#409EFF',
					btnType: 'text',
					btnSize: '',
					margin: 5,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				}
			},
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
			},
		},
		{
			component: 'Calendar',
			type: 'container',
			name: '日历组件',
			icon: 'iconriqi',
			isLock: false, // 是否锁定
			needCommonStyle: false,
			propValue: '标题',
			style: {
				height: '100%',
				width: '100%',
				lineHeight: '',
				rotate: 0,
				marginTop: 0,
				marginBottom: 0,
				marginRight: 0,
				marginLeft: 0,
				paddingLeft: 0,
				paddingRight: 0,
				paddingTop: 0,
				paddingBottom: 0
			},
			actionConfig: {
				needAction: true,
				rowClick: {
					use: true,
					useDepend: false,
					dependLogic: [],
					type: 'rowClick',
					name: '日期点击',
					color: '#409EFF',
					btnType: 'text',
					btnSize: '',
					margin: 5,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				}
			},
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
				disabled: false,
        // 顶部路由对应的开关
        showTitle: true,
        showSearch: true,
        mobileSearchInput: true
			}
		},
		{
			name: '全景组件',
			component: 'Pano',
			type: 'container',
			icon: 'icon-quanjing',
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
				isContrast: false, // 全景对比开关
			},
			style: {
				height: '100%',
				width: '100%',
				marginTop: 0,
				marginBottom: 0,
				marginRight: 0,
				marginLeft: 0,
				paddingLeft: 0,
				paddingRight: 0,
				paddingTop: 0,
				paddingBottom: 0
			},
		},
		{
			name: '图纸预览',
			component: 'DwgPreview',
			type: 'container',
			icon: 'icona-tuzhishenchaguanlishenpi',
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
			},
			style: {
				height: '100%',
				width: '100%',
				marginTop: 0,
				marginBottom: 0,
				marginRight: 0,
				marginLeft: 0,
				paddingLeft: 0,
				paddingRight: 0,
				paddingTop: 0,
				paddingBottom: 0
			},
		},
	]
};

export default functionCom;