<!--
 * @Author: shiltin
 * @Date: 2022-05-26 15:45:04
 * @LastEditTime: 2022-08-09 11:24:57
 * @LastEditors: Shiltin 18580045074@163.com
 * @Description: 暗黑模式的3D项目地图，
 * @FilePath: \knock-viewer\src\fn-components\viewer\index.vue
-->
<template>
  <div v-loading="loading" id="main-gis" class="relative w-full bg-f5">
    <div id="viewer-gis" ref="viewerDom" class="w-full h-full"></div>
    <!-- 遮罩方便操作 -->
    <div class="mask" v-if="$route.path === '/modify-page' && showToggle"></div>
		<a href="javascript:;" class="mask-btn" v-if="$route.path === '/modify-page'" @click="onToggle">{{ showToggle ? '隐藏遮罩' : '显示遮罩' }}</a>
		<el-image-viewer v-if="dialogVisible" :url-list="avatarData" :on-close="closeImgViewer" />
  </div>
</template>

<script >
import { dataInterface } from '@/apis/data';
import { initParams, getComponentById, getLinkByTemplate, doEEActionHandle } from '@/utils/tools';
import eventBus from '@/plugins/eventBus';
import { mapState } from 'vuex';

let gis = null
export default {
  name: 'CommonProjectMap',
  props: {
    element: {
      type: Object,
      required: true,
      default: () => {}
    },
    // 是否在组合内
    isGroup: {
			type: Boolean,
		},
    // 组合内组件列表
		groupComponents: {
			type: Array,
			default: () => []
		},
    componentList: {
      default: null
    }
  },
  components: {
		'el-image-viewer': () => import('element-ui/packages/image/src/image-viewer')
	},
	computed: {
    ...mapState(['componentData']),
    // 操作配置
    actionConfig() {
      return this.element?.actionConfig || {};
    }
  },
	data() {
    return {
      viewerDom: null,
      initParm: {
        appId : process.env.VUE_APP_MODEL_APPID ? process.env.VUE_APP_MODEL_APPID : "bdafce12a2d9465d89821ec14214a382",
				appSecret : process.env.VUE_APP_MODEL_APPSECRET ? process.env.VUE_APP_MODEL_APPSECRET:"3EYcdAjbnWxP",
        initClient: false, // 是否加载组件数据
        initUI: false, // 是否加载UI组件
        viewerSetting: {
          imageryLayer: 'ArcGIS', // 地图地图配置，默认为Cesium Ion,ArcGIS暗黑,AMapStreets街道
          isScreen: true // 是否是大屏缩放模式
        }
      },
			loading: false,
			showToggle: false,
			pageType:'home',
			projectData:[],//项目信息
			dialogVisible: false,
			avatarData:[], //项目负责人头像
    };
  },
	methods: {
		/**
     * @desc: 关闭项目负责人头像预览
     * @return {*}
     */
		closeImgViewer(){
			this.dialogVisible = false
		},
		/**
     * @desc: 开关遮罩
     * @return {*}
     */
		onToggle() {
      this.showToggle = !this.showToggle;
    },
    /**
     * @desc: 初始化模型
     * @return {*}
     */
    initModel(){
      gis = new window.BIMCC.Viewer('viewer-gis', {
        isGIS: true
      })
      gis.init(this.initParm).then(()=>{
				gis.on('clickDivBillboard', (res)=>{  
					if(res?.className === 'click-avatar'){
						if(res.avatarData && res.avatarData.length){
							res.avatarData.forEach((item)=>{
								this.avatarData.push(item.url)
							})
							this.dialogVisible = true
						}
					}
					if(res?.className === 'click-name'){
						this.setData(res)
					}
				})
				this.getData('home')
			})
     
    },
    /**
     * @desc: 获取所有项目
     */
    getData (type) {
			this.pageType = type
			let data = []
			if(this.projectData.length) {
				this.projectData.map((item)=>{
					item.itemCol = this.getCol(item,type)
				})
				this.setSign(this.projectData)
			} else {
				this.loading = true
				const params = {
					__method_name__: 'dataList',
					object_uuid: 'object6178c6f9c01af',
					view_uuid: 'view62a072dc9a87e',
					page:1,
					size:1000,
					transcode: 0
				}
				dataInterface(params).then((res) => {
					if (res.data.code === 200 && res.data.data?.length) {
						const arr = res.data.data
						for (let i = 0; i < arr.length; i++) {
							let it = arr[i]
							let address = it.build_address.split('/')
							if (it.lnglat && address.length !== 0) {
								let obj = {
									key: it.project_id,
									archi_type_value:it.archi_type_value,
									position:{
										lat: Number(it.lnglat.latitude),
										lng: Number(it.lnglat.longitude),
										height: 0
									},
									country: it.china_if === null || +it.china_if === 1?'中国':address[0],
									province: address.length===0 ? address[0] : address[0],
									city: address.length===0 ? address[0] : address[1],
									address: address.length===0 ? address[0] : address[2],
									projectName:it.project_abbreviation,
									avatarData:it.person_photo && Array.isArray(it.person_photo)&&it.person_photo.length?it.person_photo : [],
									avatar:it.person_photo && Array.isArray(it.person_photo)&&it.person_photo.length?it.person_photo[0].url:'//static.bimcc.com/images/userheaderimg.png',
									itemCol:this.getCol(it,type),
									quality_num_1: it.quality_num_1,
									quality_num_2: it.quality_num_2,
									quality_num_3: it.quality_num_3,
									quality_num_4: it.quality_num_4,
									safety_num_1: it.safety_num_1,
									safety_num_2: it.safety_num_2,
									safety_num_3: it.safety_num_3,
									safety_num_4: it.safety_num_4,
								}
								data.push(obj)
							} else {
								this.loading = false
							}
						}
						this.projectData = data
						this.setSign(data)
					} else {
						this.loading = false
					}
				})
			}
    },
		//获取颜色
		getCol(data, field){
			let arr = ['quality','safety',]
			//默认蓝色
			if(!arr.includes(field)){
				return 'blue-style'
			}
			//重大问题红色
			if(+data[field+'_num_3'] !== 0){
				return 'red-style'
			}
			//一般问题黄色
			if(+data[field+'_num_2'] !== 0){
				return 'yellow-style'
			}
			//安全问题未处理
			if(+data[field+'_num_1'] === 0){
				return 'green-style'
			}
			//安全问题总数
			if(+data[field+'_num_4'] === 0){
				return 'white-style'
			}

		},
		setSign(data){
			if(Array.isArray(data) && data.length){
				let html = '<div class="custom-sign1">'
					+'<div class="top-cont" >'
					+'<div class="top-inner-cont clusterClickClass">'
					+'<p class="bottom-line">地点<span>format-zoom</span></p>'
					+'<p>项目数量<span class="num">format-num</span></p>'
					+'</div>'
					+'</div>'
					+'<span class="arrow-icon shake-icon"></span>'
					+'</div>'
				let htmlInner = '<div class="custom-sign2">'
					+'<div class="format-itemCol"><div class="top-cont"  >'
					+'<img src="format-avatar" class="click-avatar" />'
					+'<div class="right-cont click-name">'
					+'<div class="half-arc"></div>'	
					+'<p>format-projectName</p>'
					+'</div></div>'
					+'<div class="bottom-cont"><span></span></div>'
					+'</div></div>'
				gis.addDivBillboardArray(data,
					{
						offset: [0, -133],  //标签点偏移值
						clickName: ['click-name','click-avatar'],  //标签点点击Class
						div: htmlInner,
						clusterOffset: [0, -103],  //聚合标签偏移值
						clusterClickName: ['clusterClickClass'],    //聚合标签点击Class
						clusterDiv: html
					}
				)
				this.loading = false
			}
		},
		/**
     * @desc: 点击最后一层项目层
     * @param {Object} data 当前点击的项目
     */
		setData(data){
			this.$emit('set-data',data)
      let result = {};
      if (Object.prototype.toString.call(data) === '[object Object]') {
        for (let key in data) {
          result = {
            ...result,
            [key]: key
          }
        }
      } else {
        result = data;
      }
      this.element.resolveData = data || {};
      this.element.metadata = result || {};
      eventBus.$emit('databaseTrigger', {
        componentId: this.element.id,
        action: 'any',
        output: result,
        isInit: false,
        noUpdate: false
      });
      // 触发事件
      this.onAction(this.actionConfig.clickProject, data);
		},
    /**
		 * @desc: 操作
		 * @param {String} action 操作
		 * @param {Object} output 输出的数据
		 */
		onAction(action = {}, output) {
      // console.log(action, '000--1111--0000', output)
			const comEvents = action?.eventList || [];
			for (let i = 0; i < comEvents.length; i++) {
				const { pattern, eventList = [], specialEventList = [] } = comEvents[i];
				if (pattern === undefined) {
					this.doActionItem(comEvents[i], output);
					break;
				}
				const result = pattern === 'special' ? specialEventList : eventList
				result.forEach((ele) => {
					this.doActionItem(ele, output);
				});
			}
			this.$nextTick(() => {
				eventBus.$emit('databaseTrigger', {
					componentId: this.element.id,
					action,
					output,
					isInit: false,
					noUpdate: true
				});
			});
			return false;
		},
		doActionItem(ele, output) {
			// const eventList = action.eventList || [];
			// if (eventList && eventList.length) {
				// eventList.forEach((ele) => {
					if (ele.key === 'click') {
						// 跳转页面
						if (ele.actionType === 'jumpPage') {
							if (ele.linkType === 'projectPage') {
								const query = {};
								ele.queryList.forEach((queryItem) => {
									let component = getComponentById(this.componentList || this.componentData, queryItem.componentId);
									if (!component && this.isGroup && this.groupComponents.length) {
										component = getComponentById(this.groupComponents, queryItem.componentId);
									}
									this.$set(
										query,
										queryItem.key,
										component.resolveData[queryItem.feild]
									);
								});
								this.$router.push({
									path: ele.pageId,
									query
								});
								return;
							} else if (ele.linkType === 'outPage') {
								window.open(ele.linkUrl);
							} else if (ele.linkType === 'custom') {
								const customLink = getLinkByTemplate(ele.linkTemplate, output)
								window.open(customLink);
							}
						} else if (ele.actionType === 'eeAction') {
							// 触发后端事件
							this.doEEAction(ele, output);
							// 搜集参数
						} else {
							ele.effects.forEach((effect) => {
								this.$store.commit('triggerEvents', {
									config: {
											...ele,
										...effect
									},
									element: this.element
								});
							});
						}
					}
					// 导出事件(只能导出当前行的数据)
					if (ele.actionType === 'export') {
						this.doExport(ele, output);
					}
				// });
			// }
		},
		/**
		 * @desc: 触发后端事件
		 */
		doEEAction(btnConfig, rowData) {
			if (btnConfig && btnConfig.actionType === 'eeAction') {
				const { objectUUID, viewUUID, eventName } = btnConfig;
				if (!objectUUID || !viewUUID || !eventName) {
					this.$message.error('请正确配置事件!');
					return false;
				}
				const idUUID = this.getIdUUID();
				const data_id = rowData?.[idUUID];
				if (!data_id) {
					this.$message.error('事件未配置触发对象!');
					return false;
				}
				this.loading = true;
				dataInterface({
					__method_name__: 'customEventCall',
					object_uuid: btnConfig.objectUUID,
					view_uuid: btnConfig.viewUUID,
					data_id,
					event: btnConfig.eventName
				}).then((res) => {
					if (res.status === 200 && res.data.code === 200) {
						this.$message.success('操作成功！');
						doEEActionHandle(res.data?.__adds__);
					}
					this.loading = false;
				}).catch((err) => {
					console.log(err, '00000后端事件错误');
					this.loading = false;
				});
			}
		},
    /**
     * @desc: 测试触发行为
     * @param {Object} data
     */
    testBehavior(data) {
      // console.log(data, '-----data----')
			if(Object.prototype.toString.call(data) === '[object Object]' && data.type){
				console.log(data.type, this.pageType)
				if(data.type !== this.pageType){
					//返回地球返回初始状态
					if(data.type === 'back'){
						if(gis!== null){
							gis.flyToHome();
						}
					}else{
						if(gis!== null){
							//清除标记地球返回初始状态
							gis.flyToHome();
							gis.removeAllDivBillboard()
						}
						this.getData(data.type)
					}
					
				}
				
			}
    }
	},
	mounted() {
		this.initModel()
		eventBus.$on('doComponentBehavior', config => {
      const { component, list = [] } = config;
      if (component !== this.element.id) return false;
      list.forEach(ele => {
        const { behaviors, params } = ele;
        const { param = {}, canPost } = initParams(params, this.isGroup, this.componentList || this.componentData, this.groupComponents);
        if (canPost) {
          // 调用行为方法
          behaviors.forEach(funName => {
            try {
              eval(this[funName])(param)
            } catch (err) {
              console.log(err);
            }
          });
        }
      });
    })
	},
	beforeDestroy() {
    eventBus.$off('doComponentBehavior');
  }
}
</script>
<style lang="less" scoped>
#viewer-gis{
  height: 100%;
  width: 100%;
}
#main-gis{
  height: 100vh!important;
}
.pointerNone {
  pointer-events: none;
}
.w-full{
  width: 100%;
}
.h-full{
  height: 100%;
}
.relative{
  position: relative;
}
.mask{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, .1);
}
.test-inner{
	color:red;
}
.mask-btn{
  position: absolute;
  padding: 5px;
  font-size: 14px;
  color: @theme;
  right: 0;
  top: 0;
  cursor: pointer;
}
</style>
