<!--
 * @Description: 进度图
 * @Author: luocheng
 * @Date: 2022-05-07 09:52:27
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-01-03 09:59:56
-->
<template>
  <div class="chart-completion-ratio"
		v-loading="getting"
    :style="{
      height: height + 'px'
    }">
    <section class="complete-item"
			v-for="(item, index) in renderList"
			:key="index"
			:style="{
				marginTop: scaleSize(attributes.barMarginTop) + 'px',
				paddingRight: scaleSize(60 * attributes.percentFontSize / 16) + 'px'
			}"
		>
			<p class="name" v-if="attributes.showTitle"
				:style="{
					color: attributes.titleColor,
					fontSize: scaleSize(attributes.titleFontSize) + 'px',
					top: scaleSize(-1 * (attributes.titleFontSize + 10)) + 'px'
				}"
			>{{ item.name }}</p>
			<p class="count" v-if="attributes.showCompleteCount"
				:style="{
					color: attributes.completeColor,
					fontSize: scaleSize(attributes.completeFontSize) + 'px',
					top: -1 * (attributes.completeFontSize + 10) + 'px',
					right: 5 * attributes.percentFontSize + 'px'
				}"
			>{{ item.completeData || 0 }}</p>
			<p class="percent" v-if="attributes.showPercent"
				:style="{
					color: attributes.percentColor,
					fontSize: scaleSize(attributes.percentFontSize) + 'px',
					top: (attributes.barHeight - attributes.percentFontSize) / 2 + 'px',
				}"
			>{{ item.percent || '0.00%' }}</p>
			<div class="total-box"
				:style="{
					background: attributes.transparentBg ? 'transparent' : attributes.background,
					height: attributes.barHeight + 'px',
					width: isNaN(attributes.barWidth) ? attributes.barWidth : attributes.barWidth + 'px',
					borderRadius: attributes.isRadius ? attributes.barHeight / 2 + 'px' : 'none',
					border: attributes.showBorder ? '1px solid ' + attributes.borderColor : 'none'
				}"
			>
				<div class="complete-box"
					:style="{
						width: item.percent,
						background: attributes.completeBg,
						borderRadius: attributes.isRadius ? attributes.barHeight / 2 + 'px' : 'none',
						'background-image': item.gradientStyle ? item.gradientStyle : ''
					}"
				></div>
			</div>
		</section>
  </div>
</template>

<script>
/* eslint-disable */
import mixin from './mixin';
import eventBus from '@/plugins/eventBus';

export default {
  name: 'ChartCompletionRatio',
  mixins: [mixin],
	data() {
		return {
			option: null,
			renderList: []
		};
	},
	created() {
		this.getOptions();
	},
	computed: {
		// transparentBg: false,
    // isRadius: true,
    // showBorder: true,
    // borderColor: '#fff'
		attributes() {
			return this.chartData.attributes || {}
		}
	},
	mounted() {
		this.$nextTick(() => {
			eventBus.$on('refreshChart', () => {
				this.option = null;
				this.getOptions();
			});
			eventBus.$on('databaseTrigger', config => {
				if (!config || !this.paramsConfig.length || !this.paramsConfig.find(ele => ele.componentId === config.componentId)) {
					return;
				}
				this.option = null;
				this.getOptions();
			});
		});
	},
	methods: {
		/**
		 * @desc: 设置配置项
		 * @param {Object} data 数据对象
		 */
		setOptions(data) {
			this.renderList = [];
			const { completionRatio = [] } = this.chartData.staticConfig[0]
			const { useGradient = false, gradientRange = []} = this.attributes
			completionRatio.forEach((ele, i) => {
				const { complete, completeText, total } = ele;
				const completeData = data?.[complete] || 0;
				const totalData = data?.[total] || 0;
				const item = {
					name: completeText,
					completeData,
					totalData,
					percent: `${!totalData ? 0 : ((completeData / totalData) * 100).toFixed(2)}%`
				}
				if(useGradient) {
					item.gradientStyle = `linear-gradient(to right, ${gradientRange[i % gradientRange.length].join(',')})`
				} else {
					item.gradientStyle = ''
				}
				this.renderList.push(item)
			});
		}
	},
	beforeDestroy() {
		eventBus.$off('refreshChart');
    eventBus.$off('databaseTrigger');
		console.log('销毁', '666666666')
	}
}
</script>

<style lang="less" scoped>
.chart-completion-ratio{
  height: 100%;
	width: 100%;
	.complete-item{
		box-sizing: border;
		position: relative;
		padding: 0px 10px;
		padding-right: 60px;
		margin-top: 30px;
		p{
			color: #fff;
			position: absolute;
			font-size: PingFang SC;
			&.name{
				top: -24px;
			}
			&.count{
				right: 70px;
				top: -24px;
				font-weight: bold;
			}
			&.percent{
				right: 0;
			}
		}
		.total-box{
			height: 40px;
			width: 100%;
			box-sizing: border-box;
			background: #f2f3f5;
			border-radius: 2px;
			.complete-box{
				background: @theme;
				height: 100%;
			}
		}
	}
}
</style>