<!--
 * @Description: 走马灯/swiper
 * @Author: luocheng
 * @Date: 2021-09-28 15:09:27
 * @LastEditors: luocheng
 * @LastEditTime: 2022-10-24 16:17:51
-->
<template>
  <el-carousel class="common-swiper"
    v-loading="loading"
    :height="isNaN(statusConfig.height) ? statusConfig.height : statusConfig.height +'px'"
    :interval="statusConfig.interval || 3000"
    :arrow="statusConfig.arrow"
    :initial-index="statusConfig.initialIndex || 0"
    :trigger="statusConfig.trigger"
    :autoplay="statusConfig.autoplay"
    :indicator-position="statusConfig.indicatorPosition"
    :type="statusConfig.type"
    :loop="statusConfig.loop"
    :direction="statusConfig.direction || 'horizontal'"
  >
    <el-carousel-item v-for="(item, index) in swiperOptions" :key="index">
      <img :src="item.img || defaultImg" :alt="item.text || ''" @click="onItem(item, index)">
      <p class="desc" v-if="statusConfig.showText && item.text" @click="onItem(item, index)">{{ item.text || ''}}</p>
    </el-carousel-item>
  </el-carousel>
</template>

<script>
import { Carousel, CarouselItem } from 'element-ui';
import { dataInterface } from '@/apis/data';
import CustomComponentMixin from '@/custom-component/mixins/CustomComponentMixin.js';

export default {
  name: 'CommonSwiper',
  mixins: [CustomComponentMixin],
  components: {
    'el-carousel': Carousel,
    'el-carousel-item': CarouselItem
  },
  props: {
    element: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data() {
    return {
      loading: false,
      swiperOptions: [],
      defaultImg: require('@/assets/images/logo1.png')
    }
  },
  computed: {
    statusConfig() {
      return this.element && this.element.statusConfig;
    },
    swiperConfig() {
      return this.element?.swiperConfig;
    }
  },
  watch: {
    swiperConfig: {
      handler() {
        this.swiperOptions = [];
        this.resetSwiper();
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    /**
     * @desc: 重置轮播图取值配置
     */
    resetSwiper() {
      if (!this.swiperConfig) {
        return false;
      }
      const { valueType, fixedConfig, databaseConfig } = this.swiperConfig;
      if (valueType === 'fixed') {
        // 固定
        this.formatFixed(fixedConfig);
      } else if (valueType === 'database') {
        // 动态取值
        this.getDynamic(databaseConfig);
      }
    },
    /**
     * @desc: 固定配置
     */
    formatFixed(fixedConfig) {
      if (!fixedConfig || !Array.isArray(fixedConfig)) {
         return false
      }
      this.swiperOptions = fixedConfig || [];
    },
    /**
     * @desc: 动态取值配置
     */
    getDynamic(config) {
      const {
        objectUUID,
        viewUUID,
        imgUUID,
        textUUID,
        hrefUUID,
        hrefTypeUUID
      } = config;
      if (!objectUUID || !viewUUID) {
        this.$message.warning('请确认配置项完整！');
        return;
      }
      this.loading = true;
      dataInterface({
        __method_name__: 'dataList',
        object_uuid: objectUUID,
        view_uuid: viewUUID
      }).then(res => {
        let result = null;
        if (Array.isArray(res?.data?.data)) {
          result = res?.data?.data
        } else {
          result = res?.data?.data?.data || []
        }
        this.swiperOptions = result.map(ele => {
          // 兼容多张图类型
           let img = ele?.[imgUUID];
          if (Array.isArray(img)) {
            img = img?.[0]?.path || '';
          }
          return {
            img,
            text: ele?.[textUUID],
            href: ele?.[hrefUUID],
            hrefTypeUUID: ele?.[hrefTypeUUID]
          }
        });
        this.loading = false;
      }).catch(err => {
        console.log(err);
        this.loading = false;
      });
    },
    /**
     * @desc: 点击
     * @param {Object} item
     * @param {Number} index
     */
    onItem(item, index) {
      if (!this.statusConfig.clickLink) return;
      console.log(index, '----index0000-----');
      const { href, hrefType } = item;
      const { path } = this.$route;
      if (path === '/modify-page' || path === '/page-view') {
        this.$message.info(`跳转${href}---方式${hrefType}`)
        return;
      }
      if (hrefType === 'link') {
        window.open(href);
      } else if (hrefType === 'route') {
        this.$router.push({
          path: href
        });
      }
    }
  }
}
</script>

<style lang="less" scoped>
.common-swiper{
  width: 100%;
  overflow: hidden;
  background: transparent;
  .el-carousel__item{
    background: transparent;
    img{
      display: block;
      margin: 0 auto;
      height: 100%;
      object-fit: cover;
    }
    .desc{
      position: absolute;
      bottom: 0;
      left: 0;
      height: 20px;
      width: 100%;
      background: rgba(0, 0, 0, .6);
      color: 14px;
      line-height: 20px;
      box-sizing: border-box;
      padding: 0 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: left;
    }
  }
}
</style>