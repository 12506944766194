/*
 * @Description: 配置
 * @Author: luocheng
 * @Date: 2022-06-20 09:45:43
 * @LastEditors: zx
 * @LastEditTime: 2022-07-01 17:41:10
 */

/**
 * @desc: 根据路由获取原始配置信息
 * @param {String} path 页面路由
 */
export const currentApp = sessionStorage.getItem('currentApp');
export const currentAppJSON = currentApp ? JSON.parse(currentApp) : null;

export const getMenuOriginData = (path, arr) => {
  if (!path || !Array.isArray(arr)) return null;
  if (!currentApp) return null;
  let result = arr.find(ele => ele.menu_path === path);
  if (result) {
    return result;
  }
  for (let i = 0; i < arr.length; i++) {
    const { children_list = []} = arr[i];
    result = getMenuOriginData(path, children_list);
    if (result) {
      return result;
    }
  }
  return null;
}

// 个人应用配置
export const appConfig = [
  
  {
    icon: require('@/assets/images/second-screen/app-p1.png'),
    title: '管理中心',
    desc: '项目的估概预结…',
    type: "1",
    path: '/work-order',
    query: {
      pageUUID: ''
    },
    // originData: getMenuOriginData('/work-order', currentAppJSON.app_orign_data)
  },
  
  {
    icon: require('@/assets/images/second-screen/app-p2.png'),
    title: '一张图',
    desc: '项目的估概预结…',
    type: "2",
    path: '/entry?type=bimConsole',
    query: {
      pageUUID: "",
      menu_type: 4
    },
    // originData: getMenuOriginData('/entry?type=bimConsole', currentAppJSON.app_orign_data)
    // originData: {
    //   archi_limit: [],
    //   auth_key: "",
    //   child_path_list: [],
    //   children_list: [],
    //   created_at: "2022-05-19 18:49:06",
    //   deleted_at: null,
    //   describe: "",
    //   icon: "",
    //   icon_color: "#202126",
    //   id: 129,
    //   index_path: "",
    //   is_hide: 0,
    //   is_index: 0,
    //   is_screen: 1,
    //   level: 2,
    //   link: "",
    //   link_type: "_blank",
    //   menu_path: "/entry?type=bimConsole",
    //   menu_type: 4,
    //   name: "一张图决策系统",
    //   page_uuid: "page629da16de1c56",
    //   parent_id: 89,
    //   permissions: [],
    //   sort: "129"
    // }
  },
  {
    icon: require('@/assets/images/second-screen/app-p3.png'),
    title: '智慧工地',
    desc: '项目的估概预结…',
    type: "3",
    path: '/child-app-bim/wisdomsite/monitor',
    query: {},
    // originData: getMenuOriginData('/child-app-bim/wisdomsite/monitor', currentAppJSON.app_orign_data)
  },
  {
    icon:require('@/assets/images/second-screen/app-p4.png'),
    title: '区块链', desc: '项目的估概预结…',
    type: "4",
    path: '/qkl',
    query: {},
    // originData: getMenuOriginData('/qkl', currentAppJSON.app_orign_data)
  },
  {
    icon: require('@/assets/images/second-screen/app-p5.png'),
    title: '智慧监管',
    desc: '项目的估概预结…',
    type: "5",
    path: '/zhjg',
    query: {},
    // originData: getMenuOriginData('/zhjg', currentAppJSON.app_orign_data)
  },
  {
    icon: require('@/assets/images/second-screen/app-p6.png'),
    title: '民生罗铁',
    desc: '项目的估概预结…',
    type: "6",
    // path: '/pc/dataview/show/20',
    query: {},
    isScreen: true
  }
];

// 信息相关
export const infoList = [
  
  { 
    icon: require('@/assets/images/second-screen/info-p1.png'), 
    title: '参建各方',
    type: "1" ,
    path: '/all_parties_involved_in_the_construction?pageUUID=page62beb2a27c5b4',
    query: {},
    isScreen:false,
  },
  { icon: require('@/assets/images/second-screen/info-p2.png'),
    title: '总包架构', 
    type: "2" ,
    path: '/general_contracting_structure?pageUUID=page62beb2898122b',
    query: {},
    isScreen:false,
  },
  { 
    icon: require('@/assets/images/second-screen/info-p3.png'), 
    title: '创新创优', 
    type: "3",
    path: '/qc_innovation?pageUUID=page62b6d45f20e29',
    query: {},
    isScreen:false,
  },
  { 
    icon: require('@/assets/images/second-screen/info-p4.png'), 
    title: '规章制度', 
    type: "4",
    path: '/rules_and_regulations_new?pageUUID=page62b7f920f00e2',
    query: {},
    isScreen:false,
  },
  { 
    icon: require('@/assets/images/second-screen/info-p5.png'),
    title: '操作手册', 
    type: "5",
    path: '/operation_manual?pageUUID=page62b8126965de3',
    query: {},
    isScreen:false,
  },
];