/*
 * @Description: 
 * @Author: luocheng
 * @Date: 2021-12-10 18:02:55
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2022-12-07 15:21:17
 */

export default {
  state: {
    // 页面类型
    editorType: 'page',
    // 组件数据
    componentData: [],
    // 画布样式
    editorConfig: {
      title: '测试配置',
      height: 840,
      width: 800, // 1200
      scale: 100,
      backgroundColor: '#fff'
    },
  },
  mutations: {
    /**
     * @desc: 保存配置数据
     */
    setConfigData(state, { componentData, editorConfig, editorType }) {
      state.componentData = componentData;
      state.editorConfig = editorConfig;
      state.editorType = editorType;
    }
  }
}