/*
 * @Author: zx
 * @Date: 2022-08-18 15:24:54
 * @LastEditTime: 2022-12-28 09:38:58
 * @Description:
 * @LastEditors: yaoling 1871883713@qq.com
 */
/**
 * @description 管理后台路由
 *
 */
import Home from '@/manage-views/views/home/home'

const baseRoutes = [
  {
    path: '/application',
    name: 'Application',
    component: Home,
    meta: {
      title: '应用管理',
      icon: 'iconyingyong'
    },
    children: [
      {
        path: 'my-application',
        name: 'MyApplication',
        component: () => import('@/manage-views/views/application/myApplication/index.vue'),
        meta: {
          title: '我的应用',
          icon: 'iconyingyong'
        }
      },
      {
        path: 'page-parser',
        name: 'PageParser',
        component: () => import('@/manage-views/views/pageParser/Index.vue'),
        meta: {
          title: '页面渲染',
          icon: 'iconliebiaomoshi'
        }
      },
      {
        path: 'page-edit',
        name: 'PageEdit',
        meta: {
          title: '页面设计',
          icon: 'iconliebiaomoshi'
        },
        component: () => import('@/views/ModifyPage.vue')
      },
      {
        path: '/bulletin-board',
        name: 'BulletinBoard',
        meta: {
          title: '看板',
          icon: 'iconliebiaomoshi'
        },
        component: () => import('@/manage-views/views/bulletin_board/index.vue'),
      },
      {
        path: '/bulletin-board/shower',
        name: 'BulletinBoardShower',
        meta: {
          title: '看板预览',
          icon: 'iconliebiaomoshi'
        },
        component: () => import('@/manage-views/views/bulletin_board/Preview.vue'),
      },
    ]
  },
  {
    path: '/full-page-parser',
    name: 'FullPageParser',
    component: () => import('@/manage-views/views/pageParser/Index.vue'),
    meta: {
      title: '页面渲染',
      icon: 'iconliebiaomoshi'
    }
  },
  {
    path: '/full-page-edit',
    name: 'FullPageEdit',
    meta: {
      title: '页面设计',
      icon: 'iconliebiaomoshi'
    },
    component: () => import('@/views/ModifyPage.vue')
  },
  {
    path: '/edit-list-viewer',
    name: 'editViewer',
    component: () => import('@/manage-views/views/application/myApplication/EditViewer.vue'),
  },
  /**
  * @desc 系统设置
  */
  {
    path: '/sys-setting',
    name: 'sysSetting',
    component: Home,
    meta: {
      title: '系统设置',
      icon: 'icongis_xitongshezhi'
    },
    children:[
      {
        path: '/sys-setting/menu',
        name: 'menu',
        component: () => import('@/manage-views/views/sysSetting/menu/index.vue'),
        meta: {
          title: '菜单管理',
          icon: 'iconliebiaomoshi'
        }
      },
      {
        path: '/sys-setting/dict',
        name: 'dict',
        component: () => import('@/manage-views/views/sysSetting/dict/index.vue'),
        meta: {
          title: '字典管理',
          icon: 'iconrizhi'
        }
      },
      {
        path: '/sys-setting/config',
        name: 'config',
        component: () => import('@/manage-views/views/sysSetting/config/index.vue'),
        meta: {
          title: '参数设置',
          icon:'iconwenjianliuzhuan'
        }
      },
      {
        path: '/sys-setting/comp',
        name: 'comp',
        component: () => import('@/manage-views/views/sysSetting/comp/index.vue'),
        meta: {
          title: '企业管理',
          icon: 'iconrichangpeixunguanli'
        }
      },
      {
        path: '/sys-setting/versions',
        name: 'versions',
        component: () => import('@/manage-views/views/sysSetting/versions/index.vue'),
        meta: {
          title: '版本控制',
          icon: 'iconrichangpeixunguanli'
        }
      },
    ]
  },
  /**
   * @desc 组织管理
   */
  {
    path:'/org',
    name: 'org',
    component: Home,
    meta: {
      title: '组织管理',
      icon: 'iconc-tiled'
    },
    children: [
      {
        path: '/org/user',
        name: 'user',
        component: () => import('@/manage-views/views/org/user/index.vue'),
        meta: {
          title: '用户管理',
          icon: 'icontuandui'
        }
      },
      {
        path: '/org/dept',
        name: 'dept',
        component: () => import('@/manage-views/views/org/dept/index.vue'),
        meta: {
          title: '部门管理',
          icon: 'iconjiagou'
        }
      },
      {
        path: '/org/post',
        name: 'post',
        component: () => import('@/manage-views/views/org/post/index.vue'),
        meta: {
          title: '岗位管理',
          icon: 'iconrichangpeixunguanli'
        }
      },
    ]
  },
  /**
   * @desc: 权限管理
   */
  {
    path:'/auth',
    name: 'auth',
    component: Home,
    meta: {
      title: '权限管理',
      icon: 'iconrichangpeixunguanli'
    },
    children: [
      {
        path: '/auth/role',
        name: 'role',
        component: () => import('@/manage-views/views/auth/role/index.vue'),
        meta: {
          title: '角色管理',
          icon: 'icontouxiang'
        }
      },
      {
        path: '/auth/sys-auth',
        name: 'sysAuth',
        component: () => import('@/manage-views/views/auth/sysAuth/index.vue'),
        meta: {
          title: '菜单授权',
          icon: 'iconliuchengchakan'
        }
      },
    ]
  },
    /**
   * @desc: 系统应用
   */
  {
    path:'/sys-app',
    name: 'sysApp',
    component: Home,
    meta: {
      title: '系统应用',
      icon: 'icona-gongzuotaidiannaofill'
    },
    children: [
      {
        path: '/sys-app/notice',
        name: 'Notice',
        component: () => import('@/manage-views/views/sysApp/notice/index.vue'),
        meta: {
          title: '通知公告',
          icon: 'iconguangbo'
        }
      },
      {
        path: '/sys-app/news',
        name: 'News',
        component: () => import('@/manage-views/views/sysApp/news/index.vue'),
        meta: {
          title: '新闻动态',
          icon: 'iconguangbo'
        }
      },
    ]
  },
  /**
   * @desc: 模板分类
   */
       {
        path:'/template',
        name: 'template',
        component: Home,
        meta: {
          title: '模板分类',
          icon: 'icona-gongzuotaidiannaofill'
        },
        children: [
          {
            path: '/template/classification',
            name: 'inform',
            component: () => import('@/manage-views/views/template/detail/index.vue'),
            meta: {
              title: '模板应用',
              icon: 'iconguangbo'
            }
          },
        ]
      },
  /**
   * @desc: 知识库
   */
  {
    path: '/knowledge-base',
    name: 'knowledge',
    meta: {
      title: '知识库',
      icon: 'iconrichangpeixunguanli'
    },
    component: Home,
    children: [
      {
        path: 'home',
        name: 'KnowledgeBase',
        component: () => import('@/manage-views/views/knowledgeBase/home/index.vue'),
        meta: {
          title: '知识库首页',
          icon: 'iconrichangpeixunguanli',
        },
      },
      {
        path: ':id',
        name: 'KnowledgeDetails',
        component: () => import('@/manage-views/views/knowledgeBase/details/index.vue'),
        meta: {
          title: '知识库详情',
          icon: 'iconrichangpeixunguanli',
          hideInMenu: true
        },
      },
      {
        path: 'editor/:id',
        name: 'KnowledgeEditor',
        component: () => import('@/manage-views/views/knowledgeBase/TextEditor/index.vue'),
        meta: {
          title: '知识库编辑',
          icon: 'iconrichangpeixunguanli',
          hideInMenu: true
        },
      },
    ],
  },
    /**
   * @desc: 登录后的第二屏
   */
  {
    path: '/manage',
    name: 'Manage',
    meta: {
      title: '管理中心',
      icon: 'iconrichangpeixunguanli',
    },
    component: () => import('@/manage-views/views/home/home.vue'),
    children: [
      {
        path: '/manage/config',
        name: 'ManageConfig',
        meta: {
          title: '管理中心',
          icon: 'iconrichangpeixunguanli',
        },
        component: () => import('@/manage-views/views/personalCenter/index.vue')
      },
    ]
  },
  /**
 * @desc: 系统应用
 */
  {
    path:'/project',
    name: 'Project',
    component: Home,
    meta: {
      title: '项目看板',
      icon: 'icona-gongzuotaidiannaofill'
    },
    children: [
      {
        path: 'info',
        name: 'Info',
        component: () => import('@/manage-views/views/projectInfo/Index.vue'),
        meta: {
          title: '项目概况',
          icon: 'iconguangbo'
        }
      },
    ]
  },
  /**
   * @desc: 登录路由
   */
  {
    path:'/log',
    name: 'Log',
    component: Home,
    meta: {
      title: '日志管理',
      icon: 'icona-gongzuotaidiannaofill'
    },
    children: [
      {
        path: 'login-log',
        name: 'Loginlog',
        component: () => import('@/manage-views/views/log/loginLog/index.vue'),
        meta: {
          title: '登录日志',
          icon: 'iconguangbo'
        }
      },
      {
        path: 'operate-log',
        name: 'OperateLog',
        component: () => import('@/manage-views/views/log/operateLog/index.vue'),
        meta: {
          title: '登录日志',
          icon: 'iconguangbo'
        }
      },
      {
        path: 'probation',
        name: 'Probation',
        component: () => import('@/manage-views/views/log/probation/index.vue'),
        meta: {
          title: '试用申请',
          icon: 'iconguangbo'
        }
      },
    ]
  },
  {
    path: '/sys-application-list',
    name: 'SysApplicationList',
    component: Home,
    meta: {
      title: '模板应用',
      icon: 'icona-gongzuotaidiannaofill'
    },
    children: [
      {
        path: '',
        name: 'ApplicationList',
        component: () => import('@/manage-views/views/sysApplicationList/index.vue'),
        meta: {
          title: '模板应用',
          icon: 'iconguangbo'
        }
      },
      {
        path: '/config-application',
        name: 'ConfigApplication',
        meta: {
          title: '应用配置'
        },
        component: () => import('@/views/config-app/Index')
      }
    ]
  },
  /* 页面编辑器 */
  {
    path:'/page-editor',
    name: 'PageEditorIndex',
    component: Home,
    meta: {
      title: '页面列表',
      icon: 'icona-gongzuotaidiannaofill'
    },
    children: [
      {
        path: '/index/page-list',
        meta: {
          title: '页面列表'
        },
        name: 'PageList',
        component: () => import('@/manage-views/views/pageEdit/PageList.vue')
      },
      {
        path: '/index/spreadsheets',
        meta: {
          title: 'Excel模板列表'
        },
        name: 'ExcelTemplateList',
        component: () => import('@/manage-views/views/pageEdit/ExcelList.vue'),
      },
    ]
  },
  {
    path:'/data-store',
    name: 'DataStore',
    component: Home,
    meta: {
      title: '页面编辑',
      icon: 'icona-gongzuotaidiannaofill'
    },
    children: [
      {
        path: '/modify-page',
        meta: {
          title: '页面编辑'
        },
        name: 'ModifyPage',
        component: () => import('@/views/ModifyPage.vue')
      },
      {
        path: '/config-chart',
        meta: {
          title: '配置图表'
        },
        name: 'ConfigChart',
        component:  () => import('@/views/ConfigChart.vue')
      },
    ]
  },
	{
		path: '/page-view',
		meta: {
			title: '预览'
		},
		component: () => import('@/views/PageView.vue')
	},
  /**
 * @desc: 数仓列表
 */
  {
    path:'/data-store',
    name: 'DataStore',
    component: Home,
    meta: {
      title: '数仓列表',
      icon: 'icona-gongzuotaidiannaofill'
    },
    children: [
      {
        path: '/data-store/list',
        name: 'DataStoreList',
        component: () => import('@/manage-views/views/dataStoreList/Index.vue'),
        meta: {
          title: '数仓列表',
          icon: 'iconguangbo'
        }
      },
      {
        path: '/data-store/analyse',
        name: 'DataStoreAnalyse',
        component: () => import('@/components/graph/ETL.vue'),
        meta: {
          title: '数仓列表',
          icon: 'iconguangbo'
        }
      },
    ]
  },
]
export default baseRoutes
