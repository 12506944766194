/*
 * @Author: zqy
 * @Date: 2022-11-09 14:43:50
 * @LastEditTime: 2022-11-09 14:44:17
 * @Description: 
 * @LastEditors: zqy
 */
const charts = {
  type: 'charts',
  title: '图表组件',
  show: true,
	order: 4,
  componentList: [
    {
      component: 'CommonChart',
      type: 'charts',
      name: '图表盒子',
      icon: 'icon-tubiao1',
      isLock: false, // 是否锁定
      needCommonStyle: true,
      propValue: '',
      span: 24,
      statusConfig: {
        displayShow: true,
        isShow: true, // 是否显示
      },
      style: {
        minHeight: 0,
        height: '100%',
        width: '',
        rotate: 0,
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 16,
        paddingRight: 16
      },
      chartConfig: {
        chartUUID: '', // 图表分析组件
      },
      paramsConfig: [], // 筛选参数
      actionConfig: []
    },
  ]
}
export default charts;