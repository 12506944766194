<!--
 * @Description: 网盘整体组件 vue2
 * @Author: luocheng
 * @Date: 2022-03-02 09:28:30
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2023-01-04 15:53:24
-->
<template>
  <div class="common-net-disk">
    <DiskHeader
      :pathList="pathList"
      @changeModel="onChangeModel"
      @updateList="getList" 
      @changeBreadcrumb="onChangeBread"
      @filterFile="onFilter"
      :isGroup="isGroup"
      :groupComponents="groupComponents"
    ></DiskHeader>
    <article class="disk-main" v-loading="loading">
      <!-- 表格视图 -->
      <TableView :tableData="fileList" v-if="viewType === 'table'"
        @updateList="getList"
        @selectFile="onSelectFile">
      </TableView>
      <!-- 文件夹模式 -->
      <FolderView v-else-if="viewType === 'folder'" :fileList="fileList"
        @selectFile="onSelectFile"
      >
      </FolderView>
    </article>
    <!-- 预览图片 -->
    <el-image-viewer v-if="showPreview" :on-close="closePreview" :url-list="previewList"></el-image-viewer>
  </div>
</template>

<script>
import DiskHeader from './components/Header';
import TableView from './components/TableView';
import FolderView from './components/FolderView';
import { dataInterface } from '@/apis/data';
import { mapState } from 'vuex';
import eventBus from '@/plugins/eventBus';
// import mixin from './mixin';
import ElImageViewer from 'element-ui/packages/image/src/image-viewer.vue';
import { initParams, removeEventBus  } from '@/utils/tools';

export default {
  name: 'CommonNetDisk',
  props: {
    element: {
      type: Object,
      required: true
    },
    // 是否在组合内
    isGroup: {
			type: Boolean,
		},
    // 组合内组件列表
		groupComponents: {
			type: Array,
			default: () => []
		},
    componentList: {
      default: null
    }
  },
  inject: ['EDITOR_pageUUID'],
  components: {
    DiskHeader,
    TableView,
    FolderView,
    'el-image-viewer': ElImageViewer
  },
  data() {
    return {
      viewType: 'table', // 视图类型 table 表格模式，folder 文件夹模式
      fileList: [], // 文件列表
      loading: false,
      // 父级id
      parentId: 0,
      // 模块名称
      pathList: [],
      // 筛选名称
      filterName: '',
      // 预览视图
      previewList: [],
      showPreview: false,
      outParam: null
    }
  },
  provide() {
    return {
      element: this.element,
      getParentId: () => this.parentId
    }
  },
  computed: {
    ...mapState(['componentData']),
    statusConfig() {
      return this.element?.statusConfig;
    },
    database() {
      return this.element?.database;
    }
  },
  created() {
    this.getList();
    this.pathList.push({
      label: this.statusConfig.partName, // 模块名称
      type: 'folder', // 类型 folder 文件夹， file 文件
      partId: 0 // 父级id
    });
  },
  mounted() {
    const databaseTrigger = {
      [this.element.id]: () => {
        this.getList();
      }
    };
    eventBus.$on('databaseTrigger', databaseTrigger[this.element.id]);
  },
  methods: {
    /**
     * @desc: 筛选
     * @param {String} name 名称
     */
    onFilter(name) {
      this.filterName = name;
      this.getList(true);
    },
    /**
     * @desc: 获取数据列表 
     */
    getList(isSearch = false) {
      this.fileList = [];
      const { objectData, viewData } = this.database;
      if (!objectData || !viewData ) {
        this.$message.error('获取数据失败!');
        return false;
      }
			const { param = {}, search = [], canPost } = initParams(this.element?.database?.paramsConfig || [], this.isGroup, this.componentList || this.componentData, this.groupComponents);
      if (!canPost) return;
      if (param && this.outParam) {
        let needClear = false;
        for (let key in param) {
          if (param[key] !== this.outParam[key]) {
            needClear = true;
          }
        }
        if (needClear) {
          this.parentId = 0;
          this.pathList = [];
          this.pathList.push({
            label: this.statusConfig.partName, // 模块名称
            type: 'folder', // 类型 folder 文件夹， file 文件
            partId: 0 // 父级id
          });
        }
      }
      this.outParam = param;
      const objectUUID = objectData.uuid;
      const defaultView = viewData.uuid;
      this.loading = true;
      const fixParams = isSearch ? {} : {
        parent_id: this.parentId || 0,
      }
      dataInterface({
        __method_name__: 'dataList',
				object_uuid: objectUUID,
				view_uuid: defaultView,
        ...param,
				search:  [
          ...search,
          {
            code: 'name',
            ruleType: 'like',
            value: this.filterName
          }
        ],
        ...fixParams,
        transcode: 0
      }).then(res => {
        if (res.status === 200 && res.data?.code === 200) {
          const fileList = res?.data?.data || [];
          let files = [], folder = [], other = [];
          fileList.forEach(ele => {
            if (+ele.type_id === 1) {
              folder.push(ele);
            } else if (+ele.type_id === 2) {
              files.push(ele);
            } else {
              other.push(ele)
            }
          });
          this.fileList = folder.concat(files).concat(other);
        }
        this.loading = false;
      }).catch(err => { 
        console.log(err, '---')
        this.$message.error('获取文件失败！')
        this.loading = false;
      })
    },
    /**
     * @desc: 切换面包屑
     * @param {Object} bread 当前选中面包屑
     */
    onChangeBread(bread) {
      if (!bread || this.parentId === bread.partId) {
        return;
      }
      const index = this.pathList.findIndex(ele => ele.partId === bread.partId);
      if (index !== -1) {
        this.pathList = this.pathList.slice(0, index + 1);
        this.parentId = bread.partId;
        this.getList();
      }
    },
    /**
     * @desc: 选择文件
     * @param {Object} file
     */
    onSelectFile(file) {
      if (+file.type_id === 1 && !this.pathList.find(ele => ele.partId === file.id)) {
        this.parentId = file.id;
        // 文件夹
        this.pathList.push({
          label: file.name, // 模块名称
          type: 'folder', // 类型 folder 文件夹， file 文件
          partId: file.id // 父级id
        });
        this.getList();
      } else if (+file.type_id === 2) {
        const baseUrl = process.env.VUE_APP_BASE_URL;
        if (['bmp', 'jpg', 'png', 'gif', 'jpeg', 'cdr', 'psd'].indexOf(file.ext.toLowerCase()) >= 0) {
          // 图片
          this.previewList = file.path.indexOf('http') === -1 ? [`${baseUrl}/${file.path.replace('_thumb', '')}`] : [file.path.replace('_thumb', '')];
          this.showPreview = true;
        } else if (['doc', 'docx', 'pdf', 'ppt', 'pptx', 'xlsx', 'xls', 'txt'].indexOf(file.ext.toLowerCase()) >= 0) {
          // 文档
          /* eslint-disable */ 
          let url = 'https://weboffice.bimcc.net?furl='
          if (baseUrl.includes('https://')) {
            url = 'https://weboffice.bimcc.net?ssl=1&furl='
          }
          if (file.path.includes('http://') || file.path.includes('https://')) {
            window.open('https://dev.bimcc.net/pc/openFile2.html?fileName='+file.name+'&url='+file.path);
          }else{
            window.open('https://dev.bimcc.net/pc/openFile2.html?fileName='+file.name+'&url='+baseUrl+'/'+file.path);
          }
          // window.open(`${url}${file.path.indexOf('http') === -1 ? baseUrl : ''}${file.path}`);
        } else if (['mp4'].indexOf(file.ext.toLowerCase()) >= 0) {
          window.open(`${file.path.indexOf('http') === -1 ? baseUrl : ''}${file.path}`);
        } else {
          this.$message.error('无法打开此格式的文件！')
        }
      }
    },
    /**
     * @desc: 关闭预览 
     */
    closePreview() {
      this.showPreview = false;
    },
    /**
     * @desc: 切换显示模式
     * @param {String} type 类型
     */
    onChangeModel(type) {
      this.viewType = type;
    }
  },
  beforeDestroy() {
    if (!this.EDITOR_pageUUID) {
      removeEventBus('databaseTrigger', this.element.id);
    }
  }
}
</script>

<style lang="less" scoped>
.common-net-disk{
  height: 100%;
  // width: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  overflow: hidden;
  .disk-main{
    overflow: hidden;
    flex: 1;
    width: 100%;
  }
}
</style>