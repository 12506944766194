<!--
 * @Description: 常规页面配置
 * @Author: luocheng
 * @Date: 2021-08-17 16:59:51
 * @LastEditors: zx
 * @LastEditTime: 2022-12-07 09:05:49
-->
<template>
	<div class="normal normalpage-container">
		<ComponentBox
			v-for="element in customComponentList"
			:key="element.id"
			:element="element"
			:isPreview="true"
			@click.native.stop="onClickBox(element)"
			:defaultTableSelectData="defaultTableSelectData"
			:componentList="customComponentList"
		></ComponentBox>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { judgingEventLogic, getStatusVal } from '@/utils/tools';

export default {
	name: 'NormalPage',
	props: {
		// 内嵌渲染页配置数据
		insidePageData: {
			required: false,
			default: null
		},
		// 表格默认选中数据
		defaultTableSelectData: {
			type: Array,
			default: () => []
		}
	},
	inject: ['EDITOR_pageUUID'],
	data() {
		return {
			componentList: null
		}
	},
	computed: {
		...mapState([
			'componentData', // 图层列表
			'sContainer',
			'originComponentData', // 大屏容器组件的元组件
			'_PageCustomStatus',
			'_APPCustomStatus',
			'subsidiaryComponentData'
		]),
		customComponentList() {
			if (this.EDITOR_pageUUID) {
				return this.subsidiaryComponentData?.[this.EDITOR_pageUUID]?.componentData || [];
			}
			return this.componentData || [];
		},
	},
	methods: {
		/**
		 * @desc: 触发点击事件
		 * @param {Object} item 点击的组件
		 *  触发方式实质就是在改变指定的组件状态
		 */
		onClickBox(item) {
			console.log('触发点击事件-----触发点击事件')
			if (
				!item.events ||
				!item.events.click ||
				!Array.isArray(item.events.click.effects) ||
				item.component === 'CommonDateButton'
			) {
				return false
			}
			const click = item.events.click || {};
			const { pattern, effects = [], specialEventList = []} = click;
			if (!pattern) {
				if (click.actionType === 'pageStatus') {
					// 修改页面状态
					this.updatePageStatus(click.stateMachine || []);
				}
				effects.forEach((ele) => {
					this.$store.commit('triggerEvents', {
						config: ele,
						element: item
					});
				});
			} else if (pattern === 'special') {
				specialEventList.forEach(ele => {
					const { actionType } = ele;
					if (actionType === 'pageStatus') {
						// 修改页面状态
						this.updatePageStatus(ele.stateMachine || []);
					}
					ele.effects.forEach(effect => {
						this.$store.commit('triggerEvents', {
							config: effect,
							element: item
						});
					})
				})
			}
			const behaviors = item?.events?.click?.behaviors || [];
			// 触发行为
			behaviors.forEach(behavior => {
				this.$store.commit('triggerEvents', {
					config: {
						behavior,
						isBehavior: true
					},
					item
				});
			});
		},
		/**
		 * @desc: 更新页面状态
		 * @param {Array} arr 页面状态列表
		 */
		updatePageStatus(arr) {
			let componentData = this.sContainer ? this.originComponentData : this.componentList;
			arr.forEach(ele => {
				if (!judgingEventLogic(ele.logics, componentData)) return;
				const { value } = getStatusVal(ele, componentData);
				this.$store.commit('updatePageCustomStatus', {
					key: ele.code,
					value
				});
			})
		},
	}
};
</script>

<style lang="less" scoped>
.normal {
	height: 100%;
	width: 100%;
	position: relative;
	background: #fff;
	margin: auto;
	transition: all 0.3s;
	overflow: hidden;
	box-sizing: border-box;
	padding: 16px;
}
</style>