/*
 * @Description: 图表
 * @Author: luocheng
 * @Date: 2021-10-15 14:52:56
 * @LastEditors: zx
 * @LastEditTime: 2022-10-17 16:45:44
 */
// 图表
const charts = {
  type: 'charts',
  title: '图表组件',
  show: true,
	order: 4,
  componentList: [
    {
      component: 'CommonChart',
      type: 'charts',
      name: '图表盒子',
      icon: 'icon-tubiao1',
      isLock: false, // 是否锁定
      needCommonStyle: true,
      propValue: '',
      span: 24,
      statusConfig: {
        displayShow: true,
        isShow: true, // 是否显示
      },
      style: {
        minHeight: 0,
        height: '100%',
        width: '',
        rotate: 0,
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 16,
        paddingRight: 16
      },
      chartConfig: {
        chartUUID: '', // 图表分析组件
      },
      paramsConfig: [], // 筛选参数
      actionConfig: []
    },
    {
      component: 'CommonProgressCurve',
      type: 'commonProgressCurve',
      types: ['dataview'],
      name: '进度曲线',
      icon: 'iconjindufenxi',
      isLock: false, // 是否锁定
      needCommonStyle: true,
      propValue: '',
      span: 24,
      statusConfig: {
        displayShow: true,
        isShow: true, // 是否显示
      },
      style: {
        minHeight: 0,
        height: 400,
        width: 500,
        rotate: 0,
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 5,
        paddingRight: 5
      },
      paramsConfig: [], // 筛选参数
      actionConfig: []
    },
    {
      component: 'CommonOverallProgress',
      type: 'commonProgressCurve',
      types: ['dataview'],
      name: '总进度曲线',
      icon: 'icona-lichengbeijindu',
      isLock: false, // 是否锁定
      needCommonStyle: true,
      propValue: '',
      span: 24,
      statusConfig: {
        displayShow: true,
        isShow: true, // 是否显示
      },
      style: {
        minHeight: 0,
        height: 400,
        width: 500,
        rotate: 0,
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 5,
        paddingRight: 5
      },
      paramsConfig: [], // 筛选参数
      actionConfig: []
    },
    {
      component: 'CommonStatisticsAnalyze', // 生产督察安全问题排列分析
      type: 'commonStatisticsAnalyze',
      name: '排列统计分析',
      types: ['dataview'],
      icon: 'icon-shuyi_tongjifenxi',
      isLock: false,
      needCommonStyle: true,
      propValue: '',
      span: 24,
      statusConfig: {
        displayShow: true,
        isShow: true,
        valueStatus: 2,
        projectLevelFilterHidden: false
      },
      style: {
        minHeight: 400,
        height: 400,
        width: 800,
        minWidth: 600,
        rotate: 0
      },
      paramsConfig: [],
      actionConfig: []
    }
  ]
};

export default charts;