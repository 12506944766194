/*
 * @Author: zx
 * @Date: 2022-09-21 10:38:39
 * @LastEditTime: 2022-09-27 09:57:05
 * @Description: 
 * @LastEditors: 朱廷果 1028509503@qq.com
 */
import Home from '@/manage-views/views/home/home'
const baseRoutes = [
  
  /* 表单整个路由 */
  {
    path: '/dwh/form',
    component: Home,
    name: 'objectFormList',
    children: [
      {
        // path: '/formList/:uuid',
        path: '/dwh/form-list',
        name: 'formList',
        component: () => import('@/views/formDesign/index.vue'),
        meta: {
          title: '对象表单列表',
          icon: 'home',
        },
      },
      {
        // path: '/formList/:uuid',
        path: '/dwh/form-test',
        name: 'formTest',
        component: () => import('@/views/formViewer/index.vue'),
        meta: {
          title: '对象表单列表',
          icon: 'home',
        },
      },
      {
        path: '/dwh/form-design-list',
        name: 'formDesignList',
        component: () => import('@/views/formViewer/formList.vue'),
        meta: {
          title: '表单设计列表',
          icon: 'home',
        },
      },
      {
        path: '/dwh/form-test-design',
        name: 'formTestDesign',
        component: () => import('@/views/formViewer/designForm.vue'),
        meta: {
          title: '表单设计',
          icon: 'home',
        },
      },
      {
        path: '/dwh/form-data-list',
        name: 'formDataList',
        component: () => import('@/components/formParser/formDataList/index.vue'),
        meta: {
          title: '表单设计',
          icon: 'home',
        },
      },
      {
        path: '/dwh/flow-design',
        name: 'flowDesign',
        component: () => import('@/components/formParser/flowEngine/index.vue'),
        meta: {
          title: '流程设计',
          icon: 'home',
        },
      },
    ],

  },
]
export default baseRoutes