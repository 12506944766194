<!--
 * @Description: 页面解析器
 * @Author: luocheng
 * @Date: 2021-09-13 15:48:38
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2023-01-06 17:32:02
-->
<template>
  <div class="parser">
    <template v-if="initEnd">
      <!-- 普通页 -->
      <RegularPage v-if="pageEditorType === 'page' || pageEditorType === 'template' || pageEditorType === 'second'"
        :insidePageData="insidePageData"
        :defaultTableSelectData="defaultTableSelectData"
        :componentList="insidePageData && insidePageData.componentData"
      ></RegularPage>
      <!-- 大屏页面 -->
      <ScreenPage v-else-if="pageEditorType === 'dataview' || pageEditorType === 'console'"
        :insidePageData="insidePageData"
        :defaultTableSelectData="defaultTableSelectData"
        :componentList="insidePageData && insidePageData.componentData"
      ></ScreenPage>
      <MobilePage v-else-if="pageEditorType === 'mobile'"></MobilePage>
    </template>
    <el-empty description="暂无有效页面数据" v-else></el-empty>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { isJSONStr } from '@/utils/tools';
import { pageFromDetail} from '@/api/saas'
import RegularPage from './page/Index';
import ScreenPage from './screen/Index';
import MobilePage from '@/mobile-components/mobilePage/Index.vue';
import { Empty } from 'element-ui';
import { deepClone } from '@topology/core';

export default {
  name: 'Parser',
  components: {
    'el-empty': Empty,
    RegularPage,
    ScreenPage,
    MobilePage
  },
  props: {
		// 表格默认选中数据
		defaultTableSelectData: {
			type: Array,
			default: () => []
		},
    // 附加页面的UUID
    pageUUID: {
      type: String,
      default: ''
    },
    // 附加页面的type类型
    pageType: {
      type: String,
      default: ''
    },
    // 是否问底层页面
    isNormal: {
      type: Boolean,
      default: false
    }
  },
  provide() {
    return {
      EDITOR_pageUUID: this.pageUUID
    }
  },
  data() {
    return {
      initEnd: false,
      insidePageData: null,
      pageEditorType: '',
      loading: false,
      count: 0
    }
  },
  computed: {
    ...mapState([
      'editorType' // 类型
    ])
  },
  watch: {
    pageUUID() {
      // this.$nextTick(()=>{

      // })
      if (this.pageUUID) {
        this.getInsideData();
      }
    }
  },
  created() {
    sessionStorage.setItem('editorType', this.pageType);
    if (this.pageUUID) {
      // 内嵌渲染器
      this.getInsideData();
    } else {
      this.pageEditorType = this.pageType || this.editorType;// vuex获取不到type时  使用传入的type类型
      try {
        const statusConfig = JSON.parse(sessionStorage.getItem('statusConfig'));
        if (statusConfig && Array.isArray(statusConfig) && statusConfig.length) {
          this.$store.commit('initPageCustomStatus', statusConfig);
        }
        this.initEnd = true;
      } catch(err) {
        console.log(err);
      }
    }
  },
  methods: {
    /**
     * @desc: 获取内嵌渲染器
     */
    getInsideData() {
      this.loading = true;
			pageFromDetail({
				__method_name__: 'dataList',
				page_uuid: this.pageUUID,
				object_uuid: 'a4f016d6-c602-4492-8874-f088c3c0b3b9',
				view_uuid: 'view61b951c6a8186',
				transcode: 0
			}).then((res) => {
        this.loading = false;
        if (!res?.data?.data?.length) {
          this.$message.error('暂无有效渲染配置！');
          return;
        }
        const { page_data } = res?.data?.data?.[0] || {};
        this.insidePageData = isJSONStr(page_data ) ? deepClone(JSON.parse(page_data)) : deepClone(page_data);
        this.pageEditorType = this.insidePageData?.editorType;
        const { componentData, editorConfig, editorType } = this.insidePageData;
        this.$store.commit('setSubsidiaryComponentData', {
          pageUUID: this.pageUUID,
          pageConfig: {
            componentData,
            editorConfig,
            editorType
          }
        })
        console.log(this.isNormal)
        // debugger
        if (this.isNormal) {
          // 兼容处理内嵌页问题
          this.$store.commit('setConfigData', {componentData, editorConfig, editorType})
          /* 暂时页面编辑器这边拿不到componentData  所以手动改动一下 */
          this.$store.commit('setComponentData', componentData)
        }
        this.initEnd = true;
      }).catch((err) => {
        console.log(err);
        this.loading = false;
        this.$message.error('获取渲染配置失败！')
      });
    }
  }
}
</script>

<style lang="less" scoped>
.parser{
  height: 100%;
  width: 100%;
}
</style>