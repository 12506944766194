<!--
 * @Description: 选择人员
 * @Author: luocheng
 * @Date: 2021-10-15 17:19:41
 * @LastEditors: luocheng
 * @LastEditTime: 2022-05-30 10:16:41
-->
<template>
	<el-drawer
		:append-to-body="true"
		:visible.sync="showSelect"
		:with-header="false"
		:direction="'rtl'"
		class="select-person"
		:before-close="onClose"
		:modal="path.indexOf('modify-page') === -1"
	>
		<article class="select-contents">
			<header class="header">
				<span class="line"></span>
				<h3 class="title">{{ statusConfig.title || '选择人员' }}</h3>
			</header>
			<div class="select-main">
				<el-input
					:placeholder="statusConfig.filterText"
          v-if="statusConfig.filter"
					v-model="filterText"
					prefix-icon="el-icon-search"
					clearable
				>
				</el-input>
				<el-tree
					class="filter-tree"
          :ref="element.id"
					:data="personList"
					:props="{
						children: statusConfig.children,
						label: statusConfig.label
					}"
					default-expand-all
					:filter-node-method="filterNode"
          :show-checkbox="true"
					:node-key="statusConfig.key"
					@check-change="onCheckChange"
				>
				</el-tree>
			</div>
			<footer class="footer">
				<el-button v-for="item in actionConfig" :key="item.key"
					:type="item.buttonType"
					@click="onAction(item)"
				>
				{{ item.text }}
				</el-button>
			</footer>
		</article>
	</el-drawer>
</template>

<script>
import { Input, Tree } from 'element-ui';
import { getComponentById } from '@/utils/tools';
import { mapState } from 'vuex';
import { dataInterface } from '@/apis/data/index';

export default {
	name: 'CommonSelectPerson',
	components: {
		'el-input': Input,
		'el-tree': Tree
	},
	props: {
		element: {
			type: Object,
			required: true,
			default: () => {}
		},
		// 是否为分组
		isGroup: {
			type: Boolean,
		},
		// 当前分组的组件数据
		groupComponents: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			// 显示
			showSelect: false,
			// 过滤
			filterText: '',
			// 人员数据
			personList: [
				{
					id: 1,
					label: '一级 1',
					children: [
						{
							id: 4,
							label: '二级 1-1',
							children: [
								{
									id: 9,
									label: '三级 1-1-1'
								},
								{
									id: 10,
									label: '三级 1-1-2'
								}
							]
						}
					]
				},
				{
					id: 2,
					label: '一级 2',
					children: [
						{
							id: 5,
							label: '二级 2-1'
						},
						{
							id: 6,
							label: '二级 2-2'
						}
					]
				},
				{
					id: 3,
					label: '一级 3',
					children: [
						{
							id: 7,
							label: '二级 3-1'
						},
						{
							id: 8,
							label: '二级 3-2'
						}
					]
				}
			],
			// 配置
			defaultProps: {
				children: 'children',
				label: 'label'
			},
      path: ''
		};
	},
	computed: {
		...mapState(['componentData']),
		// 状态
		statusConfig() {
			return this.element && this.element.statusConfig;
		},
		// 操作配置
		actionConfig() {
			return this.element && this.element.actionConfig;
		}
	},
	created() {
		this.showSelect = this.statusConfig.isShow;
    this.path = this.$route.path;
	},
  watch: {
    filterText(val) {
      this.$refs[this.element.id].filter(val);
    }
  },
	methods: {
		/**
		 * @desc: 关闭
		 */
		onClose() {
			this.element.statusConfig.isShow = false;
		},
    /**
     * @desc: 过滤
     * @param {String} value 关键字
     * @param {Object} data 每行的数据对象
     * @return {Boolean} 是否显示本行
     */
		filterNode(value, data) {
      if (!value) return true;
        return data.label.indexOf(value) !== -1;
    },
		/**
		 * @desc: 选择人员
		 * @param {Object} data 点击复选框的数据行(不含子节点)
		 * @param {Boolean} checked 是否选中状态
		 * @param {Boolean} indeterminate 是否全选
		 * @return {*}
		 */
		onCheckChange(data, checked, indeterminate) {
			console.log(data, checked, indeterminate);
		},
		/**
		 * @desc: 点击操作
		 * @param {Object} item 按钮配置对象
		 */
		onAction(item) {
			console.log(item, '99999')
			if (item && item.key === 'confirm') {
				this.onSubmit();
			} else {
				this.onCancel();
			}
		},
    /**
     * @desc: 提交
     */
    onSubmit() {
			const { actionConfig, sourceConfig } = this.element;
			const btnConfig = actionConfig.find((ele) => ele.key === 'confirm');
			if (!btnConfig || !btnConfig.effect) {
				this.onCancel();
				return;
			}
			// 附加作用一般是发送请求
			if (btnConfig.effectType === 'postData') {
				let data_id = '';
				let params = {};
				// 发送请求 整理参数
				if (btnConfig.postType === 'updateData') {
					// 编辑时候必须设置data_id
					if (!sourceConfig.componentId || !sourceConfig.field) return;
					let sourceComponent = getComponentById(this.componentData, sourceConfig.componentId);
					if (!sourceComponent && this.isGroup && this.groupComponents.length) {
						sourceComponent = getComponentById(this.groupComponents, sourceConfig.componentId);
					}
					if (!sourceComponent || !sourceComponent.resolveData) return;
					data_id = sourceComponent.resolveData[sourceConfig.field];
					if (!data_id) return;
				}
				params = {
					__method_name__: btnConfig.postType,
					object_uuid: btnConfig.objectUUID,
					data_id,
					// 表单的字段
					// ...formData
				};
				this.$loading();
				// 选中的节点
				// const nodes = this.$refs[this.element.id].getCheckedNodes(true);
				// const keys = this.$refs[this.element.id].getCheckedKeys(true);
				// 发送请求
				dataInterface(params)
					.then(() => {
						this.$loading().close();
						this.onCancel();
					})
					.catch((err) => {
						console.log(err);
						this.$loading().close();
					});
			}
      // this.showSelect = false;
      // this.onClose();
    },
    /**
     * @desc: 取消
     */
    onCancel() {
      this.showSelect = false;
      this.onClose();
    }
	}
};
</script>

<style lang="less" scoped>
.select-person {
	.select-contents {
		height: 100%;
		width: 100%;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		padding: 5px 10px;
		overflow: hidden;
		.header {
			display: flex;
			min-height: 40px;
			width: 100%;
			box-sizing: border-box;
			padding: 10px 0;
			border-bottom: 1px solid @borderColor;
			.line {
				height: 15px;
				width: 3px;
				margin-top: 3px;
				margin-right: 10px;
				background: @theme;
				border-radius: 1px;
			}
			.title {
				flex: 1;
				line-height: 20px;
				font-size: 14px;
				color: #333;
				text-align: left;
			}
		}
		.select-main {
			flex: 1;
			width: 100%;
			overflow: hidden;
			display: flex;
			flex-direction: column;
			box-sizing: border-box;
			padding: 10px 0;
			.el-tree {
        box-sizing: border-box;
        padding-top: 10px;
				flex: 1;
				width: 100%;
				overflow: hidden;
				overflow-y: auto;
			}
		}
		.footer {
			box-sizing: border-box;
			padding: 10px 0;
			text-align: right;
			border-top: 1px solid @borderColor;
		}
	}
}
</style>