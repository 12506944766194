<!--
 * @Author: 朱廷果 1028509503@qq.com
 * @Date: 2022-12-28 10:01:18
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2023-01-11 09:25:11
 * @FilePath: \dataview-next\src\manage-views\views\home\components\templatePage\AppInstall.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
	<div class="install-container">
		<div class="install-header">
			<div
				class="iconfont-div"
				:style="'background:' + module.background">
				<svg
					aria-hidden="true"
					class="iconfont-svg">
					<use :xlink:href="module.icon.indexOf('#') === -1 ? '#' + module.icon : module.icon" />
				</svg>
			</div>
			<div class="left-text">
				<h1 class="title">
					{{ module?.name }}
				</h1>
				<p>{{ module?.is_pay === 0 ? '免费版' : '付费版' }}</p>
			</div>
		</div>
		<el-divider/>
		<div class="install-body">
			<div class="body-title">安装后，应用将对可见范围内用户开放。</div>
			<div class="select-visible">
				<div class="select-title">可见范围</div>
				<div class="select-radio">
					<el-radio-group v-model="visibleRange">
						<el-radio :label="0">工作区可见</el-radio>
						<el-radio :label="1">指定架构可见</el-radio>
					</el-radio-group>
				</div>
			</div>
			<div
				class="select-visible"
				style="margin-top: 16px;padding-right: 0;"
				v-if="visibleRange === 1">
				<div class="select-title">指定架构</div>
				<div
					class="select-label"
					@click.stop="showDialog = true">
					<div
						v-if="departmentLabel"
						style="color: #000000">
						{{ departmentLabel }}
					</div>
					<div v-else>请选择</div>
					<i class="iconfont iconxuanzeqiehuan-jiantou label-icon"></i>
				</div>
			</div>
			<div class="body-foot">
				<div class="foot-title">安装应用可能需要1～2分钟，过程中您可以正常使用其他功能</div>
        <div v-if="module.num"	class="foot-btn-install" style="justify-content: center;">
          安装中
          <el-progress
          :width="12"
          color="#fff"
          define-back-color="red"
          :stroke-width="2"
          type="circle"
          :percentage="module.num"
          :show-text="false"
        ></el-progress>
        </div>

				<div
          v-else
					class="foot-btn"
					@click="handleConfirm">
					确定安装
				</div>
			</div>
		</div>
		<Department
			ref="department"
			:show="showDialog"
      :list=departmentList 
			@close="close"
			@sure="sure"></Department>
	</div>
</template>

<script>
import { Divider,Progress } from 'element-ui';
import Department from './Department.vue';
import { installApp } from '@/api/saas';
export default {
	components: {
		'el-divider': Divider,
    'el-progress':Progress,
		Department
	},
	props: {
		module: {
			type: Object,
			default: () => {}
		}
	},
	data() {
		return {
			departmentLabel: '',
			showDialog: false,
			visibleRange: 0,
      departmentList:[]
		};
	},
  mounted() {
    // 查看当前还有那些应用正在安装
    this.installList = JSON.parse(sessionStorage.getItem('appInstallList')) 
  },
	methods: {
		close() {
			this.showDialog = false;
		},
    // 点击确认后将选中的架构传给父组件
		sure(data) {
      this.departmentList = data
      this.departmentLabel = ''
      this.departmentList.forEach(item=>{
        this.departmentLabel += item.name+','
      })
      // 删除最后一个逗号
      this.departmentLabel = this.departmentLabel.slice(0,this.departmentLabel.length-1)
			this.showDialog = false;
		},
    // 安装我的应用
		handleConfirm() {
      this.$set(this.module,'num',0)
			let timer = setInterval(() => {
				this.module.num += 0.15;
				if (this.module.num > 90 || this.module.num === 100) {
					window.clearInterval(timer);
				}
			}, 100);
			if (!this.installList) {
				this.installList = [];
			}
			this.installList.push(this.module.id);
			sessionStorage.setItem('appInstallList', JSON.stringify(this.installList));
			let params = {
				app_id: this.module.id,
				visible_range: this.visibleRange,
			};
      if(this.visibleRange){
        let departmentList = this.$refs.department.departmentList
        let departmentId = ''
        departmentList.forEach(item => {
          departmentId += item.id+','
        });
        departmentId = departmentId.slice(0,departmentId.length-1)
        params.range_id = departmentId
      }
			installApp(params)
				.then(() => {
          // 返回成功后去首页监听
					this.$emit('getAppInstallStatus');
				})
				.catch((res) => {
					if (timer) {
						window.clearInterval(timer);
					}
					this.dialogVisible = false;
					this.$message(res.data.msg);
        });
		}
	},

};
</script>
<style lang="less" scoped>
.install-container {
	width: 100%;
	height: 100%;
	// z-index: 9999;
  align-items: center;
	display: flex;
	flex-direction: column;
	:deep(.el-divider--horizontal) {
		width: 960px;
		background-color: #eff1f5;
		margin: 32px 0;
	}
	.install-header {
		width: 960px;
		display: flex;
		align-items: center;
		font-family: 'Source Han Sans CN';
		padding-top: 12px;
		.iconfont-div {
			width: 72px;
			height: 72px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 14px;
			.iconfont-svg {
				width: 56px;
				height: 56px;
				background-color: transparent;
			}
		}
		.left-text {
			padding-left: 20px;
			.title {
				display: flex;
				align-items: center;
				font-style: normal;
				font-weight: 500;
				font-size: 20px;
				line-height: 28px;
				color: #161c1f;
				.cost-type {
					width: 41px;
					height: 20px;
					margin-left: 8px;
					padding: 1px 8px;
					box-sizing: border-box;
					border-radius: 4px;
					background: #76abff;
					font-weight: 500;
					font-size: 12px;
					line-height: 18px;
					letter-spacing: 0.4px;
					color: #ffffff;
				}
			}
			p {
				margin-top: 4px;
				font-weight: 400;
				font-size: 14px;
				line-height: 22px;
				letter-spacing: 0.5px;
				color: #41474c;
			}
		}
	}
	.install-body {
		width: 960px;
		.body-title {
			font-weight: 400;
			font-size: 14px;
			line-height: 22px;
			letter-spacing: 0.5px;
			color: #000000;
		}
		.body-foot {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-top: 32px;
			.foot-title {
				font-weight: 400;
				font-size: 14px;
				line-height: 22px;
				letter-spacing: 0.5px;
				color: #71787f;
			}
      .foot-btn-install{
        width: 107px;
				height: 40px;
				display: flex;
				align-items: center;
				justify-content: center;
				background: #A5C7FE;
				border-radius: 6px;
				font-weight: 500;
				font-size: 14px;
				line-height: 22px;
				color: #ffffff;
        :deep(.el-progress--circle){
          margin-left: 5px;
          .el-progress-circle__track{
            stroke: #A5C7FE !important;
          }
        }
      }
			.foot-btn {
				width: 98px;
				height: 40px;
				display: flex;
				align-items: center;
				justify-content: space-around;
				background: #337eff;
				border-radius: 6px;
				font-weight: 500;
				font-size: 14px;
				line-height: 22px;
				color: #ffffff;
			}
		}
		.select-visible {
			width: 960px;
			height: 56px;
			background: #f4f6fa;
			border-radius: 6px;
			display: flex;
			box-sizing: border-box;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			padding: 0 20px;
			margin-top: 12px;
			.select-title {
				font-weight: 500;
				font-size: 14px;
				line-height: 22px;
				letter-spacing: 0.5px;
				color: #000000;
			}
			.select-label {
				color: #a5acb3;
				font-weight: 400;
				font-size: 14px;
				display: flex;
				align-items: center;
        height: 100%;
        padding-left: 500px;
        padding-right: 20px;
			}
			.label-icon {
				color: #161c1f;
			}
		}
	}
}
</style>
