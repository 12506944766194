<template>
  <div class="currency-component-container">
    {{ timeString }}
  </div>
</template>

<script>
import Minin from './mixin'

export default {
  name: 'DayCom',
  mixins: [Minin],
  computed: {
    day () {
      // 返回星期 1-7
      return this.date.getDay()
    },
    timeString() {
      return this.formatTimeMethod(this.day)
    },
    style() {
      return {
        // fontStyle: this.italic,
        // fontFamily: this.fontFamily,
      }
    }
  },
  data() {
    return {
      date: new Date()
    }
  },
  methods: {
    // 格式化星期方法
    formatTimeMethod(day) {
      let d = day
      if (this.dayShowType === 'chinese') {
        d = this.formatChineseMethod(d)
      } else if (this.dayShowType === 'english') {
        d = this.formatEnglishMethod(d)
        return d
      }
      return this.dayFormatString.replace('${day}', d)
    },
    /**
     * @description 格式化中文 适用预日期转化
     * @param name { Number } 需要转换的数字
     * @param complement { Boolean } 是否添加进制单位
     * @return { string } 转化结果
     */
    formatChineseMethod(num, complement = false) {
      const t = ['', '十', '百', '千', '万']
      const ns = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九', '十']
      const numStringArr = (num + '').split('')
      const res = numStringArr.map((el, index) => {
        let s = ns[Number(el)]
        if (complement) {
          s += t[Number(numStringArr.length - 1 - index)]
          if (el + '' === '0') {
            return ''
          }
        }
        return s
      })
      return res.join('')
    },
    // 显示英文
    formatEnglishMethod(n) {
      const t = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
      return t[Number(n)]
    }
  }
}
</script>

<style lang="less" scoped>
  .currency-component-container{
    width: 100%;
    height: 100%;
  }
</style>