<template>
	<div class="advisory-list">
    <div class="smart-robot" @click="open()" >
      <img src="https://openim-1309784708.cos.ap-shanghai.myqcloud.com/920f991ddd49fa7de1ad32cd80c9149a.png">
    </div>
    <SmartRobotChat :showSmart="showSmart" @close="close()"></SmartRobotChat>
	</div>
</template>

<script>
import SmartRobotChat from './components/SmartRobotChat.vue'
export default {
	name: 'FixTools',
	components: {
    SmartRobotChat,
	},
	props: {},
	data() {
		return {
			// 滚动条距顶长度
			distanceY: null,
      showBot:false,
      showSmart:false
		};
	},
	mounted() {},
	methods: {
    close(){
      this.showSmart = false
    },
	open() {
		this.showSmart =!this.showSmart
	},
    // showSmart(){

    // },
		goApplyDemo() {
			this.$router.push({ name: 'ApplyDemo' });
		},
		// 回到网页顶部
		clickHandler() {
			this.$nextTick(() => {
				let timer = setInterval(function () {
					this.distanceY = document.getElementById('content').scrollTop;
					if (this.distanceY == 0) {
						clearInterval(timer);
						return;
					}
					let speed = Math.ceil(this.distanceY / 16);
					document.getElementById('content').scrollTop = this.distanceY - speed;
				}, 16);
			});
		}
	}
};
</script>

<style lang="less" scoped>
.qr-code-box {
	text-align: center;
}
.iphone {
	.title {
		padding-bottom: 5px;
		box-sizing: border-box;
		font-size: 14px;
	}
	.iphone-number {
		color: #3571e9;
		font-size: 16px;
		font-weight: bold;
	}
}
.advisory-list {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 32%;
	right: 10px;
	width: 72px;
	height: 298px;
	border-radius: 4px;
	z-index: 998;
	animation-name: translateAnimation;
	animation-duration: 1s;
	animation-iteration-count: 1;
	@keyframes translateAnimation {
		0% {
			transform: translateX(100px);
		}
		100% {
			transform: translateX(0);
		}
	}
  .smart-robot{
    width: 63px;
    height:100px;
    position: absolute;
    top: -100px;
    right: -6px;
    img{
      width: 100%;
      height: 100%;
    }
  }
	.bs-contact-us {
		background: #fff;
		border-radius: 4px;
		.contact-button {
			display: flex;
			flex-direction: column;
			align-items: center;
			display: -webkit-box;
			display: -ms-flexbox;
			display: -webkit-flex;
			position: relative;
			width: 72px;
			height: 72px;
			padding: 30px 0;
			box-sizing: border-box;
			text-align: center;
			cursor: pointer;
			border-radius: 4px;
			-webkit-box-pack: center;
			-ms-flex-pack: center;
			-webkit-justify-content: center;
			justify-content: center;
			-webkit-box-orient: vertical;
			-webkit-box-direction: normal;
			-webkit-flex-direction: column;
			-ms-flex-direction: column;
			-webkit-transition: background-color 218ms;
			transition: background-color 218ms;
			i {
				padding-bottom: 10px;
				box-sizing: border-box;
				color: #3571e9;
				font-size: 22px;
			}
			span {
				font-size: 12px;
				line-height: 10px;
			}
		}
		.contact-button:hover {
			background: #3571e9;
			i {
				color: #fff;
			}
			span {
				color: #fff;
			}
		}
	}
	.contact-button {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		position: relative;
		width: 72px;
		height: 72px;
		background: #fff;
		text-align: center;
		cursor: pointer;
		border-radius: 4px;
		-webkit-transition: background-color 218ms;
		transition: background-color 218ms;
		/*动画效果*/
		outline: none;
		transition: all 0.3s ease-in-out;
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		-o-transition: all 0.3s ease-in-out;
		-ms-transition: all 0.3s ease-in-out;
		i {
			padding-bottom: 10px;
			box-sizing: border-box;
			color: #3571e9;
			font-size: 36px;
		}
	}
	.contact-button:hover {
		background: #3571e9;
		i {
			color: #fff;
		}
		span {
			color: #fff;
		}
	}
}
</style>
