/*
 * @Description: 混入
 * @Author: luocheng
 * @Date: 2022-01-07 09:33:53
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2023-01-06 17:34:17
 */
import ChartContainer from './common/chart';
import { dataInterface } from '@/apis/data';
import { pageFromDetail } from '@/api/saas'
import { Empty } from 'vant';
import { getComponentById, getQueryValue } from '@/utils/tools';
import { mapState } from 'vuex';

export default {
  props: {
    // 高度
    height: {
      type: Number|String,
      required: true,
      default: 0
    },
    // 图表UUID
    chartUUID: {
      type: String,
      default: '',
      required: true
    },
    // 图表数据
    chartData: {
      type: Object,
      default: () => {},
      required: true
    },
    // 参数配置
    paramsConfig: {
      type: Array,
      default: () => [],
      required: false
    },
    // 是否在组合内
    isGroup: {
			type: Boolean,
		},
    // 组合内组件列表
		groupComponents: {
			type: Array,
			default: () => []
		},
    scaleWidth: {
      type: Number,
			default: 1
    },
    scaleHeight: {
      type: Number,
			default: 1
    }
  },
  components: {
    ChartContainer,
    'van-empty': Empty
  },
  data() {
    return {
      chartConfig: null,
      getting: false,
      // loadingBackColor:"rgba(0, 0, 0, 0.8)"
      // todo 是否放到配置项里面去？
      loadingBackColor:""
    }
  },
  computed: {
    ...mapState(['componentData']),
    sourceType() {
      return this.$store.state.chart?.chartData?.sourceType;
    }
  },
  methods: {
    /**
     * @desc: 获取配置项
     */
    async getOptions() {
      if (this.getting && this.chartConfig) {
        return;
      }
      if (!this.chartUUID) {
        return;
      }
      this.getting = true;
      if (!this.chartConfig) {
        this.chartConfig = await this.getChartConfig(this.chartUUID);
      }
      if (!this.chartConfig) {
        this.getting = false;
        return;
      }
      const pageChartData = this.chartConfig?.page_data?.chartData;
      const sourceType = pageChartData?.sourceType || this.sourceType;
      if (sourceType === 'static') {
        console.log('静态配置-----')
        // 静态配置
        this.getStaticData(pageChartData);
      } else if (pageChartData?.sourceType === 'custom') {
        // 自定义接口统计
        this.getCustomData(pageChartData)
      } else if(pageChartData?.sourceType === 'fillData') {
        if(!this.chartData || JSON.stringify(pageChartData) === JSON.stringify(this.chartData)) {
          this.getFillData(pageChartData)
        } else {
          // 当本地与请求不一样时，加载本地配置以预览
          this.getFillData(this.chartData)
        }
      } else {
        // 动态统计
        console.log('动态统计-----')
        const { param, search, canPost = false } = this.getQuery();
        if (!canPost && window.location.href.indexOf('/config-chart?id=') === -1) {
          this.getting = false;
          return false;
        }
        const statisticalData = await this.getStatisticalData(param, search);
        if (!statisticalData || !statisticalData.length) {
          return false;
        }
        this.setOptions(statisticalData);
        this.getting = false;
      }
    },
    /**
     * @desc: 获取静态统计数据
     * @param {Object} config
     */
    async getStaticData(config) {
      const { staticConfig = [] } = config;
      const chartLineData = [];
      for (let i = 0; i < staticConfig.length; i++) {
        const ele = staticConfig[i];
        const { objectUUID, viewUUID, x_fields = [], y_fields = [] } = ele || {};
        if (!objectUUID || !viewUUID || !Array.isArray(x_fields), !Array.isArray(y_fields)) {
          this.getting = false;
          return;
        }
        const { param, search, canPost = false } = this.getQuery();
        if (!canPost) {
          this.getting = false;
          return;
        }
        const dataList = await this.getDataList({
          object_uuid: objectUUID,
          view_uuid: viewUUID
        },
          param,
          search
        );
        if (!dataList || !Array.isArray(dataList)) {
          this.getting = false;
          return
        }
        if (config.type === 'ring') {
          // 百分比环图
          const names = x_fields.map(ele => {
            return ele.name;
          });
          let process = 0, totalData = 0;
          if (dataList.length) {
            process = dataList[0]?.[x_fields[0]?.uuid];
            totalData = dataList[0]?.[y_fields[0]?.uuid];
          }
          this.setOptions({
            names,
            process,
            totalData
          });
        } else if (config.type === 'completionRatio') {
          // 柱状进度图
          this.setOptions(dataList?.[0]);
        } else if (config.type === 'barLine') {
          // 折柱混合
          const xData = [], yData = [];
          dataList.forEach(dataItem => {
            xData.push(dataItem?.[x_fields[0]?.uuid])
            yData.push(+dataItem?.[y_fields[0]?.uuid])
          });
          chartLineData.push({
            chartType: ele.chartType,
            names: [''],
            xData,
            series: [{
              data: yData,
              name: ''
            }]
          });
        } else if (config.type === 'pointer') {
          // 仪表盘
          const {
            totalField,
            totalType,
            valueField,
            valueType
          } = config?.staticConfig?.[0]?.pointer;
          let total = 100, value = 0;
          if (totalType === 'fixed') {
            total = totalField;
          } else {
            total = dataList?.[0]?.[totalField] || 0;
          }
          if (valueType === 'fixed') {
            value = valueField;
          } else {
            value = dataList?.[0]?.[valueField] || 0;
          }
          this.setOptions({
            total,
            value
          });
        } else {
          let series = [], xData = [];
          const names = y_fields.map(ele => {
            series.push({
              name: ele.name,
              uuid: ele.uuid,
              data: []
            });
            return ele.name;
          });
          dataList.forEach(ele => {
            series = series.map(item => {
              const data = item.data;
              data.push(isNaN(+ele[item.uuid]) ? 0 : +ele[item.uuid]);
              return {
                ...item,
                data
              };
            });
            xData.push(ele[x_fields[0]?.uuid]);
            this.setOptions([
              {
                names,
                xData,
                series
              }
            ]);
          });
        }
      }
      if (config.type === 'barLine') {
        this.setOptions(chartLineData, true);
      }
      this.getting = false;
      return;
    },
    /**
     * @desc: 获取静态统计数据列表
     */
    getDataList(config, param, search, __method_name__ = 'dataList') {
      return new Promise((resolve, reject) => {
        dataInterface({
          __method_name__,
          ...config,
          ...param,
          search
        }).then(res => {
          resolve(res?.data?.data || []);
        }).catch(err => {
          console.log(err, '-----');
          reject([]);
        });
      });
    },
    /**
     * @desc: 获取图表配置
     */
    getChartConfig(page_uuid) {
      return new Promise((resolve, reject) => {
        pageFromDetail ({
          __method_name__: 'dataList',
          object_uuid: "a4f016d6-c602-4492-8874-f088c3c0b3b9",
          page_uuid,
          transcode: 0,
          view_uuid: "view61b951c6a8186"
        }).then(res => {
          resolve(res?.data?.data?.[0]);
        }).catch(err => {
          console.log(err);
          reject([]);
        });
      });
    },
    /**
     * @desc: 获取查询参数以及是否可以操作
     * 需要对search进行合并
     *
     * paramsConfig<ConfigItem>
     *
     * paramType: '', // 请求类型
     * code: '', //  字段code
     * uuid: '', // 字段UUID
     * isRequired: false, // 是否必填字段
     * originType: 'component', // 参数来源. 组件 component， URL参数 url
     * componentId: '', // 来源组件 Id
     * componentKey: '', // 组件字段key
     * urlKey: '', // url参数的key
     * ruleType: '' // 逻辑类型
     */
    getQuery() {
      // 是否可以请求
      let canPost = true;
      if (!this.paramsConfig || !this.paramsConfig.length)
        return {
          canPost
        };
      let search = [];
      const param = {};
      for (let i = 0; i < this.paramsConfig.length; i++) {
        const {
          paramType, code, uuid, isRequired, originType, componentId, urlKey, ruleType, componentKey, fixedValue
        } = this.paramsConfig[i];
        let paramsVal = '';
        if (originType === 'url') {
          // url参数
          paramsVal = getQueryValue(urlKey);
          if (isRequired && !paramsVal) {
            canPost = false;
            break;
          }
        } else if (originType === 'component') {
          // 组件参数
          let originComponent = getComponentById(this.componentData, componentId);
          if (!originComponent && this.isGroup && this.groupComponents.length) {
            originComponent = getComponentById(this.groupComponents, componentId);
          }
          if(!originComponent) {
            console.error(`查询的组件id不存在！， 请检测组件ID:${componentId}`)
          }
          if (isRequired && (!originComponent || !originComponent.resolveData)) {
            canPost = false;
            break;
          }
          if (componentKey) {
            paramsVal = originComponent?.resolveData?.[componentKey];
          } else {
            paramsVal = originComponent?.resolveData;
          }
        } else if (originType === 'fixed') {
          // 固定值
          paramsVal = fixedValue;
        }
        if (paramType === 'param') {
          // 普通请求参数
          // 写入params
          if (code) {
            this.$set(param, code, paramsVal);
          } else if (uuid) {
            this.$set(param, uuid, paramsVal);
          } else {
            canPost = false;
            // console.log('参数配置出错')
            break;
          }
        } else if (paramType === 'search') {
          if (Array.isArray(paramsVal) && this.validSearch(paramsVal)) {
            search = search.concat(paramsVal);
          } else if (uuid) {
            search.push({
              field_uuid: uuid,
              ruleType,
              value: paramsVal
            });
          } else if (code) {
            search = search.concat({
              code,
              ruleType,
              value: paramsVal
            });
          } else {
            canPost = false;
            console.log('参数配置出错');
            break;
          }
        }
      }
      return { search, param, canPost };
    },
    /**
     * @desc: 是否为合法的search
     * @param {Array} arr
     */
    validSearch(arr) {
      if (!arr || !Array.isArray(arr) || !arr.length)
        return true;
      for (let i = 0, len = arr.length; i < len; i++) {
        const item = arr[i];
        if (Object.prototype.toString.call(item) !== ['object Object']) {
          return false;
        }
      }
      return true;
    },
    /**
     * @desc: 获取统计数据
     */
    getStatisticalData(param = {}, search = []) {
      return new Promise((resolve, reject) => {
        dataInterface({
          __method_name__: 'globalFunctionCall',
          typeName: 'PublicFunc',
          type: 'value',
          funcName: 'CommonAnalyse',
          payload: {
            page_uuid: this.chartUUID
          },
          search,
          ...param
        }).then(res => {
          if (res && res.status === 200 && res.data && res.data.code === 200) {
            resolve(res.data.data);
          } else {
            reject(null);
          }
        }).catch(err => {
          console.log(err);
          reject(null);
        });
      });
    },
    /**
     * @desc: 图表位置
     * @param {Object} attributes 属性配置
     * @return {Object} 位置信息
     */
    getLegendPosition(attributes) {
      return {
        left: attributes.legendLeft ?? 'auto',
        right: attributes.legendRight ?? 'auto',
        top: attributes.legendTop ?? 'auto',
        bottom: attributes.legendBottom ?? 'auto'
      };
    },
    /**
     * @desc: 获取自定义配置数据
     * @param {Object} data
     */
    async getCustomData(config) {
      const chartLineData = [];
      const { customConfig = {} } = config;
      const { xDataConfig = [], legendConfig = [], bindConfig = [] } = customConfig;
      if (!bindConfig.length) {
        this.$message.error('暂无可用配置');
        return;
      }
      for (let i = 0; i < bindConfig.length; i++) {
        const ele = bindConfig[i];
        const { objectUUID, viewUUID, methodName = 'dataList', bindLegend = '', valueConfig = [] } = ele || {};
        if (!objectUUID || !viewUUID) {
          this.getting = false;
          return;
        }
        const { param, search, canPost = false } = this.getQuery();
        if (!canPost) {
          this.getting = false;
          return;
        }
        const dataList = await this.getDataList({
          object_uuid: objectUUID,
          view_uuid: viewUUID
        },
          param,
          search,
          methodName
        );
        if (!dataList || !Array.isArray(dataList)) {
          this.getting = false;
          return;
        }
        if (config.type === 'ring') {
          // 百分比环图
          // TO DO
        } else if (config.type === 'completionRatio') {
          // 柱状进度图
          // TO DO
        } else if (config.type === 'barLine') {
          // 折柱混合
          const xData = xDataConfig, yData = [];
          // 取列表第一个
          let valueObject = null;
          if (Array.isArray(dataList) && dataList.length) {
            valueObject = dataList[0];
          }
          if (!valueObject) return;
          valueConfig.forEach(dataItem => {
            yData.push(isNaN(+valueObject[dataItem.fieldUUID]) ? 0 : +valueObject[dataItem.fieldUUID]);
          });
          chartLineData.push({
            chartType: ele.chartType,
            names: legendConfig,
            xData,
            series: [{
              data: yData,
              name: bindLegend
            }]
          });
        } else {
          // TO DO
        }
      }
      if (config.type === 'barLine') {
        this.setOptions(chartLineData, true);
      }
      this.getting = false;
      return;
    },
    getFillData(config) {
      const chartLineData = [];
      const { fillConfig = [] } = config;
      if(config.type === 'barLine' || config.type === 'bar' || config.type === 'line') {
        fillConfig.forEach(ele => {
          const obj = {
            chartType: ele.chartType,
            names: ele.data.data.map(elee => elee.name),
            xData: ele.data.col.map(elee => elee.name),
            series: ele.data.data.map(elee => {
              return {
                name: elee.name,
                data: ele.data.col.map(eleee => elee[eleee.fild])
              }
            })
          }
          chartLineData.push(obj);
        })
        this.setOptions(chartLineData, true);
      } else if (config.type === 'pie') {
        const data = []
        fillConfig.forEach(ele => {
          const obj = {}
          ele.data.col.forEach(elee => {
            if(Array.isArray(ele.data.data) && ele.data.data.length) {
              obj[elee.name] = ele.data.data[0][elee.fild]
            }
          })
          data.push(obj)
        })
        if(data.length) {
          this.setOptions(data);
        }
      } else if( config.type === 'ring') {
        if(fillConfig.length) {
          const obj = {
            names: [fillConfig[0].data.data[0].name],
            process: fillConfig[0].data.data[0][fillConfig[0].data.col[0].fild],
            totalData: fillConfig[0].data.data[0][fillConfig[0].data.col[1].fild]
          }
          this.setOptions(obj);
        }
      }
      this.getting = false;
      return;
    },
    /**
     * @desc: 缩放适配2/4K屏幕
     * @param {Number} num
     */
    scaleSize(num) {
      if (isNaN(+num)) {
        return num;
      }
			// const ratio = window.devicePixelRatio;
      const ratio = 1;
      return ((+num) * this.scaleWidth * ratio).toFixed(2);
    },
    scaleHeightSize(num) {
      if (isNaN(+num)) {
        return num;
      }
			// const ratio = window.devicePixelRatio;
      const ratio = 1;
      return ((+num) * this.scaleHeight * ratio).toFixed(2);
    }
  }
}