<!--
 * @Author: zx
 * @Date: 2022-05-25 15:26:23
 * @LastEditTime: 2022-07-25 18:17:09
 * @Description: 
 * @LastEditors: zx
-->
<template>
  <div class="search">
    <div class="route">
      <div class="top-back" @click="routerBack">
        <i class="iconfont iconxiangzuojiantou1" />
      </div>
      <div class="top-middle" v-if="element.statusConfig.showTitle">
        <p v-if="element.titleConfig && element.titleConfig.topTitleType==='onlyTitle'">{{element.propValue}}</p>
        <div class="tab-wrap" v-if="element.titleConfig && element.titleConfig.topTitleType==='tab'">
          <van-tabs style="width: 100%" v-model="activeTab" @change="changeTab">
            <van-tab v-for="(item,index) in tabs" :key="item.type" :name="index">
              <template #title>
                <div style="margin-bottom: 10px">{{item.title}}</div>
              </template>
              <!-- 内容 {{ index }} -->
            </van-tab>
          </van-tabs>
        </div>
      </div>
      <div class="top-right">
        <div v-if="element.statusConfig.showSearch">
          <i class="iconfont iconshaixuan1" @click="searchClick()"/>
        </div>
      </div>
    </div>
    <div v-if="element.statusConfig.mobileSearchInput" class="search-input">
      <div class="searchInput">
          <div class="placeholder" v-if="!!!materialSearch">
              <img src="@/assets/images/mobile-select/search.png" alt="" width="24" height="24">
              搜索
          </div>
          <input v-model="materialSearch" style="background-color: unset;" @keyup.enter="searchData">
          <img class="search-icon" v-show="materialSearch" @click="searchData" src="@/assets/images/mobile-select/search.png" alt="" width="24" height="24">
      </div>
    </div>
  </div>
</template>
<script>
  import { toRefs, reactive, onMounted, defineComponent, computed, watch, nextTick } from 'vue'
  import { dataInterface } from '@/apis/data';
  import eventBus from '@/plugins/eventBus';
  import { Row, Col, Tabs, Tab } from 'vant'
  import useStore from '@/store'
  import { Message } from 'element-ui';
  import { judgingEventLogic } from '@/utils/tools';

  export default defineComponent({
    components: {
      'van-row': Row,
      'van-col': Col,
      'van-tabs': Tabs,
      'van-tab': Tab
    },
    props: {
      element: {
        type: Object,
        default: () => {},
        require: true
      }
    },
    setup(props) {
      const store = useStore
      const componentData = computed(() => {
        return store.state['componentData']
      })
      const state = reactive({
        fieldsForView: [],
        filterForm: {},
        lastSearch: [],
        showScreen: false,
        tabs:[],
        tabConfig:[],
        activeTab:'2',
        materialSearch: ''
      })
      watch(()=>props.element,(element)=>{
        if(element && element.titleConfig && element.titleConfig.topTitleType==='tab'){
          const { tabs } = element.titleConfig;
          state.tabs = tabs;
          state.tabConfig = element.tabConfig;
        }
      },{
        immediate:true,
        deep:true
      })
      const routerBack = ()=>{
        if (props.element && props.element.actionConfig && props.element.actionConfig.rowClick && props.element.actionConfig.rowClick.eventList) {
          const eventList = props.element.actionConfig.rowClick.eventList
          if (eventList && eventList.length) {
            eventList.forEach((el) => {
              el.eventList.forEach(ele => {
                if (ele.key === 'click') {
                  ele.effects.forEach((effect) => {
                    let logicBoolean = true
                    if (effect.logics.length) {
                      logicBoolean = judgingEventLogic(effect.logics, componentData || []);
                    }
                    if (logicBoolean) {
                      store.commit('triggerEvents', {
                        config: {
                          ...ele,
                        ...effect
                        },
                        element: props.element
                      });
                    }
                  });
                }
              })
            });
          }
          return
        }
        const { routeBackConfig } = props.element
        if (routeBackConfig.topBackType === 'beforePage') {
          Message({
            message: '返回触发，编辑器暂时不显示！',
            type: 'success'
          })
        } else if(routeBackConfig.topBackType === 'homePage'){
          Message({
            message: '跳转至移动端首页，编辑器暂时不显示！',
            type: 'success'
          })
        } else if(routeBackConfig.topBackType === 'fixedRoute'){
          Message({
            message: '跳转至指定页面，编辑器暂时不显示！',
            type: 'success'
          })
        } else if(routeBackConfig.topBackType === 'appointPage'){
          eventBus.$emit('mobileRefreshPage', routeBackConfig.appointPageUuid);
        }
      }
      // tab 事件触发
      const changeTab =(name)=>{
        state.activeTab = name
        const index = +name;
				const comEvents = state.tabConfig[index]?.eventList || [];
				for (let i = 0; i <comEvents.length; i++) {
					const { pattern, eventList = [], specialEventList = [] } = comEvents[i];
					if (pattern === undefined) {
						if (comEvents[i].key === 'click') {
							comEvents[i].effects.forEach((effect) => {
                let logicBoolean = true
                if (effect.logics.length) {
                  logicBoolean = judgingEventLogic(effect.logics, componentData || []);
                }
                if (logicBoolean) {
                  store.commit('triggerEvents', {
                    config: {
                      ...comEvents[i],
                      ...effect
                    },
                    element: props.element
                  });
                }
							});
						}
						break;
					}
					const result = pattern === 'special' ? specialEventList : eventList
					result.forEach((ele) => {
						if (ele.key === 'click') {
							ele.effects.forEach((effect) => {
                let logicBoolean = true
                if (effect.logics.length) {
                  logicBoolean = judgingEventLogic(effect.logics, componentData || []);
                }
                if (logicBoolean) {
                  store.commit('triggerEvents', {
                    config: {
                      ...ele,
                    ...effect
                    },
                    element: props.element
                  });
                }
							});
						}
					});
				}
        let resolveData = null;
        resolveData = {
					label: state.tabConfig[index].title,
					value: state.tabConfig[index].type
				};
        props.element.resolveData = resolveData;
        store.commit('modifyComponent', {
          component: {
            ...props.element,
            database: {
              ...props.element.database,
              fieldList:[
                {
                  name: 'tab值',
                  uuid: 'value'
                }
              ],
              metadata: {
                value: 'tab值'
              }
            },
            metadata: {
              value: 'tab值'
            },
            resolveData
          },
          containerId: null,
          isModify: true
        });
        // 触发事件
        // event bus  挂载卸载问题
        setTimeout(() => {
          eventBus.$emit('databaseTrigger', {
            componentId: props.element.id,
            action: 'any',
            output: resolveData
          });
        }, 0);
        console.log(props.element, '1111111111111')
      }
      const fieldList = computed(() => {
        return (
          (props.element &&
            props.element.database &&
            props.element.database.fieldList) ||
          []
        );
      })
      
      // 配置
      const statusConfig = computed(() => {
        return props.element.statusConfig;
      })
      watch(
        () => fieldList,
        (newVal) => {
          console.log(newVal,'newVal');
          state.fieldsForView = [];
          if (!fieldList || !fieldList.length) return [];
          const result = [];
          // 暂不做校验
          fieldList.forEach((ele) => {
            if (ele.viewConfig.showInFilter) {
              result.push(ele);
            }
          });
          
          state.fieldsForView = result || [];
          // console.log(fieldList, '-----fieldList', state.fieldsForView);
          // 处理字段
          state.filterForm = {};
          let initSearch = false;
          state.fieldsForView.forEach(async (ele, index, arr) => {
            let defaultVal = '';
            let selectOptions = []
            // tabs 筛选默认选中
            if (ele.viewConfig && (ele.viewConfig.type === 'tabs' || ele.viewConfig.type === 'select')) {
              const { optionOrign, options = [], optionConfig} = ele.viewConfig;
              if (optionOrign === 'fixed') {
                defaultVal = options.find(ele => ele.defaultSelect)?.value;
                initSearch = true;
                selectOptions = options;
                ele.selectOptions = selectOptions
              } else if (optionOrign === 'database') {
                await getDataOptions(optionConfig, ele, index, JSON.parse(JSON.stringify(arr)));
                if (optionConfig.popAll) {
                  initSearch = true;
                }
              }
            }
            state.filterForm[ele.uuid] = defaultVal
          });
          nextTick(() => {
            if (initSearch) {
              // 基本避免请求覆盖问题
              setTimeout(() => {
                onResolve();
              }, 1000)
            }
          });
        }
      )
       
      /**
       * @desc: 数仓options
       * @param {*} optionConfig
       * @param {*} itemData
       * @param {*} index
       */
      const getDataOptions = (optionConfig, itemData, index) => {
        return new Promise((resolve, reject) => {
          dataInterface({
            __method_name__: 'dataList',
            object_uuid: optionConfig.objectUUID,
            view_uuid: optionConfig.viewUUID,
          }).then(res => {
            const data = res?.data?.data || [];
            const selectOptions = data.map(ele => {
              return {
                label: ele[optionConfig.labelCode],
                value: ele[optionConfig.valueCode]
              }
            });
            if (optionConfig.popAll) {
              selectOptions.unshift({
                label: '全部',
                value: ''
              })
            }
            try {
              state.fieldsForView.splice(index, 1, {
                ...itemData,
                viewConfig: {
                  ...itemData.viewConfig,
                  selectOptions
                }
              })
              if (optionConfig.defaultSelect) {
                const defaultVal = selectOptions[0].value || '';
                state.filterForm[itemData.uuid] = defaultVal
              }
              resolve()
            } catch (err) {
              console.log(err);
            }
          }).catch(err => {
            console.log(err);
            reject(null)
          })
        })
      }
      /**
       * @desc: 暴露数据
       */
      const onResolve = () => {
        const resolveData = [];
        state.fieldsForView.forEach((ele) => {
          const value = state.filterForm[ele.uuid] || '';
          let valueFilter = value;
          if (Array.isArray(value) && value.length === 2) {
            // 时间段
            valueFilter = formatDuring(value)
          } else if (ele.viewConfig.ruleType === 'in') {
            // 包含
            if (value && value.indexOf('[') !== 0 && value.indexOf(']') !== value.length - 1) {
              valueFilter = value !== '' && value.split(',')
            } else {
              valueFilter = value
            }
          }
          resolveData.push({
            field_uuid: ele.uuid,
            ruleType: ele.viewConfig.ruleType,
            value: valueFilter
          });
        });
        props.element.resolveData = resolveData;
        // 自动提交
        if (statusConfig.quickSearch) {
          onSearch(false);
        }
      }
      /**
       * @desc: 格式化时间段
       */
      const formatDuring = (timeArr) => {
        if (Array.isArray(timeArr) && timeArr.length === 2) {
          const item = timeArr[1];
          if (typeof item !== 'object' && item.toString().indexOf('00:00:00') > -1) {
            const result = JSON.parse(JSON.stringify(timeArr));
            result.splice(1, 1, timeArr[1].toString().replace('00:00:00', '23:59:59'));
            return result;
          }
        }
        return timeArr || '';
      }
      /**
       * @desc: 提交过滤查询
       */
      const onSearch = (resolveData = true) => {
        const search = [];
        state.fieldsForView.forEach((ele) => {
          const value = state.filterForm[ele.uuid] || '';
          let valueFilter = value;
          if (Array.isArray(value) && value.length === 2) {
            // 时间段
            valueFilter = formatDuring(value)
          } else if (ele.viewConfig.ruleType === 'in') {
            // 包含
            valueFilter = value.split(',')
          }
          search.push({
            field_uuid: ele.uuid,
            ruleType: ele.viewConfig.ruleType,
            // 老的处理逻辑下行
            // value: Array.isArray(value) && value.length === 2 ? state.formatDuring(value) : value
            value: valueFilter
          });
        });
        // 需要暴露数据
        if (resolveData) {
          onResolve();
        }
        if (JSON.stringify(state.lastSearch) === JSON.stringify(search)) {
          return;
        }
        state.lastSearch = search || [];
        // 提交
        eventBus.$emit('databaseTrigger', {
          componentId: props.element.id,
          action: 'list',
          isSearch: true,
          output: {
            search
          }
        });
      }
      const searchClick = () => {
        eventBus.$emit('openMobileSearchPop', state.fieldsForView);
      }
      const searchData = () => {
        eventBus.$emit('routeTopInputSearch', state.materialSearch);
      }
      onMounted(() => {
        if(props.element.titleConfig && props.element.titleConfig.topTitleType && props.element.titleConfig.topTitleType==='tab'){
          const { tabs } = props.element.titleConfig;
          state.tabs = tabs;
          if(tabs && tabs.length){
            tabs.map((item,index)=>{
              if(item.isDefault){
                state.activeTab = item.type;
                changeTab(index);
              }
            })
          }
        }
      })
      return {
        ...toRefs(state),
        searchClick,
        changeTab,
        routerBack,
        searchData
      }
    },
  })
</script>
<style lang="less">
.route{
  .van-tab--active{
    font-weight: 550;
  }
  .van-tabs__line{
    top: 27px;
    height: 6px;
    background: linear-gradient(to right, #4199FE , #4EB3F6);
    opacity: 0.8;
    // width: calc(100% - 140px);
    width: 50%;
    border-radius: unset;
  }
}
</style>
<style lang='less' scoped>
.search {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  .route {
    width: 100%;
    display: flex;
    position: relative;
    width: 100%;
    height: 44px;
    .top-back {
      width: 16%;
      max-width: 16%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      i {
        font-size: 24px;
      }
    }
    .top-middle {
      flex: 1;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: 550;
      font-size: 17px;
    }
    .top-right {
      width: 16%;
      max-width: 16%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      div {
        padding: 0 5px;
        i {
          font-size: 18px;
          font-weight: 600;
        }
      }
    }
  }
  .search-input {
    width: 100%;
    height: 44px;
    padding: 0 8px 8px 8px;
    box-sizing: border-box;
    .searchInput{
        width: calc(100% - 18px);
        margin-left:9px;
        height:36px;
        background-color: white;
        border-radius: 6px;
        border:1px solid rgba(114,126,143,0.2);
        position: relative;
        .search-icon{
            position:absolute;
            right:5px;
            top:5px;
            z-index:10;
        }
    }
    .searchInput div{
        position:absolute;
        left: 0;
        z-index: 1;
        height: 100%;
        width: calc(100% - 20px);
        text-align: center;
        font-size:17px;
        font-weight:400;
        line-height: 38px;
        color:rgba(125,129,138,1);
    }
    .searchInput div img{
        vertical-align: middle;
        width: 24px;
        margin-top:-3px;
    }
    .searchInput input{
        position: absolute;
        left: 0;
        z-index: 2;
        margin-left:10px;
        font-size:17px;
        font-weight:400;
        height: calc(100% - 2px);;
        width: calc(100% - 20px);
        border: 0;
    }
  }
}
.tab-wrap{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  .tabs{
    width:240px;
    height:28px;
    color:#878D96;
    .tab{
        line-height: 26px;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
    }
  }
  .activeTab{
    color:#121619;
    position: relative;
    ::after{
        content: "";
        position: absolute;
        top: calc(100% - 8px);
        left: 20px;
        width: calc(100% - 40px);
        height: 6px;
        background: linear-gradient(to right, #4199FE , #4EB3F6);
        opacity: 0.8;
    }
  }
}
</style>
