<!--
    @name: widget-input
    @description：widget-input
    @author: ZengWei
    @date: 2022-03-25 09:25
-->
<template>
  <el-input
    v-if="!(disabled || element.config.__config__.openRule || element.config.disabled)"
    v-model="element.value"
    :disabled="disabled || element.config.__config__.openRule || element.config.disabled"
    :placeholder="element.config.placeholder"
    :maxlength="element.config.maxlength"
    @change="formatPrecision">
    <template slot="prepend" v-if="element.config.__slot__.prepend">
      {{ element.config.__slot__.prepend }}
    </template>
    <template slot="append" v-if="element.config.__slot__.append">
      {{ element.config.__slot__.append }}
    </template>
  </el-input>
  <p class="reshow-text" v-else>{{ element.value || '-' }}</p>
</template>

<script>
import {Input} from "element-ui";
import formItemMinix from "@/custom-component/form/newParser/scripts/formItemMinix";

export default {
  name: "WidgetInput",
  components: {
    'el-input': Input
  },
  mixins: [formItemMinix],
  props: ['element', 'disabled', 'displayData'],
  data() {
    return {}
  },
  methods: {
    formatPrecision(val) {
      if (this.element.config.precision) {
        let precision = this.element.config.precision;
        let value = Number(val).toFixed(precision);
        this.element.value = value;
      }
    },
  },
}
</script>

<style scoped>

</style>
