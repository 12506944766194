<!--
    @name: input_item
    @description：input_item
    @author: ZengWei
    @date: 2021-09-15 11:49
-->
<template>
	<!-- eslint-disable -->
	<div>
		<template v-if="element.type == 'input'">
			<el-input
				v-if="!element.config.disabled"
				v-model="element.value"
        :maxlength="element.config.maxlength"
				:disabled="disabled || element.config.__config__.openRule || element.config.disabled"
				:placeholder="element.config.placeholder"
        @change="formatPrecision"
			>
        <template slot="prepend" v-if="element.config.__slot__.prepend">{{element.config.__slot__.prepend}}</template>
        <template slot="append" v-if="element.config.__slot__.append">{{ element.config.__slot__.append }}</template>
      </el-input>
			<span v-else>{{ element.value }}</span>
		</template>

		<el-input
			v-else-if="element.type == 'textarea'"
			v-model="element.value"
      type="textarea"
      :maxlength="element.config.maxlength"
			:rows="3"
			:placeholder="element.config.placeholder"
			:disabled="disabled || element.config.disabled"
		></el-input>

		<el-input
			v-else-if="element.type == 'password'"
			v-model="element.value"
			type="password"
			:placeholder="element.config.placeholder"
			:disabled="disabled || element.config.disabled"
		></el-input>

		<el-input-number
			v-else-if="element.type == 'number'"
			v-model="element.value"
			:disabled="disabled || element.config.disabled"
		></el-input-number>

    <template v-else-if="element.type == 'rich-text'">
      <Editor v-if="!(disabled || element.config.disabled)" id="tinymce" :init="setting" :model-value="element.value" />
      <div v-else v-html="element.value"></div>
    </template>

    <div v-else-if="element.type == 'select'" style="position: relative">
      <div :class="{'outer-viewer':element.config.__config__.outerViewer}">
        <el-select
          v-model="element.value"
          :disabled="disabled || element.config.disabled"
          :placeholder="element.config.placeholder"
          :clearable="element.config.clearable"
          :multiple="element.config.multiple"
          :filterable="element.config.filterable"
          @change="triggerInteractive"
        >
          <el-option
            v-for="(val, key) in element.config.__slot__.options"
            :value="'' + val.value"
            :label="val.label"
            :key="key"
          >{{ val.label }}
          </el-option>
        </el-select>
      </div>
      <el-button
        type="primary"
        v-if="element.config.__config__.outerViewer"
        style="position: absolute;top:0;right: 0;"
        @click="openOuterViewer"
      >查看</el-button>
    </div>

		<el-cascader
			v-else-if="element.type == 'cascader'"
			v-model="element.value"
			:disabled="disabled || element.config.disabled"
			:options="element.config.options"
			:placeholder="element.config.placeholder"
			:clearable="element.config.clearable"
			:filterable="element.config.filterable"
		></el-cascader>

		<el-radio-group
			v-else-if="element.type == 'radio'"
			v-model="element.value"
			:disabled="disabled || element.config.disabled"
			@change="triggerInteractive"
		>
			<template v-if="element.config.__config__.optionType === 'default'">
				<el-radio
					v-for="(val, key) in element.config.__slot__.options"
					:label="'' + val.value"
					:key="key"
				>{{ val.label }}</el-radio>
			</template>
			<template v-else>
				<el-radio-button
					v-for="(val, key) in element.config.__slot__.options"
					:label="'' + val.value"
					:key="key"
				>{{ val.label }}</el-radio-button>
			</template>
		</el-radio-group>

		<el-checkbox-group
			v-else-if="element.type == 'checkbox'"
			v-model="element.value"
			:disabled="disabled || element.config.disabled"
		>
			<el-checkbox
				v-for="(val, key) in element.config.__slot__.options"
				:label="'' + val.value"
				:key="key"
			>{{ val.label }}</el-checkbox>
		</el-checkbox-group>

		<el-switch
			v-else-if="element.type == 'switch'"
			v-model="element.value"
			:disabled="disabled"
			@change="triggerInteractive"
		></el-switch>

		<el-slider
			v-else-if="element.type == 'slider'"
			v-model="element.value"
			:disabled="disabled || element.config.disabled"
			:min="element.config.min"
			:max="element.config.max"
			:step="element.config.step"
		></el-slider>

		<el-time-select
			v-else-if="element.type === 'time'"
			v-model="element.value"
			:disabled="disabled || element.config.disabled"
			:type="element.config.type"
			:format="element.config.format"
      :value-format="element.config['value-format']"
			:placeholder="element.config.placeholder"
			style="width: 100%"
		>
		</el-time-select>

		<el-time-picker
			v-else-if="element.type === 'time-range'"
			v-model="element.value"
			is-range
			:disabled="disabled || element.config.disabled"
			:range-separator="element.config['range-separator']"
			:start-placeholder="element.config['start-placeholder']"
			:end-placeholder="element.config['end-placeholder']"
      :value-format="element.config['value-format']"
			:placeholder="element.config.placeholder"
			style="width: 100%"
		>
		</el-time-picker>

		<el-date-picker
			v-else-if="['date', 'date-range'].indexOf(element.type) > -1"
			v-model="element.value"
			:disabled="disabled || element.config.disabled"
			:type="element.config.type"
      :range-separator="element.config['range-separator']"
			:format="element.config.format"
      :value-format="element.config['value-format']"
			:placeholder="element.config.placeholder"
			style="width: 100%"
		></el-date-picker>

		<el-rate
			v-else-if="element.type == 'rate'"
			v-model="element.value"
			:show-text="element.config['show-text']"
			:allow-half="element.config['allow-half']"
			:count="element.config.max"
			:disabled="disabled || element.config.disabled"
		></el-rate>

		<el-color-picker v-else-if="element.type == 'color'" v-model="element.value" recommend></el-color-picker>

		<uploader
			v-if="element.type == 'upload'"
			:disabled="disabled"
			:files="element.value"
      :fileTypeList="fileTypeList"
			@extra-files="onSuccess"
		>
		</uploader>

		<div v-else-if="element.type === 'user'">
			<select-user
        :key="JSON.stringify(element.value)"
				v-if="element.config.__config__.userData"
				:data-info="element.config.__config__.userData"
        :show-list="element.value.isArray ? element.value : []"
				@on-select-change="onSelectChange"
			/>
		</div>

		<div v-else-if="element.type === 'signature'">
			<div>
				<el-popover placement="right" width="100">
					<Button style="margin: 10px" @click="generateQrcode">
						<i class="iconfont iconfenbaoshenhe"></i>&nbsp;&nbsp;&nbsp;签名签章
					</Button>
					<div slot="content">
						<img v-if="qrCode" :src="qrCode" alt width="100" height="100" />
					</div>
				</el-popover>
			</div>
			<img v-for="item in element.value" :src="item" width="130" height="80" style="margin: 10px" />
		</div>

		<div v-else-if="element.type === 'database'">
			<el-table ref="multipleTable" :data="relateData" border>
				<el-table-column align="center" width="65">
					<template slot="header" slot-scope="scope">
						<el-button v-if="!disabled" type="primary" size="mini" icon="el-icon-notebook-2" @click="openSelectRelate"></el-button>
					</template>
					<template slot-scope="scope">{{ scope.$index + 1 }}</template>
				</el-table-column>
				<el-table-column
					v-for="(item,index) in element.config.__slot__.options"
					:key="index"
					:label="item.label"
					:prop="item.value"
				></el-table-column>
				<el-table-column v-if="!disabled" label="操作" align="center" :width="element.config.__config__.detailShow? 130 : 60">
					<template slot-scope="scope">
						<div style="display: flex;justify-content: space-around">
							<div v-if="element.config.__config__.detailShow">
								<el-button type="text" size="mini" icon="el-icon-view">详情</el-button>
							</div>
							<div><i class="el-icon-remove-outline remove" @click="relateData.splice(scope.$index,1)"></i></div>
						</div>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<div v-else-if="element.type === 'outerForm'">
			<el-button
				type="primary"
				icon="el-icon-search"
				@click="openOuterForm(element)"
			>打开{{ element.config.__config__.label }}</el-button>
		</div>

    <div v-else-if="element.type === 'qrCode'">
      <img v-if="element.value" :src="element.value" style="height: 100px;width: 100px" alt="">
      <span v-else style="color: #0076ff"> 暂未生成二维码</span>
    </div>

    <div v-else-if="element.type === 'bimList'">
      <ChooseModel
        ref="bimListRef"
        @set-data="setData"
        :modelData="element.value && element.value != null? element.value: []">
        <template v-slot:showType>
          <el-button
            style="width: 100%"
            @click="openModelDrawer('bimListRef')"
            size="medium"
            type="primary"
            v-html="
          element.value && element.value != null && element.value.length != 0
          ? '已选择': '请选择'"
          ></el-button>
        </template>
      </ChooseModel>
    </div>
		<div v-else-if="element.type === 'drawingList'">
      <ChooseDrawing
        ref="drawingListRef"
        @set-data="setData"
        :drawingData="element.value && element.value != null? element.value: []">
        <template v-slot:showType>
          <el-button
            style="width: 100%"
            @click="openModelDrawer('drawingListRef')"
            size="medium"
            type="primary"
            v-html="element.value && element.value != null && element.value.length != 0
          ? '已选择': '请选择'"
          ></el-button>
        </template>
      </ChooseDrawing>
    </div>
		<div v-else-if="element.type === 'modelView'">
      <ChooseModel
        ref="modelViewRef"
				chooseType="view"
        @set-data="setData"
        :modelData="element.value && element.value != null? element.value: []">
        <template v-slot:showType>
          <el-button
            style="width: 100%"
            @click="openModelDrawer('modelViewRef')"
            size="medium"
            type="primary"
            v-html="
          element.value && element.value != null && element.value.length != 0
          ? '已选择': '请选择'"
          ></el-button>
        </template>
      </ChooseModel>
    </div>

		<span
			v-else-if="['bimConsole', 'gisConsole'].indexOf(element.type) > -1"
			v-model="element.value"
			style="float: right; margin: 12px 10px 0"
		>
			{{ element.value == "" ? "未选择" : "已选择" }}
			<i class="iconfont iconxuanzeqiehuan-jiantou"></i>
		</span>

    <template v-else-if="element.type === 'money'">
      <el-input
        type="number"
        :style="element.config.style"
        v-if="!element.config.disabled"
        v-model="element.value"
        :disabled="disabled || element.config.__config__.openRule || element.config.disabled"
        :placeholder="element.config.placeholder"
        @change="formatPrecision"
      >
        <template slot="prepend" v-if="element.config.__slot__.prepend">{{element.config.__slot__.prepend}}</template>
        <template slot="append" v-if="element.config.__slot__.append">{{ element.config.__slot__.append }}</template>
      </el-input>
      <span style="margin-left: 8px">
        <b style="color: red;">RMB：</b>
        <el-tag size="small">{{moneyToCny(element.value)}}</el-tag>
      </span>
    </template>

		<div v-else-if="element.type === 'treeSelect'">
			<span style="display: none">{{ treeSelectLabel }}</span>
			<el-select
        ref="select"
				v-model="treeSelectValue"
				placeholder="请选择"
				:multiple="element.config.props.props.multiple"
				collapse-tags
				:disabled="disabled"
        @visible-change="visibleChange"
			>
				<el-option :value="element.value" class="tree-select-option is-current">
					<el-tree
						ref="treeSelect"
						:data="element.config.options"
						:default-expand-all="true"
						:show-checkbox="element.config.props.props.multiple"
						node-key="id"
						highlight-current
            :expand-on-click-node="false"
						@node-click="treeSelectClick"
						@check-change="handleCheckChange"
					></el-tree>
				</el-option>
			</el-select>
		</div>

		<template v-else-if="element.type === 'position'">
			<SelectPosition
        v-model="element.value"
        :subId="element.config.__config__.cascade"
        :isCheckType="element.config.multiple"
        :subIdNecessary="element.config.__config__.cascadeOpen"
        :disabled="disabled || element.config.disabled"
        @change="positionChange"
      />
		</template>

		<el-dialog title="选择关联数据" :visible.sync="relateVisible" width="80%" append-to-body>
			<div style="height: 70vh" v-if="relateObjectUUid && relateViewUUid">
				<relateData
					:obuuid="relateObjectUUid"
					:view_uuid="relateViewUUid"
					@select-relate="selectRelateResult"
				></relateData>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="relateVisible = false">取 消</el-button>
				<el-button type="primary" @click="relateVisible = false">确 定</el-button>
			</div>
		</el-dialog>

    <el-dialog
      title="外部内容查看"
      :visible.sync="outerViewerVisible"
      :width="outerViewerWidth"
      append-to-body
    >
      <div v-loading="outerViewerLoading" style="height: calc(100vh - 200px)">
        <iframe
          ref="iframe"
          :src="outerRouter"
          style="width: 100%; height: 100%"
          frameborder="0"></iframe>
      </div>
    </el-dialog>
	</div>
</template>

<script>
/* eslint-disable */
import tinymce from 'tinymce/tinymce'
import Editor from '@tinymce/tinymce-vue'
import 'tinymce/themes/silver' // 编辑器主题，不引入则报错
import 'tinymce/icons/default' // 引入编辑器图标icon，不引入则不显示对应图标
import 'tinymce/skins/ui/oxide/skin.min.css'
import 'tinymce/skins/ui/oxide/content.inline.min.css'
import 'tinymce/skins/ui/oxide/skin.shadowdom.min.css'
import '@/assets/zh_CN'

// 引入编辑器插件（基本免费插件都在这儿了）
import 'tinymce/plugins/advlist' // 高级列表
import 'tinymce/plugins/anchor' // 锚点
import 'tinymce/plugins/autolink' // 自动链接
import 'tinymce/plugins/autoresize' // 编辑器高度自适应,注：plugins里引入此插件时，Init里设置的height将失效
import 'tinymce/plugins/autosave' // 自动存稿
import 'tinymce/plugins/charmap' // 特殊字符
import 'tinymce/plugins/code' // 编辑源码
import 'tinymce/plugins/codesample' // 代码示例
import 'tinymce/plugins/directionality' // 文字方向
import 'tinymce/plugins/emoticons' // 表情
import 'tinymce/plugins/fullpage' // 文档属性
import 'tinymce/plugins/fullscreen' // 全屏
import 'tinymce/plugins/help' // 帮助
import 'tinymce/plugins/hr' // 水平分割线
import 'tinymce/plugins/image' // 插入编辑图片
import 'tinymce/plugins/importcss' // 引入css
import 'tinymce/plugins/insertdatetime' // 插入日期时间
import 'tinymce/plugins/link' // 超链接
import 'tinymce/plugins/lists' // 列表插件
import 'tinymce/plugins/media' // 插入编辑媒体
import 'tinymce/plugins/nonbreaking' // 插入不间断空格
import 'tinymce/plugins/pagebreak' // 插入分页符
import 'tinymce/plugins/paste' // 粘贴插件
import 'tinymce/plugins/preview'// 预览
import 'tinymce/plugins/print'// 打印
import 'tinymce/plugins/quickbars' // 快速工具栏
import 'tinymce/plugins/save' // 保存
import 'tinymce/plugins/searchreplace' // 查找替换
import 'tinymce/plugins/tabfocus' // 切入切出，按tab键切出编辑器，切入页面其他输入框中
import 'tinymce/plugins/table' // 表格
import 'tinymce/plugins/template' // 内容模板
import 'tinymce/plugins/textcolor' // 文字颜色
import 'tinymce/plugins/textpattern' // 快速排版
import 'tinymce/plugins/toc' // 目录生成器
import 'tinymce/plugins/visualblocks' // 显示元素范围
import 'tinymce/plugins/visualchars' // 显示不可见字符
import 'tinymce/plugins/wordcount' // 字数统计

import axios from "axios";
import { getToken, baseUrl } from "@/plugins/util";
import { formRequest } from "@/apis/data/form";
import relateData from "./relateData";
import selectUser from "./selectUser/index";
import {
	Cascader, Checkbox, CheckboxGroup, DatePicker,
  Dialog, Drawer, Radio, RadioButton, RadioGroup,
  Slider, Switch, Table, TableColumn, TimePicker,
  Tree, Upload, Popover, InputNumber
} from 'element-ui';
import uploader from "@/components/fileUpload/newFiles/index";
import ChooseModel from "@/components/bindModel/ButtonType.vue";
import ChooseDrawing from "@/components/bindDrawing/ButtonType.vue";
import {numToCny} from "./scripts/tools";
import SelectPosition from "./selectPosition/index";

export default {
	name: "input_item",
	components: {
		relateData,
		selectUser,
		uploader,
    ChooseModel,
		ChooseDrawing,
		SelectPosition,
    Editor,
		'el-date-picker': DatePicker,
		'el-time-select': TimePicker,
		'el-slider': Slider,
		'el-cascader': Cascader,
		'el-radio': Radio,
		'el-radio-group': RadioGroup,
		'el-radio-button': RadioButton,
		'el-checkbox-group': CheckboxGroup,
		'el-checkbox': Checkbox,
		'el-switch': Switch,
		'el-table': Table,
		'el-table-column': TableColumn,
		'el-drawer': Drawer,
		'el-dialog': Dialog,
		'el-tree': Tree,
		'el-upload': Upload,
    'el-popover': Popover,
    'el-time-picker': TimePicker,
    'el-input-number':InputNumber
	},
	props: {
		nodeElement: {
			type: Object,
		},
		disabled: {
			type: Boolean,
		},
		firstIndex: {
			type: Number,
		},
		secondIndex: {
			type: Number,
		},
		threeIndex: {
			type: [Number, String],
		},
	},
	data() {
		return {
			qrCode: "",
			qrToken: "",
			timer: null,
			signImg: null,
			token: getToken(),
			baseUrl: baseUrl(),
			element: this.nodeElement,
			setting: {
        language: 'zh_CN',
        menubar: false, // 禁用菜单栏
        plugins: 'print preview searchreplace fullscreen autolink image link media code' +
          ' codesample table charmap hr pagebreak nonbreaking advlist lists textpattern help',
        toolbar: `undo redo restoredraft
        |formatselect
        |bold italic
        |alignleft aligncenter alignright alignjustify
        |bullist numlist
        |removeformat
        |fontselect fontsizeselect forecolor backcolor lineheight
        |underline strikethrough link autolink blockquote superscript subscript outdent indent
        |table image media hr codesample insertdatetime visualblocks
        |code preview searchreplace fullscreen`,
        toolbar_mode: 'sliding',
        placeholder: '请输入正文',
        branding: false,
        autosave_ask_before_unload: false,
        font_formats: '微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;' +
          '苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;' +
          'Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;' +
          'Comic Sans MS=comic sans ms,sans-serif;Courier New=courier new,courier;Georgia=georgia,palatino;' +
          'Helvetica=helvetica;Impact=impact,chicago;Symbol=symbol;Tahoma=tahoma,arial,helvetica,sans-serif;' +
          'Terminal=terminal,monaco;Times New Roman=times new roman,times;Verdana=verdana,geneva;Webdings=webdings;' +
          'Wingdings=wingdings,zapf dingbats;' +
          '知乎配置=BlinkMacSystemFont, Helvetica Neue, PingFang SC, Microsoft YaHei, ' +
          'Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei, sans-serif;' +
          '小米配置=Helvetica Neue,Helvetica,Arial,Microsoft Yahei,Hiragino Sans GB,Heiti SC,WenQuanYi Micro Hei,sans-serif',
        fontsize_formats: '12px 14px 16px 18px 24px 36px 48px 56px 72px',
        formats: {
          h1: { block: 'h1', classes: 'aiming-point' },
          h2: { block: 'h2', classes: 'aiming-point' },
          h3: { block: 'h3', classes: 'aiming-point' },
          h4: { block: 'h4', classes: 'aiming-point' },
          h5: { block: 'h5', classes: 'aiming-point' }
        },
        // 图片上传
        images_upload_handler: function (blobInfo, success, failure) {
          if (blobInfo.blob().size / 1024 / 1024 > 2) {
            failure('上传失败，图片大小请控制在 2M 以内')
          } else {
            const data = new FormData()
            data.append('file', blobInfo.blob())
            const headers = {
              'Content-Type': 'multipart/form-data',
              metadatatoken: process.env.VUE_APP_METATOKEN
            }
            formRequest('post','/api/mapi?__method_name__=file', data, headers).then(res => {
              if (res.code === 200) {
                success(res.data.url.replace('_thumb', ''))
              } else {
                failure('上传失败')
              }
            })
          }
        },
        height: 350
      },
			fileList: [],
			relateVisible: false,
			relateObjectUUid: null,
			relateViewUUid: null,
			relateData: [],
			outerFormType: 2,
			outerFormVisible: false,
			outerFormTitle: '外部表单',
			treeSelectValue: '',

      outerViewerVisible: false,
      outerViewerLoading: false,
      outerViewerWidth: '100%',
      outerViewerParam: {},
      outerRouter: '',
		};
	},
  inject: ["parser"],
	created() {
    this.element = this.nodeElement;
		if (this.element.type == 'upload') {
			this.fileList = this.element.value
		}
	},
  mounted() {
    if(['radio','switch','select'].includes(this.element.type)){
      this.triggerInteractive(this.element.value)
    }
    if (this.element.type === 'position'){
      const casFormId = this.element.config?.__config__?.cascadeFormId
      if(casFormId){
        // 部位依赖筛选
        const cascade = this.parser.getFieldValue(casFormId,true)
        this.element.config.__config__.cascade = cascade
      }
    }
    tinymce.init({})
  },
	watch: {
		element: {
			handler(newV) {
				this.$emit('change-value', this.firstIndex, this.secondIndex, this.threeIndex, newV.value)
			},
			deep: true
		}
	},
	computed: {
    fileTypeList(){
      const type = this.element.config.accept.split(',')
      const typeArr = type.filter(item => {return item && item.trim()})
      return typeArr
    },
		treeSelectLabel() {
			const value = this.element.value;
			const treeData = this.element.config.options;
			let labelData;
			value instanceof Array ? labelData = [] : labelData = '';
			function getTree(data) {
				for (let item of data) {
					if (value instanceof Array && value.includes(item.id)) {
						labelData.push(item.label)
					} else if (value == item.id) {
						labelData = item.label
					}
					if (item.children && item.children.length > 0) {
						getTree(item.children)
					}
				}
			}
			getTree(treeData);
			this.treeSelectValue = labelData
			return labelData;
		},
    moneyToCny(){
      return function(money){
        let unit = this.element.config.__slot__.append
        if(unit === '万' || unit === '万元'){
          money = parseFloat(money)*10000
        }
        return numToCny(money)
      }
    },
	},
	methods: {
    positionChange(position){
      if(position instanceof Array && position.length){
				console.log(position,'position')
				let modelArr = []
				if(position && position.length!=0){
					for(let i = 0; i < position.length; i++){
						if(position[i].correlation_model && position[i].correlation_model!=null){
							modelArr.push(position[i].correlation_model)
						}
					}
					// if(modelArr.length){
						this.$emit('trigger-active',{},'positionModel',modelArr)
					// }
				}
        // const modelArr = Array.from(position,item=>item.correlation_model)
       
      }
    },
    openOuterViewer(){
      this.outerViewerVisible = true
      let route = this.element.config.__config__.outerRouter
      const width = this.element.config.__config__.windowWidth
      if(width) this.outerViewerWidth = width
      const obj = this.outerViewerParam
      const keys = Object.keys(obj)
      const regex = /\{(.+?)\}/g
      const arr = route.match(regex)
      if(arr !== null){
        for (let item of arr){
          let param = item.replace('{','').replace('}','')
          if(keys.includes(param)){
            route = route.replace(item,obj[param])
          }
        }
      }
      this.outerRouter = window.location.origin + route
      this.outerViewerLoading = true
      this.$nextTick(()=>{
        this.$refs.iframe.onload = () => {
          this.outerViewerLoading = false
        }
      })
    },
    formatPrecision(val){
      if(this.element.config.precision){
        let precision = this.element.config.precision;
        let value = Number(val).toFixed(precision);
        this.element.value = value;
      }
    },
    visibleChange(e) {
      if(e) {
        let selectDom = document.querySelector('.is-current')
        setTimeout(() => {
          this.$refs.select.scrollToOption({$el: selectDom})
        },0)
      }
    },
    openModelDrawer(name) {
      this.$nextTick(()=>{
        this.$refs[name].show = true;
      })
    },
    setData(data){
      console.log(data,'数据')
      this.element.value = data;
    },
		onSelectChange(userData){
			let users = []
			for (let item of userData) {
				let user = {
					id: item.user_id,
					name: item.name,
					user_table: item.user_table,
					user_id:item.user_id,
          section_id:item.section_id,
				}
				users.push(user)
			}
			this.element.value = users;
		},
		handleCheckChange() {
			let res = this.$refs.treeSelect.getCheckedNodes(true, true);
			let arr = [];
			res.forEach(item => {
				arr.push(item.id)
			});
			this.element.value = arr;
		},
		treeSelectClick(data) {
			if (!this.element.config.props.props.multiple) {
				// this.treeSelectValue = data.label
				this.element.value = data.id.toString()
        // treeSelect 单选事件填充
        this.$emit('trigger-active', {}, 'selectFill', data.allField)
        // treeSelect 部位参数
        this.$emit('trigger-active', [], 'cascader', this.element.value)
			}
		},
		openOuterForm(element){
      let index={first:this.firstIndex,second:this.secondIndex,three:this.threeIndex}
			this.$emit('open-form',element,index)
		},
		openSelectRelate() {
			this.relateObjectUUid = this.element.config.__config__.objectUuid
			this.relateViewUUid = this.element.config.__config__.viewUuid
			this.relateVisible = true
		},
		selectRelateResult(idKey, rows) {
			this.relateData = rows;
			const relateId = Array.from(this.relateData, item => item[idKey]);
			// const config = this.element.config.__config__;
			// const relationship = {
			// 	type: config.dataType,
			// 	objectUuid: config.objectUuid,
			// 	viewUuid: config.viewUuid,
			// 	relationship: config.relationship || '',
			// 	relateId: relateId,
			// }
			this.element.value = relateId;
		},
		//触发交互事件
		async triggerInteractive(val) {
			const interact = this.element.config.__config__.interact;
			const effect = interact.effect;
			const condition = interact.condition;
			if (effect === 'showhide') {
        let trueData = condition.filter(item => {
          if(typeof item.condV === 'string' && item.condV.indexOf(',')){
            let arr = item.condV.split(',');
            return arr.includes(val) || arr.includes(val.toString())
          }
          return item.condV == val
        })
        let compList = Array.from(condition, item => item.comp).flat();
        let compShowHide = {};
        for (let item of compList){
          compShowHide[item] = false;
        }
        if (trueData.length) {
          for (let eleItem of trueData){
            let compShow = eleItem.comp;
            for (let item of compShow){
              compShowHide[item] = true;
            }
          }
        }
        this.$emit('trigger-active', compShowHide);
			}
			if (effect === 'cascader') {
        if(interact.objectUuid && interact.viewUuid){
          let url, params, options = [];
          url = '/api/mapi';
          params = {
            __method_name__: 'dataList',
            object_uuid: interact.objectUuid,
            view_uuid: interact.viewUuid,
          };
          params[interact.param] = val;
          let response = await formRequest('post', url, params);
          let userData = response.data.data;
          let label = interact.label;
          let value = interact.value;
          if (userData instanceof Array) {
            for (let element of userData) {
              if (element[label] && element[value]) {
                let item = { label: element[label], value: element[value] };
                options.push(item);
              }
            }
          }
          let comp = Array.from(condition, item => item.comp).flat()
          this.$emit('trigger-active', comp, 'cascader', options)
        } else {
          // 部位组件级联参数值
          this.$emit('trigger-active', [], 'cascader', val)
        }
			}
			const tag = this.element.config.__config__.tag;
			if (tag === 'el-select') {
        this.$emit('auto-fill-relate',this.element.config.__config__.formId);
				const dataType = this.element.config.__config__.dataType;
				const multi = this.element.config.multiple;
				const options = this.element.config.__slot__.options;
				const index = options.findIndex(item => item.value == val);
				const selectData = options[index]
				if (!selectData) return;
				const keys = Object.keys(selectData)
        if(keys.includes('allField')){
          this.outerViewerParam = selectData.allField
        }
				if (keys.includes('allField') && multi === false && dataType === 'dynamic') {
					this.$emit('trigger-active', {}, 'selectFill', selectData.allField)
				}
			}
		},
		onSuccess(files){
			this.element.value = files;
		},
		cadListData(value, element) {
			element.value = value;
		},
		dealWithData(val, index, nn, ii) {
			this.$emit("on-callback", val, index, nn, ii);
		},
		generateQrcode() {
			clearInterval(this.timer);
			axios
				.request({
					url: this.baseUrl + "/api/generate/signature",
					data: { token: this.token },
					method: "post",
				})
				.then((res) => {
					if (res.data.code === 200) {
						this.qrCode = res.data.data.qrcode;
						this.qrToken = res.data.data.token;
						let that = this;
						this.timer = setInterval(() => {
							that.getAppSignature();
						}, 1000);
					}
				});
		},
		getAppSignature() {
			axios
				.request({
					url: this.baseUrl + "/api/generate/signature",
					params: { token: this.token, qrToken: this.qrToken },
					method: "get",
				})
				.then((res) => {
					if (res.data.code === 200) {
						this.signImg = res.data.data.signature;
						if (this.signImg !== null) {
							this.element.value.push(this.signImg);
							clearInterval(this.timer);
						}
					}
				});
		},
	},
	destroyed() {
		clearInterval(this.timer);
	},
};
</script>

<style lang="less" scoped>
.outer-viewer{
  padding-right: 80px;
}
.tree-select-option {
  height: auto;
	background: #ffffff !important;
  :deep(.el-tree) {
    padding-bottom: 15px;
    .el-tree-node__label {
      font-weight: normal;
    }
  }
}
.remove {
	color: #e83525;
	cursor: pointer;
	font-size: 23px;
}
</style>
