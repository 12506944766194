<template>
    <div class="waring-list-warp" v-loading="loading">
        <div class="list-item" v-for="(item, index) in list" :key="index" @click="handleItemClick(item)">
            <div class="title-warp">
                <span class="status" :class="flterStatus(item.alert_level).class">{{ flterStatus(item.alert_level).text}}</span>
                <span class="title">【{{ item.alert_type || '其他' }}预警】{{ item.alert_text }}</span>
            </div>
            <div class="info-warp">
                <span class="project-info">项目：{{ item.archi_name ? item.archi_name : '-' }}</span>
                <span class="time">{{filterTime(item.alert_time)}}</span>
            </div>
        </div>
        <Empty v-if="list.length === 0" description="暂无数据"  :image-size="100"></Empty>
        <WarringDialog
        :warringData ='warringData'
        :dialogVisible ='dialogVisible'
        @onSave="onSave"
        @onClose="onClose"
        ></WarringDialog>
    </div>
</template>
<script>
import { formatTime } from '@/utils/tools';
import { Empty } from 'element-ui'
import WarringDialog from "./WarringDialog.vue"
export default {
    components: {
        Empty,
        WarringDialog
    },
    props: {
        list: {
            type: Array,
            default () {
                return []
            },
            require: true
        },
        loading: {
            type: Boolean,
            default: false
        },
        isend: {
            type: Boolean,
            default: false
        },
    }, 
    data() {
        return {
          warringData:{},
          dialogVisible:false,
        }
    },
    methods: {

        onClose(){
          this.dialogVisible = false
        },
        onSave(){
          this.dialogVisible = false
          this.$emit('refresh')
        },
        handleItemClick(item) {
          this.dialogVisible = true
          this.warringData = item
        },
        flterStatus(text = '') {
            if(text.length === 0) {
                return {
                text: '-',
                class: ''
                }
            }
            const str = text[0]
            const classHash = {
                '黄': 'y-status',
                '橙': 'o-status',
                '蓝': 'b-status',
                '红': 'r-status'
            }
            return {
                text: classHash[str] ? `${str}色预警` : '其他预警',
                class: classHash[str] || 'other-status'
            }
        },
        filterTime(time) {
            const targetDate = new Date(time)
            if(isNaN(targetDate.getTime())) {
                return ''
            } else {
                return this.timeFormat(time)
            }
        },
        // 时间格式化
        timeFormat (time) {
            if (!this.nowDateObj) {
                this.nowDateObj = new Date();
            }
            const targetDate = new Date(time);
            const ny = this.nowDateObj.getFullYear();
            const ty = targetDate.getFullYear();
            const nm = this.nowDateObj.getMonth();
            const tm = targetDate.getMonth();
            if (ny === ty) {
                if (nm === tm) {
                    return `${formatTime(targetDate, "MM/dd HH:mm")}`
                } else {
                    return `${formatTime(targetDate, "MM/dd HH:mm")}`;
                    }
            } else {
                return `${formatTime(targetDate, "yyyy/MM/dd")}`
            }
        }
    }
}
</script>
<style lang="less" scoped>
.waring-list-warp{
    width: 100%;
    height: 100%;
    overflow-y: auto;
    .list-item{
        width: 100%;
        padding: 12px 10px;
        box-sizing: border-box;
        cursor: pointer;
        &:hover{
            background: #f5f5f5;
            border-radius: 3px;
        }
        .title-warp{
            width: 100%;
            display: flex;
            align-items: center;
            .status{
                height: 18px;
                border-radius: 2px;
                padding: 0 6px;
                box-sizing: border-box;
                font-size: 14px;
                font-family: YouSheBiaoTiHei;
                line-height: 18px;
                -webkit-text-stroke: 0px #FFEECC;
            }
            .r-status{
                background: #FFE6E6;
                color: #FF5C5C;
            }
            .o-status{
                background: #FFE7D4;
                color: #FF8826;
            }
            .b-status{
                background: #E0E8FF;
                color: #5C87FF;
            }
            .y-status{
                background: hsla(40, 100%, 50%, .2);
                color: #FFAA00;
            }
            .other-status{
                background: rgba(77, 77, 77, .2);
                color: #777;
            }
            .title{
                flex-grow: 1;
                width: 10px;
                margin-left: 2px;
                height: 22px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #2A2F3D;
                line-height: 22px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .is-readed{
                color: #6D768F;
            }
        }
        .info-warp{
            margin-top: 4px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .project-info{
                height: 18px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #6D768F;
                line-height: 18px;
            }
            .time{
                height: 18px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #6D768F;
                line-height: 18px;
            }
        }
    }
}
</style>
