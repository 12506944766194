/*
 * @Description: 
 * @Author: luocheng
 * @Date: 2022-08-16 15:42:39
 * @LastEditors: zqy
 * @LastEditTime: 2022-10-12 11:11:13
 */
import AppDownload from '@/views/appConfig/Index';
import AppConfig from '@/views/appConfig/Config';

const baseRoutes = [
  {
    path: '/knowledge/shower',
    name: 'KnowledgeShower',
    component: () => import('@/manage-views/views/knowledgeBase/shower/index.vue'),
    meta: {
      title: '知识库详情',
      icon: 'iconrichangpeixunguanli',
      hideInMenu: true
    },
  },
  {
		path: '/app-download',
		meta: {
			title: 'App下载'
		},
		name: 'AppDownload',
		component: AppDownload
	},
  {
		path: '/app-config',
		meta: {
			title: 'App配置'
		},
		name: 'AppConfig',
		component: AppConfig
	},
]
export default baseRoutes;