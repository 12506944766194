<!--
    @name: widget-time-range
    @description：widget-time-range
    @author: ZengWei
    @date: 2022-03-25 09:37
-->
<template>
  <div>
    <el-time-picker
      v-if="!(disabled || element.config.disabled)"
      v-model="element.value"
      is-range
      :disabled="disabled || element.config.disabled"
      :range-separator="element.config['range-separator']"
      :start-placeholder="element.config['start-placeholder']"
      :end-placeholder="element.config['end-placeholder']"
      :value-format="element.config['value-format']"
      :placeholder="element.config.placeholder"
      style="width: 100%"
    >
    </el-time-picker>
    <p class="reshow-text" v-else>{{ element.value || '-' }}</p>
  </div>
</template>

<script>
import {TimePicker} from "element-ui";
import formItemMinix from "@/custom-component/form/newParser/scripts/formItemMinix";

export default {
  name: "WidgetTimeRange",
  components: {
    'el-time-picker':TimePicker
  },
  mixins: [formItemMinix],
  props: ['element', 'disabled', 'displayData'],
  data() {
    return {}
  },
  computed: {
    reShowVal() {
      let res = '';
      if(Array.isArray(this.element.value)) {
        res = this.element.value.map(el => {
          if(el) {
              return el;
          } else {
            return '-';
          }
        }).join('-');
      }
      return res || '-';
    } 
  },
  methods: {},
}
</script>

<style scoped>

</style>
