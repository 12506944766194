<template>
    <article class="gisViewer-cont">
        <div ref="viewerMaskDom" class="mask" v-show="setting.showMask"></div>

        <div id="viewerGISDom" ref="viewerGISDom" class="viewerGIS_main"></div>
        <gis-toolbar id="toolbar" ref="toolbar"></gis-toolbar>
        <div class="mask_revert iconfont iconzhuye" v-show="!setting.showMask"></div>
    </article>
</template>

<script>
    import VueConfig from "./VueConfig";

    const vc = new VueConfig();

    export default vc.generate();
</script>

<style scoped>
    .viewerGIS_main{
        height: 100%;
        width: 100%;
        background-color: #000000;
    }

    .mask{
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: transparent;
        z-index: 999;
    }

    .mask_revert{
        position: absolute;
        top: 10px;
        right: 10px;
        background-color: transparent;
        color: white;
        font-size: 20px;
        z-index: 999;
    }
</style>
