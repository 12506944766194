<!--
 * @Author: zqy
 * @Date: 2021-10-21 15:55:01
 * @LastEditTime: 2022-10-14 11:03:08
 * @Description: 
    选择组件，详细参数见props
-->
<template>
    <div class="common-select" :style="'width: '+ canvasStyleWidth+ 'px'">
        <!-- <ToolBar 
            :title="title" 
            :extension="true"
            @search="search"
            @back="back"
        ></ToolBar> -->
        <div class="route">
            <div class="top-back">
                <i class="iconfont iconxiangzuojiantou1" @click="back" />
            </div>
            <div class="top-middle">
                <p>{{title}}</p>
            </div>
            <div class="top-right">
                <van-button v-if="checkBoxFlag" hairline plain size="mini" type="primary" text="确定" @click="checkBoxSelect" />
            </div>
        </div>
        <article>
            <template v-if="canSelect && canSelect.length">
                <div v-for="(item,index) in canSelect" :key="index">
                    <div class="item-css">
                        <div v-if="!checkBoxFlag" style="margin-right: 5px;">
                            <img style="vertical-align: middle;" src="@/assets/images/mobile-select/radio_checked.png" width="24" @click.stop="checked(item,index)" v-if="selectedRadio[valueString] == item[valueString]"/>
                            <img style="vertical-align: middle;" src="@/assets/images/mobile-select/radio.png" width="24" v-else @click.stop="checked(item,index)"/>
                        </div>
                        <div v-if="checkBoxFlag" style="margin-right: 5px;">
                            <img style="vertical-align: middle;" src="@/assets/images/mobile-select/checked1.png" width="24" @click.stop="checkboxChecked(item,index)"  v-if="selectedCheckboxId.indexOf(item[valueString]) != -1"/>
                            <img style="vertical-align: middle;" src="@/assets/images/mobile-select/unchecked.png" width="24" v-else @click.stop="checkboxChecked(item,index)"/>
                        </div>
                        <div style="display: flex;justify-content: space-between;flex: 1;">
                            <p class="adjust-name">{{item[labelString]}}</p>
                        </div>
                    </div>
                    <div style="width: 100%;height: 1px;background: #F2F4F5"></div>
                </div>   
            </template>
            <van-empty v-else description="暂无数据" />
        </article>
        <!-- <footer class="footer" v-if="checkBoxFlag">
            <van-row type="flex" justify="space-around" >
                <van-button round hairline plain  style="width: 100px;height: 40px;" type="primary" text="确定" @click="checkBoxSelect" />
            </van-row>
        </footer> -->
    </div>
</template>
<script>
import { defineComponent,reactive,toRefs,onMounted } from 'vue'
import ToolBar from "./components/toolBar.vue"
import { Divider, Button, Row, Empty } from 'vant'
import { dataInterface } from '@/apis/data/index';
import 'vant/lib/index.css'
export default defineComponent({
    components:{
      ToolBar,
      'van-divider': Divider,
      'van-button': Button,
      'van-row': Row,
      'van-empty': Empty
    },       
    props:{
        // 标题
        title:{
            type:String,
            default:'请选择'
        },
        /* 整个筛选项的配置数据 */
        selectData: {
            type: Object,
            default: () => {}
        },
        // 选择的数据源
        itemData:{
            type:Array,
            default:() => [
                {
                    id:1,
                    name:'信息',
                    role_name: "经理",
                    wechat_avatar:'',
                    value: {role_id: 1, user_id: 1},
                }
            ]
        },
        // 姓名字段
        nameString:{
            type:String,
            default:'name',
        },
        // 选择事件
        checkedItem:{
            type:Function,
            default:() => {}
        },
        // 多选按钮
        checkBoxFlag:{
            type:Boolean,
            default:false
        },
        // 单选默认选中
        defaultRadio:{
            type:Object,
            default:() => {}
        },
        // 多选默认选中
        defaultCheckbox:{
            type:Array,
            default:() => []
        },
        // 唯一区分标识
        idName:{
            type:String,
            default:'id'
        },
    } ,
    emits:['checkedItem','close-drawer'],
    setup(props,ctx){
        const canvasStyle = JSON.parse(sessionStorage.getItem('canvasStyle'))
        let state=reactive({
            selectedRadio:{},
            selectedCheckboxId:[],
            checkboxData:[],
            canSelect:[],
            copyCanSelect:[],
            labelString: '',
            valueString: '',
            canvasStyleWidth: canvasStyle.width
        })

        const search=(value)=>{
            if(value){
                state.canSelect = state.canSelect.filter(item => item[state.labelString].indexOf(value) > -1)
            }else{
                state.canSelect = state.copyCanSelect
            }
        }
        const back=()=>{
            ctx.emit('close-drawer')
        }
        const checked=(item)=>{
            state.selectedRadio = item
            ctx.emit('close-drawer')
            ctx.emit('checkedItem',item)
        }
        const checkBoxSelect=()=>{
            ctx.emit('close-drawer')
            ctx.emit('checkedItem',state.checkboxData)
        }
        const checkboxChecked=(item)=>{
            let indexItem = state.selectedCheckboxId.indexOf(item[state.valueString])
            if(indexItem == -1){
                state.selectedCheckboxId.push(item[state.valueString])
                state.checkboxData.push(item)
            }else{
                state.selectedCheckboxId.splice(indexItem,1)
                state.checkboxData.splice(indexItem,1)
            }
        }
        /* 多选回显 */
        const defaultCheckboxFun=()=>{
            let arr = JSON.parse(JSON.stringify(props.defaultCheckbox))
            arr.forEach(item=>{
                state.selectedCheckboxId.push(item[state.valueString])
                state.checkboxData.push(item)
            })
        }
        /* 单选回显 */
        const defaultRadioFun=()=>{
            state.selectedRadio = props.defaultRadio
        }

        onMounted(()=>{
            console.log(props.selectData, 'selectDataselectDataselectData')
            if (props.selectData && props.selectData.optionOrign !== 'database') {
                state.labelString = props.nameString
                state.valueString = props.idName
                state.canSelect = JSON.parse(JSON.stringify(props.itemData))
                state.copyCanSelect = JSON.parse(JSON.stringify(props.itemData))
                
                if(props.defaultCheckbox && props.defaultCheckbox.length){
                    defaultCheckboxFun()
                }
                if(props.defaultRadio){
                    defaultRadioFun()
                }
            } else if (props.selectData && props.selectData.optionOrign === 'database') {
                state.labelString = props.selectData.optionConfig.labelCode
                state.valueString = props.selectData.optionConfig.valueCode
				// 发送请求
				dataInterface({
                    object_uuid: props.selectData.optionConfig.objectUUID,
                    view_uuid: props.selectData.optionConfig.viewUUID,
                    __method_name__: 'dataList'
                }).then((res) => {
                    if (res.status === 200 && res.data && res.data.code == 200) {
                        state.canSelect = JSON.parse(JSON.stringify(res.data.data.data || res.data.data))
                        state.copyCanSelect = JSON.parse(JSON.stringify(res.data.data.data || res.data.data))
                    }
                    if(props.defaultCheckbox && props.defaultCheckbox.length){
                        defaultCheckboxFun()
                    }
                    if(props.defaultRadio){
                        defaultRadioFun()
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.$loading().close();
                });
            }
            
        })
        
        return {
            ...toRefs(state),...props,
            back,checkboxChecked,checked,checkBoxSelect,search
        }
    }
})
</script>
<style lang="less" scoped> 

@keyframes popup {
	/* 动画开始时的状态 */
	0% {
		top: 100%;
	}
	100% {
		top: 50%;
	}
}
.common-select{
    height: 50%;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    right: 0;
    animation-name: popup;
    animation-duration: 0.4s;
    background-color: #fff;
    .route {
        margin: 8px 0;
        display: flex;
        position: relative;
        .top-back {
            width: 20%;
            max-width: 20%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            i {
            font-size: 24px;
            }
        }
        .top-middle {
            flex: 1;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight: 550;
        }
        .top-right {
            width: 20%;
            max-width: 20%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
article{
    flex: 1;
    overflow-y: auto;
}
.footer{
    height: 40px;
    background: #fff;
    padding: 10px 0;
    width: 100%;
}
.img-head{
    width:36px;
    height:36px;
    margin-right:10px;
    .avatar{
        width:100%;
        height:100%;
        background-color: #2C374D;
        border-radius:4px;
        display: flex;
        align-items: center;
        justify-content: center;
        span{
            font-size:20px;
            font-family:'PingFangSC';
            font-weight:400;
            color:rgba(255,255,255,1);
        }
    }
}
.item-css{
    display: flex;
    align-items: center;
    background: #fff;
    padding: 10px;
}
.adjust-name{
    font-size:16px;
    font-family:'PingFangSC-Regular';
    font-weight:400;
    color:rgba(37,40,46,1);
}
.ellipsis{
    max-width: 85px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>
