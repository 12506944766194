<!--
 * @Author: zx
 * @Date: 2022-08-03 11:43:46
 * @LastEditTime: 2022-08-09 14:19:44
 * @Description: 
 * @LastEditors: zx
-->
<template>
  <div>
    <DetailDrawer v-if="showForm" :detailConfig="detailConfig" />
  </div>
</template>
<script>
  import { defineComponent, onMounted, reactive, toRefs } from 'vue' 
  import DetailDrawer from '@/mobile-components/mobilePage/DetailDrawer' 
  import { getToken } from '@/utils/tools';
  import { getComponentById } from '@/utils/tools';
  import { judgingArchiAPermission, judgingUILogic } from '@/utils/tools';
  export default defineComponent({
    components: {
      DetailDrawer
    },
    props: {
      element: {
        type:Object,
        default:{}
      }
    },
    setup(props) {
      const state = reactive({
        showForm: false,
        detailConfig: {
          type: 'mobileiDetailframe',
          token: getToken(),
          userInfo: localStorage.getItem('userInfo'),
          targetArchi: localStorage.getItem('targetArchi'),
          myCompany: localStorage.getItem('myCompany'),
          myProjects: localStorage.getItem('myProjects')
        }
      })
      /**
       * @desc: 获取query参数
       * @param {Object} 
       */
      const getQueryStringArgs = () => {
        //取得查询字符串并去掉开头的问号
        var qs = location.search.length > 0 ? location.search.substring(1) : "",
        //保存数据的对象
        args = {},
        //取得每一项
        items = qs.length ? qs.split("&") : [],
        item = null,
        name = null,
        value = null,
        //在 for 循环中使用
        i = 0,
        len = items.length;
        //逐个将每一项添加到 args 对象中
        for (i = 0; i < len; i++) {
          item = items[i].split("=");
          name = decodeURIComponent(item[0]);
          value = decodeURIComponent(item[1]);
          if (name.length) {
            args[name] = value;
          }
        }
        return args;
      }
      onMounted(() => {
        let params = []
        if (props.element?.formConfig?.params?.length) {
          const queryObj = getQueryStringArgs()
          props.element.formConfig.params.map(param => {
            if (param.queryType === 'component') {
              let component = getComponentById(this.componentData, param.component);
              if (param.key === 'data_id') {
                state.detailConfig.data_id = component.resolveData[param.value]
              } else {
                params[param.key] = component.resolveData[param.value]
              }
            } else {
              if (param.key === 'data_id') {
                state.detailConfig.data_id = queryObj[param.value]
              } else {
                params[param.key] = queryObj[param.value]
              }
            }
          })
        }
        const info = JSON.parse(JSON.stringify(props.element.formConfig))
        delete info.params
        state.detailConfig.params = params
        Object.assign(state.detailConfig, info)
        /* 判断是否显示编辑和删除 */
        const formInfo = props.element.formConfig
        //编辑UI判断
        const editUiBoo = judgingUILogic(formInfo && formInfo.detaiUILogic)
        //删除UI判断
        const deleteUiBoo = judgingUILogic(formInfo && formInfo.deleteUILogic)
        const editLimitBoo = formInfo.detailLimit ? judgingArchiAPermission(formInfo.detailLimit.archiLimit, formInfo.detailLimit.permission) : true
        const deleteLimitBoo = formInfo.deleteLimit ? judgingArchiAPermission(formInfo.deleteLimit.archiLimit, formInfo.deleteLimit.permission) : true
        state.detailConfig.editVisible = editUiBoo && editLimitBoo
        state.detailConfig.deleteVisible = deleteUiBoo && deleteLimitBoo
        state.showForm = true

      })
      return {
        ...toRefs(state)
      }
    }
  })
</script>
<style lang='less' scoped>
</style>
