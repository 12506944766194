<!--
 * @Description: 循环容器
 * @Author: luocheng
 * @Date: 2021-09-22 10:34:11
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2023-01-06 17:32:28
-->
<template>
	<div
		class="loop-container"
		:id="boxId"
		v-loading="loading"
	>
		<template v-if="dataList && dataList.length">
			<ComponentBox
        v-for="(item, index) in dataList"
        :key="index"
        :isPreview="isPreview"
        :containerData="item"
        :fullData="item"
        :mapData="{
					loopConfig: loopConfig,
					renderData: item
				}"
        @click.native.stop="onClickBox(getLoopChildren(index, item))"
        :element="getLoopChildren(index, item)"
        :pageId="pageId"
        :isGroup="isGroup"
        :groupComponents="groupComponents"
        :defaultTableSelectData="defaultTableSelectData"
        :componentList="componentList"
      ></ComponentBox>
		</template>
		<el-empty description="暂无数据" v-else></el-empty>
		<!-- 默认应当导出pdf 当 任存在问题，先默认为excel -->
		<Spreadsheet v-if="showSheet && sheetFormData"
			:type="spreadsheetType"
			:excelUuid="exportConfig.exportTemplate"
			:objectUuid="exportConfig.objectUUID"
			:viewUuid="exportConfig.viewUUID"
			:dataIds="sheetFormData"
			:exportType="exportConfig.exportTypeSheet || 'pdf'"
			@exportSuccess="onExportSuccess"
			:viewSearch="exportViewSearch"
			:viewQuery="exportViewParams"
			@exportError="onExportError"
			:extraParams="exportParam"
		></Spreadsheet>
	</div>
</template>

<script>
import { dataInterface } from '@/apis/data/index';
import { pageFromDetail} from '@/api/saas'
import eventBus from '@/plugins/eventBus';
import { getComponentById, initParams, doEEActionHandle } from '@/utils/tools';
import { Empty } from 'element-ui';
import { mapState } from 'vuex';
import Spreadsheet from '@/custom-component/common/Entry';
import { judgingArchiAPermission, judgingUILogic } from '@/utils/tools';
import mixin from './mixins';

export default {
	name: 'CommonLoopContainer',
  mixins: [mixin],
	props: {
    // 是否为预览
		isPreview: {
			type: Boolean,
			required: false,
			default: false
		},
    // 弹窗页面配置的ID
		pageId: {
			type: Number,
			required: false
		},
		// 是否为分组
		isGroup: {
			type: Boolean,
		},
		// 当前分组的组件数据
		groupComponents: {
			type: Array,
			default: () => []
		},
		// 表格默认选中数据
		defaultTableSelectData: {
			type: Array,
			default: () => []
		},
    componentList: {
      default: null
    }
	},
	components: {
		'el-empty': Empty,
		Spreadsheet
	},
	data() {
		return {
			// 当前组件唯一Key
			boxId: `table-container-${new Date().getTime()}`,
			// 分页
			pager: {
				current_page: 1,
				per_page: 15,
				total: 0
			},
			// 表格数据
			dataList: [],
			// 配置数据
			metadata: [],
			loading: false,
			// 缓存的搜索条件
			search: [],
			param: {},
			rowKey: '',
			// 表格弹窗
			showSheet: false,
			// 表格数据
			sheetFormData: null,
			exportConfig: null,
			// 操作类型：add - 新增，edit - 编辑，info - 详情，export - 导出
			spreadsheetType: 'export',
			exportViewParams: {},
			exportViewSearch: [],
			// 使用后端返回配置导出的导出配置
			exportWay: '', // 导出配置 eeConfig 后端配置， '' 自定义
			eeExportTemplate: '',
			eeObjectUUID: '',
			eeViewUUID: '',
      // 循环模版
      templateList: [],
			exportParam: {}
		};
	},
	created() {
		// 分页器
		const { 
			defaultPerPage = 15,
		} = this.statusConfig;
		this.pager.per_page = defaultPerPage;
		// 请求数据
		this.metadata = this.element.metadata || [];
    this.templateId = this.loopConfig?.cardId;
		if (this.database && !this.hasComponentParam()) {
			const { search = [], param = {}, canPost } = initParams(this.element?.database?.paramsConfig || [], this.isGroup, this.componentList || this.componentData, this.groupComponents);
      if (!canPost) return;
			this.getList(this.database, search, param);
		}
	},
	computed: {
		...mapState(['componentData']),
		// 数据仓库配置
		database() {
			return this.element && this.element.database;
		},
		// 操作类型
		actionConfig() {
			return this.element && this.element.actionConfig;
		},
		// 配置
		statusConfig() {
			return this.element && this.element.statusConfig;
		},
		// 字段配置
		fieldConfig() {
			const configs = this.element && this.element.fieldConfig;
			if (!configs) return [];
			if (!configs && !configs.length) return this.metadata || [];
			const result = [];
			configs.forEach((ele) => {
				if (ele && ele.show) {
					result.push(ele);
				}
			});
			return result;
		},
		// 前端排序
		useSort() {
			return !!this.element?.statusConfig?.useSort;
		},
		// 排序字段类表
		sortConfigs() {
			return this.fieldConfig?.filter(ele => ele.bySort);
		},
    /**
     * @desc: 循环容器配置
     */
    loopConfig() {
      return this.element?.loopConfig;
    }
	},
	mounted() {
		// 导出
		const exportData = {
			[this.element.id]: exportConfig => {
				if (!exportConfig || exportConfig.componentId !== this.element.id) return;
				this.exportConfig = exportConfig;
				this.doExport(exportConfig);
			}
		}
		// 导出
		eventBus.$on('exportData', exportData[this.element.id]);
		const databaseTrigger = {
			[this.element.id]: data => {
				const { isSearch = false } = data;
				if (data.parentId && data?.parentId !== this.element.id) return false;
				// 配置时候触发请求
				if (data.componentId === this.element.id && data.isInit) {
					const {
						search = [],
						param = {},
						canPost
					} = initParams(
						this.element?.database?.paramsConfig || [],
						this.isGroup,
						this.componentList || this.componentData,
						this.groupComponents
					);
					if (!canPost) return;
					if (isSearch) {
						this.pager.current_page = 1;
					}
					this.getList(this.element.database, search, param);
					return;
				}
				// 点击操作时候不更新数据
				if (data.noUpdate) return;
				const { paramsConfig } = this.element.database;
				if (!paramsConfig || !paramsConfig.length) {
					if (isSearch) {
						this.pager.current_page = 1;
					}
					this.getList(this.element.database);
					return;
				}
				// 以下步骤是为了避免有多个来源的search需要进行differ 避免检索结果错误情况
				let {
					search = [],
					param = {},
					canPost
				} = initParams(
					this.element?.database?.paramsConfig || [],
					this.isGroup,
					this.componentList || this.componentData,
					this.groupComponents
				);
				if (!canPost) return;
				let isTarget = false;
				paramsConfig.forEach(ele => {
					if (ele.componentId === data.componentId) {
						isTarget = true;
					}
				});
				if (!isTarget && !data.isUpdate) return;
				this.param = param;
				if (isSearch) {
					this.pager.current_page = 1;
				}
				this.getList(this.element.database, search, param);
			}
		}
		// 配置关联参数的容器才需要监听
		eventBus.$on('databaseTrigger', databaseTrigger[this.element.id]);

		// 触发暴露值
		const EDITORLoopResolve = {
			[this.element.id]: data => {
				if (!data) return;
				const { index, isLoop, loopId } = data;
				if (!isLoop) return;
				if (loopId !== this.element.id) return;
				if (!this.dataList?.[index]) return;
				this.element.resolveData = this.dataList?.[index];
			}
		}
		eventBus.$on('EDITOR_loopResolve', EDITORLoopResolve[this.element.id]);
	},
	methods: {
		// 判断架构及权限
		judgingArchiAPermission,
		judgingUILogic,
		/**
		 * @desc: 判断是否存在依赖其他组件的取值
		 */
		hasComponentParam() {
			if (!this.database.paramsConfig || !this.database.paramsConfig.length) {
				return false;
			}
			for (let i = 0; i < this.database.paramsConfig.length; i++) {
				const { componentId = '', key = '', sourceType = '' } = this.database.paramsConfig[i];
				if ((key !== 'search' || !componentId.includes('CommonForm')) && sourceType !== 'url') {
					// 补充不同架构问题
					if (!componentId.includes('CommonTableContainer') && this.paramsSameArchi(componentId)) {
						return true;
					}
				}
			}
			return false;
		},
		/**
		 * @desc: 判断依赖的参数是否在当前架构下启用(@凌志华树形图架构限制)
		 * @param {String} componentId
		 * @return {Boolean}
		 */
		paramsSameArchi(componentId) {
			let comp = getComponentById(this.componentList || this.componentData, componentId);
			if (!comp && this.isGroup && this.groupComponents.length) {
				comp = getComponentById(this.groupComponents, componentId);
			}
			if (!comp) return false;
			const targetArchi = this.$GetTargetArchi('archiType');
			if (comp && comp.archiLimit && comp.archiLimit.length && comp.archiLimit.includes(targetArchi)) {
				return true;
			}
			return false;
		},
		/**
		 * @desc: 获取渲染列表
		 * @param {Object} database 数据配置对象
		 * @param {Array} search 搜索
		 */
		getList(database, search = [], params = {}) {
			this.search = Array.isArray(search) ? search : [];
			this.dataList = [];
			if (!this.validDatabase(database)) return;
			this.loading = true;
			const paramsObj = {
				...params,
				page: this.pager.current_page || 1,
				size: this.statusConfig.pagination ? this.pager.per_page || 15 : 15
			};
			// 配置
			let __method_name__ = 'dataList';
			const mapping = database.mapping;
			let configObj = null;
			if (mapping === 'object') {
				configObj = {
					__method_name__,
					object_uuid: database.objectData.uuid,
					view_uuid: database.viewData.uuid,
					...paramsObj,
					search
				};
			} else if (mapping === 'relation') {
				__method_name__ = 'relationList';
				configObj = {
					__method_name__: 'relationList',
					object_uuid: database.objectData.uuid,
					relationship_uuid: database.relationData.uuid,
					...paramsObj,
					search
				};
			}
			// 获取表格数据
			dataInterface(configObj).then((res) => {
				if (res && res.status === 200) {
					if (!this.metadata || !this.metadata.length) {
						this.metadata = res.data.metadata;
					}
					let dataList = [];
					if (__method_name__ === 'dataList' || __method_name__ === 'relationList') {
						// 列表数据
						dataList = this.getListFromRes(res, true) || [];
					}
					for (let i = 0; i < this.fieldConfig.length; i++) {
						const item = this.fieldConfig[i];
						if (item.type === 7) {
							// switch 编辑
							dataList = dataList.map((ele) => {
								if (item.switchOptions.type === 'number') {
									// 数字
									ele[item.uuid] =
										+ele[item.uuid] === +item.switchOptions.trueValue;
								} else if (item.switchOptions.type === 'boolean') {
									if (ele[item.uuid] === 'true') {
										ele[item.uuid] = true;
									} else if (ele[item.uuid] === 'false') {
										ele[item.uuid] = false;
									}
								} else if (item.switchOptions.type === 'string') {
									ele[item.uuid] =
										ele[item.uuid] === item.switchOptions.trueValue;
								}
								return ele;
							});
						}
					}
					// 排序
					if (this.useSort && this.sortConfigs && this.sortConfigs.length) {
						dataList = this.customSort(dataList);
					}
					this.dataList = dataList;
					// 完全数据 暂时保存
					this.fullData = res.data.data;
					// 保存容器数据
					this.$store.commit('modifyComponent', {
						component: {
							...this.element,
							containerData: this.dataList,
							fullData: this.fullData,
							metadata: res.data.metadata,
							database: {
								...this.element.database,
								fieldList: this.getFieldList(res.data.metadata)
							}
						},
						containerId: null,
						isModify: true,
						pageUUID: this.EDITOR_pageUUID
					});
				}
				this.loading = false;
			}).catch((err) => {
				console.log(err);
				this.loading = false;
			});
		},
		/**
		 * @desc: 前端自定义排序
		 * @param {Array} arr
		 * sortWay:排序顺序 ascending 升序， descending 降序
		 */
		customSort(arr) {
			if (!arr || !Array.isArray(arr) || !arr.length) return [];
			let dataList = arr;
			try {
				this.sortConfigs.forEach(ele => {
					const { uuid, sortWay } = ele;
					dataList.sort((a, b) => {
						return a?.[uuid] - b?.[uuid];
					});
					// 降序
					if (sortWay === 'descending') {
						dataList.reverse();
					}
				});
			} catch(e) {
				console.log(e, '前端排序故障---表格1');
			}
			return dataList;
		},
		/**
		 * @desc: 获取字段列表
		 * @param {Object} obj metadata对象
		 */
		getFieldList(obj) {
			const arr = [];
			if (obj && typeof obj === 'object') {
				for (let key in obj) {
					arr.push({
						name: obj[key],
						uuid: key
					});
				}
			}
			return arr;
		},
		/**
		 * @desc: 验证
		 * @param {Object} database 数据仓库的绑定
		 */
		validDatabase(database) {
			if (!database || typeof database !== 'object') return false;
			if (!database.objectData) return false;
			if (!database.viewData && !database.relationData) return false;
			return true;
		},
		/**
		 * @desc: 根据请求返回获取列表结构
		 * @param {Object} res 请求返回的数据
		 * @param {Boolean} withPager 是否需要重置pager
		 */
		getListFromRes(res, withPager = true) {
			if (res.data && Array.isArray(res.data)) {
				if (withPager) {
					this.pager = res;
				}
				return res.data;
			}
			if (res.data && typeof res === 'object') {
				return this.getListFromRes(res.data, withPager);
			}
			return [];
		},
		/**
		 * @desc: 触发后端事件
		 */
		doEEAction(btnConfig, rowData, type = 'main') {
			if (btnConfig && btnConfig.actionType === 'eeAction') {
				const { objectUUID, viewUUID, eventName } = btnConfig;
				if (!objectUUID || !viewUUID || !eventName) {
					this.$message.error('请正确配置事件!');
					return false;
				}
				const idUUID = type === 'main' ? this.getIdUUID() : this.statusConfig.nestingRowKey;
				const data_id = rowData?.[idUUID];
				if (!data_id) {
					this.$message.error('事件未配置触发对象!');
					return false;
				}
				this.loading = true;
				dataInterface({
					__method_name__: 'customEventCall',
					object_uuid: btnConfig.objectUUID,
					view_uuid: btnConfig.viewUUID,
					data_id,
					event: btnConfig.eventName
				}).then((res) => {
					if (res.status === 200 && res.data.code === 200) {
						this.$message.success('操作成功！');
						doEEActionHandle(res.data?.__adds__);
						this.onPage(this.pager.current_page);
					}
					this.loading = false;
				}).catch((err) => {
					console.log(err, '00000后端事件错误');
					this.loading = false;
				});
			}
		},
		/**
		 * @desc: 切换页码
		 * @param {Number} current_page 当前页码
		 */
		onPage(current_page) {
			this.pager = {
				...this.pager,
				current_page
			};
			let { search = [], param = {}, canPost } = initParams(this.element?.database?.paramsConfig || [], this.isGroup, this.componentList || this.componentData, this.groupComponents);
			if (canPost) {
				this.getList(this.database,search, param || {});
			}
		},
		/**
		 * @desc: 导出
		 * @param {Object} config 配置
		 */
		async doExport(config, rowData = null, type = 'main') {
			this.exportTableType = type;
			const loading = this.$loading({
				text: '导出中....'
			});
			const { exportType = 'all' } = config;
			let idUUID = type === 'main' ? this.getIdUUID() : this.statusConfig.nestingRowKey;
			
			if (!idUUID) {
				loading.close();
				this.$message.error('导出失败！数据验证错误。');
				return;
			}
			/* eslint-disable */ 
			// exportType 导出配置 all 全部， targetPage 本页，checked 当前选中
			// 最终导出数据
			// 导出全部
			if (exportType === 'targetPage') {
				if (!this.dataList || !this.dataList.length) {
					loading.close();
					this.$message.error('暂无数据可供导出！');
					return;
				}
				// 本页
				this.sheetFormData = [];
				this.dataList.forEach((ele) => {
					this.sheetFormData.push(ele[idUUID]);
				});
			} else if (exportType === 'checked') {
				if (!this.element.cacheData || !this.element.cacheData.length) {
					loading.close();
					this.$message.error('请选择需要导出的数据！');
					return;
				}
				this.sheetFormData = [];
				// 当前选中
				this.element.cacheData.forEach((ele) => {
					this.sheetFormData.push(ele[idUUID]);
				});
			} else if (exportType === 'all') {
				this.sheetFormData = 'all';
			} else if (exportType === 'searchAll') {
				this.sheetFormData = 'all';
				const queryData = initParams(this.element?.database?.paramsConfig || [], this.isGroup, this.componentList || this.componentData, this.groupComponents);
				this.exportViewParams = queryData.param || {};
				this.exportViewSearch = queryData.search;
			} else if (exportType === 'targetRow') {
				this.sheetFormData = rowData?.[idUUID]
				if (this.exportWay === 'eeConfig') {
					// 后端导出配置
					const { exportTemplateField, objectUUIDField, viewUUIDField } = config;
					if (!exportTemplateField || !objectUUIDField || !viewUUIDField) {
						loading.close();
						this.$message.error('导出配置错误！');
						return false;
					}
					this.eeExportTemplate = rowData?.[exportTemplateField];
					this.eeObjectUUID = rowData?.[objectUUIDField];
					this.eeViewUUID = rowData?.[viewUUIDField];
					this.exportConfig = {
						...this.exportConfig,
						exportTemplate: this.eeExportTemplate,
						objectUUID: this.eeObjectUUID,
						viewUUID: this.eeViewUUID
					}
					if (!this.eeExportTemplate || !this.eeObjectUUID || !this.eeViewUUID) {
						loading.close();
						this.$message.error('导出配置错误！');
						return false;
					}
				}
			}
			if (!this.sheetFormData) {
				loading.close();
				this.$message.error('导出配置错误！');
				return false;
			}
			// 参数
			if (config?.params) {
				const { param = {} } = initParams(config.params, this.isGroup, this.componentList || this.componentData, this.groupComponents);
				this.exportParam = param
			}
			this.showSheet = true;
		},
		/**
		 * @desc: 导出成功
		 */
		onExportSuccess() {
			this.$loading().close();
			this.showSheet = false;
			this.$message.success('导出成功！');
			this.exportParam = {};
		},
		/**
		 * @desc: 导出错误
		 * @param {String} msg 错误信息
		 */
		onExportError(msg) {
			this.$loading().close();
			this.showSheet = false;
			this.$message.error(msg || '导出失败！');
			this.exportParam = {};
		},
		/**
		 * @desc: 获取数据主键，ID UUID
		 */
		getIdUUID() {
			let idUUID = this.statusConfig.idUUID || '';
			if (!idUUID) {
				let idCount = 0;
				for (let key in this.metadata) {
					if (this.metadata[key] === 'id') {
						idCount++;
						idUUID = key;
					}
				}
				// 编辑器提示
				if (['/modify-page', '/page-view'].includes(this.$route.path)) {
					// 编辑器提示ID问题
					if (idCount === 0) {
						setTimeout(() => {
							this.$message.warning('接口无【id】字段返回，请检查视图！');
						}, 0);
						return '';
					}
					if (idCount > 1) {
						setTimeout(() => {
							this.$message.warning(`接口存在【${idCount}】个【id】字段返回，请检查视图！`);
						}, 0);
						return '';
					}
				}
			}
			return idUUID;
		},
		/**
		 * @desc: 格式化循环容器子节点
		 * @param {Number} index 下标
		 */
		getLoopChildren(index, sourceData, level = 0) {
			if (!this.element?.children?.length) return {};
			const template = this.element.children[0];
			return {
				...template,
				id: `${template.id}-${level}-${index}`,
				loopData: {
					isLoop: true,
					sourceData,
					index,
					loopId: this.element.id
				},
				children: this.getChildren(template.children, sourceData, level + 1, index)
			}
		},
		/**
		 * @desc: 获取下级
		 * @param {Array} arr 数组
		 * @param {Object} sourceData
		 * @param {Number} level
		 * @param {Number} index 数据源下标
		 */
		getChildren(arr = [], sourceData, level = 1, index) {
			return arr.map((ele, cIndex) => {
				return {
					...ele,
					id: `${ele.id}-${level}-${cIndex}`,
					loopData: {
						isLoop: true,
						sourceData,
						index,
						loopId: this.element.id
					},
					children: this.getChildren(ele.children, sourceData, level + 1, index)
				}
			});
		},
    /**
     * @desc: 获取页面配置数据
     */
    getPageData(page_uuid) {
      return new Promise((resolve, reject) => {
				pageFromDetail({
					__method_name__: 'dataInfo',
					object_uuid: 'a4f016d6-c602-4492-8874-f088c3c0b3b9',
					view_uuid: 'view61b951c6a8186',
					page_uuid,
					transcode: 0
				}).then((res) => {
          resolve(res?.data?.data);
        }).catch((err) => {
          console.log(err);
          reject(null);
        });
			});
    },
		/**
		 * @desc: 触发点击事件
		 * @param {Object} item 点击的组件(可能需要兼容)
		 * 触发方式实质就是在改变指定的组件状态
		 * 目前覆盖点击事件
		 */
		onClickBox(item) {
			const excludesEvents = ['CommonDateButton'];
			//  || !Array.isArray(item?.events?.click?.effects)
			if (excludesEvents.includes(item.component)) {
				return false;
			}
			if (!item.events || Array.isArray(item.events)) return;
			const { actionType, effects = [], behaviors, specialEventList = [], pattern} = item.events?.click || {};
			const { linkType, queryList = [], linkUrl = '', pageId } = item?.events?.click || {};
			if (actionType === 'eeAction') {
				// 触发后端事件
				const { objectUUID, viewUUID, eventName } = item.events.click;
				if (!objectUUID || !viewUUID || !eventName) {
					this.$message.error('事件配置错误！');
					return false;
				}
				const data_id = this.getBindParams(item)?.data_id;
				dataInterface({
					__method_name__: 'customEventCall',
					object_uuid: objectUUID,
					view_uuid: viewUUID,
					data_id, // 参数配置
					event: eventName
				}).then((res) => {
					if (res.status === 200 && res.data.code === 200) {
						this.$message.success('操作成功！');
						doEEActionHandle(res.data?.__adds__);
					}
					this.loading = false;
				}).catch(() => {
					this.loading = false;
				});
				return false;
			} else if (actionType === 'jumpPage') {
				if (linkType === 'projectPage') {
					const query = {};
					queryList.forEach((queryItem) => {
						let component = getComponentById(this.componentData, queryItem.componentId);
						this.$set(
							query,
							queryItem.key,
							component?.resolveData[queryItem.feild]
						);
					});
					this.$router.push({
						path: pageId,
						query
					});
					return;
				} else if (linkType === 'outPage') {
					window.open(linkUrl);
				} else if (linkType === 'custom') {
					// 暂未处理
					// const customLink = getLinkByTemplate(linkTemplate, output)
					// window.open(customLink);
				}
			} else {
				// 普通事件
				if (!pattern) {
					effects.forEach((ele) => {
						this.$store.commit('triggerEvents', {
							config: ele,
							element: item
						});
					});
				} else if (pattern === 'special') {
					specialEventList.forEach(ele => {
						ele.effects.forEach(effect => {
							this.$store.commit('triggerEvents', {
								config: effect,
								element: item
							});
						})
						if(ele.behaviors?.length){
							for (let i=0; i<ele.behaviors.length; i++) {
								this.$store.commit('triggerEvents', {
									config: {
										behavior:ele.behaviors[i],
										isBehavior: true
									}
								});
							}
						}
					})
				}
			}
			if (behaviors && Array.isArray(behaviors)) {
				behaviors.forEach(behavior => {
					this.$store.commit('triggerEvents', {
						config: {
						behavior,
							isBehavior: true
						},
						element: item
					});
				})
			}
		}
	},
};
</script>

<style lang="less" scoped>
.loop-container {
	height: 100%;
	width: 100%;
  >.el-empty {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
