<!--
 * @Author: zx
 * @Date: 2022-12-05 16:38:01
 * @LastEditTime: 2022-12-06 15:51:19
 * @Description: 
 * @LastEditors: zx
-->
<template>
  <div class="dwg-preview">
    <Preview :info="fileObject" />
  </div>
</template>
<script>
  import Preview from '@/components/bindDrawing/preview.vue';
  export default {
    components:{
      Preview
    },
    props:{
      element: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        fileObject: {}
      }
    },
    mounted() {
      this.getFileObject()
    },
    methods:{
      getFileObject() {
        console.log(this.element)
      }
    }
  }
</script>
<style lang='less' scoped>
</style>