<!--
 * @Author: Shiltin
 * @Date: 2021-10-20 17:52:57
 * @LastEditors: ttheitao
 * @LastEditTime: 2022-08-04 14:54:05
 * @Description:
-->
<template>
  <div
    style="height: 100%"
    :style="!notForm ? 'max-width:' + maxShowWidth + 'px' : 'width:100%'"
  >
    <cascader
      :data-info="data3"
      :default-expand="defaultExpand"
      :disabled="disabled"
      :inpage="inpage"
      :is-check-type="isCheckType"
      :max-height="maxHeight"
      :position-type="positionType"
      :show-list="showList"
      :show-tree="showTree"
      :width="width"
      @on-select-change="onSelectChange"
    />
  </div>
</template>
<script>
/* eslint-disable */
import cascader from './components/cascader'
import { getUserList } from './userSelect'
export default{
  name: '',
  components: { cascader },
  props: {
    /* 流程组件内（inpage）页面盒子内使用示例
   <select-user
      :inpage="true"
      :not-form="true"
      :is-itself="true" //当前架构人员  不选下级
      :depart-id="210" //指定部门id
      @on-select-change="onSelectChange"
    />
   */
    dataInfo: {
      type: Object,
      default: () => {}
    },
    /* 回显传入的选中的值[
          { user_id: 1, section_id: 1 },
          { user_id: 14, section_id: 1 },
        ], */
    showList: {
      type: Array,
      default: () => []
    },
    /* 指定公司id */
    companyId: {
      type: Number,
      default: 0
    },
    /* 指定部门id */
    departId: {
      type: Array,
      default: () => []
    },
    /* 指定项目id */
    projectId: {
      type: Number,
      default: 0
    },
    /* 只请求架构本身的人员  不包括下级单位 */
    isItself: {
      type: Boolean,
      default: false
    },
    /* 回显传入的选中的值 */
    inpage: {
      type: Boolean,
      default: false
    },
    // cascaderCheck 级联单选
    // cascaderCheckbox 级联多选
    isCheckType: {
      type: String,
      default: 'cascaderCheckbox'
    },
    /* 点击选择窗口位置 */
    positionType: {
      type: String,
      default: 'bottom-start'
    },
    /* 弹出框宽度 */
    width: {
      type: Number,
      default: 450
    },
    /* 弹出框最大高度 */
    maxHeight: {
      type: Number,
      default: 300
    },
    /* 弹出框宽度 */
    notForm: {
      type: Boolean,
      default: false
    },
    /* 最大的显示人的长度(form表单默认100%会撑开，不能超出隐藏，form表单下使用需要设置宽度数值) */
    maxShowWidth: {
      type: Number,
      default: 428
    },
    /* 默认展开 */
    defaultExpand: {
      type: Boolean,
      default: true
    },
    pickerType: {
      type: String,
      default: 'target'
    },
    pickerNode: {
      type: String,
      default: ''
    },
    /* 禁用选择 */
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      userInfo:{},
      archiInfo:{},
      data3: null,
      showTree: true
    }
  },
  methods: {
    onSelectChange(arr){
      const pickerNode = this.pickerNode
      const pickerType = this.pickerType
      const params = { pickerNode, pickerType }
      this.$emit('on-select-change', arr, params)
    },
    companySectionEdit(data){
      let arr = data
      if (arr.length) {
        arr.forEach((item) => {
          item.children = item.users
        })
        arr = arr.filter((item) => item.children.length > 0)
        let key = 1
        if (arr.length) {
          arr.forEach((element) => {
            element.children.forEach((itemz) => {
              itemz.nodeKey = key
              itemz.departmentName = element.name
              key += 1
            })
          })
          this.data3 = JSON.parse(JSON.stringify(arr))
          this.showTree = false
        }
      }
    },
    projectSectionEdit(data){
      const arr = []
      data.forEach((element) => {
        const project_name = element.name
        const project_id = element.id
        element.sections.forEach((item) => {
          if (item.users && item.users.length) {
            item.children = item.users
            item.children.forEach((user) => {
              user.project_name = project_name
              user.project_id = project_id
            })
            arr.push(item)
          }
        })
      })
      let key = 1
      arr.forEach((element) => {
        element.children.forEach((itemz) => {
          itemz.nodeKey = key
          key += 1
        })
      })
      this.data3 = JSON.parse(JSON.stringify(arr))
      this.showTree = false
    },
	  handleDataInfo(obj) {
		  if (obj.type === 'section') {
			  this.companySectionEdit(obj.list)
		  } else if (obj.type === 'project') {
			  const arr = getUserList(obj.list, 'project', 'section')
			  let key = 1
			  const nodeKey = ($list) => {
				  if (!$list || $list.length <= 0) {
					  return
				  }
				  $list.forEach((element) => {
					  if (element.section_id && element.user_id) {
						  element.nodeKey = key
						  key += 1
					  } else if (element.children && element.children.length) {
						  nodeKey(element.children)
					  }
				  })
			  }
			  if (arr && arr.length) {
				  nodeKey(arr)
				  this.data3 = JSON.parse(JSON.stringify(arr))
			  } else {
				  this.data3 = []
			  }
			  this.showTree = false
		  } else if (obj.type === 'company') {
			  const arr = getUserList(obj.list, 'company', 'section')
			  let key = 1
			  const nodeKey = ($list) => {
				  if (!$list || $list.length <= 0) {
					  return
				  }
				  $list.forEach((element) => {
					  if (element.section_id && element.user_id) {
						  element.nodeKey = key
						  key += 1
					  } else if (element.children && element.children.length) {
						  nodeKey(element.children)
					  }
				  })
			  }
			  if (arr && arr.length) {
				  nodeKey(arr)
				  this.data3 = JSON.parse(JSON.stringify(arr))
			  } else {
				  this.data3 = []
			  }
			  this.showTree = false
		  }
	  }
  },
  created () {
  },
  mounted () {
		if (Object.keys(this.dataInfo).length) {
			const obj = JSON.parse(JSON.stringify(this.dataInfo))
			this.handleDataInfo(obj)
		}
  }
}
</script>
<style lang="less" scoped></style>
