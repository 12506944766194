/*
 * @Author: zx
 * @Date: 2022-06-14 10:36:10
 * @LastEditTime: 2022-12-07 09:10:45
 * @Description: 
 * @LastEditors: zx
 */
// 页面组件
const pageComponent = {
	type: 'pageComponent',
	title: '页面组件',
	show: true,
	componentList: [
		{
			component: 'FormMobileDetail',
			type: 'container',
			name: '表单详情',
			icon: 'iconwangpan-xiangqing1x',
			isLock: false, // 是否锁定
			needCommonStyle: false,
			style: {
				height: '100%',
				width: '100%',
				lineHeight: '',
				rotate: 0,
				marginTop: 0,
				marginBottom: 0,
				marginRight: 0,
				marginLeft: 0,
				paddingLeft: 0,
				paddingRight: 0,
				paddingTop: 0,
				paddingBottom: 0
			},
			statusConfig: {
				isShow: true, // 是否显示
			},
		},
		{
			component: 'FormMobileAdd',
			type: 'container',
			name: '表单新增',
			icon: 'iconzidingyibiaodan',
			isLock: false, // 是否锁定
			needCommonStyle: false,
			style: {
				height: '100%',
				width: '100%',
				lineHeight: '',
				rotate: 0,
				marginTop: 0,
				marginBottom: 0,
				marginRight: 0,
				marginLeft: 0,
				paddingLeft: 0,
				paddingRight: 0,
				paddingTop: 0,
				paddingBottom: 0
			},
			statusConfig: {
				isShow: true, // 是否显示
			},
		}
	]
};
export default pageComponent;