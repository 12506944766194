<template>
  <div class="list-container">
		<PullRefresh v-if="listShow" v-model="refreshing" @refresh="onRefresh">
			<List 
				v-model="loading"
				:finished="finished"
				:error.sync="error"
				error-text="请求失败，点击重新加载"
				@load="onPage"
				finished-text="没有更多数据了"
			>
				<div class="list-item" v-for="item in tableData" :key="item.id" @click.stop="onAction('rowClick', item, $event)">
					<template v-if="layoutConfig.type">
						<!-- 图片 -->
						<div class="item-img-warp" v-if="['3rowTextAndImg', '2rowTextAndImg'].includes(layoutConfig.type) && layoutConfig.img">
							<img
								v-if="item[layoutConfig.img] && getItemImgSrc(item)"
								:style="{
									height: (fieldConfigObj[layoutConfig.img] && fieldConfigObj[layoutConfig.img].imgConfig.height || 72) + 'px',
									width: (fieldConfigObj[layoutConfig.img] && fieldConfigObj[layoutConfig.img].imgConfig.width || 72) + 'px',
								}"
								:src="getItemImgSrc(item)"
							>
							<img
								v-else
								:style="{
									height: (fieldConfigObj[layoutConfig.img] && fieldConfigObj[layoutConfig.img].imgConfig.height || 72) + 'px',
									width: (fieldConfigObj[layoutConfig.img] && fieldConfigObj[layoutConfig.img].imgConfig.width || 72) + 'px',
								}"
								src="../../assets/images/logo1.png"
							>
						</div>
						<!-- 文本盒子 -->
						<div class="item-text-warp">
							<div class="title-and-status-warp">
								<p
									v-if="layoutConfig.title"
									:style="{ color: fieldConfigObj[layoutConfig.title] && fieldConfigObj[layoutConfig.title].color}"
									class="title"
								>
									{{layoutConfig.titlePrefix || '' }}{{ item[layoutConfig.title] || '-' }}{{layoutConfig.titleSuffix || ''}}
								</p>
								<!-- 状态 -->
								<p
									v-if="item[layoutConfig.status] && layoutConfig.type !== '2rowText'"
									class="status"
									:style="{
										color: filterStatus(item[layoutConfig.status], fieldConfigObj[layoutConfig.status].statusOptions).color || fieldConfigObj[layoutConfig.status].color,
										background: formatColorOp(filterStatus(item[layoutConfig.status], fieldConfigObj[layoutConfig.status].statusOptions).color || fieldConfigObj[layoutConfig.status].color)
									}"
								>
									{{ filterStatus(item[layoutConfig.status], fieldConfigObj[layoutConfig.status].statusOptions).label }}
								</p>
							</div>
							<template v-if="['2rowTextAndImg', '2rowText'].includes(layoutConfig.type) && Array.isArray(layoutConfig.lineText) && layoutConfig.lineText.length">
								<p
									class="text-item"
								>
									<span
										v-if="item[layoutConfig.status] && layoutConfig.type === '2rowText'"
										class="status"
										:style="{
											color: filterStatus(item[layoutConfig.status], fieldConfigObj[layoutConfig.status].statusOptions).color || fieldConfigObj[layoutConfig.status].color,
											background: formatColorOp(filterStatus(item[layoutConfig.status], fieldConfigObj[layoutConfig.status].statusOptions).color || fieldConfigObj[layoutConfig.status].color)
										}"
									>
										{{ filterStatus(item[layoutConfig.status], fieldConfigObj[layoutConfig.status].statusOptions).label }}
									</span>
									<span v-for="(sitem, index) in layoutConfig.lineText" :key="index" :style="{ color: sitem.type === 'fild' && sitem.value ? fieldConfigObj[sitem.value].color : '' }">
										{{ sitem.type === 'fild' && sitem.value ? item[sitem.value] : sitem.value}}
									</span>
								</p>
							</template>
							<template v-else-if="Array.isArray(layoutConfig.text)">
								<p 
									class="text-item"
									v-for="sitem in layoutConfig.text"
									:key="sitem"
									:style="{ color: fieldConfigObj[sitem].color}"
								>
									<span>{{ fieldConfig.find(el => el.uuid === sitem) ? fieldConfig.find(el => el.uuid === sitem).label : '-' }}</span>
									:&nbsp;
									<span>
										{{ getShowValue(item, sitem) }}
										<!-- {{ item[sitem] || '-'}} -->
									</span>
								</p>
							</template>
						</div>
					</template>
					<!-- 兼容以前版本，后续进行优化 -->
					<template v-else>
						<template v-if="Object.keys(layoutConfig).length > 0">
							<div class="item-img-warp" v-if="layoutConfig.img">
								<img
									v-if="item[layoutConfig.img]"
									:style="{
										height: (fieldConfigObj[layoutConfig.img] && fieldConfigObj[layoutConfig.img].imgConfig.height || 72) + 'px',
										width: (fieldConfigObj[layoutConfig.img] && fieldConfigObj[layoutConfig.img].imgConfig.width || 72) + 'px',
									}"
									:src="item[layoutConfig.img]"
									>
							</div>
							<div v-if="layoutConfig.title || Array.isArray(layoutConfig.text) && layoutConfig.text.length" class="item-text-warp">
								<div
									v-if="item[layoutConfig.title]"
									class="title-and-status-warp"
									:style="{ color: fieldConfigObj[layoutConfig.title] && fieldConfigObj[layoutConfig.title].color}"
								>
									<p class="title">{{layoutConfig.titlePrefix}}{{ item[layoutConfig.title] }}{{layoutConfig.titleSuffix}}</p>
									<p
										v-if="item[layoutConfig.status]"
										class="status"
										:style="{
											color: filterStatus(item[layoutConfig.status], fieldConfigObj[layoutConfig.status].statusOptions).color || fieldConfigObj[layoutConfig.status].color,
											background: formatColorOp(filterStatus(item[layoutConfig.status], fieldConfigObj[layoutConfig.status].statusOptions).color || fieldConfigObj[layoutConfig.status].color)
										}"
									>
										{{ filterStatus(item[layoutConfig.status], fieldConfigObj[layoutConfig.status].statusOptions).label }}
									</p>
								</div>
								<template v-if="Array.isArray(layoutConfig.text) && layoutConfig.text.length">
									<p 
										class="text-item"
										v-for="sitem in layoutConfig.text"
										:key="sitem"
										:style="{ color: fieldConfigObj[sitem].color}"
									>
										<span>{{ fieldConfig.find(el => el.uuid === sitem) ? fieldConfig.find(el => el.uuid === sitem).label : '-' }}</span>
										:&nbsp;
										<span>
											{{ getShowValue(item, sitem) }}
											<!-- {{ item[sitem] || '-'}} -->
										</span>
									</p>
								</template>
							</div>
						</template>
						<template v-else>
							<Skeleton style="width: 100%" title avatar :row="2" >
							</Skeleton>
						</template>
					</template>
				</div>
				<template v-if="tableData.length === 0 && loading">
					<p class="skeleton-item" v-for="item in 8" :key="item">
						<Skeleton title avatar :row="2" >
						</Skeleton>
					</p>
				</template>
			</List>
		</PullRefresh>
  </div>
</template>

<script>
import { Empty, List, PullRefresh, Skeleton  } from 'vant'
import { $ } from '@/utils/tools';
import { dataInterface } from '@/apis/data/index';
import eventBus from '@/plugins/eventBus';
import { getComponentById, getQueryValue, getLinkByTemplate, judgingArchiAPermission, judgingUILogic, judgingEventLogic, doEEActionHandle } from '@/utils/tools';
import { mapState } from 'vuex';
import { defineComponent } from 'vue'
import { Message } from 'element-ui';
/**
 * @description 表格列表
 * 第一次载入不检索的原因是因为在编辑器中会导致finished,然后无法后续操作
 */
export default defineComponent({
	name: 'ListContainer',
	props: {
		element: {
			type: Object,
			required: true,
			default: () => {}
		}
	},
	components: {
    Empty,
    List,
		PullRefresh,
		Skeleton
	},
	data() {
		return {
			// 当前组件唯一Key
			boxId: `table-container-${new Date().getTime()}`,
			// 分页
			pager: {
				current_page: 1,
				per_page: 15,
				total: 0
			},
			// 表格数据
			tableData: [],
			copyTableData: [],
			// 配置数据
			metadata: [],
			// 列表状态
			loading: false,
      finished: false,
			error: false,
			// 下拉刷新
			refreshing: false,
			// 缓存的搜索条件
			search: [],
			// 周几
			weekDays: ['周天', '周一', '周二', '周三', '周四', '周五', '周六'],
			// 默认图片
			defaultImage: require('@/assets/images/logo1.png'),
			param: {},
			// 树形图
			rowKey: '',
			// 表格弹窗
			fieldForView: [],
			// 分页
      page: 1,
			configList: []
		};
	},
	computed: {
		...mapState(['componentData', 'curComponent']),
		// 数据仓库配置
		database() {
			return this.element && this.element.database;
		},
		// 操作类型
		actionConfig() {
			return this.element && this.element.actionConfig;
		},
		// 配置
		statusConfig() {
			return this.element && this.element.statusConfig;
		},
		listShow() {
			if(this.database.viewData) {
				return true
			} else {
				return false
			}
		},
		// 字段配置
		fieldConfig() {
			const configs = this.element && this.element.fieldConfig;
			if (!configs) return [];
			if (!configs && !configs.length) return this.metadata || [];
			const result = [];
			configs.forEach((ele) => {
				if (ele) {
					result.push(ele);
				}
			});
			return result;
		},
		// hash 字段配置，方便查询
		fieldConfigObj() {
			const hash = {}
			this.fieldConfig.map(el => {
				hash[el.uuid] = el
			})
			return hash
		},
		// layout 配置
		layoutConfig() {
			const configs = this.element && this.element.layoutConfig;
			if (!configs) {
				return {}
			} else {
				return configs
			}
		}
	},
	watch: {
		// 为了触发配置时候字段排序更新
		fieldConfig: {
			handler() {
				this.fieldForView = [];
				setTimeout(() => {
					this.fieldForView = this.fieldConfig || []
				}, 0);
			},
			deep: true,
			immediate: true
		}
	},
	mounted() {
		this.page = 1
		this.$nextTick(() => {
			const box = $(`#${this.boxId}`);
			if (box && box.offsetHeight) {
				this.height = box.offsetHeight - 42;
			}
		});
		this.finished = false
		this.pager = {
			current_page: this.page
		};
		let { search = [], param = {}, canPost } = this.initParams();
		if (canPost) {
			this.getList(this.element.database,search, param || {});
		}
		// 监听过滤名称
		eventBus.$on('routeTopInputSearch', (val) => {
			if (val) {
				this.tableData = this.copyTableData.filter((item) => {
					return item[this.layoutConfig.filterKey] ? item[this.layoutConfig.filterKey].indexOf(val) !== -1 : true
				})
			} else {
				this.tableData = JSON.parse(JSON.stringify(this.copyTableData))
			}
		});
		eventBus.$off('searchClick');
		// 筛选触发请求
		eventBus.$on('searchClick', (search) => {
			this.tableData = []
			this.pager.current_page = 1
			let { param = {}, canPost } = this.initParams();
			if (!canPost) return;
			this.getList(this.element.database, search, param);
		});
		// 列表刷新
		eventBus.$off('closeAddDrawer');
		eventBus.$on('closeAddDrawer', (boo) => {
			if (boo) {
				this.tableData = []
				this.pager.current_page = 1
				let { search = [], param = {}, canPost } = this.initParams();
				if (!canPost) return;
				this.getList(this.element.database, search, param);
			}
		});
		// 监听请求
		// 配置关联参数的容器才需要监听
		eventBus.$on('databaseTrigger', (data) => {
			if (data.resetPage) {
				this.pager.current_page = 1
				this.tableData = []
			}
			if (data.parentId && data.parentId !== this.element.id) return false;
			// 配置时候触发请求
			if (data.componentId === this.element.id && data.isInit) {
				const { search = [], param = {}, canPost } = this.initParams();
				if (!canPost) return;
        this.tableData = []
        this.finished = false
				this.pager.current_page = 1
				this.getList(this.element.database, search, param);
				return;
			}
			// 点击操作时候不更新数据
			if (data.noUpdate) return;
			const { paramsConfig } = this.element.database;
			if (!paramsConfig || !paramsConfig.length) return;
			// 以下步骤是为了避免有多个来源的search需要进行differ 避免检索结果错误情况
			let { search = [], param = {}, canPost } = this.initParams();
			if (!canPost) return;
			let isTarget = false;
			paramsConfig.forEach((ele) => {
				if (ele.componentId === data.componentId) {
					isTarget = true;
				}
			});
			if (!isTarget && !data.isUpdate) return;
			this.param = param;
      this.tableData = []
      this.finished = false
			this.getList(this.element.database, search, param);
		});
	},
	methods: {
		// 获取图片路径
		getItemImgSrc(item) {
			if(Array.isArray(item[this.layoutConfig.img])) {
				return item[this.layoutConfig.img] && item[this.layoutConfig.img].length && item[this.layoutConfig.img][0]?.url.indexOf('http') > -1 ? item[this.layoutConfig.img][0].url : ''
			} else {
				return item[this.layoutConfig.img] || ''
			}
		},
		// 判断架构及权限
		judgingArchiAPermission,
		judgingUILogic,
		/**
		 * @desc: 根据配置逻辑判断操作是否显示
		 * @param {Object} actionConfig
		 */
		logicAction(actionConfig, row) {
			if (!actionConfig.useDepend || !actionConfig || !actionConfig.dependLogic ||!actionConfig.dependLogic.length) {
				return true;
			}
			const dependLogic = actionConfig.dependLogic;
			const logicList = [];
			for (let i = 0, len = dependLogic.length; i < len; i++) {
				const { field, fieldAttr, fieldLogic, itemLogic, value, valueType } =
					dependLogic[i];
				let logicValue = null;
				if (fieldAttr === 'length') {
					logicValue = row[field].length;
				} else {
					logicValue = row[field];
				}
				// 当前项判断条件
				// 字段关系 大于>  小于 < 等于 === 大于等于>=  小于等于 <= 不等于 !=
				let itemBoolean = true;
				if (fieldLogic === '===') {
					if (valueType === 'string') {
						itemBoolean = logicValue === value;
					} else if (valueType === 'number') {
						itemBoolean = +logicValue === +value;
					} else if (valueType === 'date') {
						itemBoolean = new Date(logicValue).getTime() === value;
					}
				} else if (fieldLogic === '!=') {
					if (valueType === 'string') {
						itemBoolean = logicValue !== value;
					} else if (valueType === 'number') {
						itemBoolean = logicValue !== +value;
					} else if (valueType === 'date') {
						itemBoolean = new Date(logicValue).getTime() !== value;
					}
				} else if (fieldLogic === '>') {
					if (valueType === 'number') {
						itemBoolean = logicValue > +value;
					} else if (valueType === 'date') {
						itemBoolean = new Date(logicValue).getTime() > value;
					}
				} else if (fieldLogic === '<') {
					if (valueType === 'number') {
						itemBoolean = logicValue < +value;
					} else if (valueType === 'date') {
						itemBoolean = new Date(logicValue).getTime() < value;
					}
				} else if (fieldLogic === '<=') {
					if (valueType === 'number') {
						itemBoolean = logicValue <= +value;
					} else if (valueType === 'date') {
						itemBoolean = new Date(logicValue).getTime() <= value;
					}
				} else if (fieldLogic === '>=') {
					if (valueType === 'number') {
						itemBoolean = logicValue >= +value;
					} else if (valueType === 'date') {
						itemBoolean = new Date(logicValue).getTime() >= value;
					}
				}
				logicList.push({ itemLogic, itemBoolean });
			}
			if (logicList.length === 1) {
				return logicList[0].itemBoolean;
			}
			// 循环逻辑关系
			let prevLogicBoolean = null;
			for (let j = 1; j < logicList.length; j++) {
				const prev = logicList[j - 1];
				const prevItemLogic = prev.itemLogic;
				if (prevItemLogic === 'and') {
					if (j === 1) {
						prevLogicBoolean = prev.itemBoolean && logicList[j].itemBoolean
					} else {
						prevLogicBoolean = prevLogicBoolean && logicList[j].itemBoolean;
					}
					if (!prevLogicBoolean) {
						return false;
					}
				} else if (prevItemLogic === 'or') {
					if (j === 1) {
						prevLogicBoolean = prev.itemBoolean || logicList[j].itemBoolean
					} else {
						prevLogicBoolean = prevLogicBoolean || logicList[j].itemBoolean
					}
					if (!prevLogicBoolean) {
						return false;
					}
				}
			}
			return true;
		},
		/**
		 * @desc: 判断是否存在依赖其他组件的取值
		 */
		hasComponentParam() {
			if (!this.database.paramsConfig || !this.database.paramsConfig.length) {
				return false;
			}
			for (let i = 0; i < this.database.paramsConfig.length; i++) {
				const { componentId = '', key = '', sourceType = '' } = this.database.paramsConfig[i];
				if ((key !== 'search' || !componentId.includes('CommonForm')) && sourceType !== 'url') {
					// componentId.includes('CommonTableContainer')兼容跨页请求的动态判定
					// 补充不同架构问题
					if (!componentId.includes('CommonTableContainer') && this.paramsSameArchi(componentId)) {
						return true;
					}
				}
			}
			return false;
		},
		/**
		 * @desc: 判断依赖的参数是否在当前架构下启用(@凌志华树形图架构限制)
		 * @param {String} componentId
		 * @return {Boolean}
		 */
		paramsSameArchi(componentId) {
			let comp = getComponentById(this.componentData, componentId);
      if (!comp && this.isGroup && this.groupComponents.length) {
				comp = getComponentById(this.groupComponents, componentId);
			}
			if (!comp) return false;
			const targetArchi = this.$GetTargetArchi('archiType');
			if (comp && comp.archiLimit && comp.archiLimit.length && comp.archiLimit.includes(targetArchi)) {
				return true;
			}
			return false;
		},
		/**
		 * @desc: 初始化获取参数
		 */
		initParams() {
			const { paramsConfig } = this.element.database;
			let canPost = true;
			if (!paramsConfig || !paramsConfig.length) return { canPost };
			let search = [];
			const param = {};
			for (let i = 0; i < paramsConfig.length;i++) {
				const ele = paramsConfig[i];
				let originComponent = getComponentById(this.componentData, ele.componentId);
				if (!originComponent && this.isGroup && this.groupComponents.length) {
					originComponent = getComponentById(this.groupComponents, ele.componentId);
				}
				if (ele.key !== 'search') {
					if (ele.sourceType === 'url') {
						// url参数
						const queryVal = getQueryValue(ele.urlParamsKey);
						this.$set(param, ele.key, queryVal);
						if (ele.isRequired && !queryVal) {
							canPost = false;
							break;
						}
					} else if (!ele.sourceType) {
						// 来源于组件
						if (ele.isRequired && (!originComponent || !originComponent.resolveData)) {
							canPost = false;
							break;
						} else if (originComponent && originComponent.resolveData) {
							const queryVal = originComponent && originComponent.resolveData[ele.paramKey];
							this.$set(param, ele.key, queryVal);
							if (ele.isRequired && !queryVal) {
								canPost = false;
								break;
							}
						}
					} else if (ele.sourceType === 'system') {
						// 系统及架构参数 用户信息，架构信息
						let systemObject = null;
						if (ele.systemKey) {
							if (ele.systemKey === 'userInfo') {
								systemObject = this.$GetUserInfo()
							} else if (ele.systemKey === 'targetArchi') {
								systemObject = this.$GetTargetArchi()
							}
						}
						let systemValue = '';
						if (systemObject && ele.systemCode) {
							systemValue = systemObject[ele.systemCode];
						}
						this.$set(param, ele.key, systemValue);
						if (ele.isRequired && (!systemObject || !systemValue)) {
							canPost = false;
							break;
						}
					}
				} else {
					if (originComponent && originComponent.resolveData && Array.isArray(originComponent.resolveData)) {
						search = search.concat(originComponent.resolveData);
					}
				}
			}
			return {
				search,
				param,
				canPost
			};
		},
		/**
		 * @desc: 操作
		 * @param {String} action 操作
		 * @param {Object} output 输出的数据
		 */
		onAction(action, output, e) {
			e.preventDefault();
			e.stopPropagation();
			this.$store.commit('modifyComponent', {
				component: {
					...this.element,
					resolveData: output
				},
				containerId: null,
				isModify: true
			});
			const eventList = this.element.actionConfig[action].eventList;
			if (eventList && eventList.length) {
				eventList.forEach((el) => {
					el.eventList.forEach(ele => {
						if (ele.key === 'click') {
							// 跳转页面
							if (ele.actionType === 'jumpPage') {
								if (ele.linkType === 'projectPage') {
									const query = {};
									ele.queryList.forEach((queryItem) => {
										let component = getComponentById(this.componentData, queryItem.componentId);
										if (!component && this.isGroup && this.groupComponents.length) {
											component = getComponentById(this.groupComponents, queryItem.componentId);
										}
										this.$set(
											query,
											queryItem.key,
											component.resolveData[queryItem.feild]
										);
									});
									// this.$router.push({
									//   path: ele.routePath,
									//   query
									// });
								} else if (ele.linkType === 'outPage') {
									window.open(ele.linkUrl);
								} else if (ele.linkType === 'custom') {
									const customLink = getLinkByTemplate(ele.linkTemplate, output)
									window.open(customLink);
								} else if (ele.linkType === 'customForm') {
									const params = {}
									if(ele.customFormLink) {
										if(ele.customFormLink && Array.isArray(ele.customFormLink.params)) {
											ele.customFormLink.params.map(param => {
												if (param.queryType === "component") {
													let component = getComponentById(this.componentData, param.component);
													params[param.key] = component.resolveData[param.value]
												} else if (param.queryType === "system") {
													let systemObject = {}
													if (param.systemKey === 'targetArchi') {
														systemObject = this.$GetTargetArchi()
													} else if (param.systemKey === 'userInfo') {
														systemObject = this.$GetUserInfo()
													}
													params[param.key] = systemObject[param.systemCode]
												} else {
													params[param.key] = output[param.value]
												}
											})
										}
										const { object_uuid, view_uuid, detailData, editData, title, type, hideEdit, hideDelete } = ele.customFormLink
										if ( !object_uuid || !view_uuid || !title ) {
											Message.error('表单参数配置缺失！');
											return
										}
										let keyStr = ''
										Object.keys(this.metadata).map((key) => {
											if (this.metadata[key] === 'id') {
												keyStr = key
											}
										})
										const formInfo = ele.customFormLink
										// 编辑依赖判断
										const editShowBoo = formInfo.detailShow ? this.logicAction(formInfo.detailShow, output) : true
										//删除依赖判断
										const deleteShowBoo = formInfo.deleteShow ? this.logicAction(formInfo.deleteShow, output) : true
										//编辑UI判断
										const editUiBoo = judgingUILogic(formInfo && formInfo.detaiUILogic)
										//删除UI判断
										const deleteUiBoo = judgingUILogic(formInfo && formInfo.deleteUILogic)
										const editLimitBoo = formInfo.detailLimit ? judgingArchiAPermission(formInfo.detailLimit.archiLimit, formInfo.detailLimit.permission) : true
										const deleteLimitBoo = formInfo.deleteLimit ? judgingArchiAPermission(formInfo.deleteLimit.archiLimit, formInfo.deleteLimit.permission) : true
										let editVisible = editShowBoo && editUiBoo && editLimitBoo
										let deleteVisible = deleteShowBoo && deleteUiBoo && deleteLimitBoo
										if (!editData || hideEdit) {
											editVisible = false
										}
										if (hideDelete) {
											deleteVisible = false
										}
										eventBus.$emit('openPage', {
											type,
											data_id: output[keyStr],
											params, 
											object_uuid,
											view_uuid,
											detailData,
											editData,
											title,
											editVisible: editVisible,
											deleteVisible: deleteVisible,
										});
									}
								} else if (ele.linkType === 'pageH5') {
									const params = {}
									this.getConfigList()
									if (this.configList && this.configList.length && ele.paramsList.length) {
										this.configList.forEach(element => {
											ele.paramsList.forEach(item => {
												if (element.uuid === item.uuid) {
													params[item.key] = output[item.uuid]
												}
											});
										});
									}
									this.$router.push({
										name: 'ModifyPage',
										query: {
											...params,
											pageUUID: ele.page_uuid
										}
									});
									this.$router.go(0)
								}
							} else if (ele.actionType === 'eeAction') {
								console.log(ele, 'eleeleele')
								// 触发后端事件
								let logicBoolean = true
								if (ele?.logics?.length) {
									/* 后端事件逻辑判断 */
									logicBoolean = judgingEventLogic(ele.logics, this.componentData || [], output);
								}
								console.log(logicBoolean, 'logicBoolean')
								if (logicBoolean) {
									this.doEEAction(ele, output, 'main');
								}
								// 搜集参数
							} else {
								ele.effects.forEach((effect) => {
									let logicBoolean = true
									if (effect.logics.length) {
										logicBoolean = judgingEventLogic(effect.logics, this.componentData || [], output);
									}
									if (logicBoolean) {
										this.$store.commit('triggerEvents', {
											config: {
												...ele,
											...effect
											},
											element: this.element
										});
									}
								});
							}
						}
					})
				});
			}
		},
		/**
		 * @desc: 获取渲染列表
		 * @param {Object} database 数据配置对象
		 * @param {Array} search 搜索
		 */
		getList(database, search = [], params = {}) {
			this.search = Array.isArray(search) ? search : [];
			if (!this.validDatabase(database)) return;
			/* 外部注入参数 */
			const paramsObj = {
				...params,
				page: this.pager.current_page,
				size: 15
			};
			// 配置
			let __method_name__ = 'dataList';
			const mapping = database.mapping;
			let configObj = null;
			if (mapping === 'object') {
				configObj = {
					__method_name__,
					object_uuid: database.objectData.uuid,
					view_uuid: database.viewData.uuid,
					...paramsObj,
					search
				};
			} else if (mapping === 'relation') {
				__method_name__ = 'relationList';
				configObj = {
					__method_name__: 'relationList',
					object_uuid: database.objectData.uuid,
					relationship_uuid: database.relationData.uuid,
					...paramsObj,
					search
				};
			}
			/* 值绑定设置的获取请求参数 */
			if (database.mobileQeuryInfo && database.mobileQeuryInfo.params && database.mobileQeuryInfo.params.length) {
				const queryObj = this.getQueryStringArgs()
				database.mobileQeuryInfo.params.forEach(element => {
					if (element.component) {
						/* 组件取值 */
						const originComponent = getComponentById(this.componentData, element.component);
						if (originComponent.resolveData) {
							let obj = {
								ruleType: element.ruleType,
								value: originComponent.resolveData[element.value]
							}
							if (element.key.indexOf('field') !== -1) {
								obj.field_uuid = element.key
							} else {
								obj.code = element.key
							}
							configObj.search.push(obj)
						}
					} else {
						/* 路由取值 */
						for (const key in queryObj) {
							if (queryObj[element.key] && (element.key === key)) {
								let obj = {
									ruleType: element.ruleType,
									value: queryObj[key]
								}
								if (element.key.indexOf('field') !== -1) {
									obj.field_uuid = element.key
								} else {
									obj.code = element.key
								}
								configObj.search.push(obj)
							}
						}
					}
					
				});
			}
      this.loading = true;
			// 获取表格数据
			dataInterface(configObj)
				.then((res) => {
					if (res && res.status === 200) {
						if (!this.metadata || !this.metadata.length) {
							this.metadata = res.data.metadata;
						}
						let tableData = [];
						if (__method_name__ === 'dataList' || __method_name__ === 'relationList') {
							// 列表
							// 列表数据
							tableData = this.getListFromRes(res, true) || [];
						}
						for (let i = 0; i < this.fieldConfig.length; i++) {
							const item = this.fieldConfig[i];
							if (item.type === 7) {
								// switch 编辑
								tableData = tableData.map((ele) => {
									if (item.switchOptions.type === 'number') {
										// 数字
										ele[item.uuid] =
											+ele[item.uuid] === +item.switchOptions.trueValue;
									} else if (item.switchOptions.type === 'boolean') {
										if (ele[item.uuid] === 'true') {
											ele[item.uuid] = true;
										} else if (ele[item.uuid] === 'false') {
											ele[item.uuid] = false;
										}
									} else if (item.switchOptions.type === 'string') {
										ele[item.uuid] =
											ele[item.uuid] === item.switchOptions.trueValue;
									}
									return ele;
								});
							}
						}
						if (this.refreshing) {
							this.tableData = [];
							this.refreshing = false;
						}
						this.tableData.push(...tableData);
						this.copyTableData = JSON.parse(JSON.stringify(this.tableData))
						// 如果达到了最大条数或者说请求到了空数据将不再请求
            if(res.data.total <= this.tableData.length || tableData.length === 0) {
              this.finished = true
            }
						// 完全数据 暂时保存
						this.fullData = res.data.data;
						// 保存容器数据
						setTimeout(() => {
							this.$store.commit('modifyComponent', {
								component: {
									...this.element,
									containerData: this.tableData,
									fullData: this.fullData,
									metadata: res.data.metadata,
									database: {
										...this.element.database,
										fieldList: this.getFieldList(res.data.metadata)
									}
								},
								containerId: null,
								isModify: true
							});
						}, 10);
					}
					this.loading = false;
				})
				.catch(() => {
					this.error = true;
					this.loading = false;
				});
		},
		/**
		 * @desc: 获取query参数
		 * @param {Object} 
		 */
    getQueryStringArgs() {
      //取得查询字符串并去掉开头的问号
      var qs = location.search.length > 0 ? location.search.substring(1) : "",
      //保存数据的对象
      args = {},
      //取得每一项
      items = qs.length ? qs.split("&") : [],
      item = null,
      name = null,
      value = null,
      //在 for 循环中使用
      i = 0,
      len = items.length;
      //逐个将每一项添加到 args 对象中
      for (i = 0; i < len; i++) {
        item = items[i].split("=");
        name = decodeURIComponent(item[0]);
        value = decodeURIComponent(item[1]);
        if (name.length) {
          args[name] = value;
        }
      }
      return args;
    },
		/**
		 * @desc: 获取字段列表
		 * @param {Object} obj metadata对象
		 */
		getFieldList(obj) {
			const arr = [];
			if (obj && typeof obj === 'object') {
				for (let key in obj) {
					arr.push({
						name: obj[key],
						uuid: key
					});
				}
			}
			return arr;
		},
		/**
		 * @desc: 格式化比对收缩内容
		 * @param {Array} search 当前组件中的搜索内容
		 * @param {Array} outputSearch 外部传入的搜索项目
		 */
		formatSearch(search, outputSearch) {
			if (!search.length) return outputSearch;
			let result = search || [];
			for (let i = 0; i < outputSearch.length; i++) {
				const item = outputSearch[i];
				const index = search.findIndex(
					(ele) => ele.field_uuid === item.field_uuid
				);
				if (index === -1) {
					result.push(item);
				} else {
					result.splice(index, 1, item);
				}
			}
			return result;
		},
		/**
		 * @desc: 验证
		 * @param {Object} database 数据仓库的绑定
		 */
		validDatabase(database) {
			if (!database || typeof database !== 'object') return false;
			if (!database.objectData) return false;
			if (!database.viewData && !database.relationData) return false;
			return true;
		},
		/**
		 * @desc: 根据请求返回获取列表结构
		 * @param {Object} res 请求返回的数据
		 * @param {Boolean} withPager 是否需要重置pager
		 */
		getListFromRes(res, withPager = true) {
			if (res.data && Array.isArray(res.data)) {
				if (withPager) {
					this.pager = res;
				}
				return res.data;
			}
			if (res.data && typeof res === 'object') {
				return this.getListFromRes(res.data, withPager);
			}
			return [];
		},
		/**
		 * @desc: 切换页码
		 * 实现页面 +1
		 */
		onPage() {
      this.page += 1;
			this.pager = {
        current_page: this.page
			};
			let { search = [], param = {}, canPost } = this.initParams();
			if (canPost) {
				this.getList(this.database,search, param || {});
			}
		},
		/**
		 * @description 下拉刷新
		 */
		onRefresh() {
      // 清空列表数据
      this.finished = false;
			this.pager.current_page = 1;
			this.refreshing = true
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      let { search = [], param = {}, canPost } = this.initParams();
			if (canPost) {
				this.getList(this.database,search, param || {});
			}
    },
		/**
		 * @desc: 获取状态值
		 * @param {any普通类型} value 值
		 * @param {Array} statusOptions 状态选项
		 * @return {Object} 用于状态显示的对象  label value color
		 */
		filterStatus(value, statusOptions = []) {
			if (!statusOptions || !Array.isArray(statusOptions)) {
				return {
					label: value
				};
			}
			for (let i = 0; i < statusOptions.length; i++) {
				if (
					(isNaN(value) && statusOptions[i].value === value) ||
					+statusOptions[i].value === +value
				) {
					return statusOptions[i];
				}
			}
			return {
				label: value
			};
		},
		/**
		 * @description 格式化颜色透明
		 * @param color { String } 颜色
		 * @param op { String } 透明的
		 * @return String 格式化后的颜色
		 */
		formatColorOp(color = '', op = 0.2) {
			if(!color) {
				return ''
			}
			if(color.startsWith('#')) {
				if (color.length === 4) {
					return `RGBA(${Number('0x'+color[1] + color[1])},${Number('0x'+color[2] + color[2])},${Number('0x'+color[3] + color[3])},${op})`
				} else if(color.length === 7) {
					return `RGBA(${Number('0x'+color[1] + color[2])},${Number('0x'+color[3] + color[4])},${Number('0x'+color[5] + color[6])},${op})`
				}
			} else if(color.startsWith(/RGBA/gi)) {
				const colorArr = color.split(',')
				colorArr[colorArr.length - 1] = `${op})`
				return colorArr.join()
			} else if(color.startsWith(/rgb/gi)) {
				return color.substring(0, color.length -1) + `,${op})`
			} else {
				return color
			}
		},
		/**
		 * @desc: 格式化时间
		 * @param {String/Object/Number} value 时间值 兼容时间戳、时间字符串、时间对象
		 * @param {String} timeFormat 格式化
		 * @param {String} connector 连接符
		 */
		filterTime(value, timeFormat, connector = '') {
			if (!value) return '';
			// 时间格式表单无法统一不做校验
			// const reg = /^(\d{4})(-|\/)(\d{2})(-|\/)(\d{2})/;
			// if ((isNaN(+value) && !reg.test(value))) return '';
			let dateObj = null;
			if (value instanceof Date) {
				dateObj = value;
			} else if (isNaN(value)) {
				dateObj = new Date(value);
			} else {
				dateObj = new Date(value * 1000);
			}
			// @凌志华 后端返回的无效时间显示为 '' (2022-02-15 15:51:51)
			if (!(dateObj instanceof Date) || dateObj.toString() === 'Invalid Date') return '';
			const year = dateObj.getFullYear();
			// 无效的时间
			if (year === 1970) return '';
			let month = this.addZero(dateObj.getMonth() + 1);
			const day = this.addZero(dateObj.getDate());
			const hour = this.addZero(dateObj.getHours());
			const minute = this.addZero(dateObj.getMinutes());
			const second = this.addZero(dateObj.getSeconds());
			let connectorStr = '-';
			if (connector === '/') {
				connectorStr = '/';
			} else if (connector === 'cn') {
				connectorStr = connector;
			}
			const defaultStr = `${year}${
				connectorStr === 'cn' ? '年' : connectorStr
			}${month}${connectorStr === 'cn' ? '月' : connectorStr}${day}${
				connectorStr === 'cn' ? '日' : ''
			} ${hour}${connectorStr === 'cn' ? '时' : ':'}${minute}${
				connectorStr === 'cn' ? '分' : ':'
			}${second}${connectorStr === 'cn' ? '秒' : ''}`;
			switch (timeFormat) {
				case 'YMDhms':
					return defaultStr;
				case 'YMDhm':
					return `${year}${
						connectorStr === 'cn' ? '年' : connectorStr
					}${month}${connectorStr === 'cn' ? '月' : connectorStr}${day}${
						connectorStr === 'cn' ? '日' : ''
					} ${hour}${connectorStr === 'cn' ? '时' : ':'}${minute}${
						connectorStr === 'cn' ? '分' : ''
					}`;
				case 'YMDh':
					return `${year}${
						connectorStr === 'cn' ? '年' : connectorStr
					}${month}${connectorStr === 'cn' ? '月' : connectorStr}${day}${
						connectorStr === 'cn' ? '日' : ''
					} ${hour}${connectorStr === 'cn' ? '时' : ''}`;
				case 'YMD':
					return `${year}${
						connectorStr === 'cn' ? '年' : connectorStr
					}${month}${connectorStr === 'cn' ? '月' : connectorStr}${day}${
						connectorStr === 'cn' ? '日' : ''
					}`;
				case 'YM':
					return `${year}${
						connectorStr === 'cn' ? '年' : connectorStr}${month}${connectorStr === 'cn' ? '月' : ''}`;
				case 'MD':
					return `${month}${connectorStr === 'cn' ? '月' : connectorStr}${day}${connectorStr === 'cn' ? '日' : ''}`;
				case 'hms':
					return `${hour}${connectorStr === 'cn' ? '时' : ':'}${minute}${
						connectorStr === 'cn' ? '分' : ':'
					}${second}${connectorStr === 'cn' ? '秒' : ''}`;
				case 'hm':
					return `${hour}${connectorStr === 'cn' ? '时' : ':'}${minute}${
						connectorStr === 'cn' ? '分' : ''
					}`;
				case 'yyyy':
					return `${year}${connectorStr === 'cn' ? '年' : ''}`;
				case 'mm':
					return `${month}${connectorStr === 'cn' ? '月' : ''}`;
				case 'dd':
					return `${day}${connectorStr === 'cn' ? '日' : ''}`;
				case 'weekDay':
					return this.weekDays[dateObj.getDay()];
				case 'week':
					return `第${this.getWeek(dateObj)}周`;
				default:
					return defaultStr;
			}
		},
		/**
		 * @desc: 数字
		 * @param {Number} value
		 * @param {Object} numberFormat
		 */
		filterNumber(value, numberFormat = {}) {
			if (isNaN(value) && isNaN(+value)) return value;
			const { type, decimalPlace, prefix = '', suffix = '' } = numberFormat;
			let result = value;
			if (type === 'float') {
				result = (+value).toFixed(isNaN(+decimalPlace) ? 0 : +decimalPlace);
			}
			return `${prefix} ${result} ${suffix}`;
		},
		/**
		 * @desc: 补零
		 * @param {Number} val 需要补零的值
		 */
		addZero(val) {
			if (isNaN(val) || val < 0 || val >= 10) return val;
			return `0${val}`;
		},
		/**
		 * @desc: 获取当前日期对象为全年第几周
		 * @param {Object<Date>} dateObj 时间对象
		 * @return {Number} 第几周
		 */
		getWeek(dateObj) {
			const firstDay = new Date(`${dateObj.getFullYear()}-1-1`);
			const timeBetween = dateObj - firstDay;
			const between = timeBetween / (7 * 24 * 60 * 60 * 1000);
			return Math.ceil(between);
		},
		/**
		 * @desc: 验证架构类型
		 * @param {Array, String} types 类型 group company project
		 */
		validArchiType(types) {
			if (!types || !Array.isArray(types) || !types.length) return true;
			if (this.$GetUserInfo('name') === 'admin') return true;
			const archiType = this.$GetTargetArchi('archiType');
			if (archiType && types.includes(archiType)) {
				return true;
			}
			return false;
		},
		/**
		 * @desc: 获取当前组件绑定数据对象的key值配置
		 * @param 
		 * @return 
		 */
    getConfigList() {
      this.configList = [];
			if (!this.curComponent) return;
      if (
        this.curComponent.fieldConfig &&
        this.curComponent.fieldConfig.length
      ) {
        this.configList = this.curComponent.fieldConfig.map(ele => {
          return {
            align: 'center',
            headerAlign: 'center',
            numberOptions: {
              type: '', // 类型
              decimalPlace: '', // 小数位数
              prefix: '', // 前缀
              suffix: '' // 后缀
            },
            permission: [],
            UILogic: null,
            ...ele,
            quickEdit: false,
          }
        });
        return;
      }
      if (!this.curComponent && !this.curComponent.metadata) return;
      // this.initField();
    },
		/* 格式化数据显示 */
		getShowValue(data, value) {
			let showValue = '-'
			let fieldConfig = {}
			if (Array.isArray(this.element.fieldConfig) && this.element.fieldConfig.length) {
				this.element.fieldConfig.forEach(element => {
					if (element.uuid === value) {
						fieldConfig = element
					}
				});
			}
			if (fieldConfig && fieldConfig.type === 4) {
				showValue = this.filterTime(data[value], fieldConfig.timeFormat, fieldConfig.connector)
			} else {
				showValue =	data[value]
			}
			return showValue
		},
		/**
		 * @desc: 触发后端事件
		 */
		doEEAction(btnConfig, rowData, type = 'main') {
			if (btnConfig && btnConfig.actionType === 'eeAction') {
				const { objectUUID, viewUUID, eventName } = btnConfig;
				if (!objectUUID || !viewUUID || !eventName) {
					this.$message.error('请正确配置事件!');
					return false;
				}
				// const idUUID = this.getIdUUID();
				const idUUID = type === 'main' ? this.getIdUUID() : this.statusConfig.nestingRowKey;
				const data_id = rowData?.[idUUID];
				if (!data_id) {
					this.$message.error('事件未配置触发对象!');
					return false;
				}
				this.loading = true;
				dataInterface({
					__method_name__: 'customEventCall',
					object_uuid: btnConfig.objectUUID,
					view_uuid: btnConfig.viewUUID,
					data_id,
					event: btnConfig.eventName
				}).then((res) => {
					if (res.status === 200 && res.data.code === 200) {
						this.$message.success('操作成功！');
						doEEActionHandle(res.data?.__adds__);
						this.onPage(this.pager.current_page);
					}
					this.loading = false;
				}).catch((err) => {
					console.log(err, '00000后端事件错误');
					this.loading = false;
				});
			}
		},
		/**
		 * @desc: 获取数据主键，ID UUID
		 */
		getIdUUID() {
			let idUUID = this.statusConfig.idUUID || '';
			if (!idUUID) {
				let idCount = 0;
				for (let key in this.metadata) {
					if (this.metadata[key] === 'id') {
						idCount++;
						idUUID = key;
					}
				}
				// 编辑器提示
				if (['/modify-page', '/page-view'].includes(this.$route.path)) {
					// 编辑器提示ID问题
					if (idCount === 0) {
						setTimeout(() => {
							this.$message.warning('接口无【id】字段返回，请检查视图！');
						}, 0);
						return '';
					}
					if (idCount > 1) {
						setTimeout(() => {
							this.$message.warning(`接口存在【${idCount}】个【id】字段返回，请检查视图！`);
						}, 0);
						return '';
					}
				}
			}
			return idUUID;
		},
	},
	unmounted() {
		// v3
		eventBus.$off('databaseTrigger');
		eventBus.$off('openPage');
		eventBus.$off('closeAddDrawer');
		eventBus.$off('routeTopInputSearch');
		console.log('销毁', '14141414')
	}
})
</script>

<style lang="less" scoped>
	.list-container{
		background: #f1f1f1;
		padding: 5px;
		box-sizing: border-box;
		.list-item{
			display: flex;
			align-items: center;
			background: #fff;
			padding: 12px 16px;
			box-sizing: border-box;
			border-radius: 5px;
			.item-img-warp{
				margin-right: 16px;
				img{
					border-radius: 6px;
				}
			}
			.item-text-warp{
				flex-grow: 1;
				width: 100px;
				.title-and-status-warp{
					width: 100%;
					display: flex;
					flex-wrap: nowrap;
					align-items: center;
					.title{
						font-size: 16px;
						font-weight: 520;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						text-align: left;
						line-height: 24px;
					}
					.status{
						line-height: 20px;
						padding: 0 10px;
						border-radius: 12px;
						white-space: nowrap;
						margin-left: 16px;
					}
				}
				.text-item{
					width: 100%;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					text-align: left;
					line-height: 22px;
					font-size: 14px;
					.status{
						line-height: 20px;
						padding: 0 10px;
						border-radius: 12px;
						white-space: nowrap;
						margin-right: 8px;
					}
				}
			}
			& + .list-item{
				margin-top: 10px;
			}
		}
		.skeleton-item{
			background: #fff;
			border-radius: 8px;
			padding: 5px;
			box-sizing: border-box;
			& + .skeleton-item{
				margin-top: 10px;
			}
		}
	}
</style>
