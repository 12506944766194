<!--
 * @Author: 朱廷果 1028509503@qq.com
 * @Date: 2022-12-13 17:18:20
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2022-12-14 11:40:02
 * @FilePath: \dataview-next\src\custom-component\form\newParser\FormItem\widget-date.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--
    @name: widget-date
    @description：widget-date
    @author: ZengWei
    @date: 2022-03-25 09:36
-->
<template>
  <el-date-picker
      v-if="!(disabled || element.config.disabled)"
      v-model="element.value"
      :disabled="disabled || element.config.disabled"
      :type="element.config.type"
      :range-separator="element.config['range-separator']"
      :format="element.config.format"
      :placeholder="element.config.placeholder"
      :value-format="element.config['value-format']"
      style="width: 100%"
    >
    </el-date-picker>
    <p class="reshow-text" v-else>{{ reShowVal || '-' }}</p>
</template>

<script>
import {DatePicker} from "element-ui";
import dayjs from 'dayjs';
import formItemMinix from "@/custom-component/form/newParser/scripts/formItemMinix";

export default {
  name: "WidgetDate",
  components: {
    'el-date-picker': DatePicker,
  },
  mixins: [formItemMinix],
  props: ['element', 'disabled', 'displayData'],
  data() {
    return {}
  },
  computed: {
    reShowVal() {
      // 表单配置有时候没给默认值会给个0000的默认值?
      if(this.element.value && this.element.value !== "0000-00-00 00:00:00") {
        if(this.element.config.format) {
          // dayjs 与之前所见的格式化不同，这里做适配处理
          let format = this.element.config.format
          format = format.replace('yyyy', 'YYYY')
          format = format.replace('dd', 'DD')
          return dayjs(new Date(this.element.value)).format(format)
        } else {
          return this.element.value
        }
      } else {
        return '-'
      }
    }
  },
  methods: {},
}
</script>

<style scoped>

</style>
