/*
 * @Description: 事件列表
 * @Author: luocheng
 * @Date: 2021-08-25 10:20:17
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2022-12-08 11:56:09
 */
import store from './index';
import { getComponentById } from '@/utils/tools';
import Vue from 'vue';
import eventBus from '@/plugins/eventBus';

/**
	用于记录每个组件的缓存状态
	记录值为Boolean, 键值为组件id
 */
const cacheHash = {}
export default {
	state: {
		eventList: [],
		// 状态对应列表
		event2Status: {
			hidden: {
				key: 'isShow',
				value: false
			},
			show: {
				key: 'isShow',
				value: true
			},
			displayShow: {
				key: 'displayShow',
				value: true
			},
			displayHidden: {
				key: 'displayShow',
				value: false
			},
			cacheDisplay: {
				key: 'USECATCH',
				value: true
			},
			cacheHidden: {
				key: 'USECATCH',
				value: false
			},
			active: {
				key: 'isActive',
				value: true
			},
			inactive: {
				key: 'isActive',
				value: false
			}
		}
	},
	mutations: {
		/**
		 * @desc: 编辑组件事件
		 */
		modifyComponentEvent(state, eventConfigs) {
			const keys = Object.keys(state.curComponent.events);
			const newKeys = [];
			for (let i = 0; i < eventConfigs.length; i++) {
				const { eventList = [], pattern = undefined, specialEventList = [] } = eventConfigs[i];
				if (pattern === undefined) {
					const data = eventConfigs[i];
					newKeys.push(data.key);
					if (!state.curComponent.events[data.key]) {
						state.curComponent.events[data.key] = {};
					}
					// 修复组件事件绑定
					state.curComponent.events[data.key] = {
						...data
					}
				} else {
					const result = !pattern ? eventList : specialEventList;
					for (let i = 0; i < result.length; i++) {
						const data = result[i];
						newKeys.push(data.key);
						if (!state.curComponent.events[data.key]) {
							state.curComponent.events[data.key] = {};
						}
						// 老逻辑已注释
						// state.curComponent.events[data.key].effects = data.effects;
						// 修复组件事件绑定
						state.curComponent.events[data.key] = {
							...data
						}
					}
				}
			}
			// 删除已不存在的事件绑定
			for (let j = 0; j < keys.length; j++) {
				if (!newKeys.includes(keys[j])) {
					delete state.curComponent.events[keys[j]];
				}
			}
			// 测试
			// sessionStorage.setItem('componentData', JSON.stringify(state.componentData));
		},
		/**
		 * @desc: 编辑组件内部事件
		 * @param {Array} eventList 事件列表
		 * @param {Object} partData 当前操作的按钮
		 */
		modifyComInsideEvent({ curComponent }, { eventList = [], partData, isNesting = false }) {
			if (!curComponent || !partData || (!partData.type && !partData.key)) return;
			if (
				['CommonTableContainer', 'ListContainerOne', 'ConsoleList',
					'ConsoleForm', 'MobileSelectTree', 'ConsoleTypeTree', 'ViewerBIM',
					'ThreeTerminal', 'BindComponent', 'CommonGantt'].includes(curComponent.component)
			) {
				// 表格容器
				if (isNesting) {
					// 子表
					if (!curComponent.nestingActionConfig) return;
					Vue.set(curComponent.nestingActionConfig[partData.type], 'eventList', {
						...curComponent.nestingActionConfig[partData.type].eventList,
						...eventList
					})
					curComponent.nestingActionConfig[partData.type].eventList = eventList;
				} else {
					// 单一表格
					if (!curComponent.actionConfig) return;
					Vue.set(curComponent.actionConfig[partData.type], 'eventList', {
						...curComponent.actionConfig[partData.type].eventList,
						...eventList
					});
					curComponent.actionConfig[partData.type].eventList = eventList;
				}
			} else if (curComponent.component === 'CommonTabs') {
				// tab组件
				const tabConfig = curComponent.tabConfig;
				for (let i = 0; i < tabConfig.length; i++) {
					if (partData.type === tabConfig[i].type) {
						tabConfig.splice(i, 1, {
							...tabConfig[i],
							eventList: eventList
						});
						break;
					}
				}
				Vue.set(curComponent, 'tabConfig', tabConfig);
			} else if (curComponent.component === 'RouteTop' || curComponent.component === 'Calendar') {
				if (partData.type && partData.type === 'rowClick' && partData.use) {
					// 表格容器
					if (isNesting) {
						// 子表
						if (!curComponent.nestingActionConfig) return;
						Vue.set(curComponent.nestingActionConfig[partData.type], 'eventList', {
							...curComponent.nestingActionConfig[partData.type].eventList,
							...eventList
						})
						curComponent.nestingActionConfig[partData.type].eventList = eventList;
					} else {
						// 单一表格
						if (!curComponent.actionConfig) return;
						Vue.set(curComponent.actionConfig[partData.type], 'eventList', {
							...curComponent.actionConfig[partData.type].eventList,
							...eventList
						})
						curComponent.actionConfig[partData.type].eventList = eventList;
					}
					return
				}
				if (curComponent.component === 'RouteTop') {
					// 移动端 tab组件
					const tabConfig = curComponent.tabConfig;
					for (let i = 0; i < tabConfig.length; i++) {
						// console.log(partData.type,'partData.type');
						// console.log(tabConfig[i].type,'tabConfig[i].type');
						if (partData.type === tabConfig[i].type) {
							tabConfig.splice(i, 1, {
								...tabConfig[i],
								eventList: eventList
							});
							break;
						}
					}
					Vue.set(curComponent, 'tabConfig', tabConfig);
				} else {
					// 移动端 tab组件
					const tabConfig = curComponent.tabConfig.tabs;
					for (let i = 0; i < tabConfig.length; i++) {
						if (partData.type === tabConfig[i].type) {
							tabConfig.splice(i, 1, {
								...tabConfig[i],
								eventList: eventList
							});
							break;
						}
					}
					Vue.set(curComponent.tabConfig, 'tabs', tabConfig);
				}
			} else if (curComponent.component === 'CommonForm' || curComponent.component === 'ConsoleFilter') {
				// Form组件
				const actionConfig = curComponent.actionConfig;
				for (let i = 0; i < actionConfig.length; i++) {
					if (partData.type === actionConfig[i].type) {
						actionConfig.splice(i, 1, {
							...actionConfig[i],
							eventList: eventList
						});
						break;
					}
				}
				Vue.set(curComponent, 'actionConfig', actionConfig);
			} else if (curComponent.component === 'CommonTree') {
				// 树组件
				const actionConfig = curComponent.actionConfig || [];
				for (let i = 0; i < actionConfig.length; i++) {
					if (actionConfig[i].key === partData.type) {
						// const result = actionConfig[i].eventList.concat(eventList)
						actionConfig.splice(i, 1, {
							...actionConfig[i],
							eventList: eventList
						});
						break;
					}
				}
				Vue.set(curComponent, 'actionConfig', actionConfig)
			} else if (curComponent.component === 'CommonProjectMap') {
				// GIS组件
				const actionConfig = curComponent.actionConfig || [];
				for (let key in actionConfig) {
					if (key === partData.type) {
						actionConfig[key] = {
							...actionConfig[key],
							eventList
						};
					}
				}
				Vue.set(curComponent, 'actionConfig', actionConfig)
			} else if (curComponent.component === 'CommonDialog') {
				if (!curComponent.actionConfig) return;
				const actionConfig = curComponent?.actionConfig || [];
				const index = actionConfig.findIndex(ele => ele.key === partData.key);
				actionConfig.splice(index, 1, {
					...actionConfig[index],
					eventList
				})
				Vue.set(curComponent, 'actionConfig', actionConfig);
			} else {
				// 老版表格(弃用)
				if (
					!curComponent.statusConfig ||
					!curComponent.statusConfig.action ||
					!curComponent.statusConfig.action[partData.type]
				) {
					return;
				}
				Vue.set(curComponent.statusConfig.action[partData.type], 'eventList', {
					...curComponent.statusConfig.action[partData.type].eventList,
					...eventList
				})
				curComponent.statusConfig.action[partData.type].eventList = eventList;
			}
			store.commit('modifyComponent', {
				component: curComponent,
				containerId: null,
				isModify: true
			});
		},
		/**
		 * @desc: 触发事件
		 * @param {Array} targets 目标组件
		 * @param {Array} actions 要进行的操作
		 */
		triggerEvents(state, { config = {}, element }) {
			const { targets = [], actions = [], logics = [], isBehavior = false, behavior = {} } = config;
			if (isBehavior) {
				// 触发行为
				eventBus.$emit('doComponentBehavior', {
					...behavior
				});
				return;
			}
			if (!targets.length || !actions.length) return;
			const status = [];
			actions.forEach((action) => {
				status.push(state.event2Status[action]);
			});
			// 循环切换
			targets.forEach((target) => {
				// event bus 挂载卸载问题
				setTimeout(() => {
					store.commit('changeComponentStatus', { target, status, logics, element });
				}, 0)
			});
		},
		/**
		 * @desc: 切换组件状态值(事件)
		 * @param {String} target 目标组件ID
		 * @param {Array} status 状态数组
		 */
		changeComponentStatus(state, { target, status, logics }) {
			const component = getComponentById(state.componentData, target);
			if (!component || !component.statusConfig) return;
			// console.log(component, 'state.component')
			// 附加逻辑判断
			let logicBoolean = true;
			if (logics && logics.length) {
				for (let i = 0; i < logics.length; i++) {
					const item = logics[i];
					// type: 'component/ system / inner
					// dataAttr: "value"
					// dataField: "field61a885dcdbd42"
					// dataKey: "resolveData"
					// itemLogic: "and"
					// logicRule: "!="
					// target: "CommonTree-1642581368412"
					// type: "component" 暂未启用
					// value: "version"
					// valueType: "string"
					const { type, dataAttr, dataField, dataKey, itemLogic, logicRule, value, valueType, valueObj, valueKey } = item;
					let resolveData = null;
					// console.log(type, '-----type 取值类型')
					if (type === 'component') {
						// 对象参数
						const resolveTargetKey = item.target;
						if (!dataKey || !resolveTargetKey || !valueType || !logicRule || !dataAttr) {
							break;
						}
						const resolveTarget = getComponentById(state.componentData, resolveTargetKey);
						if (!resolveTarget) {
							break;
						}
						if (!resolveTarget.statusConfig.isShow) {
							logicBoolean = false;
							break;
						}
						if (dataKey === 'propValue') {
							resolveData = resolveTarget.propValue;
						} else if (dataAttr === 'value') {
							resolveData = resolveTarget.resolveData?.[dataField];
						} else if (dataAttr === 'length') {
							resolveData = resolveTarget.resolveData.length;
						}
					} else if (type === 'system') {
						// 前端取值对象
						let result = sessionStorage.getItem(valueObj);
						if (!result) {
							result = localStorage.getItem(valueObj);
						}
						try {
							const value = JSON.parse(result)?.[valueKey];
							if (dataAttr === 'value') {
								resolveData = value;
							} else if (dataAttr === 'length') {
								resolveData = value.length;
							}
						} catch (err) {
							console.log(err);
						}
					}
					// console.log(resolveData, '------resolveData----', value, logicRule);
					// 判断关系
					let booleanItem = false;
					if (logicRule === '===') {
						if (valueType === 'string') {
							booleanItem = resolveData === (value + '');
						} else if (valueType === 'number') {
							booleanItem = +resolveData === +value;
						} else if (valueType === 'date') {
							booleanItem = (new Date(resolveData)).getTime() === +value;
						}
					} else if (logicRule === '!=') {
						if (valueType === 'string') {
							booleanItem = resolveData !== (value + '');
						} else if (valueType === 'number') {
							booleanItem = +resolveData !== +value;
						} else if (valueType === 'date') {
							booleanItem = (new Date(resolveData)).getTime() !== +value;
						}
					} else if (logicRule === '>') {
						if (valueType === 'number') {
							booleanItem = +resolveData > +value;
						} else if (valueType === 'date') {
							booleanItem = (new Date(resolveData)).getTime() > +value;
						}
					} else if (logicRule === '<') {
						if (valueType === 'number') {
							booleanItem = +resolveData < +value;
						} else if (valueType === 'date') {
							booleanItem = (new Date(resolveData)).getTime() < +value;
						}
					} else if (logicRule === '<=') {
						if (valueType === 'number') {
							booleanItem = +resolveData <= +value;
						} else if (valueType === 'date') {
							booleanItem = (new Date(resolveData)).getTime() <= +value;
						}
					} else if (logicRule === '>=') {
						if (valueType === 'number') {
							booleanItem = +resolveData >= +value;
						} else if (valueType === 'date') {
							booleanItem = (new Date(resolveData)).getTime() >= +value;
						}
					}
					if (i === 0) {
						logicBoolean = booleanItem;
					} else {
						if (itemLogic === 'and') {
							logicBoolean = logicBoolean && booleanItem;
						} else if (itemLogic === 'or') {
							logicBoolean = logicBoolean || booleanItem;
						}
					}
				}
			}
			if (!logicBoolean) return;
      console.log(component)
			if (component?.component === 'CommonDialog') {
				// 表单特殊处理
			}
			status.forEach((item) => {
				/* eslint-disable */
				if (component.statusConfig.hasOwnProperty(item.key)) {
					component.statusConfig[item.key] = item.value;
				} else if (item.key === 'USECATCH') {
					// 如果是需要缓存显示或隐藏的
					// 显示操作
					if (item.value === true) {
						// 这里强制处理v-if 与 v-show 避免显示时失败 
						if (Object.hasOwnProperty.call(cacheHash, target)) {
							component.statusConfig['displayShow'] = item.value;
							if (component.statusConfig['isShow'] === false) {
								component.statusConfig['isShow'] = item.value;
							}
						} else {
							component.statusConfig['isShow'] = item.value;
							if (component.statusConfig['displayShow'] === false) {
								component.statusConfig['displayShow'] = item.value;
							}
						}
						cacheHash[target] = item.value;
					} else if (item.value === false) { // 隐藏操作
						// if(Object.hasOwnProperty.call(cacheHash, target)) {
						// 	component.statusConfig['displayShow'] = item.value;
						// } else {
						// 	component.statusConfig['isShow'] = item.value;
						// }
						// 无论如何都只修改displayShow
						component.statusConfig['displayShow'] = item.value;
						cacheHash[target] = item.value;
					}
				}
			});
		}
	}
};
