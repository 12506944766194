<!--
 * @Author: wsp
 * @Date: 2021-10-22 14:29:14
 * @LastEditors: zx
 * @LastEditTime: 2022-12-07 09:05:34
 * @Description:
-->

<template>
	<div class="file-upload">
		<el-upload
			v-if="!disabled"
			:action="uploadURL"
			:before-upload="beforeUpload"
			class="upload-style"
			:data="{ token }"
			:multiple="multiple"
			:on-success="onSuccess"
			:show-file-list="uploading"
			:on-change="getModelFile"
			:limit="goalType === 'default' ? 100 : 1"
			:headers="{
        metadatatoken: 'aba62ca1-c2ff-42af-9d3a-bbe5b02f7b06',
      }"
		>
			<el-button
				:icon="icon"
				type="default"
			>
				<i
					class="iconfont iconjijia_shangchuan"
					style="font-size: 16px"
				></i>
				{{ btnName }}
			</el-button>
		</el-upload>
		<div class="upload-img-area">
			<template v-if="imageArr && imageArr.length">

				<div
					class="upload-img-item"
					v-for="(item, index) in imageArr"
					:key="index"
				>
					<el-image
						:src="item.path"
						@click="handleView(index)"
						:preview-src-list="previewList"
						:z-index="index"
					/>
					<div
						v-show="!disabled"
						class="del-image"
					>
						<i
							class="iconfont iconc-close"
							style="color: #fff; font-size: 18"
							@click.stop="handleRemoveImg(index)"
						/>
					</div>
				</div>
			</template>
			<p
				class="no-data"
				v-else-if="!Array.isArray(fileArr) || !fileArr?.length"
			>暂无数据</p>
		</div>
		<div
			v-if="Array.isArray(fileArr) && fileArr.length"
			class="upload-files-area"
		>
			<el-table
				border
				:data="fileArr"
				style="width: 100%"
			>
				<el-table-column
					label="附件名称"
					prop="process_name"
				>
					<template #default="scope">
						<div>
							<img
								alt=""
								:src="fileType(scope.row.filename)"
								style="width: 20px; vertical-align: middle"
							/>
							<span
								class="file-name"
								:title="scope.row.filename"
							>
								{{ scope.row.filename }}
							</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column
					align="center"
					label="上传人"
					prop="user_name"
					width="120"
				/>
				<el-table-column
					align="center"
					label="上传时间"
					prop="upTime"
					width="170"
				/>
				<el-table-column
					align="center"
					label="操作"
					prop=""
					width="110"
				>
					<template #default="scope">
						<div>
							<span
								class="operation-primary"
								@click="openFile(scope.row)"
							>
								预览
							</span>
							<a
								v-if="!disabled ? false : true"
								class="operation-down"
								:download="scope.row.name"
								:href="scope.row.path"
							>
								下载
							</a>
							<span
								v-if="disabled ? false : true"
								class="operation-del"
								@click="handleRemove(scope.index)"
							>
								删除
							</span>
						</div>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<el-dialog
			v-model="dialogVisible"
			append-to-body
			title="查看大图"
		>
			<div style="width: 100%; height: 100%">
				<el-image
					fit="fill"
					:src="dialogImageUrl"
				/>
			</div>
		</el-dialog>
	</div>
</template>
<script>
import { parseTime, getToken } from '@/utils/tools';
import { baseUrl } from '@/apis/http/request';
import { Dialog, Image, Table, TableColumn, Upload } from 'element-ui';
// 文件图标
const pdf = '//static.bimcc.com/images/disk/pdf.png';
const ppt = '//static.bimcc.com/images/disk/ppt.png';
const word = '//static.bimcc.com/images/disk/word.png';
const excel = '//static.bimcc.com/images/disk/excel.png';
const none = '//static.bimcc.com/images/disk/none.png';

export default {
	props: {
		// 文件
		files: {
			type: Array,
			default() {
				return [];
			}
		},
		// 禁用
		disabled: {
			type: Boolean,
			default: false
		},
		// 按钮名
		btnName: {
			type: String,
			default: '上传文件'
		},
		// icon
		icon: {
			type: String,
			default: ''
		},
		// 是否多选
		multiple: {
			type: Boolean,
			default: true
		},
		// 允许上传的文件格式
		fileTypeList: {
			type: Array,
			default: () => []
		},
		//上传类型
		goalType: {
			type: String,
			default: 'default'
		}
	},
	components: {
		'el-dialog': Dialog,
		'el-upload': Upload,
		'el-image': Image,
		'el-table': Table,
		'el-table-column': TableColumn
	},
	// emits: ['extra-files'],
	data() {
		return {
			extra_file: [],
			uploading: false,
			currentUser: '', //当前用户
			imageArr: [], // 图片数组
			fileArr: [], //文件数组
			url: baseUrl,
			dialogVisible: false,
			dialogImageUrl: '',
			uploadURL: `${baseUrl}api/mapi?__method_name__=saasFile&token=${getToken()}`,
			token: getToken(),
			imageType: ['bmp', 'jpg', 'png', 'gif', 'jpeg', 'cdr', 'psd']
		};
	},
	created() {
	},
	computed: {
		// 预览列表
		previewList() {
			if (!this.imageArr || !Array.isArray(this.imageArr)) return [];
			return this.imageArr.map((ele) => {
				return ele.path;
			});
		}
	},
	watch: {
		files: {
			handler(files) {
				this.imageArr = [];
				this.fileArr = [];
				files.map((item) => {
					if (typeof item === 'object') {
						if (
							item.filepath && this.imageType.indexOf(item.filepath.substring(item.filepath.lastIndexOf('.') + 1).toLowerCase()) > -1
						) {
							this.imageArr.push(item);
						} else {
							this.fileArr.push(item);
						}
					} else {
						this.imageArr.push({
							name: '',
							upUser: '未知',
							upTime: parseTime(new Date()),
							path: item
						});
					}
				});
				this.extra_file = files;
			},
			immediate: true,
			deep: true
		},
		extra_file: {
			handler(data) {
				this.loading = false;
				this.$emit('extra-files', data, this.index);
			},
			immediate: true,
			deep: true
		}
	},
	methods: {
		/**
		 * @description 上传成功回调
		 * @param {*} response
		 * @param {*} file
		 * @param {*} fileList
		 */
		onSuccess(res) {
			this.uploading = false;
			if (!Array.isArray(this.extra_file)) {
				this.extra_file = [];
			}
			/* this.extra_file.push({
          name: file.name,
          upUser: this.currentUser,
          upTime: parseTime(new Date()),
          path: response.data.filepath,
        }) */

			this.uploading = false;
			if (this.extra_file == null) {
				this.extra_file = new Array();
			}
			//判断上传文件是否为图片
			res.data.upTime = parseTime(new Date());
			res.data.name = res.data.filename;
			if (this.imageType.indexOf(res.data.fileext.toLowerCase()) > -1) {
				res.data.path = res.data.thumb_path;
			} else {
				res.data.path = res.data.filepath;
			}
			this.extra_file.push(res.data);
		},
		/**
		 * @description 选中文件回调
		 */
		getModelFile(file){
			if(!['default','schedule','dwg'].includes(this.goalType)) {
				this.$emit('extra-files', file);
				return false
			}
		},
		/**
		 * @description 上传前置钩子
		 */
		beforeUpload(file) {
			//需要单独处理的上传方式
			if(!['default','schedule','dwg'].includes(this.goalType)) {
				return false
			}
			this.uploading = true;
			if (this.fileTypeList.length > 0) {
				let arr = [];
				this.fileTypeList.forEach((item) => {
					if (file.name.indexOf(item) > -1) {
						arr.push(file.name);
					}
				});
				if (arr.length == 0) {
					this.$message.error(
						'请上传 ' + this.fileTypeList.join(',') + ' 格式的文件！'
					);
					return false;
				}
			}
		},
		/**
		 * @description 查看图片
		 * @param {Number} index
		 */
		handleView(index) {
			this.dialogVisible = true;
			const isThumb =  this.imageArr[index].filepath.indexOf('_thumb') !== -1
			let str = '';
			if(isThumb) {
				let path = this.imageArr[index].filepath.split('_thumb');
				if (path[1] && path[1] !== 'undefined') {
					str = path[0] + '' + path[1];
				} else {
					str = path[0];
				}
			} else {
				str = this.imageArr[index].filepath
			}
			this.dialogImageUrl = str;
			// 暂时没有实现
			// let arr=[]
			// this.imageArr.map(item=>{
			//     arr.push(item.path)
			// })
			// this.$store.commit('setImageUrls', {data: arr,index :index})
		},
		/**
		 * @description 查看文件预览
		 * @param {Object} row
		 */
		openFile(row) {
			if (
				['bmp', 'jpg', 'png', 'gif', 'jpeg', 'cdr', 'psd'].indexOf(
					row.path.substring(row.path.lastIndexOf('.') + 1).toLowerCase()
				) >= 0
			) {
				// this.$store.commit('setImageUrls', { data: [row.path] })
				this.dialogImageUrl = row.path;
				this.dialogVisible = true;
			} else if (
				['doc', 'docx', 'pdf', 'ppt', 'pptx', 'xlsx', 'xls', 'txt'].indexOf(
					row.path.substring(row.path.lastIndexOf('.') + 1).toLowerCase()
				) >= 0
			) {
        let url = 'https://weboffice.bimcc.net?furl='
				if (row.path.includes('https://')) {
					url = 'https://weboffice.bimcc.net?ssl=1&furl='
				}
				window.open(
					url + row.path
				)
			} else if (
				['mp4'].indexOf(
					row.path.substring(row.path.lastIndexOf('.') + 1).toLowerCase()
				) >= 0
			) {
				window.open(row.path);
			} else {
				this.$message.info('无法打开此格式的文件！');
			}
		},
		/**
		 * @description 移除图片
		 * @param {Number} index
		 */
		handleRemoveImg(index) {
			this.imageArr.splice(index, 1);
			this.extra_file = [].concat(this.imageArr, this.fileArr);
		},
		/**
		 * @description 移除文件
		 * @param {Number} index
		 */
		handleRemove(index) {
			this.fileArr.splice(index, 1);
			this.extra_file = [].concat(this.imageArr, this.fileArr);
		},
		/**
		 * @description 返回文件类型
		 * @param {String} url
		 */
		fileType(url) {
			if (url.indexOf('pdf') > -1) return pdf;
			else if (url.indexOf('ppt') > -1) return ppt;
			else if (url.indexOf('doc') > -1) return word;
			else if (url.indexOf('xls') > -1) return excel;
			else return none;
		}
	}
};
</script>

<style lang="less" scoped>
.file-upload {
	width: 100%;
	height: auto;
	.upload-style {
		height: auto;
		.drag-text {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
	.upload-img-area {
		width: 100%;
		height: auto;
		margin: 10px 0;
		.upload-img-item {
			display: inline-block;
			width: 100px;
			height: 100px;
			text-align: center;
			line-height: 100px;
			border: 1px solid transparent;
			border-radius: 4px;
			overflow: hidden;
			background: #fff;
			position: relative;
			// box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
			margin-right: 4px;
			cursor: pointer;
			img {
				width: 100%;
				height: 100%;
				cursor: pointer;
			}
			.del-image {
				position: absolute;
				top: 0;
				width: 18px;
				right: 0;
				background: #d3d4d5;
				color: #fff;
				line-height: 16px;
			}
		}
	}
	.upload-files-area {
		.file-name {
			width: 100%;
			display: inline-block;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			width: calc(100% - 23px);
			vertical-align: middle;
		}
	}
	.no-data {
		padding: 0 20px;
		color: #909399;
	}
}
</style>
