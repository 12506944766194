/* eslint-disable */
import http from "../global/http"
import { axios } from '@/apis/http/request';
/**
 * @desc: 删除search参数中的value为空的筛选
 */
 const removeNullSearch = data => {
	for (let key in data) {
		if (key === 'search' && Array.isArray(data[key]) && data[key].length) {
			let search = [];
			for (let i = 0; i < data[key].length; i++) {
				if (data[key][i].value) {
					search.push(data[key][i]);
				}
			}
			return {
				...data,
				search
			}
		}
	}
	return data;
}
//表单渲染器
export const formRequest = (method, url, data = {}) => {
	if (method === 'get' || method === 'GET') {
		return http({
			url: url,
			method: method,
			params: data
		})
	} else {
		return http({
			url: url,
			method: method,
			data: data
		})
	}
};

/**
 * @desc: 用户数据接口
 */
export const dataInterface = (data, url, method) => {
	if (method && method === 'GET') {
		return axios.request({
			url: url || 'api/mapi',
			params: removeNullSearch(data),
			method
		});
	}
	return axios.request({
		url: url || 'api/mapi',
		data: removeNullSearch(data),
		method: method || 'POST'
	});
}

export const formDesign = {
	formList(data){
		return http({
			url: '/api/form4/design',
			method: 'get',
			params: data
		})
	},
	formStore(data){
		return http({
			url: '/api/form4/design',
			method: 'post',
			data: data
		})
	},
	formShow(id){
		return http({
			url: '/api/form4/design/'+id,
			method: 'get',
		})
	},
	formUpdate(id,data){
		return http({
			url: '/api/form4/design/'+id,
			method: 'put',
			data: data
		})
	},
	formDel(id){
		return http({
			url: '/api/form4/design/'+id,
			method: 'delete'
		})
	},
	formValidate(params){
		return http({
			url: '/api/form4/formValidate',
			method: 'get',
			params: params
		})
	},
	formDataList(params){
		return http({
			url: '/api/form4/data',
			method: 'get',
			params: params
		})
	},
	formDataStore(data){
		return http({
			url: '/api/form4/data',
			method: 'post',
			data:data
		})
	},
	formDataDetail(id){
		return http({
			url: '/api/form4/data/'+id,
			method: 'get',
		})
	},
	formDataUpdate(data,id){
		return http({
			url: '/api/form4/data/'+id,
			method: 'put',
			data:data
		})
	},
	formDataDel(id){
		return http({
			url: '/api/form4/data/'+id,
			method: 'delete',
		})
	},
}
