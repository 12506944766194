<!--
 * @Author: zx
 * @Date: 2022-08-30 11:39:53
 * @LastEditTime: 2023-03-02 14:49:08
 * @Description: 
 * @LastEditors: 姚铃 1871883713@qq.com
-->
<template>
	<div class="user-actions">
		<!-- 自定义实现 -->
		<section class="info-desc-box" :class="{
      'active': showActions
    }" @click="onToggleShow">
      <img :src="userInfo.avatar || defaultAvatar" alt="" class="avatar" />
      <!-- <i class="iconfont iconyonghuzhanghu userinfo"></i> -->
		</section>
		<!-- 内容 -->
    <!-- 283px -->
		<article class="action-menu" :style="{
        height: showActions ? 'auto' : '0px'
    }">
      <!-- 头像 -->
      <section class="base" v-if="userInfo">
        <img :src="userInfo.avatar || defaultAvatar" alt="" class="avatar">
        <p class="username">{{ userInfo.name || '' }}</p>
      </section>
      <ul class="actions">
        <section class="action-section">
          <li class="action-item" @click="onClearCache">
            <i class="iconfont iconqingsaoqingchu"></i>
            <span class="action-item-span">清除缓存</span>
          </li>
          <!-- <li class="action-item download">
            <i class="iconfont iconshoucewendang"></i>
            <span>下载操作手册</span>
          </li> -->
        </section>
        <section class="action-section">
          <li class="action-item user-center" @click="onUserCenter">
            <i class="iconfont icona-yonghugeren"></i>
            <span class="action-item-span">个人中心</span>
          </li>
        </section>
            <el-popover
              placement="right"
              width="160"
              trigger="hover"
              >
              <div class="style-list">
                <div class="style-change-label" @click="changeLabelShow()">
                  <div class="change-label-icon">
                    <i v-if="styleItem.id === 1 ? +blueLabel === 1 ? true : false : +darkLabel === 1 ? true : false" class='iconfont iconselect-duigou'></i>
                  </div>
                  <span>显示菜单名称</span>
                </div>
                <div class="style-list-container">
                  <div v-for="(item,index) in styleList" :key="index" class="list-item" style="height: 40px" @click="changeStyle(item)">
                    <div class="item-left">
                      <i v-if="item.id == styleNum" class='iconfont iconselect-duigou company-select'></i>
                    </div>
                    <div class="item-right">
                      {{item.name}}
                    </div>
                  </div>
                </div>
              </div>
              <el-button slot="reference" class='pop-button'>
                <section class="action-section">
                  <li class="action-item">
                    <div class="icon-container">
                      <div>
                        <i class="iconfont icontubiao"></i>
                        <span>切换风格</span>
                      </div>
                      <i class="iconfont iconlujingjiantou"></i>
                    </div>
                  </li>
                </section>
              </el-button>
            </el-popover>
        <section class="action-section" style="height: 47px;padding-bottom: 7px;border-bottom: 1px solid #F2F4F7">
          <li class="action-item">
            <el-popover
              placement="right"
              width="140"
              trigger="hover"
              >
              <div class="list">
                <div class="list-title">{{ companyList && companyList.length ? '切换企业' : '暂无企业'}}</div>
                <div v-for="(item,index) in companyList" :key="index" class="list-item" @click="goCompany(item)">
                  <div class="item-left">
                    <img v-if="item.logo" :src="item.logo" alt="">
                    <div class="text-icon" v-else>{{item.name.slice(0,1)}}</div>
                    <div class="company-name">{{item.name}}</div>
                  </div>
                  <div class="item-right">
                    <i v-if="item.id == company_id" class='iconfont iconselect-duigou company-select'></i>
                    <i v-else class="iconfont iconxiangyoujiantou2"></i>
                  </div>
                </div>
              </div>
              <el-button slot="reference" class='pop-button'>
                <div class="icon-container">
                  <div>
                    <i class="iconfont iconqiye"></i>
                    <span>{{ companyList && companyList.length ? '切换企业' : '暂无企业'}}</span>
                  </div>
                  <i class="iconfont iconlujingjiantou"></i>
                </div>
              </el-button>
            </el-popover>
          </li>
        </section>
        <section class="action-section" style="margin-top: 8px;">
          <li class="action-item" @click="onQuit">
            <i class="iconfont icontuichu"></i>
            <span class="action-item-span">退出登录</span>
          </li>
        </section>
      </ul>
		</article>
		<!-- mask -->
		<div class="actions-mask" v-show="showActions" @click.self="onToggleShow"></div>
	</div>
</template>

<script>
// import { dataInterface } from '@/apis/data/index';
import eventBus from '@/plugins/eventBus';
import { setToken } from '@/utils/tools';
import { mapState } from 'vuex';
import {userUseCompany} from '@/api/userInfo'
import { Popover } from 'element-ui'

export default {
	name: 'UserActions',
	components: {
    'el-popover': Popover,
  },
	data() {
		return {
      companyList:null,
      company_id:null,
      styleNum: +localStorage.getItem('system_style'),
      styleItem: {},
      styleList:[
        {
          id: 1,
          name: '纯蓝',
          switch: 1
        },
        {
          id: 2,
          name: '暗黑',
          switch: 1
        },
      ],
			showActions: false,
			loading: true,
      // userInfo: null,
			defaultAvatar: require('@/assets/images/Group.png'),
		}
	},
  // watch:{
  //   userInfo:{
  //     handler(){
  //       this.getData();
  //     },
  //     deep: true,
  //   },
  // },
  computed: {
    ...mapState(['systemStyle','userInfo','blueLabel', 'darkLabel']),
  },
  created() {
    this.companyList = JSON.parse(localStorage.getItem('userInfo')).saas
    if (+this.styleNum === 1) {
      this.styleItem = this.styleList[0]
    } else {
      this.styleItem = this.styleList[1]
    }
    // this.userInfo = this.$GetUserInfo();
  },
  watch: {
    blueLabel: {
      handler(val) {
        this.styleList[0].switch = +val
      },
      deep: true,
      immediate: true
    },
    darkLabel: {
      handler(val) {
        this.styleList[1].switch = +val
      },
      deep: true,
      immediate: true
    },
  },
  mounted() {
    // eventBus.$on('updateUserInfo', () => {
    //   this.userInfo = this.$GetUserInfo();
    // })
  },
	methods: {
    /**
     * @desc: 切换显示
     */
		onToggleShow() {
			this.showActions = !this.showActions;
		},
    /**
     * @desc: 用户中心
     */
    onUserCenter() {
      this.$emit('jumpUserInfo')
      this.onToggleShow();
    },
    /**
     * @desc: 清除缓存
     */
    onClearCache() {
      const loading = this.$loading({
        text: '清除中...'
      });
      this.showActions = false;
      setTimeout(() => {
        loading.close();
        this.$message.success('清除成功!');
      }, 1500);
    },
    /**
		 * @desc: 退出登录
		 */
		onQuit() {
			// 移除路由文件重新获取一次
      if(JSON.parse(localStorage.getItem('officialAccount')) === 1) {
          this.$router.replace({
          name: 'Login',
          query: {
            followed: 1
          }
        });
      } else {
          this.$router.replace({
          name: 'Login'
        });
      }
			setToken('');
			sessionStorage.clear();
      localStorage.clear();
			eventBus.$emit('updateRoute');
			// 刷新数据
			sessionStorage.removeItem('navHistoryList');
			this.$store.commit('setUserInfo', '');
			this.$store.commit('setSaasTargetArchi', '');
		},
    changeStyle(data) {
      this.styleNum = +data.id
      this.styleItem = data
      this.$store.commit('setSystemStyle', this.styleNum)
    },
    goCompany(data){
      if(data.id == this.company_id){
        return false;
      }
      userUseCompany({company_id:data.id}).then(res=>{
        if(res.data && res.data.code !== 200){
          return false;
        }
        this.$store.commit('setSaasTargetArchi', res?.data?.data?.department_info)
        localStorage.setItem('saas',JSON.stringify(data)); // 当前选择的系统的所有信息
        localStorage.setItem('company_id',res?.data?.data?.company_id)
        this.$router.push('/home');
      })
    },
    changeLabelShow() {
      if (+this.styleNum === 1) {
        this.styleList[0].switch = +this.styleList[0].switch === 1 ? 0 : 1
        console.log(this.styleList[0].switch, 'this.styleList[0].switch')
        this.$store.commit('setBlueLabel', this.styleList[0].switch)
      } else {
        this.styleList[1].switch = +this.styleList[1].switch === 1 ? 0 : 1
        this.$store.commit('setDarkLabel', this.styleList[1].switch)
      }
    }
	},
  beforeDestroy() {
    // eventBus.$off('updateUserInfo');
  }
};
</script>

<style lang="less" scoped>
@height: 48px;
@partWidth: 280px;
  .icon-container {
    color: #4D5358;
    width: 204px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 400;
    div {
      display: flex;
      align-items: center;
    }
  }
  :deep(.el-popover) {
    .el-popover__reference-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
    }
  }
  .pop-button {
    background: transparent;
    border: none;
    padding: 0;
    font-size: 14px;
    color: #4D5358;
    i {
      font-size: 14px;
      margin-right: 10px;
    }
  }
  .pop-button:hover {
    background: transparent;
    color: #4D5358;
  }
  /* 选择样式 */
  .style-list {
    display: flex;
    flex-direction: column;
    .style-change-label:hover {
      background: #F2F4F7;
      border-radius: 6px;
    }
    .style-change-label {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      user-select: none;
      box-sizing: border-box;
      padding: 0 8px;
      margin-bottom: 7px;
      .change-label-icon {
        width: 16px;
        height: 16px;
        margin-right: 8px;
        line-height: 16px;
        i {
          width: 9px;
          height: 9px;
        }
      }
    }
    .style-list-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 80px;
      border-top: 1px solid #F2F4F7;
      .list-item {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        user-select: none;
        box-sizing: border-box;
        padding: 0 8px;
        .item-left {
          width: 16px;
          height: 16px;
          margin-right: 8px;
          line-height: 16px;
          i {
            width: 9px;
            height: 9px;
          }
        }
      }
      .list-item:hover {
        background: #F2F4F7;
        border-radius: 6px;
      }
    }

  }
  .list{
    box-sizing: border-box;
    overflow: hidden;
    overflow-y: auto;
    .list-title{
      font-size: 14px;
      font-weight: 600;
      padding-bottom: 5px;
      box-sizing: border-box;
    }
    .list-item{
      padding: 5px;
      margin: 5px 0;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #d5d5d5;
      border-radius: 5px;
      cursor: pointer;
      .item-left{
        display: flex;
        align-items: center;
        >img{
          width: 30px;
          height: 30px;
        }
        .text-icon{
          width: 30px;
          height: 30px;
          background: #4D81FB;
          color: #fff;
          font-size: 16px;
          font-weight: 600;
          text-align: center;
          line-height: 30px;
          border-radius: 10px;
        }
        img{
          display: block;
          width: 30px;
        }
        .company-name{
          width: 76px;
          font-size: 14px;
          font-weight: 600;
          padding-left: 10px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .item-right{
        i{
          font-size: 24px;
        }
        color: #aaa;
        .company-select{
          color: #4D81FB;
        }
      }
    }
  }
.user-actions {
	max-width: 48px;
	.info-desc-box {
		box-sizing: border-box;
    margin-right: 8px;
    border-radius: 50%;
		cursor: pointer;
		display: flex;
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 36px;
      width: 36px;
      border-radius: 8px;
      border-radius: 50%;
      -o-object-fit: cover;
      object-fit: cover;
    }
		.iconfont {
			line-height: 24px;
			font-family: PingFangSC, PingFangSC-Medium;
			font-weight: 500;
      font-size: 17px;
		}
	}
	.action-menu{
		position: fixed;
		right: 5px;
		z-index: 2999;
    width: 240px;
		box-sizing: border-box;
		box-shadow: 0px 5px 10px -1px rgba(0, 0, 0, 8%);
		transition: all .2s;
		overflow: hidden;
    background: #fff;
    .base{
      width: 100%;
      box-sizing: border-box;
      height: 72px;
      border-bottom: 1px solid #f2f4f7;
      padding: 16px;
      display: flex;
      .avatar{
        height: 40px;
        width: 40px;
        margin-right: 16px;
        border-radius: 8px;
        object-fit: cover;
      }
      .username{
        flex: 1;
        box-sizing: border-box;
        padding: 9px 0;
        height: 100%;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #121619;
        line-height: 22px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .actions{
      box-sizing: border-box;
      width: 100%;
      padding: 8px 12px;
      .action-section{
        box-sizing: border-box;
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        &:last-of-type{
          border-bottom: 0;
        }
        .action-item{
          display: flex;
          width: 100%;
          height: 40px;
          overflow: hidden;
          box-sizing: border-box;
          border-radius: 4px;
          cursor: pointer;
          display: flex;
          align-items: center;
          border-radius: 6px;
          padding-left: 12px;
          &:hover{
            background: #E9EBF0;
          }
          .iconfont{
            font-size: 14px;
            color: #4D5358;
            line-height: 22px;
          }
          .action-item-span {
            flex: 1;
            margin-left: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #4D5358;
            line-height: 22px;
          }
          span {
            width: 100%;
            display: flex;
            span {
              width: 100%;
              height: 100%;
              display: flex;
            }
          }
        }
      }
    }
	}
	.actions-mask{
		position: fixed;
		height: 100vh;
		width: 100vw;
		background: transparent;
		top: 0;
		left: 0;
		z-index: 2998;
		cursor: pointer;
	}
}
.active {
  border: 1px solid #337EFF;
}
.system-first {
  margin-right: 0;
  .info-desc-box {
    border-radius: 50%;
    margin-right: 0;
    img {
      height: 36px;
      width: 36px;
      margin-right: 0;
      border-radius: 50%;
      border-radius: 0px;
    }
  }
}
</style>