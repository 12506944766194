<!--
 * @Author: zx
 * @Date: 2022-08-17 17:16:51
 * @LastEditTime: 2023-02-13 09:37:24
 * @Description: 
 * @LastEditors: 朱廷果 1028509503@qq.com
-->
<template>
  <div class="home">
    <!-- 把token带给官网 让官网也能有用户信息 -->
    <iframe :src="('https://www.bimcc.com/web-page/home?token=' + token)" frameborder="0" style="width:0px;height:0px"></iframe>
    <div class="home-menu">
      <HomeMenu ref="homeMenu"
        :currentRouteName="currentRouteName"
        @menuClick="menuClick"
        @menuList="menuList"
        @currentMenuList="currentMenuList"
        @closeMenuItem="closeMenuItem"
        @dept='dept' 
      />
      <HomeMenuItem
        v-if="currentMenu && 
          currentMenu.name !== 'Home' && 
          !currentMenu.is_apps && 
          currentMenu.children && 
          currentMenu.children.length !== 0 && 
          currentMenu.menu_type !== 1
        "
        ref="menuItem"
        :currentMenu="currentMenu"
        :currentRouteName="currentRouteName"
        class="home-menu-item"
        @currentMenuList="currentMenuList"
      />
      <ApplicationTree
        ref="applicationTree"
        v-if="currentMenu && currentMenu.is_apps && currentMenu.menu_type !== 1"
        :currentMenu="currentMenu"
        :currentRouteName="currentRouteName"
        :showTemplatePage="showTemplatePage"
        :inTemplatechild="inTemplatechild"
        class="home-menu-item"
        @applicationClick="applicationClick"
        @updateMenuList="updateMenuList"
        @currentMenuList="currentMenuList"
        @childClick="childClick"
        @appChildAdd='appChildAdd'
        
      />
      <ApplicationMenu
        v-if="currentMenu && currentMenu.menu_type === 1"
        :currentMenu="currentMenu"
        :currentRouteName="currentRouteName"
        class="home-menu-item"
        @updateMenuList="updateMenuList"
        @currentMenuList="currentMenuList"
      />
    </div>
    <div class="home-content">
      <div class="home-tool">
        <HomeTool
          ref="homeTool"
          :hasDept="hasDept"
          :chooseMenuList="chooseMenuList"
          :menuTreeData="menuTreeData"
          :currentMenu="currentMenu"
          @currentShowRoute="currentShowRoute"
          @currentMenuList="currentMenuList"
          @jumpDept='jump("/org/dept")'
          @jumpUserInfo="jump('/manage/config')"
          @breadClick="breadClick"
          @backInstall="backInstall"
        />
      </div>
      <!-- :style 工作台特殊判断 -->
      <div class="home-content-view" :style="(routePath === '/home' && !currentMenu.is_apps && !isSuper)?'padding:0':''" > 
        <div class="dealt-component" v-if="showTemplatePage">
          <TemplatePage ref="templatePage" 
            @updateMenuList="updateMenuList" 
            @addChildDialog="addChildDialog" 
            @goApp='goApp' 
            @installAppMenu="installAppMenu"
            @onChildPage="onChildPage" 
            @appChildAdd='appChildAdd'/>
        </div>
        <div class="dealt-component" v-else-if="showGuideVisible">
          <GuidePage :appInfo="appInfo" @updateMenuList="updateMenuList" @openAddChild="openAddChild" />
        </div>
        <div class="dealt-component" v-else-if="routePath === '/home' && !currentMenu.is_apps">
          <StatisticalPage v-if='isSuper' />
          <SecondMain v-else @toAppUrl='toAppUrl'/>
        </div>
        <div class="dealt-component" v-else>
          <router-view :key="$route.fullPath" @toAppUrl='toAppUrl'/>
        </div>
      </div>
    </div>
    <FristLogin :dialog='dialog' @jumpDept='jump("/org/dept")'/>
    <FixTools></FixTools>
  </div>
</template>
<script>
import FixTools from '../fixTools/Index.vue';
import {firstLoginStatus} from '@/api/saas'
import HomeMenu from "./components/Menu"
import HomeMenuItem from "./components/HomeMenuItem"
import HomeTool from "./components/HomeTool"
import SecondMain from '../workbench/index.vue'
import StatisticalPage from '../workbench/adminWork/StatisticalPage.vue'
// import DealtPage from "./components/DealtPage"
import ApplicationTree from "./components/applicationTree"
import ApplicationMenu from "./components/applicationTree/applicationMenu"
import FristLogin from "./components/FristLogin"
import GuidePage from "./components/guidePage" // 应用引导页
import TemplatePage  from "./components/templatePage" // 模板引导页
import { mapState } from 'vuex';
import { getToken } from '@/utils/tools'
import { userInfo } from '@/api/user'
export default {
  components:{
    HomeMenu, // 左侧菜单显示组件
    HomeMenuItem, // 系统功能二级菜单显示
    HomeTool, // 面包屑显示组件
    SecondMain, // 第二屏显示
    // DealtPage,  // 首页待办
    ApplicationTree, // 我的应用树结构数据
    ApplicationMenu, // 平铺状态下点击我的应用显示
    StatisticalPage, // 超级管理员的第二屏
    FristLogin, // 组织架构未创建提示组件
    GuidePage, // 新增应用引导页组件
    TemplatePage, // 模板市场安装引导页组件
    FixTools,
  },
  props:{
  },
  data() {
    return {
      isSuper:false, // 是否是超级管理员
      currentMenu: {}, // 当前选择的左侧菜单数据
      chooseMenuList: [], // 面包屑数据
      currentRouteName: '', // 当前功能id及标识
      menuTreeData: [], // 处理后的菜单树
      routePath: window.location.pathname, // 当前功能路由
      dialog:false, // 未创建架构时提示组件内弹窗展示开关
      hasDept:false, // 是否含有org/dept路由功能权限
      showGuideVisible:false, // 是否显示应用引导页
      showTemplatePage:false, // 是否展示模板分类页面
      appInfo: {}, // 当前点击的应用
      token:'',
      inTemplatechild:false, // 是否在新增我的应用的子页面
    }
  },
  computed: {
    ...mapState(['saasCurrentMenu', 'manageItem', 'stystemItem', 'appItem', 'childAppItem'])
  },
  watch: {
    $route(val) {
      this.routePath = val.fullPath
      this.showGuideVisible = false
    },
  },
  created() {
    this.token = getToken()
    const projectInfo = {
      name: "dev测试服务器-9",
      metadatatoken: "aba62ca1-c2ff-42af-9d3a-bbe5b02f7b06",
      originURl: "https://saas-api.bimcc.net",
    }

    if(getToken()){
      let token = getToken()
      userInfo(token).then((res) => {
        if(res.data.code !== 200){
          this.$router.push({ name: "Login" });
          return
        }else{
          this.isSuper = res.data.data.saas_admin
        }
      })
      // 如果没架构 则回登录页重新选择架构
      localStorage.setItem('projectInfo', JSON.stringify(projectInfo))
      let targetArchi = JSON.parse(localStorage.getItem('saasTargetArchi'))
      if(!targetArchi){
        this.$router.push({ name: "Login" });
      }
    }

  },
  methods:{

    /**
     * 当在子页面时返回
     */
     onChildPage(){
      this.inTemplatechild = true;
     },
    /**
     * @description: 打开具体我的应用
     * @param {*} 
     * @return {*}
     */
    goApp(data){
      this.currentMenu.children.forEach(item=>{
        if(item.app_id === data.id){
          this.$refs.applicationTree.rowClick(item)
        }
      })
    },
    /**
     * @description: 打开模板分类页面
     * @param {*} 
     * @return {*}
     */
    appChildAdd(){
      if(this.showTemplatePage){
        this.$refs.templatePage.closePage()
      }
      this.inTemplatechild = false
      this.$refs.applicationTree.rowClick({})
      this.showGuideVisible = false
      this.showTemplatePage = true
      this.chooseMenuList = [{
        name:'我的应用',
        icon:'icona-yingyongxuanzhong',
        id:-3,
        isInstall:true,
        //showEllipsis:true,
      }]
    },
    /**
     * @description: 判断菜单路由是否有/org/dept权限回调
     * @param {*} data:boolean是否有/org/dept路由权限
     * @return {*}
     */
    dept(data){
      this.hasDept = data
      let first_login =JSON.parse(localStorage.getItem('userInfo')).first_login
      if (first_login && data) {
        this.dialog = true
        firstLoginStatus().then(()=>{
          let token = getToken()
          userInfo(token).then((res) => {
            if(res.data.code==200){
              this.$store.commit('setUserInfo',res.data.data)
            }
          })
        })
      }
    },
    /**
     * @description: 首页应用点击跳转应用功能
     * @param {*} item:菜单item功能
     * @return {*}
     */
    toAppUrl(item){
      this.currentRouteName = item.all_parent.split(',')[1]
      if (!this.currentRouteName) {
        this.menuTreeData.forEach(element => {
          if (+element.id === +item.id) {
            this.currentRouteName = item.id + ''
            this.currentMenu = element
          }
        });
        return
      }
      if (item.menu_type) {
        this.menuTreeData.forEach(res=>{
          if (res.is_apps) {
            this.currentMenu = res
            this.currentMenu.children.forEach(re=>{
              if(re.id === item.id){
                this.$nextTick(()=>{
                  this.$refs.applicationTree.rowClick(re) 
                })
              }
            })
          }
        })
      } else {
        this.$router.push({ path: item.route })
        this.currentMenuList(item)
      }
    },
    /**
     * @description: 左侧菜单点击触发事件，判断系统功能及我的应用点击切换
     * @param {*} data：菜单对象，bool:切换暗黑状态下应用树显示与否
     * @return {*}
     */
    menuClick(data, bool) {
      this.$store.commit('setCurrentMenus', data)
      if (data.is_apps && (!data.children || !data.children.length)) {
        this.currentMenu = data
        this.showGuideVisible = false
        this.showTemplatePage = true
        this.chooseMenuList = [data]
        this.chooseMenuList = [{
          name:'我的应用',
          icon:'icona-yingyongxuanzhong',
          id:-3
          //showEllipsis:true,
        }]
        return
      }
      this.showTemplatePage = false
      this.showGuideVisible = false
      if (data) {
        this.$refs.homeTool.menuItemVisible = true
      }
      const applicationTree = this.$refs.applicationTree || null
      if (applicationTree && bool) {
        applicationTree.changeToList()
      }
      if (data.path === '/home') {
        this.$store.commit('setCurrentMenus', data)
        if (location.href.includes('?chooseApps')) {
          this.chooseMenuList = []
          this.currentMenu = {}
          this.currentRouteName = ''
          return
        } else {
          this.$router.push({ path: '/home' })
        }
        this.chooseMenuList = [data]
        this.currentMenu = data
        this.currentRouteName = data.id + ''
        let navHistoryList = JSON.parse(sessionStorage.getItem('navHistoryList'))
        if (navHistoryList && navHistoryList.length) {
          let boo = false
          navHistoryList.forEach(element => {
            if (+element.id === +data.id) {
              boo = true
            }
          });
          if (!boo) {
            navHistoryList.push(data)
          }
        } else {
          navHistoryList = [data]
        }
        sessionStorage.setItem('navHistoryList', JSON.stringify(navHistoryList))
      } else if (!data.children || data.children.length === 0 && !data.is_apps) {
        this.$router.push({ path: data.path })
        this.currentMenu = data
        this.currentMenuList(data)
        this.currentRouteName = data.id + ''
        this.chooseMenuList = [data]
        let navHistoryList = JSON.parse(sessionStorage.getItem('navHistoryList'))
        if (navHistoryList && navHistoryList.length) {
          let boo = false
          navHistoryList.forEach(element => {
            if (+element.id === +data.id) {
              boo = true
            }
          });
          if (!boo) {
            navHistoryList.push(data)
          }
        } else {
          navHistoryList = []
        }
        sessionStorage.setItem('navHistoryList', JSON.stringify(navHistoryList))
      } else if (data.menu_type && +data.menu_type === 1) {
        this.currentMenu = data
      } else {
        this.currentMenu = data
                /* 刷新页面及编辑页返回页面时，用于保存当前我的应用的选择记录跳转 */
        if (this.appItem?.children?.length && this.childAppItem?.id) {
          let isChildren = false
          let loop = (array) => {
            array.forEach(element => {
              if (element.id === this.childAppItem.id) {
                isChildren = true
              }
              if (element?.children?.length) {
                loop(element.children)
              }
            });
          }
          if (this.appItem?.children?.length && this.childAppItem?.id) {
            loop(this.appItem.children)
          }
          /* 有历史记录的 */
          this.$nextTick(() => {
            if(this.$refs.applicationTree){
              this.$refs.applicationTree.appInfo = this.appItem?.id ? this.appItem : null
              this.$refs.applicationTree.historyAppInfo = this.appItem?.id ? this.appItem : null
              this.appInfo = this.appItem?.id ? this.appItem : null
              if (isChildren) {
                this.$refs.applicationTree.historyChildAppInfo = this.childAppItem
                this.$refs.applicationTree.childRowClick(this.childAppItem, false)
              }
            }
          })
        }
        if (!bool) {
          return
        }
        if (!data.is_apps && data.children?.length) {
          let jumpObj = {}
          let oldObj = {}
          if (data.route && data.route === '/stystem') {
            /* 系统工具历史功能记录 */
            oldObj = this.stystemItem
          } else {
            /* 企业管理历史功能记录 */
            oldObj = this.manageItem
          }
          console.log(oldObj)
          if (oldObj?.id) {
            let isChildren = false
            data.children.forEach(ite => {
              if (ite.id === oldObj.id) {
                isChildren = true
              } else {
                ite.children.forEach(it => {
                  if (it.id === oldObj.id) {
                    isChildren = true
                  }
                });
              }
            });
            if (isChildren) {
              this.$nextTick(() => {
                this.$refs.menuItem.jumpMenu(oldObj)
              })
              return
            }
          }
          /* 没有历史功能记录  企业管理及系统工具用第一个有效功能 */
          data.children.forEach(element => {
            if (element.children?.length) {
              element.children.forEach(item => {
                if (!jumpObj || !jumpObj.id || !jumpObj.route) {
                  jumpObj = item
                }
              });
            } else {
              if (!jumpObj || !jumpObj.id || !jumpObj.route) {
                jumpObj = element
              }
            }
          });
          if (jumpObj?.id && jumpObj?.route) {
            this.$nextTick(() => {
              this.$refs.menuItem.jumpMenu(jumpObj)
            })
          }
        } else {
          if (!this.appItem?.id && !this.childAppItem?.id) {
            let appObj = null
            let funcObj = null
            const loopTree = (array) => {
              for (let index = 0; index < array.length; index++) {
                const element = array[index];
                if (!funcObj && (!element.children || element.children.length === 0) &&  element && element.menu_type && [2,3,4,5,7,8].indexOf(+element.menu_type) !== -1) {
                  funcObj = element
                  break;
                }
                if (!funcObj && element.children?.length) {
                  loopTree(element.children)
                }
              }
            }
            if (data.children?.length) {
              data.children.forEach(element => {
                if (element?.children?.length) {
                  loopTree(element.children)
                  if (!appObj && funcObj && funcObj.id) {
                    appObj = element
                  }
                }
              });
            }
            this.$nextTick(() => {
              if (this.$refs.applicationTree?.historyAppInfo && this.$refs.applicationTree.historyAppInfo?.id !== appObj?.id) {
                this.applicationClick(appObj)
                this.$refs.applicationTree.historyAppInfo = appObj
                this.$refs.applicationTree.appInfo = appObj
              }
              if(!data.children.length){
                this.showTemplatePage = true
                this.chooseMenuList = [{
                  name:'我的应用',
                  icon:'icona-yingyongxuanzhong',
                  id:-3
                  //showEllipsis:true,
                }]
                this.$refs.applicationTree.rowClick({})
                this.showGuideVisible = true
              }
            });
          } else {
            let isChildren = false
            let loop = (array) => {
              array.forEach(element => {
                if (element.id === this.childAppItem.id) {
                  isChildren = true
                }
                if (element?.children?.length) {
                  loop(element.children)
                }
              });
            }
            if (this.appItem?.children?.length && this.childAppItem?.id) {
              loop(this.appItem.children)
            }
            /* 有历史记录的 */
            this.$nextTick(() => {
              this.$refs.applicationTree.appInfo = this.appItem?.id ? this.appItem : null
              this.$refs.applicationTree.historyAppInfo = this.appItem?.id ? this.appItem : null
              this.appInfo = this.appItem?.id ? this.appItem : null
              if (isChildren) {
                this.$refs.applicationTree.historyChildAppInfo = this.childAppItem
                this.$refs.applicationTree.childRowClick(this.childAppItem, false)
              } else {
                if(!this.appInfo){
                  this.showTemplatePage = true
                  this.$refs.applicationTree.rowClick({})
                  this.chooseMenuList = [{
                    name:'我的应用',
                    icon:'icona-yingyongxuanzhong',
                    id:-3,
                    background:'linear-gradient(180deg, #5ADFFA 0%, #459CFF 100%);'
                    //showEllipsis:true,
                  }]
                }
                this.chooseMenuList = [this.appInfo]
                this.showGuideVisible = true
              }
            })
          }
        }
      }
    },
    /**
     * @description: 功能跳转方法
     * @param {*} data：菜单功能对象
     * @return {*}
     */    
    currentMenuList(data) {
      /* 在我应用下  不执行下面操作  避免修改choosemenulist */
      if (data && typeof data === 'object' && data.id) {
        this.$store.commit('setCurrentMenus', data)
      }
      if (this.showTemplatePage) {
        return
      }
      const loopMenu = (array) => {
        array.forEach(element => {
          if (+element.id === +data.id) {
            this.currentRouteName = element.id + ''
            this.chooseMenuList = [element]
          }
          if (element.children && element.children.length) {
            loopMenu(element.children)
          }
        });
      }
      if (this.menuTreeData && this.menuTreeData.length) {
        loopMenu(this.menuTreeData)
      }
      if (data.id) {
        this.currentRouteName = data.id + ''
        if (data.all_parent && +data.all_parent !== 0) {
          let arr = data.all_parent.split(',').filter((item) => +item)
          arr.push(data.id)
          arr = arr.map((item) => {
            return +item
          })
          this.chooseMenuList = this.getMenuItemPath(arr)
          if (this.chooseMenuList && this.chooseMenuList.length && this.currentMenu?.id && +this.currentMenu.id !== +this.chooseMenuList[0].id) {
            if (!(+this.chooseMenuList[0].menu_type === 1 && this.currentMenu?.is_apps)) {
              this.currentMenu = this.chooseMenuList[0]
            }
          }
        }
        let navHistoryList = JSON.parse(sessionStorage.getItem('navHistoryList'))
        if (navHistoryList && navHistoryList.length) {
          let boo = false
          navHistoryList.forEach(element => {
            if (+element.id === +data.id) {
              boo = true
            }
          });
          if (!boo) {
            navHistoryList.push(data)
          }
        } else {
          navHistoryList = [data]
        }
        sessionStorage.setItem('navHistoryList', JSON.stringify(navHistoryList))
      }
    },
    /**
     * @description: 获取菜单链式对象数组，按顺序生成功能路径
     * @param {*} numArr：菜单id链式数组
     * @return {*}
     */    
    getMenuItemPath(numArr) {
      const arr = []
      const loop = (array) => {
        array.forEach(element => {
          if (numArr.indexOf(+element.id) !== -1) {
            arr.push(element)
          }
          if (element.children && element.children.length) {
            loop(element.children)
          }
        });
      }
      if (numArr && numArr.length && this.menuTreeData && this.menuTreeData.length) {
        loop(this.menuTreeData)
      }
      return arr
    },
    /**
     * @description: 当前路由名称，选择的功能路由id
     * @param {*} name
     * @return {*}
     */    
    currentShowRoute(name) {
      this.currentRouteName = name
    },
    /**
     * @description: 关闭显示二级菜单及我的应用展示
     * @param {*} boo：boolean当前是否是展示状态
     * @return {*}
     */    
    closeMenuItem(boo) {
      if (boo) {
        if (this.currentMenu && this.currentMenu.is_apps) {
          if (this.$refs.applicationTree) {
            this.$refs.applicationTree.showMenuItemClick()
          }
        } else {
          if (this.$refs.menuItem) {
            this.$refs.menuItem.showMenuItemClick()
          }
        }
      } else {
        if (this.currentMenu && this.currentMenu.is_apps) {
          if (this.$refs.applicationTree) {
            this.$refs.applicationTree.closeMenuItemClick()
          }
        } else {
          if (this.$refs.menuItem) {
            this.$refs.menuItem.closeMenuItemClick()
          }
        }
      }
    },
    /**
     * @description: 获取菜单树
     * @param {*} data：菜单树数据
     * @return {*}
     */    
    menuList(data) {
      this.menuTreeData = data
      if (this.saasCurrentMenu && this.saasCurrentMenu.id) {
        this.currentMenuList(this.saasCurrentMenu)
      }
    },
    /**
     * @description: 调用更新菜单数据方法
     * @param {*} boo：传入此布尔值为true  则记录当前选择的menu  用于刷新数据后继续寻找当前menu  保持menu不变
     * @return {*}
     */    
    updateMenuList(boo) {
      this.$refs.homeMenu.getMenuList(boo)
    },
    /**
     * @desc: 通过遍历左边菜单跳转到对应页面
     * @param {*} url  跳转的路由连接
     */
    jump(url){
      let data = {}
      const loopTree = (arr) => {
        arr.forEach(element => {
          if (element.route === url) {
            data = element
          }
          if (element.children?.length) {
            loopTree(element.children)
          }
        });
      }
      if (this.menuTreeData?.length) {
        loopTree(this.menuTreeData)
      }
      if (data && data.id && data.path) {
        this.$router.push({ path: data.path })
        this.currentMenuList(data)
      }
    },
    /**
     * @description: 应用点击
     * @param {*} data：应用点击的数据
     * @return {*}
     */    
    applicationClick(data) {
      this.chooseMenuList = [data]
      this.showGuideVisible = false
      if(data.name){
        this.showTemplatePage = false
      }
      let relateChild = null
      let boo = false
      const obj = this.$refs.applicationTree?.historyChildAppInfo || {}
      const loopTree = (arr) => {
        arr.forEach(element => {
          if (obj && element.id === obj.id) {
            boo = true
          }
          if (data.relation_id && (element.id === data.relation_id) && data.relation_id) {
            relateChild = element
          }
          if (element?.children?.length) {
            loopTree(element.children)
          }
        });
      }
      if (data?.children?.length) {
        loopTree(data.children)
      }
      if (relateChild) {
        this.showGuideVisible = false
        if (relateChild && relateChild.id) {
          this.$nextTick(() => {
            this.$refs.applicationTree.historyChildAppInfo = relateChild
          })
          this.$refs.applicationTree.childRowClick(relateChild, true)
        }
        return
      }
      if (obj?.id && boo) {
        this.$refs.applicationTree.childRowClick(obj, boo)
      }
      if (!data?.children?.length || !data.relation_id) {
        this.$nextTick(() => {
          this.appInfo = data
          this.showGuideVisible = true
        })
      }
    },
    /**
     * @description: 打开新增应用弹窗
     * @param {*} data：菜单item数据，用于判断是应用新增或者应用下新增功能分组等
     * @return {*}
     */    
    openAddChild(data) {
      if (data.menu_type === 1) {
        this.$refs.applicationTree.childAdd()
      } else {
        this.$refs.applicationTree.childRowAdd(data)
      }
    },
    /**
     * @description: 调用新增应用弹窗
     * @return {*}
     */    
    addChildDialog(){
      this.$refs.applicationTree.addChildDialog()
    },
    /**
     * @description: 面包屑功能点击
     * @param {*} data：点击的面包屑数据，可能是应用也可能是功能
     * @return {*}
     */    
    breadClick(data) {
      /* 我的应用相关点击 */
      if (data.menu_type) {
        if (+data.menu_type === 1) {
          this.$nextTick(() => {
            if (this.$refs.applicationTree) {
              this.$refs.applicationTree.rowClick(data)
              this.$refs.applicationTree.historyAppInfo = data
              this.chooseMenuList = [data]
            }
          })
        } else {
          this.$nextTick(() => {
            if (this.$refs.applicationTree) {
              this.showGuideVisible = false
              this.$refs.applicationTree.childRowClick(data)
            }
          })
        }
      } else {
        //系统应用等等相关面包屑点击
        this.currentMenuList(data)
      }
    },
    /**
     * @description: 点击返回安装方法
     * @param {*} data：拼凑的面包屑对象
     * @return {*}
     */    
    backInstall(data){
      console.log(data)
      if(data.id===-3){
        this.appChildAdd()
        console.log(this.$refs.templatePage)
        this.$refs.templatePage.closePage()
      }else if(data.id === -4){
        this.$refs.templatePage.onModulePages()
      }
    },
    /**
     * @description: 应用下功能点击
     * @return {*}
     */    
    childClick() {
      this.showGuideVisible = false
    },
    /**
     * @description: 前端手动拼凑面包屑数据，用于安装模板应用的面包屑显示
     * @param {*} type：1 模板市场  2 安装设置面包屑数据
     * @return {*}
     */    
    installAppMenu(type){
      if(type === 1){
        this.chooseMenuList = [{
          name:'我的应用',
          icon:'icona-yingyongxuanzhong',
          id:-3,
          isInstall:true,
          //showEllipsis:true,
        },{
          name:'安装应用',
          icon:'iconanzhuangyingyong',
          id:-4,
          background:'linear-gradient(180deg, #5ABFFA 0%, #4583FF 100%)',
          isInstall:true,
        },]
      }else{
        this.chooseMenuList = [{
          name:'我的应用',
          icon:'icona-yingyongxuanzhong',
          id:-3,
          isInstall:true,
          //showEllipsis:true,
        },{
          name:'安装应用',
          icon:'iconanzhuangyingyong',
          id:-4,
          background:'linear-gradient(180deg, #5ABFFA 0%, #4583FF 100%)',
          isInstall:true,
        },{
          name:'安装设置',
          icon:'iconanzhuangshezhi',
          id:-5,
          background:'linear-gradient(180deg, #8C9FFF 0%, #A970FF 100%);',
          isInstall:true,
      }]
      }
    },
  },
  
}
</script>
<style lang='less' scoped>
.home {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #F2F4F7;
  .home-menu {
    display: flex;
    width: auto;
    height: 100%;
    background-color: #fff;
    z-index: 1;
    -moz-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
    .home-menu-item {
      flex: 1;
    }
  }
  .home-content {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    .home-tool {
      width: 100%;
      height: auto;
      display: flex;
      align-content: center;
      padding: 0 16px 0 16px;
      box-sizing: border-box;
    }
    .home-content-view {
      flex: 1;
      overflow: hidden;
      background-color: #F6F8F9;
      padding: 16px;
      padding-bottom: 0;
      box-sizing: border-box;
      border-radius: 8px 8px 0 0;
      .dealt-component {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
