/*
 * @Author: zqy
 * @Date: 2022-06-14 16:11:30
 * @LastEditTime: 2022-12-07 09:10:12
 * @Description: 新增组件
 * @LastEditors: zx
 */
const addComponent = {
  type: 'addComponent',
	title: '新增组件',
	show: true,
  componentList: [
		{
			component: 'AddButton',
			type: 'addComponent',
			name: '新增按钮',
			icon: 'icona-xinzengS',
			isLock: false, // 是否锁定
			needCommonStyle: false,
			propValue: '新增按钮',
			style: {
				height: '100',
				width: '100',
				lineHeight: '',
				backgroundColor: '',
				color: '',
				backdropFilter:'',
				borderWidth: '',
				borderStyle: '',
				borderColor: '',
				borderRadius: '',
				fontSize: '',
				rotate: 0,
				marginTop: 0,
				marginBottom: 0,
				marginRight: 0,
				marginLeft: 0,
				paddingLeft: 0,
				paddingRight: 0,
				paddingTop: 0,
				paddingBottom: 0,
        position: 'absolute',
        bottom: '10',
        right: '10',
        zIndex: 100
			},
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
				icon: '',
			},
			actionConfig: {
				needAction: false,
				detail: {
					use: false,
					useDepend: false,
					dependLogic: [],
					type: 'add',
					name: '新增',
					color: '#409EFF',
					btnType: 'text',
					btnSize: '',
					margin: 5,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				}
			},
		}
	]
};
export default addComponent;