<!--
    @name: widget-textarea
    @description：widget-textarea
    @author: ZengWei
    @date: 2022-03-25 09:28
-->
<template>
  <div>
    <el-input
      v-if="!(disabled || element.config.disabled)"
      v-model="element.value"
      :rows="3"
      type="textarea"
      :placeholder="element.config.placeholder"
      :maxlength="element.config.maxlength"
      :disabled="disabled || element.config.disabled"
    ></el-input>
    <p class="reshow-text" v-else>
      {{ element.value || '-' }}
    </p>
  </div>
</template>

<script>
import {Input} from "element-ui";
import formItemMinix from "@/custom-component/form/newParser/scripts/formItemMinix";

export default {
  name: "WidgetTextarea",
  components: {
    'el-input': Input
  },
  mixins: [formItemMinix],
  props: ['element', 'disabled', 'displayData'],
  data() {
    return {}
  },
  methods: {},
}
</script>

<style scoped>

</style>
