/*
 * @Description: 操作按钮配置
 * @Author: luocheng
 * @Date: 2021-09-30 10:07:42
 * @LastEditors: luocheng
 * @LastEditTime: 2021-10-21 11:51:23
 */
import store from './index';

export default {
  state: {},
  mutations: {
    setComponentActions({ curComponent }, actionConfig) {
      if (!curComponent || !curComponent.actionConfig) return;
      // 提交编辑
      store.commit('modifyComponent', {
        component: {
          ...curComponent,
          actionConfig
        },
        containerId: null,
        isModify: true
      })
    }
  }
}