/*
 * @Author: zx
 * @Date: 2022-11-09 16:02:22
 * @LastEditTime: 2022-12-09 15:53:41
 * @Description: 
 * @LastEditors: 朱廷果 1028509503@qq.com
 */
export const funcIcons = [
  {
    name: 'a-1',
    font_class: 'icon-a-1-1zhuye',
    color: '#E06363',
    children: [
      {
        "icon_id": "32263817",
        "name": "1-1 主页",
        "font_class": "icon-a-1-1zhuye",
        "unicode": "e833",
        "unicode_decimal": 59443
      },
      {
        "icon_id": "32263811",
        "name": "1-5 应用 目录",
        "font_class": "icon-a-1-5yingyongmulu",
        "unicode": "e82d",
        "unicode_decimal": 59437
      },
      {
        "icon_id": "32263812",
        "name": "1-4 应用 网格",
        "font_class": "icon-a-1-4yingyongwangge",
        "unicode": "e82e",
        "unicode_decimal": 59438
      },
      {
        "icon_id": "32263813",
        "name": "1-8 文件 分组 汇总",
        "font_class": "icon-a-1-8wenjianfenzuhuizong",
        "unicode": "e82f",
        "unicode_decimal": 59439
      },
      {
        "icon_id": "32263814",
        "name": "1-6 应用 模块",
        "font_class": "icon-a-1-6yingyongmokuai",
        "unicode": "e830",
        "unicode_decimal": 59440
      },
      {
        "icon_id": "32263815",
        "name": "1-2 提醒 消息 铃铛",
        "font_class": "icon-a-1-2tixingxiaoxilingdang",
        "unicode": "e831",
        "unicode_decimal": 59441
      },
      {
        "icon_id": "32263816",
        "name": "1-9 文件 文件夹",
        "font_class": "icon-a-1-9wenjianwenjianjia",
        "unicode": "e832",
        "unicode_decimal": 59442
      },
      {
        "icon_id": "32263818",
        "name": "1-3 电话 通讯",
        "font_class": "icon-a-1-3dianhuatongxun",
        "unicode": "e834",
        "unicode_decimal": 59444
      },
      {
        "icon_id": "32263819",
        "name": "1-10 文件 用户文件",
        "font_class": "icon-a-1-10wenjianyonghuwenjian",
        "unicode": "e835",
        "unicode_decimal": 59445
      },
      {
        "icon_id": "32263820",
        "name": "1-7 电脑 工作台",
        "font_class": "icon-a-1-7diannaogongzuotai",
        "unicode": "e836",
        "unicode_decimal": 59446
      },
      {
        "icon_id": "32263821",
        "name": "1-20 时间 历史",
        "font_class": "icon-a-1-20shijianlishi",
        "unicode": "e837",
        "unicode_decimal": 59447
      },
      {
        "icon_id": "32263822",
        "name": "1-26 警示 提醒",
        "font_class": "icon-a-1-26jingshitixing",
        "unicode": "e838",
        "unicode_decimal": 59448
      },
      {
        "icon_id": "32263823",
        "name": "1-11 文件箱 通用",
        "font_class": "icon-a-1-11wenjianxiangtongyong",
        "unicode": "e839",
        "unicode_decimal": 59449
      },
      {
        "icon_id": "32263824",
        "name": "1-17 日历 日期",
        "font_class": "icon-a-1-17riliriqi",
        "unicode": "e83a",
        "unicode_decimal": 59450
      },
      {
        "icon_id": "32263825",
        "name": "1-14 用户 合作",
        "font_class": "icon-a-1-14yonghuhezuo",
        "unicode": "e83b",
        "unicode_decimal": 59451
      },
      {
        "icon_id": "32263826",
        "name": "1-27 警示 警告",
        "font_class": "icon-a-1-27jingshijinggao",
        "unicode": "e83c",
        "unicode_decimal": 59452
      },
      {
        "icon_id": "32263827",
        "name": "1-16 日历",
        "font_class": "icon-a-1-16rili",
        "unicode": "e83d",
        "unicode_decimal": 59453
      },
      {
        "icon_id": "32263828",
        "name": "1-12 用户 个人",
        "font_class": "icon-a-1-12yonghugeren",
        "unicode": "e83e",
        "unicode_decimal": 59454
      },
      {
        "icon_id": "32263829",
        "name": "1-28 分享",
        "font_class": "icon-a-1-28fenxiang",
        "unicode": "e83f",
        "unicode_decimal": 59455
      },
      {
        "icon_id": "32263830",
        "name": "1-19 日历 设置",
        "font_class": "icon-a-1-19rilishezhi",
        "unicode": "e840",
        "unicode_decimal": 59456
      },
      {
        "icon_id": "32263831",
        "name": "1-25 停止 停工",
        "font_class": "icon-a-1-25tingzhitinggong",
        "unicode": "e841",
        "unicode_decimal": 59457
      },
      {
        "icon_id": "32263832",
        "name": "1-24 进度 设置 配置",
        "font_class": "icon-a-1-24jindushezhipeizhi",
        "unicode": "e842",
        "unicode_decimal": 59458
      },
      {
        "icon_id": "32263833",
        "name": "1-23 疑问 用户帮助",
        "font_class": "icon-a-1-23yiwenyonghubangzhu",
        "unicode": "e843",
        "unicode_decimal": 59459
      },
      {
        "icon_id": "32263834",
        "name": "1-21 协作 协同",
        "font_class": "icon-a-1-21xiezuoxietong",
        "unicode": "e844",
        "unicode_decimal": 59460
      },
      {
        "icon_id": "32263835",
        "name": "1-32 编辑 修改",
        "font_class": "icon-a-1-32bianjixiugai",
        "unicode": "e845",
        "unicode_decimal": 59461
      },
      {
        "icon_id": "32263836",
        "name": "1-34 筛选",
        "font_class": "icon-a-1-34shaixuan",
        "unicode": "e846",
        "unicode_decimal": 59462
      },
      {
        "icon_id": "32263837",
        "name": "1-30 设置",
        "font_class": "icon-a-1-30shezhi",
        "unicode": "e847",
        "unicode_decimal": 59463
      },
      {
        "icon_id": "32263838",
        "name": "1-29 点赞",
        "font_class": "icon-a-1-29dianzan",
        "unicode": "e848",
        "unicode_decimal": 59464
      },
      {
        "icon_id": "32263839",
        "name": "1-22 消息 提示",
        "font_class": "icon-a-1-22xiaoxitishi",
        "unicode": "e849",
        "unicode_decimal": 59465
      },
      {
        "icon_id": "32263840",
        "name": "1-15 用户 请假",
        "font_class": "icon-a-1-15yonghuqingjia",
        "unicode": "e84a",
        "unicode_decimal": 59466
      },
      {
        "icon_id": "32263841",
        "name": "1-18 日历 建设",
        "font_class": "icon-a-1-18rilijianshe",
        "unicode": "e84b",
        "unicode_decimal": 59467
      },
      {
        "icon_id": "32263842",
        "name": "1-33 修改 编辑",
        "font_class": "icon-a-1-33xiugaibianji",
        "unicode": "e84c",
        "unicode_decimal": 59468
      },
      {
        "icon_id": "32263843",
        "name": "1-13 用户 团队 合作",
        "font_class": "icon-a-1-13yonghutuanduihezuo",
        "unicode": "e84d",
        "unicode_decimal": 59469
      },
      {
        "icon_id": "32263844",
        "name": "1-31 设置 齿轮",
        "font_class": "icon-a-1-31shezhichilun",
        "unicode": "e84e",
        "unicode_decimal": 59470
      }
    ]
  },
  {
    name: 'a-2',
    font_class: 'icon-a-2-1fangangongdanrenwu',
    color: '#F59A31',
    children: [
      {
        "icon_id": "32264164",
        "name": "2-1 方案 工单 任务",
        "font_class": "icon-a-2-1fangangongdanrenwu",
        "unicode": "e84f",
        "unicode_decimal": 59471
      },
      {
        "icon_id": "32264165",
        "name": "2-2 方案 列表",
        "font_class": "icon-a-2-2fanganliebiao",
        "unicode": "e850",
        "unicode_decimal": 59472
      },
      {
        "icon_id": "32264166",
        "name": "2-4 方案 记录 文件",
        "font_class": "icon-a-2-4fanganjiluwenjian",
        "unicode": "e851",
        "unicode_decimal": 59473
      },
      {
        "icon_id": "32264167",
        "name": "2-5 方案 动态判断",
        "font_class": "icon-a-2-5fangandongtaipanduan",
        "unicode": "e852",
        "unicode_decimal": 59474
      },
      {
        "icon_id": "32264168",
        "name": "2-6终端 开发中",
        "font_class": "icon-a-2-6zhongduankaifazhong",
        "unicode": "e853",
        "unicode_decimal": 59475
      },
      {
        "icon_id": "32264169",
        "name": "2-8 日历 月报",
        "font_class": "icon-a-2-8riliyuebao",
        "unicode": "e854",
        "unicode_decimal": 59476
      },
      {
        "icon_id": "32264170",
        "name": "2-7 日历 日报",
        "font_class": "icon-a-2-7riliribao",
        "unicode": "e855",
        "unicode_decimal": 59477
      },
      {
        "icon_id": "32264171",
        "name": "2-12 合同 审批",
        "font_class": "icon-a-2-12hetongshenpi",
        "unicode": "e856",
        "unicode_decimal": 59478
      },
      {
        "icon_id": "32264172",
        "name": "2-9方案 勘查 设计",
        "font_class": "icon-a-2-9fangankanchasheji",
        "unicode": "e857",
        "unicode_decimal": 59479
      },
      {
        "icon_id": "32264173",
        "name": "2-10 功能 算量",
        "font_class": "icon-a-2-10gongnengsuanliang",
        "unicode": "e858",
        "unicode_decimal": 59480
      },
      {
        "icon_id": "32264174",
        "name": "2-11 功能 交付 交底",
        "font_class": "icon-a-2-11gongnengjiaofujiaodi",
        "unicode": "e859",
        "unicode_decimal": 59481
      },
      {
        "icon_id": "32264175",
        "name": "2-13 方案 应急",
        "font_class": "icon-a-2-13fanganyingji",
        "unicode": "e85a",
        "unicode_decimal": 59482
      },
      {
        "icon_id": "32264176",
        "name": "2-14 待办",
        "font_class": "icon-a-2-14daiban",
        "unicode": "e85b",
        "unicode_decimal": 59483
      },
      {
        "icon_id": "32264177",
        "name": "2-22 播放",
        "font_class": "icon-a-2-22bofang",
        "unicode": "e85c",
        "unicode_decimal": 59484
      },
      {
        "icon_id": "32264178",
        "name": "2-16 岗位 办公",
        "font_class": "icon-a-2-16gangweibangong",
        "unicode": "e85d",
        "unicode_decimal": 59485
      },
      {
        "icon_id": "32264179",
        "name": "2-3 表单",
        "font_class": "icon-a-2-3biaodan",
        "unicode": "e85e",
        "unicode_decimal": 59486
      },
      {
        "icon_id": "32264180",
        "name": "2-19 方案 验收 完成",
        "font_class": "icon-a-2-19fanganyanshouwancheng",
        "unicode": "e85f",
        "unicode_decimal": 59487
      },
      {
        "icon_id": "32264181",
        "name": "2-17 施工管理",
        "font_class": "icon-a-2-17shigongguanli",
        "unicode": "e860",
        "unicode_decimal": 59488
      },
      {
        "icon_id": "32264182",
        "name": "2-27 监测 验收 隐蔽工程",
        "font_class": "icon-a-2-27jianceyanshouyinbigongcheng",
        "unicode": "e861",
        "unicode_decimal": 59489
      },
      {
        "icon_id": "32264183",
        "name": "2-23 验收 查看",
        "font_class": "icon-a-2-23yanshouchakan",
        "unicode": "e862",
        "unicode_decimal": 59490
      },
      {
        "icon_id": "32264184",
        "name": "2-18 云盘 网盘",
        "font_class": "icon-a-2-18yunpanwangpan",
        "unicode": "e863",
        "unicode_decimal": 59491
      },
      {
        "icon_id": "32264185",
        "name": "2-25 监理 管理",
        "font_class": "icon-a-2-25jianliguanli",
        "unicode": "e864",
        "unicode_decimal": 59492
      },
      {
        "icon_id": "32264186",
        "name": "2-24变更",
        "font_class": "icon-a-2-24biangeng",
        "unicode": "e865",
        "unicode_decimal": 59493
      },
      {
        "icon_id": "32264187",
        "name": "2-20 功能 审批管理",
        "font_class": "icon-a-2-20gongnengshenpiguanli",
        "unicode": "e866",
        "unicode_decimal": 59494
      },
      {
        "icon_id": "32264188",
        "name": "2-28 危大工程 危险方案",
        "font_class": "icon-a-2-28weidagongchengweixianfangan",
        "unicode": "e867",
        "unicode_decimal": 59495
      },
      {
        "icon_id": "32264189",
        "name": "2-29 物资管理",
        "font_class": "icon-a-2-29wuziguanli",
        "unicode": "e868",
        "unicode_decimal": 59496
      },
      {
        "icon_id": "32264190",
        "name": "2-21 大屏",
        "font_class": "icon-a-2-21daping",
        "unicode": "e869",
        "unicode_decimal": 59497
      },
      {
        "icon_id": "32264191",
        "name": "2-26 疫情管理",
        "font_class": "icon-a-2-26yiqingguanli",
        "unicode": "e86a",
        "unicode_decimal": 59498
      },
      {
        "icon_id": "32264192",
        "name": "2-15 方案 概况 台账",
        "font_class": "icon-a-2-15fangangaikuangtaizhang",
        "unicode": "e86b",
        "unicode_decimal": 59499
      },
      {
        "icon_id": "32264193",
        "name": "2-30 报规报建",
        "font_class": "icon-a-2-30baoguibaojian",
        "unicode": "e86c",
        "unicode_decimal": 59500
      },
    ]
  },
  {
    name: 'a-3',
    font_class: 'icon-a-3-1tongji01',
    color: '#2CB8A4',
    children: [
      {
        "icon_id": "32264359",
        "name": "3-40 烟感 烟雾",
        "font_class": "icon-a-3-40yanganyanwu",
        "unicode": "e88f",
        "unicode_decimal": 59535
      },
      {
        "icon_id": "32264360",
        "name": "3-33 无人机 机器",
        "font_class": "icon-a-3-33wurenjijiqi",
        "unicode": "e890",
        "unicode_decimal": 59536
      },
      {
        "icon_id": "32264361",
        "name": "3-39 旁站监测 监理",
        "font_class": "icon-a-3-39pangzhanjiancejianli",
        "unicode": "e891",
        "unicode_decimal": 59537
      },
      {
        "icon_id": "32264362",
        "name": "3-31 全景 图片",
        "font_class": "icon-a-3-31quanjingtupian",
        "unicode": "e892",
        "unicode_decimal": 59538
      },
      {
        "icon_id": "32264363",
        "name": "3-37 设备 齿轮",
        "font_class": "icon-a-3-37shebeichilun",
        "unicode": "e893",
        "unicode_decimal": 59539
      },
      {
        "icon_id": "32264364",
        "name": "3-12 统计 看板",
        "font_class": "icon-a-3-12tongjikanban",
        "unicode": "e894",
        "unicode_decimal": 59540
      },
      {
        "icon_id": "32264325",
        "name": "3-1 统计01",
        "font_class": "icon-a-3-1tongji01",
        "unicode": "e86d",
        "unicode_decimal": 59501
      },
      {
        "icon_id": "32264326",
        "name": "3-2 统计02",
        "font_class": "icon-a-3-2tongji02",
        "unicode": "e86e",
        "unicode_decimal": 59502
      },
      {
        "icon_id": "32264327",
        "name": "3-4 统计04",
        "font_class": "icon-a-3-4tongji04",
        "unicode": "e86f",
        "unicode_decimal": 59503
      },
      {
        "icon_id": "32264328",
        "name": "3-5 统计05",
        "font_class": "icon-a-3-5tongji05",
        "unicode": "e870",
        "unicode_decimal": 59504
      },
      {
        "icon_id": "32264329",
        "name": "3-3 统计03",
        "font_class": "icon-a-3-3tongji03",
        "unicode": "e871",
        "unicode_decimal": 59505
      },
      {
        "icon_id": "32264330",
        "name": "3-8 统计08",
        "font_class": "icon-a-3-8tongji08",
        "unicode": "e872",
        "unicode_decimal": 59506
      },
      {
        "icon_id": "32264331",
        "name": "3-7 统计07",
        "font_class": "icon-a-3-7tongji07",
        "unicode": "e873",
        "unicode_decimal": 59507
      },
      {
        "icon_id": "32264332",
        "name": "3-9 统计09",
        "font_class": "icon-a-3-9tongji09",
        "unicode": "e874",
        "unicode_decimal": 59508
      },
      {
        "icon_id": "32264333",
        "name": "3-13 节点 树状图",
        "font_class": "icon-a-3-13jiedianshuzhuangtu",
        "unicode": "e875",
        "unicode_decimal": 59509
      },
      {
        "icon_id": "32264334",
        "name": "3-10 流程 节点",
        "font_class": "icon-a-3-10liuchengjiedian",
        "unicode": "e876",
        "unicode_decimal": 59510
      },
      {
        "icon_id": "32264335",
        "name": "3-19 电池 电量",
        "font_class": "icon-a-3-19dianchidianliang",
        "unicode": "e877",
        "unicode_decimal": 59511
      },
      {
        "icon_id": "32264336",
        "name": "3-17 节点 模式 类型",
        "font_class": "icon-a-3-17jiedianmoshileixing",
        "unicode": "e878",
        "unicode_decimal": 59512
      },
      {
        "icon_id": "32264337",
        "name": "3-6 统计06",
        "font_class": "icon-a-3-6tongji06",
        "unicode": "e879",
        "unicode_decimal": 59513
      },
      {
        "icon_id": "32264338",
        "name": "3-11 节点 类型 模式",
        "font_class": "icon-a-3-11jiedianleixingmoshi",
        "unicode": "e87a",
        "unicode_decimal": 59514
      },
      {
        "icon_id": "32264339",
        "name": "3-16 节点 模式",
        "font_class": "icon-a-3-16jiedianmoshi",
        "unicode": "e87b",
        "unicode_decimal": 59515
      },
      {
        "icon_id": "32264340",
        "name": "3-21 监测 验收",
        "font_class": "icon-a-3-21jianceyanshou",
        "unicode": "e87c",
        "unicode_decimal": 59516
      },
      {
        "icon_id": "32264341",
        "name": "3-23 温度",
        "font_class": "icon-a-3-23wendu",
        "unicode": "e87d",
        "unicode_decimal": 59517
      },
      {
        "icon_id": "32264342",
        "name": "3-14 节点 类型",
        "font_class": "icon-a-3-14jiedianleixing",
        "unicode": "e87e",
        "unicode_decimal": 59518
      },
      {
        "icon_id": "32264343",
        "name": "3-27 定位 位置",
        "font_class": "icon-a-3-27dingweiweizhi",
        "unicode": "e87f",
        "unicode_decimal": 59519
      },
      {
        "icon_id": "32264344",
        "name": "3-26 实时监控摄像头",
        "font_class": "icon-a-3-26shishijiankongshexiangtou",
        "unicode": "e880",
        "unicode_decimal": 59520
      },
      {
        "icon_id": "32264345",
        "name": "3-25 监控 摄像头",
        "font_class": "icon-a-3-25jiankongshexiangtou",
        "unicode": "e881",
        "unicode_decimal": 59521
      },
      {
        "icon_id": "32264346",
        "name": "3-15 节点 四象限",
        "font_class": "icon-a-3-15jiediansixiangxian",
        "unicode": "e882",
        "unicode_decimal": 59522
      },
      {
        "icon_id": "32264347",
        "name": "3-22 电压",
        "font_class": "icon-a-3-22dianya",
        "unicode": "e883",
        "unicode_decimal": 59523
      },
      {
        "icon_id": "32264348",
        "name": "3-24 塔吊",
        "font_class": "icon-a-3-24tadiao",
        "unicode": "e884",
        "unicode_decimal": 59524
      },
      {
        "icon_id": "32264349",
        "name": "3-28 定位 位置 考勤",
        "font_class": "icon-a-3-28dingweiweizhikaoqin",
        "unicode": "e885",
        "unicode_decimal": 59525
      },
      {
        "icon_id": "32264350",
        "name": "3-20 物联网",
        "font_class": "icon-a-3-20wulianwang",
        "unicode": "e886",
        "unicode_decimal": 59526
      },
      {
        "icon_id": "32264351",
        "name": "3-32 监测 验收 模块",
        "font_class": "icon-a-3-32jianceyanshoumokuai",
        "unicode": "e887",
        "unicode_decimal": 59527
      },
      {
        "icon_id": "32264352",
        "name": "3-18 节点 数据 关联",
        "font_class": "icon-a-3-18jiedianshujuguanlian",
        "unicode": "e888",
        "unicode_decimal": 59528
      },
      {
        "icon_id": "32264353",
        "name": "3-30 地球仪 一张图",
        "font_class": "icon-a-3-30diqiuyiyizhangtu",
        "unicode": "e889",
        "unicode_decimal": 59529
      },
      {
        "icon_id": "32264354",
        "name": "3-36 录像 视频",
        "font_class": "icon-a-3-36luxiangshipin",
        "unicode": "e88a",
        "unicode_decimal": 59530
      },
      {
        "icon_id": "32264355",
        "name": "3-35 录像 录制",
        "font_class": "icon-a-3-35luxiangluzhi",
        "unicode": "e88b",
        "unicode_decimal": 59531
      },
      {
        "icon_id": "32264356",
        "name": "3-29 地球 GIS 地图",
        "font_class": "icon-a-3-29diqiuGISditu",
        "unicode": "e88c",
        "unicode_decimal": 59532
      },
      {
        "icon_id": "32264357",
        "name": "3-34 无人机 飞行器",
        "font_class": "icon-a-3-34wurenjifeihangqi",
        "unicode": "e88d",
        "unicode_decimal": 59533
      },
      {
        "icon_id": "32264358",
        "name": "3-38 指纹 手指 印章",
        "font_class": "icon-a-3-38zhiwenshouzhiyinzhang",
        "unicode": "e88e",
        "unicode_decimal": 59534
      },
    ]
  },
  {
    name: 'a-4',
    font_class: 'icon-a-4-1tubiaogongyi',
    color: '#4B98E8',
    children: [
      {
        "icon_id": "32264663",
        "name": "4-2 爱心 配合 合作",
        "font_class": "icon-a-4-2aixinpeihehezuo",
        "unicode": "e895",
        "unicode_decimal": 59541
      },
      {
        "icon_id": "32264664",
        "name": "4-6 计价 测算",
        "font_class": "icon-a-4-6jijiacesuan",
        "unicode": "e896",
        "unicode_decimal": 59542
      },
      {
        "icon_id": "32264665",
        "name": "4-5 计算器",
        "font_class": "icon-a-4-5jisuanqi",
        "unicode": "e897",
        "unicode_decimal": 59543
      },
      {
        "icon_id": "32264666",
        "name": "4-4 计算 计量 计价",
        "font_class": "icon-a-4-4jisuanjiliangjijia",
        "unicode": "e898",
        "unicode_decimal": 59544
      },
      {
        "icon_id": "32264667",
        "name": "4-8 类型 分类 划分",
        "font_class": "icon-a-4-8leixingfenleihuafen",
        "unicode": "e899",
        "unicode_decimal": 59545
      },
      {
        "icon_id": "32264668",
        "name": "4-7 发起 分享 发布",
        "font_class": "icon-a-4-7faqifenxiangfabu",
        "unicode": "e89a",
        "unicode_decimal": 59546
      },
      {
        "icon_id": "32264669",
        "name": "4-3 实测实量 计算 测量",
        "font_class": "icon-a-4-3shiceshiliangjisuanceliang",
        "unicode": "e89b",
        "unicode_decimal": 59547
      },
      {
        "icon_id": "32264670",
        "name": "4-17 进度 项目 甘特图",
        "font_class": "icon-a-4-17jinduxiangmugantetu",
        "unicode": "e89c",
        "unicode_decimal": 59548
      },
      {
        "icon_id": "32264671",
        "name": "4-10 审批 盖章",
        "font_class": "icon-a-4-10shenpigaizhang",
        "unicode": "e89d",
        "unicode_decimal": 59549
      },
      {
        "icon_id": "32264672",
        "name": "4-11 管理 图层 模型",
        "font_class": "icon-a-4-11guanlitucengmoxing",
        "unicode": "e89e",
        "unicode_decimal": 59550
      },
      {
        "icon_id": "32264673",
        "name": "4-18 排列 分列",
        "font_class": "icon-a-4-18pailiefenlie",
        "unicode": "e89f",
        "unicode_decimal": 59551
      },
      {
        "icon_id": "32264674",
        "name": "4-20 模型BIM 管理",
        "font_class": "icon-a-4-20moxingBIMguanli",
        "unicode": "e8a0",
        "unicode_decimal": 59552
      },
      {
        "icon_id": "32264675",
        "name": "4-19 数据 事件蓝图",
        "font_class": "icon-a-4-19shujushijianlantu",
        "unicode": "e8a1",
        "unicode_decimal": 59553
      },
      {
        "icon_id": "32264676",
        "name": "4-12 判断 确定",
        "font_class": "icon-a-4-12panduanqueding",
        "unicode": "e8a2",
        "unicode_decimal": 59554
      },
      {
        "icon_id": "32264677",
        "name": "4-16 集团 企图 公司",
        "font_class": "icon-a-4-16jituanqitugongsi",
        "unicode": "e8a3",
        "unicode_decimal": 59555
      },
      {
        "icon_id": "32264678",
        "name": "4-21 数据源 集合",
        "font_class": "icon-a-4-21shujuyuanjihe",
        "unicode": "e8a4",
        "unicode_decimal": 59556
      },
      {
        "icon_id": "32264679",
        "name": "4-14 企业 公司",
        "font_class": "icon-a-4-14qiyegongsi",
        "unicode": "e8a5",
        "unicode_decimal": 59557
      },
      {
        "icon_id": "32264680",
        "name": "4-9 巡检 监测",
        "font_class": "icon-a-4-9xunjianjiance",
        "unicode": "e8a6",
        "unicode_decimal": 59558
      },
      {
        "icon_id": "32264681",
        "name": "4-31 权限管理 锁",
        "font_class": "icon-a-4-31quanxianguanlisuo",
        "unicode": "e8a7",
        "unicode_decimal": 59559
      },
      {
        "icon_id": "32264682",
        "name": "4-13 项目 集合",
        "font_class": "icon-a-4-13xiangmujihe",
        "unicode": "e8a8",
        "unicode_decimal": 59560
      },
      {
        "icon_id": "32264683",
        "name": "4-30 收藏 五角星 星星",
        "font_class": "icon-a-4-30shoucangwujiaoxingxingxing",
        "unicode": "e8a9",
        "unicode_decimal": 59561
      },
      {
        "icon_id": "32264684",
        "name": "4-32 锁定",
        "font_class": "icon-a-4-32suoding",
        "unicode": "e8aa",
        "unicode_decimal": 59562
      },
      {
        "icon_id": "32264685",
        "name": "4-29 旗帜 竣工",
        "font_class": "icon-a-4-29qizhijungong",
        "unicode": "e8ab",
        "unicode_decimal": 59563
      },
      {
        "icon_id": "32264686",
        "name": "4-26 旗帜 里程碑",
        "font_class": "icon-a-4-26qizhilichengbei",
        "unicode": "e8ac",
        "unicode_decimal": 59564
      },
      {
        "icon_id": "32264687",
        "name": "4-23 图纸 图册管理",
        "font_class": "icon-a-4-23tuzhituceguanli",
        "unicode": "e8ad",
        "unicode_decimal": 59565
      },
      {
        "icon_id": "32264688",
        "name": "4-24 任务 定时",
        "font_class": "icon-a-4-24renwudingshi",
        "unicode": "e8ae",
        "unicode_decimal": 59566
      },
      {
        "icon_id": "32264689",
        "name": "4-22 拆迁管理",
        "font_class": "icon-a-4-22chaiqianguanli",
        "unicode": "e8af",
        "unicode_decimal": 59567
      },
      {
        "icon_id": "32264690",
        "name": "4-1 图标 工艺",
        "font_class": "icon-a-4-1tubiaogongyi",
        "unicode": "e8b0",
        "unicode_decimal": 59568
      },
      {
        "icon_id": "32264691",
        "name": "4-28 党工团",
        "font_class": "icon-a-4-28danggongtuan",
        "unicode": "e8b1",
        "unicode_decimal": 59569
      },
      {
        "icon_id": "32264692",
        "name": "4-25 招标 投标 招投标",
        "font_class": "icon-a-4-25zhaobiaotoubiaozhaotoubiao",
        "unicode": "e8b2",
        "unicode_decimal": 59570
      },
      {
        "icon_id": "32264693",
        "name": "4-15 企业 集团 公司",
        "font_class": "icon-a-4-15qiyejituangongsi",
        "unicode": "e8b3",
        "unicode_decimal": 59571
      },
      {
        "icon_id": "32264694",
        "name": "4-27 旗帜 党建",
        "font_class": "icon-a-4-27qizhidangjian",
        "unicode": "e8b4",
        "unicode_decimal": 59572
      },
    ]
  },
  {
    name: 'a-5',
    font_class: 'icon-a-5-1zhiliang',
    color: '#9F6BF5',
    children: [
      {
        "icon_id": "32268768",
        "name": "5-3 财务 金额",
        "font_class": "icon-a-5-3caiwujine",
        "unicode": "e8b5",
        "unicode_decimal": 59573
      },
      {
        "icon_id": "32268769",
        "name": "5-7 房屋建筑",
        "font_class": "icon-a-5-7fangwujianzhu",
        "unicode": "e8b6",
        "unicode_decimal": 59574
      },
      {
        "icon_id": "32268770",
        "name": "5-1 质量",
        "font_class": "icon-a-5-1zhiliang",
        "unicode": "e8b7",
        "unicode_decimal": 59575
      },
      {
        "icon_id": "32268771",
        "name": "5-6 钱包",
        "font_class": "icon-a-5-6qianbao",
        "unicode": "e8b8",
        "unicode_decimal": 59576
      },
      {
        "icon_id": "32268772",
        "name": "5-2 安全 安全帽",
        "font_class": "icon-a-5-2anquananquanmao",
        "unicode": "e8b9",
        "unicode_decimal": 59577
      },
      {
        "icon_id": "32268773",
        "name": "5-4 票券 财务",
        "font_class": "icon-a-5-4piaoquancaiwu",
        "unicode": "e8ba",
        "unicode_decimal": 59578
      },
      {
        "icon_id": "32268774",
        "name": "5-5 图像 照片",
        "font_class": "icon-a-5-5tuxiangzhaopian",
        "unicode": "e8bb",
        "unicode_decimal": 59579
      },
      {
        "icon_id": "32268775",
        "name": "5-8 打印机器",
        "font_class": "icon-a-5-8dayinjiqi",
        "unicode": "e8bc",
        "unicode_decimal": 59580
      },
      {
        "icon_id": "32268776",
        "name": "5-11 学习 阅读 知识 教育",
        "font_class": "icon-a-5-11xuexiyueduzhishijiaoyu",
        "unicode": "e8bd",
        "unicode_decimal": 59581
      },
      {
        "icon_id": "32268777",
        "name": "5-16 楼层管理",
        "font_class": "icon-a-5-16loucengguanli",
        "unicode": "e8be",
        "unicode_decimal": 59582
      },
      {
        "icon_id": "32268778",
        "name": "5-10 施工",
        "font_class": "icon-a-5-10shigong",
        "unicode": "e8bf",
        "unicode_decimal": 59583
      },
      {
        "icon_id": "32268779",
        "name": "5-9 商店",
        "font_class": "icon-a-5-9shangdian",
        "unicode": "e8c0",
        "unicode_decimal": 59584
      },
      {
        "icon_id": "32268780",
        "name": "5-20消息 对话",
        "font_class": "icon-a-5-20xiaoxiduihua",
        "unicode": "e8c1",
        "unicode_decimal": 59585
      },
      {
        "icon_id": "32268781",
        "name": "5-12 阅读 知识",
        "font_class": "icon-a-5-12yueduzhishi",
        "unicode": "e8c2",
        "unicode_decimal": 59586
      },
      {
        "icon_id": "32268782",
        "name": "5-14 工程 维保",
        "font_class": "icon-a-5-14gongchengweibao",
        "unicode": "e8c3",
        "unicode_decimal": 59587
      },
      {
        "icon_id": "32268783",
        "name": "5-17 天气",
        "font_class": "icon-a-5-17tianqi",
        "unicode": "e8c4",
        "unicode_decimal": 59588
      },
      {
        "icon_id": "32268784",
        "name": "5-15 清扫 清除",
        "font_class": "icon-a-5-15qingsaoqingchu",
        "unicode": "e8c5",
        "unicode_decimal": 59589
      },
      {
        "icon_id": "32268785",
        "name": "5-13 飞机 出差",
        "font_class": "icon-a-5-13feijichucha",
        "unicode": "e8c6",
        "unicode_decimal": 59590
      },
      {
        "icon_id": "32268786",
        "name": "5-19 消息框",
        "font_class": "icon-a-5-19xiaoxikuang",
        "unicode": "e8c7",
        "unicode_decimal": 59591
      },
      {
        "icon_id": "32268787",
        "name": "5-18 疫苗 医疗",
        "font_class": "icon-a-5-18yimiaoyiliao",
        "unicode": "e8c8",
        "unicode_decimal": 59592
      },
      {
        "icon_id": "32268788",
        "name": "5-21 购物车",
        "font_class": "icon-a-5-21gouwuche",
        "unicode": "e8c9",
        "unicode_decimal": 59593
      },
      {
        "icon_id": "32268789",
        "name": "5-26 语音",
        "font_class": "icon-a-5-26yuyin",
        "unicode": "e8ca",
        "unicode_decimal": 59594
      },
      {
        "icon_id": "32268790",
        "name": "5-31 闪电 警示",
        "font_class": "icon-a-5-31shandianjingshi",
        "unicode": "e8cb",
        "unicode_decimal": 59595
      },
      {
        "icon_id": "32268791",
        "name": "5-25 奖杯 荣誉",
        "font_class": "icon-a-5-25jiangbeirongyu",
        "unicode": "e8cc",
        "unicode_decimal": 59596
      },
      {
        "icon_id": "32268792",
        "name": "5-22 汽车 运输",
        "font_class": "icon-a-5-22qicheyunshu",
        "unicode": "e8cd",
        "unicode_decimal": 59597
      },
      {
        "icon_id": "32268793",
        "name": "5-29 礼物 礼品",
        "font_class": "icon-a-5-29liwulipin",
        "unicode": "e8ce",
        "unicode_decimal": 59598
      },
      {
        "icon_id": "32268794",
        "name": "5-32 链接 关联",
        "font_class": "icon-a-5-32lianjieguanlian",
        "unicode": "e8cf",
        "unicode_decimal": 59599
      },
      {
        "icon_id": "32268795",
        "name": "5-23 动态 运营",
        "font_class": "icon-a-5-23dongtaiyunying",
        "unicode": "e8d0",
        "unicode_decimal": 59600
      },
      {
        "icon_id": "32268796",
        "name": "5-30 沙漏 倒计时",
        "font_class": "icon-a-5-30shaloudaojishi",
        "unicode": "e8d1",
        "unicode_decimal": 59601
      },
      {
        "icon_id": "32268797",
        "name": "5-27 工具 擦除 清除 橡皮",
        "font_class": "icon-a-5-27gongjucachuqingchuxiangpi",
        "unicode": "e8d2",
        "unicode_decimal": 59602
      },
      {
        "icon_id": "32268798",
        "name": "5-28 雨伞 防护 安全 保护",
        "font_class": "icon-a-5-28yusanfanghuanquanbaohu",
        "unicode": "e8d3",
        "unicode_decimal": 59603
      },
      {
        "icon_id": "32268799",
        "name": "5-24 网络 域名",
        "font_class": "icon-a-5-24wangluoyuming",
        "unicode": "e8d4",
        "unicode_decimal": 59604
      },
    ]
  },
]

export const appliIcons = [
  {
    "icon_id": "33316599",
    "name": "全景管理",
    "font_class": "#icon-quanjingguanli",
    "unicode": "e86a",
    "unicode_decimal": 59498
  },
  {
    "icon_id": "33308438",
    "name": "技术管理",
    "font_class": "#icon-jishuguanli",
    "unicode": "e86d",
    "unicode_decimal": 59501
  },
  {
    "icon_id": "33308439",
    "name": "危险因素",
    "font_class": "#icon-weixianyinsu",
    "unicode": "e86e",
    "unicode_decimal": 59502
  },
  {
    "icon_id": "33308441",
    "name": "智慧工地",
    "font_class": "#icon-zhihuigongdi",
    "unicode": "e870",
    "unicode_decimal": 59504
  },
  {
    "icon_id": "33308442",
    "name": "环境",
    "font_class": "#icon-huanjing",
    "unicode": "e871",
    "unicode_decimal": 59505
  },
  {
    "icon_id": "33308443",
    "name": "基础数据",
    "font_class": "#icon-jichushuju",
    "unicode": "e872",
    "unicode_decimal": 59506
  },
  {
    "icon_id": "33279613",
    "name": "物资管理",
    "font_class": "#icon-wuziguanli",
    "unicode": "e86c",
    "unicode_decimal": 59500
  },
  {
    "icon_id": "33278718",
    "name": "塔吊",
    "font_class": "#icon-fill_tadiao",
    "unicode": "e514",
    "unicode_decimal": 58644
  },
  {
    "icon_id": "33276982",
    "name": "设备",
    "font_class": "#icon-shebei",
    "unicode": "e86a",
    "unicode_decimal": 59498
  },
  {
    "icon_id": "32291327",
    "name": "05 记录 文档",
    "font_class": "#icon-a-05jiluwendang",
    "unicode": "e846",
    "unicode_decimal": 59462
  },
  {
    "icon_id": "32291328",
    "name": "03 收藏 五角星",
    "font_class": "#icon-a-03shoucangwujiaoxing",
    "unicode": "e847",
    "unicode_decimal": 59463
  },
  {
    "icon_id": "32291329",
    "name": "07 应用 功能",
    "font_class": "#icon-a-07yingyonggongneng",
    "unicode": "e848",
    "unicode_decimal": 59464
  },
  {
    "icon_id": "32291330",
    "name": "01 工作台",
    "font_class": "#icon-a-01gongzuotai",
    "unicode": "e849",
    "unicode_decimal": 59465
  },
  {
    "icon_id": "32291331",
    "name": "04 审批 盖章",
    "font_class": "#icon-a-04shenpigaizhang",
    "unicode": "e84a",
    "unicode_decimal": 59466
  },
  {
    "icon_id": "32291332",
    "name": "09 统计饼图",
    "font_class": "#icon-a-09tongjibingtu",
    "unicode": "e84b",
    "unicode_decimal": 59467
  },
  {
    "icon_id": "32291332",
    "name": "09 统计饼图",
    "font_class": "#icon-a-02xiaoxitixingdaiban-copy",
    "unicode": "e84b",
    "unicode_decimal": 59467
  },
  {
    "icon_id": "32291333",
    "name": "12 测量 设计",
    "font_class": "#icon-a-12celiangsheji",
    "unicode": "e84c",
    "unicode_decimal": 59468
  },
  {
    "icon_id": "32291334",
    "name": "17 模型 功能",
    "font_class": "#icon-a-17moxinggongneng",
    "unicode": "e84d",
    "unicode_decimal": 59469
  },
  {
    "icon_id": "32291335",
    "name": "10 统计 分析",
    "font_class": "#icon-a-10tongjifenxi",
    "unicode": "e84e",
    "unicode_decimal": 59470
  },
  {
    "icon_id": "32291336",
    "name": "08 进度 甘特图",
    "font_class": "#icon-a-08jindugantetu",
    "unicode": "e84f",
    "unicode_decimal": 59471
  },
  {
    "icon_id": "32291337",
    "name": "23 定位 地址 地图",
    "font_class": "#icon-a-23dingweidizhiditu",
    "unicode": "e850",
    "unicode_decimal": 59472
  },
  {
    "icon_id": "32291338",
    "name": "32 消息 对话",
    "font_class": "#icon-a-32xiaoxiduihua",
    "unicode": "e851",
    "unicode_decimal": 59473
  },
  {
    "icon_id": "32291339",
    "name": "34 录像 摄像",
    "font_class": "#icon-a-34luxiangshexiang",
    "unicode": "e852",
    "unicode_decimal": 59474
  },
  {
    "icon_id": "32291340",
    "name": "13 质量",
    "font_class": "#icon-a-13zhiliang",
    "unicode": "e853",
    "unicode_decimal": 59475
  },
  {
    "icon_id": "32291341",
    "name": "26 工单 台账 汇总 概况",
    "font_class": "#icon-a-26gongdantaizhanghuizonggaikuang",
    "unicode": "e854",
    "unicode_decimal": 59476
  },
  {
    "icon_id": "32291342",
    "name": "06 日历 计划 规划",
    "font_class": "#icon-a-06rilijihuaguihua",
    "unicode": "e855",
    "unicode_decimal": 59477
  },
  {
    "icon_id": "32291343",
    "name": "15 安全 安全帽",
    "font_class": "#icon-a-15anquananquanmao",
    "unicode": "e856",
    "unicode_decimal": 59478
  },
  {
    "icon_id": "32291344",
    "name": "27 爱心 合作",
    "font_class": "#icon-a-27aixinhezuo",
    "unicode": "e857",
    "unicode_decimal": 59479
  },
  {
    "icon_id": "32291345",
    "name": "20 团队 合作 协同",
    "font_class": "#icon-a-20tuanduihezuoxietong",
    "unicode": "e858",
    "unicode_decimal": 59480
  },
  {
    "icon_id": "32291346",
    "name": "21 文件夹 文档 汇总",
    "font_class": "#icon-a-21wenjianjiawendanghuizong",
    "unicode": "e859",
    "unicode_decimal": 59481
  },
  {
    "icon_id": "32291347",
    "name": "35 地球 GIS",
    "font_class": "#icon-a-35diqiuGIS",
    "unicode": "e85a",
    "unicode_decimal": 59482
  },
  {
    "icon_id": "32291348",
    "name": "25 办公 公文包 出差",
    "font_class": "#icon-a-25bangonggongwenbaochucha",
    "unicode": "e85b",
    "unicode_decimal": 59483
  },
  {
    "icon_id": "32291349",
    "name": "24 编辑 汇总 输入 填写",
    "font_class": "#icon-a-24bianjihuizongshurutianxie",
    "unicode": "e85c",
    "unicode_decimal": 59484
  },
  {
    "icon_id": "32291350",
    "name": "14 企业 集团",
    "font_class": "#icon-a-14qiyejituan",
    "unicode": "e85d",
    "unicode_decimal": 59485
  },
  {
    "icon_id": "32291351",
    "name": "18 财务 金额",
    "font_class": "#icon-a-18caiwujine",
    "unicode": "e85e",
    "unicode_decimal": 59486
  },
  {
    "icon_id": "32291352",
    "name": "30 荣誉 奖杯",
    "font_class": "#icon-a-30rongyujiangbei",
    "unicode": "e85f",
    "unicode_decimal": 59487
  },
  {
    "icon_id": "32291353",
    "name": "33 施工",
    "font_class": "#icon-a-33shigong",
    "unicode": "e860",
    "unicode_decimal": 59488
  },
  {
    "icon_id": "32291354",
    "name": "22 发起 分享 飞机",
    "font_class": "#icon-a-22faqifenxiangfeiji",
    "unicode": "e861",
    "unicode_decimal": 59489
  },
  {
    "icon_id": "32291355",
    "name": "28 功能 工艺",
    "font_class": "#icon-a-28gongnenggongyi",
    "unicode": "e862",
    "unicode_decimal": 59490
  },
  {
    "icon_id": "32291356",
    "name": "16 知识库 图书",
    "font_class": "#icon-a-16zhishikutushu",
    "unicode": "e863",
    "unicode_decimal": 59491
  },
  {
    "icon_id": "32291357",
    "name": "11 计算 合记",
    "font_class": "#icon-a-11jisuanheji",
    "unicode": "e864",
    "unicode_decimal": 59492
  },
  {
    "icon_id": "32291358",
    "name": "31 设计 勘查",
    "font_class": "#icon-a-31shejikancha",
    "unicode": "e865",
    "unicode_decimal": 59493
  },
  {
    "icon_id": "32291359",
    "name": "19 用户 个人中心 信息",
    "font_class": "#icon-a-19yonghugerenzhongxinxinxi",
    "unicode": "e866",
    "unicode_decimal": 59494
  },
  {
    "icon_id": "32291360",
    "name": "36 摄像头 监控",
    "font_class": "#icon-a-36shexiangtoujiankong",
    "unicode": "e867",
    "unicode_decimal": 59495
  },
  {
    "icon_id": "32291361",
    "name": "29 节点 组织",
    "font_class": "#icon-a-29jiedianzuzhi",
    "unicode": "e868",
    "unicode_decimal": 59496
  }
]

export const menuIcons = [
  {
      "icon_id": "32865981",
      "name": "创优创先",
      "font_class": "icon-fill-chuangyouchuangxian",
      "unicode": "e854",
      "unicode_decimal": 59476
  },
  {
      "icon_id": "32865982",
      "name": "电话 通讯",
      "font_class": "icon-fill-a-dianhuatongxun",
      "unicode": "e855",
      "unicode_decimal": 59477
  },
  {
      "icon_id": "32865983",
      "name": "工作台 电脑",
      "font_class": "icon-fill-a-gongzuotaidiannao",
      "unicode": "e856",
      "unicode_decimal": 59478
  },
  {
      "icon_id": "32865984",
      "name": "日历",
      "font_class": "icon-fill-rili",
      "unicode": "e857",
      "unicode_decimal": 59479
  },
  {
      "icon_id": "32865985",
      "name": "计算 计价",
      "font_class": "icon-fill-a-jisuanjijia",
      "unicode": "e858",
      "unicode_decimal": 59480
  },
  {
      "icon_id": "32865986",
      "name": "模型",
      "font_class": "icon-fill-moxing",
      "unicode": "e859",
      "unicode_decimal": 59481
  },
  {
      "icon_id": "32865987",
      "name": "安全",
      "font_class": "icon-fill-anquan",
      "unicode": "e85a",
      "unicode_decimal": 59482
  },
  {
      "icon_id": "32865988",
      "name": "图纸",
      "font_class": "icon-fill-tuzhi",
      "unicode": "e85b",
      "unicode_decimal": 59483
  },
  {
      "icon_id": "32865989",
      "name": "提醒 消息",
      "font_class": "icon-fill-a-tixingxiaoxi",
      "unicode": "e85c",
      "unicode_decimal": 59484
  },
  {
      "icon_id": "32865990",
      "name": "视频 录像",
      "font_class": "icon-fill-a-shipinluxiang",
      "unicode": "e85d",
      "unicode_decimal": 59485
  },
  {
      "icon_id": "32865991",
      "name": "文件夹",
      "font_class": "icon-fill-wenjianjia",
      "unicode": "e85e",
      "unicode_decimal": 59486
  },
  {
      "icon_id": "32865992",
      "name": "文件 知识库 图册",
      "font_class": "icon-fill-a-wenjianzhishikutuce",
      "unicode": "e85f",
      "unicode_decimal": 59487
  },
  {
      "icon_id": "32865993",
      "name": "质量",
      "font_class": "icon-fill-zhiliang",
      "unicode": "e860",
      "unicode_decimal": 59488
  },
  {
      "icon_id": "32865994",
      "name": "荣誉 奖杯",
      "font_class": "icon-fill-a-rongyujiangbei",
      "unicode": "e861",
      "unicode_decimal": 59489
  },
  {
      "icon_id": "32865995",
      "name": "疑问",
      "font_class": "icon-fill-yiwen",
      "unicode": "e862",
      "unicode_decimal": 59490
  },
  {
      "icon_id": "32865996",
      "name": "团队 合作",
      "font_class": "icon-fill-a-tuanduihezuo",
      "unicode": "e863",
      "unicode_decimal": 59491
  },
  {
      "icon_id": "32865997",
      "name": "一张图",
      "font_class": "icon-fill-yizhangtu",
      "unicode": "e864",
      "unicode_decimal": 59492
  },
  {
      "icon_id": "32865998",
      "name": "消息 评论",
      "font_class": "icon-fill-a-xiaoxipinglun",
      "unicode": "e865",
      "unicode_decimal": 59493
  },
  {
      "icon_id": "32865999",
      "name": "图片 相册",
      "font_class": "icon-fill-a-tupianxiangce",
      "unicode": "e866",
      "unicode_decimal": 59494
  },
  {
      "icon_id": "32866000",
      "name": "用户 个人",
      "font_class": "icon-fill-a-yonghugeren",
      "unicode": "e867",
      "unicode_decimal": 59495
  },
  {
      "icon_id": "32864736",
      "name": "节点 数据",
      "font_class": "icon-fill-a-jiedianshuju",
      "unicode": "e84e",
      "unicode_decimal": 59470
  },
  {
      "icon_id": "32864737",
      "name": "统计 分析",
      "font_class": "icon-fill-a-tongjifenxi",
      "unicode": "e84f",
      "unicode_decimal": 59471
  },
  {
      "icon_id": "32864738",
      "name": "主页 首页",
      "font_class": "icon-fill-a-zhuyeshouye",
      "unicode": "e850",
      "unicode_decimal": 59472
  },
  {
      "icon_id": "32864739",
      "name": "应用",
      "font_class": "icon-fill-yingyong",
      "unicode": "e851",
      "unicode_decimal": 59473
  },
  {
      "icon_id": "32864740",
      "name": "企业 公司",
      "font_class": "icon-fill-a-qiyegongsi",
      "unicode": "e852",
      "unicode_decimal": 59474
  },
  {
      "icon_id": "32864742",
      "name": "系统 设置",
      "font_class": "icon-fill-a-xitongshezhi",
      "unicode": "e853",
      "unicode_decimal": 59475
  }
]