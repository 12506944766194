<!--
 * @LastEditTime: 2022-10-18 11:02:31
 * @Description: 视图选择器
 * @FilePath: /dwh_front/src/components/metaSelect/ViewSelect.vue
 * @Date: 2021-12-20 20:20:52
 * @Author: lisushuang
 * @LastEditors: zx
-->

<template>
  <el-select
    :multiple="multiple"
    filterable
    allow-create
    v-model="selectValue"
    style="width: 100%"
    v-loading="selectLoading"
    default-first-option
    ref="obselect"
    element-loading-spinner="el-icon-loading"
    @focus="onSelectFoucus = true"
  >
    <el-option
      v-for="(item, index) in realSelectData"
      :key="index"
      :label="item.object_name + '-' + item.name"
      :value="item.uuid"
    >
    </el-option>
    <template slot="prefix">
    <MetaTraceIcon method="ShowViewForm" :uuid="selectValue"  style="line-height: 40px;"/>
    </template>

  </el-select>
</template>

<script>
import objects from "@/api/objects";
import collect from 'collect.js';
import MetaTraceIcon from './MetaTraceIcon.vue';

export default {
  name: "ViewSelect",
  components: { MetaTraceIcon },
  props: {
    object_uuid: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    // 没有条件时是否需要所有的视图
    needAllSelect:{
      type :Boolean,
      default:true
    }
  },
  watch: {
    object_uuid() {
      this.getSelectData();
    },
    selectValue() {
      this.$emit("input", this.selectValue);
    },
    value(val, oldVal) {
      if (val && !oldVal && !this.selectValue) {
        this.selectValue = val;
      }
    },
  },
  data() {
    return {
      selectData: [],
      selectValue: "",
      selectLoading: true,
      onSelectFoucus:false
    };
  },
  computed: {
    /**
     * @description: 实际展示的选择数据，在没有focus时只显示唯一的那一条
     * @param {*}
     * @return {*}
     */
    realSelectData() {
      if (this.onSelectFoucus) {
        return this.selectData;
      } else {
        let one = collect(this.selectData).where("uuid", this.value).first();
        if (one) return [one];
        return [];
      }
    },
  },
  methods: {
    getSelectData() {
      this.selectLoading = true;
      let search = [];
      if (this.object_uuid) {
        search.push({
          code: "object_uuid",
          ruleType: "eq",
          value: this.object_uuid,
        });
      }else{
        if(!this.needAllSelect){
          search.push({
            code:'id',
            ruleType:'eq',
            value:0
          })
        }
      }

      // 重新请求视图列表数据
      objects.mapi
        .common({
          __method_name__: "dataList",
          object_uuid: "object6315b2f43316f",
          view_uuid: "view634e0ee59b673",
          search: search,
          transcode: 0,
          __increment__:1
        })
        .then((res) => {
          this.selectLoading = false;
          if (res.data.code == 200) {
            this.selectData = res.data.data;
          } else {
            this.selectData = [];
          }
        })
        .catch(() => {
          this.selectLoading = false;
          this.selectData = [];
        });
    },
  },
  mounted() {
    this.getSelectData();
    this.selectValue = this.value
  },
};
</script>