<!--
 * @Author: Shiltin
 * @Date: 2021-10-22 11:43:57
 * @LastEditors: Shiltin 18580045074@163.com
 * @LastEditTime: 2022-10-28 14:36:23
 * @Description:
-->
<template>
    <div id="main" style="width: 100%; height: 100%">
        <div :id="'gisDom'+domId"  style="width: 100%; height: 100%" />
    </div>
</template>

<script>
let gis = null;
import {dataInterface} from "@/apis/data/index";
export default {
    name: "gisPreview",
    props: {
        //回显模型格式[{models:[],position:{lat,lng,height}}]
        modelData: {
            type: Object,
            default: null
        },
				domId:{
					type:String,
					default: ""
				},
				appId: {
					type: String,
					default: ""
				},
				appSecret: {
					type: String,
					default: ""
				}
    },
    data() {
        return {
					isInited: false,
					isDrawPoint: false,
					defaultGis: [],
					modelList: [],
					loadedModel:[],
					initParams: {
							themeColor: '#ffffff',
							activeColor: '#0076FF',
							textColor: '#121619',
							appId :this.appId || process.env.VUE_APP_MODEL_APPID ,
              appSecret : this.appSecret || process.env.VUE_APP_MODEL_APPSECRET ,
							modelList:[]
					},
        };
    },
    methods: {
        //初始gis模型
        initModel() {
						this.initParams.modelList = this.modelList
            gis = new window.BIMCC.Viewer("gisDom" + this.domId, {
							isGIS: true,
						});
            gis.init(this.initParams);
            gis.on("viewerLoaded",() => {
							this.isInited = true
							gis.drawPointByMouseCustom()
							if(this.defaultGis.length){
								this.defaultGis.forEach(async(item)=>{
									await gis.addModel([{modelId: item, modelName: ''}])
									gis.locationModel(item)
								})
							}
							if (this.modelData?.position?.lng) {
								const data = [{ key: 1, position: this.modelData.position }]
								const ele = {
									offset: [-8, -8], // 标签点偏移值
									div: '<i class="iconfont icongis_zidongdingwei" style="color:blue">'
								}
								gis.addDivBillboardArray(data,ele)
							}
						});
						//监听点经纬度
						gis.on('drawPointByMouseCustom',async(res) => {
							if(res){
								let models = [] 
								let data  = await gis.getLoadedModel()
								if(Object.prototype.toString.call(data) === '[object Object]'){
									for(let key in data){
										models.push({modelId:data[key].modelId,modelName:data[key].modelName})
									}
								}
								this.$emit('set-data',{models:models,position:res})
							}
						})
        },
				echoData () {
						// 模型回显
						if (this.modelData?.models?.length) {
							this.defaultGis = this.modelData.models
						}
				},
				getModelList () {
					dataInterface({
						object_uuid: 'object62624ddb0a688',
						view_uuid: 'view62b03f11b6e99',
						__method_name__: 'dataList',
						transcode: 0,
					}).then((res) => {
						if (res.code === 200 && res.data.length !== 0) {
							let arr = []
							res.data.forEach((item) => {
								const it = item
								const models = it.gis_type
								if (models && models.length !== 0) {
									models.forEach((val) => {
										if (val.model_id) {
											const obj = { id: val.model_id, content: val.name }
											arr.push(obj)
										}
									})
								}
							})
							this.echoData()
							this.modelList = arr
							this.isDrawPoint = true
							this.initModel()
						}else{
								this.echoData()
								this.modelList = []
								this.isDrawPoint = true
								this.initModel()
						}
					})
				}
        
    },
    mounted() {
        this.getModelList()
    },
};
</script>

