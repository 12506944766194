// 解决select渲染大量数据出现卡顿的问题
<template>
  <div v-if="show" class="w-full">
    <el-form ref="form" :rules="rules" :model="form" label-width="110px">
      <el-form-item :label="label" :prop="required ? 'value' : ''">
        <el-select
          filterable
          clearable
          class="w-full"
          v-model="form.value"
          :disabled="disabled"
          placeholder="请选择"
          :multiple="multiple"
          @visible-change="visibleChange"
          :filter-method="examPaperFilter"
          v-el-select-load-more:rangeNumber="loadMoreFun(rangeNumber)"
        >
          <el-option
            v-for="(item, index) in datas.slice(0, rangeNumber)"
            :key="index"
            :label="item.name"
            :value="item[keyField]"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "largeDataSelct",
  components: {},
  props: {
    selectData: {
      //selectData数据
      type: Array,
      default: () => [],
    },
    keyField: {
      //
      type: String,
      default: "uuid",
    },
    required: {
      //是否必填
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: "",
    },
    backSelected: {
      type: [String, Array],
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rangeNumber: 20, //下拉框滚动到底部后新增的条数
      datas: [], //select的数据
      rules: {
        value: [{ required: true, message: "请选择", trigger: "change" }],
      },
      form: {
        value: "",
      },
      show: false,
      timer: null,
      filterMode: false, //当前是都使用搜索
    };
  },
  computed: {},
  //监听el-select绑定的数据
  watch: {
    "form.value"(newVal) {
      // 清空输入框后重新给下拉内容赋值
      if (!newVal) {
        this.datas = JSON.parse(JSON.stringify(this.selectData));
      }
      this.$emit("on-change", newVal);
    },
    selectData() {
      //总数据可能返回时间较长
      if (!this.show) {
        this.initData();
      }
    },
  },
  //注册一个自定义指令 `v-el-select-load-more`
  directives: {
    "el-select-load-more": {
      bind(el, binding) {
        // 获取element-ui定义好的scroll盒子
        const SELECTWRAP_DOM = el.querySelector(
          ".el-select-dropdown .el-select-dropdown__wrap"
        );
        SELECTWRAP_DOM.addEventListener("scroll", function () {
          /**
           * scrollHeight 获取元素内容高度(只读)
           * scrollTop 获取或者设置元素的偏移值,常用于计算滚动条的位置, 当一个元素的容器没有产生垂直方向的滚动条, 那它的scrollTop的值默认为0.
           * clientHeight 读取元素的可见高度(只读)
           * 如果元素滚动到底, 下面等式返回true, 没有则返回false:
           * ele.scrollHeight - ele.scrollTop === ele.clientHeight;
           */
          const condition =
            this.scrollHeight - this.scrollTop - 20 <= this.clientHeight;
          if (condition) binding.value();
        });
      },
    },
  },
  methods: {
    visibleChange(bol) {
      if (bol && this.filterMode) {
        let arr = this.selectData.filter((v) => v.uuid == this.form.value);
        if (arr.length != 0) {
          this.rangeNumber = 20;
          this.datas = JSON.parse(JSON.stringify(this.selectData));
          this.filterMode = false;
          let showedArr = this.datas.slice(0, this.rangeNumber);
          if (showedArr.filter((v) => v.uuid == this.form.value).length == 0) {
            this.datas.unshift(arr[0]);
          }
        }
      }
    },
    loadMoreFun() {
      //每次滚动到底部可以新增条数
      return () => (this.rangeNumber += 20);
    },
    examPaperFilter(val) {
      let data = JSON.parse(JSON.stringify(this.selectData));
      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        if (val) {
          let arr = this.selectData.filter((v) => v.uuid == this.form.value);
          this.datas = data.filter((item) => item.name.includes(val));
          this.filterMode = true;
          if (arr.length != 0) {
            this.datas.push(arr[0]);
          }
        }
      }, 1000);
    },
    initData() {
      this.datas = [];
      this.form.value = this.backSelected;
      for (const item of this.selectData) {
        if (!this.multiple) {
          if (item[this.keyField] == this.backSelected) {
            this.datas.splice(0, 0, item);
          } else {
            this.datas.push(item);
          }
        } else {
          // TODO: 这句是为什么？
          // this.form.value=[]
          if (
            this.backSelected.filter((v) => v == item[this.keyField]).length !=
            0
          ) {
            this.datas.splice(0, 0, item);
          } else {
            this.datas.push(item);
          }
        }
      }
      if (this.datas.length != 0) {
        this.show = true;
      }
    },
  },
  mounted() {
    if (this.backSelected != "") {
      //回显
      this.initData();
    } else {
      this.datas = JSON.parse(JSON.stringify(this.selectData));
      this.show = true;
    }
  },
};
</script>

<style lang="less">
</style>