<template>
	<div
		:style="{ width: width }"
		class="left-item no-boder-table h-full">
		<div
			class="seach-bar"
			style="position: relative"
			v-if="!hideHeader && searchAble"></div>
		<div
			class="left-item-header"
			v-if="!hideHeader">
			<div class="left-item-title truncate">
				<!-- <i
          v-if="showBack"
          class="iconc-chevron-left iconfont"
          color="#0076ff"
          @click="$emit('back')"
        /> -->
				<p>{{ topTitle }}</p>
			</div>
			<div class="left-item-action">
				<i
					class="iconxinzeng2 iconfont"
					color="#0076ff"
					v-if="topAddable"
					@click="topAdd" />
			</div>
		</div>
		<p
			style="font-size: 12px; line-height: 13px; margin-top: -15px; text-align: left"
			v-if="!hideHeader && topSubTitle">
			{{ topSubTitle }}
		</p>
		<div
			:style="!hideHeader ? 'height: 100%;padding: 0 8px;overflow-y: auto;' : 'height: 100%;'"
			class="overflow-y-auto"
			:class="[showRoll === true ? '' : 'hidden']">
			<el-tree
				v-if="showTree && tableData && tableData.length"
				ref="treeRef"
				:data="tableData"
				node-key="id"
				:default-expand-all="true"
				:filter-node-method="filterNode"
				@node-drag-start="handleDragStart"
				@node-drag-end="handleDragEnd"
				@node-drop="handleDrop"
				:allow-drop="allowDrop"
				:draggable="isCompany"
				:props="defaultProps"
				@node-click="rowClick">
				<template #default="{ node, data }">
					<div
						class="archi-label"
						:class="getROwClass(data)">
						<div class="position-container">
							<div class="archi-label-svg">
								<svg
									v-if="+node.data.menu_type === 6 && !node.expanded"
									aria-hidden="true"
									class="iconfont-svg">
									<use xlink:href="#iconwenjianjia2" />
								</svg>
								<svg
									v-else-if="+node.data.menu_type === 6 && node.expanded"
									aria-hidden="true"
									class="iconfont-svg">
									<use xlink:href="#icona-wenjianjiadakai" />
								</svg>
								<div
									v-else-if="+node.data.menu_type === 1"
									class="header-img"
									:style="
										'background:' +
										(data.background ? data.background : 'linear-gradient(rgb(255, 165, 87) 0%, rgb(255, 92, 92) 100%')
									">
									<svg
										v-if="data.icon"
										aria-hidden="true"
										class="color-icon">
										<use :xlink:href="data.icon.indexOf('#') === -1 ? '#' + data.icon : data.icon" />
									</svg>
									<svg
										v-else
										aria-hidden="true"
										class="color-icon">
										<use xlink:href="#icon-a-05jiluwendang" />
									</svg>
								</div>
								<div
									v-else
									class="header-img"
									style="background: transparent">
									<i
										class="iconfont"
										:class="data.icon ? data.icon : 'icon-a-5-1zhiliang'"
										:style="
											'color: ' +
											(selectRow.id !== data.id
												? ['#E06363', '#F59A31', '#2CB8A4', '#4B98E8', '#9F6BF5'].indexOf(data.color) === -1
													? '#E06363'
													: data.color
												: ['#E06363', '#F59A31', '#2CB8A4', '#4B98E8', '#9F6BF5'].indexOf(data.color) === -1
												? '#E06363'
												: data.color)
										">
									</i>
								</div>
								<div class="archi-label-txt">
									{{ node.data[defaultProps.label] }}
								</div>
							</div>
							<div
								v-if="checkAuth(data)"
								class="edit"
								@click.stop="clicData()">
								<!--  && selectRow == data -->
								<el-dropdown
									v-if="delAble || childAddAble || editAble"
									placement="bottom"
									class="dropdown-container">
									<div class="el-dropdown-link">
										<i class="iconfont icongengduomore"></i>
									</div>
									<el-dropdown-menu slot="dropdown">
										<el-dropdown-item v-if="childShowAdd || (childAddAble && +data.menu_type === 6)">
											<!-- <p
												v-if="childShowAdd"
												@click.stop="$emit('child-add', data)">
												新增
											</p> -->
											<!-- 只有菜单分组类型才能点击子数据新增 -->
											<p
												v-if="childAddAble && +data.menu_type === 6"
												@click.stop="$emit('row-add', data)">
												新增
											</p>
											<!-- <i
                        class="iconxinzeng2 iconfont"
                        color="#0076ff"
                        v-if="childAddAble && +data.menu_type === 6"
                        @click.stop="$emit('row-add', data)"
                      /> -->
										</el-dropdown-item>
										<el-dropdown-item v-if="editAble">
											<p @click.stop="$emit('row-edit', data)">编辑</p>
										</el-dropdown-item>
										<el-dropdown-item v-if="delAble && data.id">
											<p
												style="color: #f56c6c"
												@click.stop="
													delModal = true;
													delData = data;
												">
												删除
											</p>
										</el-dropdown-item>
									</el-dropdown-menu>
								</el-dropdown>
							</div>
						</div>
					</div>
				</template>
			</el-tree>
			<el-empty
				slot="empty"
				:image-size="90"
				v-if="tableData.length == 0 && emptyMode"
				description="暂无数据"></el-empty>
		</div>
		<el-dialog
			title="提示"
			:visible.sync="delModal"
			width="25%"
			append-to-body>
			<div class="del-container">是否删除</div>
			<span
				slot="footer"
				class="dialog-footer">
				<el-button @click="delModal = false">取 消</el-button>
				<el-button
					type="primary"
					@click="delItem()"
					>确 定</el-button
				>
			</span>
		</el-dialog>
	</div>
</template>
<script>
import { Empty, Dropdown, DropdownMenu, DropdownItem, Tree } from 'element-ui';
import { mapState } from 'vuex';
import { saasMenuSort } from '@/api/myApps';

export default {
	neme: 'left-item',
	components: {
		'el-empty': Empty,
		'el-dropdown': Dropdown,
		'el-dropdown-menu': DropdownMenu,
		'el-dropdown-item': DropdownItem,
		'el-tree': Tree
	},
	props: {
		tableData: {
			//总数据
			type: Array,
			default: () => []
		},
		searchAble: {
			//搜索
			type: Boolean,
			default: true
		},
		hideHeader: {
			//搜索
			type: Boolean,
			default: false
		},
		defaultProps: {
			//显示的字段
			type: Object,
			default: () => {
				return {
					label: 'name',
					children: 'children'
				};
			}
		},
		width: {
			type: String,
			default: '100%'
		},
		topTitle: {
			//顶部名称
			type: String,
			default: ''
		},
		topSubTitle: {
			type: String,
			default: ''
		},
		topAddable: {
			//顶部新增
			type: Boolean,
			default: true
		},
		delAble: {
			//删除
			type: Boolean,
			default: false
		},
		childAddAble: {
			type: Boolean,
			default: true
		},
		editAble: {
			type: Boolean,
			default: true
		},
		isChildren: {
			type: Boolean,
			default: false
		},
		showBack: {
			type: Boolean,
			default: false
		},
		childShowAdd: {
			type: Boolean,
			default: false
		},
		historyAppInfo: {
			type: Object,
			default: () => {}
		},
		historyChildAppInfo: {
			type: Object,
			default: () => {}
		},
		/* 当前是否点击的应用  如果为true  就算有子级  也是父级展示蓝色  子级展示灰色 点击应用则应用显示为蓝色 */
		showHistoryVisible: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			selectRow: '',
			delModal: false, //删除确认框
			delData: null,
			emptyMode: false,
			pageEditNumber: [2, 3, 4, 5, 8], // 属于pageeditor的类型
			showTree: true,
			timer: null, //定时器
			showRoll: false, //滚动条显示隐藏
			distanceY: null, //滚动条长度
			roll: 0,
			isCompany: false
		};
	},
	computed: {
		...mapState(['userInfo', 'systemStyle'])
	},
	watch: {
		tableData() {
			this.showTree = false;
			this.$nextTick(() => {
				this.showTree = true;
			});
		},
		historyChildAppInfo: {
			handler(val) {
        
				if (this.showBack) {
					this.selectRow = val;
				}
			},
			deep: true,
			immediate: true
		},
		historyAppInfo: {
			handler(val) {
				if (val) {
          this.$emit('history',val)
					if (!this.showBack) {
						this.selectRow = val;
					}
				}
			},
			deep: true,
			immediate: true
		}
	},
	mounted() {
		//监听滚动事件
		window.addEventListener('scroll', this.scroll, true);
		setTimeout(() => {
			this.emptyMode = true;
		}, 500);
		this.isCompany = JSON.parse(localStorage.getItem('userInfo')).is_company ? true : false;
	},
	methods: {
		allowDrop(draggingNode, dropNode, type) {
			if (dropNode.data.menu_type === 6) {
				return true;
			} else {
				return type !== 'inner';
			}
		},
		handleDragStart(node, event) {
			console.log('drag start', node);
			console.log(event);
		},
		handleDragEnd(draggingNode, dropNode, dropType) {
			if (dropType === 'none') return;
			let parentLength = dropNode.data.all_parent.split(',').length;
			let sort;
			if (dropType === 'before') {
				if (dropNode.data.sort !== 0) {
					sort = dropNode.data.sort - 1;
				} else {
					sort = 0;
				}
			} else if (dropType === 'after') {
				sort = dropNode.data.sort + 1;
			}
			let params = {
				menu_id: draggingNode.data.id,
				parent_id: dropNode.data.all_parent.split(',')[parentLength - 1],
				sort: sort
			};
			if (dropNode.data.children.length) {
				dropNode.data.children.forEach((item, index) => {
					if (item.id === params.menu_id) {
						params.parent_id = dropNode.data.id;
						if (index !== 0) {
							params.sort = dropNode.data.children[index - 1].sort + 1;
						} else {
							params.sort = 0;
						}
					}
				});
			}
			saasMenuSort(params).then((res) => {
				console.log(res);
			});
		},
		handleDrop(draggingNode, dropNode, dropType) {
			console.log('tree drop: ', dropNode.label, dropType);
		},
		rowClick(row, node, dom) {
			if ((row.menu_type === 6 && !row.children) || row.children.length === 0) {
				node.expanded = !node.expanded;
			}
			if ((row.row_click !== undefined && !row.row_click) || +row.menu_type === 6) {
				/* 分组点击后增加hover颜色 */
				if (+row.menu_type === 6) {
					const domElement = dom.$refs.node;
					domElement.children.forEach((item) => {
						if (item._prevClass.indexOf('el-tree-node__content') !== -1) {
							setTimeout(() => {
								item.style.background = 'rgb(242, 244, 247)';
							}, 1);
						}
					});
				}
				return;
			}
			this.selectRow = row;
			this.$emit('row-click', row);
		},
		topAdd() {
			//顶部新增
			this.$emit('top-add');
		},
		delItem() {
			//删除
			this.delModal = false;
			this.$emit('row-del', this.delData);
		},
		/* tree组件的过滤规则 */
		filterNode(value, data) {
			if (!value) return true;
			return !!(data.name && data.name.indexOf(value) !== -1);
		},
		/* 搜索 */
		filterValue(val) {
			this.$refs.treeRef.filter(val || '');
		},
		/* 根据数据选择  返回不同class */
		getROwClass(data) {
			let className = '';
			if (this.showBack) {
				/* 暗黑风格下 选中都为蓝色 */
				if (+this.systemStyle === 2) {
					if (this.selectRow?.id === data.id) {
						className = 'selected';
					} else {
						className = '';
					}
				} else {
					if (!this.historyChildAppInfo) {
						this.selectRow = {};
					}
					if (this.selectRow?.id && data.id === this.selectRow.id) {
						if (this.showHistoryVisible) {
							className = 'select';
						} else {
							className = 'selected';
						}
					}
				}
			} else {
				if (this.selectRow?.id && data.id === this.selectRow.id) {
					let boo = false;
					const loop = (arr) => {
						if (arr?.length) {
							arr.forEach((element) => {
								if (element.id === this.historyChildAppInfo.id) {
									boo = true;
								}
								if (element.children?.length) {
									loop(element.children);
								}
							});
						}
					};
					if (data?.children?.length) {
						loop(data.children);
					}
					if (boo) {
						if (this.showHistoryVisible) {
							className = 'selected';
						} else {
							className = 'select';
						}
					} else {
						className = 'selected';
					}
				}
			}
			this.$nextTick(() => {
				const allDom = document.getElementsByClassName('el-tree-node__content');
				allDom.forEach((element) => {
					let boo = false;
					let boo1 = false;
					const blue = '#337EFF';
					const eee = '#E6E9F0';
					if (element?.children?.length) {
						element.children.forEach((item) => {
							if (item._prevClass.indexOf('selected') !== -1) {
								boo = true;
								element.style.background = blue;
								element.style.borderRadius = '6px';
							}
							if (item._prevClass.indexOf('select') !== -1 && item._prevClass.indexOf('selected') === -1) {
								boo1 = true;
								element.style.background = eee;
								element.style.borderRadius = '6px';
							}
						});
					}
					if (!boo && (element.style.background === '#337EFF' || element.style.background === 'rgb(51, 126, 255)')) {
						element.style.background = '#fff';
						element.style.backgroundColor = '#fff';
					}
					if (
						!boo1 &&
						!boo &&
						(element.style.background === '#337EFF' || element.style.background === 'rgb(51, 126, 255)')
					) {
						element.style.background = '#fff';
						element.style.backgroundColor = '#fff';
					}
					if (!boo && !boo1) {
						element.style.background = '#fff';
						element.style.backgroundColor = '#fff';
						element.onmouseover = function () {
							element.style.background = '#F2F4F7';
						};
						element.onmouseleave = function () {
							element.style.background = '#fff';
						};
					} else {
						if (boo) {
							element.onmouseover = function () {
								element.style.background = blue;
							};
							element.onmouseleave = function () {
								element.style.background = blue;
							};
						}
						if (boo1) {
							element.onmouseover = function () {
								element.style.background = eee;
							};
							element.onmouseleave = function () {
								element.style.background = eee;
							};
						}
					}
				});
			});
			return className;
		},
		/* 判断是否是自己新增的数据 */
		checkAuth(data) {
			if (+data.create_user_id === +this.userInfo.id) {
				return true;
			}
			return false;
		},
		clicData() {},
		//滚动监听事件
		scroll(event) {
			this.distanceY = event.target.scrollTop;
			let e = event || event.target;
			this.roll = e.srcElement.scrollTop;
			clearTimeout(this.timer);
			this.showRoll = true;
			this.timer = setTimeout(() => {
				if (this.distanceY === this.roll) {
					this.showRoll = false;
				}
			}, 1000);
		}
	},
	destroyed() {
		window.removeEventListener('scroll', this.scroll, true);
	}
};
</script>
<style lang="less" scoped>
@import '~@/manage-views/css/manageAdd.less';
.hidden::-webkit-scrollbar-thumb {
	background-color: transparent !important;
}
.hidden::-webkit-scrollbar {
	display: none;
	background-color: transparent !important;
}
.del-container {
	width: 100%;
	height: 30px;
	padding: 16px;
	box-sizing: border-box;
}
.left-item {
	height: 100%;
	display: flex;
	flex-direction: column;
	:deep(.el-tree-node__content) {
		height: 32px;
		margin-bottom: 4px;
		position: relative;
		.el-tree-node__expand-icon,
		.is-leaf {
			display: none;
			margin: 0;
			padding: 0;
			color: transparent;
		}
	}
	:deep(.el-tree-node__content:hover) {
		background: #f2f4f7;
		border-radius: 6px;
	}
}
:deep(.el-empty__description){
  p{
    font-size: 12px;
    color: #a5acb3;
  }

}
.archi-label {
	width: 100%;
	height: 32px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	overflow: hidden;
	position: relative;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	-khtml-user-select: none;
	-o-user-select: none;
	user-select: none;
	&:hover {
		.position-container {
			.edit {
				opacity: 1;
			}
		}
	}
	.position-container {
		width: 100%;
		height: 32px;
		// todo
		// position: absolute;
		// top: 0;
		// left: 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		z-index: 9;
		overflow: hidden;
		box-sizing: border-box;
		.edit {
			opacity: 0;
			width: 24px;
			height: 24px;
			text-align: center;
			margin-left: 6px;
			margin-right: 4px;
			display: flex;
			align-items: center;
			border-radius: 4px;
			display: flex;
			justify-content: center;
			align-items: center;
			div {
				height: 40px;
				line-height: 40px;
			}
			i {
				font-size: 16px;
			}
			.dropdown-container {
				width: 100%;
				height: 100%;
				.el-dropdown-link {
					width: 100%;
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					line-height: 18px;
					i {
						width: 16px;
						height: 16px;
						font-size: 16px;
					}
				}
			}
		}
		.edit:hover {
			background: #d7dae0;
			i {
				color: #2a2f3d;
			}
		}
		.archi-label-svg {
			font-family: SourceHanSansCN;
			font-size: 14px;
			font-weight: 400;
			display: flex;
			align-items: center;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			padding-left: 11px;
			box-sizing: border-box;
			display: flex;
			.header-img {
				width: 16px;
				height: 16px;
				margin-right: 8px;
				border-radius: 3px;
				display: flex;
				justify-content: center;
				align-items: center;
				background: linear-gradient(180deg, #ffa557 0%, #ff5c5c 100%);
				svg {
					width: 13px;
					height: 13px;
					margin: 0;
				}
				i {
					width: 16px;
					height: 16px;
					font-size: 16px;
				}
			}
			svg {
				width: 16px;
				height: 16px;
				margin-right: 8px;
				border-radius: 4px;
			}
			.archi-label-img {
				width: 32px;
				height: 32px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 4px;
				box-sizing: border-box;
				margin-right: 6px;
				border-radius: 4px;
				background-color: #e8f4ff;
				border: 1px solid #dee1e6;
				img {
					width: 22px;
					height: 22px;
				}
				span {
					color: #409eff;
					font-weight: 550;
					font-size: 18px;
				}
			}
			i {
				width: 16px;
				height: 16px;
				font-size: 16px;
			}
			.archi-label-txt {
				flex: 1;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				height: 22px;
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #2a2f3d;
				line-height: 22px;
			}
		}
	}
}
.selected {
	.iconfont {
		color: #fff !important;
	}
	.archi-label-txt {
		color: #fff !important;
	}
	.edit {
		color: #fff;
		i {
			color: #fff;
		}
	}
	.edit:hover {
		background: rgba(255, 255, 255, 0.2) !important;
		i {
			color: #fff !important;
		}
	}
	i {
		color: #fff;
	}
}
.select {
	& {
		background: #e6e9f0;
		border-radius: 6px;
	}
}
.left-item-header {
	display: flex;
	justify-content: space-between;
	line-height: 16px;
	font-weight: 600;
	width: 100%;
	padding: 0 8px;
	box-sizing: border-box;

	.left-item-title {
		width: calc(100% - 20px);
		height: 45px;
		line-height: 45px;
		text-align: left;
		display: flex;
		align-items: center;
		margin-top: 4px;
		p {
			font-size: 14px;
			line-height: 14px;
			font-weight: 600;
			flex: 1;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}

	.left-item-action {
		cursor: pointer;
		line-height: 45px;

		i {
			margin-right: 5px;
		}
	}
}
.overflow-y-auto {
	overflow: scroll;
	overflow-x: hidden;
}
.overflow-y-auto::-webkit-scrollbar {
	width: 10px;
}
.overflow-y-auto::-webkit-scrollbar-track-piece {
	/*鼠标移动上去再显示滚动条*/
	background-color: #fff;
	/* 滚动条的背景颜色 */
	border-radius: 6px;
	/* 滚动条的圆角宽度 */
}
.overflow-y-auto::-webkit-scrollbar-thumb {
	background-color: #c0cecc;
	-webkit-box-shadow: inset 0 0 6px #fff;
}
.overflow-y-auto::-webkit-scrollbar-thumb:vertical {
	border: 2px solid #fff;
	background-color: #c0cedc;
	border-radius: 6px;
	outline: 2px solid #fff;
	outline-offset: -2px;
}
</style>
<style>
.no-boder-table .el-table th.is-leaf,
.no-boder-table .el-table td {
	border: none !important;
}

.no-boder-table .el-table--group::after,
.no-boder-table .el-table--border::after,
.no-boder-table .el-table::before {
	display: none !important;
}
</style>
