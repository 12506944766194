/*
 * @Author: zqy
 * @Date: 2021-12-07 15:06:24
 * @LastEditTime: 2021-12-07 16:38:04
 * @Description:
 */
export default function FlowPropData() {
	return {
		btnList: [
			{btn:'退回',status: -1,show: true,system:1},
			{btn:'通过',status: 1,show: true,system:1},
			{btn:'转交',status: 2,show: true,system:1},
			{btn:'重新发起',status: 3,show: true,system:1},
			{btn:'提交至退回人',status: 4,show: true,system:1},
		],
		extendDialog: false,
		objectList:[],
		objectFieldsList:[],
		designList:[],
		archId: 0,
		active: 'flowSetting',
		props: null,
		nodeName: '',
		fieldData: this.flowData.field_data,
		triggerTitle: '请选择事件触发器',
		eventType: 'trigger',
		flowConfig: {
			flow_name: this.flowData.name,
			flow_desc: this.flowData.desc,
			module: this.flowData.module || '',
			formUuid: this.flowData?.formUuid
		},
		editFields: [],
		userFromConf: [
			{value: 1, name: '本级架构'},
			{value: 2, name: '全部用户'},
		],
		targetUser: [],
		sendUser: [],
		userList: [],
		roleList: [],
		sendRoleList: [],
		flowStep: {
			userType: 7,
			userScope: false,
			userEmpty: 1,
			userWho: 1,
			sendType: 1,
			sendWho: 1,
			userFrom: 1,
			userSection:[],
			userRole:[],
			sendRole:[],
			sendFrom: 1,
			signType: 1,
			signRate: 1,
			turnTo: false,
			selectStep:false,
			attachRequired: false,
			suggestRequired: false,
			extendFields: {}
		},
		flowBranch: {
			conditionType: 1,
			userType: 1,
			condition: [],
			conditionCollect: [{field: '', judge: 'gt', value: ''}],
		},
		publisher: [],
		conditionList: [
			{name: '大于', value: 'gt'},
			{name: '小于', value: 'lt'},
			{name: '等于', value: 'eq'},
			{name: '不等于', value: 'neq'},
			{name: '大于等于', value: 'egt'},
			{name: '小于等于', value: 'elt'},
			{name: '包含任意', value: 'or'},
			{name: '完全等于', value: 'and'},
			{name: '介于之间', value: 'between'},
		],
		flowEventModal: false,
		selectedEventRows: [],
		flowSelectedEvent: [],
		stepSelectedEvent: [],
	}
}
