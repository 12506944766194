<!--
    @name: FormDesigner
    @description：FormDesigner
    @author: ZengWei
    @date: 2021-12-09 09:49
-->
<template>
	<div class="form-designer" v-loading="loading">
		<iframe
			ref="designForm"
			v-if="pathSrc"
			:src="pathSrc"
			style="width: 100%; height: 100%"
			frameborder="0"
		></iframe>
		<el-dialog
			title="表单设计预览"
			:visible.sync="dialogVisible"
      top="5vh"
			width="1200px"
			custom-class="form-designer-preview"
      append-to-body>
			<div class="domain-body">
				<PcParser
          v-if="previewData && dialogVisible"
          ref="formParser"
          :form-data="previewData"
          :hide-btn="true"/>
				<skeleton v-else></skeleton>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="dialogVisible = false">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
  import PcParser from '@/custom-component/form/newParser/PcParserNew';
	import skeleton from "@/custom-component/form/newParser/skeleton";
	import { dataInterface } from '@/apis/data/index';
	import { Dialog } from 'element-ui'

	export default {
		name: "FormDesigner",
		components: {
      PcParser,
      skeleton,
			'el-dialog': Dialog,
		},
		props: {
			path: {
				type: String,
				default: '',
			},
			formUuid: {
				type: String,
				default: '',
			},
		},
		data() {
			return {
				loading: true,
				iframeWindow: null,
				formDesignData: null,
				dialogVisible:false,
				previewData: null,
				pathSrc: '',
        timer: null
			}
		},
		created() {
      this.pathSrc = "/localForm/#/design";
			window.saveFormDesign = this.saveFormDesign.bind(this);
			window.previewPC = this.previewPC.bind(this);
		},
		mounted() {
			this.initIframeWindow();
		},
		methods: {
			initIframeWindow() {
				const _this = this;
				if (this.formUuid) {
					this.timer = setInterval(() => {
						const iframe = this.$refs.designForm?.contentWindow;
						if (typeof iframe.formDesignRecover === "function") {
							clearInterval(_this.timer);
							// let param = { formUuid: this.formUuid }
							dataInterface({},'api/form4/design/'+this.formUuid,'GET')
								.then((res) => {
									if (res && res.data && res.data.data) {
										_this.formDesignData = JSON.parse(res.data.data.json);
										if(_this.formDesignData){
											iframe.formDesignRecover(JSON.stringify(_this.formDesignData));
										}
									}
									_this.loading = false;
								})
								.catch((err) => {
									console.log(err);
									_this.loading = false;
								});
						}
					}, 100);
				} else {
					_this.loading = false;
				}
			},
			saveFormDesign(formData) {
				let data = {
					json: JSON.stringify(formData),
				};
				this.$confirm("请确认相关配置配置完成, 是否继续?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				}).then(() => {
						console.log('表单设计数据：',data)
						this.$emit('form-data',data)
					})
					.catch(() => {});
			},
			previewPC(formDesignData){
				this.previewData = formDesignData
				this.dialogVisible = true
			},
		},
    destroyed() {
      if(this.timer){
        clearInterval(this.timer)
      }
    }
  }
</script>

<style lang="less" scoped>
	.form-designer {
		height: 100%;
		background: #fff;
		text-align: left;
	}
  :deep(.form-designer-preview){
    border-radius: 8px;
    .el-dialog__header{
      padding: 12px 20px 12px;
      border-radius: 8px 8px 0 0;
       background: linear-gradient(90deg, #6292FF 0%, #DE93FF 84%, #CE92FF 91%, #C568FF 100%);
      .el-dialog__headerbtn{
        top: 15px;
        .el-dialog__close{
          color: #FFFFFF;
          font-size: 20px;
        }
      }
      .el-dialog__title{
        color: #FFFFFF;
      }
    }
    .el-dialog__body{
      padding: 0;
    }
    .el-dialog__footer{
      padding: 10px 20px;
    }

    .domain-body{
      padding: 0 15px;
      max-height: calc(100vh - 210px);
      overflow: auto;
    }
  }
</style>
