<!--
    @name: widget-cascader
    @description：widget-cascader
    @author: ZengWei
    @date: 2022-03-25 09:36
-->
<template>
  <el-cascader
    v-if="!(disabled || element.config.disabled)"
    v-model="element.value"
    :disabled="disabled || element.config.disabled"
    :options="element.config.options"
    :placeholder="element.config.placeholder"
    :clearable="element.config.clearable"
    :filterable="element.config.filterable"
  >
  </el-cascader>
  <p class="reshow-text" v-else>{{ reShowVal || '-' }}</p>
</template>

<script>
import formItemMinix from "@/custom-component/form/newParser/scripts/formItemMinix";
import {Cascader} from "element-ui";

export default {
  name: "WidgetCascader",
  components: {
    'el-cascader': Cascader
  },
  props: ['element', 'disabled', 'displayData'],
  mixins: [formItemMinix],
  data() {
    return {}
  },
  computed: {
    options() {
      const hash = {};
      const handel = (data) => {
        data.map(el => {
          hash[el.value] = el.label;
          if(Array.isArray(el.children) && el.children.length) {
            handel(el.children);
          }
        })
      }
			if(this.element && this.element.config && this.element.config.__slot__ && this.element.config.__slot__.options && Array.isArray(this.element.config.__slot__.options)) {
				handel(this.element.config.__slot__.options);
			}
      return hash;
    },
  },
  created() {
    this.loadingDynamicData(this.element);
  },
  methods: {},
}
</script>

<style scoped>

</style>
