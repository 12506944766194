<!--
    @name: detail
    @description：detail
    @author: ZengWei
    @date: 2021-12-16 17:06
-->
<template>
	<div class="pcparser-warp" v-loading="loading">
		<PcParser
			v-if="dataId && formData && editData"
			ref="formParser"
			:form-data="formData"
			:displayData="editData"
			:module-uuid="moduleUuid"
			:need-flow="!!formData.need_flow"
			form-mode="detail"
			:disabled="true"
			:hide-btn="true"
      :editFields="editData.editFields"
			:fillData="formFillData"
			:sideShow="sideShow"
      @on-close="refresh"
		>
		</PcParser>
	</div>
</template>

<script>
	import {formDesign} from "@/apis/data/workflow";
  import PcParser from "@/custom-component/form/newParser/PcParserNew";
	export default {
		name: "detail",
		components: {PcParser},
		props: ['moduleUuid','dataId','formFillData', 'sideShow'],
		data() {
			return {
				editData: null,
        formData: {},
        loading: true,
			}
		},
		created() {
			this.getDetailData();
		},
		methods: {
			getDetailData(){
        this.loading = true
        formDesign.formShow(this.moduleUuid).then(res=>{
          if(res.data.code === 200){
            const {need_flow} = res.data.data
            const designData = JSON.parse(res.data.data.json);
            formDesign.formDataDetail(this.dataId).then(res=>{
              if(res.data.code === 200){
                this.editData = res.data.data;
                designData.need_flow = !!need_flow;
                this.formData = designData
								this.$emit('need-sider-change', !!need_flow)
                this.loading = false
              }
            });
          }
        })
			},
      refresh(){
        this.editData = null;
        this.getDetailData();
      }
		},
	}
</script>
