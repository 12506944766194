/*
 * @Author: zx
 * @Date: 2022-09-09 09:28:54
 * @LastEditTime: 2023-06-27 17:17:00
 * @Description: 
 * @LastEditors: 朱廷果 1028509503@qq.com
 */
import request from '@/apis/http/saasRequest.js'
// 新增应用
export function createdApps(data) {
  return request({
    url: 'api/saas/addAppMenu',
    method: 'post',
    data
  })
}
// 编辑应用
export function editApps(data) {
  return request({
    url: 'api/saas/updateAppMenu',
    method: 'POST',
    data
  })
}
// 改变排序
export function saasMenuSort(data) {
  return request({
    url: 'api/saas/saasMenuSort',
    method: 'POST',
    data
  })
}

// 编辑菜单详情
export function appsDetail(id) {
  return request({
    url: `api/saas/admin/menu/${id}`,
    method: 'get',
  })
}

//elt详细
export function etlList() {
  return request({
    url: '/api/etl/etlList',
    method: 'get',
  })
}

// 改变排序
export function dragType(data) {
  return request({
    url: 'api/saas/admin/dragType',
    method: 'POST',
    data
  })
}