<!--
 * @Author: zx
 * @Date: 2022-08-30 11:35:58
 * @LastEditTime: 2022-11-02 11:26:34
 * @Description: 
 * @LastEditors: 朱廷果 1028509503@qq.com
-->
<template>
  <div class="archi-login">
    <SelectArchi :menuTreeData="menuTreeData" @jumpDept="jumpDept" :hasDept='hasDept'/>
    <UserActions @jumpUserInfo='jumpUserInfo'/>
  </div>
</template>
<script>
import SelectArchi from './SelectArchi'
import UserActions from './UserActions'
export default {
  components:{
    SelectArchi, // 架构选择
    UserActions, // 用户头像操作
  },
  props:{
    menuTreeData: {
      type: Array,
      default: () => {
        return []
      }
    },
    hasDept:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods:{
    jumpDept() {
      this.$emit('jumpDept')
    },
    jumpUserInfo(){
      this.$emit('jumpUserInfo')
    }
  }
}
</script>
<style lang='less' scoped>
  .archi-login {
    width: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
</style>