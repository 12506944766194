<!--
 * @Description: 组件盒子
 * @Author: luocheng
 * @Date: 2021-08-18 11:57:07
 * @LastEditors: luocheng
 * @LastEditTime: 2022-10-11 10:25:14
-->
<template>
  <el-col class="component-box"
    :style="getBoxStyle(element)"
    :data-id="element.id"
    :span="element.span !== undefined ? +element.span : -1"
    v-if="element.statusConfig && element.statusConfig.isShow
      && judgingArchiAPermission(element.archiLimit, element.authConfig && element.authConfig.list)
			&& getUILogicStatus()
    "
  >
    <component
      :is="element.component"
      :element="element"
      :containerData="containerData"
      :style="getComStyle(element)"
      :pageId="pageId"
    >
    </component>
  </el-col>
</template>

<script>
import { Col } from 'element-ui';
import { getStyle } from '@/utils/style';

// 注册组件
const componentsList = require.context('@/custom-component', true, /\.+vue$/);
const customComponents = {};
componentsList.keys().forEach(fileName => {
	let name = fileName.replace(/.vue/, '');
	name = name.replace(/\.\//, '');
	name = name.split('/')[1];
	customComponents[name] = componentsList(fileName).default;
});
export default {
  name: 'ComponentBox',
  props: {
    // 组件对象
    element: {
      type: Object,
      default: () => {},
      required: true
    },
    // 容器数据
    containerData: {
      type: [Object, Array],
      required: false
    },
    // 页面样式配置
    pageId: {
      type: Number,
      required: true
    }
  },
  components: {
    ...customComponents,
    'el-col': Col
  },
  methods: {
    /**
		 * @desc: 根据ID在数组中获取组件
		 * @param {Array} arr 组件列表
		 * @param {String} id 组件ID
		 * @return {Object} 组件对象
		 */
		getChildComponent(arr, id) {
			if (!arr || !Array.isArray(arr) || !arr.length) return null;
			let result = null;
			for (let i = 0; i < arr.length; i++) {
				if (arr[i].id === id) {
					result = arr[i];
					break;
				}
				if (arr[i].children && arr[i].children.length) {
					result = this.getChildComponent(arr[i].children, id);
					if (result) {
						break;
					}
				}
			}
			return result;
		},
    /**
     * @desc: 组件盒型样式
     * @param {Object} element 组件对象
     */
    getBoxStyle(element) {
      if (element.component === 'CommonModel' || element.component==='ConsoleBimModel') return;
      const { style, specialStyle } = element;
			if (!style) return {};
			let exculdesArr = ['top', 'left', 'rotate',
        'overflowY', 'overflowX'
      ];
      let result = getStyle(style, exculdesArr);
      if (this.element.span !== undefined && +this.element.span !== -1) {
        result.width = '';
      }
      // 特殊样式
      if (specialStyle.isCollector) {
        // 收集者
        result.flex = 1;
      }
      result.justifyContent = specialStyle.justifyContent;
			if (specialStyle.isAbsolute) {
				result.position = 'absolute';
				const { absoluteTop = 0, absoluteLeft = 0, zIndex = 10 } = specialStyle;
				result.top = isNaN(+absoluteTop) ? absoluteTop : `${absoluteTop}px` ;
				result.left = isNaN(+absoluteLeft) ? absoluteLeft : `${absoluteLeft}px`;
				result.zIndex = isNaN(+zIndex) ? 10 : zIndex;
			}
			return result;
		},
    /**
     * @desc: 组件样式
     * @param {Object} element 组件对象
     */
    getComStyle(element) {
      if (element.component === 'CommonModel' || element.component==='ConsoleBimModel') return;
      const { style } = element;
      // 基础样式
			if (!style) return {};
			let exculdesArr = ['top', 'left', 'rotate', 'paddingLeft', 'paddingRight', 'paddingTop', 'paddingBottom',
        'marginTop', 'marginBottom', 'marginLeft', 'marginRight', 'borderWidth',
				'borderStyle',
				'borderColor'
      ];
      let result = getStyle(style, exculdesArr);
      if (this.element.span !== undefined && +this.element.span !== -1) {
        result.width = '100%';
      }
      if(element.specialStyle.flexDirection) {
        // 轴方向
        result.flexDirection = element.specialStyle.flexDirection;
        result.flexWrap = element.specialStyle.flexWrap ? 'wrap' : 'nowrap';
      }
			return result;
		}
  }
}
</script>

<style lang="less" scoped>
.component-box{
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
}
</style>