<!--
 * @Author: zqy
 * @Date: 2021-11-01 16:08:28
 * @LastEditTime: 2022-06-08 14:22:20
 * @Description: 日历选择组件，返回选中的日期， checkDate：默认选中的值
-->
<template>
    <div class="date-picker">
        <div class="header"> 
            <p @click="left"><i class="iconfont iconc-chevron-left"></i></p>
            <p @click="middle">{{selectTitle}}</p>
            <p @click="right"><i class="iconfont iconxiangyoujiantou2"></i></p>
        </div>
        <div class="content">
            <template v-if="showFlag=='day'">
                <van-row type="flex" justify="center">
                    <van-col span="3" class="week-name" v-for="(name,index) in weekNames" :key="'week'+index">
                        <p>{{name}}</p>
                    </van-col>
                </van-row>
                <van-row type="flex" justify="center" v-for="(item,index) in calendatArr" :key="'day'+index">
                    <van-col @click="checkDateFun(sub)" span="3" class="day-name" v-for="(sub,index) in item" :key="'sub_day'+index">
                        <p :class="['day-name-p',{ circle:sub.date==today, checkedCircle:sub.date==selectDate }]" v-show="sub.month==curMonth">{{sub.day}}</p>
                    </van-col>
                </van-row>
            </template>
            <template v-else>
                <div class="month">
                    <van-row type="flex" justify="center" style="padding: 16px 0;">
                        <van-col :style="todayMonth==month.key && todayYear==curYear?'background-color: #2196f3 !important;color:#fff;':''" 
                            @click="changeCurMonth(month)" class="month-name" span="8" v-for="(month,index) in monthArr" :key="'month'+index">
                            {{month.name}}
                        </van-col>
                    </van-row>
                </div>
            </template>
        </div>
    </div>
</template>
<script>
import { reactive,toRefs,onMounted, defineComponent } from 'vue'
import dayjs from "dayjs"
import isoWeek from "dayjs/plugin/isoWeek";
dayjs.extend(isoWeek);
import { Row, Col } from 'vant'

export default defineComponent({
    props:{
        checkDate:{
            type:String,
        }
    },
    components: {
      'van-row': Row,
      'van-col': Col
    },
    emits:['check-date'],
    setup(props,ctx){
        let state=reactive({
            selectTitle:'2021年10月1日',
            weekNames:['日','一','二','三','四','五','六'],
            curYear:'',
            curMonth:'',
            curDay:'',
            showFlag:'day',//当前选择标志，day选择日期，month选择月份
            /* 保存今天的年月日 */
            todayYear:'',
            todayMonth:'',
            todayDay:'',
            today:'',
            monthArr:[
                {key:'01',name:'1月'},
                {key:'02',name:'2月'},
                {key:'03',name:'3月'},
                {key:'04',name:'4月'},
                {key:'05',name:'5月'},
                {key:'06',name:'6月'},
                {key:'07',name:'7月'},
                {key:'08',name:'8月'},
                {key:'09',name:'9月'},
                {key:'10',name:'10月'},
                {key:'11',name:'11月'},
                {key:'12',name:'12月'},
            ],
            calendatArr:[],//当前月所有日期数组
            selectDate:'',
        })

        /* 日期赋予初值 */
        state.today = dayjs().format('YYYY-MM-DD')
        state.curYear = dayjs().format('YYYY')
        state.todayYear = dayjs().format('YYYY')
        state.curMonth = dayjs().format('MM')
        state.todayMonth = dayjs().format('MM')
        state.curDay = dayjs().format('DD')
        state.todayDay = dayjs().format('DD')

        let methods={
            /*  showFlag==month 时，减少一年每次
                showFlag==day 时，减少一月
            */
            left(){
                if(state.showFlag=="month"){
                    state.curYear = dayjs(dayjs(state.curYear+state.curMonth+state.curDay).valueOf() - 31536000000).format('YYYY') 
                }else{
                    let month = state.curMonth - 1
                    if(month<1){
                        state.curYear = state.curYear - 1
                        state.curMonth = 12
                    }else{
                        state.curMonth = month
                    }
                    if(parseInt(month)<10 && month!=0){
                        state.curMonth = '0' + state.curMonth
                    }
                }
                methods.getWeekDayArr()
                methods.changeSelectTitle()
            },
             /*  showFlag==month 时，增加一年每次
                showFlag==day 时，增加一月
            */
            right(){
                if(state.showFlag=="month"){
                    state.curYear = dayjs(dayjs(state.curYear+state.curMonth+state.curDay).valueOf() + 31536000000).format('YYYY') 
                }else{
                    let month = parseInt(state.curMonth) + 1
                    if(month>12){
                        state.curYear = parseInt(state.curYear) + 1
                        state.curMonth = 1
                    }else{
                        state.curMonth = month
                    }
                }
                
                methods.getWeekDayArr()
                methods.changeSelectTitle()
            },
            /* 切换选择年月 或年月日 */
            middle(){
                if(state.showFlag=='day'){
                    state.showFlag='month'
                }else{
                    state.showFlag='day'
                }
                // 切换title
                methods.changeSelectTitle()
            },
            changeSelectTitle(){
                // let day = state.curDay
                let month = state.curMonth
                // if(state.curDay<10){
                //     day = state.curDay.substr(1,1)
                // }
                if(state.curMonth<10 && JSON.stringify(state.curMonth).length>1){
                    month = state.curMonth.substr(1,1)
                }
                if(state.showFlag=='day'){
                    state.selectTitle = `${state.curYear}年${month}月`
                }else{
                    state.selectTitle = `${state.curYear}年`
                }
            },
            /* 获取选中月有多少天 */
            getMonthDays(){
            },
            /* 获取当前选中日期是周几 */
            getWeek(){
            },
            /* 获取选中年月的全月日期 */
            getWeekDayArr(){        
                let date=state.curYear +'-'+ state.curMonth +'-' + '01'
                // 获取当月的第一天
                let currentFirstDay = date
                
                // 获取第一天是星期几
                let weekDay = dayjs(date).isoWeekday();
                
                // 用当月的第一天减去 周几前面几天 这样就能得到上个月开始的天数
                let startTime = dayjs(currentFirstDay).valueOf() - (weekDay - 1) * 24 * 60 * 60 * 1000;
                
                // 然后得到所有的日期
                let calendatArr=[]
                for (let i = 0; i < 42; i++) {
                    let day = dayjs(new Date(startTime + i * 24 * 60 * 60 * 1000)).format('DD')
                    calendatArr.push({
                        day: day<10?day.substr(1,1)+ '日' :day+ '日' ,
                        year: dayjs(new Date(startTime + i * 24 * 60 * 60 * 1000)).format('YYYY') ,
                        month: dayjs(new Date(startTime + i * 24 * 60 * 60 * 1000)).format('MM') ,
                        date: dayjs(new Date(startTime + i * 24 * 60 * 60 * 1000)).format('YYYY-MM-DD') 
                    })
                }
                state.calendatArr=[]
                for(var i=0;i<calendatArr.length;i+=7){
                    state.calendatArr.push(calendatArr.slice(i,i+7));
                }
            },
            /* 选择月 */
            changeCurMonth({key}){
                state.curMonth = key
                methods.middle()
                methods.getWeekDayArr()
            },
            checkDateFun(item){
                state.selectDate = item.date
                // 返回选中的日期
                ctx.emit('check-date',item.date)
            }
        }
        onMounted(()=>{
            methods.changeSelectTitle()
            methods.getWeekDayArr()

            // 时间回显处理
            if(props.checkDate){
                state.selectDate = props.checkDate
            }
        })
        return {
            ...toRefs(state),...props,
            ...methods,
        }
    }

})
</script>
<style lang="less" scoped>
.date-picker{
    background: #fff;
    .header{
        display: flex;
        justify-content: space-around;
        padding: 12px 16px;
        p{
            font-weight: bolder;
        }
    }
    .content{
        .circle{
            border: 1px solid #82b1ff;
            border-radius: 50%;
        }
        .checkedCircle{
            background-color: #2196f3;
            color: #fff;
            border: 1px solid #2196f3;
            border-radius: 50%;
        }
        .week-name{
            color: rgba(0,0,0,0.38);
            font-size: 12px;
            padding: 8px 0;
            text-align: center;
        }
        .day-name{
            font-size: 12px;
            text-align: center;
        }
        .day-name-p{
            width: 32px;
            height: 32px;
            line-height: 32px;
            text-align: center;
        }
        .month{
            .month-name{
                text-align: center;
                padding: 10px 0;
                font-size: 12px;
            }
        }
    }
}
</style>