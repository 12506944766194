/*
 * @Description: 混入方法
 * @Author: luocheng
 * @Date: 2022-03-07 15:03:01
 * @LastEditors: luocheng
 * @LastEditTime: 2022-09-23 15:54:08
 */
import { getComponentById, getQueryValue } from '@/utils/tools';
import { mapState } from 'vuex';

export default {
	props: {
		// 是否在组合内
    isGroup: {
			type: Boolean,
		},
    // 组合内组件列表
		groupComponents: {
			type: Array,
			default: () => []
		}
	},
	computed: {
    ...mapState(['componentData'])
  },
  methods: {
    /**
		 * @desc: 初始化获取参数
		 */
		initParams() {
			const { paramsConfig } = this.element.database;
			if (!paramsConfig || !paramsConfig.length) return {};
			let search = [];
			const param = {};
			paramsConfig.forEach((ele) => {
				// 暂时归类了param ,特殊的search暂时无法处理？？？
				let originComponent = getComponentById(this.componentData, ele.componentId);
				if (!originComponent && this.isGroup && this.groupComponents.length) {
					originComponent = getComponentById(this.groupComponents, ele.componentId);
				}
				if (ele.key !== 'search') {
					if (ele.sourceType === 'url') {
						// url参数
						this.$set(param, ele.key, getQueryValue(ele.urlParamsKey));
					} else if (originComponent && originComponent.resolveData) {
						this.$set(param, ele.key, originComponent && originComponent.resolveData[ele.paramKey]
						);
					}
				} else {
					if (originComponent && originComponent.resolveData && Array.isArray(originComponent.resolveData)) {
						search = search.concat(originComponent.resolveData);
					}
				}
			});
			return { search, param };
		},
    /**
     * @desc: 获取操作参数
     * @return {*}
     */
    /**
     * @desc: 获取操作参数
     * @param {Array} paramsConfig
     */
    getQuery(paramsConfig = []) {
      // 是否可以请求
      let canPost = true;
      if (!paramsConfig || !paramsConfig.length)
        return {
          canPost
        };
      let search = [];
      const param = {};
      for (let i = 0; i < paramsConfig.length; i++) {
        const {
          paramType, code, uuid, isRequired, originType, componentId, urlKey, ruleType, componentKey, fixedValue
        } = paramsConfig[i];
        let paramsVal = '';
        if (originType === 'url') {
          // url参数
          paramsVal = getQueryValue(urlKey);
          if (isRequired && !paramsVal) {
            canPost = false;
            break;
          }
        } else if (originType === 'component') {
          // 组件参数
          let originComponent = getComponentById(this.componentData, componentId);
          if (!originComponent && this.isGroup && this.groupComponents.length) {
            originComponent = getComponentById(this.groupComponents, componentId);
          }
          if(!originComponent) {
            console.error(`查询的组件id不存在！， 请检测组件ID:${componentId}`)
          }
          if (isRequired && (!originComponent || !originComponent.resolveData)) {
            canPost = false;
            break;
          }
          if (componentKey) {
            paramsVal = originComponent?.resolveData?.[componentKey];
          } else {
            paramsVal = originComponent?.resolveData;
          }
        } else if (originType === 'fixed') {
          // 固定值
          paramsVal = fixedValue;
        }
        if (paramType === 'param') {
          // 普通请求参数
          // 写入params
          if (code) {
            this.$set(param, code, paramsVal);
          } else if (uuid) {
            this.$set(param, uuid, paramsVal);
          } else {
            canPost = false;
            // console.log('参数配置出错')
            break;
          }
        } else if (paramType === 'search') {
          if (Array.isArray(paramsVal) && this.validSearch(paramsVal)) {
            search = search.concat(paramsVal);
          } else if (uuid) {
            search.push({
              field_uuid: uuid,
              ruleType,
              value: paramsVal
            });
          } else if (code) {
            search = search.concat({
              code,
              ruleType,
              value: paramsVal
            });
          } else {
            canPost = false;
            break;
          }
        }
      }
      return { search, param, canPost };
    },
		/**
     * @desc: 是否为合法的search
     * @param {Array} arr
     */
		validSearch(arr) {
      if (!arr || !Array.isArray(arr) || !arr.length)
        return true;
      for (let i = 0, len = arr.length; i < len; i++) {
        const item = arr[i];
        if (Object.prototype.toString.call(item) !== ['object Object']) {
          return false;
        }
      }
      return true;
    },
  }
}