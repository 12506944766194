<!--
 * @Author: zx
 * @Date: 2022-08-30 11:39:43
 * @LastEditTime: 2022-10-31 16:18:04
 * @Description:
 * @LastEditors: 朱廷果 1028509503@qq.com
-->
<template>
	<div class="select-archi">
		<!-- 自定义实现 -->
		<section class="archi-desc-box" @click="onToggleShow">
      <div class='archi-icon'>
        <i class="iconfont" :class="archiIcon" />
      </div>
			<span class="archi-name">{{ saasTargetArchi && (saasTargetArchi.short_name || saasTargetArchi.name) }}</span>
			<i :class="{'select-icon':!showSelect}" class=' iconfont iconxiangshangjiantou1 select-icon-transition'></i>
		</section>
		<!-- 内容 -->
		<article class="archi-select-menu"
			:style="{
				height: showSelect ? '460px' : '0px'
			}"
			v-loading="loading"
		>
			<template v-if="showSelect">
				<!-- 架构菜单 -->
				<section class="archi-nav-box">
					<!-- <h4 class="title" @click="entrySwitch">
						<i class="el-icon-back"></i>
						<span>入口切换</span>
					</h4> -->
					<ul class="archi-nav-list">
						<li class="archi-nav-item" v-for="nav in navList" :key="nav.key" @click="onNav(nav)"
							:class="{
								'active': nav.key === currentKey
							}"
						>
							<i :class="['iconfont', nav.icon]"></i>
							<p class="archi-nav-name">{{ nav.label }}</p>
						</li>
					</ul>

          <div class='archi-nav-button' v-if='hasDept'>
            <el-button type='primary' @click='toDept'>创建组织架构</el-button>
          </div> 
          
				</section>
				<!-- 内容区域 -->
				<article class="archi-select-content"
					:class="{
						'withOutPB': currentKey === 'project'
					}"
				>
					<!-- 最近 -->
					<RecentView :historyArchi="historyArchi" :menuTreeData="menuTreeData" v-show="currentKey === 'recent'" @close="closePop()"></RecentView>
					<!-- 项目 -->
					<ProjectView v-show="currentKey === 'project'" :menuTreeData="menuTreeData" @close="closePop()"></ProjectView>
				</article>
			</template>
		</article>
		<!-- mask -->
		<div class="select-archi-mask" v-show="showSelect" @click.self="onToggleShow"></div>
	</div>
</template>
<script>
import RecentView from './selectArchiComp/recent/Index';
import ProjectView from './selectArchiComp/project/Index';
import { mapState } from 'vuex';
export default {
  components:{
		RecentView,
		ProjectView
  },
  props:{
    menuTreeData: {
      type: Array,
      default: () => {
        return []
      }
    },
    hasDept:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
			// 侧边栏
			navList: [
				{
					key: 'recent',
					label: '最近查看',
					icon: 'icontime'
				},
				{
					key: 'project',
					label: '架构',
					icon: 'iconproject'
				}
			],
			// 当前nav
			currentKey: 'recent',
			showSelect: false,
			// 最近登录
			historyArchi: {},
			loading: false,
    }
  },
  computed: {
    ...mapState(['saasTargetArchi', 'systemStyle']),
		// 架构图标
		archiIcon() {
      let arr = ['1','2','3','4']
      if(this.saasTargetArchi && this.saasTargetArchi.type && arr.includes(this.saasTargetArchi.type)){
        return 'iconzi-qiye'
      }else{
        return 'iconzi-xiangmu'
      }
		}
  },
  // created() {
  //   let routeRoles = JSON.parse(localStorage.getItem('routeRoles')) || [] ;
  //   if (routeRoles && routeRoles.length) {
  //     this.hasDept = routeRoles.some( item => {
  //       if('/org/dept'.indexOf(item) !== -1) {
  //         return true;
  //       }
  //     })
  //   }
  // },
  // mounted() {
  //   let routeRoles = JSON.parse(localStorage.getItem('routeRoles')) || [] ;
  //   if (routeRoles && routeRoles.length) {
  //     this.hasDept = routeRoles.some( item => {
  //       if('/org/dept'.indexOf(item) !== -1) {
  //         return true;
  //       }
  //     })
  //   }
  // },
  methods:{
    /**
     * @desc 跳转到组织架构
     */
    toDept(){
      // this.$router.push({ path: '/org/dept'})
			this.$emit('jumpDept')
      this.closePop()
    },
    /**
     * @desc: 切换显示
     */
		onToggleShow() {
			this.showSelect = !this.showSelect;
			this.currentKey = this.navList[0].key;
		},
		/**
		 * @desc: 点击菜单
		 * @param {Object} nav 侧边菜单对象
		 */
		onNav(nav) {
      console.log(nav)
			this.currentKey = nav.key;
			// 取消当前选中的架构预览
      this.$store.commit('setCacheArchi', null);
		},
    closePop() {
      this.showSelect = false
    }
  }
}
</script>
<style lang="less" scoped>
@height: 48px;
@partWidth: 280px;
.select-archi {
	height: auto;
	max-width: 200px;
	margin-right: 16px;
	.archi-desc-box {
    padding-right: 16px;
    border-radius: 6px;
		display: block;
		box-sizing: border-box;
		cursor: pointer;
		display: flex;
		background-color: rgb(242, 244, 247) !important;
    padding: 0px 12px;
		align-items: center;
    height: 36px;
    .archi-icon{
      color: #fff;
      width: 18px;
      height: 18px;
      background: linear-gradient(180deg, #5ADFFA 0%, #459CFF 100%);
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
    }
		.iconfont {
			line-height: 24px;
			font-size: 15px;
			font-family: PingFangSC, PingFangSC-Medium;
			font-weight: 500;
		}
		.archi-name {
			flex: 1;
			font-size: 14px;
			line-height: 24px;
			font-family: PingFangSC, PingFangSC-Medium;
			font-weight: 500;
			box-sizing: border-box;
			padding: 0 8px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
    .select-icon{
      transform: rotate(180deg);
    }
    .select-icon-transition{
      transition:all .5s;
    }
	}
	.system-first {
		background-color: transparent;
	}
	.archi-select-menu{
		position: fixed;
		right: 5px;
		background: rgb(239, 241, 246);
		z-index: 2999;
		width: 680px;
		height: 460px;
		box-sizing: border-box;
		display: flex;
    border-radius: 10px;
		// padding: 0!important;
		// box-shadow:0 2px 12px 0 rgba(0, 0, 0, .5);
		box-shadow: 0px 5px 10px -1px rgba(0, 0, 0, 8%);
		transition: all .2s;
		overflow: hidden;
		.archi-nav-box {
		width: 160px;
		box-sizing: border-box;
    padding-bottom: 10px;
    padding-top: 16px;
		display: flex;
		flex-direction: column;
		background: #fff;
    background:#eff1f6;
    border-top: 1px solid rgba(17,16,16, 0.04);
		.title {
			line-height: 22px;
			font-size: 14px;
			font-family: PingFangSC, PingFangSC-Medium;
			font-weight: 500;
			text-align: left;
			color: #161616;
			box-sizing: border-box;
			padding: 0 20px;
			margin-bottom: 20px;
			display: flex;
			cursor: pointer;
			i{
				line-height: 22px;
				margin-right: 5px;
				font-weight: bold;
			}
		}
		.archi-nav-list {
			flex: 1;
      background: #fff;
			.archi-nav-item {
				box-sizing: border-box;
				width: 100%;
				display: flex;
				height: 40px;
				padding: 9px 16px;
				cursor: pointer;
				i,
				p {
					font-size: 14px;
					font-family: PingFangSC, PingFangSC-Regular;
					font-weight: 400;
					text-align: left;
					color: #161616;
					line-height: 22px;
				}
				i {
					margin-right: 9px;
				}
				p {
					flex: 1;
				}
				&.active {
					background: linear-gradient(90deg,#ffffff, #e8f0fe);
					border-right: 4px solid #0f62fe;
					i,
					p {
						color: #0f62fe;
					}
				}
			}
		}
    .archi-nav-button{
      background:#eff1f6;
      padding-top:10px;
      :deep(.el-button){
        width: 100%;
      }
    }
	}
	.archi-select-content{
		height: 460px;
		flex: 1;
		overflow: hidden;
		background: rgb(239, 241, 246);
		box-sizing: border-box;
		padding: 16px 20px 0 20px;
		&.withOutPB{
			padding-bottom: 0;
		}
	}
	.archi-part {
		// min-width: @partWidth;
			width: @partWidth;
		height: 100%;
		box-sizing: border-box;
		padding: 16px 16px 10px 16px;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		&.archi-info-part{
		}
		&.archi-list-part {
			// display: none;
			border-right: 1px solid rgb(226, 225, 226);
		}
	}
	}
	.select-archi-mask{
		position: fixed;
		height: 100vh;
		width: 100vw;
		background: transparent;
		top: 0;
		left: 0;
		z-index: 2998;
		cursor: pointer;
	}
}
</style>
