/*
 * @Author: zx
 * @Date: 2022-08-27 14:29:20
 * @LastEditTime: 2022-10-31 16:09:42
 * @Description: 
 * @LastEditors: 朱廷果 1028509503@qq.com
 */
import request from '@/apis/http/saasRequest.js'
export function mapiRequest(data) {
  return request({
    url: 'api/mapi',
    method: 'post',
    data
  })
}
