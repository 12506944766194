<!--
 * @Author: Shiltin
 * @Date: 2021-10-20 17:52:57
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-01-05 11:01:58
 * @Description:
-->
<template>
  <div style="height:100%;width:100%;">
    <div v-if="!inpage">
      <el-popover
        :placement="positionType"
        popper-class="pover"
        trigger="click"
        :width="width"
      >
        <div
          v-loading="showTree"
          class="pover-content"
          :style="'height:' + maxHeight + 'px'"
        >
          <div class="content-left">
            <div class="content-left-search">
              <el-input v-model="searchValue" placeholder="请输入人员名字" />
            </div>
            <div class="content-left-tree scroller">
              <el-tree
                ref="treeRef"
                :data="dataInfo"
                :default-checked-keys="showTreeKeys"
                :default-expand-all="defaultExpand"
                :filter-node-method="filterNode"
                node-key="nodeKey"
                :this="{
                  label: 'name',
                  children: 'children',
                }"
                :show-checkbox="isCheckType === 'cascaderCheckbox' ? true : false"
                @check-change="handleCheckChange"
                @node-click="handleNodeClick"
              >
                <template #default="{ node }">
                  <span class="custom-tree-node">
                    <el-tooltip
                      v-if="node.label&&(node.label+'').length>8"
                      class="item"
                      :content="node.label"
                      effect="dark"
                      placement="top-start"
                    >
                      <span>{{ node.label }}</span>
                    </el-tooltip>
                    <span v-else>{{ node.label }}</span>
                  </span>
                </template>
              </el-tree>
            </div>
          </div>
          <div class="content-right">
            <div class="content-right-top">
              <span>
                已选中
                <span class="content-right-top-number">
                  {{ selectUserList.length }}
                </span>
                人
              </span>
              <span
                style="float: right; color: #0076ff; cursor: pointer"
                @click="clearChoose(1)"
              >
                清空
              </span>
            </div>
            <div class="content-right-choose">
              <div
                v-for="(item, index) in selectUserList"
                :key="index"
                class="content-show"
              >
                <div class="content-show-name">
                  <el-tooltip
                    class="item"
                    :content="item.name + getShowName(item)"
                    effect="dark"
                    placement="top-start"
                  >
                    <p>{{ item.name }}{{ getShowName(item) }}</p>
                  </el-tooltip>
                  {{ item.companyName ? item.companyName : item.projectName }}
                </div>
                <div class="content-show-delete">
                  <i
                    class="iconfont iconbimgis_guanbi"
                    @click.stop="deleteClick(item)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <template #reference>
          <div v-if="showValue" class="show-value">
            {{ showValue }}
          </div>
          <div v-else class="show-value" style="color: #999">
            请选择
          </div>
        </template>
      </el-popover>
    </div>
    <div v-if="inpage" class="pover" style="width: 100%; height: 100%">
      <!-- <div class="show-value" style="height: 30px; line-height: 30px">
        <span>{{ showValue }}</span>
      </div> -->
      <div v-loading="showTree" class="pover-content">
        <div class="content-left">
          <div class="content-left-search">
            <el-input v-model="searchValue" placeholder="请输入人员名字" />
          </div>
          <div class="content-left-tree scroller">
            <el-tree
              ref="treeRef"
              :data="dataInfo"
              :default-checked-keys="showTreeKeys"
              :default-expand-all="defaultExpand"
              :filter-node-method="filterNode"
              node-key="nodeKey"
              :this="{
                label: 'name',
                children: 'children',
              }"
              :show-checkbox="isCheckType === 'cascaderCheckbox' ? true : false"
              @check-change="handleCheckChange"
              @node-click="handleNodeClick"
            />
          </div>
        </div>
        <div class="content-right">
          <div class="content-right-top">
            <span>
              已选中
              <span class="content-right-top-number">
                {{ selectUserList.length }}
              </span>
              人
            </span>
            <span
              style="float: right; color: #0076ff; cursor: pointer"
              @click="clearChoose(1)"
            >
              清空
            </span>
          </div>
          <div class="content-right-choose">
            <div
              v-for="(item, index) in selectUserList"
              :key="index"
              class="content-show"
            >
              <div class="content-show-name">
                <el-tooltip
                  class="item"
                  :content="item.name + '/' + getShowName(item)"
                  effect="dark"
                  placement="top-start"
                >
                  <p>{{ item.name }}{{ getShowName(item) }}</p>
                </el-tooltip>
                {{ item.companyName ? item.companyName : item.projectName }}
              </div>
              <div class="content-show-delete">
                <i
                  class="iconfont iconbimgis_guanbi"
                  @click.stop="deleteClick(item)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {Popover,Tooltip,Tree,Input} from 'element-ui'
export default{
  name: '',
  components: {'el-popover':Popover,
		'el-tooltip':Tooltip,
		'el-tree':Tree,
		'el-input':Input
	},
  props: {
    dataInfo: {
      type: Array,
      default: () => []
    },
    showList: {
      type: Array,
      default: () => []
    },
    inpage: {
      type: Boolean,
      default: false
    },
    // cascaderCheck 级联单选
    // cascaderCheckbox 级联多选
    isCheckType: {
      type: String,
      default: 'cascaderCheckbox'
    },
    positionType: {
      type: String,
      default: 'bottom-start'
    },
    /* 弹出框宽度 */
    width: {
      type: Number,
      default: 450
    },
    /* 弹出框最大高度 */
    maxHeight: {
      type: Number,
      default: 300
    },
    /* 默认展开 */
    defaultExpand: {
      type: Boolean,
      default: true
    },
    /* 展示内容 */
    showTree: {
      type: Boolean,
      default: true
    }
  },
  data(){
    return{
      treeRef: null,
      showTreeKeys: [],
      selectUserList: [],
      searchValue: '',
      firstIn: false
    }
  },
  computed:{
    /* 计算当前选择的人员回显 */
    showValue(){
      let str = ''
      this.selectUserList.forEach((item) => {
        if (!str) {
          str += item.name
        } else {
          str += ',' + item.name
        }
      })
      return str
    }
  },
  watch:{
    searchValue:{
      handler(val){
        this.$refs.treeRef.filter(val)
      },
      deep:true
    },
    dataInfo:{
      handler(val){
        this.showTreeInfo(this.showList, val)
      },
      deep:true
    },
    showList:{
      handler(val){
        this.showTreeInfo(val)
      },
      deep:true
    },
  },
  methods: {
    /* 当前选择的nodekey数组 */
    handleCheckChange(){
      /* 级联单选 */
      if (this.isCheckType === 'cascaderCheck') {
        this.returnShowTreeInfo(this.showTreeKeys)
        return
      }
      // const treeRef = ref(this.treeRef)
      const keys = this.$refs.treeRef.getCheckedKeys()
      const arr = keys.filter((item) => item > 0)
      this.showTreeKeys = arr
      this.returnShowTreeInfo(arr)
    },
    /* 级联单选 */
    handleNodeClick(data){
      if (this.isCheckType === 'cascaderCheckbox') {
        return
      }
      if (data.nodeKey) {
        this.showTreeKeys = []
        this.showTreeKeys = [data.nodeKey]
      }
      this.returnShowTreeInfo(this.showTreeKeys)
    },
    /* 当前选择的人员数组数据（item包含所有信息） */
    returnShowTreeInfo(list){
      const selectArr = []
      list.forEach((element) => {
        const getKey = (array) => {
          array.forEach((itemy) => {
            if (itemy.users && itemy.users.length >= 0) {
              if (itemy.nodeKey === element) {
                selectArr.push(itemy)
              }
            } else if (itemy.children && itemy.children.length) {
              getKey(itemy.children)
            }
          })
        }
        getKey(this.dataInfo)
      })
      this.selectUserList = selectArr
      this.calculation()
      this.$emit('on-select-change', selectArr)
    },
    /* 传入回显的showList 获取勾选数组  进行默认回显 */
    showTreeInfo(list){
      if (!list || !this.dataInfo || this.firstIn) {
        return
      }
      this.firstIn = true
      let showArr = []
      const showTree = (arr) => {
        arr.forEach((item) => {
          list.forEach((element) => {
            if (item.id === element.id) {
              showArr.push(item.nodeKey)
            } else if (item.children && item.children.length) {
              showTree(item.children)
            }
          })
        })
      }
      showTree(this.dataInfo)
      showArr = showArr.filter((item) => !!item)
      this.showTreeKeys = showArr
      this.returnShowTreeInfo(this.showTreeKeys)
      this.calculation()
    },
    /* 删除单个勾选 */
    deleteClick(data){
      for (let index = 0; index < this.showTreeKeys.length; index++) {
        if (this.showTreeKeys[index] === data.nodeKey) {
          this.showTreeKeys.splice(index, 1)
        }
      }
      this.returnShowTreeInfo(this.showTreeKeys)
      // const treeRef = ref(this.treeRef)
      this.$refs.treeRef.setCheckedKeys(this.showTreeKeys)
    },
    /* 清空所有勾选 */
    clearChoose(){
      if (this.isCheckType === 'cascaderCheck') {
        // const treeRef = ref(this.treeRef)
        this.$refs.treeRef.setCurrentKey(null)
        this.showTreeKeys = []
        this.returnShowTreeInfo(this.showTreeKeys)
        return
      }
      // const treeRef = ref(this.treeRef)
      this.$refs.treeRef.setCheckedKeys([])
    },
    /* tree组件的过滤规则 */
    filterNode(value, data){
      if (!value) return true
      return !!(data.name && data.name.indexOf(value) !== -1)
    },
    /* 计算选择进度 */
    calculation(){
      this.dataInfo.forEach((itemy) => {
        let choose = 0
        const total = itemy.children.length
        itemy.children.forEach((item) => {
          this.showTreeKeys.forEach((element) => {
            if (element === item.nodeKey) {
              choose += 1
            }
          })
        })
        itemy.label = itemy.name + '【' + choose + '/' + total + '】'
      })
    },
    getShowName(item){
      if (item.departmentName || item.externalName) {
        return item.departmentName
          ? '(' + item.departmentName + ')'
          : '(' + item.externalName + ')'
            ? '(' + item.externalName + ')'
            : ''
      } else {
        return ''
      }
    }
  },
  mounted () {
    this.showTreeInfo(this.showList)
  }
}
</script>
<style lang="less" scoped>
  .show-value {
    width: 100%;
    height: 32px;
    line-height: 32px;
    border: 1px solid #dcdfe6;
    border-radius: 2px;
    padding: 0 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .pover {
    width: 428px;
    height: 100%;
    .pover-content {
      width: 100%;
      height: 100%;
      display: flex;
      overflow-y: auto;
      .content-left {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        .content-left-search {
          height: 50px;
          padding: 5px 10px;
        }
        .content-left-tree {
          padding: 0 10px;
          flex: 1;
          overflow-y: auto;
          overflow-x: hidden;
        }
      }
      .content-right {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        .content-right-top {
          height: 50px;
          line-height: 40px;
          padding: 0 10px;
          .content-right-top-number {
            color: #0076ff;
          }
        }
        .content-right-choose {
          flex: 1;
          padding: 0 10px;
          overflow-y: auto;
          .content-show {
            width: 100%;
            height: 50px;
            display: flex;
            .content-show-name {
              width: 88%;
              height: 50px;
              p {
                height: 25px;
                line-height: 25px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              p:nth-child(2) {
                font-size: 12px;
                color: #999;
              }
            }
            .content-show-delete {
              width: 10%;
              height: 50px;
              line-height: 50px;
              text-align: center;
            }
          }
        }
      }
    }
  }
</style>
