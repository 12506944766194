<!--
 * @Description: dsd
 * @Author: luocheng
 * @Date: 2021-12-15 17:39:11
 * @LastEditors: luocheng
 * @LastEditTime: 2022-10-12 11:50:52
-->
<template>
	<div v-if="showIcon">
		<!-- 中控台图标样式 -->
		<i class="console-icon"  v-if="editorType === 'console'" :class="classList"></i>
		<div class="base-icon" v-else>
			<p  :class="['iconfont', propValue ]" v-if="propValue.indexOf('el-') > -1 || type === 'class'"></p>
			<svg aria-hidden="true" class="iconfont-svg" v-else>
				<use :xlink:href="'#' + propValue" />
			</svg>
		</div>
	</div>
</template>

<script>
export default {
	name: 'BaseIcon',
	props: {
		// 绑定的值
		element: {
			type: Object,
			default: () => {},
			required: true
		},
		fullData: {
			type: Object
		}
	},
  computed: {
		propValue() {
			return this.element.propValue || '';
		},
		showIcon() {
      const { propValue } = this.element;
			return  propValue && typeof propValue === 'string';
		},
		editorType() {
			return sessionStorage.getItem('editorType')
		},
		classList() {
      const {propValue} = this.element;
      if (!propValue || typeof propValue !== 'string') return [];
      const result = [];
      result.push(propValue);
      if (propValue.indexOf('el-icon-') === -1) {
        result.push('iconfont')
      }
      return result;
    },
		// 渲染类型
		type() {
			return this.element?.statusConfig?.type || 'auto';
		}
  },
	methods:{
	},
	mounted() {
	}
};
</script>

<style lang="less" scoped>
.base-icon {
	width: 100%;
	.iconfont-svg{
		height: 100%;
	}
	.iconfont {
		font-size: inherit;
	}
}
.console-icon {
	background-color:inherit;
	color:inherit;
	font-size:inherit;
}
</style>