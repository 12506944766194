<template>
    <div class="viewPointTree-wrap">
        <el-collapse>
            <el-collapse-item v-for="item in viewPointList" :key="item.id" :name="item.id">
                <template #title>
                    <div class="horizontal-flex tree-group-wrap">
                        <div class="tree-group-wrap__title">{{item.name}}</div>
                        <el-dropdown trigger="click"
                                     hide-on-click
                                     class="tree-group-wrap__btns"
                                     @command="onClickDropdown($event, item)"
                        >
                            <a class="iconfont iconbimgis_gengduo" @click.stop></a>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="renameGroup">重命名</el-dropdown-item>
                                <el-dropdown-item command="deleteGroup">删除组</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </template>

                <div v-for="child in item.children" :key="child.id" class="horizontal-flex tree-obj-wrap">
                    <img class="tree-obj-wrap__img" :src="child.img" @click="onClickViewPoint(child)">
                    <div class="vertical-flex tree-obj-wrap__content">
                        <span class="tree-obj-wrap__title">{{ child.name }}</span>

                        <div class="horizontal-flex">
                            <div class="tree-obj-wrap__tip">{{ child.created_at.substring(0, 10) }}</div>
                            <el-tooltip content="更新视点" placement="bottom">
                                <a class="tree-obj-wrap__btns iconfont icongis_huifuchushi" @click="onClickDropdown('refresh', child)"></a>
                            </el-tooltip>
                            <el-dropdown trigger="click"
                                         hide-on-click
                                         class="tree-obj-wrap__btns"
                                         @command="onClickDropdown($event, child)"
                            >
                                <a class="iconfont iconbimgis_gengduo" @click.stop></a>
                                <el-dropdown-menu slot="dropdown">
                                    <!--                                        <el-dropdown-item command="default">设为默认</el-dropdown-item>-->
                                    <!--                                        <el-dropdown-item command="share">分享视点</el-dropdown-item>-->
                                    <el-dropdown-item command="view">查看视点</el-dropdown-item>
                                    <el-dropdown-item command="rename">重命名视点</el-dropdown-item>
                                    <el-dropdown-item command="delete">删除视点</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                    </div>
                </div>
            </el-collapse-item>
        </el-collapse>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import eventBus from '@/plugins/eventBus';
    import { dataInterface } from '@/apis/data/index';
    import { Tooltip, Dropdown, DropdownMenu, DropdownItem } from "element-ui";


    const DataBaseInfo = {
        Group: {
            object_uuid: 'object63a957e49579b',
            view_uuid: {
                'all': 'view63a95806c9d19',
                'user_id': 'view63acf85b00d84',
                'archi_id': 'view63acf85e22389'
            }
        },
        ViewPoint: {
            object_uuid: 'object63a15d09d4f7f',
            view_uuid:{
                'all': 'view63a15da09d7b4',
                'user_id': 'view63acf8fcf12ad',
                'archi_id': 'view63acf9055957e'
            }
        }
    };

    export default {
        name: "ViewPoint",
        components: {
            "el-tooltip" : Tooltip,
            "el-dropdown" : Dropdown,
            "el-dropdown-menu" : DropdownMenu,
            "el-dropdown-item" : DropdownItem
        },
        props: {
            element: {
                type: Object,
                required: true,
                default: () => {
                    return {
                    }
                }
            },
            // 是否在组合内
            isGroup: {
                type: Boolean,
            },
            // 组合内组件列表
            groupComponents: {
                type: Array,
                default: () => []
            },
            bindViewerId: {
                type: String,
            },
        },
        data(){
            return {
                viewType: 'all',
                viewPointList: []
            }
        },
        computed: {
            ...mapState(["curComponent"]),
        },
        mounted(){
            this.getViewPointData();
        },
        methods: {
            async getViewPointData(bindId = this.bindViewerId){
                if(!bindId) {
                    this.viewPointList = [];
                    return;
                }

                this.viewPointList = [];
                this.$parent.setIsLoading(true);

                const groupRes = await dataInterface({
                    __method_name__ : "dataList",
                    object_uuid: DataBaseInfo.Group.object_uuid,
                    view_uuid: DataBaseInfo.Group.view_uuid[this.viewType],
                    transcode: 0,
                    search: this.getDataSearch('Group')
                });

                if(groupRes?.data?.data && groupRes?.data?.data?.data && groupRes.status === 200) {
                    const groupList = groupRes?.data?.data?.data;

                    for(let { id, name } of groupList){
                        this.viewPointList.push({
                            id : `group_${id}`,
                            name : name,
                            type: 'Group',
                            children : [],
                        });
                    }
                }

                const childRes = await dataInterface({
                    __method_name__ : "dataList",
                    object_uuid: DataBaseInfo.ViewPoint.object_uuid,
                    view_uuid: DataBaseInfo.ViewPoint.view_uuid[this.viewType],
                    transcode: 0,
                    search: this.getDataSearch('Child')
                });

                if(childRes?.data?.data && childRes?.data?.data?.data && childRes.status === 200) {
                    const childList = childRes?.data?.data?.data;

                    for(let { id, name, group_id, tooltip, img, state, created_at } of childList){
                        let groupIndex = this.viewPointList.findIndex(x => x.id === `group_${group_id}`);
                        if(groupIndex === -1) continue;

                        this.viewPointList[groupIndex].children.push({
                            id: `vp_${id}`,
                            type: 'Child',
                            name: name,
                            tooltip: tooltip,
                            group_id: group_id,
                            img: img,
                            state: state,
                            created_at: created_at
                        });
                    }
                }

                this.$parent.setIsLoading(false);
            },
            getDataSearch(type){
                const user_id = this.$GetUserInfo('id');
                const archi_id = this.$GetTargetArchi('id');

                let search = [];

                if(type === 'Group'){
                    search.push({
                        code: 'type',
                        ruleType: 'eq',
                        value: "ViewPoint"
                    });
                }

                if(this.viewType === 'all' || this.viewType === 'archi_id'){
                    search.push({
                        code: "archi_type",
                        ruleType: "eq",
                        value: archi_id
                    });
                }else if(this.viewType === 'user_id'){
                    search.push({
                        code: "user_id",
                        ruleType: "eq",
                        value: user_id
                    });
                }

                return search;
            },
            getEditorData(){
                if(this.viewPointList.length === 0){
                    this.$message.warning('暂无分组，请先创建一个分组！');
                    return false;
                }

                let group = this.viewPointList[0].id;
                let data = [
                    {id: 'name', name: '标题', type: 'input', value: '视点'},
                    {id: 'tooltip', name: '描述', type: 'input', value: ''},
                    {id: 'group', name: '视图组', type: 'selector', options: this.getGroupOptions(), value: group},
                ];
                return data;
            },
            getGroupOptions(){
                let res = [];
                for (let item of this.viewPointList){
                    res.push({id: item.id, name: item.name});
                }
                return res;
            },
            setViewType(type){
                if(this.viewType === type) return;

                this.viewType = type;
                this.getViewPointData();
            },
            async createViewPointGroup(){
                let name = await this.$prompt("请输入分组名称：", '提示');

                const res = await dataInterface({
                    __method_name__ : "createData",
                    object_uuid: DataBaseInfo.Group.object_uuid,
                    view_uuid: DataBaseInfo.Group.view_uuid[this.viewType],
                    type : "ViewPoint",
                    name : name.value,
                });

                if(!res?.data?.data || res.status !== 200) return this.$message.warning(`[GIS]创建视点分组失败！`);
                const data = res?.data?.data;

                this.viewPointList.push({
                    id : `group_${data.id}`,
                    name : name.value,
                    type: 'Group',
                    children : [],
                });
                this.$message.success('创建视点分组成功！');
            },
            async renameViewPointGroup(key, name = ''){
                let index = this.viewPointList.findIndex(x => x.id === key);
                if(index === -1) return;

                let res = await this.$prompt("请输入分组名称：", '提示', {inputValue: name});

                await dataInterface({
                    __method_name__ : "updateData",
                    object_uuid: DataBaseInfo.Group.object_uuid,
                    view_uuid: DataBaseInfo.Group.view_uuid[this.viewType],
                    data_id : key.split('_')[1],
                    name : res.value,
                });
                this.viewPointList[index].name = res.value;
                this.$message.success('视点分组重命名成功！');
            },
            async deleteViewPointGroup(key){
                let index = this.viewPointList.findIndex(x => x.id === key);
                if(index === -1) return;

                for (let item of this.viewPointList[index].children){
                    await this.deleteViewPointObj(item.id, `group_${item.group_id}`);
                }

                await dataInterface({
                    __method_name__ : "deleteData",
                    object_uuid: DataBaseInfo.Group.object_uuid,
                    view_uuid: DataBaseInfo.Group.view_uuid[this.viewType],
                    data_id : key.split('_')[1],
                });

                this.viewPointList.splice(index, 1);
                this.$message.success('视点分组删除成功！');
            },
            createViewPointObj(view = {}){
                if(!view['group'] || this.viewPointList.findIndex(x => x.id === view['group']) === -1)
                    return this.$message.warning('无效分组！');
                if(!this.bindViewerId) return this.$message.warning('[GIS]未绑定viewerGIS！');

                let groupIndex = this.viewPointList.findIndex(x => x.id === view['group']);
                this.$parent.setIsLoading(true);

                setTimeout(()=>{
                    eventBus.$emit('ViewerGIS.getViewPointState', this.bindViewerId, async (opt) => {
                        const group_id =  view['group'].split('_')[1];

                        const res = await dataInterface({
                            __method_name__ : "createData",
                            object_uuid: DataBaseInfo.ViewPoint.object_uuid,
                            view_uuid: DataBaseInfo.ViewPoint.view_uuid[this.viewType],
                            name: view.name,
                            tooltip: view.tooltip,
                            group_id: group_id,
                            img: opt.img,
                            state: JSON.stringify(opt.info)
                        });

                        if(res.status !== 200) return;

                        const id = res.data.data.id;
                        const created_at = res.data.data.created_at;
                        let item = {
                            id: `vp_${id}`,
                            type: 'Child',
                            name: view.name,
                            tooltip: view.tooltip,
                            group_id: group_id,
                            img: opt.img,
                            state: opt.info,
                            created_at: created_at
                        };
                        this.viewPointList[groupIndex].children.push(item);

                        this.$message.success('视点创建成功！');
                        this.$parent.setIsEdit(false);
                        this.$parent.setIsLoading(false);
                    });
                }, 50);
            },
            refreshViewPointObj(id, group_id){
                let groupIndex = this.viewPointList.findIndex(x => x.id === group_id);
                if(groupIndex === -1) return;
                let childIndex = this.viewPointList[groupIndex].children.findIndex(x => x.id === id);
                if(childIndex === -1) return;

                this.$parent.setIsLoading(true);

                setTimeout(()=>{
                    eventBus.$emit('ViewerGIS.getViewPointState', this.bindViewerId, async (res) => {
                        await dataInterface({
                            __method_name__ : "updateData",
                            object_uuid: DataBaseInfo.ViewPoint.object_uuid,
                            view_uuid: DataBaseInfo.ViewPoint.view_uuid[this.viewType],
                            data_id: id.split('_')[1],
                            img: res.img,
                            state: JSON.stringify(res.info)
                        });

                        this.viewPointList[groupIndex].children[childIndex].img = res.img;
                        this.viewPointList[groupIndex].children[childIndex].state = res.info;

                        this.$message.success('视点更新成功！');
                        this.$parent.setIsLoading(false);
                    });
                }, 50);
            },
            async renameViewPointObj(id, name, group_id){
                let groupIndex = this.viewPointList.findIndex(x => x.id === group_id);
                if(groupIndex === -1) return;
                let childIndex = this.viewPointList[groupIndex].children.findIndex(x => x.id === id);
                if(childIndex === -1) return;

                let res = await this.$prompt("请输入视点名称：", '提示', {inputValue: name});

                await dataInterface({
                    __method_name__ : "updateData",
                    object_uuid: DataBaseInfo.ViewPoint.object_uuid,
                    view_uuid: DataBaseInfo.ViewPoint.view_uuid[this.viewType],
                    data_id : id.split('_')[1],
                    name : res.value,
                });
                this.viewPointList[groupIndex].children[childIndex].name = res.value;
                this.$message.success('视点重命名成功！');
            },
            async deleteViewPointObj(id, group_id){
                let groupIndex = this.viewPointList.findIndex(x => x.id === group_id);
                if(groupIndex === -1) return;
                let childIndex = this.viewPointList[groupIndex].children.findIndex(x => x.id === id);
                if(childIndex === -1) return;

                await dataInterface({
                    __method_name__ : "deleteData",
                    object_uuid: DataBaseInfo.ViewPoint.object_uuid,
                    view_uuid: DataBaseInfo.ViewPoint.view_uuid[this.viewType],
                    data_id : id.split('_')[1],
                });

                this.viewPointList[groupIndex].children.splice(childIndex, 1);
                this.$message.success('视点删除成功！');
            },
            onClickDropdown(type = '', item){
                switch (type) {
                    case 'renameGroup'://重命名
                        this.renameViewPointGroup(item.id, item.name);
                        break;
                    case 'deleteGroup'://删除组
                        this.deleteViewPointGroup(item.id);
                        break;
                    case 'refresh'://更新视点
                        this.refreshViewPointObj(item.id, `group_${item.group_id}`);
                        break;
                    case 'default'://设为默认

                        break;
                    case 'share'://分享视点

                        break;
                    case 'view'://查看视点
                        eventBus.$emit('ViewerGIS.setViewPointState', this.bindViewerId, item.state);
                        break;
                    case 'rename'://重命名视点
                        this.renameViewPointObj(item.id, item.name, `group_${item.group_id}`);
                        break;
                    case 'delete'://删除视点
                        this.deleteViewPointObj(item.id, `group_${item.group_id}`);
                        break;
                }
            },
            onClickViewPoint(item){
                this.onClickDropdown('view', item);
            },
        }
    }
</script>

<style lang="less" scoped>
    .vertical-flex{
        display: inline-flex;
        flex-direction: column;
        line-height: initial;
        vertical-align: middle;
    }

    .horizontal-flex{
        display: inline-flex;
        flex-direction: row;
        line-height: initial;
        vertical-align: middle;
    }

    .viewPointTree-wrap{
        background-color: transparent !important;
        color: inherit !important;
        overflow-y: auto;

        :deep(.el-collapse){
            border-top: none !important;
            border-bottom: none !important;
        }

        :deep(.el-collapse-item__header){
            padding-left: 10px;
            height: 40px;
            line-height: 40px;
            border-bottom: none;
            border-bottom: 1px solid rgba(255,255,255,0.3);
            background-color: transparent;
            color: inherit;
        }

        :deep(.el-collapse-item__wrap){
            border-bottom: 1px solid rgba(255,255,255,0.3);
            background-color: transparent;
            color: inherit;
        }

        :deep(.el-collapse-item__content){
            color: inherit;
        }
    }

    .tree-group-wrap{
        width: 100%;
        height: 40px;
        line-height: 40px;
    }

    .tree-group-wrap__title{
        width: calc(100% - 20px);
        text-align: left;
    }

    .tree-group-wrap__btns{
        font-size: 18px;
        color: inherit;
    }

    .tree-group-wrap__btns:hover{
        color: #0076FF;
    }

    .tree-obj-wrap{
        width: 100%;
        height: 90px;
        color: inherit;
    }

    .tree-obj-wrap:hover{
        background-color: #838383;
    }

    .tree-obj-wrap__img{
        margin-left: 10px;
        margin-top: 10px;
        width: 100px;
        height: 68px;
        background-color: #909399;
        object-fit: fill;
        border: 1px solid rgba(255,255,255,0.2);
    }

    .tree-obj-wrap__content{
        margin-left: 10px;
        width: 100%;
        color: inherit;
    }

    .tree-obj-wrap__title{
        padding-top: 12px;
        height: 50px;
        text-align: left;
        color: inherit;
    }

    .tree-obj-wrap__tip{
        width: 100%;
        height: 20px;
        line-height: 20px;
        text-align: left;
        color: inherit;
    }

    .tree-obj-wrap__btns{
        float: right;
        padding-right: 5px;
        height: 20px;
        line-height: 20px;
        font-size: 18px;
        color: inherit;
    }

    .tree-obj-wrap__btns:hover{
        color: #0076FF;
    }
</style>
