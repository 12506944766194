<!--
 * @Description: 翻牌器组件 
 * @Author: luocheng
 * @Date: 2022-01-12 17:08:44
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2023-01-04 11:48:49
-->
<template>
	<div
		class="chart-flop"
		v-loading="loading"
		:element-loading-background="loadingBackColor"
	>
		<h3
			class="title"
			v-if="commonConfig && commonConfig.showTitle"
			:style="{
				...commonConfig.titleStyle
			}"
		>
			{{ commonConfig.title || '' }}
		</h3>
		<!-- 翻牌器 {{ staticData }} -->
		<article class="flop-main" v-if="staticData && staticData.length">
			<section
				class="flop-item"
				v-for="(item, index) in staticData"
				:key="index"
				:style="{
					width: commonConfig && commonConfig.width || '100%'
				}"
			>
				<section class="value-row">
					<span class="money" v-if="item.isMoney">
						{{ item.moneyType }}
					</span>
					<span
						class="value-text"
						:style="{
							...item.valueConfig.style
						}"
					>{{ item.value || '--' }}</span>
					<span
						class="unit"
						v-if="item.unitConfig.showUnit"
						:style="{
							...item.unitConfig.style
						}"
					>
						{{ item.unitConfig.unit }}
					</span>
				</section>
				<h4 class="label-box" v-if="item.labelConfig.showLabel" :style="{
					...item.labelConfig.style
				}">{{ item.label || 0 }}</h4>
			</section>
		</article>
		<el-empty
			description="暂无数据"
			v-else
		></el-empty>
	</div>
</template>

<script>
import mixin from './mixin';
import eventBus from '@/plugins/eventBus';
import { dataInterface } from '../../apis/data';

export default {
	name: 'ChartFlop',
	mixins: [mixin],
	data() {
		return {
			loading: false,
			staticData: [],
			// 标题配置
			commonConfig: null
		};
	},
	created() {
		this.getFlopCount();
	},
	mounted() {
		this.$nextTick(() => {
			const refreshChart = {
				[this.chartUUID]: () => {
					this.staticData = [];
					this.getFlopCount();
				}
			}
			eventBus.$on('refreshChart', refreshChart[this.chartUUID]);
			const databaseTrigger = {
				[this.chartUUID]: config => {
					if (!config || !this.paramsConfig.length || !this.paramsConfig.find(ele => ele.componentId === config.componentId)) {
						return;
					}
					this.staticData = [];
					this.getFlopCount();
				}
			}
			eventBus.$on('databaseTrigger', databaseTrigger[this.chartUUID]);
		});
	},
	methods: {
		/**
		 * @desc: 获取统计数据
		 */
		getFlopCount() {
			const { configs = [] } = this.chartData;
			if (!configs || !configs.length) return;
			this.loading = true;
			const data = {};
			configs.forEach((ele) => {
				this.$set(data, ele.key, {
					__method_name__: 'globalFunctionCall',
					typeName: 'PublicFunc',
					type: 'value',
					funcName: 'ViewAggregate',
					payload: {
						field_uuid: ele.field_uuid,
						view_uuid: ele.view_uuid,
						aggregate: ele.aggregate
					}
					// search,
				});
			});
			dataInterface({
				__method_name__: 'moreAction',
				data
			}).then((res) => {
				if (res.status === 200) {
					const staticData = res.data.data || null;
					if (typeof staticData === 'object') {
						this.formatData(staticData);
					}
				}
				this.loading = false;
			}).catch((err) => {
				console.log(err);
				this.loading = false;
			});
		},
		/**
		 * @desc: 格式化统计数据
		 * @param {Object} staticData
		 * @return {*}
		 */
		formatData(staticData) {
			this.staticData = [];
			this.commonConfig = null;
			const { configs = [], attributes = {} } = this.chartData;
			const {
				width = '100%',
				theme = 'normal',
				// 标题
				showTitle = true,
				title = '默认标题',
				titleSize = 14,
				titleLineHeight = 30,
				titleColor = '#000000',
				titleAlign = 'center',
				// label
				showLabel = true,
				labelAlign = 'center',
				labelSize = 14,
				labelLineHeight = 30,
				labelColor = '#000000',
				// value
				valueSize = 14,
				valueColor = '#000000',
				valueAlign = 'Center',
				valueLineHeight = 30,
				// unit
				unitSize = 12,
				unitColor = '#000000',
				unitLineHeight = 30,
				moneyType = '￥'
			} = attributes;
			const result = [];
			this.commonConfig = {
				width,
				theme,
				title,
				showTitle,
				titleStyle: {
					textAlign: titleAlign,
					color: titleColor || '#000000',
					lineHeight: this.scaleHeightSize(titleLineHeight) + 'px',
					fontSize: this.scaleSize(titleSize) + 'px'
				}
			};
			for (let key in staticData) {
				const staticItem = configs.find((ele) => ele.key === key)
				const { isMoney = false, unit = '' } = staticItem;
				result.push({
					label: staticItem?.label,
					value: `${
						isMoney && !isNaN(+staticData[key])
							? (+staticData[key]).toFixed(2)
							: staticData[key]
					}`,
					isMoney,
					moneyType,
					unitConfig: {
						showUnit: unit ? true : false,
						unit,
						style: {
							fontSize: this.scaleSize(unitSize) + 'px',
							color: unitColor,
							lineHeight: this.scaleHeightSize(unitLineHeight) + 'px'
						}
					},
					labelConfig: {
						showLabel,
						style: {
							textAlign: labelAlign,
							color: labelColor || '#000000',
							lineHeight: this.scaleHeightSize(labelLineHeight) + 'px',
							fontSize: this.scaleSize(labelSize) + 'px'
						}
					},
					valueConfig: {
						style: {
							textAlign: valueAlign,
							color: valueColor || '#000000',
							lineHeight: this.scaleHeightSize(valueLineHeight) + 'px',
							fontSize: this.scaleSize(valueSize) + 'px'
						}
					}
				});
			}
			this.staticData = result;
		}
	},
	// beforeDestroy() {
	// 	eventBus.$off('refreshChart');
  //   // eventBus.$off('databaseTrigger');
	// 	console.log('销毁', '----chart-Flop---');
	// }
};
</script>

<style lang="less" scoped>
.chart-flop {
	height: 100%;
	width: 100%;
	.title{
		padding-bottom: 15px;
	}
	.flop-main{
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		// justify-content: space-between;
		// height: 100%;
		overflow: hidden;
		.flop-item {
			box-sizing: border-box;
			padding: 10px;
			height: auto;
			.value-text{
				font-weight: bold;
			}
			.label-box{
				margin-top: 5px;
			}
		}
	}
	:deep(.el-empty){
		padding: 0;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
		.el-empty__image{
			width: 35%;
			max-width: 120px;
			min-width: 50px;
		}
		.el-empty__description{
			margin-top: 15px;
		}
		.page-container p{
			color: #fff;
		}
	}
}
</style>