 <!--
 * @Author: zx
 * @Date: 2022-08-19 09:54:23
 * @LastEditTime: 2023-06-05 15:07:08
 * @Description:
 * @LastEditors: 朱廷果 1028509503@qq.com
-->
<template>
  <div class="home-tool-component">
    <div class="home-tool-breadcrumb">
      <div class="breadcrumb">
        <el-breadcrumb class="breadcrumb" separator-class="el-icon-arrow-right">
          <el-breadcrumb-item
            class="breadcrumb-item"
            v-for="(item, index) in filterBreadList"
            :key="index"
          >
            <div v-if="!item.showEllipsis" class="breadcrumb-item-container" :class="item.menu_type && +item.menu_type === 6 ? 'no-allowed' : ''" @click="onBreadClick(item)">
              <svg v-if="item.menu_type && +item.menu_type === 6" aria-hidden="true">
                <use xlink:href="#iconwenjianjia2" />
              </svg>
              <div v-else-if="item.menu_type === 1" class="header-img" :style="'background: ' + (item.background || '')">
                <svg 
                v-if="item.background && item.icon && item.background.indexOf('linear') !== -1 && item.icon.indexOf('el-icon') === -1"
                  aria-hidden="true"
                  class="color-icon">
                  <use :xlink:href="item.icon.indexOf('#')===-1? '#' + item.icon : item.icon" />
                </svg>
                <svg 
                  v-else
                  aria-hidden="true"
                  class="color-icon">
                  <use :xlink:href="item.icon.indexOf('#')===-1? '#' + item.icon : item.icon" />
                </svg>
              </div>
              <!-- <div class="header-img" v-else-if="index === filterBreadList.length -1 && item.menu_type" 
               :style="'background:'+getBackground(item) ">
                <i  class='iconfont' :class="item.icon ? item.icon : 'icon-a-5-1zhiliang'" ></i>
              </div> -->
              <div v-else>
                <svg 
                 v-if="item.type == 1 && index !==  filterBreadList.length -1"
                  aria-hidden="true"
                  class="color-icon">
                  <use xlink:href="#iconwenjianjia2" />
                </svg>
                <div v-else-if="index === 0 || item.id === -4 || item.id === -5" class="header-img mainicon" :style="'background: ' + (item.background || '')">
                  <i class='iconfont' :style="'background: ' + (item.background || '')"
                    :class="item.icon ? item.icon : 'icon-a-5-1zhiliang'" >
                  </i>
                </div>
                <div v-else
                  style="width: 26px; height: 26px;border-radius: 16px;" 
                  :style="{background:getBackground(item),color:item.color?item.color:'#e06363'}">
                  <i  class='iconfont' 
                    style="width:26px;margin-left: 5px;"
                    :class="item.icon ? item.icon : 'icon-a-5-1zhiliang'" >
                  </i>
                </div>
              </div>
              <span :style="index === filterBreadList.length -1 ? 'margin-right: 8px' : ''">{{ item.name }}</span>
            </div>
            <div v-else-if="showEllipsis && item.showEllipsis && ellipsisList?.length">
              <el-dropdown
                trigger="click"
                placement="bottom"
                class="dropdown-container"
              >
                <div class="ellipsis">
                  ...
                </div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for="(ite, ind) in ellipsisList" :key="'ellipsisList' + ite.id">
                    <div class="breadcrumb-item-container" :class="item.menu_type && +item.menu_type === 6 ? 'no-allowed' : ''" @click="onBreadClick(ite)">
                      <svg v-if="ite.menu_type && +ite.menu_type === 6" aria-hidden="true">
                        <use xlink:href="#iconwenjianjia2" />
                      </svg>
                      <div v-else-if="ite.menu_type === 1" class="header-img" :style="'background: ' + (ite.background || '#e3e9ff')">
                        <i :style="'color:' + (ite.color || '#268bfb')"  :class="ite.icon ? ite.icon : 'el-icon-platform-eleme'"></i>
                      </div>
                      <div v-else-if="ind === filterBreadList.length -1 && ite.menu_type">
                        <i :class="'iconfont ' + ite.icon"  :style="'color:'+ (ite.color?ite.color:'#e06363')"></i>
                      </div>
                      <i v-else :class="'iconfont ' + ite.icon"  :style="'color:'+ (ite.color?ite.color:'#e06363')"></i>
                      <span>{{ ite.name }}</span>
                    </div>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="breadcrumb-setting">
        <div v-if="!isSuper" class="top-iconfont-text" :style="(version===2)?'background: linear-gradient(135deg, #F2F5F9 0%, #DAE5F2 100%);color: #869CB9;':''">
          <el-button @click="openUpdate" :loading='versionLoading' :style="(version===2)?'color: #869CB9;text-shadow: 0px 1px 0px rgba(255,255,255,0.8);':''">
            <i class="iconfont iconshanshan"></i> {{topTitle}}
          </el-button>
        </div>
      <!-- 占位  登录相组件 -->
        <ArchiLogin :menuTreeData="menuTreeData" @jumpDept="jumpDept" :hasDept='hasDept' @jumpUserInfo='jumpUserInfo'/>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Breadcrumb,
  BreadcrumbItem,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  // Dropdown,
	// DropdownItem,
	// DropdownMenu,
	// TabPane,
	// Tabs
} from "element-ui";
import ArchiLogin from './ArchiLogin.vue'
import { versionHeader} from "@/api/saas";
import { mapState } from 'vuex';
import {userVersionApply} from '@/api/saasManage'
export default {
  components:{
    'el-breadcrumb': Breadcrumb,
    'el-breadcrumb-item': BreadcrumbItem,
    'el-dropdown' : Dropdown,
    'el-dropdown-menu': DropdownMenu,
    'el-dropdown-item' : DropdownItem,
		// 'el-tabs': Tabs,
		// 'el-tab-pane': TabPane,
		// 'el-dropdown': Dropdown,
		// 'el-dropdown-menu': DropdownMenu,
		// 'el-dropdown-item': DropdownItem,
    ArchiLogin, // 切换结构及推出登录等功能
  },
  props:{
    chooseMenuList: {
      type: Array,
      default: () => {
        return []
      },
			required: true
    },
    menuTreeData: {
      type: Array,
      default: () => {
        return []
      }
    },
    hasDept:{
      type:Boolean,
      default:false
    },
    currentMenu:{
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      versionLoading:false,
      version:3,
      historyList: [],
      targetHistory: '',
			// tab菜单
			visible: false,
			top: 0,
			left: 0,
      filterBreadList: [], // 过滤我的应用级别
      isSuper:false,
      ellipsisList: [], // 省略号下拉数组
      showEllipsis: false, // 是否显示省略号
    }
  },
  computed: {
    ...mapState(['saasCurrentMenu', 'systemStyle']),
    pathname() {
      return window.location.pathname
    },
    // 顶级title的计算 有三种情况
    topTitle() {
      if(this.version == 1){
        return '审核中'
      }else if(this.version == 2){
        return '升级企业版'
      }else{
        return '企业版'
      }
    },
  },
  watch: {
    $route: {
      handler() {
        this.historyList = JSON.parse(sessionStorage.getItem('navHistoryList'))
        if(this.historyList&&this.historyList.length){
          this.historyList.forEach((element) => {
            if (this.chooseMenuList && this.chooseMenuList.length > 1 && +element.id === +this.chooseMenuList[this.chooseMenuList.length - 1].id) {
              this.targetHistory = element.id + ''
            }
          })
        }
      },
      deep: true,
      immediate: true
    },
    visible(value) {
      if (value) document.body.addEventListener('click', this.closeMenu)
      else document.body.removeEventListener('click', this.closeMenu)
    },
    chooseMenuList(val) {
      this.$nextTick(() => {
        if (val && val.length) {
          this.filterBreadList = this.filterBread()
          if( this.filterBreadList[0]?.name !== '我的应用'){ // 我的应用特殊判断
            if (this.currentMenu.is_apps && this.filterBreadList?.length) {
              this.filterBreadList = this.filterBreadList.filter((item) => item?.menu_type || item?.showEllipsis)
            }
          }
          this.historyList = JSON.parse(sessionStorage.getItem('navHistoryList'))
          this.historyList.forEach((element) => {
            if (element.id && +element.id === +val[val.length - 1].id && +element.id === +this.targetHistory) {
              this.targetHistory = element.id + ''
            }
          })
        } else {
          this.filterBreadList = []
        }
        
      })
    },
    targetHistory(val) {
      this.$emit('currentShowRoute', val)
    },
    saasCurrentMenu(val) {
      if (val.path !== '/home') {
        this.targetHistory = val.id + ''
      }
    },
    currentMenu() {
      this.filterBreadList = this.filterBread()
      if (this.currentMenu.is_apps && this.filterBreadList?.length) {
        this.filterBreadList = this.filterBreadList.filter((item) => item.menu_type)
      }
    }
  },
  mounted() {
    this.historyList = JSON.parse(sessionStorage.getItem('navHistoryList'))
    this.isSuper = JSON.parse(localStorage.getItem('userInfo')).saas_admin
    if (!this.historyList || !Array.isArray(this.historyList) || this.historyList.length <= 0) {
      this.historyList = []
      sessionStorage.setItem('navHistoryList', JSON.stringify(this.historyList))
    }
    this.historyList.forEach((element) => {
      if (element.path === window.location.pathname || window.location.pathname.indexOf(element.path) > -1) {
        this.targetHistory = element.id + ''
      }
    });
    if (this.historyList && this.historyList.length && this.saasCurrentMenu && this.saasCurrentMenu.id) {
      this.historyList.forEach(element => {
        if (+element.id === +this.saasCurrentMenu.id) {
          this.targetHistory = element.id + ''
          this.$emit('currentMenuList',element)
        }
      });
    }
    if (this.chooseMenuList && this.chooseMenuList.length) {
      this.filterBreadList = this.filterBread()
    }
    if(!this.isSuper){
      this.getData()
    }
  },
  methods:{
    /**
     * 获取当前企业是什么状态
     */
    getData(){
      versionHeader().then((res)=>{
        if(res.data && res.data.code !== 200){
          return false;
        }
        this.version = res.data.data
        this.$store.commit('setSaasVersion', +this.version)
        this.versionLoading = false;
      }).catch(() => {
        this.$message.error(`操作错误`)
      });
    },
    openUpdate() {
      if(this.version!==2){
        return
      }
      this.versionLoading = true
      let phone = JSON.parse(localStorage.getItem('userInfo')).phone
      if(!phone){
        this.$message.error('请先绑定手机号！')
        this.versionLoading = false;
        return
      }
      this.userLoading = true
      userVersionApply().then((res)=>{
        if (res.data && res.data.code !== 200) {
          this.$message.error(res.data.msg)
          this.versionLoading = false;
          return false
        }
        this.versionLoading = false;
        this.$message.success('操作成功,请等待申请通过！')
        this.userLoading = false
        this.getData()
      }).catch(() => {
        this.versionLoading = false;
        this.userLoading = false
        this.$message.error(`操作错误`)
      });
      this.userLoading = false
    },
    /* 移除历史记录 */
    onTabRemove(data) {
      let index = this.historyList.findIndex((item) => {
        return +item.id === +data
      })
      let boo = false
      if (index === this.historyList.length - 1) {
        boo = true
      }
      this.historyList.splice(index, 1)
      sessionStorage.setItem('navHistoryList', JSON.stringify(this.historyList))
      if (boo) {
        const currentHistory = this.historyList[this.historyList.length - 1]
        if (this.targetHistory === data) {
          this.$router.push({ path: currentHistory.path })
          this.targetHistory = currentHistory.id + ''
        }
        return
      }
      if (this.targetHistory === data) {
        this.$router.push({ path: this.historyList[index].path })
        this.targetHistory = this.historyList[index].id + ''
      }
    },
    onTabs() {
      this.onTab(this.targetHistory)
    },
    onTab(data) {
      this.historyList.forEach(element => {
        if (+element.id === +data) {
          const pageEditNumber = [2,3,4,5,8]
          this.$emit('currentMenuList',element)
          if (+element.menu_type === 7) {
            this.$router.push({ path: '/application/my-application', query: { formUuid: element.form_module_uuid, appMenuId: element.id } })
          } else if (pageEditNumber.indexOf(+element.menu_type) !== -1) {
            const typeList = ['page', 'dataview', 'console', 'mobile', 'chart']
            this.$router.push({
              path: '/application/page-parser',
              query: {
                pageUUID: element.page_uuid,
                id: element.current_data_id,
                type: typeList[pageEditNumber.indexOf(+element.menu_type)],
                name: element.name
              }
            })
          } else if (+element.menu_type === 1) {
            this.$router.push({
              path: '/dwh/objects/detail',
              query: {
                obuuid: element.object_info.obuuid,
                obname: element.object_info.name,
                type: 'field',
                is_virtual: element.object_info.is_virtual,
                enabled: 1,
                obcode: element.object_info.obcode
              }
            })
          } else {
            /* 判断是否是数仓相关 */
            if (element.store_type) {
              if (element.store_type === 'form') {
                this.$router.push({
                  path: element.path,
                  query: {
                    obuuid: element.uuid,
                    obname: element.name,
                    type: "form",
                    obcode: element.code,
                  },
                })
              } else {
                this.$router.push({
                  path: element.path,
                  query: {
                    obuuid: element.uuid,
                    obname: element.name,
                    type: element.store_type,
                    is_virtual: element.is_virtual,
                    enabled: element.enabled,
                    obcode: element.code,
                  },
                })
              }
              return
            } else {
              this.$router.push({ path: element.path })
            }
          }
          this.targetHistory = element.id + ''
        }
      });
    },
		/**
		 * @desc: 关闭功能标签
		 * @param {String} type all left right other
		 */
		onClose(type) {
			if (type === 'all') {
        this.historyList = []
        sessionStorage.setItem('navHistoryList', JSON.stringify(this.historyList))
        this.$router.push({ path: '/home' })
        this.targetHistory = '/home'
			} else if (type === 'other') {
        let currentPath = {}
        this.historyList.forEach(element => {
          if (+element.id === +this.chooseMenuList[this.chooseMenuList.length - 1].id) {
            currentPath = element
          }
        });
        if (currentPath && currentPath.id) {
          this.historyList = [currentPath]
        } else {
          this.historyList = []
        }
        sessionStorage.setItem('navHistoryList', JSON.stringify(this.historyList))
			} else if (type === 'left') {
				const index = this.historyList.findIndex(
					(ele) => +ele.id === +this.chooseMenuList[this.chooseMenuList.length - 1].id
				);
        let resultArr = []
				for (let i = 0; i < this.historyList.length; i++) {
					if (i >= index) {
						resultArr.push(this.historyList[i]);
					}
				}
        if (resultArr && resultArr.length) {
          this.historyList = [].concat(resultArr)
        }
        sessionStorage.setItem('navHistoryList', JSON.stringify(this.historyList))
			} else if (type === 'right') {
				const index = this.historyList.findIndex(
					(ele) => +ele.id === +this.chooseMenuList[this.chooseMenuList.length - 1].id
				);
        let resultArr = []
				for (let i = 0; i < this.historyList.length; i++) {
					if (i <= index) {
						resultArr.push(this.historyList[i]);
					}
				}
        if (resultArr && resultArr.length) {
          this.historyList = resultArr
        }
        sessionStorage.setItem('navHistoryList', JSON.stringify(this.historyList))
			}
		},
		/**
		 * @desc: tab 菜单
		 * @param {Object} e
		 * @param {Object} item
		 * @return {*}
		 */
		openMenu(e) {
			const offsetLeft = this.$el.getBoundingClientRect().left;
			const offsetWidth = this.$el.offsetWidth;
			const maxLeft = Math.round(offsetWidth);
			const left = Math.round(e.clientX - offsetLeft);
			if (left > maxLeft) this.left = maxLeft;
			else this.left = left;
			this.top = Math.round(e.clientY - 60);
			this.visible = true;
		},
    /**
     * @desc: 关闭
     * @param {*}
     * @return {*}
     */
    closeMenu() {
      this.visible = false;
    },
    filterBread() {
      let arr = []
      this.showEllipsis = false
      if (this.chooseMenuList?.length && this.chooseMenuList?.length < 6) {
        if (this.chooseMenuList[this.chooseMenuList.length - 1]?.menu_type === 1) {
          arr = this.chooseMenuList
        } else {
          arr = this.chooseMenuList.filter((item) => !item?.is_apps)
        }
      } else if (this.chooseMenuList?.length > 5) {
        this.showEllipsis = true
        if (this.chooseMenuList[this.chooseMenuList.length - 1].menu_type === 1) {
          arr = this.chooseMenuList.filter((item, index) => [0, 1, this.chooseMenuList.length - 2 , this.chooseMenuList.length - 1].indexOf(index) !== -1)
        } else {
          const array = this.chooseMenuList.filter((item) => !item.is_apps)
          arr = array.filter((item, index) => [0, 1, array.length - 2 ,array.length - 1].indexOf(index) !== -1)
          this.ellipsisList = array.filter((item, index) => [0, 1, array.length - 2 ,array.length - 1].indexOf(index) === -1)
        }
      }
      if (this.showEllipsis) {
        arr.splice(2, 0, { showEllipsis: true })
      }
      return arr
    },
    jumpDept() {
      this.$emit('jumpDept')
    },
    jumpUserInfo() {
      this.$emit('jumpUserInfo')
    },
    onBreadClick(data) {
      console.log(data)
      if(data.isInstall){
        this.$emit('backInstall',data)
        return
      }
      if (!data.menu_type || data.menu_type === 6) {
        return 
      }
      this.$emit('breadClick', data, this.filterBreadList[0])
    },
    getBackground(data) {
      const color = ['#E06363', '#F59A31','#2CB8A4','#4B98E8','#9F6BF5']
      const linerColor = [
        'linear-gradient(180deg, #FFA557 0%, #FF5C5C 100%)', 
        'linear-gradient(180deg, #FFCF3D 0%, #FF9A42 100%)', 
        'linear-gradient(180deg, #48E199 0%, #15C2B6 100%)', 
        'linear-gradient(180deg, #5ADFFA 0%, #459CFF 100%)', 
        'linear-gradient(180deg, #8C9FFF 0%, #A970FF 100%)']
      const index = color.indexOf(data.color)
      if (index !== -1) {
        return linerColor[index]
      } else {
        return linerColor[0]
      }
    }
  }
}
</script>
<style lang='less' scoped>
:deep(.el-breadcrumb) {
  i {
    margin: 0 4px;
    font-size: 16px;
  }
}
.mainicon{
  width: 16px;
  height: 16px;
  padding: 3px 0px;
  box-sizing: border-box;
  background: linear-gradient(180deg, #5ADFFA 0%, #459CFF 100%);
  border-radius: 3px;
  color: #fff;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.breadcrumb-item-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  -moz-user-select:none;
  -webkit-user-select:none;
  -ms-user-select:none;
  -khtml-user-select:none;
  -o-user-select:none;
  user-select:none; 
  .header-img {
    width: 16px;
    height: 16px;
    // margin-right: 9px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: linear-gradient(180deg, #FFA557 0%, #FF5C5C 100%);
    svg {
      width: 13px;
      height: 13px;
      margin: 0;
    }
    i {
      font-size: 12px;
      margin-right: 0;
    }
  }
  svg {
    width: 20px;
    height: 20px;
  }
  i {
    margin: 0;
    margin-right: 8px;
    font-size: 12px;
  }
  span {
    height: 24px;
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #2A2F3D;
    line-height: 24px;
    margin-left: 8px;
  }
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 16px;
    // background: linear-gradient(180deg, #FFCF3D 0%, #FF9A42 100%);
    border-radius: 13px;
    // margin-right: 8px;
    i {
      font-size: 16px;
      margin: 0;
      color: #fff;
    }
  }
}

.breadcrumb-item-container:hover {
  background-color: #F4F6FA;
  border-radius: 6px;
}
.no-allowed {
  cursor: not-allowed;
  span {
    cursor: not-allowed;
  }
}
.no-allowed:hover {
  background-color: #fff;
  :deep(.el-dropdown) {
    cursor: not-allowed;
  }
}
.home-tool-component {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 8px;
  margin-top: 12px;
  // padding: 8px 0 8px 10px;
  .home-tool-breadcrumb {
    width: 100%;
    height: 54px;
    box-sizing: border-box;
    display: flex;
    padding: 0 0 0 12px;
    align-items: center;
    .breadcrumb {
      width: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .breadcrumb {
        display: flex;
        align-items: center;
        .breadcrumb-item {
          height: 34px;
          display: flex;
          align-items: center;
          color: #515a6e;
          .ellipsis {
            padding: 8px;
          }
          .ellipsis:hover {
            background-color: #eee;
          }
        }
      }
    }
    .breadcrumb-setting {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      box-sizing: border-box;
      padding-right: 10px;
    }
  }
  .home-tool-tabs {
    width: 100%;
    height: 50px;
    .history-box {
      position: relative;
      width: 100%;
      box-sizing: border-box;
      padding: 8px 20px 0px 10px;
      height: 50px;
      box-sizing: border-box;
      padding-right: 50px;
      display: flex;
      user-select: none;
      .tab-list {
        flex: 1;
        width: 100%;
        height: 100%;
        .el-tabs--card > .el-tabs__header {
          border: none;
        }
        :deep(.el-tabs) {
          box-sizing: border-box;
          .el-tabs__header {
            border: none !important;
            margin: 0;
          }
          .el-tabs__content {
            display: none;
          }
          .el-tabs__nav {
            height: 42px;
            border: none;
          }
          .el-tabs__item {
            box-sizing: border-box;
            // margin: 0 5px;
            margin-top: 2px;
            height: 100%;
            box-sizing: border-box;
            line-height: 38px;
            font-family: PingFangSC, PingFangSC-Medium;
            border: none;
            transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
            margin-right: -18px;
            padding: 0 30px 0 30px;
            &:hover {
              padding: 0 30px 0 30px;
              // color: #515a6e;
              color: #409EFF;
              // background: #E9EBF0;
              background: #dee1e6                     ;
              mask: url('~@/assets/images/layout/smoot-tab.png');
              mask-size: 100% 100%;
              .tab-pane-label {
                i {
                  color: #409EFF;
                }
              }
            }
            &.is-active {
              padding: 0 30px 0 30px;
              color: #409EFF;
              background: #e8f4ff;
              outline: none;
              mask: url('~@/assets/images/layout/smoot-tab.png');
              mask-size: 100% 100%;
              font-weight: bold;
              .tab-pane-label {
                i {
                  color: #409EFF;
                }
              }
            }
          }
          .tab-pane-label {
            display: inline-block;
            height: 36px;
            overflow: hidden;
            .iconfont {
              color: #303133;
            }
            i {
              color: #303133;
              line-height: 38px;
              margin-right: 5px;
            }
            span {
              line-height: 38px;
            }
          }
          .el-icon-close {
            margin-top: -22px;
          }
          .el-tabs__nav-prev {
            line-height: 42px;
          }
          .el-tabs__nav-next {
            line-height: 42px;
          }
          .el-tabs__new-tab {
            display: none;
          }
        }
      }
      .contextmenu {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        user-select: none;
      }
    }
  }
}
</style>
<style lang="less" scoped>
  .top-iconfont-text{
    border-radius: 6px;
    margin-right: 16px;
    height: 36px;
    line-height: 36px;
    background: #FDECC4;
    text-align: center;
    color: #D68F00;
    font-size: 14px;
    display: flex;
    align-items: center;
    padding-right: 12px;
    >i {
      padding-left: 13px;
      color: #EB9C00;
    }
    :deep(.el-button){
      background: transparent;
      border: none;
      text-align: center;
      color: #D68F00;
      font-size: 14px;

    }
    :deep(.is-loading):before{
      background: transparent;
    }
  }
  .history-box {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    padding: 8px 20px;
    height: 50px;
    box-sizing: border-box;
    padding-right: 50px;
    .tab-list {
      flex: 1;
      .el-tabs {
        box-sizing: border-box;
        .el-tabs__header {
          border: none;
          margin: 0;
        }
        .el-tabs__content {
          display: none;
        }
        .el-tabs__nav {
          height: 34px;
          border: none;
        }
        .el-tabs__item {
          box-sizing: border-box;
          margin: 0 5px;
          height: 34px;
          box-sizing: border-box;
          border: 1px solid #f2f3f5;
          line-height: 34px;
          font-family: PingFangSC, PingFangSC-Medium;
          border: 1px solid #dcdfe6;
          border-radius: 4px;
          transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
          &:hover {
            border-color: #409EFF;
            .tab-pane-label{
              i{
                color: #409EFF;
              }
            }
          }
          &.is-active {
            border-color: #409EFF;
            font-weight: bold;
            .tab-pane-label{
              i{
                color: #409EFF;
              }
            }
          }
        }
        .tab-pane-label {
          display: inline-block;
          height: 33px;
          overflow: hidden;
          .iconfont {
            color: #303133;
          }
          i{
            color: #303133;
            line-height: 34px;
            margin-right: 5px;
          }
          span{
            line-height: 34px;
          }
        }
        .el-icon-close{
          margin-top: -22px;
        }
        .el-tabs__nav-prev {
          line-height: 34px;
        }
        .el-tabs__nav-next {
          line-height: 34px;
        }
        .el-tabs__new-tab {
          display: none;
        }
      }
    }
    .el-dropdown {
      position: absolute;
      right: 24px;
      top: 18px;
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        transform: rotate(90deg);
        .box-t {
          &:before {
            transform: rotate(45deg) !important;
          }
        }
        .box:before,
        .box:after {
          background: #409EFF!important;
        }
      }
    }
    .header-tabs-more {
      .header-tabs-more-icon {
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border 0s,
          color 0.1s, font-size 0s;
        // transform: rotate(90deg);
        .box-t {
          &:before {
            transform: rotate(0deg);
          }
        }
        .box:before,
        .box:after {
          background: #9a9a9a;
        }
        .box {
          position: relative;
          display: block;
          width: 14px;
          height: 8px;

          &:before {
            position: absolute;
            top: 2px;
            left: 0;
            width: 6px;
            height: 6px;
            content: '';
            background: #9a9a9a;
          }

          &:after {
            position: absolute;
            top: 2px;
            left: 8px;
            width: 6px;
            height: 6px;
            content: '';
            background: #9a9a9a;
          }
        }

        .box-t {
          &:before {
            transition: transform 0.3s ease-out 0.3s;
          }
        }
      }
    }
  }
</style>
