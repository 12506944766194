<template>
	<p class="annex-container">
		<!-- 固定 -->
		<template v-if="element.valueOrign === 'fixed'">固定值对该组件不使用</template>
		<!-- 来源于其他 -->
		<template v-else>
      <div class="annex-item" v-for="(item, index) in dataSoure" :key="index" @click.stop="openFile(item)">
        {{ item.name || item.filename }}
      </div>
			<span v-if="dataSoure.length === 0">/</span>
    </template>
		<el-image
			style="width:0;height:0;overflow:hidden"
			ref="imgRef"
			:src="dialogImageUrl"
			:preview-src-list="[dialogImageUrl]"
		/>
	</p>
</template>

<script>
import { getComponentById } from '@/utils/tools';
import { mapState } from 'vuex';
import { Image } from 'element-ui'

export default {
	name: 'CommonAnnex',
	components: { 'el-image': Image },
	props: {
		// 绑定的值
		element: {
			type: Object,
			default: () => {},
			required: true
		},
		fullData: {
			// type: Object
		},
		isGroup: {
			type: Boolean,
		},
		groupComponents: {
			type: Array,
			default: () => []
		},
    componentList: {
      default: null
    },
		// 循环映射值
		mapData: {
			type: Object,
			default: () => {}
		}
	},
	data() {
		return {
			that: this,
			dialogImageUrl: '',
		};
	},
	computed: {
		...mapState(['componentData']),
		// 数据仓库
		database() {
			return this.element.database;
		},
		// 字段对象
		dataObj() {
			if (!this.database || !this.database.containerKey) return {};
			let container = getComponentById(this.componentList || this.componentData, this.database.containerKey);
			if(!container && this.isGroup && this.groupComponents.length) {
				container = getComponentById(this.groupComponents, this.database.containerKey);
			}
			if ((!container || !container.containerData) && !this.fullData) return {};
			// 列表情况默认返回一个
			if (Array.isArray(container.containerData) && container.containerData.length) {
				return container.containerData?.[0] || {};
			}
			// 列表有分页和不分页情况
			if (this.fullData) {
				if (Array.isArray(this.fullData)) {
					// 分页
					return this.fullData[0] || {};
				} else if (Array.isArray(this.fullData?.data)) {
					// 不分页
					return this.fullData?.data?.[0] || {};
				}
				return this.fullData;
			}
			const result = (container && container.containerData) || this.fullData;
			return result;
		},
		// 绑定的uuid
		bindUUID() {
			if (!this.element || !this.element.database) return '';
			return this.element.database.bindUUID;
		},
		// 用于获取绑定的数据字典(描述文字)
		metaData() {
			if (!this.element || !this.element.database) return null;
			const { fieldList, bindUUID } = this.element.database;
			return fieldList.find((ele) => ele.uuid === bindUUID);
		},
		// 数据绑定类型
		databaseType() {
			if (!this.element || !this.element.database) return 'value';
			return this.element.database.databaseType || 'value';
		},
		statusConfig() {
			return this.element?.statusConfig;
		},
    dataSoure() {
      let data = []
      if(this.element.valueOrign === 'component') {
        data = this.getValueFromComponent(this.element.componentValueConfig, this)
      } else if (this.element.valueOrign === 'database') {
        if(this.dataObj && this.bindUUID) {
          if(this.databaseType === 'value') {
            data = this.dataObj[this.bindUUID]
            return this.dataObj[this.bindUUID]
          } else if(this.databaseType === 'wordbook' && this.metaData) {
            data = this.metaData.name
          }
        }
      } else if(this.element.valueOrign === 'valueMap') {
        data =  this._getValueFromMap()
      }
      if(Array.isArray(data)) {
        return data
      } else {
        return []
      }
    }
	},
	filters: {
		// 来源于组件的值
		getValueFromComponent(config, _this) {
			if (!config || !config.component || !config.field || (!_this.componentData && !_this.componentList)) return '';
			let component = getComponentById(_this.componentList || _this.componentData, config.component);
			if(!component && _this.isGroup && _this.groupComponents.length) {
				component = getComponentById(_this.groupComponents, config.component);
			}
			if (!component) return '';
			if (config.field === 'propValue') {
				return component.propValue;
			}
			if (!component.customStatus) return '';
			return component.customStatus[config.field];
		},
	},
	updated() {
		this.toResolveData();
	},
	methods: {
		/**
		 * @desc: 暴露数据
		 */
		toResolveData() {
			const { valueOrign = 'fixed', componentValueConfig = {} } = this.element;
			if (valueOrign === 'fixed') {
				// 固定值
				this.element.resolveData = {
					value: this.element.propValue || ''
				};
			} else if (valueOrign === 'component') {
				// 其他组件
				this.element.resolveData = {
					value: this._getValueFromComponent(componentValueConfig)
				};
			} else if (valueOrign === 'database') {
				// 数据仓库
				this.element.resolveData = {
					value: this._getDatabaseData()
				};
			}
		},
		/**
		 * @desc: 获取数仓数据
		 */
		_getDatabaseData() {
			if (!this.dataObj || !this.bindUUID) return '';
			if (this.databaseType === 'value') {
				// 绑定值
				return this.dataObj[this.bindUUID] || '/';
			}
			if (this.databaseType === 'wordbook' && this.metaData) {
				// 数据字典(描述文字)
				return this.metaData.name || '';
			}
			return '';
		},
		/**
		 * @desc: 获取组件来源绑定值
		 * @param {Object} config
		 */
		_getValueFromComponent(config) {
			if (!config || !config.component || !config.field || (!this.componentData && !this.componentList)) return '';
			let component = getComponentById(this.componentList || this.componentData, config.component);
			if(!component && this.isGroup && this.groupComponents.length) {
				component = getComponentById(this.groupComponents, config.component);
			}
			if (!component) return '';
			if (config.field === 'propValue') {
				return component.propValue;
			}
			if (!component.customStatus) return '';
			return component.customStatus[config.field];
		},
		/**
		 * @desc: 获取映射值
		 */
		_getValueFromMap() {
			const { bindField } = this.element?.loopMapConfig || {};
			if (!bindField) return '/';
			const { renderData } = this.mapData || {};
			if (!renderData) return '/';
			return renderData?.[bindField] || '/';
		},
		/**
		 * @description 查看文件预览
		 * @param {Object} row
		 */
		openFile(row) {
			console.log(row)
			if (
				['bmp', 'jpg', 'png', 'gif', 'jpeg', 'cdr', 'psd'].indexOf(
					row.path.substring(row.path.lastIndexOf('.') + 1).toLowerCase()
				) >= 0
			) {
				this.dialogImageUrl = row.path;
				if(this.$refs.imgRef) {
					this.$refs.imgRef.showViewer = true
				}
			} else if (
				['doc', 'docx', 'pdf', 'ppt', 'pptx', 'xlsx', 'xls', 'txt'].indexOf(
					row.path.substring(row.path.lastIndexOf('.') + 1).toLowerCase()
				) >= 0
			) {
        let url = 'https://weboffice.bimcc.net?furl='
				if (row.path.includes('https://')) {
					url = 'https://weboffice.bimcc.net?ssl=1&furl='
				}
				window.open(
					url + row.path
				)
			} else if (
				['mp4'].indexOf(
					row.path.substring(row.path.lastIndexOf('.') + 1).toLowerCase()
				) >= 0
			) {
				window.open(row.path);
			} else {
				this.$message.info('无法打开此格式的文件！');
			}
		}
	}
};
</script>

<style lang="less" scoped>
.annex-container {
	width: 100%;
	.annex-item{
		line-height: 24px;
		&:hover{
			color: #409eff;
		}
	}
}
</style>
