
<!--
 * @Description: 循坏容器
 * @Author: luocheng
 * @Date: 2021-08-18 13:48:12
 * @LastEditors: luocheng
 * @LastEditTime: 2022-07-29 10:56:00
-->
<template>
	<div
		class="common-loop-container"
		:data-id="element.id"
		v-loading="loading"
	>
		<template v-if="element.children && element.children.length">
			<ComponentBox
				v-for="(itemData, index) in renderData"
				:isPreview="isPreview"
				:key="index"
				:containerData="renderData"
				:fullData="itemData"
				:loopMetadata="loopMetadata"
				@click.native.stop="onClickBox(element.children[0], itemData)"
				:element="element.children[0]"
				:pageId="pageId"
			>
			</ComponentBox>
		</template>
	</div>
</template>

<script>
import { dataInterface } from '@/apis/data/index';
import eventBus from '@/plugins/eventBus';

export default {
	name: 'CommonContainer',
	props: {
		// 组件对象
		element: {
			type: Object,
			default: () => {},
			required: true
		},
		// 是否为预览
		isPreview: {
			type: Boolean,
			required: false,
			default: false
		},
		// 弹窗页面配置的ID
		pageId: {
			type: Number,
			required: false
		}
	},
	inject: ['EDITOR_pageUUID'],
	data() {
		return {
			// 渲染数据
			renderData: [],
			// 完全渲染数据未格式化
			fullData: null,
			loading: false,
			// 数据字段
			loopMetadata: null
		};
	},
	computed: {
		// 值来源
		valueOrigin() {
			return this.element && this.element.valueOrign;
		}
	},
	created() {
		if (
			this.element &&
			this.element.database &&
			this.element.database.objectData &&
			this.element.database.viewData
		) {
			const { paramsConfig } = this.element.database;
			// 获取某些参数配置
			let canRequest = true;
			if (paramsConfig && Array.isArray(paramsConfig)) {
				// 必须参数放置一些接口需要参数来源的 在组件初始化时发送错误接口造成接口浪费和渲染错误
				canRequest = paramsConfig.every((ele) => ele.isRequired === false);
			}
			if (canRequest) {
				this.getRenderData(this.element.database, {}, []);
			}
		} else if (
			this.element &&
			this.element.database &&
			this.element.database.requestType === 'moreAction'
		) {
			const { statisticalConfig } = this.element.database;
			if (statisticalConfig && statisticalConfig.length) {
				this.getRenderData(this.element.database, {}, []);
			}
		}
	},
	watch: {
		valueOrigin(newV) {
			// 清空值
			// 数据源
			if (newV === 'database') {
				if (
					this.element &&
					this.element.database &&
					this.element.database.objectData &&
					this.element.database.viewData
				) {
					this.getRenderData(this.element.database);
				}
			}
		}
	},
	mounted() {
		// 配置关联参数的容器才需要监听
		eventBus.$on('databaseTrigger', (data) => {
			let { paramsConfig, requestType } = this.element.database;
			if (!paramsConfig || !Array.isArray(paramsConfig)) return;
			// 是否当前组件响应
			let isTarget = false;
			let configItem = null;
			let params = {};
			for (let i = 0; i < paramsConfig.length; i++) {
				if (
					paramsConfig[i].componentId === data.componentId &&
					this.isSameAction(requestType, data.action)
				) {
					isTarget = true;
					configItem = paramsConfig[i];
					params = {
						...params,
						...this.getParams(configItem, data)
					};
				}
			}
			if (!isTarget && !data.isInit) return;
			let search = [];
			if (data.isSearch) {
				search = data.output.search || [];
			}
			this.getRenderData(this.element.database, params, search, data.isInit);
		});
	},
	methods: {
		/**
		 * @desc: 触发点击事件
		 * @param {Object} item 点击的组件
		 * 触发方式实质就是在改变指定的组件状态
		 */
		onClickBox(item, data) {
			console.log(data, '0000事件触发需求进行修改');
			if (
				!item.events ||
				!item.events.click ||
				!item.events.click.effects ||
				!Array.isArray(item.events.click.effects)
			)
				return;
			item.events.click.effects.forEach((ele) => {
				this.$store.commit('triggerEvents', {
					config: ele,
					element: item
				});
			});
		},
		/**
		 * @desc: 获取数据渲染时候的数据
		 */
		getRenderData(database, params, search = [], isInit = false) {
			this.loading = true;
			// 注入的参数
			let outParams = {};
			if (this.element.database.userOutParams) {
				outParams = sessionStorage.getItem(
					`dialogRelationParams_${this.pageId || this.$route.query.id}`
				);
				outParams = outParams ? JSON.parse(outParams) : {};
				// Mock数据
				/* eslint-disable */
				if (
					(!outParams || !Object.entries(outParams).length) &&
					this.$route.path === '/modify-page'
				) {
					const mockData = this.element.database.mockData || [];
					mockData.forEach((ele) => {
						outParams[ele.paramsKey] = ele.paramsValue;
					});
				}
			}
			// 请求方式  dataList dataInfo updateData createData deleteData relationList moreAction
			if (!database.objectData) {
			this.loading = false;
				return
			};
			if (!database.viewData && !database.relationData) {
			this.loading = false;
				return
			};;
			let __method_name__ = database.requestType;
			const mapping = database.mapping;
			let paramObj = null;
			if (mapping === 'object') {
				paramObj = {
					__method_name__,
					object_uuid: database.objectData.uuid,
					view_uuid: database.viewData.uuid,
					search,
					...params,
					...outParams
				};
			} else if (mapping === 'relation') {
				__method_name__ = 'relationList';
				paramObj = {
					__method_name__: 'relationList',
					object_uuid: database.objectData.uuid,
					relationship_uuid: database.relationData.uuid,
					search,
					...params,
					...outParams
				};
			}
			if (__method_name__ !== 'dataList' && 	__method_name__ !== 'relationList' ) {
				this.$message.error('循环容器仅支持列表及关联列表数据!');
				return;
			}
			dataInterface(paramObj)
				.then((res) => {
					if (res.status === 200) {
						// 列表数据
						this.renderData = this.getListFromRes(res) || [];
						// 完全数据
						this.fullData = res.data.data || {};
						// 保存容器数据
						this.loopMetadata = Array.isArray(this.element.metadata) || !this.element.metadata || isInit ?  res.data.metadata : this.element.metadata
						this.$store.commit('modifyComponent', {
							component: {
								...this.element,
								containerData: this.renderData,
								fullData: this.fullData,
								metadata: this.loopMetadata
							},
							containerId: null,
							isModify: true,
							pageUUID: this.EDITOR_pageUUID
						});
					}
					this.loading = false;
				})
				.catch((err) => {
					this.loading = false;
				});
		},
		/**
		 * @desc: 获取请求参数
		 * @param {Array} config 请求参数配置
		 */
		getParams(config, data) {
			if (!config || !data || !data.output) return {};
			let result = {};
			for (let key in data.output) {
				if (key === config.paramKey) {
					result[config.key] = data.output[key];
				}
			}
			return result;
		},
		/**
		 * @desc: 根据请求返回获取列表结构
		 * @param {Object} res 请求返回的数据
		 */
		getListFromRes(res) {
			if (res.data && Array.isArray(res.data)) {
				return res.data;
			}
			if (res.data && typeof res === 'object') {
				return this.getListFromRes(res.data);
			}
			return [];
		},
		/**
		 * @desc: 判断前后端操作标识是否已知
		 * @param {String} beAction 后端标识
		 * @param {String} feAction 前端标识
		 * @return {Boolean}
		 */
		isSameAction(beAction, feAction) {
			if (feAction === 'any') return true;
			const feToBe = {
				list: 'dataList',
				add: 'createData',
				detail: 'dataInfo',
				modify: 'updateDate',
				delete: 'deleteData'
			};
			return beAction === feAction || beAction === feToBe[feAction];
		}
	},
	beforeDestroy() {
		eventBus.$off('databaseTrigger');
	}
};
</script>

<style lang="less" scoped>
.common-loop-container {
	height: 100%;
	width: 100%;
}
</style>
