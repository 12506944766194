<!--
 * @Author: 朱廷果 1028509503@qq.com
 * @Date: 2022-09-05 14:13:22
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2022-12-07 17:20:04
 * @FilePath: \dataview-next\src\manage-views\views\home\components\MenuBottom.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="menu-bottom-icon">
    <div class="menu-item-container">
      <!-- <el-tooltip
        effect="dark"
        placement="right"
        :open-delay="1000"
        style="margin-top: 12px"
      >
        <template #content>
          <p>新增应用</p>
        </template>
        <div class="icon-container add-app" @click="addApp()">
          <i class="iconfont iconxinzeng"></i>
        </div>
      </el-tooltip> -->
      <el-tooltip
        effect="dark"
        placement="right"
        :open-delay="1000"
        style="margin-top: 12px"
      >
        <template #content>
          <p>联系人</p>
        </template>
        <div class="icon-container" @click="jumpRouter('user')">
          <i class="iconfont icona-lianxiyonghutuandui"></i>
        </div>
      </el-tooltip>
      <el-tooltip
        effect="dark"
        placement="right"
        :open-delay="1000"
        style="margin-top: 12px"
      >
        <template #content>
          <p>帮助</p>
        </template>
        <div class="icon-container" @click="jumpRouter('help')">
          <i class="iconfont icona-bangzhuyiwen"></i>
        </div>
      </el-tooltip>
      <!-- <el-tooltip
        effect="dark"
        placement="right"
        :open-delay="1000"
        style="margin-top: 12px"
      >
        <template #content>
          <p>切换风格</p>
        </template>
        <el-popover
          placement="right"
          width="140"
          trigger="click"
          >
          <div class="list">
            <div class="list-title">切换风格</div>
            <div v-for="(item,index) in styleList" :key="index" class="list-item" style="height: 40px" @click="changeStyle(item)">
              <div class="item-left">
                <div class="company-name">{{item.name}}</div>
              </div>
              <div class="item-right">
                <i v-if="item.id == styleNum" class='iconfont iconselect-duigou company-select'></i>
              </div>
            </div>
          </div>
          <el-button slot="reference" class='pop-button'>
            <div class="icon-container">
              <i class="iconfont iconshezhipeizhi"></i>
            </div>
          </el-button>
        </el-popover>
      </el-tooltip>
      <el-tooltip
        effect="dark"
        placement="right"
        :open-delay="1000"
        style="margin-top: 12px"
      >
        <template #content>
          <p>切换企业</p>
        </template>
        <el-popover
          placement="right"
          width="140"
          trigger="click"
          >
          <div class="list">
            <div class="list-title">{{ companyList && companyList.length ? '切换企业' : '暂无企业'}}</div>
            <div v-for="(item,index) in companyList" :key="index" class="list-item" @click="goCompany(item)">
              <div class="item-left">
                <img v-if="item.logo" :src="item.logo" alt="">
                <div class="text-icon" v-else>{{item.name.slice(0,1)}}</div>
                <div class="company-name">{{item.name}}</div>
              </div>
              <div class="item-right">
                <i v-if="item.id == company_id" class='iconfont iconselect-duigou company-select'></i>
                <i v-else class="iconfont iconxiangyoujiantou2"></i>
              </div>
            </div>
          </div>
          <el-button slot="reference" class='pop-button'>
            <div class="icon-container">
              <i class="iconfont iconshezhi2"></i>
            </div>
          </el-button>
        </el-popover>
      </el-tooltip> -->
      <div class="border"></div>
      <el-tooltip
        effect="dark"
        placement="right"
        :open-delay="1000"
        style="margin: 12px 0"
      >
        <template #content>
          <p>展开/收起</p>
        </template>
        <div class="icon-container" @click="closeMenuItem()">
          <svg
            :style="menuItemVisible ? '' : 'transform: rotate(180deg);'"
            viewBox="64 64 896 896"
            data-icon="menu-fold"
            width="1em"
            height="1em"
            fill="currentColor"
            aria-hidden="true"
            focusable="false"
          >
            <path
              d="M408 442h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8zm-8 204c0 4.4 3.6 8 8 8h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56zm504-486H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 632H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM115.4 518.9L271.7 642c5.8 4.6 14.4.5 14.4-6.9V388.9c0-7.4-8.5-11.5-14.4-6.9L115.4 505.1a8.74 8.74 0 0 0 0 13.8z"
            ></path>
          </svg>
        </div>
      </el-tooltip>
    </div>

    <!-- 编辑应用弹窗 -->
    <el-dialog
      title="新增应用"
      :fullscreen="false"
      :visible.sync="dialogVisible"
      width="600px"
      append-to-body
      custom-class="create-app-dialog"
    >
      <div v-if="dialogVisible" class="common-body">
        <el-form
          v-if="dialogVisible"
          ref="form"
          :model="appFormData"
          :rules="rules"
          label-position="top"
          label-width="80px"
        >
          <div class="wrapper">
            <el-form-item
              class="form-item-height"
              label="应用图标"
              prop="icon"
            >
              <el-select :value="other" style="width: 100%" placeholder="" class="select-icon">
                <template slot="prefix">
                  <div
                    class="header-img"
                    :style="'background: ' + appFormData.background">
                    <i
                      :style="'color:' + appFormData.color"
                      :class="
                        appFormData.icon
                          ? appFormData.icon
                          : 'el-icon-platform-eleme'
                      "
                    ></i>
                  </div>
                </template>
                <template #empty>
                  <div class="choose-color">
                    <p>选择图形颜色</p>
                    <div class="color-container">
                      <div
                        class="color-item"
                        :style="'background:' + item.color"
                        v-for="(item, index) in colorList"
                        :key="index"
                        @click="
                          (appFormData.color = item.color),
                            (appFormData.background = item.background)
                        "
                      >
                        <i
                          v-if="appFormData.color === item.color"
                          class="iconfont iconselect-duigou"
                        ></i>
                      </div>
                    </div>
                    <p>选择图形</p>
                    <div
                      class="color-container"
                      style="flex: 1; overflow-y: auto"
                    >
                      <div
                        class="color-item"
                        v-for="(icon, index) in iconList"
                        :key="index"
                        :style="
                          appFormData.icon === icon
                            ? 'background:' +
                              appFormData.background +
                              ';color:' +
                              appFormData.color
                            : ''
                        "
                        @click="appFormData.icon = icon"
                      >
                        <i
                          class="color-item-i"
                          :style="
                            appFormData.icon === icon
                              ? 'color:' + appFormData.color
                              : ''
                          "
                          :class="icon"
                        ></i>
                      </div>
                    </div>
                  </div>
                </template>
              </el-select>
            </el-form-item>
            <el-form-item class="form-item-height" style="margin-bottom: 14px;" label="名称" prop="name">
              <el-input
                v-model="appFormData.name"
                clearable
                placeholder="请输入名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="form-item-height" style="margin-bottom: 14px;" label="描述"
            >
              <el-input
                v-model="appFormData.remarks"
                clearable
                placeholder="请输入描述"
              ></el-input>
            </el-form-item>
            <el-form-item
              v-if="appFormData.menu_type === 1"
              class="form-item-height"
              style="margin-bottom: 14px;"
              label="可见范围"
              prop="visible_range"
            >
              <el-select
                v-model="appFormData.visible_range"
                style="width: 100%">
                <el-option :value="0" label="工作区可见">工作区可见</el-option>
                <el-option :value="1" label="指定架构可见">指定架构可见</el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="
                appFormData.menu_type === 1 &&
                +appFormData.visible_range === 1
              "
              class="form-item-height required-star"
              style="margin-bottom: 14px;"
              label="架构选择"
            >
              <el-select
                :value="archiRange"
                style="width: 100%"
                placeholder=""
              >
                <template slot="prefix">
                  <div class="show-name">
                    {{ archiName ? archiName : "请选择架构" }}
                  </div>
                </template>
                <template #empty>
                  <div class="choose-color" style="width: 420px">
                    <el-tree
                      ref="treeRef"
                      v-loading="treeLoading"
                      :data="treeData"
                      default-expand-all
                      node-key="id"
                      :props="{
                        label: 'name',
                        children: 'children',
                      }"
                      show-checkbox
                      :check-strictly="true"
                      style="height: 100%"
                      @check="treeSelectCheck"
                    >
                      <template #default="{ node, data }">
                        <div class="archi-label">
                          <span class="archi-label-svg">
                            <svg aria-hidden="true" class="color-icon">
                              <use :xlink:href="getIcon(data, 'label')" />
                            </svg>
                            <span>{{ node ? data.name : "" }}</span>
                          </span>
                        </div>
                      </template>
                    </el-tree>
                  </div>
                </template>
              </el-select>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button :loading="submitLoading" type="primary" @click="editSubmit"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Tooltip, Tree } from "element-ui";
import { userUseCompany } from "@/api/userInfo";
import { createdApps } from "@/api/myApps";
import { elementIcons } from "@/utils/icons";
// import { mapiRequest } from "@/apis/http/saasMapiRequest";
import { departmentFilter } from '@/api/saas'
export default {
  components: {
    // 'el-popover': Popover,
    "el-tooltip": Tooltip,
    "el-tree": Tree,
  },
  props: {
    menuList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      companyList: null,
      company_id: null,
      menuItemVisible: true,
      styleNum: +localStorage.getItem("system_style"),
      styleList: [
        {
          id: 1,
          name: "纯蓝",
        },
        {
          id: 2,
          name: "暗黑",
        },
      ],
      dialogVisible: false,
      appFormData: {
        remarks: "",
        icon: "el-icon-platform-eleme",
        name: "",
        color: "#fb9337",
        visible_range: 0,
        background: "#ffebdb",
        menu_id: 0
      },
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        remarks: [{ required: true, message: "请输入描述", trigger: "blur" }],
        icon: [{ required: true, message: "请选择图标", trigger: "change" }],
        visible_range: [
          { required: true, message: "请选择可见范围", trigger: "change" },
        ],
      },
      iconList: elementIcons,
      colorList: [
        {
          color: "#fb9337",
          background: "#ffebdb",
        },
        {
          color: "#fab300",
          background: "#fff1d8",
        },
        {
          color: "#67c200",
          background: "#e7f4d7",
        },
        {
          color: "#00bd77",
          background: "#ddf4e6",
        },
        {
          color: "#00c5fb",
          background: "#e2f4ff",
        },
        {
          color: "#268bfb",
          background: "#e3e9ff",
        },
        {
          color: "#6468fb",
          background: "#e8e2ff",
        },
        {
          color: "#392fc2",
          background: "#e1d7f5",
        },
        {
          color: "#9e64fb",
          background: "#f0e3ff",
        },
        {
          color: "#d164fb",
          background: "#f9e4ff",
        },
        {
          color: "#fb4b51",
          background: "#ffe1dd",
        },
        {
          color: "#fa6f32",
          background: "#ffe6d9",
        },
      ],
      other: "",
      archiRange: "",
      treeData: [], // 架构数据
      treeLoading: false, // 架构请求loading
      archiName: "",
      submitLoading: false,
    };
  },
  created() {
    this.getArchiTreeList();
  },
  mounted() {
    this.companyList = JSON.parse(localStorage.getItem("userInfo")).saas;
    this.company_id = localStorage.getItem("company_id");
  },
  methods: {
    goCompany(data) {
      if (data.id == this.company_id) {
        return false;
      }
      userUseCompany({ company_id: data.id }).then((res) => {
        if (res.data && res.data.code !== 200) {
          return false;
        }
        localStorage.setItem(
          "saasTargetArchi",
          res?.data?.data?.department_info
        );
        localStorage.setItem("saas", JSON.stringify(data)); // 当前选择的系统的所有信息
        localStorage.setItem("company_id", res?.data?.data?.company_id);
        this.$router.push("/home");
      });
    },
    /**
     * @desc: 收起功能菜单
     * @param {*}
     * @return {*}
     */
    closeMenuItem() {
      this.menuItemVisible = !this.menuItemVisible;
      this.$emit("closeMenuItem", this.menuItemVisible);
    },
    changeStyle(data) {
      this.styleNum = +data.id;
      this.$store.commit("setSystemStyle", this.styleNum);
    },
    jumpRouter(type) {
      if (type === "user") {
        this.$router.push({ path: "/org/user" });
        let data = {};
        if (this.menuList?.length) {
          const loop = (array) => {
            array.forEach((element) => {
              if (element.route === "/org/user") {
                data = element;
              }
              if (element?.children?.length) {
                loop(element.children);
              }
            });
          };
          loop(this.menuList);
        }
        if (data && data.id) {
          this.$emit("currentMenuList", data);
        }
      } else if (type === "help") {
        this.$router.push({ path: "/web-page/doc-center" });
      }
    },
    getArchiTreeList() {
      this.treeLoading = false;
      departmentFilter().then((res) => {
        this.treeLoading = false;
        if (res.status === 200 && res.data.code === 200) {
          const data = res.data?.data;
          const chooseArr = [1, 2, 3, 4, 5, 6];
          const loopTree = (arr) => {
            arr.forEach((element) => {
              if (element.children && element.children.length) {
                element.children = element.children.filter((ite) => {
                  return chooseArr.indexOf(+ite.type) !== -1;
                });
                loopTree(element.children);
              }
            });
          };
          if (data && data.length) {
            loopTree(data);
          }
          this.treeData = data;
        }
      });
    },
    /* 点击新增应用 */
    addApp() {
      this.appFormData = {
        remarks: "",
        icon: "el-icon-platform-eleme",
        name: "",
        color: "#fb9337",
        visible_range: 0,
        background: "#ffebdb",
        menu_type: 1,
        menu_id: 0
      };
      this.archiName = "";
      this.dialogVisible = true;
    },
    treeSelectCheck() {
      const checkedKeys = this.$refs.treeRef.getCheckedKeys();
      this.appFormData.range_id = checkedKeys || [];
      let archiName = "";
      const loopTree = (arr) => {
        arr.forEach((element) => {
          if (checkedKeys.includes(+element.id)) {
            if (archiName) {
              archiName += ", " + element.name;
            } else {
              archiName = element.name;
            }
          }
          if (element.children && element.children.length) {
            loopTree(element.children);
          }
        });
      };
      if (checkedKeys && checkedKeys.length) {
        loopTree(this.treeData);
      }
      this.archiName = archiName;
    },
    getIcon(data) {
      const group = [1, 2];
      const company = [3, 4];
      const project = [5, 6];
      const section = [7];
      if (!data) {
        return "#iconjituan";
      }
      if (group.indexOf(+data.type) !== -1) {
        return "#iconjituan";
      } else if (company.indexOf(+data.type) !== -1) {
        return "#icongongsi";
      } else if (project.indexOf(+data.type) !== -1) {
        return "#iconxiangmu";
      } else if (section.indexOf(+data.type) !== -1) {
        return "#iconbumen";
      }
    },
    editSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const {
            menu_id,
            remarks,
            icon,
            name,
            background,
            color,
            visible_range,
            range_id,
          } = this.appFormData;
          const params = {
            remarks,
            icon,
            name,
            background,
            color,
            menu_id,
            visible_range,
            range_id,
          };
          if (!this.appFormData.menu_type || this.appFormData.menu_type !== 1) {
            params.visible_range = 0;
            delete params.range_id;
          }
          if (
            this.appFormData.menu_type &&
            this.appFormData.menu_type === 1 &&
            visible_range === 1 &&
            (!range_id || range_id.length === 0)
          ) {
            this.$message.warning("请选择架构！");
            return;
          }
          params.menu_type = 1;
          params.type = 1;
          params.company_id = localStorage.getItem("company_id");
          this.submitLoading = true;
          if (!params.menu_id || +params.meue_id === -1) {
            params.menu_id = 0
          }
          createdApps(params)
            .then((res) => {
              this.submitLoading = false;
              if (res.status === 200 && res.data.code === 200) {
                this.$message.success("新增成功！");
                this.dialogVisible = false;
                this.$emit("updateMenuList", true);
              }
            })
            .catch(() => {
              this.submitLoading = false;
              this.dialogVisible = false;
            });
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../../css/manageAdd.less";
/* 修改select下input展示 */
:deep(.select-icon) {
  .el-input {
    width: 48px;
    height: 48px;
    background: #FFFFFF;
    border-radius: 4px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;

    input {
      display: none;
    }
    .el-input__suffix {
      display: none;
    }
    .el-input__prefix {
      width: 48px;
      height: 48px;
      i {
        font-size: 18px;
        color: #000;
      }
    }
  }
}
.menu-bottom-icon {
  width: 100%;
  text-align: center;
  line-height: 60px;
  .menu-item-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    text-align: center;
    align-items: center;
    justify-content: center;
    .border {
      width: 100%;
      border-top: 1px solid rgba(255, 255, 255, 0.2);
      margin-top: 12px;
    }
    i {
      flex: 1;
      line-height: 28px;
    }
    span {
      flex: 1;
      line-height: 18px;
      font-size: 12px;
    }
    .add-app {
      border: 1px dashed rgb(255, 255, 255);
    }
    .icon-container {
      color: #fff;
      opacity: 0.8;
      width: 36px;
      height: 36px;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      svg {
        transition-duration: 0.3s;
        width: 20px;
        height: 20px;
      }
      i {
        font-size: 20px;
      }
    }
    .icon-container:hover {
      opacity: 1;
      background-color: rgba(250, 251, 252, 0.2);
    }
    .pop-button {
      width: 100%;
      background: transparent;
      border: none;
      color: white;
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        opacity: 0.8;
      }
    }
  }
}

.list {
  box-sizing: border-box;
  overflow: hidden;
  overflow-y: auto;
  .list-title {
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 5px;
    box-sizing: border-box;
  }
  .list-item {
    padding: 5px;
    margin: 5px 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #d5d5d5;
    border-radius: 5px;
    cursor: pointer;
    .item-left {
      display: flex;
      align-items: center;
      .text-icon {
        width: 30px;
        height: 30px;
        background: #4d81fb;
        color: #fff;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        line-height: 30px;
        border-radius: 10px;
      }
      img {
        display: block;
        width: 40px;
      }
      .company-name {
        width: 76px;
        font-size: 14px;
        font-weight: 600;
        padding-left: 10px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .item-right {
      i {
        font-size: 24px;
      }
      color: #aaa;
      .company-select {
        color: #4d81fb;
      }
    }
  }
}
/* 新增应用弹窗样式 */
/* 选择颜色图标 */
.choose-color {
  width: 460px;
  height: 300px;
  display: flex;
  flex-direction: column;
  padding: 10px 0 10px 10px;
  box-sizing: border-box;
  p {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: #121315;
    margin: 16px 0;
  }
  .color-container {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    .color-item {
      width: 26px;
      height: 26px;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      i {
        color: #fff;
      }
      .color-item-i {
        color: #606266;
      }
    }
  }
}
/* 架构选择样式 */
.archi-label {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  -moz-user-select: none; /* Firefox私有属性 */
  -webkit-user-select: none; /* WebKit内核私有属性 */
  -ms-user-select: none; /* IE私有属性(IE10及以后) */
  -khtml-user-select: none; /* KHTML内核私有属性 */
  -o-user-select: none; /* Opera私有属性 */
  user-select: none; /* CSS3属性 */
  .archi-label-svg {
    width: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    span {
      flex: 1;
      overflow: hidden;
      padding-right: 6px;
      box-sizing: border-box;
    }
    img {
      width: 20px;
      height: 20px;
      margin-right: 6px;
    }
    svg {
      width: 20px;
      height: 20px;
      margin-right: 6px;
    }
  }
}
.common-body {
  height: auto;
}
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* 头像显示 */
  .header-img {
    width: 48px;
    height: 48px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      font-size: 26px;
    }
  }
  .form-item-height {
    width: 100%;
    .show-name {
      width: 100%;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-right: 40px;
      box-sizing: border-box;
    }
  }
}
</style>
