/*
 * @Author: zx
 * @Date: 2022-05-13 14:32:20
 * @LastEditTime: 2022-09-23 16:30:35
 * @Description: 
 * @LastEditors: 朱廷果 1028509503@qq.com
 */
import request from '@/apis/http/saasRequest.js'
// 登录/注册 获取验证码
export function getLoginCode(data) {
  return request({
    url: 'api/v4/phoneSendMsg',
    method: 'post',
    data
  })
}

// 忘记密码获取验证码
export function getChangePassCode(data) {
  return request({
    url: 'api/saas/sendChangePassCode',
    method: 'post',
    data
  })
}

export function getChangePassCodeNew(data) {
  return request({
    url: 'api/saas/retrieveSendPhoneCode',
    method: 'post',
    data
  })
}

export function reSetPassword(data) {
  return request({
    url: 'api/saas/retrievePhonePass',
    method: 'post',
    data
  })
}

// 忘记密码提交
export function changePassLogin(data) {
  return request({
    url: 'api/saas/changePassLogin',
    method: 'post',
    data
  })
}

// 通过token直接进入
export function userInfo(data) {
  return request({
    url: 'api/saas/userInfo',
    method: 'get',
    params:{
      data
    }
  })
}
