<template>
    <div class="wrapPanel">
        <div class="title-wrap">
            <span>模型树</span>
            <a :class="['iconfont iconjijia_liebiao2', {'showUnload-btn_active': isShowUnload}]" @click="onShowUnload"></a>
        </div>

        <div v-show="isShowUnload" class="unload-wrap">
            <el-tree class="unloadModelTree-wrap"
                     ref="unloadModelTree"
                     empty-text="暂无数据"
                     :data="unLoadModelList"
                     :props="treeProps"
                     :default-expand-all="true"
                     :expand-on-click-node="false"
                     node-key="id"
                     highlight-current
                     :check-on-click-node="false"
                     :check-strictly="false"
                     :show-checkbox="true"
                     @check="onCheckNode"
            >
                <template #default="{ node, data }">
                    <div :class="['horizontal-flex tree-node-unload']">
                        <!--<el-popover placement="bottom" trigger="click">
                            <template #reference>
                                <div class="horizontal-flex">
                                    <a :class="['tree-node-icon iconfont ', getIconByType(data.type)]"></a>
                                    <div v-if="data.type==='model'" class="tree-node-info"></div>
                                </div>
                            </template>
                            <div class="vertical-flex">
                                <span style="line-height: 25px">模型大小：{{data.size}}</span>
                                <span style="line-height: 25px">上传日期：{{data.uploadDate}}</span>
                            </div>
                        </el-popover>-->
                        <a :class="['tree-node-icon iconfont ', getIconByType(data.type)]"></a>
                        <div class="tree-node-content">{{ data.name + getModelLength(data)}}</div>
                        <el-popconfirm title="是否卸载模型?"
                                       confirm-button-text="是"
                                       cancel-button-text="否"
                                       @confirm="onDelete(data)">
                            <template #reference>
                                <a v-show="data.isLoaded" class="iconfont iconbimgis_guanbi tree-node-delete"></a>
                            </template>
                        </el-popconfirm>
                    </div>
                </template>
            </el-tree>

            <div :class="['unload-btn', {'unload-btn_active': checkModelList.length > 0}]" @click="onAppend">确定加载{{checkModelList.length}}个模型</div>
        </div>

        <el-tree ref="modelTree"
                 class="modelTree-wrap"
                 empty-text="暂无数据"
                 :data="modelList"
                 :props="treeProps"
                 :expand-on-click-node="false"
                 :default-expanded-keys="modelExpanded"
                 node-key="id"
                 :lazy="true"
                 :load="onLoadModelNode"
                 highlight-current
                 :check-on-click-node="false"
                 :check-strictly="false"
                 :show-checkbox="true"
                 @check=onCheckModelNode
        >
            <template #default="{ node, data }">
                <div :class="['horizontal-flex tree-node']">
                    <a v-if="data.type==='file'||data.type==='model'" :class="['tree-node-icon iconfont ', getIconByType(data.type, data.modelType)]"></a>
                    <span class="tree-node-content" @click="onRadioModelNode(node, data)" @dblclick="onDblClickModelNode(node, data)">{{ data.name}}</span>
                    <a v-if="data.type==='model'&&!data.isLoaded" class="iconfont iconshuaxin tree-node-loading" style="margin-right: 5px"></a>
                    <a v-if="data.type==='model'" class="iconfont icongis_dingwei tree-node-location" @click="onLocationModel(data)"></a>
                </div>
            </template>
        </el-tree>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import eventBus from '@/plugins/eventBus';
    import { Tree, Popconfirm } from 'element-ui';
    import { dataInterface } from '@/apis/data/index';
    let treeCollection = {};

    export default {
        name: "ViewerGISModel",
        components: {
            'el-tree': Tree,
            'el-popconfirm': Popconfirm
        },
        props: {
            element: {
                type: Object,
                required: true,
                default: () => {
                    return {
                    }
                }
            },
            // 是否在组合内
            isGroup: {
                type: Boolean,
            },
            // 组合内组件列表
            groupComponents: {
                type: Array,
                default: () => []
            },
            bindViewerId: {
                type: String,
            },
        },
        data(){
            return {
                isShowUnload: false,
                checkModelList: [],
                unLoadModelList: [],
                modelList: [],
                modelExpanded: [],
                treeProps: {
                    label: 'name',
                    children: 'children',
                    isLeaf: 'isLeaf',
                },
            }
        },
        watch: {
            bindViewerId: {
                handler() {
                    this.getModelData();
                },
            },
        },
        computed: {
            ...mapState(["curComponent"]),
        },
        mounted(){
            this.getModelData();
            this.initEventListener();
        },
        methods: {
            initEventListener(){
                eventBus.$on("ViewerGIS.onModelLoaded" , (eid , model_id) =>{
                    if(eid !== this.bindViewerId) return;

                    this.setModelNodeLoaded(model_id);
                });
            },
            getModelData(){
                if(!this.bindViewerId) {
                    this.checkModelList = [];
                    this.unLoadModelList = [];
                    this.modelList = [];
                    this.modelExpanded = [];
                    return;
                }

                const handleData = (data , list) => {
                    const node = {
                        id : data.id,
                        name : data.name,
                        type: "file",
                        isLoaded : false,
                    };
                    const children = [];

                    if(data.gis_type && data.gis_type.length > 0){
                        for(let { model_id , name , modelSize , updated_at } of data.gis_type){
                            if(!model_id) continue;
                            if(model_id === '7d0310a1172141f98aa265352c555d42') continue;

                            children.push({
                                id : model_id,
                                name : name,
                                type : "model",
                                size : modelSize,
                                uploadDate : updated_at,
                                isLeaf: true,
                                isLoaded : false,

                            });
                        }
                    }

                    node.children = children;
                    list.push(node);

                    if(data.children && data.children.length > 0 ){
                        for(let child of data.children){
                            handleData(child, children);
                        }
                    }
                };

                const db = {
                    object_uuid:'object62624ddb0a688',
                    view_uuid:'view62624f04dabbb',
                };

                dataInterface({
                    __method_name__ : "dataList",
                    object_uuid: db.object_uuid,
                    view_uuid: db.view_uuid,
                    transcode: 0,
                    // search: [{code: "archi_type", ruleType: "eq", value: "org_projects-1"}]
                }).then( async ( res )=>{
                    if (res && res.status === 200) {
                        const data = res.data.data;

                        const treeData = [];

                        for(let obj of data){
                            handleData(obj, treeData);
                        }

                        this.unLoadModelList = treeData;
                    }
                });
            },
            getModelLength(data){
                if(data.type !== 'file') return '';

                let leaf = [];
                this.getLeafNodes(data, leaf);

                return `(${leaf.length})`;
            },
            getLeafNodes(node, leaf = []){
                if(node.type === 'model') leaf.push(node);
                if(node.children && node.children.length > 0){
                    for(let item of node.children){
                        if(item.type === 'model') leaf.push(item);

                        if(item.children && item.children.length > 0){
                            this.getLeafNodes(item, leaf);
                        }
                    }
                }
                return leaf;
            },
            getIconByType(type, modelType = undefined){
                switch (type) {
                    case 'file':return 'iconjijia_liebiao2';
                    case 'model':
                        if(!modelType) return 'iconBIM';
                        switch (modelType) {
                            case 'bim':return 'iconBIM1';
                            case 'gltf':return 'icongltf';
                            case 'osgb':return 'iconosgb';
                            default: return 'iconBIM';
                        }
                    default: return '';
                }
            },
            getModelNodeByFile(fileNode, leaf = []){
                if(fileNode.type === 'model') leaf.push(fileNode);
                if(fileNode.type === 'file' && fileNode.children && fileNode.children.length > 0){
                    for(let item of fileNode.children){
                        if(item.type === 'model') leaf.push(item);

                        if(fileNode.type === 'file' && item.children && item.children.length > 0){
                            this.getLeafNodes(item, leaf);
                        }
                    }
                }
                return leaf;
            },
            getFeatureLeafByNodes(nodes, leaf = []){
                for (let item of nodes){
                    if(item.isLeaf){
                        leaf.push(item.dbid);
                        continue;
                    }

                    const tree = treeCollection[item.modelId];
                    getLeafDbid(tree, item.dbid, leaf);
                }
                return leaf;

                function getLeafDbid(tree, key, leaf) {
                    let childIndex = tree.findIndex(x=>x.pid === key);

                    if(childIndex === -1){
                        leaf.push(key);
                        return;
                    }

                    for (let index in tree){
                        if(tree[index].pid !== key) continue;
                        getLeafDbid(tree, tree[index].dbid, leaf);
                    }
                }
            },
            setUnloadModelNodesCheck(keys){
                const el = this.$refs.unloadModelTree;
                for (let item of keys){
                    let node = el.getNode(item);
                    if(!node) continue;

                    el.setChecked(item, true);
                    this.checkModelList.push(node.data);
                }
                this.onAppend();
            },
            setUnloadModelNodeLoaded(key){
                const el = this.$refs.unloadModelTree;
                let node = el.getNode(key);
                if(!node) return;

                if(typeof node.data.isLoaded === "undefined"){
                    this.$set(node.data, 'isLoaded', true)
                }else{
                    node.data.isLoaded = true;
                }
            },
            async loadModelNodeCallback(node){
                return await new Promise((resolve)=> {
                    let children = [];
                    if(node.modelType === 'bim'){
                        const tree = treeCollection[node.modelId];
                        for (let key in tree){
                            if(tree[key].pid === node.dbid) {
                                let childIndex = tree.findIndex(x=>x.pid === tree[key].dbid);
                                let temp = Object.assign(tree[key], {
                                    type: 'feature',
                                    id: node.modelId + tree[key].dbid,
                                    modelId: node.modelId,
                                    modelType: node.modelType,
                                    isLeaf: childIndex === -1 ? true : false
                                });
                                children.push(temp);
                            }
                        }
                    }
                    resolve({leafs: children});
                });
            },
            appendModelList(nodes, checked = []){
                const el = this.$refs.modelTree;
                let expand = [];

                for(let item of nodes){
                    let node = el.getNode(item.id);

                    if(!node) {
                        el.append(item, item.pId === -1 ? null : item.pId);

                        if (item.level <= 2 && item.type !== 'model') expand.push(item.id);

                        if (item.children && item.children.length > 0){
                            item.children.forEach(x => expand.push(x.id));
                        }
                    }
                }

                this.$nextTick(()=>{
                    if(checked.length > 0) this.setModelNodesCheck(checked, true);

                    this.modelExpanded = expand;
                });
            },
            deleteModelList(leaf, nodes){
                this.modelExpanded = [];
                const el = this.$refs.modelTree;
                for (let item of leaf){
                    let node = el.getNode(item.id);
                    if(!node) continue;

                    el.remove(item.id);
                }

                for (let item of nodes){
                    let node = el.getNode(item.id);
                    if(!node) continue;

                    if(node.childNodes.length === 0) el.remove(item.id);
                }
            },
            setModelNodesCheck(nodes, checked){
                const el = this.$refs.modelTree;
                for (let key in nodes){
                    el.setChecked(nodes[key] instanceof Object ? nodes[key].id : nodes[key], checked, true);
                }
            },
            setModelNodeProps(key, props){
                const el = this.$refs.modelTree;
                let node = el.getNode(key);
                if(!node) return;

                if(!props) return;
                for (let key in props){
                    if(typeof node.data[key] === "undefined"){
                        this.$set(node.data, key, props[key]);
                    }else {
                        node.data[key] = props[key];
                    }
                }
            },
            setModelNodeLoaded(key){
                const el = this.$refs.modelTree;
                let node = el.getNode(key);
                if(!node) return;

                if(typeof node.data.isLoaded === "undefined"){
                    this.$set(node.data, 'isLoaded', true);
                }else{
                    node.data.isLoaded = true;
                }
            },
            setModelShow(data, isShow){
                if(data.modelType === 'bim'){
                    let leaf = this.getFeatureLeafByNodes([data]);
                    eventBus.$emit('ViewerGIS.showFeature', this.bindViewerId, data.modelId, leaf, isShow);
                }else{
                    eventBus.$emit('ViewerGIS.showModel', this.bindViewerId, data.modelId, isShow);
                }
            },
            onShowUnload(){
                this.isShowUnload = !this.isShowUnload;
            },
            onHideUnload(){
                this.isShowUnload = false;
            },
            onCheckNode(data, checked){
                let leaf =[];
                let checkedNodes = checked.checkedNodes;
                for(let item of checkedNodes){
                    if(item.type === 'model' && !item.isLoaded) leaf.push(item);
                }

                this.checkModelList = leaf;
            },
            onAppend(){
                if(this.checkModelList.length === 0) {
                    this.$message.error('请勾选模型加载!');
                    return;
                }

                const el = this.$refs.unloadModelTree;
                let loadArray = [];
                let defaultChecked = [];
                let halfCheckedNodes = el.getHalfCheckedNodes();
                for(let item of halfCheckedNodes){
                    let temp = el.getNode(item.id);
                    let pId = -1;
                    if(temp.level > 1) pId = temp.parent.data.id;
                    loadArray.push({
                        id: item.id,
                        name: item.name,
                        pId: pId,
                        level: temp.level,
                        type: item.type,
                        // isLeaf: item.type === 'model',
                        isLoaded: item.type === 'model' ? false : undefined
                    })
                }

                let checkedNodes = el.getCheckedNodes();
                for(let item of checkedNodes){
                    if(!item.isLoaded){
                        let temp = el.getNode(item.id);
                        let pId = -1;
                        if(temp.level > 1) pId = temp.parent.data.id;
                        loadArray.push({
                            id: item.id,
                            name: item.name,
                            pId: pId,
                            level: temp.level,
                            type: item.type,
                            // isLeaf: item.type === 'model',
                            isLoaded: item.type === 'model' ? false : undefined
                        });
                        if(item.type === 'model') defaultChecked.push(item.id);
                    }

                    this.setUnloadModelNodeLoaded(item.id);
                    item.disabled = true;
                    el.setCurrentKey(item.id);
                }

                this.checkModelList = [];
                this.appendModelList(loadArray, defaultChecked);
                this.onHideUnload();

                for (let key of defaultChecked){
                    eventBus.$emit('ViewerGIS.createModel', this.bindViewerId,  key, true, (props, tree) => {
                        this.setModelNodeProps(key, {
                            type: 'model',
                            modelId: key,
                            modelType: props.modelType,
                            dbid: 1,
                            root: true,
                            isLeaf: props.modelType === 'bim' ? false : true
                        });
                        treeCollection[key] = tree;
                    });
                }
            },
            onDelete(data){
                const el = this.$refs.unloadModelTree;
                let beforeChecked = el.getCheckedNodes();
                let beforeHalfChecked = el.getHalfCheckedNodes();

                let leaf = [];
                this.getLeafNodes(data, leaf);
                for (let item of leaf){
                    el.setChecked(item.id, false, true);
                }

                let nodeList = [];
                let currentChecked = el.getCheckedNodes();
                let currentHalfChecked = el.getHalfCheckedNodes();
                for(let item of beforeHalfChecked){
                    if(currentHalfChecked.findIndex(x=>x.id === item.id) !== -1) continue;

                    nodeList.push(item);
                }
                for(let item of beforeChecked){
                    if(currentChecked.findIndex(x=>x.id === item.id) !== -1) continue;

                    item.isLoaded = false;
                    item.disabled = false;
                    el.setCurrentKey(item.id);
                    nodeList.push(item);
                }

                this.deleteModelList(leaf, nodeList.reverse());

                for (let item of leaf){
                    eventBus.$emit('ViewerGIS.removeModel', this.bindViewerId,  item.id);
                    delete treeCollection[item.id];
                }
            },
            onLoadModelNode(node, resolve){
                if(node.level === 0) return resolve(node.data);

                if(node.data.children) {
                    node.isLeaf = true;
                    return resolve(node.data.children);
                }

                if(!node.isLeaf && this.loadModelNodeCallback){
                    this.loadModelNodeCallback(node.data).then(({leafs = [], checkedList = [], checked = true})=>{
                        if(leafs.length > 0){
                            node.data.children = leafs;
                            node.isLeaf = false;

                            this.$nextTick(()=>{
                                this.setModelNodesCheck(checkedList, checked);
                            });

                            return resolve(node.data.children);
                        }else{
                            node.isLeaf = true;
                            return resolve([]);
                        }
                    })
                }else{
                    node.isLeaf = true;
                    return resolve([]);
                }
            },
            onLocationModel(data){
                eventBus.$emit('ViewerGIS.locationModel', this.bindViewerId,  data.id);
            },
            onCheckModelNode(data, checked){
                const checkedKeys = checked.checkedKeys;
                let isChecked = checkedKeys.findIndex(x => x === data.id) !== -1;
                if(data.type === 'file'){
                    let modelList = this.getModelNodeByFile(data);
                    for (let item of modelList){
                        this.setModelShow(item, isChecked);
                    }
                }else {
                    this.setModelShow(data, isChecked);
                }
            },
            onRadioModelNode(node, data){
                console.log(node, data);
            },
            onDblClickModelNode(node, data){
                console.log(node, data);
            }
        }
    }
</script>

<style lang="less" scoped>
    .wrapPanel{
        float: left;
        padding: 0 !important;
        overflow-y: hidden;
        background-color: inherit;
        color: inherit;

        .title-wrap{
            width: 100%;
            height: 38px;
            line-height: 38px;
            box-sizing: border-box;
            border-bottom: 1px solid rgba(255,255,255,0.3);
        }

        .title-wrap span{
            float: left;
            padding-left: 10px;
            font-size: 14px;
            color: inherit;
        }

        .title-wrap a{
            float: right;
            padding-right: 10px;
            font-size: 18px;
            color: inherit;
        }

        .showUnload-btn_active{
            color: #0076FF !important;
        }

        .unload-wrap{
            z-index: 9999;
            position: absolute;
            top: 90px;
            right: 55px;
            width: 400px;
            height: 500px;
            overflow: hidden;
            border: 1px solid rgba(255, 255, 255, 0.3);
            backdrop-filter: blur(3px);
            background-color: inherit;
            color: inherit;

            .unload-btn{
                float: right;
                margin-right: 15px;
                width: 120px;
                height: 32px;
                line-height: 32px;
                font-size: 14px;
                background-color: rgba(131, 131, 131, 0.5);
                color: inherit;
            }

            .unload-btn_active{
                background-color: #0076FF;
            }
        }
    }

    .unloadModelTree-wrap{
        background-color: transparent !important;
        color: inherit !important;
        overflow-y: auto;
        height: calc(100% - 50px);

        :deep(.el-tree-node__content) {
            height: 32px !important;
            background-color: transparent !important;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        :deep(.el-tree-node__content:hover) {
            background-color: rgba(131, 131, 131, 0.5) !important;
        }

        :deep(.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content) {
            background-color: rgba(131, 131, 131, 0.5) !important;
        }
    }

    .modelTree-wrap{
        background-color: transparent !important;
        color: inherit !important;
        overflow-y: auto;
        height: calc(100% - 38px);

        :deep(.el-tree-node__content) {
            height: 32px !important;
            background-color: transparent !important;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        :deep(.el-tree-node__content:hover) {
            background-color: rgba(131, 131, 131, 0.5) !important;
        }

        :deep(.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content) {
            background-color: rgba(131, 131, 131, 0.5) !important;
        }
        :deep(.el-tree-node__content>label.el-checkbox) {
            position: absolute !important;
            right: 0;
            margin-right: 13px !important;
            color: inherit;
        }

        :deep(.el-checkbox__inner) {
            background-color: transparent;
            border: none;
            height: 35px;
            color: inherit;
        }

        :deep(.el-checkbox__inner::after) {
            font-family: "iconfont";
            content: "\e745";
            color: inherit;
            font-size: 16px;
            line-height: 35px;
            position: absolute;
            height: 7px;
            top: 0;
            left: 0;
            width: 3px;
            border: none;
            background-color: transparent !important;
            transform: none !important;
            transition: none !important;
        }

        :deep(.el-checkbox__input.is-checked .el-checkbox__inner::after) {
            font-family: "iconfont";
            content: "\e739";
            color: #0076FF;
            font-size: 16px;
            background-color: transparent !important;
            transform: none !important;
            /*transition: none !important;*/
        }

        :deep(.el-checkbox__input.is-indeterminate .el-checkbox__inner::before) {
            font-family: "iconfont";
            content: "\e745";
            color: inherit;
            font-size: 16px;
            line-height: 35px;
            position: absolute;
            height: 7px;
            top: 0;
            left: 0;
            width: 3px;
            border: none;
            background-color: transparent !important;
            transform: none !important;
            transition: none !important;
        }

        :deep(.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner){
            background-color: transparent !important;
        }
    }

    .tree-node{
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        color: inherit;
        font-size: 14px;
        width: 80%;
        height: 35px;
        line-height: 35px;
        padding-right: 33px;
    }

    .tree-node-unload{
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        color: inherit;
        font-size: 14px;
        width: 100%;
        height: 35px;
        line-height: 35px;
        padding-right: 10px;
    }

    .tree-node-icon{
        margin-right: 3px;
        margin-left: -5px;
        margin-top: 2px;
        width: 20px;
    }

    .tree-node-info{
        position: relative;
        left: -11px;
        top: 2px;
        width: 8px;
        height: 8px;
        border-radius: 4px;
        background-color: red;
    }

    .tree-node-content{
        text-align: left;
        width: -webkit-fill-available;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .tree-node-loading{
        width: 20px;
        margin-top: 2px;
        animation: rotating 2s linear infinite;
    }

    .tree-node-location{
        width: 20px;
        margin-top: 2px;
    }

    .tree-node-location:hover{
        color: #0076FF !important;
    }

    .tree-node-delete{
        width: 20px;
    }

    .tree-node-delete:hover{
        color: #0076FF !important;
    }
</style>
