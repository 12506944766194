/*
 * @LastEditTime: 2022-08-04 15:08:55
 * @Description: 全局行为工厂
 * @FilePath: \dwh_front\src\global\actionFactory.js
 * @Date: 2022-01-12 14:09:55
 * @Author: lisushuang
 * @LastEditors: ttheitao
 */

import { Message,Notification } from "element-ui"

export default (instance,name,data) => {
  import("./actions/" + name).then(res => {
    instance = new res.default().bind(instance).data(data)
    instance.handler()
  }).catch(res => {
    console.error(res)
    // todo 连续多次调用message会导致样式错位，未找到原因
    // todo Notification 亦然
    Message.error("执行"+name+"行为失败！")

    if(process.env.NODE_ENV === 'production'){
      // do
    }else{
      // Message.error("执行"+name+"行为失败！")
      Notification.error({
        title: '错误',
        message: res,
        duration: 5000
      })
    }
  })
}