/*
 * @Description: 可选组件列表
 * @Author: luocheng
 * @Date: 2021-08-09 10:57:23
 * @LastEditors: zqy
 * @LastEditTime: 2022-11-09 14:45:31
 */
import baseComponent from '@/mobile-custom-components/component-config/bases';
import containers from '@/mobile-custom-components/component-config/containers';
import functionCom from '@/mobile-custom-components/component-config/functionCom';
import listComponent from '@/mobile-custom-components/component-config/list';
import addComponent from '@/mobile-custom-components/component-config/add';
import pageComponent from '@/mobile-custom-components/component-config/page';
import chartComponent from '@/mobile-custom-components/component-config/charts';
import modelComponent from '@/mobile-custom-components/component-config/models';

// 公共样式
export const commonStyle = {
	rotate: 0,
	opacity: 1,
	marginTop: 0,
	marginBottom: 0,
	marginLeft: 0,
	marginRight: 0,
	paddingTop: 0,
	paddingBottom: 0,
	paddingLeft: 0,
	paddingRight: 0,
	borderRadius: 0,
	color: sessionStorage.getItem('editorType') === 'dataview' ? '#fff' : '#333',
	backgroundColor: 'transcode',
	borderWidth: 0,
	borderStyle: 'none',
	borderColor: '#ccc',
	boxSizing: 'border-box',
	overflowX: 'hidden',
	overflowY: 'hidden'
};

// 公共属性
export const commonAttr = {
	animations: [],
	events: {},
	groupStyle: {}, // 当一个组件成为 Group 的子组件时使用
	isLock: false, // 是否锁定组件
	valueOrign: 'fixed', // 值来源 fixed 固定值 database 数据仓库 component 其他组件
	// 数据仓库数据
	database: {
		mapping: 'object', // 数据流转来源 可能为关联关系或默认的对象+视图配置方式 object、relation
		requestType: 'dataList', // 请求方式
		objectData: null, // 对象数据
		viewData: null, // 视图数据
		relationData: null, // 关联关系
		fieldList: [], // 字段列表
		paramsConfig: [], // 参数配置
		metadata: [], // 字段
		userOutParams: false, // 使用外部参数
		mockData: [], // 模拟数据 { paramsKey: '', paramsValue: '' }
		// 统计分析配置
		statisticalConfig: [
			// {
			// 	key: '', // key
			// view_uuid: ''
			// 	field_uuid: '', // 字段uuid 一般无需配置
			// 	aggregate: '', // 统计方法
			// }
		]
	},
	// 来自组件数据
	componentValueConfig: {
		component: '',
		field: ''
	},
	// 字段配置 将此字段作为data_id 传给后端(目前在弹窗中使用) 下版本将会整体修改此内容
	sourceConfig: [
		{
			id: '',
			type: '',
			key: '',
			componentId: '',
			field: '',
			originType: '',
      urlParamKey: ''
		}
	],
	// 当前组件所暴露出来可供其他组件使用的字段
	resolveData: null,
	// 特殊样式
	specialStyle: {
		isCollector: false, // 是否设为收集者 flex 1
		flexDirection: 'column', // flex 主轴方向	
		flexWrap: false, // 是否换行	
	},
	// 权限控制 此按钮显示需要的权限列表，admin用户不鉴权
	authConfig: {
		list: '', // 需要的权限列表 字符串逗号分隔
		privilegers: ['admin'] // 特权人
	},
	// 架构限制
	archiLimit: [],
	// 渲染逻辑
	UILogic: null
};

// 编辑器左侧组件列表
const list = [];
list.push(baseComponent);
list.push(containers);
list.push(functionCom);
list.push(modelComponent);
list.push(listComponent);
list.push(addComponent);
list.push(pageComponent);
list.push(chartComponent);
for (let i = 0, len = list.length; i < len; i++) {
	const componentList = list[i].componentList || [];
	if (componentList && Array.isArray(componentList)) {
		for (let j = 0; j < componentList.length; j++) {
			const item = componentList[j];
			if (item.needCommonStyle) {
				// 需要添加公共样式
				item.style = {...commonStyle,  ...item.style };
			}
			componentList[j] = { ...commonAttr, ...item };
		}
	}
	list[i] = {
		...list[i],
		componentList
	};
}
export default list;
