<!--
 * @Description: 分部分项(标准用表)页面模板
 * @Author: luocheng
 * @Date: 2022-08-03 19:12:48
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2022-12-08 13:52:17
-->
<template>
  <div class="common-subitem-page" v-loading="loading" style="display:flex">
		<!-- 三端 -->
		<template v-if="statusConfig.needTreeTerminal">
			<div v-show="icon" style="width:300px;height:100%;display:flex;box-sizing: border-box;min-width: 300px;" v-if="showProjectListBool">
				<CommonSubitemTree v-if="treeProjectArray && Array.isArray(treeProjectArray)" :treeData="treeProjectArray"
					:defaultSelect="true" :withFilter="true" v-model="selectProjectKey" :treeProps="treeProjectProps"
					@setNodes="getProjectChildrenNode" treeTitle="项目列表" :isSubitem="false">
				</CommonSubitemTree>
			</div>
			<div style="position:relative" v-if="showProjectListBool">
				<i class="iconfont shrink-icon iconjijia_shouqi"
					style="position:absolute;right:-10px; font-size: 24px;top:15px; animation: fade 600ms infinite;"
					@click="clickIcon" :class="icon ? 'icon-all' : 'icon-hide'" />
			</div>
		</template>
		<Splitpanes class="default-theme table-box" style="height:100%;width:100%;overflow-x:auto" @resize="onSplitpane" :push-other-panes="false">
			<Pane id="left-pane" :size="leftSize" min-size="15" max-size="50">
				<CommonSubitemTree v-if="treeData && Array.isArray(treeData)" v-loading="showFormatTreeDefaultExpandKeysLoading" :treeData="treeData" :defaultSelect="true"
					:withFilter="true" v-model="selectKeys" :treeProps="treeProps" @setNodes="getNodes"
					:defaultExpandKeys="defaultExpandKeysArray" :treeTitle="statusConfig.title || '工程列表'"></CommonSubitemTree>
			</Pane>
			<Pane :size="100 - leftSize" id="content-pane">
				<article class="table-content">
					<template v-if="pageId && !updating">
						<!-- 绑定的表单关系 -->
						<!-- <FromDataList
							:moduleUuid="pageId"
							v-if="pageType === 'formPage' && pageId"
							:formFillData="formFillData"
						></FromDataList>
						绑定的自定义配置页面 -->
						<PageParser
							v-if="pageId && showPage"
              :isNormal="true"
							:pageUUID="pageId">
						</PageParser>
					</template>
					<el-empty v-else :description="emptyDesc"></el-empty>
				</article>
			</Pane>
		</Splitpanes>
  </div>
</template>

<script>
import CommonSubitemTree from '@/custom-component/common/CommonSubitemTree.vue';
import { Splitpanes, Pane } from 'splitpanes';
import 'splitpanes/dist/splitpanes.css';
import { dataInterface } from '@/apis/data';
import { Empty } from 'element-ui';
// import PageParser from '@/components/parser/Index';
// 三端@蒲亚军
// 逻辑: 若存在子项目，则根据项目获取分部分项；否则根据当前架构获取数据
// PageParser 中的componentBox 存在自嵌套问题，所以直接全局注册
import CustomComponentMixin from '@/custom-component/mixins/CustomComponentMixin.js';

export default {
	mixins: [CustomComponentMixin],
	name: 'CommonSubitemTemplate',
	components: {
		CommonSubitemTree,
		Splitpanes,
		Pane,
		// PageParser,
		'el-empty': Empty
	},
	data() {
		return {
			treeProjectProps: {
				children: 'projects',
				label: 'name'
			},
			treeProjectArray: null,
			selectProjectKey: '',
			showProjectListBool: false,
			loading: true,
			leftSize: 35,
			// 树形图
			treeData: null,
			selectKeys: [],
			treeProps: {
				children: 'table_childrens',
				label: 'name'
			},
			// 表单绑定的UUID
			moduleUuid: '',
			emptyDesc: '请选择分部分项',
			// 绑定的id 表单ID (formModule61c03a774f3ef)或页面uuid (page61937793eda7f)
			pageId: '',
			// 虚线描述字段
			ancestorField: {
				industry_type: 'I', // 行业类型
				version: 'V', // 版本库
				project_type: 'P', // 项目类型
				unit_engineering: 'D', // 单位工程
				subunit_engineering: 'ZD', // 子单位工程
				division_of_engineering: 'B', // 分部工程
				subdivision_project: 'ZB', // 子分部工程
				sectional_works: 'X', // 分项工程
				sub_projects: 'ZX', // 子分项工程
				inspection_lot: 'J', // 检验批
				classification: 'C', // 仅分类
				table: 'T' // 表格
			},
			// 暴露的数据
			formFillData: {},
			// 类型
			TYPE_DESC: {
				supervision: '监理表格',
				quality: '质量表格',
				'side-station': '旁站表格',
				construction: '施工表格',
				parallel: '平行检验表格'
			},
			// 更新中(重新渲染)
			updating: false,
			showPage: false,
			// 三端选中的架构
			projectDetails: null,
			// 当前架构是否包含子项目
			hasChildren: 0,
			icon: true,
			defaultExpandKeysArray: [],
			showFormatTreeDefaultExpandKeysLoading:false
		};
	},
	computed: {
		statusConfig() {
			return this.element?.statusConfig;
		},
		database() {
			return this.element?.database || {};
		},
		paramsConfig() {
			return this.element?.paramsConfig
		},
		// 接口数据对象及视图配置
		interfaceConfig() {
			// 测试数据
			// // 表格 视图缺失暂不改动
			// this.interfaceConfig = {
			// 	object_uuid: 'object62830dfd61652',
			// 	view_uuid: 'view62845e89b1dfa'
			// }
			// // 分部分项
			// this.interfaceConfig = {
			// 	object_uuid: 'object61a8855060394',
			// 	view_uuid: 'view61c52ed5f0323'
			// }
			return {
				object_uuid: this.database?.objectUUID,
				view_uuid: this.database?.viewUUID
			}
		}
	},
	created() {
		this.getTreeData();
		if (this.$route.query && this.$route.query.id) {
			this.selectKeys.push(+this.$route.query.id)
			this.pageId = this.$route.query.list_uuid || this.$route.query.origin_list_uuid;
		}
		this.hasChildren = this.$GetTargetArchi('exist_children');
	},
	watch: {
		$route: {
			handler(newRoute, oldRoute) {
				if (oldRoute && oldRoute.name === newRoute.name) return;
				if (!this.interfaceConfig?.object_uuid || !this.interfaceConfig?.view_uuid) {
					this.$message.error('接口配置错误！');
					return;
				}
				this._showProjectList();
				this.resetData(true);
				this.initPaneWidth();
			},
			deep: true,
			immediate: true
		},
		pageId() {
			this.showPage = false;
			setTimeout(() => {
				this.showPage = true;
			}, 300);
		}
	},
	mounted() {
		this.initPaneWidth();
	},
	methods: {
		// 修改分栏宽度 @蒋冬梅
		initPaneWidth() {
			this.$nextTick(() => {
				const leftPane = document.querySelector('#left-pane');
				const contentPane = document.querySelector('#content-pane');
				if (leftPane && contentPane) {
					leftPane.style.width = '265px';
					contentPane.style.width = 'calc(100% - 265px)'
				}
			})
		},
		/**
		 * 
		 * @param {Array} array 需要查询的数组
		 * @param {String} keyLabel 需要查询的数组keys,当keys为空时则默认查找叶子节点
		 */
		findKeysToDefaultExpandTree(array = [], keyLabel = []) {
			let result = [];
			const _isNotLeafNode = (node, value) => {
				return Boolean(node[value]?.length)
			}
			const _useKeyFindItem = (arr, k) => {
				for (let i = 0; i < arr.length; i++) {
					let obj = arr[i];
					if (_isNotLeafNode(obj, k)) {
						_useKeyFindItem(obj[k], k)
					}
					if (keyLabel.length > 0 && keyLabel.includes(obj.type)) {
						result.push(obj.id)
					}
					if (keyLabel.length == 0) {
						result.push(obj.id)
					}
				}
			}
			_useKeyFindItem(array, this.treeProps.children)
			return result;
		},
		/**
		 * @desc: 点击图标
		 */
		clickIcon() {
			this.icon = !this.icon
			this.onSplitpane();
		},
		/**
		 * @desc: 获取树形图(弃用)
		 */
		getTreeData() {
			const { param } = this.initParams(this.paramsConfig);
			this.loading = true;
			return dataInterface({
				__method_name__: 'dataList',
				...this.interfaceConfig,
				archi_type: this.$GetTargetArchi('data_id'),
				transcode: 0,
				...param,
			}).then((res) => {
					if (res.status === 200) {
						const treeData = res.data.data || [];
						this.treeData = this.formatParent(treeData, {});
						this.loading = false;
						return this.treeData
					}
					this.loading = false;

				})
				.catch((err) => {
					console.log(err, '9999999999');
					this.loading = false;
				});
		},
		/**
		 * @desc: 寻找属性图的所有祖先字段
		 * @param {Array} treeData 树形图
		 * @param {Object} ancestor 祖先对象
		 * @return {Object} 包含所有祖先的字段
		 */
		formatParent(treeData, ancestor = {}) {
			return treeData.map(ele => {
				const formFillData = {
					...ancestor,
					...this.getAncestorKey(ancestor, ele)
				}
				if (ele[this.treeProps.children] && ele[this.treeProps.children].length) {
					return {
						...ele,
						formFillData,
						[this.treeProps.children]: this.formatParent(ele[this.treeProps.children], formFillData)
					}
				}
				return {
					...ele,
					formFillData
				}
			})
		},
		/**
		 * @desc: 获取
		 * @param {Object} ancestor 祖先
		 * @param {Object} node 当前节点
		 */
		getAncestorKey(ancestor, node) {
			const keys = Object.keys(ancestor);
			const { id, name, type, type_id, acceptance_items, origin_data = [], inspection_type, construction_specifications, specification } = node;
			let targetKey = this.ancestorField[type_id]
			let count = 0;
			keys.forEach(ele => {
				if (ele.toString().indexOf(targetKey) > -1) {
					count++;
				}
			});
			if (count > 0) {
				targetKey += `_${count}`
			}
			return {
				...ancestor,
				[targetKey + '_origin_id']: origin_data && origin_data.length ? origin_data[0].id : null,
				[targetKey + '_id']: id,
				[targetKey + '_name']: name,
				[targetKey + '_type']: type,
				[targetKey + '_type_id']: type_id,
				[targetKey + '_acceptance_name']: acceptance_items && acceptance_items.length ? acceptance_items[0].specification :  (specification || ''),
				[targetKey + '_inspection_type']: type_id === 'inspection_lot' ? (inspection_type || 1) : '',
				[targetKey + '_construction_specifications']: construction_specifications
				// [targetKey + '_children']: node[this.treeProps.children] || []
			}
		},
		/**
		 * @desc: 获取树形图选中的节点
		 * @param {Array} details 选中的节点ID
		 */
		getNodes(details) {
			this.resetData(false);
			this.updating = true;
			if (details.type_id !== 'table') {
				this.emptyDesc = '请选择表格查看！';
				this.updating = false;
				return false;
			}
			if (!details) {
				this.loading = false;
				this.emptyDesc = '获取数据失败！';
				return;
			}
			let pageId = details.list_uuid || details.origin_list_uuid || '';
			if (!pageId && details.origin_data) {
				const { origin_data } = details;
				if (Array.isArray(origin_data) && origin_data.length) {
					pageId = origin_data[0].list_uuid || '';
				}
			}
			let originDta = null;
			if (typeof details.origin_data === 'object' && details.origin_data.length) {
				originDta = details.origin_data[0]
			}
			if (typeof details.formFillData === 'object') {
				sessionStorage.setItem('_formFilleData', JSON.stringify(details.formFillData))
			}
			if (!pageId) {
				this.emptyDesc = '暂无绑定数据！';
				return;
			}
			this.pageId = pageId;
			if (this.$route.path === '/modify-page' || this.$route.path === '/page-view') {
				console.log(originDta, '---originDta---')
				// this.$router.replace({
				// 	path: this.$route.path,
				// 	query: {
				// 		id: details.id,
				// 		type_id: details.type_id,
				// 		list_uuid: details.list_uuid,
				// 		origin_id: originDta?.id,
				// 		origin_list_uuid: originDta?.list_uuid,
				// 		...this.$route.query,
				// 	}
				// });
			} else {
				// this.$router.replace({
				// 	path: this.$route.path,
				// 	query: {
				// 		id: details.id,
				// 		type_id: details.type_id,
				// 		list_uuid: details.list_uuid,
				// 		origin_id: originDta?.id,
				// 		origin_list_uuid: originDta?.list_uuid,
				// 	}
				// });
			}
			this.$nextTick(() => {
				this.updating = false;
			});
		},
		/**
		 * @desc: 重置数据
		 */
		resetData(resetTree) {
			// 表单绑定的UUID
			this.moduleUuid = '';
			this.emptyDesc = '请选择分部分项';
			this.pageId = '';
			// this.pageType = '';
			if (resetTree) {
				this.treeData = null;
				this.selectKeys = []
				if (+this.$GetTargetArchi('exist_children')) {
					this.getProjectChildrenNode(null);
				} else {
					this.getTreeData();
				}
			}
		}
		,
		/**
		 * 根据架构id获取架构下的所有项目
		 */
		_getProjectsList() {
			if (!this.statusConfig?.needTreeTerminal) return;
			let id = this.$GetUserInfo('id')
			if (!id) {
				this.$message.error('出错了!');
				return;
			}
			this.loading = true;
			return dataInterface({
				__method_name__: 'dataList',
				object_uuid: 'object616fe6fe8119f',
				view_uuid: 'view61b1d47593ddd',
				transcode: 0,
				id: id,
			})
				.then((res) => {
					console.log(res)
					if (res.status === 200) {
						const data = res.data.data || [];
						this.treeProjectArray = data.data
						this.loading = false;
					}
				})
				.catch((err) => {
					this.loading = false;
					throw new Error(err)
				});

		},

		/**
		 * @description: 点击项目列表中的item条目时触发 获取该项目下的分项目类 
		 * @param {*} details
		 * @return {*}
		 */
		getProjectChildrenNode(details = null) {
			if (details) {
				this.projectDetails = details;
			}
			if (!this.projectDetails) return;
			const { param } = this.initParams(this.paramsConfig);
			dataInterface({
				__method_name__: 'dataList',
				object_uuid: 'object61a8855060394',
				view_uuid: 'view61c52ed5f0323',
				archi_type: this.projectDetails.archi_type_value,
				transcode: 0,
				...param
			}).then((res) => {
				console.log(res)
				if (res.status === 200) {
					const data = res.data.data || [];

					this.treeData = data
					return data

				} else {
					this.$message.error(res.data.msg)
				}
			}).catch((err) => {
				console.error(`TableDataERRoR:--${err}`);
			});
		},
		/**
		 * @description: 用于显示左侧项目列表 (标准用表、分部分项)
		 * @param {*} param String类型
		 * @return {*} 
		 */
		_showProjectList(param) {
			this.showFormatTreeDefaultExpandKeysLoading = true;
			let promise = null;
			let arr = ['quality', 'parallel']  // 此数组参数是依赖于 this.data.TYPE_DESC中的key值,数组存在该值则显示项目列表
			if (arr.includes(param) && +this.$GetTargetArchi('exist_children')) {
				this.showProjectListBool = true
				promise = this._getProjectsList()
			} else {
				this.showProjectListBool = false
				promise = this.getTreeData();
			}
			promise?.then(r => {
				if (r) {
					let arr = [];
					let needUnfoldTreeToLeafNodeArray = ['supervision', 'construction']
					if (needUnfoldTreeToLeafNodeArray.includes(param)) {
						arr = this.findKeysToDefaultExpandTree(r, [])
					} else {
						arr = this.findKeysToDefaultExpandTree(r, ['单位工程', '子单位工程'])
					}
					this.defaultExpandKeysArray = arr
					this.showFormatTreeDefaultExpandKeysLoading = false;
				}
			});

		},
		/**
		 * @desc: 拖动分隔
		 */
		onSplitpane() {
			console.log('拖动分隔-----')
			try {
				const ev = document.createEvent('Event');
				ev.initEvent('resize', true, true);
				window.dispatchEvent(ev);
			} catch (err) {
				console.log(err);
			}
		}
	}
};
</script>

<style lang="less" scoped>
.common-subitem-page{
  height: 100%;
	width: 100%;
	box-sizing: border-box;
	overflow: hidden;
	padding: 10px;
	background: #fff;
	.table-content {
		position: relative;
		box-sizing: border-box;
		padding: 0 10px;
		height: 100%;
		width: 100%;
		overflow: hidden;
		background: #fff;
		.el-empty {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			margin: auto;
		}
	}
}

.icon-hide {
	transform: rotateY(180deg)
}
:deep(.splitpanes--vertical>.splitpanes__splitter) {
	&:before {
		margin-left: -10px;
		width: 4px;
		height: 50px;
	}
	&:after {
		width: 4px;
		margin-left: -1px;
		height: 50px;
		z-index: 100;
	}
}
</style>