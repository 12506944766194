/*
 * @Description: 调整组件层级 组件在列表中下标越大则距离顶层越近(顺序渲染)
 * @Author: luocheng
 * @Date: 2021-08-12 14:35:22
 * @LastEditors: luocheng
 * @LastEditTime: 2022-08-02 20:01:04
 */
import { arrSwap, deepCopy } from '@/utils/tools';
import toast from '@/utils/toast';

export default {
	mutations: {
		/**
		 * @desc: 上移一层
		 */
		upComponent({ componentData, curIndex }) {
      console.log(curIndex, componentData, '---');
			if (curIndex < componentData.length - 1) {
				arrSwap(componentData, curIndex, curIndex + 1);
			} else {
				toast('已经到顶了', 'warning');
			}
		},
		/**
		 * @desc: 下移一层
		 */
		downComponent({ componentData, curIndex }) {
			if (curIndex > 0) {
				arrSwap(componentData, curIndex, curIndex - 1);
			} else {
				toast('已经到底了', 'warning');
			}
		},
		/**
		 * @desc: 置顶
		 */
		topComponent({ componentData, curIndex }) {
			if (curIndex < componentData.length - 1) {
				const target = deepCopy(componentData[curIndex]);
				componentData.splice(curIndex, 1);
				componentData.push(target);
				// arrSwap(componentData, curIndex, componentData.length - 1);
			} else {
				toast('已经到顶了', 'warning');
			}
		},
		/**
		 * @desc: 置底
		 */
		bottomComponent({ componentData, curIndex }) {
			if (curIndex > 0) {
				const target = deepCopy(componentData[curIndex]);
				componentData.splice(curIndex, 1);
				// arrSwap(componentData, curIndex, 0);
				componentData.unshift(target);
			} else {
				toast('已经到底了', 'warning');
			}
		}
	}
};
