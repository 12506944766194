<!--
 * @Description: 入口Vue
 * @Author: luocheng
 * @Date: 2021-08-10 15:10:33
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2023-06-05 14:40:01
-->
<template>
  <div id="app" v-if="initEnd">
    <router-view />
		<!-- 导出 -->
		<Spreadsheet
			v-if="showSheet && exportConfig"
			:type="spreadsheetType"
			:excelUuid="exportConfig.sheet_uuid"
			:objectUuid="exportConfig.object_uuid"
			:viewUuid="exportConfig.view_uuid || ''"
			:formData="exportConfig.data"
			:exportType="exportConfig.autoDownLoad || 'pdf'"
			@exportSuccess="onExportSuccess"
			:viewSearch="exportViewSearch"
			:viewQuery="exportViewParams"
			@exportError="onExportError"
		></Spreadsheet>
	</div>
</template>
<script>
import initGlobalSearch from "@/global/globalSearch"
import Spreadsheet from '@/custom-component/common/Entry';
import eventBus from '@/plugins/eventBus';
import { dataInterface } from '@/apis/data/index';
import { getDevToken } from '@/utils/tools';
import Cookies from 'js-cookie';

export default {
  components: {
    Spreadsheet
  },
  name: 'App',
  data() {
    return {
      initEnd: false,
			// 导出
			showSheet: false,
			spreadsheetType: 'export',
			exportConfig: null,
			exportViewSearch: [],
			exportViewParams: {}
    }
  },
  created() {
    // 刷新token
    // if (this.$route.path === '/login') {
    //   this.initEnd = true;
    // } else {
    //   this.refreshToken();
    // }
		this.initEnd = true;
    initGlobalSearch();
    const objectList = sessionStorage.getItem('objectList');
    if (objectList) {
      this.$store.commit('setObjectList', JSON.parse(objectList));
    } else {
			if (Cookies.get('bimccToken')) {
				this.$store.dispatch('getObjectList', {});
			}
    }
  },
  mounted() {
    // 预加载表格iframe
    this.initSheet();
    // 全局导出
    eventBus.$on('EDITOR_EEExport', config => {
      this.$loading({
				text: '导出中....'
      });
      this.showSheet = true;
      this.exportConfig = config;
    })
  },
  methods: {
		/**
		 * @desc: 导出成功
		 */
		onExportSuccess() {
			this.$loading().close();
			this.showSheet = false;
      this.exportConfig = null;
			this.$message.success('导出成功！');
		},
		/**
		 * @desc: 初始化sheet
		 */
		onExportError(msg) {
			this.$loading().close();
			this.showSheet = false;
      this.exportConfig = null;
			this.$message.error(msg || '导出失败！');
		},
    /**
     * @desc: 初始化sheet表格
     */
    initSheet() {
      let sssIframe = document.createElement('iframe');
      sssIframe.src = 'https://sss.bimcc.com';
      sssIframe.id = 'sssIframe';
      sssIframe.style.display = 'block';
      sssIframe.style.border = 'none';
      sssIframe.style.position = 'absolute';
      sssIframe.style.top = '-10000px';
      sssIframe.style.right = '-10000px';
      sssIframe.style.zIndex = -1;
      sssIframe.width = '1000px';
      sssIframe.height = '600px';
      document.body.appendChild(sssIframe);
      // sss服务加载状态
      window.sssLoaded = false;

      sssIframe.onload = () => {
          setTimeout(() => {
              window.sssLoaded = true;
          }, 0);

          sssIframe.contentWindow.postMessage({
              event: 'spreadsheetInit',
              options:{
                  baseUrl: process.env.VUE_APP_BASE_URL || 'https://saas-api.bimcc.net/',
                  metadataToken: process.env.VUE_APP_METATOKEN || 'aba62ca1-c2ff-42af-9d3a-bbe5b02f7b06',
                  bimcctoken: this.$GetToken(),
              }
          }, '*');
      }
    },
    /**
     * @desc: 更新token
     */
    refreshToken() {
      const devToken = getDevToken();
      if (!devToken) {
        // this.$router.replace({
        //   name: 'Login'
        // });
        this.initEnd = true;
        return false;
      }
      // token校验
      // api/v4/user/refreshToken
      // post
      // 参数
      // token token值
      // refresh  是否刷新  0否/1是
      dataInterface({
        token: devToken,
        refresh: 1
      }, `${process.env.VUE_APP_BASE_URL}api/v4/user/refreshToken`).then(res => {
        this.initEnd = true;
        console.log(res, '---3443----');
        if (res.status === 200 && res.data?.code === 200) {
          // 刷新
          return;
        }
        this.$message.error('请重新登录!');
        console.log('APP刷新token报错111111', res);
        this.$router.replace({
          name: 'Login'
        });
      }).catch(err => {
        console.log(err);
        this.initEnd = true;
        this.$message.error('请重新登录!');
        console.log('APP刷新token报错2222222', err);
        this.$router.replace({
          name: 'Login'
        });
      });
    }
  },
  beforeDestroy() {
    eventBus.$off('EDITOR_EEExport');
  }
};
</script>
<style lang="less">
#app {
	height: 100%;
	width: 100%;
	// min-width: 707px;
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: left;
	color: #2c3e50;
	overflow-y: auto;
}
.tox-tinymce-aux {
	z-index: 99999 !important;
}

.el-empty__image {
	width: 80px !important;
	height: 80px;
}
/* 替换空状态图片 */
.config-chart-container,
.normalpage-container {
	.el-empty__image {
		svg {
			display: none;
		}
    background-image: url(assets/images/empty-light.png);
    background-size: contain;
    background-repeat: no-repeat;
  }
}
.screen-container, .chart-container-dialog{
  .el-loading-mask {
    background: rgba(0, 0, 0, .9);
    .el-loading-spinner .path {
      stroke: #fff;
    }
  }
  .el-empty__image{
    svg{
			display: none;
		}
    background-image: url(assets/images/empty-dark.png);
    background-size: contain;
    background-repeat: no-repeat;
  }
  .el-empty__description p{
    color: #fff;
  }
}
.el-message-box--center{
    border: none;
    border-radius: 10px;
    .el-message-box__header{
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    height: 48px;
    background: linear-gradient(90deg, #6292FF 0%, #DE93FF 84%, #CE92FF 91%, #C568FF 100%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 68px 0 24px;
    color: #fff;
    .el-message-box__title{
      color: #fff;
      font-size: 14px;
    }
    .el-message-box__headerbtn{
      top: 14px;
      right: 24px;
      .el-message-box__close{
        color: #fff;
          font-size: 20px;
      }
    }
  }
}
.chart-container-dialog {
	background-color: rgba(255, 255, 255, 0.3) !important;
	backdrop-filter: blur(12px);
}

/* 以下样式为数仓样式 */
/* sss服务 dialog 样式 */
.sss-dialog {
	display: flex;
	flex-direction: column;

	.el-dialog__header {
		border-bottom: 1px solid #f2f3f5;
	}

	.el-dialog__body {
		flex: 1;
		padding: 10px 20px;
	}

	.el-dialog__footer {
		border-top: 1px solid #f2f3f5;
		padding-bottom: 10px;
	}
}

// 滚动条样式1
.scrollbar-style1 {
	&::-webkit-scrollbar {
		width: 6px;
	}

	&::-webkit-scrollbar-track {
		background-color: #fff;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #dddee0;
	}

	&::-webkit-scrollbar-thumb:hover {
		background-color: #c7c9cc;
	}

	&::-webkit-scrollbar-thumb:active {
		background-color: #c7c9cc;
	}
}
.el-table::before {
	display: none !important;
}
.col-theme {
	color: #007fe0;
}
.bg-fff {
	background: #fff;
}
.w-full {
	width: 100%;
}
.h-full {
	height: 100%;
}
.inline-block {
	display: inline-block;
}
.p-5 {
	padding: 1.25rem;
}
.pl-5 {
	padding-left: 1.25rem;
}
.pr-5 {
	padding-right: 1.25rem;
}
.py-5 {
	padding-top: 1.25rem;
	padding-bottom: 1.25rem;
}
.mt-5 {
	margin-top: 1.25rem !important;
}
.mb-5 {
	margin-bottom: 1.25rem;
}
.mx-5 {
	margin-left: 1.25rem;
	margin-right: 1.25rem;
}
.ml-2 {
	margin-left: 0.5rem;
}
.text-left {
	text-align: left;
}
.text-right {
	text-align: right;
}
.text-center {
	text-align: center;
}
.float-left {
	float: left;
}
.float-right {
	float: right;
}
.relative {
	position: relative;
}
.absolute {
	position: absolute;
}
.fixed {
	position: fixed;
}
.overflow-y-hidden {
	overflow-y: hidden;
}
.overflow-y-auto {
	overflow-y: auto;
}
.overflow-x-hidden {
	overflow-x: hidden;
}
.overflow-x-auto {
	overflow-x: auto;
}
.overflow-hidden {
	overflow: hidden;
}
.truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.bd-top {
	border-top: 1px solid #eaebed;
}
.bd-bottom {
	border-bottom: 1px solid #eaebed;
}
.bd-left {
	border-left: 1px solid #eaebed;
}
.bd-right {
	border-right: 1px solid #eaebed;
}
.clearfix {
	overflow: auto;
	zoom: 1;
}
// .el-form-item__content {
//   margin-left: 0 !important;
// }
.el-form-item__content {
	text-align: left;
}

.scroller::-webkit-scrollbar,
.drawer__body::-webkit-scrollbar,
.el-table--scrollable-x::-webkit-scrollbar {
	/*滚动条整体样式*/
	width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
	height: 1px;
}

.scroller::-webkit-scrollbar-thumb,
.drawer__body::-webkit-scrollbar-thumb,
.el-table--scrollable-x::-webkit-scrollbar-thumb {
	/*滚动条里面小方块*/
	border-radius: 6px;
	box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	background: #c0c4cc;
}

.scroller::-webkit-scrollbar-track,
.drawer__body::-webkit-scrollbar-track,
.el-table--scrollable-x::-webkit-scrollbar-track {
	/*滚动条里面轨道*/
	box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	border-radius: 6px;
	background: #ededed;
}
.no-scroll-bar::-webkit-scrollbar {
	/*滚动条整体样式*/
	width: 0px !important; /*高宽分别对应横竖滚动条的尺寸*/
	height: 1px;
}
.no-scroll-bar {
	-ms-overflow-style: none;
	overflow: -moz-scrollbars-none;
}
.required-star .el-form-item__label::before {
	content: '*';
	color: #f56c6c;
	margin-right: 4px;
}
//表格操作文字样式
.operation-primary {
	padding: 0 5px;
	color: #0076ff;
	position: relative;
	&:first-child {
		padding-left: 0;
	}
	&:not(:last-child):after {
		content: '';
		height: 10px;
		width: 1px;
		background-color: #c2c5cc;
		position: absolute;
		top: 4px;
		right: 0;
		/*border-right: 1px  #C2C5CC solid;*/
	}
	cursor: pointer;
}
.operation-del {
	padding: 0 5px;
	color: #e83525;
	&:not(:last-child) {
		border-right: 1px #343434 solid;
	}
	cursor: pointer;
}
//表格操作文字样式结束

//elemnet元素样式开始
// .el-button {
//   padding: 10px 20px !important;
// }
// .el-button--mini {
//   padding: 5px 12px !important;
// }
// .el-form-item {
//   //表单的各行的间距
//   margin-bottom: 18px !important;
// }
// .el-loading-spinner {
//   text-align: center !important;
// }
// svg.circular {
//   //loading居中被tailwind
//   display: inline;
// }
//elemnet元素样式结束

.el-message-box__message {
	p {
		line-break: anywhere;
	}
}
.type-label-before-blue::before {
	content: ' ';
	font-size: 15px;
	line-height: 30px;
	border-left: 3px solid #007fe0 !important;
	margin-right: 5px;
}
.type-label-before-blue {
	text-align: left;
}
.type-label-before-blue {
	padding: 15px 10px;
	padding-left: 0px;
	line-height: 20px;
	font-size: 14px;
	// margin: 15px 0 !important;
	font-weight: 600;
	position: sticky;
	top: 0;
	background-color: white;
	z-index: 202;
}

// baseaction弹窗样式
.base-action-dialog-fullscreen {
	display: flex;
	flex-direction: column;
	height: 100vh;
	.el-dialog__body {
		flex: 1;
		display: flex;
		flex-direction: column;
		overflow-y: auto;
		.dialog-body-content {
			flex: 1;
		}
	}
}
.max-height-70 {
	max-height: 70vh;
}
/* 强制隐藏gantt授权弹框 */
.gantt_message_area.dhtmlx_message_area,.gantt-error,.dhtmlx-error {
		display: none !important;
}
.el-collapse-item__header {
	font-size: 14px;
}
</style>
