<!--
 * @Author: zx
 * @Date: 2022-09-01 15:30:46
 * @LastEditTime: 2022-10-31 16:40:23
 * @Description:
 * @LastEditors: zx
-->
<template>
  <div class="application-tree" :style="'width:' + width + 'px'">
    <div class="application-tree-container">
      <LeftItem
        v-loading="loading"
        delAble
        :topTitle="childTitle"
        :showBack="true"
        :tableData="appInfo.children"
        :defaultProps="{
          label: 'name',
          children: 'children'
        }"
        field="name"
        @row-del="rowDel"
        @row-click="childRowClick"
        @top-add="childAdd"
        @row-edit="childRowEdit"
        @row-add="childRowAdd"
        @back="back"
      />
    </div>
    <el-drawer
      :title="applicationTitle"
      :visible.sync="showAddDrawer"
      :append-to-body="true"
      :direction="'rtl'"
      size="70%"
    >
      <AddContent :childInfo="childInfo" @closeAddDrawer="closeAddDrawer"></AddContent>
    </el-drawer>
    <!-- 编辑应用弹窗 -->
    <el-dialog
      :title="editTitle"
      :fullscreen="dialogFull"
      :visible.sync="dialogVisible"
      width="600px"
      append-to-body
      custom-class="create-app-dialog"
    >
      <div v-if="dialogVisible" class="common-body">
        <el-form
          v-if="dialogVisible"
          ref="form"
          :model="appFormData"
          :rules="rules"
          label-position="top"
          label-width="80px"
        >
          <div class="wrapper">
            <el-form-item
              class="form-item-height"
              label="应用图标"
              prop="icon"
            >
              <el-select :value="other" style="width: 100%" placeholder="" class="select-icon">
                <template slot="prefix">
                  <div
                    class="header-img"
                    :style="'background: ' + appFormData.background">
                    <i
                      :style="'color:' + appFormData.color"
                      :class="
                        appFormData.icon
                          ? appFormData.icon
                          : 'el-icon-platform-eleme'
                      "
                    ></i>
                  </div>
                </template>
                <template #empty>
                  <div class="choose-color">
                    <p>选择图形颜色</p>
                    <div class="color-container">
                      <div
                        class="color-item"
                        :style="'background:' + item.color"
                        v-for="(item, index) in colorList"
                        :key="index"
                        @click="
                          (appFormData.color = item.color),
                            (appFormData.background = item.background)
                        "
                      >
                        <i
                          v-if="appFormData.color === item.color"
                          class="iconfont iconselect-duigou"
                        ></i>
                      </div>
                    </div>
                    <p>选择图形</p>
                    <div
                      class="color-container"
                      style="flex: 1; overflow-y: auto"
                    >
                      <div
                        class="color-item"
                        v-for="(icon, index) in iconList"
                        :key="index"
                        :style="
                          appFormData.icon === icon
                            ? 'background:' +
                              appFormData.background +
                              ';color:' +
                              appFormData.color
                            : ''
                        "
                        @click="appFormData.icon = icon"
                      >
                        <i
                          class="color-item-i"
                          :style="
                            appFormData.icon === icon
                              ? 'color:' + appFormData.color
                              : ''
                          "
                          :class="icon"
                        ></i>
                      </div>
                    </div>
                  </div>
                </template>
              </el-select>
            </el-form-item>
            <el-form-item class="form-item-height" style="margin-bottom: 14px;" label="名称" prop="name">
              <el-input
                v-model="appFormData.name"
                clearable
                placeholder="请输入名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="form-item-height" style="margin-bottom: 14px;" label="描述"
            >
              <el-input
                v-model="appFormData.remarks"
                clearable
                placeholder="请输入描述"
              ></el-input>
            </el-form-item>
            <el-form-item
              v-if="appFormData.menu_type === 1"
              class="form-item-height"
              style="margin-bottom: 14px;"
              label="可见范围"
              prop="visible_range"
            >
              <el-select
                v-model="appFormData.visible_range"
                style="width: 100%">
                <el-option :value="0" label="工作区可见">工作区可见</el-option>
                <el-option :value="1" label="指定架构可见">指定架构可见</el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="
                appFormData.menu_type === 1 &&
                +appFormData.visible_range === 1
              "
              class="form-item-height required-star"
              style="margin-bottom: 14px;"
              label="架构选择"
            >
              <el-select
                :value="archiRange"
                style="width: 100%"
                placeholder=""
              >
                <template slot="prefix">
                  <div class="show-name">
                    {{ archiName ? archiName : "请选择架构" }}
                  </div>
                </template>
                <template #empty>
                  <div class="choose-color" style="width: 420px">
                    <el-tree
                      ref="treeRef"
                      v-loading="treeLoading"
                      :data="treeData"
                      default-expand-all
                      node-key="id"
                      :props="{
                        label: 'name',
                        children: 'children',
                      }"
                      show-checkbox
                      :check-strictly="true"
                      style="height: 100%"
                      @check="treeSelectCheck"
                    >
                      <template #default="{ node, data }">
                        <div class="archi-label">
                          <span class="archi-label-svg">
                            <svg aria-hidden="true" class="color-icon">
                              <use :xlink:href="getIcon(data, 'label')" />
                            </svg>
                            <span>{{ node ? data.name : "" }}</span>
                          </span>
                        </div>
                      </template>
                    </el-tree>
                  </div>
                </template>
              </el-select>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button :loading="submitLoading" type="primary" @click="editSubmit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import LeftItem from "@/manage-views/views/home/components/applicationTree/components/LeftItem.vue";
  import AddContent from "./components/addContent.vue";
  import { mapiRequest } from '@/apis/http/saasMapiRequest'
  import { getToken } from '@/utils/tools';
  import { baseUrl } from '@/libs/util';
  import { elementIcons } from '@/utils/icons';
  import { createdApps, editApps } from '@/api/myApps';
  import { mapState } from 'vuex';
  import { Tree } from 'element-ui';
  import { departmentFilter } from '@/api/saas'
  export default {
    components:{
      'el-tree': Tree,
      LeftItem,
      AddContent,
    },
    props:{
      currentMenu: {
        type: Object,
        default: () => {
          return {}
        },
        required: true
      }
    },
    data() {
      return {
        dialogFull:false,
        iconList: elementIcons,
        baseURL: baseUrl(),
        metadataToken: process.env.VUE_APP_METATOKEN,
        token: getToken(),
        showAddDrawer: false,
        applicationTitle: '新增应用',
        childInfo: {},
        loading: false,
        appFormData: {},
        defaultProps: {
          label: 'name',
          children: 'cancel'
        },
        rules: {
          name: [
            { required: true, message: '请输入名称', trigger: 'blur' }
          ],
          remarks: [
            { required: true, message: '请输入描述', trigger: 'blur' }
          ],
          icon: [
            { required: true, message: '请选择图标', trigger: 'change' }
          ],
          visible_range: [
            { required: true, message: '请选择可见范围', trigger: 'change' }
          ],
        },
        dialogVisible: false,
        submitLoading: false,
        editTitle: '应用设置',
        appInfo: {}, // 选择的应用
        appListShow: false, // 是否显示应用子列表
        childTitle: '',
        width: 240,
        colorList: [
          {
            color: '#fb9337',
            background: '#ffebdb'
          },
          {
            color: '#fab300',
            background: '#fff1d8'
          },
          {
            color: '#67c200',
            background: '#e7f4d7'
          },
          {
            color: '#00bd77',
            background: '#ddf4e6'
          },
          {
            color: '#00c5fb',
            background: '#e2f4ff'
          },
          {
            color: '#268bfb',
            background: '#e3e9ff'
          },
          {
            color: '#6468fb',
            background: '#e8e2ff'
          },
          {
            color: '#392fc2',
            background: '#e1d7f5'
          },
          {
            color: '#9e64fb',
            background: '#f0e3ff'
          },
          {
            color: '#d164fb',
            background: '#f9e4ff'
          },
          {
            color: '#fb4b51',
            background: '#ffe1dd'
          },
          {
            color: '#fa6f32',
            background: '#ffe6d9'
          }
        ],
        other: '',
        archiRange: '',
        archiCheckValue: true,
        treeData: [], // 架构数据
        treeLoading: false, // 架构请求loading
        archiName: '', 
      }
    },
    watch: {
      currentMenu: {
        handler(val) {
          this.childTitle = this.currentMenu.name
          this.appInfo = val
        },
        deep: true,
        immediate: true
      }
    },
    computed: {
      ...mapState(['systemStyle']),
    },
    created() {
      this.getArchiTreeList()
    },
    mounted() {
      this.childTitle = this.currentMenu.name
    },
    methods:{
      rowDel(info) {
        const data = {
          __method_name__: 'deleteData',
          object_uuid:'object62fb5e636d27a',
          data_id:info.id,
        }
        this.loading = true
        mapiRequest(data).then((res) => {
          this.loading = false
          if (res.status === 200 && res.data.code === 200) {
            this.$message.success('删除成功!');
            this.$emit('updateMenuList', true)
          }
        })
      },
      rowClick(data) {
        this.appInfo = data
        this.childTitle = data.name
        this.appListShow = true
      },
      add() {
        this.editTitle = '新增应用'
        this.childInfo = this.currentMenu
        this.appFormData = {
          remarks: '',
          icon: 'el-icon-platform-eleme',
          name: '',
          color: '#fb9337',
          visible_range: 0,
          background: '#ffebdb',
          id: this.childInfo.id,
          menu_type: 1
        }
        this.archiName = ''
        this.dialogVisible = true
      },
      rowAdd(data) {
        this.childInfo = JSON.parse(JSON.stringify(data))
        this.showAddDrawer = true
      },
      rowEdit(data) {
        this.archiName = ''
        this.editTitle = '编辑' + data.name
        this.appFormData = JSON.parse(JSON.stringify(data))
        if (!this.appFormData.visible_range) {
          this.appFormData.visible_range === 0
        }
        if (this.appFormData.menu_type === 1 && +this.appFormData.visible_range === 1 && this.appFormData.range_id && this.appFormData.range_id.length) {
          this.appFormData.range_id = this.appFormData.range_id.map(Number)
          this.dialogVisible = true
          this.$nextTick(() => {
            this.$refs.treeRef.setCheckedKeys(this.appFormData.range_id)
            this.treeSelectCheck()
          })
        } else {
          this.dialogVisible = true
        }
      },
      editSubmit() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            const { id, remarks, icon, name, background, color, visible_range, range_id  } = this.appFormData
            const params = {
              remarks,
              icon,
              name,
              background,
              color,
              menu_id: id,
              visible_range,
              range_id,
            }
            if (!this.appFormData.menu_type || this.appFormData.menu_type !== 1) {
              params.visible_range = 0
              delete params.range_id
            }
            if (this.appFormData.menu_type &&  this.appFormData.menu_type === 1 && visible_range === 1 && (!range_id || range_id.length === 0)) {
              this.$message.warning('请选择架构！')
              return
            }
            if (!params.menu_id || params.meue_id === -1) {
              params.menu_id = 0
            }
            if (!(this.editTitle.indexOf('编辑') !== -1)) {
              params.menu_type = 1
              params.type = 1
              params.company_id = localStorage.getItem('company_id')
              createdApps(params).then((res) => {
                if (res.status === 200 && res.data.code === 200) {
                  this.$message.success('新增成功！')
                  this.dialogVisible = false
                  this.$emit('updateMenuList', true)
                }
              }).catch(() => {
                  this.dialogVisible = false
              })
              return
            }
            editApps(params).then((res) => {
              if (res.status === 200 && res.data.code === 200) {
                this.$message.success('编辑成功！')
                this.dialogVisible = false
                this.$emit('updateMenuList', true)
              }
            }).catch(() => {
                this.dialogVisible = false
            })
          }
        })

      },
      beforeUpload() {
      },
      /* 新增侯关闭弹窗 */
      closeAddDrawer(boo) {
        this.editTitle = ''
        this.childInfo = {}
        this.showAddDrawer = false
        if (boo) {
          this.$emit('updateMenuList', true)
        }
      },
      /* 应用下新增编辑删除等 */
      childRowClick(data) {
        if (!data || +data.menu_type === 6) {
          return
        }
        let path = ''
        let query = {}
        // 页面编辑器类型
        const pageTypeNmuber = [2, 5]
        if (+data.menu_type === 7) {
          path = '/application/my-application'
          query = { formUuid: data.form_module_uuid ,appMenuId:data.id }
        } else if (pageTypeNmuber.indexOf(+data.menu_type) !== -1) {
          const typeHash = {
            2: 'page',
            5: 'mobile'
          }
          path = '/application/page-parser'
          query = {
            pageUUID: data.page_uuid,
            id: data.current_data_id,
            type: typeHash[data.menu_type],
            name: data.name
          }
        } else if(+data.menu_type === 3 || +data.menu_type == 4) {
          const query = {
            pageUUID: data.page_uuid,
            id: data.current_data_id,
            type: data.menu_type === 3 ? 'dataview' : 'console',
            name: data.name
          }
          let path = '/full-page-parser'
          if(Object.keys(query).length) {
            let queryStr = ''
            for (const key in query) {
              if (Object.hasOwnProperty.call(query, key)) {
                if(query[key]) {
                  if(queryStr) {
                    queryStr += '&'
                  }
                  queryStr += `${key}=${query[key]}`
                }
              }
            }
            path = path + '?' + queryStr
          }
          path = encodeURI(path)
          window.open(path)
          return
        } else if(+data.menu_type === 8) {
          path = '/bulletin-board/shower'
          query = {
            chart_uuid: data.chart_uuid,
            id: data.current_data_id,
            name: data.name
          }
        }
        if ([2, 3, 4, 5, 7].indexOf(+data.menu_type) !== -1) {
          let navHistoryList = JSON.parse(sessionStorage.getItem('navHistoryList'))
          if (navHistoryList && navHistoryList.length) {
            let boo = false
            navHistoryList.forEach(element => {
              if (+element.id === +data.id) {
                boo = true
              }
            });
            if (!boo) {
              navHistoryList.push(data)
            }
          } else {
            navHistoryList = [data]
          }
          sessionStorage.setItem('navHistoryList', JSON.stringify(navHistoryList))
        }
        if (!data.menu_type) {
          /* 判断是否是大屏 目前是废弃 走上面逻辑 */
          if (data.path.indexOf('dataview/show') !== -1) {
            const uuid = data.path.indexOf('?pageUUID=') !== -1 ? data.path.split('?pageUUID=')[1] : ''
            path = '/application/page-parser'
            query = {
              pageUUID: uuid,
              id: data.id,
              type: 'dataview',
              name: data.name
            }
            this.$router.push({ path: path, query: query })
          } else {
            this.$router.push({ path: data.path })
          }
        }
        this.$router.push({ path: path, query: query })
        this.$emit('currentMenuList',data)
      },
      childRowEdit(data) {
        this.archiName = ''
        this.editTitle = '编辑' + data.name
        this.appFormData = JSON.parse(JSON.stringify(data))
        if (!this.appFormData.visible_range) {
          this.appFormData.visible_range === 0
        }
        this.dialogVisible = true
      },
      childAdd() {
        this.childInfo = this.appInfo
        this.showAddDrawer = true
      },
      childRowAdd(data) {
        this.childInfo = JSON.parse(JSON.stringify(data))
        this.showAddDrawer = true
      },
      back() {
        this.appInfo = {}
        this.appListShow = false
      },
      changeToList() {
        this.appInfo = {}
        this.appListShow = false
      },
      closeMenuItemClick() {
        this.width = 0
      },
      showMenuItemClick() {
        this.width = 240
      },
      getArchiTreeList() {
        this.treeLoading = false
        departmentFilter().then((res) => {
          this.treeLoading = false
          if (res.status === 200 && res.data.code === 200) {
            const data = res.data?.data
            const chooseArr = [1, 2, 3, 4, 5, 6]
            const loopTree = (arr) => {
              arr.forEach(element => {
                if (element.children && element.children.length) {
                  element.children = element.children.filter((ite) => {
                    return chooseArr.indexOf(+ite.type) !== -1
                  })
                  loopTree(element.children)
                }
              });
            }
            if (data && data.length) {
              loopTree(data)
            }
            this.treeData = data
          }
        })
      },
      treeSelectCheck() {
        const checkedKeys = this.$refs.treeRef.getCheckedKeys()
        this.appFormData.range_id = checkedKeys || []
        let archiName = ''
        const loopTree = (arr) => {
          arr.forEach(element => {
            if (checkedKeys.includes(+element.id)) {
              if (archiName) {
                archiName += ', ' + element.name
              } else {
                archiName = element.name
              }
            }
            if (element.children && element.children.length) {
              loopTree(element.children)
            }
          });
        }
        if (checkedKeys && checkedKeys.length) {
          loopTree(this.treeData)
        }
        this.archiName = archiName
      },
      getIcon(data) {
        const group = [1, 2]
        const company = [3, 4]
        const project = [5, 6]
        const section = [7]
        if (!data) {
          return '#iconjituan'
        }
        if (group.indexOf(+data.type) !== -1 ) {
          return '#iconjituan'
        } else if (company.indexOf(+data.type) !== -1 ) {
          return '#icongongsi'
        } else if (project.indexOf(+data.type) !== -1 ) {
          return '#iconxiangmu'
        } else if (section.indexOf(+data.type) !== -1 ) {
          return '#iconbumen'
        }
      },
    }
  }
</script>
<style lang='less' scoped>
/* 修改select下input展示 */
:deep(.select-icon) {
  .el-input {
    width: 48px;
    height: 48px;
    background: #FFFFFF;
    border-radius: 4px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;

    input {
      display: none;
    }
    .el-input__suffix {
      display: none;
    }
    .el-input__prefix {
      width: 48px;
      height: 48px;
      i {
        font-size: 18px;
        color: #000;
      }
    }
  }
}
.common-body{
  height: 350px;
}
  :deep(.avatar-uploader) {
    .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;

      &:hover {
        border-color: #409EFF;
      }
    }
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
  }

  .avatar {
    width: 80px;
    height: 80px;
    display: block;
  }
  .full-width {
    width: 100%;
  }
  .wrapper{
    display: flex;
    align-items: center;

    .right{
      flex: 1;
      .form-item-height {
        width: 100%;
        .show-name {
          width: 100%;
          overflow-x: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding-right: 40px;
          box-sizing: border-box;
        }
      }
    }
  }

  .application-tree {
    width: 240px;
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
    transition: width 0.3s;
    .application-tree-container {
      height: 100%;
    }
  }
  /* 头像显示 */
  .header-img {
    width: 48px;
    height: 48px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      font-size: 26px;
    }
  }
  /* 选择颜色图标 */
  .choose-color {
    width: 460px;
    height: 300px;
    display: flex;
    flex-direction: column;
    padding: 10px 0 10px 10px;
    box-sizing: border-box;
    p {
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
      color: #121315;
      margin: 16px 0;
    }
    .color-container {
      width: 100%;
      height: auto;
      display: flex;
      flex-wrap: wrap;
      .color-item {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        i {
          color: #fff;
        }
        .color-item-i {
          color: #606266
        }
      }
    }
    /* 架构选择样式 */
    .archi-label {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;
        -moz-user-select:none; /* Firefox私有属性 */
        -webkit-user-select:none; /* WebKit内核私有属性 */
        -ms-user-select:none; /* IE私有属性(IE10及以后) */
        -khtml-user-select:none; /* KHTML内核私有属性 */
        -o-user-select:none; /* Opera私有属性 */
        user-select:none; /* CSS3属性 */
        .archi-label-svg {
          width: 100%;
          display: flex;
          align-items: center;
          overflow: hidden;
          span {
            flex: 1;
            overflow: hidden;
            padding-right: 6px;
            box-sizing: border-box;
          }
          img {
            width: 20px;
            height: 20px;
            margin-right: 6px;
          }
          svg {
            width: 20px;
            height: 20px;
            margin-right: 6px;
          }
        }
      }
  }
</style>
<style lang="less" scoped>
@import "../../../../css/manageAdd.less";
</style>
