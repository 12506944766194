/*
 * @Author: yaoling 1871883713@qq.com
 * @Date: 2022-11-02 16:43:20
 * @LastEditors: yaoling 1871883713@qq.com
 * @LastEditTime: 2022-11-06 10:10:31
 * @FilePath: \dataview-next\src\api\statistics.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { axios } from '@/apis/http/request';

export const statistics = () => {
        return axios.request({
                // todo 线上后就有问题 先固定写死
                url: 'https://saas-api.bimcc.net/api/metadata/countData',
                method: 'get'
        });
}