<template>
	<div
		class="smart-chat-wrap"
		v-show="showSmart"
	>
		<!-- <div class="img-content"> -->
		<!-- <img
				src="https://openim-1309784708.cos.ap-shanghai.myqcloud.com/8ca3a813915c7c26bef91abe3ac19f41.png"
				style="width: 66px; height: 30px" />
			<div
				style="color: #ffffff; font-size: 16px"
				@click="goback"> -->
		<!-- <i class="iconfont icona-zuojiantouyuanjiaoL" style='margin-top:2px;'></i> -->
		<!-- 返回
			</div> -->
		<!-- <span style="color: #FFFFFF;font-size:16px">登录</span> -->
		<!-- </div> -->
		<header class="smart-chat-header">
			<div class="action">小椽</div>
			<div
				class="left"
				@click="goback"
			>
				<i
					class="iconfont iconc-close"
					style="margin-top: 2px"
				></i>
			</div>
		</header>

		<div class="smart-main">
			<div
				ref="mainRef"
				class="smart-chat-main"
			>
				<!-- <div class="main-header" @click="state.unfold = !state.unfold">
          <div class="main-img"
          :style="state.unfold?'background-image: url(https://openim-1309784708.cos.ap-shanghai.myqcloud.com/b96fab5960c926076ebd27c3a5087a41.png);height:165px;'
          :'background-image: url(https://openim-1309784708.cos.ap-shanghai.myqcloud.com/e33041723e68a6c411238ad76b56de6a.png);height:48px;'">
          <div class="img-content">
            <img src ='https://openim-1309784708.cos.ap-shanghai.myqcloud.com/8ca3a813915c7c26bef91abe3ac19f41.png' style="width:66px;height:30px"/>
            <span style="color: #FFFFFF;font-size:14px">登录</span>
          </div>
          </div>
        </div> -->
				<div
					v-for="(item, index) in messageList"
					:key="index"
					class="chart-item"
				>
					<!-- <div class="name" :class="item.from">
            {{ item.from === 'robot' ? '智能机器人' : '我' }}
          </div> -->
					<div
						style="display: flex"
						:class="item.from"
					>
						<div class="avatar-div">
							<img
								class="avatar"
								:src="
									item.from === 'robot' || item.from === 'hot-message'
										? 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/bfff1b89af8dfb32762a292f081c9a12.png'
										: userInfo?.avatar
										? userInfo.avatar
										: 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/7fd5a82c085a5df14f25c71f6d3774bd.png'
								"
							/>
						</div>

						<div
							class="message"
							:class="item.from"
						>
							<div class="message-wrap">
								<div
									v-if="item.btnLoading"
									class="loading-span"
								>
									<div class="loading">
										<span />
										<span />
										<span />
									</div>
									正在寻找答案
								</div>
								<MessageRender
									:data="item"
									@message="handleMessage"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<footer class="smart-chat-footer">
				<el-input
					type="textarea"
					v-model="input"
					autosize
					id="input"
					placeholder="输入消息..."
					@keydown.enter="send()"
				/>
				<el-button
					class="send-btn"
					type="default"
					@click.stop="send()"
				>
					发送
				</el-button>
			</footer>
		</div>
		<section
			class="mask"
			v-show="showMask"
		>
			<div class="img">
				<img :src="this.qrCodeUrl" />
				<div class="qr-code-name">关注微信公众号</div>
			</div>
		</section>
	</div>
</template>
<script>
import MessageRender from './MessageRender';
import { get } from 'axios';
import { postReq } from '@/saas-apis/konwledgeBase';

export default {
	data() {
		return {
			// 1关注 0未关注 (公众号)
			followed: 0,
			time: '',
			qrCode: '',
			qrCodeUrl: '',
			input: '',
			userInfo: '',
			unfold: false,
			showMask: true,
			codeLoading: false,
			messageList: [
				{
					from: 'robot',
					content: '您好，我是筑云智能小椽椽，请描述您的问题，小椽椽会帮您解决的哟',
					type: 'generalMessages'
				}
			]
		};
	},
	components: { MessageRender },
	props: {
		showSmart: {
			type: Boolean,
			required: true,
			default: false
		}
	},
	watch: {
		showSmart: {
			handler(newVal) {
				if (newVal === true && this.followed !== 1) {
					this.getQrCode();
				} else {
					clearInterval(this.time);
				}
			},
			immediate: true,
			deep: true
		}
	},
	mounted() {
		this.userInfo = localStorage.getItem('userInfo') && JSON.parse(localStorage.getItem('userInfo'));
		this.followed = localStorage.getItem('officialAccount') && JSON.parse(localStorage.getItem('officialAccount'));
		this.getHot();
		if (this.followed === 1) {
			this.showMask = false;
			clearInterval(this.time);
		}
	},
	methods: {
		goback() {
			console.log('goback');
			this.$emit('close');
			clearInterval(this.time);
		},
		/**
		 * @desc: 获取二维码
		 */
		getQrCode() {
			get('https://y.bimcc.com/api/wechat/qrcode/saasRobotQrcode')
				.then(res => {
					this.codeLoading = false;
					if (res.data.code === 200) {
						this.qrCode = res.data.data.code;
						this.qrCodeUrl = res.data.data.url;
						this.time = setInterval(() => {
							this.judgmentFocus();
						}, 3000);
					}
				})
				.catch(res => {
					this.$message.error(res.data.msg);
				})
				.finally(() => {
					this.codeLoading = false;
				});
		},
		/**
		 * @desc: 是否关注(公众号)
		 */
		judgmentFocus() {
			get('https://y.bimcc.com/api/wechat/qrcode/robotPoll?code=' + this.qrCode)
				.then(res => {
					this.codeLoading = true;
					if (res.data.code === 200) {
						if (res.data.data === 1) {
							this.showMask = false;
							clearInterval(this.time);
							localStorage.setItem('officialAccount', JSON.stringify(res.data.data));
							return;
						}
					}
				})
				.catch(res => {
					this.$message.error(res.data.msg);
				})
				.finally(() => {
					this.codeLoading = false;
				});
		},
		getHot() {
			this.messageList.push({
				from: 'hot-message',
				content: [
					{
						id: 5,
						user_id: 2829,
						user_name: 'admin',
						user_table: 'sys_users',
						user_department_id: 1,
						name: '什么是工程零代码开发？',
						key_words: '土方,土方开挖',
						answer:
							' 零代码是一种工具，无需手动编写任何代码，只需拖放现成的构件块，即可构建软件、Web 应用程序等，零代码开发平台提升了制作团队的能力，使团队能够以野兽模式进行设计开发，并大大减少错误；在零代码特性的基础上，结合工程行业的特点，融入工程行业的规范规则，最大程度的制作行业通用的工具和模板，在此基础上进一步提升了零代码平台针对工程行业的适用性，同时大大提升了工程行业开发管理平台进行数字化转型的效率。',
						is_hot: 1
					},
					{
						id: 6,
						user_id: 2829,
						user_name: 'admin',
						name: '什么是飞椽云？',
						img: 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/a9e0b499e4b8670b88e6bbe921323445.png',
						answer:
							'飞椽云是筑云科技自主研发的为工程领域相关企业数字化转型进行赋能的云体系，包含为项目管理提供标准化应用的「BIMCC数字建造平台4.0」和为工程企业提供零代码开发的工具的「飞椽工程零代码开发平台」两大平台。其中「飞椽工程零代码开发平台」拥有九大功能引擎和一个物联网中台，可为复杂的项目管理需求提供强有力的零代码开发能力。咨询热线：023-68682379体验地址：www.bimcc.com平台交流群：',
						is_hot: 1
					},
					{
						id: 7,
						user_id: 2829,
						user_name: 'admin',
						name: '什么是BIMXR?',
						answer:
							' BIMXR是指能将BIM技术和XR技术（包含VR、AR、MR技术)进行无缝融合并可提供极致的沉浸式融合式应用的技术，在工程相关领域，目前将该技术融合集成较好的软件是「FUZOR」。',
						is_hot: 1
					}
				],
				type: 'hotList'
			});
		},
		handleMessage(type, data) {
			console.log('*****************************');
			const item = {
				from: 'me',
				content: data.name,
				type: 'generalMessages'
			};
			this.messageList.push(item);
			this.messageList.push({
				from: 'robot',
				content: data.answer,
				img: data.img,
				type: 'answer'
			});
			this.$nextTick().then(() => {
				if (this.$refs.mainRef.lastElementChild.scrollIntoView) {
					this.$refs.mainRef.lastElementChild.scrollIntoView();
				}
			});
		},
		send(message = '') {
			if (!this.input && !message) return;
			const data = {
				from: 'me',
				content: message || this.input,
				type: 'generalMessages'
			};
			this.messageList.push(data);
			this.input = '';
			this.$nextTick().then(() => {
				if (this.$refs.mainRef.lastElementChild.scrollIntoView) {
					this.$refs.mainRef.lastElementChild.scrollIntoView();
				}
			});
			this.doQuery(data.content);
		},
		doQuery(data) {
			const REQDATA = {
				url: 'api/saas/intelligenceAnswer',
				data: {
					name: data
				}
			};
			this.messageList.push({
				from: 'robot',
				content: '',
				type: 'answer',
				btnLoading: true
			});
			let length = this.messageList.length;
			postReq(REQDATA.url, REQDATA.data).then(res => {
				if (res.data.code === 200) {
					this.messageList[length - 1].btnLoading = false;
					this.messageList[length - 1].content = res.data.data;
					this.$nextTick().then(() => {
						if (this.$refs.mainRef.lastElementChild.scrollIntoView) {
							this.$refs.mainRef.lastElementChild.scrollIntoView();
						}
					});
				}
			});
		}
	}
};
</script>
<style lang="less" scoped>
.smart-chat-wrap {
	position: relative;
	width: 382px;
	height: 600px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	// background-image: url('https://openim-1309784708.cos.ap-shanghai.myqcloud.com/c14ebf900431480bb7fc006ce3e486bc.png');
	// background-repeat: no-repeat;
	// background-size: 100%;
	position: fixed;
	right: 91px;
	top: 198px;
	border-radius: 8px;

	box-shadow: 0px 4px 25px rgba(122, 149, 173, 0.25);
	.img-content {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 30px;
		padding: 11px 14px;
	}
	.smart-chat-header {
		height: 48px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding: 12px;
		box-sizing: border-box;
		color: #fff;
		border-radius: 8px 8px 0px 0px;
		background-image: url(https://openim-1309784708.cos.ap-shanghai.myqcloud.com/b11ba03c809e3444813773cfe1ea26d7.png);
		background-repeat: no-repeat;
		background-size: 100%;
		box-sizing: border-box;
		.left {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			color: #fff;
			font-size: 18px;
			width: 20%;
			img {
				width: 20px;
				height: 20px;
				margin-right: 16px;
			}
		}
		.action {
			width: 60%;
			font-weight: 500;
			font-size: 17px;
			line-height: 26px;
			color: #fff;
			text-align: center;
		}
	}
	.smart-main {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		box-sizing: border-box;

		.smart-chat-main {
			// max-height: 384px;
			flex: 1;
			// height: 10px;
			box-sizing: border-box;
			// padding: 16px 0;
			overflow-y: auto;
			background: #f4f6fa;
			padding-top: 8px;
			max-height: 488px;
			.main-header {
				width: 100%;
				padding: 8px;
				margin-bottom: 8px;
				box-sizing: border-box;
				.main-img {
					transition: all 0.3s;
					padding: 0 16px;
					padding-top: 10px;
					box-sizing: border-box;
					background-size: cover;
					.img-content {
						display: flex;
						align-items: center;
						justify-content: space-between;
						height: 30px;
					}
				}
			}
			.chart-item {
				width: 100%;
				padding: 0 10px;
				box-sizing: border-box;
				.time-stamp {
					text-align: center;
					span {
						padding: 2px;
						border-radius: 2px;
						font-size: 10px;
						background: #fff;
					}
				}
				.name {
					display: flex;
				}
				.me {
					flex-direction: row-reverse;
					justify-content: flex-start;
					.avatar-div {
						position: relative;
						width: 38px;
						height: 38px;
						margin-left: 7px;
					}
					.avatar-div:before {
						content: '';
						position: absolute;
						transform: translateY(-5px);
						top: 50%;
						left: -7px;
						width: 0;
						height: 0;
						border: 5px solid transparent;
						border-left-color: #a4c4fe;
					}
					.avatar {
						width: 38px;
						height: 38px;
						border-radius: 50%;
						position: relative;
					}

					.message-wrap {
						font-size: 16px;
						color: #161c1f;
						max-width: 100%;
						height: auto;
						padding: 8px;
						// background: #000;
						// word-break: break-all;
						line-height: 22px;
						border-radius: 4px;
						// min-width: 32px;
						// padding: 3px 8px;
						// box-sizing: border-box;
						// border-radius: 4px;
						text-align: left;
						color: #161c1f;
						background: #a4c4fe;
						// position: relative;
						// user-select: text;
						// -webkit-user-select: text;
						// &::selection{
						//   background: #A4C4FE;
						//   color: #fff;
						// }
					}
				}
				.robot {
					justify-content: flex-start;
					.avatar-div {
						position: relative;
						width: 38px;
						height: 38px;
						margin-right: 7px;
					}
					.avatar-div:before {
						content: '';
						position: absolute;
						transform: translateY(-5px);
						right: -7px;
						top: 50%;
						width: 0;
						height: 0;
						border: 5px solid transparent;
						border-right-color: #fff;
					}
					.avatar {
						width: 38px;
						height: 38px;
						border-radius: 50%;
						position: relative;
					}
					.message-wrap {
						min-width: 32px;
						padding: 8px;
						box-sizing: border-box;
						border-radius: 3px;
						color: #161c1f;
						background: #fff;
						position: relative;
						user-select: text;
						-webkit-user-select: text;
						::selection {
							background: #a4c4fe;
							color: #fff;
						}
						&::before {
						}
					}
				}
				.hot-message {
					justify-content: flex-start;
					.hot-message {
						width: 227px;
						background: #fff;
					}
					.avatar-div {
						position: relative;
						width: 38px;
						height: 38px;
						margin-right: 7px;
					}
					.avatar-div:before {
						content: '';
						position: absolute;
						transform: translateY(-5px);
						right: -7px;
						top: 50%;
						width: 0;
						height: 0;
						border: 5px solid transparent;
						border-right-color: #fff;
					}
					.avatar {
						width: 38px;
						height: 38px;
						border-radius: 50%;
						position: relative;
					}
					.message-wrap {
						width: 228px;
						min-width: 32px;
						padding: 8px;
						box-sizing: border-box;
						border-radius: 3px;
						color: #161c1f;
						background: #fff;
						position: relative;
						user-select: text;
						-webkit-user-select: text;
						::selection {
							background: #a4c4fe;
							color: #fff;
						}
						&::before {
						}
					}
				}
				.message {
					max-width: calc(100% - 90px);
					// margin-top: 11px;
					// display: flex;
					:deep(.message-wrap) {
						font-size: 16px;
						word-break: break-all;
						color: #161c1f;
						max-width: 100%;
						height: auto;
						line-height: 22px;
						border-radius: 4px;

						.list-warp {
							width: 208px;
							.name {
								color: #206efc;
								padding-bottom: 5px;
								border-bottom: 1px solid #dfe3e8;
							}
							.list-ul {
								.list-item {
									color: #161c1f;
									padding: 8px 0;
									border-bottom: 1px solid #dfe3e8;
									display: flex;
									justify-content: space-between;
									// .point{
									//   display: inline-block;
									//   width: 6px;
									//   height: 6px;
									//   background: #A4C4FE;
									//   border-radius: 50%;
									//   margin-right: 5px;
									// }
									// &:hover{
									//   text-decoration: underline;
									// }
								}
							}
						}
						.answer-warp {
							.action-row {
								min-width: 120px;
								display: flex;
								align-items: center;
								margin-top: 6px;
								border-top: 1px solid #f1f1f1;
								.action-item {
									flex-grow: 1;
									text-align: center;
									position: relative;
									& + .action-item {
										border-left: 1px solid #f1f1f1;
									}
									.roate-180 {
										display: inline-block;
										transform-origin: center;
										transform: rotateZ(180deg);
									}
								}
							}
							.answer-img {
								width: 50%;
								height: auto;
								display: block;
								margin: 0 auto;
							}
						}
					}
				}
				& + .chart-item {
					margin-top: 10px;
				}
			}
			.loading-span {
				// padding: 10px 6px;
				box-sizing: border-box;
				display: flex;
				justify-content: center;
				.loading {
					display: flex;
					align-items: center;
					background: #fff;
					padding: 4px 8px;
					border-radius: 4px;
					box-sizing: border-box;
					@keyframes twinkle {
						0% {
							opacity: 1;
						}
						100% {
							opacity: 0;
						}
					}
					span {
						display: inline-block;
						width: 2px;
						height: 2px;
						background: #161c1f;
						border-radius: 50%;
						animation: twinkle 1.5s infinite linear;
						& + span {
							margin-left: 10px;
						}
						&:nth-child(1) {
							animation-delay: 0s;
						}
						&:nth-child(2) {
							animation-delay: 0.2s;
						}
						&:nth-child(3) {
							animation-delay: 0.4s;
						}
					}
				}
			}
		}
	}

	.smart-chat-footer {
		height: auto;
		min-height: 64px;
		display: flex;
		align-items: flex-start;
		// border-radius: 4px;
		padding: 8px;
		padding-bottom: 16px;
		box-sizing: border-box;
		border: 1px solid #f4f6fa;
		border-radius: 0px 0px 8px 8px;
		background: rgba(255, 255, 255);
		border-top: none;
		input {
			padding-left: 8px;
			height: 32px;
			flex: 1;
			margin-right: 8px;
			box-sizing: border-box;
			background: #ffffff;
			/* sys/surface/surface-01-active */

			border: 1px solid #c1c7cd;
			border-radius: 4px;
		}
		input::-webkit-input-placeholder {
			/* WebKit browsers */
			letter-spacing: 0.5px;
			color: #a5acb3;
			font-size: 16px;
		}
		.send-btn {
			color: #ffffff;
			width: 61px;
			height: 32px;
			background: #206efc;
			border-radius: 4px;
			display: flex;
			align-items: center;
			justify-content: space-around;
		}
	}
}
.smart-chat-wrap .mask {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.7);
	.img {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 200px;
		height: 190px;
		background: #fff;
		img {
			width: 150px;
			height: 150px;
		}
		.qr-code-name {
			text-align: center;
			color: #161c1f;
		}
	}
}
</style>
