import http from "../global/http"
import cacheHttp from './apiStore'
import { apiStore } from "./apiStore"
import { axios } from '../apis/http/request';

// 全局字段和全局字典的 object_uuid 为 global
// 所有的字典，字段，对象，关联关系的键均为 uuid
// code字段 一旦建立都不允许修改
const objects = {

  getMockToken() {
    let data = localStorage.getItem('mockUserInfo')
    if (!data) {
      return ''
    } else {
      data = JSON.parse(data)
      return data.token
    }
  },
  // 获取对象数据带搜索
  getAll: (page, size, search = '', is_virtual = null, enabled = 0) => {
    let params = { page: page, size: size }
    params.is_virtual = is_virtual

    if (search) {
      params.name = search
    }
    if (enabled > 0) {
      params.enabled = enabled
    }

    return cacheHttp({
      url: 'objects',
      method: 'GET',
      params: params
    })
  },

  getOne: (obuuid) => {
    return http({
      url: 'objects/' + obuuid,
      method: "GET",
    })
  },

  /**
   * @description: 新增一个对象
   * @param {Object} data 新增的数据
   * @return {AxiosPromise} AxiosPromise
   */
  addOne: (data) => {
    return http({
      url: 'objects',
      method: 'POST',
      data: data
    })
  },

  // 编辑一个对象，目前只支持修改对象名称描述
  editOne: (uuid, name, remark, extra = '') => {
    let data = {
      name: name,
      remark: remark
    }
    if (extra) {
      data.extra = extra
    }
    return http({
      url: 'objects/' + uuid,
      method: 'PUT',
      data: data
    })
  },

  /**
   * @description: 导出对象数据
   * @param {String} uuid 需要导出的对象的uuid
   * @return {AxiosPromise} AxiosPromise
   */
  exportOne: (uuid) => {
    return http({
      url: 'objects/exportObject/' + uuid,
      method: 'GET',
      params: {
        is_file: 0
      }
    })
  },

  /**
   * @description: 本仓库导入对象
   * @param {String} data 要导入的对象数据
   * @param {Object} target 要导入的服务器
   * @return {AxiosPromise} AxiosPromise
   */
  importOne: (data, target = null) => {
    if (target && target.value && target.value.originURl) {
      return http({
        url: target.value.originURl + '/api/metadata/objects/importObject',
        method: "POST",
        data: {
          data,
          is_file: 0
        },
        metadatatoken: target.value.metadatatoken
      })
    }
    return http({
      url: 'objects/importObject',
      method: 'POST',
      data: {
        is_file: 0,
        data: data
      }
    })
  },

  /**
   * @description: 导出对象表数据
   * @param {String} uuid 需要导出的对象的uuid
   * @return {AxiosPromise} AxiosPromise
   */
  exportObjData: (uuid) => {
    return http({
      url: 'objectData/exportObjectData/' + uuid,
      method: 'GET',
      params: {
        is_file: 0
      }
    })
  },


  /**
   * @description: 导入对象表数据
   * @param {String} uuid 需要导出的对象的uuid
   * @param {String} data 要导入的对象数据
   * @param {Object} target 要导入的服务器
   * @return {AxiosPromise} AxiosPromise
   */
  importObjData: (uuid, data, target = null) => {
    if (target && target.value && target.value.originURl) {
      return http({
        url: target.value.originURl + '/api/metadata/objectData/importObjectData/' + uuid,
        method: "POST",
        data: {
          data: data,
          is_file: 0
        },
        metadatatoken: target.value.metadatatoken
      })
    }
    return http({
      url: 'objectData/importObjectData/' + uuid,
      method: 'POST',
      data: {
        data: data,
        is_file: 0
      }
    })
  },

  // 删除一个对象
  deleteOne: () => {
    // return http({
    //     url :'objects',
    //     method :'PUT',
    //     data:{
    //         name : name,
    //         remark : remark
    //     }
    // })
  },

  // =================== 字段集 =================
  // 获取对象下的所有字段
  _fieldsStore: [],

  getFields: (obuuid, data = {}) => {

    let params = { object_uuid: obuuid, size: 500, page: 1, ...data }
    // let pKey = JSON.stringify(params)
    // // 如果有缓存直接返回promise
    // if(objects._fieldsStore[pKey]){
    //   return objects._fieldsStore[pKey]
    // }
    let res = cacheHttp({
      url: 'objectFields',
      method: 'GET',
      params: params
    })
    // objects._fieldsStore[pKey] = res
    // // 10s 后销毁缓存
    // setTimeout(() => {
    //   delete objects._fieldsStore[pKey]
    // }, 10000);

    return res
  },

  //字段集视图转换
  transFields: (obuuid) => {
    return http({
      url: 'transform/' + obuuid,
      method: 'GET',
    });
  },
  //字段UUID转换
  transFieldsUUid: (obuuid) => {
    return http({
      url: 'transformuuid/' + obuuid,
      method: 'GET',
    });
  },

  // 获取表单数据
  getFormList: (object_uuid) => {
    return axios.request({
      url: 'api/saas/metadata/getFormDesigns',
      params: {
        object_uuid: object_uuid
      },
      method: 'GET'
    });
  },

  // 获取子表单数据
  getSubFormList: (parent_uuid) => {
    return http({
      url: 'getSubFormDesigns',
      method: "GET",
      params: {
        parent_uuid: parent_uuid
      }
    })
  },

  editFormName: (id, data) => {
    return http({
      url: 'editFormName/' + id,
      method: "PUT",
      data: data
    })
  },
  editFormFlow: (uuid, data) => {
    return http({
      url: 'editFormFlow/' + uuid,
      method: "PUT",
      data: data
    })
  },

  deleteForm: (id) => {
    return http({
      url: 'deleteForm/' + id,
      method: "delete",
    })
  },

  // 更新表单规则
  updateFormRules: (uuid,data) => {
    return http({
      url: 'objectFields/updateFormRules/' + uuid,
      method: "PUT",
      data: data
    })
  },

  //保存数据对象表单设计
  saveObjectDesign: (data) => {
    return http({
      url: 'objectFields/saveForm',
      data: data,
      method: 'POST',
    });
  },

  // 获取某个字段的详情
  getFieldDetail: (uuid) => {
    return http({
      url: 'objectFields/' + uuid,
      method: "GET",
    })
  },
  // 修改某个字段
  // 普通字段 修改name is_search comment default
  // 虚拟字段 修改name is_search comment extra
  editField: (uuid, data) => {
    return http({
      url: 'objectFields/' + uuid,
      method: "PUT",
      data: data
    })
  },

  // 新增某个字段
  // data:{object_uuid,name,code,type,comment,default,is_search,extra}
  // mysql 数值类型 ：'tinyint', 'smallint', 'mediumint', 'int', 'integer', 'bigint', 'float', 'double', 'decimal',
  // mysql 日期和时间类型：'date', 'time', 'year', 'datetime', 'timestamp',
  // mysql 字符串类型：'char', 'varchar', 'tinyblob', 'tinytext', 'blob', 'text', 'mediumblob', 'mediumtext', 'longblob', 'longtext',
  // 虚拟字段：'append',
  //types 目前使用这些
  fieldTypes: [
    { name: '整数 - integer', value: 'integer' },
    { name: '小数 - decimal', value: 'decimal' },
    { name: '日期时间 - datetime', value: 'datetime' },
    { name: '时间戳 - timestamp', value: 'timestamp' },
    { name: '字符串 - varchar', value: 'varchar' },
    { name: '字符串 - string', value: 'string' },
    { name: '长字符串 - text', value: 'text' },
    { name: '超长字符串 - longtext', value: 'longtext' },
    { name: '虚拟字段 - append', value: 'append' },
  ],


  /**
   * @description: 返回字段类型
   * @param {String} type 字段类型
   * @return {String} field name
   */
  getFieldTypeName(type) {
    let one = objects.fieldTypes.find(item => item.value === type);
    if (one) {
      return one.name
    } else {
      return type
    }
  },


  addField: (data) => {
    return http({
      url: 'objectFields',
      method: "POST",
      data: data
    })
  },

  // 删除某个字段
  deleteField: (uuid) => {
    return http({
      url: 'objectFields/' + uuid,
      method: "DELETE",
    })
  },

  // =============== 字段字典 不分页 ================
  // 字典列表
  getDicts: (uuid) => {
    return cacheHttp({
      url: 'dictionary',
      method: "GET",
      params: { object_uuid: uuid },
      time: 10
    })
  },
  //获取列表详情
  getDictDetail: (uuid) => {
    return http({
      url: 'dictionary/' + uuid,
      method: "GET",
    })
  },

  /**
   * @description: 获取有取值类的字典
   * @return {*}
   */
  getValueDicts: (uuid) => {
    return http({
      url: '/api/metadata/dictionary/getValueDict',
      method: "GET",
      params: { object_uuid: uuid }
    })
  },

  // 获取字典数据
  getDictItems: (uuid) => {
    return http({
      url: 'dictionaryItem',
      method: "GET",
      params: { uuid: uuid }
    })
  },

  getDictInfo: (uuid, obuuid, data_id = 0) => {
    return http({
      url: '/api/mapi',
      method: "POST",
      data: {
        __method_name__: 'getDictInfo',
        dict_uuid: uuid,
        object_uuid: obuuid,
        data_id: data_id
      }
    })
  },



  replicateToGlobal: (uuid, force = false) => {
    return http({
      url: 'dictionary/' + uuid + '/replicateToGlobal',
      method: "POST",
      data: {
        force: force
      }
    })
  },

  dictionaryItemType: [
    { value: 0, name: '请选择' },
    { value: 3, name: '固定值' }, // 直接填
    { value: 1, name: '字段' }, // 选择全局字段或者本表字段
    { value: 4, name: '字典' }, // 选择全局字典或者本表字典
    { value: 5, name: '列表' }, // 选择全局字典或者本表字典  列表和字典实际上一样，只是列表是当做数组用
    { value: 6, name: '取值类' }, // 选择全局字典或者本表字典  列表和字典实际上一样，只是列表是当做数组用
  ],
  // 新增一条字典item
  // name , object_uuid , code , dictionay_uuid , type , value
  addDictItem: (data) => {
    return http({
      url: 'dictionaryItem',
      method: "POST",
      data: data
    })
  },

  // 编辑一条字典item
  // name，type，value  其他的不允许编辑
  editDictItem: (uuid, data) => {
    return http({
      url: 'dictionaryItem/' + uuid,
      data: {
        name: data.name,
        type: data.type,
        value: data.value,
        code: data.code,
        dictionary_uuid: data.dictionary_uuid,
        object_uuid: data.object_uuid,

      },
      method: "PUT"
    })
  },

  // 删除一条字典item
  deleteDictItem: (uuid) => {
    return http({
      url: 'dictionaryItem/' + uuid,
      method: "DELETE"
    })
  },

  // 新增一个字典
  // name , object_uuid , code
  addDict: (data) => {
    return http({
      url: 'dictionary',
      method: "POST",
      data: data
    })
  },

  // 编辑一个字段字典
  // 只能修改名称
  editDict: (uuid, data) => {
    return http({
      url: 'dictionary/' + uuid,
      method: "PUT",
      data: data
    })
  },

  // 删除一个字段字典
  deleteDict: (uuid) => {
    return http({
      url: 'dictionary/' + uuid,
      method: "DELETE"
    })
  },

  /**
   * @description 定时任务列表
   * @returns {AxiosPromise} AxiosPromise
   */
  getTimedTasks: () => {
    return http({
      url: 'timedTask',
      method: "GET",
    })
  },
  /**
   * @description 定时任务详情
   * @param uuid 定时任务uuid
   * @returns {AxiosPromise} AxiosPromise
   */
  getTimedTaskInfo: (uuid) => {
    return http({
      url: 'timedTask/' + uuid,
      method: "GET",
    })
  },
  /**
   * @description 添加定时任务
   * @param data 添加数据
   * @returns {AxiosPromise} AxiosPromise
   */
  addTimedTask: (data) => {
    return http({
      url: 'timedTask',
      method: "POST",
      data: data
    })
  },
  /**
   * @description 编辑定时任务
   * @param uuid 定时任务uuid
   * @param data 编辑数据
   * @returns {AxiosPromise} AxiosPromise
   */
  editTimedTask: (uuid, data) => {
    return http({
      url: 'timedTask/' + uuid,
      method: "PUT",
      data: data
    })
  },
  /**
   * @description 删除定时任务
   * @param uuid 定时任务uuid
   * @returns {AxiosPromise} AxiosPromise
   */
  delTimedTask: (uuid) => {
    return http({
      url: 'timedTask/' + uuid,
      method: "DELETE",
    })
  },
  /**
   * @description 手动执行定时任务
   * @param uuid 定时任务uuid
   * @returns {AxiosPromise} AxiosPromise
   */
  manualExecuteTimedTask: (uuid) => {
    return http({
      url: 'timedTask/manualExecute/' + uuid,
      method: "POST",
    })
  },
  /**
   * @description 定时任务执行日志
   * @param uuid 定时任务uuid
   * @returns {AxiosPromise} AxiosPromise
   */
  logsTimedTask: (uuid, page = 1) => {
    return http({
      url: 'timedTask/logs/' + uuid,
      method: "GET",
      params: { page: page }
    })
  },

  // ================= API集成 ===================
  // 获取 API 列表
  getApis: (uuid) => {
    return http({
      url: 'objectIntegratedApi',
      method: 'get',
      params: { object_uuid: uuid }
    })
  },

  // 获取API详情
  getApiDetail: (uuid) => {
    return http({
      url: 'objectIntegratedApi/' + uuid,
      method: 'get',
    })
  },

  triggers: [
    { value: '', name: '请选择' },
    { value: 'created', name: '创建完成后' },
    { value: 'updated', name: '修改完成后' },
    { value: 'deleting', name: '删除完成前' },
  ],

  requestMethods: [
    { value: 'GET', name: 'GET' },
    { value: 'POST', name: 'POST' },
    { value: 'PUT', name: 'PUT' },
    { value: 'DELETE', name: 'DELETE' },
  ],

  // 新增API 编辑同理
  // object_uuid , name , code , url , method(select), header(select dicts) ,
  // query(select dicts) , form(select dicts) ,raw(select dicts) ,
  // trigger(select triggers) , enable
  addApi: (data) => {
    return http({
      url: 'objectIntegratedApi',
      method: "POST",
      data: data
    })
  },

  // 修改 API数据
  editApi: (uuid, data) => {
    return http({
      url: 'objectIntegratedApi/' + uuid,
      method: "PUT",
      data: data
    })
  },

  // 删除API
  deleteApi: (uuid) => {
    return http({
      url: 'objectIntegratedApi/' + uuid,
      method: "DELETE"
    })
  },

  // 测试 API
  testApi: (uuid, data_id, trigger) => {
    return http({
      url: 'triggerIntegratedApi',
      method: "POST",
      data: {
        object_uuid: uuid,
        data_id: data_id,
        trigger: trigger // triggerSelects
      }
    })
  },

  // API请求日志
  apiLogs: (uuid, page = 1) => {
    return http({
      url: 'objectIntegratedApiLog',
      method: "GET",
      params: { api_uuid: uuid, page: page }
    })
  },

  // ========== 特殊、组合API ==========
  getFieldsSelect: (uuid) => {
    return Promise((resolve, reject) => {
      let data = {}
      this.getFields(uuid).then(res => {
        if (res.data.code == 200) {
          data.mine = res.data.data.data
          this.getFields('global').then(() => {
            if (res.data.code == 200) {
              data.global = res.data.data.data
              resolve(data)
            } else {
              reject()
            }
          }).catch(() => {
            reject()
          })
        } else {
          reject()
        }
      }).catch((err) => {
        reject(err)
      })
    })
  },

  getDictsSelect: (uuid) => {
    return new Promise((resolve, reject) => {
      let data = [];
      objects.getDicts(uuid).then((res) => {
        if (res.data.code == 200) {
          let obj = { label: "普通字典", options: res.data.data };
          data.push(obj);
          objects.getDicts("global").then((res1) => {
            if (
              res1.data.code == 200 &&
              res1.data.data &&
              res1.data.data.length != 0
            ) {
              let obj1 = { label: "全局字典", options: res1.data.data };
              data.unshift(obj1);
              resolve(data);
            }
          }).catch(() => reject());
        }
      }).catch(() => reject());
    })
  },

  // 取值函数获取 - 永久缓存
  // 取值函数大部分情况只在虚拟字段定义中使用
  // 取值函数中有对函数需要的值的定义 type 是组件类型
  // field 取全局字段或者本对象字段 可以是虚拟字段
  // dictionary 取全局字典或者本对象字典
  // relationship 只能取本对象的关联关系
  getValueFunctions() {
    return cacheHttp({
      url: 'valueClasses',
      method: "GET",
      time: 0
    })
  },

  // =============== 关联关系 ================
  // get relationships from object_uuid
  getMyRelations: (uuid) => {
    return http({
      url: 'relationships',
      method: "GET",
      params: { owner_uuid: uuid, size: 1000 }
    })
  },

  // get object's related infos
  getMyRelated: (uuid) => {
    return http({
      url: 'relationships',
      method: "GET",
      params: { related_uuid: uuid }
    })
  },

  // relation_uuid
  getRelationDetail: (uuid) => {
    return http({
      url: 'relationships/' + uuid,
      method: "GET",
    })
  },

  relationTypes: [
    { name: '请选择关联关系', value: 0 },
    { name: '一对一：扩展', value: 1 },
    { name: '一对多：子表', value: 2 },
    // { name: '多对多：多选', value: 3 },
  ],
  // type(select relationtypes),name,code  都必填
  // owner_uuid(一定是当前对象uuid),owner_primary_uuid(当前对象的某个字段uuid，不填默认id字段)
  // related_uuid(被关联的对象 必填),related_primary_uuid(被关联的对象的主键 ，不填默认id字段)
  // view_uuid (被关联对象的视图uuid 必填)
  // pivot_uuid （中间表对象）, owner_pivot_uuid(中间表代表主对象的字段)，related_primary_uuid(中间表代表被关联对象的字段)

  addRelation: (data) => {
    return http({
      url: "relationships",
      method: "POST",
      data: data
    })
  },

  // relation_uuid type ，code 等不允许编辑
  editRelation: (uuid, data) => {
    return http({
      url: "relationships/" + uuid,
      method: "PUT",
      data: data
    })
  },

  // relation_uuid
  deleteRealtion: (uuid) => {
    return http({
      url: 'relationships/' + uuid,
      method: "DELETE"
    })
  },

  entityType: [
    { value: 1, name: '对象' },
    { value: 2, name: '字段' },
  ],

  // ============== 验证 验证左侧为对象下所有的*非*虚拟字段 ======================
  // get field's validates detial
  getValidateList: (uuid) => {
    return http({
      url: 'validates',
      method: "GET",
      params: { entity_uuid: uuid }
    })
  },
  getValidateDetail: (uuid) => {
    return http({
      url: 'validates/' + uuid,
      method: "GET",
    })
  },

  operators: [
    { name: '或：有一个成立即可', value: 'or' },
    { name: '且：全部成立才可', value: 'and' }
  ],

  // add validate
  // uuid : field uuid
  // data : type:2 固定值
  //        desc：描述
  //        message：验证失败提示
  //    rules:   {oprator:"and | or",
  //                 children:[
  //                     {ruleType:"",rightValue:{class:"const_value",payload:{"const_value":3},message:"报错信息1"},
  //                     {ruleType:"",rightValue:{class:"const_value",payload:{"const_value":3},message:"报错信息2"},
  //                     {ruleType:"",rightValue:{class:"const_value",payload:{"const_value":3},message:"报错信息3"}
  //                 ]
  //             }
  addFieldValidate: (data) => {
    return http({
      url: 'validates',
      method: "POST",
      data: data
    })
  },

  editFieldVAlidate: (uuid, data) => {
    return http({
      url: 'validates/' + uuid,
      method: "PUT",
      data: data
    })
  },

  deleteFieldValidate: (uuid) => {
    return http({
      url: 'validates/' + uuid,
      method: "DELETE",
    })
  },

  // ================= 视图 ========================

  orderType: [
    { name: '正序', value: 'asc' },
    { name: '倒序', value: 'desc' },
    { name: '分组', value: 'groupby' }
  ],
  filterType: [
    { name: '请选择', value: '' },
    { name: '等于 =', value: 'eq' },
    { name: '不等于 != <>', value: 'noteq' },
    { name: '大于 >', value: 'gt' },
    { name: '大于等于 >=', value: 'gte' },
    { name: '小于 <', value: 'lt' },
    { name: '小于等于 <=', value: 'lte' },
    { name: '类似 like', value: 'like' },
    { name: '不类似 not like', value: 'notlike' },
    { name: '包含于 in', value: 'in' },
    { name: '严格包含于 binary in', value: 'binaryin' },
    { name: '不包含于 not in', value: 'notin' },
    { name: '区间包含 between', value: 'between' },
    { name: '区间不包含 not between', value: 'notbetween' },
    { name: '空 empty', value: 'empty' },
    { name: '非空 not empty', value: 'notEmpty' },
  ],

  getViewsList: (obuuid, data = {}) => {
    data.object_uuid = obuuid
    data.size = 999
    return http({
      url: 'views',
      method: "GET",
      params: data
    })
  },

  // data: name object_uuid is_paging
  // select[field_uuid]
  // filter[operator,type:eq|gt|gte|lt|lte|like|in|empty|notEmp,filed_uuid,value,children:[无限极]]
  // order [field_uuid , operator:desc|asc]
  addView: (obuuid, data) => {
    data.object_uuid = obuuid
    return http({
      url: 'views',
      method: "POST",
      data: data
    })
  },

  addDefaultField: (obuuid, type = '') => {
    let data = {
      object_uuid: obuuid,
      type: type
    }
    return http({
      url: 'addDefaultFields',
      method: "POST",
      data: data
    })
  },

  addDefaultView: (obuuid) => {
    let id_uuid = ''
    let field_uuids = []
    return new Promise((resolve, reject) => {
      objects.getFields(obuuid).then(res => {
        if (res.data.code == 200) {
          let fields = res.data.data.data
          fields.forEach(item => {
            field_uuids.push(item.uuid)
            if (item.code == 'id') {
              id_uuid = item.uuid
            }
          });
          let data = {
            "name": "所有数据",
            "is_paging": true,
            "select": field_uuids,
            "filter": {
              "id": 0,
              "label": "且：全部成立才可",
              "operator": "and",
              "children": [
                {
                  "id": 1000,
                  "label": "默认",
                  "children": [],
                  "operator": "",
                  "ruleType": "gt",
                  "leftValue": id_uuid,
                  "rightValue": {
                    "class": "ConstValue",
                    "payload": {
                      "const_value": "0",
                      "_throw_dte": false
                    }
                  }
                }
              ]
            },
            "order": [
              {
                "field_uuid": id_uuid,
                "operator": "desc"
              }
            ],
            "object_uuid": obuuid
          }
          objects.addView(obuuid, data).then(res => {
            resolve(res)
          }).catch(() => {
            reject()
          })
        }
      }).catch(() => {
        reject()
      })
    })

  },

  editView: (uuid, data) => {
    return http({
      url: 'views/' + uuid,
      method: "PUT",
      data: data
    })
  },

  deleteView: (uuid) => {
    return http({
      url: 'views/' + uuid,
      method: "DELETE",
    })
  },

  // 展示视图数据
  getOneView: (uuid) => {
    return http({
      url: "views/" + uuid,
      method: "get"
    })
  },

  // 展示视图数据
  getViewDocument: (uuid) => {
    return http({
      url: "views/document/" + uuid,
      method: "get"
    })
  },

  // ==================== 事件 =======================

  eventTypes: [
    { name: '自定义事件', value: 2 },
    { name: '系统事件', value: 1 }
  ],

  systemEvents: [
    { name: '新增之前', value: 'creating' },
    { name: '新增之后', value: 'created' },
    { name: '修改之前', value: 'updating' },
    { name: '修改之后', value: 'updated' },
    { name: '关联关系处理完毕', value: 'relationComplete' },
    { name: '删除之前', value: 'deleting' },
    { name: '删除之后-仅触发', value: 'deleted' },
    { name: '流程-发起', value: 'flowEngineStart' },
    { name: '流程-退回', value: 'flowEngineBack' },
    { name: '流程-通过', value: 'flowEnginePass' },
    { name: '流程-转交', value: 'flowEngineTurn' },
    { name: '流程-重新发起', value: 'flowEngineRestart' },
    { name: '流程-提交至退回人', value: 'flowEngineToBack' },
    { name: '流程-结束', value: 'flowEngineEnd' },
    //   $flowSysEvent = [
    //     'flowEngineStart',      //流程发起
    //     'flowEngineBack',       //流程退回
    //     'flowEnginePass',       //流程通过
    //     'flowEngineTurn',       //流程转交
    //     'flowEngineRestart',    //流程重新发起
    //     'flowEngineToBack',     //流程提交至退回人
    //     'flowEngineEnd',        //流程结束
    // ];
  ],

  /**
   * @description: 获取系统事件名称
   */
  getSystemEventName: (name) => {
    for (let i = 0; i < objects.systemEvents.length; i++) {
      const item = objects.systemEvents[i]
      if (item.value == name) {
        return item.name
      }
    }
    return name;
  },
  getEventsList: (uuid, data = { page: 1, size: 15 }) => {
    data.object_uuid = uuid
    return http({
      url: "events",
      method: "GET",
      params: data
    })
  },

  getEventDetail: (uuid) => {
    return http({
      url: "events/" + uuid,
      method: "GET",
    })
  },

  /**
   * @description: 触发所有有效数据的事件
   * @param {String} uuid 事件的uuid
   * @return {AxiosPromise}
   */
  eventsTriggerAll: (uuid) => {
    return http({
      url: "eventsTriggerAll/" + uuid,
      method: "POST",
    })
  },

  /**
   * @description: 触发单条数据的事件
   * @param {String} uuid 事件的uuid
   * @param {Integer} id 数据的id
   * @return {AxiosPromise}
   */
  eventsTriggerOne: (uuid, id) => {
    return http({
      url: "eventsTriggerOne/" + uuid + "/" + id,
      method: "POST",
    })
  },

  /**
   * @description: 触发所有有效数据的行为
   * @param {String} uuid 行为的uuid
   * @return {AxiosPromise}
   */
  behaviorTriggerAll: (uuid) => {
    return http({
      url: "behaviorTriggerAll/" + uuid,
      method: "POST",
    })
  },

  /**
   * @description: 触发单条数据的行为
   * @param {String} uuid 行为的uuid
   * @param {Integer} id 数据的id
   * @return {AxiosPromise}
   */
  behaviorTriggerOne: (uuid, id) => {
    return http({
      url: "behaviorTriggerOne/" + uuid + "/" + id,
      method: "POST",
    })
  },

  // type :1, eventTyps
  // object_uuid
  // name
  // behavior[] behavior select
  // rules : [] {operator:'select',children:{oprator:'',leftValue:{},ruleType:'filterType',rightValue:''}}
  addEvent: (obuuid, data) => {
    data.object_uuid = obuuid
    return http({
      url: 'events',
      method: 'POST',
      data: data
    })
  },

  editEvent: (uuid, data) => {
    return http({
      url: 'events/' + uuid,
      method: 'PUT',
      data: data
    })
  },

  deleteEvent: (uuid) => {
    return http({
      url: 'events/' + uuid,
      method: "DELETE"
    })
  },


  // ================ 行为 ======================
  getBehaviorList: (obuuid, size, page) => {
    let data = { object_uuid: obuuid, size: 500, page: 1 }
    if (size != undefined && page != undefined) {
      data.size = size;
      data.page = page
    }
    return http({
      url: 'behaviors',
      method: "GET",
      params: data
    })
  },
  addBehavior: (obuuid, data) => {
    data.object_uuid = obuuid
    return http({
      url: 'behaviors',
      method: 'POST',
      data: data
    })
  },

  editBehavior: (uuid, data) => {
    return http({
      url: 'behaviors/' + uuid,
      method: 'PUT',
      data: data
    })
  },

  deleteBehavior: (uuid) => {
    return http({
      url: 'behaviors/' + uuid,
      method: "DELETE"
    })
  },

  //行为类 - 永久缓存
  getBehaviorClasses: () => {
    return cacheHttp({
      url: 'behaviorClasses',
      method: "GET",
      time: 0
    })
  },
  // ================ 后续开发中 ===================
  globalFunction: {
    getAll: () => {
      return http({
        url: "globalFunc/globalFuncList",
        method: "get"
      })
    },

    config: (name, data) => {
      return http({
        url: 'globalFunc/configGlobalFunc/' + name,
        data: data,
        method: "POST"
      })
    },

    getConfig: (name) => {
      return http({
        url: 'globalFunc/getGlobalFuncConfig/' + name,
        method: "GET"
      })
    },

    getFunc: (name) => {
      return http({
        url: "globalFunc/getFuncClass/" + name,
        method: "GET"
      })
    }
  },

  metaDictMigrate: (target = null, data) => {
    if (!target) {
      return new Promise((resolve, reject) => reject('错误的目标服务器'))
    }

    return http({
      url: target.value.originURl + '/api/metadata/dictionaryMigrate',
      method: "POST",
      data: data,
      metadatatoken: target.value.metadatatoken
    })

  },

  formDesginMigrate: (target = null, module_uuid, data) => {
    if (!target) {
      return new Promise((resolve, reject) => reject('错误的目标服务器'))
    }

    return http({
      url: target.value.originURl + '/api/form4/design/' + module_uuid,
      method: "PUT",
      data: data,
      metadatatoken: target.value.metadatatoken
    })

  },

  // ================ 蓝图 ======================
  getBlueprintsList: (data, size, page) => {
    data.size = 15;
    data.page = 1;
    if (size != undefined && page != undefined) {
      data.size = size;
      data.page = page
    }
    return http({
      url: 'blueprints',
      method: "GET",
      params: data
    })
  },
  showBlueprint: (uuid) => {
    return http({
      url: 'blueprints/' + uuid,
      method: 'GET',
    })
  },
  addBlueprint: (data) => {
    return http({
      url: 'blueprints',
      method: 'POST',
      data: data
    })
  },

  editBlueprint: (uuid, data) => {
    return http({
      url: 'blueprints/' + uuid,
      method: 'PUT',
      data: data
    })
  },

  deleteBlueprint: (uuid) => {
    return http({
      url: 'blueprints/' + uuid,
      method: "DELETE"
    })
  },

  getBlueprintBindingsList: (owner_uuid, blueprint_uuid, size, page) => {
    let data = { size: 15, page: 1 }
    if (owner_uuid != undefined) {
      data.owner_uuid = owner_uuid
    }
    if (blueprint_uuid != undefined) {
      data.blueprint_uuid = blueprint_uuid
    }
    if (size != undefined && page != undefined) {
      data.size = size;
      data.page = page
    }
    return http({
      url: 'blueprintBindings',
      method: "GET",
      params: data
    })
  },


  // ================ 统一对外接口 ===================


  mapi: {
    common: (data, target) => {
      if (data.__increment__ === 1) {
        delete data.__increment__
        if (target) {
          return apiStore.incrementHttp({
            url: target.value.originURl + '/api/mapi',
            method: "POST",
            data: data,
            metadatatoken: target.value.metadatatoken
          })
        }
        return apiStore.incrementHttp({
          url: '/api/mapi',
          method: "POST",
          data: data
        })
      }
      if (target) {
        return http({
          url: target.value.originURl + '/api/mapi',
          method: "POST",
          data: data,
          metadatatoken: target.value.metadatatoken
        })
      }
      return http({
        url: '/api/mapi',
        method: "POST",
        data: data
      })
    },
    // 获取列表数据
    // ruuids = [
    //      {relationship_uuid:'',children:[field1,field2]}
    // ]
    // search = [
    //      {field_uuid:'fied1',ruleType:'one of filter type',value:'value'}
    // ]
    getList: (object_uuid, page = 1, size = 10, view_uuid = '', ruuids = [], search = []) => {
      return http({
        url: '/api/mapi',
        method: "POST",
        data: {
          object_uuid: object_uuid,
          view_uuid: view_uuid,
          ruuids: ruuids,
          search: search,
          page: page,
          size: size,
          __method_name__: "dataList"
        }
      })
    },

    getSimpleList: (object_uuid, view_uuid = '') => {
      return http({
        url: '/api/mapi',
        method: "POST",
        data: {
          object_uuid: object_uuid,
          view_uuid: view_uuid,
          __method_name__: "dataList",
          transcode: 0
        }
      })
    },

    getDetail: (object_uuid, data_id = 0, view_uuid = '', ruuids = []) => {
      return http({
        url: '/api/mapi',
        method: "POST",
        data: {
          object_uuid: object_uuid,
          data_id: data_id,
          view_uuid: view_uuid,
          ruuids: ruuids,
          __method_name__: "dataInfo"
        }
      })
    },

    // data => {field1:value1,field2:value2,ruuid:[{field1:value2}]}
    editOne: (object_uuid, data_id, data) => {
      data.object_uuid = object_uuid
      data.data_id = data_id
      data.__method_name__ = "updateData"
      return http({
        url: '/api/mapi',
        method: "POST",
        data: data
      })
    },

    addOne: (object_uuid, data) => {
      data.object_uuid = object_uuid
      // data.data_id = data_id
      data.__method_name__ = "createData"

      return http({
        url: '/api/mapi',
        method: "POST",
        data: data
      })
    },

    deleteOne: (object_uuid, data_id) => {
      return http({
        url: '/api/mapi',
        method: "POST",
        data: {
          object_uuid: object_uuid,
          data_id: data_id,
          __method_name__: "deleteData "
        }
      })
    },

    getMetadataDictionary: (field_uuids) => {
      return http({
        url: "/api/mapi",
        method: "POST",
        data: {
          field_uuid: field_uuids,
          __method_name__: 'metadataDictionary'
        }
      })
    },


    getAnalyseData: () => {

    }
  },

  apps: {
    baseUrl: () => {
      return '/api/metadata/objects/app/'
    },

    createApp: (data) => {
      return http({
        url: '/api/metadata/objects/app/createApp',
        data: data,
        method: 'POST'
      })
    },

    getApps: (category = '') => {
      return http({
        url: '/api/metadata/objects/app/getApps',
        params: { category: category },
        method: "GET"
      })
    },

    getAppInfo: (id) => {
      return http({
        url: '/api/metadata/objects/app/getAppInfo/' + id,
        method: "GET"
      })
    },

    getPages: () => {
      return http({
        url: '/api/metadata/objects/app/getPages',
        method: "GET"
      })
    },

    editApp: (id, data) => {
      return http({
        url: '/api/metadata/objects/app/editApp/' + id,
        method: "PUT",
        data: data
      })
    },

    deleteApp: (id) => {
      return http({
        url: '/api/metadata/objects/app/deleteApp/' + id,
        method: "delete",
      })
    }
  },

  /**
   * @description: 迁移前端菜单数据
   * @param {*} target 目标服务器
   * @param {*} data 迁移的数据
   * @return {*}
   */
  migrateMenuProject(target, data) {
    return http({
      url: target.value.originURl + '/api/metadata/objectData/menusSync',
      method: "POST",
      data: data,
      metadatatoken: target.value.metadatatoken
    })
  },


  /**
   * @description:  etl 调试
   * @param {Array} cells
   * @param {String} debug_cell
   * @param {Number} page
   * @param {Number} size
   */  
  etlDebug (cells,debug_cell,page=1,size=15){
    return http({
      url : "/api/etl/debug",
      method:"post",
      data:{
        cells,debug_cell,page,size
      }
    })
  },

  /**
   * @description: etl 列表
   * @param {Number} page
   * @param {Number} size
   * @param {String} name
   */  
  etlList(page=1,size=10,name){
    return http({
      url : "/api/etl",
      method:"get",
      params:{
        page,size,name
      }
    })
  },

  /**
   * @description: etl 详情
   * @param {String} id
   */  
  etlInfo(id){
    return http({
      url:"/api/etl/" + id,
      method:"get"
    })
  },

  /**
   * @description: ETL 新增
   * @param {String} name
   * @param {Array} cells
   */  
  etlAdd(name,cells){
    return http({
      url:"/api/etl",
      method:"post",
      data:{
        name,cells
      }
    })
  },

  /**
   * @description: ETL 编辑
   * @param {String} id
   * @param {String} name
   * @param {Array} cells
   */  
  etlEdit(id,name,cells){
    return http({
      url:"/api/etl/" + id,
      method:"put",
      data:{
        name,cells
      }
    })
  },

  /**
   * @description: ETL 删除
   * @param {String} id
   */  
  etlDelete(id){
    return http({
      url:"/api/etl/" + id,
      method:"delete"
    })
  }
}


export default objects
