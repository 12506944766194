<!--
 * @Description: 基础模型组件
 * @Author: luocheng
 * @Date: 2021-10-14 09:39:11
 * @LastEditors: Shiltin 18580045074@163.com
 * @LastEditTime: 2022-08-26 10:13:09
-->
<template>
  <div class="common-model">
    <div :id="element.id" ref="viewer" style="width: 100%; height: 100%"></div>
		<!-- 监控等图标 -->
		<div v-for="(val,key) in aiotSignData" :key="key">
			<div v-if="val.data.length">
				<div :class="[lockMouse?'pointer-none':'',item.showDetail?'top-level':'']"  class="aiot-sign" v-for="(item,index) in val.data" :key="index"  :style="getPosition(item,val.color,zoom)">
					<Player  v-if="item.showDetail && item.type === 'monitor'" ref="playerRef" @closeMonitor="item.showDetail = false" :playerType="item.param.playerType" :playerItem="item.param.playerItem" />
					<i @click.stop="clickSign(item)" class="iconfont"  :style="{color:val.color}" :class="val.icon"></i>
					<span class="trangle" :style="{borderTopColor:val.color}"></span>
				</div>
			</div>
		</div>
		<div class="mask" v-if="$route.path === '/modify-page' && showToggle"></div>
		<!-- 遮罩 -->
		<a href="javascript:;" class="mask-btn" v-if="$route.path === '/modify-page'" @click="onToggle">{{ showToggle ? '隐藏遮罩' : '显示遮罩' }}</a>
  </div>
</template>

<script>
import eventBus from '@/plugins/eventBus';
import { dataInterface } from '@/apis/data';
import Player from '@/custom-component/model/components/player.vue'
import { mapState } from 'vuex';
//  getComponentById, getQueryValue,
import { initParams } from '@/utils/tools';
let viewer = null
export default {
  name: 'CommonModel',
  props: {
    element: {
      type: Object,
      required: true,
      default: () => {}
    },
    // 是否在组合内
    isGroup: {
			type: Boolean,
		},
    // 组合内组件列表
		groupComponents: {
			type: Array,
			default: () => []
		},
    componentList: {
      default: null
    }
  },
	components: {Player},
  data() {
    return {
      // 是否初始化
      inited: false,
      // 尝试次数
      times: 0,
      // 定时器对象
      intervalObj: null,
			loadedModelIds: [],
			//选中的模型和构件
			initState:'',
			modelID:[],
			showToggle: true,
			//解决鼠标拖动的问题
			lockMouse:false,
			zoom:1,
			firstLevel:1,
			//iot标记数据
			aiotSignData:{
				monitor:{data:[],icon:'',color:''},
				towerCrane:{data:[],icon:'',color:''},
			}
    };
  },
  computed: {
    ...mapState(['componentData']),
    // 模型配置
    modelConfig() {
      return this.element && this.element.modelConfig;
    },
		getPosition: ()=>{
			return (data,col,zoom) =>{
				if (data != undefined && data.screenPointInfo != undefined) {
					return {
							left: data.screenPointInfo.x - (19*1) + "px",
							top: data.screenPointInfo.y - 50 + "px",
							transform: "scale(" + zoom + ")",
							position:'absolute',
							borderColor:col
					};
				}
			}

		}
  },
  mounted() {
    this.$nextTick(() => {
      this.initModel();
      eventBus.$on('doComponentBehavior', config => {
        const { component, list = [] } = config;
        if (component !== this.element.id) return false;
        list.forEach(ele => {
          const { behaviors, params } = ele;
					const { param = {}, canPost } = initParams(params, this.isGroup, this.componentList || this.componentData, this.groupComponents);
          if (canPost) {
            // 调用行为方法
            behaviors.forEach(funName => {
              try {
                eval(this[funName])(param)
              } catch (err) {
                console.log(err);
              }
            });
          }
        });
      })
    });
  },
  updated() {
    // this.$nextTick(() => {
    //   this.initModel();
    // });
  },
  methods: {
		/**
		 * @desc: 切换遮罩
		 */
		onToggle() {
			this.showToggle = !this.showToggle;
		},
    /**
     * @desc: 显示详情
     */
		clickSign(data){
			if(data.type === 'monitor'){
				if(data.showDetail){
					data.showDetail = false
				} else {
					console.log(data,'data')
					if(data.monitor_path){
						let playerType = data.info.app_info[0].app_name
						let playerItem = {
							monitor_path:data.monitor_path
						}
						let obj = {playerType:playerType,playerItem:playerItem}
						data.param = obj
						data.showDetail = true
					} else {
						this.$message.error('监控参数错误，请联系管理员！');
						return
					}
        }
			} else if(data.type === 'towerCrane'){
				if(data.showDetail){
					let bimInfo = data.info.derver_att.bim
					//关闭塔吊
					viewer.displayAngleCircle(`${bimInfo.type}_r`,false)
					viewer.displayText(`${bimInfo.type}_t`,false)
					data.showDetail = false
				} else {
					//创建塔吊
					if( data.info?.derver_att?.bim?.position){
						let bimInfo = data.info.derver_att.bim
						viewer.createAngleCircle(`${bimInfo.type}_r`, bimInfo.position, bimInfo.radius, 340);
						viewer.createText(`${bimInfo.type}_t`, `${340}°`, bimInfo.position)
						data.showDetail = true
					}else {
						this.$message.error('塔吊参数错误，请联系管理员！');
					}
				}
				
					
			}
		},
    // 测试结束
    /**
     * @desc: 初始化模型
     */
    initModel() {
      if (!this.validConfig()) {
        // 配置错误的情况直接放弃初始化
        this.inited = true; 
        return;
      }
      /* eslint-disable */
      const domId = this.element.id;
      if (!domId || !document.querySelector(`#${domId}`) || !window.BIMCC || !window.BIMCC.Viewer) {
        this.times++;
        // return;
      }
      // 应用ID "bdafce12a2d9465d89821ec14214a382"
      // 应用签名 "3EYcdAjbnWxP"
			// this.modelID = [{model_id:'4d0ed584ef65434786786419005def5d',dbids:[]},{model_id:'d1a2579f34b042738f485aa327be9e8d',dbids:[]}]
      const modelID = this.modelConfig.modelId.type === "routeQuery"? this.$route.query[this.modelConfig.modelId.value]: this.modelConfig.modelId.value; //模型
			if(Object.prototype.toString.call(modelID) === '[object String]' && modelID.includes('model_id')){
				this.modelID = this.strToJson(modelID)
			}
      viewer = new window.BIMCC.Viewer(domId,{});
			window.viewer = viewer
			if(this.modelID.length){
				this.addModels(this.modelID)
			}
			//监听模型加载
			viewer.on("Viewer.loadedModel", async () => {
					let data = await viewer.getModelIdList();
					this.loadedModelIds = data;
					this.isLoadModel = false
					this.$emit("changeModelIds", data);
					// viewer.setBackgroundColor(19, 206, 102)
			});
			//监听模型卸载
			viewer.on("Viewer.unloadModel", async () => {
					let data = await viewer.getModelIdList();
					this.$emit("changeModelIds", data);
			});
			//选中构件构件
			viewer.on("Viewer.onSelect", async (selections) => {
					this.$emit("changeCompanents", selections);
			});
			 // 鼠标摁下
			viewer.on("Viewer.MouseDown", async () => {
					this.lockMouse = true
			});
			// 鼠标松开
			viewer.on("Viewer.MouseUp", async () => {
					this.lockMouse = false
			});
			//监听相机旋转
      viewer.on("Viewer.cameraChange",(res) => {
				//zoom值计算
				let tier=res.position;
				let dis = tier.x*tier.x + tier.y*tier.y + tier.z*tier.z;
				if(this.firstLevel==1 && dis){
						this.firstLevel=dis
				}else{
						let a=this.firstLevel/dis
						if(a<1){
								this.zoom=a.toFixed(1)
						}else{
								this.zoom=1
						}
				}
				for(let i in this.aiotSignData){
					let item =this.aiotSignData[i].data
					if(item.length){
						item.map(async(val)=>{
							val.screenPointInfo = await viewer.worldToScreen(val.worldPointInfo);
						})
					}
				}

			})
      this.inited = true;
    },
		/**
     * @desc: 字符串转json
     * @return {}
     */
		strToJson (str) {
			let json = (new Function("return " + str))()
			return json
		},
    /**
     * @desc: 验证配置
     * @return {Boolean}
     */
    validConfig() {
      return (
        this.modelConfig &&
        // this.modelConfig.appId &&
        // this.modelConfig.appSecret &&
        this.modelConfig.modelId &&
        // this.modelConfig.modelId.type &&
        this.modelConfig.modelId.value
      );
    },
    /**
     * @desc: 模型操作方法
     */
		// data参数均为[{model_id:xxx,dbids:[]}] 格式
		//获取构件属性
		addModels (arr)  {
			if (arr && arr.length != 0) {
				if (this.loadedModelIds.length == 0) {
					let bol = false;
					arr.forEach((item) => {
						if (!bol) {
							// 加载第一个模型
							bol = true;
							viewer.on("viewerLoaded", async () => {
									if (item.dbids && item.dbids.length != 0) {
										viewer.fitToView([item]);
										viewer.select([item]);
									}
							});
							this.initFirstModel(item.model_id,arr.length==0);
						} else {
							let timer = setInterval(() => {
								if (this.initState === "done") {
									clearInterval(timer);
										viewer.on("Viewer.loadedModel", () => {
												if (item.dbids && item.dbids.length != 0) {
													viewer.fitToView([item]);
													viewer.select([item]);
												}
										});
									this.isLoadModel = true
									viewer.loadModel(item.model_id);
								}
							}, 200);
						}
					});
				} else {
					arr.forEach((item) => {
						viewer.on("Viewer.loadedModel", () => {
							if (item.dbids && item.dbids.length != 0) {
								viewer.fitToView([item]);
								viewer.select([item]);
							}
						});
						this.isLoadModel = true
						viewer.loadModel(item.model_id);
					});
				}
			}
		},
		//初始化加载第一个模型
		initFirstModel (id,bol)  {
				this.isLoadModel = true
				if (viewer) {
						viewer.clearUI();
						let param = {
								appId:this.modelConfig.appId?this.modelConfig.appId:'bdafce12a2d9465d89821ec14214a382',
								appSecret:this.modelConfig.appSecret?this.modelConfig.appSecret:'3EYcdAjbnWxP',
								modelList:this.modelID,
								themeColor: this.modelConfig.toolbarBg?this.modelConfig?.toolbarBg:'#FFFFFF',
								activeColor: '#0076ff',
								textColor: this.modelConfig.toolbarCol?this.modelConfig?.toolbarCol:'#121619',
								removeBackground:!this.modelConfig.showModelBg?true:false
						}
						if(+this.modelConfig.showToolbar === 0){
							param.UIType = 'UI.None'
						}
						if(+this.modelConfig.showToolbar === 1){
							param.UIType = 'UI.Base'
						}
						console.log(param,'param')
						viewer.init(id,param);
						//加载模型监听
						viewer.on("viewerLoaded", async () => {
								//模型背景色
								if(this.modelConfig?.modelBg && this.modelConfig.showModelBg){
									viewer.setBackgroundColor(this.modelConfig.modelBg)
								}
								this.initState = "done";
								this.isFirstLoad = false
								if(bol){
									this.isLoadModel = false
								}
								let data = await viewer.getModelIdList();
								this.loadedModelIds = data;
								this.$emit("changeModelIds", data);
						});
				}
		},
		// /获取构件属性
		async getProperties (dbid,modelId) {
			if (viewer != null ) {
				let data =  await viewer.getProperties(dbid,modelId)
				data.model_id = modelId
				return data
			}
		},
		//AIOT指挥工地显示
		changeAiot (data) {
			console.log('222',data)
			let obj = data?.key || {}
			if(obj){
				this.aiotSignData[obj.type].icon = obj.icon
				this.aiotSignData[obj.type].color = obj.color
				if(this.aiotSignData.towerCrane.data.length && !obj.show){
					this.aiotSignData.towerCrane.data.forEach((item)=>{
						console.log(item,'item')
							if(item.info?.derver_att?.bim){
								let bimInfo = item.info.derver_att.bim
								//关闭塔吊
								viewer.displayAngleCircle(`${bimInfo.type}_r`,false)
								viewer.displayText(`${bimInfo.type}_t`,false)
							}
					})

				}
				this.aiotSignData[obj.type].data = []
				if(obj.show){
					const params = {
						__method_name__: 'dataList',
						object_uuid: obj.object_uuid,
						view_uuid: obj.view_uuid,
						page:1,
						size:1000,
						transcode: 0
					}
					dataInterface(params).then((res) => {
						if(res.data.code === 200 && res.data.data.data.length){
							res.data.data.data.map( async(item) => {
								let modelRelate = null
								if(item.model_relate && item.model_relate.length){
									modelRelate = item.model_relate 
								} else if(item.derver_att?.bim?.model) {//塔吊设备
									modelRelate = [item.derver_att.bim.model] || ''
								}
								if(modelRelate && modelRelate!=null){
									let val = {info:item,screenPointInfo:null,worldPointInfo:null,showDetail:false,type:obj.type,param:null}
									if (viewer != null && viewer.getBoundingSphere) {
											//世界座标
											let worldPointInfo = await viewer.getBoundingSphere(modelRelate[0].dbids[0]);
											let screenPointInfo = null;
											if (worldPointInfo && worldPointInfo.center) {
													//屏幕座标
													screenPointInfo = await viewer.worldToScreen(worldPointInfo.center);
											}
											val.screenPointInfo = screenPointInfo
											val.worldPointInfo = worldPointInfo.center
									}
									this.$nextTick(()=>{
										this.aiotSignData[obj.type].data.push (val) 
									})
								}
							})
						}
					})
				}
			}

		},
		//卸载模型
		minusModel (id)  {
			if (viewer != null ) {
				viewer.unloadModel(id);
			}
		},
		//添加模型
		addModel (id)  {
			if (viewer != null ) {
				viewer.loadModel(id);
			}
		},
		//选中构件
		selectModel  (data)  {
			if (viewer != null ) {
				viewer.select(data);
			}
		},
		//缩放到构件位置
		fitToModel  (data)  {
			if (viewer != null ) {
				viewer.fitToView(data);
			}
		},
		//隔离方法
		isolate  (data)  {
			if (viewer != null ) {
				viewer.isolate(data);
			}
		},
		//隔离全部
		hideAll  ()  {
			if (this.loadedModelIds.length !== 0) {
				this.loadedModelIds.forEach((item) => {
						viewer.setOptions("ghosting", true);
						viewer.hideModel(item);
				});
			}
		},
		//显示全部
		showAll  ()  {
			if (this.loadedModelIds.length !== 0) {
				this.loadedModelIds.forEach((item) => {
						viewer.setOptions("ghosting", false);
						viewer.showAll(item);
						viewer.clearColorAll(item);
				});
			}
		},
		//获取视点列表
		async getViewList () {
			if (viewer != null ) {
				let data = await viewer.getViewStateList();
				return data;
			}
		},
		//添加视点
		async addView ()  { 
			if (viewer != null ) {
				const res = await viewer.addViewState()
				if (res) {
					this.$emit('setViewData', res)
				}
			}
		},
		//更新视点
		async updateView (data)  {
			if (viewer != null ) {
				await viewer.updateViewState({
						id: data.id,
						name: data.name,
				});
				return true;
			}
		},
		//视点回显
		setViewState  (data)  {
			if (viewer != null ) {
					viewer.viewViewState(data);
			}
		},
		//设置主题色
		setTheme  (data)  {
			if (viewer != null ) {
					viewer.setTheme(data);
			}
		},
		//构件着色
		setCol  (data, col) {
			if (viewer != null ) {
				data.forEach((val) => {
						viewer.setColor(val.dbids, col, val.model_id);
				});
			}
		},
		//进度模拟生长效果相关的方法
		// obj参数：{opacity:0.5,color:#999999,model:模型id,speed:100}
		async createShadowMesh  (dbids,obj){
			if (viewer != null ) {
				let growId = await viewer.createShadowMesh(dbids,obj);
				return growId;
			}
		},
		// 播放生长进度
		playShadowMeshGrow  (id) {
			if (viewer != null ) {
				viewer.playShadowMeshGrow(id)
			}
		},
		// 暂停生长进度
		stopShadowMeshGrow  (id) {
			if (viewer != null ) {
				viewer.stopShadowMeshGrow(id)
			}
		},
		//隐藏结束生长进度
		displayShadowMesh  (id, bol) {
			if (viewer != null ) {
				viewer.displayShadowMesh(id, bol)
			}
		}
		//模型操作方法

  },
  beforeDestroy() {
    if (this.intervalObj) {
      clearInterval(this.intervalObj);
    }
    eventBus.$off('doComponentBehavior');
  }
};
</script>

<style lang="less" scoped>
.common-model {
  height: 100%;
  width: 100%;
  overflow: hidden;
	position: relative;
	.mask-btn{
		position: absolute;
		padding: 5px;
		font-size: 14px;
		color: @theme;
		right: 0;
		top: 0;
		cursor: pointer;
	}
	.mask{
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background: transparent;
		line-height: 100%;
		color: #fff;
	}
	.top-level{
		z-index: 20!important;
	}
	.aiot-sign{
		background: #fff;
		border-width: 4px;
		border-style: solid;
		border-radius: 50%;
		z-index: 10;
		i{
			font-size: 30px;
			z-index: 10;
		}
		.trangle{
			width: 0px;
			height: 0px;
			border-width: 12px;
			border-style: solid;
			border-bottom-color: transparent;
			border-left-color: transparent;
			border-right-color: transparent;
			position: absolute;
			bottom: -24px;
			left: 3px;
			z-index: 10;
		}
	}
	.player-cont{
			position: absolute;
			width: 250px;
			height: 150px;
			top: -165px;
    	left: -110px;
			z-index: 20;
			.close{
				top: 2px;
				right: 5px;
				font-size: 22px;
				color:#fff;
			}
			.full-screen{
				font-size: 15px;
				top: 5px;
				right:30px;
				color:#fff;
			}
	}
	.pointer-none{
		pointer-events: none;
	}
}
</style>