<!--
 * @Description: 弹窗组件
 * @Author: luocheng
 * @Date: 2021-09-03 09:48:55
 * @LastEditors: luocheng
 * @LastEditTime: 2022-05-30 10:25:50
-->
<template>
	<div class="common-model" v-if="element && element.statusConfig.isShow" :style="boxStyle"
    :data-id="element && element.id"
  >
		<div class="common-model-content" :style="getComStyle(element)">
			<ComponentBox
				v-for="child in element.children"
				:key="child.id"
				@click.native.stop="onClickBox(child)"
				:element="child"
			>
			</ComponentBox>
		</div>
	</div>
</template>

<script>
import { getStyle } from '@/utils/style';
import { mapState } from 'vuex';

export default {
	name: 'CommonModel',
	components: {},
	props: {
		// 组件对象
		element: {
			type: Object,
			required: true,
			default: () => {}
		}
	},
	data() {
		return {
      boxStyle: {}
    };
	},
	computed: {
		...mapState([
			'canvasStyle' // 样式
		]),
		// 外层的样式
		boxStyles() {
			let height = 0,
				width = 0;
			if (isNaN(this.canvasStyle.width) || !this.canvasStyle.scale) {
				width = this.canvasStyle.width;
			} else {
				width = (this.canvasStyle.width * parseInt(this.canvasStyle.scale)) / 100;
			}
			if (isNaN(this.canvasStyle.height) || !this.canvasStyle.scale) {
				height = this.canvasStyle.height;
			} else {
				height = (this.canvasStyle.height * parseInt(this.canvasStyle.scale)) / 100;
			}
			return {
				height: height + 'px',
				width: width + 'px',
				top: ((window.innerHeight - height) / 2 - 16)+ 'px',
				left: ((window.innerWidth - width) / 2 - 43) + 'px'
			};
		}
	},
  watch: {
    canvasStyle: {
      handler() {
        this.getBoxStyle();
      },
      deep: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.getBoxStyle();
    })
    window.onresize = () => {
      this.getBoxStyle();
    }
  },
	methods: {
		/**
		 * @desc: 组件样式
		 * @param {Object} element 组件对象
		 */
		getComStyle(element) {
			const { style } = element;
			// 基础样式
			if (!style) return {};
			let exculdesArr = [
				'top',
				'left',
				'rotate',
				'paddingLeft',
				'paddingRight',
				'paddingTop',
				'paddingBottom',
				'marginTop',
				'marginBottom',
				'marginLeft',
				'marginRight'
			];
			let result = getStyle(style, exculdesArr);
			if (this.element.span !== undefined && +this.element.span !== -1) {
				result.width = '100%';
			}
			if (element.specialStyle.flexDirection) {
				// 轴方向
				result.flexDirection = element.specialStyle.flexDirection;
        result.flexWrap = element.specialStyle.flexWrap ? 'wrap' : 'nowrap';
			}
			// 特殊样式
			// console.log(specialStyle, 'specialStylespecialStyle');
			// if (specialStyle.isCollector) {
			//   // 收集者
			//   result.flex = 1;
			// }
			return result;
		},
		/**
		 * @desc: 触发点击事件
		 * @param {Object} item 点击的组件
		 *  触发方式实质就是在改变指定的组件状态
		 */
		onClickBox(element) {
			if (!element.events || !element.events.click || !Array.isArray(element.events.click.effects)) return;
			element.events.click.effects.forEach(config => {
				this.$store.commit('triggerEvents', {
					config,
					element
				});
			});
		},
    // 计算尺寸
    getBoxStyle() {
      let height = 0,
				width = 0;
			if (isNaN(this.canvasStyle.width) || !this.canvasStyle.scale) {
				width = this.canvasStyle.width;
			} else {
				width = (this.canvasStyle.width * parseInt(this.canvasStyle.scale)) / 100;
			}
			if (isNaN(this.canvasStyle.height) || !this.canvasStyle.scale) {
				height = this.canvasStyle.height;
			} else {
				height = (this.canvasStyle.height * parseInt(this.canvasStyle.scale)) / 100;
			}
			this.boxStyle = {
				height: height + 'px',
				width: width + 'px',
				top: ((window.innerHeight - height) / 2 - 16)+ 'px',
				left: ((window.innerWidth - width) / 2 - 43) + 'px'
			};
    }
	}
};
</script>

<style lang="less" scoped>
.common-model {
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	z-index: 10;
	background: rgba(0, 0, 0, 0.4);
	.common-model-content {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background: #fff;
		border-radius: 4px;
		box-sizing: border-box;
		padding: 15px;
		.header {
			width: 100%;
			height: 40px;
			display: flex;
			box-sizing: border-box;
			padding: 10px;
			.title {
				flex: 1;
				color: #333;
				line-height: 20px;
				font-size: 16px;
				text-align: center;
			}
			.close-btn {
				font-size: 20px;
				color: @borderColor;
				&:hover {
					color: @theme;
				}
			}
		}
	}
}
</style>
