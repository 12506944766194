/*
 * @Description: 大屏容器相关
 * @Author: luocheng
 * @Date: 2022-05-16 15:24:46
 * @LastEditors: luocheng
 * @LastEditTime: 2022-05-17 09:39:00
 */
import store from './index';
import { deepCopy } from '@/utils/tools';

export default {
  state: {
    // 
		// 全屏容器ID 存在即为编辑大屏容器状态
		sContainer: null,
		// 原始组件列表
		originComponentData: []
  },
  mutations: {
    /**
     * @desc: 进入大屏容器状态
     * @param {Object} sContainer 大屏容器
     */
    toScreenContainer(state, sContainer) {
      if (!sContainer) return false;
      state.sContainer = sContainer;
      state.originComponentData = deepCopy(state.componentData);
      // 暂时清除全局的页面组件状态
      store.commit('setComponentsBatch', sContainer.children || []);
      store.commit('setCurComponent', {
        component: null
      });
    },
    /**
     * @desc: 退出大屏编辑状态
     */
    /**
     * @desc: 退出大屏编辑状态
     * @param {Object} positionInfo 位置信息
     * @return {*}
     */
    quietScreenContainer(state, positionInfo) {
      const children = deepCopy(state.componentData);
      const result = state.originComponentData.map(ele => {
        if (ele.id === state.sContainer.id) {
          return {
            ...ele,
            children,
            childPositionInfo: positionInfo
          }
        }
        return {
          ...ele
        }
      });
      store.commit('setComponentsBatch', result || []);
      store.commit('setCurComponent', {
        component: null
      });
      state.sContainer = null;
    }
  }
}