<template>
  <section class="views-box">
    <header class="theme">
      <h4>{{ title }}</h4>
    </header>
    <div class="views-echarts" id="viewsRing"></div>
  </section>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "ViewsDoubleRing",
  components: {},
  props: {
    // 统计数据
    statisticsList: {
      type: Object,
      required: true,
      default: () => {},
    },
    // 模块标题
    title: {
      type: String,
      required: true,
      default: "",
    },
  },
  data() {
    return {
      newData: {},
    };
  },
  watch: {
    statisticsList(newVal) {
      this.newData = newVal;
      this.drawRings();
    },
  },
  created() {},
  mounted() {},
  methods: {
    // 视图
    drawRings() {
      this.chartDom = document.getElementById("viewsRing");
      this.myChartRings = echarts.init(this.chartDom);
      let pieChartData = [
        {
          name: "非自动生成视图",
          value: this.statisticsList.notAutoView??0,
        },
        {
          name: "有权限视图",
          value: this.statisticsList.hasPermission??0,
        },
      ];
      this.option = {
        title: [
          {
            text: "所有视图",
            subtext: this.newData.allViews??0,
            top: "56%",
            left: "center",
            textStyle: {
              fontFamily: "Source Han Sans CN",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: 14,
              lineHeight: 24,
              color: "#000000",
            },
            subtextStyle: {
              fontFamily: "Source Han Sans CN",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: 20,
              lineHeight: 15,
              color: "#000000",
            },
          },
        ],
        visualMap: {
          show: false,
        },
        legend: [
          {
            itemWidth: 14,
            itemHeight: 14,
            itemGap: 65,
            selectedMode: true,
            top: "10%",
            left: "center",
            formatter: (name) => {
              let data = pieChartData;
              let target;
              for (let i = 0, l = data.length; i < l; i++) {
                if (data[i].name == name) {
                  target = data[i].value;
                }
              }
              let arr = ["{a|" + name + "}", "{b|" + target + "}"];
              return arr.join("\n");
            },
            textStyle: {
              rich: {
                a: {
                  fontSize: 14,
                  lineHeight: 24,
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontFamily: "Source Han Sans CN",
                  color: "#73767A",
                  padding: [25, 0, 0, 8],
                },
                b: {
                  color: "#000000",
                  fontFamily: "Source Han Sans CN",
                  fontStyle: "normal",
                  fontWeight: 700,
                  fontSize: 18,
                  lineHeight: 24,
                  padding: [28, 0, 0, 8],
                },
              },
            },
            data: ["非自动生成视图", "有权限视图"],
          },
        ],
        series: [
          {
            type: "pie",
            radius: ["63%", "50%"],
            center: ["50%", "69.5%"],
            hoverAnimation: false,
            data: [
              {
                name: "非自动生成视图",
                value: this.statisticsList.notAutoView??0,
                itemStyle: { color: "#409EFF" },
              },
              {
                name: "有权限视图",
                value: this.statisticsList.hasPermission??0,
                itemStyle: { color: "#95D475" },
              },
            ],
            label: {
              show: false,
            },
            itemStyle: {
              borderWidth: 7,
              borderColor: "#fff",
            },
            animationType: "scale",
            animationEasing: "elasticOut",
          },
        ],
      };
      this.option && this.myChartRings.setOption(this.option);
    },
  },
};
</script>

<style lang="less" scoped>
.views-box {
  position: relative;
  width: 18.5%;
  min-width: 325px;
  height: 100%;
  padding: 16px 0 35px 20px;
  box-sizing: border-box;
  background: #fff;
  .theme {
    position: absolute;
    top: 20px;
    left: 5px;
    color: rgb(68, 151, 254);
    font-size: 17px;
    font-weight: bold;
    line-height: 22px;
    text-align: left;
    h4 {
      padding-left: 10px;
      box-sizing: border-box;
    }
  }
  .views-echarts {
    width: 100%;
    height: 100%;
  }
}
</style>
