<!--
    @name: widget-number
    @description：widget-number
    @author: ZengWei
    @date: 2022-03-25 09:26
-->
<template>
  <div>
    <el-input-number
      v-if="!(disabled || element.config.disabled)"
      v-model="element.value"
      :disabled="disabled || element.config.disabled"
    ></el-input-number>
    <p class="reshow-text" v-else>{{ element.value || '0' }}</p>
  </div>
</template>

<script>
import formItemMinix from "@/custom-component/form/newParser/scripts/formItemMinix";
import {InputNumber} from "element-ui";

export default {
  name: "WidgetNumber",
  components: {
    'el-input-number': InputNumber
  },
  mixins: [formItemMinix],
  props: ['element', 'disabled', 'displayData'],
  data() {
    return {}
  },
  methods: {},
}
</script>

<style scoped>

</style>
