<!--
    @name: widget-upload
    @description：widget-upload
    @author: ZengWei
    @date: 2022-03-25 09:38
-->
<template>
  <uploader
    :disabled="disabled"
    :files="['default','schedule'].includes(goalType)?element.value : filesData"
    :fileTypeList="fileTypeList"
    @extra-files="onSuccess"
		:goalType="goalType"
  >
  </uploader>
</template>

<script>
import axios from "axios";
import uploader from "@/components/fileUpload/fileUpload/Index";
import { baseUrl } from '@/apis/http/request';
import { bytesToSize, parseTime } from '@/utils/index.js'
import { dataInterface } from '@/apis/data/index';
import { getToken } from '@/utils/tools';
import formItemMinix from "@/custom-component/form/newParser/scripts/formItemMinix";

export default {
  name: "WidgetUpload",
  components: {uploader},
  mixins: [formItemMinix],
  props: ['element', 'disabled', 'displayData','formData'],
  data() {
    return {
			token: getToken(),
			filesData:[],
			userInfo: null,
		}
  },
  computed:{
    fileTypeList(){
      const type = this.element.config.accept.split(',')
      const typeArr = type.filter(item => {return item && item.trim()})
      return typeArr
    },
		//上传模式：
		goalType(){
			return this.element.config.__config__.goalType || 'default'
		}
  },
  methods: {
    onSuccess(files) {
			if(this.goalType === 'bim' || this.goalType === 'gis'){
				if(files.name && files.raw){
					this.uploadModel(files);
				}
			}else{
        this.element.value = files;
      }
    },
		//获取请求模型参数token
		getModelToken(){
			return new Promise(resolve => {
				axios.post('https://models.bimcc.net/api/v3/auth/token', { appId: process.env.VUE_APP_MODEL_APPID, appSecret: process.env.VUE_APP_MODEL_APPSECRET }).then(res => {
					if (res.status === 200 && res.data?.data?.token) {
						resolve(res.data.data.token)
					}else {
						resolve('')
					}
				})
			})
			
		},
		//上传bim或gis模型
		async uploadModel (files){
			this.$loading({
				text: '上传中....'
			});
			let nameData = files.name.split('.')
			let requestUrl = ''
			let timestamp = Date.parse(new Date()) / 1000
			let formData = new window.FormData()
			formData.append('file', files.raw)
			if(files.name.includes('.svfzip') || files.name.includes('.3dtilezip')){
				requestUrl = 'https://models.bimcc.net/api/v3/upload/file'
				formData.append('name', nameData[0] + timestamp)
				let modelToken = await this.getModelToken()
				if(modelToken){
					axios({
						method: 'post',
						url: requestUrl ,
						headers: {
							'Content-Type': 'multipart/form-data',
							authorization: `Bearer ${modelToken}`
						}, // 请求头参数
						data: formData
					}).then((res) => {
						if(res.status === 200 && res.data.data){
							console.log(res.data.data)
							//回显上传文件
							this.filesData = [{filename:files.name,path:res.data.data.path,upTime: parseTime(new Date()),upUser:this.userInfo.name}]
							let obj = {model_id:'', modelSize:bytesToSize(res.data.data.size), img_path:'',used_version:''}
							if(res.data.data.info?.modelId){
								obj.model_id = res.data.data.info.modelId
							}
							if(res.data.data.info?.option?.picture){
								obj.img_path = 'https://models.bimcc.net/' + res.data.data.info.option.picture
							}
							if(this.goalType === 'bim'){
								this.$set(obj,'light_model_id','')
								//bim创建版本
								this.creatModelVersion(obj,nameData[0])
							} else {
								//gis目前暂时无版本
								this.element.value = obj
								this.$loading().close();
							}
							
						} else {
							this.$loading().close();
						}
					}).catch(err => {
						this.$loading().close();
						console.log(err, '-----');
					});
				}
			} else if(files.name.includes('.rvt')){
				requestUrl = baseUrl + 'api/mapi?__method_name__=file&token=' + this.token
				axios({
					method: 'post',
					url: requestUrl ,
					headers: {
						'Content-Type': 'multipart/form-data',
					}, // 请求头参数
					data: formData
				}).then((res) => {
					if(res.status === 200 && res.data.data){
						//回显上传文件
						let resetData = JSON.parse(JSON.stringify(res.data.data))
						resetData.upTime = parseTime(new Date())
						resetData.upUser = res.data.data.user_name
						this.filesData = [resetData]
						let obj = {light_model_id:res.data.data.id,model_id:res.data.data.model_id,model_file_id:res.data.data.model_file_id, modelSize:bytesToSize(res.data.data.filesize), img_path:'',used_version:''}
						this.creatModelVersion(obj,nameData[0])
					} else {
						this.$loading().close();
					}
				})
			}else{
				console.log('该格式文件上传开发中....')
				this.$loading().close();
				return false
			}
			
		},
		//创建bim模型版本
		creatModelVersion (data,name) {
			let version_code = name+'/'+parseTime(new Date(),'{y}-{m}-{d}')
			dataInterface({
				model_id:data.model_id,
				object_uuid:'object61766c46480b6',
				version_code:version_code,
				__method_name__: "createData"
			}).then((res) => {
				if(res.data.code === 200){
					if(res.data.data?.id){
						data.used_version = res.data.data.id
					}
					console.log(data,'value')
					this.element.value = data
					this.$loading().close();
				}
			}).catch(err => {
				this.$loading().close();
				console.log(err, '-----');
			});
		},

  },
	created() {
		this.userInfo = this.$GetUserInfo();
	}
}
</script>

<style scoped>

</style>
