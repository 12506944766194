<!--
 * @Author: zx
 * @Date: 2022-09-01 15:30:46
 * @LastEditTime: 2023-01-10 10:17:29
 * @Description:
 * @LastEditors: 朱廷果 1028509503@qq.com
-->
<template>
  <div class="application-tree" :style="'width:' + width + 'px'">
    <div v-if="+systemStyle === 2" class="application-tree-container">
      <div class="left-item-title">
        <el-input class="item-title-input" v-model="searchValue" placeholder="请输入名称" clearable @input="searchValueChange"></el-input>
      </div>
      <LeftItem
        ref="leftItem"
        v-if="!appListShow"
        v-loading="loading"
        delAble
        topTitle="我的应用"
        :defaultProps="defaultProps"
        :historyAppInfo="historyAppInfo"
        :historyChildAppInfo="historyChildAppInfo"
        :tableData="listData"
        field="name"
        @row-del="rowDel"
        @row-click="rowClick"
        @top-add="add"
        @row-edit="rowEdit"
      />
      <LeftItem
        ref="childLeftItem"
        v-if="appListShow"
        v-loading="loading"
        delAble
        :topTitle="childTitle"
        :showBack="true"
        :tableData="appInfo.children"
        :historyChildAppInfo="historyChildAppInfo"
        :defaultProps="{
          label: 'name',
          children: 'children'
        }"
        field="name"
        @row-del="rowDel"
        @row-click="childRowClick"
        @top-add="childAdd"
        @row-edit="childRowEdit"
        @row-add="childRowAdd"
        @back="back"
      />
    </div>
    <div v-if="+systemStyle === 1" class="application-tree-container">
      <LeftItemStyle
        ref="LeftItemStyle"
        :appListShow="appListShow"
        :showTemplatePage="showTemplatePage"
        :inTemplatechild="inTemplatechild"
        :loading="loading"
        :listData="listData"
        :tableData="appInfo.children"
        :historyAppInfo="historyAppInfo"
        :historyChildAppInfo="historyChildAppInfo"
        :showHistoryVisible="showHistoryVisible"
        @f-row-del="rowDel"
        @f-row-click="rowClick"
        @f-top-add="add"
        @f-row-edit="rowEdit"
        @row-click="childRowClick"
        @top-add="childRowAdd"
        @row-edit="childRowEdit"
        @row-add="childRowAdd"
        @back="back"
      />
    </div>
    <el-drawer
      :visible.sync="showAddDrawer"
      :append-to-body="true"
      :direction="'rtl'"
      size="640px"
    >
      <div slot="title" class="add-content-title">
        {{applicationTitle}}
      </div>
      <AddContent :childInfo="childInfo" @closeAddDrawer="closeAddDrawer" @updateMenuList="updateMenuList"></AddContent>
    </el-drawer>
    <!-- 编辑应用弹窗 -->
    <el-dialog
      :title="editTitle"
      :fullscreen="dialogFull"
      :visible.sync="dialogVisible"
      width="600px"
      append-to-body
      custom-class="create-app-dialog"
    >
      <div v-if="dialogVisible" class="common-body">
        <el-form
          v-if="dialogVisible"
          ref="form"
          :model="appFormData"
          :rules="rules"
          label-position="top"
          label-width="80px"
        >
          <div class="wrapper">
            <el-form-item
              v-if="+appFormData.menu_type === 1"
              class="form-item-height"
              label="应用图标"
              prop="icon"
            >
              <el-select :value="other" style="width: 344px;" placeholder="" class="select-icon">
                <template slot="prefix">
                  <div
                    class="header-img"
                    :style="'background: ' + appFormData.background">
                    <svg aria-hidden="true"
                      class="color-icon"> 
                      <use :xlink:href="appFormData.icon.indexOf('#')===-1? '#' + appFormData.icon : appFormData.icon" />
                    </svg>
                  </div>
                </template>
                <template #empty>
                  <div class="choose-color" style="width: 344px; padding: 8px;height: 384px">
                    <p>选择颜色</p>
                    <div class="color-container">
                      <div
                        class="color-item"
                        :style="'background:' + item.background"
                        v-for="(item, index) in colorList"
                        style="width: 32px;height: 32px"
                        :key="index"
                        @click="
                          (appFormData.color = item.color),
                            (appFormData.background = item.background)
                        "
                      >
                        <i
                          v-if="appFormData.background === item.background"
                          class="iconfont iconselect-duigou"
                        ></i>
                      </div>
                    </div>
                    <p>选择图形</p>
                    <div
                      class="color-container"
                      style="flex: 1; overflow-y: auto"
                    >
                      <div
                        v-for="(icon, index) in appliIcons"
                        :key="index"
                        :class="(appFormData.icon.includes('#')? appFormData.icon: '#'+appFormData.icon) === icon.font_class? 'color-item-app-border' : ''"
                        class="color-item-app"
                        @click="appFormData.icon = icon.font_class"
                      >
                      <div  class="color-item color-item-app-div" :style="'background:' + appFormData.background">
                        <svg aria-hidden="true"
                          class="color-icon">
                          <use :xlink:href="icon.font_class" />
                        </svg>
                      </div>

                      </div>
                    </div>
                  </div>
                </template>
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="+appFormData.menu_type !== 1 && +appFormData.menu_type !== 6"
              class="form-item-height"
              label="应用图标"
              prop="icon"
            >
              <el-select :value="other" style="width: 344px" placeholder="" class="select-icon">
                <template slot="prefix">
                  <div
                    class="header-img" style="border: 2px solid #D0E2FF;border-radius: 4px">
                    <i
                      :style="'color: ' + appFormData.color"
                      :class="
                        appFormData.icon 
                          ? 'iconfont ' + appFormData.icon
                          : 'iconfont icon-a-1-1zhuye'
                      "
                    ></i>
                  </div>
                </template>
                <template #empty>
                  <div class="choose-color" style="width: 344px; padding: 8px;height: 384px">
                    <p>选择类型</p>
                    <div class="color-container">
                      <div
                        class="color-item"
                        style="background: #F2F4F7;border-radius: 4px;width: 40px;height: 32px;box-sizing:border-box"
                        :style="currentChooseType.font_class === item.font_class ? 'border: 2px solid #D0E2FF;background: #fff' : ''"
                        v-for="(item, index) in iconList"
                        :key="index"
                        @click="currentChooseType = item"
                      >
                        <i
                          :style="'color: ' + item.color"
                          class="iconfont"
                          style="color: #2A2F3D"
                          :class="item.font_class"
                        ></i>
                      </div>
                    </div>
                    <p>选择图形</p>
                    <div
                      class="color-container"
                      style="flex: 1; overflow-y: auto"
                    >
                      <div
                        class="color-item"
                        v-for="(icon, index) in currentChooseType.children"
                        :key="index"
                        style="width: 40px;height: 40px;background: #FFFFFF;border-radius: 4px;border: 1px solid #F2F4F7;"
                        :style="
                          appFormData.icon === icon.font_class
                            ? 'background: #337EFF;border: none;color:#fff': ''
                        "
                        @click="appFormData.icon = icon.font_class, appFormData.color = currentChooseType.color"
                      >
                        <i
                          class="iconfont "
                          style="color: #2A2F3D"
                          :style="
                            appFormData.icon === icon.font_class
                              ? 'color:#fff'
                              : ''
                          "
                          :class="icon.font_class"
                        ></i>
                      </div>
                    </div>
                  </div>
                </template>
              </el-select>
            </el-form-item>
            <el-form-item class="form-item-height" style="margin-bottom: 14px;" label="名称" prop="name">
              <el-input
                v-model="appFormData.name"
                clearable
                placeholder="请输入名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="form-item-height" style="margin-bottom: 14px;" label="描述"
            >
              <el-input
                v-model="appFormData.remarks"
                clearable
                placeholder="请输入描述"
              ></el-input>
            </el-form-item>
            <el-form-item
              v-if="appFormData.menu_type === 1"
              class="form-item-height"
              style="margin-bottom: 14px;"
              label="可见范围"
              prop="visible_range"
            >
              <el-select
                v-model="appFormData.visible_range"
                style="width: 100%">
                <el-option :value="0" label="工作区可见">工作区可见</el-option>
                <el-option :value="1" label="指定架构可见">指定架构可见</el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="+appFormData.menu_type !== 1 && +appFormData.menu_type !== 6" class="form-item-height"  style="margin-bottom: 14px;" label="关联应用">
              <el-select @remove-tag="removeTag" v-model="appFormData.menu_app" multiple  placeholder="请选择"  style="width: 100%">
                <el-option
                  v-for="item in relevanceData"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="
                appFormData.menu_type === 1 &&
                +appFormData.visible_range === 1
              "
              class="form-item-height required-star"
              style="margin-bottom: 14px;"
              label="架构选择"
            >
              <el-select
                :value="archiRange"
                style="width: 100%"
                placeholder=""
              >
                <template slot="prefix">
                  <div class="show-name">
                    {{ archiName ? archiName : "请选择架构" }}
                  </div>
                </template>
                <template #empty>
                  <div class="choose-color" style="width: 420px">
                    <el-tree
                      ref="treeRef"
                      v-loading="treeLoading"
                      :data="treeData"
                      default-expand-all
                      node-key="id"
                      :props="{
                        label: 'name',
                        children: 'children',
                      }"
                      show-checkbox
                      :check-strictly="true"
                      style="height: 100%"
                      @check="treeSelectCheck"
                    >
                      <template #default="{ node, data }">
                        <div class="archi-label">
                          <span class="archi-label-svg">
                            <svg aria-hidden="true" class="color-icon">
                              <use :xlink:href="getIcon(data, 'label')" />
                            </svg>
                            <span>{{ node ? data.name : "" }}</span>
                          </span>
                        </div>
                      </template>
                    </el-tree>
                  </div>
                </template>
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="(appFormData.menu_type === 1 || appFormData.menu_type === 6) && this.editTitle !== '新增应用'"
              class="form-item-height"
              style="margin-bottom: 14px;"
              label="关联页面"
            >
              <el-select
                :value="selectRowRange"
                style="width: 100%"
                placeholder=""
              >
                <template slot="prefix">
                  <div class="show-name">
                    {{ selectRow ? selectRow.name : "请选择关联页面" }}
                  </div>
                </template>
                <template #empty>
                  <div class="choose-color" style="width: 100%;padding-right: 10px;overflow-y: auto;">
                    <el-tree
                      :data="appFormData.children"
                      default-expand-all
                      node-key="id"
                      :props="{
                        label: 'name',
                        children: 'children',
                      }"
                      :check-strictly="true"
                      style="height: 100%"
                      @node-click="treeSelectClick"
                    >
                      <template #default="{ node, data }">
                        <div class="archi-label-container"
                          :style="+node.data.menu_type === 6 && childCanChoose(node.data) ? 'height: 70px' : ''"
                          :class="selectRow && (+selectRow.id === +node.data.id) ? 'selected-relation' : ''"
                        >
                          <div class="archi-label-body">
                            <div class="archi-label-svg">
                              <svg v-if="+node.data.menu_type === 6" aria-hidden="true" class="iconfont-svg">
                                <use xlink:href="#iconwenjianjia2" />
                              </svg>
                              <div v-else-if="data.icon" class="header-img">
                                <svg v-if="+node.data.menu_type === 6" aria-hidden="true" class="iconfont-svg">
                                  <use :xlink:href="data.icon && data.icon.indexOf(el-icon) !== -1 ? data.icon : '#icon-a-05jiluwendang'" />
                                </svg>
                                <!-- <div v-else class="header-img"> -->
                                  <i 
                                    v-else
                                    class="iconfont" 
                                    :class="data.icon? data.icon : 'icon-a-5-1zhiliang'"
                                    :style="'color:' + (['#E06363', '#F59A31','#2CB8A4','#4B98E8','#9F6BF5'].indexOf(data.color) === -1 ? '#E06363' : data.color)"
                                  >
                                  </i>
                                <!-- </div> -->
                                <!-- <svg v-else aria-hidden="true" class="iconfont-svg">{{ data.icon }}
                                  <use :xlink:href="data.icon.indexOf('#')===-1? '#' + data.icon : data.icon" />
                                </svg> -->
                              </div>
                              <div v-else class="header-img">
                                <i class="iconfont icon-a-5-1zhiliang" :style="'color:' + (['#E06363', '#F59A31','#2CB8A4','#4B98E8','#9F6BF5'].indexOf(data.color) === -1 ? '#E06363' : data.color)"></i>
                              </div>
                              <div class="archi-label-txt">
                                {{ node.data.name }}
                              </div>
                            </div>
                            <div class="archi-label-click" v-if="selectRow && (+selectRow.id === +node.data.id)">
                                <i class="iconfont iconselect-duigou"></i>
                            </div>
                          </div>
                          <div v-if="+node.data.menu_type === 6 && childCanChoose(node.data)" class="empty-container">
                            该分组下无可选应用
                          </div>
                        </div>
                      </template>
                    </el-tree>
                  </div>
                </template>
              </el-select>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button :loading="submitLoading" type="primary" @click="editSubmit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import LeftItem from "@/manage-views/views/home/components/applicationTree/components/LeftItem.vue";
  import AddContent from "./components/addContent.vue";
  import LeftItemStyle from "@/manage-views/views/home/components/applicationTree/components/LeftItemStyle.vue";
  import {detMenus} from '@/api/saasManage'
  import { getToken } from '@/utils/tools';
  import { baseUrl } from '@/libs/util';
  import { createdApps,editApps, appsDetail } from '@/api/myApps';
  import { mapState } from 'vuex';
  import { Tree } from 'element-ui' 
  import { departmentFilter } from '@/api/saas'
  import { appliIcons, funcIcons } from './components/icons';
  export default {
    components:{
      'el-tree': Tree,
      LeftItem,
      LeftItemStyle,
      AddContent,
    },
    props:{
      currentMenu: {
        type: Object,
        default: () => {
          return {}
        },
        required: true
      },
      currentRouteName: {
        type: String || Number,
        default: '',
        required: false
      },
      showTemplatePage: {
        type: Boolean,
        default:false,
      },
      inTemplatechild:{
        type: Boolean,
        default:false,
      }
    },
    data() {
      return {
        menuApp:[],
        relevanceData:null, // 关联应用数据
        appliIcons: appliIcons, // 阿里图库的图标列表
        iconList: funcIcons, // 功能图标数组
        dialogFull:false, // 编辑应用弹窗展示
        baseURL: baseUrl(),
        metadataToken: process.env.VUE_APP_METATOKEN,
        token: getToken(),
        listData: [], // 应用及下级数据
        showAddDrawer: false, // 新增功能及分组弹窗显示状态
        applicationTitle: '创建下级功能或分组', // 新增功能及分组弹窗标题
        childInfo: {}, // 新增时父级数据，新增到当前info的id菜单下
        loading: false,
        appFormData: {}, // 编辑应用数据
        defaultProps: {
          label: 'name',
          children: 'cancel'
        },
        rules: {
          name: [
            { required: true, message: '请输入名称', trigger: 'blur' }
          ],
          remarks: [
            { required: true, message: '请输入描述', trigger: 'blur' }
          ],
          icon: [
            { required: true, message: '请选择图标', trigger: 'change' }
          ],
          visible_range: [
            { required: true, message: '请选择可见范围', trigger: 'change' }
          ],
        },
        dialogVisible: false, // 编辑应用弹窗是否显示
        submitLoading: false, // 提交状态
        editTitle: '应用设置',
        appInfo: {}, // 当前选择的应用
        historyAppInfo: {}, // 记录页面所在的应用
        historyChildAppInfo: {}, // 记录当前选中的具体功能
        showHistoryVisible: true,
        appListShow: false, // 是否显示应用子列表
        childTitle: '', // 暗黑状态功能显示父级应用名称
        width: 240,
        colorList: [
          {
            color: '#fb9337',
            background: 'linear-gradient(180deg, #FFA557 0%, #FF5C5C 100%)'
          },
          {
            color: '#fab300',
            background: 'linear-gradient(180deg, #FFCF3D 0%, #FF9A42 100%)'
          },
          {
            color: '#67c200',
            background: 'linear-gradient(180deg, #48E199 0%, #15C2B6 100%)'
          },
          {
            color: '#00bd77',
            background: 'linear-gradient(180deg, #5ADFFA 0%, #459CFF 100%)'
          },
          {
            color: '#00c5fb',
            background: 'linear-gradient(180deg, #8C9FFF 0%, #A970FF 100%)'
          },
        ],
        other: '',
        archiRange: '', // 架构限制选择数据
        archiCheckValue: true,
        treeData: [], // 架构数据
        treeLoading: false, // 架构请求loading
        archiName: '', // 选择的架构名
        selectRowRange: '', // 选择的架构范围
        selectRow: null, // 关联页面选择数据
        currentChooseType: funcIcons[0],
        searchValue: '' // 搜索输入的字段
      }
    },
    watch: {
      currentMenu: {
        handler(val) {
          this.listData = val.children
          if (this.appInfo && this.appInfo.id) {
            this.listData.forEach(element => {
              if (+element.id === +this.appInfo.id) {
                this.appInfo = element
              }
            });
          }
        },
        deep: true,
        immediate: true
      },
      appInfo: {
        handler(val) {
          if (val?.id) {
            this.$store.commit('setAppItem', val)
          } else {
            this.$store.commit('setAppItem', {})
          }
        },
        deep: true
      },
      historyAppInfo: {
        handler(val) {
          if (val?.id) {
            this.$store.commit('setAppItem', this.appInfo)
          } else {
            this.$store.commit('setAppItem', {})
          }
        },
        deep: true
      },
      historyChildAppInfo: {
        handler(val) {
          if (val?.id) {
            this.$store.commit('setChildAppItem', val)
          } else {
            this.$store.commit('setChildAppItem', {})
          }
        },
        deep: true
      },
    },
    computed: {
      ...mapState(['systemStyle']),
    },
    created() {
      this.getArchiTreeList()
    },
    mounted() {
      this.listData = this.currentMenu.children
      this.relevanceData = this.currentMenu.children
    },
    methods:{
      /**
       * @description: 删除得时候不让删除最后一个
       * @param {*} e：最后一个关联应用数据
       * @return {*}
       */      
      removeTag(e){
        if(!this.appFormData.menu_app.length){
          this.appFormData.menu_app.push(e)
        }
        return
      },
      /**
       * @description: 删除指定应用
       * @param {*} info：删除指定的菜单数据
       * @return {*}
       */      
      rowDel(info) {
        this.loading = true
        detMenus(info.id).then((res) => {
          if (this.appInfo.id === info.id) {
            this.appInfo = {}
            this.historyAppInfo = {}
            this.historyChildAppInfo = {}
          }
          this.loading = false
          if (res.status === 200 && res.data.code === 200) {
            this.$message.success('删除成功!');
            this.$emit('updateMenuList', true)
            this.$emit('appChildAdd')
          }
        })
      },
      /**
       * @description: 应用点击
       * @param {*} data：点击的应用数据
       * @return {*}
       */      
      rowClick(data) {
        this.showHistoryVisible = true
        this.historyAppInfo = data
        this.appInfo = data
        this.childTitle = data.name
        this.appListShow = true
        this.$emit('applicationClick', data)
      },
      /**
       * @description: 新增应用点击 
       * @return {*}
       */      
      add() {
        this.$emit('appChildAdd')
        this.editTitle = '新增应用'
        this.childInfo = this.currentMenu
        this.appFormData = {
          remarks: '',
          icon: '#icon-a-05jiluwendang',
          name: '',
          color: '#fb9337',
          visible_range: 0,
          background: 'linear-gradient(180deg, #FFA557 0%, #FF5C5C 100%)',
          id: this.childInfo.id,
          menu_type: 1
        }
        this.archiName = ''
        // this.dialogVisible = true
      },
      /**
       * @description: 用于外部调用新增弹窗方法
       * @return {*}
       */      
      addChildDialog() {
        this.editTitle = '新增应用'
        this.childInfo = this.currentMenu
        this.appFormData = {
          remarks: '',
          icon: '#icon-a-05jiluwendang',
          name: '',
          color: '#fb9337',
          visible_range: 0,
          background: 'linear-gradient(180deg, #FFA557 0%, #FF5C5C 100%)',
          id: this.childInfo.id,
          menu_type: 1
        }
        this.archiName = ''
        this.dialogVisible = true
      },
      /**
       * @description: 编辑应用点击
       * @param {*} data：编辑的应用数据
       * @return {*}
       */      
      rowEdit(data) {
        this.selectRow = null
        this.archiName = ''
        this.editTitle = '编辑' + data.name
        this.appFormData = JSON.parse(JSON.stringify(data))
        if (!this.appFormData.icon || this.appFormData.icon.indexOf('el-icon') !== -1) {
          this.appFormData.icon = +this.appFormData.menu_type === 1 ? '#icon-a-05jiluwendang' : 'icon-a-1-1zhuye'
        }
        if (!this.appFormData.background || this.appFormData.background.indexOf('linear') === -1) {
          this.appFormData.background = +this.appFormData.menu_type === 1 ? 'linear-gradient(180deg, #FFA557 0%, #FF5C5C 100%)' : '#fff'
        }
        if (!this.appFormData.color && +this.appFormData.menu_type === 1) {
          this.appFormData.color = '#268bfb'
        }
        if (this.appFormData.relation_id && this.appFormData?.children?.length) {
          const loopTree = (arr) => {
            arr.forEach(element => {
              if (element.id === this.appFormData.relation_id) {
                this.selectRow = element
              } else if (element?.children?.length) {
                loopTree(element.children)
              }
            });
          }
          if (data?.children?.length) {
            loopTree(data.children)
          }
        }
        if (!this.appFormData.visible_range) {
          this.appFormData.visible_range === 0
        }
        if (this.appFormData.menu_type === 1 && +this.appFormData.visible_range === 1 && this.appFormData.range_id && this.appFormData.range_id.length) {
          this.appFormData.range_id = this.appFormData.range_id.map(Number)
          this.dialogVisible = true
          this.$nextTick(() => {
            this.$refs.treeRef.setCheckedKeys(this.appFormData.range_id)
            this.treeSelectCheck()
          })
        } else {
          this.dialogVisible = true
        }
      },
      /**
       * @description: 编辑提交数据方法
       * @return {*}
       */      
      editSubmit() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            const { id, remarks, icon, name, color, background, visible_range, range_id  } = this.appFormData
            const params = {
              remarks,
              icon: icon && icon.indexOf('el-icon') === -1 ? icon : '#icon-a-05jiluwendang',
              name,
              background: background ? background : 'linear-gradient(180deg, #FFA557 0%, #FF5C5C 100%)',
              color,
              menu_id: id > 0 ? id : 0,
              visible_range,
              range_id,
            }
            if (!this.appFormData.menu_type || this.appFormData.menu_type !== 1) {
              params.visible_range = 0
              delete params.range_id
            }
            if (this.appFormData.menu_type &&  this.appFormData.menu_type === 1 && visible_range === 1 && (!range_id || range_id.length === 0)) {
              this.$message.warning('请选择架构！')
              return
            }
            if (!params.menu_id || +params.meue_id === -1) {
              params.menu_id = 0
            }
            if (!(this.editTitle.indexOf('编辑') !== -1)) {
              params.menu_type = 1
              params.type = 1
              params.company_id = localStorage.getItem('company_id')
              createdApps(params).then((res) => {
                if (res.status === 200 && res.data.code === 200) {
                  this.$message.success('新增成功！')
                  this.dialogVisible = false
                  this.$emit('updateMenuList', true)
                }
              }).catch(() => {
                  this.dialogVisible = false
              })
              return
            }
            if (this.selectRow) {
              params.relation_id = this.selectRow.id
            }
            if(this.appFormData.menu_app){
              params.menu_app = this.appFormData.menu_app
            }
            editApps(params).then((res) => {
              if (res.status === 200 && res.data.code === 200) {
                this.$message.success('编辑成功！')
                this.dialogVisible = false
                this.$emit('updateMenuList', true)
              }
            }).catch(() => {
                this.dialogVisible = false
            })
          }
        })

      },
      /**
       * @description: 新增侯关闭弹窗
       * @param {*} boo：是否刷新菜单
       * @return {*}
       */      
      closeAddDrawer(boo) {
        this.editTitle = ''
        this.childInfo = {}
        this.showAddDrawer = false
        if (boo) {
          this.$emit('updateMenuList', true)
        }
      },
      /**
       * @description:刷新菜单列表
       * @param {*} boo：是否更新请求菜单数据
       * @return {*}
       */      
      updateMenuList(boo) {
        this.$emit('updateMenuList', boo)
      },
      /**
       * @description: 应用下新增编辑删除等
       * @param {*} data：编辑或者删除对象
       * @param {*} boo：当前是否点击的应用
       * @return {*}
       */      
      childRowClick(data, boo) {
        if (this.historyChildAppInfo.id === data.id && boo) {
          return
        }
        if (!boo) {
          this.showHistoryVisible = false
        }
        // 大屏页面不关闭
        if(data.menu_type && +data.menu_type !== 3 && +data.menu_type !== 4){
          this.$emit('childClick', data)
          this.historyAppInfo = this.appInfo
          this.historyChildAppInfo = data
        }
        

        if (!data) {
          return
        }
        let path = ''
        let query = {}
        // 页面编辑器类型
        const pageTypeNmuber = [2, 5]
        if (+data.menu_type === 7) {
          path = '/application/my-application'
          query = { formUuid: data.form_module_uuid ,appMenuId:data.id, create_user_id: data.create_user_id }
        } else if (pageTypeNmuber.indexOf(+data.menu_type) !== -1) {
          const typeHash = {
            2: 'page',
            5: 'mobile'
          }
          path = '/application/page-parser'
          query = {
            pageUUID: data.page_uuid,
            id: data.current_data_id,
            type: typeHash[data.menu_type],
            name: data.name,
            create_user_id: data.create_user_id
          }
        } else if(+data.menu_type === 3 || +data.menu_type == 4) {
          const query = {
            pageUUID: data.page_uuid,
            id: data.current_data_id,
            type: data.menu_type === 3 ? 'dataview' : 'console',
            name: data.name,
            create_user_id: data.create_user_id
          }
          let path = '/full-page-parser'
          if(Object.keys(query).length) {
            let queryStr = ''
            for (const key in query) {
              if (Object.hasOwnProperty.call(query, key)) {
                if(query[key]) {
                  if(queryStr) {
                    queryStr += '&'
                  }
                  queryStr += `${key}=${query[key]}`
                }
              }
            }
            path = path + '?' + queryStr
          }
          path = encodeURI(path)
          /* 目前无法解决浏览器刷新问题，等待后续解决 */
          // const a = document.createElement('a') //创建a标签
          // a.setAttribute('target',data.menu_type === 3 ? 'dataview' : 'console')  //href链接
          // a.setAttribute('href',path)  //href链接
          // a.click() //自执行点击事件
          window.open(path)
          return
        } else if(+data.menu_type === 8) {
          path = '/bulletin-board/shower'
          query = {
            chart_uuid: data.chart_uuid,
            id: data.current_data_id,
            name: data.name,
            create_user_id: data.create_user_id
          }
        }
        if ([2, 3, 4, 5, 7].indexOf(+data.menu_type) !== -1) {
          let navHistoryList = JSON.parse(sessionStorage.getItem('navHistoryList'))
          if (navHistoryList && navHistoryList.length) {
            let boo = false
            navHistoryList.forEach(element => {
              if (+element.id === +data.id) {
                boo = true
              }
            });
            if (!boo) {
              navHistoryList.push(data)
            }
          } else {
            navHistoryList = [data]
          }
          sessionStorage.setItem('navHistoryList', JSON.stringify(navHistoryList))
        }
        if (!data.menu_type) {
          /* 判断是否是大屏 目前是废弃 走上面逻辑 */
          if (data.path.indexOf('dataview/show') !== -1) {
            const uuid = data.path.indexOf('?pageUUID=') !== -1 ? data.path.split('?pageUUID=')[1] : ''
            path = '/application/page-parser'
            query = {
              pageUUID: uuid,
              id: data.id,
              type: 'dataview',
              name: data.name,
              create_user_id: data.create_user_id
            }
            this.$router.push({ path: path, query: query })
          } else {
            this.$router.push({ path: data.path })
          }
        }
        if (data.menu_type !== 6) {
          this.$router.push({ path: path, query: query })
        }
        if (!boo) {
          this.$emit('currentMenuList',data)
        }
      },
      /**
       * @description: 自己功能或者分组编辑
       * @param {*} data：编辑的数据
       * @return {*}
       */      
      childRowEdit(data) {
        appsDetail(data.id).then((res)=>{
          let dataInfo = res.data.data
          this.archiName = ''
          this.editTitle = '编辑' + dataInfo.name
          this.appFormData = JSON.parse(JSON.stringify(dataInfo))
          if (!this.visible_range) {
            this.appFormData.visible_range === 0
          }
          if (+this.appFormData.menu_type !== 1 && +this.appFormData.menu_type !== 6) {
            this.currentChooseType = funcIcons[0]
          }
          if (+this.appFormData.menu_type !== 1 && +this.appFormData.menu_type !== 6 && this.appFormData.icon && this.appFormData.icon.indexOf('el-icon') === -1) {
            this.iconList.forEach(item => {
              item.children.forEach(element => {
                if (element.font_class === this.appFormData.icon) {
                  this.currentChooseType = item
                  this.appFormData.color = this.currentChooseType.color
                }
              });
            });
          }
          if (+this.appFormData.menu_type !== 1 && (!this.appFormData.color || ['#E06363', '#F59A31','#2CB8A4','#4B98E8','#9F6BF5'].indexOf(this.appFormData.color) === -1)) {
            this.appFormData.color = '#F59A31'
          }
          this.dialogVisible = true
        })

      },
      /**
       * @description: 子级数据新增 
       * @return {*}
       */      
      childAdd() {
        this.childInfo = this.appInfo
        this.showAddDrawer = true
      },
      /**
       * @description: 指定子级数据下新增数据
       * @param {*} data：指定的子级数据
       * @return {*}
       */      
      childRowAdd(data) {
        this.childInfo = JSON.parse(JSON.stringify(data))
        this.showAddDrawer = true
      },
      /**
       * @description: 返回
       * @return {*}
       */      
      back() {
        this.appInfo = {}
        this.appListShow = false
      },
      /**
       * @description: 是否显示应用子列表切换方法
       * @return {*}
       */      
      changeToList() {
        this.listData = this.currentMenu.children
        this.appInfo = {}
        this.appListShow = false
      },
      /**
       * @description: 隐藏应用显示 
       * @return {*}
       */      
      closeMenuItemClick() {
        this.width = 0
      },
      /**
       * @description: 显示应用数据展示 
       * @return {*}
       */      
      showMenuItemClick() {
        this.width = 240
      },
      /**
       * @description: 请求架构数据
       * @return {*}
       */      
      getArchiTreeList() {
        this.treeLoading = false
        departmentFilter().then((res) => {
          this.treeLoading = false
          if (res.status === 200 && res.data.code === 200) {
            const data = res.data?.data
            const chooseArr = [1, 2, 3, 4, 5, 6]
            const loopTree = (arr) => {
              arr.forEach(element => {
                if (element.children && element.children.length) {
                  element.children = element.children.filter((ite) => {
                    return chooseArr.indexOf(+ite.type) !== -1
                  })
                  loopTree(element.children)
                }
              });
            }
            if (data && data.length) {
              loopTree(data)
            }
            this.treeData = data
          }
        })
      },
      /**
       * @description: 架构选择触发
       * @return {*}
       */      
      treeSelectCheck() {
        const checkedKeys = this.$refs.treeRef.getCheckedKeys()
        this.appFormData.range_id = checkedKeys || []
        let archiName = ''
        const loopTree = (arr) => {
          arr.forEach(element => {
            if (checkedKeys.includes(+element.id)) {
              if (archiName) {
                archiName += ', ' + element.name
              } else {
                archiName = element.name
              }
            }
            if (element.children && element.children.length) {
              loopTree(element.children)
            }
          });
        }
        if (checkedKeys && checkedKeys.length) {
          loopTree(this.treeData)
        }
        this.archiName = archiName
      },
      /**
       * @description: 根据架构类型返回架构图标
       * @param {*} data：架构数据
       * @return {*}
       */      
      getIcon(data) {
        const group = [1, 2]
        const company = [3, 4]
        const project = [5, 6]
        const section = [7]
        if (!data) {
          return '#iconjituan'
        }
        if (group.indexOf(+data.type) !== -1 ) {
          return '#iconjituan'
        } else if (company.indexOf(+data.type) !== -1 ) {
          return '#icongongsi'
        } else if (project.indexOf(+data.type) !== -1 ) {
          return '#iconxiangmu'
        } else if (section.indexOf(+data.type) !== -1 ) {
          return '#iconbumen'
        }
      },
      /**
       * @description: 子级是否有可选对象 true为没有可选子级
       * @param {*} data
       * @return {*}
       */      
      childCanChoose(data) {
        let boo = true
        const loopTree = (arr) => {
          arr.forEach(element => {
            if (element?.menu_type && element.menu_type !== 6) {
              boo = false
            } else if (element?.children?.length) {
              loopTree(element.children)
            }
          });
        }
        if (data?.children?.length) {
          loopTree(data.children)
        }
        return boo
      },
      /**
       * @description: 关联数据选择
       * @param {*} row：选择的关联数据对象
       * @return {*}
       */      
      treeSelectClick(row) {
        if (this.selectRow?.id === row.id) {
          this.selectRow = {}
          return
        }
        if (row && +row.menu_type !== 6) {
          this.selectRow = row
        }
      },
      /* 搜索 */
      searchValueChange() {
        if (!this.appListShow) {
          if(this.$refs.leftItem){
            this.$refs.leftItem.filterValue(this.searchValue)
          }
        } else {
          if(this.$refs.childLeftItem){
            this.$refs.childLeftItem.filterValue(this.searchValue)
          }
        }
      }
    }
  }
</script>
<style lang="less">
  .el-drawer__header {
    padding: 15px 20px 15px !important;
    margin-bottom: 0;
  }

</style>
<style lang='less' scoped>
:deep(.select-icon) {
  .el-input {
    width: 48px;
    height: 48px;
    background: #FFFFFF;
    border-radius: 4px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;

    input {
      display: none;
    }
    .el-input__suffix {
      display: none;
    }
    .el-input__prefix {
      width: 48px;
      height: 48px;
      i {
        font-size: 18px;
        color: #000;
      }
    }
  }
}
.add-content-title {
  height: 26px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #2A2F3D;
  line-height: 26px;
  padding-left: 10px;
  box-sizing: border-box;
}
.common-body{
  height: auto;
}
  :deep(.avatar-uploader) {
    .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;

      &:hover {
        border-color: #409EFF;
      }
    }
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
  }

  .avatar {
    width: 80px;
    height: 80px;
    display: block;
  }
  .full-width {
    width: 100%;
  }
  .wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    .form-item-height {
      width: 100%;
      .show-name {
        width: 100%;
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0 40px 0 12px;
        box-sizing: border-box;
        color: #606266;
      }
    }
  }

  .application-tree {
    width: 240px;
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
    transition: width 0.3s;
    .application-tree-container {
      height: 100%;
      .left-item-title {
        width: 100%;
        height: auto;
        padding: 8px 8px 0 8px;
        box-sizing: border-box;
        .item-title-input {
          height: 32px;
          background: #F2F4F7;
          border-radius: 6px;
        }
      }
    }
  }
  /* 头像显示 */
  .header-img {
    width: 48px;
    height: 48px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      font-size: 26px;
    }
    svg {
      width: 36px;
      height: 36px;
    }
  }
  /* 选择颜色图标 */
  .choose-color {
    width: 460px;
    height: 300px;
    display: flex;
    flex-direction: column;
    padding: 10px 0 10px 10px;
    box-sizing: border-box;
    :deep(.el-tree-node__content) {
      height: auto;
      margin-bottom: 4px;
      box-sizing: border-box;
      .el-tree-node__expand-icon, .is-leaf {
        display: none;
      }
    }
    p {
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
      color: #121315;
      margin: 16px 0;
    }
    .color-container {
      width: 100%;
      height: auto;
      display: flex;
      flex-wrap: wrap;
      .color-item {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        i {
          color: #fff;
        }
        .color-item-i {
          color: #606266
        }
      }
      .color-item-app {
        width: 46px;
        height: 46px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        margin: 0 6px 6px 0;
        .color-item-app-div{
          width: 40px;
          height: 40px;
          border-radius: 8px;
          margin-right: 0 !important;
        }
        svg {
          width: 30px;
          height: 30px;
          color: #fff;
        }
      }
      .color-item-app-border {
        border: 2px solid #D0E2FF ;
        box-sizing: border-box;
        border-radius: 10px;
      }
    }
    /* 架构选择样式 */
    .archi-label {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;
        -moz-user-select:none; /* Firefox私有属性 */
        -webkit-user-select:none; /* WebKit内核私有属性 */
        -ms-user-select:none; /* IE私有属性(IE10及以后) */
        -khtml-user-select:none; /* KHTML内核私有属性 */
        -o-user-select:none; /* Opera私有属性 */
        user-select:none; /* CSS3属性 */
        .archi-label-svg {
          width: 100%;
          display: flex;
          align-items: center;
          overflow: hidden;
          span {
            flex: 1;
            overflow: hidden;
            padding-right: 6px;
            box-sizing: border-box;
          }
          img {
            width: 20px;
            height: 20px;
            margin-right: 6px;
          }
          svg {
            width: 20px;
            height: 20px;
            margin-right: 6px;
          }
        }
      }
  }
  .archi-label-container {
    width: 100%;
    height: 40px;
    display: flex;
    position: relative;
    .archi-label-body {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 9;
      width: 100%;
      height: 40px;
      display: flex;
      .archi-label-svg {
        flex: 1;
        font-family: SourceHanSansCN;
        font-size: 14px;
        font-weight: 400;
        display: flex;
        align-items: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-left: 11px;
        box-sizing: border-box;
        .header-img {
          width: 32px;
          height: 32px;
          margin-right: 6px;
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          i {
            width: 16px;
            height: 16px;
            font-size: 16px;
          }
        }
        svg {
          width: 22px;
          height: 22px;
          margin: 0 6px;
          border-radius: 4px;
        }
        .archi-label-img {
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 4px;
          box-sizing: border-box;
          margin-right: 6px;
          border-radius: 4px;
          background-color: #e8f4ff;
          border: 1px solid #dee1e6;
          img {
            width: 22px;
            height: 22px;
          }
          span {
            color: #409EFF;
            font-weight: 550;
            font-size: 18px;
          }
        }
        i {
          font-size: 16px;
          margin-right: 6px;
        }
        .archi-label-txt {
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          height: 22px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #2A2F3D;
          line-height: 22px;
        }
      }
      .archi-label-click {
        width: 60px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        i {
          font-size: 20px;
        }
      }
    }
    .empty-container {
      position: absolute;
      bottom: 3px;
      width: 100%;
      height: 22px;
      font-size: 14px;
      box-sizing: border-box;
      padding-left: 40px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #A9ADB8;
      line-height: 22px;
    }
  }
  // .selected-relation {
  //   .archi-label-txt {
  //     color: #fff !important;
  //   }
  //   .header-img {
  //     i {
  //       color: #fff;
  //     }
  //   }
  //   .archi-label-click {
  //     color: #fff;
  //     i {
  //       color: #fff;
  //     }
  //   }
  // }
  // .selected-relation::after {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   height: 100%;
  //   width: 100%;
  //   box-sizing: border;
  //   content: '';
  //   background: #337EFF;
  //   border-radius: 6px;
  // }
</style>
<style lang="less" scoped>
@import "../../../../css/manageAdd.less";
</style>
