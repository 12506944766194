<!--
 * @Author: 朱廷果 1028509503@qq.com
 * @Date: 2022-10-18 13:56:07
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2022-10-19 09:35:10
 * @FilePath: \dataview-next\src\manage-views\views\home\components\fristLogin.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <el-dialog
      title="提示"
      :visible.sync="loginDialog"
      width="24%"
      :before-close="handleClose">
      <span style="font-size:16px">
        <i class="el-icon-info" style="color: darkorange"></i>
        检测到您尚未创建组织架构，是否前往创建组织架构
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="loginDialog = false">取 消</el-button>
        <el-button type="primary" @click="toDept">去创建</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    dialog:{
      type:Boolean,
      default:false,
    },
  },
  data() {
    return {
      loginDialog:false,
    };
  },
  watch: {
    dialog(newVal){
      console.log(newVal);
      this.loginDialog = newVal;
    },
  },
  computed: {},
  methods: {
    toDept(){
      this.loginDialog = false;
      this.$emit('jumpDept')
    },
    handleClose(){
      this.loginDialog = false;
    }
  },
  created() {},
  mounted() {}
};
</script>
<style lang="less" scoped>
:deep(.el-dialog__body){
  padding: 24px !important;
}
@import "../../../css/manageAdd.less";
</style>
