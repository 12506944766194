<!--
 * @Author: zx
 * @Date: 2022-08-01 14:15:10
 * @LastEditTime: 2022-10-17 16:26:30
 * @Description:
 * @LastEditors: zx
-->
<template>
  <div class="three-terminal">
    <MobileSelectTree 
      v-if="showTree"
      :data="listData"
      :defaultProps="treeProps"
      :checkbox="false"
      :is-children="true"
      @stateCheckClick="stateCheckClick" 
    />
  </div>
</template>
<script>
  import { defineComponent, onMounted, reactive, toRefs } from 'vue'
  import { dataInterface } from '@/apis/data/index';
  import useStore from '@/store'
  import eventBus from '@/plugins/eventBus';
  import { judgingEventLogic } from '@/utils/tools';
  export default defineComponent({
    components: {
    },
    props: {
      element: {
        type: Object,
        default: () => {}
      }
    },
    setup(props) {
      const store = useStore
      const state = reactive({
        archiInfo: localStorage.getItem('targetArchi'),
        showTree: false,
        listData: [],
        copyListData: [],
        treeProps: {
          label: props.element?.database?.mobileTreeInfo?.labelKey || 'name',
          children: props.element?.database?.mobileTreeInfo?.childrenKey || 'projects'
        },
        metadata: null
      })
      eventBus.$on('databaseTrigger', () => {
        getProjectList()
      })
      const getProjectList = () => {
        state.listData = []
        const params = {
          object_uuid:'object616fe6fe8119f',
          view_uuid:'view62986db9582aa',
          __method_name__: 'dataList',
        }
        if (props.element?.database?.objectData?.uuid) {
          params.object_uuid = props.element.database.objectData.uuid
          params.view_uuid = props.element.database.viewData.uuid
          if (props.element.database.paramsConfig && Array.isArray(props.element.database.paramsConfig) && props.element.database.paramsConfig.length) {
            const obj = {}
            props.element.database.paramsConfig.forEach(element => {
              if (element.systemKey === 'targetArchi') {
                obj[element.key] = element.systemObject[element.systemCode]
              }
            });
            Object.assign(params, obj)
          }
        }
        dataInterface(params).then((res) => {
          if(res && +res.data.code === 200){
            let arr = res.data.data
            state.metadata = res.data.metadata
            store.commit('modifyComponent', {
              component: {
                ...props.element,
                database: {
                  ...props.element.database,
                  metadata: state.metadata
                },
                metadata: state.metadata,
              },
              containerId: null,
              isModify: true
            });
            const loopDataList = (arr) => {
              arr.forEach(element => {
                element.expand = true
                const childrenKey = props.element?.database?.mobileTreeInfo?.childrenKey || 'projects'
                if (childrenKey && element[childrenKey] && element[childrenKey].length) {
                  loopDataList(element[childrenKey])
                }
              });
            }
            if (Array.isArray(arr) && arr.length) {
              loopDataList(arr)
            } else {
              arr = res.data.data.data
              loopDataList(arr)
            }
            state.listData = arr;
            state.copyListData = JSON.parse(JSON.stringify(state.listData))
          }
          state.showTree = true
        })
      }
      const stateCheckClick = (data) => {
        store.commit('modifyComponent', {
          component: {
            ...props.element,
            database: {
              ...props.element.database,
              metadata: state.metadata
            },
            metadata: state.metadata,
            resolveData: data
          },
          containerId: null,
          isModify: true
        });
        if (
          props.element?.actionConfig?.sureClick?.eventList?.length
        ) {
          const eventList = props.element.actionConfig.sureClick.eventList;
          if (eventList && eventList.length) {
              eventList.forEach((el) => {
                el.eventList.forEach(ele => {
                  if (ele.key === 'click') {
                    ele.effects.forEach((effect) => {
                      let logicBoolean = true
                      if (effect.logics.length) {
                        logicBoolean = judgingEventLogic(effect.logics, useStore.state['componentData'] || [], data);
                      }
                      if (logicBoolean) {
                         store.commit('triggerEvents', {
                          config: {
                            ...ele,
                          ...effect
                          },
                          element: props.element
                        });
                      }
                    });
                  }
                })
              });
            }
          return
        }
      }
      /* 搜索数据结构方法 */
      const mapTree = (value, arr, key, childrenKey) => {
          let newarr = [];
          arr.forEach(element => {
              if (element[key].indexOf(value) > -1) { // 判断条件
                  newarr.push(element);
              } else {
              if (element[childrenKey] && element[childrenKey].length > 0) {
                  let redata = mapTree(value, element[childrenKey], key, childrenKey);
                  if (redata && redata.length > 0) {
                      let obj = {
                          ...element,
                          [childrenKey]: redata
                      };
                          newarr.push(obj);
                      }
                  }
              }
          });
          return newarr;
      }
      onMounted(() => {
        getProjectList()
        // 筛选触发请求
        eventBus.$off('routeTopInputSearch');
        eventBus.$on('routeTopInputSearch', (val) => {
          if (val && state.listData && state.listData.length) {
            state.listData = mapTree(val, state.copyListData, state.treeProps[props.element?.database?.mobileTreeInfo?.labelKey || 'name'], state.treeProps[props.element?.database?.mobileTreeInfo?.childrenKey || 'projects'])
          } else {
            state.listData = JSON.parse(JSON.stringify(state.copyListData))
          }
        });
      })
      return {
        ...toRefs(state),
        stateCheckClick
      }
    }
  })
</script>
<style lang='less' scoped>
.three-terminal {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
</style>
