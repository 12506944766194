<!--
 * @Author: zyf
 * @Date: 2022-07-13 17:09:11
 * @LastEditTime: 2022-09-19 11:35:59
 * @Description: 
 * @LastEditors: zyf
-->
<template>
    <div class="dynamic-list-warp">
        <div class="list-box" :style="rankList && rankList.length ? 'width: 80%;' : 'width: 100%;'" @scroll="scrollEvent">
          <div class="list-item" v-for="(item,index) in dataList" :key="index" >
            <div>
              <span class="avatar-box" :class="!!item.defaultIcon ? 'defaultIcon' : ''">
                <img :src="item.avatar" class="head-icon">
              </span>
              <span class="item-content">{{item.user_name}}&nbsp;&nbsp;{{item.type_name}}了【{{item.object_name}}】</span>
            </div>
            <div>
              <span class="item-time">{{switchTime(item.created_at)}}</span>
            </div>
          </div>
          <Empty v-if="list.length === 0" description="暂无数据"  :image-size="100"></Empty>
        </div>
        <div v-if="rankList && rankList.length" class="rank-box">
          <div v-for="(item,index) in rankList"  :key="item.id" class="icon-box">
            <img :src="rankIconList[index]" class="rank-icon">
            <el-tooltip class="item" :content="item.user_name" placement="bottom">
              <span class="avatar-box" :class="!!item.defaultIcon ? 'defaultIcon' : ''">
                <img :src="item.avatar" class="head-icon">
              </span>
            </el-tooltip>
            
          </div>
        </div>
    </div>
</template>
<script>
import { Empty,Tooltip } from 'element-ui'
import { rankIconList } from './config'
export default {
    components: {
        Empty,
        'el-tooltip': Tooltip,
    },
    props: {
        list: {
          type: Array,
          default:()=>[]
        },
        rankData:{
          type: Array,
          default:()=>[]
        },
        isUpdate:{
          type:Boolean,
          default:true
        }
    }, 
    data() {
        return {
          dataList:this.list,
          rankList:this.rankData,
          rankIconList:rankIconList,
        }
    },
    computed:{
      switchTime(){
        return function(time){
          const m = time.slice(5,7)
          const d = time.slice(8,11)
          const h = time.slice(11,13)
          const mm = time.slice(14,16)
          return m + '/' + d + ' ' + h + '/' + mm
        }
      },
    },
    methods: {
      scrollEvent(e){
        if((e.target.offsetHeight + e.target.scrollTop) - e.target.scrollHeight === 0){
          if(this.isUpdate){
            this.$emit('refresh')
          }else{
            this.$message.warning('没有更多数据了')
          }
        }
      }
    },
}
</script>

<style lang="less">
  .dynamic-list-warp{
    ::-webkit-scrollbar{
      width:  0 !important;
    }
    ::-webkit-scrollbar{
      width: 0 !important;
      height: 0;
    }
  }
</style>

<style lang="less" scoped>
  .dynamic-list-warp{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    .list-box{
      height: 100%;
      box-sizing: border-box;
      overflow: auto;
      
      .list-item{
        & div:first-child{
          padding: 0 0 0 12px;
          height: 40px;
          line-height: 40px;
          margin-bottom: 12px;
          background: #F5F6F7;
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
          max-width: calc(100% - 154px);
          width: auto;
          box-sizing: border-box;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          display: inline-block;
          .item-content{
            height: 22px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #2A2F3D;
            line-height: 22px;
            padding-left: 8px;
            max-width: 200px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        & div:last-child{
          padding: 0 12px 0 0;
          height: 40px;
          line-height: 40px;
          margin-bottom: 12px;
          background: #F5F6F7;
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
          box-sizing: border-box;
          overflow: hidden;
          width: 154px;
          display: inline-block;

          .item-time{
            height: 22px;
            font-size: 13px;
            padding-left: 56px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #787E8F;
            line-height: 22px;
          }
        }
      }
    }
    
    .rank-box{
      box-sizing: border-box;
      overflow: hidden;
      .icon-box{
         display: flex;
         align-items: center;
         padding-bottom: 16px;
        .rank-icon{
          display: block;
          width: 20px;
          height: 20px;
          padding-right: 12px;
        }
      }
      
    }
    .avatar-box{
      width: 20px;
      height: 20px;
      display: inline-block;
      overflow: hidden;
      vertical-align:middle;
      border-radius: 4px;
      .head-icon{
        width: 20px;
        height: 20px;
        display: block;
        line-height: 20px;
      }
    }
    .defaultIcon{
      border: 1px solid;
      border-color: rgba(0, 0, 0, 0.1);
    }
  }
</style>
