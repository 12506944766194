/*
 * @Description: 统计分析
 * @Author: luocheng
 * @Date: 2022-01-04 17:15:31
 * @LastEditors: luocheng
 * @LastEditTime: 2022-04-27 10:45:08
 */
import vue from 'vue'

export default {
  namespace: true,
  state: {
    // 当前选中图表对象
    chartData: null
    // chartData: {
    //   name: '', // 名称标题
    //   type: '', // 图表类型
    //   component: '', // 组件
    //   configs: [
    //     {
    //       view_uuid: '',
    //       x: { // 维度
    //         field_uuid: '',
    //         show_field_uuid: ''
    //       },
    //       group:{ // 分组
    //         field_uuid : '',
    //         show_field_uuid:''
    //       },
    //       y:{ // 指标
    //         field_uuid: '',
    //         show_field_uuid: '',
    //         method:'',
    //       }
    //     }
    //   ]
    // }
  },
  mutations: {
    /**
     * @desc: 切换编辑图表
     */
    setChartData(state, chartData) {
      // console.log(chartData, '222222切换编辑图表22222', JSON.parse(JSON.stringify(chartData)))
      // state.chartData = chartData;
      vue.set(state, 'chartData', chartData)
    }
  },
  actions: {
  },
  modules: {
  }
}