<!--
 * @Description: iframe
 * https://www.w3school.com.cn/tags/tag_iframe.asp
 * @Author: luocheng
 * @Date: 2021-10-19 11:34:49
 * @LastEditors: luocheng
 * @LastEditTime: 2021-10-21 15:38:34
-->
<template>
  <div class="common-iframe">
    <iframe class="iframe-content" :src="linkUrl" frameborder="0"></iframe>
  </div>
</template>

<script>
export default {
  name: 'CommonIframe',
  props: {
    element: {
      type: Object,
      required: true
    }
  },
  computed: {
    linkUrl() {
      return this.element && this.element.propValue;
    }
  }
}
</script>

<style lang="less" scoped>
.common-iframe{
  width: 100%;
  height: 100%;
  .iframe-content{
    display: block;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: none;
    outline: none;
  }
}
</style>