<template>
  <div class="priview">
    <div id="viewerDom" ref="viewerDom" style="width: 100%; height: 100%"></div>
  </div>
</template>
<script src="https://models.bimcc.net/javascripts/viewerBIM.js"></script>
<script>
let viewer = null
export default {
  name: "bimPreview",
  props: {
    modelData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      initState: "stop",
      selectData: [],
    };
  },
  components: {},
	watch: {
		// 为了触发配置时候字段排序更新
		modelData: {
			handler(arr) {
        if(arr && arr.length){
          this.$nextTick(()=>{
            arr.forEach((item) => {
              if (item.dbids && item.dbids.length !== 0) {
                if (item.model_id) {
                  this.addModel(item.model_id, [item])
                }
              } else {
                if (item.model_id) {
                  this.addModel(item.model_id)
                }
              }
            })
          })
    }
			},
			deep: true,
			immediate: true
		}
	},
  methods: {
    // dbids是回显的构件id
    addModel(id, dbids) {
      if (this.initState === "stop") {
        // 加载第一个模型
        this.initModel(id, dbids);
      } else {
        const timer = setInterval(() => {
          if (this.initState === "done") {
            clearInterval(timer);
            viewer.loadModel(id).then(() => {
              if (dbids !== undefined && viewer) {
                viewer.addSelect(dbids);
                this.selectData = this.selectData.concat(
                  JSON.parse(JSON.stringify(dbids))
                );
              }
            });
          }
        }, 200);
      }
    },
    initModel(id, dbids) {
      this.$nextTick(() => {
        this.initState = "loading";
        const appId = 'bdafce12a2d9465d89821ec14214a382';
        const appSecret = '3EYcdAjbnWxP';
        viewer = new BIMCC.Viewer("viewerDom", {});
        viewer.init(id, {
          appId,
          appSecret,
          UIType: "UI.Base",
        });
        viewer.on("viewerLoaded", async () => {
          this.initState = "done";
          // 回显构件选中
          if (dbids !== undefined && viewer) {
            // 传入数组
            viewer.addSelect(dbids);
            setTimeout(() => {
              viewer.fitToView(this.selectData);
            }, 500);
            this.selectData = JSON.parse(JSON.stringify(dbids));
          }
        });
        // 监听构件选中
        viewer.on("Viewer.onSelect", async (selections) => {
          this.selectData = selections;
          this.$emit("on-select", selections);
        });
      });
    },

  },
};
</script>

<style lang="less" scoped>
.priview {
  width: 100%;
  height: 100%;
  #viewerDom {
    width: 100%;
    height: 100%;
  }
}
</style>
