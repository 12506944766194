<!--
 * @Description: 公共表单容器 用于引入外部表单
 * @Author: luocheng
 * @Date: 2021-10-15 15:01:42
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2023-01-04 14:32:57
-->
<template>
	<div class="common-form-container">
		<template v-if="canInitForm">
			<article class="common-form-main">
				<PcParser
					v-if="contentConfig && contentConfig.objectUUID && formDesignData"
					@submit="onFormSubmit"
					:form-data="formDesignData"
					:ref="'common-form-container' + element.id"
					:displayData="displayData"
					@on-close="onEndFlow"
					:editFields="editFields"
					:disabled="formDisabled"
					:needFlow="needFlow"
					:moduleUuid="contentConfig.formUUID || contentConfig.objectUUID"
					:needParentFlow="needParentFlow"
					:formMode="requestType"
				>
				</PcParser>
			</article>
			<footer
				class="footer"
				v-if="statusConfig && statusConfig.showAction && actionConfig && Array.isArray(actionConfig)"
			>
				<el-button
					v-for="(btn, bIndex) in actionConfig"
					:key="bIndex"
					:type="btn.buttonType"
					@click="onButton(btn)"
				>
					{{ btn.text }}
				</el-button>
			</footer>
		</template>
		<el-empty description="暂无数据" v-else></el-empty>
	</div>
</template>

<script>
import PcParser from '@/custom-component/form/newParser/PcParser';
import { transFieldsUUid, dataInterface } from '@/apis/data/index';
import { getComponentById, getQueryValue } from '@/utils/tools';
import eventBus from '@/plugins/eventBus';
import { mapState } from 'vuex';
import { Empty } from 'element-ui';
import databaseTriggerMixin from '@/custom-component/databaseTriggerMixin';

export default {
	name: 'CommonFormContainer',
  mixins: [databaseTriggerMixin],
	props: {
		element: {
			type: Object,
			required: true,
			default: () => {}
		},
		// 是否为分组
		isGroup: {
			type: Boolean,
		},
		// 当前分组的组件数据
		groupComponents: {
			type: Array,
			default: () => []
		}
	},
	components: {
		PcParser,
		'el-empty': Empty
	},
	data() {
		return {
			// 表单配置数据
			formDesignData: null,
			displayData: {},
			// 流程中展示字段
      editFields:{},
			// 表单是否disable
			formDisabled: false,
			// 流程
			needFlow: false,
			needParentFlow: false,
			// 是否可以渲染表单
			canInitForm: false
		};
	},
	computed: {
		...mapState([
			'componentData',
			'_PageCustomStatus',
			'_APPCustomStatus'
		]),
		// 内容配置
		contentConfig() {
			return this.element && this.element.contentConfig;
		},
		// 操作配置
		actionConfig() {
			return this.element && this.element.actionConfig;
		},
		// 组件属性配置
		statusConfig() {
			return this.element && this.element.statusConfig;
		},
		// 请求类型(待追加)
		requestType() {
			const types = {
				createData: 'add',
				dataInfo: 'detail'
			};
			return types[this.element?.sourceConfig?.[0].type || 'add'];
		},
		// 更新规则
		updateRulesConfig() {
			return this.element?.updateRulesConfig || {
				pattern: '',
				rules: []
			}
		}
	},
	watch: {
		contentConfig: {
			handler() {
				if (!this.contentConfig || !this.contentConfig.objectUUID) return;
				this.initFormDesign(this.contentConfig);
			},
			deep: true,
			immediate: true
		}
	},
	mounted() {
		this.$nextTick(() => {
			// 监听请求
			// 配置关联参数的容器才需要监听
			const databaseTrigger = {
				[this.$elementId]: data => {
					if (data?.parentId !== this.element.id) return false;
					if (!this.contentConfig?.objectUUID) return;
					this.initFormDesign(this.contentConfig);
				}
				};
				eventBus.$on('databaseTrigger', databaseTrigger[this.$elementId]);
		});
	},
	methods: {
		/**
		 * @desc: 表单提交
		 * @param {Object} formData 自定义表单中提交的数据
		 */
		onFormSubmit(formData) {
			const { actionConfig } = this.element;
			const btnConfig = actionConfig.find((ele) => ele.key === 'confirm');
			if (!btnConfig || !btnConfig.effect) {
				this.statusConfig.isShow = false;
				return;
			}
			// 附加作用一般是发送请求
			if (btnConfig.effectType === 'postData') {
				// let data_id = '';
				let params = {};
				// 发送请求 整理参数
				const param = this.initParams().param;
				params = {
					__method_name__: btnConfig.postType,
					object_uuid: btnConfig.objectUUID,
					...param,
					// 表单的字段
					...formData
				};
				this.$loading();
				// 发送请求
				dataInterface(params)
					.then((res) => {
						this.$loading().close();
						if (res.status === 200 && res.data.code === 200) {
							this.beforeClose();
						}
					})
					.catch((err) => {
						console.log(err);
						this.$loading().close();
					});
			}
		},
		/**
		 * @desc: 初始化表单
		 */
		initFormDesign(contentConfig) {
			if (!contentConfig) return;
			this.loading = true;
			this.formDesignData = null;
			transFieldsUUid(contentConfig.objectUUID, contentConfig.formUUID).then((res) => {
					if (res.data.code == 200) {
						const { sourceConfig } = this.element;
						if (!sourceConfig || !Array.isArray(sourceConfig) || !sourceConfig.length) return;
						const { type } = sourceConfig[0];
						this.formDisabled = type === 'dataInfo';
						this.needFlow = !!res.data.data.need_flow;
						this.needParentFlow = !!res.data.data.need_parent_flow;
						// 编辑或详情
						if (type === 'updateData' || type === 'dataInfo') {
							// 编辑
							// 获取已经有的数据进行绑定
							let config = res.data.data;
							let ruuids = [];
							for (let i = 0; i < config.fields.length; i++) {
								let item = config.fields[i];
								if (item.__config__.tagIcon == 'form') {
									ruuids.push({ relationship_uuid: item.__vModel__ });
								}
							}
							const param = this.initParams().param;
							dataInterface({
								...param,
								// search,
								object_uuid: contentConfig.objectUUID,
								view_uuid:type === 'dataInfo' ? this.contentConfig.viewUUID : '',
								ruuids,
								__method_name__: 'dataInfo'
							}).then((res1) => {
								let data = res1.data.data;
								this.formDesignData = res.data.data;
								this.excelFormData = {
									[contentConfig.objectUUID]: JSON.parse(JSON.stringify(data))
								};
								this.excelMetaData = res1?.data?.metadata ? JSON.parse(JSON.stringify(res1.data.metadata)) : {};
								this.displayData = data || {};
                this.editFields = data.editFields || {};
								this.loading = false;
								this.canInitForm = true;
							});
						} else {
							this.formDesignData = res.data.data;
							this.canInitForm = true;
							this.loading = false;
						}
					}
				})
				.catch((err) => {
					console.log(err);
					this.loading = false;
				});
		},
		/**
		 * @desc: 流程处理结束回调处理
		 */
		onEndFlow() {
			this.beforeClose();
			this.statusConfig.isShow = false;
		},
		/**
		 * @desc: 关闭前需要将组件的暴露数据清空
		 */
		beforeClose() {
			// 如果是对表格中的数据进行编辑需要对数据进行更新
			// 对于参照相关参数的需要进行获取
			// 更新规则 pattern: '' 默认规则, rules 强规则， compatible 兼容
			const { pattern = '', rules = [] } = this.updateRulesConfig;
			if (!pattern || pattern === 'compatible') {
				// 默认或兼容模式
				const sourceConfig = this.element.sourceConfig || [];
				for (let i = 0; i < sourceConfig.length; i++) {
					if (
						sourceConfig[i] &&
						!sourceConfig[i].originType &&
						sourceConfig[i].componentId
					) {
						eventBus.$emit('databaseTrigger', {
							action: 'dataList',
							componentId: sourceConfig[i].componentId,
							isInit: sourceConfig[i].componentId.indexOf('CommonTree-') > -1,
							isUpdate: true,
							output:
								sourceConfig[i].componentId.indexOf('CommonTabs-') > -1
									? this.getOutput(sourceConfig[i].componentId)
									: ''
						});
					}
				}
			}
			if (pattern === 'rules' || pattern === 'compatible') {
				rules.forEach(ele => {
					eventBus.$emit('databaseTrigger', {
						action: 'dataList',
						componentId: ele.componentId,
						isInit: ele.componentId.indexOf('CommonTree-') > -1,
						isUpdate: true,
						output: ele.componentId.indexOf('CommonTabs-') > -1 ? this.getOutput(ele.componentId) : ''
					});
				})
			}
		},
		/**
		 * @desc: 点击按钮
		 * @param {Object} btn 按钮对象
		 */
		onButton(btn) {
			if (btn.key === 'confirm') {
				// 确认
				this.onConfirm();
			} else if (btn.key === 'cancel') {
				// 取消
				console.log('取消');
			}
		},
		/**
		 * @desc: 确认按钮
		 */
		onConfirm() {
			const refString = `common-form-container${this.element.id}`;
			if (this.$refs && this.$refs[refString]) {
				this.$refs[refString].handleSubmit();
			}
		},
		/**
		 * @desc: 初始化参数
		 */
		initParams() {
			const { sourceConfig } = this.element;
			// 是否可以请求
			let canPost = true;
			if (!sourceConfig || !sourceConfig.length) return {
				canPost
			};
			let search = [];
			const param = {};
			for (let i = 0; i < sourceConfig.length;i++) {
				const ele = sourceConfig[i];
				let originComponent = getComponentById(this.componentData, ele.componentId);
				if (!originComponent && this.isGroup && this.groupComponents.length) {
					originComponent = getComponentById(this.groupComponents, ele.componentId);
				}
				if (ele.key !== 'search') {
					if (ele.originType === 'pageStatus') {
						const queryVal = this._PageCustomStatus[ele.statusCode] || this._APPCustomStatus[ele.statusCode]
						this.$set(param, ele.key, queryVal);
						if (ele.isRequired && !queryVal) {
							canPost = false;
							break;
						}
					} else if (ele.originType === 'url') {
						// url参数
						const queryVal = getQueryValue(ele.urlParamKey);
						this.$set(param, ele.key, queryVal);
						// 必填的不能为空，带ID 的不能为空
						if ((ele.isRequired && !queryVal) || (ele.urlParamKey && ele.key.indexOf('id') > -1 && !queryVal)) {
							canPost = false;
							break;
						}
					} else if (ele.isRequired && (!originComponent || !originComponent.resolveData)) {
						canPost = false;
						break;
					} else if (originComponent && originComponent.resolveData) {
						const queryVal = originComponent && originComponent.resolveData[ele.field];
						this.$set(param, ele.key, queryVal);
						if ((ele.isRequired && !queryVal) || (ele.urlParamKey && ele.key.indexOf('id') > -1 && !queryVal)) {
							canPost = false;
							break;
						}
					}
				} else {
					if (originComponent && originComponent.resolveData && Array.isArray(originComponent.resolveData)) {
						search = search.concat(originComponent.resolveData);
					}
				}
			}
			return { search, param, canPost};
		}
	},
};
</script>

<style lang="less" scoped>
.common-form-container {
	height: 100%;
	width: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	.common-form-main {
		flex: 1;
		width: 100%;
		overflow: hidden;
		overflow-y: auto;
	}
	.footer {
		text-align: center;
	}
	// 公共内容
	:deep(.type-label) {
		border-left: 3px solid #007fe0 !important;
		padding: 0 10px;
		line-height: 1;
		font-size: 14px;
		margin: 0 0 16px;
		font-weight: 600;
		text-align: left;
	}
}
</style>
