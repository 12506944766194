<template>
  <div class="statistical-page" v-loading="loading" v-if="statisticsList">
    <div class="header">
      <HeaderStatistics :totalList="totalList"></HeaderStatistics>
    </div>
    <div class="middle-content">
      <!-- 表单 -->
      <DoubleRing
        ref="form"
        :title="formTitle"
        :statisticsList="statisticsList"
      ></DoubleRing>
      <!-- 对象 -->
      <Object ref="object" :statisticsList="statisticsList"></Object>
      <!-- 字典 -->
      <Dictionaries
        ref="dictionaries"
        :statisticsList="statisticsList"
      ></Dictionaries>
    </div>
    <div class="bottom-content">
      <!-- 视图 -->
      <ViewsDoubleRing
        ref="views"
        :title="viewsTitle"
        :statisticsList="statisticsList"
      ></ViewsDoubleRing>
      <!-- 页面 -->
      <Pages
        ref="pages"
        :title="viewsTitle"
        :statisticsList="statisticsList"
      ></Pages>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { statistics } from "@/api/statistics";
import HeaderStatistics from "./components/HeaderStatistics.vue";
import DoubleRing from "./components/DoubleRing.vue";
import ViewsDoubleRing from "./components/ViewsDoubleRing.vue";
import Object from "./components/Object.vue";
import Dictionaries from "./components/Dictionaries.vue";
import Pages from "./components/Pages.vue";
export default {
  name: "StatisticalPage",
  components: {
    HeaderStatistics,
    DoubleRing,
    ViewsDoubleRing,
    Object,
    Dictionaries,
    Pages,
  },
  data() {
    return {
      statisticsList: {}, //接口统计数据
      formTitle: "表单",
      viewsTitle: "视图",
      loading: false,
      totalList: [
        //头部总数统计数据
        {
          id: 0,
          title: "关联关系",
          number: null,
          backgroundImage:
            "https://static.bimcc.com/openim/1666938540809D9D4A4FDA70D5DE4F27843C310CC390D.png",
        },
        {
          id: 1,
          title: "蓝图",
          number: null,
          backgroundImage:
            "https://static.bimcc.com/openim/16669386594410F1F7C06810982795EA85CA3403843D7.png",
        },
        {
          id: 2,
          title: "对象",
          number: null,
          backgroundImage:
            "https://static.bimcc.com/openim/1666938631905133C6F23F1F1C041202376AE75FB2C61.png",
        },
        {
          id: 3,
          title: "视图",
          number: null,
          backgroundImage:
            "https://static.bimcc.com/openim/1666938584786B5FC583B7FED7FA802722BEE736E0D96.png",
        },
      ],
      contrastList: [
        //对比统计数据
        {
          id: 0,
          name: "视图",
          backgroundColor: "blue",
          children: [
            {
              id: 1,
              name: "有权限视图",
              type: "hasPermission",
              number: null,
            },
            {
              id: 2,
              name: "非自动生成视图",
              type: "notAuto",
              number: null,
            },
          ],
        },
        {
          id: 3,
          name: "页面",
          backgroundColor: "pureWhite",
          children: [
            {
              id: 4,
              name: "全部页面",
              type: "all",
              number: null,
            },
            {
              id: 5,
              name: "有效页面",
              type: "valid",
              number: null,
            },
          ],
        },
      ],
    };
  },
  created() {},
  mounted() {
    this.getData();
    this.$nextTick(function () {
      // 图表自适应
      window.onresize = function () {
        echarts.init(document.getElementById("viewsRing")).resize();
        echarts.init(document.getElementById("dictionaries")).resize();
        echarts.init(document.getElementById("pages")).resize();
        echarts.init(document.getElementById("formRing")).resize();
        echarts.init(document.getElementById("object")).resize();
      };
      this.$refs.form.drawRing();
      this.$refs.views.drawRings();
      this.$refs.object.drawPie();
      this.$refs.dictionaries.histogram();
      this.$refs.pages.histograms();
    });
  },
  methods: {
    //获取所有数据
    getData() {
      this.loading = true;
      statistics().then((res) => {
        console.log(res)
        this.statisticsList = res.data.data;
        this.totalList[0].number = this.statisticsList.countRelatd
        this.totalList[1].number = this.statisticsList.countBluePrints
        this.totalList[2].number = this.statisticsList.allobject
        this.totalList[3].number = this.statisticsList.allViews
        this.getNumber(this.contrastList);
      });
      this.loading = false;
    },
    //给分类数据的子数组的属性赋值
    getNumber: function (data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].type && data[i].type === "hasPermission") {
          data[i].number = this.statisticsList.hasPermission;
        }
        if (data[i].type && data[i].type === "notAuto") {
          data[i].number = this.statisticsList.notAutoView;
        }
        if (data[i].type && data[i].type === "all") {
          data[i].number = this.statisticsList.allPage;
        }
        if (data[i].type && data[i].type === "valid") {
          data[i].number = this.statisticsList.validPage;
        }
        if (data[i].children && data[i].children.length) {
          this.getNumber(data[i].children);
        }
      }
      return data;
    },
  },
};
</script>

<style lang="less" scoped>
.statistical-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgb(242, 243, 245);
  .header {
    width: 100%;
    height: 20%;
  }
  .middle-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 37.5%;
  }
  .bottom-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 37.5%;
  }
}
</style>
