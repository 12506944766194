<template>
  <div>
    <Preview :modelData="modelData" @on-select="selectSelections" />
  </div>
</template>

<script>
import Preview from "@/mobile-components/components/bimPreview/index.vue";
import { getComponentById } from '@/utils/tools';
import { mapState } from 'vuex';
import eventBus from '@/plugins/eventBus';
export default {
  name: "",
  props: {
    element: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      modelData: [{model_id: '', dbids: []}]
    };
  },
  computed: {
    ...mapState([
      'componentData',
    ]),
  },
  components: {
    Preview,
  },
  created() {
    this.getValue()
  },
  watch: {
    element: {
      handler() {
        this.getValue()
      },
      deep: true
    }
  },
  mounted() {
    eventBus.$on('databaseTrigger', () => {
      this.getValue()
    });
  },
  methods: {
    selectSelections(dbids) {
      console.log(dbids, '点击构件回调')
    },
    getValue() {
      let value = ''
      let dbidsValue = []
      const modelMobileConfig = this.element.modelMobileConfig
      let originComponent = getComponentById(this.componentData, modelMobileConfig.componentId);
      if (originComponent?.resolveData) {
        value = Array.isArray(originComponent.resolveData) && originComponent.resolveData.length ? originComponent.resolveData[0][modelMobileConfig.model_id] : originComponent.resolveData[modelMobileConfig.model_id]
      }
      this.modelData[0].model_id = value
      let originDbidsComponent = getComponentById(this.componentData, modelMobileConfig.dbidsComponentId);
      if (originDbidsComponent?.resolveData) {
        dbidsValue = Array.isArray(originDbidsComponent.resolveData) && originDbidsComponent.resolveData.length ? originDbidsComponent.resolveData[0][modelMobileConfig.dbids] : originDbidsComponent.resolveData[modelMobileConfig.dbids]
      }
      this.modelData[0].dbids = dbidsValue
    }
  }
};
</script>

<style scoped></style>
