<!--
 * @LastEditTime: 2022-04-28 20:21:26
 * @Description: 元数据定义追踪图标组件
 * @FilePath: /dwh_front/src/components/metaSelect/MetaTraceIcon.vue
 * @Date: 2022-04-28 20:14:09
 * @Author: lisushuang
 * @LastEditors: lisushuang
-->
<template>
  <span>
    <i
      :class="iconName"
      style="font-size: 16px; color: #67c23a; cursor: pointer"
      @click.stop.prevent="goTrace"
    />
  </span>
</template>

<script>
export default {
  name: "MetaTraceIcon",
  props: {
    method: {
      type: String,
      default: "",
    },
    uuid: {
      type: String,
      default: "",
    },
    iconName: {
      type: String,
      default: "el-icon-postcard",
    },
  },
  methods: {
    /**
     * @description: 跳转元数据追踪
     */
    goTrace() {
      if (!this.method) {
        this.$message.error("非法的查询方式");
        return;
      }
      if (!this.uuid) {
        this.$message.error("错误的uuid参数");
        return;
      }
      this.$globalAction(this.method, { uuid: this.uuid });
    },
  },
};
</script>