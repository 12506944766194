<!--
    @name: widget-row
    @description：widget-row
    @author: ZengWei
    @date: 2022-03-25 09:40
-->
<template>
  <el-form
    ref="childForm"
    size="medium"
    :model="element"
    :class="{'form-parser':formData.tableStyle,'normal-parser': !formData.tableStyle}"
    :show-message="!formData.tableStyle"
    :label-width="formData.labelWidth + 'px'"
    :label-position="formData.labelPosition"
  >
    <div class="type-label" v-if="element.config.__config__.componentName">
      {{ element.config.__config__.componentName }}
    </div>
    <el-row
      :gutter="10"
      :type="element.config.type === 'flex' ? element.config.type : ''"
      :align="element.config.align ? element.config.align : 'middle'"
      :justify="element.config.justify ? element.config.justify : 'start'"
      style="position: relative"
    >
      <el-col
        v-for="(item, ii) in element.value"
        :key="item.config.__config__.formId"
        :span="item.config.__config__.span"
      >
        <el-form-item
          :prop="'value.' + ii + '.value'"
          :rules="item.validate"
          :label="showLabel(item)"
          :label-width="labelWidth(item)"
          class="no-padding"
        >
          <InputItem
            :element="item"
            :disabled="canEditField(item.config.__config__.formId)"
          >
          </InputItem>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
export default {
  name: "WidgetRow",
  components: {},
  props: ['element', 'disabled', 'displayData', 'formData', 'editFields'],
  data() {
    return {}
  },
  computed: {
    labelWidth(){
      return function(element){
        if(element.config.__ques__){
          return '0px'
        }
        const width = element.config.__config__.labelWidth
          ? element.config.__config__.labelWidth + 'px'
          : 120 + 'px'
        return width
      }
    },
    showLabel() {
      return function (item) {
        const config = item.config.__config__
        if (item.config.__ques__ || config.showLabel === undefined || config.showLabel === false)
          return "";
        return config.label;
      };
    },
    canEditField() {
      return function (formId) {
        const editFormId = Object.keys(this.editFields);
        if (editFormId.length > 0 && editFormId.includes(formId))
          return this.editFields[formId];
        return this.disabled;
      };
    },
    showHideField() {
      return function (config) {
        const formId = config.formId;
        const editFormId = Object.keys(this.editFields);
        if (editFormId.includes(formId)) return true;
        return config.addShow;
      };
    }
  },
  methods: {
    validateFormInput(){
      this.$refs.childForm.validate((valid)=>{
        if(!valid){
          throw new Error('数据验证失败');
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
