<template>
	<div class="scroll-block-container">
		<div class="scroll-block-body">
			<template v-if="tableData.length !== 0">
				<div
					v-for="(row, index) in activeRow"
					:key="index"
					class="block-com"
				>
					<transition name="scroll-list">
						<section
							class="block-item"
							:style="blockStyle"
							:key="row.key"
						>
							<div
								v-for="(item, index) in fieldForView"
								class="block"
								:style="{
									color: item.color,
									textAlign: item.align || 'left',
									...bodyStyle
								}"
								:key="item.uuid"
							>
								<span
									class="cell-span"
									:key="row[item.uuid]"
								>
									<!-- 图片 -->
									<template v-if="item.type === 2">
										<el-image
											v-if="Array.isArray(row[item.uuid])"
											:src="
												row[item.uuid] && row[item.uuid].length && row[item.uuid][0].url.toString().indexOf('http') > -1
													? row[item.uuid][0].url
													: defaultImage
											"
											:previewSrcList="[
												row[item.uuid].length ? row[item.uuid][0].url.toString().replace('_thumb', '') : defaultImage
											]"
											lazy
											fit="cover"
											alt="图片"
											class="image"
											:style="{
												height: (item.imgConfig.height || 60) + 'px',
												width: (item.imgConfig.height || 60) + 'px'
											}"
										></el-image>
										<!-- 链接 -->
										<el-image
											v-else
											:src="row[item.uuid] && row[item.uuid].indexOf('http') > -1 ? row[item.uuid] : defaultImage"
											:previewSrcList="[
												row[item.uuid] ? row[item.uuid].toString().replace('_thumb', '') : defaultImage
											]"
											lazy
											fit="cover"
											alt="图片"
											class="image"
											:style="{
												height: (item.imgConfig.height || 60) + 'px',
												width: (item.imgConfig.height || 60) + 'px'
											}"
										></el-image>
									</template>
									<!-- 状态 -->
									<span
										v-else-if="item.type === 3 && row[item.uuid]"
										:style="{
											color: filterStatus(row[item.uuid], item.statusOptions).color || item.color
										}"
									>
										{{ filterStatus(row[item.uuid], item.statusOptions).label }}
									</span>
									<!-- 时间 -->
									<template v-else-if="item.type === 4 && row[item.uuid]">{{
										filterTime(row[item.uuid], item.timeFormat, item.connector)
									}}</template>
									<!-- 富文本 -->
									<template v-else-if="item.type === 5 && row[item.uuid]">
										<div
											class="rich-text"
											v-html="row[item.uuid]"
										></div>
									</template>
									<!-- 富文本 -->
									<template v-else-if="item.type === 6 && row[item.uuid]">
										<a
											:href="row[item.uuid]"
											class="link"
											alt="链接"
										>
											{{ row[item.uuid] }}
										</a>
									</template>
									<!-- switch 开关 -->
									<template v-else-if="item.type === 7">
										<el-switch
											v-model="row[item.uuid]"
											@change="onSwitch(item, row[item.uuid], row)"
										></el-switch>
									</template>
									<!-- 常规显示 -->
									<template v-else>
										<span :style="index === 0 ? headerStyle : ''">
											{{ row[item.uuid] || '/' }}
										</span>
									</template>
								</span>
							</div>
						</section>
					</transition>
				</div>
			</template>
			<el-empty
				v-else
				description="暂无数据"
			></el-empty>
		</div>
	</div>
</template>

<script>
  import { dataInterface } from '@/apis/data/index';
  import eventBus from '@/plugins/eventBus';
  import { $, getComponentById, initParams, judgingArchiAPermission, removeEventBus } from '@/utils/tools';
  import { Empty, Image, Switch } from 'element-ui';
  import { mapState } from 'vuex';
  /**
   * @description 表格列表
   */
  export default {
    name: 'ScrollBlock',
    props: {
      element: {
        type: Object,
        required: true,
        default: () => {}
      },
      // 是否在组合内
      isGroup: {
        type: Boolean
      },
      // 组合内组件列表
      groupComponents: {
        type: Array,
        default: () => []
      },
      componentList: {
        default: null
      }
    },
    inject: ['EDITOR_pageUUID'],
    components: {
      'el-image': Image,
      'el-switch': Switch,
      'el-empty': Empty
    },
    data() {
      return {
        // 当前组件唯一Key
        boxId: `table-container-${new Date().getTime()}`,
        // 分页
        pager: {
          current_page: 1,
          per_page: 3,
          total: 0
        },
        activePage: 0,
        // 表格数据
        tableData: [],
        // 表格高度
        height: 250,
        // 配置数据
        metadata: [],
        loading: false,
        // 缓存的搜索条件
        search: [],
        // 周几
        weekDays: ['周天', '周一', '周二', '周三', '周四', '周五', '周六'],
        // 默认图片
        defaultImage: require('@/assets/images/logo1.png'),
        param: {},
        // 树形图
        treeProps: {},
        rowKey: '',
        // 表格弹窗
        showSheet: false,
        // 表格数据
        sheetFormData: null,
        exportConfig: null,
        // 操作类型：add - 新增，edit - 编辑，info - 详情，export - 导出
        spreadsheetType: 'export',
        exportViewParams: {},
        exportViewSearch: [],
        // 使用后端返回配置导出的导出配置
        exportWay: '', // 导出配置 eeConfig 后端配置， '' 自定义
        eeExportTemplate: '',
        eeObjectUUID: '',
        eeViewUUID: '',
        fieldForView: []
      };
    },
    created() {
      if (this.database && !this.hasComponentParam()) {
        const {
          search = [],
          param = {},
          canPost
        } = initParams(
          this.element?.database?.paramsConfig || [],
          this.isGroup,
          this.componentList || this.componentData,
          this.groupComponents
        );
        if (!canPost) return;
        this.getList(this.database, search, param);
      }
      this.metadata = this.element.metadata || [];
    },
    computed: {
      ...mapState(['componentData']),
      // 当前活动行
      activeRow() {
        // 如果循环滚动
        if (this.statusConfig.loopScroll) {
          let activePage = this.activePage % (this.tableData.length - 1);
          if (this.tableData.length - 1 === 0) {
            activePage = 0;
          }
          const data = [];
          let temp = this.linkedList[activePage];
          // 更新key才能触发动画
          temp.key = Math.random() * 10000 + '' + temp.index;
          data.push(temp);
          for (let i = 0; i < Number(this.statusConfig.rowNumber) - 1; i++) {
            temp = temp.next();
            temp.key = Math.random() * 10000 + '' + temp.index;
            data.push(temp);
          }
          return data;
        } else {
          let activePage = this.activePage % (this.tableData.length - Number(this.statusConfig.rowNumber));
          if (this.tableData.length - Number(this.statusConfig.rowNumber) <= 0) {
            activePage = 0;
          }
          const data = this.tableData
            .slice(activePage, activePage + Number(this.statusConfig.rowNumber))
            .map((el, index) => {
              // 更新key才能触发动画
              el.key = Math.random() * 10000 + '' + index;
              return el;
            });
          return data;
        }
      },
      // 表格链表
      linkedList() {
        const data = [];
        let temp = null;
        let header = null;
        this.tableData.map((el, index) => {
          if (!temp) {
            header = el;
          } else {
            temp.next = () => el;
            el.pre = () => temp;
          }
          if (index === this.tableData.length - 1) {
            header.pre = () => el;
            el.next = () => header;
          }
          temp = el;
          data.push(el);
        });
        return data;
      },
      // 数据仓库配置
      database() {
        return this.element && this.element.database;
      },
      // 操作类型
      actionConfig() {
        return this.element && this.element.actionConfig;
      },
      // 子表操作配置
      nestingActionConfig() {
        return this.element && this.element.nestingActionConfig;
      },
      // 配置
      statusConfig() {
        return this.element && this.element.statusConfig;
      },
      // 字段配置
      fieldConfig() {
        const configs = this.element && this.element.fieldConfig;
        if (!configs) return [];
        if (!configs && !configs.length) return this.metadata || [];
        const result = [];
        configs.forEach(ele => {
          if (ele && ele.show) {
            result.push(ele);
          }
        });
        return result;
      },
      // 字表字段配置
      nestingFieldConfig() {
        const configs = this.element && this.element.nestingFieldConfig;
        if (!configs) return [];
        if (!configs && !configs.length) return this.metadata || [];
        const result = [];
        configs.forEach(ele => {
          if (ele && ele.show) {
            result.push(ele);
          }
        });
        return result;
      },
      headerStyle() {
        return {
          color: this.statusConfig.titleColor,
          fontSize: this.statusConfig.titleFontSize
        };
      },
      bodyStyle() {
        return {
          'line-height': 1,
          height: this.statusConfig.bodyHieght,
          background: this.statusConfig.bodyTransparent ? 'transparent' : this.statusConfig.bodyBackGround,
          fontSize: this.statusConfig.bodyFontSize
        };
      },
      blockStyle() {
        return {
          backgroundImage: `url(${this.statusConfig.backgroundImage})` || '',
          backgroundRepeat: 'no-repeat',
          backgroundSize: this.statusConfig.objectFit || ''
        };
      }
    },
    watch: {
      // 为了触发配置时候字段排序更新
      fieldConfig: {
        handler() {
          this.fieldForView = [];
          setTimeout(() => {
            this.fieldForView = this.fieldConfig || [];
          }, 0);
        },
        deep: true,
        immediate: true
      }
    },
    mounted() {
      this.$nextTick(() => {
        const box = $(`#${this.boxId}`);
        if (box && box.offsetHeight) {
          this.height = box.offsetHeight - 42;
        }
        this.initTimer(true);
      });
      // 监听请求
      // 配置关联参数的容器才需要监听
      const databseTrigger = {
        [this.element.id]: data => {
          if (data.parentId && data.parentId !== this.element.id) return false;
          // 配置时候触发请求
          if (data.componentId === this.element.id && data.isInit) {
            const {
              search = [],
              param = {},
              canPost
            } = initParams(
              this.element?.database?.paramsConfig || [],
              this.isGroup,
              this.componentList || this.componentData,
              this.groupComponents
            );
            // console.log("配置时候触发请求-------", canPost, search, param);
            if (!canPost) return;
            this.getList(this.element.database, search, param);
            return;
          }
          // 点击操作时候不更新数据
          if (data.noUpdate) return;
          const { paramsConfig } = this.element.database;
          if (!paramsConfig || !paramsConfig.length) return;
          // 以下步骤是为了避免有多个来源的search需要进行differ 避免检索结果错误情况
          let {
            search = [],
            param = {},
            canPost
          } = initParams(
            this.element?.database?.paramsConfig || [],
            this.isGroup,
            this.componentList || this.componentData,
            this.groupComponents
          );
          // console.log("以下步骤是为了避免有多个来源的search需要进行differ-------", canPost, search, param);
          if (!canPost) return;
          let isTarget = false;
          paramsConfig.forEach(ele => {
            if (ele.componentId === data.componentId) {
              isTarget = true;
            }
          });
          if (!isTarget && !data.isUpdate) return;
          this.param = param;
          this.getList(this.element.database, search, param);
        }
      }
      eventBus.$on('databaseTrigger', databseTrigger[this.element.id]);
    },
    methods: {
      initTimer(flag) {
        const timerHandle = () => {
          this.times += 1;
          if (this.times >= (this.statusConfig.duration || 3) * 60) {
            this.times = 0;
            this.activePage += 1;
          }
          requestAnimationFrame(timerHandle);
        };
        if (flag) {
          this.times = 0;
          this.timer = requestAnimationFrame(timerHandle);
        } else if (this.timer) {
          cancelAnimationFrame(this.timer);
        }
      },
      // 判断架构及权限
      judgingArchiAPermission,
      /**
       * @desc: 根据配置逻辑判断操作是否显示
       * @param {Object} actionConfig
       */
      logicAction(actionConfig, row) {
        if (!actionConfig.useDepend || !actionConfig || !actionConfig.dependLogic || !actionConfig.dependLogic.length) {
          return true;
        }
        const dependLogic = actionConfig.dependLogic;
        const logicList = [];
        for (let i = 0, len = dependLogic.length; i < len; i++) {
          const { field, fieldAttr, fieldLogic, itemLogic, value, valueType } = dependLogic[i];
          let logicValue = null;
          if (fieldAttr === 'length') {
            logicValue = row[field].length;
          } else {
            logicValue = row[field];
          }
          // 当前项判断条件
          // 字段关系 大于>  小于 < 等于 === 大于等于>=  小于等于 <= 不等于 !=
          let itemBoolean = true;
          if (fieldLogic === '===') {
            if (valueType === 'string') {
              itemBoolean = logicValue === value;
            } else if (valueType === 'number') {
              itemBoolean = +logicValue === +value;
            } else if (valueType === 'date') {
              itemBoolean = new Date(logicValue).getTime() === value;
            }
          } else if (fieldLogic === '!=') {
            if (valueType === 'string') {
              itemBoolean = logicValue !== value;
            } else if (valueType === 'number') {
              itemBoolean = logicValue !== +value;
            } else if (valueType === 'date') {
              itemBoolean = new Date(logicValue).getTime() !== value;
            }
          } else if (fieldLogic === '>') {
            if (valueType === 'number') {
              itemBoolean = logicValue > +value;
            } else if (valueType === 'date') {
              itemBoolean = new Date(logicValue).getTime() > value;
            }
          } else if (fieldLogic === '<') {
            if (valueType === 'number') {
              itemBoolean = logicValue < +value;
            } else if (valueType === 'date') {
              itemBoolean = new Date(logicValue).getTime() < value;
            }
          } else if (fieldLogic === '<=') {
            if (valueType === 'number') {
              itemBoolean = logicValue <= +value;
            } else if (valueType === 'date') {
              itemBoolean = new Date(logicValue).getTime() <= value;
            }
          } else if (fieldLogic === '>=') {
            if (valueType === 'number') {
              itemBoolean = logicValue >= +value;
            } else if (valueType === 'date') {
              itemBoolean = new Date(logicValue).getTime() >= value;
            }
          }
          logicList.push({ itemLogic, itemBoolean });
        }
        if (logicList.length === 1) {
          return logicList[0].itemBoolean;
        }
        // 循环逻辑关系
        let prevLogicBoolean = null;
        for (let j = 1; j < logicList.length; j++) {
          const prev = logicList[j - 1];
          const prevItemLogic = prev.itemLogic;
          if (prevItemLogic === 'and') {
            if (j === 1) {
              prevLogicBoolean = prev.itemBoolean && logicList[j].itemBoolean;
            } else {
              prevLogicBoolean = prevLogicBoolean && logicList[j].itemBoolean;
            }
            if (!prevLogicBoolean) {
              return false;
            }
          } else if (prevItemLogic === 'or') {
            if (j === 1) {
              prevLogicBoolean = prev.itemBoolean || logicList[j].itemBoolean;
            } else {
              prevLogicBoolean = prevLogicBoolean || logicList[j].itemBoolean;
            }
            if (!prevLogicBoolean) {
              return false;
            }
          }
        }
        return true;
      },
      /**
       * @desc: 判断是否存在依赖其他组件的取值
       */
      hasComponentParam() {
        if (!this.database.paramsConfig || !this.database.paramsConfig.length) {
          return false;
        }
        for (let i = 0; i < this.database.paramsConfig.length; i++) {
          const { componentId = '', key = '', sourceType = '' } = this.database.paramsConfig[i];
          if ((key !== 'search' || !componentId.includes('CommonForm')) && sourceType !== 'url') {
            // componentId.includes('CommonTableContainer')兼容跨页请求的动态判定
            // 补充不同架构问题
            if (!componentId.includes('CommonTableContainer') && this.paramsSameArchi(componentId)) {
              return true;
            }
          }
        }
        return false;
      },
      /**
       * @desc: 判断依赖的参数是否在当前架构下启用(@凌志华树形图架构限制)
       * @param {String} componentId
       * @return {Boolean}
       */
      paramsSameArchi(componentId) {
        let comp = getComponentById(this.componentList || this.componentData, componentId);
        if (!comp && this.isGroup && this.groupComponents.length) {
          comp = getComponentById(this.groupComponents, componentId);
        }
        if (!comp) return false;
        const targetArchi = this.$GetTargetArchi('archiType');
        if (comp && comp.archiLimit && comp.archiLimit.length && comp.archiLimit.includes(targetArchi)) {
          return true;
        }
        return false;
      },
      /**
       * @desc: 获取行key
       */
      getRowKey(row) {
        return row[this.rowKey];
      },
      /**
       * @desc: 获取渲染列表
       * @param {Object} database 数据配置对象
       * @param {Array} search 搜索
       */
      getList(database, search = [], params = {}) {
        // console.log(database, search, params, '88888888888')
        this.search = Array.isArray(search) ? search : [];
        this.tableData = [];
        if (!this.validDatabase(database)) return;
        this.loading = true;
        const paramsObj = {
          ...params
        };
        // console.log('获取渲染列表______表格容器', params, paramsObj);
        // 配置
        let __method_name__ = 'dataList';
        const mapping = database.mapping;
        let configObj = null;
        if (mapping === 'object') {
          configObj = {
            __method_name__,
            object_uuid: database.objectData.uuid,
            view_uuid: database.viewData.uuid,
            ...paramsObj,
            search
          };
        } else if (mapping === 'relation') {
          __method_name__ = 'relationList';
          configObj = {
            __method_name__: 'relationList',
            object_uuid: database.objectData.uuid,
            relationship_uuid: database.relationData.uuid,
            ...paramsObj,
            search
          };
        }
        // 获取表格数据
        dataInterface(configObj)
          .then(res => {
            if (res && res.status === 200) {
              if (!this.metadata || !this.metadata.length) {
                this.metadata = res.data.metadata;
              }
              let tableData = [];
              if (__method_name__ === 'dataList' || __method_name__ === 'relationList') {
                // 列表
                // 列表数据
                tableData = this.getListFromRes(res, true) || [];
              }
              for (let i = 0; i < this.fieldConfig.length; i++) {
                const item = this.fieldConfig[i];
                if (item.type === 7) {
                  // switch 编辑
                  tableData = tableData.map(ele => {
                    if (item.switchOptions.type === 'number') {
                      // 数字
                      ele[item.uuid] = +ele[item.uuid] === +item.switchOptions.trueValue;
                    } else if (item.switchOptions.type === 'boolean') {
                      if (ele[item.uuid] === 'true') {
                        ele[item.uuid] = true;
                      } else if (ele[item.uuid] === 'false') {
                        ele[item.uuid] = false;
                      }
                    } else if (item.switchOptions.type === 'string') {
                      ele[item.uuid] = ele[item.uuid] === item.switchOptions.trueValue;
                    }
                    return ele;
                  });
                }
              }
              // 树形表格
              if (this.statusConfig.isTreeTable) {
                let children = '';
                for (let key in this.metadata) {
                  if (this.metadata[key] === this.statusConfig.children) {
                    children = key;
                  }
                  this.rowKey = this.statusConfig.idUUID || '';
                  if (!this.rowKey) {
                    for (let key in this.metadata) {
                      if (this.metadata[key] === 'id') {
                        this.rowKey = key;
                      }
                    }
                  }
                }
                tableData = tableData.map(dataItem => {
                  return {
                    ...dataItem,
                    children: dataItem[children] || []
                  };
                });
                this.treeProps = {
                  children,
                  hasChildren: 'hasChildren'
                };
              }
              // 嵌套表格
              if (this.statusConfig.isNesting) {
                this.rowKey = '';
                let children = '';
                for (let key in this.metadata) {
                  if (this.metadata[key] === this.statusConfig.nestingKey) {
                    children = key;
                  }
                  // if (!this.rowKey && this.metadata[key] === 'id') {
                  // 	this.rowKey = key;
                  // }
                  this.rowKey = this.statusConfig.idUUID || '';
                  if (!this.rowKey) {
                    this.rowKey = this.statusConfig.idUUID || '';
                    if (!this.rowKey) {
                      for (let key in this.metadata) {
                        if (this.metadata[key] === 'id') {
                          this.rowKey = key;
                        }
                      }
                    }
                  }
                }
                tableData = tableData.map(dataItem => {
                  return {
                    ...dataItem,
                    children: dataItem[children] || []
                  };
                });
                // 字段处理
                for (let i = 0; i < this.nestingFieldConfig.length; i++) {
                  const item = this.nestingFieldConfig[i];
                  if (item.type === 7) {
                    // switch 编辑
                    tableData = tableData.map(ele => {
                      const childrenTable = ele[children] || [];
                      return {
                        ...ele,
                        [children]: childrenTable.map(child => {
                          if (item.switchOptions.type === 'number') {
                            // 数字
                            child[item.uuid] = +child[item.uuid] === +item.switchOptions.trueValue;
                          } else if (item.switchOptions.type === 'boolean') {
                            if (child[item.uuid] === 'true') {
                              child[item.uuid] = true;
                            } else if (child[item.uuid] === 'false') {
                              child[item.uuid] = false;
                            }
                          } else if (item.switchOptions.type === 'string') {
                            child[item.uuid] = child[item.uuid] === item.switchOptions.trueValue;
                          }
                          return {
                            ...child
                          };
                        })
                      };
                    });
                  }
                }
              }
              this.tableData = tableData.map((el, index) => {
                el.index = index + 1;
                return el;
              });
              // console.log(this.tableData, '--000tableData00', this.rowKey);
              // 完全数据 暂时保存
              this.fullData = res.data.data;
              // 保存容器数据
              this.$store.commit('modifyComponent', {
                component: {
                  ...this.element,
                  containerData: this.tableData,
                  fullData: this.fullData,
                  metadata: res.data.metadata,
                  database: {
                    ...this.element.database,
                    fieldList: this.getFieldList(res.data.metadata)
                  }
                },
                containerId: null,
                isModify: true,
                pageUUID: this.EDITOR_pageUUID
              });
            }
            this.loading = false;
          })
          .catch(err => {
            console.log(err);
            this.loading = false;
          });
      },
      /**
       * @desc: 获取字段列表
       * @param {Object} obj metadata对象
       */
      getFieldList(obj) {
        const arr = [];
        if (obj && typeof obj === 'object') {
          for (let key in obj) {
            arr.push({
              name: obj[key],
              uuid: key
            });
          }
        }
        return arr;
      },
      /**
       * @desc: 切换switch操作状态
       * @param {Object} item 配置
       * @param {Boolean} data 当前切换的布尔值
       * @param {Object} row 当前行
       * @param {Boolean} isNesting 是否为子表操作
       */
      onSwitch(item, data, row, isNesting) {
        this.loading = true;
        let idUUID = this.statusConfig.idUUID || '';
        if (!idUUID) {
          for (let key in this.metadata) {
            if (this.metadata[key] === 'id') {
              idUUID = key;
            }
          }
        }
        // 需要将boolean还原为后端允许的值
        let object_uuid = this.element?.database?.objectData?.uuid;
        if (isNesting && this.statusConfig?.nestingObjectUUID) {
          object_uuid = this.statusConfig?.nestingObjectUUID;
        }
        if (!object_uuid) {
          this.loading = false;
          this.$message.error('操作失败！');
          return false;
        }
        const params = {
          __method_name__: 'updateData',
          object_uuid,
          data_id: row[idUUID],
          ...row
        };
        const value = data ? item.switchOptions.trueValue : item.switchOptions.falseValue;
        this.$set(params, item.uuid, value);
        dataInterface(params)
          .then(() => {
            this.loading = false;
            this.onPage(this.pager.current_page);
          })
          .catch(err => {
            console.log(err);
            this.loading = false;
          });
      },
      /**
       * @desc: 选择表格
       * @param {Array} selectList
       */
      handleSelectionChange(selectList) {
        this.element.cacheData = selectList || [];
      },
      /**
       * @desc: 格式化比对收缩内容
       * @param {Array} search 当前组件中的搜索内容
       * @param {Array} outputSearch 外部传入的搜索项目
       */
      formatSearch(search, outputSearch) {
        if (!search.length) return outputSearch;
        let result = search || [];
        for (let i = 0; i < outputSearch.length; i++) {
          const item = outputSearch[i];
          const index = search.findIndex(ele => ele.field_uuid === item.field_uuid);
          if (index === -1) {
            result.push(item);
          } else {
            result.splice(index, 1, item);
          }
        }
        return result;
      },
      /**
       * @desc: 验证
       * @param {Object} database 数据仓库的绑定
       */
      validDatabase(database) {
        if (!database || typeof database !== 'object') return false;
        if (!database.objectData) return false;
        if (!database.viewData && !database.relationData) return false;
        return true;
      },
      /**
       * @desc: 根据请求返回获取列表结构
       * @param {Object} res 请求返回的数据
       * @param {Boolean} withPager 是否需要重置pager
       */
      getListFromRes(res, withPager = true) {
        if (res.data && Array.isArray(res.data)) {
          if (withPager) {
            this.pager = res;
          }
          return res.data;
        }
        if (res.data && typeof res === 'object') {
          return this.getListFromRes(res.data, withPager);
        }
        return [];
      },
      /**
       * @desc: 点击行展开详情操作（@廖总）
       * @param {Object} row 行数据
       * @param {Object} column
       */
      onRowClick(row) {
        if (!this.actionConfig || !this.actionConfig.detail || !this.statusConfig.rowShowDetails) {
          return;
        }
        const { detail } = this.actionConfig;
        if (!detail || !detail.eventList || !detail.eventList.length) return;
        const comEvents = detail.eventList || [];
        for (let i = 0; i < comEvents.length; i++) {
          const { pattern, eventList = [], specialEventList = [] } = comEvents[i];
          if (pattern === undefined) {
            this.onRowClickItem(comEvents[i], row);
            break;
          }
          const result = pattern === 'special' ? specialEventList : eventList;
          result.forEach(ele => {
            this.onRowClickItem(ele, row);
          });
        }
      },
      onRowClickItem(eventItem, row) {
        const { effects } = eventItem;
        if (effects && effects.length) {
          for (let j = 0; j < effects.length; j++) {
            const effectItem = effects[j];
            const { actions = [], targets = [] } = effectItem;
            if (actions.length && targets.length && actions.includes('show')) {
              targets.forEach(ele => {
                if (ele.toString().indexOf('CommonDialog-') === 0) {
                  this.element.resolveData = row;
                  this.$store.commit('triggerEvents', {
                    config: {
                      ...eventItem,
                      ...effectItem,
                      actions: ['hidden']
                    },
                    element: this.element
                  });
                  this.$nextTick(() => {
                    this.$store.commit('triggerEvents', {
                      config: {
                        ...eventItem,
                        ...effectItem
                      },
                      element: this.element
                    });
                    this.$nextTick(() => {
                      const interval = setInterval(() => {
                        const drawer = document.querySelector('.common-drawer');
                        if (drawer) {
                          clearInterval(interval);
                          const drawerBox = drawer.parentNode?.parentNode;
                          if (drawerBox) {
                            drawerBox.classList.add('Editor-drawer__wrapper');
                          }
                        }
                      }, 100);
                    });
                  });
                }
              });
            }
          }
        }
      },
      /**
       * @desc: 操作
       * @param {String} action 操作
       * @param {Object} output 输出的数据
       */
      onAction(action, output, e, type, actionConfig) {
        e.preventDefault();
        e.stopPropagation();
        // 是否为删除
        if (action === 'delete') {
          this.doDelete(output);
          return;
        }
        // 为兼容自定义export，多个导出
        if (action === 'export') {
          // 行内导出
          const exportConfig = this.element.exportConfig;
          this.exportConfig = exportConfig;
          this.spreadsheetType = 'export';
          this.exportWay = exportConfig.exportWay || '';
          this.doExport(exportConfig, output);
          return;
        }
        // 其他导出
        if (action.toString().indexOf('export') === 0) {
          // 行内导出
          const exportConfig = actionConfig[action].eventList[0];
          exportConfig.exportType = 'targetRow';
          this.exportConfig = exportConfig;
          this.spreadsheetType = 'export';
          this.exportWay = exportConfig.exportWay || '';
          this.doExport(exportConfig, output);
        }
        this.element.resolveData = output;
        // 详情
        if (action === 'detail' && this.statusConfig?.rowShowDetails) {
          this.onRowClick(output);
          return;
        }
        // 关闭所有弹窗及抽屉
        this.removeModel();
        // 事件
        const actionKey = type === 'nesting' ? 'nestingActionConfig' : 'actionConfig';
        const comEvents = this.element[actionKey][action].eventList || [];
        for (let i = 0; i < comEvents.length; i++) {
          const { pattern, eventList = [], specialEventList = [] } = comEvents[i];
          if (pattern === undefined) {
            this.onActionItem(comEvents[i], output);
            break;
          }
          const result = pattern === 'special' ? specialEventList : eventList;
          result.forEach(ele => {
            this.onActionItem(ele, output);
          });
        }
        this.$nextTick(() => {
          eventBus.$emit('databaseTrigger', {
            componentId: this.element.id,
            action,
            output,
            isInit: false,
            noUpdate: true
          });
        });
        return false;
      },
      onActionItem(ele) {
        if (ele.key === 'click') {
          // 跳转页面
          if (ele.actionType === 'jumpPage') {
            if (ele.linkType === 'projectPage') {
              const query = {};
              ele.queryList.forEach(queryItem => {
                const component = getComponentById(this.componentList || this.componentData, queryItem.componentId);
                this.$set(query, queryItem.key, component.resolveData[queryItem.feild]);
              });
            } else if (ele.linkType === 'outPage') {
              window.open(ele.linkUrl);
            }
          } else if (ele.actionType === 'eeAction') {
            // 触发后端事件
            console.log(ele, '000000');
            // 搜集参数
          } else {
            ele.effects.forEach(effect => {
              this.$store.commit('triggerEvents', {
                config: {
                  ...ele,
                  ...effect
                },
                element: this.element
              });
            });
          }
        }
      },
      /**
       * @desc: 移除所有显示弹窗
       */
      removeModel() {
        if (!this.actionConfig || !this.actionConfig.detail) return;
        const { detail } = this.actionConfig;
        if (!detail || !detail.eventList || !detail.eventList.length) return;
        const comEvents = detail.eventList || [];
        for (let i = 0; i < comEvents.length; i++) {
          const { pattern, eventList = [], specialEventList = [] } = comEvents[i];
          if (pattern === undefined) {
            this.onRemoveItem(comEvents[i]);
            break;
          }
          const result = pattern === 'special' ? specialEventList : eventList;
          result.forEach(ele => {
            this.onRemoveItem(ele);
          });
        }
      },
      onRemoveItem(eventItem) {
        const { effects } = eventItem;
        if (effects && effects.length) {
          for (let j = 0; j < effects.length; j++) {
            const effectItem = effects[j];
            const { actions = [], targets = [] } = effectItem;
            if (actions.length && targets.length && actions.includes('show')) {
              targets.forEach(ele => {
                if (ele.toString().indexOf('CommonDialog-') === 0) {
                  // console.log(ele, '移除， 888888')
                  this.$store.commit('triggerEvents', {
                    config: {
                      ...eventItem,
                      ...effectItem,
                      actions: ['hidden']
                    },
                    element: this.element
                  });
                }
              });
            }
          }
        }
      },
      /**
       * @desc: 获取状态值
       * @param {any普通类型} value 值
       * @param {Array} statusOptions 状态选项
       * @return {Object} 用于状态显示的对象  label value color
       */
      filterStatus(value, statusOptions = []) {
        if (!statusOptions || !Array.isArray(statusOptions)) {
          return {
            label: value
          };
        }
        for (let i = 0; i < statusOptions.length; i++) {
          if ((isNaN(value) && statusOptions[i].value === value) || +statusOptions[i].value === +value) {
            return statusOptions[i];
          }
        }
        return {
          label: value
        };
      },
      /**
       * @desc: 格式化时间
       * @param {String/Object/Number} value 时间值 兼容时间戳、时间字符串、时间对象
       * @param {String} timeFormat 格式化
       * @param {String} connector 连接符
       */
      filterTime(value, timeFormat, connector = '') {
        if (!value) return '';
        // 时间格式表单无法统一不做校验
        // const reg = /^(\d{4})(-|\/)(\d{2})(-|\/)(\d{2})/;
        // if ((isNaN(+value) && !reg.test(value))) return '';
        let dateObj = null;
        if (value instanceof Date) {
          dateObj = value;
        } else if (isNaN(value)) {
          dateObj = new Date(value);
        } else {
          dateObj = new Date(value * 1000);
        }
        // @凌志华 后端返回的无效时间显示为 '' (2022-02-15 15:51:51)
        if (!(dateObj instanceof Date) || dateObj.toString() === 'Invalid Date') return '';
        const year = dateObj.getFullYear();
        // 无效的时间
        if (year === 1970) return '';
        let month = this.addZero(dateObj.getMonth() + 1);
        const day = this.addZero(dateObj.getDate());
        const hour = this.addZero(dateObj.getHours());
        const minute = this.addZero(dateObj.getMinutes());
        const second = this.addZero(dateObj.getSeconds());
        let connectorStr = '-';
        if (connector === '/') {
          connectorStr = '/';
        } else if (connector === 'cn') {
          connectorStr = connector;
        }
        const defaultStr = `${year}${connectorStr === 'cn' ? '年' : connectorStr}${month}${
          connectorStr === 'cn' ? '月' : connectorStr
        }${day}${connectorStr === 'cn' ? '日' : ''} ${hour}${connectorStr === 'cn' ? '时' : ':'}${minute}${
          connectorStr === 'cn' ? '分' : ':'
        }${second}${connectorStr === 'cn' ? '秒' : ''}`;
        switch (timeFormat) {
          case 'YMDhms':
            return defaultStr;
          case 'YMDhm':
            return `${year}${connectorStr === 'cn' ? '年' : connectorStr}${month}${
              connectorStr === 'cn' ? '月' : connectorStr
            }${day}${connectorStr === 'cn' ? '日' : ''} ${hour}${connectorStr === 'cn' ? '时' : ':'}${minute}${
              connectorStr === 'cn' ? '分' : ''
            }`;
          case 'YMDh':
            return `${year}${connectorStr === 'cn' ? '年' : connectorStr}${month}${
              connectorStr === 'cn' ? '月' : connectorStr
            }${day}${connectorStr === 'cn' ? '日' : ''} ${hour}${connectorStr === 'cn' ? '时' : ''}`;
          case 'YMD':
            return `${year}${connectorStr === 'cn' ? '年' : connectorStr}${month}${
              connectorStr === 'cn' ? '月' : connectorStr
            }${day}${connectorStr === 'cn' ? '日' : ''}`;
          case 'YM':
            return `${year}${connectorStr === 'cn' ? '年' : connectorStr}${month}${connectorStr === 'cn' ? '月' : ''}`;
          case 'MD':
            return `${month}${connectorStr === 'cn' ? '月' : connectorStr}${day}${connectorStr === 'cn' ? '日' : ''}`;
          case 'hms':
            return `${hour}${connectorStr === 'cn' ? '时' : ':'}${minute}${connectorStr === 'cn' ? '分' : ':'}${second}${
              connectorStr === 'cn' ? '秒' : ''
            }`;
          case 'hm':
            return `${hour}${connectorStr === 'cn' ? '时' : ':'}${minute}${connectorStr === 'cn' ? '分' : ''}`;
          case 'yyyy':
            return `${year}${connectorStr === 'cn' ? '年' : ''}`;
          case 'mm':
            return `${month}${connectorStr === 'cn' ? '月' : ''}`;
          case 'dd':
            return `${day}${connectorStr === 'cn' ? '日' : ''}`;
          case 'weekDay':
            return this.weekDays[dateObj.getDay()];
          case 'week':
            return `第${this.getWeek(dateObj)}周`;
          default:
            return defaultStr;
        }
      },
      /**
       * @desc: 数字
       * @param {Number} value
       * @param {Object} numberFormat
       */
      filterNumber(value, numberFormat = {}) {
        if (isNaN(value) && isNaN(+value)) return value;
        const { type, decimalPlace, prefix = '', suffix = '' } = numberFormat;
        let result = value;
        if (type === 'float') {
          result = (+value).toFixed(isNaN(+decimalPlace) ? 0 : +decimalPlace);
        }
        return `${prefix} ${result} ${suffix}`;
      },
      /**
       * @desc: 补零
       * @param {Number} val 需要补零的值
       */
      addZero(val) {
        if (isNaN(val) || val < 0 || val >= 10) return val;
        return `0${val}`;
      },
      /**
       * @desc: 获取当前日期对象为全年第几周
       * @param {Object<Date>} dateObj 时间对象
       * @return {Number} 第几周
       */
      getWeek(dateObj) {
        const firstDay = new Date(`${dateObj.getFullYear()}-1-1`);
        const timeBetween = dateObj - firstDay;
        const between = timeBetween / (7 * 24 * 60 * 60 * 1000);
        return Math.ceil(between);
      },
      /**
       * @desc: 验证架构类型
       * @param {Array, String} types 类型 group company project
       */
      validArchiType(types) {
        if (!types || !Array.isArray(types) || !types.length) return true;
        if (this.$GetUserInfo('name') === 'admin') return true;
        const archiType = this.$GetTargetArchi('archiType');
        if (archiType && types.includes(archiType)) {
          return true;
        }
        return false;
      }
    },
    beforeDestroy() {
      if (!this.EDITOR_pageUUID) {
        removeEventBus('databaseTrigger', this.element.id);
      }
      this.initTimer(false);
    }
  };
</script>

<style lang="less" scoped>
.scroll-block-container {
	height: 100%;
	width: 100%;
	background: transparent;
	overflow: hidden;
	position: relative;
	.scroll-block-body {
		display: flex;
		height: 100%;
		width: 100%;
		position: relative;
		left: 0;
		overflow: hidden;
		overflow-y: auto;
		.block-com {
			height: 100%;
			flex: 1;
			position: relative;
			& + .block-com {
				margin-left: 5px;
			}
		}
		.block-item {
			display: flex;
			flex-direction: column;
			justify-content: center;
			width: 100%;
			height: 100%;
			position: absolute;
			.block {
				padding: 5px 0;
				box-sizing: border-box;
				text-align: center;
				word-break: break-all;
			}
		}
	}
	.el-empty {
		margin: 0 auto;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}
</style>

<style>
.scroll-list-enter-active {
	transition: transform 0.5s;
}
.scroll-list-leave-active {
	transition: display 0s;
}
.scroll-list-enter {
	transform: translateX(100%);
}
.scroll-list-leave-to {
	display: none;
}
</style>
