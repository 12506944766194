/*
 * @Description: 编辑器右击菜单
 * @Author: luocheng
 * @Date: 2021-08-11 17:18:34
 * @LastEditors: luocheng
 * @LastEditTime: 2021-10-21 15:59:35
 */
export default {
  state: {
    // 显示编辑器菜单
    editorMenuShow: false,
    // 菜单位置
    editorMenuPosition: {
      left: 0,
      top: 0
    }
  },
  mutations: {
    /**
     * @desc: 显示编辑器菜单
     * @param {Object} position 鼠标右键点击的位置信息
     */
    showEditorMenu(state, position) {
      state.editorMenuPosition = position;
      state.editorMenuShow = true;
    },
    /**
     * @desc: 隐藏编辑器菜单
     */
    hideEditorMenu(state) {
      state.editorMenuShow = false;
    }
  }
}