<!--
 * @LastEditTime: 2022-10-10 09:05:21
 * @Description: 字段选择器
 * @FilePath: /dataview-next/src/components/metaSelect/FieldSelect.vue
 * @Date: 2021-12-20 20:20:46
 * @Author: lisushuang
 * @LastEditors: zx
-->
<template>
  <div class="w-full h-full">
    <el-tag
      disable-transitions
      v-show="showTag && !showSelect"
      style="cursor: pointer; height: 40px; line-height: 40px"
      @click="
        showSelect = true;
        $nextTick(() => $refs.obselect.focus());
      "
    >
      {{ showName }}
    </el-tag>
    <!-- 在显示tag的时候展示的追踪图标 -->

    <MetaTraceIcon
      v-show="showTag && !showSelect"
      method="ShowFieldDetail"
      :uuid="selectValue"
      style="margin-left: 5px;"
    />

    <el-select
      v-if="!showTag || showSelect"
      v-model="selectValue"
      filterable
      allow-create
      :multiple="multiple"
      value-key="uuid"
      style="width: 100%"
      default-first-option
      v-loading="selectLoading"
      placeholder="请选择字段"
      ref="obselect"
      element-loading-spinner="el-icon-loading"
      @focus="onSelectFoucus = true"
      @blur="SelectInstanceBlur()"
    >
      <el-option
        v-for="(item, index) in realSelectData"
        :key="index"
        v-show="needVirtual || item.type !== 'append'"
        :label="item.type == 'append' ? '[虚拟] ' + item.name : item.name"
        :value="item.uuid"
      >
      </el-option>

      <template slot="prefix" v-if="showSelect">
        <MetaTraceIcon method="ShowFieldDetail" :uuid="selectValue" />
      </template>
    </el-select>
  </div>
</template>

<script>
import objects from "@/api/objects";
import collect from "collect.js";
import MetaTraceIcon from "./MetaTraceIcon.vue";
import { Tag } from "element-ui";

export default {
  components: { MetaTraceIcon,'el-tag':Tag },
  name: "FieldSelect",
  props: {
    object_uuid: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    // 是否需要虚拟字段
    needVirtual: {
      type: Boolean,
      default: true,
    },
    effect: {
      type: [Array , Object],
      default() {
        return {};
      },
    },
    showTag: {
      type: Boolean,
      default: true,
    },
    fieldList:{
      type:Array,
      default:() => {
        return []
      }
    }
  },

  watch: {
    object_uuid() {
      this.getSelectData();
    },
    effect(val, oldVal) {
      if (val.value == oldVal.value) {
        return;
      }
      this.getSelectData();
    },
    value(val, oldVal) {
      if (val && !oldVal && !this.selectValue) {
        this.selectValue = val;
      }
    },
    selectValue(val) {
      this.$emit("input", val);
      this.$emit("on-select", val);
      this.$nextTick(() => {
        this.onSelectFoucus = false;
        if (this.$refs.obselect) {
          this.$refs.obselect.blur();
        }
        // this.$nextTick(() => {
        //   this.showSelect = false
        // })
      });
    },
  },
  data() {
    return {
      selectData: [],
      selectValue: "",
      selectLoading: true,
      onSelectFoucus: false,
      showSelect: false,

      init:false,
    };
  },
  computed: {
    showName() {
      if (Array.isArray(this.selectData) && !this.selectData.length) {
        return "数据加载中...";
      }
      if (!this.selectValue) {
        return "请选择字段";
      }
      let find = collect(this.selectData)
        .where("uuid", this.selectValue)
        .first();
      if (!find) return this.selectValue;
      return find.name;
    },
    /**
     * @description: 实际展示的选择数据，在没有focus时只显示唯一的那一条
     * @param {*}
     * @return {*}
     */
    realSelectData() {
      if (this.onSelectFoucus) {
        return [{uuid:"",name:"请选择字段"},...this.selectData];
      } else {
        let one = collect(this.selectData).where("uuid", this.value).first();
        if (one) return [one];
        return [{uuid:"",name:"请选择字段"}];
      }
    },
  },
  methods: {
    SelectInstanceBlur() {
      setTimeout(() => {
        this.showSelect = false;
      }, 100);
    },
    getSelectData() {
      if(this.init === false){
        this.init = true;
        if(this.fieldList.length && JSON.stringify(this.effect) === "{}"){
          this.selectLoading = false;
          this.selectData = this.fieldList;
          return;
        }
      }
      this.selectLoading = true;
      let params = {};
      if (this.effect && this.effect.type && this.effect.value) {
        switch (this.effect.type) {
          case "view":
            params.view_uuid = this.effect.value;
            break;
          case "relationship":
            params.relationship_uuid = this.effect.value;
            break;
          case "object":
            params.object_uuid = this.effect.value;
        }
        // params.object_uuid = this.object_uuid
      }
      // 重新请求视图列表数据
      objects
        .getFields(this.object_uuid, params)
        .then((res) => {
          this.selectLoading = false;
          if (res.data.code == 200) {
            this.selectData = res.data.data.data;
          } else {
            this.selectData = [];
          }
        })
        .catch(() => {
          this.selectLoading = false;
          this.selectData = [];
        });
    },
  },
  mounted() {
    this.getSelectData();
    this.selectValue = this.value;
  },
};
</script>
