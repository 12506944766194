<!--
 * @LastEditTime: 2022-08-04 14:59:26
 * @Description: 来自视图数据的选择框
 * @FilePath: /dwh_front/src/components/metaSelect/ViewDataSelect.vue
 * @Date: 2022-03-20 00:04:15
 * @Author: lisushuang
 * @LastEditors: ttheitao
-->
<template>
    <el-select
    :multiple="multiple"
    filterable
    allow-create
    v-model="selectValue"
    style="width: 100%"
    v-loading="selectLoading"
    default-first-option
    ref="obselect"
    element-loading-spinner="el-icon-loading"
    @focus="onSelectFoucus = true"
  >
    <el-option
      v-for="(item, index) in realSelectData"
      :key="index"
      :label="item[label]"
      :value="item[value_key]"
    >
    </el-option>
  </el-select>
</template>

<script>
/* eslint-disable */
import collect from 'collect.js';
import objects from '../../api/objects';

export default {
  name : 'ViewDataSelect',
  props:{
    value: {
      type: String|Number,
      default: "",
    },
    view_uuid:{
      type:String,
      default:''
    },
    label:{
      type:String,
      default:'name'
    },
    value_key:{
      type:String,
      default:'id'
    },
    multiple:{
      type:Boolean,
      default:false
    }
  },
  watch: {
    selectValue() {
      this.$emit("input", this.selectValue);
    },
    value(val, oldVal) {
      if (val && !oldVal && !this.selectValue) {
        this.selectValue = val;
      }
    },
  },
  data() {
    return {
      selectData: [],
      selectValue: "",
      selectLoading: true,
      onSelectFoucus:false
    };
  },
  computed: {
    /**
     * @description: 实际展示的选择数据，在没有focus时只显示唯一的那一条
     * @param {*}
     * @return {*}
     */
    realSelectData() {
      if (this.onSelectFoucus) {
        return this.selectData;
      } else {
        let one = collect(this.selectData).where(this.value_key, this.value).first();
        if (one) return [one];
        return [];
      }
    },
  },
  methods: {
    getSelectData() {
      this.selectLoading = true;
      // todo 后期在处理搜索值传入的问题
      // 重新请求视图列表数据
      objects.mapi
        .common({
          __method_name__: "dataList",
          view_uuid: this.view_uuid,
          transcode: 0,
        })
        .then((res) => {
          this.selectLoading = false;
          if (res.data.code == 200) {
            this.selectData = res.data.data;
          } else {
            this.selectData = [];
          }
        })
        .catch((res) => {
          this.selectLoading = false;
          this.selectData = [];
        });
    },
  },
  mounted() {
    this.getSelectData();
    this.selectValue = this.value
  }
}
</script>