/*
 * @Author: ttheitao
 * @Description: some description
 * @Date: 2022-08-04 09:58:43
 * @LastEditors: luocheng
 * @LastEditTime: 2022-10-12 14:54:23
 */

import object from '@/api/objects'

// 设置cookie
function setCookie(name, value, seconds) {
  seconds = seconds || 0;
  var expires = "";
  if (seconds != 0 ) {      //设置cookie生存时间
    var date = new Date();
    date.setTime(date.getTime()+(seconds*1000));
    expires = "; expires="+date.toGMTString();
  }
  document.cookie = name+"="+escape(value)+expires+"; path=/";   //转码并赋值
}

export default {
  state: {
    valueClasses: [],//虚拟字段配置的类别
    valueFormDatas: '',//取值类的下拉框基础数据

    mockUser: {
      archi_type: '',
      user_id: '',
      user_name: '',
      archi_name: '',
      token: ''
    },
    // 表单tag
    usageTags:[
      {
        value: "all",
        label: "全部"
      },
      {
        value: "check",
        label: "待处理"
      },
      {
        value: "mine",
        label: "我发起的"
      },
      {
        value: "copy",
        label: "抄送"
      },
      {
        value: "finish",
        label: "已处理"
      }
    ],
  },
  mutations: {
    setValueClasses(state, data) {
      state.valueClasses = data;
    },
    setValueFormDatas(state, data) {
      state.valueFormDatas = data;
    },
    setMockUser(state,data){
      localStorage.setItem('mockUserInfo',JSON.stringify(data))
      if(!data){
        data = {
          archi_type: '',
          user_id: '',
          user_name: '',
          archi_name: '',
          token: ''
        }
      }
      // 此处token是否存在置空考虑待确认！！！
      if (data.token) {
        setCookie('bimcctoken', data.token)
      }
      state.mockUser = data
    }
  },
  getters: {
    getValueClasses(state) {
      if (state.valueClasses.length == 0) {
        object.getValueFunctions().then((res) => {
          if (res.data.code == 200) {
            state.valueClasses = res.data.data;
            return res.data.data;
          }
        })
      } else {
        return state.valueClasses
      }
    },
    getValueFormDatas(state) {
      return state.valueFormDatas;
    },

    getMockUser(state){
      if(state.mockUser.user_name){
        return state.mockUser
      }else{
        let data = localStorage.getItem('mockUserInfo')
        if(data){
          data = JSON.parse(data)
          state.mockUser = data
        }
        return state.mockUser
      }
      
    }
  },
  actions: {

  }
}
