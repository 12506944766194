<template>
    <div>
        <ul class="vertical-flex moduleUl">
            <li v-for="(item, index) in moduleList" :key="index"
                :class="['vertical-flex moduleLi', {'moduleLi_active': activeModule === item.id}]"
                @click="onSelectModule(item)"
            >
                <a :class="['iconfont', item.icon]"></a>
                <span>{{item.name}}</span>
            </li>
        </ul>

        <div class="modulePanel">
            <ul class="tabUl">
                <li v-for="(item, index) in tabList" :key="index"
                    :class="['vertical-flex tabLi', {'tabLi_active': activeTab === item.id}]"
                >
                    <a :class="['iconfont', item.icon]"></a>
                    <span>{{item.name}}</span>
                </li>
            </ul>

            <ViewerGISModel
                    ref="modelList"
                    v-show="activeModule==='model' && activeTab === 'modelTree'"
                    class="moduleWrap"
                    :element.sync="element"
                    :bindViewerId="bindViewerId"
            ></ViewerGISModel>

            <ViewerGISScene
                    ref="sceneList"
                    v-show="activeModule==='scene' && activeTab === 'viewPoint'"
                    class="moduleWrap"
                    :element.sync="element"
                    :bindViewerId="bindViewerId"
            ></ViewerGISScene>
        </div>


    </div>
</template>

<script>
    import CustomComponentMixin from '@/custom-component/mixins/CustomComponentMixin.js';
    import eventBus from '@/plugins/eventBus';
    import ViewerGISModel from "./components/ViewerGISModel";
    import ViewerGISScene from "./components/ViewerGISScene";

    export default {
        name: "ViewerGISModule",
        components: {
            ViewerGISModel,
            ViewerGISScene
        },
        data(){
            return{
                moduleList: [
                    {id: 'model', name: '模型', icon: 'icongis_moxingshu'},
                    {id: 'scene', name: '场景', icon: 'icongis_changjing'},
                ],
                activeModule: 'model',
                tabList: [
                    {id: 'modelTree', name: '模型', icon: 'icongis_tuceng'},
                ],
                activeTab: 'modelTree',
                bindViewerId : null,
            }
        },
        props:{
            element: {
                type: Object,
                required: true,
                default: () => {
                    return {
                    }
                }
            },
            isGroup: {
                type: Boolean,
            },
            groupComponents: {
                type: Array,
                default: () => []
            }
        },
        mixins: [CustomComponentMixin],
        mounted(){
            this.initEventListener();

            if(this.element.viewerBindId){
                if( this.checkBind( this.element.viewerBindId) ){
                    this.bindViewerId = this.element.viewerBindId;
                }
            }
        },
        methods: {
            initEventListener(){
                eventBus.$on("GISModule.GetSource", (eid, cb) => {
                    if( eid !== this.element.id) return;

                    const comps = [];
                    for(let comp of this.componentData){
                        if(comp.component !== "ViewerGIS") continue;

                        comps.push({
                            id: comp.id,
                            name: comp.name,
                        });
                    }

                    cb({
                        list: comps,
                        bindId: this.bindViewerId,
                    });
                });


                eventBus.$on("GISModule.BindViewer", (eid, bindId) => {
                    if(eid !== this.element.id) return;
                    if(!this.checkBind(bindId)) return;

                    this.element.viewerBindId = bindId;
                    this.bindViewerId = bindId;
                    this.$message.success(`[${this.element.name}] 绑定成功`);
                });
            },
            checkBind(bindId = this.bindViewerId){
                let hasComp = false;

                for(let comp of this.componentData){
                    if(comp.id !== bindId ) continue;

                    hasComp = true;
                    break;
                }

                if (!hasComp) return this.$message.error(`[${this.element.name}] 绑定失效，组件可能已经被删除`);

                return hasComp;
            },
            onSelectModule(item){
                this.activeModule = item.id;
                switch (this.activeModule) {
                    case "model":
                        this.tabList = [
                            {id: 'modelTree', name: '模型', icon: 'icongis_tuceng'},
                        ];
                        this.activeTab = 'modelTree';
                        break;
                    case "scene":
                        this.tabList = [
                            {id: 'viewPoint', name: '视点', icon: 'iconbim_shidianliebiao'}
                        ];
                        this.activeTab = 'viewPoint';
                        break;
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    .vertical-flex{
        display: inline-flex;
        flex-direction: column;
        line-height: initial;
        vertical-align: middle;
    }

    .moduleUl{
        float: right;
        width: 48px;
        height: 100%;
        box-sizing: border-box;
        border-left: 1px solid rgba(255,255,255,0.3);
        background-color: inherit;
        color: inherit;

        .moduleLi{
            width: 48px;
            height: 56px;
        }

        .moduleLi:hover{
            color: #0076FF;
        }

        .moduleLi_active{
            color: #0076FF;
        }

        .moduleLi a{
            padding-top: 10px;
            font-size: 18px;
            color: inherit;
        }

        .moduleLi span{
            padding-bottom: 10px;
            font-size: 14px;
            color: inherit;
        }
    }

    .modulePanel{
        float: left;
        padding: 0 !important;
        overflow: hidden;
        width: calc(100% -  48px);
        height: 100%;
        background-color: inherit;
        color: inherit;

        .tabUl{
            width: 100%;
            height: 56px;
            box-sizing: border-box;
            border-bottom: 1px solid rgba(255, 255, 255, 0.3);

            .tabLi{
                float: left;
                width: 70px;
                height: 56px;
            }

            .tabLi_active{
                background-color: #8d8d8d;
            }

            .tabLi a{
                padding-top: 10px;
                font-size: 18px;
                color: inherit;
            }

            .tabLi span{
                padding-bottom: 10px;
                font-size: 14px;
                color: inherit;
            }
        }

        .moduleWrap{
            width: 100%;
            height: calc(100% - 56px);
        }
    }
</style>
