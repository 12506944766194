<template>
    <ViewerGISScene
            ref="sceneList"
            class="moduleWrap"
            :element.sync="element"
            :bindViewerId="bindViewerId"
    ></ViewerGISScene>
</template>

<script>
    import CustomComponentMixin from '@/custom-component/mixins/CustomComponentMixin.js';
    import eventBus from '@/plugins/eventBus';
    import ViewerGISScene from "./components/ViewerGISScene";

    export default {
        name: "ViewerGISViewPoint",
        components: {
            ViewerGISScene
        },
        data(){
            return{
                bindViewerId: null
            }
        },
        props:{
            element: {
                type: Object,
                required: true,
                default: () => {
                    return {
                    }
                }
            },
            isGroup: {
                type: Boolean
            },
            groupComponents: {
                type: Array,
                default: () => []
            }
        },
        mixins: [CustomComponentMixin],
        mounted(){
            this.initEventListener();

            if(this.element.viewerBindId){
                if(this.checkBind(this.element.viewerBindId)){
                    this.bindViewerId = this.element.viewerBindId;
                }
            }
        },
        methods: {
            initEventListener(){
                eventBus.$on("GISModule.GetSource", (eid, cb) => {
                    if(eid !== this.element.id) return;

                    const comps = [];
                    for(let comp of this.componentData){
                        if(comp.component !== "ViewerGIS") continue;

                        comps.push({
                            id: comp.id,
                            name: comp.name,
                        });
                    }

                    cb({
                        list: comps,
                        bindId: this.bindViewerId
                    });
                });


                eventBus.$on("GISModule.BindViewer", (eid, bindId) => {
                    if(eid !== this.element.id) return;
                    if(!this.checkBind(bindId)) return;

                    this.element.viewerBindId = bindId;
                    this.bindViewerId = bindId;
                    this.$message.success(`[${this.element.name}] 绑定成功`);
                });
            },
            checkBind(bindId = this.bindViewerId){
                let hasComp = false;

                for(let comp of this.componentData){
                    if(comp.id !== bindId) continue;

                    hasComp = true;
                    break;
                }

                if (!hasComp) return this.$message.error(`[${this.element.name}] 绑定失效，组件可能已经被删除`);

                return hasComp;
            }
        }
    }
</script>

<style lang="less" scoped>
    .moduleWrap{
        width: 100%;
        height: 100%;
    }
</style>
