import Utils from './Utils';
import PostMessageBase from './PostMessageBase';
class Viewer extends PostMessageBase{
   constructor(domId,objectKey,opt={}){
      super(domId,objectKey,opt);

      this.callbackPool = {};

      this.ver = '0.20';
   }

   getMessage(data){
      switch (data.type){
         case 'callback':
            this.handleCallback(data);
            break;
         case 'event':
            this.handleEvent(data);
      }
   }

   handleCallback(res){
      this.callbackPool[res.id].rol(...res.data);
      delete this.callbackPool[res.id];
   }

   handleEvent(res){
      this.emit(res.name,...res.data);
   }

   initionMap(options){
      let data = {
         'name':'initionMap',
         'arg' : [options],
      };
      this.sendMsg(data);
   }

   setWidgetDisplay(name, isShow){
      let data = {
         'name':'setWidgetDisplay',
         'arg' : [name, isShow],
      };
      this.sendMsg(data);
   }

   setView(lnglat, zoom){
      let data = {
         'name':'setView',
         'arg' : [lnglat, zoom],
      };
      this.sendMsg(data);
   }

   setImagery(type){
      let data = {
         'name':'setImagery',
         'arg' : [type],
      };
      this.sendMsg(data);
   }

   zoomToLatLngs(Latlngs){
      let data = {
         'name':'zoomToLatLngs',
         'arg' : [Latlngs],
      };
      this.sendMsg(data);
   }

   latLngToContainerPoint(lnglat){
      return new Promise((rol,rej)=>{
         let id = Utils.getGuid();

         this.callbackPool[id] = {
            rol,rej
         };

         let data = {
            id,
            'name':'latLngToContainerPoint',
            'arg' : [lnglat],
         };
         this.sendMsg(data);
      });
   }

   latLngArrayToContainerPoint(lnglatArray){
      return new Promise((rol,rej)=>{
         let id = Utils.getGuid();

         this.callbackPool[id] = {
            rol,rej
         };

         let data = {
            id,
            'name':'latLngArrayToContainerPoint',
            'arg' : [lnglatArray],
         };
         this.sendMsg(data);
      });
   }

   getZoom(){
      return new Promise((rol,rej)=>{
         let id = Utils.getGuid();

         this.callbackPool[id] = {
            rol,rej
         };

         let data = {
            id,
            'name':'getZoom',
         };

         this.sendMsg(data);
      });
   }

   emitMove(){
      let data = {
         'name':'emitMove',
         'arg' : [],
      };
      this.sendMsg(data);
   }

   clearStampStatus(){
      let data = {
         'name':'clearStampStatus',
         'arg' : [],
      };
      this.sendMsg(data);
   }

   createFenceByType(type, options){
      return new Promise((rol,rej)=>{
         let id = Utils.getGuid();

         this.callbackPool[id] = {
            rol, rej
         };

         let data = {
            id,
            'name':'createFenceByType',
            'arg' : [type, options],
         };
         this.sendMsg(data);
      });
   }

   addFenceByType(key, type, positions, options){
      let data = {
         'name':'addFenceByType',
         'arg' : [key, type, positions, options],
      };
      this.sendMsg(data);
   }

   showFenceByKey(key, isShow){
      let data = {
         'name':'showFenceByKey',
         'arg' : [key, isShow],
      };
      this.sendMsg(data);
   }

   locationFenceByKey(key){
      let data = {
         'name':'locationFenceByKey',
         'arg' : [key],
      };
      this.sendMsg(data);
   }

   removeFenceByKey(key){
      let data = {
         'name':'removeFenceByKey',
         'arg' : [key],
      };
      this.sendMsg(data);
   }

   judgeFenceContainWgs(fenceList, lng, lat){
      return new Promise((rol,rej)=>{
         let id = Utils.getGuid();

         this.callbackPool[id] = {
            rol, rej
         };

         let data = {
            id,
            'name':'judgeFenceContainWgs',
            'arg' : [fenceList, lng, lat],
         };
         this.sendMsg(data);
      });
   }

   addMarkerByWgs(key, lng, lat, options){
      let data = {
         'name':'addMarkerByWgs',
         'arg' : [key, lng, lat, options],
      };
      this.sendMsg(data);
   }

   locationMarkerByKey(key){
      let data = {
         'name':'locationMarkerByKey',
         'arg' : [key],
      };
      this.sendMsg(data);
   }

   removeMarkerByKey(key){
      let data = {
         'name':'removeMarkerByKey',
         'arg' : [key],
      };
      this.sendMsg(data);
   }

   createPointByDivIcon(key, lng, lat, style){
      let data = {
         'name':'createPointByDivIcon',
         'arg' : [key, lng, lat, style],
      };
      this.sendMsg(data);
   }

   locationPointByKey(key){
      let data = {
         'name':'locationPointByKey',
         'arg' : [key],
      };
      this.sendMsg(data);
   }

   removePointByKey(key){
      let data = {
         'name':'removePointByKey',
         'arg' : [key],
      };
      this.sendMsg(data);
   }

   removeAllPoints(){
      let data = {
         'name':'removeAllPoints',
         'arg' : [],
      };
      this.sendMsg(data);
   }
}

export default {
   Viewer2DGIS : Viewer,
}
window.Viewer2DGIS = Viewer;

