<!--
 * @Description: vue
 * @Author: luocheng
 * @Date: 2021-10-29 12:08:51
 * @LastEditors: Shiltin 18580045074@163.com
 * @LastEditTime: 2022-11-06 11:28:02
-->
<template>
    <div v-if="iframeUrl == ''" class="w-full h-full relative">
        <p class="align-center">{{ tipName }}</p>
    </div>
    <iframe v-else class="w-full h-full" :src="iframeUrl" />
</template>

<script>
import { baseUrl } from '@/apis/http/request';
import axios from 'axios'
export default {
    name: "DrawingPreview",
    components: {},
    props: {
        info: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            loading: false,
            iframeUrl: "",
						isFinsh: false, // 图纸未完成转换
            tipName: ''	
        };
    },
    mounted() {
        if (this.info != null) {
            if (this.info.fileext === "pdf") {
                let url = 'https://weboffice.bimcc.net?furl='
                if (baseUrl.includes('https://')) {
                url = 'https://weboffice.bimcc.net?ssl=1&furl='
                }
                this.iframeUrl = url + baseUrl + "/" + this.info.path;
            } else if (this.info.fileext === "dwg") {
							this.loading = true
							if (this.info.model_file_id === undefined) {
								this.tipName = '该文件格式错误，请联系管理员！'
								this.loading = false
								return
							}
							axios({
								method: 'post',
								url: 'https://models.bimcc.net/api/v3/auth/token',
								data: { appId: process.env.VUE_APP_MODEL_APPID, appSecret: process.env.VUE_APP_MODEL_APPSECRET }
							}).then((res) => {
								if (res.data?.data?.token) {
									axios({
										method: 'get',
										url: 'https://models.bimcc.net/api/v3/model/tranlate?file_id=' + this.info.model_file_id,
										headers: {
											authorization: `Bearer ${res.data.data.token}`
										}
									}).then((re) => {
										if (re.data.data?.progress === 'Finish') {
											let modelId = re.data.data.data.model_id
											if (re.data?.data?.data?.f2d && re.data.data.data.f2d.length !== 0) {
												modelId = re.data.data.data.f2d[0].model_id
											}
											this.isFinsh = true
											this.iframeUrl = 'https://models.bimcc.net/viewer/' + modelId + '?id='+ process.env.VUE_APP_MODEL_APPID +'&secret='+ process.env.VUE_APP_MODEL_APPSECRET
										} else {
											this.tipName = 'dwg文件转换中...'
											this.loading = false
										}
									})
								} else {
									this.$message.error('请稍后再试！')
									this.loading = false
								}
							})
            }
        }
    },
};
</script>
