<template>
  <section class="object-box">
    <header class="theme">
      <h4>对象</h4>
    </header>
    <div class="object-echarts" id="object"></div>
    <div class="image">
      <img
        class="img"
        src="https://static.bimcc.com/openim/16671877945936DCD1ED69BFDC463E070BBCFF5F2224C.png"
        alt=""
      />
    </div>
  </section>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "Object",
  components: {},
  props: {
    // 统计数据
    statisticsList: {
      type: [Object, null],
      required: true,
      default: () => null,
    },
  },
  data() {
    return {};
  },
  watch: {
    statisticsList(newVal) {
      this.newData = newVal;
      this.drawPie();
    },
  },
  created() {},
  mounted() {},
  methods: {
    //饼图
    drawPie() {
      this.chartDom = document.getElementById("object");
      this.myChartPie = echarts.init(this.chartDom);
      let pieChartData = [
        {
          value: this.statisticsList.aggrega??0,
          name: "聚合",
        },
        {
          value: this.statisticsList.Entity??0,
          name: "实体",
        },
        {
          value: this.statisticsList.formObject??0,
          name: "表单",
        },
        {
          value: this.statisticsList.hasView??0,
          name: "有效视图",
        },
        {
          value: this.statisticsList.hasRelated??0,
          name: "有关联关系",
        },
      ];
      this.option = {
        title: [
          {
            text: "总数",
            subtext: this.statisticsList.allobject,
            top: "48%",
            left: "26%",
            textStyle: {
              fontFamily: "Source Han Sans CN",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: 14,
              lineHeight: 24,
              color: "#000000",
            },
            subtextStyle: {
              fontFamily: "Source Han Sans CN",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: 20,
              lineHeight: 15,
              color: "#000000",
            },
          },
        ],
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
        },
        legend: {
          itemWidth: 14,
          itemHeight: 14,
          itemGap: 5,
          selectedMode: true,
          top: "15%",
          right: "0%",
          orient: "vertical",
          formatter: (name) => {
            let data = pieChartData;
            let target;
            for (let i = 0, l = data.length; i < l; i++) {
              if (data[i].name == name) {
                target = data[i].value;
              }
            }
            let arr = ["{a|" + name + "}", "{b|" + target + "}"];
            return arr.join("\n");
          },
          textStyle: {
            rich: {
              a: {
                fontSize: 14,
                lineHeight: 24,
                fontWeight: 400,
                fontStyle: "normal",
                fontFamily: "Source Han Sans CN",
                color: "#73767A",
                padding: [25, 0, 0, 8],
              },
              b: {
                color: "#000000",
                fontFamily: "Source Han Sans CN",
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: 18,
                lineHeight: 24,
                padding: [28, 0, 0, 8],
              },
            },
          },
        },
        color: ["#A46CFF", "#409EFF", "#FFBC58", "#C6E2FF", "#95D475"],
        series: [
          {
            name: "对象",
            type: "pie",
            radius: ["45%", "60%"],
            center: ["30%", "56%"],
            label: {
              normal: {
                show: false,
              },
            },
            itemStyle: {
              normal: {
                borderWidth: 5,
                borderColor: "#fff",
              },
            },
            data: pieChartData,
          },
        ],
      };
      this.option && this.myChartPie.setOption(this.option);
      this.myChartPie.dispatchAction({
        type: "highlight",
        seriesIndex: 0,
        dataIndex: 1,
      }); //中心默认显示内容
      this.myChartPie.on("mouseover", (v) => {
        //清空第一次选中
        if (v.dataIndex != 0) {
          this.myChartPie.dispatchAction({
            type: "downplay",
            seriesIndex: 0,
            dataIndex: 1,
          });
        }
      });
      this.myChartPie.on("mouseout", () => {
        //鼠标离开圆环默认选第一次
        this.myChartPie.dispatchAction({
          type: "highlight",
          seriesIndex: 0,
          dataIndex: 1,
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
@media screen and (max-width: 1329px) {
  .middle-content {
    .object-box {
      padding: 16px 0 0 20px;
      box-sizing: border-box;
    }
  }
}
.object-box {
  position: relative;
  width: 39.4%;
  height: 100%;
  padding: 16px 4% 0 20px;
  box-sizing: border-box;
  background: #fff;
  .theme {
    position: absolute;
    top: 20px;
    left: 16px;
    color: rgb(68, 151, 254);
    font-size: 17px;
    font-weight: bold;
    line-height: 22px;
    text-align: left;
    h4 {
      padding-left: 10px;
      box-sizing: border-box;
    }
  }
  .object-echarts {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 999;
  }
  .image {
    position: absolute;
    top: 46%;
    left: 22.5%;
    .img {
      width: 90px;
    }
  }
}
</style>
