<!--
 * @Description: 指针图
 * @Author: luocheng
 * @Date: 2022-09-23 17:42:02
 * @LastEditors: zqy
 * @LastEditTime: 2022-11-10 16:00:00
-->
<template>
  <div class="chart-pointer"
		:style="{
			height: height + 'px'
		}"
		v-loading="getting"
	>
		<ChartContainer
			v-if="option"
			ref="chartInstance"
			class="chart-pie"
			:option="option"
		></ChartContainer>
		<el-empty
			description="暂无数据"
			v-else
		></el-empty>
	</div>
</template>

<script>
import mixin from './mixin';
import eventBus from '@/plugins/eventBus';

export default {
  name: 'ChartPointer',
  mixins: [mixin],
	data() {
		return {
			option: null
		};
	},
  created() {
    this.option = {}
		this.getOptions();
  },
	mounted() {
		this.$nextTick(() => {
			eventBus.$on('refreshChart', () => {
				this.option = null;
				this.getOptions();
			});
			eventBus.$on('databaseTrigger', config => {
				if (!config || !this.paramsConfig.length || !this.paramsConfig.find(ele => ele.componentId === config.componentId)) {
					return;
				}
				this.option = null;
				this.getOptions();
			});
		});
	},
	methods: {
		setOptions(configData) {
			console.log('---123123----', configData);
			const { value, total } = configData;
			const {
				// radius = '75%',
				// clockwise = true,
				// splitNumber = 10,
				// axisShow = true,
				// roundCap = false,
				lineStyleColor = '#E6EBF8',
				lineStyleWidth = 35,
				// progress = true,
				progressWidth = 30,
				// split = true,
				// splitLength = 10,
				// splitDistance = 10,
				// splitColor = '#63677A',
				// splitWidth = 3,
				// splitType = 'solid',
				mainColor = '#FFAB91',
				lessColor = '#FD7347',
				detailUnit = '',
				detailColor = 'auto',
				// detailShadow = true,
				// detailShadowColor = 'transparent',
				detailShadowBlur = 0,
				detailWidth = 100,
				detailHeight = 40,
				detailFontSize = 30,
				detailLineHeight = 30,
				borderRadius = 50,
				borderWidth = 0,
				borderColor = 'transparent',
				backgroundColor = '#ffffff',
				// 位置
				positionX = '50%',
				positionY = '60%'
			} = this.chartData?.attributes || {};
			this.option = {
				title: {
					show: false
				},
				legend: {
					show: false
				},
				series: [
					{
						type: 'gauge',
						center: [positionX, positionY],
						startAngle: 200,
						endAngle: -20,
						min: 0,
						max: total,
						splitNumber: 4,
						itemStyle: {
							color: mainColor
						},
						progress: {
							show: true,
							width: +this.scaleSize(progressWidth)
						},
						pointer: {
							show: false
						},
						axisLine: {
							lineStyle: {
								width: +this.scaleSize(lineStyleWidth),
								color: [[1, lineStyleColor]]
							}
						},
						axisTick: {
							distance: (+this.scaleHeightSize(45 * (progressWidth / 20))) * -1,
							splitNumber: 5,
							lineStyle: {
								width: +this.scaleSize(2),
								color: '#999'
							}
						},
						splitLine: {
							distance: (+this.scaleHeightSize(52 * (progressWidth / 20))) * -1,
							length: +this.scaleSize(14),
							lineStyle: {
								width: +this.scaleSize(3),
								color: '#999'
							}
						},
						axisLabel: {
							distance: (+this.scaleHeightSize(20)) * -1,
							color: '#999',
							fontSize: this.scaleSize(20),
						},
						anchor: {
							show: false
						},
						title: {
							show: false
						},
						detail: {
							valueAnimation: true,
							width: +this.scaleSize(detailWidth),
							height: +this.scaleHeightSize(detailHeight),
							lineHeight: +this.scaleHeightSize(detailLineHeight),
							borderRadius,
							offsetCenter: [0, '-15%'],
							fontSize: +this.scaleSize(detailFontSize),
							fontWeight: 'bolder',
							formatter: `{value}${detailUnit}`,
							color: detailColor,
							backgroundColor,
							shadowColor: mainColor,
							shadowBlur: +this.scaleSize(detailShadowBlur),
							borderWidth: +this.scaleSize(borderWidth),
							borderColor
						},
						data: [
							{
								value
							}
						]
					},
					{
						type: 'gauge',
						center: [positionX, positionY],
						startAngle: 200,
						endAngle: -20,
						min: 0,
						max: total,
						itemStyle: {
							color: lessColor
						},
						progress: {
							show: true,
							width: this.scaleSize(8)
						},
						pointer: {
							show: false
						},
						axisLine: {
							show: false
						},
						axisTick: {
							show: false
						},
						splitLine: {
							show: false
						},
						axisLabel: {
							show: false
						},
						title: {
							show: false
						},
						detail: {
							show: false
						},
						data: [
							{
								value
							}
						]
					}
				]
			};
			console.log(this.option, '----this.option----')
		}
	}
}
</script>

<style lang="less" scoped>
.chart-pointer {
	height: 100%;
	width: 100%;
	:deep(.el-empty){
		padding: 0;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
		.el-empty__image{
			width: 35%;
			max-width: 120px;
			min-width: 50px;
		}
		.el-empty__description{
			margin-top: 15px;
		}
		.page-container p{
			color: #fff;
		}
	}
}
</style>