<!--
 * @Description: 组件盒子
 * @Author: luocheng
 * @Date: 2021-08-18 11:57:07
 * @LastEditors: zx
 * @LastEditTime: 2022-12-06 17:58:49
-->
<template>
		<div 
			class="component-box"
			:style="getBoxStyle(element)"
			:data-id="element.id"
			:span="element.span !== undefined ? +element.span : -1"
			:class="['component-box-span-' + (+element.span), isActive ?  'active' : '']"
			@mousedown="onMouseDown"
			v-if="element.statusConfig && element.statusConfig.isShow
			&& judgingArchiAPermission(element.archiLimit, element.authConfig && element.statusConfig.list)
			&& getUILogicStatus()"
		>
		<template v-if="!isPreview">
			<div class="border border-top"></div>
			<div class="border border-bottom"></div>
			<div class="border border-left"></div>
			<div class="border border-right"></div>
		</template>
		<component
			:is="element.component"
			:element="element"
      :isPreview="isPreview"
			:containerData="containerData"
			:fullData="fullData"
			:style="getComStyle(element)"
			@mousedown="onMouseDown"
			:isGroup="isGroup"
			:groupComponents="groupComponents"
		>
		</component>
		<!-- 操作 -->
		<section class="actions" v-if="!isPreview && curComponent && curComponent.id === element.id">
			<i class="iconfont iconshangyi" @click="onMove('forward')"></i>
			<i class="iconfont iconxiayi" @click="onMove('backward')"></i>
		</section>
		</div>
</template>

<script>
import { getStyle } from '@/utils/style';
import { mapState } from 'vuex';
import useStore from '@/store'
import { judgingArchiAPermission } from '@/utils/tools';
import { reactive, onMounted, defineComponent, computed, toRefs } from 'vue'
import { MessageBox } from 'element-ui';

// 注册组件
const componentsList = require.context('@/mobile-custom-components', true, /\.+vue$/);
const customComponents = {};
componentsList.keys().forEach((fileName) => {
	let name = fileName.replace(/.vue/, '');
	name = name.replace(/\.\//, '');
	name = name.split('/')[1];
	customComponents[name] = componentsList(fileName).default;
});
// console.log(customComponents, 'customComponents')
export default defineComponent({
	name: 'MobileComponentBox',
	props: {
		// 组件对象
		element: {
			type: Object,
			default: () => {},
			required: true
		},
		// 容器数据
		containerData: {
			type: [Object, Array],
			required: false
		},
		// 完整数据
		fullData: {
			type: [Object, Array],
			required: false
		},
		// 是否为预览
		isPreview: {
			type: Boolean,
			required: false,
			default: false
		},
		// 是否为分组
		isGroup: {
			type: Boolean,
		},
		// 当前分组的组件数据
		groupComponents: {
			type: Array,
			default: () => []
		}
	},
	components: {
		...customComponents
	},
	setup (props) {
    const store = useStore;
    const storeStateFns = mapState(["curComponent"]);
    const storeState = {};
    Object.keys(storeStateFns).forEach(fnKey => {
      const fn = storeStateFns[fnKey].bind({ $store: store });
      storeState[fnKey] = computed(fn);
    });
		const isActive = computed(() => {
			return storeState.curComponent && storeState.curComponent.value && (storeState.curComponent.value.id === props.element.id)
		})
		const archiLimit = computed(() => {
			const limits = props.element.archiLimit || [];
			if (limits && Array.isArray(limits) && limits.length) {
				const archiType = props.$GetTargetArchi('archiType') || '';
				return limits.includes(archiType)
			}
			return true;
		})
		const state = reactive({

		})
		onMounted(() => {
		})
		/**
		 * @desc: 获取UI判断逻辑
		 */
		const getUILogicStatus = () => {
			const { UILogic } = props.element;
			if (!UILogic || !UILogic.enable) return true;
			const { logicConfigs } = UILogic;
			const logicList = [];
			for (let i = 0; i < logicConfigs.length; i++) {
				const item = logicConfigs[i];
				const { dataAttr, field, itemLogic, logicRule, type, value, valueType } = item
				// 逻辑关系
				let itemBoolean = true;
				let logicValue = null;
				if (type === 'userInfo') {
					// 用户信息
					const userInfo = localStorage.getItem('userInfo') && JSON.parse(localStorage.getItem('userInfo'));
					logicValue = userInfo && userInfo[field];
					if (dataAttr === 'length') {
						logicValue = logicValue.length;
					}
				} else if (type === 'framework') {
					// 架构信息
					const targetArchi = localStorage.getItem('targetArchi') && JSON.parse(localStorage.getItem('targetArchi'));
					logicValue = targetArchi && targetArchi[field];
					if (dataAttr === 'length') {
						logicValue = logicValue.length;
					}
				}
				// 逻辑关系
				if (logicRule === '===') {
					if (valueType === 'string') {
						itemBoolean = logicValue === value;
					} else if (valueType === 'number') {
						itemBoolean = +logicValue === +value;
					} else if (valueType === 'date') {
						itemBoolean = new Date(logicValue).getTime() === value;
					}
				} else if (logicRule === '!=') {
					if (valueType === 'string') {
						itemBoolean = logicValue !== value;
					} else if (valueType === 'number') {
						itemBoolean = logicValue !== +value;
					} else if (valueType === 'date') {
						itemBoolean = new Date(logicValue).getTime() !== value;
					}
				} else if (logicRule === '>') {
					if (valueType === 'number') {
						itemBoolean = logicValue > +value;
					} else if (valueType === 'date') {
						itemBoolean = new Date(logicValue).getTime() > value;
					}
				} else if (logicRule === '<') {
					if (valueType === 'number') {
						itemBoolean = logicValue < +value;
					} else if (valueType === 'date') {
						itemBoolean = new Date(logicValue).getTime() < value;
					}
				} else if (logicRule === '<=') {
					if (valueType === 'number') {
						itemBoolean = logicValue <= +value;
					} else if (valueType === 'date') {
						itemBoolean = new Date(logicValue).getTime() <= value;
					}
				} else if (logicRule === '>=') {
					if (valueType === 'number') {
						itemBoolean = logicValue >= +value;
					} else if (valueType === 'date') {
						itemBoolean = new Date(logicValue).getTime() >= value;
					}
				}
				logicList.push({
					itemBoolean,
					itemLogic
				});
			}
			if (logicList.length === 1) {
				return logicList[0].itemBoolean;
			}
			// 组合逻辑判断
			let prevLogicBoolean = null;
			for (let j = 1; j < logicList.length; j++) {
				const prev = logicList[j - 1];
				const prevItemLogic = prev.itemLogic;
				if (prevItemLogic === 'and') {
					if (j === 1) {
						prevLogicBoolean = prev.itemBoolean && logicList[j].itemBoolean
					} else {
						prevLogicBoolean = prevLogicBoolean && logicList[j].itemBoolean;
					}
					if (!prevLogicBoolean) {
						return false;
					}
				} else if (prevItemLogic === 'or') {
					if (j === 1) {
						prevLogicBoolean = prev.itemBoolean || logicList[j].itemBoolean
					} else {
						prevLogicBoolean = prevLogicBoolean || logicList[j].itemBoolean
					}
					if (!prevLogicBoolean) {
						return false;
					}
				}
			}
			return true;
		}
		/**
		 * @desc: 组件上落下鼠标
		 */
		const onMouseDown = (e) => {
			if (props.isPreview) return;
			store.commit('setClickComponentStatus', true);
			e.stopPropagation();
			e.preventDefault();
			const domId = e.target.dataset.id;
			if (
				domId &&
				domId.toLowerCase().indexOf('container') === -1 &&
				props.element.children &&
				props.element.children.length
			) {
				// 根据在子节点中获取组件
				const component = getChildComponent(props.element.children, domId);
				store.commit('setCurComponent', {
					component,
					index: props.index
				});
			} else {
				store.commit('setCurComponent', {
					component: props.element,
					index: props.index
				});
			}
		}
		/**
		 * @desc: 根据ID在数组中获取组件
		 * @param {Array} arr 组件列表
		 * @param {String} id 组件ID
		 * @return {Object} 组件对象
		 */
		const getChildComponent = (arr, id) => {
			if (!arr || !Array.isArray(arr) || !arr.length) return null;
			let result = null;
			for (let i = 0; i < arr.length; i++) {
				if (arr[i].id === id) {
					result = arr[i];
					break;
				}
				if (arr[i].children && arr[i].children.length) {
					result = getChildComponent(arr[i].children, id);
					if (result) {
						break;
					}
				}
			}
			return result;
		}
		/**
		 * @desc: 组件盒型样式
		 * @param {Object} element 组件对象
		 */
		const getBoxStyle = (element) => {
			// if (element.component === 'CommonModel' || element.component==='ConsoleBimModel') return;
			const { style, specialStyle } = element;
			if (!style) return {};
			let excludesArr = [
				'top',
				'left',
				'rotate',
				'overflowY',
				'overflowX'
			];
			let result = getStyle(style, excludesArr);
			if (result.display) {
				delete result.display
			}
			console.log(result, 'resultresultresultresult')
			// 兼容flex 不居中表格横向滚动失效
			if ((props.element.span !== undefined && +props.element.span !== -1)
				|| (props.element.component === 'CommonTableContainer'
					&& result.width
					&& result.width.toString().indexOf('calc') === -1
				)
			) {
				result.width = '';
			}
			// 特殊样式
			if (specialStyle.isCollector) {
				// 收集者
				result.flex = 1;
			}
			if (specialStyle.isAbsolute) {
				result.position = 'absolute';
				const { absoluteTop = 0, absoluteLeft = 0, zIndex = 10 } = specialStyle;
				result.top = isNaN(+absoluteTop) ? absoluteTop : `${absoluteTop}px` ;
				result.left = isNaN(+absoluteLeft) ? absoluteLeft : `${absoluteLeft}px`;
				result.zIndex = isNaN(+zIndex) ? 10 : zIndex;
				result.height = style && style.height && (Number(style.height) || (!style.height.includes('cal') && !style.height.includes('%'))) ? 
					style.height + 'px' : 
					style.height;
				result.width = style && style.width && (Number(style.width) || (!style.width.includes('cal') && !style.width.includes('%'))) ? 
					style.width + 'px' : 
					style.width;
			}
			if(element.component === 'MobileCommonContainer' || element.component === 'ListContainerOne') {
				result.overflowY = 'hidden'
				if (element.component === 'ListContainerOne') {
					result.flex = 1
				}
			}
			return result;
		}
		/**
		 * @desc: 组件样式
		 * @param {Object} element 组件对象
		 */
		const getComStyle = (element) => {
			// if (element.component === 'CommonModel') return;
			const { style } = element;
			// 基础样式
			if (!style) return {};
			let excludesArr = [
				'height',
				'width',
				'top',
				'left',
				'rotate',
				'paddingLeft',
				'paddingRight',
				'paddingTop',
				'paddingBottom',
				'marginTop',
				'marginBottom',
				'marginLeft',
				'marginRight',
				'borderWidth',
				'borderStyle',
				'borderColor',
				'position',
				'bottom',
				'right',
				'zIndex',
			];
			let result = getStyle(style, excludesArr);
			if ((props.element.span !== undefined && +props.element.span !== -1)
				|| (props.element.component === 'CommonTableContainer' && result.width && result.width.toString().indexOf('calc') > -1)) {
				if (!element.specialStyle.isAbsolute) {
					result.width = '100%';
				}
			}
			if (element.specialStyle.flexDirection) {
				// 轴方向
				result.flexDirection = element.specialStyle.flexDirection;
			}
      result.flexWrap = element.specialStyle.flexWrap ? 'wrap' : 'nowrap';
			// 特殊样式
			// if (specialStyle.isCollector) {
			//   // 收集者
			//   result.flex = 1;
			// }
			result.height = '100%';
			result.width = '100%';
			return result;
		}
		/**
		 * @desc: 向前移动
		 * @param {String} action 动作 forward 向前 backward 向后
		 */
		const onMove = (action) => {
			store.commit('moveComponentOrder', {
				action
			});
		}
		/**
		 * @desc: 删除组件
		 */
		const onDelete = () => {
			MessageBox.confirm('是否确认删除组件?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				store.commit('deleteComponent');
			});
		}
		return {
			...toRefs(state),
			...storeState,
			judgingArchiAPermission,
			isActive,
			archiLimit,
			getUILogicStatus,
			onMouseDown,
			getChildComponent,
			getBoxStyle,
			getComStyle,
			onMove,
			onDelete
		}
	}
})
</script>

<style lang="less" scoped>
.component-box {
	box-sizing: border-box;
	cursor: pointer;
	position: relative;
	// border: 1px dashed #ccc;
	// &:before{
	//   content: '';
	//   position: absolute;
	//   display: block;
	//   left: 0;
	//   top: 0;
	//   box-sizing: border-box;
	//   height: 100%;
	//   width: 100%;
	//   border: 1px dashed #ccc;
	// }
	.border {
		position: absolute;
		border: 1px dashed #ccc;
		z-index: 1000;
		&.border-top {
			height: 0px;
			width: calc(100% - 2px);
			top: 0;
			left: 0;
		}
		&.border-bottom {
			height: 0px;
			width: calc(100% - 2px);
			bottom: 0;
			left: 0;
		}
		&.border-left {
			height: calc(100% - 2px);
			width: 0px;
			top: 0;
			left: 0;
		}
		&.border-right {
			height: calc(100% - 2px);
			width: 0px;
			top: 0;
			right: 0;
		}
	}
	&.active {
		// border: 2px solid @theme;
		> .border {
			border: 1px solid @theme;
		}
		&:before {
			border: 2px solid @theme;
		}
	}
	.actions {
		position: absolute;
		left: 0;
		top: 0;
		display: flex;
		background: rgba(30, 178, 254, 0.6);
		border-bottom-right-radius: 2px;
		z-index: 1000;
		i {
			font-size: 12px;
			line-height: 14px;
			color: #fff;
			box-sizing: border-box;
			padding: 2px 2px 2px 5px;
			cursor: pointer;
			&.icon-shanchu {
				color: @dangerColor;
			}
			&:hover{
				font-weight: bold;
			}
		}
	}
	&.component-box-span-1{
		width: 4.17%;
	}
	&.component-box-span-2{
		width: 8.33%;
	}
	&.component-box-span-3{
		width: 12.5%;
	}
	&.component-box-span-4{
		width: 16.67%;
	}
	&.component-box-span-5{
		width: 20.83%;
	}
	&.component-box-span-6{
		width: 25%;
	}
	&.component-box-span-7{
		width: 29.17%;
	}
	&.component-box-span-8{
		width: 33.33%;
	}
	&.component-box-span-9{
		width: 37.5%;
	}
	&.component-box-span-10{
		width: 41.67%;
	}
	&.component-box-span-6{
		width: 45.83%;
	}
	&.component-box-span-12{
		width: 50%;
	}
	&.component-box-span-13{
		width: 54.16%;
	}
	&.component-box-span-14{
		width: 58.33%;
	}
	&.component-box-span-15{
		width: 62.5%;
	}
	&.component-box-span-16{
		width: 66.67%;
	}
	&.component-box-span-17{
		width: 70.83%;
	}
	&.component-box-span-18{
		width: 75%;
	}
	&.component-box-span-19{
		width: 79.17%;
	}
	&.component-box-span-20{
		width: 83.33%;
	}
	&.component-box-span-21{
		width: 87.5%;
	}
	&.component-box-span-22{
		width: 91.67%;
	}
	&.component-box-span-23{
		width: 95.83%;
	}
	&.component-box-span-24{
		width: 100%;
	}
}
</style>
