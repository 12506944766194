<!--
    @name: widget-user
    @description：widget-user
    @author: ZengWei
    @date: 2022-03-25 09:39
-->
<template>
  <select-user
    :key="key"
    :disabled="disabled"
		:isConsole="isConsole"
    :data-info="element.config.__config__.userData || {}"
    :show-list="element.value"
    :isItself="isItself"
    :lazy="element.config.__config__.lazy || false"
    @on-select-change="onSelectChange"
  />
</template>

<script>
import selectUser from '@/manage-views/components/selectUser/index';
import formItemMinix from "@/custom-component/form/newParser/scripts/formItemMinix";
import {formRequest} from "@/apis/data/form";
import {uniqid} from "@/plugins/util";
import eventBus from "@/plugins/eventBus";
import {getSearchData} from "@/custom-component/form/newParser/scripts/tools";

export default {
  name: "WidgetUser",
  components: {selectUser},
  mixins: [formItemMinix],
  props: ['element', 'disabled', 'displayData','isConsole'],
  data() {
    return {
      key:uniqid(),
      isItself: false
    }
  },
  computed: {
    reshowText() {
      let res= '';
      if (Array.isArray(this.element.value)) {
       res = this.element.value.map(el => el.name).join(',');
      }
      return res;
    }
  },
  created() {
    this.initUserData()
  },
  mounted() {
    eventBus.$on('TRIGGER_selectChange',(formId)=>{
      const eleConf = this.element.config.__config__;
      const filterRule = eleConf?.filterRule ?? []
      const formIdArr = filterRule.filter(item => item.valueType === 2)
      const compFormId = Array.from(formIdArr,item => item.value)
      if(compFormId.includes(formId)){
        this.initUserData();
      }
    })
    eventBus.$on('TRIGGER_treeSelectChange',(formId)=>{
      const eleConf = this.element.config.__config__;
      const filterRule = eleConf?.filterRule ?? []
      const formIdArr = filterRule.filter(item => item.valueType === 2)
      const compFormId = Array.from(formIdArr,item => item.value)
      if(compFormId.includes(formId)){
        this.initUserData();
      }
    })
  },
  methods: {
    initUserData(){
      const url= '/api/mapi';
      const objectUuid = this.element.config.__config__.objectUuid
      const viewUuid = this.element.config.__config__.viewUuid
      const filterRule = this.element.config.__config__.filterRule ?? []
      const params = {
        __method_name__: 'dataList', object_uuid: objectUuid,
        view_uuid: viewUuid, transcode: 0,
        __now_archi_type: this.parser.nowArchiType,
      }
      const searchData = getSearchData(filterRule,this.parser.getFormIdValue);
      if(searchData.length) params.search = searchData.filter((item) => item.value)
      formRequest('post', url, params).then(res => {
        let respData = res.data.data;
        if (res.data?.data?.data) {
          respData = res.data.data.data;
        }
        if(!this.element.config.__config__.dataType || !this.element.config.__config__.userData){
          const archiInfo = this.$GetTargetArchi()
          let userData = {list:respData, type:this.element.config.__config__.dataType || archiInfo.archiType}
          this.element.config.__config__.userData = userData
        } else {
          this.isItself = true // 本级架构数据
        }
        if(this.displayData && Object.prototype.toString.call(this.displayData) === '[object Object]' && Object.keys(this.displayData).includes(this.element.field)){
          this.element.value = this.displayData[this.element.field];
        }
        this.key = uniqid()
      })
    },
    onSelectChange(userData) {
      let users = []
      for (let item of userData) {
        let user = {
          id: item.id,
          name: item.name,
          user_table: 'sys_users',
          section_id: item.section_id,
        }
        users.push(user)
      }
      this.element.value = users;
    },
  },
}
</script>

<style scoped>

</style>
