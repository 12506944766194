/*
 * @LastEditTime: 2022-08-04 15:15:00
 * @Description: 删除一条数据的行为
 * @FilePath: /dwh_front/src/views/listViewer/actions/deleteData.js
 * @Date: 2021-12-02 20:44:15
 * @Author: lisushuang
 * @LastEditors: ttheitao
 */
/* eslint-disable */

import { Message, MessageBox } from "element-ui";
import objects from "../../../api/objects";
import baseAction from "./baseAction";


export default class deleteData extends baseAction{

  /**
   * @description: 删除一条数据的行为
   * @param {String} config.object_uuid 对象标识
   * @param {Integer} config.data_id 数据id
   * @param {String} config.message 确认提示信息
   * @param {String} config.type 提示类型 
   * @param {Boolean} config.needConfirm 是否需要确认？
   * @return {*} void
   */  
  constructor(config){
    super()
    this.object_uuid = config.object_uuid
    this.data_id = config.data_id
    this.type = config.type ? config.type : 'warning'
    this.needConfirm = config.needConfirm ? true : false
    this.message = config.message?config.message : '确认删除该条数据吗？'
  }


  handler(){
    MessageBox.confirm(this.message,'提示',{type:this.type}).then(res => {
      if(res == 'confirm'){
        this.getLoading('请稍后')
        objects.mapi.common({
          __method_name__:'deleteData',
          object_uuid:this.object_uuid,
          data_id:this.data_id
        }).then(res=>{
          this.loading.close()
          this.changeRefreshTag()      
        }).catch(res => {
          this.changeRefreshTag()      

          this.loading.close()
        })
      }
    }).catch(res => {
    })
  }
}
