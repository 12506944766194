/* eslint-disable */
import {formRequest} from "@/api/home";
import XEUtils from "xe-utils";

async function eleValidate(ele,fillMatch,fillData) {
	let itemData; //格式化后的item数据
	let recoverVal = '';
	switch (ele.__config__.tagIcon) {
		case 'input':
		case 'textarea':
		case 'rich-text':
		case 'money':
		case 'password':
			recoverVal = ele.__config__.defaultValue ? ele.__config__.defaultValue : '';

			itemData = {value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele};
			if (ele.__config__.required) {
				itemData.validate = {required: true, message: ele.__config__.label + '为必填项', type: 'string', trigger: 'blur'};
			}
			break;
		case 'number':
		case 'slider':
		case 'rate':
			recoverVal = 0;

			itemData = {value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele};
			if (ele.__config__.required) {
				itemData.validate = {required: true, message: ele.__config__.label + '为必填项', type: 'number', trigger: 'change'};
			}
			break;
		case 'select':
			let defaultVal = ele.__config__.defaultValue ? ele.__config__.defaultValue : '';
			defaultVal += '';
			let defaultValid = {};
			if (ele.__config__.required) {
				defaultValid = [{
					required: true, message: ele.__config__.label + '为必填项', type: 'string', trigger: 'change',
					transform: (value) => {
						let base;
						if (value) base = "" + value;
						return base;
					}
				}];
			}
			if (ele.multiple) {
				defaultVal = [];
				if (ele.__config__.required) {
					defaultValid = {required: true, message: ele.__config__.label + '为必填项', type: 'array', trigger: 'change'}
				}
			}
			if (ele.__config__.dataType === 'dynamic') {
				let url, params, options = [];
				if (ele.__config__.objectUuid && ele.__config__.viewUuid) {
					let searchData = [];
					if(ele.__config__.filterRule && ele.__config__.filterRule instanceof Array){
						for (let item of ele.__config__.filterRule){
							let itemData = { ruleType: item.ruleType };
							if(item.paramType === 2){
								itemData.field_uuid = item.field_uuid;
							}else{
								itemData.code = item.code;
							}
							if(item.valueType === 1){
								itemData.value = item.value
								if(item.value.indexOf(';')){
									itemData.value = item.value.split(';')
								}
								searchData.push(itemData)
							}
						}
					}

					url = '/api/mapi'
					params = {
						__method_name__: 'dataList',
						object_uuid: ele.__config__.objectUuid,
						view_uuid: ele.__config__.viewUuid,
						size: 1000,
						// transcode: 0,
					}
					if(searchData.length) params.search = searchData
					let response = await formRequest('post', url, params);
					let userData;
					if (response.data.data.data) {
						userData = response.data.data.data
					} else {
						userData = response.data.data
					}
					let label = ele.props.props.label;
					let value = ele.props.props.value;
					if (userData instanceof Array) {
						for (let element of userData) {
							if (element[label] && element[value]) {
								let item = {label: element[label], value: element[value],allField:element};
								options.push(item);
							}
						}
					}
				}
				ele.__slot__.options = options;
			}

			itemData = {
				value: defaultVal,
				type: ele.__config__.tagIcon,
				field: ele.__vModel__,
				config: ele,
				validate: defaultValid
			};
			break;
		case 'radio':
		case 'color':
			recoverVal = ele.__config__.defaultValue ? ele.__config__.defaultValue + '' : '';
			itemData = {value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele};
			if (ele.__config__.required) {
				itemData.validate = {
					required: true, type: 'string', message: ele.__config__.label + '为必填项',
					trigger: 'change', transform: (value) => {
						let base;
						if (value) base = "" + value;
						return base;
					}
				};
			}
			break;
		case 'switch':
			recoverVal = ele.__config__.defaultValue;
			itemData = {value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele};
			if (ele.__config__.required) {
				itemData.validate = {
					required: true,
					message: ele.__config__.label + '为必填项',
					type: 'boolean',
					trigger: 'change'
				};
			}
			break;
		case 'time':
		case 'date':
			recoverVal = ele.__config__.defaultValue ? ele.__config__.defaultValue : '';
			itemData = {value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele};
			if (ele.__config__.required) {
				itemData.validate = {
					required: true, message: ele.__config__.label + '为必填项', type: 'string', trigger: 'change',
					transform: (value) => {
						let base;
						if (value) base = "" + value;
						return base;
					}
				}
			}
			break;
		case 'cascader':
			recoverVal = ele.__config__.defaultValue ? ele.__config__.defaultValue : [];
			itemData = {value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele, validate: []};
			if (ele.__config__.required) {
				itemData.validate = {required: true, message: ele.__config__.label + '为必填项', type: 'array', trigger: 'change'};
			}
			if (ele.__config__.dataType === 'dynamic' && ele.__config__.objectUuid && ele.__config__.viewUuid) {
				let url = '/api/mapi', params = {
					__method_name__: 'dataList',
					object_uuid: ele.__config__.objectUuid,
					view_uuid: ele.__config__.viewUuid,
					// transcode: 0,
				};
				let response = await formRequest('post', url, params);
				let respData = [];
				if (response.data.data.data) {
					respData = response.data.data.data
				} else {
					respData = response.data.data
				}
				let dataTree = [];
				let propLabel = ele.props.props.label;
				let propValue = ele.props.props.value;
				let propChild = ele.props.props.children;
				if (respData) {
					respData.map(item => {
						let obj = {
							id: item.id,
							value: item[propValue] ? item[propValue] : item.id,
							label: item[propLabel] ? item[propLabel] : item.name,
							children: []
						};
						obj.children = XEUtils.mapTree(item.children, item2 => {
							let objChildren = {
								id: item2.id,
								value: item2.id,
								label: item2.name,
							};
							return objChildren;
						}, {children: propChild});
						if (obj.children) {
							dataTree.push(obj)
						}
					});
				}
				ele.options = dataTree;
			}
			break;
		case 'checkbox':
		case 'time-range':
		case 'date-range':
		case 'upload':
			recoverVal = ele.__config__.defaultValue ? ele.__config__.defaultValue : [];
			if(ele.__config__.tagIcon === 'time-range') {
				recoverVal = [new Date(),new Date()]
			}
			itemData = {value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele, validate: []};
			if (ele.__config__.required) {
				itemData.validate = {required: true, message: ele.__config__.label + '为必填项', type: 'array', trigger: 'change'};
			}
			break;
		case 'form':
			recoverVal = [];
			itemData = {value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele, validate: []};
			if (ele.__config__.required) {
				itemData.validate = {required: true, message: ele.__config__.label + '为必填项', type: 'array', trigger: 'change'};
			}
			break;
		case 'bimList':
			recoverVal = [];
			itemData = {value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele, validate: []};
			if (ele.__config__.required) {
				itemData.validate = {required: true, message: ele.__config__.label + '为必填项', type: 'array', trigger: 'change'};
			}
			break;
		case 'gisList':
			recoverVal = [];
			itemData = {value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele, validate: []};
			if (ele.__config__.required) {
				itemData.validate = {required: true, message: ele.__config__.label + '为必填项', type: 'array', trigger: 'change'};
			}
			break;
		case 'bimConsole':
			recoverVal = [];
			itemData = {value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele, validate: []};
			if (ele.__config__.required) {
				itemData.validate = {required: true, message: ele.__config__.label + '为必填项', type: 'object', trigger: 'change'};
			}
			break;
		case 'gisConsole':
			recoverVal = [];
			itemData = {value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele, validate: []};
			if (ele.__config__.required) {
				itemData.validate = {required: true, message: ele.__config__.label + '为必填项', type: 'object', trigger: 'change'};
			}
			break;
		case 'user':
			recoverVal = [];
			if (ele.__config__.objectUuid && ele.__config__.viewUuid) {
				let url = '/api/mapi',
					params = {
						__method_name__: 'dataList',
						object_uuid: ele.__config__.objectUuid,
						view_uuid: ele.__config__.viewUuid,
						transcode: 0,
					}
				let response = await formRequest('post', url, params);
				let resData = [];
				if(response.data.data.data){
					resData = response.data.data.data
				} else {
					resData = response.data.data
				}
				const userData = {list:resData, type:ele.__config__.dataType}
				ele.__config__.userData = userData
			}
			itemData = {value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele, validate: []};
			if (ele.__config__.required) {
				itemData.validate = {required: true, message: ele.__config__.label + '为必填项', type: 'array', trigger: 'change'};
			}
			break;
		case 'signature':
			recoverVal = [];
			itemData = {value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele, validate: []};
			if (ele.__config__.required) {
				itemData.validate = {required: true, message: ele.__config__.label + '为必填项', type: 'array', trigger: 'change'};
			}
			break;
		case 'treeSelect':
			if (ele.__config__.objectUuid && ele.__config__.viewUuid) {
				let url, params;
				let searchData = [];
				if(ele.__config__.filterRule && ele.__config__.filterRule instanceof Array){
					for (let item of ele.__config__.filterRule){
						let itemData = { ruleType: item.ruleType };
						if(item.paramType === 2){
							itemData.field_uuid = item.field_uuid;
						}else{
							itemData.code = item.code;
						}
						if(item.valueType === 1){
							itemData.value = item.value
							if(item.value.indexOf(';')){
								itemData.value = item.value.split(';')
							}
							searchData.push(itemData)
						}
					}
				}
				url = '/api/mapi'
				params = {
					__method_name__: 'dataList',
					object_uuid: ele.__config__.objectUuid,
					view_uuid: ele.__config__.viewUuid,
					size: 1000,
					// transcode: 0,
				}
				if(searchData.length) params.search = searchData

				let response = await formRequest('post', url, params);
				let resData = [];
				if(response.data.data.data){
					resData = response.data.data.data
				} else {
					resData = response.data.data
				}
				let label = ele.props.props.label;
				let value = ele.props.props.value;
				let child = ele.props.props.children;
				let recurData = []; //树形数据格式化树形数据key
				function mapTree (data) {
					const haveChildren = Array.isArray(data[child]) && data[child].length > 0;
					return {
						id: data[value],
						label: data[label],
						value: data[value],
						children: haveChildren ? data[child].map(i => mapTree(i)) : []
					}
				}
				recurData = resData.map(item => mapTree(item));
				ele.options = recurData;
			}

			recoverVal = '';
			let type = 'string'
			const isMulti = ele.props.props.multiple;
			if(isMulti) {
				recoverVal = [];
				type = 'array';
			}
			itemData = {value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele, validate: []};
			if (ele.__config__.required) {
				itemData.validate = {required: true, message: ele.__config__.label + '为必填项', type: type, trigger: 'change'};
			}
			break
		case 'outerForm':
			if(ele.__config__.formDesignId){
				let url;
				if(ele.__config__.formType === 1){
					url = '/api/saas/metadata/transformuuid/'+ ele.__config__.formDesignId
				} else {
					url = '/api/form4/moduledesign?module_uuid='+ ele.__config__.formDesignId
				}
				let response = await formRequest('get', url, {});
				let resData = [];
				resData = response.data.data;
				if(ele.__config__.formType === 1){
					ele.__config__.designData = resData
				} else {
					let outerFormData = JSON.parse(resData.json)
					outerFormData.need_flow = resData.need_flow
					ele.__config__.designData = outerFormData
				}
			}
			itemData = {type: ele.__config__.tagIcon, config: ele};
			if(ele.__vModel__){
				itemData.field = ele.__vModel__;
				itemData.value = '';
			}
			break;
		default:
			itemData = {type: ele.__config__.tagIcon, config: ele};
			if(ele.__vModel__){
				itemData.field = ele.__vModel__;
				itemData.value = '';
			}
	}

	//表单配置了填充规则的数据匹配 -- 数据自动填充
	const fillMatchKeys = Object.keys(fillMatch)
	const fillDataKeys = Object.keys(fillData)
	if(fillMatchKeys.length>0 && fillDataKeys.length>0){
		const formId = ele.__config__.formId;
		if(fillMatchKeys.includes(formId)){
			const matchVal = fillMatch[formId]
			if(fillDataKeys.includes(matchVal)){
				itemData.value = fillData[matchVal]
				//填充数据禁用输入
			}
		}
	}

	return itemData;
}

export default eleValidate;
