<!--
 * @Author: zx
 * @Date: 2022-07-15 09:37:07
 * @LastEditTime: 2022-10-17 11:17:02
 * @Description: 
 * @LastEditors: zx
-->
<template>
  <div v-if="showTree" class="tree" :style="!isChildren ? 'height: 100%' : ''">
    <div v-if="treeData && treeData.length" class="tree-data" :style="!isChildren ? 'overflow-y:auto' : ''">
      <div class="tree-item" v-for="item in treeData" :key="item.id" :style="item.expand ? 'border-bottom: none;padding-bottom: 0;' : ''">
        <div class="tree-item-label" :style="stateCheckObj?.id && item.id === stateCheckObj.id ? 'background: #eee;' : ''">
          <i v-if="treeProps.children && !item.expand && item[treeProps.children] && item[treeProps.children].length" class="iconfont iconjiantouxiangyou" @click="expendClick(item)" />
          <i v-else-if="treeProps.children && item.expand && item[treeProps.children] && item[treeProps.children].length" class="iconfont iconxiangxia1" @click="expendClick(item)" />
          <div class="seat" :style="!item[treeProps.children] || item[treeProps.children].length === 0 ? 'margin-Left: 22px' : ''"></div>
          <div v-if="checkBoxVisible && treeProps.children" class="square">
            <img v-if="!item.checkType || item.checkType === 'none'" src="./img/none.png" alt="" @click="changeStatus(item)">
            <img v-if="item.checkType === 'half'" src="./img/half.png" alt="" @click="changeStatus(item)">
            <img v-if="item.checkType === 'all'" src="./img/all.png" alt="" @click="changeStatus(item)">
          </div>
          <div v-if="checkBoxVisible" class="seat"></div>
          <div class="text" @click="checked(item)">
            {{ item[treeProps.label] }}
          </div>
        </div>
        <div v-if="treeProps.children && item.expand && item[treeProps.children] && Array.isArray(item[treeProps.children]) && item[treeProps.children].length" class="tree-item-children">
          <MobileSelectTree 
            :data="item[treeProps.children]" 
            :checkObj="stateCheckObj" 
            :element="element"
            :defaultProps="treeProps"
            :checkbox="checkBoxVisible"
            :is-children="true" 
            :show-list="showList"  
            @childrenStatusChange="childrenStatusChange" 
            @stateCheckClick="stateCheckClick" 
          />
        </div>
      </div>
    </div>
    <div v-if="(!treeData || treeData.length === 0) && !isChildren" class="tree-empty">
      <van-empty description="暂无数据" />
    </div>
    <div v-if="treeData && treeData.length && !isChildren" class="submit">
      <van-button type="primary" style="width: 100%" size="small" @click="submitData()">
        确认
      </van-button>
    </div>
  </div>
</template>
<script>
  import { defineComponent, onMounted, reactive, toRefs, watch, onUnmounted, computed } from 'vue'
  import { Empty, Button } from 'vant'
  import { dataInterface } from '@/apis/data/index';
  import eventBus from '@/plugins/eventBus';
  import { Notify } from 'vant';
  import useStore from '@/store'
  import { judgingEventLogic } from '@/utils/tools';
  export default defineComponent({
  props: {
    /* 传入显示的树状数据 */
    data: {
      type: Array,
      default: () => []
    },
    /* 树状数据的显示的key及children的key值 */
    defaultProps: {
      type: Object,
      default: () => {}
    },
    /* 回显的数据（会触发一次回调） */
    showList: {
      type: Array,
      default: () => []
    },
    /* 默认开启多选 */
    checkbox: {
      type: Boolean,
      default: false
    },
    /* （使用组件不传此数据）循环嵌套数据使用，用来区分外界传入和树状数据递归 */
    isChildren: {
      type: Boolean,
      default: false
    },
    /* （使用组件时不传此数据）循环嵌套数据使用，当前单选选中的数据 */
    checkObj: {
      type: Object,
      default: () => {}
    },
		element: {
			type: Object,
			default: () => {}
		}
  },
  components: {
    'van-empty': Empty,
    'van-button': Button,
  },
  setup(props, context) {
    const store = useStore
    const componentData = computed(() => {
      return store.state['componentData']
    })
    const { emit } = context
    const state = reactive({
      treeData: [],
      copyTreeData: [],
      showTree: false,
      emitData: [],
      emitHalfData: [],
      stateCheckObj: {},
      treeProps: {},
      view_uuid: '',
      object_uuid: '',
      checkBoxVisible: false,
      getListLoading: false,
    })
    watch(
      () => props.checkObj,
      (val) => {
        state.stateCheckObj = val ? val : {}
      }
    )
    watch(
      () => props.checkbox,
      (val) => {
        if (props.isChildren) {
          state.checkBoxVisible = val
        }
      }
    )
    watch(
      () => props.data,
      (val) => {
        if (val && val.length) {
          state.treeData = val
          const tree = (list) => {
            if (list && list.length) {
              list.forEach(element => {
                if (!element.expand) {
                  element.expand = false
                }
                if (!element.checkType) {
                  element.checkType = 'none'
                }
                if (element[state.treeProps.children] && element[state.treeProps.children].length) {
                  tree(element[state.treeProps.children])
                }
              })
            }
          }
          tree(state.treeData)
        }
      }
    )
    
    const unique = (arr, attrName) => {
      const res = new Map()
      return arr.filter(
        (a) => !res.has(a[attrName]) && res.set(a[attrName], 1)
      )
    }
    const getList = async (params) => {
      if (props.isChildren) {
        return
      }
      const configObj = {
        __method_name__: 'dataList',
        object_uuid: props.element.database.objectData.uuid,
        view_uuid: props.element.database.viewData.uuid,
        page: 1,
        size: 1000
      }
      if (props.element.database.paramsConfig && Array.isArray(props.element.database.paramsConfig) && props.element.database.paramsConfig.length) {
        const obj = {}
        props.element.database.paramsConfig.forEach(element => {
          if (element.systemKey === 'targetArchi') {
            obj[element.key] = element.systemObject[element.systemCode]
          }
        });
        Object.assign(configObj, obj)
      }
      if (params && params.length) {
        if (configObj.search && configObj.search.length) {
          configObj.search = configObj.search.concat(params)
          configObj.search = unique(configObj.search, 'field_uuid')
        } else {
          configObj.search = params
        }
      }
      if(state.getListLoading) {
        return
      }
      state.getListLoading = true
      const res = await dataInterface(configObj)
      state.getListLoading = false
      if (res && res.status === 200) {
        /* 字段值 */
        state.metadata = res.data.metadata
        store.commit('modifyComponent', {
          component: {
            ...props.element,
            database: {
              ...props.element.database,
              metadata: state.metadata
            }
          },
          containerId: null,
          isModify: true
        });
        let idKey = ''
        for(let key in state.metadata) {
          if (state.metadata[key] === 'id') {
            idKey = key
          }
        }
        if (res.data.data) {
          state.treeData = props.element.database.mobileTreeInfo.isPage ? res.data.data.data : res.data.data
          if (state.treeData && state.treeData.length) {
            const loop = (array) => {
              array.forEach(element => {
                element.id = element[idKey]
                if (state.treeProps.children && element[state.treeProps.children] && Array.isArray(element[state.treeProps.children]) && element[state.treeProps.children].length) {
                  loop(element[state.treeProps.children])
                }
              });
            }
            loop(state.treeData)
          }
        }
        state.showTree = false
        const tree = (list, num) => {
          if (list && list.length) {
            list.forEach(element => {
              element.expand = true
              if (!props.isChildren) {
                element.level = num
              }
              if (!element.checkType) {
                element.checkType = 'none'
              }
              if (state.treeProps.children && element[state.treeProps.children] && Array.isArray(element[state.treeProps.children]) && element[state.treeProps.children].length) {
                tree(element[state.treeProps.children], num+1)
              }
            })
          }
        }
        tree(state.treeData, 1)
        if (props.showList.length && state.treeData.length) {
          for (let index = 0; index < props.showList.length; index++) {
            const item = props.showList[index];
            state.treeData.forEach(element => {
              if (element.id === item.id) {
                element.checkType = 'all'
                state.stateCheckObj = element
                if (state.treeProps.children && element[state.treeProps.children] && Array.isArray(element[state.treeProps.children]) && element[state.treeProps.children].length) {
                  element[state.treeProps.children].forEach(chidItem => {
                    chidItem.checkType = 'all'
                  });
                }
                emit('childrenStatusChange', state.treeData, element.id)
              }
            })
          }
        }
        state.treeData = JSON.parse(JSON.stringify(state.treeData))
        if (props.element.database.mobileTreeInfo) {
          const boo = props.element.database.mobileTreeInfo.checkBoxVisible
          state.checkBoxVisible = boo ? boo : false
        }
        state.showTree = true
      }
    }
    watch(
      () => props.element,
      (val) => {
        if (val && val.database) {
          if (val.database.viewData.uuid && val.database.objectData.uuid) {
            if (val.database.viewData.uuid === state.view_uuid && val.database.objectData.uuid === state.object_uuid) {
              return
            }
            state.view_uuid = val.database.viewData.uuid
            state.object_uuid = val.database.objectData.uuid
            getList()
          }
          if (val.database.mobileTreeInfo && val.database.mobileTreeInfo.labelKey) {
            state.treeProps = {
              children: val.database.mobileTreeInfo.childrenKey || '',
              label: val.database.mobileTreeInfo.labelKey
            }
          } 
          if (val.database.mobileTreeInfo) {
            const boo = val.database.mobileTreeInfo.checkBoxVisible
            state.checkBoxVisible = boo ? boo : false
          }
        }
      }
    )
    onUnmounted(() => {
      if (!props.isChildren) {
        eventBus.$off('searchClick')
      }
    })
    onMounted( async () => {
      /* 循环递归渲染的子级组件数据 */
      if (props.isChildren) {
        state.treeProps = props.defaultProps
        state.treeData = props.data
        state.checkBoxVisible = props.checkbox
      } else {
        // 筛选触发请求
        eventBus.$off('searchClick');
        eventBus.$on('searchClick', (search) => {
          getList(search)
        });
      }
      if (!props.isChildren && props.element && props.element.database && props.element.database.mobileTreeInfo) {
        if (
          props.element.database.mobileTreeInfo.labelKey
        ) {
          state.treeProps = {
            children: props.element.database.mobileTreeInfo.childrenKey || '',
            label: props.element.database.mobileTreeInfo.labelKey
          }
        }
        if (props.element.database.mobileTreeInfo.checkBoxVisible) {
          state.checkBoxVisible = props.element.database.mobileTreeInfo.checkBoxVisible
        } else {
          state.checkBoxVisible = false
        }
      }
      if (
        !props.isChildren &&
        props.element && 
        props.element.database && 
        props.element.database.viewData && 
        props.element.database.objectData && 
        props.element.database.viewData.uuid && 
        props.element.database.objectData.uuid
      ) {
        eventBus.$on('databaseTrigger', (data) => {
          if (props.element && (data.componentId === props.element.id) && data.isInit) {
            getList()
          }
        })
        await getList()
      }
      const tree = (list, num) => {
        if (list && Array.isArray(list) && list.length) {
          list.forEach(element => {
            element.expand = true
            if (!props.isChildren) {
              element.level = num
            }
            if (!element.checkType) {
              element.checkType = 'none'
            }
            if (element[state.treeProps.children] && element[state.treeProps.children].length) {
              tree(element[state.treeProps.children], num+1)
            }
          })
        }
      }
      tree(state.treeData, 1)
      if (props.showList.length && state.treeData.length) {
        for (let index = 0; index < props.showList.length; index++) {
          const item = props.showList[index];
          state.treeData.forEach(element => {
            if (element.id === item.id) {
              element.checkType = 'all'
              state.stateCheckObj = element
              if (state.treeProps.children && element[state.treeProps.children] && Array.isArray(element[state.treeProps.children]) && element[state.treeProps.children].length) {
                element[state.treeProps.children].forEach(chidItem => {
                  chidItem.checkType = 'all'
                });
              }
              emit('childrenStatusChange', state.treeData, element.id)
            }
          })
        }
      }
      state.treeData = JSON.parse(JSON.stringify(state.treeData))
      state.showTree = true
      if (!props.isChildren) {
        state.copyTreeData = JSON.parse(JSON.stringify(state.treeData))
        // 监听过滤名称
        eventBus.$on('routeTopInputSearch', (val) => {
          if (val && state.treeData && state.treeData.length) {
            state.treeData = mapTree(val, state.copyTreeData, state.treeProps.label, state.treeProps.children)
          } else {
            state.treeData = JSON.parse(JSON.stringify(state.copyTreeData))
          }
        });
      }
    })
    /* 搜索数据结构方法 */
    const mapTree = (value, arr, key, childrenKey) => {
        let newarr = [];
        arr.forEach(element => {
            if (element[key].indexOf(value) > -1) { // 判断条件
                newarr.push(element);
            } else {
            if (element[childrenKey] && element[childrenKey].length > 0) {
                let redata = mapTree(value, element[childrenKey], key, childrenKey);
                if (redata && redata.length > 0) {
                    let obj = {
                        ...element,
                        [childrenKey]: redata
                    };
                        newarr.push(obj);
                    }
                }
            }
        });
        return newarr;
    }
    const expendClick = (data) => {
      data.expand = !data.expand
      state.treeData = JSON.parse(JSON.stringify(state.treeData))
    }
    const changeStatus = (row) => {
      const loop = (list, type) => {
        list.forEach(element => {
          element.checkType = type
          if (element[state.treeProps.children] && element[state.treeProps.children].length) {
            loop(element[state.treeProps.children], type)
          }
        });
      }
      const loopTree = (list) => {
        list.forEach(element => {
          if (+element.id === +row.id) {
            if (!element.checkType || element.checkType === 'half' || element.checkType === 'none') {
              element.checkType = 'all'
              row.checkType = 'all'
              if (element[state.treeProps.children] && element[state.treeProps.children].length) {
                loop(element[state.treeProps.children], 'all')
              }
            } else if (element.checkType === 'all') {
              element.checkType = 'none'
              row.checkType = 'none'
              if (element[state.treeProps.children] && element[state.treeProps.children].length) {
                loop(element[state.treeProps.children], 'none')
              }
            }
          }
        });
      }
      loopTree(state.treeData)
      state.treeData = JSON.parse(JSON.stringify(state.treeData))
      changeAllStatus()
      emit('childrenStatusChange', state.treeData, row.id)
      childrenStatusChange(state.treeData, row.id)
    }
    /* 修改当前全局数据的选中办选未选状态 */
    const changeAllStatus = () => {
      const loopTableData = (arr) => {
        arr.forEach(element => {
          if (element[state.treeProps.children] && element[state.treeProps.children].length) {
            const noneArr = []
            const halfArr = []
            const allArr = []
            element[state.treeProps.children].forEach(item => {
              if (!item.checkType || item.checkType === 'none') {
                noneArr.push(item.id)
              } else if (item.checkType === 'half') {
                halfArr.push(item.id)
              } else if (item.checkType === 'all') {
                allArr.push(item.id)
              }
            });
            if ((halfArr.length) || (allArr.length && noneArr.length)) {
              element.checkType = 'half'
            } else if (halfArr.length === 0 && allArr.length && noneArr.length === 0 ) {
              element.checkType = 'all'
            } else {
              element.checkType = 'none'
            }
            loopTableData(element[state.treeProps.children])
          }
        });
      }
      if (state.treeData && state.treeData.length) {
        loopTableData(state.treeData)
      }
    }
    const childrenStatusChange = (list, id) => {
      let objId = null
      const loopTableData = (arr) => {
        arr.forEach(element => {
          if (element[state.treeProps.children] && element[state.treeProps.children].length) {
            element[state.treeProps.children].forEach(item => {
              if (+item.id === +id) {
                element[state.treeProps.children] = list
                objId = element.id
              }
            });
          }
        });
      }
      if (state.treeData && state.treeData.length) {
        loopTableData(state.treeData)
      }
      changeAllStatus()
      state.treeData = JSON.parse(JSON.stringify(state.treeData))
      state.treeData.forEach(element => {
        if (+element.id === +objId) {
          emit('childrenStatusChange', state.treeData, objId)
        }
      });
      if (state.treeData[0].level !== 1) {
        return
      }
      const allArr = []
      const halfArr = []
      const loop = (arr) => {
        arr.forEach(element => {
          if (element && element.checkType && (element.checkType === 'all' || element.checkType === 'half')) {
            if (element.checkType === 'all') {
              allArr.push(element)
            } else if (element.checkType === 'half') {
              halfArr.push(element)
            }
          }
          if (element[state.treeProps.children] && element[state.treeProps.children].length) {
            loop(element[state.treeProps.children])
          }
        });
      }
      loop(state.treeData)
      state.emitData = allArr
      state.emitHalfData = halfArr
    }
    /* 单选点击选择数据 */
    const checked = (data) => {
      if (state.checkBoxVisible) {
        return
      }
      state.stateCheckObj = data
      emit('stateCheckClick', data)
      emit('check', [data])
			store.commit('modifyComponent', {
				component: {
					...props.element,
					resolveData: data
				},
				containerId: null,
				isModify: true
			});
    }
    const submitData = () => {
      if (
        props.element && 
        props.element.actionConfig && 
        props.element.actionConfig.sureClick && 
        props.element.actionConfig.sureClick.eventList && 
        props.element.actionConfig.sureClick.eventList.length
      ) {
        const eventList = props.element.actionConfig.sureClick.eventList;
        if (eventList && eventList.length) {
            eventList.forEach((el) => {
              el.eventList.forEach(ele => {
                if (ele.key === 'click') {
                  ele.effects.forEach((effect) => {
                    let logicBoolean = true
                    if (effect.logics.length) {
                      logicBoolean = judgingEventLogic(effect.logics, componentData || []);
                    }
                    if (logicBoolean) {
                      store.commit('triggerEvents', {
                        config: {
                          ...ele,
                        ...effect
                        },
                        element: props.element
                      });
                    }
                  });
                }
              })
            });
          }
        return
      }
      const mobileTreeInfo = props.element.database.mobileTreeInfo
      if (!mobileTreeInfo.checkBoxVisible) {
        if (state.stateCheckObj && state.stateCheckObj[state.treeProps.label]) {
          let paramsStr = ''
          if (mobileTreeInfo.params && mobileTreeInfo.params.length) {
            mobileTreeInfo.params.forEach(element => {
              for(let key in state.stateCheckObj) {
                if (element.value === key) {
                  paramsStr += '&' + element.key + '=' + state.stateCheckObj[key]
                }
              }
            });
          }
          if (+mobileTreeInfo.type === 1) {
            window.location.href =  window.location.origin + '/modify-page?id=' + mobileTreeInfo.page_id + '&pageUUID=' + mobileTreeInfo.page_uuid + paramsStr
          } else if (+mobileTreeInfo.type === 2) {
            Notify({ type: 'succes', message: '跳转成功,但编辑器暂不支持跳转移动端功能页面!' });
          }
        } else {
          Notify({ type: 'danger', message: '请选择数据!' });
          return
        }
      } else {
        if (state.emitData && state.emitData.length) {
          if (+mobileTreeInfo.type === 1) {
            window.location.href =  window.location.origin + '/modify-page?id=' + mobileTreeInfo.page_id + '&pageUUID=' + mobileTreeInfo.page_uuid
          } else if (+mobileTreeInfo.type === 2) {
            Notify({ type: 'succes', message: '跳转成功,编辑器暂不支持跳转移动端功能页面!' });
          }
        } else {
          Notify({ type: 'danger', message: '请选择数据!' });
          return
        }
      }
      // return
      // window.location.href =  window.location.origin + '/modify-page?pageUUID=' + 'page62cfe8b1c8b96'
    }
    const stateCheckClick = (data) => {
      state.stateCheckObj = data
      emit('stateCheckClick', data)
    }
    return {
      ...toRefs(state),
      expendClick,
      changeStatus,
      childrenStatusChange,
      checked,
      submitData,
      stateCheckClick
    }
  }
 })
</script>
<style lang='less' scoped>
.tree {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  color: #939599;
  display: flex;
  flex-direction: column;
  .tree-data {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    .tree-item {
      width: 100%;
      height: auto;
      text-align: left;
      padding: 13px 0;
      box-sizing: border-box;
      border-bottom: 1px solid #e8eaec;
      .tree-item-label {
        width: 100%;
        padding: 0 10px;
        line-height: 30px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        i {
          font-size: 18px;
        }
        .seat {
          width: 0;
          padding-left: 4px;
        }
        .square {
          width: 20px;
          height: 20px;
          img {
            width: 100%;
          }
        }
      }
      .tree-item-children {
        width: 100%;
        height: auto;
        padding: 0 10px;
        box-sizing: border-box;
      }
    }
  }
  
  .tree-empty {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .submit {
    width: 100%;
    height: 48px;
    box-sizing: border-box;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
