<!--
 * @Author: your name
 * @Date: 2021-12-02 15:32:01
 * @LastEditTime: 2022-03-28 19:59:59
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \nanhu-micro\src\views\components\bindModel\index.vue
-->
<template>
    <!-- 绑定图纸 -->
    <div>
			<slot name="showType"></slot>
			<el-drawer
				custom-class="no-padding"
				:visible.sync="show"
				size="100%"
				append-to-body
				:with-header="false"
			>
					<BindDrawing
							v-if="show"
							:drawing-data="drawingData"
							:search="search"
							@back="show = false"
							@set-data="setData"
					/>
			</el-drawer>
		</div>
</template>

<script>
import BindDrawing from "./index.vue";
export default {
    name: "ButtonBind",
    components: { BindDrawing },
    props: {
        // 回显数据
        drawingData: {
            type: Array,
            default: () => [],
        },
		search:{
			type: Array,
            default: () => [],
		}
    },
		data() {
			return {
				show: false
			};
		},
		methods: {
			setData(data) {
				this.$emit("set-data", data);
				this.show = false;
			}
		}
		
};
</script>
