<!--
    @name: widget-time
    @description：widget-time
    @author: ZengWei
    @date: 2022-03-25 09:36
-->
<template>
  <div>
    <el-time-picker
      v-if="!(disabled || element.config.disabled)"
      v-model="element.value"
      :disabled="disabled || element.config.disabled"
      :type="element.config.type"
      :format="element.config.format"
      :value-format="element.config['value-format']"
      :placeholder="element.config.placeholder"
      style="width: 100%"
    >
    </el-time-picker>
    <p class="reshow-text" v-else>{{ element.value || '-' }}</p>
  </div>
</template>

<script>
import {TimePicker} from 'element-ui'
import formItemMinix from "@/custom-component/form/newParser/scripts/formItemMinix";

export default {
  name: "WidgetTime",
  components: {
    'el-time-picker':TimePicker
  },
  mixins: [formItemMinix],
  props: ['element', 'disabled', 'displayData'],
  data() {
    return {}
  },
  computed: {
    reShowVal() {
      if(this.element.value) {
          return this.element.value;
      } else {
        return '-';
      }
    }
  },
  methods: {},
}
</script>

<style scoped>

</style>
