<!--
    @name: ques-assemble
    @description：ques-assemble
    @author: ZengWei
    @date: 2022-04-01 11:21
-->
<template>
  <div class="widget-compose">
    <div class="q-title-wrap" v-if="!element.config.__ques__.showTitle">
      <div class="seq-num">
        <span v-if="element.config.__config__.required" class="required">*</span>
      </div>
      <div class="q-title">
        <span class="ques-type">[组合题]</span>
        {{ element.config.__config__.label }}
      </div>
      <div
        class="q-title"
        v-if="element.config.__ques__.quesShowDesc"
        v-html="element.config.__ques__.quesDesc">
      </div>
    </div>
    <div class="q-option-list">
      <el-form
        ref="rowFormRef"
        :model="element"
      >
        <template v-for="(item, index) in element.child">
          <el-form-item
            :key="`sub${index}`"
            :prop="`child.${index}.value`"
            :rules="item.validate"
            label-width="0px"
          >
            <QuestionItem :element="item" :number="index" />
          </el-form-item>
        </template>
      </el-form>
    </div>
  </div>
</template>

<script>
import formValidate from "@/custom-component/form/quesParser/scripts/quesParserFormat"
export default {
  name: "ques-compose",
  components: {
    QuestionItem: () => import('@/custom-component/form/quesParser/components/QuestionItem.vue'),
  },
  props: ['element','number'],
  data() {
    return {}
  },
  watch: {
    'element.child': {
      handler(n) {
        this.element.score = 0
        this.element.value = []
        n.map(el => {
          this.element.score += (isNaN(el.score) ? 0 : Number(el.score))
          if (el.field) {
            this.element.value.push(el)
          } else if (Array.isArray(el.value)) {
            this.element.value.push(...el.value)
          }
        })
      },
      deep: true
    }
  },
  created() {
    this.initChildQuestion()
  },
  methods: {
    initChildQuestion() {
      this.$set(this.element, 'child', formValidate(this.element.config.__slot__.children))
      this.$set(this.element, 'value', [])
    }
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/css/questionItem';
</style>
