const requireComponent = require.context('./', false, /\w+\.vue$/)

const comps = {}

// eslint-disable-next-line array-callback-return
requireComponent.keys().map(fileName => {
	const comp = requireComponent(fileName).default
	comps[comp.name] = comp
})


export default comps
