<!--
 * @Description: 编辑器右击菜单
 * @Author: luocheng
 * @Date: 2021-08-11 17:14:58
 * @LastEditors: Shiltin 18580045074@163.com
 * @LastEditTime: 2022-08-19 17:57:18
-->
<template>
	<div class="editor-menu" v-show="editorMenuShow"
    :style="{
      left: editorMenuPosition.left + 'px',
      top: editorMenuPosition.top + 'px'
    }"
  >
		<ul @mouseup="onMouseUp">
			<template v-if="curComponent">
				<template v-if="!curComponent.isLock">
					<li @click="onCopy">复制</li>
					<li @click="onPaste">粘贴</li>
					<li @click="onCut">剪切</li>
					<li @click="onDelete" class="remove">删除</li>
					<template v-if="editorType === 'dataview' || editorType === 'console'">
						<li @click="onToggleLock(true)">锁定</li>
						<template v-if="componentData.length > 1">
							<li @click="onTopComponent"
								:class="{
									'disable-btn': componentData.length < 2
								}"
							>置顶</li>
							<li @click="onBottomComponent"
								:class="{
									'disable-btn': componentData.length < 2
								}"
							>置底</li>
							<li @click="onUpComponent"
								:class="{
									'disable-btn': componentData.length < 2
								}"
							>上移一层</li>
							<li @click="onDownComponent"
								:class="{
									'disable-btn': componentData.length < 2
								}"
							>下移一层</li>
						</template>
					</template>
				</template>
				<li v-else-if="editorType === 'dataview' || editorType === 'console'" @click="onToggleLock(false)">解锁</li>
			</template>
			<li v-else @click="onPaste">粘贴</li>
		</ul>
	</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
	name: 'EditorMenu',
	computed: {
		...mapState([
			'editorMenuShow', // 右击菜单
      'editorMenuPosition', // 菜单位置
			'curComponent', // 当前编辑菜单
			'componentData', // 图层列表
			'editorType', // 编辑器类型
		])
	},
	methods: {
		/**
		 * @desc: 点击时候保持选中状态不变
		 */
		onMouseUp() {
			this.$store.commit('setClickComponentStatus', true);
		},
		/**
		 * @desc: 复制
		 */
		onCopy() {
			this.$store.commit('copyComponent');
      this.actionEnd();
    },
		/**
		 * @desc: 粘贴
		 */
		onPaste() {
			this.$store.commit('pasteComponent', true);
      this.$store.commit('recordSnapshot');
      this.actionEnd();
    },
		/**
		 * @desc: 剪切
		 */
		onCut() {
			this.$store.commit('cutComponent');
      this.actionEnd();
    },
		/**
		 * @desc: 删除
		 */
		onDelete() {
			this.$confirm('是否确认删除组件?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
				this.$store.commit('deleteComponent');
      });
    },
		/**
		 * @desc: 锁定&解除锁定
		 * @param {Boolean} status 锁定状态
		 * @return {*}
		 */
		onToggleLock(status) {
			this.$store.commit('toggleLock', status);
      this.actionEnd();
    },
		/**
		 * @desc: 置顶
		 */
		onTopComponent() {
			this.$store.commit('topComponent');
      this.actionEnd();
    },
		/**
		 * @desc: 置底
		 */
		onBottomComponent() {
			this.$store.commit('bottomComponent');
      this.actionEnd();
    },
		/**
		 * @desc: 上移一层
		 */
		onUpComponent() {
			this.$store.commit('upComponent');
      this.actionEnd();
    },
		/**
		 * @desc: 下移一层
		 */
		onDownComponent() {
			this.$store.commit('downComponent');
      this.actionEnd();
    },
    /**
     * @desc: 操作完毕关闭菜单
     */
    actionEnd() {
      this.$store.commit('hideEditorMenu');
    }
	}
};
</script>

<style lang="less" scoped>
.editor-menu {
	position: absolute;
	z-index: 100000;
	ul {
		border: 1px solid #e4e7ed;
		border-radius: 4px;
		background-color: #fff;
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
		box-sizing: border-box;
		margin: 5px 0;
		padding: 6px 0;
		li {
			font-size: 14px;
			padding: 0 20px;
			position: relative;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			color: #606266;
			height: 34px;
			line-height: 34px;
			box-sizing: border-box;
			cursor: pointer;
			font-weight: 500;
			&:hover {
				background-color: #f5f7fa;
        color: @theme;
			}
			&.disable-btn{
				color: @disableColor;
				cursor: not-allowed;
			}
			&.remove{
				color: @dangerColor;
			}
		}
	}
}
</style>
