<template>
  <div style="height: 100%; position: relative">
    <template v-if="isSingle">
      <iframe
        :src="pano_path"
        width="100%"
        height="100%"
        frameborder="0"
        scrolling="yes"
      ></iframe>
    </template>
    <template v-if="!isSingle">
      <div :class="[isOrientation ? 'left' : 'nleft', 'commomCompare']">
        <p @click="selectLeftShow = !selectLeftShow" class="name-css">
          <span>{{ selectLeftPano.pano_name }}</span>
          <i
            class="iconfont iconsaidi-shaixuan-xiaojiantou1"
            v-show="selectLeftShow"
          ></i>
          <i
            class="iconfont iconsaidi-shaixuan-xiaojiantou2"
            v-show="!selectLeftShow"
          ></i>
        </p>
        <div v-show="selectLeftShow" class="commomCompareCss">
          <div v-for="(cur, index) in panoData" :key="index">
            <div @click="selectLeft(cur)" class="toggle-name" align-center>
                <p :style="cur.id == selectLeftPano.id ? 'color:#1989fa' : ''">
                  <span>{{ cur.pano_name }}</span
                  ><span style="margin-left: 10px">{{ cur.pano_time }}</span>
                </p>
            </div>
          </div>
        </div>
      </div>
      <div :class="[isOrientation ? 'right' : 'nright', 'commomCompare']">
        <p @click="selectRightShow = !selectRightShow" class="name-css">
          <span>{{ selectRightPano.pano_name }}</span>
          <i
            class="iconfont iconsaidi-shaixuan-xiaojiantou1"
            v-show="selectRightShow"
          ></i>
          <i
            class="iconfont iconsaidi-shaixuan-xiaojiantou2"
            v-show="!selectRightShow"
          ></i>
        </p>
        <div v-show="selectRightShow" class="commomCompareCss">
          <div v-for="(cur, index) in panoData" :key="index">
            <div
              @click="selectRight(cur)"
              class="toggle-name"
              align-center
            >
              <p :style="cur.id == selectRightPano.id ? 'color:#1989fa' : ''">
                <span>{{ cur.pano_name }}</span
                ><span style="margin-left: 10px">{{ cur.pano_time }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <iframe
        :src="comparePath"
        frameborder="0"
        style="width: 100%; height: 100%"
      ></iframe>
    </template>
  </div>
</template>

<script>
import { getComponentById, initParams } from "@/utils/tools";
import { dataInterface } from "@/apis/data";
import { mapState } from "vuex";
import eventBus from "@/plugins/eventBus";
export default {
  name: "",
  props: {
    element: {
      type: Object,
      default: () => {},
    },
  },
  components: {},
  data() {
    return {
      panoData: [], // 列表数据
      isSingle: true, // 单个或者多个展示开关
      /* 单个展示相关 */
      pano_path: "",
      /* 多个展示相关 */
      isOrientation: true,
      screenHW: window.orientation,
      selectLeftShow: false,
      selectLeftPano: {},
      selectRightShow: false,
      selectRightPano: {},
      parentIndex: 0,
      rightparentIndex: 0,
    };
  },
  computed: {
    ...mapState(["componentData"]),
    comparePath() {
      return `https://pano.bimcc.net/splitscreen?pano_1=${this.selectLeftPano.pano_path}&pano_2=${this.selectRightPano.pano_path}`;
    },
    // 参数配置
    paramsConfig() {
      return this.element?.paramsConfig || [];
    },
    // 数据仓库配置
    database() {
      return this.element && this.element.database;
    },
  },
  methods: {
    selectLeft(item) {
      this.selectLeftPano = item;
    },
    selectRight(item) {
      this.selectRightPano = item;
    },
    openParent(index) {
      if (index === this.parentIndex) {
        this.parentIndex = -1;
      } else {
        this.parentIndex = index;
      }
    },
    openRightParent(index) {
      if (index === this.rightparentIndex) {
        this.rightparentIndex = -1;
      } else {
        this.rightparentIndex = index;
      }
    },
    /* 数据处理 */
    /**
     * @desc: 验证
     * @param {Object} database 数据仓库的绑定
     */
    validDatabase(database) {
      if (!database || typeof database !== "object") return false;
      if (!database.objectData) return false;
      if (!database.viewData && !database.relationData) return false;
      return true;
    },
    /**
     * @desc: 判断依赖的参数是否在当前架构下启用(@凌志华树形图架构限制)
     * @param {String} componentId
     * @return {Boolean}
     */
    paramsSameArchi(componentId) {
      const comp = getComponentById(this.componentData, componentId);
      if (!comp) return false;
      const targetArchi = this.$GetTargetArchi("archiType");
      if (
        comp &&
        comp.archiLimit &&
        comp.archiLimit.length &&
        comp.archiLimit.includes(targetArchi)
      ) {
        return true;
      }
      return false;
    },
    /**
     * @desc: 判断是否存在依赖其他组件的取值
     */
    hasComponentParam() {
      if (!this.database.paramsConfig || !this.database.paramsConfig.length) {
        return false;
      }
      for (let i = 0; i < this.database.paramsConfig.length; i++) {
        const {
          componentId = "",
          key = "",
          sourceType = "",
        } = this.database.paramsConfig[i];
        if (
          (key !== "search" || !componentId.includes("CommonForm")) &&
          sourceType !== "url"
        ) {
          // componentId.includes('CommonTableContainer')兼容跨页请求的动态判定
          // 补充不同架构问题
          if (
            !componentId.includes("CommonTableContainer") &&
            this.paramsSameArchi(componentId)
          ) {
            return true;
          }
        }
      }
      return false;
    },
    /**
     * @desc: 获取渲染列表
     * @param {Object} database 数据配置对象
     * @param {Array} search 搜索
     */
    getList(database, search = [], params = {}) {
      this.search = Array.isArray(search) ? search : [];
      this.sourceData = {};
      if (!this.validDatabase(database)) return;
      this.loading = true;
      const paramsObj = {
        ...params,
      };
      // console.log('获取渲染列表______表格容器', params, paramsObj);
      // 配置
      let __method_name__ = "dataList";
      const mapping = database.mapping;
      let configObj = null;
      if (mapping === "object") {
        configObj = {
          __method_name__,
          object_uuid: database.objectData.uuid,
          view_uuid: database.viewData.uuid,
          transcode: 0,
          ...paramsObj,
          search,
        };
      } else if (mapping === "relation") {
        __method_name__ = "relationList";
        configObj = {
          __method_name__: "relationList",
          object_uuid: database.objectData.uuid,
          relationship_uuid: database.relationData.uuid,
          ...paramsObj,
          search,
        };
      }
      // 获取表格数据
      dataInterface(configObj)
        .then((res) => {
          if (res && res.status === 200) {
            this.panoData = []
            // 验证数据格式
            if (
              res.data &&
              res.data.data &&
              Array.isArray(res.data.data.data) && 
              res.data.data.data.length
            ) {
              res.data.data.data.map((item) => {
                this.panoData.push({
                  ...item,
                  pano_name: item.pano_name,
                  pano_path: item.pano_path,
                })
              })
              if (this.isSingle) {
                this.pano_path = `https://pano.bimcc.net/pc/preView?id=${res.data.data.data[0].pano_path}`
              } else {
                this.selectLeftPano = res.data.data.data[0]
                this.selectRightPano = res.data.data.data[0]
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    /* 请求绑定的仓库接口 */
    if (this.element?.statusConfig?.isContrast) {
      this.isSingle = false
    }
    const panoMobileConfig = this.element?.panoMobileConfig
    if (this.isSingle && panoMobileConfig && panoMobileConfig?.componentId && panoMobileConfig?.panoId) {
      const comp = getComponentById(this.componentData, panoMobileConfig.componentId);
      this.pano_path = `https://pano.bimcc.net/pc/preView?id=${comp?.resolveData[panoMobileConfig.panoId]}`
      return
    }
    if (this.database && !this.hasComponentParam()) {
      const {
        search = [],
        param = {},
        canPost,
      } = initParams(
        this.element?.database?.paramsConfig || [],
        this.isGroup,
        this.componentData,
        this.groupComponents
      );
      if (!canPost) return;
      this.getList(this.database, search, param);
    }
    this.metadata = this.element.metadata || [];
  },
  mounted() {
    if (
      this.panoData &&
      this.panoData.length &&
      this.panoData[0].panos &&
      this.panoData[0].panos.length
    ) {
      //默认展示一副全景
      this.selectLeftPano = this.panoData[0].panos[0];
      this.selectRightPano = this.panoData[0].panos[0];
    }
    window.onresize = () => {
      return (() => {
        // 把横竖屏的变化赋值
        if (window.orientation == 180 || window.orientation == 0) {
          this.isOrientation = false;
        } else if (window.orientation == 90 || window.orientation == -90) {
          this.isOrientation = true;
        }
      })();
    };
    // 监听请求
    // 配置关联参数的容器才需要监听
    eventBus.$on("databaseTrigger", (data) => {
      if (data.parentId && data.parentId !== this.element.id) return false;
      // 配置时候触发请求
      if (data.componentId === this.element.id && data.isInit) {
        const {
          search = [],
          param = {},
          canPost,
        } = initParams(
          this.element?.database?.paramsConfig || [],
          this.isGroup,
          this.componentData,
          this.groupComponents
        );
        if (!canPost) return;
        this.getList(this.element.database, search, param);
        return;
      }
      // 点击操作时候不更新数据
      if (data.noUpdate) return;
      const { paramsConfig } = this.element.database;
      if (!paramsConfig || !paramsConfig.length) return;
      // 以下步骤是为了避免有多个来源的search需要进行differ 避免检索结果错误情况
      let {
        search = [],
        param = {},
        canPost,
      } = initParams(
        this.element?.database?.paramsConfig || [],
        this.isGroup,
        this.componentData,
        this.groupComponents
      );
      if (!canPost) return;
      let isTarget = false;
      paramsConfig.forEach((ele) => {
        if (ele.componentId === data.componentId) {
          isTarget = true;
        }
      });
      if (!isTarget && !data.isUpdate) return;
      this.param = param;
      this.getList(this.element.database, search, param);
    });
  },
  beforeDestroy() {
    eventBus.$off("databaseTrigger");
  },
};
</script>

<style scoped>
.toggle-name {
  display: flex;
}
.name-css {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  padding: 5px;
  margin-bottom: 5px;
  min-width: 150px;
}
.commomCompare {
  position: absolute;
}
.commomCompareCss {
  background: #fff;
  overflow: auto;
  line-height: 25px;
  font-size: 13px;
  min-width: 150px;
  max-height: 150px;
  padding: 0 6px;
}
.left {
  top: 10px;
  left: 10px;
}
.nleft {
  top: 10px;
  left: 10px;
}
.right {
  top: calc(50% + 10px);
  left: 10px;
}
.nright {
  top: 10px;
  right: 10px;
}
.nameStyle {
  font-size: 14px;
  font-weight: 400;
  color: #25282e;
}
</style>
