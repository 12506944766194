/*
 * @Author: zx
 * @Date: 2022-08-25 14:07:41
 * @LastEditTime: 2022-12-30 11:01:21
 * @Description: 
 * @LastEditors: 朱廷果 1028509503@qq.com
 */
import axios from 'axios'
import { getToken, setToken } from '@/utils/tools';
import { Message } from 'element-ui';
let baseUrl = 'https://saas-api.bimcc.net'
let request = axios.create({
	// 请求公共地址
    baseURL: baseUrl,
    // 超时时间
    timeout: 600000,
})

let metadatatoken = 'aba62ca1-c2ff-42af-9d3a-bbe5b02f7b06'
let contentType = 'application/json;charset=UTF-8'
let method = ''
// 请求拦截
request.interceptors.request.use(config => {
  method = config.method || 'get'
  config.headers.bimcctoken = getToken() || ''
  config.headers.metadatatoken = metadatatoken
  config.headers['dev-metadatatoken'] = getToken() || ''
  config.headers.Content = contentType
  return config
}, err => {
    // throw new Error(err)
    Promise.reject(err)
})
// 响应拦截
request.interceptors.response.use(res => {
  let {data} = res
  if (data.msg == 'SUCCESS') {
    return res
  }
  if (data.code == undefined) {
    return res
  } else if (data.code !== 200) {
    if (data.code === 401) {
      Message({
        message: data.msg || '未登录或登录失效，请登录',
        type: 'error'
      })
      sessionStorage.clear();
			localStorage.clear();
      setToken('')
      window.location.href = '/login'
    } else if (data.code === 422) {
      if (data.msg) {
        Message({
          message: data.msg,
          type: 'error'
        })
      }
    } else if (data.code === 500) {
      if (data.msg === 'Invalid Token') {
        sessionStorage.clear()
        setToken('')
        // window.location.href = '/login'
        Message({
          message: '未登录或登录失效，请登录!',
          type: 'error'
        })
      }
      Message({
        message: data.msg,
        type: 'error'
      })
    } else {
      if (data.msg) {
        Message({
          message: data.msg,
          type: 'error'
        })
      }
    }
    return res
  } else {
    if (data.msg && method != 'get') {
      // console.log(11, 'success');
    }
    return res
  }
}, error => {
  if (error.message) {
    Message({
      message: '服务器内部错误',
      type: 'error'
    })
  }
  return Promise.reject(error)
})

export default request