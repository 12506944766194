<!--
 * @Author: zx
 * @Date: 2022-05-30 10:41:47
 * @LastEditTime: 2022-08-09 14:53:18
 * @Description: 
 * @LastEditors: zx
-->
<template>
	<div
		class="common-container"
		:data-id="element.id"
		:style="statusConfigStyle"
	>
		<!-- v-loading="loading" -->
		<MobileComponentBox
			v-for="child in element.children"
			:isPreview="isPreview"
			:key="child.id"
			@click.stop="onClickBox(child)"
			:element="child"
			:pageId="pageId"
			:isGroup="isGroup"
			:groupComponents="groupComponents"
		>
		</MobileComponentBox>
	</div>
</template>
<script>
	import { reactive, toRefs, onMounted, defineComponent, watch, computed } from 'vue'
	import { dataInterface } from '@/apis/data/index';
	import store from 'vuex';
	import STORE from '@/store'
	import route from 'vue-router'
	import eventBus from '@/plugins/eventBus';
	import { getComponentById, getQueryValue, getTargetArchi, getUserInfo, judgingEventLogic } from '@/utils/tools';
	import { Message } from 'element-ui';
	export default defineComponent ({
		name: '',
		props: {
			// 组件对象
			element: {
				type: Object,
				default: () => {},
				required: true
			},
			// 是否为预览
			isPreview: {
				type: Boolean,
				required: false,
				default: false
			},
			// 弹窗页面配置的ID
			pageId: {
				type: Number,
				required: false
			},
			// 是否为分组
			isGroup: {
				type: Boolean,
			},
			// 当前分组的组件数据
			groupComponents: {
				type: Array,
				default: () => []
			}
		},
		setup (props) {
			const state = reactive({
				// 渲染数据
				renderData: [],
				// 完全渲染数据未格式化
				fullData: null,
				loading: false,
			})
			/* 计算当前选择的人员回显 */
			const valueOrigin = computed(() => {
				return props.element && props.element.valueOrign;
			})
			const componentData = store.mapState['componentData']
			watch(
				() => valueOrigin,
				(newV) => {
					if (newV === 'database') {
						if (
							props.element &&
							props.element.database &&
							props.element.database.objectData &&
							props.element.database.viewData
						) {
							getRenderData(props.element.database);
						}
					}
				},
				{ immediate: true }
			)
			/**
			 * @desc: 触发点击事件
			 * @param {Object} item 点击的组件
			 * 触发方式实质就是在改变指定的组件状态
			 * 目前覆盖点击事件
			 */
			const onClickBox = (item) => {
				if (
					!item.events ||
					!item.events.click ||
					!item.events.click.effects ||
					!Array.isArray(item.events.click.effects)
				) {
					return false;
				}
				const { actionType, effects, behaviors } = item.events.click;
				if (actionType === 'eeAction') {
					// 触发后端事件
					const { objectUUID, viewUUID, eventName } = item.events.click;
					if (!objectUUID || !viewUUID || !eventName) {
						Message.error('事件配置错误！');
						return false;
					}
					const data_id = getBindParams(item)?.data_id;
					dataInterface({
						__method_name__: 'customEventCall',
						object_uuid: objectUUID,
						view_uuid: viewUUID,
						data_id, // 参数配置
						event: eventName
					})
						.then((res) => {
							if (res.status === 200 && res.data.code === 200) {
								Message.success('操作成功！');
							}
							state.loading = false;
						})
						.catch(() => {
							state.loading = false;
						});
					return false;
				}
				// 普通事件
				effects.forEach((ele) => {
					let logicBoolean = true
					if (ele.logics.length) {
						logicBoolean = judgingEventLogic(ele.logics, componentData || []);
					}
					if (logicBoolean) {
						store.Store.commit('triggerEvents', ele);
					}
				});
				// 触发行为
				if (behaviors && Array.isArray(behaviors)) {
					behaviors.forEach(behavior => {
						let logicBoolean = true
						if (behavior.logics.length) {
							logicBoolean = judgingEventLogic(behavior.logics, componentData || []);
						}
						if (logicBoolean) {
							store.Store.commit('triggerEvents', {
								behavior,
								isBehavior: true
							});
						}
					})
				}
			}
			/**
			 * @desc: 获取数据渲染时候的数据
			 */
			const getRenderData = (database, params, search = [], isInit = false) => {
				state.loading = true;
				if (database && database.requestType === 'moreAction') {
					// 数据统计逻辑
					getStaticsData(database, isInit);
					state.loading = false;
					return;
				}
				// 注入的参数
				let outParams = {};
				if (props.element.database.userOutParams) {
					outParams = sessionStorage.getItem(
						`dialogRelationParams_${props.pageId || route.query.id}`
					);
					outParams = outParams ? JSON.parse(outParams) : {};
					// Mock数据
					/* eslint-disable */
					if ((!outParams || !Object.entries(outParams).length) && route.path === '/modify-page') {
						const mockData = props.element.database.mockData || [];
						mockData.forEach((ele) => {
							outParams[ele.paramsKey] = ele.paramsValue;
						});
					}
				}
				// 请求方式  dataList dataInfo updateData createData deleteData relationList moreAction
				if (!database.objectData) {
				state.loading = false;
					return
				};
				if (!database.viewData && !database.relationData) {
				state.loading = false;
					return
				};;
				let __method_name__ = database.requestType;
				const mapping = database.mapping;
				let paramObj = null;
				if (mapping === 'object') {
					paramObj = {
						__method_name__,
						object_uuid: database.objectData.uuid,
						view_uuid: database.viewData.uuid,
						search,
						...params,
						...outParams
					};
				} else if (mapping === 'relation') {
					__method_name__ = 'relationList';
					paramObj = {
						__method_name__: 'relationList',
						object_uuid: database.objectData.uuid,
						relationship_uuid: database.relationData.uuid,
						search,
						...params,
						...outParams
					};
				}
				dataInterface(paramObj)
					.then((res) => {
						if (res.status === 200) {
							if (
								__method_name__ === 'dataList' ||
								__method_name__ === 'relationList'
							) {
								// 列表
								// 列表数据
								state.renderData = getListFromRes(res) || [];
							} else if (__method_name__ === 'dataInfo') {
								// 详情
								state.renderData = res.data.data || {};
							}
							// 完全数据
							state.fullData = res.data.data || {};
							const componentDataInfo = JSON.parse(JSON.stringify(props.element))
							componentDataInfo.database.metadata = res.data.metadata
							componentDataInfo.resolveData = state.renderData
							STORE.commit('modifyComponent', {
								component: {
									...componentDataInfo,
									resolveData: state.renderData,
									fullData: state.fullData,
									// metadata: isInit ? res.data.metadata : element.metadata
									// metadata: Array.isArray(element.metadata) || !element.metadata || isInit ?  res.data.metadata : element.metadata,
									metadata: res.data.metadata,
								},
								containerId: null,
								isModify: true
							});
							eventBus.$emit('databaseTrigger', {
								componentId: props.element.id,
								action: 'any',
								isSearch: true,
								output: state.renderData
							});
							if (
								props.element.database.userOutParams &&
								outParams &&
								route.path === '/modify-page' &&
								!props.pageId &&
								isInit
							) {
								// 详情页面需要初始化
								store.Store.commit('initDetailsPage', {
									targetContainer: props.element,
									fullData: state.fullData,
									containerData: state.renderData,
									metadata: res.data.metadata
								});
							}
						}
						state.loading = false;
					})
					.catch((err) => {
						state.loading = false;
					});
			}
			/**
			 * @desc: 获取统计结果
			 * @param {array} database 数据仓库配置
			 * @param {boolean} isInit 是否为初始化
			 */
			const getStaticsData = (database, isInit = false) => {
				const { statisticalConfig } = database;
				const { search = [], param = {}, canPost } = initParams();
				if (!canPost) return;
				if (!statisticalConfig || !Array.isArray(statisticalConfig)) {
					Message.error('获取统计数据失败！');
					return;
				}
				const data = {};
				const metadata = {};
				statisticalConfig.forEach((ele) => {
					data[ele.key] = {
						__method_name__: 'globalFunctionCall',
						typeName: 'PublicFunc',
						type: 'value',
						funcName: 'ViewAggregate',
						payload: {
							field_uuid: ele.field_uuid,
							view_uuid: ele.view_uuid,
							aggregate: ele.aggregate
						},
						search,
						...param
					}
					// $set(data, ele.key, {
					// 	__method_name__: 'globalFunctionCall',
					// 	typeName: 'PublicFunc',
					// 	type: 'value',
					// 	funcName: 'ViewAggregate',
					// 	payload: {
					// 		field_uuid: ele.field_uuid,
					// 		view_uuid: ele.view_uuid,
					// 		aggregate: ele.aggregate
					// 	},
					// 	search,
					// 	...param
					// });
					metadata[ele.key] = ele.key
					// $set(metadata, ele.key, ele.key);
				});
				dataInterface({
					__method_name__: 'moreAction',
					data
				})
					.then((res) => {
						if (res.status === 200) {
							state.renderData = res?.data?.data || {};
							// 完全数据
							state.fullData = state.renderData || {};
							// 保存容器数据
							store.Store.commit('modifyComponent', {
								component: {
									...props.element,
									containerData: state.renderData,
									fullData: state.fullData,
									metadata: isInit ? metadata : props.element.metadata
								},
								containerId: null,
								isModify: true
							});
						}
					})
					.catch((err) => {
					});
			}
			/**
			 * @desc: 初始化获取参数
			 */
			const initParams = () => {
				const { paramsConfig } = props.element.database;
				let canPost = true;
				if (!paramsConfig || !paramsConfig.length) return { canPost };
				let search = [];
				const param = {};
				for (let i = 0; i < paramsConfig.length;i++) {
					const ele = paramsConfig[i];
					let originComponent = getComponentById(componentData, ele.componentId);
					if (!originComponent && props.isGroup && props.groupComponents.length) {
						originComponent = getComponentById(props.groupComponents, ele.componentId);
					}
					if (ele.key !== 'search') {
						if (ele.sourceType === 'url') {
							// url参数
							const queryVal = getQueryValue(ele.urlParamsKey);
							param[ele.key] = queryVal
							// $set(param, ele.key, queryVal);
							if (ele.isRequired && !queryVal) {
								canPost = false;
								break;
							}
						} else if (!ele.sourceType) {
							// 来源于组件
							if (ele.isRequired && (!originComponent || !originComponent.resolveData)) {
								canPost = false;
								break;
							} else if (originComponent && originComponent.resolveData) {
								const queryVal = originComponent && originComponent.resolveData[ele.paramKey];
								param[ele.key] = queryVal
								// $set(param, ele.key, queryVal);
								if (ele.isRequired && !queryVal) {
									canPost = false;
									break;
								}
							}
						} else if (ele.sourceType === 'system') {
							// 系统及架构参数 用户信息，架构信息
							let systemObject = null;
							if (ele.systemKey) {
								if (ele.systemKey === 'userInfo') {
									systemObject = getUserInfo()
								} else if (ele.systemKey === 'targetArchi') {
									systemObject = getTargetArchi()
								}
							}
							let systemValue = '';
							if (systemObject && ele.systemCode) {
								systemValue = systemObject[ele.systemCode];
							}
							param[ele.key] = systemValue
							// $set(param, ele.key, systemValue);
							if (ele.isRequired && (!systemObject || !systemValue)) {
								canPost = false;
								break;
							}
						}
					} else {
						if (originComponent && originComponent.resolveData && Array.isArray(originComponent.resolveData)) {
							search = search.concat(originComponent.resolveData);
						}
					}
				}
				return {
					search,
					param,
					canPost
				};
			}
			/**
			 * @desc: 获取请求参数
			 * @param {Array} config 请求参数配置
			 */
			const getParams = (config, data) => {
				if (!config || !data || !data.output) return {};
				let result = {};
				for (let key in data.output) {
					if (key === config.paramKey) {
						result[config.key] = data.output[key];
					}
				}
				return result;
			}
			/**
			 * @desc: 根据请求返回获取列表结构
			 * @param {Object} res 请求返回的数据
			 */
			const getListFromRes = (res) => {
				if (res.data && Array.isArray(res.data)) {
					return res.data;
				}
				if (res.data && typeof res === 'object') {
					return getListFromRes(res.data);
				}
				return [];
			}
			/**
			 * @desc: 判断前后端操作标识是否已知
			 * @param {String} beAction 后端标识
			 * @param {String} feAction 前端标识
			 * @return {Boolean}
			 */
			const isSameAction = (beAction, feAction) => {
				if (feAction === 'any') return true;
				const feToBe = {
					list: 'dataList',
					add: 'createData',
					detail: 'dataInfo',
					modify: 'updateDate',
					delete: 'deleteData'
				};
				return beAction === feAction || beAction === feToBe[feAction];
			}
			/**
			 * @desc: 获取绑定参数
			 */
			/**
			 * @desc: 后端事件获取绑定参数
			 * @param {Object} comp 组件数据
			 * @return {Object}
			 */
			const getBindParams = (comp) => {
				if (!comp) return {};
				const sourceConfig = comp.sourceConfig || [];
				const sourceParams = {};
				for (let i = 0; i < sourceConfig.length; i++) {
					const {
						componentId,
						field,
						key,
						originType = '',
						urlParamKey = ''
					} = sourceConfig[i];
					if (originType === 'url' && urlParamKey) {
						// 从url获取参数
						const result = getQueryValue(urlParamKey);
						sourceParams[key] = result
						// $set(sourceParams, key, result);
					} else if (componentId && field && key) {
						// 普通从组件获取
						let sourceComponent = getComponentById(componentData, componentId);
						if (!sourceComponent && props.isGroup && props.groupComponents.length) {
							sourceComponent = getComponentById(props.groupComponents, componentId);
						}
						const result =
							sourceComponent && sourceComponent.resolveData
								? sourceComponent.resolveData[field]
								: '';
						sourceParams[key] = result
						// $set(sourceParams, key, result);
					}
				}
				return sourceParams;
			}
			onMounted(() => {
				if (
					props.element &&
					props.element.database &&
					props.element.database.objectData &&
					props.element.database.viewData
				) {
					const { param = {}, search = [], canPost } = initParams();
					if (canPost) {
						getRenderData(props.element.database, param, search);
					}
				} else if (
					props.element &&
					props.element.database &&
					props.element.database.requestType === 'moreAction'
				) {
					const { statisticalConfig } = props.element.database;
					if (statisticalConfig && statisticalConfig.length) {
						getRenderData(props.element.database, {}, []);
					}
				}
				// 配置关联参数的容器才需要监听
				eventBus.$on('databaseTrigger', (data) => {
					// 测试代码
					let { paramsConfig, requestType } = props.element.database;
					if (!paramsConfig || !Array.isArray(paramsConfig)) return;
					// console.log(data, '--配置关联参数的容器才需要监听--');
					// 是否当前组件响应
					let isTarget = false;
					let configItem = null;
					let params = {};
					for (let i = 0; i < paramsConfig.length; i++) {
						if ((paramsConfig[i].componentId === data.componentId && isSameAction(requestType, data.action))
							|| (data.componentId.indexOf('CommonTree') > -1 && data.parentId == props.element.id && data.isSearch)
						) {
							isTarget = true;
							configItem = paramsConfig[i];
							params = {
								...params,
								...getParams(configItem, data)
							};
						}
					}
					if (!isTarget && !data.isInit) return;
					const { param = {}, search = [], canPost } = initParams();
					if (!canPost) return;
					getRenderData(props.element.database, param, search, data.isInit);
				});
			})
			const statusConfigStyle = computed(() => {
				const statusConfig = props.element?.statusConfig || {}
				const StyleConfig = props.element?.style || {}
				const key = ['borderTop', 'borderRight', 'borderBottom', 'borderLeft']
				const res = {}
				key.forEach(el => {
					if(statusConfig[el + 'Show']) {
						if(statusConfig[el + 'Width'] && statusConfig[el + 'Type'] && statusConfig[el + 'Color']) {
							res[el] = `${statusConfig[el + 'Width']}px ${statusConfig[el + 'Type']} ${statusConfig[el + 'Color']}`
						}
					}
				})
				return res
			})
			return {
				...toRefs(state),
				statusConfigStyle,
				onClickBox
			}
		}
	})
</script>
<style lang='less' scoped></style>
