var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"srcoll-list-cont"},[_c('div',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadData),expression:"loadData"}],staticClass:"infinite-list",attrs:{"infinite-scroll-immediate":_vm.immediate}},[_vm._l((_vm.listData),function(item,index){return _c('div',{key:index,staticClass:"infinite-list-item",style:({backgroundColor:_vm.activeIndex == index?(_vm.layoutConfig.activeCol?_vm.layoutConfig.activeCol:''):''})},[(_vm.layoutConfig.type)?[(['3rowTextAndImg', '2rowTextAndImg'].includes(_vm.layoutConfig.type) && _vm.layoutConfig.img)?_c('div',{staticClass:"item-img-warp",on:{"click":function($event){$event.stopPropagation();return _vm.onAction('rowDetail', item, $event, index)}}},[(item[_vm.layoutConfig.img])?_c('el-image',{style:({
											height: (_vm.fieldConfigObj[_vm.layoutConfig.img] && _vm.fieldConfigObj[_vm.layoutConfig.img].imgConfig.height || 72) + 'px',
											width: (_vm.fieldConfigObj[_vm.layoutConfig.img] && _vm.fieldConfigObj[_vm.layoutConfig.img].imgConfig.width || 72) + 'px',
										}),attrs:{"src":item[_vm.layoutConfig.img].length?item[_vm.layoutConfig.img][0].url:'//static.bimcc.com/images/h5-img/empty_no_content@2x.png',"fit":"fit"}}):_c('img',{style:({
											height: (_vm.fieldConfigObj[_vm.layoutConfig.img] && _vm.fieldConfigObj[_vm.layoutConfig.img].imgConfig.height || 72) + 'px',
											width: (_vm.fieldConfigObj[_vm.layoutConfig.img] && _vm.fieldConfigObj[_vm.layoutConfig.img].imgConfig.width || 72) + 'px',
										}),attrs:{"src":"//static.bimcc.com/images/h5-img/empty_no_content@2x.png","alt":""}})],1):_vm._e(),_c('div',{staticClass:"item-text-warp",on:{"click":function($event){$event.stopPropagation();return _vm.onAction('rowDetail', item, $event, index)}}},[_vm._l((_vm.fieldConfig.filter(v=>v.show && v.type !== 2)),function(sitem,j){return _c('p',{key:j,staticClass:"text-item",class:sitem.uuid === _vm.layoutConfig.title? 'title-text' : ''},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(sitem.uuid !== _vm.layoutConfig.title),expression:"sitem.uuid !== layoutConfig.title"}]},[_vm._v(_vm._s(sitem.label)+" :")]),_c('span',{staticClass:"value-style",style:({	backgroundColor:_vm.getValue(sitem,item,'color')})},[_vm._v(_vm._s(_vm.getValue(sitem,item,'value')))])])}),[_c('div',{staticClass:"bottom-icons"},_vm._l((_vm.modelHandle),function(val,i){return _c('el-tooltip',{key:i,staticClass:"item",attrs:{"effect":"dark","content":val.name,"placement":"top"}},[(_vm.logicAction(val,item) && _vm.checkIconState(val,item))?_c('i',{staticClass:"iconfont",class:_vm.getIconStyle(val,item),on:{"click":function($event){$event.stopPropagation();return _vm.modelAction(val,$event,item)}}}):_vm._e()])}),1)]],2),_c('div',{staticClass:"item-handle-warp"},[(_vm.listHandle.length)?_c('el-dropdown',{staticStyle:{"margin-top":"10px"},attrs:{"hide-on-click":false}},[_c('i',{staticClass:"iconfont iconbimgis_gengduo"}),_c('template',{slot:"dropdown"},[_c('el-dropdown-menu',_vm._l((_vm.listHandle),function(val,i){return _c('el-dropdown-item',{key:i},[_c('span',{on:{"click":function($event){$event.stopPropagation();return _vm.onAction(val.type, item, $event, index)}}},[_vm._v(_vm._s(val.name))])])}),1)],1)],2):_vm._e()],1)]:_vm._e()],2)}),(_vm.loading && !_vm.finished)?_c('div',{staticClass:"loading-cont"},[_c('span'),_c('p',[_vm._v("加载中...")])]):_vm._e(),(_vm.finished)?_c('p',{staticClass:"no-more"},[_vm._v("没有更多了!")]):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }