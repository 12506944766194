/* eslint-disable */
import eleValidate from "./inputItemConf";

async function formValidate(formJson,fillMatch,fillData,data=[]) {



	for (let ele of formJson){
		let itemData; //格式化后的item数据
        itemData = await eleValidate(ele,fillMatch,fillData);

		if(ele.__tabs__){
			itemData.value = [];
			for (let tab of ele.__tabs__){
				let childrenData = await formValidate(tab.children,fillMatch,fillData,[]);
				itemData.value.push(childrenData);
			}
		}

		if(ele.__config__.children && ele.__config__.children.length>0){
			let childrenData = await formValidate(ele.__config__.children,fillMatch,fillData,[]);
			let relateIndex = childrenData.findIndex(item => item.type === 'database')
			if(relateIndex !== -1){
				let otherComp = childrenData.filter(item => item.type !== 'database')
				let relateComp = childrenData[relateIndex]
				relateComp.config.__slot__.options = relateComp.config.__slot__.options.filter(item=>!item.element)
				for (let item of otherComp){
					let attachItem = {
						label: item.config.__config__.label,
						value: item.config.__vModel__,
						width: item.config.__config__.width || 150,
						element:item
					}
					relateComp.config.__slot__.options.push(attachItem)
				}
				itemData.config.__config__.display = 'table-relate'
				itemData.config.__config__.relateConfig = relateComp.config;
			}else{
				if(ele.__config__.tagIcon === 'form'){
					if(ele.__config__.fillData && ele.__config__.fillData.length>0){
						let fillItem = []; //预设填充数据
						for (let item of ele.__config__.fillData){
							let formId = Object.keys(item)
							let cycleData = JSON.parse(JSON.stringify(childrenData));
							cycleData.forEach((ite,ind)=>{
								if(formId.includes(ite.config.__config__.formId)){
									ite.value = item[ite.config.__config__.formId]
									//填充数据禁用输入
									if(ele.__config__.fillType && ele.__config__.fillType === 'text'){
										ite.config.disabled = true
									}
								}
							})
							fillItem.push(cycleData)
						}
						itemData.value = fillItem;
					}else{
						itemData.value.push(childrenData);
					}
				} else {
					itemData.value = childrenData;
				}
			}
		}
		data.push(itemData);
	}
	return data;
}


export default formValidate;
