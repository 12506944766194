/*
 * @Author: 朱廷果 1028509503@qq.com
 * @Date: 2022-08-29 17:57:06
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2023-01-06 17:36:12
 * @FilePath: \dataview-next\src\api\saas.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { dataInterface } from '@/apis/data/saasMapi';
import { getCompanyId } from "@/utils/tools";
import request from '@/apis/http/saasRequest.js';

export function saasInterface(data, url, method = 'POST') {
  return dataInterface(
    data={
    ...data,
      company_id:getCompanyId()
  },url,method)
}

// 登录/注册 获取验证码
export function getUserList(data) {
  return request({
    url: 'api/saas/saasUserList',
    method: 'post',
    data: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

// 修改密码
export function changeSaasPassword(data) {
  return request({
    url: 'api/v4/user/changeSaasPassword',
    method: 'post',
    data: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

// 版本管理通过
export function versionApply(data) {
  return request({
    url: '/api/saas/admin/versionApply',
    method: 'post',
    data: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

// 版本管理撤销
export function versionRevoke(data) {
  return request({
    url: '/api/saas/admin/versionRevoke',
    method: 'post',
    data: {
      ...data,
      company_id:getCompanyId()
    }
  })
}
// 版本管理驳回
export function versionApplyReject(data) {
  return request({
    url: '/api/saas/admin/versionApplyReject',
    method: 'post',
    data: {
      ...data,
      company_id:getCompanyId()
    }
  })
}
// 获取市场模板左侧菜单
export function getTempMenuList() {
  return request({
    url: '/api/saas/appsList',
    method: 'post',
    data: {
      company_id:getCompanyId()
    }
  })
}

// 安装模板市场应用
export function installApp(data) {
  return request({
    url: '/api/saas/installApp',
    method: 'post',
    data: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

// 角色管理修改角色
export function roleOperation(data) {
  return request({
    url: '/api/saas/roleOperation',
    method: 'post',
    data: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

// 菜单授权更改权限
export function menuOperation(data) {
  return request({
    url: '/api/saas/menuOperation',
    method: 'post',
    data: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

// 菜单授权更改权限
export function repositoryList(data) {
  return request({
    url: 'api/saas/app/repositoryList',
    method: 'post',
    data: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

// 新首页列表
export function getNewHomeList(data) {
  return request({
    url: 'api/saas/app/repositoryList',
    method: 'post',
    data: {
      ...data,
      company_id:getCompanyId()
    }
  })
}
// 文档中心筛选内容的接口
export function getRepositoryTypeList(data) {
  return request({
    url: 'api/saas/app/repositoryTypeList',
    method: 'post',
    data: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

export function versionHeader(data) {
  return request({
    url: 'api/saas/admin/versionHeader',
    method: 'post',
    data: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

/**
 * @desc 
 */
 export function firstLoginStatus(data) {
  return request({
    url: 'api/saas/admin/firstLoginStatus',
    method: 'post',
    data: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

/**
 * @desc 下拉选择框的数据
 * @data ： type
 */
 export function dictSelect(data) {
  return request({
    url: 'api/saas/admin/dict/select',
    method: 'post',
    data: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

/**
 * @desc 组织架构
 */
 export function departmentFilter() {
  return request({
    url: 'api/saas/admin/dept/departmentFilter',
    method: 'post',
    data: {
      company_id:getCompanyId()
    }
  })
}
/**
 * @desc 判断我的应用是否安装
 * @data 数组id
 */
 export function appInstallStatus(data) {
  return request({
    url: 'api/saas/appInstallStatus',
    method: 'post',
    data: {
      ...data,
      company_id:getCompanyId()
    }
  })
}

/**
 * @desc 数仓安装优化详情接口
 * @data 数组id
 */
 export function pageFromDetail(data) {
  return request({
    url: 'api/saas/pageFromDetail',
    method: 'post',
    data:{...data},
  })
}