<!--
 * @Description: 最近查看
 * @Author: luocheng
 * @Date: 2021-11-05 14:58:33
 * @LastEditors: zx
 * @LastEditTime: 2022-10-10 10:27:11
-->
<template>
	<div class="recent select-archi-node">
		<!-- <SearchBox v-model="filterName"></SearchBox> -->
		<article class="recent-main select-archi-node">
			<div class="recent-child archi-list select-archi-node">
				<section class="compny item select-archi-node" v-if="!isProjectLevel">
					<GroupItem
						:title="'企业'"
						:styleObj="styleObj"
						:dataList="historyCompanys"
						:current="targetId"
					></GroupItem>
				</section>
				<section class="project item select-archi-node"
					:style="{
						marginTop: isProjectLevel ? 0 : '12px'
					}"
				>
					<GroupItem
						:title="'项目'"
						:styleObj="styleObj"
						:dataList="historyProjects"
						:current="targetId"
					></GroupItem>
				</section>
			</div>
		</article>
		<div class="choose-archi">
			<el-button :disabled="isDisabled" :type="isDisabled ? 'default' : 'primary'" @click="chooseArchi()">
				{{ getButtonLabel(cacheArchi) }}
			</el-button>
		</div>
		
	</div>
</template>

<script>
// import SearchBox from '../SearchBox';
import GroupItem from '../GroupItem';
import { Button } from 'element-ui' 
import { mapState } from 'vuex';
import { mapiRequest } from '@/apis/http/saasMapiRequest'
import request from '@/apis/http/saasRequest.js'
import { getCompanyId } from '@/utils/tools';
export default {
	name: 'RecentView',
	components: {
		'el-button': Button,
		// SearchBox,
		GroupItem,

	},
  props: {
    // 最近登录 
    historyArchi: {
      type: Object,
      required: true,
      default: () => {}
    },
    menuTreeData: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
	data() {
		return {
			styleObj: {
				height: '200px'
			},
			filterName: '',
      historyProjects: [],
      historyCompanys: [],
			// 当前选中
			targetId: null,
			isProjectLevel: 0
		};
	},
	computed: {
		...mapState(['cacheArchi', 'saasTargetArchi']),
    isDisabled() {
      return !this.cacheArchi || this.getButtonLabel(this.cacheArchi) === '选择架构' || (this.saasTargetArchi && +this.cacheArchi.id === +this.saasTargetArchi.id)
    }

	},	
  created() {
		this.$store.commit('setCacheArchi', '')
		this.getHistoryArchi()
  },
	methods: {
		getHistoryArchi() {
			const params = {
				__method_name__: 'dataList',
				object_uuid: 'object630dc4fbdb3ab',
				view_uuid: 'view630dc70df0071',
				page: 1,
				size: 10,
				transcode: 0
			}
			mapiRequest(params).then((res) => {
				if (res.status === 200 && res.data.code === 200) {
					if (res.data.data.data && res.data.data.data.length) {
						this.historyProjects = []
						this.historyCompanys = []
						res.data.data.data.forEach(element => {
							if (+element.values.type === 3 || +element.values.type === 4) {
								this.historyCompanys.push(element)
							} else if (+element.values.type === 5 || +element.values.type === 6) {
								this.historyProjects.push(element)
							}
						});
					}
				}
			})
		},
		getButtonLabel(data) {
      const group = [1, 2]
      const company = [3, 4]
      const project = [5, 6]
			if (!data) {
				return '选择架构'
			}
      if (group.indexOf(+data.type) !== -1 ) {
        return '选择架构'
      } else if (company.indexOf(+data.type) !== -1 ) {
        return '选择企业'
      } else if (project.indexOf(+data.type) !== -1 ) {
        return '选择项目'
      }
		},
    chooseArchi() {
      const data = {
        values: JSON.stringify(this.cacheArchi),
        company_id: getCompanyId(),
        department_id: this.cacheArchi.id
      }
      request({url: 'api/saas/departmentSwitch', method: 'post', data}).then((res) => {
        if (+res.status === 200 && +res.data.code === 200) {
					this.$store.commit('setSaasTargetArchi', this.cacheArchi)
          this.$message.success('切换成功')
          this.$emit('close')
          if (this.menuTreeData && this.menuTreeData.length) {
            this.menuTreeData.forEach(element => {
              if (element.path === '/home') {
                this.$store.commit('setCurrentMenus', element)
                this.$router.push({ path: '/home' })
              }
            });
          }
        }
      })
    }
	}
};
</script>

<style lang="less" scoped>
.recent {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	.recent-main {
		width: 100%;
		flex: 1;
		overflow: hidden;
		display: flex;
		justify-content: space-between;
		box-sizing: border-box;
		.recent-child {
			width: 100%;
			height: 100%;
			overflow: hidden;
			&.archi-list {
				.project {
					margin-top: 12px;
				}
			}
		}
	}
	.choose-archi {
		width: 100%;
		height: auto;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 10px 0;
		button {
			width: 100%;
		}
	}
}
</style>