import { render, staticRenderFns } from "./TaskRecover.vue?vue&type=template&id=9e88f424&scoped=true&"
import script from "./TaskRecover.vue?vue&type=script&lang=js&"
export * from "./TaskRecover.vue?vue&type=script&lang=js&"
import style0 from "./TaskRecover.vue?vue&type=style&index=0&id=9e88f424&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9e88f424",
  null
  
)

export default component.exports