<!--
 * @Description: 折线图
 * @Author: luocheng
 * @Date: 2022-01-07 09:22:05
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2023-01-04 11:51:55
-->
<template>
	<div
		class="chart-line"
		:style="{
			height: height + 'px'
		}"
		v-loading="getting"
		:element-loading-background="loadingBackColor"
	>
		<ChartContainer
			v-if="option"
			ref="chartInstance"
			class="chart-bar"
			:option="option"
		></ChartContainer>
		<el-empty
			description="暂无数据"
			v-else
		></el-empty>
	</div>
</template>

<script>
import mixin from './mixin';
// import eventBus from '@/plugins/eventBus';

export default {
	name: 'ChartLine',
	mixins: [mixin],
	data() {
		return {
			statisticalData: null,
			option: null
		};
	},
	created() {
		this.getOptions();
	},
	// mounted() {
	// 	this.$nextTick(() => {
	// 		eventBus.$on('refreshChart', () => {
	// 			this.option = null;
	// 			this.getOptions();
	// 		});
	// 		eventBus.$on('databaseTrigger', config => {
	// 			if (!config || !this.paramsConfig.length || !this.paramsConfig.find(ele => ele.componentId === config.componentId)) {
	// 				return;
	// 			}
	// 			this.option = null;
	// 			this.getOptions();
	// 		});
	// 	});
	// },
	methods: {
		/**
		 * @desc: 设置配置项
		 * @param {*} data
		 * @return {*}
		 */
		setOptions(data) {
			if (!data || !data.length) return false;
			const { attributes = {} } = this.chartData;
			const { xData = [], series = [], names = [] } = data[0];
			const {
				showTitle = true,
				title = '',
				textColor = '',
				titleFontSize = 12,
				titleFontWeight = 400,
				showTooltip = true,
				showLegend = true,
				legendTextColor = '#000000',
				smooth = false,
				xAxisText = '',
				yAxisText = '',
				colorRange = [],
				left = '10%',
				right = '10%',
				top = 60,
				bottom = 60,
				legendIcon = 'circle',
				showDataZoom = false,
				dataZoomBottom = 'auto',
				dataZoomStart = 0,
				dataZoomEnd = 100,
				legendOrient = 'vertical',
				legendType = 'plain',
				titleTop = 'auto',
				titleBottom = 'auto',
				titleRight = 'auto',
				titleLeft = 'auto',
				axisTextColor = '#ffffff',
				axisFontSize = '14px',
				useArea = false,
				userStack = false,
				useGradient = false,
				gradientRange = [],
				minInterval = 0,
			} = attributes;
			this.option = {
				title: {
					show: showTitle,
					text: title,
					left: 'left',
					y: 'top',
					textStyle: {
						color: textColor,
						fontFamily: '微软雅黑',
						fontSize: this.scaleSize(titleFontSize || 12),
						fontWeight: titleFontWeight || 400,
						left: titleLeft,
						top: titleTop,
						bottom: titleBottom,
						right: titleRight
					}
				},
				tooltip: {
					show: showTooltip,
					trigger: 'axis',
					axisPointer: {
						type: 'line'
					}
				},
				legend: {
					show: showLegend,
					itemWidth: 10,
					itemHeight: 10,
					data: this.getLegendData(names, legendTextColor),
					icon: legendIcon,
					...this.getLegendPosition(attributes),
					type: legendType,
					orient: legendOrient,
				},
				color: colorRange || [],
				grid: {
					left,
					right,
					bottom,
					top,
					containLabel: true
				},
				xAxis: {
					type: 'category',
					name: xAxisText,
					data: xData || [],
					axisTick: {
						show: false // 隐藏刻度线
					},
					axisLine: {
						show: true // 隐藏坐标轴
					},
					axisLabel: {
						textStyle: {
							color: axisTextColor,
							fontSize: this.scaleSize(axisFontSize)
						},
						margin: 16 // 文案与坐标轴间距
					}
				},
				dataZoom: [
					{
						type: 'slider',
						show: showDataZoom,
						backgroundColor: 'transparent',
						fillerColor: 'rgba(0, 0, 0, .1)',
						dataBackground: {
								areaStyle: {
									color: 'rgb(233, 236, 238)',
									opacity: 0.8,
									shadowColor: 'rgba(233, 236, 238, .1)'
								}
						},
						selectedDataBackground: {
								areaStyle: {
									color: 'rgb(233, 236, 238)',
									opacity: 0.8
								},
								shadowColor: 'rgba(233, 236, 238, 1)'
						},
						start: dataZoomStart || 0,
						end: dataZoomEnd || 100,
						bottom: dataZoomBottom,
					}
				],
				yAxis: {
					type: 'value',
					name: yAxisText,
					axisTick: {
						show: false // 隐藏刻度线
					},
					axisLine: {
						show: true // 隐藏坐标轴
					},
					axisLabel: {
						textStyle: {
							color: axisTextColor,
							fontSize: this.scaleSize(axisFontSize)
						},
						margin: 16 // 文案与坐标轴间距
					},
					splitLine: {
						show: true,
						lineStyle: {
							type: 'dashed',
							color: 'rgba(194, 197, 204, 0.1)'
						}
					}
				},
				series: series.map((ele, i) => {
					// return {
					// 	name: ele.name,
					// 	type: 'line',
					// 	symbol: smooth ? 'none' : '', //去掉折线图中的节点
					// 	smooth, //true 为平滑曲线，false为直线
					// 	data: ele.data
					// };
					const data = {
						name: ele.name,
						type: 'line',
						symbol: smooth ? 'none' : '', //去掉折线图中的节点
						smooth, //true 为平滑曲线，false为直线
						data: ele.data
					}
					if(userStack || useArea) {
						// 使用堆叠
						if(userStack) {
							data.stack = 'Total'
						}

						const areaStyle = {
							opacity: 0.8
						}
						if(useGradient) {
							areaStyle.color = {
								type: 'linear',
								x: 0.5,
								y: 1,
								x2: 0.5,
								y2: 0,
								colorStops: Array.isArray(gradientRange[i % gradientRange.length]) ?
								gradientRange[i % gradientRange.length].map((el, index) => {
									if(index === 0) {
										return ({
											offset: 0,
											color: el
										})
									}
									return ({
										offset: ( 1 / gradientRange[i % gradientRange.length].length) * (index + 1),
										color: el
									})
								}) : [],
								global: false // 缺省为 false
							}
						}
						data.areaStyle = areaStyle
					}
					return data
				})
			};
			if(!isNaN(Number(minInterval))) {
				const number = Number(minInterval)
				if(number > 0) {
					this.option.yAxis.minInterval = number
				}
			}
		},
		/**
		 * @desc: 设置图例
		 * @param {Array} names 名称
		 * @param {String} color 文本颜色
		 * @return {*}
		 */
		getLegendData(names, color) {
			let s = [];
			if (!names) {
				return [];
			}
			for (let i = 0; i < names.length; i++) {
				s.push({
					name: names[i],
					textStyle: {
						color,
						fontSize: this.scaleSize(12)
					}
				});
			}
			return s;
		}
	},
	// beforeDestroy() {
	// 	eventBus.$off('refreshChart');
  //   // eventBus.$off('databaseTrigger');
	// }
};
</script>

<style lang="less" scoped>
.chart-line {
	height: 100%;
	width: 100%;
	:deep(.el-empty){
		padding: 0;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
		.el-empty__image{
			width: 35%;
			max-width: 120px;
			min-width: 50px;
		}
		.el-empty__description{
			margin-top: 15px;
		}
		.page-container p{
			color: #fff;
		}
	}
}
</style>