/* eslint-disable */
import {formRequest} from "@/api/home";

export const getSearchData = (filterRule,getFormIdValue) => {
	let searchData=[];
	for (let itemV of filterRule){
		let itemData = { ruleType: itemV.ruleType };
		if(itemV.paramType === 2){
			itemData.field_uuid = itemV.field_uuid;
		}else{
			itemData.code = itemV.code;
		}
		if(itemV.valueType === 2){
			itemData.value = getFormIdValue(itemV.value)
		}else{
			itemData.value = itemV.value
		}
		searchData.push(itemData)
	}
	return searchData
}

export async function getRequestData(objectUuid,viewUuid,searchData= []) {
	let url, params;
	url = '/api/mapi'
	params = {
		__method_name__: 'dataList',
		object_uuid: objectUuid,
		view_uuid: viewUuid,
		size: 1000,
		search: searchData
		// transcode: 0,
	}

	if(searchData.length) params.search = searchData
	if(objectUuid && viewUuid){
		let response = await formRequest('post', url, params);
		let respData;
		if (response.data.data.data) {
			respData = response.data.data.data
		} else {
			respData = response.data.data
		}
		return respData;
	}
	return [];
}

export function optionData(originData,tag,label,value,child=''){
	let optionData = [];
	if(tag === 'select'){
		for (let element of originData) {
			if (element[label] && element[value]) {
				let item = {label: element[label], value: element[value],allField:element};
				optionData.push(item);
			}
		}
	} else if(tag === 'treeSelect' || tag === 'cascader'){
		function mapTree (data) {
			const haveChildren = Array.isArray(data[child]) && data[child].length > 0;
			return {
				id: data[value],
				label: data[label],
				value: data[value],
				children: haveChildren ? data[child].map(i => mapTree(i)) : []
			}
		}
		optionData = originData.map(item => mapTree(item));
	}

	return optionData
}

// json化控件数据
export const parseJson = (value)=>{
	if(!(value instanceof Array)){
			try{
				return JSON.parse(value);
			}catch (e){
				console.warn('json解析错误：',e)
				return value
			}
	}
	return value
}

// 控件数据回显统一格式化处理
export function formatValue(tagIcon,value,multiple=false){
	switch (tagIcon) {
		case 'date-range':
		case 'upload':
		case 'bimList':
		case 'user':
			return parseJson(value);
		case 'cascader':
		case 'checkbox':
			return parseJson(value);
		case 'select':
			if(multiple) return parseJson(value);
			return value+'';
		case 'date':
			if(value.indexOf('0000-00-00')>-1 || value === null){
				return '';
			}
			return value;
		case 'number':
		case 'slider':
		case 'rate':
			return parseInt(value);
		case 'switch':
			return (value == 'true' || value == 1)? true : false;
		case 'treeSelect':
			if(multiple) return parseJson(value);
			return value+'';
		default:
			return value+''
	}
}

// 小写金额转中文大写金额
export function numToCny(money) {
	// 汉字的数字
	const cnNums = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
	// 基本单位
	const cnIntRadice = ['', '拾', '佰', '仟'];
	// 对应整数部分扩展单位
	const cnIntUnits = ['', '万', '亿', '兆'];
	// 对应小数部分单位
	const cnDecUnits = ['角', '分', '毫', '厘'];
	// 整数金额时后面跟的字符
	let cnInteger = '整';
	// 整型完以后的单位
	let cnIntLast = '元';
	// 最大处理的数字
	let maxNum = 999999999999999.9999;
	// 金额整数部分
	let integerNum;
	// 金额小数部分
	let decimalNum;
	// 输出的中文金额字符串
	let chineseStr = '';
	// 分离金额后用的数组，预定义
	let parts;
	if (money == '') {
		return '';
	}
	money = parseFloat(money);
	if (money >= maxNum) {
		// 超出最大处理数字
		return '';
	}
	if (money == 0) {
		chineseStr = cnNums[0] + cnIntLast + cnInteger;
		return chineseStr;
	}
	// 转换为字符串
	money = money.toString();
	if (money.indexOf('.') == -1) {
		integerNum = money;
		decimalNum = '';
	} else {
		parts = money.split('.');
		integerNum = parts[0];
		decimalNum = parts[1].substr(0, 4);
	}
	// 获取整型部分转换
	if (parseInt(integerNum, 10) > 0) {
		let zeroCount = 0;
		let IntLen = integerNum.length;
		for (let i = 0; i < IntLen; i++) {
			let n = integerNum.substr(i, 1);
			let p = IntLen - i - 1;
			let q = p / 4;
			let m = p % 4;
			if (n == '0') {
				zeroCount++;
			} else {
				if (zeroCount > 0) {
					chineseStr += cnNums[0];
				}
				// 归零
				zeroCount = 0;
				chineseStr += cnNums[parseInt(n)]
					+ cnIntRadice[m];
			}
			if (m == 0 && zeroCount < 4) {
				chineseStr += cnIntUnits[q];
			}
		}
		chineseStr += cnIntLast;
	}
	// 小数部分
	if (decimalNum != '') {
		let decLen = decimalNum.length;
		for (let i = 0; i < decLen; i++) {
			let n = decimalNum.substr(i, 1);
			if (n != '0') {
				chineseStr += cnNums[Number(n)] + cnDecUnits[i];
			}
		}
	}
	if (chineseStr == '') {
		chineseStr += cnNums[0] + cnIntLast + cnInteger;
	} else if (decimalNum == '') {
		chineseStr += cnInteger;
	}
	return chineseStr;
}
