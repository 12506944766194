<!--
 * @Author: wsp 542278473@qq.com
 * @Date: 2022-11-30 14:17:40
 * @LastEditors: wsp 542278473@qq.com
 * @LastEditTime: 2022-12-02 15:00:47
 * @FilePath: \dataview-next\src\custom-component\login\CommonLoginNew.vue
 * @Description: 新登录组件
-->
<template>
  <div class="common-login-new">
    <div class="common-login-wrap">
      <transition name="alternate" mode="out-in">
        <LoginByInput v-if="type === 'input'" :methodConfig="methodConfig" @changeModel="handleChangeModel" />
        <LoginByCode v-else-if="type === 'code'" :methodConfig="methodConfig" @changeModel="handleChangeModel" />
        <ResetPassword v-else-if="type === 'reset'" :methodConfig="methodConfig" @changeModel="handleChangeModel" />
        <BindPassword v-else-if="type === 'bindPassword'" :methodConfig="methodConfig" @changeModel="handleChangeModel" />
        <BindPhone v-else-if="type === 'bindPhone'" :methodConfig="methodConfig" @changeModel="handleChangeModel" />
      </transition>
    </div>
  </div>
</template>
<script>
import LoginByInput from './components/LoginByInput.vue'
import LoginByCode from './components/LoginByCode.vue'
import ResetPassword from './components/ResetPassword.vue'
import BindPassword from './components/BindPassword.vue'
import BindPhone from './components/BindPhone.vue'
import { mapState } from 'vuex';
export default {
  name: 'CommonLoginNew',
  components: {
    LoginByInput,
    LoginByCode,
    ResetPassword,
    BindPassword,
    BindPhone
  },
  props: {
    // 绑定的值
		element: {
			type: Object,
			default: () => {},
			required: true
		},
    // 是否在组合内
    isGroup: {
      type: Boolean,
    },
    // 组合内组件列表
    groupComponents: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      type: 'input'
    }
  },
  computed: {
    ...mapState(['componentData', '_PageCustomStatus', '_APPCustomStatus']),
    statusConfig() {
      return this.element.statusConfig || {}
    },
    /**
     * @description: 配置允许的登录方式的对象
     * @return {Object} 配置允许的登录方式的对象
     */    
    methodConfig() {
      const usePasswordLogin = !!this.statusConfig.usePasswordLogin
      const useCodeLogin = !!this.statusConfig.useCodeLogin
      const useResetPassword = !!this.statusConfig.useResetPassword
      const userQRCodelogin = !!this.statusConfig.userQRCodelogin
      if(!usePasswordLogin && !useCodeLogin && userQRCodelogin) {
        this.handleChangeModel('code')
      } else if(usePasswordLogin || useCodeLogin) {
        this.handleChangeModel('input')
      } else {
        this.handleChangeModel('')
      }
      return {
        usePasswordLogin,
        useCodeLogin,
        useResetPassword,
        userQRCodelogin
      }
    }
  },
  methods: {
    /**
     * @description: 改变登录弹窗
     * @param {String} type
     */    
    handleChangeModel(type) {
      this.type = type
    }
  }
}
</script>
<style lang="less" scoped>
  .common-login-new{
    width: 100%;
    height: 100%;
    .common-login-wrap{
      width: 100%;
      height: 100%;
      border-radius: 4px;
      background: #fff;
        .alternate-enter-active, .alternate-leave-active {
          transition: all .3s ease;
        }
        .alternate-enter, .alternate-leave-to /* .fade-leave-active below version 2.1.8 */ {
          opacity: 0;
        }
    }
  }
</style>
