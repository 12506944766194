/*
 * @LastEditTime: 2022-03-02 11:52:25
 * @Description: 动态调用action 的工厂类
 * @FilePath: /dwh_front/src/views/listViewer/actions/actionFactory.js
 * @Date: 2021-12-02 12:17:57
 * @Author: lisushuang
 * @LastEditors: lisushuang
 */
import { Message } from "element-ui"
import showForm from './showForm';
import migratePageProject from './migratePageProject';
import deleteData from './deleteData';

export default {
  make : (name,payload,instance) => {

    // 注册行为
    switch(name) {
      case 'showForm':// 显示表单
        return new showForm(payload).bind(instance).handler()
      case 'migratePageProject' : // 迁移前端项目及菜单配置 
        return new migratePageProject(payload).bind(instance).handler()  
      case 'deleteData' : // 删除数据
        return new deleteData(payload).bind(instance).handler()  
      default :
        Message.error("执行" + name + "行为失败！")
    }
  }
}