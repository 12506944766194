<!--
 * @Author: zx
 * @Date: 2022-06-01 10:37:56
 * @LastEditTime: 2022-12-06 15:32:23
 * @Description: 
 * @LastEditors: zx
-->
<template>
  <div class="drawer-container">
    <div class="drawer">
      <div class="route">
        <div class="top-back">
          <i class="iconfont iconxiangzuojiantou1" @click="close" />
        </div>
        <div class="top-middle">
          <p>筛选</p>
        </div>
      </div>
      <div class="search-body">
        <div class="screen-data" v-for="(item,index) in fildList" :key="index" style="margin-bottom:8px;">
            <p class="filter-title">{{item.viewConfig.name}}</p>
            <van-row class="status-all" gutter="20" v-if="item.viewConfig.type == 'tabs'" style="margin-bottom:20px;">
                <van-col span="12" v-for="(menuItem,idx) in item.viewConfig.options" :key="idx" 
                  @click.stop="allMethod(item.viewConfig, menuItem, index)">
                    <div class="filter-status" :class="item.viewConfig.value === menuItem.value ? 'active' : ''">{{menuItem.label}}</div>
                </van-col>
            </van-row>
            <div v-if="item.viewConfig.type == 'datepicker'" style="position: relative;margin-top:8px;margin-bottom:20px;">
              <van-field style="background: rgb(242,244,245);"  size="large" is-link readonly clickable name="picker" @click="openPicker(index)"
                input-align="left" label-width="0"
                v-model="item.viewConfig.value" :placeholder="item.placeholder ? item.placeholder : '请选择...'"/>
              <div v-if="item.viewConfig.modelFlag" class="date-div">
                <datePicker v-if="item.viewConfig.modelFlag" :checkDate="item.viewConfig.value" @check-date="getDateInfo(item,$event,index)"></datePicker>
              </div>
            </div>
            <div v-if="item.viewConfig.type == 'during'" style="position: relative;margin-top:8px;margin-bottom:20px;">
              <van-field style="background: rgb(242,244,245);"  size="large" is-link readonly clickable name="picker" @click="openDuringPicker(index, 'startVisible')"
                input-align="left" label-width="0"
                v-model="item.viewConfig.start" :placeholder="item.placeholder ? item.placeholder : '请选择...'"/>
              <van-field style="background: rgb(242,244,245);margin-top: 10px"  size="large" is-link readonly clickable name="picker" @click="openDuringPicker(index, 'endVisible')"
                input-align="left" label-width="0"
                v-model="item.viewConfig.end" :placeholder="item.placeholder ? item.placeholder : '请选择...'"/>
              <div v-if="item.viewConfig.startVisible" class="date-div">
                <datePicker v-if="item.viewConfig.startVisible" :checkDate="item.viewConfig.start" @check-date="getDuringDateInfo(item,$event,index, 'start')"></datePicker>
              </div>
              <div v-if="item.viewConfig.endVisible" class="date-div">
                <datePicker v-if="item.viewConfig.endVisible" :checkDate="item.viewConfig.end" @check-date="getDuringDateInfo(item,$event,index, 'end')"></datePicker>
              </div>
            </div>
            <div v-if="item.viewConfig.type == 'radio' || item.viewConfig.type == 'checkbox'" style="position: relative;margin-top:8px;margin-bottom:20px;">
              <van-field style="background: rgb(242,244,245);"  size="large" is-link readonly clickable name="picker" @click="openSelectDrawer(index)"
                input-align="left" label-width="0"
                v-model="item.viewConfig.value" :placeholder="item.placeholder ? item.placeholder : '请选择...'"/>
            </div>
            <div v-if="item.viewConfig.type == 'select'" style="position: relative;margin-top:8px;margin-bottom:20px;">
              <van-field style="background: rgb(242,244,245);"  size="large" is-link readonly clickable name="picker" @click="openSelectDrawer(index)"
                input-align="left" label-width="0"
                v-model="item.viewConfig.value" :placeholder="item.placeholder ? item.placeholder : '请选择...'"/>
            </div>
            <van-field
                v-if="item.viewConfig.type == 'input'"
                style="background: rgb(242,244,245);margin-top:8px;margin-bottom:20px;"
                label=""
                v-model="item.viewConfig.value"
                name="pattern"
                input-align="left"
                placeholder="请输入"
            />
        </div>
      </div>
      <div class="button-list">
        <div class="btn-list" v-for="(item, index) in buttonList" :key="index" >
          <van-button :type="item.btnType" size="small" @click="btnClick(item)">
            {{item.label}}
          </van-button>
        </div>
      </div>
      <!-- <div class="select-wrap" v-if="activeIndex!==null && fildList[activeIndex] && fildList[activeIndex].viewConfig && fildList[activeIndex].viewConfig.selectVisible">
        <ComomSelect 
          v-if="activeIndex!==null && fildList[activeIndex] && fildList[activeIndex].viewConfig && fildList[activeIndex].viewConfig.selectVisible" 
          :itemData="fildList[activeIndex].viewConfig.options"
          :selectData="fildList[activeIndex].viewConfig"
          idName="value"
          nameString="label"
          :defaultRadio="fildList[activeIndex].viewConfig.showData"
          :defaultCheckbox="fildList[activeIndex].viewConfig.showData"
          @close-drawer="closeSelectDrawer"
          @checkedItem="checkedItem"
          :checkBoxFlag="fildList[activeIndex].viewConfig.type == 'checkbox'"
        ></ComomSelect>
      </div> -->
      <ComomSelect 
          v-if="activeIndex!==null && fildList[activeIndex] && fildList[activeIndex].viewConfig && fildList[activeIndex].viewConfig.selectVisible" 
          :itemData="fildList[activeIndex].viewConfig.options"
          :selectData="fildList[activeIndex].viewConfig"
          idName="value"
          nameString="label"
          :defaultRadio="fildList[activeIndex].viewConfig.showData"
          :defaultCheckbox="fildList[activeIndex].viewConfig.showData"
          @close-drawer="closeSelectDrawer"
          @checkedItem="checkedItem"
          :checkBoxFlag="fildList[activeIndex].viewConfig.type == 'checkbox'"
        ></ComomSelect>
    </div>
  </div>
</template>
<script>
  import { defineComponent, onMounted, computed, reactive, toRefs } from 'vue'
  import { Row, Col, Field, Button, Popup } from 'vant'
  import 'vant/lib/index.css'
  import { mapState } from 'vuex';
  import datePicker from '@/mobile-custom-components/datePicker/DatePicker.vue';
  import ComomSelect from '@/mobile-custom-components/select/comomSelect.vue';
  import useStore from '@/store'
  import eventBus from '@/plugins/eventBus';
  import { judgingArchiAPermission, judgingUILogic } from '@/utils/tools';
  export default defineComponent({
    components: {
      'van-row': Row,
      'van-col': Col,
      'van-field': Field,
      'van-button': Button,
      'van-popup': Popup,
      datePicker,
      ComomSelect
    },
    setup(props, context) {
      const store = useStore;
      const storeStateFns = mapState(["curComponent"]);
      const storeState = {};
      Object.keys(storeStateFns).forEach(fnKey => {
        const fn = storeStateFns[fnKey].bind({ $store: store });
        storeState[fnKey] = computed(fn);
      });
      console.log(storeState.curComponent, 'storeState.curComponent')
      const state = reactive({
        fildList: [],
        searchList: [],
        buttonList: [
          {
            btnType: "warning",
            icon: "el-icon-user-solid",
            isCustom: true,
            label: "取消",
            show: true,
            type: "cancel"
          },
          {
            btnType: "primary",
            icon: "el-icon-setting",
            isCustom: true,
            label: "确认",
            show: true,
            type: "submit"
          }
        ],
        activeItem: {},
        activeIndex:null,
      })
      onMounted(() => {
        setTimeout(() => {
          getFiledList()
          eventBus.$on('databaseTrigger', () => {
            getFiledList()
          })
        }, 20);
      })
      const { emit } = context
      const close = () => {
        emit('close', false)
      }
      const getFiledList= () => {
        console.log(storeState?.curComponent?.value?.database?.fieldList, 'storeState.curComponent.value.database.fieldList')
        if(storeState?.curComponent?.value?.database?.fieldList?.length) {
          state.fildList = []
          storeState.curComponent.value.database.fieldList.forEach((item) => {
            if (item.viewConfig.showInFilter && judgingArchiAPermission(item.viewConfig.archiLimit, item.viewConfig.permission) && judgingUILogic(item.viewConfig.UILogic)) {
              if (item.viewConfig.type === 'input' || item.viewConfig.type === 'tabs') {
                if(item.viewConfig.options && item.viewConfig.options.length) {
                  item.viewConfig.options.forEach(element => {
                    if (element.defaultSelect) {
                      item.viewConfig.value = element.value
                    }
                  });
                } else {
                  item.viewConfig.value = ''
                }
              } else if (item.viewConfig.type === 'datepicker') {
                if(!item.viewConfig.modelFlag) {
                  item.viewConfig.modelFlag = false
                }
              } else if (item.viewConfig.type === 'during') {
                if(!item.viewConfig.startVisible) {
                  item.viewConfig.startVisible = false
                }
                if(!item.viewConfig.endVisible) {
                  item.viewConfig.endVisible = false
                }
              } else if (item.viewConfig.type == 'radio' || item.viewConfig.type == 'checkbox') {
                if(!item.viewConfig.selectVisible) {
                  item.viewConfig.selectVisible = false
                }
              }
              state.fildList.push(item)
            }
          })
          console.log(state.fildList,'state.fildList');
          console.log(storeState.curComponent.value, 'storeState.curComponent.value')
        }
      }
      const allMethod = (data, item, index) => {
        state.fildList[index].viewConfig.value = item.value
      }
      /* select类型 */
      // 打开
      const openSelectDrawer = (index)=>{
        state.activeIndex = index
        state.fildList[index].viewConfig.selectVisible = true
      }
      const closeSelectDrawer = ()=>{
        state.fildList[state.activeIndex].viewConfig.selectVisible = false
        const newData = JSON.parse(JSON.stringify(state.fildList))
        state.fildList = newData
      }
      const checkedItem = (data)=>{
        console.log(data,'data');
        state.fildList[state.activeIndex].viewConfig.showData = data
        if (data && !Array.isArray(data)) {
          const boo = state.fildList[state.activeIndex].viewConfig.optionOrign === 'database'
          state.fildList[state.activeIndex].viewConfig.selectValue = boo ? data[state.fildList[state.activeIndex].viewConfig.optionConfig.valueCode] : data.value
          state.fildList[state.activeIndex].viewConfig.value = boo ? data[state.fildList[state.activeIndex].viewConfig.optionConfig.labelCode] : data.label
          return
        } else if (!data) {
          state.fildList[state.activeIndex].viewConfig.selectValue = ''
          state.fildList[state.activeIndex].viewConfig.value = ''
          return
        }
        if (data && data.length && state.fildList[state.activeIndex].viewConfig.optionOrign === 'database') {
          const arr = []
          let showStr = ''
          data.forEach(element => {
            const boo = state.fildList[state.activeIndex].viewConfig.optionOrign === 'database'
            const value = boo ? element[state.fildList[state.activeIndex].viewConfig.optionConfig.valueCode] : element.value
            arr.push(value)
            console.log(state.fildList[state.activeIndex].viewConfig.optionConfig.labelCode, element, 'name')
            if (!showStr) {
              showStr = boo ? element[state.fildList[state.activeIndex].viewConfig.optionConfig.labelCode] : element.label
            } else {
              showStr += (boo ?',' + element[state.fildList[state.activeIndex].viewConfig.optionConfig.labelCode] :',' + element.label)
            }
          });
          state.fildList[state.activeIndex].viewConfig.selectValue = arr
          state.fildList[state.activeIndex].viewConfig.value = showStr
        } else {
          state.fildList[state.activeIndex].viewConfig.selectValue = []
          state.fildList[state.activeIndex].viewConfig.value = ''
        }
        console.log(state.fildList[state.activeIndex].viewConfig.value, 'value');
        console.log(state.fildList[state.activeIndex].viewConfig,'当前配置文件');
      }
      /*  */
      const openPicker=(index)=>{
          state.fildList[index].viewConfig.modelFlag = true
          const newData = JSON.parse(JSON.stringify(state.fildList))
          state.fildList = newData
      }
      const openDuringPicker=(index, type)=>{
          state.fildList[index].viewConfig[type] = true
          const newData = JSON.parse(JSON.stringify(state.fildList))
          state.fildList = newData
      }
      
      const getDateInfo = (item,data,index) => {
        state.fildList[index].viewConfig.modelFlag = false
        state.fildList[index].viewConfig.value = data
        const newData = JSON.parse(JSON.stringify(state.fildList))
        state.fildList = newData
      }
      const getDuringDateInfo = (item,data,index, type) => {
        if (type === 'start') {
          state.fildList[index].viewConfig.startVisible = false
        } else {
          state.fildList[index].viewConfig.endVisible = false
        }
        const newData = JSON.parse(JSON.stringify(state.fildList))
        state.fildList = newData
        state.fildList[index].viewConfig[type] = data
      }
      const btnClick = (data) => {
        console.log(data)
        if (data.type === 'cancel') {
          eventBus.$emit('drawerCancel');
        } else if (data.type === 'submit') {
          eventBus.$emit('drawerCancel');
          const search = []
          if (state.fildList && state.fildList.length) {
            state.fildList.forEach(element => {
              if ((
                  element.viewConfig.type === 'input'
                  || element.viewConfig.type === 'tabs'
                  || element.viewConfig.type === 'datepicker'
                ) &&
                element.viewConfig.value
              ) {
                search.push({
                  field_uuid: element.viewConfig.uuid,
                  ruleType: element.viewConfig.ruleType,
                  value: element.viewConfig.value
                })
              } else if (element.viewConfig.type === "during" && element.viewConfig.start && element.viewConfig.end) {
                search.push({
                  field_uuid: element.viewConfig.uuid,
                  ruleType: element.viewConfig.ruleType,
                  value: [element.viewConfig.start, element.viewConfig.end]
                })
              } else if ((element.viewConfig.type == 'radio' || element.viewConfig.type == 'checkbox'  || element.viewConfig.type == 'select') && element.viewConfig.selectValue) {
                console.log(element.viewConfig, 'element.viewConfig')
                search.push({
                  field_uuid: element.viewConfig.uuid,
                  ruleType: element.viewConfig.ruleType,
                  value: element.viewConfig.selectValue
                })
              }
            });
          }
          console.log(state.fildList, 'fildList')
          console.log(search, 'search111')
          eventBus.$emit('searchClick', search);
        }
      }
      return {
        ...toRefs(state),
        close,
        allMethod,
        openPicker,
        getDateInfo,
        openDuringPicker,
        getDuringDateInfo,
        btnClick,
        openSelectDrawer,
        checkedItem,
        closeSelectDrawer
      }
    }
  })
</script>
<style lang='less' scoped>
.drawer-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  .drawer {
    width: 70%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background-color: #fff;
    animation-name: popup;
    animation-duration: 0.4s;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    z-index: 1;
    .select-wrap{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #fff;
      z-index: 2;
    }
    .route {
      height: 44px;
      display: flex;
      position: relative;
      .top-back {
        width: 20%;
        max-width: 20%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        i {
          font-size: 24px;
        }
      }
      .top-middle {
        flex: 1;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: 550;
      }
    }
    .search-body {
      width: 100%;
      padding: 16px;
      box-sizing: border-box;
      flex: 1;
      overflow-y: scroll;
      .screen-data {
        width: 100%;
        text-align: left;
        .filter-status{
            width:108px;
            text-align:center;
            height:45px;
            line-height: 45px;
            background:rgba(242,244,245,1);
            font-size:15px;
            font-family:'PingFangSC-Regular';
            font-weight:400;
            color:rgba(37,40,46,1);
            margin-top:8px;
        }
        .active{
            background:rgba(5,143,253,0.1);
            color:rgba(5,143,253,1);
            font-weight:600;
        }
        .date-div{
          width: 100%;
          // height: 330px;
          position: absolute;
          z-index: 100000;
          right: 0;
          top: 0;
          background: rgb(255,255,255);
          box-shadow: 0 5px 5px -3px #999, 0 8px 10px 1px #999, 0 3px 14px 2px #999;
        }
      }
    }
    .button-list {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      .btn-list {
        flex: 1;
        button {
          width: 100%;
          height: 44px;
        }
      }
    }
  }
}
@keyframes popup {
	/* 动画开始时的状态 */
	0% {
		width: 0%;
	}
	100% {
		width: 70%;
	}
}
</style>
