/*
 * @Description: 配置组件内置的表单
 * @Author: luocheng
 * @Date: 2021-09-29 16:14:12
 * @LastEditors: luocheng
 * @LastEditTime: 2021-09-29 16:15:32
 */
export default {
  state: {},
  mutations: {
    /**
     * @desc: 配置当前组件的表单配置
     * @param {Object} curComponent
     * @param {Object} config
     */
    setFormConfig({ curComponent}, config) {
      if (!curComponent || !config) return;
      curComponent.formConfig = config; 
    }
  }
}