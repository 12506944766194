<!--
 * @Description: 图表盒子
 * @Author: luocheng
 * @Date: 2022-01-10 11:07:04
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2023-01-06 17:34:30
-->
<template>
	<div
		class="common-chart"
		:id="boxId"
		v-loading="loading"
		ref="commonChart"
	>
		<!-- 全屏图标 -->
		<!-- <div class="eject-icon" @click.stop="dialogVisible = !dialogVisible" :style="transformStyle">
			<i class="iconfont icondaping-quanping" />
		</div> -->
		<component
			:is="chartData.component"
			v-if="initEnd"
			:chartData="chartData"
			:height="chartHeight"
			:chartUUID="chartUUID"
			:paramsConfig="paramsConfig"
			:isGroup="isGroup"
			:groupComponents="groupComponents"
			:scaleWidth="scaleWidth"
		>
		</component>
		<van-empty description="配置错误" image="error" v-else></van-empty>
		<!-- 全屏查看 -->
		<!-- <van-dialog
			:visible.sync="dialogVisible"
			title=""
			:modal="false"
			:append-to-body="true"
			custom-class="chart-container-dialog"
			width="60%"
		>
			<template v-if="dialogVisible">
				<div style="">
					<component
						:is="chartData.component"
						v-if="initEnd"
						:chartData="chartData"
						:height="600"
						:chartUUID="chartUUID"
						:paramsConfig="paramsConfig"
						:isGroup="isGroup"
						:groupComponents="groupComponents"
						:scaleWidth="scaleWidth"
						:scaleHeight="scaleHeight"
					>
					</component>
					<van-empty description="配置错误" v-else></van-empty>
				</div>
			</template>
		</van-dialog> -->
	</div>
</template>

<script>
import { isJSONStr } from '@/utils/tools';
import { pageFromDetail } from '@/api/saas'
import { Empty, Dialog } from 'vant';
// 注册组件
const componentsList = require.context(
	'@/mobile-chart-component/components',
	true,
	/\.+vue$/
);
const customComponents = {};
componentsList.keys().forEach((fileName) => {
	let name = fileName.replace(/.vue/, '');
	name = name.replace(/\.\//, '');
	if (name.indexOf('/') < 0) {
		customComponents[name] = componentsList(fileName).default;
	}
});

export default {
	name: 'CommonChart',
	props: {
		element: {
			type: Object,
			required: true,
			default: () => {}
		},
    // 是否在组合内
    isGroup: {
			type: Boolean,
		},
    // 组合内组件列表
		groupComponents: {
			type: Array,
			default: () => []
		},
    scaleWidth: {
      type: Number,
			default: 1
    },
		scaleHeight: {
			type: Number,
			default: 1
		}
	},
	components: {
		...customComponents,
    'van-empty': Empty,
		'van-dialog': Dialog
	},
	data() {
		return {
			chartHeight: '',
			boxId: `component${this.element.id}`,
			domInit: false,
			// 图表数据
			chartData: null,
			loading: false,
			dialogVisible: false,
			editorType: ''
		};
	},
	computed: {
		chartUUID() {
			return this.element?.chartConfig?.chartUUID || '';
		},
		initEnd() {
			return this.domInit && this.chartUUID && this.chartData;
		},
		height() {
			return this.element?.style?.height;
		},
		width() {
			return this.element?.style?.width;
		},
		paramsConfig() {
			return this.element?.paramsConfig;
		},
		transformStyle() {
			return {
				top: `${this.scaleHeightSize(5)}px`,
				right: `${this.scaleSize(5)}px`,
				width: `${this.scaleSize(16)}px`,
				height: `${this.scaleHeightSize(16)}px`,
			}
		}
	},
	created() {
		this.editorType = sessionStorage.getItem('editorType');
		this.boxId = `component${this.element.id}`;
		this.getChartData();
	},
	mounted() {
		this.resetBoxSize();
		window.addEventListener('resize', this.resetBoxSize);
	},
	watch: {
		chartUUID() {
			if (this.chartUUID) {
				this.getChartData();
			}
		},
		height: {
			handler() {
			this.$nextTick(() => {
				this.resetBoxSize();
			})
		},
		deep: true,
		immediate: true
		},
		width() {
			this.$nextTick(() => {
				this.resetBoxSize();
			})
		}
	},
	methods: {
		/**
		 * @desc: 获取图表配置数据
		 */
		getChartData() {
			this.chartData = null;
			if (!this.chartUUID) {
				return;
			}
			// 获取
			this.loading = true;
			pageFromDetail({
				__method_name__: 'dataList',
				page_uuid: this.chartUUID,
				object_uuid: 'a4f016d6-c602-4492-8874-f088c3c0b3b9',
				view_uuid: 'view61b951c6a8186',
				transcode: 0
			}).then((res) => {
				if (!res || res.status !== 200) {
					this.loading = false;
					return;
				}
				const { page_data } = res.data.data?.[0] || {};
				if (!page_data) {
					return;
				}
				// attributes 属性
				const { chartData } = isJSONStr(page_data) ? JSON.parse(page_data) : page_data;
				this.chartData = chartData;
				this.loading = false;
			}).catch((err) => {
				console.log(err);
				this.loading = false;
			});
		},
		/**
		 * @desc: 样式resize
		 */
		resetBoxSize() {
			this.$nextTick(() => {
				const less = this.editorType === 'dataview' ? 32 : 0;
				this.chartHeight = (this.$refs.commonChart?.offsetHeight || 200 * this.scaleHeight) - less * this.scaleHeight;
				this.domInit = true;
			});
		},
		/**
     * @desc: 缩放适配2/4K屏幕
     * @param {Number} num
     */
    scaleSize(num) {
      if (isNaN(+num)) {
        return num;
      }
			// const ratio = window.devicePixelRatio;
      const ratio = 1;
      return ((+num) * this.scaleWidth * ratio).toFixed(2);
    },
    scaleHeightSize(num) {
      if (isNaN(+num)) {
        return num;
      }
			// const ratio = window.devicePixelRatio;
      const ratio = 1;
      return ((+num) * this.scaleHeight * ratio).toFixed(2);
    }
	}
};
</script>

<style lang="less" scoped>
.common-chart {
	height: 100%;
	width: 100%;
	position: relative;
	&:hover{
		.eject-icon{
			visibility: visible;
		}
	}
	.eject-icon{
		visibility: hidden;
		position: absolute;
		top: 5px;
		right: 5px;
		width: 16px;
		height: 16px;
		z-index: 1000;
		color: #eee;
		&:hover{
			color: #409eff;
		}
	}
	:deep(.el-empty){
		padding: 0;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
		.el-empty__image{
			width: 35%;
			max-width: 120px;
			min-width: 50px;
		}
	}
}
</style>