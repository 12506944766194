<!--
 * @Author: zx
 * @Date: 2022-09-29 16:15:54
 * @LastEditTime: 2023-01-06 16:57:55
 * @Description: 
 * @LastEditors: 朱廷果 1028509503@qq.com
-->
<template>
  <div class="left-item">
    <Splitpanes horizontal :push-other-panes="false" vertical>
      <Pane :size="topSize" min-size="20" max-size="50">
        <div class="left-item-top">
          <div class="left-item-title">
            <el-input
              class="item-title-input"
              v-model="searchValue"
              placeholder="请输入名称"
              clearable
              @input="searchValueChange"
              prefix-icon="el-icon-search"
            ></el-input>
            <div class="item-title">
              <p>我的应用组</p>
              <div :class="(showTemplatePage && !inTemplatechild) ? 'select' : 'local'"  @click="add">
                <i class="iconfont iconxinzeng2"></i>
              </div>
            </div>
          </div>
          <div class="left-item-tree">
            <LeftItem
              ref="leftItem"
              v-loading="loading"
              delAble
              :tableData="listData"
              :searchAble="false"
              :childShowAdd="true"
              :hideHeader="true"
              :defaultProps="defaultProps"
              :historyAppInfo="historyAppInfo"
              :historyChildAppInfo="historyChildAppInfo"
              :showHistoryVisible="showHistoryVisible"
              field="name"
              @row-del="rowDel"
              @row-click="rowClick"
              @row-edit="rowEdit"
              @history="hasHistory"
            />
          </div>
        </div>
      </Pane>
      <Pane :size="100 - topSize">
        <div class="left-item-bottom">
          <div class="item-title" :class="rowClickData ? '' : 'disabled'">
            <p>应用功能</p>
            <div class="child" @click="childAdd">
              <i class="iconfont iconadd"></i>
            </div>
          </div>
          <LeftItem
            v-if="tableData && tableData.length"
            ref="leftItemTree"
            v-loading="loading"
            delAble
            :showBack="true"
            :tableData="tableData"
            :historyChildAppInfo="historyChildAppInfo"
            :showHistoryVisible="showHistoryVisible"
            :hideHeader="true"
            :defaultProps="{
              label: 'name',
              children: 'children',
            }"
            field="name"
            @row-del="rowDel"
            @row-click="childRowClick"
            @row-edit="childRowEdit"
            @row-add="childRowAdd"
            @history="hasHistory"
          />
          <div v-if="!tableData.length && rowClickData" class="nodata">
            暂无功能
          </div>
        </div>
      </Pane>
    </Splitpanes>
  </div>
</template>
<script>
import { Splitpanes, Pane } from "splitpanes";
import LeftItem from "@/manage-views/views/home/components/applicationTree/components/LeftItem.vue";
import "splitpanes/dist/splitpanes.css";
export default {
  components: {
    Splitpanes,
    Pane,
    LeftItem,
  },
  props: {
    appListShow: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    /* 应用数组 */
    listData: {
      type: Array,
      default: () => [],
    },
    /* 应用下数据 */
    tableData: {
      type: Array,
      default: () => [],
    },
    /* 记录当前选择页面后的历史appinfo */
    historyAppInfo: {
      type: Object,
      default: () => {},
    },
    /* 记录当前选择的功能 */
    historyChildAppInfo: {
      type: Object,
      default: () => {},
    },
    /* 当前是否点击的应用  如果为true  就算有子级  也是父级展示蓝色  子级展示灰色 点击应用则应用显示为蓝色 */
    showHistoryVisible: {
      type: Boolean,
      default: true,
    },
    showTemplatePage: {
      type: Boolean,
      default: false,
    },
    inTemplatechild:{
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      topSize: 40,
      searchValue: "",
      addSelect: false, //判断当前是否为添加模板模式
      defaultProps: {
        label: "name",
        children: "cancel",
      },
      rowClickData:null,
    };
  },
  mounted() {

  },
  methods: {
    hasHistory(val){
      console.log(val)
      if(val.id){
        this.rowClickData=val
      }else{
        this.rowClickData=null
      }
    },
    rowDel(info) {
      this.$emit("f-row-del", info);
    },
    rowClick(info) {
      this.$emit("f-row-click", info);
    },
    add() {
      this.$emit("f-top-add");
    },
    rowEdit(info) {
      this.$emit("f-row-edit", info);
    },
    childRowClick(info) {
      this.$emit("row-click", info);
    },
    childAdd() {
      if(!this.rowClickData){
        return
      }
      this.$emit("top-add", this.rowClickData);
    },
    childRowEdit(info) {
      this.$emit("row-edit", info);
    },
    childRowAdd(info) {
      this.$emit("row-add", info);
    },
    /* 搜索 */
    searchValueChange() {
      this.$refs.leftItem.filterValue(this.searchValue);
      if(this.$refs.leftItemTree){
        this.$refs.leftItemTree.filterValue(this.searchValue);
      }
    },
  },
};
</script>
<style lang="less" scoped>
:deep(.el-input.is-active .el-input__inner .el-input__inner:focus) {
  border-color: #409eff;
  outline: 0;
  background-color: #fff !important;
}
:deep(.splitpanes__splitter) {
  min-height: 4px;
  height: 4px;
  cursor: row-resize;
  position: relative;
  background-color: transparent;
  &:after {
    position: absolute;
    top: 0;
    left: 0;
    height: 1px;
    width: 100%;
    box-sizing: border;
    content: "";
    background: #eee;
    border-radius: 6px;
  }
}
:deep(.el-input__inner) {
  background: #f2f4f7;
  border: 1px solid transparent;
  border-radius: 6px;
}
:deep(.el-input__inner:hover) {
  border-color: #f2f4f7;
}
:deep(.el-input__inner:focus) {
  border-color: #337eff;
}
:deep(.el-input__inner:focus) {
  background: #fff;
  border-radius: 6px;
}
.left-item {
  width: 100%;
  height: 100%;
  .left-item-top {
    width: 100%;
    height: 100%;
    padding: 12px 8px 0 8px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .left-item-title {
      width: 100%;
      height: auto;
      .item-title-input {
        height: 32px;
        background: #f2f4f7;
        border-radius: 6px;
      }
    }
    .left-item-tree {
      flex: 1;
      overflow-y: auto;
    }
  }

  .item-title {
        height: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 8px 0 4px 0;
        padding: 0px 0px 0px 4px;
        p {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 500;
          color: #616e8f;
          line-height: 18px;
        }
        .select {
          background: #337eff !important;
          .select:hover {
            background: #337eff !important;
          }
          i {
            color: #fff !important;
          }
        }
        div {
          width: 24px;
          height: 24px;
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        div:hover {
          background: #e6e9f0;
        }
        .local {
          background: #f2f4f7;
          i {
            font-size: 16px;
            color: #8590a9;
          }
        }
        .local:hover {
          background: #e6e9f0;
        }
        .child{
          color: #616e8f;
          font-size: 14px;
        }
        .child:hover{
            background: #F4F6FA;
          }
      }
  .left-item-bottom {
    width: 100%;
    height: 100%;
    padding: 8px;
    padding-top: 0;
    box-sizing: border-box;
    .disabled{
      p{
        color: #C1C7CD;
      }
      div{
        cursor:not-allowed;
      }
      div:hover {
          background: #F4F6FA ;
      }
      .child{
        color: #C1C7CD!important
        
      }
    }
    .nodata{
      width:100%;
      height:64px;
      font-size: 12px;
      color: #A5ACB3;
      font-weight: 400;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

</style>
