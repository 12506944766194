<template>
  <div class="live-com-container" v-loading="loading">
    <div v-if="!loading && successed" class="live-com-inner" v-generateLive="liveOption">
    </div>
    <div v-if="!loading && !successed" class="none-msg">
      <van-empty description="暂无内容"></van-empty>
    </div>
  </div>
</template>
<script>
import { EZUIPlayer } from '@/libs/ezuikit.js'
import HLS from '@/libs/HLS.js'
import Minin from './mixin'
import { dataInterface } from '@/apis/data/index'
import eventBus from '@/plugins/eventBus';
import { getComponentById, initParams } from '@/utils/tools';
import { Empty } from 'vant';
export default {
  name: 'LiveCom',
  components: {
		'van-empty': Empty,
  },
  mixins: [Minin],
  data() {
    return {
      liveObj: null,
      player: null,
      url: '',
      loading: false,
      successed: true,
    }
  },
  created() {
		if (this.database && !this.hasComponentParam()) {
			const { search = [], param = {}, canPost } = initParams(this.element?.database?.paramsConfig || [], this.isGroup, this.componentData, this.groupComponents);
			if (!canPost) return;
			this.getList(this.database, search, param);
		}
		this.metadata = this.element.metadata || [];
	},
  mounted() {
		// 监听请求
		// 配置关联参数的容器才需要监听
		eventBus.$on('databaseTrigger', (data) => {
			if (data.parentId && data.parentId !== this.element.id) return false;
			// 配置时候触发请求
			if (data.componentId === this.element.id && data.isInit) {
				const { search = [], param = {}, canPost } = initParams(this.element?.database?.paramsConfig || [], this.isGroup, this.componentData, this.groupComponents);
				// console.log("配置时候触发请求-------", canPost, search, param);
				if (!canPost) return;
				this.getList(this.element.database, search, param);
				return;
			}
			// 点击操作时候不更新数据
			if (data.noUpdate) return;
			const { paramsConfig } = this.element.database;
			if (!paramsConfig || !paramsConfig.length) return;
			// 以下步骤是为了避免有多个来源的search需要进行differ 避免检索结果错误情况
			let { search = [], param = {}, canPost } = initParams(this.element?.database?.paramsConfig || [], this.isGroup, this.componentData, this.groupComponents);
			// console.log("以下步骤是为了避免有多个来源的search需要进行differ-------", canPost, search, param);
			if (!canPost) return;
			let isTarget = false;
			paramsConfig.forEach((ele) => {
				if (ele.componentId === data.componentId) {
					isTarget = true;
				}
			});
			if (!isTarget && !data.isUpdate) return;
			this.param = param;
			this.getList(this.element.database, search, param);
		});
	},
  directives: {
    /**
     * @description 用于生成直播流的指令
     * @param el { Object } element
     * @param binding { Object } src:地址; videoType:播放类型; setLiveObj:设置播放对象; setPlayer: 设置播放对象; player: 返回的实例
     */
    generateLive: (() => {
      // 使用闭包暂存处理函数
      const generateLive = (el, binding) => {
        // 如果存在旧值
        if (binding.oldValue) {
          const typeFlag = binding.oldValue.videoType === binding.value.videoType;
          const srcFlag = binding.oldValue.src === binding.value.src;
          const showControlFlag = binding.oldValue.showControl === binding.value.showControl;
          // 如果值并没有变化则不进行处理，原因是因为当盒子被点击时compute会重新计算
          if (typeFlag && srcFlag && showControlFlag) {
            return;
          }
        }
        // 如果存在之前的实例，停掉它
        if (binding.value.player && binding.value.player.stop) {
          binding.value.player.stop();
        }
        if (binding.value.player && binding.value.player.destroy) {
          binding.value.player.destroy();
        }
        // 清空el
        el.innerHTML = '';
        const videoObj = document.createElement('video');
        videoObj.style = 'width: 100%; height: 100%;object-fit: fill;';
        videoObj.setAttribute('autoplay', 'autoplay');
        if(binding.value.showControl) {
          videoObj.setAttribute('controls', binding.value.showControl);
        }
        videoObj.setAttribute('playsInline', true);
        videoObj.setAttribute('webkit-playsinline', true);
        videoObj.muted = true;
        const source = document.createElement('source');
        if(binding.value.videoType === 'YS7') {
          source.src = binding.value.src;
          source.setAttribute('type', "application/x-mpegURL");
          videoObj.appendChild(source);
          const player = new EZUIPlayer(videoObj);
          binding.value.setPlayer(player);
        } else if(binding.value.videoType === 'HLS') {
          if(HLS.isSupported()) {
            const hls = new HLS();
            hls.attachMedia(videoObj);
            hls.on(HLS.Events.MEDIA_ATTACHED, function () {
              console.log('video and hls.js are now bound together !');
              hls.loadSource(binding.value.src);
              hls.on(HLS.Events.MANIFEST_PARSED, function (event, data) {
                console.log('manifest loaded, found ' + data.levels.length + ' quality level');
                videoObj.play();
              })
              hls.on(HLS.ErrorTypes.NETWORK_ERROR, (err, data) => {
                console.error(err, data);
              })
              hls.on(HLS.ErrorTypes.MEDIA_ERROR, (err, data) => {
                console.error(err, data);
              })
              hls.on(HLS.ErrorTypes.OTHER_ERROR, (err, data) => {
                console.error(err, data);
              })
            })
            binding.value.setPlayer(hls);
          }
        } else {
          source.src = binding.value.src || 'https://minio.bimcc.com:9000/jgsc/20220226-6219ce63eae0c.mp4';
          videoObj.appendChild(source);
          videoObj.setAttribute('loop', true);
          videoObj.setAttribute('autoplay', true);
          videoObj.addEventListener('click', () => {
            videoObj.muted = !videoObj.muted;
          })
        }
        el.appendChild(videoObj);
        binding.value.setLiveObj(videoObj);
      }
      return (
        {
          inserted: generateLive,
          update: generateLive,
          unbind: (el, binding) => {
            if (binding.value.player && binding.value.player.stop) {
              binding.value.player.stop()
            }
            if (binding.value.player && binding.value.player.destroy) {
              binding.value.player.destroy()
            }
          }
        }
      )
    })()
  },
  computed: {
    liveOption() {
      return  {
        src: this.formatURL(this.url || this.statusConfig.src || ''),
        videoType: this.url ? 'YS7' : '' || this.statusConfig.videoType || 'other',
        showControl: this.showControl,
        setLiveObj: this.setLiveObj,
        setPlayer: this.setPlayer,
        player: this.player
      }
    },
    // 数据仓库配置
		database() {
			return this.element && this.element.database;
		},
    showControl() {
      return this.element && this.element.statusConfig && this.element.statusConfig.showControl;
    }
  },
  methods: {
    /**
     * @description 在 https 下将地址 http 转为 https; 目前只是权宜之计;
     * @param { String } url 需要处理的url
     */
     formatURL(url) {
       if(url) {
         const reg = /http[s]?/i;
         if(window.location.protocol === 'https:') {
           return url.replace(reg, 'https');
         }
       }
       return url
     },
    /**
		 * @desc: 获取渲染列表
		 * @param {Object} database 数据配置对象
		 * @param {Array} search 搜索
		 */
		getList(database, search = [], params = {}) {
			this.search = Array.isArray(search) ? search : [];
			this.sourceData = {};
			if (!this.validDatabase(database)) return;
			this.loading = true;
			const paramsObj = {
				...params
			};
			// console.log('获取渲染列表______表格容器', params, paramsObj);
			// 配置
			let __method_name__ = 'dataList';
			const mapping = database.mapping;
			let configObj = null;
			if (mapping === 'object') {
				configObj = {
					__method_name__,
					object_uuid: database.objectData.uuid,
					view_uuid: database.viewData.uuid,
					transcode: 0,
					...paramsObj,
					search
				};
			} else if (mapping === 'relation') {
				__method_name__ = 'relationList';
				configObj = {
					__method_name__: 'relationList',
					object_uuid: database.objectData.uuid,
					relationship_uuid: database.relationData.uuid,
					...paramsObj,
					search
				};
			}
      if(this.statusConfig.screen_sequence) {
        configObj.screen_sequence = this.statusConfig.screen_sequence;
      }
			// 获取表格数据
      this.url = '';
      this.loading = true;
      this.successed = false;
			dataInterface(configObj)
				.then((res) => {
					if (res && res.status === 200) {
            // 验证数据格式
            if(Array.isArray(res.data.data) && res.data.data.length) {
              this.url = res.data.data[0].monitor_path;
              this.successed = true;
            }
          }
				})
				.catch((err) => {
					console.log(err);
				}).finally(() => {
          this.loading = false;
        });
		},
    /**
		 * @desc: 验证
		 * @param {Object} database 数据仓库的绑定
		 */
		validDatabase(database) {
			if (!database || typeof database !== 'object') return false;
			if (!database.objectData) return false;
			if (!database.viewData && !database.relationData) return false;
			return true;
		},
    /**
		 * @desc: 判断是否存在依赖其他组件的取值
		 */
		hasComponentParam() {
			if (!this.database.paramsConfig || !this.database.paramsConfig.length) {
				return false;
			}
			for (let i = 0; i < this.database.paramsConfig.length; i++) {
				const { componentId = '', key = '', sourceType = '' } = this.database.paramsConfig[i];
				if ((key !== 'search' || !componentId.includes('CommonForm')) && sourceType !== 'url') {
					// componentId.includes('CommonTableContainer')兼容跨页请求的动态判定
					// 补充不同架构问题
					if (!componentId.includes('CommonTableContainer') && this.paramsSameArchi(componentId)) {
						return true;
					}
				}
			}
			return false;
		},
    /**
		 * @desc: 判断依赖的参数是否在当前架构下启用(@凌志华树形图架构限制)
		 * @param {String} componentId
		 * @return {Boolean}
		 */
		paramsSameArchi(componentId) {
			const comp = getComponentById(this.componentData, componentId);
			if (!comp) return false;
			const targetArchi = this.$GetTargetArchi('archiType');
			if (comp && comp.archiLimit && comp.archiLimit.length && comp.archiLimit.includes(targetArchi)) {
				return true;
			}
			return false;
		},
    setLiveObj(obj) {
      this.liveObj = obj
    },
    setPlayer(obj) {
      this.player = obj
    }
  }
}
</script>
<style lang="less" scoped>
  .live-com-container{
    width: 100%;
    height: 100%;
    .live-com-inner{
      width: 100%;
      height: 100%;
    }
    .none-msg{
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
</style>