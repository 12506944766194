<template>
  <div class="currency-component-container" :style="style">
    <a
      :href="hrefValue"
      :target="target"
      :style="style"
      >{{ element.propValue ?  element.propValue : '-'}}</a
    >
  </div>
</template>

<script>
import Minin from './mixin'
export default {
  name: 'Hyperlinks',
  mixins: [Minin],
  computed: {
    style() {
      return {
        fontSize: this.fontSize,
        textAlign: this.textAlign || '',
        lineHeight: this.lineHeight || '',
        fontWeight: this.bold,
        fontStyle: this.italic,
        // fontFamily: this.fontFamily,
      }
    },
    // 超链接href
    hrefValue() {
      if(!this.statusConfig.hrefValue && window.location.href.indexOf('modify-page')> -1){
        return 'javascript:void(0)'
      }
      return this.statusConfig.hrefValue
    },
    // 打开目标
    target() {
      return this.statusConfig.openWay || "_blank"
    }
  }
}
</script>

<style lang="less" scoped>
  .currency-component-container{
    width: 100%;
    height: 100%;
  }
</style>