/*
 * @LastEditTime: 2022-08-04 15:15:18
 * @Description: 按钮行为 - 弹出展示某个新增？编辑？详情？表单
 * @FilePath: /dwh_front/src/views/listViewer/actions/showForm.js
 * @Date: 2021-12-01 16:27:52
 * @Author: lisushuang
 * @LastEditors: ttheitao
 */
/* eslint-disable */

import { Button, Dialog, Message, MessageBox } from "element-ui"
import objects from "../../../api/objects"
import parser from "@/components/formParser/parser_pc";
import baseAction from "./baseAction"
import Vue from 'vue';


// 组件内调用方式

// let form = new showForm(object_uuid).bind(this).handler()

export default class showForm extends baseAction {

  /**
   * @description: 显示表单
   * @param {String} object_uuid 对象uuid
   * @param {String} form_uuid 表单uuid ？ 可以为空
   * @param {Int} data_id 数据id 为空时新增，不为空时编辑
   * @param {Boolean} disabled 是否禁止编辑 ？ 为true时整个表单全部disable
   * @return {*} void
   */
  constructor(config) {
    super()
    this.object_uuid = config.object_uuid
    this.form_uuid = config.form_uuid
    this.displayData = {}
    this.formDesignData = {}
    this.message = null
    this.form = null
    this.data_id = config.data_id
    this.loading = null
    this.disabled = config.disabled ? true : false
    let default_name = '编辑'
    if(this.disabled) default_name = '详情'
    if(!this.disabled && !this.data_id) default_name = '新增'
    this.title = config.title ? config.title : default_name
  }

  /**
   * @description: 根据配置请求接口
   * @param {*} data 已经验证过的表单数据
   * @return {*}
   */  
  doSure(data){
    if(this.disabled) return 
    if(this.data_id){
      data.data_id = this.data_id
      data.__method_name__ = 'updateData'
    }else{
      data.__method_name__ = 'createData'
    }
    data.object_uuid = this.object_uuid
    this.getLoading('请求中...')
    objects.mapi.common(data).then(res => {
      if(res.data.code == 200){
        Message.success('操作成功')
        this.loading.close()
        this.changeRefreshTag()
        this.form.closeMe()
      }else{
        Message.error(res.data.msg)
      }
    }).catch(res => {
      Message.error('请求失败！')
    })
  }


  handler(needRender=false) {
    let uuid = this.form_uuid ? this.form_uuid : this.object_uuid
    let _this = this

    this.initFormDesign(uuid).then(res => {

      this.form = new Vue({
        data: {
          visible: true
        },
        methods: {
          closeMe() {
            this.visible = false
            setTimeout(() => {
              _this.form.$el.remove() 
              this.$destroy()                          
            }, 500);
          }
        },
        render: function (h) {
            return (
              <Dialog title={_this.title} visible={this.visible} v-on:close={() => this.closeMe('123')}>
                <parser 
                  form-data={_this.formDesignData} 
                  displayData={_this.displayData} 
                  disabled={_this.disabled}
                  ref="pcparser"
                  v-on:submit={(res) => {_this.doSure(res)}}
                ></parser>
                <div style="text-align:center" v-show={!_this.disabled}>
                  <Button type="danger" onClick={() => this.closeMe()}>取消</Button>
                  <Button type="success" onClick={() => this.$refs.pcparser.handleSubmit()}>确认</Button>
                </div>
                
              </Dialog>
            )
          
        }
      }).$mount()
      this.instance.$el.appendChild(this.form.$el)
    }).catch(res => {
      console.error(res, 'show form error')
      Message.error('请求表单配置数据失败！')
    })
  }


  /**
   * @description: 将表单渲染到固定的节点上
   * @param {*} dom 挂载的节点
   * @return {*}
   */
  render(dom) {
    let uuid = this.form_uuid ? this.form_uuid : this.object_uuid
    this.initFormDesign(uuid).then(res => {
      let h = this.instance.$createElement

      let form = h('div', [h(parser, {
        props: {
          'form-data': this.formDesignData,
          displayData: this.displayData
        }
      })])

      return form
    }).catch(res => {
      console.error(res, 'show form error')
      Message.error('请求表单配置数据失败！')
    })
  }

  /**
   * @description: 初始化表单配置数据 以及编辑时的回显数据
   * @param {String} uuid  选择的表单uuid
   * @return {*} Promise
   */
  initFormDesign(uuid = '') {
    return new Promise((resolve, reject) => {
      this.getLoading('请稍后')
      objects.transFieldsUUid(uuid || this.object_uuid).then((res) => {
        if (res.data.code == 200) {
          if (this.data_id) {
            // 获取已经有的数据进行绑定
            let config = res.data.data;
            let ruuid = [];
            for (let i = 0; i < config.fields.length; i++) {
              let item = config.fields[i];
              if (item.__config__.tagIcon == "form") {
                ruuid.push({ relationship_uuid: item.__vModel__ });
              }
            }
            objects.mapi
              .getDetail(this.object_uuid, this.data_id, "", ruuid)
              .then((res1) => {
                this.loading.close()
                let data = res1.data.data;
                this.formDesignData = res.data.data;
                this.displayData = data;
                resolve(true);
              }).catch(res => {
                this.loading.close()
              });
          } else {
            this.loading.close()
            this.formDesignData = res.data.data;
            resolve(true);
          }
        } else {
          reject();
        }
      });
    });
  }
}
