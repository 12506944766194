import { render, staticRenderFns } from "./viewerBIMModule.vue?vue&type=template&id=e9f4b726&scoped=true&"
import script from "./viewerBIMModule.vue?vue&type=script&lang=js&"
export * from "./viewerBIMModule.vue?vue&type=script&lang=js&"
import style0 from "./viewerBIMModule.vue?vue&type=style&index=0&id=e9f4b726&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e9f4b726",
  null
  
)

export default component.exports