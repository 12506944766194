/*
 * @Author: zqy
 * @Date: 2022-06-14 16:11:30
 * @LastEditTime: 2022-12-06 15:55:23
 * @Description: 新增组件
 * @LastEditors: zx
 */
const modelComponent = {
  type: 'modelComponent',
	title: 'bim组件',
	show: true,
  componentList: [
		{
			component: 'ModelPreview',
			type: 'modelComponent',
			name: '模型组件',
			icon: 'iconmoxing2',
			isLock: false, // 是否锁定
			needCommonStyle: false,
			propValue: '模型',
			style: {
				height: '100%',
				width: '100%',
				lineHeight: '',
				rotate: 0,
				marginTop: 0,
				marginBottom: 0,
				marginRight: 0,
				marginLeft: 0,
				paddingLeft: 0,
				paddingRight: 0,
				paddingTop: 0,
				paddingBottom: 0,
        zIndex: 1
			},
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
			}
		}
	]
};
export default modelComponent;