<!--
 * @Author: zqy
 * @Date: 2022-06-14 16:42:01
 * @LastEditTime: 2022-08-16 19:19:03
 * @Description: 新增按钮
 * @LastEditors: zx
-->
<template>
  <div v-if="addButtonVisible()" class="add-button" @click.stop="onAction('click', $event)">
    <i v-if="curComponent?.statusConfig?.icon" :style="{ color: curComponent?.style?.color, 'font-size': curComponent?.style?.fontSize + 'px' }" :class="'iconfont ' + curComponent.statusConfig.icon"></i>
    <img v-else src="https://static.bimcc.com/images/h5-img/tab_add.png" alt="" width="70" height="70">
  </div>
</template>
<script>
import { defineComponent, onMounted, computed } from 'vue'
import { mapState } from 'vuex'
import useStore from '@/store'
import eventBus from '@/plugins/eventBus';
import { getComponentById, getLinkByTemplate } from '@/utils/tools';
import { judgingArchiAPermission, judgingUILogic, judgingEventLogic } from '@/utils/tools';

export default defineComponent({
  props: {
    // 组件对象
    element: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  computed: {
    ...mapState([
      'curComponent',
      'componentData', // 组件数据
      'sContainer',
      'originComponentData',
    ]),
  },
  setup() {
    const store = useStore;
    const storeStateFns = mapState(["curComponent"]);
    const storeState = {};
    Object.keys(storeStateFns).forEach(fnKey => {
      const fn = storeStateFns[fnKey].bind({ $store: store });
      storeState[fnKey] = computed(fn);
    });
    onMounted(()=>{
      // console.log(props.element,'add-button-element');
    })
  },
  methods: {
    /**
		 * @desc: 操作
		 * @param {String} action 操作
		 * @param {Object} e 事件
		 */
		onAction(action, e) {
			e.preventDefault();
			e.stopPropagation();
			const event = this.element?.events[action] || null;
			if (event) {
        if (event.key === 'click') {
          // 跳转页面
          if (event.actionType === 'jumpPage') {
            if (event.linkType === 'projectPage') {
              const query = {};
              event.queryList.forEach((queryItem) => {
                let component = getComponentById(this.componentData, queryItem.componentId);
                if (!component && this.isGroup && this.groupComponents.length) {
                  component = getComponentById(this.groupComponents, queryItem.componentId);
                }
                this.$set(
                  query,
                  queryItem.key,
                  component.resolveData[queryItem.feild]
                );
              });
              // this.$router.push({
              //   path: ele.routePath,
              //   query
              // });
            } else if (event.linkType === 'outPage') {
              window.open(event.linkUrl);
            } else if (event.linkType === 'custom') {
              const customLink = getLinkByTemplate(event.linkTemplate, {})
              window.open(customLink);
            } else if (event.linkType === 'customForm') {
              if(event.customFormLink) {
                const { object_uuid, view_uuid, detailData, editData, title, type, createData, params, openCache, fillData  } = event.customFormLink
                if ( !object_uuid || !view_uuid || !title ) {
                  console.log(event)
                  return
                }
                let paramsObject = {}
                if (params && params.length) {
                  params.forEach(element => {
                    if (element.queryType === "component") {
                      const originComponent = getComponentById(this.componentData, element.component);
                      if (originComponent.resolveData) {
                        paramsObject[element.key] = originComponent.resolveData[element.value]
                      }
                    } else if (element.queryType === "system") {
                      let systemObject = {}
                      if (element.systemKey === 'targetArchi') {
                        systemObject = this.$GetTargetArchi()
                      } else if (element.systemKey === 'userInfo') {
                        systemObject = this.$GetUserInfo()
                      }
                      paramsObject[element.key] = systemObject[element.systemCode]
                    } else {
                      paramsObject[element.key] = element.value
                    }
                  });
                }
                let fillDataObject = {}
                if (fillData && fillData.length) {
                  fillData.forEach(element => {
                    if (element.queryType === "component") {
                      const originComponent = getComponentById(this.componentData, element.component);
                      if (originComponent.resolveData) {
                        fillDataObject[element.key] = originComponent.resolveData[element.value]
                      }
                    } else if (element.queryType === "system") {
                      let systemObject = {}
                      if (element.systemKey === 'targetArchi') {
                        systemObject = this.$GetTargetArchi()
                      } else if (element.systemKey === 'userInfo') {
                        systemObject = this.$GetUserInfo()
                      }
                      fillDataObject[element.key] = systemObject[element.systemCode]
                    } else {
                      fillDataObject[element.key] = element.value
                    }
                  });
                }
                console.log(event.customFormLink, paramsObject, 'event.customFormLink')
                console.log(fillDataObject, 'fillDataObject')
                eventBus.$emit('openPage', {
                  createData,
                  type,
                  object_uuid,
                  view_uuid,
                  detailData,
                  editData,
                  title,
                  openCache,
                  paramsObject,
                  fillDataObject
                });
              }
            }
          } else {
            event.effects.forEach((effect) => {
              let logicBoolean = true
              if (effect.logics.length) {
                logicBoolean = judgingEventLogic(effect.logics, this.componentData || []);
              }
              if (logicBoolean) {
                this.$store.commit('triggerEvents', {
                  config: {
                    ...event,
                  ...effect
                  },
                  element: this.element
                });
              }
            });
          }
        }
			}
		},
    /**
		 * @desc: 判断是否显示
		 * @param {} 
		 */
    addButtonVisible() {
      let component = getComponentById(this.componentData, this.element.id);
      return judgingUILogic(component.UILogic) && judgingArchiAPermission(this.element.archiLimit, [])
    }
  }
})
</script>
<style lang="less" scoped>
.add-button{
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
