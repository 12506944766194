/*
 * @Author: your name
 * @Date: 2021-11-23 16:41:57
 * @LastEditTime: 2022-11-03 21:20:49
 * @LastEditors: zx
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \nanhu-micro\src\views\schedule\gantt\ganttObj.js
 */
/*eslint-disable*/
import { getRequestId } from '@/api/mapi.js'

function shiftTask (task_id, direction) {
  let task = gantt.getTask(task_id)
  task.start_date = gantt.date.add(task.start_date, direction, 'day')
  task.end_date = gantt.calculateEndDate(task.start_date, task.duration)
  gantt.updateTask(task.id)
}

export const actions = {
  undo: function () {
    gantt.ext.undo.undo()
  },
  redo: function () {
    gantt.ext.undo.undo()
  },
  indent: function indent (task_id) {
    let prev_id = gantt.getPrevSibling(task_id)
    while (gantt.isSelectedTask(prev_id)) {
      let prev = gantt.getPrevSibling(prev_id)
      if (!prev) break
      prev_id = prev
    }
    if (prev_id) {
      let new_parent = gantt.getTask(prev_id)
      gantt.moveTask(task_id, gantt.getChildren(new_parent.id).length, new_parent.id)
      new_parent.type = gantt.config.types.project
      new_parent.$open = true
      gantt.updateTask(task_id)
      gantt.updateTask(new_parent.id)
      let indentTask = gantt.getTask(task_id)
      getRequestId('action/gantttask/', { parent: indentTask.parent }, indentTask.task_id)
      // console.log("降级任务",indentTask);

      return task_id
    }
    return null
  },
  outdent: function outdent (task_id, initialIndexes, initialSiblings) {
    let cur_task = gantt.getTask(task_id)
    let old_parent = cur_task.parent
    if (gantt.isTaskExists(old_parent) && old_parent != gantt.config.root_id) {
      let index = gantt.getTaskIndex(old_parent) + 1
      let prevSibling = initialSiblings[task_id].first

      if (gantt.isSelectedTask(prevSibling)) {
        index += initialIndexes[task_id] - initialIndexes[prevSibling]
      }
      gantt.moveTask(task_id, index, gantt.getParent(cur_task.parent))
      if (!gantt.hasChild(old_parent)) {
        gantt.getTask(old_parent).type = gantt.config.types.task
      }
      gantt.updateTask(task_id)
      gantt.updateTask(old_parent)

      let outdentTask = gantt.getTask(task_id)
      getRequestId('action/gantttask/', { parent: outdentTask.parent }, outdentTask.task_id)
      // console.log("升级任务",outdentTask);

      return task_id
    }
    return null
  },
  milestone: function (task_id) {
    let cur_task = gantt.getTask(task_id)
    cur_task.type = gantt.config.types.milestone
    gantt.updateTask(task_id)
    getRequestId('action/gantttask/', { type: gantt.config.types.milestone }, cur_task.task_id)
    return task_id
  },
  task: function (task_id) {
    let cur_task = gantt.getTask(task_id)
    cur_task.type = gantt.config.types.task
    gantt.updateTask(task_id)
    getRequestId('action/gantttask/', { type: gantt.config.types.task }, cur_task.task_id)
    return task_id
  },
  del: function (task_id) {
    if (gantt.isTaskExists(task_id)) gantt.deleteTask(task_id)
    return task_id
  },
  moveForward: function (task_id) {
    shiftTask(task_id, 1)
  },
  moveBackward: function (task_id) {
    shiftTask(task_id, -1)
  }
}
export const cascadeAction = {
  indent: true,
  outdent: true,
  del: true,
  milestone: true
}
export const singularAction = {
  undo: true,
  redo: true
}
