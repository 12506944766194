<!--
 * @Date: 2022-08-22 11:28:17
 * @LastEditors: AsaHi
 * @LastEditTime: 2022-12-30 11:14:49
 * @FilePath: \dataview-next\src\custom-component\viewerBIM\ViewerBIM.vue
-->
<template>
    <article class="bimViewer-cont">
        <div ref="viewerMarkDom" class="mask" v-show="setting.showMask"></div>
        <bim-toolbar ref="toolbar"></bim-toolbar>
        <!-- <bim-modellist
                ref="modellist"
                @loadModel="loadModel"
                @unloadModel="unloadModel"
                @fitToView="fitToModel"
        ></bim-modellist> -->
        <div ref="viewerDom" class="bimviewer_main"></div>
    </article>
</template>

<script>
    import ComponentFactory from "./ComponentFactory";
    const cf = new ComponentFactory();

    export default cf.generate();
</script>

<style scoped>
    .bimviewer_main{
        height: 100%;
        width: 100%;
        background-color: #000000;
    }
    .mask{
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: 999;
    }
</style>
