import eventBus from '@/plugins/eventBus';

export default class MouseCustomEvents{
    constructor(){

    }

    static getEvents(viewer, comp){
        viewer.on(viewer.EventType.cameraChange, () => {
            eventBus.$emit("ViewerGIS.onCameraChange");
        });

        return {
            'ViewerGIS.registerCameraChange': (eid)=>{
                if(eid !== comp.element.id) return;

                viewer.MouseCustomEvent.registerCameraChange();
            },
            'ViewerGIS.logoutCameraChange': (eid)=>{
                if(eid !== comp.element.id) return;

                viewer.MouseCustomEvent.logoutCameraChange();
            },
            'ViewerGIS.drawPointByMouseCustom': (eid, cb)=>{
                if(eid !== comp.element.id) return;

                viewer.on(viewer.EventType.drawPointByMouseCustom, (xyz) => {
                    cb && cb(xyz);
                });
                viewer.MouseCustomEvent.drawPoint();
            },
            'ViewerGIS.drawCircleByMouseCustom': (eid, cb)=>{
                if(eid !== comp.element.id) return;

                viewer.on(viewer.EventType.drawCircleByMouseCustom, (res) => {
                    cb && cb(res);
                });
                viewer.MouseCustomEvent.drawCircle();
            },
            'ViewerGIS.removeMouseCustomEvent': (eid)=>{
                if(eid !== comp.element.id) return;

                viewer.off(viewer.EventType.drawPointByMouseCustom);
                viewer.off(viewer.EventType.drawCircleByMouseCustom);
                viewer.MouseCustomEvent.removeDrawing();
            },
        }
    }
}
