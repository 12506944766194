<!--
    @name: TaskNode
    @description：TaskNode
    @author: ZengWei
    @date: 2022-05-13 16:35
-->
<template>
  <div class="task-node-block" :style="{'border-color': color}">
    <i class="iconfont iconlvyuepingjia" @click="showFormParser"></i>
    <p class="task-title" :style="{'background-color': color}">
      <i class="iconfont iconyuebao"></i> {{title}}
    </p>
    <div class="task-content">
      <template v-for="(item,index) in content">
        <div v-if="index<5" :key="index" class="text-item">
          <div class="left">
            {{item.name}}
          </div>
          <div class="center-line">
            <p class="line"></p>
          </div>
          <div class="right">
            <div v-if="item.type !== 'upload'">
              {{item.value}}
            </div>
            <div v-else>
              <el-link type="primary" @click="showAttach(item.attach)">
                <i class="el-icon-link"></i> 查看附件
              </el-link>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {Link} from "element-ui";

export default {
  name: "TaskNode",
  components: {
    'el-link': Link
  },
  inject: ["getGraph", "getNode"],
  data() {
    return {
      title: '任务标题',
      color: '#7E8595',
      content: [
        // {type:'input',name:'文本',value:'',show:true},
        // {type:'data',name:'日期',value:'',show:true},
        // {type:'data-range',name:'日期范围',value:[],show:true},
        // {type:'upload',name:'附件',value:[],show:true},
      ]
    }
  },
  mounted() {
    const self = this
    const node = this.getNode()
    const data = node.getData()
    if(data){
      self.title = data.name
      self.content = data?.content || []
      self.color = data?.color || '#7E8595'
    }
    // 监听数据改变事件
    node.on("change:data", ({ current }) => {
      self.title = current.name
      self.content = current.content
      self.color = current?.color || '#7E8595'
    });
  },
  methods: {
    showFormParser(){
      const node = this.getNode()
      const data = node.getData()
      window.postMessage({
        event:'open-task-form',
        task:data
      },'*')
    },
    showAttach(attach){
      window.postMessage({
        event:'open-attach',
        attach: attach
      },'*')
    }
  },
}
</script>

<style lang="less" scoped>
.task-node-block{
  width: 100%;
  height: 100%;
  border: 2px solid #F2654F;
  border-radius: 8px;
  box-shadow: 0 4px 10px #0c1f5014;

  /*&:hover{
    box-shadow: 0 0 5px #F2654F,
    0 0 25px #F2654F,
    0 0 50px #F2654F,
    0 0 100px #F2654F;
  }*/

  .iconlvyuepingjia{
    position: absolute;
    top: 10px;
    right: 8px;
    font-size: 17px;
    color: #ffffff;
    cursor: pointer;
  }

  .task-title{
    padding: 8px 25px 8px 10px;
    background-color: #F2654F;
    color: #ffffff;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .task-content{
    margin-bottom: 2px;
    height: calc(100% - 43px);
    overflow: hidden;
    .text-item{
      display: flex;
      justify-content: space-between;
      line-height: 31px;
      cursor: pointer;

      &:hover{
        background-color: #C0C4CC;
      }

      .center-line{
        flex: 1;
        color: #C0C4CC;
        padding: 0 10px;
        .line {
          border-bottom: 1px dashed #C0C4CC;
          margin-top: 16px;
        }
      }

      .left{
        padding-left: 10px;
        width: 75px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .right{
        width: 70px;
        text-align: right;
        padding-right: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>
