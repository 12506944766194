<!--
 * @Author: zx
 * @Date: 2022-09-26 16:32:01
 * @LastEditTime: 2022-12-30 14:48:23
 * @Description:
 * @LastEditors: zx
-->
<template>
  <div class="select-user">
    <Cascader
      :userData="userData"
      :disabled="disabled"
      :showList="showList"
      :loading="loading"
      :show-choose-button="showChooseButton"
      @on-select-change="onSelectChange"
    />
  </div>
</template>
<script>
  import Cascader from '@/manage-views/components/selectUser/components/Cascader.vue'
  import { getDepartmentUser } from '@/api/saasManage.js'
  export default {
    components:{
      Cascader
    },
    props:{

      /* 只请求架构本身的人员  不包括下级单位 */
      isItself: {
        type: Boolean,
        default: false
      },
      /* 禁用选择 */
      disabled: {
        type: Boolean,
        default: false
      },
      /* 展示按钮的选择人员 */
      showChooseButton: {
        type: Boolean,
        default: false
      },
      // false 级联单选
      // true 级联多选
      isCheckType: {
        type: Boolean,
        default: true
      },
      /* 回显数据 */
      showList: {
        type: Array,
        default: () => []
      },
      /* 流程选人使用 */
      pickerType: {
        type: String,
        default: 'target'
      },
      pickerNode: {
        type: String,
        default: ''
      },
      /* 过滤部门id数组 */
      filterDepart: {
        type: Array,
        default: () => []
      },
      /* 过滤人员id数组 */
      filterUsers: {
        type: Array,
        default: () => []
      },
    },
    data() {
      return {
        userData: [],
        loading: true
      }
    },
    mounted() {
      this.getDataList()
    },
    methods:{
      onSelectChange(arr){
        const pickerNode = this.pickerNode
        const pickerType = this.pickerType
        const params = { pickerNode, pickerType }
        this.$emit('on-select-change', arr, params)
      },
      /* 处理数据结构，增加人员数据携带父级信息 */
      editData(list) {
        const loopTree = (arr) => {
          arr.forEach(element => {
            if (element.user_list && element.user_list.length) {
              element.user_list.forEach(user => {
                user.parentInfo = element
              });
              element.children = element.children.concat(...element.user_list)
            }
            if (Array.isArray(element.children) && element.children && element.children.length) {
              loopTree(element.children)
            }
          });
        }
        if (Array.isArray(list) && list.length) {
          loopTree(list)
        }
      },
      /* 根据过滤部门id数组过滤部门数据 */
      filterDepartList(list) {
        const array = []
        const filterDepart = this.filterDepart.map((ite) => {
          return +ite
        })
        const loopTree = (arr) => {
          arr.forEach(element => {
            if (+element.type === 7 && filterDepart.indexOf(+element.id) !== -1) {
              array.push(element)
            }
            if (Array.isArray(element.children) && element.children && element.children.length) {
              loopTree(element.children)
            }
          });
        }
        if (Array.isArray(list) && list.length) {
          loopTree(list)
        }
        return array
      },
      /* 根据过滤人员id数组过滤人员数据 */
      filterUsersList(list) {
        const array = []
        const filterUsers = this.filterUsers.map((ite) => {
          return +ite
        })
        const loopTree = (arr) => {
          arr.forEach(element => {
            if (element.parentInfo?.id && filterUsers.indexOf(+element.id) !== -1) {
              array.push(element)
            }
            if (Array.isArray(element.children) && element.children && element.children.length) {
              loopTree(element.children)
            }
          });
        }
        if (Array.isArray(list) && list.length) {
          loopTree(list)
        }
        return array
      },
      /* 获取架构人员数据 */
      getDataList() {
        this.loading = true
        getDepartmentUser({}).then((res) => {
          if (res.status && +res.status === 200 && res.data && +res.data.code === 200) {
            let treeData = res.data.data
            this.editData(treeData)
            if (this.filterDepart?.length) {
              this.userData = this.filterDepartList(treeData)
            } else if (this.filterUsers?.length) {
              this.userData = this.filterUsersList(treeData)
            } else {
              this.userData = treeData
            }
            this.loading = false
          }
        })
      }
    }
  }
</script>
<style lang='less' scoped>
</style>
