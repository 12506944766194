<!--
    @name: input_item
    @description：input_item
    @author: ZengWei
    @date: 2021-09-15 11:49
-->
<!-- eslint-disable -->
<template>
  <div>
    <template v-if="element.type == 'input'">
      <el-input
        v-if="!element.config.disabled"
        v-model="element.value"
        :disabled="disabled || element.config.__config__.openRule || element.config.disabled"
        :placeholder="element.config.placeholder"
        @change="formatPrecision"
      >
        <template slot="prepend" v-if="element.config.__slot__.prepend">{{element.config.__slot__.prepend}}</template>
        <template slot="append" v-if="element.config.__slot__.append">{{ element.config.__slot__.append }}</template>
      </el-input>
      <span v-else>{{ element.value }}</span>
    </template>

    <el-input
      v-else-if="element.type == 'textarea'"
      v-model="element.value"
      :rows="3"
      type="textarea"
      :placeholder="element.config.placeholder"
      :disabled="disabled || element.config.disabled"
    >
    </el-input>

    <el-input
      v-else-if="element.type == 'password'"
      v-model="element.value"
      type="password"
      :placeholder="element.config.placeholder"
      :disabled="disabled || element.config.disabled"
    >
    </el-input>

    <el-input-number
      v-else-if="element.type == 'number'"
      v-model="element.value"
      :disabled="disabled || element.config.disabled"
    >
    </el-input-number>

    <!-- <vue-tinymce v-else-if="element.type == 'rich-text'"
					 v-model="element.value" :setting="setting" ref="editorModify"/> -->

    <!-- 暂时使用 textarea代替 -->
    <el-input
      v-else-if="element.type == 'rich-text'"
      v-model="element.value"
      :rows="3"
      type="textarea"
      :placeholder="element.config.placeholder"
      :disabled="disabled || element.config.disabled"
    />

    <div v-else-if="element.type == 'select'" style="position: relative">
      <div :class="{'outer-viewer':element.config.__config__.outerViewer}">
        <el-select
          v-model="element.value"
          :disabled="disabled || element.config.disabled"
          :placeholder="element.config.placeholder"
          :clearable="element.config.clearable"
          :multiple="element.config.multiple"
          :filterable="element.config.filterable"
          @change="triggerInteractive"
        >
          <el-option
            v-for="(val, key) in element.config.__slot__.options"
            :value="'' + val.value"
            :label="val.label"
            :key="key"
          >{{ val.label }}
          </el-option>
        </el-select>
      </div>
      <el-button
        type="primary"
        v-if="element.config.__config__.outerViewer"
        style="position: absolute;top:0;right: 0;"
        @click="openOuterViewer"
      >查看</el-button>
    </div>


    <el-cascader
      v-else-if="element.type == 'cascader'"
      v-model="element.value"
      :disabled="disabled || element.config.disabled"
      :options="element.config.options"
      :placeholder="element.config.placeholder"
      :clearable="element.config.clearable"
      :filterable="element.config.filterable"
    >
    </el-cascader>

    <el-radio-group
      v-else-if="element.type == 'radio'"
      v-model="element.value"
      :disabled="disabled || element.config.disabled"
      @change="triggerInteractive"
    >
      <template v-if="element.config.__config__.optionType === 'default'">
        <el-radio
          v-for="(val, key) in element.config.__slot__.options"
          :label="'' + val.value"
          :key="key"
        >{{ val.label }}
        </el-radio>
      </template>
      <template v-else>
        <el-radio-button
          v-for="(val, key) in element.config.__slot__.options"
          :label="'' + val.value"
          :key="key"
        >{{ val.label }}
        </el-radio-button>
      </template>

    </el-radio-group>

    <el-checkbox-group
      v-else-if="element.type == 'checkbox'"
      v-model="element.value"
      :disabled="disabled || element.config.disabled"
    >
      <el-checkbox
        v-for="(val, key) in element.config.__slot__.options"
        :label="'' + val.value"
        :key="key"
      >{{ val.label }}
      </el-checkbox>
    </el-checkbox-group>

    <el-switch
      v-else-if="element.type == 'switch'"
      v-model="element.value"
      :disabled="disabled || element.config.disabled"
      @change="triggerInteractive"
    >
    </el-switch>

    <el-slider
      v-else-if="element.type == 'slider'"
      v-model="element.value"
      :disabled="disabled || element.config.disabled"
      :min="element.config.min"
      :max="element.config.max"
      :step="element.config.step"
    >
    </el-slider>

    <el-time-select
      v-else-if="element.type === 'time'"
      v-model="element.value"
      :disabled="disabled || element.config.disabled"
      :type="element.config.type"
      :format="element.config.format"
      :value-format="element.config['value-format']"
      :placeholder="element.config.placeholder"
      style="width: 100%"
    >
    </el-time-select>

    <el-time-picker
      v-else-if="element.type === 'time-range'"
      v-model="element.value"
      is-range
      :disabled="disabled || element.config.disabled"
      :range-separator="element.config['range-separator']"
      :start-placeholder="element.config['start-placeholder']"
      :end-placeholder="element.config['end-placeholder']"
      :value-format="element.config['value-format']"
      :placeholder="element.config.placeholder"
      style="width: 100%"
    >
    </el-time-picker>

    <el-date-picker
      v-else-if="['date', 'date-range'].indexOf(element.type) > -1"
      v-model="element.value"
      :disabled="disabled || element.config.disabled"
      :type="element.config.type"
      :range-separator="element.config['range-separator']"
      :format="element.config.format"
      :placeholder="element.config.placeholder"
      :value-format="element.config['value-format']"
      style="width: 100%"
    >
    </el-date-picker>

    <el-rate
      v-else-if="element.type == 'rate'"
      v-model="element.value"
      :show-text="element.config['show-text']"
      :allow-half="element.config['allow-half']"
      :count="element.config.max"
      :disabled="disabled || element.config.disabled"
    >
    </el-rate>

    <el-color-picker
      v-else-if="element.type == 'color'"
      v-model="element.value"
      recommend
    >
    </el-color-picker>

    <uploader
      v-if="element.type == 'upload'"
      :disabled="disabled || element.config.disabled"
      :files="element.value"
      @extra-files="onSuccess"
    >
    </uploader>

    <div v-else-if="element.type === 'user'">
      <select-user
        v-if="element.config.__config__.userData"
        :data-info="element.config.__config__.userData"
        :show-list="element.value"
        @on-select-change="onSelectChange"
      />
    </div>

    <div v-else-if="element.type === 'signature'">
      <div>
        <el-popover placement="right" width="100">
          <Button style="margin: 10px" @click="generateQrcode">
            <i class="iconfont iconfenbaoshenhe"></i>&nbsp;&nbsp;&nbsp;签名签章
          </Button>
          <div slot="content">
            <img v-if="qrCode" :src="qrCode" alt="" width="100" height="100"/>
          </div>
        </el-popover>
      </div>
      <img
        v-for="item in element.value"
        :src="item"
        width="130"
        height="80"
        style="margin: 10px"
      />
    </div>

    <div v-else-if="element.type === 'database'">
      <el-table ref="multipleTable" :data="relateData" border>
        <el-table-column align="center" width="65">
          <template slot="header" slot-scope="scope">
            <el-button
              v-if="!disabled"
              type="primary" size="mini" icon="el-icon-notebook-2"
              @click="openSelectRelate">
            </el-button>
          </template>
          <template slot-scope="scope">{{ scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column
          v-for="(item,index) in element.config.__slot__.options"
          :key="index"
          :label="item.label"
          :prop="item.value"
        ></el-table-column>
        <el-table-column
          v-if="!disabled"
          label="操作"
          align="center"
          :width="element.config.__config__.detailShow? 130 : 60"
        >
          <template slot-scope="scope">
            <div style="display: flex;justify-content: space-around">
              <div v-if="element.config.__config__.detailShow">
                <el-button type="text" size="mini" icon="el-icon-view">详情</el-button>
              </div>
              <div><i class="el-icon-remove-outline remove" @click="relateData.splice(scope.$index,1)"></i></div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div v-else-if="element.type === 'outerForm'">
      <el-button
        type="primary" icon="el-icon-search"
        @click="openOuterForm(element)">
        打开{{ element.config.__config__.label }}
      </el-button>
    </div>

    <div v-else-if="element.type === 'qrCode'">
      <img v-if="element.value" :src="element.value" style="height: 100px;width: 100px" alt="">
      <span v-else style="color: #0076ff"> 暂未生成二维码</span>
    </div>

    <div v-else-if="element.type === 'bimList'">
      <choose-model
        ref="chooseModel"
        @set-model-data="setModelData"
        :modelData="element.value && element.value != null? element.value: []">
        <template v-slot:showType>
          <el-button
            style="width: 100%"
            @click="openModelDrawer"
            size="medium"
            type="primary"
            v-html="
          element.value && element.value != null && element.value.length != 0
          ? '已选择': '请选择'"
          ></el-button>
        </template>
      </choose-model>
    </div>

    <span
      v-else-if="['bimConsole', 'gisConsole'].indexOf(element.type) > -1"
      v-model="element.value"
      style="float: right; margin: 12px 10px 0"
    >
      {{ element.value == "" ? "未选择" : "已选择" }}
      <i class="iconfont iconxuanzeqiehuan-jiantou"></i>
    </span>

    <template v-else-if="element.type === 'money'">
      <el-input
        type="number"
        :style="element.config.style"
        v-if="!element.config.disabled"
        v-model="element.value"
        :disabled="disabled || element.config.__config__.openRule || element.config.disabled"
        :placeholder="element.config.placeholder"
        @change="formatPrecision"
      >
        <template slot="prepend" v-if="element.config.__slot__.prepend">{{element.config.__slot__.prepend}}</template>
        <template slot="append" v-if="element.config.__slot__.append">{{ element.config.__slot__.append }}</template>
      </el-input>
      <span style="margin-left: 8px">
        <b style="color: red;">RMB：</b>
        <el-tag size="small">{{moneyToCny(element.value)}}</el-tag>
      </span>
    </template>

    <div v-else-if="element.type === 'treeSelect'">
      <span style="display: none">{{ treeSelectLabel }}</span>
      <el-select
        ref="select"
        v-model="treeSelectValue"
        placeholder="请选择"
        :multiple="element.config.props.props.multiple"
        collapse-tags
        :disabled="disabled"
        @visible-change="visibleChange"
      >
        <el-option :value="element.value" class="tree-select-option is-current">
          <el-tree
            ref="treeSelect"
            :data="element.config.options"
            :default-expand-all="true"
            :show-checkbox="element.config.props.props.multiple"
            node-key="id"
            highlight-current
            :expand-on-click-node="false"
            @node-click="treeSelectClick"
            @check-change="handleCheckChange"
          ></el-tree>
        </el-option>
      </el-select>
    </div>

    <el-dialog
      title="选择关联数据"
      :visible.sync="relateVisible"
      width="80%"
      append-to-body
    >
      <div style="height: 70vh" v-if="relateObjectUUid && relateViewUUid">
        <relateData
          :obuuid="relateObjectUUid"
          :view_uuid="relateViewUUid"
          @select-relate="selectRelateResult"
        >
        </relateData>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="relateVisible = false">取 消</el-button>
        <el-button type="primary" @click="relateVisible = false">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="外部内容查看"
      :visible.sync="outerViewerVisible"
      :width="outerViewerWidth"
      append-to-body
    >
      <div v-loading="outerViewerLoading" style="height: calc(100vh - 200px)">
        <iframe
          ref="iframe"
          :src="outerRouter"
          style="width: 100%; height: 100%"
          frameborder="0"></iframe>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable */

import axios from "axios";
import {getToken, baseUrl} from "@/libs/util";
import {formRequest} from "@/api/home";
import relateData from "./relateData";
import selectUser from "./selectUser/index";
import uploader from "@/components/fileUpload/fileUpload/Index";
import ChooseModel from "@/components/bindModel/ButtonType.vue";
import {numToCny} from "./scripts/tools";

export default {
  name: "input_item",
  components: {
    relateData, selectUser, uploader, ChooseModel
  },
  props: {
    nodeElement: {
      type: Object,
    },
    disabled: {
      type: Boolean,
    },
    firstIndex: {
      type: Number,
    },
    secondIndex: {
      type: Number,
    },
    threeIndex: {
      type: [Number, String],
    },
  },
  data() {
    return {
      qrCode: "",
      qrToken: "",
      timer: null,
      signImg: null,
      token: getToken(),
      baseUrl: baseUrl(),
      element: this.nodeElement,
      setting: {
        // menubar: false,
        toolbar:
          "code searchreplace bold italic underline strikethrough alignleft aligncenter alignright outdent indent blockquote removeformat subscript superscript codesample hr bullist numlist link image charmap preview anchor pagebreak insertdatetime media table forecolor backcolor fullscreen",
        toolbar_drawer: "sliding",
        plugins:
          "advlist anchor autolink autosave code codesample directionality fullscreen hr image imagetools insertdatetime link lists media nonbreaking noneditable pagebreak paste preview print save searchreplace spellchecker tabfocus table template textpattern visualblocks visualchars wordcount",
        language: "zh_CN", //本地化设置
        height: 300,
      },
      fileList: [],
      relateVisible: false,
      relateObjectUUid: null,
      relateViewUUid: null,
      relateData: [],
      outerFormType: 2,
      outerFormVisible: false,
      outerFormTitle: '外部表单',
      treeSelectValue: '',

      outerViewerVisible: false,
      outerViewerLoading: false,
      outerViewerWidth: '100%',
      outerViewerParam: {},
      outerRouter: '',
    };
  },
  created() {
    this.element = this.nodeElement;
    if (this.element.type == 'upload') {
      this.fileList = this.element.value
    }
  },
  mounted() {
    if(['radio','switch','select'].indexOf(this.element.type) >-1){
      this.triggerInteractive(this.element.value)
    }
  },
  watch: {
    element: {
      handler(newV) {
        this.$emit('change-value', this.firstIndex, this.secondIndex, this.threeIndex, newV.value)
      },
      deep: true
    }
  },
  computed: {
    treeSelectLabel() {
      const value = this.element.value;
      const treeData = this.element.config.options;
      let labelData;
      value instanceof Array ? labelData = [] : labelData = '';

      function getTree(data) {
        for (let item of data) {
          if (value instanceof Array && value.includes(item.id)) {
            labelData.push(item.label)
          } else if (value == item.id) {
            labelData = item.label
          }
          if (item.children && item.children.length > 0) {
            getTree(item.children)
          }
        }
      }

      getTree(treeData);
      this.treeSelectValue = labelData
      return labelData;
    },
    moneyToCny(){
      return function(money){
        let unit = this.element.config.__slot__.append
        if(unit === '万' || unit === '万元'){
          money = parseFloat(money)*10000
        }
        return numToCny(money)
      }
    },
  },
  methods: {
    openOuterViewer(){
      this.outerViewerVisible = true
      let route = this.element.config.__config__.outerRouter
      const width = this.element.config.__config__.windowWidth
      if(width) this.outerViewerWidth = width
      const obj = this.outerViewerParam
      const keys = Object.keys(obj)
      const regex = /\{(.+?)\}/g
      const arr = route.match(regex)
      if(arr !== null){
        for (let item of arr){
          let param = item.replace('{','').replace('}','')
          if(keys.includes(param)){
            route = route.replace(item,obj[param])
          }
        }
      }
      this.outerRouter = route
      this.outerViewerLoading = true
      this.$nextTick(()=>{
        this.$refs.iframe.onload = () => {
          this.outerViewerLoading = false
        }
      })
    },
    formatPrecision(val){
      if(this.element.config.precision){
        let precision = this.element.config.precision;
        let value = Number(val).toFixed(precision);
        this.element.value = value;
      }
    },
    visibleChange(e) {
      if(e) {
        let selectDom = document.querySelector('.is-current')
        setTimeout(() => {
          this.$refs.select.scrollToOption({$el: selectDom})
        },0)
      }
    },
    openModelDrawer() {
      this.$nextTick(()=>{
        this.$refs.chooseModel.show = true;
      })
    },
    setModelData(data){
      console.log(data,'模型数据')
      this.element.value = data;
    },
    onSelectChange(userData) {
      let users = []
      for (let item of userData) {
        let user = {
          id: item.user_id,
          name: item.name,
          user_table: item.user_table,
          user_id: item.user_id,
          section_id: item.section_id,
        }
        users.push(user)
      }
      this.element.value = users;
    },
    handleCheckChange() {
      let res = this.$refs.treeSelect.getCheckedNodes(true, true);
      let arr = [];
      res.forEach(item => {
        arr.push(item.id)
      });
      this.element.value = arr;
    },
    treeSelectClick(data) {
      if (!this.element.config.props.props.multiple) {
        // this.treeSelectValue = data.label
        this.element.value = data.id.toString()
      }
    },
    openOuterForm(element) {
      let index={first:this.firstIndex,second:this.secondIndex,three:this.threeIndex}
      this.$emit('open-form', element, index)
    },
    openSelectRelate() {
      this.relateObjectUUid = this.element.config.__config__.objectUuid
      this.relateViewUUid = this.element.config.__config__.viewUuid
      this.relateVisible = true
    },
    selectRelateResult(idKey, rows) {
      this.relateData = rows;
      const relateId = Array.from(this.relateData, item => item[idKey]);
      this.element.value = relateId;
    },
    //触发交互事件
    async triggerInteractive(val) {
      const interact = this.element.config.__config__.interact;
      const effect = interact.effect;
      const condition = interact.condition;
      if (effect === 'showhide') {
        let trueData = condition.filter(item => {
          if(typeof item.condV === 'string' && item.condV.indexOf(',')){
            let arr = item.condV.split(',');
            return arr.includes(val) || arr.includes(val.toString())
          }
          return item.condV == val
        })
        let compList = Array.from(condition, item => item.comp).flat();
        let compShowHide = {};
        for (let item of compList){
          compShowHide[item] = false;
        }
        if (trueData.length) {
          for (let eleItem of trueData){
            let compShow = eleItem.comp;
            for (let item of compShow){
              compShowHide[item] = true;
            }
          }
        }
        this.$emit('trigger-active', compShowHide);
      }
      if (effect === 'cascader') {
        let url, params, options = [];
        url = '/api/mapi';
        params = {
          __method_name__: 'dataList',
          object_uuid: interact.objectUuid,
          view_uuid: interact.viewUuid,
        };
        params[interact.param] = val;
        let response = await formRequest('post', url, params);
        let userData = response.data.data;
        let label = interact.label;
        let value = interact.value;
        if (userData instanceof Array) {
          for (let element of userData) {
            if (element[label] && element[value]) {
              let item = {label: element[label], value: element[value]};
              options.push(item);
            }
          }
        }
        let comp = Array.from(condition, item => item.comp).flat()
        this.$emit('trigger-active', comp, 'cascader', options)
      }
      const tag = this.element.config.__config__.tag;
      if (tag === 'el-select') {
        this.$emit('auto-fill-relate');
        const dataType = this.element.config.__config__.dataType;
        const multi = this.element.config.multiple;
        const options = this.element.config.__slot__.options;
        const index = options.findIndex(item => item.value == val);
        const selectData = options[index]
        if (!selectData) return;
        const keys = Object.keys(selectData)
        if(keys.includes('allField')){
          this.outerViewerParam = selectData.allField
        }
        if (keys.includes('allField') && multi === false && dataType === 'dynamic') {
          this.$emit('trigger-active', [], 'selectFill', selectData.allField)
        }
      }
    },
    onSuccess(files) {
      this.element.value = files;
    },
    cadListData(value, element) {
      element.value = value;
    },
    dealWithData(val, index, nn, ii) {
      this.$emit("on-callback", val, index, nn, ii);
    },
    generateQrcode() {
      clearInterval(this.timer);
      axios
        .request({
          url: this.baseUrl + "/api/generate/signature",
          data: {token: this.token},
          method: "post",
        })
        .then((res) => {
          if (res.data.code === 200) {
            this.qrCode = res.data.data.qrcode;
            this.qrToken = res.data.data.token;
            let that = this;
            this.timer = setInterval(() => {
              that.getAppSignature();
            }, 1000);
          }
        });
    },
    getAppSignature() {
      axios
        .request({
          url: this.baseUrl + "/api/generate/signature",
          params: {token: this.token, qrToken: this.qrToken},
          method: "get",
        })
        .then((res) => {
          if (res.data.code === 200) {
            this.signImg = res.data.data.signature;
            if (this.signImg !== null) {
              this.element.value.push(this.signImg);
              clearInterval(this.timer);
            }
          }
        });
    },
  },
  destroyed() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="less" scoped>
.outer-viewer{
  padding-right: 80px;
}

.tree-select-option {
  height: auto;
  background: #ffffff !important;
  :deep(.el-tree) {
    padding-bottom: 15px;
    .el-tree-node__label {
      font-weight: normal;
    }
  }
}

.remove {
  color: #e83525;
  cursor: pointer;
  font-size: 23px;
}
</style>
