var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"application-tree",style:('width:' + _vm.width + 'px')},[_c('div',{staticClass:"application-tree-container"},[_c('LeftItem',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"delAble":"","topTitle":_vm.childTitle,"showBack":true,"tableData":_vm.appInfo.children,"defaultProps":{
        label: 'name',
        children: 'children'
      },"field":"name"},on:{"row-del":_vm.rowDel,"row-click":_vm.childRowClick,"top-add":_vm.childAdd,"row-edit":_vm.childRowEdit,"row-add":_vm.childRowAdd,"back":_vm.back}})],1),_c('el-drawer',{attrs:{"title":_vm.applicationTitle,"visible":_vm.showAddDrawer,"append-to-body":true,"direction":'rtl',"size":"70%"},on:{"update:visible":function($event){_vm.showAddDrawer=$event}}},[_c('AddContent',{attrs:{"childInfo":_vm.childInfo},on:{"closeAddDrawer":_vm.closeAddDrawer}})],1),_c('el-dialog',{attrs:{"title":_vm.editTitle,"fullscreen":_vm.dialogFull,"visible":_vm.dialogVisible,"width":"600px","append-to-body":"","custom-class":"create-app-dialog"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[(_vm.dialogVisible)?_c('div',{staticClass:"common-body"},[(_vm.dialogVisible)?_c('el-form',{ref:"form",attrs:{"model":_vm.appFormData,"rules":_vm.rules,"label-position":"top","label-width":"80px"}},[_c('div',{staticClass:"wrapper"},[_c('el-form-item',{staticClass:"form-item-height",attrs:{"label":"应用图标","prop":"icon"}},[_c('el-select',{staticClass:"select-icon",staticStyle:{"width":"100%"},attrs:{"value":_vm.other,"placeholder":""},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"choose-color"},[_c('p',[_vm._v("选择图形颜色")]),_c('div',{staticClass:"color-container"},_vm._l((_vm.colorList),function(item,index){return _c('div',{key:index,staticClass:"color-item",style:('background:' + item.color),on:{"click":function($event){(_vm.appFormData.color = item.color),
                          (_vm.appFormData.background = item.background)}}},[(_vm.appFormData.color === item.color)?_c('i',{staticClass:"iconfont iconselect-duigou"}):_vm._e()])}),0),_c('p',[_vm._v("选择图形")]),_c('div',{staticClass:"color-container",staticStyle:{"flex":"1","overflow-y":"auto"}},_vm._l((_vm.iconList),function(icon,index){return _c('div',{key:index,staticClass:"color-item",style:(_vm.appFormData.icon === icon
                          ? 'background:' +
                            _vm.appFormData.background +
                            ';color:' +
                            _vm.appFormData.color
                          : ''),on:{"click":function($event){_vm.appFormData.icon = icon}}},[_c('i',{staticClass:"color-item-i",class:icon,style:(_vm.appFormData.icon === icon
                            ? 'color:' + _vm.appFormData.color
                            : '')})])}),0)])]},proxy:true}],null,false,3381102087)},[_c('template',{slot:"prefix"},[_c('div',{staticClass:"header-img",style:('background: ' + _vm.appFormData.background)},[_c('i',{class:_vm.appFormData.icon
                        ? _vm.appFormData.icon
                        : 'el-icon-platform-eleme',style:('color:' + _vm.appFormData.color)})])])],2)],1),_c('el-form-item',{staticClass:"form-item-height",staticStyle:{"margin-bottom":"14px"},attrs:{"label":"名称","prop":"name"}},[_c('el-input',{attrs:{"clearable":"","placeholder":"请输入名称"},model:{value:(_vm.appFormData.name),callback:function ($$v) {_vm.$set(_vm.appFormData, "name", $$v)},expression:"appFormData.name"}})],1),_c('el-form-item',{staticClass:"form-item-height",staticStyle:{"margin-bottom":"14px"},attrs:{"label":"描述"}},[_c('el-input',{attrs:{"clearable":"","placeholder":"请输入描述"},model:{value:(_vm.appFormData.remarks),callback:function ($$v) {_vm.$set(_vm.appFormData, "remarks", $$v)},expression:"appFormData.remarks"}})],1),(_vm.appFormData.menu_type === 1)?_c('el-form-item',{staticClass:"form-item-height",staticStyle:{"margin-bottom":"14px"},attrs:{"label":"可见范围","prop":"visible_range"}},[_c('el-select',{staticStyle:{"width":"100%"},model:{value:(_vm.appFormData.visible_range),callback:function ($$v) {_vm.$set(_vm.appFormData, "visible_range", $$v)},expression:"appFormData.visible_range"}},[_c('el-option',{attrs:{"value":0,"label":"工作区可见"}},[_vm._v("工作区可见")]),_c('el-option',{attrs:{"value":1,"label":"指定架构可见"}},[_vm._v("指定架构可见")])],1)],1):_vm._e(),(
              _vm.appFormData.menu_type === 1 &&
              +_vm.appFormData.visible_range === 1
            )?_c('el-form-item',{staticClass:"form-item-height required-star",staticStyle:{"margin-bottom":"14px"},attrs:{"label":"架构选择"}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"value":_vm.archiRange,"placeholder":""},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"choose-color",staticStyle:{"width":"420px"}},[_c('el-tree',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.treeLoading),expression:"treeLoading"}],ref:"treeRef",staticStyle:{"height":"100%"},attrs:{"data":_vm.treeData,"default-expand-all":"","node-key":"id","props":{
                      label: 'name',
                      children: 'children',
                    },"show-checkbox":"","check-strictly":true},on:{"check":_vm.treeSelectCheck},scopedSlots:_vm._u([{key:"default",fn:function({ node, data }){return [_c('div',{staticClass:"archi-label"},[_c('span',{staticClass:"archi-label-svg"},[_c('svg',{staticClass:"color-icon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":_vm.getIcon(data, 'label')}})]),_c('span',[_vm._v(_vm._s(node ? data.name : ""))])])])]}}],null,false,3793114521)})],1)]},proxy:true}],null,false,500715608)},[_c('template',{slot:"prefix"},[_c('div',{staticClass:"show-name"},[_vm._v(" "+_vm._s(_vm.archiName ? _vm.archiName : "请选择架构")+" ")])])],2)],1):_vm._e()],1)]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"loading":_vm.submitLoading,"type":"primary"},on:{"click":_vm.editSubmit}},[_vm._v("确 定")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }