/*
 * @Author: your name
 * @Date: 2021-10-31 22:05:57
 * @LastEditTime: 2022-11-04 10:02:47
 * @LastEditors: zx
 * @Description: In User Settings Edit
 * @FilePath: \dataview-viewer-test\src\apis\data\mapi.js
 */
import axios from "axios";
const request = axios.request

const axiosRequest = {
	getDatalist: (data, url) => {
		return request({
			url: url || 'https://saas-api.bimcc.net/api/mapi',
			method: 'POST',
			data,
		})
	},
	getProject: (id) => {
		return request({
			url: 'https://saas-api.bimcc.net/api/project' + '/' + id,
			method: 'GET',
		})
	},
	getRequest: (url, data) => {
		return request({
			url: 'https://saas-api.bimcc.net/api/' + url,
			data,
			method: 'GET',
		})
	},
	outRequest: (url, data) => {
		return request({
			url: url,
			data,
			method: 'GET',
		})
	},
	postRequest(url, data) {
		return request({
			url: 'https://saas-api.bimcc.net/api/' + url,
			data,
			method: 'post'
		})
	}
}

export default axiosRequest
export const getRequestId = (url, data, id) => {
	return request({
		url: 'https://saas-api.bimcc.net/api/' + url + '/' + id,
		params: data,
		method: 'get'
	})
}
export const postRequest = (url, data) => {
	return request({
		url: 'https://saas-api.bimcc.net/api/' + url,
		data,
		method: 'post'
	})
}
