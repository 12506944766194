<!--
 * @Description: 数据列表
 * @Author: luocheng
 * @Date: 2022-05-31 18:38:11
 * @LastEditors: luocheng
 * @LastEditTime: 2022-06-30 15:46:54
-->
<template>
  <div class="table-list" v-if="paramsResult">
    <article class="table-box">
      <header class="header">
        <section class="order">序号</section>
        <section class="percent">可作用时间利用率%</section>
        <section class="row-item stop_work">停工情况</section>
        <section class="row-item screen_average">期内实际平均</section>
        <section class="row-item cumulative_max">累计最大</section>
        <section class="row-item screen_max">期内最大</section>
      </header>
      <ul class="list">
        <template v-if="dataList && dataList.length">
          <li v-for="(row, index) in dataList" :key="index">
            <section class="order">{{ index + 1 }}</section>
            <section class="percent">{{ row.operable_rate || 0 }}</section>
            <section class="row-item stop_work">{{ row.stop_work || '/' }}</section>
            <section class="row-item screen_average">{{ row.screen_average || 0 }}</section>
            <section class="row-item cumulative_max">{{ row.cumulative_max || 0 }}</section>
            <section class="row-item screen_max">{{ row.screen_max || 0 }}</section>
          </li>
        </template>
        <p class="no-data">暂无数据</p>
      </ul>
    </article>
  </div>
</template>

<script>
import { dataInterface } from '@/apis/data'

export default {
  name: 'TableList',
  props: {
    filterKey: {
      type: [Number, String],
      default: ''
    },
    paramsResult: {
      required: true
    }
  },
  data() {
    return {
      headerList: [
        {
          name: '累计最大',
          key: 'cumulative_max'
        },
        {
          name: '期内最大',
          key: 'screen_max'
        }
      ],
      dataList: []
    }
  },
  created() {
    this.getList(this.paramsResult);
  },
  watch: {
    paramsResult: {
      handler() {
        this.getList(this.paramsResult);
      },
      deep: true,
      immediate: true
    },
    filterKey: {
      handler() {
        if (!this.filterKey) return;
        this.getList(this.paramsResult);
      }
    }
  },
  methods: {
    /**
     * @desc: 验证是否需要渲染字段
     */
    validField(key) {
      const obj = this.headerList.find(ele => ele.key === key);
      return !!obj;
    },
    /**
     * @desc: 获取数据
     */
    getList(params = {}) {
      const { search = [], param = {}, canPost = true } = params;
      if (!canPost) {
        return false;
      }
      this.dataList = [];
      dataInterface({
        rule_id: this.filterKey,
        search,
        ...param
      }, '/api/fourSchedule/countList', 'GET').then(res => {
        if (res.status === 200 && res.data?.code === 200) {
          this.dataList = res.data?.data || [];
        }
      }).catch(err => {
        console.log(err);
      });
    }
  }
}
</script>

<style lang="less" scoped>
.table-list{
  width: 100%;
  height: 65px;
  background: transparent;
  box-sizing: border-box;
  padding-left: 20px;
  .table-box{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    .header{
      min-height: 40px;
      box-sizing: border-box;
      display: flex;
      flex-wrap: nowrap;
    }
    .list{
      flex: 1;
      width: 100%;
      overflow: hidden;
      li{
        height: 25px;
        width: 100%;
        display: flex;
        .row-item{
          height: 25px;
          color: #fff;
          text-align: left;
          line-height: 20px;
        }
        .order, .percent{
          color: #fff;
          height: 25px;
          color: #fff;
          text-align: center;
          line-height: 20px;
        }
      }
      .no-data{
        text-align: center;
        line-height: 25px;
      }
    }
  }
  .order, .percent{
    width: 40px;
    box-sizing: border-box;
    padding: 5px;
    line-height: 30px;
    text-align: center;
    color: rgb(0, 206, 209);
    font-size: 12px;
  }
  .percent{
    width: 118px;
  }
  .row-item{
    flex: 1;
    box-sizing: border-box;
    padding: 5px;
    line-height: 30px;
    text-align: left;
    color: rgb(0, 206, 209);
    font-size: 12px;
  }
}
</style>