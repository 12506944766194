var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.initEnd && _vm.propObj)?_c('div',{staticClass:"common-tree"},[(_vm.statusConfig.showSearch)?_c('el-input',{staticClass:"search",attrs:{"prefix-icon":"el-icon-search","clearable":"","placeholder":_vm.statusConfig.placeholder},on:{"input":_vm.onFilter},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}):_vm._e(),(_vm.statusConfig.showCheckAll)?_c('section',{staticClass:"check-all"},[_c('el-checkbox',{on:{"change":_vm.onCheckAll},model:{value:(_vm.checkAll),callback:function ($$v) {_vm.checkAll=$$v},expression:"checkAll"}},[_vm._v(_vm._s(_vm.statusConfig.checkAllText))])],1):_vm._e(),_c('el-tree',{ref:"treeContent",staticClass:"tree-content",attrs:{"data":_vm.treeData,"default-expand-all":_vm.statusConfig.defaultExpandAll === undefined || _vm.statusConfig.defaultExpandAll ? true : false,"expand-on-click-node":true,"node-key":_vm.propObj.id || 'id',"empty-text":_vm.statusConfig.emptyText,"lazy":_vm.statusConfig.lazy,"load":_vm.loadNode,"highlight-current":"","check-on-click-node":true,"filter-node-method":_vm.filterNode,"show-checkbox":_vm.statusConfig.showCheckBox,"props":{
				children: _vm.propObj.children,
				label: function (data, node) {
					return data[_vm.propObj.label];
				},
				disabled: _vm.statusConfig.disabled
			}},on:{"node-click":_vm.onNodeClick,"check":_vm.onGetCheckNodes},scopedSlots:_vm._u([{key:"default",fn:function({ node, data }){return _c('span',{staticClass:"custom-tree-node",class:{
					'current-custom-tree-node': _vm.currentKey && data[_vm.propObj.id] && _vm.currentKey == data[_vm.propObj.id]
				}},[_c('TreeIcon',{attrs:{"config":_vm.element.iconConfig || {},"rowData":data,"type":'pre',"componentData":_vm.componentList || _vm.componentData}}),_c('span',{staticClass:"label"},[_vm._v(" "+_vm._s(data[_vm.propObj.label] || node.label)+" ")]),(data.__number_count !== undefined && _vm.element.countNumberShow)?_c('span',{staticClass:"tree-count-number",staticStyle:{"z-index":"100"}},[_c('span',{style:({color:_vm.element.countNumberConfig.masterColorPosition == '前' ? _vm.element.countNumberConfig.masterColor : '' })},[_vm._v(_vm._s(_vm.element.countNumberConfig.preText)+" ")]),(data.__pre_number_count !== undefined && data.__pre_number_count !== null )?_c('span',{style:({color:_vm.element.countNumberConfig.masterColorPosition == '0' ? _vm.element.countNumberConfig.masterColor : '' })},[_vm._v(_vm._s(data.__pre_number_count))]):_vm._e(),(data.__pre_number_count !== undefined && data.__pre_number_count !== null)?_c('span',{style:({color:_vm.element.countNumberConfig.masterColorPosition == '/' ? _vm.element.countNumberConfig.masterColor : '' })},[_vm._v("/")]):_vm._e(),_c('span',{style:({color:_vm.element.countNumberConfig.masterColorPosition == '1' ? _vm.element.countNumberConfig.masterColor : '' })},[_vm._v(_vm._s(data.__number_count))])]):_vm._e(),(!_vm.statusConfig.hideActions && _vm.judgingArchiAPermission(_vm.actionLimit.archiLimit, _vm.actionLimit.permission)
					&& _vm.judgingUILogic(_vm.actionLimit.UILogic, _vm.componentList || _vm.componentData)
					)?[(_vm.statusConfig.appendChild && _vm.validDomLogic('appendChild'))?_c('el-button',{staticClass:"action-btn plus-child",attrs:{"type":"text","icon":"el-icon-plus"},on:{"click":function($event){_vm.doAction(_vm.actionConfig.find(ele => ele.key === 'appendChild'), $event, data)}}}):_vm._e(),_c('span',{staticClass:"action-content"},[_c('TreeIcon',{attrs:{"config":_vm.element.iconConfig || {},"rowData":data,"type":'action',"componentData":_vm.componentList || _vm.componentData},on:{"click":function($event){return _vm.onAction(data)}}}),_c('ul',{staticClass:"action-list"},[_vm._l((_vm.actionConfig),function(item,index){return (item.type !== 'appendChild' && item.type !== 'rowClick' && _vm.validDomLogic(item.type))?_c('li',{key:index,staticClass:"action-item",style:({
									color: item.color
								}),on:{"click":function($event){return _vm.doAction(item, $event, data)}}},[_vm._v(_vm._s(item.name))]):_vm._e()}),(_vm.judgingArchiAPermission(_vm.deleteLimit.archiLimit, _vm.deleteLimit.permission)
										&& _vm.judgingUILogic(_vm.deleteLimit.UILogic, _vm.componentList || _vm.componentData))?_c('li',{staticClass:"action-item remove",on:{"click":function($event){return _vm.onRemove(data, $event)}}},[_vm._v("删除")]):_vm._e()],2)],1)]:_vm._e()],2)}}],null,false,3790221628)}),(_vm.showSheet && _vm.sheetFormData)?_c('Spreadsheet',{attrs:{"type":_vm.spreadsheetType,"excelUuid":_vm.exportConfig.exportTemplate,"objectUuid":_vm.exportConfig.objectUUID,"viewUuid":_vm.exportConfig.viewUUID,"dataIds":_vm.sheetFormData,"exportType":_vm.exportConfig.exportTypeSheet || 'pdf',"viewSearch":_vm.exportViewSearch,"viewQuery":_vm.exportViewParams,"extraParams":_vm.exportParam || {}},on:{"exportSuccess":_vm.onExportSuccess,"exportError":_vm.onExportError}}):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }