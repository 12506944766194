var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"guide"},[(_vm.checkAuth())?_c('div',{staticClass:"guide-container"},[_vm._m(0),_c('div',{staticClass:"guide-button"},[_c('div',{staticClass:"guide-button-create",on:{"click":function($event){return _vm.openAddChild()}}},[_vm._m(1),_vm._m(2)]),_c('div',{staticClass:"guide-button-relate",class:!_vm.appInfo?.children?.length ? 'no-relation' : '',on:{"click":_vm.openRelate}},[_vm._m(3),_vm._m(4)])])]):_c('div',{staticClass:"guide-container"},[_vm._m(5)]),_c('el-dialog',{attrs:{"title":"关联页面（仅支持添加功能）","visible":_vm.relateVisible,"width":"480px","append-to-body":""},on:{"update:visible":function($event){_vm.relateVisible=$event}}},[(_vm.relateVisible)?_c('div',{staticClass:"dialog-body"},[_c('div',{staticClass:"content-left-search"},[_c('el-input',{attrs:{"placeholder":"搜索"},model:{value:(_vm.searchValue),callback:function ($$v) {_vm.searchValue=$$v},expression:"searchValue"}})],1),_c('div',{staticClass:"content-tree"},[_c('el-tree',{ref:"treeRef",staticStyle:{"height":"100%"},attrs:{"data":_vm.appInfo.children,"default-expand-all":"","node-key":"id","filter-node-method":_vm.filterNode,"props":{
            label: 'name',
            children: 'children',
          },"check-strictly":true},on:{"node-click":_vm.treeSelectClick},scopedSlots:_vm._u([{key:"default",fn:function({ node, data }){return [_c('div',{staticClass:"archi-label-container",class:_vm.selectRow && (+_vm.selectRow.id === +node.data.id) ? 'selected' : '',style:(+node.data.menu_type === 6 && _vm.childCanChoose(node.data) ? 'height: 70px' : '')},[_c('div',{staticClass:"archi-label-body"},[_c('div',{staticClass:"archi-label-svg"},[(+node.data.menu_type === 6)?_c('svg',{staticClass:"iconfont-svg",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":"#iconwenjianjia2"}})]):(data.icon && data.background)?_c('div',{staticClass:"header-img"},[_c('svg',{staticClass:"iconfont-svg",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":data.icon.indexOf('#')===-1? '#' + data.icon : data.icon}})])]):_c('div',{staticClass:"header-img",staticStyle:{"background":"transparent"}},[_c('i',{staticClass:"iconfont",class:data.icon? data.icon : 'icon-a-5-1zhiliang',style:('color:' + (['#E06363', '#F59A31','#2CB8A4','#4B98E8','#9F6BF5'].indexOf(data.color) === -1 ? '#E06363' : data.color))})]),_c('div',{staticClass:"archi-label-txt"},[_vm._v(" "+_vm._s(node.data.name)+" ")])]),(_vm.selectRow && (+_vm.selectRow.id === +node.data.id))?_c('div',{staticClass:"archi-label-click"},[_c('i',{staticClass:"iconfont iconselect-duigou"})]):_vm._e()]),(+node.data.menu_type === 6 && _vm.childCanChoose(node.data))?_c('div',{staticClass:"empty-container"},[_vm._v(" 该分组下无可选功能 ")]):_vm._e()])]}}],null,false,994688529)})],1)]):_vm._e(),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.relateVisible = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"loading":_vm.submitLoading,"disabled":!_vm.selectRow,"type":"primary"},on:{"click":_vm.editSubmit}},[_vm._v("确 定")])],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"guide-img"},[_c('img',{attrs:{"src":"https://static.bimcc.com/openim/166729471325242497E26640CD10A5B3E4C570DDAD242.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{staticClass:"iconfont icona-xinzengS"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("创建")]),_c('span',[_vm._v("快速创建下级功能或分组")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{staticClass:"iconfont icona-5-32lianjieguanlian"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("关联页面")]),_c('span',[_vm._v("选择一个功能页面显示在这里")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"guide-img"},[_c('img',{attrs:{"src":"https://static.bimcc.com/openim/1668156034062B71813F3684C914BED853B4DF54B8482.png","alt":""}}),_c('div',[_vm._v(" 抱歉，当前账号没有创建下级功能和分组的权限 ")])])
}]

export { render, staticRenderFns }