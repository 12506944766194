<!--
    @name: FLowEngine Index
    @description：FLowEngine Index
    @author: ZengWei
    @date: 2021-10-27 12:06
-->
<template>
	<div class="flow-container">
		<FlowDesign v-if="flowData" :flow-data="flowData"/>
		<el-empty style="height: 100%" v-else :description="emptyString"></el-empty>
	</div>
</template>

<script>
	import FlowDesign from './components/FlowDesign'
	import {flowEngine} from '@/apis/data/workflow'
	import { Empty } from 'element-ui'

	export default {
		name: 'Index',
		components: {FlowDesign,'el-empty':Empty},
		props: {
			module: {
				type: String,
				default: '' //material_scrap
			}
		},
		data() {
			return {
				flowData: null,
				moduleUuid: this.$route.query.module,
        emptyString:'流程加载中',
			}
		},
		methods: {
			flowDesign(param) {
				flowEngine.designShow(param).then((res) => {
					this.flowData = res.data.data
				})
			},
		},
		mounted() {
			let moduleUuid = '';
			if (this.module) {
				moduleUuid = this.module
			} else if (this.moduleUuid) {
				moduleUuid = this.moduleUuid
			}
			if (moduleUuid) {
				let param = {module: moduleUuid}
				this.flowDesign(param)
			} else {
        this.emptyString = '流程加载错误'
				this.$message.error('表单模块唯一标识不存在')
			}
		}
	}
</script>

<style lang="less" scoped>
	.flow-container {
		height: 100%;
	}
</style>
