<!--
    @name: TaskProps
    @description：TaskProps
    @author: ZengWei
    @date: 2022-05-13 10:11
-->
<template>
  <div class="prop-container">
    <i class="iconfont iconc-close" @click="close"></i>
    <el-tabs type="border-card" v-model="activeTab">
      <el-tab-pane v-if="nodeType === 'task'" label="任务属性" name="info">
        <div class="scrollbar">
          <div class="label-title">任务基本信息</div>
          <el-form ref="form" :rules="rules" :model="task" label-position="top" label-width="80px">
            <el-form-item label="任务名称" prop="name">
              <el-input v-model="task.name" @change="onChange"></el-input>
            </el-form-item>
            <el-form-item label="任务状态">
              <el-radio-group v-model="task.status" @change="onChange">
                <el-radio :label="0">
                  <span class="task-status" :style="{background: taskColor.color0}">未开始</span>
                </el-radio>
                <el-radio :label="1">
                  <span class="task-status" :style="{background: taskColor.color1}">进行中</span>
                </el-radio>
                <el-radio :label="2">
                  <span class="task-status" :style="{background: taskColor.color2}">已完成</span>
                </el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="显示预警">
              <el-radio-group v-model="task.warning">
                <el-radio label="是"></el-radio>
                <el-radio label="否"></el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>

          <div class="label-title">{{task.deadline_name}}</div>
          <el-form class="form-table" label-position="left" label-width="80px">
            <el-form-item label="名称">
              <el-input v-model="task.deadline_name"></el-input>
            </el-form-item>
            <el-form-item label="日期">
              <el-date-picker
                v-model="task.deadline"
                style="width: 100%"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="显示">
              <el-radio-group v-model="task.deadline_show">
                <el-radio :label="true">是</el-radio>
                <el-radio :label="false">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>

          <div class="label-title" style="margin-top: 18px">自定义任务内容</div>
          <el-form label-position="left" class="form-table" label-width="80px">
            <template v-for="(item,index) in task.content">
              <div :key="index">
                <div class="clearfix self-define">
                  <span>{{index>9 ? index : '0'+(index+1)}}</span>
                  <span class="float-r" @click="delContent(index)">
                    <i class="el-icon-delete"></i>
                  </span>
                  <span class="float-r" @click="upGo(index)">
                    <i class="el-icon-top"></i>
                  </span>
                  <span class="float-r" @click="downGo(index)">
                    <i class="el-icon-bottom"></i>
                  </span>
                </div>
                <el-form-item label="类型">
                  <el-radio-group class="self-type" v-model="item.type">
                    <el-radio label="input">文本</el-radio>
                    <el-radio label="upload">上传</el-radio>
                    <el-radio label="date">日期</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="名称">
                  <el-input v-model="item.name" @change="onChange"></el-input>
                </el-form-item>
                <el-form-item label="显示">
                  <el-radio-group v-model="item.show" @change="onChange">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="内容">
                  <template v-if="item.type === 'input'">
                    <el-input type="textarea" v-model="item.value" @change="onChange"></el-input>
                  </template>
                  <template v-if="item.type === 'upload'">
                    <uploader :files="item.attach" @extra-files="onSuccess($event,index)"></uploader>
                  </template>
                 <template v-if="item.type === 'date'">
                   <el-date-picker
                     v-model="item.value"
                     type="date"
                     placeholder="选择日期"
                     format="yyyy-MM-dd">
                   </el-date-picker>
                 </template>
                </el-form-item>
              </div>
            </template>
            <el-button @click="addContent" style="width: 100%;margin: 15px 0" icon="el-icon-plus">添加新字段</el-button>
          </el-form>
        </div>
      </el-tab-pane>
      <el-tab-pane v-if="['start','task','end'].includes(nodeType)" label="任务配置" name="conf">
        <div class="scrollbar">
          <div class="label-title">任务配置</div>
          <el-form label-position="top" label-width="80px">
            <el-form-item label="任务责任人" style="margin-bottom: 0">
              <el-radio-group class="user-type-radio" @change="onTypeChange" v-model="task.user_type" size="small">
                <!--与流程保持一致-->
                <el-radio :label="1">指定人员</el-radio>
                <el-radio :label="2">发起人分派</el-radio>
                <el-radio :label="5">指定部门</el-radio>
                <el-radio :label="6">指定岗位</el-radio>
                <el-radio :label="7">任务流中分派</el-radio>
              </el-radio-group>

              <el-form-item label="人员范围" v-if="[1, 2, 7].includes(task.user_type)">
                <el-radio-group v-model="task.user_from" @change="changeUserScope">
                  <el-radio-button :label="1">项目人员</el-radio-button>
                  <el-radio-button :label="2" disabled>全部人员</el-radio-button>
                  <el-radio-button :label="3">公司人员</el-radio-button>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="人员选择" v-if="task.user_type === 1" style="margin-bottom: 0">
                <select-user
                  :show-list="task.target_users"
                  :show-choose-button="false"
                  position-type="left"
                  @on-select-change="onSelectUser"
                />
              </el-form-item>
              <el-form-item v-if="task.user_type === 5" label="部门选择">
                <select-depart
                  :show-list="task.user_depart"
                  @on-select-change="onSelectDepart"
                />
              </el-form-item>
              <el-form-item v-if="task.user_type === 6" label="岗位选择">
                <el-select
                  v-model="task.user_role"
                  multiple
                  placeholder="请选择"
                  class="select-item"
                  @change="onChange">
                  <el-option
                    v-for="item in roleList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form-item>
            <el-form-item label="是否允许分派">
              <el-radio-group v-model="task.assign" @change="onChange">
                <el-radio :label="1">允许分派</el-radio>
                <el-radio :label="2">不许分派</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="多责任人处理">
              <el-radio-group v-model="task.sign" @change="onChange">
                <el-radio :label="1">任意一人处理</el-radio>
                <el-radio :label="2">所有人处理</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="任务工单">
              <el-radio-group v-model="task.form_type">
                <el-radio :label="1">功能表单</el-radio>
                <el-radio :label="2">对象表单</el-radio>
                <el-radio :label="3">系统表单</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="功能表单" v-if="task.form_type === 1">
              <el-select
                filterable
                v-model="task.func_form"
                class="select-item"
                @change="onChange"
                placeholder="请选择功能表单">
                <el-option
                  v-for="item in funcFormList"
                  :key="item.id"
                  :label="item.module_name"
                  :value="item.module_uuid">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="对象表单" v-if="task.form_type === 2">
              <el-select
                filterable
                v-model="task.object_uuid"
                class="select-item mb-10"
                @change="getObjectFormList"
                placeholder="请选择数据对象">
                <el-option
                  v-for="item in objectList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.uuid">
                </el-option>
              </el-select>
              <el-select
                filterable
                v-model="task.func_form"
                @change="onChange"
                class="select-item"
                placeholder="请选择对象表单">
                <el-option
                  v-for="item in objectFormList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.uuid">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="系统表单" v-if="task.form_type === 3">
              <el-select v-model="task.func_form" @change="onChange" class="select-item" placeholder="请选择系统表单">
                <el-option label="质量巡检" value="shanghai"></el-option>
                <el-option label="安全巡检" value="beijing"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
      </el-tab-pane>
      <el-tab-pane label="逻辑配置" name="logic">
        <div class="scrollbar">
          <div class="label-title">逻辑配置</div>
          <el-form label-position="top" label-width="80px">
            <el-form-item v-if="['edge','gateway'].includes(nodeType)" label="逻辑名称">
              <el-input v-model="task.name" @change="onChange"></el-input>
            </el-form-item>
            <el-form-item v-else label="任务流转条件" style="margin-bottom: 0">
              <el-radio-group class="task-run-radio" v-model="task.run_flow" @change="onChange">
                <el-radio :label="1">发起后流转</el-radio>
                <el-radio :label="2">流程结束后</el-radio>
                <el-radio :label="3">责任人决策</el-radio>
                <el-radio :label="4">自定义条件</el-radio>
              </el-radio-group>
            </el-form-item>
            <task-rules
              v-if="task.run_flow === 4 || ['edge','gateway'].includes(nodeType)"
              :allFormFields="allFormFields"
              @on-logic="logicChange"></task-rules>
<!--            <template v-if="!['edge','gateway'].includes(nodeType)">
              <div class="label-title">事件触发器</div>
              <el-form-item label="数据对象事件">
                <el-select
                  filterable
                  v-model="task.object_e_uuid"
                  class="select-item mb-10"
                  @change="getObjectFormList"
                  placeholder="请选择数据对象">
                  <el-option
                    v-for="item in objectList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.uuid">
                  </el-option>
                </el-select>
                <el-select
                  filterable
                  v-model="task.object_event"
                  @change="onChange"
                  class="select-item"
                  placeholder="请选择对象事件">
                  <el-option
                    v-for="item in objectEventList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.uuid">
                  </el-option>
                </el-select>
              </el-form-item>
              <div class="label-title">任务消息提醒</div>
              <msg-config :msg-select="task.msg_select" :save-btn="true" @on-change="msgChange"></msg-config>
            </template>-->
          </el-form>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {Tabs,TabPane,RadioGroup,Radio,DatePicker} from "element-ui";
import uploader from "@/components/fileUpload/fileUpload/Index";
import selectUser from "@/custom-component/form/formParser/selectUser";
import selectDepart from "@/custom-component/form/formParser/flowEngine/components/selectDepartment";
import TaskRules from "@/custom-component/task-flow/components/TaskRules";
// import MsgConfig from "@/custom-component/task-flow/components/MsgConfig";
import {dataInterface} from "@/apis/data";
export default {
  name: "TaskProps",
  components: {
    uploader,
    selectUser,
    selectDepart,
    TaskRules,
    // MsgConfig,
    'el-tabs': Tabs,
    'el-tab-pane': TabPane,
    'el-radio-group': RadioGroup,
    'el-radio': Radio,
    'el-date-picker': DatePicker,
  },
  props: ['currentItem','currentNodeId','nodeType','formNode'],
  data() {
    return {
      activeTab:'info',
      archId: 0,
      taskColor:{
        color0: '#7E8595',
        color1: '#EF9818',
        color2: '#40B67C',
      },
      task: {
        name: '任务名称',
        status: 0,
        warning: false,
        color: '#7E8595',
        deadline_name: '计划完成时间',
        deadline: '',
        deadline_show: true,
        content: [],

        user_type: 7,
        target_users:[],
        user_from: 1,
        user_role: 1,
        user_depart: [],
        assign: 1,
        sign: 1,
        form_type: 1,
        object_uuid: '',
        object_e_uuid: '',
        object_event: '',
        func_form: '',
        run_flow: 1,
        run_logic:[],
        msg_select: '',
      },
      rules: {
        name: [
          { required: true, message: '请输入任务名称', trigger: 'blur' },
        ],
      },
      departList:[], // 部门数据
      roleList:[], // 岗位数据
      objectList: [],
      objectEventList: [],
      funcFormList: [],
      objectFormList: [],
      allFormFields:[],
    }
  },
  watch: {
    currentItem: {
      handler() {
        this.initPropsData()
      },
      deep: true
    },
    nodeType(){
      this.activeTabs()
    },
    currentNodeId:{
      handler() {
        this.getFormHasField()
      },
      immediate: true
    }
  },
  created() {
    this.getFuncDesign();
    this.getObjectList();
  },
  mounted() {
    this.activeTabs()
    this.initPropsData()
  },
  methods: {
    getFormHasField(){
      const nodeData = this.$parent.getPreTask(this.currentNodeId,this.nodeType)
      const formNode = nodeData.filter(item=>item.form_type)
      dataInterface({formNode:formNode},'/api/taskengine/taskfield').then(res=>{
        if(res.data.code === 200){
          this.allFormFields = res.data.data
        }
      })
    },
    initPropsData(){
      const name = this.nodeType === 'edge'? '逻辑条件' : '任务名称';
      this.task.name = this.currentItem?.name || name
      this.task.status = this.currentItem?.status || 0
      this.task.warning = this.currentItem?.warning || false
      this.task.color = this.currentItem?.color || '#7E8595'
      this.task.deadline_name = this.currentItem?.deadline_name || '计划完成时间'
      this.task.deadline = this.currentItem?.deadline || ''
      this.task.deadline_show = this.currentItem?.deadline_show || false
      this.task.content = this.currentItem?.content || []
      this.task.user_type = this.currentItem?.user_type || 7
      this.task.target_users = this.currentItem?.target_users || []
      this.task.user_from = this.currentItem?.user_from || 1
      this.task.user_role = this.currentItem?.user_role || 1
      this.task.user_depart = this.currentItem?.user_depart || []
      this.task.assign = this.currentItem?.assign || 1
      this.task.sign = this.currentItem?.sign || 1
      this.task.form_type = this.currentItem?.form_type || 1
      this.task.object_uuid = this.currentItem?.object_uuid || ''
      this.task.object_e_uuid = this.currentItem?.object_e_uuid || ''
      this.task.object_event = this.currentItem?.object_event || ''
      this.task.func_form = this.currentItem?.func_form || ''
      this.task.run_flow = this.currentItem?.run_flow || 1
      this.task.run_logic = this.currentItem?.run_logic || []
      this.task.msg_select = this.currentItem?.msg_select || ''
    },
    onSuccess(file,index){
      this.task.content[index].attach = file
      console.log(this.task.content[index], 444)
    },
    getFuncDesign(){
      const url = `api/form4/design?page=1&per_page=1000`
      dataInterface({},url,'GET').then(res => {
        if(res.data.code === 200){
          this.funcFormList = res.data.data.data
        }
      })
    },
    getObjectList(){
      const url = `api/metadata/objects?page=1&size=1000`
      dataInterface({},url,'GET').then(res => {
        if(res.data.code === 200){
          this.objectList = res.data.data.data
        }
      })
    },
    getObjectFormList(objectUuid){
      const url = `api/saas/metadata/getFormDesigns?object_uuid=${objectUuid}`
      dataInterface({},url,'GET').then(res => {
        if(res.data.code === 200){
          this.objectFormList = res.data.data.filter(item=>item.uuid !== '')
        }
      })
    },
    // 获取项目或公司岗位
    getArchRole(){
      let queryData;
      const archiInfo = this.$GetTargetArchi()
      if(this.task.user_from === 1){
        queryData = {
          object_uuid:'object6170c260890d9',
          view_uuid:'view6172842c7f669',
          project_id:archiInfo.archi_id,
          transcode: 0,
          __method_name__:'dataList'
        }
      } else {
        queryData = {
          object_uuid:'object6170c260890d9',
          view_uuid:'view61728398b0390',
          company_id:archiInfo.company_id,
          transcode: 0,
          __method_name__:'dataList'
        }
      }
      dataInterface(queryData).then(res => {
        if(res.data.code === 200){
          this.roleList = res.data.data;
        }
      })
    },
    onTypeChange(){
      if(this.task.user_type === 6){
        this.getArchRole() //请求岗位接口
      }
      this.onChange()
    },
    changeUserScope(){
      if(this.task.user_type === 6){
        this.getArchRole() //请求岗位接口
      }
      let targetArch = this.$GetTargetArchi()
      if (this.task.user_from === 3) {
        this.archId = targetArch.company_id ?
          targetArch.company_id : targetArch.archi_id
      } else {
        this.archId = 0
      }
    },
    activeTabs(){
      if(this.nodeType === 'task'){
        this.activeTab = 'info'
      } else if(this.nodeType === 'gateway' || this.nodeType === 'edge'){
        this.activeTab = 'logic'
      } else {
        this.activeTab = 'conf'
      }
    },
    addContent(){
      const newData = {type: 'input', name: '',value:'',attach:[], show: true}
      this.task.content.push(newData)
      this.onChange()
    },
    delContent(index){
      this.task.content.splice(index,1);
      this.onChange()
    },
    logicChange(logicRules){
      this.task.run_logic = logicRules
      this.onChange()
    },
    msgChange(msgId){
      this.task.msg_select = msgId
      this.onChange()
    },
    onChange(){
      let data = {}
      if(['edge','gateway'].includes(this.nodeType)){
        const {name, run_logic} = this.task
        data = {name, run_logic}
      } else if(['start','end'].includes(this.nodeType)) {
        const {
          user_type, target_users, user_from, user_role, user_depart,assign, sign, form_type,
          object_uuid,object_e_uuid,object_event, func_form, run_flow, run_logic,msg_select
        } = this.task
        data = {
          user_type, target_users, user_from, user_role,user_depart, assign, sign, form_type,
          object_uuid,object_e_uuid,object_event, func_form, run_flow, run_logic,msg_select
        }
      } else {
        let color = 'color' + this.task.status
        this.task.color = this.taskColor[color]
        data = this.task
      }
      this.$emit('on-change',data)
    },
    onSelectUser(userData){
      let users = []
      for (let item of userData) {
        let user = {
          id: item.user_id,
          user_id: item.user_id,
          name: item.name,
          avatar: item.avatar,
          user_table: item.user_table,
          section_id: item.section_id
        }
        users.push(user)
      }
      this.task.target_users = users
      this.onChange()
    },
    onSelectDepart(depart){
      let departList = []
      for (let item of depart) {
        let users = [] // 部门用户
        for (let user of item.users){
          let userItem = {
            id: user.user_id,
            user_id: user.user_id,
            name: user.name,
            avatar: user.avatar,
            user_table: user.user_table,
            section_id: user.section_id
          }
          users.push(userItem)
        }
        let itemData = {
          id: item.id,
          name: item.name,
          section_id: item.section_id,
          users: users
        }
        departList.push(itemData)
      }
      this.task.user_depart = departList
      this.onChange()
    },
    close(){
      this.$emit('on-close')
    },
    upGo(index){
      let fieldData = this.task.content;
      if(index!=0){
        fieldData[index] = fieldData.splice(index-1, 1, fieldData[index])[0];
      }else{
        fieldData.push(fieldData.shift());
      }
      this.task.content = fieldData;
      this.onChange();
    },
    downGo(index){
      let fieldData = this.task.content;
      if(index != fieldData.length-1){
        fieldData[index] = fieldData.splice(index+1, 1, fieldData[index])[0];
      }else{
        fieldData.unshift( fieldData.splice(index,1)[0]);
      }
      this.task.content = fieldData;
      this.onChange();
    },
  },
}
</script>

<style lang="less" scoped>
.scrollbar{
  overflow-y: auto;
  padding: 15px 15px 0 15px;
  height: calc(100% - 15px);
}

:deep(.el-tabs--border-card) {
  border-left: 1px solid #DCDFE6;
  border-top: none;
  border-right: none;
  border-bottom: none;
}

:deep(.form-table)  {
  .el-form-item {
    border: 1px solid #eaebed;
    display: flex;
    margin-bottom: -1px !important;
    /*margin-bottom: -1px !important;*/

    .el-form-item__label {
      border-right: 1px solid #eaebed;
      background: #f8f8f9;
      padding: 6px 10px;
    }

    .el-form-item__content {
      margin-left: 0 !important;
      flex: 1;
      padding: 6px 10px;
      overflow: hidden;
      clear: both;
      /*margin-bottom: 1px;*/

      .el-select,
      .el-cascader {
        width: 100%;
      }

      .self-type {
        .el-radio{
          margin-right: 18px;
        }
      }
    }
  }
}

.label-title{
  border-left: 3px solid #007FE0;
  padding: 0 10px;
  line-height: 1;
  margin: 0 0 16px;
  font-weight: 600;
}

.clearfix:after{
  content: '';
  display: block;
  clear: both;
}

.select-item{
  width: 100%;
}
.mb-10{
  margin-bottom: 10px;
}

.float-r{
  float: right;
  padding: 0 1px;
  cursor: pointer;
}
.self-define{
  margin: 8px 0;
  text-indent: 2px;
}

:deep(.user-type-radio) {
  .el-radio{
    width: 76px;
    margin-bottom: 15px;
  }
}

:deep(.task-run-radio) {
  .el-radio{
    width: 125px;
    margin-bottom: 15px;
  }
}


.prop-container{
  width: 350px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  background: #ffffff;

  :deep(.el-tabs) {
    height: 100%;
    .el-tabs__content{
      padding: 0;
      height: calc(100% - 40px);
      .el-tab-pane{
        height: 100%;
      }
    }
  }

  .iconc-close{
    position: absolute;
    font-size: 22px;
    top: 5px;
    right: 8px;
    z-index: 10;
    cursor: pointer;
  }

  .property{
    height: calc(100% - 52px);
  }

  .task-status{
    padding: 5px 7px;
    color: white;
  }
}
</style>
