<!--
 * @Description: 流程图
 * @Author: luocheng
 * @Date: 2021-12-09 11:24:58
 * @LastEditors: luocheng
 * @LastEditTime: 2022-04-01 18:11:36
-->
<template>
	<div class="common-flow" v-loading="!isInit">
		<FlowDesigner
			v-if="flowConfig && isInit"
      ref="flowDesigner"
      :form-uuid="flowConfig.form_uuid || flowConfig.object_uuid"
      :editable="true"
      :flow-data="flowData"
      :user-data="userData"
      :form-item="formInputItem"
      :sub-flow-list="subFlowData"
      @on-save="submitFlow"
      @on-form="openFormDesign"
		></FlowDesigner>

    <el-dialog
      :visible.sync="dialogVisible"
      title="流程节点自定义审批内容"
      fullscreen
      append-to-body
      custom-class="flow-form-design"
    >
      <section class="domain-body">
        <FormDesigner @form-data="saveFormDesign"></FormDesigner>
      </section>
    </el-dialog>
	</div>
</template>

<script>
import {FlowDesigner} from "bimcc-ui"
import {flowEngine} from "@/apis/data/workflow";
import {transFieldsUUid} from "@/apis/data";
import {viewGetFormItem} from "@/custom-component/form/newParser/scripts/itemDataCopy";
import {getDepartmentUser} from "@/api/saasManage";
import FormDesigner from "@/custom-component/form/FormDesigner";

export default {
	name: 'CommonFlow',
	components: {
    FlowDesigner,
    FormDesigner
	},
	props: {
		element: {
			type: Object,
			required: true,
			default: () => {}
		}
	},
	data() {
		return {
			isInit: false,
      flowData: {},
      userData: [],
      formInputItem: [],
      subFlowData: [],
      dialogVisible: false,
      totalCount: 0
		}
	},
	computed: {
		// 流程图配置
		flowConfig() {
			return this.element && this.element.flowConfig;
		}
	},
	watch: {
		flowConfig: {
			handler() {
        this.initData()
				if (this.flowConfig?.object_uuid) {
					this.$router.replace({
						query: {
							...this.$route.query,
							obuuid: this.flowConfig.object_uuid
						}
					});
				}
			},
			immediate: true,
			deep: true
		}
	},
  mounted() {
  },
  methods: {
    initData(){
      this.totalCount = 0
      const formUuid = this.flowConfig.form_uuid || this.flowConfig.object_uuid
      if(formUuid){
        this.initFlowDetail(formUuid)
        this.initFormItem(formUuid)
        this.initFlowUser()
        this.initLowerFlow()
      }
    },
    setIsInit(){
      if(this.totalCount === 4){
        this.isInit = true;
      }
    },
    initFlowDetail(formUuid){
      flowEngine.designShow({module: formUuid}).then(res=>{
        this.flowData = res.data.data
        this.totalCount++
        this.setIsInit()
      })
    },
    initFormItem(formUuid){
      transFieldsUUid(formUuid).then(res=>{
        const jsonFields = res.data.data?.fields || []
        this.formInputItem = viewGetFormItem(jsonFields)
        this.totalCount++
        this.setIsInit()
      })
    },
    initFlowUser(){
      getDepartmentUser({}).then(res=>{
        this.userData = res.data.data
        this.totalCount++
        this.setIsInit()
      })
    },
    initLowerFlow(){
      flowEngine.designList({parent: true}).then(res=>{
        this.subFlowData = res.data.data.data
        this.totalCount++
        this.setIsInit()
      })
    },
    submitFlow(data){
      flowEngine.designSave(this.flowData.id,data).then(res=>{
        if(res.data.code === 200) {
          this.$message.success('保存流程成功');
        }
      })
    },
    openFormDesign(){
      this.dialogVisible = true;
    },
    saveFormDesign(formDesignData){
      const design = JSON.parse(formDesignData.json)
      this.$refs.flowDesigner.setExtendForm(design)
      this.dialogVisible = false
    },
  }
};
</script>

<style lang="less" scoped>
.common-flow {
	height: 100%;
	width: 100%;
	position: relative;
	.mask {
		position: absolute;
		height: 100%;
		width: 100%;
		z-index: 10;
		background: rgba(0, 0, 0, 0.3);
		left: 0;
		top: 0;
	}
}
:deep(.flow-form-design){
  .domain-body{
    width: 100%;
    height: calc(100vh - 60px);
  }
  .el-dialog__header{
    border-bottom: 1px solid #f1e8e8;
  }
  .el-dialog__body{
    padding: 0;
  }
}
</style>
