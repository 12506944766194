<!--
    @name: widget-position
    @description：widget-position
    @author: ZengWei
    @date: 2022-03-25 09:40
-->
<template>
  <div>
    <SelectPosition
      v-model="element.value"
      :subId="element.config.__config__.cascade"
      :isCheckType="element.config.multiple"
      :subIdNecessary="element.config.__config__.cascadeOpen"
      :disabled="disabled || element.config.disabled"
      @change="positionChange"
    />
  </div>
</template>

<script>
import SelectPosition from "@/custom-component/form/formParser/selectPosition/index";
import formItemMinix from "@/custom-component/form/newParser/scripts/formItemMinix";

export default {
  name: "WidgetPosition",
  components: {SelectPosition},
  mixins: [formItemMinix],
  inject: ["parser"],
  props: ['element', 'disabled', 'displayData'],
  data() {
    return {}
  },
  created() {
    const casFormId = this.element.config?.__config__?.cascadeFormId
    if(casFormId){
      // 部位依赖筛选
      const cascade = this.parser.getFieldValue(casFormId,true)
      this.element.config.__config__.cascade = cascade
    }
  },
  methods: {
    positionChange(position){
      if(position instanceof Array && position.length){
        let modelArr = []
        if(position && position.length!=0){
          for(let i = 0; i < position.length; i++){
            if(position[i].correlation_model && position[i].correlation_model!=null){
              modelArr.push(position[i].correlation_model)
            }
          }
          if(modelArr.length) {
            this.$emit('trigger-active',{},'positionModel',modelArr)
          }
        }
      }
    },
  },
}
</script>

<style scoped>

</style>
