var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scroll-block-container"},[_c('div',{staticClass:"scroll-block-body"},[(_vm.tableData.length !== 0)?_vm._l((_vm.activeRow),function(row,index){return _c('div',{key:index,staticClass:"block-com"},[_c('transition',{attrs:{"name":"scroll-list"}},[_c('section',{key:row.key,staticClass:"block-item",style:(_vm.blockStyle)},_vm._l((_vm.fieldForView),function(item,index){return _c('div',{key:item.uuid,staticClass:"block",style:({
								color: item.color,
								textAlign: item.align || 'left',
								..._vm.bodyStyle
							})},[_c('span',{key:row[item.uuid],staticClass:"cell-span"},[(item.type === 2)?[(Array.isArray(row[item.uuid]))?_c('el-image',{staticClass:"image",style:({
											height: (item.imgConfig.height || 60) + 'px',
											width: (item.imgConfig.height || 60) + 'px'
										}),attrs:{"src":row[item.uuid] && row[item.uuid].length && row[item.uuid][0].url.toString().indexOf('http') > -1
												? row[item.uuid][0].url
												: _vm.defaultImage,"previewSrcList":[
											row[item.uuid].length ? row[item.uuid][0].url.toString().replace('_thumb', '') : _vm.defaultImage
										],"lazy":"","fit":"cover","alt":"图片"}}):_c('el-image',{staticClass:"image",style:({
											height: (item.imgConfig.height || 60) + 'px',
											width: (item.imgConfig.height || 60) + 'px'
										}),attrs:{"src":row[item.uuid] && row[item.uuid].indexOf('http') > -1 ? row[item.uuid] : _vm.defaultImage,"previewSrcList":[
											row[item.uuid] ? row[item.uuid].toString().replace('_thumb', '') : _vm.defaultImage
										],"lazy":"","fit":"cover","alt":"图片"}})]:(item.type === 3 && row[item.uuid])?_c('span',{style:({
										color: _vm.filterStatus(row[item.uuid], item.statusOptions).color || item.color
									})},[_vm._v(" "+_vm._s(_vm.filterStatus(row[item.uuid], item.statusOptions).label)+" ")]):(item.type === 4 && row[item.uuid])?[_vm._v(_vm._s(_vm.filterTime(row[item.uuid], item.timeFormat, item.connector)))]:(item.type === 5 && row[item.uuid])?[_c('div',{staticClass:"rich-text",domProps:{"innerHTML":_vm._s(row[item.uuid])}})]:(item.type === 6 && row[item.uuid])?[_c('a',{staticClass:"link",attrs:{"href":row[item.uuid],"alt":"链接"}},[_vm._v(" "+_vm._s(row[item.uuid])+" ")])]:(item.type === 7)?[_c('el-switch',{on:{"change":function($event){return _vm.onSwitch(item, row[item.uuid], row)}},model:{value:(row[item.uuid]),callback:function ($$v) {_vm.$set(row, item.uuid, $$v)},expression:"row[item.uuid]"}})]:[_c('span',{style:(index === 0 ? _vm.headerStyle : '')},[_vm._v(" "+_vm._s(row[item.uuid] || '/')+" ")])]],2)])}),0)])],1)}):_c('el-empty',{attrs:{"description":"暂无数据"}})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }