/*
 * @Description: 手写或特殊组件mixin
 * @Author: luocheng
 * @Date: 2022-06-01 09:59:26
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2023-01-04 15:50:37
 */
import { mapState } from 'vuex';
import { getQueryValue, getComponentById, removeEventBus  } from '@/utils/tools';

export default {
  props: {
    element: {
      type: Object,
      required: true,
      default: () => {}
    },
    // 是否在组合内
    isGroup: {
      type: Boolean,
    },
    // 组合内组件列表
    groupComponents: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState(['componentData', '_PageCustomStatus', '_APPCustomStatus'])
  },
  methods: {
    /**
		 * @desc: 初始化参数
		 * @param {Array} paramsConfig
		 */
		initParams(paramsConfig = []) {
			// 是否可以请求
			let canPost = true;
			if (!paramsConfig || !paramsConfig.length) return {
				canPost
			};
			let search = [];
			const param = {};
			for (let i = 0; i < paramsConfig.length;i++) {
        const {
          paramType,
          code,
          uuid,
          isRequired,
          originType,
          componentId,
          urlKey,
          ruleType,
          componentKey,
          statusCode,
          fixedValue
        } = paramsConfig[i];
        let paramsVal = '';
        if (originType === 'url') {
          // url参数
          paramsVal = getQueryValue(urlKey);
          if (isRequired && !paramsVal) {
            canPost = false;
            break;
          }
        } else if (originType === 'component') {
          // 组件参数
          let originComponent = getComponentById(this.componentData, componentId);
          if (!originComponent && this.isGroup && this.groupComponents.length) {
            originComponent = getComponentById(this.groupComponents, componentId);
          }
          if (isRequired && (!originComponent || originComponent === null ||  !originComponent.resolveData )) {
            canPost = false;
            break;
          }
          if (componentKey) {
            paramsVal = originComponent?.resolveData?.[componentKey];
          } else {
            paramsVal = originComponent?.resolveData;
          }
        } else if (originType === 'pageStatus') {
          paramsVal = this._PageCustomStatus[statusCode] || this._APPCustomStatus[statusCode];
          if (isRequired && !paramsVal) {
            canPost = false;
            break;
          }
        } else if (originType === 'fixed') {
          paramsVal = fixedValue;
          if (isRequired && !paramsVal) {
            canPost = false;
            break;
          }
        }
        if (paramType === 'param') {
          // 普通请求参数
          // 写入params
          if (code) {
            this.$set(param, code, paramsVal);
          } else if (uuid) {
            this.$set(param, uuid, paramsVal);
          } else {
            canPost = false;
            // console.log('参数配置出错')
            break;
          }
        } else if (paramType === 'search') {
          if (Array.isArray(paramsVal) && this.validSearch(paramsVal) ) {
            search = search.concat(paramsVal);
          }  else if (uuid) {
            search.push({
              field_uuid: uuid,
              ruleType,
              value: paramsVal
            });
          } else if (code) {
            search = search.concat({
              code,
              ruleType,
              value: paramsVal
            });
          } else {
            canPost = false;
            console.log('参数配置出错')
            break;
          }
        }
			}
			return { search, param, canPost};
		},
		/**
     * @desc: 是否为合法的search
     * @param {Array} arr
     */
    validSearch(arr) {
      if (!arr || !Array.isArray(arr) || !arr.length)
        return true;
      for (let i = 0, len = arr.length; i < len; i++) {
        const item = arr[i];
        if (Object.prototype.toString.call(item) !== ['object Object']) {
          return false;
        }
      }
      return true;
    },
  },
  beforeDestroy() {
    if (!this.EDITOR_pageUUID) {
      removeEventBus('databaseTrigger', this.element.id);
    }
  }
}