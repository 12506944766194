
/*
 * @Description: router
 * @Author: luocheng
 * @Date: 2021-08-17 16:57:39
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2023-01-18 16:11:28
 */
import Vue from 'vue';
import VueRouter from 'vue-router';
// import ModifyPage from '@/views/ModifyPage.vue';
// import FlexTest from '@/views/FlexTest.vue';
// import Login from '@/views/Login';
// import Index from '@/views/Index';
// import FlowEngine from '@/custom-component/form/formParser/flowEngine/index.vue';
// import FormDesigner from "@/custom-component/form/FormDesigner";
// import TestPage from '@/views/Test';
// import PageView from '@/views/PageView';
// import ConfigChart from '@/views/ConfigChart';
// import Layout from '@/layouts/index.vue';
import saasBase from '@/router/saasBase';
import dataStoreRoutes from '@/router/manage/dataStore'
import formRouter from '@/router/manage/form'
Vue.use(VueRouter);


// saas系统路由替换
// 上面定义routers的路由被全部覆盖，使用新的saas相关路由，后续需要可以添加进来
// routes = routes.concat(saasBase);
let routes = saasBase;

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});
/* 获取官网所有路由名称数组 */
let websiteList = []
let websiteListPath = []
const filesWebsite = require.context('@/router/website', true, /\.js$/)
filesWebsite.keys().forEach(key => {
  const name = Object.keys(filesWebsite(key).default)
  name.forEach(element => {
    const routerItem = filesWebsite(key).default[element]
    websiteList.push(routerItem.name)
    websiteListPath.push(routerItem.path)
    if (routerItem.children && routerItem.children.length) {
      routerItem.children.forEach(item => {
        websiteList.push(item.name)
        websiteListPath.push(item.path)
        if (item.children && item.children.length) {
          item.children.forEach(item => {
            websiteList.push(item.name)
            websiteListPath.push(item.path)
          });
        }
      });
    }
  });
})
router.beforeEach((to, from, next) => {
  // 打开文件不对权限进行判断
  if(to.path === 'openFile.html') {
    next()
    return
  }
  let routeRoles = JSON.parse(localStorage.getItem('routeRoles')) || [] ;
// 如果有数仓权限 则把数仓路由添加进权限判断里
  if(routeRoles && routeRoles.length && routeRoles.includes('/data-store/list')){
    formRouter[0].children.forEach(data => {
      routeRoles.push(data.path)
    })
    dataStoreRoutes[0].children.forEach(data => {
      routeRoles.push(data.path)
    })
  }
  if (!routeRoles || routeRoles.length === 0) {
    routeRoles.push('/login')
    routeRoles.push('/login')
    routeRoles.push('/share')
    routeRoles.push('/shareDetail')
    routeRoles.push('/appshareDetail')
    routeRoles.push('/home')
    routeRoles.push('/manage/config')
  } else if (Array.isArray(routeRoles) && routeRoles.length > 0) {
    routeRoles.push('/login')
    routeRoles.push('/home')
    routeRoles.push('/modify-page')
    routeRoles.push('/application/page-parser')
    routeRoles.push('/application/page-edit')
    routeRoles.push('/manage/config')
    routeRoles.push('/application/my-application')
    routeRoles.push('/dwh/objects/fields-manage')
    routeRoles.push('/share')
    routeRoles.push('/shareDetail')
    routeRoles.push('/appshareDetail')
  }
  if(routeRoles && routeRoles.length){
    let newArr = routeRoles.some( item => {
      if(to.path.indexOf(item) !== -1) {
        return true;
      }
    })
    if(!newArr){
      next({
        name: 'Login'
      });
      return;
    }
  }
	if (to.meta.title) {
    //如果设置标题，拦截后设置标题
    document.title = to.meta.title;
  }
  /* 判断官网页面不需要登录等验证 */
  if (websiteList.indexOf(to.name) !== -1) {
    next();
    return
  }
  if(!to.path.includes('share')){
    if (!localStorage.getItem('userInfo')  && to.name !== 'Login') {
      next({
        name: 'Login'
      });
      return;
    }
  }

  if (to.matched.length === 0) {
    //匹配不到相对应的路由时，跳转到首页
    next({
			name: to.name || 'Login'
		});
    return;
  }
  next();
});

export default router;
