/*
 * @Description: 计算尺寸
 * @Author: luocheng
 * @Date: 2021-08-10 16:13:14
 * @LastEditors: luocheng
 * @LastEditTime: 2021-08-26 15:58:39
 */
import store from '@/store';

/**
 * @desc: 根据配置的缩放比例计算缩放后的值
 * @param {Number} value 被缩放的值
 * @return {Number}
 */
export function onSizeWithScale(value) {
	if (isNaN(value) || !store.state || !store.state.canvasStyleObj || store.state.canvasStyleObj.scale) return value;
	return (value * parseInt(store.state.canvasStyleObj.scale)) / 100;
}

/**
 * @desc: 角度转弧度
 * @param {Number} angle 角度
 * Math.PI = 180 度
 * @return {Number}
 */
function angleToRadian(angle) {             
	return (angle * Math.PI) / 180;
}

/**
 * @desc: 计算正弦函数
 * @param {Number} rotate 角度
 */
export function sin(rotate) {
	return Math.abs(Math.sin(angleToRadian(rotate)));
}

/**
 * @desc: 计算正弦函数
 * @param {Number} rotate 角度
 */
export function cos(rotate) {
	return Math.abs(Math.cos(angleToRadian(rotate)));
}

/**
 * @desc: 计算可用角度
 * @param {Number} deg 角度值
 */
export function mod360(deg) {
	return (deg + 360) % 360;
}

/**
 * @desc: 根据角度改变style
 * @param {Number} value
 */
export function changeStyleWithScale(value) {
	return (value * parseInt(store.state.canvasStyleData.scale)) / 100;
}

/**
 * @desc: 求两点之间的中点坐标
 * @param {Object} p1 开始点
 * @param {Object} p2 结束点
 * @return {Object} 中心点坐标
 */
export function getCenterPoint(p1, p2) {
	return {
		x: p1.x + (p2.x - p1.x) / 2,
		y: p1.y + (p2.y - p1.y) / 2
	};
}

/**
 * 计算根据圆心旋转后的点的坐标
 * @param   {Object} point   旋转前的点坐标
 * @param   {Object} center  旋转中心
 * @param   {Number} rotate  旋转的角度
 * @return  {Object}         旋转后的坐标
 * https://www.zhihu.com/question/67425734/answer/252724399 旋转矩阵公式
 */
export function calculateRotatedPointCoordinate(point, center, rotate) {
	/**
	 * 旋转公式：
	 *  点a(x, y)
	 *  旋转中心c(x, y)
	 *  旋转后点n(x, y)
	 *  旋转角度θ                tan ??
	 * nx = cosθ * (ax - cx) - sinθ * (ay - cy) + cx
	 * ny = sinθ * (ax - cx) + cosθ * (ay - cy) + cy
	 */

	return {
		x:
			(point.x - center.x) * Math.cos(angleToRadian(rotate)) -
			(point.y - center.y) * Math.sin(angleToRadian(rotate)) +
			center.x,
		y:
			(point.x - center.x) * Math.sin(angleToRadian(rotate)) +
			(point.y - center.y) * Math.cos(angleToRadian(rotate)) +
			center.y
	};
}
