<template>
	<div class="template-container">
		<div v-if="!showModule && !showAppinstall" v-loading>
			<div class="template-blank">
				<div class="template-blank-title">新建空白应用</div>
				<div class="template-blank-box">
					<img src="https://openim-1309784708.cos.ap-shanghai.myqcloud.com/4d4666596baf0311d1a612d5df1c7628.png" />
					<div class="template-blank-content">
						<div class="blank-title">新建空白应用</div>
						<div class="blank-content">从零开始，定制属于自己的全新应用</div>
					</div>
					<el-button
						class="template-blank-button"
						@click="hanldeAdd">
						新建
					</el-button>
				</div>
			</div>
			<div
				v-if="listData"
				class="template-body">
				<div class="template-top">
					<div
						v-for="(item, index) in listData"
						:key="index"
						class="template-top-title"
						@click="handleClick(item)"
						:class="[activeName == item.type.name ? 'select-title' : '']">
						{{ item.type.name }}
					</div>
				</div>
				<div class="template-body-main">
					<div
						v-for="(item, listIndex) in list"
						@click="onModulePages(item)"
						:key="listIndex"
						class="template-box">
						<div class="template-box-main">
							<div
								class="iconfont-div"
								:style="'background:' + item.background">
								<svg
									aria-hidden="true"
									class="iconfont-svg">
									<use :xlink:href="item.icon.indexOf('#') === -1 ? '#' + item.icon : item.icon" />
								</svg>
							</div>

							<div class="template-name">
								<div class="template-title">
									{{ item.name }}					
                  <h1 class="title">
                    <span class="cost-type">{{ item?.is_pay === 0 ? '免费' : '付费' }}</span>
                  </h1>
								</div>
								<div class="template-describe">
									{{ item.describe }}
								</div>
							</div>
							<el-progress
								v-if="item.num"
								:width="24"
								:stroke-width="3"
								type="circle"
								:percentage="item.num"
								:show-text="false"></el-progress>
							<el-button
								v-else
								class="template-button"
								@click.stop="getApp(item)">
								{{item.is_pay?'试用' :item.is_install ? '打开' : '安装' }}
							</el-button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<ModulePages
			v-if="showModule"
			:module="module"
			@toInstall="toInstall"></ModulePages>
		<AppInstall
			v-if="showAppinstall"
			:module="installModule"
			@updateMenuList="updateMenuList"
			@getAppInstallStatus="getAppInstallStatus"></AppInstall>
	</div>
</template>

<script>
import ModulePages from './ModulePages.vue';
import { getTempMenuList, appInstallStatus } from '@/api/saas';
import AppInstall from './AppInstall.vue';
// import { installApp } from '@/api/saas'
import { Progress } from 'element-ui';
export default {
	components: {
		'el-progress': Progress,
		ModulePages,
		AppInstall
	},
  props:{
    installId:{
      type: String,
      required: ''
    }
  },
	data() {
		return {
			showAppinstall: false,
			InstallPercentage: 50,
			listData: null, //顶部导航栏
			list: [], //下面具体模板
			activeName: '',
			num: 0,
			app_id: null,
			installAll: false, // 是否可安装
			dialogVisible: false, // 安装弹窗
			module: {},
			installModule: {},
			showModule: false,
			installList: []
		};
	},
	mounted() {
		this.getData();
	},
	methods: {
		/**
		 * 返回最外层页面
		 */
		goHome() {
			this.showModule = false;
			this.showAppinstall = false;
		},
		toInstall() {
			this.getApp(this.module);
		},
		/**
		 * 关闭两个附加页面
		 */
		closePage() {
			this.showAppinstall = false;
			this.showModule = false;
		},
		/**
		 * @desc 新建子模板弹窗
		 */
		hanldeAdd() {
			this.$emit('addChildDialog');
		},
		/**
		 * @desc 监听应用是否已经安装成功
		 */
		getAppInstallStatus() {
			this.installList = JSON.parse(sessionStorage.getItem('appInstallList'));
			if (this.installList && this.installList.length) {
				appInstallStatus({ app_id: this.installList }).then((res) => {
					if (res.data.code !== 200) {
						return;
					}
					let data = res.data.data;
					this.installList.forEach((item, index) => {
						data.forEach((it) => {
							if (item === it.id) {
								if (it.status) {
									this.installList.splice(index, 1);
									sessionStorage.setItem('appInstallList', JSON.stringify(this.installList));
									this.$emit('updateMenuList', true);
                  this.$emit('appChildAdd')
								}
							}
						});
					});
				});
				setTimeout(() => {
					this.getAppInstallStatus();
				}, 5000);
			}
		},
		/**
		 * @desc 获取模板列表
		 */
		getData() {
			getTempMenuList().then((res) => {
				if (res.data.code !== 200) {
					return;
				}
				let data = res.data.data;
				// 找到目前正在安装的数据 已经把安装好的数据给他清除出缓存
				this.installList = JSON.parse(sessionStorage.getItem('appInstallList'));
				if (this.installList && this.installList.length) {
					data.forEach((item) => {
						if (item.list && item.list.length) {
							item.list.forEach((r) => {
                if(+this.installId === r.id){
                  this.getApp(r)
                }
								this.installList.forEach((re, index) => {
									if (r.id === re) {
										this.$set(r, 'num', 60);
										if (r.is_install) {
											this.installList.splice(0, index);
										} else {
											this.$set(r, 'num', 60);
											let timer = setInterval(() => {
												r.num += 0.15;
												if (r.num > 90 || r.num === 100) {
													window.clearInterval(timer);
												}
											}, 100);
										}
									}
								});
							});
						}
					});
					this.getAppInstallStatus();
				}
				// 给出展示数据的默认值
				this.listData = data;
				this.list = res.data.data[0].list;
				this.activeName = res.data.data[0].type.name;
			});
		},
		/**
		 *
		 * @param {*} data
		 */
		handleClick(data) {
			this.list = data.list;
			this.activeName = data.type.name;
		},
		onModulePages(data) {
			if (data) {
				this.module = data;
			}
			this.showAppinstall = false;
			this.showModule = true;

			this.$emit('onChildPage');
			this.$emit('installAppMenu', 1);
		},
		// 点击安装或者打开app
		getApp(item) {
      if(item.is_pay){
        window.location.href = 'https://www.bimcc.com/web-page/apply-demo';
        return
      }
			this.$emit('onChildPage');
			if (item.is_install === 1) {
				// 打开应用
				this.$emit('goApp', item);
			} else {
				this.module = item;
				this.installModule = item;
				this.showAppinstall = true;
				this.showModule = false;
				this.app_id = item.id;
				this.$emit('installAppMenu', 2);
			}
		},
		updateMenuList() {
			this.$emit('updateMenuList', true);
		}
	}
};
</script>

<style lang="less" scoped>
.template-container {
	position: relative;
	width: 100%;
	height: 100%;
	background: #ffffff;
	border-radius: 8px;
	padding: 20px 16px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	.template-blank {
		width: 100%;
		padding-left: 8px;
		.template-blank-title {
			font-size: 14px;
			font-weight: 600;
			color: #2a2f3d;
			margin-bottom: 12px;
		}
		.template-blank-box {
			width: calc(25% - 16px);
			height: 100px;
			border-radius: 12px;
			padding: 20px 24px 0 20px;
			background: #f4f7fe;
			display: flex;
			box-sizing: border-box;
			min-width: 330px;
			.template-blank-content {
				flex: 1;
				padding-left: 17px;
				padding-right: 33px;
				min-width: 140px;
				.blank-title {
					font-size: 14px;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 600;
					color: #2a2f3d;
					line-height: 22px;
					letter-spacing: 0.5px;
				}
				.blank-content {
					font-size: 12px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #616e8f;
					line-height: 18px;
					letter-spacing: 0.4px;
				}
			}
			.template-blank-button {
				width: 56px;
				height: 28px;
				background: #e8f0fe;
				border-radius: 20px;
				color: #337eff;
				border: none;
			}
			> img {
				width: 40px;
				height: 40px;
			}
		}
	}

	.template-body {
		flex: 1;
		display: flex;
		flex-direction: column;
		padding-top: 43px;
		.template-top {
			display: flex;
			.template-top-title {
				padding: 5px 12px;
				border-radius: 6px;
				font-size: 14px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 600;
				color: #616e8f;
				text-align: center;
				line-height: 32px;
				margin-right: 8px;
			}
			.template-top-title:hover {
				background: #f2f4f7;
				border-radius: 6px;
			}
			.select-title {
				color: #2a2f3d;
			}
		}
		.template-body-main {
			display: flex;
			width: 100%;
			flex-wrap: wrap;
			justify-content: flex-start;
			.template-box {
				width: 25%;
				min-width: 330px;
				.template-box-main {
					height: 100px;
					border-radius: 12px;
					padding: 20px 24px 0 20px;
					display: flex;
					border: 1px solid #ebeef5;
					margin: 8px;
					box-sizing: border-box;
					.template-name {
						flex: 1;
						padding-left: 17px;
						padding-right: 33px;
						min-width: 140px;
						.template-title {
							font-size: 14px;
							font-family: PingFangSC-Medium, PingFang SC;
							font-weight: 600;
							color: #2a2f3d;
							line-height: 22px;
							letter-spacing: 0.5px;
              display: flex;
              .title {
                display: flex;
                align-items: center;
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 28px;
                color: #161c1f;

                .cost-type {
                  width: 41px;
                  height: 20px;
                  margin-left: 4px;
                  padding: 1px 8px;
                  box-sizing: border-box;
                  border-radius: 4px;
                  background: #76abff;
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 20px;
                  letter-spacing: 0.4px;
                  color: #ffffff;
                }
              }
						}
						.template-describe {
							max-height: 40px;
							font-size: 12px;
							font-family: PingFangSC-Regular, PingFang SC;
							font-weight: 400;
							color: #616e8f;
							line-height: 18px;
							overflow: hidden;
							text-overflow: ellipsis;
							letter-spacing: 0.4px;
							display: -webkit-box;
							white-space: normal !important;
							word-wrap: break-word;
							-webkit-line-clamp: 2;
							-webkit-box-orient: vertical;
						}
					}
					.template-button {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 56px;
						height: 28px;
						font-size: 12px;
						font-weight: 500;
						color: #337eff;
						background: #f2f4f7;
						border-radius: 16px;
						border: none;
					}
					.template-button:hover {
						background: #e6e9f0;
					}
				}
			}
			.iconfont-div {
				width: 40px;
				height: 40px;
				border-radius: 8px;
				display: flex;
				align-items: center;
				justify-content: center;
				.iconfont-svg {
					width: 30px;
					height: 30px;
				}
			}
		}
	}
}
.install-box {
	height: 400px;
	background: url(https://static.bimcc.com/openim/166547535420953C16D9B364974BCEE9F397F03DF6339.png) no-repeat;
	background-position: center 0;
	background-size: cover;
	font-family: 'Source Sans Pro';

	-webkit-background-size: cover;
	-o-background-size: cover;
	-moz-background-size: cover;

	.content {
		text-align: center;
		transform: translateY(192px);
		.install-num {
			padding-bottom: 50px;
			line-height: 30px;
			font-style: normal;
			font-weight: 600;
			font-size: 26px;
			color: #131314;
		}
		.text {
			padding-bottom: 20px;
			font-size: 30px;
			color: #f4f4f4;
		}
		.btn {
			border: 1px solid #d9d9d9;
			border-radius: 12px;
			width: 190px;
			height: 54px;
			background: #f4f4f4;
			font-size: 20px;
			color: #131314;
			&:hover {
				transform: scale(1.1);
			}
		}
	}
}
</style>
