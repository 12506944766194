export default class SceneEvents{
    constructor(){

    }

    static getEvents(viewer, comp){
        return {
            "ViewerGIS.getCameraView":  (eid, cb)=>{
                if(eid !== comp.element.id) return;

                const state = viewer.Scene.getCameraView();
                cb(Object.assign({}, state));
            },
            'ViewerGIS.setCameraView': (eid, state)=>{
                if(eid !== comp.element.id) return;

                viewer.Scene.setCameraView(Object.assign({}, state));
            },
            'ViewerGIS.flyTo': (eid, destination, range = 100, angle = 90, duration = 0)=>{
                if(eid !== comp.element.id) return;

                viewer.Scene.flyTo(destination, range, angle, duration);
            },
            'ViewerGIS.flyToPoint': (eid, lng, lat, height, duration, angle)=>{
                if(eid !== comp.element.id) return;

                viewer.Scene.flyToPoint(lng, lat, height, duration, angle);
            },
            'ViewerGIS.flyToBound': (eid, destination, angle = 90, duration = 0)=>{
                if(eid !== comp.element.id) return;

                viewer.Scene.flyToBound(destination, angle, duration);
            },
            'ViewerGIS.flyToBoundingSphere': (eid, bound, angle = 90, duration)=>{
                if(eid !== comp.element.id) return;

                viewer.Scene.flyToBoundingSphere(bound, angle, duration);
            },

            'ViewerGIS.getViewPointState': (eid, cb)=>{
                if(eid !== comp.element.id) return;

                viewer.ViewPoint.getState().then((res) => {
                    cb && cb(res);
                });
            },
            'ViewerGIS.setViewPointState': (eid, info = {})=>{
                if(eid !== comp.element.id) return;

                viewer.ViewPoint.setState(info);
            }
        }
    }
}
