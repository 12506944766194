/*
 * @Description: 入口
 * @Author: luocheng
 * @Date: 2021-08-05 13:48:03
 * @LastEditors: zx
 * @LastEditTime: 2022-11-03 18:01:55
 */
import Vue from 'vue';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/plugins/element';
import '@/assets/css/reset.css';
import CommonGridContainer from "@/custom-component/container/CommonGridContainer";
import ComponentBox from '@/components/normalPage/ComponentBox';
import PageParser from '@/components/parser/Index';
import MobileComponentBox from '@/mobile-components/mobilePage/MobileComponentBox';
import MobileSelectTree from '@/mobile-custom-components/selectTree/SelectTree';
import ShapeBox from '@/components/parser/screen/ShapeBox';
import Entry from '@/custom-component/common/Entry';

/* 数仓开始 */
import bModal from '@/components/elModal'
import bSelect from '@/components/largeDataSelect.vue'
import actionFactory from './views/listViewer/actions/actionFactory';
import treeForm from "@/views/objects/components/treeForm.vue";
import globalActionCall from "@/global/actionFactory";
//大量数据的select组件
Vue.component('bSelect', bSelect)
//全局的弹窗和抽屉，便于以后维护
Vue.component('bModal', bModal)
//
Vue.component('treeForm',treeForm)

// 普通行为挂接
Vue.prototype.$doAction = function(name,payload){
  return actionFactory.make(name,payload,this)
}

// 全局行为挂接
Vue.prototype.$globalAction = function(name,data){
  return globalActionCall(this,name,data)
}
/* 数仓结束 */

Vue.component('MobileComponentBox', MobileComponentBox)
Vue.component('MobileSelectTree', MobileSelectTree)
Vue.component('CommonGridContainer', CommonGridContainer);
Vue.component('ComponentBox', ComponentBox);
Vue.component('ShapeBox', ShapeBox);
Vue.component('PageParser', PageParser);
Vue.config.productionTip = false;

import InputItem from "@/custom-component/form/newParser/InputItem";
import PcParser from "@/custom-component/form/newParser/PcParser";
Vue.component('InputItem', InputItem);
Vue.component('PcParser', PcParser);
// 全局注册是因为在流程审批组件存在互相引用，导致递归
Vue.component('Entry', Entry);

// 全局方法
import { getTargetArchi, getToken, getUserInfo } from '@/utils/tools';
Vue.prototype.$GetTargetArchi = getTargetArchi;
Vue.prototype.$GetToken = getToken;
Vue.prototype.$GetUserInfo = getUserInfo;

Vue.prototype.$ELEMENT = { size: 'small', zIndex: 3000 };

// 重写router的push和replace方法 路由守卫的跳转bug
import Router from 'vue-router';

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
	if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
	return originalPush.call(this, location).catch((err) => err);
};

const originalReplace = Router.prototype.replace;
Router.prototype.replace = function replace(location) {
	return originalReplace.call(this, location).catch((err) => err);
};

export default new Vue({
	router,
	store,
	render: (h) => h(App)
}).$mount('#app');
