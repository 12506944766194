<!--
    @name: widget-date-range
    @description：widget-date-range
    @author: ZengWei
    @date: 2022-03-25 09:37
-->
<template>
  <el-date-picker
    v-if="!(disabled || element.config.disabled)"
    v-model="element.value"
    :disabled="disabled || element.config.disabled"
    :type="element.config.type"
    :range-separator="element.config['range-separator']"
    :format="element.config.format"
    :placeholder="element.config.placeholder"
    :value-format="element.config['value-format']"
    style="width: 100%"
  >
  </el-date-picker>
  <p class="reshow-text" v-else>{{ reShowVal || '-' }}</p>
</template>

<script>
import {DatePicker} from "element-ui";
import dayjs from 'dayjs';
import formItemMinix from "@/custom-component/form/newParser/scripts/formItemMinix";

export default {
  name: "WidgetDateRange",
  components: {
    'el-date-picker': DatePicker,
  },
  mixins: [formItemMinix],
  props: ['element', 'disabled', 'displayData'],
  data() {
    return {}
  },
  computed: {
    reShowVal() {
      let res = '';
      if(Array.isArray(this.element.value)) {
        res = this.element.value.map(el => {
          if(el) {
            if(this.element.config.format) {
              let format = this.element.config.format
              format = format.replace('yyyy', 'YYYY')
              format = format.replace('dd', 'DD')
              if(!isNaN(new Date(el).getTime())) {
                return dayjs(new Date(el)).format(format);
              } else {
                return el;
              }
            } else {
              return el;
            }
          } else {
            return '-';
          }
        }).join('-');
      }
      return res || '-';
    } 
  },
  methods: {},
}
</script>

<style scoped>

</style>
