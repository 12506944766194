<!--
 * @Author: zqy
 * @Date: 2022-06-13 16:23:59
 * @LastEditTime: 2022-06-14 15:50:16
 * @Description: 
 * @LastEditors: zqy
-->
<template>
  <div class="tool-bar"> 
    <section class="top-content">
      <div class="left">
        <i class="iconfont iconc-chevron-left" @click="back"></i>
      </div>
      <div class="middle">
        <p class="title">{{title}}</p>
      </div>
      <div class="right">
        
      </div>
    </section>
    <section class="search" v-if="extension">
        <div class="searchInput">
            <div class="placeholder" v-show="!materialSearch">
                <img src="@/assets/images/mobile-select/search.png" alt="" width="24" height="24">
                {{searchString}}
            </div>
            <input v-model="materialSearch" style="background-color: unset;" @keyup.enter="searchData">
            <img class="search-icon" v-show="materialSearch" @click="searchData" src="@/assets/images/mobile-select/search.png" alt="" width="24" height="24">
        </div>
    </section>
  </div>
</template>
<script>
import { defineComponent, reactive, toRefs, watch } from 'vue'

export default defineComponent({
  props:{
    title:{
      type:String,
      default:'标题'
    },
    searchString:{
      type:String,
      default:'搜索'
    },
    extension:{
      type:Boolean,
      default:true
    }
  },
  emits:['back'],
  setup(props,ctx) {
    const state = reactive({
      materialSearch:'',
    })
    watch(state, (oldVal, newVal) => {
        if(!newVal.materialSearch){
            ctx.emit('search',newVal.materialSearch)
        }
      }, {
      deep: true
    })
    const searchData =()=>{
      ctx.emit('search',state.materialSearch)
    }
    const back = ()=>{
      ctx.emit('back')
    }
    return {
      ...toRefs(state),...props,
      back,
      searchData
    }
  }
})
</script>
<style lang="less" scoped>
.tool-bar{
  width: 100%;
  background: rgb(242, 244, 245);
  .top-content{
    width: 100%;
    height: 44px;
    padding: 0 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    .left{
      text-align: left;
      i{
        font-size: 24px;
        font-weight: 500;
      }
    }
    .middle{
      flex: 1;
      text-align: center;
      .title{
        font-size: 17px;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .rigth{
      min-width: 20%;
    }
  }
}
.search{
  width: 100%;
  height: 45px;
  background-color: #f2f4f5
}
.searchInput{
    width: calc(100% - 18px);
    margin-left:9px;
    height:36px;
    background-color: white;
    border-radius: 6px;
    border:1px solid rgba(114,126,143,0.2);
    position: relative;
}
.searchInput div{
    position:absolute;
    left: 0;
    z-index: 1;
    height: 100%;
    width: calc(100% - 20px);
    text-align: center;
    font-size:17px;
    font-weight:400;
    line-height: 38px;
    color:rgba(125,129,138,1);
}
.searchInput div img{
    vertical-align: middle;
    width: 24px;
    margin-top:-3px;
}
.searchInput input{
    position: absolute;
    left: 0;
    z-index: 2;
    margin-left:10px;
    font-size:17px;
    font-weight:400;
    height: calc(100% - 2px);;
    width: calc(100% - 20px);
    border: 0;
}
.search-icon{
    position:absolute;
    right:5px;
    top:5px;
    z-index:10;
}
</style>
