<!--
 * @Description: 全屏按钮
 * @Author: luocheng
 * @Date: 2022-04-29 10:27:27
 * @LastEditors: luocheng
 * @LastEditTime: 2022-05-23 11:14:19
-->
<template>
  <div class="full-screen-container">
    <i :class="['iconfont', isFullScreen ? 'icondaping-feiquanping' : 'icondaping-quanping']" @click="handleScreenChange" :style="style"></i>
  </div>
</template>
<script>
/**
 * @description 全屏按钮
 */
import Minin from './mixin'
export default {
  name: 'FullScreenCom',
  mixins: [Minin],
  data () {
    return {
      isFullScreen: false
    }
  },
  computed: {
    style() {
      return {
        fontSize: this.fontSize,
        textAlign: this.textAlign || '',
        lineHeight: this.lineHeight
      };
    }
  },
  methods: {
    // 全屏事件
    handleScreenChange() {
      let main = document.body;
      if (this.isFullScreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (main.requestFullscreen) {
          main.requestFullscreen();
        } else if (main.mozRequestFullScreen) {
          main.mozRequestFullScreen();
        } else if (main.webkitRequestFullScreen) {
          main.webkitRequestFullScreen();
        } else if (main.msRequestFullscreen) {
          main.msRequestFullscreen();
        }
      }
      this.isFullScreen = !this.isFullScreen;
    }
  }
}
</script>
<style lang="less" scoped>
  .full-screen-container{
    width: 100%;
    height: 100%;
  }
</style>