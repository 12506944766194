/*
 * @Description: saas系统管理中心保存数据
 * @Author: zx
 * @Date: 2021-08-10 16:45:55
 * @LastEditors: yl 1871883713@qq.com
 * @LastEditTime: 2024-06-13 09:28:16
 */
import { deepCopy } from '@/utils/tools';

export default {
	state: {
		saasCurrentMenu: sessionStorage.getItem('saasCurrentMenu') ? JSON.parse(sessionStorage.getItem('saasCurrentMenu')) : {},
		systemStyle: localStorage.getItem('system_style') ? +localStorage.getItem('system_style') : 1,
		saasTargetArchi: localStorage.getItem('saasTargetArchi') ? JSON.parse(localStorage.getItem('saasTargetArchi')) : {},
		saasVersion: 2,
		indexCurrentTab: sessionStorage.getItem("indexCurrentTab") ? +sessionStorage.getItem("indexCurrentTab") : 0,
		manageItem: sessionStorage.getItem("manageItem") ? +sessionStorage.getItem("manageItem") : null,
		stystemItem: sessionStorage.getItem("stystemItem") ? JSON.parse(sessionStorage.getItem("stystemItem")) : null,
		appItem: sessionStorage.getItem("appItem") ? JSON.parse(sessionStorage.getItem("appItem")) : null,
		childAppItem: sessionStorage.getItem("childAppItem") ? JSON.parse(sessionStorage.getItem("childAppItem")) : null,
		blueLabel: sessionStorage.getItem("blueLabel") ? +sessionStorage.getItem("blueLabel") : 1,
		darkLabel: sessionStorage.getItem("darkLabel") ? +sessionStorage.getItem("darkLabel") : 1,
		loginVersion:'V1.0',
	},
	mutations: {
		/**	
     * @desc 当前架构版本
     * @param {*} state 
     * @param {*} data 
     */
		setLoginVersion(state, data) {
			state.loginVersion = data;
		},
		/**
		 * @desc: 保存当前菜单数据
		 */
		setCurrentMenus(state, data) {
			if (Array.isArray(data) && data.length) {
				sessionStorage.setItem('saasCurrentMenu', JSON.stringify(data[data.length - 1]))
				state.saasCurrentMenu = deepCopy(data[data.length - 1])
			} else {
				sessionStorage.setItem('saasCurrentMenu', JSON.stringify(data))
				state.saasCurrentMenu = deepCopy(data)
			}
		},
		/**
		 * @desc: 设置当前样式
		 */
		setSystemStyle(state, data) {
			state.systemStyle = data
      localStorage.setItem('system_style', data)
		},
		/**
		 * @desc: 设置当前架构选中
		 */
		setSaasTargetArchi(state, data) {
			state.saasTargetArchi = data
      localStorage.setItem('saasTargetArchi', JSON.stringify(data))
		},
		/**
		 * @desc: 设置当前版本 企业版  个人版
		 */
		setSaasVersion(state, data) {
			state.saasVersion = +data
		},
		/**
		 * @desc: 设置官网当前tab
		 */
		setIndexCurrentTab(state, data) {
			state.indexCurrentTab = +data
      sessionStorage.setItem("indexCurrentTab", +data);
		},
		/**
		 * @desc: 记录企业管理历史选择功能
		 */
		setManageItem(state, data) {
			state.manageItem = data
			sessionStorage.setItem("manageItem", JSON.stringify(data));
		},
		/**
		 * @desc: 记录企业管理历史选择功能
		 */
		setStystemItem(state, data) {
			state.stystemItem = data
			sessionStorage.setItem("stystemItem", JSON.stringify(data));
		},
		/**
		 * @desc: 记录选择的应用
		 */
		setAppItem(state, data) {
			state.appItem = data
			sessionStorage.setItem("appItem", JSON.stringify(data));
		},
		/**
		 * @desc: 记录选择的应用下的功能
		 */
		setChildAppItem(state, data) {
			state.childAppItem = data
			sessionStorage.setItem("childAppItem", JSON.stringify(data));
		},
		/**
		 * @desc: 记录纯蓝风格文字显示
		 */
		setBlueLabel(state, data) {
			state.blueLabel = +data
			sessionStorage.setItem("blueLabel", +data);
		},
		/**
		 * @desc: 记录暗黑风格文字显示
		 */
		setDarkLabel(state, data) {
			state.darkLabel = +data
			sessionStorage.setItem("darkLabel", +data);
		},
	}
};
