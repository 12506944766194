<!--
 * @Description: 表格视图
 * @Author: luocheng
 * @Date: 2022-03-02 10:21:21
 * @LastEditors: luocheng
 * @LastEditTime: 2022-09-23 16:05:07
-->
<template>
  <div class="table-view" ref="tableBox" id="table-view">
    <el-table
      ref="tableRef"
      class="table"
      :data="tableData"
      :header-cell-style="{background:'#f5f5f5',border:'none'}"
      :height="tableHeight"
      @selection-change="onSelectionChange"
      highlight-current-row
      @row-click="onTabRow"
    >
      <el-table-column
        v-if="statusConfig.batchAction"
        type="selection"
        width="55">
      </el-table-column>
      <el-table-column
        prop="name"
        label="文件名">
        <section class="name-slot" slot="header">
          <span class="name">
            <template v-if="!selectList || !selectList.length">文件名</template>
            <template v-else>已选{{ selectList.length }}项</template>
          </span>
          <ul class="header-actions" v-if="selectList && selectList.length">
            <!-- <li class="action-item" @click="onBatchAction('download')">下载</li> -->
            <li class="action-item" @click="onBatchAction('move')">移动</li>
            <li class="action-item" @click="onBatchAction('copy')">复制</li>
            <li class="action-item delete" @click="onBatchAction('delete')">删除</li>
          </ul>
        </section>
        <template slot-scope="{ row }">
          <div class="custom-file" style="cursor: pointer">
            <i class="iconfont" :class="[getClass(row)]"></i>
            <span style="font-size: 14px;line-height:20px;">{{ row.name }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="created_at"
        label="创建时间"
        width="180">
      </el-table-column>
      <el-table-column
        prop="file_size"
        label="大小"
        width="100">
        <template slot-scope="{ row }">
          {{ row.file_size | filterSize }}
        </template>
      </el-table-column>
      <el-table-column
        prop="user_name"
        label="创建人"
        width="100">
      </el-table-column>
      <el-table-column
        v-if="statusConfig.showActions"
        prop="name"
        label="操作"
        width="80">
        <template slot-scope="{ row }">
          <ActionMenu :rowData="row" @action="onAction"></ActionMenu>
        </template>
      </el-table-column>
    </el-table>
    <!-- 重命名 -->
    <Rename v-if="currenData && showRename" v-model="showRename"
      :fileName="currenData && currenData.name"
      @nameResult="onRename"
    >
    </Rename>
    <!-- 移动文件 -->
    <!-- 操作文件(批量) -->
    <OperationFile
      v-if="showBatchOperation"
      v-model="showBatchOperation"
      :type="batchAction"
      :movedData="selectList"
      @updateList="onBatchUpdate"
    >
    </OperationFile>
  </div>
</template>

<script>
import ActionMenu from './ActionMenu';
import Rename from './Rename';
import OperationFile from './OperationFile';
import { dataInterface } from '@/apis/data';
import { downloadFileBlob } from '@/utils/tools';

export default {
  name: 'TableView',
  props: {
    tableData: { // 列表数据
      type: Array,
      required: true,
      default: () => []
    }
  },
  components: {
    ActionMenu,
    Rename,
    OperationFile
  },
  data() {
    return {
      tableHeight: 800,
      // 当前操作行
      currenData: {},
      // 重命名
      showRename: false,
      // 选中
      selectList: [],
      // 批量操作
      showBatchOperation: false,
      batchAction: 'move'
    }
  },
  inject: ['element'],
  computed: {
    actionConfig() {
      return this.element?.actionConfig;
    },
    statusConfig() {
      return this.element?.statusConfig;
    },
    database() {
      return this.element?.database;
    }
  },
  filters: {
    filterSize(size) {
      if (!size) return '-';
      return `${(size/ 1024 /1024).toFixed(2)} M`;
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.resizeHeight();
    })
    window.onresize = () => {
      this.resizeHeight();
    }
  },
  methods: {
    /**
     * @desc: 点击行
     * @param {Object} row 行数据
     */
    onTabRow(row) {
      this.$emit('selectFile', row);
    },
    // 文件操作
    async onAction(data) {
      const { type, rowData } = data;
      this.currenData = rowData;
      if (!type || !rowData) {
        this.$message.error('出错了，请重试!');
        return false;
      }
      if (type === 'delete') {
        // 删除
        this.doDelete();
      } else if (type === 'modify') {
        // 编辑文件重命名
        this.showRename = true;
      } else if (type === 'move') {
        // 移动
        this.selectList = [rowData];
        this.batchAction = 'move';
        this.showBatchOperation = true;
      } else if (type === 'download') {
        // 下载
        let url = rowData.path || rowData.url;
        if (!url) {
          this.$message.error('文件地址错误!');
          return;
        }
        if (url.indexOf('http') === -1) {
          url = process.env.VUE_APP_BASE_URL + url
        }
        const loading = this.$loading();
        await downloadFileBlob(url, rowData.name)
        loading.close();
      } else {
        this.$message.warning('功能开发中...');
      }
    },
    /**
     * @desc: 重命名
     * @param {String} name 新名成
     */
    onRename(name) {
      this.modifyData({
        __method_name__: 'updateData',
        name,
        data_id: this.currenData.id
      });
    },
    /**
     * @desc: 删除
     */
    doDelete(deleteIds = []) {
      const confirmStr = Array.isArray(deleteIds) && deleteIds.length ? '是否确认删除选中文件、文件夹及所有下级?' : '';
      this.$confirm(confirmStr || `是否确认删除【${this.currenData.name}】?`).then(() => {
        this.modifyData({
          __method_name__: 'deleteData',
          data_id: Array.isArray(deleteIds) && deleteIds.length ? deleteIds : this.currenData.id
        })
      }).catch(() => {})
    },
    /**
     * @desc: 图表类名
     * @param {Object} row 行数据
     */
    getClass(row) {
      const { type_id, ext } = row;
      if (+type_id === 1) return 'iconwenjianjia';
      if (ext === 'xlsx' || ext === 'xls') {
        return 'iconexcle1x'
      } else if (ext === 'doc' || ext === 'docx') {
        return 'iconword1x'
      } else if (ext === 'txt') {
        return 'icontxt1x'
      } else if (ext === 'pptx' || ext === 'ppt') {
        return 'iconppt1x'
      } else if (['bmp', 'jpg', 'png', 'gif', 'jpeg', 'cdr', 'psd'].includes(ext)) {
        return 'iconzhaopian1x'
      } else if (ext === 'pdf') {
        return 'iconpdf1x'
      }
      return 'iconother1x'
    },
    /**
     * @desc: 选择表格
     */
    onSelectionChange(val) {
      this.selectList = val;
    },
    /**
     * @desc: 计算表格高度
     */
    resizeHeight() {
      const tableBox = document.querySelector('#table-view');
      if (tableBox) {
        this.tableHeight = tableBox.offsetHeight || '100%';
      }
    },
    /**
     * @desc: 更新列表
     */
    updateList() {
      this.$emit('updateList');
      this.currenData = null;
    },
    /**
     * @desc: 创建数据或编辑数据
     * @param {Object} params 参数
     */
    modifyData(params) {
      const { objectData = {} } = this.database;
      if (!objectData) {
        this.$message.error('操作失败！');
        return;
      }
      const loading = this.$loading();
      dataInterface({
        ...params,
        object_uuid: objectData.uuid
      }).then(res => {
        loading.close();
        if (res.status === 200 && res.data.code === 200) {
          this.$message.success('操作成功！');
          this.showRename = false;
          this.$emit('updateList');
        }
      }).catch(err => {
        console.log(err);
        this.$message.error('操作失败！');
        loading.close();
      })
    },
    /**
     * @desc: 批量操作
     * @param {String} type 操作类型
     */
    onBatchAction(type) {
      this.batchAction = type;
      if (type === 'download') {
        // 下载
      } else if (type === 'move') {
        // 移动
        this.showBatchOperation = true;
      } else if (type === 'copy') {
        // 复制
        this.showBatchOperation = true;
      } else if (type === 'delete') {
        // 删除
        this.doDelete(this.selectList.map(ele => ele.id));
      }
    },
    /**
     * @desc: 批量操作操作回调
     */
    onBatchUpdate() {
      this.selectList = [];
      this.updateList();
    }
  }
}
</script>

<style lang="less" scoped>
.table-view{
  height: 100%;
  width: 100%;
  overflow: hidden;
  :deep(.el-table) { 
    td.el-table__cell, .el-table th.el-table__cell.is-leaf{
      border: none;
    }
    &.el-table--small tbody .el-table__cell{
      height: 52px;
    }
    .name-slot {
      display: flex;
      .header-actions {
        flex: 1;
        display: flex;
        box-sizing: border-box;
        padding-left: 12px;
        .action-item {
          padding: 0 12px;
          color: @theme;
          &.delete {
            color: @dangerColor;
          }
        }
      }
    }
    .custom-file{
      display: flex;
      span{
        line-height: 20px;
        font-size: 14px;
      }
    }
    .iconfont{
      margin-right: 5px;
      font-size: 20px;
      line-height: 20px;
    }
    .iconwenjianjia {
      color: rgb(249, 194, 10)
    }
    .iconexcle1x {
      color: rgb(48, 165, 92);
    }
    .iconword1x {
      color: rgb(45, 133, 254);
    }
    .icontxt1x {
      color: rgb(45, 133, 254);
    }
    .iconzhaopian1x{
      color: rgb(44, 162, 92);
    }
    .iconpdf1x{
      color: rgb(226, 52, 45);
    }
    .iconppt1x{
      color: rgb(246, 123, 30);
    }
    .iconother1x{
      color: rgb(171, 189, 204);
    }
  }
}
</style>