<template>
    <div class="toolbar-main">
        <div class="toolbar-body" v-show="isInit && isShow">
            <div class="toolbar-button" v-for="(item , key ) in btns" :key="key" v-show="hideIds.indexOf(item.id) == -1">
                <el-tooltip :content="item.name" placement="top">
                    <div class="toolbar-icon " @click="onClick(item)">
                        <i class="icon-color" :class="item.icon" :style="{ color : item.needActive && item.active ? '#409eff' : '#000' }"></i>
                    </div>
                </el-tooltip>
            </div>
        </div>
    </div>
</template>

<script>
    import { Tooltip } from "element-ui";
    export default {
        name: "BIMViewerToolbar",
        data (){
            return {
                btns : {},
                hideIds : [],
                isInit : false,
                isShow : true,
            }
        },
        components: {
            "el-tooltip" : Tooltip,
        },
        methods:{
            onClick( item ){
                let active = null;
                if(this.btns[item.id].needActive) {
                    this.btns[item.id].active = !this.btns[item.id].active;
                    active = this.btns[item.id].active;
                }

                item.func && item.func( active );
            },
            refresh( data , show = true  ){
                const map = {};

                for (let id in data){
                    const item = data[id];
                    map[id] = {
                        id : item.id,
                        icon : item.icon,
                        name : item.name,
                        func : item.func,
                        needActive : !!item.needActive,
                        active : false,
                    }
                }

                this.$set(this , 'btns' , map);
                this.isInit = true;
                this.isShow = show;
                this.$forceUpdate();
            },
            display( bool ){
                this.isShow = bool;
                this.$forceUpdate();
            },
            setHideIds(ids){
                this.hideIds = ids;
                this.$forceUpdate();
            },
            add( id , opt ){
                this.btns[id] = {
                    id , 
                    icon : opt.icon,
                    name : opt.name,
                    func : opt.func,
                    needActive : !!opt.needActive,
                    active : false,
                }
                this.$forceUpdate();
            },
            delete(id){
                if(this.btns[id]){
                    delete this.btns[id];
                    this.$forceUpdate();
                }
            }
        },
        mounted(){
        }
    }
</script>

<style scoped>
    .toolbar-main{
        width: 100%;
        height: 80px;
        position: absolute;
        bottom: 0;
        left : 0;
        display : flex;
        justify-content: center;
    }
    .toolbar-body{
        height: 35px;
        background-color: white;
        z-index : 100;
        border-radius: 5px;
        padding: 5px;
        display: flex;
    }
    .toolbar-button{
        width: 35px;
        height: 35px;
        padding-right: 5px;
        padding-left: 5px;
    }
    .toolbar-icon{
        width: 100%;
        height: 100%;
        background-color: #ffffff;
        border-radius: 5px;
        cursor: pointer;
    }
    .icon-color{
        font-size: 20px;
        line-height: 35px;
    }
    .toolbar-icon:hover{
        background-color: #cbcbcb;
    }
</style>