/*
 * @Author: 吴绍鹏 542278473@qq.com
 * @Date: 2022-11-09 14:56:41
 * @LastEditors: 姚铃 1871883713@qq.com
 * @LastEditTime: 2023-02-16 14:15:53
 * @FilePath: \nanhu-micro\src\views\knowledge\question\components\smartRobot\MessageRender.js
 * @Description: 智能机器人聊天界面
 */
const listRender = (h, list, ctx) => {
  const onClickHandle = (row) => {
    ctx.$emit('message', 'hotItem', row)
  }
  return h('div', { class: 'list-warp' }, [
    h('p', { class: 'name' }, '猜你想问'),
    h('ul', { class: 'list-ul' }, list.map(el => {
      return h('li', { class: 'list-item', on: { click: () => { onClickHandle(el) }}}, [ el.name,h('i', { class: 'point iconfont iconlujingjiantou' }, '')])
    }))
  ])
}

const AnswerRender = (h, message) => {
  console.log(message)
  return h('div', { class: 'answer-warp' }, [
    // h('p', { class: 'answer' }, [message.content])
    h('p', { class: 'answer' }, 
      !message.img ? message.content : [
        message.content, h('img', 
        { 
          class: 'answer-img', 
          attrs: {
            src: message.img
          } 
        })
      ])
  ])
}

const GeneralMessages = (h, message) => {
  return h('span', null, message.content)
}

const MessageRender = {
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  render(h) {
    switch (this.data.type) {
      case 'generalMessages': {
        return GeneralMessages(h,this.data)
      }
      case 'hotList': {
        return listRender(h, this.data.content, this)
      }
      case 'answer': {
        return AnswerRender(h,this.data)
      }
    }
  }
}

export default MessageRender
