<!--
    @name: widget-treeSelect
    @description：widget-treeSelect
    @author: ZengWei
    @date: 2022-03-25 09:43
-->
<template>
  <div>
    <span style="display: none">{{ treeSelectLabel }}</span>
    <el-select
      v-if="!disabled"
      ref="select"
      v-model="treeSelectValue"
      placeholder="请选择"
      :multiple="element.config.props.props.multiple"
      collapse-tags
      :disabled="disabled"
      @visible-change="visibleChange"
    >
      <el-option :value="element.value" class="tree-select-option is-current">
        <el-tree
          ref="treeSelect"
          :data="element.config.options"
          :default-expand-all="element.config.__config__?.expand || false"
          :show-checkbox="element.config.props.props.multiple"
          node-key="id"
          highlight-current
          :expand-on-click-node="false"
          @node-click="treeSelectClick"
          @check-change="handleCheckChange"
        ></el-tree>
      </el-option>
    </el-select>
    <p class="reshow-text" v-else>{{ treeSelectLabel || '-' }}</p>
  </div>
</template>

<script>
/* eslint-disable */
import {Tree} from "element-ui";
import formItemMinix from "@/custom-component/form/newParser/scripts/formItemMinix";
import {getSearchData, optionData} from "@/custom-component/form/newParser/scripts/tools";
import {formRequest} from "@/apis/data/form";
import eventBus from "@/plugins/eventBus";

export default {
  name: "WidgetTreeSelect",
  components: {
    'el-tree': Tree
  },
  props: ['element', 'disabled', 'displayData'],
  mixins: [formItemMinix],
  data() {
    return {
      treeSelectValue: '',
    }
  },
  computed: {
    treeSelectLabel() {
      const value = this.element.value;
      const treeData = this.element.config.options;
      let labelData;
      value instanceof Array ? labelData = [] : labelData = '';

      function getTree(data) {
        for (let item of data) {
          if (value instanceof Array && value.includes(item.id)) {
            labelData.push(item.label)
          } else if (value == item.id) {
            labelData = item.label
          }
          if (item.children && item.children.length > 0) {
            getTree(item.children)
          }
        }
      }

      getTree(treeData);
      this.treeSelectValue = labelData
      return this.treeSelectValue
    },
  },
  created() {
    this.initDynamicData();
    const cascadeFormId = this.element.config.__config__?.cascadeFormId || null
    if(cascadeFormId){
      this.$emit('trigger-active', cascadeFormId, 'cascader', this.element.value)
    }
  },
  methods: {
    initDynamicData(){
      const filterRule = this.element.config.__config__.filterRule ?? []
      const objectUuid = this.element.config.__config__.objectUuid
      const viewUuid = this.element.config.__config__.viewUuid

      let url= '/api/mapi', params;
      let searchData = getSearchData(filterRule,this.parser.getFormIdValue);
      params = {
        __method_name__: 'dataList', object_uuid: objectUuid,
        view_uuid: viewUuid, search: searchData, size: 1000,
        __now_archi_type: this.parser.nowArchiType,
      }
      if(searchData.length) params.search = searchData

      if(objectUuid && viewUuid){
        formRequest('post', url, params).then(res => {
          let respData;
          respData = res.data.data;
          if (res.data.data.data) {
            respData = res.data.data.data;
          }

          const tag = this.element.config.__config__.tagIcon;
          const propLabel = this.element.config.props.props.label;
          const propValue = this.element.config.props.props.value;
          const propChild = this.element.config.props.props.children || [];
          const options = optionData(respData,tag,propLabel,propValue,propChild)
          if(options){
            this.element.config.options = options
          }
          this.loading = false
        });
      }
    },
    visibleChange(e) {
      if (e) {
        let selectDom = document.querySelector('.is-current')
        setTimeout(() => {
          this.$refs.select.scrollToOption({$el: selectDom})
        }, 0)
      }
    },
    treeSelectClick(data) {
      if (!this.element.config.props.props.multiple) {
        eventBus.$emit('TRIGGER_treeSelectChange',this.element.config.__config__.formId)
        // this.treeSelectValue = data.label
        this.element.value = data.id.toString()
        // treeSelect 单选事件填充
        this.$emit('trigger-active', [], 'selectFill', data.allField)
        // treeSelect 部位参数
        const cascadeFormId = this.element.config.__config__?.cascadeFormId || null
        if(cascadeFormId){
          this.$emit('trigger-active', cascadeFormId, 'cascader', this.element.value)
        }
      }
    },
    handleCheckChange() {
      let res = this.$refs.treeSelect.getCheckedNodes(true, true);
      let arr = [];
      res.forEach(item => {
        arr.push(item.id)
      });
      this.element.value = arr;
    },
  },
}
</script>

<style lang="less" scoped>
.tree-select-option {
  height: auto;
  background: #ffffff !important;

  :deep(.el-tree) {
    padding-bottom: 15px;

    .el-tree-node__label {
      font-weight: normal;
    }
  }
}
</style>
