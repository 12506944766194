import Utils from "../Utils";

export default class Viewer {
    constructor( rootDom, props = {}){
        this.rootDom = rootDom;
        this.setting = props;

        this.viewer = null;
    }

    getGlobal(){
        return window;
    }

    async initResource(){
        const host = 'https://models.bimcc.net/';

        const loadUrl = [
            `${host}javascripts/Cesium/Cesium.js`,
            `${host}javascripts/Cesium/Widgets/widgets.css`,
            `${host}javascripts/viewer/viewerGISBase.js`, //todo 切换线上
            // `http://localhost:8080/files/viewerGISBase.js`, //todo 切换本地测试
        ];

        Utils.checkLoadedUrl( [...loadUrl] ,{
            onLoaded : ( url )=>{
                if( loadUrl.indexOf(url) !== -1){
                    loadUrl.splice( loadUrl.indexOf(url) , 1)
                }
            }
        });

        Utils.appendListInHead( loadUrl );
    }

    async init(){
        const global = this.getGlobal();

        Utils.log('开始进行初始化');

        await this.initResource();

        await Utils.waitLoaded(()=>{
            if(global.BIMCC_GIS_Base && global.Cesium){
                return true
            }else{
                return false
            }
        });

        Utils.log('初始化资源完成...');

        const viewer = this.viewer = new global.BIMCC_GIS_Base.Viewer(this.rootDom.id, this.setting);
        await viewer.init();

        Utils.log('创建对象完成...');

        return viewer;
    }
}
