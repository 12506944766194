/*
 * @Author: 朱廷果 1028509503@qq.com
 * @Date: 2023-01-04 11:30:51
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2023-01-04 15:09:03
 * @FilePath: \dataview-next\src\custom-component\functionCom\mixin.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { mapState } from 'vuex';
import { removeEventBus } from '@/utils/tools';

export default {
  props: {
    // 绑定的值
    element: {
      type: Object,
      default: () => { },
      required: true
    },
    // 是否在组合内
    isGroup: {
      type: Boolean,
    },
    // 组合内组件列表
    groupComponents: {
      type: Array,
      default: () => []
    }
  },
  inject: ['EDITOR_pageUUID'],
  computed: {
    ...mapState(['componentData', '_PageCustomStatus', '_APPCustomStatus']),
    statusConfig() {
      return this.element.statusConfig || {}
    },
    fontSize() {
      return this.statusConfig.fontSize || '16px'
    },
    textAlign() {
      return this.statusConfig.textAlign || 'center'
    },
    lineHeight() {
      return this.statusConfig.lineHeight || '32px'
    },
    isPreview() {
      return this.statusConfig.isPreview
    },
    src() {
      return this.statusConfig.src || ''
    },
  },
  beforeDestroy() {
    if (!this.EDITOR_pageUUID) {
      removeEventBus(['databaseTrigger'], this.element.id);
    }
  }
}
