<!--
 * @Author: zx
 * @Date: 2022-11-01 17:03:50
 * @LastEditTime: 2023-01-03 16:58:58
 * @Description: 
 * @LastEditors: 朱廷果 1028509503@qq.com
-->
<template>
  <div class="guide">
    <!-- 有权限 -->
    <div v-if="checkAuth()" class="guide-container">
      <div class="guide-img">
        <img src="https://static.bimcc.com/openim/166729471325242497E26640CD10A5B3E4C570DDAD242.png" alt="">
      </div>
      <div class="guide-button">
        <div class="guide-button-create" @click="openAddChild()">
          <div>
            <i class="iconfont icona-xinzengS"></i>
          </div>
          <p>
            <span>创建</span>
            <span>快速创建下级功能或分组</span>
          </p>
        </div>
        <div class="guide-button-relate" :class="!appInfo?.children?.length ? 'no-relation' : ''" @click="openRelate">
          <div>
            <i class="iconfont icona-5-32lianjieguanlian"></i>
          </div>
          <p>
            <span>关联页面</span>
            <span>选择一个功能页面显示在这里</span>
          </p>
        </div>
      </div>
    </div>
    <!-- 无权限 -->
    <div v-else class="guide-container">
      <div class="guide-img">
        <img src="https://static.bimcc.com/openim/1668156034062B71813F3684C914BED853B4DF54B8482.png" alt="">
        <div>
          抱歉，当前账号没有创建下级功能和分组的权限
        </div>
      </div>
      <!-- <div class="no-auth">
        <div class="no-auth-container">
          <i class="iconfont iconxiaoxikuang"></i>
          <span>联系管理员</span>
        </div>
      </div> -->
    </div>
    <el-dialog
      title="关联页面（仅支持添加功能）"
      :visible.sync="relateVisible"
      width="480px"
      append-to-body
    >
      <div v-if="relateVisible" class="dialog-body">
        <div class="content-left-search">
          <el-input v-model="searchValue" placeholder="搜索" />
        </div>
        <div class="content-tree">
          <el-tree
            ref="treeRef"
            :data="appInfo.children"
            default-expand-all
            node-key="id"
            :filter-node-method="filterNode"
            :props="{
              label: 'name',
              children: 'children',
            }"
            :check-strictly="true"
            style="height: 100%"
            @node-click="treeSelectClick"
          >
            <template #default="{ node, data }">
              <div class="archi-label-container"
                :style="+node.data.menu_type === 6 && childCanChoose(node.data) ? 'height: 70px' : ''"
                :class="selectRow && (+selectRow.id === +node.data.id) ? 'selected' : ''"
              >
                <div class="archi-label-body">
                  <div class="archi-label-svg">
                    <svg v-if="+node.data.menu_type === 6" aria-hidden="true" class="iconfont-svg">
                      <use xlink:href="#iconwenjianjia2" />
                    </svg>
                    <div v-else-if="data.icon && data.background" class="header-img">
                      <svg aria-hidden="true" class="iconfont-svg">
                        <use :xlink:href="data.icon.indexOf('#')===-1? '#' + data.icon : data.icon" />
                      </svg>
                    </div>
                    <div v-else class="header-img" style="background: transparent">
                      <i 
                        class="iconfont" 
                        :class="data.icon? data.icon : 'icon-a-5-1zhiliang'"
                        :style="'color:' + (['#E06363', '#F59A31','#2CB8A4','#4B98E8','#9F6BF5'].indexOf(data.color) === -1 ? '#E06363' : data.color)"
                      >
                      </i>
                    </div>
                    <div class="archi-label-txt">
                      {{ node.data.name }}
                    </div>
                  </div>
                  <div class="archi-label-click" v-if="selectRow && (+selectRow.id === +node.data.id)">
                      <i class="iconfont iconselect-duigou"></i>
                  </div>
                </div>
                <div v-if="+node.data.menu_type === 6 && childCanChoose(node.data)" class="empty-container">
                  该分组下无可选功能
                </div>
              </div>
            </template>
          </el-tree>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="relateVisible = false">取 消</el-button>
        <el-button :loading="submitLoading" :disabled="!selectRow" type="primary" @click="editSubmit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import { Tree } from "element-ui";
  import { editApps } from '@/api/myApps';
  import { mapState } from 'vuex';
  export default {
    components:{
		'el-tree': Tree,
    },
    props:{
      appInfo: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        relateVisible: false,
        submitLoading: false,
        searchValue: '',
        selectRow: null
      }
    },
    watch: {
      searchValue(val) {
        if (val) {
          this.$refs.treeRef.filter(val)
        } else {
          this.$refs.treeRef.filter('')
        }
      }
    },
    computed: {
      ...mapState(['userInfo']),
    },
    methods:{
      openAddChild() {
        this.$emit('openAddChild', this.appInfo)
      },
      openRelate() {
        if (!this.appInfo?.children?.length) {
          this.$message.warning(this.appInfo?.menu_type !== 6 ? '当前应用下无可选功能！' : '当前分组下无可选功能！')
          return
        }
        this.selectRow = null
        this.relateVisible = true
      },
      editSubmit() {
        this.submitLoading = true
        const { id, remarks, icon, name, background, color, visible_range, range_id  } = this.appInfo
        const params = {
          remarks,
          icon,
          name,
          background: background || '#ffebdb',
          color: color || '#fb9337',
          menu_id: id,
          visible_range,
          range_id,
        }
        params.relation_id = this.selectRow.id
        editApps(params).then((res) => {
          if (res.status === 200 && res.data.code === 200) {
            this.$message.success('编辑成功！')
            this.relateVisible = false
            this.$emit('updateMenuList', true)
          }
        }).catch(() => {
            this.dialogVisible = false
        })
      },
      treeSelectClick(row) {
        if (row && +row.menu_type !== 6) {
          this.selectRow = row
        }
      },
      /* 子级是否有可选对象 true为没有可选子级 */
      childCanChoose(data) {
        let boo = true
        const loopTree = (arr) => {
          arr.forEach(element => {
            if (element?.menu_type && element.menu_type !== 6) {
              boo = false
            } else if (element?.children?.length) {
              loopTree(element.children)
            }
          });
        }
        if (data?.children?.length) {
          loopTree(data.children)
        }
        return boo
      },
      /* tree组件的过滤规则 */
      filterNode(value, data){
        if (!value) return true
        return data.name.indexOf(value) !== -1
      },
      /* 判断是否是自己新增的数据 */
      checkAuth() {
        if (+this.appInfo.create_user_id === +this.userInfo.id) {
          return true
        } 
        return false
      },
    }
  }
</script>
<style lang='less' scoped>
@import "../../../../css/manageAdd.less";
  .guide {
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    box-sizing: border-box;
    .guide-container {
      width: 400px;
      height: 544px;
      display: flex;
      flex-direction: column;
      position: relative;
      .guide-img {
        width: 100%;
        height: 400px;
        img {
          width: 100%;
          height: 100%;
        }
        div {
          width: 100%;
          height: 22px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #616E8F;
          line-height: 22px;
          letter-spacing: 1px;
          text-align: center;
        }
      }
      .guide-button {
        position: absolute;
        top: 380px;
        left: 0;
        width: 100%;
        height: 164px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .guide-button-create, .guide-button-relate {
          width: 328px;
          height: 72px;
          display: flex;
          align-items: center;
          background: #FFFFFF;
          box-shadow: 0px 4px 0px 0px #EAF2FE;
          border-radius: 12px;
          border: 1px solid #EAF2FE;
          padding-left: 24px;
          box-sizing: border-box;
          div {
            width: 40px;
            height: 40px;
            background: #EAF2FE;
            border-radius: 20px;
            text-align: center;
            line-height: 40px;
            margin-right: 20px;
            i {
              color: #337EFF;
              font-size: 20px;
            }
          }
          p {
            display: flex;
            flex-direction: column;
            justify-content: center;
            span:first-child {
              height: 22px;
              font-size: 14px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #2A2F3D;
              line-height: 22px;
            }
            span:nth-child(2) {
              height: 18px;
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #616E8F;
              line-height: 18px;
            }
          }
        }
        .guide-button-relate {
          margin-top: 20px;
          box-shadow: 0px 4px 0px 0px #FFEFE3;
          border: 1px solid #FFEEE2;
          div {
            background: #FFEEE2;
            i {
              color: #FF6B00;
            }
          }
        }
        .guide-button-create:hover {
          background: #EAF2FE;
          box-shadow: none;
          transition: all 0.7s;
          div {
            background: #fff;
            transition: all 0.7s;
          }
        }
        .guide-button-relate:hover {
          background: #FFEEE2;
          box-shadow: none;
          transition: all 0.7s;
          div {
            background: #fff;
            transition: all 0.7s;
          }
        }
        .no-relation {
          div {
            i {
              color: #F59931;
            }
          }
          p {
            span {
              color: #A9ADB8 !important;
            }
          }
        }
        .no-relation:hover {
          background: #FFFFFF;
          box-shadow: 0px 4px 0px 0px #FFEFE3;
          cursor: not-allowed;
          div {
            cursor: not-allowed;
            background: #FFEEE2;
          }
          p {
            cursor: not-allowed;
            span {
              cursor: not-allowed !important;
            }
          }
        }
      }
      /* 无权限 */
      .no-auth {
        position: absolute;
        top: 380px;
        left: 0;
        width: 100%;
        height: 164px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .no-auth-container {
          width: 146px;
          height: 40px;
          background: #FFFFFF;
          box-shadow: 0px 4px 0px 0px #EAF2FE;
          border-radius: 6px;
          border: 1px solid #EAF2FE;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #337EFF;
          i {
            font-size: 13px;
            margin-right: 13px;
          }
        }
        .no-auth-container:hover {
          width: 146px;
          height: 40px;
          background: #EAF2FE;
          border-radius: 6px;
          border: 1px solid #EAF2FE;
        }
      }
    }
  }
  .dialog-body {
    width: 100%;
    height: 310px;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .content-left-search {
      height: 42px;
      box-sizing: border-box;
      :deep(.el-input__inner) {
        background: #F2F4F7;
        border-radius: 6px;
      }
    }
    .content-tree {
      flex: 1;
      overflow-y: auto;
    }
    :deep(.el-tree-node__content) {
      height: auto;
      margin-bottom: 4px;
      box-sizing: border-box;
      .el-tree-node__expand-icon, .is-leaf {
        display: none;
      }
    }
  }
  .archi-label-container {
    width: 100%;
    height: 40px;
    display: flex;
    position: relative;
    .archi-label-body {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 9;
      width: 100%;
      height: 40px;
      display: flex;
      .archi-label-svg {
        flex: 1;
        font-family: SourceHanSansCN;
        font-size: 14px;
        font-weight: 400;
        display: flex;
        align-items: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-left: 11px;
        box-sizing: border-box;
        .header-img {
          width: 32px;
          height: 32px;
          margin-right: 6px;
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          i {
            font-size: 20px;
            margin-right: 0;
          }
        }
        svg {
          width: 22px;
          height: 22px;
          margin: 0 6px;
          border-radius: 4px;
        }
        .archi-label-img {
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 4px;
          box-sizing: border-box;
          margin-right: 6px;
          border-radius: 4px;
          background-color: #e8f4ff;
          border: 1px solid #dee1e6;
          img {
            width: 22px;
            height: 22px;
          }
          span {
            color: #409EFF;
            font-weight: 550;
            font-size: 18px;
          }
        }
        i {
          font-size: 16px;
          margin-right: 6px;
        }
        .archi-label-txt {
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          height: 22px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #2A2F3D;
          line-height: 22px;
        }
      }
      .archi-label-click {
        width: 60px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        i {
          font-size: 20px;
        }
      }
    }
    .empty-container {
      position: absolute;
      bottom: 3px;
      width: 100%;
      height: 22px;
      font-size: 14px;
      box-sizing: border-box;
      padding-left: 40px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #A9ADB8;
      line-height: 22px;
    }
  }
  .selected {
    .archi-label-txt {
      color: #fff !important;
    }
    .header-img {
      i {
        color: #fff;
      }
    }
    .archi-label-click {
      color: #fff;
      i {
        color: #fff;
      }
    }
  }
  .selected::after {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    box-sizing: border;
    content: '';
    background: #337EFF;
    border-radius: 6px;
  }
</style>