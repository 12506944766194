<!--
 * @Description: 大屏
 * @Author: luocheng
 * @Date: 2022-04-28 17:03:58
 * @LastEditors: luocheng
 * @LastEditTime: 2022-10-31 09:48:51
 * @LastEditTime: 2022-09-14 15:38:48
-->
<template>
	<div
		class="screen-parser screen-container"
		:style="{
			backgroundColor: editorConfig.backgroundColor
		}"
	>
		<!-- eslint-disable -->
		<ShapeBox
			v-for="(item, index) in componentList"
			:key="item.id"
			:index="index"
			:defaultStyle="item.style || {}"
			:element="item"
			:style="getBoxStyle(item.style, item.specialStyle.fixedSize)"
			v-if="
			(item.statusConfig
				&& item.statusConfig.isShow
			)
			&& judgingArchiAPermission(item.archiLimit, item.authConfig && item.authConfig.list)
			&& getUILogicStatus(item)
      "
			@click.native.stop="onClickBox(item)"
		>
			<component
				v-show="item.statusConfig
					&& 
					(item.statusConfig.displayShow
						|| item.statusConfig.displayShow === undefined
					)
				"
        :is="item.component"
        :index="index"
        :element="item"
        :id="'component' + item.id"
        :style="getComStyle(item.style, item.specialStyle.fixedSize, item)"
        :propValue="item.propValue"
				:scaleHeight="+scaleHeight"
				:scaleWidth="+scaleWidth"
				:ratio="+ratio"
				:isPreview="true"
      >
      </component>
    </ShapeBox>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { getStyle } from '@/utils/style';
import ShapeBox from './ShapeBox';
import { judgingArchiAPermission, judgingEventLogic, getStatusVal, getComponentById } from '@/utils/tools';

// 注册组件
const componentsList = require.context('@/custom-component', true, /\.+vue$/);
const customComponents = {};
componentsList.keys().forEach((fileName) => {
	let name = fileName.replace(/.vue/, '');
	name = name.replace(/\.\//, '');
	name = name.split('/')[1];
	customComponents[name] = componentsList(fileName).default;
});
//中控台组件
const componentsConsole = require.context('@/console-components', true, /\.+vue$/);
componentsConsole.keys().forEach((fileName) => {
  let name = fileName.replace(/.vue/, '');
  name = name.replace(/\.\//, '');
  name = name.split('/')[1];
  customComponents[name] = componentsConsole(fileName).default;
});

export default {
  name: 'ScreenParser',
  components: {
    ShapeBox,
    ...customComponents
  },
  data() {
		return {
			componentList: [],
      scaleHeight: 1,
      scaleWidth: 1,
			ratio: 1
		};
	},
	computed: {
		...mapState([
			'componentData', // 图层列表
      'editorConfig'
		]),
    originHeight() {
      return this.editorConfig.height;
    },
    originWidth() {
      return this.editorConfig.width;
    }
	},
	created() {
		this.componentList = this.componentData;
	},
	watch: {
		// 组件列表
		componentData: {
			handler() {
				this.componentList = this.componentData;
			},
			deep: true
		},
		originWidth() {
			sessionStorage.setItem('screenViewOriginWidth', this.originWidth);
		}
	},
  mounted() {
    this.getSizeScale();
		window.addEventListener('resize',this.getSizeScale);
		// 全屏变化回调
    if (typeof document.fullScreen !== "undefined") {
      document.addEventListener("fullscreenchange", function() {
				this.doResize();
				this.getSizeScale();
      });
    } else if (typeof document.webkitIsFullScreen !== "undefined") {
      document.addEventListener("webkitfullscreenchange", function() {
				this.doResize();
				this.getSizeScale();
      });
    } else if (typeof document.mozFullScreen !== "undefined") {
      document.addEventListener("mozfullscreenchange", function() {
				this.doResize();
				this.getSizeScale();
      });
    }
		this.$nextTick(() => {
		})
  },
	methods: {
    judgingArchiAPermission,
		/**
		 * @desc: 获取UI判断逻辑
		 */
		getUILogicStatus(item) {
			const { UILogic } = item;
			if (!UILogic || !UILogic.enable) return true;
			const { logicConfigs } = UILogic;
			const logicList = [];
			for (let i = 0; i < logicConfigs.length; i++) {
				const item = logicConfigs[i];
				const { dataAttr, field, itemLogic, logicRule, type, value, valueType,
					componentId,
					dataOrigin,
					valueField
				} = item;
				// 逻辑关系
				let itemBoolean = true;
				let logicValue = null;
				if (type === 'userInfo') {
					// 用户信息
					const userInfo = localStorage.getItem('userInfo') && JSON.parse(localStorage.getItem('userInfo'));
					logicValue = userInfo && userInfo[field];
					if (dataAttr === 'length') {
						logicValue = logicValue.length;
					}
				} else if (type === 'framework') {
					// 架构信息
					const targetArchi = localStorage.getItem('targetArchi') && JSON.parse(localStorage.getItem('targetArchi'));
					logicValue = targetArchi && targetArchi[field];
					if (dataAttr === 'length') {
						logicValue = logicValue.length;
					}
				} else if (type === 'component') {
					// 组件
					let originComponent = getComponentById(this.componentData, componentId);
					if (!originComponent && this.isGroup && this.groupComponents.length) {
						originComponent = getComponentById(this.groupComponents, componentId);
					}
					if (!originComponent) return false;
					let logicData = null;
					if (dataOrigin === 'resolveData') {
						// 暴露值
						logicData = originComponent?.resolveData;
					} else if (dataOrigin === 'componentData') {
						// 组件数据
						logicData = originComponent?.fullData || originComponent?.containerData;
					}
					if (dataAttr === 'value') {
						logicValue = logicData;
						if (valueField) {
							// 取值字段
							logicValue = logicData?.[valueField];
						}
					} else if (dataAttr === 'length') {
						try {
							logicValue = logicData.length;
						} catch (e) {
							console.log(e, '---999----')
						}
					}
				}
				// 逻辑关系
				if (logicRule === '===') {
					if (valueType === 'string') {
						itemBoolean = logicValue === value;
					} else if (valueType === 'number') {
						itemBoolean = +logicValue === +value;
					} else if (valueType === 'date') {
						itemBoolean = new Date(logicValue).getTime() === value;
					}
				} else if (logicRule === '!=') {
					if (valueType === 'string') {
						itemBoolean = logicValue !== value;
					} else if (valueType === 'number') {
						itemBoolean = logicValue !== +value;
					} else if (valueType === 'date') {
						itemBoolean = new Date(logicValue).getTime() !== value;
					}
				} else if (logicRule === '>') {
					if (valueType === 'number') {
						itemBoolean = logicValue > +value;
					} else if (valueType === 'date') {
						itemBoolean = new Date(logicValue).getTime() > value;
					}
				} else if (logicRule === '<') {
					if (valueType === 'number') {
						itemBoolean = logicValue < +value;
					} else if (valueType === 'date') {
						itemBoolean = new Date(logicValue).getTime() < value;
					}
				} else if (logicRule === '<=') {
					if (valueType === 'number') {
						itemBoolean = logicValue <= +value;
					} else if (valueType === 'date') {
						itemBoolean = new Date(logicValue).getTime() <= value;
					}
				} else if (logicRule === '>=') {
					if (valueType === 'number') {
						itemBoolean = logicValue >= +value;
					} else if (valueType === 'date') {
						itemBoolean = new Date(logicValue).getTime() >= value;
					}
				}
				logicList.push({
					itemBoolean,
					itemLogic
				});
			}
			if (logicList.length === 1) {
				return logicList[0].itemBoolean;
			}
			// 组合逻辑判断
			let prevLogicBoolean = null;
			for (let j = 1; j < logicList.length; j++) {
				const prev = logicList[j - 1];
				const prevItemLogic = prev.itemLogic;
				if (prevItemLogic === 'and') {
					if (j === 1) {
						prevLogicBoolean = prev.itemBoolean && logicList[j].itemBoolean
					} else {
						prevLogicBoolean = prevLogicBoolean && logicList[j].itemBoolean;
					}
					if (!prevLogicBoolean) {
						return false;
					}
				} else if (prevItemLogic === 'or') {
					if (j === 1) {
						prevLogicBoolean = prev.itemBoolean || logicList[j].itemBoolean
					} else {
						prevLogicBoolean = prevLogicBoolean || logicList[j].itemBoolean
					}
					if (!prevLogicBoolean) {
						return false;
					}
				}
			}
			return true;
		},
    /**
     * @desc: 获取缩放比例
     */
    getSizeScale() {
			const ratio = 1;
			const curHeight = document.documentElement.clientHeight * ratio;
			const curWidth = document.documentElement.clientWidth * ratio;
      try {
        this.scaleHeight = (curHeight / this.originHeight).toFixed(2);
        this.scaleWidth = (curWidth / this.originWidth).toFixed(2);
      } catch {
        this.scaleHeight = 1;
        this.scaleWidth = 1;
      }
			// console.log(this.scaleHeight, '----缩放宽高----', this.scaleWidth);
			// console.log(this.originHeight, '----11111---', this.originWidth);
    },
    /**
		 * @desc: 根据图层的样式对象获取可渲染的组件样式
		 * @param {Object/null} styleObj 样式对象
		 * @param { Boolean } fixedSize 是固定尺寸
		 * @return {Object} 壳渲染组件
		 */
		getComStyle(styleObj, fixedSize, item) {
			if (!styleObj || !styleObj.backgroundColor) return {};
			let excludesArr = ['top', 'left', 'rotate', 'height', 'width', 'fontSize', 'lineHeight'];
      let styleResult = getStyle(styleObj, excludesArr)
      excludesArr.forEach(ele => {
        let val = styleObj[ele] || 0;
        if (ele !== 'rotate') {
          if (ele === 'height' || ele === 'top') {
						if (!isNaN(+val)) {
							val = `${val * (+fixedSize || this.scaleHeight)}px`
						}
          } else if (ele === 'fontSize') {
						val = `${val}px`;
					} else {
						if (!isNaN(+val)) {
							val = `${val * (+fixedSize || this.scaleWidth)}px`;
						}
					}
        } else {
					val = 'rotate(' + styleObj[ele] + 'deg)';
        }
        styleResult = {
          ...styleResult,
          [ele]: val
        }
      });

			const { id } = item;
			if (id === 'CommonScreenContainer-1659244834467') {
				const { width, height, left, top } = item.style || {};
				console.log(`原始信息：
					宽高比：${window.innerWidth / window.innerHeight}
					width: ${width},
					height: ${height},
					left: ${left},
					top: ${top}
				`);
				console.log(`计算比例:
					scaleHeight:${this.scaleHeight},
					scaleWidth: ${this.scaleWidth},
					ratio: ${this.ratio}
				`);
				console.log(`计算结果:
					width: ${styleResult.width},
					height: ${styleResult.height},
					left: ${styleResult.left},
					top: ${styleResult.top}
				`)
			}
			return {
				...styleResult,
				top: 0,
				left: 0
			};
		},
		/**
		 * @desc: 获取组件盒子的样式
		 * @param {Object} style 组件样式
		 * @return {Object} 格式化后样式对象
		 */
		getBoxStyle(styleObj, fixedSize, isLayout) {
			if (isLayout) {
				// 布局样式
				return this.getComStyle(styleObj, isLayout);
			}
			const result = {};
      ['width', 'height', 'top', 'left', 'rotate'].forEach(ele => {
        let val = styleObj[ele] || 0;
        if (ele !== 'rotate') {
          if (ele === 'height' || ele === 'top') {
						if (!isNaN(+val)) {
							val = `${(val * (+fixedSize || this.scaleHeight)).toFixed(2)}px`
						}
          } else if (ele === 'fontSize') {
						val = `${val}px`;
					} else {
						if (!isNaN(+val)) {
							val = `${(val * (+fixedSize || this.scaleWidth)).toFixed(2)}px`;
						}
					}
					result[ele] = val;
        } else {
					result.transform = `rotate(${styleObj[ele]}deg)`;
        }
      });
			return {
				...result,
				// top: 0,
				// left: 0,
				zoom: 1
			};
		},
		/**
		 * @desc: 触发点击事件
		 * @param {Object} element 点击的组件
		 *  触发方式实质就是在改变指定的组件状态
		 */
		onClickBox(element) {
			if (!element.events || !element.events.click ||!element.events.click.effects) {
				return;
			}
			
			const click = element.events.click || {};
			const { pattern, effects = [], specialEventList = []} = click;
			if (!pattern) {
				const { actionType, linkType, queryList = [], linkUrl = '', pageId } = click;
				if (actionType === 'pageStatus') {
					// 修改页面状态
					this.updatePageStatus(click.stateMachine || []);
				} else if (actionType === 'jumpPage') {
					if (linkType === 'projectPage') {
						const query = {};
						queryList.forEach((queryItem) => {
							let component = getComponentById(this.componentData, queryItem.componentId);
							this.$set(
								query,
								queryItem.key,
								component?.resolveData[queryItem.feild]
							);
						});
						this.$router.push({
							path: pageId,
							query
						});
						return;
					} else if (linkType === 'outPage') {
						window.open(linkUrl);
					} else if (linkType === 'custom') {
						// 暂未处理
						// const customLink = getLinkByTemplate(linkTemplate, output)
						// window.open(customLink);
					}
				} else if (actionType === 'eeAction') {
					// 触发后端事件 暂未处理
					// console.log(ele, '0000001111111111', output);
					// this.doEEAction(element, output, type);
					// 搜集参数
				} else {
					effects.forEach((ele) => {
						this.$store.commit('triggerEvents', {
							config: ele,
							element: element
						});
					});
				}
			} else if (pattern === 'special') {
				specialEventList.forEach(ele => {
					const { actionType, linkType, queryList = [], linkUrl = '', pageId } = ele;
					if (actionType === 'pageStatus') {
						// 修改页面状态
						this.updatePageStatus(ele.stateMachine || []);
					} else if (actionType === 'jumpPage') {
						if (linkType === 'projectPage') {
							const query = {};
							queryList.forEach((queryItem) => {
								let component = getComponentById(this.componentData, queryItem.componentId);
								this.$set(
									query,
									queryItem.key,
									component?.resolveData[queryItem.feild]
								);
							});
							this.$router.push({
								path: pageId,
								query
							});
							return;
						} else if (linkType === 'outPage') {
							window.open(linkUrl);
						} else if (linkType === 'custom') {
							// 暂未处理
							// const customLink = getLinkByTemplate(linkTemplate, output)
							// window.open(customLink);
						}
					} else if (actionType === 'eeAction') {
						// 触发后端事件 暂未处理
						// console.log(ele, '0000001111111111', output);
						// this.doEEAction(element, output, type);
						// 搜集参数
					} else {
						ele.effects.forEach(effect => {
							this.$store.commit('triggerEvents', {
								config: effect,
								element: element
							});
						})
					}
				})
			}
			const behaviors = element?.events?.click?.behaviors || [];
			// 触发行为
			behaviors.forEach(behavior => {
				this.$store.commit('triggerEvents', {
					config: {
						behavior,
						isBehavior: true
					},
					element
				});
			});
		},
		/**
		 * @desc: 更新页面状态
		 * @param {Array} arr 页面状态列表
		 */
		updatePageStatus(arr) {
			let componentData = this.sContainer ? this.originComponentData : this.componentData;
			arr.forEach(ele => {
				if (!judgingEventLogic(ele.logics, componentData)) return;
				const { value } = getStatusVal(ele, componentData);
				this.$store.commit('updatePageCustomStatus', {
					key: ele.code,
					value
				});
			})
		},
	},
	beforeDestroy() {
		sessionStorage.removeItem('screenViewOriginWidth');
	}
}
</script>

<style lang="less" scoped>
.screen-parser{
  position: relative;
  height: 100%;
  width: 100%;
  transition: all .3s;
  overflow: hidden;
}
</style>