var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-tag',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTag && !_vm.showSelect),expression:"showTag && !showSelect"}],staticStyle:{"cursor":"pointer","height":"32px","line-height":"32px"},attrs:{"disable-transitions":""},on:{"click":function($event){_vm.showSelect = true;
      _vm.nowNumber = 20;
      _vm.nowSelectData = _vm.selectData.slice(0, 20);
      _vm.$nextTick(() => _vm.$refs.obselect.focus());}}},[_vm._v(" "+_vm._s(_vm.showName)+" ")]),(!_vm.showTag || _vm.showSelect)?_c('el-select',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.selectLoading),expression:"selectLoading"},{name:"el-select-loadmore",rawName:"v-el-select-loadmore",value:(
      () => {
        _vm.nowNumber = _vm.nowNumber + 20;
        _vm.getNowSelectData();
      }
    ),expression:"\n      () => {\n        nowNumber = nowNumber + 20;\n        getNowSelectData();\n      }\n    "}],ref:"obselect",staticStyle:{"width":"100%"},attrs:{"multiple":_vm.multiple,"filterable":"","filter-method":_vm.customFilter,"allow-create":"","default-first-option":"","element-loading-spinner":"el-icon-loading"},on:{"focus":function($event){_vm.onSelectFoucus = true},"blur":function($event){return _vm.SelectInstanceBlur()}},model:{value:(_vm.selectValue),callback:function ($$v) {_vm.selectValue=$$v},expression:"selectValue"}},_vm._l((_vm.nowSelectData),function(item){return _c('el-option',{key:item.uuid,attrs:{"label":item && item.enabled == 2 ? '[聚合] ' + item.name : item.name,"value":item.uuid,"disabled":item && item.enabled == 2}})}),1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }