<!--
 * @Description: 盒子
 * @Author: luocheng
 * @Date: 2022-04-28 17:13:04
 * @LastEditors: luocheng
 * @LastEditTime: 2022-10-31 09:48:47
-->
<template>
	<div class="shape" :data-id="element.id">
		<slot></slot>
	</div>
</template>

<script>

export default {
	name: 'Shape',
	props: {
		// 组件对象
		element: {
			type: Object,
			default: () => {},
			required: true
		},
		// 组件默认样式
		defaultStyle: {
			type: Object,
			default: () => {},
			required: true
		},
		// 组件在图层列表中下标
		index: {
			type: Number,
			required: true
		}
	},
	data() {
		return {};
	}
};
</script>

<style lang="less" scoped>
.shape {
	position: absolute;
}
</style>
<style>
span, p, h1, h2, h3, h4, h5, h6, li, ol, div, header, section, article {
	cursor: pointer;
}
</style>
