/*
 * @LastEditTime: 2022-08-05 15:01:42
 * @Description: 全局行为基类
 * @FilePath: /dataview-next/src/global/actions/baseAction.js
 * @Date: 2022-01-12 14:11:01
 * @Author: lisushuang
 * @LastEditors: lisushuang
 */
/* eslint-disable */
import {
  Pagination,
  Dialog,
  Autocomplete,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Input,
  InputNumber,
  Radio,
  RadioGroup,
  RadioButton,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Switch,
  Select,
  Option,
  OptionGroup,
  Button,
  ButtonGroup,
  Table,
  TableColumn,
  DatePicker,
  TimeSelect,
  TimePicker,
  Popover,
  Tooltip,
  Breadcrumb,
  BreadcrumbItem,
  Form,
  FormItem,
  Tabs,
  TabPane,
  Tag,
  Tree,
  Alert,
  Slider,
  Icon,
  Row,
  Col,
  Upload,
  Progress,
  Spinner,
  Badge,
  Card,
  Rate,
  Steps,
  Step,
  Carousel,
  CarouselItem,
  Collapse,
  CollapseItem,
  Cascader,
  ColorPicker,
  Transfer,
  Container,
  Header,
  Aside,
  Main,
  Footer,
  Timeline,
  TimelineItem,
  Link,
  Divider,
  Calendar,
  Backtop,
  PageHeader,
  CascaderPanel,
  Loading,
  MessageBox,
  Message,
  Notification,
} from 'element-ui';
import rootInstance from "@/main"
import Vue from 'vue';
export default class baseAction {

  constructor() {
    this.instance = null
    this.props = null
    this.dialogInstance = null
    this.$refs = null
    this.refreshTag = '__refreshTag__'
    this.loadingTimer = null
    this.dialogFullScreen = false
    this.mountInstance = new Vue({
      components: {
        Pagination,
        Dialog,
        Autocomplete,
        Dropdown,
        DropdownMenu,
        DropdownItem,
        Menu,
        Submenu,
        MenuItem,
        MenuItemGroup,
        Input,
        InputNumber,
        Radio,
        RadioGroup,
        RadioButton,
        Checkbox,
        CheckboxButton,
        CheckboxGroup,
        Select,
        Option,
        OptionGroup,
        Button,
        ButtonGroup,
        Table,
        TableColumn,
        DatePicker,
        TimeSelect,
        TimePicker,
        Popover,
        Tooltip,
        Breadcrumb,
        BreadcrumbItem,
        Form,
        FormItem,
        Tabs,
        TabPane,
        Tag,
        Tree,
        Alert,
        Slider,
        Icon,
        Row,
        Col,
        Upload,
        Progress,
        Spinner,
        Badge,
        Card,
        Rate,
        Steps,
        Step,
        Carousel,
        CarouselItem,
        Collapse,
        CollapseItem,
        Cascader,
        ColorPicker,
        Transfer,
        Container,
        Header,
        Aside,
        Main,
        Footer,
        Timeline,
        TimelineItem,
        Link,
        Divider,
        Calendar,
        Backtop,
        PageHeader,
        CascaderPanel,
        Loading,
        MessageBox,
        Message,
        Notification
      },
      render(){
        return (
          <div></div>
        )
      }
    })
    let ele = this.mountInstance.$mount().$el
    let body = document.getElementsByTagName('body')[0]
    body.appendChild(ele)
  }

  handler() {
    return true;
  }

  /**
   * @description: 代理调用我的vue实例
   * @param {*} instance 调用我的vue实例
   * @return {*} this
   */
  bind(instance) {
    // 如果没有instance传入，那么传入根节点
    if (!instance) {
      this.instance = rootInstance
    } else {
      this.instance = instance
    }
    return this
  }

  /**
   * @description: 展示一个全局loading
   * @param {*} title
   * @return {*} void
   */
  getLoading(title) {
    if (this.loading) {
      this.loading.close()
    }
    // 100ms内就直接关闭的不展示loading
    // this.loadingTimer = setTimeout(() => {
    //   if(!this.loading){
        this.loading = Loading.service({ spinner: 'el-icon-loading', fullscreen: true, text: title ? title : "数据准备中，请稍后" }) 
    //   }
    // }, 100);
  }

  /**
   * @description: 关闭这个全局loading
   */  
  closeLoading() {
    if(this.loading){
      this.loading.close()
    }
    if(this.loadingTimer){
      clearTimeout(this.loadingTimer)
    }
  }

  data(data) {
    this.props = data
    this.init()
    return this
  }

  init() {
    return true
  }

  /**
   * @description: 显示错误信息并销毁自身
   * @param {*} msg 显示的消息
   * @return {*}
   */
  showError(msg = '') {
    Message.error(msg)
    if (this.loading) this.loading.close(); this.loading = null;
  }

  /**
   * @description: 显示成功信息
   * @param {*} msg 显示的消息
   * @return {*}
   */
  showSuccess(msg = '') {
    Message.success(msg)
  }

  /**
   * @description: 展示一个弹窗，里面是传入的组件 
   * @param {Function} content 弹窗内部的内容  (h) => {return JSX}
   * @param {String} title 弹窗的标题
   * @param {Function} footer 弹窗的底部slot
   * @param {Boolean} isFullScrenn
   * @return {Void}
   */
  showPopDialog(content, title, footer = null,isFullScreen = false) {
    let _this = this
    this.dialogInstance = new Vue({
      data: {
        visible: true,
        name: ''
      },
      components:{Dialog},
      
      methods: {
        closeMe() {
          this.visible = false
          this.$nextTick(() => {
            if (_this && _this.dialogInstance && _this.dialogInstance.$el) {
              _this.dialogInstance.$el.remove()
              _this.dialogInstance = null
            }
          })
        }
      },
      render: function (h) {
        return (
          <Dialog width="70%" custom-class={isFullScreen ? "base-action-dialog-fullscreen":''} fullscreen={isFullScreen} top="0" title={title} visible={this.visible} v-on:close={() => this.closeMe()}>
            <div class={isFullScreen ? "dialog-body-content" : 'max-height-70'} style="height:100%;overflow-y:auto;overflow-x:hidden;padding-right:5px;">
              {content ? content() : ''}
            </div>
            <div name="footer" class="dialog-body-footer" style="margin-top:10px;text-align:center">
              {footer ? footer() : ''}
            </div>
          </Dialog>
        )
      }
    })
    let ele = this.dialogInstance.$mount().$el
    let body = document.getElementsByTagName('body')[0]
    body.appendChild(ele)

    //todo 需要一个static管理所有的弹窗，以便在切换路由的时候全部关闭，记录追踪路线，可以跳转
  }

  closeDialog() {
    this.dialogInstance.closeMe()
  }
}