var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"list-container"},[(_vm.listShow)?_c('PullRefresh',{on:{"refresh":_vm.onRefresh},model:{value:(_vm.refreshing),callback:function ($$v) {_vm.refreshing=$$v},expression:"refreshing"}},[_c('List',{attrs:{"finished":_vm.finished,"error":_vm.error,"error-text":"请求失败，点击重新加载","finished-text":"没有更多数据了"},on:{"update:error":function($event){_vm.error=$event},"load":_vm.onPage},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_vm._l((_vm.tableData),function(item){return _c('div',{key:item.id,staticClass:"list-item",on:{"click":function($event){$event.stopPropagation();return _vm.onAction('rowClick', item, $event)}}},[(_vm.layoutConfig.type)?[(['3rowTextAndImg', '2rowTextAndImg'].includes(_vm.layoutConfig.type) && _vm.layoutConfig.img)?_c('div',{staticClass:"item-img-warp"},[(item[_vm.layoutConfig.img] && _vm.getItemImgSrc(item))?_c('img',{style:({
									height: (_vm.fieldConfigObj[_vm.layoutConfig.img] && _vm.fieldConfigObj[_vm.layoutConfig.img].imgConfig.height || 72) + 'px',
									width: (_vm.fieldConfigObj[_vm.layoutConfig.img] && _vm.fieldConfigObj[_vm.layoutConfig.img].imgConfig.width || 72) + 'px',
								}),attrs:{"src":_vm.getItemImgSrc(item)}}):_c('img',{style:({
									height: (_vm.fieldConfigObj[_vm.layoutConfig.img] && _vm.fieldConfigObj[_vm.layoutConfig.img].imgConfig.height || 72) + 'px',
									width: (_vm.fieldConfigObj[_vm.layoutConfig.img] && _vm.fieldConfigObj[_vm.layoutConfig.img].imgConfig.width || 72) + 'px',
								}),attrs:{"src":require("../../assets/images/logo1.png")}})]):_vm._e(),_c('div',{staticClass:"item-text-warp"},[_c('div',{staticClass:"title-and-status-warp"},[(_vm.layoutConfig.title)?_c('p',{staticClass:"title",style:({ color: _vm.fieldConfigObj[_vm.layoutConfig.title] && _vm.fieldConfigObj[_vm.layoutConfig.title].color})},[_vm._v(" "+_vm._s(_vm.layoutConfig.titlePrefix || '')+_vm._s(item[_vm.layoutConfig.title] || '-')+_vm._s(_vm.layoutConfig.titleSuffix || '')+" ")]):_vm._e(),(item[_vm.layoutConfig.status] && _vm.layoutConfig.type !== '2rowText')?_c('p',{staticClass:"status",style:({
										color: _vm.filterStatus(item[_vm.layoutConfig.status], _vm.fieldConfigObj[_vm.layoutConfig.status].statusOptions).color || _vm.fieldConfigObj[_vm.layoutConfig.status].color,
										background: _vm.formatColorOp(_vm.filterStatus(item[_vm.layoutConfig.status], _vm.fieldConfigObj[_vm.layoutConfig.status].statusOptions).color || _vm.fieldConfigObj[_vm.layoutConfig.status].color)
									})},[_vm._v(" "+_vm._s(_vm.filterStatus(item[_vm.layoutConfig.status], _vm.fieldConfigObj[_vm.layoutConfig.status].statusOptions).label)+" ")]):_vm._e()]),(['2rowTextAndImg', '2rowText'].includes(_vm.layoutConfig.type) && Array.isArray(_vm.layoutConfig.lineText) && _vm.layoutConfig.lineText.length)?[_c('p',{staticClass:"text-item"},[(item[_vm.layoutConfig.status] && _vm.layoutConfig.type === '2rowText')?_c('span',{staticClass:"status",style:({
											color: _vm.filterStatus(item[_vm.layoutConfig.status], _vm.fieldConfigObj[_vm.layoutConfig.status].statusOptions).color || _vm.fieldConfigObj[_vm.layoutConfig.status].color,
											background: _vm.formatColorOp(_vm.filterStatus(item[_vm.layoutConfig.status], _vm.fieldConfigObj[_vm.layoutConfig.status].statusOptions).color || _vm.fieldConfigObj[_vm.layoutConfig.status].color)
										})},[_vm._v(" "+_vm._s(_vm.filterStatus(item[_vm.layoutConfig.status], _vm.fieldConfigObj[_vm.layoutConfig.status].statusOptions).label)+" ")]):_vm._e(),_vm._l((_vm.layoutConfig.lineText),function(sitem,index){return _c('span',{key:index,style:({ color: sitem.type === 'fild' && sitem.value ? _vm.fieldConfigObj[sitem.value].color : '' })},[_vm._v(" "+_vm._s(sitem.type === 'fild' && sitem.value ? item[sitem.value] : sitem.value)+" ")])})],2)]:(Array.isArray(_vm.layoutConfig.text))?_vm._l((_vm.layoutConfig.text),function(sitem){return _c('p',{key:sitem,staticClass:"text-item",style:({ color: _vm.fieldConfigObj[sitem].color})},[_c('span',[_vm._v(_vm._s(_vm.fieldConfig.find(el => el.uuid === sitem) ? _vm.fieldConfig.find(el => el.uuid === sitem).label : '-'))]),_vm._v(" :  "),_c('span',[_vm._v(" "+_vm._s(_vm.getShowValue(item, sitem))+" ")])])}):_vm._e()],2)]:[(Object.keys(_vm.layoutConfig).length > 0)?[(_vm.layoutConfig.img)?_c('div',{staticClass:"item-img-warp"},[(item[_vm.layoutConfig.img])?_c('img',{style:({
										height: (_vm.fieldConfigObj[_vm.layoutConfig.img] && _vm.fieldConfigObj[_vm.layoutConfig.img].imgConfig.height || 72) + 'px',
										width: (_vm.fieldConfigObj[_vm.layoutConfig.img] && _vm.fieldConfigObj[_vm.layoutConfig.img].imgConfig.width || 72) + 'px',
									}),attrs:{"src":item[_vm.layoutConfig.img]}}):_vm._e()]):_vm._e(),(_vm.layoutConfig.title || Array.isArray(_vm.layoutConfig.text) && _vm.layoutConfig.text.length)?_c('div',{staticClass:"item-text-warp"},[(item[_vm.layoutConfig.title])?_c('div',{staticClass:"title-and-status-warp",style:({ color: _vm.fieldConfigObj[_vm.layoutConfig.title] && _vm.fieldConfigObj[_vm.layoutConfig.title].color})},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.layoutConfig.titlePrefix)+_vm._s(item[_vm.layoutConfig.title])+_vm._s(_vm.layoutConfig.titleSuffix))]),(item[_vm.layoutConfig.status])?_c('p',{staticClass:"status",style:({
											color: _vm.filterStatus(item[_vm.layoutConfig.status], _vm.fieldConfigObj[_vm.layoutConfig.status].statusOptions).color || _vm.fieldConfigObj[_vm.layoutConfig.status].color,
											background: _vm.formatColorOp(_vm.filterStatus(item[_vm.layoutConfig.status], _vm.fieldConfigObj[_vm.layoutConfig.status].statusOptions).color || _vm.fieldConfigObj[_vm.layoutConfig.status].color)
										})},[_vm._v(" "+_vm._s(_vm.filterStatus(item[_vm.layoutConfig.status], _vm.fieldConfigObj[_vm.layoutConfig.status].statusOptions).label)+" ")]):_vm._e()]):_vm._e(),(Array.isArray(_vm.layoutConfig.text) && _vm.layoutConfig.text.length)?_vm._l((_vm.layoutConfig.text),function(sitem){return _c('p',{key:sitem,staticClass:"text-item",style:({ color: _vm.fieldConfigObj[sitem].color})},[_c('span',[_vm._v(_vm._s(_vm.fieldConfig.find(el => el.uuid === sitem) ? _vm.fieldConfig.find(el => el.uuid === sitem).label : '-'))]),_vm._v(" :  "),_c('span',[_vm._v(" "+_vm._s(_vm.getShowValue(item, sitem))+" ")])])}):_vm._e()],2):_vm._e()]:[_c('Skeleton',{staticStyle:{"width":"100%"},attrs:{"title":"","avatar":"","row":2}})]]],2)}),(_vm.tableData.length === 0 && _vm.loading)?_vm._l((8),function(item){return _c('p',{key:item,staticClass:"skeleton-item"},[_c('Skeleton',{attrs:{"title":"","avatar":"","row":2}})],1)}):_vm._e()],2)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }