/* eslint-disable */
/**
 * 计算规则引擎
 * @method calcIF (条件，True，False)
 * @method calcSUM (...arg)
 * @method calcAVG (...arg)
 */

/**
 * 字符串代码执行
 * @param fn
 * @return {*}
 */
export let calcImpl = (fn) => {
    // js计算代码段执行
    const Fn = Function;
    return new Fn('return ' + fn)();
}

/**
 * 取表单值
 * @param arg1 键名
 * @param data 所有值
 */
export let calcInvoke = (arg1,data) => {
    //取表单值
}

/**
 * if判断语句块
 * @param arg1 条件
 * @param arg2 为真执行
 * @param arg3 为假执行
 */
export let calcIF = (arg1, arg2, arg3 = null) => {
	if (arg1) {
		return arg2
	} else {
		return arg3
	}
}

/**
 * And语句
 * @param arg1
 * @param arg2
 */
export let calcAND = (arg1,arg2) => {

}

export let calcOR = (arg1,arg2) => {

}

export let calcNOT = (arg1) => {

}

export let calcISEMPTY = (arg1) => {

}

export let calcISCONTAINS = (arg1,arg2) => {

}

export let calcEQUAL = (arg1,arg2) => {

}

/**
 * 求积
 * @arguments -- 可变参数
 */
export let calcMULTIPLY = (...data) => {
	let number = 1;
	for (let i=data.length-1; i>=0; i--) {
		number *= data[i];
	}
	return number
}

/**
 * 求和
 * @arguments -- 可变参数
 */
export let calcSUM = (...data) => {
	let number = 0;
	for (let i=data.length-1; i>=0; i--) {
		number += data[i];
	}
	return number
}

/**
 * 求平均
 * @arguments -- 可变参数
 */
export let calcAVG = () => {

}

/**
 * 求最大
 * @arguments -- 可变参数
 */
export let calcMAX = () => {

}

/**
 * 求最小
 * @arguments -- 可变参数
 */
export let calcMIN = () => {

}

/**
 * 求计数
 * @arguments -- 可变参数
 */
export let calcCOUNT = () => {

}
