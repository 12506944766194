<!--
 * @Description: 文件夹模式
 * @Author: luocheng
 * @Date: 2022-03-04 13:57:05
 * @LastEditors: luocheng
 * @LastEditTime: 2022-03-07 17:45:21
-->
<template>
  <div class="folder-list">
    <template v-if="fileList && Array.isArray(fileList) && fileList.length">
      <section class="folder-item" v-for="file in fileList" :key="file.id" @click="onFile(file)">
        <i class="iconfont" :class="[getClass(file)]"></i>
        <span class="name">{{ file.name }}</span>
      </section>
    </template>
    <el-empty description="暂无文件" v-else></el-empty>
  </div>
</template>

<script>
import { Empty } from 'element-ui';
export default {
  name: 'FolderView',
  components: {
    'el-empty': Empty
  },
  props: {
    fileList: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  methods: {
    /**
     * @desc: 点击
     * @param {Object} item 数据
     */
    onFile(item) {
      this.$emit('selectFile', item);
    },
    getClass(row) {
      const { type_id, ext } = row;
      if (+type_id === 1) return 'iconwenjianjia';
      if (ext === 'xlsx' || ext === 'xls') {
        return 'iconexcle1x'
      } else if (ext === 'doc' || ext === 'docx') {
        return 'iconword1x'
      } else if (ext === 'txt') {
        return 'icontxt1x'
      } else if (ext === 'pptx' || ext === 'ppt') {
        return 'iconppt1x'
      } else if (['bmp', 'jpg', 'png', 'gif', 'jpeg', 'cdr', 'psd'].includes(ext)) {
        return 'iconzhaopian1x'
      } else if (ext === 'pdf') {
        return 'iconpdf1x'
      }
      return 'iconother1x'
    }
  }
}
</script>

<style lang="less" scoped>
.folder-list{
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  box-sizing: border-box;
  padding: 10px 0;
  .folder-item{
    width: 98px;
    height: 106px;
    padding: 0 32px 30px 32px;
    .iconfont{
      display: block;
      margin-right: 5px;
      font-size: 48px;
      margin-bottom: 10px;
    }
    .name{
      text-align: center;
      line-height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #25282e;
      text-align: center;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  .el-empty{
    margin: 0 auto;
  }
  .iconwenjianjia {
    color: rgb(249, 194, 10)
  }
  .iconexcle1x {
    color: rgb(48, 165, 92);
  }
  .iconword1x {
    color: rgb(45, 133, 254);
  }
  .icontxt1x {
    color: rgb(45, 133, 254);
  }
  .iconzhaopian1x{
    color: rgb(44, 162, 92);
  }
  .iconpdf1x{
    color: rgb(226, 52, 45);
  }
  .iconppt1x{
    color: rgb(246, 123, 30);
  }
  .iconother1x{
    color: rgb(171, 189, 204);
  }
}
</style>