/*
 * @Date: 2022-12-08 16:52:22
 * @LastEditors: AsaHi
 * @LastEditTime: 2022-12-08 17:14:58
 * @FilePath: \dataview-next\src\custom-component\mixins\BehaviorHandlerMixin.js
 */
import eventBus from '@/plugins/eventBus';
import { initParams } from '@/utils/tools';

export default {
    data(){
        return{
            behaviorHandler : {},
        }
    },

    methods : {
        //注册行为
        registerBehaviors( list ){
            for(let {label , func , value , desc } of list){
                this.element.behaviors.push({
                    label,value,desc
                });

                this.behaviorHandler[value] = func;
            }
        },
    },
    mounted() {
        eventBus.$on("doComponentBehavior",  ({ component , list}) => {
            if(component === this.element.id){
                for(let {behaviors , params} of list){
                    for(let bid of behaviors){
                        if(this.behaviorHandler[bid]){
                            const { param = {} } = initParams(params, this.isGroup, this.componentData, this.groupComponents);

                            this.behaviorHandler[bid](param);
                        }
                    }
                }
            }
        });
    },
}