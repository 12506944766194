/* eslint-disable */
import formValidate from "./itemDataCopy";
import { calcImpl } from "@/plugins/calcRuleEngine";
import {formRequest} from "@/apis/data/form";
import {getSearchData, getRequestData,optionData,formatValue} from "./tools";
import {flowEngine} from '@/apis/data/workflow'
import {dataInterface} from "@/apis/data";
import { uniqid } from "@/plugins/util";
export default {
	toggleStep(item,stepInd){
		let allow = item.config.__config__.allowChange
		if(allow) {
			item.config.__config__.activeName = stepInd
		}
	},
	nextStep(item){
		let stepInd = item.config.__config__.activeName
		if(stepInd < item.config.__steps__.length-1){
			item.config.__config__.activeName++
		}
	},
	// 初始触发交互事件（step,radio,select）默认值
	triggerEventCtrl(element,val){
		const interact = element.config.__config__.interact;
		const effect = interact.effect;
		const condition = interact.condition;
		if (effect === 'showhide') {
			let trueData = condition.filter(item => {
				if(typeof item.condV === 'string' && item.condV.indexOf(',')){
					let arr = item.condV.split(',');
					return arr.includes(val) || arr.includes(val.toString())
				}
				return item.condV == val
			})
			let compList = Array.from(condition, item => item.comp).flat();
			let compShowHide = {};
			for (let item of compList){
				compShowHide[item] = false;
			}
			if (trueData.length) {
				for (let eleItem of trueData){
					let compShow = eleItem.comp;
					for (let item of compShow){
						compShowHide[item] = true;
					}
				}
			}
			this.triggerActive(compShowHide)
		}
	},
	onRefresh(){
		this.$emit('on-close');
	},
	flowDesign(param) {
		flowEngine.designShow(param).then((res) => {
			this.flowEngineId = res.data.data.id
		})
	},
	getSelectStep(nodeId) {
		this.selectStep = nodeId
	},
	// 处理流程审批人
	dealWithPicker(selected, total) {
		this.selectedUsers = selected
		this.checkPickerNum = total
	},
	// 获取匹配填充规则
	fillMatchData(){
		let reduceData = {};
		const fillRules = this.formData.fillMatch;
		const manualRules = fillRules.manual;
		const selectRules = fillRules.select;
		for (let item of manualRules){
			reduceData[item.label] = item.value
		}
		for (let item of selectRules){
			for (let ele of item.codeMatch){
				if(ele.label && ele.value)
					reduceData[ele.label] = ele.value
			}
		}
		return reduceData
	},
	//删除关联子项数据
	delRelateItem(data,index){
		data.splice(index,1)
	},
	//关联数据子表数据回绑
	changeEleValue(first,second,three,value){
		this.formValidate.items[first].value[second][three].value=value
	},
	//选择关联数据弹窗
	openSelectRelate(element,itemInd){
		const configData = element.config.__config__.relateConfig
		let searchData = [];
		if(
			configData.__config__.openFilter &&
			configData.__config__.filterRule &&
			configData.__config__.filterRule instanceof Array
		){
			const formIdField = this.getFormIdField();
			const formSubmitData = this.getFormData();
			for (let item of configData.__config__.filterRule){
				let itemData = { ruleType: item.ruleType };
				if(item.paramType === 2){
					itemData.field_uuid = item.field_uuid;
				}else{
					itemData.code = item.code;
				}
				if(item.valueType === 2){
					const fieldKey = formIdField[item.value] || ''
					itemData.value = formSubmitData[fieldKey] || ''
					if(['in','notin'].includes(item.ruleType) && !(itemData.value instanceof Array)){
						itemData.value = [itemData.value]
					}
				}else{
					itemData.value = item.value
				}
				searchData.push(itemData)
			}
		}
		let valueId = [];
		for (let item of element.value){
			if(item[0]?.value) valueId.push(item[0].value)
		}
		this.relateData = valueId; //回显数据
		this.relateFilterRule = searchData; // 组装筛选条件
		this.relateConfig = configData;
		/*this.relateObjectUUid = configData.__config__.objectUuid
		this.relateViewUUid = configData.__config__.viewUuid*/
		this.relateItemInd = itemInd
		this.relateVisible = true
	},
	//选择关联数据后处理
	async selectRelateResult(idKey,rows){
		const element = this.formValidate.items[this.relateItemInd];
		let configData = element.config.__config__.relateConfig.__slot__.options;
		let relateFormId = element.config.__config__.relateConfig.__config__.formId;
		//let otherComp = configData.filter(item => item.element);
		let itemValue=[];
		for (let row of rows){
			row.id = row[idKey]
			let itemData = [];
			let fillData = {};
			for (let item of configData) {
				if(item.element){
					let conf = JSON.parse(JSON.stringify(item.element))
					const options = await this.dynamicSelectData(conf,relateFormId,row[idKey])
					if(options) conf.config.__slot__.options = options;
					if(['user','cascader','treeSelect'].includes(conf.type)){
						this.loadingDynamicData(conf);
					}
					conf.other = row
					itemData.push(conf)
				}else{
					let conf = JSON.parse(JSON.stringify(item))
					const confR = element.config.__config__.relateConfig;
					conf.text = row[conf.value];
					conf.field = confR.__vModel__;
					fillData[idKey] = conf.text //原始字段（子表数据填充）
					conf.value = row[idKey]
					itemData.push(conf)
				}
			}
			/******************子表数据填充start*****************/
			const fillMatch = this.fillMatchData();
			const fillMatchKeys = Object.keys(fillMatch)
			const fillDataKeys = Object.keys(fillData)
			for (let ele of itemData){
				if(ele.config){
					const formId = ele.config.__config__.formId;
					if(fillMatchKeys.includes(formId)){
						const matchVal = fillMatch[formId]
						if(fillDataKeys.includes(matchVal)){
							ele.value = fillData[matchVal]
						}
					}
				}
			}
			/******************子表数据填充end*****************/
			itemValue.push(itemData)
		}
		this.formValidate.items[this.relateItemInd].value = itemValue;
	},
	// 子表下拉选择依赖关联数据ID过滤
	async dynamicSelectData(conf,relateFormId,relateId){
		const eleConfig = conf.config;
		if(
			conf.type === 'select' &&
			eleConfig.__config__.dataType === 'dynamic' &&
			eleConfig.__config__.openFilter
		) {
			let searchData = []; //过滤条件
			const formIdField = this.getFormIdField();
			const formSubmitData = this.getFormData();
			for (let item of eleConfig.__config__.filterRule){
				let itemData = { ruleType: item.ruleType };
				if(item.paramType === 2){
					itemData.field_uuid = item.field_uuid;
				}else{
					itemData.code = item.code;
				}
				if(item.valueType === 2){
					const fieldKey = formIdField[item.value] || ''
					itemData.value = formSubmitData[fieldKey] || ''
					if(item.value === relateFormId){
						itemData.value = relateId
					}
				}else{
					itemData.value = item.value
				}
				searchData.push(itemData)
			}
			let url, params, options = [];
			url = '/api/mapi'
			params = {
				__method_name__: 'dataList',
				object_uuid: eleConfig.__config__.objectUuid,
				view_uuid: eleConfig.__config__.viewUuid,
				size: 1000,
				// transcode: 0,
			}
			if(searchData.length) params.search = searchData
			let response = await formRequest('post', url, params);
			let userData;
			if (response.data.data.data) {
				userData = response.data.data.data
			} else {
				userData = response.data.data
			}
			let label = eleConfig.props.props.label;
			let value = eleConfig.props.props.value;
			if (userData instanceof Array) {
				for (let element of userData) {
					if (element[label] && element[value]) {
						let item = {label: element[label], value: element[value],allField:element};
						options.push(item);
					}
				}
			}
			return options
		}
		return false;
	},
	// 打开外部表单
	openOuterForm(element,index){
		this.outerFormIndex = index
		this.outerFormData = element.config.__config__.designData
		this.outerNeedFlow = !!element.config.__config__.designData.need_flow
		this.outerFormMode = element.value ? 'detail': 'add'
		this.outerFormTitle = element.config.__config__.label
		this.outerFormShowType = element.config.__config__.showType
		this.outerFormType = element.config.__config__.formType
		this.outerFormUuid = element.config.__config__.formDesignId
		this.outerObjectUuid = element.config.__config__.objectUuid
		if(element.value){
			//数据详情回显
			let param,url='api/mapi',method='POST';
			if(this.outerFormType === 1){
				let ruuids = [];
				for (let i = 0; i < this.outerFormData.fields.length; i++) {
					let item = this.outerFormData.fields[i];
					if (item.__config__.tagIcon == 'form') {
						ruuids.push({ relationship_uuid: item.__vModel__ });
					}
				}
				param = {
					object_uuid: this.outerObjectUuid,
					view_uuid: '',
					data_id: element.value,
					ruuids,
					__method_name__: 'dataInfo'
				}
			} else {
				url='api/form4/data/'+element.value
				method='GET';
			}
			dataInterface(param,url,method).then(res => {
				let data = res.data.data;
				this.outerFormDisplay = data;
				this.outerFormVisible = true
			});
		} else {
			this.outerFormVisible = true
		}
	},
	/**
	 * 事件交互处理 -- 显示隐藏（需配合控件可见性配置）
	 * 1.主表控件控制主表控件 2.主表控制子表控件（布局内控件）
	 * 3.子表控件控制子表控件 4.子表控件控制主表控件
	 * @param params 控件显示隐藏
	 * @param parentFormId 子表formId
	 * @param index 子表索引
	 */
	triggerActive(params,parentFormId,index){
		const compShowHide = params[0]; // 受控控件
		const ctrlType =  params[1] ?? ''// 交互类型 - 默认显示隐藏
		const optionData =  params[2] ?? '' // 级联数据

		if(ctrlType === 'cascader'){
			// 级联数据交互
			for (let item of this.formValidate.items){
				let formId = item.config.__config__.formId;
				if(item.type === 'position'){
					// 修改部位级联参数值
					item.config.__config__.cascade = optionData
				}
				for (let val of compShowHide){
					if(val === formId && item.type ==='position'){
						// 修改部位级联参数值
						item.config.__config__.cascade = optionData
					}
					if(val === formId){
						item.config.__slot__.options = optionData;
					}
				}
				if (item.value instanceof Array) {
					for (let ele of item.value){
						if(ele instanceof Array){
							for (const elItem of ele){
								let childFormId = elItem.config.__config__.formId;
								if(elItem.type === 'position'){
									// 修改部位级联参数值
									elItem.config.__config__.cascade = optionData
								}
								for (let val of compShowHide){
									if(val === formId && elItem.type ==='position'){
										// 修改部位级联参数值
										elItem.config.__config__.cascade = optionData
									}
									if(val === childFormId){
										elItem.config.__slot__.options = optionData;
									}
								}
							}
						}
					}
				}
			}
		} else if (ctrlType === 'selectFill') {
			// 下拉选择数据填充
			this.afterSelectFill(optionData)
		} else if(ctrlType === 'positionModel'){
			this.positionModel(optionData)
		} else if(compShowHide) {
			// 显示隐藏交互
			const ctrlFormId = Object.keys(compShowHide);
			for(let item of this.formValidate.items){
				const formId = item.config.__config__.formId;
				if(ctrlFormId.includes(formId)){
					item.config.__config__.addShow = compShowHide[formId]
				}
				if(item.type === 'row'){
					for (let eleItem of item.value){
						const childFormId = eleItem.config.__config__.formId;
						if(ctrlFormId.includes(childFormId)){
							eleItem.config.__config__.addShow = compShowHide[childFormId]
						}
					}
				}
				if(item.type === 'form' && !parentFormId){
					for (let element of item.value){
						for (let eleItem of element){
							if(eleItem.config){
								const childFormId = eleItem.config.__config__.formId;
								if(ctrlFormId.includes(childFormId)){
									eleItem.config.__config__.addShow = compShowHide[childFormId]
								}
							}
						}
					}
				}
				if(parentFormId === formId){
					if(item.value instanceof Array){
						for (let eleItem of item.value[index]){
							if(eleItem.config){ //关联子表展示数据无配置
								const childFormId = eleItem.config.__config__.formId;
								if(ctrlFormId.includes(childFormId)){
									eleItem.config.__config__.addShow = compShowHide[childFormId]
								}
							}
						}
					}
				}
			}
		}
	},
	validateDeal(value, index, nn, ii) {
		if (nn && ii) {
			this.formValidate.items[index].value[nn][ii].value = value;
		} else {
			this.formValidate.items[index].value = value;
		}
	},
	// 下拉选择填充数据
	afterSelectFill(fillData){
		const fillMatch = this.fillMatchData();
		const fillMatchKeys = Object.keys(fillMatch)
		const fillDataKeys = Object.keys(fillData)
		for (let item of this.formValidate.items){
			if(item.config.__config__.display === 'table-relate'){

			} else if(item.type === 'form') {
				for (let element of item.value) {
					for (let dd of element) {
						if(fillMatchKeys.length>0 && fillDataKeys.length>0){
							const formId = dd.config.__config__.formId;
							if(fillMatchKeys.includes(formId)){
								const matchVal = fillMatch[formId]
								if(fillDataKeys.includes(matchVal)){
									dd.value = fillData[matchVal]
								}
							}
						}
					}
				}
			}
			const bool = (item.value instanceof Array || item.value instanceof Object)
			if(fillMatchKeys.length>0 && fillDataKeys.length>0 && !bool){
				const formId = item.config.__config__.formId;
				if(fillMatchKeys.includes(formId)){
					const matchVal = fillMatch[formId]
					if(matchVal.indexOf('.')){
						// 多层级数据填充
						let arr = matchVal.split('.')
						if(fillDataKeys.includes(arr[0])){
							const goal = fillData[arr[0]]
							if(goal instanceof Array && goal.length > 0){
								let goalKeys = Object.keys(goal[0]);
								if(goalKeys.includes(arr[1])){
									item.value = goal[0][arr[1]]
								}
							} else if(goal instanceof Object){
								let goalKeys = Object.keys(goal);
								if(goalKeys.includes(arr[1])){
									item.value = goal[0][arr[1]]
								}
							}
						}
					}
					if(fillDataKeys.includes(matchVal)){
						item.value = fillData[matchVal]
					}
				}
			}
		}
	},
	//初始化表单验证数据
	async getFormValidate(fields) {
		const fillMatch = this.fillMatchData(); // 获取填充规则
		let fillData = Object.assign(this.fillData,this.currentInfo); //附加填充数据（当前登录数据）
		const tempFillData = sessionStorage.getItem("_formFilleData");
		if(tempFillData) {
			fillData = Object.assign(fillData, JSON.parse(tempFillData));
		}
		// const data = await formValidate(fields,fillMatch,fillData);
		let url = `api/form4/formValidate?module_uuid=${this.moduleUuid}`
		const response = await formRequest('get', url, {});
		const data = response.data.data.items; //表单验证初始数据

		// 数据匹配填充
		const fillMatchKeys = Object.keys(fillMatch)
		const fillDataKeys = Object.keys(fillData)
		if(fillMatchKeys.length>0 && fillDataKeys.length>0){
			for(let item of data){
				const formId = item.config.__config__.formId;
				if(fillMatchKeys.includes(formId)){
					const matchVal = fillMatch[formId]
					if(fillDataKeys.includes(matchVal)){
						item.value = fillData[matchVal]
						//填充数据禁用输入
					}
				}
				if (['row','form'].includes(item.type) && item.value instanceof Array) {
					for (let element of item.value) {
						if(element instanceof Array){
							for (let ele of element) {
								if(element.config){
									const childFormId = element.config.__config__.formId;
									if(fillMatchKeys.includes(childFormId)){
										const matchVal = fillMatch[childFormId]
										if(fillDataKeys.includes(matchVal)){
											element.value = fillData[matchVal]
											//填充数据禁用输入
										}
									}
								}
							}
						} else {
							const childFormId = element.config.__config__.formId;
							if(fillMatchKeys.includes(childFormId)){
								const matchVal = fillMatch[childFormId]
								if(fillDataKeys.includes(matchVal)){
									element.value = fillData[matchVal]
									//填充数据禁用输入
								}
							}
						}
					}
				}
			}
		}
		// 表单数据回显
		const existRecover = Object.keys(this.displayData);
		if (existRecover.length > 0) {
			for (let item of data) {
				if (item.type === "form") {
					const eleConf = item.config.__config__;
					const childValue = this.displayData[item.field];
					if(childValue instanceof Array && childValue.length>0){ //编辑时关联子表数据为空，展示第一条数据
						if(!existRecover.includes(item.field)) return
						let cycleData = this.displayData[item.field];
						if (cycleData && eleConf.display !== 'table-relate') {
							let goalData = item.value[0],
								valueData = [];
							for (let ele of cycleData) {
								//深拷贝问题，表单同步变化bug
								let itemData = JSON.parse(JSON.stringify(goalData));
								itemData.map((item1) => {
									const tagIcon = item1.config.__config__.tagIcon;
									let multiple = item1.config.multiple || ''
									if(item1.type === 'treeSelect') multiple =  item1.config.props.props.multiple
									const valueWait = ele[item1.field];
									item1.value = formatValue(tagIcon,valueWait,multiple);
								});
								valueData.push(itemData);
							}
							item.value = valueData;
						} else if (cycleData && eleConf.display === 'table-relate'){
							//关联子表数据回显处理
							let valueData = await this.recoverRelateData(eleConf,cycleData,data)
							valueData.length ? item.value = valueData : '';
						}
					}
				} else if (item.type === "row") {
					if (item.value) {
						for (let eleItem of item.value) {
							const tagIcon = eleItem.config.__config__.tagIcon;
							let multiple = eleItem.config.multiple || ''
							if(eleItem.type === 'treeSelect') multiple =  eleItem.config.props.props.multiple
							if(existRecover.includes(eleItem.field)){
								const valueWait = this.displayData[eleItem.field];
								eleItem.value = formatValue(tagIcon,valueWait,multiple);
							}
						}
					}
				} else {
					const tagIcon = item.config.__config__.tagIcon;
					let multiple = item.config.multiple || ''
					if(item.type === 'treeSelect') multiple =  item.config.props.props.multiple
					if(existRecover.includes(item.field)){
						const valueWait = this.displayData[item.field];
						item.value = formatValue(tagIcon,valueWait,multiple);
					}
				}
			}
		}
		this.formValidate.items = this.formValidate.items.concat(data);
		this.skeleton = false;
		// 动态数据异步加载
		this.asyncLoadingData();
	},
	/**
	 * 优先渲染表单，异步加载动态数据
	 */
	async asyncLoadingData(){
		const data = this.formValidate.items;
		for (let item of data){
			if(['select','user','cascader','treeSelect','row','form'].includes(item.type)){
				switch (item.type){
					case 'select':
					case 'user':
					case 'cascader':
					case 'treeSelect':
						this.loadingDynamicData(item);
						break;
					case 'row':
					case 'form':
						const eleConf = item.config.__config__;
						if (item.type === "form" && eleConf.display === 'table-relate') {
							// 关联子表数据填充
							const autoFill = eleConf.relateConfig.__config__.autoFill || '';
							if(autoFill){
								let valueData = await this.autoFillRelateData(eleConf,data,item.field)
								valueData.length ? item.value = valueData : [];
							}
						}
						for (let element of item.value) {
							if(element instanceof Array){
								// 子表
								for (let ele of element) {
									if(['select','user','cascader','treeSelect'].includes(ele.type)){
										this.loadingDynamicData(ele);
									}
								}
							} else {
								// row布局
								if(['select','user','cascader','treeSelect'].includes(element.type)){
									this.loadingDynamicData(element);
								}
							}
						}
				}
			}
		}
	},
	/**
	 * 动态加载组件数据（treeSelect、cascader、user）
	 * @param item
	 */
	loadingDynamicData(item){
		const filterRule = item.config.__config__.filterRule ?? []
		const objectUuid = item.config.__config__.objectUuid
		const viewUuid = item.config.__config__.viewUuid
		const dynamic = item.config.__config__.dataType

		if(item.type === 'select' && dynamic === 'static'){
			return false; //select 静态数据
		}

		let url= '/api/mapi', params;
		if(item.type === 'user'){
			params = {
				__method_name__: 'dataList', object_uuid: objectUuid,
				view_uuid: viewUuid, transcode: 0,
			}
		} else{
			let searchData = getSearchData(filterRule,this.getFormIdValue);
			params = {
				__method_name__: 'dataList', object_uuid: objectUuid,
				view_uuid: viewUuid, search: searchData, size: 1000,
			}
			if(searchData.length) params.search = searchData
		}
		if(objectUuid && viewUuid){
			formRequest('post', url, params).then(res => {
				let respData;
				respData = res.data.data;
				if (res.data.data.data) {
					respData = res.data.data.data;
				}

				if(item.type === 'user'){
					const userData = {list:respData, type:item.config.__config__.dataType}
					item.config.__config__.userData = userData
					if(Object.keys(this.displayData).includes(item.field)){
						item.value = this.displayData[item.field];
					}
				} else if(item.type === 'select'){
					const tag = item.config.__config__.tagIcon;
					const propLabel = item.config.props.props.label;
					const propValue = item.config.props.props.value;
					const options = optionData(respData,tag,propLabel,propValue)
					if(options){
						item.config.__slot__.options = options
					}
				} else {
					const tag = item.config.__config__.tagIcon;
					const propLabel = item.config.props.props.label;
					const propValue = item.config.props.props.value;
					const propChild = item.config.props.props.children || [];
					const options = optionData(respData,tag,propLabel,propValue,propChild)
					if(options){
						item.config.options = options
						this.key = uniqid(); //强制渲染
					}
				}
			});
		}
	},
	// 下拉选择自动填充关联数据
	async selectAutoFillRelate(selectFormId){
		for (let item of this.formValidate.items) {
			const eleConf = item.config.__config__;
			if (item.type === "form" && eleConf.display === 'table-relate') {
				const autoFill = eleConf.relateConfig.__config__.autoFill || '';
				// 下拉选择时，不依赖此控件值时不请求填充子表数据，子表数据置空bug
				const filterRule = eleConf.relateConfig.__config__.filterRule;
				const hasFilter = Array.from(filterRule,item=>item.value)
				if(!hasFilter.includes(selectFormId)) return;
				/***************************子表数据置空bug End************************/
				// Object.keys(this.displayData) === 0 有详情数据时不再自动加载关联数据
				if(autoFill){
					let valueData = await this.autoFillRelateData(eleConf,this.formValidate.items,item.field)
					valueData.length ? item.value = valueData : '';
				}
			}
		}
	},
	// 恢复填充数据
	async recoverRelateData(eleConf, cycleData,formData) {
		const dataType = eleConf.relateConfig.__config__.dataType;
		const objectUuid = eleConf.relateConfig.__config__.objectUuid || '';
		const viewUuid = eleConf.relateConfig.__config__.viewUuid || '';
		const vModelKey = eleConf.relateConfig.__vModel__;
		const vModelVal = Array.from(cycleData, item => item[vModelKey]);
		const openFilter = eleConf.relateConfig.__config__.openFilter || false;
		const relateFilterRule = eleConf.relateConfig.__config__.filterRule || [];
		let relateFormId = eleConf.relateConfig.__config__.formId;

		let searchData = []; //过滤条件
		if(openFilter){
			for (let item of relateFilterRule){
				let searchItem = {ruleType: item.ruleType, value: item.value};
				if(item.valueType === 2){
					let formIdField = '';
					for (let dataItem of formData){
						if(dataItem.config.__config__.formId === item.value){
							formIdField = dataItem.field //关联数据依赖表单参数过滤
						}
					}
					searchItem.value = this.displayData[formIdField] || ''
				}
				if(item.code) searchItem.code = item.code
				if(item.field_uuid) searchItem.code = item.field_uuid
				searchData.push(searchItem)
			}
		}

		let url,params;
		if(objectUuid && viewUuid && !vModelVal.includes(undefined)){
			url = '/api/mapi'
			params = {
				__method_name__: 'dataList',
				object_uuid: objectUuid,
				view_uuid: viewUuid,
				search: searchData,
				// transcode: 0,
			}
		} else if(dataType === 'interface') {
			url = '/api/form4/funcDataList'
			let param = {};
			let paramData = eleConf.relateConfig.__config__.params;
			for (let item of paramData){
				param[item.name] = item.value
			}
			params = {
				function:eleConf.relateConfig.__config__.function,
				params: param,
				search: searchData, //通用搜索
			}
		}
		if(url && params){
			let response = await formRequest('post', url, params);
			let responseData;
			if (response.data.data.data) {
				responseData = response.data.data.data
			} else {
				responseData = response.data.data
			}
			const metadata = response.data.metadata
			let idKey = ''; //查找id的键字段
			for (let key in metadata){
				if(metadata[key] === 'id'){
					idKey = key; break;
				}
			}
			let rows = responseData.filter(
				item => (vModelVal.includes(item[idKey]) || vModelVal.includes(item[idKey]+''))
			);
			const configData = eleConf.relateConfig.__slot__.options;
			let itemValue=[];
			if(rows instanceof Array && cycleData instanceof Array && rows.length){
				for (let itemC of cycleData){
					let itemData = [];
					for (let item of configData) {
						if(item.element){
							let fieldKey = item.element.field
							// 后端数据格式化
							const tagIcon = item.element.config.__config__.tagIcon;
							let multiple = item.element.config.multiple || ''
							if(item.element.type === 'treeSelect')
								multiple = item.element.config.props.props.multiple
							const valueWait = itemC[fieldKey];
							item.element.value = formatValue(tagIcon,valueWait,multiple)
							let conf = JSON.parse(JSON.stringify(item.element))
							if(['user','cascader','treeSelect'].includes(conf.type)){
								this.loadingDynamicData(conf);
							}
							const confR = eleConf.relateConfig;
							const rowFV = itemC[confR.__vModel__];
							let row = rows.find(item1=>item1[idKey] == rowFV)
							//关联子表下拉动态数据依赖关联数据ID
							const options = await this.dynamicSelectData(conf,relateFormId,row[idKey])
							if(options) conf.config.__slot__.options = options;
							// conf.other = row
							itemData.push(conf)
						} else {
							let conf = JSON.parse(JSON.stringify(item))
							const confR = eleConf.relateConfig;
							const rowFV = itemC[confR.__vModel__];
							let row = rows.find(item1=>item1[idKey] == rowFV)
							conf.text = row?.[conf.value];
							conf.field = confR?.__vModel__;
							conf.value = row?.[idKey]
							itemData.push(conf)
						}
					}
					itemValue.push(itemData)
				}
			}
			return itemValue;
		}
		return [];
	},
	// 自动填充关联数据
	async autoFillRelateData(eleConf,formData,field) {
		//关联数据不点击选择按钮，筛选条件自动填充
		const dataType = eleConf.relateConfig.__config__.dataType;
		const objectUuid = eleConf.relateConfig.__config__.objectUuid || '';
		const viewUuid = eleConf.relateConfig.__config__.viewUuid || '';
		const openFilter = eleConf.relateConfig.__config__.openFilter || false;
		const relateFilterRule = eleConf.relateConfig.__config__.filterRule || [];

		let searchData = []; //过滤条件
		if(openFilter){
			for (let item of relateFilterRule){
				let searchItem = {ruleType: item.ruleType, value: item.value};
				if(item.valueType === 2){
					let formIdField = '';
					for (let dataItem of formData){
						if(dataItem.config.__config__.formId === item.value){
							if(['in','notin'].includes(item.ruleType) && !(item.value instanceof Array)){
								searchItem.value = [dataItem.value]
							}else{
								searchItem.value = dataItem.value
							}
							formIdField = dataItem.field //关联数据依赖表单参数过滤
						}
					}
					// item.value = this.displayData[formIdField] || ''
				}
				if(item.code) searchItem.code = item.code
				if(item.field_uuid) searchItem.code = item.field_uuid
				searchData.push(searchItem)
			}
		}

		let url,params;
		if(objectUuid && viewUuid){
			url = '/api/mapi'
			params = {
				__method_name__: 'dataList',
				object_uuid: objectUuid,
				view_uuid: viewUuid,
				search: searchData,
				// transcode: 0,
			}
		} else if(dataType === 'interface') {
			url = '/api/form4/funcDataList'
			let param = {};
			let paramData = eleConf.relateConfig.__config__.params;
			for (let item of paramData){
				param[item.name] = item.value
			}
			params = {
				function:eleConf.relateConfig.__config__.function,
				params: param,
				search: searchData, //通用搜索
			}
		}
		if(url && params){
			let response = await formRequest('post', url, params);
			let responseData;
			if (response.data.data.data) {
				responseData = response.data.data.data
			} else {
				responseData = response.data.data
			}
			const metadata = response.data.metadata
			let idKey = ''; //查找id的键字段
			for (let key in metadata){
				if(metadata[key] === 'id'){
					idKey = key; break;
				}
			}
			let rows = responseData
			const configData = eleConf.relateConfig.__slot__.options;
			let itemValue=[];
			if(rows instanceof Array && rows.length){
				rows.forEach((itemC,index)=>{
					let itemData = [];
					let fillData = {};
					for (let item of configData) {
						if(item.element){
							let conf = JSON.parse(JSON.stringify(item.element))
							if(['select','user','cascader','treeSelect'].includes(conf.type)){
								this.loadingDynamicData(conf);
							}
							// 关联数回显 - 存在详情数据时
							const relateIdKey = eleConf.relateConfig.__vModel__;
							const recoverKeys = Object.keys(this.displayData);
							if(recoverKeys.includes(field)){
								const cycleData = this.displayData[field]
								const goal = cycleData.filter(el=>el[relateIdKey] == itemC[idKey])
								if(goal.length){
									conf.value = goal[0][conf.field]
								}
							}
							// conf.other = row
							itemData.push(conf)
						} else {
							let conf = JSON.parse(JSON.stringify(item))
							const confR = eleConf.relateConfig;
							conf.text = itemC[conf.value];
							conf.field = confR.__vModel__;
							fillData[idKey] = conf.text //关联数据项子表数据填充
							conf.value = itemC[idKey]
							itemData.push(conf)
						}
					}
					/******************子表数据填充start*****************/
					const fillMatch = this.fillMatchData();
					const fillMatchKeys = Object.keys(fillMatch)
					const fillDataKeys = Object.keys(fillData)
					for (let ele of itemData){
						if(ele.config){
							const formId = ele.config.__config__.formId;
							if(fillMatchKeys.includes(formId)){
								const matchVal = fillMatch[formId]
								if(fillDataKeys.includes(matchVal)){
									ele.value = fillData[matchVal]
								}
							}
						}
					}
					/******************子表数据填充end*****************/
					itemValue.push(itemData)
				})
				return itemValue
			}
		}
		return [];
	},
	// 获取表单输入数据
	getFormData() {
		// 采集表单输入数据
		let formSubmitData = {};
		for (let ele of this.formValidate.items) {
			if (ele.type === "form") {
				let fieldData = [];
				const equalField = ele.config.__config__.addShow;
				if(equalField === undefined || equalField){ // 存在交互时，并且字段相同，数据相互覆盖bug
					for (let eleData of ele.value) {
						let itemField = {};
						for (let eleItem of eleData) {
							itemField[eleItem.field] = eleItem.value;
						}
						fieldData.push(itemField);
					}
					formSubmitData[ele.field] = fieldData;
				}
			} else if (ele.type === "row") {
				if (ele.value) {
					for (let eleItem of ele.value) {
						formSubmitData[eleItem.field] = eleItem.value;
					}
				}
			} else {
				if (ele.field) {
					formSubmitData[ele.field] = ele.value;
				}
			}
		}

		// 流程附加人员数据（审批人，抄送人，退回选择步骤）
		if (this.selectedUsers.target_users && this.selectedUsers.target_users.length > 0) {
			formSubmitData.target_users = this.selectedUsers.target_users
		}
		if (this.selectedUsers.send_users && this.selectedUsers.send_users.length > 0) {
			formSubmitData.send_users = this.selectedUsers.send_users
		}
		if (this.selectStep) formSubmitData.select_step = this.selectStep
		if (this.flowEngineId) formSubmitData.flow_engine_id = this.flowEngineId

		return formSubmitData;
	},
	// 流程中编辑获取表单数据
	editFieldsInput(){
		let formSubmitData = {};
		const editFormId = Object.keys(this.editFields);
		for (let ele of this.formValidate.items) {
			if (ele.type === "form") {
				let fieldData = [];
				const equalField = ele.config.__config__.addShow;
				if(equalField === undefined || equalField){ // 存在交互时，并且字段相同，数据相互覆盖bug
					for (let eleData of ele.value) {
						let itemField = {};
						for (let eleItem of eleData) {
							itemField[eleItem.field] = eleItem.value;
						}
						fieldData.push(itemField);
					}
					if(fieldData.length) formSubmitData[ele.field] = fieldData;
				}
			} else if (ele.type === "row") {
				if (ele.value) {
					for (let eleItem of ele.value) {
						const isEditFormId = editFormId.includes(eleItem.config.__config__.formId)
						if(isEditFormId){
							formSubmitData[eleItem.field] = eleItem.value;
						}
					}
				}
			} else {
				const isEditFormId = editFormId.includes(ele.config.__config__.formId)
				if (ele.field && isEditFormId) {
					formSubmitData[ele.field] = ele.value;
				}
			}
		}
		this.editFieldsValue = formSubmitData;
		return formSubmitData
	},
	// 获取 formId与字段key匹配值
	getFormIdField(){
		let formSubmitData = {};
		for (let ele of this.formValidate.items) {
			if (ele.type === "form") {
				let formId = ele.config.__config__.formId;
				for (let eleData of ele.value) {
					for (let eleItem of eleData) {
						if(eleItem.config){
							let itemFormId = eleItem.config.__config__.formId;
							formSubmitData[itemFormId] = eleItem.field;
						}
					}
				}
				formSubmitData[formId] = ele.field;
			} else if (ele.type === "row") {
				if (ele.value) {
					for (let eleItem of ele.value) {
						let formId = ele.config.__config__.formId;
						formSubmitData[formId] = eleItem.field;
					}
				}
			} else {
				if (ele.field) {
					let formId = ele.config.__config__.formId;
					formSubmitData[formId] = ele.field;
				}
			}
		}
		return formSubmitData;
	},
	// formId获取表单值
	getFieldValue(formId,flag){
		let formSubmitData = this.getFormData();
		let keys = Object.keys(formSubmitData);
		const formIdField = this.getFormIdField()

		const newFormId = formIdField[formId] || ''
		const fieldValue = formSubmitData[newFormId] || 0

		if(flag) {
			return fieldValue
		}

		let number = keys.includes(newFormId)
			? (parseFloat(fieldValue) ? parseFloat(fieldValue) : 0)
			: 0;

		return number;
	},
	// 执行公式计算
	carryCalculate(){
		this.formValidate.items.forEach(item => {
			if(item.type === 'input' && item.config.__config__.openRule
					&& item.config.__config__.calcRules.length
			){
				const calcRules = item.config.__config__.calcRules
				let express = '';
				for (let ele of calcRules) {
					if(ele.ruleType === 'FUNCTION'){
						express += ele.funcName
					} else if(ele.ruleType === 'COMPONENT_VALUE'){
						express += `calcField('`+ele.value+`')`
					} else if(ele.ruleType === 'STATIC'){
						if (parseInt(ele.value)){
							express += ele.value
						} else {
							express += `'`+ele.value+`'`
						}
					} else {
						express += ele.displayName
					}
				}
				try {
					let value = calcImpl(express)
					if(value && item.config?.precision){
						value = parseFloat(value).toFixed(item.config.precision);
					}
					if(value >= 0) item.value = ''+value
				} catch (e) {
					console.error(e, '公式执行错误信息')
					this.$message.error('计算公式配置错误');
					return
				}
			}
		})
	},
	handleReset() {
		this.$emit('on-cancel');
	},
	handleSubmit() {
		//流程审批人员判断提示
		if (
			this.needFlow && this.checkPickerNum !== 0 &&
			this.checkPickerNum !== this.selectedUsers.target_users.length
		) {
			this.$message.error('审批人未选择完整！')
			return false
		}
		let formSubmitData = this.getFormData();
		console.log('表单数据：',formSubmitData)
		this.$refs.formParser.validate((valid) => {
			if (valid) {
				let formSubmitData = this.getFormData();
				this.formSubmitData = formSubmitData;
				this.$emit("submit", formSubmitData);
			} else {
				this.formSubmitData = null;
				this.$message({
					showClose: true,
					message: "数据校验失败，请仔细核对必填项字段！",
					type: "error",
				});
				this.$emit("closeLoading");
			}
		});
	},
	// 外部表单提交数据
	async outerFormSubmit(formSubmitData){
		if(this.outerObjectUuid && this.outerFormType === 1){
			let url = '/api/mapi',
				data = {
					__method_name__: 'createData',
					object_uuid: this.outerObjectUuid,
				}
			Object.assign(data,formSubmitData);
			let response = await formRequest('post', url, data);
			if(response.data.code === 200){
				if(this.outerFormIndex.three !== undefined){
					let first = this.outerFormIndex.first
					let second = this.outerFormIndex.second
					let three = this.outerFormIndex.three
					let id = response.data.data.id
					this.changeEleValue(first,second,three,id)
				} else {
					const first = this.outerFormIndex.first
					const id = response.data.data.id
					this.formValidate.items[first].value = id
				}
				this.$message.success(response.data.msg)
				this.outerFormVisible = false;
			} else {
				this.$message.error(response.data.msg)
			}
		} else if(this.outerFormUuid && this.outerFormType === 2){

		}
	},
	// 添加子表数据
	addChildForm(index,data) {
		const firstRow = this.firstChildRow[index] // 子表第一行初始数据
		//深拷贝问题，表单同步变化bug
		let itemData = [];
		const newCopyData = JSON.parse(JSON.stringify(firstRow))
		for (let ele of newCopyData) {
			if(ele.config.__config__.addShow){ //隐藏字段不重置
				if (typeof ele.value == "string") {
					ele.value = "";
				} else if (typeof ele.value == "number") {
					ele.value = 0;
				} else if(typeof ele.value == "boolean") {
					ele.value = false;
				} else {
					ele.value = [];
				}
			}
			ele.config.disabled = false;
			let itemDd = {
				config: ele.config,
				field: ele.field,
				type: ele.type,
				validate: ele.validate,
				value: ele.value,
			};
			itemData.push(itemDd);
		}
		data.push(itemData);
	},
	// 删除子表数据
	delChildForm(index, data) {
		data.splice(index, 1);
	},
	// 获取动态筛选条件
	getDynamicFilter(formData, goalData = []){
		for (let item of formData){
			if(['database','select','treeSelect'].indexOf(item.__config__.tagIcon)>-1){
				if(item.__config__.filterRule && item.__config__.filterRule instanceof Array){
					goalData.push(item.__config__.formId)
				}
			}
			if(item.__config__.children && item.__config__.children.length > 0){
				this.getDynamicFilter(item.__config__.children,goalData)
			}
		}
		return goalData;
	},
	// 根据表单ID获取控件值 -- 只能获取主表字段值
	getFormIdValue(formId){
		const formIdField = this.getFormIdField();
		const formSubmitData = this.getFormData();
		const fieldKey = formIdField[formId] || ''
		const fieldValue = formSubmitData[fieldKey] || ''
		return fieldValue;
	},
	// 控件值改变请求依赖数据（数据筛选联动）
	async setDynamicData(){
		const dynamicFormId = this.getDynamicFilter(this.formData.fields);
		for (let item of this.formValidate.items){
			let formId = item.config.__config__.formId;
			if(dynamicFormId.includes(formId)){
				if(item.config.__config__.filterRule && item.config.__config__.filterRule instanceof Array){
					let searchData = getSearchData(item.config.__config__.filterRule,this.getFormIdValue);
					let respData = await getRequestData(item.config.__config__.objectUuid,item.config.__config__.viewUuid,searchData)
					if(respData.length){
						let tag = item.config.__config__.tagIcon;
						let propLabel = item.config.props.props.label;
						let propValue = item.config.props.props.value;
						let propChild = item.config.props.props.children || '';
						let options = optionData(respData,tag,propLabel,propValue,propChild)
						if(item.config.__slot__.options){
							item.config.__slot__.options = options;
						}
					}
				}
			}
			if (item.value instanceof Array) {
				for (let itemValue of item.value){
					for (let ele of itemValue){
						let childFormId = ele.config.__config__.formId;
						if(dynamicFormId.includes(childFormId)){
							if(ele.config.__config__.filterRule && ele.config.__config__.filterRule instanceof Array){
								let searchData = getSearchData(ele.config.__config__.filterRule,this.getFormIdValue);
								let respData = await getRequestData(ele.config.__config__.objectUuid,ele.config.__config__.viewUuid,searchData)
								if(respData.length){
									let tag = ele.config.__config__.tagIcon;
									let propLabel = ele.config.props.props.label;
									let propValue = ele.config.props.props.value;
									let propChild = ele.config.props.props.children || '';
									let options = optionData(respData,tag,propLabel,propValue,propChild)
									if(ele.config.__slot__.options){
										ele.config.__slot__.options = options;
									}
								}
							}
						}
					}
				}
			}
		}
	},
	// 部位改变（部位绑定模型）联动模型
	positionModel(model){
		for (const element of this.formValidate.items){
			if(element.type === 'bimList'){
				let arr = []
				if(model.length!=0){
					for(let i=0; i<model.length; i++){
						arr = arr.concat(model[i])
					}
					element.type = ''
					this.$nextTick(()=>{
						element.value = arr
						element.type = 'bimList'
					})
				}



			}
		}
	},
	hidOperate() {
		this.operateDrawer = false
	}
}
