
<!--
 * @Description: 标记容器（同大屏容器）
 * @Author: Shiltin
 * @Date: 2021-08-18 13:48:12
 * @LastEditors: zx
 * @LastEditTime: 2022-12-07 09:05:57
-->
<template>
	<div class="console-sign-cont"></div>
</template>
<script>
import { judgingArchiAPermission, initParams } from '@/utils/tools';
import eventBus from '@/plugins/eventBus';
import { dataInterface } from '@/apis/data/index';
import { getComponentById, findModelComponentId } from '@/utils/tools';
import { mapState } from 'vuex';


export default {
  name: 'CommonScreenContainer',
  props: {
    element: {
      type: Object,
      required: true,
      default: () => {}
    },
		// 是否为预览
		isPreview: {
			type: Boolean,
			required: false,
			default: true
		}
  },
	watch: {
		isGis: {
      handler(newVal,oldVal){
				if(newVal !== oldVal){
					this.onHideGisSigns(newVal)
				}
      },
      deep: true
    },
		isBim:{
			handler(newVal,oldVal){
				if(newVal !== oldVal){
					this.onHideBimSigns(newVal)
				}
			},
			deep: true
		}
	},
  data() {
		return {
			signList:[],
			bimComponentId:'',
			gisComponentId:'',
			isAccumulate: true,//标记连续显示
			timer:null,//防止触发多次
			loadedSignsType:null,
			wheelTimer: null,
			timeOuter: null, //计时器
		};
	},
  computed: {
		...mapState(['componentData']),
		//判断标记容器是bim还是gis
		signType () {
			return this.element.statusConfig.signType || ''
		},
		isBim () {
			let bimComponent = this.componentData.filter(v => v.component === 'ViewerBIM')
			if(bimComponent.length && bimComponent[0].statusConfig?.displayShow && bimComponent[0].statusConfig?.isShow){
				return true
			}else{
				return false
			}
		},
		//是否是gis页面显示
		isGis () {
			let gisComponent = this.componentData.filter(v => v.component === 'ViewerGIS')
			if(gisComponent.length && gisComponent[0].statusConfig?.displayShow && gisComponent[0].statusConfig?.isShow){
				return true
			} else {
				return false
			}
		},
  },
	created(){
  /**
   * @description: 解决标记阻挡鼠标mousewheel事件，pointerEvents：none
   * @param {*} event
   * @return {*}
   */		
		window.onmousewheel = (event) => {
			const path = event.path || (event.composedPath && event.composedPath())
			if(!path || !path.length){
				return
			}
			clearTimeout(this.timeOuter)
			this.wheelTimer = setTimeout(() => {
				clearTimeout(this.wheelTimer)
				if (path?.length) {
					path.forEach((item) => {
						if (item.id && item.id.includes('-view')) {
							document.getElementById(item.id).parentNode.style.pointerEvents = 'none';
							this.timeOuter = setTimeout(()=>{
								document.getElementById(item.id).parentNode.style.pointerEvents = 'auto';
							},1500)
						}
					})
				}
			}, 150)
		}
	},
	mounted() {
		this.bimComponentId = findModelComponentId(this.componentData,'ViewerBIM') || '';
		this.gisComponentId = findModelComponentId(this.componentData,'ViewerGIS') || ''
		this.getSignList()
		//监听行为
		eventBus.$on('doComponentBehavior', config => {
			//区分gis和bim行为
			if(this.signType && this.signType === 'bim'){
				if(!this.isBim){
					return false
				}
			} else 	if(this.signType && this.signType === 'gis'){
				if(!this.isGis){
					return false
				}
			}
			const { component, list = [] } = config;
			let componentName = component.split('-')[0]
			if (!this.element.id.includes(componentName)) return false;
			list.forEach(ele => {
				const { behaviors, params } = ele;
				//获取携带的参数id
				const { param = {}, canPost } = initParams(params, false, this.componentData, []);
				//获取标记的signConfig
				let signContent = null;
				let type = '';
				if(params?.length){
					for(let i = 0; i < params.length; i++) {
						if(params[i].componentId){
							let paramComponent = getComponentById(this.componentData, params[i].componentId);
							if(paramComponent.signConfig?.show && paramComponent.signConfig?.pageUuid){
								//将视图作为判断类别的依据
								if(paramComponent.database?.viewData!==null){
									type = paramComponent.database.viewData.uuid
								}else if(paramComponent.contentConfig?.viewUUID){
									type = paramComponent.contentConfig.viewUUID
								}
								let findData = this.signList.filter((v)=>v.uuid === paramComponent.signConfig?.pageUuid)
								if(findData.length){
									signContent = findData[0].page_data.componentData
								}
								break;
							}
						} else if(params[i].sourceType === 'fixed' && params[i].key === 'sign_uuid'){
							let findData = this.signList.filter((v)=>v.uuid === params[i].fixedValue)
							if(findData.length){
								signContent = findData[0].page_data.componentData
							}
						}else if(params[i].key === 'view_uuid'){
							type = params[i].fixedValue
						}
						
					}
				}
				if (canPost) {
					// 调用行为方法
					behaviors.forEach(funName => {
						try {
							eval(this[funName])(param,signContent,type)
						} catch (err) {
							console.log(err);
						}
					});
				}
			});
		})
		//监听坐标
		if(this.signType && this.signType === 'bim'){
			eventBus.$on('Viewer.onCameraChange', (res) => {
				for (let key in res) {
					let data = res[key]
					let findData = this.componentData.filter( (v) => v.id.includes(data.id))
					if(findData.length === 0) {
						return
					}
					for(let i = 0; i < findData.length; i++){
						this.$store.commit('modifyComponent', {
							component: {
								...findData[i],
								style:{
									...findData[i].style,
									left:data.point.x - (Number(findData[i].style.width ) / 2),
									top:data.point.y - (Number(findData[i].style.height ))  
								}
							},
							containerId: null, 
							isModify: true
						});
					}
				}
			})
		}
		if(this.signType && this.signType === 'gis'){
			//注册监听相机视角变化事件
			eventBus.$emit('ViewerGIS.registerCameraChange', this.gisComponentId);
			eventBus.$on('ViewerGIS.onCameraChange', () =>{
					//经纬度转屏幕坐标，参数为经纬度对象，以及转换后的回调
					if(this.componentData?.length){
						this.componentData.forEach((val)=>{
							if(val.gisPosition){
								eventBus.$emit('ViewerGIS.getCanvasByWgs', this.gisComponentId, val.gisPosition, (position) => {
										if(position){
											val.style.left = position.x - (Number(val.style.width) / 2);
											val.style.top = position.y - (Number(val.style.height ));
											this.$store.commit('modifyComponent', {
												component: {
													...val,
												},
												containerId: null, 
												isModify: true
											});
										}
								});
							}
						})
					}
			});
		}
	},
  methods: {
    judgingArchiAPermission,
		/**
		 * @desc: 请求获取标记绑定的模板
		 */
		getSignList(){
			dataInterface({
				object_uuid: "a4f016d6-c602-4492-8874-f088c3c0b3b9",
				page: 1,
				search: [
					{field_uuid: "field61bc41f645e17", ruleType: "like", value: "console"},
					{field_uuid: "d1f7e149-2af7-468e-85be-5ef9bd97f634", ruleType: "like", value: "样式"}
				],
				size: 1000,
				transcode: 0,
				view_uuid: "view6311790375749",
				__method_name__: "dataList",
			}).then((res) => {
				if(res.data.code === 200){
					this.signList = res.data.data.data
				}
			})
		},
		/**
		 * @desc: 清除所有标记
		 */
		clearAll(){
			if(this.componentData?.length){
				for (var i = 0; i <this.componentData.length; i++) {
					if(this.componentData[i].id.includes('-view')){
						this.$store.commit('deleteComponent', this.componentData[i]);
					}
				}
			}
		},
		/**
		 * @desc: 获取id集合
		 */
		getAllIds (data = [], arr){
			if(data.length){
				for(let i=0;i<data.length;i++){
					if(data[i]?.id){
						arr.push(data[i].id)
					}
					if(data[i]?.children){
						this.getAllIds(data[i].children, arr)
					}
				}
			}
			return arr
		},
		/**
		 * @desc: 格式化数据，解决data-id重复得问题
		 */
		formatContent(arr, data, code){
			if(arr.length){
				for(let j = 0; j < arr.length; j++){
					if(data.length){
						for(let i = 0; i < data.length; i++){
							
							arr[j] = JSON.parse(JSON.stringify(arr[j]).replaceAll(data[i],data[i] + '-' + code))
						}
					}
				}
			}
			return arr
		},
		/**
		 * @desc: 显示单个标记
		 */
		showSign(param,content,type){
			clearTimeout(this.timer);
      this.timer = setTimeout(() => {
				let data = JSON.parse(JSON.stringify(content))
				if((param?.data_id  && content && type && ((param.model_info?.length && this.bimComponentId) || (this.isGis && this.gisComponentId && param.gis_info)))) {
					//格式化数据
					let code = `${type}-${param.data_id}`
					let findData = this.componentData.filter(v => v.id.includes(code))
					//已渲染该标记
					if (findData.length) {
						for (let i = 0; i < findData.length; i++){
							this.$store.commit('modifyComponent', {
								component: {
									...findData[i],
									statusConfig:{
										...findData[i].statusConfig,
										isShow: true 
									}
								},
								containerId: null, 
								isModify: true
							});
							if(this.signType && this.signType === 'gis'){
								let gisPosition = null
								if(param.gis_info.position){
									gisPosition = param.gis_info.position
								} else if(param.gis_info.latitude && param.gis_info.longitude){
									gisPosition = {lat:param.gis_info.latitude,lng:param.gis_info.longitude}
								}
								eventBus.$emit('ViewerGIS.flyTo', this.gisComponentId, gisPosition, 400, 45, 2);
							}
						}
					} else {
						let newContent = null
						let idArr = []
						this.getAllIds(data, idArr)
						let formatContent = this.formatContent(data, idArr, code)
						//标记样式元素不是在一个容器中需要手动添加个容器
						if(data.length !== 1){
							this.$message.warning('标记模板请用大屏容器套入！')
							return
						} else {
							newContent = formatContent[0]
						}
						if(this.signType && this.signType === 'bim'){
							//bim构件转换世界坐标
							eventBus.$emit("ViewerBIM.getBoundingSphere", this.bimComponentId, param.model_info[0].dbids[0],param.model_info[0].model_id, async(res)=>{
								let point = await res
								//传值给黄旭凯监听模型的坐标
								eventBus.$emit("ViewerBIM.addPosition",this.bimComponentId, type +'-'+param.data_id ,point.center, async(re)=>{
									let position = await re
									newContent.style.left = position.x - (Number(newContent.style.width) / 2);
									newContent.style.top = position.y - (Number(newContent.style.height ));
									newContent.statusConfig.signType = 'bim'
									if(!this.isAccumulate){
										eventBus.$emit("ViewerBIM.Part.ClearAllColor",this.bimComponentId)
										//清除其他的数据方法
										this.hideOtherSign(code)
									}
									let findSameComponent = this.componentData.filter(v => v.id.includes(code))
									if(findSameComponent.length === 0){
										this.$store.commit('modifyComponent', {
											component: {
												...newContent,
											},
											containerId: null, 
											isModify: false
										});
									}
								})
							})
						}
						if(this.signType && this.signType === 'gis'){
							let gisPosition = null
							if(param.gis_info.position){
								gisPosition = param.gis_info.position
							} else if(param.gis_info.latitude && param.gis_info.longitude){
								gisPosition = {lat:param.gis_info.latitude,lng:param.gis_info.longitude}
							}
							//经纬度转屏幕坐标，参数为经纬度对象，以及转换后的回调
							eventBus.$emit('ViewerGIS.getCanvasByWgs', this.gisComponentId, gisPosition, (position) => {
									newContent.style.left = position.x - (Number(newContent.style.width) / 2);
									newContent.style.top = position.y - (Number(newContent.style.height ));
									this.$set(newContent,'gisPosition',gisPosition)
									newContent.statusConfig.signType = 'gis'
									if(!this.isAccumulate){
										eventBus.$emit("ViewerBIM.Part.ClearAllColor",this.bimComponentId)
										//清除其他的数据方法
										this.hideOtherSign(code)
									}
									let findSameComponent = this.componentData.filter(v => v.id.includes(code))
									if(findSameComponent.length === 0){
										this.$store.commit('modifyComponent', {
											component: {
												...newContent,
											},
											containerId: null, 
											isModify: false
										});
										eventBus.$emit('ViewerGIS.flyTo', this.gisComponentId, gisPosition, 400, 45, 2);
										eventBus.$emit('ViewerGIS.registerCameraChange', this.gisComponentId);
									}
							});
						}
						
					}
				}
        clearTimeout(this.timer);
      }, 200);
		},
		/**
		 * @desc: 隐藏其他的标记组件
		 */
		hideOtherSign(type){
			if(this.componentData?.length){
				for(var i=0; i <this.componentData.length; i++){
					if (this.componentData[i].id.includes('-view') && !this.componentData[i].id.includes(type)) {
						this.$store.commit('modifyComponent', {
							component: {
								...this.componentData[i],
								statusConfig:{
									...this.componentData[i].statusConfig,
									isShow: false
								}
							},
							containerId: null, 
							isModify: true
						});
					}
				}
			}
		},
		/**
		 * @desc: 隐藏单个标记
		 */
		// hideSign(param,content,type){
		// 	if(param?.data_id && type){
		// 		let code =`${type}-${param.data_id}`
		// 		for(var i=0; i <this.componentData.length; i++){
		// 			if (this.componentData[i].id.includes(code)) {
		// 				this.$store.commit('modifyComponent', {
		// 					component: {
		// 						...this.componentData[i],
		// 						statusConfig:{
		// 							...this.componentData[i].statusConfig,
		// 							isShow: false
		// 						}
		// 					},
		// 					containerId: null, 
		// 					isModify: true
		// 				});
		// 			}
		// 		}
		// 	}
		// },
		//移除标记
		hideSign(param,content,type){
			if(param?.data_id && type){
				let code =`${type}-${param.data_id}`
				for(var i=0; i <this.componentData.length; i++){
					if (this.componentData[i].id.includes(code)) {
						this.$store.commit('deleteComponent', this.componentData[i]);
					}
				}
			}
		},
		/**
		 * @desc: 标记连续显示
		 */
		onAccumulate(){
			this.isAccumulate = !this.isAccumulate;
		},
		/**
		 * @desc: 缓存隐藏gis标记
		 */
		onHideGisSigns(bol = false){
			if(this.componentData?.length){
						this.componentData.forEach((val)=>{
							if(val.gisPosition){
								let statusConfig = val.statusConfig;
								statusConfig.displayShow = bol
								this.$store.commit('modifyComponent', {
									component: {
										...val,
										statusConfig
									},
									containerId: null, 
									isModify: true
								});
							}
						})
					}
		},
		/**
		 * @desc: 缓存隐藏bim标记
		 */
		onHideBimSigns(bol = false){
			if(this.componentData?.length){
				this.componentData.forEach((val)=>{
					if(val.statusConfig?.signType === 'bim'){
						let statusConfig = val.statusConfig;
						statusConfig.displayShow = bol
						this.$store.commit('modifyComponent', {
							component: {
								...val,
								statusConfig
							},
							containerId: null, 
							isModify: true
						});
					}
				})
			}
		},
		/**
		 * @desc: 显示所有列表标记
		 */
		onShowTypeAllSigns(param,content,type){
			//关闭还打开所有类型的标价开始
			if(this.loadedSignsType === null){
				this.loadedSignsType = {}
				this.$set(this.loadedSignsType,type,true)
			} else {
				if(this.loadedSignsType[type] === undefined){
					this.$set(this.loadedSignsType,type,true)
				} else  {
					let findData = this.componentData.filter(v => v.id.includes(type))
					if (findData.length) {
						for (let i = 0; i < findData.length; i++){
							this.$store.commit('modifyComponent', {
								component: {
									...findData[i],
									statusConfig:{
										...findData[i].statusConfig,
										displayShow: !this.loadedSignsType[type]
									}
								},
								containerId: null, 
								isModify: true
							});
						}	
					} 
					this.loadedSignsType[type] = !this.loadedSignsType[type]
					return false
				}
			}
			//关闭还打开所有类型的标价结束
			
			if (param?.view_uuid && param?.object_uuid && param?.sign_uuid && param.sign_field) {
				let obj = {
					object_uuid:param.object_uuid,
					view_uuid:param.view_uuid,
					__method_name__: "dataList",
					page:1,
					size:1000,
					transcode: 0
				}
				dataInterface(obj).then((res) => {
					if(res.data.code === 200){
						if(res.data.data?.data?.length){
							res.data.data?.data.forEach((item) => {
								let data = JSON.parse(JSON.stringify(content))
								if(item[param.sign_field] && item[param.sign_field] !== null){
									let code = `${type}-${item.id}`
										let newContent = null
										let idArr = []
										this.getAllIds(data, idArr)
										let formatContent = this.formatContent(data, idArr, code)
										//标记样式元素不是在一个容器中需要手动添加个容器
										if(data.length !== 1){
											this.$message.warning('标记模板请用大屏容器套入！')
											return
										} else {
											newContent = formatContent[0]
										}
										if(this.signType && this.signType === 'bim' && item[param.sign_field].length){
											//bim构件转换世界坐标
											eventBus.$emit("ViewerBIM.getBoundingSphere", this.bimComponentId, item[param.sign_field][0].dbids[0],item[param.sign_field][0].model_id, async(res)=>{
												let point = await res
												//传值给黄旭凯监听模型的坐标
												eventBus.$emit("ViewerBIM.addPosition",this.bimComponentId, type +'-'+ item.id ,point.center, async(re)=>{
													let position = await re
													newContent.style.left = position.x - (Number(newContent.style.width) / 2);
													newContent.style.top = position.y - (Number(newContent.style.height ));
													newContent.statusConfig.signType = 'bim'
													this.$store.commit('modifyComponent', {
														component: {
															...newContent,
														},
														containerId: null, 
														isModify: false
													});
												})
											})
										}
										if(this.signType && this.signType === 'gis'){
											let gisPosition = null
											if(item[param.sign_field].position){
												gisPosition = item[param.sign_field].position
											} else if(item[param.sign_field].latitude && item[param.sign_field].longitude){
												gisPosition = {lat:item[param.sign_field].latitude,lng:item[param.sign_field].longitude}
											}
											//经纬度转屏幕坐标，参数为经纬度对象，以及转换后的回调
											eventBus.$emit('ViewerGIS.getCanvasByWgs', this.gisComponentId, gisPosition, (position) => {
													newContent.style.left = position.x - (Number(newContent.style.width) / 2);
													newContent.style.top = position.y - (Number(newContent.style.height ));
													this.$set(newContent,'gisPosition',gisPosition)
													newContent.statusConfig.signType = 'gis'
													this.$store.commit('modifyComponent', {
														component: {
															...newContent,
														},
														containerId: null, 
														isModify: false
													});
													// eventBus.$emit('ViewerGIS.flyTo', this.gisComponentId, gisPosition, 400, 45, 2);
													eventBus.$emit('ViewerGIS.registerCameraChange', this.gisComponentId);
											});
										}
								}	
							})
						}
					}
				})
			}
		},
  },
	unmounted() {
		eventBus.$off('doComponentBehavior');
		eventBus.$off('Viewer.onCameraChange');
		eventBus.$emit('ViewerGIS.logoutCameraChange' , this.gisComponentId);
		window.removeEventListener('mousewheel');
		clearTimeout(this.timeOuter)
		clearTimeout(this.wheelTimer)
	}
}
</script>

<style lang="less" scoped>
.console-sign-cont{
  height: 0!important;
  width: 0!important;
  position: absolute;
}
</style>