<template>
  <div class="footer-main">
    <div class="footer-content">
      <div class="footer-content-bimcc">
        <img
          src="https://static.bimcc.com/openim/logo9378E9A5019FFE9858905C4334E34218.png"
          alt=""
        />
        <p>重庆市筑云科技有限责任公司</p>
        <p>你身边的数字化专家</p>
      </div>
      <div class="footer-content-center" v-for="(item,index) in footerList" :key="index">
        <p v-for="(itemer,index) in item.firstData" :key="index" @click="challenger(itemer.name)" ><span :class="itemer.name? 'active' : 'activer'">{{itemer.content}}</span> </p>
      </div>
      <div class="footer-rightcontent">
        <div class="footer-content-right">
          <img
            src="https://static.bimcc.com/openim/%E5%85%AC%E5%8F%B8%E5%AE%98%E7%BD%91%E4%BA%8C%E7%BB%B4%E7%A0%81%281%29567C2310106ECAEDEF35DEEEDF22E8F9.png"
            alt=""
          />
          <p>筑云官网</p>
        </div>
        <div class="footer-content-right">
          <img
            src="https://static.bimcc.com/openim/BIMCC%E7%AD%91%E4%BA%91%E5%85%AC%E4%BC%97%E5%8F%B7%E4%BA%8C%E7%BB%B4%E7%A0%81%281%29657B359934AD8920B4176BAE2B2E1D1E.png"
            alt=""
          />
          <p>筑云公众号</p>
        </div>
      </div>
    </div>
    <div class="footer-footercontent">
      Copyright 2022. All Rights Reserved. 重庆市筑云科技有限责任公司版权所有（渝ICP备13004974号-1）
      <p class="gong-an">
        <img :src="GongAn" height="20" width="20" alt="">
        <span>渝公网安备13004974号-1号</span>
      </p>
    </div>
  </div>
</template>

<script>
import GongAn from '@/assets/images/gonganbeian.png'
export default {
  name: "PublicBackground",

  data() {
    return {
      GongAn,
      footerList:[
       {firstData:[{content:"产品"},{content:"数字孪生", name: "DigitalTwin",},{content:"智慧工地", name: "WisdomSite"},{content:"筑云课堂", name: "edu"}]},
       {firstData:[{content:"用户服务"},{content:"客户案例", name: 'CustomerCase'},{content:"模板", name: 'Template'},{content:"价格", name: 'Price'},{content:"隐私条款"}]},
       {firstData:[{content:"公司"},{content:"关于我们", name: 'AboutUs'},{content:"筑云学院", name: 'ZhuyunCollege'}]},
       {firstData:[{content:"联系我们",},{content:"023-68682379"},{content:"service@bimcc.com"},{content:"重庆市江北区南桥寺永辉大厦7楼"},{content:"北京市丰台区诺德中心二期11#2103"}]}
      ]
    };
  },

  mounted() {},

  methods: {
    challenger(name){
      this.$router.push({ name:name })
    }
  },
};
</script>

<style lang="less" scoped>
 .footer-main {
    width: 100%;
    cursor: text;
    background-image: url("https://static.bimcc.com/openim/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20220830161857628D87E2AB3847B8A32023634EAB5848.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .footer-content {
      width:1200px;
      margin: 0 auto;
      padding: 80px 0 30px 0;
      display: flex;
      cursor: text;
      justify-content: space-around;
      border-bottom: 1px solid #d4d4d4d4;
      .footer-content-bimcc {
        text-align: left;
        img {
          width: 120px;
        }
        p {
          padding-top: 10px;
          font-family: "Source Han Sans CN";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #4d4d4d;
          &:hover{
            cursor: text;
          }
        }
      }
      .footer-content-center {
        text-align: left;
        cursor: text;
        p {
          font-family: "Source Han Sans CN";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.05em;
          color: #494949;
          padding-top: 10px;
          box-sizing: border-box;
          cursor: text;
        }
        :nth-child(1) span {
          font-family: "Source Han Sans CN";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.05em;
          color: #494949;
          padding: 0;
        }
      }
      .footer-rightcontent {
        display: flex;
        .footer-content-right {
          &:nth-child(1){
            padding-right: 38px;
          }
          img {
            width: 110px;
          }
          p {
            font-family: "Source Han Sans CN";
            font-style: normal;
            text-align: center;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.05em;
            color: #494949;
            &:hover{
            cursor: text;
          }
          }
        }
      }
    }
    .footer-footercontent {
      font-family: "Source Han Sans CN";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      letter-spacing: 0.05em;
      color: #494949;
      padding: 14px 0 30px 0;
      .gong-an{
        margin-top: 15px;
        &>*{
          vertical-align: middle;
        }
      }
    }
  }
  .active{
    &:hover{
      color: #3370ff;
    }
  }
  .activer{
      &:hover{
    cursor: text !important;
      }
    }
// @media screen and (min-width: 1200px) and (max-width: 1600px) {
//   .footer-main {
//     .footer-content {
//       width: 1200px;
//       .footer-rightcontent {
//         .footer-content-right {
//           padding: 0 5px;
//           img {
//             width: 80px;
//           }
//         }
//       }
//     }
//   }
// }
// @media screen and (min-width: 1600px) {
//   .footer-main {
//     .footer-content {
//       width: 1200px;
//       padding: 80px 0 30px 0;
//       .footer-content-bimcc {
//         p {
//           font-size: 14px;
//         }
//       }
//       .footer-content-center {
//         p {
//           font-size: 14px;
//         }
//         :nth-child(1) span{
//           font-size: 16px;
//         }
//       }
//       .footer-rightcontent {
//         .footer-content-right {
//           padding: 0 5px;
//           img {
//             width: 100px;
//           }
//           p {
//             padding-top: 10px;
//           }
//         }
//       }
//     }
//     .footer-footercontent {
//       font-size: 14px;
//     }
//   }
// }
</style>
