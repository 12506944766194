import eventBus from '@/plugins/eventBus';
export default class ViewState {

    get id(){
        return "ViewState";
    }

    get data(){
        return {
        }
    }

    get watch (){
        return {

        }
    }

    get methods (){
        return {
            //行为注册
            viewstate_initBehaviors(){
                this.registerBehaviors([{
                    label : "[视点]添加视点",
                    desc : `
                        参数[ id ]。 id=>视点的id值 例如[vs00]
                    `,
                    value : "viewState_add",
                    func : (params)=>{
                        if(!params?.id ) return;
                        const { id } = params; 
                        const state = this.viewer.getState();
                        this.viewer.action('addViewState' , id , state);
                    },
                },{
                    label : "[视点]根据ID还原视点",
                    desc : `
                        参数[ id ]。 id=>视点的id值 例如[vs00]
                    `,
                    value : "viewState_set",
                    func : (params)=>{
                        if(!params?.id) return;
                        const { id } = params; 
                        this.viewer.action('setState', id );
                    },
                },{
                    label : "[视点]根据状态还原视点",
                    desc : `
                        参数[ state ]。 state=>视点对象状态JSON字符串
                    `,
                    value : "viewState_restoreState",
                    func : (params)=>{
                        if(!params?.state) return;
                        const { state } = params; 

                        this.viewer.restoreState( JSON.parse(state) );
                    },
                },]);
            },
            //回调行为的注册
            viewstate_initCallback(){
                this.registerCallback( 'ViewState.getState' , ( id )=>{
                    const state = this.viewer.getState();
                    eventBus.$emit("Viewer.callback" , id ,state);
                });
            },
        }
    }

    get func(){
        return {
            initViewerEventListener(){
                // todo viewer初始化完成事件
                // this.viewer.on(Viewer.Events.ViewerInited , ()=>{
                // });
            },
            mounted (){
                this.viewstate_initBehaviors();
                this.viewstate_initCallback();

                //根据id保存当前视点
                eventBus.$on('ViewerBIM.ViewState.SaveStateById' ,(eid , id)=>{
                    if( eid !== this.element.id)return;

                    const state = this.viewer.getState();
                    this.viewer.action('addViewState' , id , state);
                });

                //根据id 和状态保存视点
                eventBus.$on('ViewerBIM.ViewState.AddState' , (eid , id , state)=>{
                    if( eid !== this.element.id)return;

                    this.viewer.action('addViewState' , id , state);
                });

                eventBus.$on('ViewerBIM.ViewState.RestoreState' , (eid , state )=>{
                    if( eid !== this.element.id)return;

                    this.viewer.action('restoreState' , state);
                });

                //根据id还原视点
                eventBus.$on('ViewerBIM.ViewState.ViewById' ,( eid , id)=>{
                    if( eid !== this.element.id)return;

                    this.viewer.action('setState', id );
                });

								//获取视点状态
                eventBus.$on('ViewerBIM.ViewState.getState' ,(eid , cb)=>{
									if( eid != this.element.id)return;
									const state = this.viewer.getState();
									cb && cb(state);
								});

								//回显视点
                eventBus.$on('ViewerBIM.ViewState.backState' ,(eid , state)=>{
									if( eid != this.element.id)return;
									this.viewer.action('setState' , state);
								});
            }
        }
    }
}

