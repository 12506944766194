<!--
    @name: calcRules
    @description：calcRules
    @author: ZengWei
    @date: 2021-11-30 09:51
-->
<template>
  <div class="rule-container">
    <div class="rule-wrapper">
      <div class="wrap-l">
        <div class="scrollbar">
          <el-collapse v-model="activeNames">
            <el-collapse-item
              v-for="(item,index) in categoryFuncList"
              :key="index"
              :title="item.category"
              :name="index"
            >
              <el-menu class="el-menu-demo" mode="horizontal">
                <el-menu-item
                  v-for="(ele,ind) in item.functions"
                  :key="ind"
                >
                  <div @mouseover="hoverEvent(ele)" @click="insertExp(ele)">
                    <p class="fn-name">
                      {{ ele.displayName }}
                    </p>
                    <p class="fn-desc">
                      {{ ele.desc }}
                    </p>
                  </div>
                </el-menu-item>
              </el-menu>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
      <div class="wrap-r">
        <div class="rule-header">
          <span>{{ hoverRule.displayName }}</span>
          <p>{{ hoverRule.wiki }}</p>
        </div>
        <div class="rule-body">
          <div
            v-for="(item,index) in advancedRuleList"
            :key="index"
            class="expression-item"
            @click="curCursor(index)"
          >
            <div v-if="item.ruleType === 'FUNCTION'" class="tag">
              {{ item.displayName }}
            </div>
            <div v-else-if="item.ruleType === 'COMPONENT_VALUE'" class="field-label">
              {{ item.displayName }}
            </div>
            <div v-else-if="item.ruleType === 'STATIC'">
              <input v-model="item.value" type="text" class="static-inp" @focus="onFocus" @blur="offFocus">
            </div>
            <div v-else class="display-name">
              {{ item.displayName }}
            </div>
            <img v-if="cursorIndex === index" src="@/assets/images/editor.gif" class="focus">
            <div v-else class="focus" />
          </div>
        </div>
        <div class="rule-footer">
          <el-dropdown
            v-if="fieldsList.length > 0"
            trigger="click"
            placement="top"
            @command="insertExpBar"
          >
            <div class="rule-op-item">
              表单字段
            </div>
            <el-dropdown-menu slot="dropdown" style="height: 300px;overflow-y: auto;">
              <el-dropdown-item
                v-for="(item,index) in fieldsList"
                :key="index"
                :command="{ruleType: 'COMPONENT_VALUE',displayName: item.name,value:item.formId}"
              >
                <span>{{ item.name }}</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <div v-else class="rule-op-item">
            表单字段
          </div>

          <div
            v-for="(item,index) in expressBarList"
            :key="index"
            class="rule-op-item"
            @click="insertExpBar(item)"
          >
            {{ item.displayName }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import {Collapse,CollapseItem,Dropdown,DropdownMenu,DropdownItem,Menu,MenuItem} from "element-ui";
import calcRules from '@/custom-component/task-flow/components/calcRules'

export default {
  name: 'LogicRules',
  components: {
    'el-collapse': Collapse,
    'el-collapse-item': CollapseItem,
    'el-menu': Menu,
    'el-menu-item': MenuItem,
    'el-dropdown': Dropdown,
    'el-dropdown-menu': DropdownMenu,
    'el-dropdown-item': DropdownItem,
  },
  props: {
    fieldsList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      activeNames: 0,
      hoverRule: calcRules.categoryFuncList[0].functions[0],
      categoryFuncList: calcRules.categoryFuncList,
      expressBarList: calcRules.expressBarList,
      // advancedRuleList:calcRules.advancedRuleList,
      advancedRuleList: [],
      cursorIndex: 0,
      focus: false
    }
  },
  created() {
    const _this = this
    document.onkeydown = function (e) {
      const e1 = e || event || window.event
      if (e1 && e1.keyCode === 37) {
        // 左按键
        _this.moveCursor(e1.keyCode)
      } else if (e1 && e1.keyCode === 39) {
        // 右按键
        _this.moveCursor(e1.keyCode)
      } else if (e1 && e1.keyCode === 8) {
        // 后退删除 -- 表单聚焦失去焦点判断
        if (!_this.focus) {
          _this.delRule(e1.keyCode)
          return false
        }
      }
    }
  },
  methods: {
    onFocus() {
      this.focus = true
    },
    offFocus() {
      this.focus = false
    },
    hoverEvent(element) {
      this.hoverRule = element
    },
    curCursor(index) {
      this.cursorIndex = index
    },
    moveCursor(code) {
      if (code === 37) {
        if (this.cursorIndex !== 0) {
          this.cursorIndex--
        }
      } else if (this.cursorIndex < this.advancedRuleList.length - 1) {
        this.cursorIndex++
      }
    },
    delRule() {
      if (this.cursorIndex >= 0) {
        this.advancedRuleList.splice(this.cursorIndex, 1)
        this.cursorIndex--
      }
    },
    insertExpBar(element) {
      const cloneNode = JSON.parse(JSON.stringify(element))
      if (this.cursorIndex.length - 1 === this.cursorIndex) {
        this.advancedRuleList.push(cloneNode)
      } else {
        const insertIndex = this.cursorIndex + 1
        this.advancedRuleList.splice(insertIndex, 0, cloneNode)
      }
      this.cursorIndex++
    },
    insertExp(item) {
      for (const ele of item.defaultFuncList) {
        this.insertExpBar(ele)
      }
    },
    getAdvancedRules() {
      return this.advancedRuleList
    }
  }
}
</script>
<style>
  .el-dropdown-menu{
    padding: 10px;
  }
  .el-dropdown-menu::-webkit-scrollbar{
    width: 7px;
    height: 7px;
    background-color: #F5F5F5;
  }

  /*定义滚动条轨道 内阴影+圆角*/
  .el-dropdown-menu::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
  }

  /*定义滑块 内阴影+圆角*/
  .el-dropdown-menu::-webkit-scrollbar-thumb{
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, .1);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .1);
    background-color: #c8c8c8;
  }
</style>
<style lang="less" scoped>

.scrollbar{
  overflow-y: auto;
  height: 100%;
}

.rule-container {
  height: 100%;

  .rule-wrapper {
    height: 100%;
    display: flex;
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;

    .wrap-l {
      width: 273px;
      height: 100%;
      border-right: 1px solid #e6e6e6;
      overflow: hidden;

      .scrollbar {
        height: 100%;

        :deep(.el-collapse) {
          border: none;

          .el-collapse-item__header {
            border: none;
          }

          .el-collapse-item__wrap {
            border: none;

            .el-collapse-item__content {
              border: none;
              padding-bottom: 0;

              .el-menu.el-menu--horizontal {
                border: none;
              }

              .el-menu-item {
                width: 100%;
                line-height: 50px;
                height: 50px;

                .fn-name {
                  margin: 0;
                  font-size: 12px;
                  line-height: 20px;
                  color: #303133;
                  font-weight: 400 !important;
                }

                .fn-desc {
                  margin: 0;
                  line-height: 20px;
                  font-size: 12px;
                  color: #b2b2b2;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              ;
              }
            }
          }
        }
      }
    }

    .wrap-r {
      flex: 1;
      display: flex;
      flex-flow: column;

      .rule-header {
        padding: 16px 32px;
        border-bottom: 1px solid #ebeef5;

        span {
          font-size: 12px;
          color: #303133;
        }

        p {
          margin: 4px 0;
          font-size: 12px;
          color: #b2b2b2;
        }
      }

      .rule-body {
        flex: 1;
        padding: 24px 32px;
        overflow-y: auto;
        user-select: none;

        .expression-item {
          margin: 4px 0;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          height: 24px;
          float: left;
          font-size: 12px;

          .static-inp {
            width: 45px;
            border: 1px solid #b2b2b2;
            outline-style: none;
            border-radius: 3px;
            padding: 3px;
          }

          .tag {
            background-color: #f5f7fa;
            padding: 6px;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            height: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
          }

          .field-label {
            color: #fff;
            font-size: 12px;
            background-color: #027aff;
            padding: 6px 8px;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            height: 24px;
            line-height: 24px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            border-radius: 2px;
            max-width: 300px;
          }

          .focus {
            width: 2px;
            height: 16px;
            margin: 0 4px;
          }
        }
      }

      .rule-footer {
        padding: 24px 32px;
        display: flex;
        justify-content: space-around;

        .rule-op-item {
          cursor: pointer;
          min-width: 22px;
          height: 10px;
          line-height: 10px;
          text-align: center;
          border: 1px solid #ebeef5;
          padding: 10px 4px;
          border-radius: 2px;
          color: #b2b2b2;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
