import { render, staticRenderFns } from "./ConsoleSignCont.vue?vue&type=template&id=c20aaf36&scoped=true&"
import script from "./ConsoleSignCont.vue?vue&type=script&lang=js&"
export * from "./ConsoleSignCont.vue?vue&type=script&lang=js&"
import style0 from "./ConsoleSignCont.vue?vue&type=style&index=0&id=c20aaf36&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c20aaf36",
  null
  
)

export default component.exports