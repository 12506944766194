<!--
 * @Author: zqy
 * @Date: 2022-06-17 18:32:04
 * @LastEditTime: 2023-01-10 09:08:00
 * @Description: 
 * @LastEditors: 朱廷果 1028509503@qq.com
-->
<template>
  <div class="notice" v-loading="loading">
    <header>
      <div class="left">
        <div
          @click="readStatus = 'unread'"
          style="display: flex; align-items: center"
        >
          <h4
            class="title"
            :style="
              readStatus === 'unread' ? 'color: #2A2F3D;' : 'color: #6D768F;'
            "
          >
            通知公告
          </h4>
          <div class="number">
            <p>{{ unreadList && unreadList.length }}</p>
          </div>
        </div>
      </div>
      <div class="right">
        <i class="iconfont iconxiangyoujiantou2" @click="goNotice"></i>
      </div>
    </header>
    <ul class="notice-list">
      <template v-if="showNoticeList && showNoticeList.length">
        <li
          v-for="(item, index) in showNoticeList"
          :key="index"
          class="list-item"
          @click="openDetail(item)"
        >
          <div class="left">
            <el-badge is-dot class="item" v-if="readStatus === 'unread'">
              <div class="badge" :class="getClass(item)">
                {{ item.type }}
              </div>
            </el-badge>
            <div class="badge" v-else :class="getClass(item)">
              {{ item.type }}
            </div>
            <p
              class="title"
              :style="
                readStatus === 'unread' ? 'color:#6D768F' : 'color:#2A2F3D'
              "
            >
              {{ item.name }}
            </p>
           
          </div>
          <div
            class="right"
            :style="readStatus === 'unread' ? 'color:#A9ADB8' : 'color:#6D768F'"
          >
            {{ getFormatDate(item.created_at) }}
          </div>
        </li>
      </template>
      <el-empty v-else description="暂无数据" :image-size="100" image="https://saas.bimcc.com/img/empty-light.b090acd9.png"></el-empty>
    </ul>
    <Detail
    :drawer='drawer' 
    :data='data' 
    @drawerClose="drawerClose"
    >

    </Detail>
  </div>
</template>
<script>
import { getSaasHomeNotice } from '@/api/saasManage'
import Detail from './Detail.vue'
import { Badge, Empty } from 'element-ui'
const BLUETYPES = ['公告', '公报', '决议', '决定']
export default {
  components: {
    'el-badge': Badge,
    'el-empty': Empty,Detail
    // DetailDailog
  },
  props: {
    archi_id: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {
      drawer:false,
      noticeList: [],
      data_id: '',
      detailShow: false,
      dailogTitle: '',
      readStatus: 'unread', //未读  read
      unreadList: [],
      readList: [],
      loading: false,
      data:null,
    }
  },
  computed: {
    showNoticeList() {
      if (this.readStatus === 'unread') {
        return this.unreadList
      } else {
        return this.readList
      }
    },
  },
  mounted() {
    this.getNoticeList()
  },
  methods: {
    getReadStatus(user) {
      const userId = this.$GetUserInfo('id')
      if (user && Array.isArray(user) && user.length) {
        let flag = false
        for (let i = 0; i < user.length; i++) {
          if (+user[i].yong_id === +userId && user[i].is_read) {
            //已读
            flag = true
          }
        }
        if (flag) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    drawerClose() {
      this.data = null
      this.drawer = !this.drawer
    },
    openDetail(item) {
      this.data = item
      this.drawer = true
    },
    getClass(item) {
      if (BLUETYPES.indexOf(item.notice_type) !== -1) {
        return 'blue-card'
      } else {
        return 'purple-card'
      }
    },
    getFormatDate(created_at) {
      const date = new Date(created_at)
      const year = date.getFullYear()
      const month = (date.getMonth() + 1).toString()
      const day = date.getDate().toString()
      if (created_at) {
        return `${year}/${month}/${day}`
      } else {
        return '-'
      }
    },
    getNoticeList() {
      this.loading = true
      getSaasHomeNotice()
        .then((res) => {
          this.loading = false
          if (res && +res.data.code == 200) {
            let data = res.data.data
            this.noticeList = data
            this.unreadList = []
            this.readList = []
            data.forEach((item) => {
              let flag = +item.is_read == 1 ? true : false
              if (flag) {
                this.readList.push(item)
              } else {
                this.unreadList.push(item)
              }
            })
          }
        })
        .catch(() => (this.loading = false))
    },
    goNotice() {
      this.$router.push({
        name: 'Notice',
      })
    },
  },

}
</script>
<style lang="less" scoped>
.blue-card {
  background: #ddf1fb;
  color: #31b4f5;
}
.purple-card {
  background: #f0eaff;
  color: #9c86ff;
}
.notice {
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 8px;
  padding: 16px 20px;
  box-sizing: border-box;
  // overflow-y: auto;
  display: flex;
  flex-direction: column;
  header {
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: space-between;
    padding: 4px 0;
    .left {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #6d768f;
      display: flex;
      align-items: center;
      .number {
        margin-left: 8px;
        height: 18px;
        line-height: 18px;
        display: inline-block;
        padding: 0 8px;
        border-radius: 10px;
        background: #33bbff;
        color: #fff;
        font-size: 14px;
      }
    }
    .right {
      i {
        font-size: 20px;
      }
    }
  }
  .notice-list {
    width: 100%;
    flex: 1;
    overflow-y: auto;
    .list-item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 12px 0;
      .left {
        display: flex;
        width: calc(100% - 70px);
        .badge {
          width: 34px;
          height: 18px;
          line-height: 18px;
          text-align: center;
          // background: rgb(229,240,250);
          border-radius: 2px;
          font-size: 12px;
          // color: #099FEE;
          font-family: YouSheBiaoTiHei;
          white-space: nowrap;
          flex-shrink: 0;
        }
        .title {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #2a2f3d;
          padding-left: 8px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .right {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #6d768f;
        padding-right: 8px;
      }
    }
  }
}
</style>
