<!--
    @name: CommonGridContainer
    @description：CommonGridContainer
    @author: ZengWei
    @date: 2022-06-22 14:21
-->
<template>
  <div class="container-layout">
    <div class="wrapper">
      <div class="header">
        <div class="head-wrap" v-if="element.statusConfig.toolbar">
          <div class="head-l">
            <span class="panel-name">
              <i class="iconfont iconzhuomian"></i>
              {{element.statusConfig.panelName}}
            </span>
          </div>
          <div class="head-r">
<!--            <span>
               <i class="iconfont iconshezhi"></i> 页面布局
            </span>-->
            <span class="pd-10" @click="add" v-if="!isPreview">
               <i class="iconfont iconxinzeng"></i> 添加面板
            </span>
            <span @click="fullScreen">
              <i class="iconfont"
                 :class="{
                'icondaping-quanping':!isFullScreen,
                'icondaping-feiquanping':isFullScreen}"
                 style="font-size: 14px"></i> 全屏显示
            </span>
          </div>
        </div>
      </div>
      <div class="grid-container">
        <grid-layout
          ref="gridlayout"
          :layout.sync="element.layoutData"
          :col-num="12"
          :row-height="33"
          :is-draggable="true"
          :is-resizable="true"
          :is-mirrored="false"
          :vertical-compact="true"
          :auto-size="element.statusConfig.autoSize"
          :preventCollision="element.statusConfig.preventCollision"
          :margin="[15, 15]"
          :use-css-transforms="true"
        >
          <grid-item
            v-for="(item,index) in element.layoutData"
            :style="{backgroundColor: item.bgColor}"
            :key="item.id"
            :x="item.x"
            :y="item.y"
            :w="item.w"
            :h="item.h"
            :i="item.i">
            <div class="panel-title">
              <p>{{item.name}}</p>
              <el-popover
                v-if="!isPreview"
                placement="bottom-start"
                width="120px"
                trigger="hover">
                <i slot="reference" class="el-icon-more more-action"></i>
                <div>
                  <div class="action-bar" @click="rename(index)">
                    <i class="iconfont iconwangpan-bianji1x"></i> 重命名面板
                  </div>
<!--                  <div class="action-bar">
                    <i class="iconfont iconwangpan-fuzhi1x"></i> 复制
                  </div>-->
                  <div class="action-bar">
                    <i class="iconfont iconjishuchuangxin"></i> 背景色
                    <el-color-picker style="margin-left: 5px" size="mini" v-model="item.bgColor"></el-color-picker>
                  </div>
                  <div class="action-bar" @click="del(index)">
                    <i class="iconfont iconwangpan-shanchu1x"></i>删除此面板
                  </div>
                </div>
              </el-popover>
            </div>
            <div
              class="drawing-board"
              @drop="onDropDown($event,item.id)"
              @dragover="onDragover"
              @contextmenu="onContextMenu"
            >
              <ComponentBox
                v-for="eleItem in gridItemComponents(item.id)"
                :key="eleItem.id"
                :element="eleItem"
                :isPreview="isPreview"
              ></ComponentBox>
            </div>
          </grid-item>
        </grid-layout>
      </div>
    </div>

    <!-- 右击菜单 -->
    <EditorMenu v-if="!isPreview"></EditorMenu>
  </div>
</template>

<script>
import {Popover,ColorPicker} from "element-ui";
import VueGridLayout from 'vue-grid-layout'
import ComponentBox from "@/components/normalPage/ComponentBox";
import EditorMenu from '@/components/editor/EditorMenu';
import componentList from "@/custom-component/component-list";
import {deepCopy, getComponentId} from "@/utils/tools";
import {mapState} from "vuex";
import {uniqid} from "@/utils";

export default {
  name: "CommonGridContainer",
  components: {
    ComponentBox,
    EditorMenu,
    'el-popover': Popover,
    'el-color-picker': ColorPicker,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem
  },
  props: {
    // 组件对象
    element: {
      type: Object,
      default: () => {},
      required: true
    },
    // 是否为预览
    isPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    // 弹窗页面配置的ID
    pageId: {
      type: Number,
      required: false
    },
    // 是否为分组
    isGroup: {
      type: Boolean,
    },
    // 当前分组的组件数据
    groupComponents: {
      type: Array,
      default: () => []
    }
  },
	inject: ['EDITOR_pageUUID'],
  data() {
    return {
      isFullScreen: false
    }
  },
  computed: {
    ...mapState([
      'canvasStyle', // 画布样式
      'componentData', // 图层列表
      'isClickComponent', // 是否点击组件
      'sContainer',
      'originComponentData' // 大屏容器组件的元组件
    ]),
    gridItemComponents(){
      return function(gridItemId){
        const containerId = this.element.id
        const container = this.componentData.filter(item=>item.id === containerId)
        if(container.length && container[0]?.children && container[0].children instanceof Array){
          return container[0].children.filter(item=>item.gridItemUuid === gridItemId)
        }
        return []
      }
    }
  },
  mounted() {
  },
  methods: {
    fullScreen(){
      this.isFullScreen = !this.isFullScreen
      if(this.isFullScreen){
        const de = document.documentElement;
        if (de.requestFullscreen) {
          de.requestFullscreen();
        } else if (de.mozRequestFullScreen) {
          de.mozRequestFullScreen();
        } else if (de.webkitRequestFullScreen) {
          de.webkitRequestFullScreen();
        }
      } else {
        if(document.exitFullscreen) {
          document.exitFullscreen();
        } else if(document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if(document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
      }
    },
    rename(index){
      this.$prompt('请输入面板名称', '提示', {
        inputValue: this.element.layoutData[index].name,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputErrorMessage: '请输入面板名称'
      }).then(({ value }) => {
        this.element.layoutData[index].name = value
      }).catch(() => {

      });
    },
    add(){
      const max = this.element.layoutData.length - 1
      const last = this.element.layoutData[max]
      let x = 0
      if(last.x < 9){
        x = last.x + 3
      }
      const newPanel = {
        id: uniqid(),name:'',bgColor:'',"x":x,"y":last.y+6,"w":3,"h":6,"i":""+(max+1)
      }
      this.element.layoutData.push(newPanel)
    },
    del(index){
      this.$confirm('此操作将删除该面板, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.element.layoutData.splice(index,1)
      }).catch(() => {
      });
    },
    /**
     * @desc: 放置拖放
     */
    onDropDown(e,gridItemId) {
      e.stopPropagation();
      e.preventDefault();

      if (this.isPreview) return;
      const groupIndex = e.dataTransfer.getData('group-index');
      const comIndex = e.dataTransfer.getData('index');
      if (
        isNaN(groupIndex) ||
        isNaN(comIndex) ||
        !Array.isArray(componentList) ||
        !componentList.length
      ) {
        this.$message.error('获取组件失败！');
        return;
      }
      const group = componentList[groupIndex];
      if (
        !group ||
        !group.componentList ||
        !Array.isArray(group.componentList)
      ) {
        this.$message.error('获取组件失败！');
        return;
      }
      const component = deepCopy(group.componentList[comIndex]);
      component.id = getComponentId(component.component);
      component.id = getComponentId(component.component);
      component.parentId = '';
      // 组件嵌套方式
      const target = e.target;
      let containerId = null;
      if (target instanceof SVGElement || target.className.includes('normal')) {
        console.log('放置到画布根节点上!');
      } else {
        // console.log('放置到容器中!1111111', target);
        // 1、当前放置位置为容器则在容器中追加
        // 2、获取当前放置节点的第一个祖先容器并放置
        const parentContainer = this.getParentContainer(target);
        if (parentContainer) {
          containerId = parentContainer.containerId;
          component.parentId = containerId;
        }
      }
      // 将组件写入GridItem
      component.gridItemUuid = gridItemId
      // 将组件写入到图层列表
      this.$store.commit('modifyComponent', { component, containerId, pageUUID: this.EDITOR_pageUUID });
      // 添加快照
      this.$store.commit('recordSnapshot');
      e.dataTransfer.setData('group-index', -1);
      e.dataTransfer.setData('index', -1);
    },
    /**
     * @desc: 拖动进入放置区域
     */
    onDragover(e) {
      e.stopPropagation();
      e.preventDefault();
      if (this.isPreview) return;
      e.dataTransfer.dropEffect = 'copy';
    },
    /**
     * @desc: 获取距离当前节点最近的容器ID
     * @param {Object} target 当前节点
     * @return {String} 返回节点树上距离当前节点最近的容器ID
     */
    getParentContainer(target) {
      if (!target || typeof target !== 'object') return null;
      if (target.dataset && target.dataset.id) {
        return {
          target,
          containerId: target.dataset.id
        };
      }
      return this.getParentContainer(target.parentNode);
    },
    /**
     * @desc: 打开右击菜单
     */
    onContextMenu(e) {
      e.stopPropagation();
      e.preventDefault();
      if (this.isPreview) return;
      /* eslint-disable */
      // 计算菜单相对于编辑器的位移
      // 此部分在嵌套组件时存在问题 留待进一步修改 question1
      let target = e.target;
      let top = e.layerY;
      let left = e.layerX;
      while (target instanceof SVGElement) {
        target = target.parentNode;
      }
      // console.log(target, '---');
      // if (target.className.includes('common-model-content')) {
      // 	left = e.offsetY + ((this.canvasWidth - target.offsetWidth) / 2);
      // 	top = e.offsetY + ((this.canvasHeight - target.offsetHeight) / 2);
      // 	// console.log(left, top, '----')
      // } else {
      while (!target.className.includes('normal')) {
        left += target.offsetLeft;
        top += target.offsetTop;
        target = target.parentNode;
      }
      top = top - 40
      // }
      this.$store.commit('showEditorMenu', {
        left,
        top
      });
      // this.$store.commit('showEditorMenu', {
      // 	left: e.clientX + e.layerX,
      // 	top: e.clientY + e.layerY
      // });
    },
  },
}
</script>

<style lang="less" scoped>
:deep(.vue-grid-layout) {

  .vue-grid-item{
    border-radius: 8px;
    border: 1px solid #eeeeee;

    .panel-title{
      position: relative;
      line-height: 35px;
      text-align: left;
      text-indent: 10px;
      font-size: 14px;
      padding-right: 35px;

      p{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .more-action{
        position: absolute;
        top: 12px;
        right: 12px;
        cursor: pointer;
        z-index: 100;
      }
    }

    .vue-resizable-handle{
      padding: 0 5px 5px 0;
    }

    &:hover{
      border: 1px solid #ADBEFF;
    }

  }

  .vue-grid-placeholder{
    background-color: #ADBEFF;
    border: 1px dashed 	#0000CD !important;
  }
}

.drawing-board{
  width: 100%;
  height: 100%;
}

.action-bar {
  align-items: center;
  border-radius: 6px;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  padding: 6px 8px 6px 12px;
  width: 100%;
  font-size: 14px;

  &:hover{
    background-color: #F2F5FF;
    color: #5B7AF6;
  }
  .iconfont {
    margin-right: 8px;
    font-size: 16px;
  }
}

.container-layout{
  width: 100%;
  height: 100%;
  overflow-y: auto;

  .wrapper{
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }

  .header{
    height: 35px;
    .head-wrap{
      display: flex;
      line-height: 50px;
      padding: 0 20px;
      justify-content: space-between;
      .head-l{
        .panel-name{
          margin-right: 15px;
          .iconzhuomian{
            font-size: 14px;
            margin-right: 5px;
          }
        }
      }
      .head-r {
        .pd-10{
          padding: 0 10px;
        }
      }
    }
  }
}

</style>
