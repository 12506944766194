import IBehaviors from "./IBehaviors";

export default class ModelBehaviors extends IBehaviors{
    constructor(viewer, comp){
        super(viewer, comp);
        this.viewer = viewer;
        this.comp = comp;

        this.init();
    }

    init(){
        const list = this.getBehaviors();

        this.registerBehaviors(list);
    }

    getBehaviors(){
        const parseParamDbids = ( _dbids )=>{
            return _dbids.split(",").map(( str )=>{
                return parseInt(str);
            });
        };

        return [
            {
                label : "[模型]加载模型",
                desc : `
                    参数[ modelId, isLocation ]。 
                    modelId=>模型ID 例如[2dedf572913146578ba838e14db32948]
                    isLocation=>是否加载完成后定位模型 例如[true] 备注:参数选择：true/false，选填
                    `,
                value : "model_create",
                func : (params)=>{
                    if(!params?.modelId) return;
                    if(!this.viewer) return this.comp.$message.error('操作失败，viewer未初始化');

                    const { modelId, isLocation = 'false' } = params;
                    this.viewer.Model.createModel(modelId, JSON.parse(isLocation));
                },
            },
            {
                label : "[模型]删除模型",
                desc : `
                    参数[ modelId ]。 
                    modelId=>模型ID 例如[2dedf572913146578ba838e14db32948]
                    `,
                value : "model_remove",
                func : (params)=>{
                    if(!params?.modelId) return;
                    if(!this.viewer) return this.comp.$message.error('操作失败，viewer未初始化');

                    const { modelId } = params;
                    this.viewer.Model.removeModel(modelId);
                },
            },
            {
                label : "[模型]定位模型",
                desc : `
                    参数[ modelId ]。 
                    modelId=>模型ID 例如[2dedf572913146578ba838e14db32948]
                    `,
                value : "model_location",
                func : (params)=>{
                    if(!params?.modelId) return;
                    if(!this.viewer) return this.comp.$message.error('操作失败，viewer未初始化');

                    const { modelId } = params;
                    this.viewer.Model.locationModel(modelId);
                },
            },
            {
                label : "[模型]显示隐藏模型",
                desc : `
                    参数[ modelId, isShow ]。 
                    modelId=>模型ID 例如[2dedf572913146578ba838e14db32948]
                    isShow=>模型显示或隐藏 例如[true] 备注:参数选择：true/false，默认false
                    `,
                value : "model_show",
                func : (params)=>{
                    if(!params?.modelId) return;
                    if(!this.viewer) return this.comp.$message.error('操作失败，viewer未初始化');

                    const { modelId, isShow = 'false' } = params;
                    this.viewer.Model.showModel(modelId, JSON.parse(isShow));
                },
            },
            {
                label : "[模型]选中构件",
                desc : `
                    参数[ modelId, dbids, isLocation ]。 
                    modelId=>模型ID 例如[2dedf572913146578ba838e14db32948]
                    dbids=>构件id列表 例如[6,7]
                    isLocation=>是否定位构件，选填，默认false
                    `,
                value : "model_selectFeature",
                func : (params)=>{
                    if(!params?.modelId || !params?.dbids ) return;
                    if(!this.viewer) return this.comp.$message.error('操作失败，viewer未初始化');

                    const { modelId, dbids, isLocation = 'false' } = params;
                    this.viewer.Model.selectFeature(modelId, parseParamDbids(dbids), JSON.parse(isLocation));
                },
            },
            {
                label : "[模型]隔离构件",
                desc : `
                    参数[ modelId, dbids, isLocation ]。 
                    modelId=>模型ID 例如[2dedf572913146578ba838e14db32948]
                    dbids=>构件id列表 例如[6,7]
                    isLocation=>是否定位构件，选填，默认false
                    `,
                value : "model_insulateFeature",
                func : (params)=>{
                    if(!params?.modelId || !params?.dbids ) return;
                    if(!this.viewer) return this.comp.$message.error('操作失败，viewer未初始化');

                    const { modelId, dbids, isLocation = 'false' } = params;
                    this.viewer.Model.insulateFeature(modelId, parseParamDbids(dbids), JSON.parse(isLocation));
                },
            },
            {
                label : "[模型]显示隐藏构件",
                desc : `
                    参数[ modelId, dbids, isShow, isLocation ]。 
                    modelId=>模型ID 例如[2dedf572913146578ba838e14db32948]
                    dbids=>构件id列表 例如[6,7]
                    isShow=>构件显示或隐藏，默认false
                    isLocation=>是否定位构件，选填，默认false
                    `,
                value : "model_showFeature",
                func : (params)=>{
                    if(!params?.modelId || !params?.dbids ) return;
                    if(!this.viewer) return this.comp.$message.error('操作失败，viewer未初始化');

                    const { modelId, dbids, isShow = 'false', isLocation = 'false' } = params;
                    this.viewer.Model.showFeature(modelId, parseParamDbids(dbids), JSON.parse(isShow), JSON.parse(isLocation));
                },
            },
            {
                label : "[模型]显示隐藏全部构件",
                desc : `
                    参数[ modelId, isShow ]。 
                    modelId=>模型ID 例如[2dedf572913146578ba838e14db32948]
                    isShow=>全部构件显示或隐藏，默认false
                    `,
                value : "model_showAllFeature",
                func : (params)=>{
                    if(!params?.modelId) return;
                    if(!this.viewer) return this.comp.$message.error('操作失败，viewer未初始化');

                    const { modelId, isShow = 'true' } = params;
                    this.viewer.Model.showAllFeature(modelId, JSON.parse(isShow));
                },
            },
            {
                label : "[模型]定位构件",
                desc : `
                    参数[ modelId, dbids, duration ]。 
                    modelId=>模型ID 例如[2dedf572913146578ba838e14db32948]
                    dbids=>构件id列表 例如[6,7]
                    duration=>定位到构件的飞行时长，默认0.5s
                    `,
                value : "model_locationFeature",
                func : (params)=>{
                    if(!params?.modelId || !params?.dbids ) return;
                    if(!this.viewer) return this.comp.$message.error('操作失败，viewer未初始化');

                    const { modelId, dbids, duration = '0.5' } = params;
                    this.viewer.Model.locationFeature(modelId, parseParamDbids(dbids), Number(duration));
                },
            },
        ];
    }


}
