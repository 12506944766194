import { removeEventBus } from '@/utils/tools';
import { mapState } from 'vuex';

export default {
  props: {
    // 组件对象
    element: {
      type: Object,
      default: () => { },
      required: true
    },
    // 是否为分组
    isGroup: {
      type: Boolean
    },
    // 当前分组的组件数据
    groupComponents: {
      type: Array,
      default: () => []
    },
    // 表格默认选中数据
    defaultTableSelectData: {
      type: Array,
      default: () => []
    },
    componentList: {
      default: null
    }
  },
  computed: {
    ...mapState(['globalInterval']),
    // 请求定时器，若存在全局则以全局为准，若无全局则取当前组件自定义
    interfaceInterval() {
      if (this.globalInterval && this.globalInterval.useInterval) {
        return this.globalInterval;
      }
      return this.element?.interfaceInterval;
    }
  },
  inject: ['EDITOR_pageUUID'],
  methods: {
    /**
     * @description: 设置定时请求
     */
    setInterval() {
      // 清除定时器
      if (this.intervalObj) {
        clearInterval(this.intervalObj);
      }
      if (!this.interfaceInterval) return false;
      const {
        useInterval = false,
        type = 'timeout',
        timeout = 3000,
        times = -1,
        unit = 'day',
        weekDay = '',
        month = '',
        day = '',
        hms = '06:00'
      } = this.interfaceInterval;
      if (!useInterval) return false;
      this.intervalObj = null;
      this.intervalTimes = 0;
      // 定时循环
      if (type === 'timeout') {
        if (timeout <= 0) return;
        this.intervalObj = setInterval(() => {
          this.intervalTimes++;
          this.doInterface();
          if (times !== -1 && this.intervalTimes >= times) {
            clearInterval(this.intervalObj);
          }
        }, timeout * 1000);
        return;
      }
      // 周期_时分秒
      if (['second', 'minutes', 'hour'].includes(unit)) {
        let count = 1;
        if (unit === 'minutes') {
          count = 60;
        } else if (unit === 'hour') {
          count = 60 * 60;
        }
        this.intervalObj = setInterval(() => {
          this.intervalTimes++;
          this.doInterface();
          if (times !== -1 && this.intervalTimes >= times) {
            clearInterval(this.intervalObj);
          }
        }, count * 1000);
      } else {
        // 每天 每分钟循环一次
        this.intervalObj = setInterval(() => {
          const date = new Date();
          const currentMonth = date.getMonth() + 1;
          const currentDay = date.getDate();
          const currentWeekDay = date.getDay();
          const hours = date.getHours();
          const minutes = date.getMinutes();
          this.intervalTimes++;
          if (unit === 'day' && hms === `${hours}:${minutes}`) {
            // 日
            this.doInterface();
          } else if (unit === 'week') {
            // 周
            if (currentWeekDay === weekDay && hms === `${hours}:${minutes}`) {
              this.doInterface();
            }
          } else if (unit === 'month') {
            // 月
            if (day === currentDay && hms === `${hours}:${minutes}`) {
              this.doInterface();
            }
          } else if (unit === 'year') {
            // 年
            if (month === currentMonth && day === currentDay && hms === `${hours}:${minutes}`) {
              this.doInterface();
            }
          }
          if (times !== -1 && this.intervalTimes >= times) {
            clearInterval(this.intervalObj);
          }
        }, 59 * 1000);
      }
    }
  },
  beforeDestroy() {
    console.log('销毁', this.element.name, this.EDITOR_pageUUID);
    if (!this.EDITOR_pageUUID) {
      // EDITOR_loopResolve 为循环容器特有
      removeEventBus(['exportData', 'databaseTrigger', 'EDITOR_loopResolve'], this.element.id);
    }
  },
};
