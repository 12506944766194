/*
 * @Description: 容器组件
 * @Author: luocheng
 * @Date: 2021-10-15 14:50:51
 * @LastEditors: zx
 * @LastEditTime: 2023-01-10 17:13:47
 */
// 容器
import { uniqid } from "@/utils/tools";

const containers = {
	type: 'container',
	title: '容器组件',
	show: true,
	order: 2,
	componentList: [
		{
			component: 'CommonContainer',
			type: 'container',
			name: '基础容器',
			icon: 'icon-jichurongqi',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			span: 24,
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
				resolveObject: false
			},
			propValue: '',
			style: {
				minHeight: '',
				width: '',
				height: 400,
				display: 'flex',
				flexWrap: 'wrap',
				alignItems: 'flex-start',
				alignContent: 'flex-start',
				overflowX: 'hidden',
				overflowY: 'hidden'
			},
			// 用于直接渲染的数据
			containerData: null,
			specialStyle: {
				isCollector: false, // 是否设为收集者 flex 1
				flexDirection: 'column', // flex 主轴方向
				flexWrap: false, // 是否换行
			}
		},
		{
			component: 'CommonTableContainer',
			type: 'container',
			name: '表格容器',
			icon: 'icon-biaogerongqi',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			span: 24,
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
				idUUID: '', // id uuid
				loading: false,
				stripe: false,
				border: false,
				size: '', // medium / small / mini
				rowKey: '',
				fit: true,
				showHeader: true,
				highlightCurrentRow: false,
				rowShowDetails: false,
				emptyText: '暂无数据',
				// 单元格属性
				align: 'center',
				useStyle: false,
				headerAlign: '',
				canSelect: false,
				showOrder: false,
				pagination: true,
				defaultPerPage: 15,
				customPagination: false,
				perList: '15, 30, 50, 100',
				isTreeTable: false,
				children: '下级数据',
				defaultExpandAll: true,
				isNesting: false,
				nestingKey: '子表数据',
				nestingRowKey: '',
				nestingObjectUUID: '',
				useSort: false,
				useFeWBS: false,
				// keepWBS: false,
				orderWidth: 50,
				feTreeKey: '',
				// fixColumn: ''
			},
			propValue: '',
			style: {
				minHeight: '',
				width: '',
				height: 400,
				overflowX: 'hidden',
				overflowY: 'hidden'
			},
			// 用于直接渲染的数据
			containerData: null,
			actionConfig: {
				needAction: false,
				width: '',
				add: {
					use: false,
					useDepend: false,
					dependLogic: [],
					type: 'add',
					name: '新增',
					btnType: 'text',
					color: '#67C23A',
					btnSize: '',
					margin: 5,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				},
				detail: {
					use: false,
					useDepend: false,
					dependLogic: [],
					type: 'detail',
					name: '详情',
					color: '#409EFF',
					btnType: 'text',
					btnSize: '',
					margin: 5,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				},
				modify: {
					use: false,
					useDepend: false,
					dependLogic: [],
					type: 'modify',
					name: '编辑',
					color: '#E6A23C',
					btnType: 'text',
					btnSize: '',
					margin: 5,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				},
				delete: {
					use: false,
					useDepend: false,
					dependLogic: [],
					type: 'delete',
					name: '删除',
					btnType: 'text',
					color: '#F56C6C',
					btnSize: '',
					margin: 5,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				},
				export: {
					use: false,
					useDepend: false,
					dependLogic: [],
					type: 'export',
					name: '导出',
					btnType: 'text',
					color: '#E6A23C',
					btnSize: '',
					margin: 5,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				}
			},
			// 导出配置
			exportConfig: {
				exportWay: '', // 导出方式
				exportType: 'targetRow', // 类型预留
				exportTemplate: '', // 导出模板
				exportRequest: '', // 导出请求
				exportTypeSheet: 'pdf', // 导出类型 [excel | image | pdf]
				objectUUID: '', // 导出对象UUID
				viewUUID: '', // 导出视图UUID
				exportTemplateField: '', // 模板字段
				objectUUIDField: '', // 模板导出对象字段
				viewUUIDField: '', // 模板导出视图字段
			},
			// 选中被用与操作的(需要保持住，因此需要单独字段进行保持)
			cacheData: null,
			specialStyle: {
				isCollector: false, // 是否设为收集者 flex 1
				flexDirection: 'column' // flex 主轴方向
			},
			fieldConfig: [], // 字段配置列表
			nestingFieldConfig: [], // 嵌套表格字段配置
			nestingActionConfig: { // 嵌套表格的操作
				needAction: false,
				width: '',
				add: {
					use: false,
					useDepend: false,
					dependLogic: [],
					type: 'add',
					name: '新增',
					btnType: 'text',
					color: '#67C23A',
					btnSize: '',
					margin: 5,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				},
				detail: {
					use: false,
					useDepend: false,
					dependLogic: [],
					type: 'detail',
					name: '详情',
					color: '#409EFF',
					btnType: 'text',
					btnSize: '',
					margin: 5,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				},
				modify: {
					use: false,
					useDepend: false,
					dependLogic: [],
					type: 'modify',
					name: '编辑',
					color: '#E6A23C',
					btnType: 'text',
					btnSize: '',
					margin: 5,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				},
				delete: {
					use: false,
					useDepend: false,
					dependLogic: [],
					type: 'delete',
					name: '删除',
					btnType: 'text',
					color: '#F56C6C',
					btnSize: '',
					margin: 5,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				},
				export: {
					use: false,
					useDepend: false,
					dependLogic: [],
					type: 'export',
					name: '导出',
					btnType: 'text',
					color: '#E6A23C',
					btnSize: '',
					margin: 5,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				}
			}
		},
		{
			component: 'CommonFormContainer',
			type: 'container',
			name: '表单容器',
			icon: 'icon-biaodanrongqi',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			span: 24,
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
				showAction: false
			},
			propValue: '',
			style: {
				width: '',
				height: 400,
				overflowX: 'hidden',
				overflowY: 'hidden'
			},
			// 用于直接渲染的数据
			containerData: null,
			actionConfig: [
				{
					key: 'cancel',
					effect: false, // 是否有附加作用
					effectType: '', // 附加操作类型 请求类型
					canCustom: false, // 是否可以删除
					buttonType: '',
					text: '取消',
					postType: '', // 请求类型
					objectUUID: '', // 对象UUID
					viewUUID: '', // 视图UUID
				},
				{
					key: 'confirm',
					effect: false,
					effectType: '',
					canCustom: false,
					postType: '', // 请求类型
					buttonType: 'primary',
					text: '确认',
					objectUUID: '', // 对象UUID
					viewUUID: '', // 视图UUID
				}
			],
			fieldConfig: [], // 字段配置列表
			contentConfig: {
				contentType: 'outForm', // 内容类型
				configType: '', // 配置类型 '' 普通类型， dynamic 动态配置
				dynamicComponent: '', // 动态组件
				objectUUID: '', // 数据对象
				viewUUID: '', // 视图ID
				pageId: '', // 页面id
				formUUID: '', // 表单对象
				developPageRoute: '', // 首页页面路由
			},
			updateRulesConfig: {
				pattern: '',
				rules: []
			}
		},
		{
			component: 'CommonSheetContainer',
			type: 'container',
			name: 'Excel容器',
			icon: 'icon-excelrongqi',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			span: 24,
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
				showAction: false
			},
			propValue: '',
			style: {
				width: '',
				height: 400,
				overflowX: 'hidden',
				overflowY: 'hidden'
			},
			// 用于直接渲染的数据
			containerData: null,
			actionConfig: [
				{
					key: 'cancel',
					effect: false, // 是否有附加作用
					effectType: '', // 附加操作类型 请求类型
					canCustom: false, // 是否可以删除
					buttonType: '',
					text: '取消',
					postType: '', // 请求类型
					objectUUID: '', // 对象UUID
					viewUUID: '', // 视图UUID
					show: true,
				},
				{
					key: 'confirm',
					effect: false,
					effectType: '',
					canCustom: false,
					postType: '', // 请求类型
					buttonType: 'primary',
					text: '确认',
					objectUUID: '', // 对象UUID
					viewUUID: '', // 视图UUID
					show: true,
				}
			],
			baseConfig: {
				/**
				 * 解析类型：form - 表单, table - 数据表
				 * 解析类型的作用是用来判断提交方式，form数据提交时走常规接口，table的导入/编辑走固定接口
				 */
				solveType: "",
				/**
				 * 操作类型：add - 新增/导入，edit - 编辑，info - 详情，export - 导出
				 * 直接传入Entry组件
				*/
				type: '',
				enableDialog: false, // 是否启用弹窗
				dialogTitle: "Excel弹窗", // 标题
				excelTemplate: '',
				autoTemplate: false,
				checkOnly: false, // 导入时查重
			},
			databaseList: [],
			dataSource: [
				{
					code: '', // 字段名
					isRequired: false, // 必须
					source: 'component', // 值来源， component 组件，url url参数，system 系统参数
					component: '', // 关联组件
					componentField: '', // 组件取值字段
					urlKey: '', // url参数字段
					systemObject: '', // 系统取值对象
					systemField: '' // 系统取值字段
				}
			],
			behaviors: [
				{
					label: '刷新',
					value: 'refresh'
				}
			],
			updateRulesConfig: {
				pattern: '',
				rules: []
			}
		},
		{
			component: 'CommonLoopContainer',
			type: 'container',
			name: '循环容器',
			icon: 'iconwangpan-fuzhi1x',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			span: 24,
			statusConfig: {
				isShow: true // 是否显示
			},
			propValue: '',
			style: {
				minHeight: '',
				maxHeight: '',
				width: '',
				height: '100%',
				display: 'flex',
				overflowX: 'hidden',
				overflowY: 'auto',
			},
			// 用于直接渲染的数据
			containerData: null,
			specialStyle: {
				isCollector: false, // 是否设为收集者 flex 1
				flexDirection: 'row', // flex 主轴方向
				flexWrap: true, // 是否换行
				justifyContent: 'flex-start'
			},
			loopConfig: { // 循环配置
				cardId: '',
				mapTable: [] // 值映射表
			}
		},
		{
			component: 'CommonScreenContainer',
			type: 'screenContainer',
			name: '大屏容器',
			icon: 'icon-dapingrongqi',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			span: 24,
			statusConfig: {
				displayShow: true,
				isShow: true // 是否显示
			},
			propValue: '',
			style: {
				width: 500,
				height: 400,
				overflowX: 'hidden',
				overflowY: 'hidden'
			},
			// 用于直接渲染的数据
			containerData: null,
			specialStyle: {
				isCollector: false, // 是否设为收集者 flex 1
				flexDirection: 'column', // flex 主轴方向
				flexWrap: false, // 是否换行
			}
		},
		{
			component: 'CommonGridContainer',
			type: 'container',
			name: '拖拽容器',
			icon: 'icon-tuozhuairongqi',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			span: 24,
			statusConfig: {
				panelName: 'Dashboard名称',
				isShow: true, // 是否显示
				toolbar: true,
				autoSize: true,
				// responsive: true,
				preventCollision: false,
			},
			propValue: '',
			style: {
				minHeight: '',
				width: '',
				height: '100%',
				display: 'flex',
				flexWrap: 'wrap',
				alignItems: 'flex-start',
				alignContent: 'flex-start',
				overflowX: 'hidden',
				overflowY: 'hidden'
			},
			// 用于直接渲染的数据
			containerData: null,
			layoutData: [
				{ id: uniqid(), name: '', bgColor: '', "x": 0, "y": 0, "w": 3, "h": 6, "i": "0" },
				{ id: uniqid(), name: '', bgColor: '', "x": 3, "y": 0, "w": 3, "h": 6, "i": "1" },
				{ id: uniqid(), name: '', bgColor: '', "x": 6, "y": 0, "w": 3, "h": 6, "i": "2" },
				{ id: uniqid(), name: '', bgColor: '', "x": 9, "y": 0, "w": 3, "h": 6, "i": "3" },
				{ id: uniqid(), name: '', bgColor: '', "x": 0, "y": 6, "w": 3, "h": 6, "i": "4" },
				{ id: uniqid(), name: '', bgColor: '', "x": 3, "y": 6, "w": 3, "h": 6, "i": "5" },
				{ id: uniqid(), name: '', bgColor: '', "x": 6, "y": 6, "w": 3, "h": 6, "i": "6" },
				{ id: uniqid(), name: '', bgColor: '', "x": 9, "y": 6, "w": 3, "h": 6, "i": "7" },
				{ id: uniqid(), name: '', bgColor: '', "x": 0, "y": 12, "w": 3, "h": 6, "i": "8" },
				{ id: uniqid(), name: '', bgColor: '', "x": 3, "y": 12, "w": 3, "h": 6, "i": "9" },
				{ id: uniqid(), name: '', bgColor: '', "x": 6, "y": 12, "w": 3, "h": 6, "i": "10" },
				{ id: uniqid(), name: '', bgColor: '', "x": 9, "y": 12, "w": 3, "h": 6, "i": "11" },
			],
			specialStyle: {
				isCollector: false, // 是否设为收集者 flex 1
				flexDirection: 'row', // flex 主轴方向
				flexWrap: false, // 是否换行
			}
		},
		{
			component: 'CommonPageContainer',
			type: 'container',
			name: '页面容器',
			icon: 'icon-biaodanrongqi',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			span: 24,
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
				showAction: false
			},
			propValue: '',
			style: {
				width: '',
				height: 400,
				overflowX: 'hidden',
				overflowY: 'hidden'
			},
			// 用于直接渲染的数据
			containerData: null,
			actionConfig: [
			],
			fieldConfig: [], // 字段配置列表
			contentConfig: {
				contentType: 'configPage', // 内容类型
				configType: '', // 配置类型 '' 普通类型， dynamic 动态配置
				dynamicComponent: '', // 动态组件
				objectUUID: '', // 数据对象
				viewUUID: '', // 视图ID
				pageId: '', // 页面id
				formUUID: '', // 表单对象
				developPageRoute: '', // 首页页面路由
			}
		}
	]
};
export default containers;
