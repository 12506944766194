import EventEmitter from './EventEmitter';
export default class PostMessageBase extends EventEmitter{
    constructor(domId,objectKey,opt={}){
        super();
        this.domId = domId;
        let pdom = document.getElementById(domId);
        this.pid = `${this.getGuid()}${Date.now()}`;
        this.objectKey = objectKey;

        this.host = opt.host?opt.host:'https://dev.bimcc.net';
        this.vcid = opt.vcid ? '&vcid='+opt.vcid : "";

        let element = document.createElement("iframe");
        const eleId = `gisViewer_${this.getGuid()}`;
        let url = `${this.host}/viewer/${this.objectKey}?pid=${this.pid}${this.vcid}`;


        element.src = url ;
        element.id = eleId;
        element.style.cssText = "width: 100%;height: 100%;border: 0;overflow: hidden";

        pdom.appendChild(element);

        this.dom = document.getElementById(eleId);

        this.initEventListener();
    }


    initEventListener(){
        window.addEventListener('message', (e)=> {
            if(e && e.data){
                const msg = e.data;

                if(typeof msg != "string") return;

                const msgList  = msg.split('||BIMCC||');

                if(msgList.length < 2) return;

                let data = JSON.parse(msgList[1]);

                if(!this.pid) return console.warn('消息丢失 初始化未完成。');

                if(this.getMessage && this.pid == data.pid){
                    this.getMessage(data);
                }
            }
        });
    }

    sendMsg(data){
        this.dom.contentWindow.postMessage(JSON.stringify(data),this.host);
    }

    getGuid(format = 'xxxxxxxxxxxx'){
        var d = new Date().getTime();
        var guid = format.replace(
            /[xy]/g,
            function (c) {
                var r = (d + Math.random() * 16) % 16 | 0;
                d = Math.floor(d / 16);
                return (c == 'x' ? r : (r & 0x7 | 0x8)).toString(16);
            });
        return guid;
    }
}
