<!--
 * @Author: Shiltin
 * @Date: 2021-10-20 17:52:57
 * @LastEditors: zx
 * @LastEditTime: 2022-06-01 16:30:12
 * @Description:
-->
<template>
  <div
    style="height: 100%"
    :style="!notForm && maxShowWidth ? 'max-width:' + maxShowWidth + 'px' : 'width:100%'"
  >
    <cascader
      :data-info="data3"
      :default-expand="defaultExpand"
      :disabled="disabled"
      :is-check-type="isCheckType"
      :max-height="maxHeight"
      :position-type="positionType"
      :show-list="value"
      :show-tree="showTree"
      :width="width"
      @onSelectChange="onSelectChange"
    />
  </div>
</template>
<script>
/* eslint-disable */
import cascader from './components/cascader'
import { dataInterface } from '@/apis/data';
export default{
  name: '',
  components: { cascader },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    // false 级联单选
    // true 级联多选
    isCheckType: {
      type: Boolean,
      default: true
    },
    subId: {
      type: [Number , String, Array],
      default: 0
    },
    /* 点击选择窗口位置 */
    positionType: {
      type: String,
      default: 'bottom-start'
    },
    /* 弹出框宽度 */
    width: {
      type: Number,
      default: 450
    },
    /* 弹出框最大高度 */
    maxHeight: {
      type: Number,
      default: 300
    },
    notForm: {
      type: Boolean,
      default: false
    },
    /* 最大的显示人的长度(form表单默认100%会撑开，不能超出隐藏，form表单下使用需要设置宽度数值) */
    maxShowWidth: {
      type: Number,
      default: 0
    },
    /* 默认展开 */
    defaultExpand: {
      type: Boolean,
      default: false
    },
    /* 禁用选择 */
    disabled: {
      type: Boolean,
      default: false
    },
    /* 分项id是否必传 */
    subIdNecessary: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      userInfo:{},
      archiInfo:{},
      data3: null,
      showTree: true
    }
  },
  watch: {
    subId: {
      handler (value) {
        if (value) {
          this.getList(value)
        }
      },
      immediate: true
    }
  },
  methods: {
    onSelectChange(arr){
      this.$emit('input', arr)
      this.$emit('change',arr)
    },
    getList(id) {
      let params = {
        object_uuid: 'object61a72e13a6ed0',
        view_uuid: 'view61b956a5f1616',
        __method_name__: 'dataList',
        transcode: 0
      }
      if (id) {
        params.option_id = id
        params.view_uuid = 'view62443fc8e4546'
      }
      this.showTree = true
      if (this.subIdNecessary && (!id || (Array.isArray(id) && id.length === 0))) {
        return
      }
      dataInterface(params).then((res) => {
        let list = res.data.data
        let key = 1
        const loopTree = (arr) => {
          arr.forEach(element => {
            if (element.option_config && element.children_type && element.children_type.length) {
              element.children_type.forEach(item => {
                item.option_config = element.option_config
              });
            }
            if (element.part_code) {
              element.nodeKey = key
              key += 1
            } else if (element.children_type && element.children_type.length) {
              element.children = element.children_type
              delete element.children_type
              loopTree(element.children)
            }
          })
        }
        if (list && Array.isArray(list) && list.length) {
          loopTree(list)
        }
        this.data3 = list
        console.log(list, '处理好的部位数据')
        this.showTree = false
      })
    }
  },
  mounted () {
    if (this.subId) {
      this.getList(this.subId)
    } else {
      this.getList()
    }
  }
}
</script>
<style lang="less" scoped></style>
