import { render, staticRenderFns } from "./MobileComponentBox.vue?vue&type=template&id=2e7e005e&scoped=true&"
import script from "./MobileComponentBox.vue?vue&type=script&lang=js&"
export * from "./MobileComponentBox.vue?vue&type=script&lang=js&"
import style0 from "./MobileComponentBox.vue?vue&type=style&index=0&id=2e7e005e&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e7e005e",
  null
  
)

export default component.exports