/*
 * @Description: 锁定 解锁
 * @Author: luocheng
 * @Date: 2021-08-11 15:00:43
 * @LastEditors: luocheng
 * @LastEditTime: 2021-10-21 15:59:54
 */
export default {
  mutations: {
    /**
     * @desc: 切换当前组件是否锁定
     * @param {Boolean} statue
     */
    toggleLock(state, statue) {
      if (!state.curComponent) return;
      state.curComponent.isLock = statue;
    }
  }
}