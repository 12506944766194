/*
 * @Description: 基础容器
 * @Author: luocheng
 * @Date: 2021-10-15 14:52:06
 * @LastEditors: Shiltin 18580045074@163.com
 * @LastEditTime: 2022-09-15 14:32:15
 * @LastEditTime: 2022-04-28 10:09:40
 */
// 基础组件
const baseComponent = {
	type: 'baseComponent',
	title: '基础组件',
	show: true,
	componentList: [
		{
			component: 'MobileCommonButton',
			type: 'button',
			name: '按钮',
			icon: 'icona-kanbantongjifill',
			isLock: false, // 是否锁定
			needCommonStyle: false,
			propValue: '按钮',
			style: {
				height: '44',
				width: '80%',
				lineHeight: '',
				rotate: 0,
				marginTop: 0,
				marginBottom: 0,
				marginRight: 0,
				marginLeft: 0,
				paddingLeft: 0,
				paddingRight: 0,
				paddingTop: 0,
				paddingBottom: 0
			},
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
				type: 'primary', // primary / warning / danger / info /default
				size: '', // medium / small / mini
				plain: false,
				round: false,
				circle: false,
				loading: false,
				disabled: false,
				icon: '',
				autofocus: false,
				outId: ''
			}
		},
		{
			component: 'CommonImage',
			name: '图片',
			icon: 'icona-kanbantongjifill',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			statusConfig: {
				displayShow: true,
				isShow: true,
				objectFit: 'cover'
			},
			style: {
				width: 200,
				height: 180,
				rotate: 0
			},
			propValue: 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/b3f6ef3ac265d34a6da4b95e300f4a30.png'
		},
		{
			component: 'CommonText',
			name: '文本框',
			icon: 'icona-kanbantongjifill',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			span: -1,
			statusConfig: {
				displayShow: true,
				isShow: true // 是否显示
			},
			style: {
				width: 'auto',
				minWidth: sessionStorage.getItem('editorType') === 'dataview' || sessionStorage.getItem('editorType') === 'console' ? 0 : 200,
				height: 32,
				fontSize: 14,
				fontWeight: 500,
				lineHeight: 32,
				letterSpacing: 0,
				textAlign: 'center',
				color: sessionStorage.getItem('editorType') === 'dataview' ? '#fff' : '#333',
				overflowX: 'hidden',
				overflowY: 'hidden',
				rotate: 0
			},
			propValue: '文本框'
		},
		{
			component: 'CommonTabs',
			type: 'tabs',
			name: 'TAB组件',
			icon: 'icon-tabxuanxiangka',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			span: 24,
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
				type: 'line', // 风格
				tabPosition: 'top', // 位置
				useSolt: false,
				defaultColor: '',
				activeColor: ''
			},
			propValue: '',
			style: {
				height: 44
			},
			// 用于直接渲染的数据
			containerData: null,
			tabOrigin: 'fixed', // 来源 fixed 固定 dictionary 字典(全局) dynamic 数据仓库
			tabConfig: [], // 固定参数
			dictionaryConfig: '', // 使用的字典
			dynamicConfig: {
				objectUUID: '',
				viewUUID: '',
				labelKey: '', // label字段UUID
				valueKey: '', // 值字段UUID
				fieldList: []
			}, // 使用的字典
		},
		{
			component: 'CommonSwiper',
			type: 'MobileSwiper',
			name: '轮播图',
			icon: 'icon-lunbo',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			span: 24,
			style: {
				width: '',
				rotate: 0,
				background: '#fff',
			},
			propValue: '',
			statusConfig: {
				displayShow: true,
				isShow: true,
				height: 300,
				initialIndex: 0,
				autoplay: 500,
				interval: 3000,
				trigger: 'click',
				indicatorPosition: '',
				arrow: 'hover',
				type: '',
				loop: true,
				direction: 'horizontal',
				showText: true,
				clickLink: false,
				vertical: false,
				showIndicators: true,
				touchable: true,
				indicatorColor: '#fff',
				descColor: '#fff',
			},
			swiperConfig: {
				valueType: 'fixed', // 值类型  fixed 固定值，database 数仓
				fixedConfig: [
					// {
					// 	img: '', // 图片链接
					// 	text: '', // 描述文本
					// 	href: '' // 跳转链接
					//  hrefType: '' // 跳转方式
					// }
				],
				databaseConfig: {
					objectUUID: '',
					viewUUID: '',
					imgUUID: '', // 图片链接
					textUUID: '', // 描述文本
					hrefUUID: '', // 跳转链接
					hrefTypeUUID: '' // 跳转方式
				}
			},
			paramsConfig: [], // 筛选参数
		},
		{
			name: '视频组件',
			component: 'LiveCom',
			type: 'container',
			icon: 'icon-a-luzhishipin',
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
				src: '',
				videoType: 'Other',
				screen_sequence: 1,
				showControl: false
			}
		},
	]
};

export default baseComponent;