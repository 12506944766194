<!--
 * @Author: zx
 * @Date: 2022-08-30 14:42:08
 * @LastEditTime: 2022-10-31 16:21:24
 * @Description: 
 * @LastEditors: 朱廷果 1028509503@qq.com
-->
<template>
  <div class="archi-tree">
    <div class="tree-content">
      <el-tree
        ref="treeRef"
        v-loading="loading"
        :data="treeData"
        default-expand-all
        node-key="id"
        :props="props"
        style="padding: 0 5px;height: 100%"
        @node-click="treeSelectClick"
      >
        <template #default="{ node, data }">
          <div class="archi-label">
						<span class="archi-label-svg">
              <svg aria-hidden="true" class="color-icon">
                <use :xlink:href="getIcon(data, 'label')" />
              </svg>
              <span>{{ node ? data[props.label] : '' }}</span>
            </span>
            <i v-if="saasTargetArchi && +saasTargetArchi.id === +data.id" class="iconfont iconselect-duigou"></i>
          </div>
        </template>
      </el-tree>
    </div>
    <div class="choose-archi">
      <el-button :disabled="isDisabled" :type="isDisabled ? 'default' : 'primary'" @click="chooseArchi()">
        {{ getIcon(cacheArchi, 'archi') }}
      </el-button>
    </div>
  </div>
</template>
<script>
import { departmentFilter } from '@/api/saas'
import request from '@/apis/http/saasRequest.js'
import { getCompanyId } from '@/utils/tools';
import { Button, Tree } from 'element-ui' 
import { mapState } from 'vuex';
export default {
  components:{
		'el-button': Button,
		'el-tree': Tree,
  },
  props:{
    menuTreeData: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      props: {
        label: 'name',
        children: 'children'
      },
      treeData: [],
      loading: true,
    }
  },
	computed: {
		...mapState(['cacheArchi', 'saasTargetArchi']),
    isDisabled() {
      return !this.cacheArchi || this.getIcon(this.cacheArchi, 'archi') === '选择架构' || (this.saasTargetArchi && +this.cacheArchi.id === +this.saasTargetArchi.id)
    }
	},	
  mounted() {
    console.log(1111)
    this.getArchiTreeList()
  },
  methods:{
    getArchiTreeList() {
			departmentFilter().then((res) => {
        console.log(res)
        this.loading = false
				if (res.status === 200 && res.data.code === 200) {
          const data = res.data?.data
          const chooseArr = [1, 2, 3, 4, 5, 6]
          const loopTree = (arr) => {
            arr.forEach(element => {
              if (element.children && element.children.length) {
                element.children = element.children.filter((ite) => {
                  return chooseArr.indexOf(+ite.type) !== -1
                })
                loopTree(element.children)
              }
            });
          }
          if (data && data.length) {
            loopTree(data)
          }
          this.treeData = data
				}
			})
    },
    treeSelectClick(data) {
      this.$store.commit('setCacheArchi', data)
    },
    getIcon(data, key) {
      const group = [1, 2]
      const company = [3, 4]
      const project = [5, 6]
      if (!data) {
        return key === 'label' ? '#iconjituan' : '选择架构'
      }
      if (group.indexOf(+data.type) !== -1 ) {
        return key === 'label' ? '#iconjituan' : '选择架构'
      } else if (company.indexOf(+data.type) !== -1 ) {
        return key === 'label' ? '#icongongsi' : '选择企业'
      } else if (project.indexOf(+data.type) !== -1 ) {
        return key === 'label' ? '#iconxiangmu' : '选择项目'
      }
    },
    chooseArchi() {
      const data = {
        values: JSON.stringify(this.cacheArchi),
        company_id: getCompanyId(),
        department_id: this.cacheArchi.id
      }
      request({url: 'api/saas/departmentSwitch', method: 'post', data}).then((res) => {
        if (+res.status === 200 && +res.data.code === 200) {
          this.$store.commit('setSaasTargetArchi', this.cacheArchi)
          this.$message.success('切换成功')
          this.$emit('close')
          if (this.menuTreeData && this.menuTreeData.length) {
            this.menuTreeData.forEach(element => {
              if (element.path === '/home') {
                this.$store.commit('setCurrentMenus', element)
                this.$router.push({ path: '/home' })
              }
            });
          }
        }
      })
    }
  }
}
</script>
<style lang='less' scoped>
  :deep(.el-tree-node__content) {
    height: 40px;
  }
  .archi-tree {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .tree-content {
      flex: 1;
      width: 100%;
      overflow-y: auto;
      padding: 16px 0;
      box-sizing: border-box;
      background: #fff;
      .archi-label {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .archi-label-svg {
          height: 50px;
          display: flex;
          align-items: center;
        }
        i {
          margin-right: 8px;
        }
      }
      .color-icon {
        width: 20px;
        height: 20px;
      }
    }
    .choose-archi {
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 0;
      button {
        width: 100%;
      }
    }
  }
</style>