/*
 * @Description: 表单所需工具函数
 * @Author: luocheng
 * @Date: 2021-09-29 15:18:57
 * @LastEditors: luocheng
 * @LastEditTime: 2022-06-21 09:12:48
 */
/* eslint-disable */
export const baseUrl = () => {
	return '4fb0b638-2c43-4d7e-8acf-f1a7abdee58e';
};

export const getToken = () => {
	return 'c2d181d73d964d9bee2ddc77251060ea';
};

export const formSrc = () => {
	return '/localForm'
	return process.env.NODE_ENV === 'development' ? '/localForm' : '/pc/designForm'
};

export const uniqid = () => {
	var d = new Date().getTime();
	if (window.performance && typeof window.performance.now === "function") {
		d += performance.now();
	}
	var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		var r = (d + Math.random() * 16) % 16 | 0;    // d是随机种子
		d = Math.floor(d / 16);
		return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
	});
	return uuid;
};


export function reduceData(goalData, resultData = []) {
	for (let item of goalData){
		if(
				['el-switch','el-select','el-radio-group'].includes(item.__config__.tag) &&
				item.__config__.interact.effect !== ''
		){
			let itemData = {
				formId: item.__config__.formId,
				defaultVal: item.__config__.defaultValue,
				interact: item.__config__.interact,
			}
			resultData.push(itemData)
		}
		if(item.__config__.children && item.__config__.children.length>0){
			reduceData(item.__config__.children,resultData)
		}
	}
	return resultData
}

/**
 * 任务引擎获取前置任务
 * @param graphData
 * @param nodeId
 */
 export const preTaskNode = (graphData,nodeId,type,preNodeId) => {
	const edge = graphData.filter(item=>item.shape === 'edge')
	const startNode = graphData.filter(item=>item.shape === 'start')
	const startId = startNode[0]?.id || 0
	if(!startId) return preNodeId
	let preNode = []
	if(type === 'edge'){
		preNode = edge.filter(item=>item.id === nodeId)
	} else {
		preNode = edge.filter(item=>item.target.cell === nodeId)
	}
	const nodeIdArr = Array.from(preNode,item=>item.source.cell)
	if(!nodeIdArr.includes(startId)){
		for (let item of nodeIdArr){
			preNodeId.push(item)
			preTaskNode(graphData,item,type,preNodeId)
		}
	}
	return preNodeId
}