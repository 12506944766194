<!--
 * @Author: 冉桂精 156189868@qq.com
 * @Date: 2022-11-07 16:18:10
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2022-11-08 11:44:01
 * @FilePath: /dataview-next/src/custom-component/tree/CommonArchiTree.vue
 * @Description: 组织架构树形图
-->
<template>
  <div class="common-archi-tree" v-if="initend">
    <el-input
			v-model="filterName"
			prefix-icon="el-icon-search"
			clearable
			placeholder="请输入"
			class="search"
			@input="onFilter"
		></el-input>
    <el-tree
      ref="commonArchiTree"
      :highlight-current="true"
      :data="treeData"
      :expand-on-click-node="false"
      :props="defaultProps"
      default-expand-all
      node-key="id"
      @node-click="onNodeClick"
    >
      <span
				class="custom-tree-node"
				slot-scope="{ node, data }"
				:class="{
					'current-custom-tree-node': currentKey === data.id
				}"
			>
				<span class="label">
					{{ data[defaultProps.label] || node[defaultProps.label] }}
				</span>
      </span>
    </el-tree>
  </div>
</template>

<script>
import { Tree } from 'element-ui';
import { dataInterface } from '@/apis/data';
import { judgingArchiAPermission, judgingUILogic } from '@/utils/tools';
import { mapState } from 'vuex';
import eventBus from '@/plugins/eventBus';

export default {
  name: 'CommonArchiTree',
  components: {
    'el-tree': Tree
  },
  props: {
    element: {
      type: Object,
      isRequired: true,
      default: () => {}
    },
    componentList: {
      default: null
    }
  },
	inject: ['EDITOR_pageUUID'],
  data() {
    return {
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      targetArchi: '',
      filterName: '',
      // 可选字段
      fieldObj: {},
      initend: false,
      currentKey: -1,
      metadata: {}
    };
  },
  computed: {
    ...mapState(['componentData']),
    isCompany() {
      return this.targetArchi?.archiType === 'company';
    },
    reqData() {
      if (!this.targetArchi) return null;
      if (this.isCompany) {
        return {
          __method_name__: 'dataInfo',
          object_uuid: 'object616fd8131c8f1',
          view_uuid: 'view6369b42f92ca3',
          transcode: 0,
          data_id: this.targetArchi?.id?.split('-')[1]
        };
      }
      return {
        __method_name__: 'dataList',
        object_uuid: 'object616fe6fe8119f',
        view_uuid: 'view6369b54f1c709',
        transcode: 0,
        project_id: this.targetArchi?.id.split('-')[1] // 项目id
      };
    },
    // 整体操作权限控制
		actionLimit() {
			return this.element?.actionLimit || {
				permission: [], // 权限
				archiLimit: [], // 架构限制
			}
		},
  },
  created() {
    this.targetArchi = this.$GetTargetArchi();
  },
  watch: {
    reqData: {
      handler(newV) {
        if (!newV) return false;
        this.getTreeData();
      }
    }
  },
  methods: {
    /**
     * @description: 获取树形图数据
     */
    getTreeData() {
      dataInterface(this.reqData)
        .then(res => {
          let data = res?.data?.data || [];
          if (!Array.isArray(data)) {
            data = [data];
          }
          this.treeData = this.formatData(data);
          this.setFiledList();
          if (this.treeData.length) {
						this.onNodeClick(this.treeData[0]);
          }
          this.initend = true;
        })
        .catch(err => {
          console.log(err);
          this.$message.error('获取组织架构列表失败！');
        });
    },
    /**
     * @description: 格式化树形图数据
     * @param {Array} list
     */
    formatData(list = []) {
      return list.map(el => {
        let children = [];
        this.fieldObj = {
          ...this.fieldObj,
          ...el
        };
        if (Array.isArray(el.digui_children)) {
          children = children.concat(el.digui_children);
        }
        if (Array.isArray(el.projects)) {
          children = children.concat(el.projects);
        }
        if (Array.isArray(children) && children.length) {
          children = this.formatData(children);
        }
        return {
          ...el,
          children
        }
      });
    },
    /**
     * @description: 点击节点
     */
    onNodeClick(data) {
      this.currentKey = data?.id;
      // 如果是可选择则不再触发单几点情况
			const rowClick = this.element.actionConfig.find(ele => ele.key === 'rowClick');
			if (rowClick && rowClick.eventList && rowClick.eventList.length
				&& judgingArchiAPermission(this.actionLimit.archiLimit, this.actionLimit.permission)
				&& judgingUILogic(this.actionLimit.UILogic, this.componentList || this.componentData)
			) {
				// 触发行点击事件
				const comEvents = rowClick.eventList || [];
				for (let i = 0; i < comEvents.length; i++) {
					const { pattern, eventList = [], specialEventList = [] } = comEvents[i];
					if (pattern === undefined) {
						if (comEvents[i].key === 'click') {
								comEvents[i].effects.forEach((effect) => {
									this.$store.commit('triggerEvents', {
										config: {
											...comEvents[i],
											...effect
										},
										element: this.element
									});
								});
							}
					} else {
						const result = pattern === 'special' ? specialEventList : eventList
						result.forEach((ele) => {
							if (ele.key === 'click') {
								ele.effects.forEach((effect) => {
									this.$store.commit('triggerEvents', {
										config: {
											...ele,
										...effect
										},
										element: this.element
									});
								});
							}
						});
					}
				}
			}
			if (!data) {
				return false;
			}
			let resolveData = data;
			if (!data) {
				resolveData = {
					...this.element.metadata
				};
				this.$set(resolveData, 'id', -1);
			}
			this.$store.commit('modifyComponent', {
				component: {
					...this.element,
					resolveData,
          metadata: this.metadata
				},
				containerId: null,
				isModify: true,
				pageUUID: this.EDITOR_pageUUID
			});
			setTimeout(() => {
				eventBus.$emit('databaseTrigger', {
					componentId: this.element.id,
					isSearch: true,
					action: 'any',
					output: resolveData
				});
			}, 0)
    },
    /**
     * @description: 设置字段列表
     */
    setFiledList() {
      if (!this.fieldObj) return false;
      const fieldList = [];
      this.metadata = {};
      for (let key in this.fieldObj) {
        fieldList.push({
          name: key,
          uuid: key,
          // 无用配置暂时冗余
          renderConfig: {
            isArchiTree: true,
            forView: true,
            width: '',
            type: 1
          }
        });
        this.$set(this.metadata, key, key);
      }
      this.$store.commit('modifyComponent', {
				component: {
					...this.element,
          metadata: this.metadata,
          database: {
            ...this.element.database,
            fieldList
          }
				},
				containerId: null,
				isModify: true
			});
    },
    /**
		 * @desc: 触发过滤
		 */
		onFilter() {
			if (this.$refs && this.$refs.commonArchiTree) {
				this.$refs.commonArchiTree.filter(this.filterName);
			}
		}
  }
};
</script>

<style lang="less" scoped>
.common-archi-tree {
  width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	border-radius: 4px;
	overflow: hidden;
  box-sizing: border-box;
  padding: 5px;
  .search {
		margin-bottom: 5px;
	}
  :deep(.el-tree) {
    flex: 1;
    width: 100%;
    overflow: hidden;
    overflow-y: auto;
    .custom-tree-node {
      position: relative;
      width: calc(100% - 50px);
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      padding-right: 25px;
      &.current-custom-tree-node {
        position: relative;
        &::after{
          content: '';
          height: 100%;
          width: 100%;
          width: calc(100% + 60px);
          position: absolute;
          left: -60px;
          top: 0;
          background: #e8f4ff;
          // z-index: -1;
        }
      }
      span {
        line-height: 26px;
        z-index: 10;
      }
    }
  }
}
</style>
 