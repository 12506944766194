/*
 * @Description: 全局状态
 * @Author: luocheng
 * @Date: 2021-08-10 15:51:02
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2022-11-08 11:44:08
 */
import Vue from 'vue';
import { $, arrSwap, getSiblings, getComponentById } from '@/utils/tools';
import { appChildById, removeComponent, editorComponent } from '@/utils/editor';
import store from './index';
import toast from '@/utils/toast';
import { CommonText } from '@/custom-component/component-config/bases';
import { commonAttr } from '@/custom-component/component-list';
import { getComponentId } from '@/utils/tools';

export default {
	state: {
		// 编辑器类型 dataview   page
		editorType: 'page',
		// 画布样式
		canvasStyle: {
			title: '测试配置',
			height: 840,
			width: 800, // 1200
			scale: 100,
			backgroundColor: '#fff'
		},
		// 状态配置
		statusConfig: [],
		// 图层列表
		componentData: [],
		// 当前所操作组件
		curComponent: null,
		curIndex: null,
		// 点击画布时是否点中组件，主要用于取消选中组件用。
		// 如果没点中组件，并且在画布空白处弹起鼠标，则取消当前组件的选中状态
		isClickComponent: false,
		// 编辑器对象
		editor: null,
		// 编辑器位置信息
		editorPosition: {
			startX: 0,
			startY: 0,
			endX: 0,
			endY: 0
		},
		// 页面配置
		pageConfig: {},
		// 当前是否处于移动状态
		moveStatus: false,
		// 附属页面数据配置
		subsidiaryComponentData: {}
	},
	mutations: {
		/**
		 * @desc: 批量设置组件数据(模板)
		 */
		setComponentsBatch(state, componentData) {
			state.componentData = componentData || [];
			sessionStorage.setItem('componentData', JSON.stringify(state.componentData));
		},
		/**
		 * @desc: 编辑图层信息，对图层增改
		 * @param {Object} component 组件对象
		 * @param {String} containerId 放置的容器ID  根容器为 null
		 */
		modifyComponent(state, { component, containerId, isModify = false, pageUUID = null }) {
			if (!component) return;
			if (!pageUUID) {
				// 页面配置状态下直接编辑主要页面数据
				if (isModify) {
					// 编辑 根据ID查找整体替换数据(存在优化点！！！)
					store.commit('setComponentData', editorComponent(state.componentData, component));
				} else {
					// 新增
					if (containerId) {
						store.commit('setComponentData', appChildById(state.componentData, containerId, component));
					} else {
						state.componentData.push(component);
					}
				}
				if (state.curComponent && state.curComponent.id === component.id) {
					store.commit('setCurComponent', {
						component: component
					});
				}
				// 测试
				// console.log(state.componentData)
				// sessionStorage.setItem('componentData', JSON.stringify(state.componentData));
			} else {
				// 附加页面数据配置只会在渲染时候生效(因此无需管curComponent等)
				// 页面配置状态下直接编辑主要页面数据
				try {
					if (state.subsidiaryComponentData?.[pageUUID]) {
						state.subsidiaryComponentData = {
							...state.subsidiaryComponentData,
							[pageUUID]: {
								...state.subsidiaryComponentData[pageUUID],
								componentData: editorComponent(state.subsidiaryComponentData[pageUUID].componentData, component)
							}
						}
					}
				} catch (err) {
					console.log(err, '--页面配置状态下直接编辑主要页面数据--');
				}
			}
		},
		/**
		 * @desc: 删除组件(需要修改，引入了嵌套组件的情况)
		 */
		deleteComponent(state, component) {
			if (!component) {
				component = state.curComponent;
			}
			state.componentData = removeComponent(state.componentData, component.id);
			if (state.curComponent && component.id === state.curComponent.id) {
				store.commit('setCurComponent', { component: null, index: null })
			}
		},
		/**
		 * @desc: 设置当前操作图层（清空时，component 为 null index 为 -1）
		 * @param {Object} component 当前编辑图层对象
		 * @param {Number} index 当前编辑图层下标
		 */
		setCurComponent(state, { component, index }) {
			if (state.curComponent && state.curComponent.id === component?.id) {
				state.curComponent = null;
				state.curComponent = component;
				state.curIndex = index;
			} else {
				state.curComponent = null;
				// 放置覆盖配置
				setTimeout(() => {
					state.curComponent = component;
					state.curIndex = index;
				}, 0)
			}
			// 测试
			// sessionStorage.setItem('componentData', JSON.stringify(state.componentData));
		},
		/**
		 * @desc: 修改当前操作图层样式
		 */
		setShapeStyle({ curComponent }, { top, left, width, height, rotate }) {
			// console.log(curComponent, '33333333333', top, left, width, height, rotate);
			// if (width) curComponent.style.width = +width;
			// if (height) curComponent.style.height = +height;
			// if (top) curComponent.style.top = +top;
			// if (left) curComponent.style.left = +left;
			// if (rotate) curComponent.style.rotate = +rotate;
			if (curComponent) {
				curComponent.style = {
					...curComponent.style,
					top,
					left,
					width,
					height,
					rotate
				}
			}
		},
		/**
		 * @desc: 设置是否为点击状态
		 */
		setClickComponentStatus(state, value) {
			state.isClickComponent = value;
		},
		/**
		 * @desc: 获取编辑器对象
		 */
		getEditor(state) {
			state.editor = $('#editor');
			state.editorPosition = {
				startX: state.editor.offsetLeft,
				startY: state.editor.offsetTop,
				endX: state.editor.offsetLeft + state.editor.offsetWidth,
				endY: state.editor.offsetTop + state.editor.offsetHeight
			};
		},
		/**
		 * @desc: 直接设置图层数据 (历史记录，直接获取编辑等)
		 */
		setComponentData(state, componentData = []) {
			Vue.set(state, 'componentData', componentData);
			try {
				sessionStorage.setItem('componentData', JSON.stringify(state.componentData));
			} catch (err) {
				console.log(err, '----超出限制！')
			}
		},
		/**
		 * @desc: 设置默认布局数据
		 */
		setLayout(state, layoutData = {}) {
			state.pageConfig = layoutData || {};
			state.componentData = layoutData.layoutData || [];
			// 测试
			sessionStorage.setItem('pageConfig', JSON.stringify(layoutData));
			sessionStorage.setItem('componentData', JSON.stringify(state.componentData));
		},
		/**
		 * @desc: 更新当前组件样式
		 * @param {Object} style 需要更新的样式对象
		 */
		updateComponentStyle(state, style = {}) {
			state.curComponent.style = {
				...state.curComponent.style,
				...style
			};
		},
		/**
		 * @desc: 移动组件顺序(注意不是层级)
		 * @param {String} action 操作类型 forward 向前 backward 向后
		 */
		moveComponentOrder(state, { action }) {
			// 移动当前组件顺序
			const { componentData, curComponent } = state;
			// 需要找到当前组件所在下标及同级兄弟节点列表
			const siblings = getSiblings(componentData, curComponent);
			if (!siblings || !Array.isArray(siblings) || !siblings.length) return;
			// 获取下标(相对下标，在同级节点列表中的相对下标)
			let curIndex = -1;
			for (let i = 0; i < siblings.length; i++) {
				if (siblings[i].id === curComponent.id) {
					curIndex = i;
					break;
				}
			}
			if (curIndex < 0) return;
			if (action === 'forward') {
				// 向前
				if (curIndex > 0) {
					arrSwap(siblings, curIndex, curIndex - 1);
				} else {
					toast('已位于最前端!', 'warning');
				}
			} else if (action === 'backward') {
				// 向后
				if (curIndex < siblings.length - 1) {
					arrSwap(siblings, curIndex, curIndex + 1);
				} else {
					toast('已位于最后端!', 'warning');
				}
			}
		},
		/**
		 * @desc: 创建页面
		 * @param {String} editorType 类型 dataview 大屏 page 常规页面
		 * @param {String} title 名称
		 */
		createPage(state, { editorType, editorConfig, statusConfig }) {
			state.editorType = editorType;
			state.statusConfig = statusConfig;
			state.canvasStyle = {
				...state.canvasStyle,
				...editorConfig
			};
			sessionStorage.setItem('editorType', editorType)
			sessionStorage.setItem('canvasStyle', JSON.stringify(state.canvasStyle));
			sessionStorage.setItem('statusConfig', JSON.stringify(state.statusConfig));
		},
		/**
		 * @desc: 清除当前编辑的图层信息及相关状态
		 */
		clearComponents(state) {
			state.editorType = 'page';
			state.componentData = [];
			store.commit('setCurComponent', {
				component: null,
				index: null
			});
			store.commit('setClickComponentStatus', false);
			state.editor = null;
			state.editorPosition = {
				startX: 0,
				startY: 0,
				endX: 0,
				endY: 0
			};
			sessionStorage.removeItem('componentData');
		},
		/**
		 * @desc: 配置页面状态 (暂时弃用) 计划用于绑定其他组件来源值的用途
		 */
		setComCustomStatus(state, { comId, key, value }) {
			if (!comId || !key) return;
			const component = getComponentById(state.componentData, comId);
			/* eslint-disable */
			if (!component || !component.customStatus || !(component.customStatus).hasOwnProperty(key)) return;
			component.customStatus[key] = value;
		},
		/**
		 * @desc: 设置大屏移动状态
		 */
		setMoveStatus(state, status) {
			state.moveStatus = !!status;
		},
		/**
		 * @desc: 初始化配置详情页面数据@lisushuang
		 */
		initDetailsPage(state, { targetContainer, fullData, containerData, metadata }) {
			if (typeof metadata !== 'object') return;
			const fieldList = [];
			for (let key in metadata) {
				fieldList.push({
					name: metadata[key],
					uuid: key,
					renderConfig: {
						forView: true,
						width: '',
						type: 1
						// 其他特殊类型配置
					}
				});
			}
			// 形成componentData targetContainer.children || 
			const children = [];
			fieldList.forEach((ele, index) => {
				children.push({
					...JSON.parse(JSON.stringify(CommonText)),
					id: getComponentId(CommonText.component) + Math.random(),
					span: 12,
					...JSON.parse(JSON.stringify(commonAttr)),
					valueOrign: 'database',
					parentId: targetContainer.id,
					database: {
						bindUUID: ele.uuid, // 待补充
						containerKey: targetContainer.id, // 待补充
						databaseType: 'wordbook', // 值类型 value wordbook
						fieldList, // 待补充
						type: 'common',
						objectData: targetContainer.database.objectData,
						viewData: targetContainer.database.viewData
					},
					metadata
				})
				children.push({
					...JSON.parse(JSON.stringify(CommonText)),
					id: getComponentId(CommonText.component) + Math.random(),
					span: 12,
					...JSON.parse(JSON.stringify(commonAttr)),
					valueOrign: 'database',
					parentId: targetContainer.id,
					database: {
						bindUUID: ele.uuid, // 待补充
						containerKey: targetContainer.id, // 待补充
						databaseType: 'value', // 值类型 value wordbook
						fieldList, // 待补充
						type: 'common',
						objectData: targetContainer.database.objectData,
						viewData: targetContainer.database.viewData
					},
					metadata
				})
			});
			// console.log(children, '3333333333')
			store.commit('modifyComponent', {
				component: {
					...targetContainer,
					style: {
						...targetContainer.style,
						'flex-wrap': 'wrap'
					},
					children
				}, containerId: null, isModify: true
			})
		},
		/**
		 * @desc: 设置附属页面配置信息(表单内嵌页)
		 */
		setSubsidiaryComponentData(state, { pageUUID, pageConfig }) {
			state.subsidiaryComponentData = {
				...state.subsidiaryComponentData,
				[pageUUID]: pageConfig
			};
		}
	}
};
