/*
 * @Description: 数据结构
 * @Author: luocheng
 * @Date: 2021-09-06 15:34:39
 * @LastEditors: 朱廷果
 * @LastEditTime: 2022-12-23 12:03:03
 */
import { axios } from '../http/request';
/**
 * @desc: 删除search参数中的value为空的筛选
 */
const removeNullSearch = data => {
	for (let key in data) {
		if (key === 'search' && Array.isArray(data[key]) && data[key].length) {
			let search = [];
			for (let i = 0; i < data[key].length; i++) {
				if (data[key][i].value) {
					search.push(data[key][i]);
				}
			}
			return {
				...data,
				search
			}
		}
	}
	return data;
}
/**
 * @desc: 获取数据仓库对象
 * @param {Object} { name, code, uuid, page, size }
 */
export const getObjects = (params) => {
	return axios.request({
		url: 'api/saas/metadata/objects',
		params,
		method: 'GET',
		size: 50
	});
};

/**
 * @desc: 获取视图列表
 * @param {Object} params object_uuid 数据仓库对象的uuid
 */
export const getViews = (params) => {
	return axios.request({
		url: 'api/saas/metadata/views',
		params,
		method: 'GET'
	});
};

/**
 * @desc: 获取字段列表
 * @param {Object} { object_uuid } 对象uuid
 */
export const getFields = (params) => {
	return axios.request({
		url: 'api/saas/metadata/objectFields',
		params,
		method: 'GET'
	});
};

/**
 * @desc: 获取表单
 * @param {Object} { object_uuid } 对象uuid
 */
 export const getForms = (params) => {
	return axios.request({
		url: 'api/saas/metadata/getFormDesigns',
		params,
		method: 'GET'
	});
};


/**
 * @desc: 获取关联关系
 * @param {Object} {owner_uuid} 关联的对象uuid
 * @return {*}  
 */
export const getRelations = (params) => {
	return axios.request({
		url: 'api/metadata/relationships',
		params,
		method: 'GET'
	});
}
/**
 * @desc: 获取实际渲染的数据
 * @param {Object} data
 * __method_name__   类型：dataList —— 列表
 * object_uuid       对象ID
 * view_uuid         视图ID
 * search            筛选项(数组)
 */
export const getRenderData = (data) => {
	return axios.request({
		url: 'api/mapi',
		data: removeNullSearch(data),
		method: 'POST'
	});
};

// const baseUrl = process.env.VUE_APP_BASE_URL;

/**
 * @desc: 用户数据接口
 */
export const dataInterface = (data, url, method = 'POST') => {
	// // 分服务器环境请求
	// const pathname = window.location.pathname;
	let requestUrl = url;
	// 	console.log('0000000000', data, url )
	// if (pathname === '/modify-page' || pathname === '/page-view') {
	// 	if (!url || (url && url.indexOf('http') === -1)) {
	// 		try {
	// 			const targetServer = JSON.parse(localStorage.getItem('targetServer'));
	// 			console.log(targetServer, '----targetServer------')
	// 			requestUrl = `${targetServer?.originURl || baseUrl}/${requestUrl ? requestUrl : 'api/mapi'}`;
	// 		} catch(err) {
	// 			console.log(err)
	// 		}
	// 	}
	// }
	if (method && method === 'GET') {
		return axios.request({
			url: requestUrl || 'api/mapi',
			params: removeNullSearch(data),
			method
		});
	}
	return axios.request({
		url: requestUrl || 'api/mapi',
		data: removeNullSearch(data),
		method: 'POST'
	});
}

/**
 * @desc: 缓存
 */
export const globalCache = {
	get: (key) => {
		return axios.request({
			url: 'system/get/global/cache',
			params: { key: key },
			method: 'GET'
		});
	},
	put: (key, value) => {
		return axios.request({
			url: 'system/put/global/cache',
			data: { key: key, value: value },
			method: 'POST'
		});
	}
};
//字段UUID转换
export const transFieldsUUid = (obuuid, formuuid, params) => {
	return axios.request({
		url: `api/saas/metadata/transformuuid/${formuuid || obuuid}`,
		params,
		method: 'GET'
	});
};

/**
 * @description 获取天气详情
 */

export const getWeather = () => {
	return axios.request({
		url: 'https://v0.yiketianqi.com/api/',
		params: {
			appid: '77521772',
			appsecret: 'Oec5XY9T',
			version: 'v61' //
		},
		method: 'get'
	})
}

// 迁移页面
export const transferPage = (data) => {
	return axios.request({
		url: process.env.VUE_APP_BASE_URL + 'api/metadata/page/migrate',
		data,
		method: 'POST'
	});
}