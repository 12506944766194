<!--
 * @Description: 带日期的按钮
 * @Author: luocheng
 * @Date: 2022-07-29 18:06:30
 * @LastEditors: luocheng
 * @LastEditTime: 2022-08-29 16:44:24
-->
<template>
  <div class="common-date-button">
    <el-button :type="statusConfig.type" class="common-button"
      :size="statusConfig.size"
      :plain="statusConfig.plain"
      :round="statusConfig.round"
      :circle="statusConfig.circle"
      :loading="statusConfig.loading"
      :disabled="statusConfig.disabled"
      :icon="statusConfig.icon"
      :autofocus="statusConfig.autofocus"
      :class="{
        'just-icon': !element.propValue
      }"
    >{{ element.propValue || '' }}</el-button>
    <!-- 日期 -->
    <el-date-picker
      v-if="statusConfig.pickerType === 'date' && pickerOptions"
      v-model="dataValue"
      :type="statusConfig.dateFormat"
      :value-format="statusConfig.valueFormat"
      @change="onSelectDate"
      :picker-options="pickerOptions"
      placeholder="请选择日期">
    </el-date-picker>
    <!-- TO DO： 新增月年周 -->
    <!-- 周 -->
    <YearWeek
      @select="onSelectDate"
      v-else-if="statusConfig.pickerType === 'week'"
      :advance="statusConfig.advance"
    ></YearWeek>
    <!-- 月 -->
    <DateButtonMonth
      @select="onSelectDate"
      v-else-if="statusConfig.pickerType === 'month'"
      :advance="statusConfig.advance"
    ></DateButtonMonth>
    <!-- 年 -->
    <el-date-picker
      v-else-if="statusConfig.pickerType === 'year'"
      v-model="dataValue"
      type="year"
      @change="onSelectYear"
      :picker-options="yearPickerOptions"
      :value-format="statusConfig.valueFormat"
      placeholder="选择年">
    </el-date-picker>
    <!-- <DateButtonYear
      @select="onSelectDate"
      v-else-if="statusConfig.pickerType === 'year'"
      :advance="statusConfig.advance"
    ></DateButtonYear> -->
  </div>
</template>

<script>
import { DatePicker } from 'element-ui';
import YearWeek from './dateButtonComponents/YearWeek';
import DateButtonMonth from './dateButtonComponents/DateButtonMonth';
// import DateButtonYear from './dateButtonComponents/DateButtonYear';

export default {
  name: 'CommonDateButton',
  components: {
    'el-date-picker': DatePicker,
    YearWeek,
    DateButtonMonth,
    // DateButtonYear
  },
  props: {
    element: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      dataValue: '',
      pickerOptions: null,
      yearPickerOptions: null
    }
  },
  computed: {
    // 状态
    statusConfig() {
      return this.element?.statusConfig;
    }
  },
  created() {
    const that = this;
    this.pickerOptions = {
      disabledDate(time) {
        if (that.statusConfig.advance) {
          return false;
        }
        return time.getTime() > Date.now();
      }
    }
    this.yearPickerOptions = {
      disabledDate(time) {
        if (that.statusConfig.advance) {
          return false;
        }
        return time.getTime() > Date.now();
      }
    }
  },
  methods: {
    /**
     * @desc: 选择日期
     * @param {Object/String} dateResult
     */
    onSelectDate(dateResult) {
      const weekStr = ['星期天', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
      let resultDesc, startTime;
      let isUserAction = false;
      if (this.statusConfig.pickerType === 'date') {
        // 日期
        resultDesc = dateResult.split(' ')?.[0],
        startTime = dateResult;
        isUserAction = true;
      } else {
        resultDesc = dateResult?.data?.resultDesc;
        startTime = dateResult?.data?.startTime;
        isUserAction = dateResult.isUserAction;
      }
      this.element.resolveData ={
        date: startTime,
        resultDesc,
        weekDay: weekStr[(new Date(startTime)).getDay()] // 仅日期有效
      };
      if (isUserAction) {
        this.onAction();
      }
    },
    /**
     * @desc: 选择年
     * @param {Number} year
     */
    onSelectYear(dateStr) {
      const date = new Date(dateStr);
      this.onSelectDate({
        data: {
          resultDesc: `${date.getFullYear()}年`,
          startTime: `${date.getFullYear()}-1-1 00:00:00`
        },
        isUserAction: true
      })
    },
    /**
     * @desc: 选择时间
     */
    onAction() {
      // 兼容change事件
      this.dataValue = '';
      if (
				!this.element.events ||
				!this.element.events.click ||
				!Array.isArray(this.element.events.click.effects)
			) {
				return false
			}
			const click = this.element.events.click || {};
			const { pattern, effects = [], specialEventList = []} = click;
			if (!pattern) {
				if (click.actionType === 'pageStatus') {
					// 修改页面状态
					this.updatePageStatus(click.stateMachine || []);
				}
				effects.forEach((ele) => {
					this.$store.commit('triggerEvents', {
						config: ele,
						element: this.element
					});
				});
			} else if (pattern === 'special') {
				specialEventList.forEach(ele => {
					const { actionType } = ele;
					if (actionType === 'pageStatus') {
						// 修改页面状态
						this.updatePageStatus(ele.stateMachine || []);
					}
					ele.effects.forEach(effect => {
						this.$store.commit('triggerEvents', {
							config: effect,
							element: this.element
						});
					})
				});
			}
    }
  }
}
</script>

<style lang="less" scoped>
.common-date-button{
  width: auto;
  position: relative;
  display: flex;
  overflow: hidden;
  .el-button{
    flex: 1;
  }
  .el-date-editor{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    z-index: 1000;
    input{
      cursor: pointer;
    }
  }
}
.date-button-child{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  >span{
    background: transparent;
  }
  .el-button{
    opacity: 0;
  }
}
</style>
<style lang="less">
.date-button-btn{
  opacity: 0;
  width: 80px;
}
.common-date-button-list{
  width: 100%;
  height: 300px;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  box-sizing: border-box;
  // align-content: flex-start;
  &.common-date-button-list_month{
    height: 200px;
  }
  .just-title{
    display: block;
    text-align: center;
    font-size: 14px;
  }
  .list-item{
    width: 90px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-size: 14px;
    margin-bottom: 5px;
    border-radius: 4px;
    overflow: hidden;
    margin-right: 5px;
    &:hover{
      background: @theme;
      color: #fff;
      font-weight: bold;
    }
    &:nth-of-type(3n){
      margin-right: 0;
    }
    &.is-target{
      background: @theme;
      color: #fff;
      font-weight: bold;
    }
    &.disabled{
      background: #f5f7fa;
      color: #c0c4cc;
    }
  }
}
</style>