/*
 * @Description: 复制 剪切  粘贴
 * @Author: luocheng
 * @Date: 2021-08-12 14:06:29
 * @LastEditors: Shiltin 18580045074@163.com
 * @LastEditTime: 2022-09-01 18:28:33
 */
import store from './index';
import toast from '@/utils/toast';
import { getComponentId, deepCopy, changeComponentsId } from '@/utils/tools';
import { setParseData } from '@/utils/editor';
export default {
	state: {
		// 复制 剪切的数据
		copyData: null,
		// 是否为剪切
		isCut: false
	},
	mutations: {
		/**
		 * @desc: 复制或剪切组件
		 */
		copyComponent(state) {
			if (!state.curComponent) return;
			state.copyData = {
				data: deepCopy(state.curComponent),
				index: state.curIndex
			};
			state.isCut = false;
		},
		/**
		 * @desc: 粘贴
		 * @param {Boolean} isMouse 是否为鼠标事件  false 时候例如contrl + v等快捷操作
		 */
		pasteComponent(state, isMouse) {
			if (!state.copyData) {
				toast('请选择组件', 'warning');
				return;
			}
			let data = state.copyData.data;
			const curComponent = state.curComponent;
			data.id = getComponentId(data.component);
			// 容器、组合修改内部组件ID,替换组件依赖值问题
			if (data.component === 'CommonGroup') {
				data = {
					...data,
					propValue : changeComponentsId(data.propValue || [])
				}
			}
			// 容器启用新ID且需要将依赖值的组件ID同步替换
			if (data.component === 'CommonScreenContainer' || data.component === 'CommonContainer' || data.component === 'ConsoleSignCont') {
				data = {
					...data,
					children: changeComponentsId(data.children || [])
				}
			}
			if (state.editorType === 'dataview' || state.editorType === 'console') {
				// 仅绝对定位需要left top
				if (isMouse) {
					data.style.top = state.editorMenuPosition.top;
					data.style.left = state.editorMenuPosition.left;
				} else {
					data.style.top += 10;
					data.style.left += 10;
				}
			} else if (state.editorType === 'page') {
				// 常规布局
				if (data.children && Array.isArray(data.children) && data.children.length) {
					data = setParseData(deepCopy(data));
				}
			}
			const containerId = curComponent?.component === 'CommonContainer' ? curComponent.id : null
			store.commit('modifyComponent', { component: deepCopy(data), containerId });
			// 剪切
			if (state.isCut) {
				state.copyData = null;
				state.isCut = false;
			}
		},
		/**
		 * @desc: 剪切
		 */
		cutComponent(state) {
			if (!state.curComponent) {
				toast('请选择组件');
				return;
			}

			if (state.copyData) {
				const data = deepCopy(state.copyData.data);
				const index = state.copyData.index;
				data.id = getComponentId(data.component);
				store.commit('modifyComponent', { component: data, index });
				if (state.curIndex >= index) {
					// 如果当前组件索引大于等于插入索引，需要加一，因为当前组件往后移了一位
					state.curIndex++;
				}
			}
			store.commit('copyComponent');
			store.commit('deleteComponent');
			state.isCut = true;
		}
	}
};
