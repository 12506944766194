<!--
 * @Author: ttheitao
 * @Description: some description
 * @Date: 2022-08-03 15:37:56
 * @LastEditors: ttheitao
 * @LastEditTime: 2022-08-04 10:13:22
-->
<template>
  <div
    :is="'el-' + mode"
    :title="title"
    :width="width"
    :visible.sync="show"
    :direction="direction"
    :before-close="handleClose"
  >
    <div :class="mode == 'drawer' ? 'pl-5 pr-5' : ''">
      <slot name="content"></slot>
    </div>
    <span v-if="mode == 'dialog'" slot="footer" class="dialog-footer">
      <el-button @click="cancel()">取 消</el-button>
      <el-button type="primary" @click="sure()" :loading="loading">{{
        loading ? "提交中 ..." : "确 定"
      }}</el-button>
    </span>
    <div v-else class="p-5 text-right">
      <el-button @click="cancel()">取 消</el-button>
      <el-button type="primary" @click="sure()" :loading="loading">{{
        loading ? "提交中 ..." : "确 定"
      }}</el-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "modalCont",
  props: {
    mode: {
      //模式，dialog和drawer
      type: String,
      default: "dialog",
    },
    show: {
      //是否显示
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    width: {
      type: [Number, String],
      default: "700",
    },
    direction: {
      type: String,
      default: "rtl",
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    handleClose() {
      // this.$confirm('确认关闭？')
      // .then(_ => {
      // 	this.$emit('cancel');
      // })
      // .catch(_ => {});
      this.$emit("cancel");
    },
    cancel() {
      this.$emit("cancel");
    },
    sure() {
      this.loading = true;
      this.$emit("sure");
    },
  },
};
</script>