<!--
 * @Author: zx
 * @Date: 2022-08-17 17:51:48
 * @LastEditTime: 2023-02-08 09:54:45
 * @Description:
 * @LastEditors: 朱廷果 1028509503@qq.com
-->
<template>
  <div class="menu" :class="{'system-first': +systemStyle === 1}">
    <div class="menu-top">
      <div class="menu-top-icon" :style="+systemStyle === 1? 'margin-bottom: 8px' : ''">
        <el-tooltip
          effect="dark"
          placement="right"
          :open-delay="100"
        >
          <template #content>
            <p>官网</p>
          </template>
          <img v-if="+systemStyle === 2" src="https://static.bimcc.com/openim/1665385818368D2BCCF5DF7E4984918C6918F62AAF01A.png" width="60" height="60" alt="" @click="jumpToWebHome">
          <img v-if="+systemStyle === 1" src="https://static.bimcc.com/openim/166441421944149D6E97E017D078BCC0386B0DF6C26F3.png" width="60" height="60" alt="" @click="jumpToWebHome">
        </el-tooltip>
      </div>
      <div class="menu-container">
        <div
          v-for="(route, index) in menuList"
          :key="index + route.name"
          class="menu-item"
          :style="+systemStyle === 1 && index === 0 ? 'margin-top: 0' : ''"
          :class="+route.id === +currentMenu.id ? +systemStyle === 1 ? 'system-style-active' : 'menu-item-active': ''"
          @click="menuItemClick(route)"
        >
          <div class="menu-item-container">
            <template v-if="+systemStyle === 1">
              <el-tooltip
                effect="dark"
                placement="right"
                :content="route.name"
                :open-delay="100"
              >
                <div class="icon-label-container" :class="{'hidden-label' : +blueLabel !== 1}">
                  <i :class="'iconfont ' + route.icon"></i>
                  <p v-if="+blueLabel === 1">{{route.name}}</p>
                </div>
              </el-tooltip>
            </template>
            <template v-if="+systemStyle === 2">
              <el-tooltip
                effect="dark"
                placement="right"
                :content="route.name"
                :open-delay="100"
              >
                <div :class="{'hidden-dark-label' : +darkLabel !== 1}">
                  <i :class="'iconfont ' + route.icon"></i>
                  <span v-if="+darkLabel === 1">{{ route.name }}</span>
                </div>
              </el-tooltip>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="menu-bottom">
      <MenuBottom
        :menuList="menuList"
        @closeMenuItem="closeMenuItem"
        @currentMenuList="currentMenuList"
        @updateMenuList="updateMenuList"
      />
    </div>
  </div>
</template>
<script>
import routers from '@/router/saasBase'
import request from '@/apis/http/saasRequest.js'
import MenuBottom from './MenuBottom.vue'
import { mapState } from 'vuex';
import { Tooltip } from 'element-ui';
import { getToken } from '@/utils/tools';
export default {
  components:{
    'el-tooltip': Tooltip,
    MenuBottom
  },
  props:{
    currentRouteName: {
      type: String || Number,
      default: ''
    }
  },
  data() {
    return {
      extra: {},
      handleRoutes: [],
      menuList: [],
      currentMenu: {},
      hasDept:'', // 判断是否有组织架构页面
    }
  },
  watch: {
    currentRouteName(val) {
      this.changeRoute(val)
    },
    saasTargetArchi() {
      this.getMenuList(true)
    }
  },
  computed: {
    ...mapState(['saasCurrentMenu', 'systemStyle', 'saasTargetArchi', 'blueLabel', 'darkLabel'])
  },
  created() {
    this.$store.commit('setSystemStyle', localStorage.getItem('system_style'))
    /* 获取官网所有路由名称数组 */
    let websiteList = []
    const filesWebsite = require.context('@/router/website', true, /\.js$/)
    filesWebsite.keys().forEach(key => {
      const name = Object.keys(filesWebsite(key).default)
      name.forEach(element => {
        websiteList.push(filesWebsite(key).default[element].path)
      });
    })
    /* 菜单栏过滤官网的功能 */
    // this.handleRoutes = routers.filter((item) => {
    //   return ['/', '/login','/second'].indexOf(item.path) === -1 && websiteList.indexOf(item.path) === -1
    // })
    this.handleRoutes = routers.filter(({path})=>{
      return ['/', '/login','/second'].includes(path) && !websiteList.includes(path);
    })
    this.getMenuList()
  },
  methods:{
    changeRoute(val, canEmit) {
      if (!val || val === '/home') {
        const loopRoutes = (list) => {
          list.forEach(element => {
            if (element.path === '/home') {
              this.currentMenu  = element
            } else if (element.children && element.children.length) {
              loopRoutes(element.children)
            }
          });
        }
        loopRoutes(this.menuList)
        return
      }
      let boo = false
      this.menuList.forEach(element => {
        if (+element.id === +val) {
          boo = true
          this.currentMenu  = element
        }
      });
      if (boo) {
        if (!canEmit) {
          this.$emit('menuClick', this.currentMenu, false)
        }
        return
      }
      let num = null
      const loopRoutes = (list) => {
        list.forEach(element => {
          if (+element.id === +val) {
            let arr = element.all_parent.split(',').filter((item) => +item)
            arr = arr.map((item) => {
              return +item
            })
            num = arr[0]
          } else if (element.children && element.children.length) {
            loopRoutes(element.children)
          }
        });
      }
      loopRoutes(this.menuList)
      if (num) {
        this.menuList.forEach(element => {
          if (+element.id === +num) {
            this.currentMenu = element
            if (!canEmit) {
              this.$emit('menuClick', element, false)
            }
          }
        });
      }
    },
    getMenuList(boo) {
      /* 传入此布尔值为true  则记录当前选择的menu  用于刷新数据后继续寻找当前menu  保持menu不变 */
      if(getToken()){
        request({ url: 'api/saas/menuPermission', method: 'post', data: {} }).then((res) => {
          if (res.status === 200 && res.data && res.data.code === 200) {
            let menuTree = res.data.data.tree
            sessionStorage.setItem('saasMenu',JSON.stringify(menuTree))
            if (!boo) {
              localStorage.setItem('userRoles', JSON.stringify(res.data.data.permission))
              localStorage.setItem('routeRoles', JSON.stringify(res.data.data.route))
              let routeRoles = res.data.data.route
              if (routeRoles && routeRoles.length) {
                this.hasDept = routeRoles.some( item => {
                  if('/org/dept'.indexOf(item) !== -1) {
                    return true;
                  }
                })
                this.$emit('dept',this.hasDept)
              }
              if (window.location.pathname === '/home' && this.saasCurrentMenu && this.saasCurrentMenu.route !== '/home') {
                sessionStorage.removeItem('saasCurrentMenu')
                this.$store.commit('setCurrentMenus', null)
              }
              let appsInfo = {} // 应用的menuItem
              const loopMenuTree = (array) => {
                for (let index = 0; index < array.length; index++) {
                  array[index].path = array[index].route
                  if (array[index].is_apps) {
                    appsInfo = array[index]
                  }
                  /* 判断当前网页路径 */
                  if (array[index].path === window.location.pathname) {
                    this.$emit('menuClick', array[index], false)
                  }
                  if (array[index].children && array[index].children.length) {
                    if (+array[index].children[0].type !== 3) {
                      loopMenuTree(array[index].children)
                    } else {
                      array[index].children = []
                    }
                  }
                }
              }
              if (menuTree && menuTree.length) {
                loopMenuTree(menuTree)
              }
              this.menuList = menuTree
              if (location.href.includes('?chooseApps') && window.location.pathname === '/home') {
                this.$nextTick(() => {
                  this.currentMenu = appsInfo
                  this.$emit('menuClick', appsInfo, true)
                })
                return
              }
              let menuId = ''
              if (this.menuList && this.menuList.length && this.saasCurrentMenu && this.saasCurrentMenu.all_parent && this.saasCurrentMenu.id) {
                let arr = this.saasCurrentMenu.all_parent.split(',').filter((item) => +item)
                if (arr && arr.length) {
                  menuId = arr[0]
                }
              }
              /* session保存的当前菜单或者点击后的当前菜单判断当前菜单选中及状态 */
              if ((this.currentMenu && this.currentMenu.id) || menuId) {
                this.menuList.forEach(element => {
                  if (+element.id === +this.currentMenu.id || ( menuId && +element.id === +menuId) ) {
                    this.currentMenu = element
                    this.$emit('menuClick', this.currentMenu, false)
                  }
                });
              }
              this.$emit('menuList', this.menuList)
              const route = this.$route
              const routeList = ['/dwh/form-list', '/dwh/objects/detail', '/modify-page','/config-chart']
              if (routeList.indexOf(route.path) !== -1) {
                this.changeRoute(this.currentRouteName, true)
                return
              }
              this.changeRoute(this.currentRouteName)
            } else {
              const loopMenuTree = (array) => {
                for (let index = 0; index < array.length; index++) {
                  array[index].path = array[index].route
                  /* 判断当前网页路径 */
                  if (array[index].path === window.location.pathname) {
                    if (!this.currentMenu.is_apps) {
                      this.currentMenu = array[index]
                    }
                    this.$emit('menuClick', array[index], false)
                  }
                  if (array[index].children && array[index].children.length) {
                    if (+array[index].children[0].type !== 3) {
                      loopMenuTree(array[index].children)
                    } else {
                      array[index].children = []
                    }
                  }
                }
              }
              if (menuTree && menuTree.length) {
                loopMenuTree(menuTree)
              }
              this.menuList = menuTree
              let menuInfo = {}
              menuInfo = this.currentMenu
              this.menuList.forEach(element => {
                if (element.id === menuInfo.id) {
                  this.currentMenu = element
                  this.$emit('menuClick', this.currentMenu, false)
                }
              });
              this.$emit('menuList', this.menuList)
            }
          }
        })
      }

    },
    menuItemClick(data) {
      this.currentMenu = data
      this.$emit('menuClick', data, true)
    },
    jumpToWebHome() {
      window.location.href = 'https://www.bimcc.com/web-page/home'
      // this.$router.push({ path: '/web-page/home' })
    },
    closeMenuItem(boo) {
      this.$emit('closeMenuItem', boo)
    },
    currentMenuList(data) {
      this.$emit('currentMenuList', data)
    },
    updateMenuList(boo) {
      this.getMenuList(boo)
    },
  }
}
</script>
<style lang='less' scoped>
.menu {
  width: 66px;
  height: 100%;
  background-color: #282C34;
  display: flex;
  flex-direction: column;
  color: #fff;
  padding-top: 8px;
  box-sizing: border-box;
  justify-content: space-between;
  .menu-top{
    flex:1;
    overflow: hidden;
    .menu-top-icon {
      width: 100%;
      height: 60px;
      text-align: center;
      line-height: 60px;
      i {
        font-size: 30px;
        font-weight: 600;
      }
      img {
        cursor: pointer;
      }
    }
    .menu-container::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    .menu-container {
      flex: 1;
      overflow-y: auto;
      padding: 0 4px;
      box-sizing: border-box;
      /* 隐藏滚动条 */
      scrollbar-width: none; /* firefox */
      -ms-overflow-style: none; /* IE 10+ */
      .menu-item {
        width: 100%;
        box-sizing: border-box;
        margin-top: 6px;
        .menu-item-container {
          display: flex;
          flex-direction: column;
          height: 52px;
          overflow: hidden;
          text-align: center;
          i {
            flex: 1;
            line-height: 28px;
          }
          div {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            i {
              flex: 1;
              line-height: 28px;
            }
            span {
              flex: 1;
              line-height: 18px;
              font-size: 12px;
              color: #fff;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }
    .menu-item:hover {
      background-color: #1890ff;
      border-radius: 5px;
    }
    .menu-item-active {
      background-color: #1890ff;
      border-radius: 5px;
    }
    .system-style-active {
      background: rgba(250, 251, 252, 0.2);
      border-radius: 8px;
      .menu-item-container {
        div {
          
          i {
            color: #fff;
            opacity: 1 !important;
          }
          p {
            color: #fff;
            opacity: 1 !important;
          }
        }
      }
    }
  }
}
.hidden-dark-label {
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    margin-top: 0;
    width: 24px;
    font-size: 24px;
    height: 24px;
    line-height: 52px !important;
  }
}
.system-first {
  width: 56px;
  height: 100%;
  background: #337EFF;
  .menu-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    .menu-top-icon {
      height: 48px;
      width: 48px;
      img {
        width: 48px;
        height: 48px;
      }
      img:hover {
        background: rgba(250, 251, 252, 0.2);
        border-radius: 8px;
      }
    }
    .menu-container {
      flex: 1;
      overflow-y: auto;
      padding: 0;
      .menu-item:hover {
        background: rgba(250, 251, 252, 0.2);
        border-radius: 6px;
      }
      .menu-item {
        width: 48px;
        height: 48px;
        border-radius: 6px;
        .menu-item-container {
          width: 48px;
          height: 48px;
          align-items: center;
          justify-content: center;
          .icon-label-container {
            i {
              font-size: 24px;
              opacity: 0.7;
              flex: inherit;
              line-height: 24px;
              margin-top: 5px;
            }
            p {
              transform: scale(0.8333);
              height: 16px;
              font-size: 10px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #FFFFFF;
              opacity: 0.7;
              line-height: 16px;
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          .hidden-label {
            display: flex;
            align-items: center;
            justify-content: center;
            i {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
}
</style>
