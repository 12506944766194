import { render, staticRenderFns } from "./AddDrawer.vue?vue&type=template&id=246447bc&scoped=true&"
import script from "./AddDrawer.vue?vue&type=script&lang=js&"
export * from "./AddDrawer.vue?vue&type=script&lang=js&"
import style0 from "./AddDrawer.vue?vue&type=style&index=0&id=246447bc&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "246447bc",
  null
  
)

export default component.exports