<template>
  <div class="quality-pages">
      <ModulePages v-if="module" :module="module" :id="id" :name="name" @close="close"></ModulePages>
       <!-- 底部 -->
      <PublicFooter v-if="!id"></PublicFooter>
  </div>
</template>

<script>
import ModulePages from './components/ModulePages.vue';
import { dataInterface } from '@/apis/data/saasMapi';
import PublicFooter from '@/website-views/components/publicFooter/index.vue'
export default {
    components:{ModulePages,PublicFooter},
    props: {
      id: {
        type: Number,
        default: 0
      },
      name: {
        type: String,
        default: ''
      }
    },
    data() {
        return {
          module: {},
        };
    },
    mounted(){
      // this.getData()
      this.module = this.$route.params.module
      console.log(this.module)
    },
    methods:{
      // 详细内容接口 
      getData() {
      let params = {
        object_uuid:'object622eefb3ebecc',
        view_uuid:'view6333b160ee53d',
        __method_name__: 'dataInfo',
        data_id: this.$route.query.id || this.id,
        transcode: 0,
      }
      dataInterface(params)
        .then((res) => {
          this.loading = false
          console.log(res,"res--------res");
          if (res.status !== 200) return
          this.module = res.data.data
        })
        .catch(() => {
        })
      },
      close(boo) {
        this.$emit('close', boo)
      }
    }
}
</script>

<style lang="less" scoped>
</style>
