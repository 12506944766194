<!--
 * @Author: zx
 * @Date: 2022-10-13 09:14:06
 * @LastEditTime: 2023-01-11 09:21:56
 * @Description: 
 * @LastEditors: 朱廷果 1028509503@qq.com
-->
<!--
 * @Description: 模块通用页面
 * @Author: hw
 * @Date: 2022-09-08 10:17:29
-->
<template>
  <div class="temp-child-pages">
    <!-- 头部面包屑 -->
    <div v-if="!id" class="header-title">
      <router-link to="/web-page/templates"><span>全部</span></router-link>
      <i class="iconfont iconlujingjiantou"></i>
      <router-link to="/web-page/templates"
        ><span>{{ moduleName }}</span></router-link
      >
      <i class="iconfont iconlujingjiantou"></i>
      <span class="module-name">{{ module.name }}</span>
    </div>
    <!-- 头部标题 -->
    <div class="module-title" v-loading="loading">
      <div class="left-content">
        <div class="pic" v-if="module?.image">
          <div class="iconfont-div" :style="'background:' + module.background">
            <svg aria-hidden="true" class="iconfont-svg" >
              <use :xlink:href="module.icon" />
            </svg>
          </div>

          <!-- <img :src="module.image??''" alt="" /> -->
        </div>
        <div class="left-text">
          <h1 class="title">
            {{ module?.name
            }}<span class="cost-type">{{
              module?.is_pay === 0 ? "免费" : "付费"
            }}</span>
          </h1>
          <p>{{ module.describe }}</p>
        </div>
      </div>
      <div class="btn">
        <div
          class="right-btn"
          @click.stop="getApp(module)"
        >
          <span v-if="module.is_install === 1">已安装</span>
          <span v-else>{{
            module.is_pay === 0 ? "安装模块" : "试用模块"
          }}</span>
        </div>
        <div class="right-btn query" @click.stop="jumpLogin">
          <span>立即咨询</span>
        </div>
      </div>
    </div>
    <!-- 菜单栏 -->
    <div class="menu-nav">
      <div class="menu">
        <div class="min-menu" v-if="module.saas_apps_detail">
          <a
            class="menu-box"
            v-for="(item, index) in module.saas_apps_detail"
            @click.stop="setActive(item, index)"
            :class="{ activeMenu: isActive === index }"
            :key="index"
            >{{ item.name }}</a
          >
        </div>
      </div>
    </div>
    <body>
      <div class="content">
        <div v-html="content"></div>
      </div>
    </body>
    <!-- 确认是否安装 -->
    <el-dialog
      :visible.sync="dialogVisible"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      width="400px"
      min-width="400px"
      :modal="false"
      :before-close="handleClose"
    >
      <div class="install-box">
        <div class="content">
          <p class="install-num">
            {{ installAll === true ? 100 : num.toFixed(2) }}%
          </p>
          <p class="text" v-if="!installAll">应用正在安装...</p>
          <button v-else class="btn" @click.stop="handleComplete">完成</button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { installApp } from "@/api/saas";
import { getToken } from '@/utils/tools'
export default {
  props: {
    module: {
      type: Object,
      default: () => {},
    },
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isActive: null,
      content: null,
      moduleName: null,
      img: null,
      dialogVisible: false,
      confirmDialog: false,
      num: 0,
      userInfoId: 0,
      installAll: false,
      isComplete: false,
      app_id: null,
      loading: true,
    };
  },
  mounted() {
    this.setActive("", 0);
    this.moduleName = this.$route.query.name || this.name;
    this.userInfoId = JSON.parse(localStorage.getItem("userInfo")).saas_admin;
    this.app_id = +window.location.href.split("id=")[1].split("&")[0];
  },
  watch: {
    module(val) {
      console.log(val)
      if(val){
        this.loading = false;
        this.content = val.saas_apps_detail?.[0]?.content;
        this.img = val.large_icon?.[0]?.path;
      }
    },
  },
  methods: {
    setActive(item, index) {
      this.isActive = index;
      this.content = item.content;
    },
    getApp(item) {
      const token = getToken()
      if (!token) {
        this.$message({
          message: '您还未登录，请先登录',
          type: 'info',
        })
        this.$router.push({ name: 'Login'})
        return
      }
      if (item.is_install === 1) {
        this.$router.push({ path: "/home", query: { chooseApps: true } });
      } else if (item.is_pay === 1) {
        this.jumpLogin();
      } else {
        this.$confirm("是否确认安装此应用?", "安装确定", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          customClass: "module-pages-message-box", // :deep不生效 在外层加class名防止影响其它页面元素
        }).then(() => {
          this.handleConfirm();
        });
      }
    },
    handleConfirm() {
      this.dialogVisible = true;
      this.confirmDialog = false;
      let params = {
        app_id: this.app_id,
      };
      let timer = setInterval(() => {
        this.num += 0.05;
        if (this.num > 90 || this.num === 100) {
          window.clearInterval(timer);
        }
      }, 100);
      this.installAll = false;
      installApp(params)
        .then((res) => {
          if (timer) {
            window.clearInterval(timer);
          }
          this.num = 100;
          if (res.status === 200 && res.data && res.data.code === 200) {
            this.isComplete = true;
            this.installAll = true;
          } else {
            this.dialogVisible = false;
            this.isComplete = false;
          }
        })
        .catch((res) => {
          if (timer) {
            window.clearInterval(timer);
          }
          this.dialogVisible = false;
          this.$message(res.data.msg);
        });
    },
    jumpLogin() {
      this.$store.commit("setIndexCurrentTab", 7);
      this.$router.push({
        path: "/web-page/apply-demo",
        query: this.id ? { prohibitFirstJump: true } : {},
      });
    },
    handleClose(done) {
      done();
    },
    handleComplete() {
      this.dialogVisible = false;
      if (this.isComplete) {
        this.$emit("close", true);
        this.$router.push({ path: "/home", query: { chooseApps: true } });
      } else {
        this.$emit("close", false);
      }
    },
  },
};
</script>
<style lang="less">
.module-pages-message-box {
  border: none;
  border-radius: 10px;
  .el-message-box__header {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    height: 48px;
    background: linear-gradient(
      90deg,
      #6292ff 0%,
      #de93ff 84%,
      #ce92ff 91%,
      #c568ff 100%
    );
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 68px 0 24px;
    color: #fff;
    .el-message-box__title {
      color: #fff;
      font-size: 14px;
    }
    .el-message-box__headerbtn {
      top: 14px;
      right: 24px;
      .el-message-box__close {
        color: #fff;
        font-size: 20px;
      }
    }
  }
}
</style>
<style lang="less" scoped>
.temp-child-pages {
  z-index: 9999;
  margin: 0 auto;
  padding-top: 80px;
  width: 1200px;
  min-width: 1200px;
  align-items: center;
  // 面包屑
  .header-title {
    margin: 24px 0 22px;
    font-family: "Source Han Sans CN";
    span {
      padding-right: 10px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #697077;
    }
    span:hover {
      color: #337eff;
    }
    .module-name {
      line-height: 24px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #121619;
    }
    .iconfont {
      padding-right: 10px;
      font-size: 13px;
      transform: matrix(0, 1, 1, 0, 0, 0);
    }
  }
  // 头部标题
  .module-title {
    display: flex;
    align-items: center;
    font-family: "Source Han Sans CN";
    .left-content {
      display: flex;
      align-items: flex-start;
      padding-right: 104px;
      width: 802px;
      .pic {
        width: 114px;
        display: flex;
        align-items: center;
        height: 114px;
        text-align: center;
        img {
          height: 114px;
        }
      }
      .left-text {
        padding-left: 38px;
        .title {
          display: flex;
          align-items: center;
          line-height: 48px;
          font-style: normal;
          font-weight: 500;
          font-size: 32px;
          color: #121619;

          .cost-type {
            margin-left: 25px;
            padding: 2px 10px;
            border-radius: 4px;
            border-radius: 2px;
            background: #7ba9fa;
            line-height: 20px;
            line-height: 22px;
            font-size: 13px;
            font-size: 14px;
            color: #fff;
          }
        }
        p {
          margin: 8px 0 12px;
          max-height: 44px;
          line-height: 22px;
          font-size: 14px;
          color: #555d67;
        }
      }
    }
    .btn {
      display: flex;
      flex: 1;
      .right-btn {
        margin-right: 16px;
        padding: 10px 16px;
        border-radius: 4px;
        height: 18px;
        background-color: #337eff;
        transition: all 0.2ms ease;
        span {
          line-height: 18px;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          color: #fff;
        }
      }
      .right-btn:hover {
        transform: scale(1.1);
      }
      .query {
        border: 1px solid #cacaca;
        border-radius: 4px;
        background: #fff;
        span {
          font-weight: 600;
          color: #121619;
        }
      }
    }
  }
  // 菜单栏
  .menu-nav {
    width: 100%;
    font-family: "Source Han Sans CN";

    .menu {
      display: flex;
      padding-top: 33px;
      width: 63%;
      height: 60px;
      line-height: 60px;
      text-align: center;
      font-size: 18px;
      .min-menu {
        display: flex;
        text-align: left;
        .menu-box {
          display: inline-block;
          position: relative;
          margin-right: 56px;
          line-height: 43px;
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          color: #121619;
        }
        .menu-box:hover {
          color: #337eff;
        }
        .activeMenu:after {
          position: absolute;
          left: 50%;
          top: 78%;
          width: 43px;
          height: 4px;
          background-color: #337eff;
          content: "";
          transform: translate(-50%, -50%);
        }
      }
    }
  }
  .content {
    padding-top: 30px;
  }
  .install-box {
    height: 400px;
    background: url(https://static.bimcc.com/openim/166547535420953C16D9B364974BCEE9F397F03DF6339.png)
      no-repeat;
    background-position: center 0;
    background-size: cover;
    font-family: "Source Sans Pro";

    -webkit-background-size: cover;
    -o-background-size: cover;
    -moz-background-size: cover;

    .content {
      text-align: center;
      transform: translateY(192px);
      .install-num {
        padding-bottom: 50px;
        line-height: 30px;
        font-style: normal;
        font-weight: 600;
        font-size: 26px;
        color: #131314;
      }
      .text {
        padding-bottom: 20px;
        font-size: 30px;
        color: #f4f4f4;
      }
      .btn {
        border: 1px solid #d9d9d9;
        border-radius: 12px;
        width: 190px;
        height: 54px;
        background: #f4f4f4;
        font-size: 20px;
        color: #131314;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
  .iconfont-div{
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    .iconfont-svg {
    width: 75px;
    height: 75px;
    background-color: transparent;
  }
  }

  .el-button {
    font-size: 16px;
  }
}
</style>
