<!--
 * @Author: 朱廷果 1028509503@qq.com
 * @Date: 2022-12-28 11:51:31
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2022-12-30 15:57:41
 * @FilePath: \dataview-next\src\manage-views\views\home\components\templatePage\department.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="wrapper">
    <el-dialog width="640px" :visible.sync="show" :close-on-click-modal=false>
      <div class="department-dialog">
        <div class="dialog-left">
          <el-input
            class="tree-input"
            placeholder="搜索"
            prefix-icon="el-icon-search"
            v-model="filterText">
          </el-input>
          <div class="tree-title">架构</div>
          <el-tree
            :check-strictly=true
            ref="treeRef"
            :data="treeData"
            default-expand-all
            :filter-node-method="filterNode"
            node-key="id"
            :props="{
              label: 'name',
              children: 'children',
            }"
            show-checkbox
            @check="getList"
          >
            <template #default="{ node, data }">
              <div class="archi-label">
                <span class="archi-label-svg">
                  <svg aria-hidden="true" class="color-icon">
                    <use :xlink:href="getIcon(data, 'label')" />
                  </svg>
                  <span class="archi-name">{{ node ? data.name : "" }}</span>
                </span>
              </div>
            </template>
          </el-tree>
        </div>
        <div class="dialog-right">
          <div class="select-list">
            <span>已选架构({{ departmentList.length }})</span>
            <span class="clear" @click="resetChecked">清空</span>
          </div>
          <div class="list-content">
            <div class="department-list archi-label" v-for="(item,index) in departmentList" :key="index">
              <span class="archi-label-svg">
                <svg aria-hidden="true" class="color-icon">
                  <use :xlink:href="getIcon(item, 'label')" />
                </svg>
                <span class="archi-name">{{ item.name }}</span>
              </span>
              <div @click="deleteChecked(index)">
                <i class="iconfont icongis_guanbi"></i>
              </div>
            </div>
          </div>
          <div class="list-foot">
            <el-button @click="close">取 消</el-button>
            <el-button  type="primary" @click="sure">确 定</el-button>
          </div>
        </div>
      </div>
 
    </el-dialog>
  </div>
</template>

<script>
import { departmentFilter } from '@/api/saas'
import {Tree} from 'element-ui'
export default {
  components: {
    'el-tree':Tree
  },
  props: {
    show:{
      type:Boolean,
      default:false
    },
    list:{
      type:Array,
      default:()=>[]
    }
  },
  data() {
    return {
      showDialog:false,
      treeData:null,
      filterText: '',
      departmentList:[]
    };
  },
  watch: {
    filterText(val) {
      this.$refs.treeRef.filter(val);
    },
    show(val){
      this.showDialog = val;
    },
    list(val){
      if(val.length){
        this.departmentList = val
      }
    }
  },
  mounted() {
    this.getTreeData()
  },
  methods: {
    // 获取树的数据 并进行处理
    getTreeData(){
      departmentFilter().then((res) => {
        if (res.status === 200 && res.data.code === 200) {
          const data = res.data?.data
          const chooseArr = [1, 2, 3, 4, 5, 6]
          const loopTree = (arr) => {
            arr.forEach(element => {
              if (element.children && element.children.length) {
                element.children = element.children.filter((ite) => {
                  return chooseArr.indexOf(+ite.type) !== -1
                })
                loopTree(element.children)
              }
            });
          }
          if (data && data.length) {
            loopTree(data)
          }
          this.treeData = data
        }
      })
    },
    // 每个结构的icon不同
    getIcon(data) {
      const group = [1, 2]
      const company = [3, 4]
      const project = [5, 6]
      const section = [7]
      if (!data) {
        return '#iconjituan'
      }
      if (group.indexOf(+data.type) !== -1 ) {
        return '#iconjituan'
      } else if (company.indexOf(+data.type) !== -1 ) {
        return '#icongongsi'
      } else if (project.indexOf(+data.type) !== -1 ) {
        return '#iconxiangmu'
      } else if (section.indexOf(+data.type) !== -1 ) {
        return '#iconbumen'
      }
    },
    // 筛选树数据
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    getList(){
      this.departmentList = this.$refs.treeRef.getCheckedNodes()
    },
    // 重置树形数据
    resetChecked() {
      this.$refs.treeRef.setCheckedKeys([]);
      this.departmentList= []
    },
    // 删除树形选择
    deleteChecked(index){
      this.departmentList.splice(index, 1);
      this.$refs.treeRef.setCheckedNodes(this.departmentList)
    },
    close(){
      this.$emit('close')
      this.departmentList = this.list
      this.$refs.treeRef.setCheckedNodes(this.departmentList)
    },
    sure(){
      this.$emit('sure',this.departmentList)
    }
  },

};
</script>
<style lang="less" scoped>
:deep(.el-dialog__header){
  display: none;
}
:deep(.el-dialog__body){
  padding: 0;
}
:deep(.el-tree){
  background-color: transparent;
  padding: 6px 12px;
  .el-tree-node__content{
    border-radius: 4px;
    height: 32px;
  }
  .el-tree-node__content:hover{
    background: #fff;
  }
  .el-tree-node{
    // padding-left: 12px;
    .el-checkbox{
    position: absolute;
    left: 20px;
    }
  }
  .el-tree-node__content>.el-tree-node__expand-icon{
    margin-left: 20px;
    padding-right: 10px;
  }
}
.wrapper{
  .department-dialog{
    display: flex;
    .dialog-left{
      width: 320px;
      height: 480px;
      background: #F5F7FC;
      .tree-input{
        width: 288px;
        height: 32px;
        padding: 16px;
        padding-bottom: 8px;
        :deep(.el-input__inner){

          border: 1px solid #DFE3E8;
          border-radius: 4px;
        }
        :deep(.el-input__prefix){
          left: 19px;
          top: 5px;
          color: #2A2F3D;
        }
      }
      .tree-title{
        padding: 6px 0 6px 24px;
        Height:32px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #2A2F3D;
        box-sizing: border-box;
      }
    }
    .dialog-right{
      display: flex;
      flex-direction: column;
      width: 320px;
      height: 480px;
      background: #FFFFFF;
      .select-list{
        width:320px;
        height:32px;
        margin-top: 16px;
        padding: 6px 24px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        justify-content: space-between;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        color: #2A2F3D;
        .clear{
          font-weight: 400;
          color: #337EFF;
        }
      }
      .list-content{
        padding: 0 24px;
        flex: 1;
        overflow-y: auto;
        .department-list{
          height:40px;
          border-bottom: 1px solid #f2f4f7;
          box-sizing: border-box;
        }
      }
      .list-foot{
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 24px;
        :deep(.el-button){
          width:128px;
        }
        :deep(.el-button--primary){
          background: #337EFF;
        }
      }
    }
  }
      /* 架构选择样式 */
  .archi-label {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    -moz-user-select:none; /* Firefox私有属性 */
    -webkit-user-select:none; /* WebKit内核私有属性 */
    -ms-user-select:none; /* IE私有属性(IE10及以后) */
    -khtml-user-select:none; /* KHTML内核私有属性 */
    -o-user-select:none; /* Opera私有属性 */
    user-select:none; /* CSS3属性 */
    .archi-label-svg {
      width: 100%;
      display: flex;
      align-items: center;
      overflow: hidden;
      span {
        flex: 1;
        overflow: hidden;
        padding-right: 6px;
        box-sizing: border-box;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: #272829;
      }
      img {
        width: 20px;
        height: 20px;
        margin-right: 6px;
      }
      svg {
        width: 20px;
        height: 20px;
        margin-right: 6px;
      }
    }
  }
}
</style>