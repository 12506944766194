/*
 * @Author: zx
 * @Date: 2022-06-14 10:36:10
 * @LastEditTime: 2022-12-07 09:10:36
 * @Description: 
 * @LastEditors: zx
 */
// 列表组件
const listComponent = {
	type: 'listComponent',
	title: '列表组件',
	show: true,
	componentList: [
		{
			component: 'ListContainerOne',
			type: 'container',
			name: '列表渲染',
			icon: 'iconbim_liebiao',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			span: 24,
			statusConfig: {
				displayShow: true,
				isShow: true // 是否显示
			},
			propValue: '',
			style: {
				minHeight: '',
				width: '100%',
				height: '100%',
				overflowX: 'hidden',
				overflowY: 'auto'
			},
			actionConfig: {
				needAction: false,
				rowClick: {
					use: false,
					useDepend: false,
					dependLogic: [],
					type: 'rowClick',
					name: '行点击',
					color: '#409EFF',
					btnType: 'text',
					btnSize: '',
					margin: 5,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				}
			},
			// 用于直接渲染的数据
			containerData: null,
			specialStyle: {
				isCollector: false, // 是否设为收集者 flex 1
				flexDirection: 'row', // flex 主轴方向
				flexWrap: false, // 是否换行
			}
		}
	]
};
export default listComponent;