<!--
 * @Description: tabs
 * @Author: luocheng
 * @Date: 2021-10-08 17:11:12
 * @LastEditors: luocheng
 * @LastEditTime: 2022-10-09 10:57:40
-->
<template>
	<div
		class="common-tabs"
		v-loading="loading"
	>
		<van-tabs
			v-model="activeName"
			:type="statusConfig.type"
			:tab-position="statusConfig.tabPosition || 'top'"
			:class="[statusConfig.type]"
			@change="onTabClick"
		>
			<!-- 固定参数 -->
			<template v-if="tabOrigin === 'fixed'">
				<van-tab
					:title="item.label"
					:name="index"
					v-for="(item, index) in tabConfig"
					:key="index"
				>
					<template v-if="useSolt" #title>
						<span :style="{ color: activeName === index ?  activeColor : defaultColor }">
							{{ item.label }}
						</span>
					</template>
				</van-tab>
			</template>
			<!-- 字典 -->
			<template v-if="tabOrigin === 'dictionary'">
				<van-tab
					:title="item.value"
					:name="item.code"
					v-for="(item, index) in dictionaryList"
					:key="index"
				>
					<template v-if="useSolt" #title>
						<span :style="{ color: activeName === item.code ?  activeColor : defaultColor }">
							{{ item.value }}
						</span>
					</template>
				</van-tab>
			</template>
			<!-- 动态数据 -->
			<template v-if="tabOrigin === 'dynamic'">
				<van-tab
					:title="item[dynamicConfig.labelKey]"
					:name="item[dynamicConfig.valueKey].toString()"
					v-for="(item, index) in dynamicTabs"
					:key="index"
				>
					<template v-if="useSolt" #title>
						<span :style="{ color: activeName === item[dynamicConfig.valueKey].toString() ?  activeColor : defaultColor }">
							{{ item[dynamicConfig.labelKey] }}
						</span>
					</template>
				</van-tab>
			</template>
		</van-tabs>
	</div>
</template>

<script>
import { Tab, Tabs } from 'vant';
import 'vant/lib/index.css'
import { dataInterface } from '@/apis/data';
import eventBus from '@/plugins/eventBus';
import { mapState } from 'vuex';
import { judgingEventLogic } from '@/utils/tools';

export default {
	name: 'CommonTabs',
	props: {
		element: {
			type: Object,
			required: false,
			default: () => {}
		}
	},
	components: {
		'van-tabs': Tabs,
		'van-tab': Tab
	},
	inject: ['EDITOR_pageUUID'],
	data() {
		return {
			activeName: '',
			// 字典配置的列表
			dictionaryList: [],
			// 动态数据
			loading: false,
			dynamicTabs: [],
			// 固定参数
			fixedInit: false,
			oldDictionaryConfig: '',
			metadata: {}
		};
	},
	computed: {
		...mapState(['componentData']),
		tabConfig() {
			return this.element && this.element.tabConfig
		},
		// 组件配置
		statusConfig() {
			return this.element ? this.element.statusConfig || {} : {};
		},
		// 参数类型
		tabOrigin() {
			return (this.element && this.element.tabOrigin) || 'fixed';
		},
		// 字典配置
		dictionaryConfig() {
			return this.element && this.element.dictionaryConfig;
		},
		// 动态数据
		dynamicConfig() {
			return this.element && this.element.dynamicConfig;
		},
		useSolt() {
			return this.element && this.element.statusConfig.useSolt;
		},
		defaultColor() {
			return this.element && this.element.statusConfig.defaultColor;
		},
		activeColor() {
			return this.element && this.element.statusConfig.activeColor;
		}
	},
	watch: {
		// 固定参数
		tabConfig: {
			handler() {
				if ( this.tabOrigin !== 'fixed' || !this.tabConfig || !this.tabConfig.length) {
					return;
				}
				for (let i = 0; i < this.tabConfig.length; i++) {
					if (this.tabConfig[i].isFirst) {
						// test 此处代码仍有待考证
						if(!this.activeName) {
							this.activeName = i;
						}
						if (!this.fixedInit) {
							this.fixedInit = true;
							this.onTabClick(this.activeName);
						}
						return;
					}
				}
				if (!this.activeName) {
					this.activeName = 0
					if (this.activeName) {
						this.onTabClick(this.activeName);
					}
				}
			},
			deep: true,
			immediate: true
		},
		// 字典
		dictionaryConfig: {
			handler() {
				if (this.tabOrigin !== 'dictionary' || !this.dictionaryConfig || this.oldDictionaryConfig === this.dictionaryConfig) return;
				this.oldDictionaryConfig = this.dictionaryConfig;
				this.getDictionaryList();
			},
			deep: true,
			immediate: true
		},
		// tab数据来源
		tabOrigin: {
			handler(newv, oldv) {
				// 动态数据
				if (newv !== oldv && this.tabOrigin === 'dynamic') {
					this.getTabList(this.dynamicConfig);
				}
			},
			deep: true,
			immediate: true
		}
	},
	created() {},
	methods: {
		// tab 被点击时触发回调
		onTabClick(tab) {
			if (this.tabOrigin === 'fixed') {
				// 固定
				const index = tab
				const comEvents = this.tabConfig[index]?.eventList || [];
				for (let i = 0; i <comEvents.length; i++) {
					const { pattern, eventList = [], specialEventList = [] } = comEvents[i];
					if (pattern === undefined) {
						console.log('老版本----', comEvents[i])
						if (comEvents[i].key === 'click') {
							comEvents[i].effects.forEach((effect) => {
								let logicBoolean = true
								if (effect.logics.length) {
									logicBoolean = judgingEventLogic(effect.logics, this.componentData || [], this.tabConfig[index]);
								}
								if (logicBoolean) {
									this.$store.commit('triggerEvents', {
										config: {
											...comEvents[i],
											...effect
										},
										element: this.element
									});
								}
							});
						}
						break;
					}
					const result = pattern === 'special' ? specialEventList : eventList
					result.forEach((ele) => {
						if (ele.key === 'click') {
							ele.effects.forEach((effect) => {
								let logicBoolean = true
								if (effect.logics.length) {
									logicBoolean = judgingEventLogic(effect.logics, this.componentData || [], this.tabConfig[index]);
								}
								if (logicBoolean) {
									this.$store.commit('triggerEvents', {
										config: {
											...ele,
										...effect
										},
										element: this.element
									});
								}
							});
						}
					});
				}
			}
			// 暴露出绑定的数据
			let resolveData = null;
			// 暴露数据
			if (this.tabOrigin === 'fixed') {
				const index = tab
				resolveData = {
					label: this.tabConfig[index].label,
					value: this.tabConfig[index].type
				};
			} else if (this.tabOrigin === 'dictionary') {
				const index = this.dictionaryList.findIndex((ite) => ite.item.code === tab)
				resolveData = {
					label: this.dictionaryList[index].value,
					[this.dictionaryList[index].code]: this.dictionaryList[index].code
				};
			} else if (this.tabOrigin === 'dynamic') {
				const index = this.dynamicTabs.findIndex((ite) => ite[this.dynamicConfig.valueKey].toString() === tab)
				resolveData = {
					label: this.dynamicTabs?.[index]?.[this.dynamicConfig.labelKey],
					[this.dynamicConfig.valueKey]: this.dynamicTabs?.[index]?.[this.dynamicConfig.valueKey]
				};
			}
			let metadata = this.metadata && Object.keys(this.metadata).length ? this.metadata : {value: this.tabConfig[tab].label}
			this.$store.commit('modifyComponent', {
				component: {
					...this.element,
					resolveData,
					metadata,
				},
				containerId: null,
				isModify: true,
				pageUUID: this.EDITOR_pageUUID
			});
			// 触发事件
			// event bus  挂载卸载问题
				setTimeout(() => {
					eventBus.$emit('databaseTrigger', {
						componentId: this.element.id,
						action: 'any',
						isSearch: true,
						resetPage: true,
						output: resolveData
					});
				}, 0);
			this.activeName = tab
		},
		/**
		 * @desc: 获取字典配置的列表
		 */
		getDictionaryList() {
			dataInterface(
				{},
				`api/metadata/dictionary/${this.dictionaryConfig}`,
				'GET'
			)
				.then((res) => {
					if (!res) return;
					this.dictionaryList = res.data.data.item || [];
					if (this.dictionaryList && this.dictionaryList.length) {
						this.activeName = this.dictionaryList[0].code || '';
					}
					this.onTabClick(this.activeName);
				})
				.catch((err) => {
					console.log(err);
				});
		},
		/**
		 * @desc: 获取字段列表
		 */
		getTabList(config) {
			this.loading = true;
			dataInterface({
				__method_name__: 'dataList',
				object_uuid: config.objectUUID,
				view_uuid: config.viewUUID,
				search: []
			})
				.then((res) => {
					this.loading = false;
					if (!res || res.status !== 200 || !res.data || !res.data.data) return;
					const data = res.data.data;
					this.dynamicTabs = Array.isArray(data)
						? data
						: res.data.data.data || [];
					if (this.dynamicTabs && this.dynamicTabs.length) {
						this.activeName =
							(this.dynamicTabs[0][this.dynamicConfig.valueKey] &&
								this.dynamicTabs[0][this.dynamicConfig.valueKey].toString()) ||
							'';
					}
					this.metadata = res.data.metadata
					console.log(this.dynamicTabs, 'this.dynamicTabs')
					this.onTabClick(this.activeName);
				})
				.catch((err) => {
					this.loading = false;
					console.log(err);
				});
		}
	}
};
</script>

<style lang="less" scoped>
.common-tabs {
	width: 100%;
	overflow: hidden;
	display: flex;
	align-items: center;
	:deep(.van-tabs) {
		width: 100%;
		height: 100%;
		.van-tabs__wrap{
			width: 100%;
			height: 100%;
			.van-tabs__nav {
				height: 100%;
			}
		}
	}
}
</style>