/*
 * @Description: 分组
 * @Author: luocheng
 * @Date: 2021-08-11 11:30:05
 * @LastEditors: luocheng
 * @LastEditTime: 2021-08-18 16:02:02
 */

export default {
	state: {
		// 选中区域包含的组件以及区域位移信息
		areaData: {
			style: {
				top: 0,
				left: 0,
				width: 0,
				height: 0
			},
			components: []
		}
	},
	mutations: {
    /**
     * @desc: 暂存当前框选的组件列表
     */
    setAreaData(state, data) {
			state.areaData = data;
		}
	}
};
