<!--
    @name: parser_pc
    @description：parser_pc
    @author: ZengWei
    @date: 2021-09-15 09:30
-->
<!-- eslint-disable -->
<template>
	<div>
		<div v-if="skeleton" class="skeleton">
			<skeleton></skeleton>
		</div>
		<div v-else>
			<el-form
        ref="formParser"
        size="medium"
        :class="{'form-parser':tableStyle,'normal-parser': !tableStyle}"
        :model="formValidate"
        :show-message="!tableStyle"
        :label-width="formData.labelWidth+'px'"
        :label-position="formData.labelPosition"
			>
				<div v-if="formLabel" class="type-label">{{ formLabel }}</div>
				<el-row>
					<el-col
            v-for="(item, index) in formValidate.items"
            :span="item.config.__config__.span"
            :key="index"
					>
						<template v-if="item.type === 'text' && showHideField(item.config.__config__)">
							<div class="type-label">{{ item.config.__config__.label }}</div>
						</template>

						<template v-else-if="item.type === 'normalText' && showHideField(item.config.__config__)">
							<div :class="{
								'normal-label':true,
								'text-left': item.config.__config__.align === 'left',
								'text-center': item.config.__config__.align === 'center',
								'text-right': item.config.__config__.align === 'right'
							}" :style="{textIndent:item.config.__config__.labelWidth+'px'}">{{ item.config.__config__.label }}</div>
						</template>

						<template v-else-if="item.type === 'form' && showHideField(item.config.__config__)">
							<!--子表单表格展示-->
							<div
                class="table-wrapper"
                v-if="item.config.__config__.display === 'table' && showHideField(item.config.__config__)">
								<h5 class="table-title">
									{{ item.config.__config__.componentName }}
								</h5>
								<el-table ref="multipleTable" :data="item.value" border class="w-full">
									<el-table-column align="center" label="序号" width="60">
										<template slot-scope="scope">{{scope.$index + 1}}</template>
									</el-table-column>

									<el-table-column
                    v-for="(element,eleInd) in item.value[0]"
                    :key="index + '-' + eleInd"
                    v-if="showHideField(element.config.__config__)"
                    :label="element.config.__config__.label"
                    min-width="160"
									>
										<template slot-scope="{ row, $index }">
											<el-form-item
                        :key="index + '-'+ $index +'-' +eleInd"
                        :label="showLabel(row[eleInd].config.__config__)"
                        :label-width="showLabel(row[eleInd].config.__config__) === '' ? '0px' : '120px'"
                        :prop="'items.' + index + '.value.' + $index + '.'+ eleInd + '.value'"
                        :rules="row[eleInd].validate"
											>
												<inputItem
                          :key="index + '-'+ eleInd +'-' +$index"
                          :node-element="JSON.parse(JSON.stringify(row[eleInd]))"
                          :disabled="canEditField(row[eleInd].field)"
                          :firstIndex="index"
                          :secondIndex="$index"
                          :threeIndex="eleInd"
                          @change-value="changeEleValue"
                          @trigger-active="triggerActive(arguments,item.config.__config__.formId,$index)"
												>
												</inputItem>
											</el-form-item>
										</template>
									</el-table-column>
									<el-table-column v-if="!disabled && item.config.__config__.cycle" label="操作" width="50">
										<template slot-scope="scope">
											<i
                        v-if="scope.$index === 0"
                        class="el-icon-circle-plus-outline add"
                        @click="addChildForm(scope.row,item.value)"
											>
											</i>
											<i
                        v-else
                        class="el-icon-remove-outline remove"
                        @click="item.value.splice(scope.$index,1)"
											>
											</i>
										</template>
									</el-table-column>
								</el-table>
							</div>

							<div
                class="table-relate"
                v-else-if="item.config.__config__.display === 'table-relate' && showHideField(item.config.__config__)">
								<el-form-item
                  :label="item.config.__config__.componentName.length > 6
                  ? item.config.__config__.componentName.substr(0, 6) + '...'
                  : item.config.__config__.componentName"
										class="child-form no-padding table-wrapper"
								>
									<el-table ref="multipleTable" :data="item.value" border class="w-full">
										<el-table-column align="center" width="65">
											<template slot="header" slot-scope="scope">
												<el-button
                          v-if="!disabled"
                          type="primary" size="mini" icon="el-icon-notebook-2"
                          @click="openSelectRelate(item,index)">
												</el-button>
											</template>
											<template slot-scope="scope">{{scope.$index + 1}}</template>
										</el-table-column>
										<el-table-column
                      v-for="(itemR,indexR) in item.config.__config__.relateConfig.__slot__.options"
                      :key="index + '-' + indexR"
                      :label="itemR.label"
                      :prop="itemR.value"
                      :min-width="itemR.width || 130"
										>
											<template slot-scope="{ row, $index }">
												<!--<i v-if="indexR === 0" class="el-icon-connection" style="cursor: pointer"></i>&nbsp;&nbsp;-->
												<template v-if="row[indexR].type">
													<el-form-item
                            v-if="showHideField(row[indexR].config.__config__)"
                            :key="index + '-'+ $index +'-' +indexR"
                            :prop="'items.' + index + '.value.' + $index+ '.'+ indexR + '.value'"
                            :rules="row[indexR].validate"
													>
														<inputItem
                              :key="index + '-'+ indexR +'-' +$index"
                              :node-element="row[indexR]"
                              :disabled="canEditField(row[indexR].field)"
                              :firstIndex="index"
                              :secondIndex="$index"
                              :threeIndex="indexR"
                              @change-value="changeEleValue"
                              @open-form="openOuterForm"
                              @trigger-active="triggerActive(arguments,item.config.__config__.formId,$index)"
														>
														</inputItem>
													</el-form-item>
												</template>
												<span v-else>{{row[indexR].text}}</span>
											</template>
										</el-table-column>
										<el-table-column
											v-if="!disabled"
											align="center" label="操作"
											:width="item.config.__config__.relateConfig.__config__.detailShow ? 130 : 60">
											<template slot-scope="scope">
												<div style="display: flex;justify-content: space-around">
													<div v-if="item.config.__config__.relateConfig.__config__.detailShow">
														<el-button type="text" size="mini" icon="el-icon-view">详情</el-button>
													</div>
													<div>
														<i class="el-icon-remove-outline remove"
															 @click="delRelateItem(item.value,scope.$index)">
														</i>
													</div>
												</div>
											</template>
										</el-table-column>
									</el-table>
								</el-form-item>
							</div>

							<el-form-item
                v-else-if="showHideField(item.config.__config__)"
                :label="item.config.__config__.componentName.length > 6
									? item.config.__config__.componentName.substr(0, 6) + '...'
									: item.config.__config__.componentName"
                class="child-form no-padding table-wrapper"
							>
								<el-row
                  v-for="(dd, nn) in item.value"
                  :key="nn"
                  :type="item.config.type === 'flex' ? item.config.type : 'flex'"
                  :align="item.config.align ? item.config.align : 'middle'"
                  :justify="item.config.justify ? item.config.justify : 'start'"
                  style="position: relative"
                  :class="{ 'cycle-pr': cycleBool(item.config.__config__) }"
								>
									<el-col
                    v-for="(element, ii) in dd"
                    :key="ii"
                    v-if="showHideField(element.config.__config__)"
                    :span="element.config.__config__.span"
									>
										<el-form-item
                      :prop="'items.' + index + '.value.' + nn + '.' + ii + '.value'"
                      :rules="element.validate"
                      :label="showLabel(element.config.__config__)"
										>
											<inputItem
                        :nodeElement="element"
                        :disabled="canEditField(element.field)"
                        :first-index="index"
                        :second-index="nn"
                        :three-index="ii"
                        :key="index + '-' + '-' + nn + '-' + ii"
                        @trigger-active="triggerActive(arguments,item.config.__config__.formId,nn)"
											>
											</inputItem>
										</el-form-item>
									</el-col>
									<div
                    class="type-action-btn"
                    v-if="cycleBool(item.config.__config__) && !disabled"
									>
										<i
                      v-if="nn === 0"
                      class="el-icon-circle-plus-outline add"
                      @click="addChildForm(dd, item.value)"
										></i>
										<i
                      v-else
                      class="el-icon-remove-outline remove"
                      @click="delChildForm(nn, item.value)"
										></i>
									</div>
								</el-row>
							</el-form-item>
						</template>

						<template v-else-if="item.type === 'row' && showHideField(item.config.__config__)">
							<div class="type-label">
								{{ item.config.__config__.componentName }}
							</div>
							<el-row
                :gutter="10"
                :type="item.config.type === 'flex' ? item.config.type : ''"
                :align="item.config.align ? item.config.align : 'middle'"
                :justify="item.config.justify ? item.config.justify : 'start'"
                style="position: relative"
							>
								<el-col
                  v-for="(element, ii) in item.value"
                  :key="ii"
                  :span="element.config.__config__.span"
								>
									<el-form-item
                    :prop="'items.' + index + '.value.' + ii + '.value'"
                    :rules="element.validate"
                    :label="showLabel(element.config.__config__)"
                    :label-width="element.config.__config__.labelWidth ? element.config.__config__.labelWidth+ 'px' :120 + 'px'"
                    class="no-padding"
									>
										<inputItem
                      :nodeElement="element"
                      :disabled="canEditField(item.field)"
                      :first-index="index"
                      :second-index="ii"
                      :key="index + '-' + ii"
										>
										</inputItem>
									</el-form-item>
								</el-col>
							</el-row>
						</template>

            <template v-else-if="item.type === 'steps' && showHideField(item.config.__config__)">
              {{ initTrigger(item) }}
              <div style="display: flex;align-items:center;margin-bottom: 20px;background-color: #f5f7fa;">
                <div style="flex: 1">
                  <el-steps :active="item.config.__config__.activeName" simple>
                    <el-step
                      v-for="(step,stepInd) in item.config.__steps__"
                      :key="stepInd"
                    >
                      <template slot="title">
                        <span style="cursor: pointer;" @click="toggleStep(item,stepInd)">{{step.label}}</span>
                      </template>
                      <!--<template slot="icon"><i :class="'iconfont '+step.icon"></i></template>-->
                    </el-step>
                  </el-steps>
                </div>
                <div style="width: 90px;">
                  <el-button type="primary" @click="nextStep(item)">下一步</el-button>
                </div>
              </div>
            </template>

						<template v-else-if="item.type === 'tab' && showHideField(item.config.__config__)">
              <el-tabs
                v-model="item.config.__config__.activeName"
                :type="item.config.__config__.type"
                :tab-position="item.config.__config__.tabPosition"
              >
                <el-tab-pane
                  v-for="(tab,tabInd) in item.config.__tabs__"
                  :key="tabInd"
                  :label="tab.label"
                  :name="tab.name">
                  <el-row>
                    <!-- eslint-disable -->
                    <el-col
                      v-for="(element, ii) in item.value[tabInd]"
                      :key="ii"
                      v-if="showHideField(element.config.__config__)"
                      :span="element.config.__config__.span"
                    >
                      <el-form-item
                        :prop="'items.' + index + '.value.' + tabInd + '.' + ii + '.value'"
                        :rules="element.validate"
                        :label="showLabel(element.config.__config__)"
                      >
                        <inputItem
                          :nodeElement="element"
                          :disabled="canEditField(element.field)"
                          :first-index="index"
                          :second-index="tabInd"
                          :three-index="ii"
                          :key="index + '-' + '-' + tabInd + '-' + ii"
                          @trigger-active="triggerActive(arguments,item.config.__config__.formId,tabInd)"
                        >
                        </inputItem>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-tab-pane>
              </el-tabs>
            </template>

						<template v-else-if="showHideField(item.config.__config__)">
							<el-form-item
                class="no-padding"
                :prop="'items.' + index + '.value'"
                :label="item.config.__config__.label"
                :rules="item.validate"
                :label-width="
                item.config.__config__.labelWidth
                  ? item.config.__config__.labelWidth + 'px'
                  : 120 + 'px'"
							>
								<inputItem
                  :nodeElement="item"
                  :disabled="canEditField(item.field)"
                  :key="index"
                  :firstIndex="index"
                  @open-form="openOuterForm"
                  @trigger-active="triggerActive(arguments)"
                  @auto-fill-relate="selectAutoFillRelate"
								>
								</inputItem>
							</el-form-item>
						</template>

            <!--兼容隐藏控件的交互事件-->
            <template v-if="['radio','switch','select'].indexOf(item.type) >-1">
              {{ initTrigger(item) }}
            </template>
					</el-col>
				</el-row>

				<div class="parser-tp">
					<slot name="other"></slot>
				</div>

				<div class="parser-tp">
					<!--<slot name="flow"></slot>-->
					<!--<template v-if="needFlow">
						<template v-if="formMode === 'add'">
							&lt;!&ndash;添加界面流程&ndash;&gt;
							<FlowPicker
								:module="moduleUuid"
								type="self"
								@checked-users="dealWithPicker"
								@selected-step="getSelectStep"
								@input-show="formItemShow"
							/>
						</template>
						<template v-else-if="formMode === 'detail'">
							&lt;!&ndash;详情界面流程&ndash;&gt;
							<FlowRecover
								v-if="Object.keys(displayData).length"
								:canvas-data="displayData.recover"
								:logs="displayData.logs"
							/>
							<FlowOperate
								v-if="Object.keys(displayData).length"
								:edit-data="displayData"
								@change-end="onRefresh"
								@input-show="formItemShow"
							/>
						</template>
					</template>-->
				</div>

				<div class="parser-tp">
					<slot name="button"></slot>
				</div>

				<div v-if="!hideBtn" style="text-align: right;margin-top: 15px;">
					<el-button type="default" @click="handleReset" style="margin-right: 10px;">取消</el-button>
					<el-button type="primary" @click="handleSubmit">提交</el-button>
				</div>
			</el-form>

			<el-dialog
        v-if="outerFormShowType === 1"
        :title="outerFormTitle"
        :custom-class="'common-dialog'"
        :visible.sync="outerFormVisible"
        width="80%"
        append-to-body
			>
        <article class="dialog-main">
          <parser_pc
            v-if="outerFormData && outerFormVisible"
            :hide-btn="outerFormMode === 'detail'?true:false"
            :form-data="outerFormData"
            :form-mode="outerFormMode"
            :need-flow="outerNeedFlow"
            :module-uuid="outerFormUuid"
            :display-data="outerFormDisplay"
            :disabled="outerFormMode === 'detail'?true:false"
            @submit="outerFormSubmit"
          ></parser_pc>
        </article>
			</el-dialog>

			<el-drawer
        v-if="outerFormShowType === 2"
        :title="outerFormTitle"
        :visible.sync="outerFormVisible"
        direction="rtl"
        size="60%"
			>
				<div style="margin:0 15px;max-height: 85vh;overflow-y: auto">
					<parser_pc
						v-if="outerFormData && outerFormVisible"
						:hide-btn="outerFormMode === 'detail'?true:false"
						:form-data="outerFormData"
            :form-mode="outerFormMode"
            :need-flow="outerNeedFlow"
            :module-uuid="outerFormUuid"
            :display-data="outerFormDisplay"
            :disabled="outerFormMode === 'detail'?true:false"
						@submit="outerFormSubmit"
					></parser_pc>
				</div>
			</el-drawer>

			<el-dialog
        title="选择关联数据"
        :visible.sync="relateVisible"
        width="80%"
        custom-class="vertical-center"
        append-to-body
			>
				<div style="height: 80vh" v-if="relateConfig">
					<relateData
            :filter-rule="relateFilterRule"
            :relate-data="relateData"
            :relate-config="relateConfig"
            @select-relate="selectRelateResult"
					>
					</relateData>
				</div>
				<div slot="footer" class="dialog-footer">
					<el-button @click="relateVisible = false">取 消</el-button>
					<el-button type="primary" @click="relateVisible = false">确 定</el-button>
				</div>
			</el-dialog>
		</div>
	</div>
</template>

<script>
/* eslint-disable */
import inputItem from "./input_item";
import { uniqid } from "@/libs/util";
import relateData from "./relateData";
import parserMethods from "./scripts/parserMethods";
import skeleton from "./skeleton";
// import FlowPicker from "./flowEngine/components/FlowPicker";
// import FlowRecover from "./flowEngine/components/FlowRecover";
// import FlowOperate from "./flowEngine/components/FlowOperate";
import { calcIF, calcMULTIPLY, calcSUM } from "@/libs/calcRuleEngine";
import {getNowTime} from "@/libs/util";

export default {
  name: "parser_pc",
  components: {
  	skeleton, inputItem, relateData,
	  // FlowPicker,FlowRecover,FlowOperate
	},
  props: {
		// 显示验证错误信息
    showMessage: {
      type: Boolean,
      default: false,
    },
		// 隐藏默认按钮
    hideBtn: {
      type: Boolean,
      default: true,
    },
		// 表单禁用
    disabled: {
      type: Boolean,
      default: false,
    },
		// 表单设计数据
    formData: {
      type: Object,
    },
		// 表单回显数据
    displayData: {
      type: Object,
      default: () => {
        return {};
      },
    },
		// 控制台模型回显
    consoleModel: {
      type: Object,
      default: () => {
        return {};
      },
    },
		// 表单模式-新增/编辑
		formMode: {
			type: String,
			default: 'add',
		},
		// 表单填充数据
		fillData: {
			type: Object,
			default: () => {
				return {};
			},
		},
	  // 是否开启流程
	  needFlow: {
		  type: Boolean,
		  default: false,
	  },
	  // 表单唯一标识--用于流程
	  moduleUuid: {
		  type: String,
		  default: '',
	  },
  },
  data() {
    return {
      editFields:[],	// 流程可编辑字段
	    currentInfo:{},
	    skeleton: true,
      key: uniqid(),
      formValidate: {
        items: [],
      },
      tdElement: null,
      validator: false,
      formSubmitData: null,
	    relateData:[],
	    relateItemInd:'',
	    relateVisible: false,
	    relateConfig: null,
	    relateObjectUUid:null,
	    relateViewUUid:null,
			relateFilterRule:[],
	    formLabel: '',
			tableStyle: false,
	    // 流程数据
	    selectedUsers: [],
	    checkPickerNum: 0,
	    selectStep: '',
	    flowEngineId:0,
			// 外部表单
      outerFormIndex:null,
			outerFormData:null,
			outerNeedFlow: false,
			outerFormUuid: '',
			outerObjectUuid: '',
			outerFormMode: 'add',
			outerFormDisplay: {},
	    outerFormType: 2,
	    outerFormShowType: 2,
	    outerFormVisible:false,
	    outerFormTitle: '外部表单',
    };
  },
  computed: {
    initTrigger(){
      return function (element) {
        let value = element.value;
        if(element.type === 'steps') {
          value = element.config.__config__.activeName;
        }
        this.triggerEventCtrl(element,value)
      };
    },
    cycleBool() {
      return function (config) {
        if (this.disabled) return false;
        if (config.cycle === undefined || config.cycle === true) return true;
        return config.cycle;
      };
    },
    showLabel() {
      return function (config) {
        if (config.showLabel === undefined || config.showLabel === false)
          return "";
        return config.label;
      };
    },
    dateRangeType() {
      return function (type) {
        if (type && type === "datetimerange") {
          return "datetimerange";
        }
        return "daterange";
      };
    },
    cadSelected() {
      return function (selected) {
        let cadData = [];
        for (let item of selected) {
          cadData.push(JSON.parse(item));
        }
        return cadData;
      };
    },
    tableWith() {
      return function (tableData) {
        let widthArr = Array.from(tableData[0].children, (item) =>
          item.width ? item.width : 80
        );
        return eval(widthArr.join("+"));
      };
    },
    canEditField() {
      return function (field) {
        if (this.editFields.length > 0 && this.editFields.indexOf(field) > -1)
          return false;
        return this.disabled;
      };
    },
		showHideField() {
      return function (config) {
        const formId = config.formId;
        if(this.editFields.includes(formId)) return true;
        return config.addShow;
      };
		}
  },
  watch: {
    consoleModel: {
      handler() {
        this.getFormValidate(this.formData.fields);
      },
      deep: true,
    },
	  formValidate: {
		  handler() {
			  this.carryCalculate();
		  },
		  deep: true,
		}
  },
  created() {
    window.calcField = this.getFieldValue.bind(this)
	  window.calcIF = calcIF
	  window.calcMULTIPLY = calcMULTIPLY
	  window.calcSUM = calcSUM

	  //附加当前填充数据 -- 当前用户，当前时间，当前项目
	  /*const userInfo = this.$GetUserInfo()
	  const archiInfo = this.$GetTargetArchi()
	  const nowData = getNowTime(1);
	  const nowDataTime = getNowTime(2);
	  const nowTime = getNowTime(3);
	  this.currentInfo = {
		  user: {
			  id: userInfo.id,
			  name: userInfo.name,
			  user_table: 'sys_users',
			  section_id: archiInfo.sectionId
		  },
		  archi_id: archiInfo.archi_id,
		  archi_name: archiInfo.name,
		  company_id: archiInfo.company_id,
		  archi_type: archiInfo.archiType,
		  section_id: archiInfo.sectionId,
		  section_name: archiInfo.sectionName,
		  now_date: nowData,
		  now_datetime: nowDataTime,
		  now_time: nowTime,
	  }*/

    this.getFormValidate(this.formData.fields);
    if(this.moduleUuid && this.needFlow && this.formMode === 'add'){
    	this.flowDesign({module:this.moduleUuid});
		}
    if(this.formData.baseLabel){
			this.formLabel = '基本信息';
		}
    if(this.formData.tableStyle){
	    this.tableStyle = this.formData.tableStyle
		}
  },
	methods: parserMethods,
};
</script>

<style>
	.table-wrapper .el-form-item,.table-relate .el-form-item {
		margin-bottom: 0 !important;
	}
	.el-form-item.is-error .el-input__inner,
	.el-form-item.is-error .el-input__inner:focus,
	.el-form-item.is-error .el-textarea__inner,
	.el-form-item.is-error .el-textarea__inner:focus {
		border-color: #F56C6C !important;
	}
	.el-dialog__wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: auto;
		max-height: 100%;
	}

	.vertical-center.el-dialog {
		margin: 0 auto !important;
	}

	.vertical-center .el-dialog__body{
		padding: 15px 20px !important;
	}

	.el-dialog__body::-webkit-scrollbar,
	.el-dialog__wrapper::-webkit-scrollbar,
	.el-table__body-wrapper::-webkit-scrollbar
	{
		width: 6px;
		height: 8px;
		margin: 0 3px;
	}

	.el-dialog::-webkit-scrollbar-track,
	.el-dialog__body::-webkit-scrollbar-track,
	.el-table__body-wrapper::-webkit-scrollbar-track
	{
		background-color: #ffffff;
	}

	.el-dialog::-webkit-scrollbar-thumb,
	.el-dialog__body::-webkit-scrollbar-thumb,
	.el-table__body-wrapper::-webkit-scrollbar-thumb
	{
		background-color: rgba(189, 192, 199, 1);
		border-radius: 4px;
	}

	.el-dialog::-webkit-scrollbar-thumb:hover,
	.el-dialog__body::-webkit-scrollbar-thumb:hover,
	.el-table__body-wrapper::-webkit-scrollbar-thumb:hover
	{
		background-color: rgba(189, 192, 199, 1);
	}

	.el-dialog::-webkit-scrollbar-thumb:active,
	.el-dialog__body::-webkit-scrollbar-thumb:active,
	.el-table__body-wrapper::-webkit-scrollbar-thumb:active
	{
		background-color: rgba(189, 192, 199, 1);
	}

	.el-dialog::-webkit-scrollbar-corner,
	.el-dialog__body::-webkit-scrollbar-corner,
	.el-table__body-wrapper::-webkit-scrollbar-corner
	{
		background-color: #ffffff;
	}

</style>

<style lang="less" scoped>
	// @import "@/main.scss";
.type-label::before{
  content:' ';
    font-size: 15px;
    border-left: 3px solid #007FE0 !important;
    margin-right:5px
  }
.type-label {

  padding: 15px 10px;
  padding-left: 0px;
  line-height: 20px;
  font-size: 14px;
  // margin: 15px 0 !important;
  font-weight: 600;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 202;
}
	:deep(.form-parser) {
		.table-wrapper,.table-relate {
			/*.el-form-item__content {
				.el-form-item {
					border: none;
				}
			}*/
			.el-table {
				.el-form-item {
					border: none;
				}
			}
		}
		.el-form-item {
			border: 1px solid #eaebed;
			display: flex;
			margin-bottom: -1px !important;
			/*margin-bottom: -1px !important;*/

			.el-form-item__label {
				border-right: 1px solid #eaebed;
				background: #f8f8f9;
				padding: 6px 10px;
			}

			.el-form-item__content {
				margin-left: 0!important;
				flex: 1;
				padding: 6px 10px;
				overflow: hidden;
				clear: both;
				margin-bottom: 1px;

				.el-select,.el-cascader  {
					width: 100%;
				}

				.el-input.is-disabled .el-input__inner{
					background-color: #ffffff;
				}

        .el-input-group__prepend,.el-input-group__append{
          border: none;
        }

				.el-input__inner, textarea{
					border: 1px solid transparent;
				}
			}
		}
	}

	:deep(.normal-parser) {
		.el-form-item__label {
			padding-left: 10px;
		}
    .el-form-item__content {
      margin-left: 0!important;
    }

		.el-select,.el-cascader  {
			width: 100%;
		}

		.table-wrapper, .table-relate {
			margin-bottom: 18px;
		}
	}
  :deep(.common-dialog) {
    max-height: calc(100vh - 50px);
    overflow-y: auto;
    .type-label {
      border-left: 3px solid #007fe0 !important;
      padding: 0 10px;
      line-height: 1;
      font-size: 14px;
      margin: 0 0 16px;
      font-weight: 600;
    }
    .dialog-main {
      overflow-x: auto;
    }
    .el-dialog__body{
      max-height: calc(100vh - 230px);
      min-height: 300px;
      overflow: hidden;
      overflow-y: auto;
    }
  }
</style>

<style scoped>
	.normal-label{
		font-size: 14px;
		color: #606266;
		line-height: 40px;
	}
	.text-right {
		text-align: right;
	}
	.text-left {
		text-align: left;
	}
	.text-center {
		text-align: center;
	}
.child-form {
  margin-bottom: 10px;
}
.child-form:last-child {
  margin-bottom: 0;
}
.filled {
  width: 100%;
}
.type-action-btn {
  position: absolute;
  right: 5px;
  top: 12px;
}
.add {
  color: #0076ff;
  cursor: pointer;
  font-size: 23px;
}
.remove {
  color: #e83525;
  cursor: pointer;
  font-size: 23px;
}
.parser-tp {
  margin-top: 20px;
}
.table-title {
  font-size: 14px;
  line-height: 44px;
  text-align: center;
  background: #f8f8f9;
  border: 1px solid #eaebed;
	border-bottom: none;
}

.cycle-pr {
  padding-right: 40px;
}

table.gridtable {
  font-family: verdana, arial, sans-serif;
	width: 100%;
  font-size: 11px;
  color: #333333;
  border-width: 1px;
  border-color: #eaebed;
  border-collapse: collapse;
}

table.gridtable th {
  border-width: 1px;
  padding: 13px;
  border-style: solid;
  border-color: #eaebed;
  background-color: #f8f8f9;
}

table.gridtable td {
  border-width: 1px;
  padding: 0 1px 2px 0;
  border-style: solid;
  border-color: #eaebed;
  background-color: #ffffff;
}

.viewer-table-box {
  transition: all 0.3s;
  overflow: auto;
}
.viewer-table-box::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  margin: 0 3px;
}
.viewer-table-box::-webkit-scrollbar-track {
  background-color: #ffffff;
}
.viewer-table-box::-webkit-scrollbar-thumb {
  background-color: rgba(189, 192, 199, 1);
  border-radius: 4px;
}
.viewer-table-box::-webkit-scrollbar-thumb:hover {
  background-color: rgba(189, 192, 199, 1);
}
.viewer-table-box::-webkit-scrollbar-thumb:active {
  background-color: rgba(189, 192, 199, 1);
}
.viewer-table-box::-webkit-scrollbar-corner {
  background-color: #ffffff;
}
.viewer-select-wrapper {
  position: relative;
  display: flex;
}

table.viewer-gridtable {
  font-family: verdana, arial, sans-serif;
  font-size: 11px;
  color: #333333;
  border-width: 1px;
  border-color: #eaebed;
  border-collapse: collapse;
  table-layout: fixed;
}

table.viewer-gridtable th {
  padding: 2px 0;
  border-right: 1px solid #ffffff;
  background-color: #ffffff;
  position: relative;
}

table.viewer-gridtable td {
  border: 1px solid #eaebed;
  background-color: #ffffff;
  white-space: nowrap;
  user-select: none;
  overflow: hidden;
}

table.viewer-gridtable td .td-input {
  width: 100%;
  height: 100%;
  border: none;
  padding: 0;
  cursor: default;
}
table.viewer-gridtable td .td-input:focus {
  border: none;
  outline: none;
}
</style>
