/*
 * @Author: zqy
 * @Date: 2021-12-07 15:28:09
 * @LastEditTime: 2022-10-24 15:47:16
 * @Description: 流程设计接口
 */
import { axios } from '../http/request';

export function getValue(data){
  data.transcode = 0
  return axios.request({
    url: '/api/mapi',
    method: 'POST',
    data,
  })
}

export const flowEngine = {
  publishDemo: (data) => {
    return axios.request({
      url: '/api/flow4/flowengine/formdata',
      method: 'post',
      data: data,
    })
  },
  designList: (data={}) => {
    return axios.request({
      url: '/api/flow4/flow/engine',
      method: 'get',
      params: data,
    })
  },
  designSave: (id, data) => {
    return axios.request({
      url: '/api/flow4/flow/engine/' + id,
      method: 'put',
      data: data,
    })
  },
  designShow: (params) => {
    return axios.request({
      url: '/api/flow4/flowengine/moduleflow',
      method: 'get',
      params: params,
    })
  },
  designEdit: () => {},
  designDel: () => {},
  templateList: () => {},
  templateSave: () => {},
  templateDel: () => {},
  selfPicker: (params) => {
    return axios.request({
      url: '/api/flow4/flowengine/selfpicker',
      method: 'post',
			data: params,
    })
  },
  nextPicker: (params) => {
    return axios.request({
      url: '/api/flow4/flowengine/nextpicker',
      method: 'post',
      data: params,
    })
  },
  getNextPicker(params){
    return axios.request({
      url: '/api/flow4/flowengine/getNextPicker',
      method: 'post',
      data: params,
    })
  },
  flowDataDetail: (id, params) => {
    return axios.request({
      url: '/api/flow4/flowengine/formdata/' + id,
      method: 'get',
      params: params,
    })
  },
  flowOperate: (id, data) => {
    return axios.request({
      url: '/api/flow4/flowengine/operationFlow/' + id,
      method: 'POST',
      data: data,
    })
  },
}


export const formDesign = {
	formList(data){
		return axios.request({
			url: '/api/form4/design',
			method: 'get',
			params: data
		})
	},
	formStore(data){
		return axios.request({
			url: '/api/form4/design',
			method: 'post',
			data: data
		})
	},
	formShow(id){
		return axios.request({
			url: '/api/form4/design/'+id,
			method: 'get',
		})
	},
	formUpdate(id,data){
		return axios.request({
			url: '/api/form4/design/'+id,
			method: 'put',
			data: data
		})
	},
	formDel(id){
		return axios.request({
			url: '/api/form4/design/'+id,
			method: 'delete'
		})
	},
	formValidate(params){
		return axios.request({
			url: '/api/form4/formValidate',
			method: 'get',
			params: params
		})
	},
	formDataList(params){
		return axios.request({
			url: '/api/form4/defaultlist',
			method: 'get',
			params: params
		})
	},
	formDataStore(data){
		return axios.request({
			url: '/api/form4/data',
			method: 'post',
			data:data
		})
	},
	formDataDetail(id){
		return axios.request({
			url: '/api/form4/data/'+id,
			method: 'get',
		})
	},
	formDataUpdate(data,id){
		return axios.request({
			url: '/api/form4/data/'+id,
			method: 'put',
			data:data
		})
	},
	formDataExport(id){
		return axios.request({
			url: '/api/form4/exportword/'+id,
			method: 'get',
		})
	},
	formDataDel(id){
		return axios.request({
			url: '/api/form4/data/'+id,
			method: 'delete',
		})
	},
  formDataDetailWithMonitor(params){
		return axios.request({
			url: 'api/form4/formDataDetail',
			method: 'get',
      params: params
		})
	},
  formDataListWithMonitor(params){
		return axios.request({
			url: 'api/form4/formDataList',
			method: 'get',
      params: params
		})
	},
}
