<!--
 * @LastEditors: ttheitao
 * @Description: 文本高亮关键字组件
 * @FilePath: /dwh_front/src/components/HightlightText.vue
 * @Date: 2022-06-01 09:32:17
 * @LastEditTime: 2022-08-04 14:48:27
 * @Author: lisushuang
-->
<template>
  <span><span v-for="(item, index) in textArr" :key="index"><span v-if="index" :style="{ color: color,fontWeight:isBold?'bold':'500' }">{{ keyword }}</span>{{ item }}</span></span>
</template>

<script>
export default {
  name: "HightlightText",
  props: {
    text: String,
    keyword: String,
    color: {
      type: String,
      default: "red",
    },
    isBold: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    textArr() {
      if(!this.keyword || !this.text) return [this.text]
      return this.text.split(this.keyword);
    },
  },
};
</script>
<style scoped>
.hightlight-text {
  color: red;
}
</style>