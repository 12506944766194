/*
 * @Description: 样式相关
 * @Author: luocheng
 * @Date: 2021-08-09 14:28:29
 * @LastEditors: Shiltin 18580045074@163.com
 * @LastEditTime: 2022-11-11 17:46:13
 */
import { sin, cos } from '@/utils/translate'

/**
 * @desc: 将配置的样式对象转换为可渲染对象
 * @param {Object} style 配置的样式对象
 * @param {Array} excludes 排除
 * @return {Object} 可渲染样式对象
 */
export function getStyle(style, excludes = []) {
	// 需要添加单位
	const needUnit = [
		'fontSize',
		'width',
		'height',
		'lineHeight',
		'top',
		'left',
		'right',
		'bottom',
		// border
		'borderWidth',
		'letterSpacing',
		'borderRadius',
		// 宽高范围
		'minHeight',
		'minWidth',
		'maxHeight',
		'maxWidth',
		// 外边距
		'marginTop',
		'marginBottom',
		'marginLeft',
		'marginRight',
		// 内边距
		'paddingTop',
		'paddingBottom',
		'paddingLeft',
		'paddingRight',
		//背景模糊
		'backdropFilter'
	];
	const result = {};
	Object.keys(style).forEach((key) => {
		if (!excludes.includes(key)) {
			if(key === 'rotate') {
				result.transform = key + '(' + style[key] + 'deg)';
			} else if(key === 'backdropFilter'){
				result[key] = 'blur('+ style[key] +'px)'
			}else{
				result[key] = style[key];
				if (needUnit.includes(key) && !isNaN(+result[key])) {
					result[key] += 'px';
				}
			}
		}
	});
	return result;
}

/**
 * @desc: 获取一个组件旋转 rotate 后的样式
 * @param {Object} style 原样式
 * @return {Object} 被处理的样式
 */
export function getComponentRotatedStyle(style) {
	style = { ...style };
	if (style.rotate != 0) {
		const newWidth = style.width * cos(style.rotate) + style.height * sin(style.rotate);
		const diffX = (style.width - newWidth) / 2; // 旋转后范围变小是正值，变大是负值
		style.left += diffX;
		style.right = style.left + newWidth;

		const newHeight = style.height * cos(style.rotate) + style.width * sin(style.rotate);
		const diffY = (newHeight - style.height) / 2; // 始终是正
		style.top -= diffY;
		style.bottom = style.top + newHeight;

		style.width = newWidth;
		style.height = newHeight;
	} else {
		style.bottom = style.top + style.height;
		style.right = style.left + style.width;
	}

	return style;
}
