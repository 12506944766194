/*
 * @Description: 
 * @Author: luocheng
 * @Date: 2021-08-10 15:10:33
 * @LastEditors: zx
 * @LastEditTime: 2022-09-13 18:53:18
 */
import Vue from 'vue'
import Vuex from 'vuex'
import user from './user';
import global from './global';
import record from './record';
import compose from './compose';
import lock from './lock';
import editorMenu from './editorMenu';
import copy from './copy';
import layer from './layer';
import events from './events';
import database from './database';
import form from './form';
import actions from './actions';
import preview from './preview'
import applicationConfig from './applicationConfig';
import screenContainer from './screenContainer';
import customStatus from './customStatus';
import toDo from './toDo';
import selectArchi from './selectArchi';
import saasManage from './saasManage';

// 统计分析
import chart from './chart/chart';
// 数仓
import dataObjects from './dataObjects';
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    chart,
    dataObjects,
    toDo
  },
  state: {
    ...user.state, // 用户信息
    ...global.state, // 全局状态
    ...record.state, // 快照
    ...compose.state, // 分组
    ...editorMenu.state, // 右击菜单
    ...copy.state, // 复制
    ...layer.state, // 组件层级
    ...events.state, // 事件
    ...database.state, // 数据仓库相关
    ...form.state, // 配置表单@曾伟自定义表单
    ...actions.state, // 弹窗
    ...preview.state, // 预览
    ...screenContainer.state, // 大屏容器编辑
    // 应用配置相关
    ...applicationConfig.state, // 配置应用
    ...customStatus.state, // 自定义页面状态
    ...selectArchi.state, // 架构切换数据
    ...saasManage.state, // 架构切换数据
  },
  mutations: {
    ...user.mutations, // 用户信息
    ...global.mutations, // 全局
    ...record.mutations, // 快照
    ...compose.mutations, // 分组
    ...lock.mutations, // 锁定
    ...editorMenu.mutations, // 右击菜单
    ...copy.mutations, // 复制
    ...layer.mutations, // 组件层级
    ...events.mutations, // 事件
    ...database.mutations, // 数据仓库相关
    ...form.mutations, // 配置表单@曾伟自定义表单
    ...actions.mutations, // 弹窗
    ...preview.mutations, // 预览
    ...screenContainer.mutations, // 大屏容器编辑
    // 应用配置相关
    ...applicationConfig.mutations, // 配置应用
    ...customStatus.mutations, // 自定义页面状态
    ...selectArchi.mutations, // 架构切换操作
    ...saasManage.mutations // 架构切换操作
  },
  actions: {
    ...database.actions, // 数据仓库
  }
})
