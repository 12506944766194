<!--
    @name: widget-money
    @description：widget-money
    @author: ZengWei
    @date: 2022-03-25 09:28
-->
<template>
  <div>
    <el-input
      type="number"
      :style="element.config.style"
      v-if="!element.config.disabled"
      v-model="element.value"
      :disabled="disabled || element.config.__config__.openRule || element.config.disabled"
      :placeholder="element.config.placeholder"
      @change="formatPrecision"
    >
      <template slot="prepend" v-if="element.config.__slot__.prepend">
        {{ element.config.__slot__.prepend }}
      </template>
      <template slot="append" v-if="element.config.__slot__.append">
        {{ element.config.__slot__.append }}
      </template>
    </el-input>
    <span style="margin-left: 8px">
      <b style="color: red;">RMB：</b>
      <el-tag size="small">{{ moneyToCny(element.value) }}</el-tag>
    </span>
  </div>
</template>

<script>
import {Input,Tag} from "element-ui";
import {numToCny} from "@/custom-component/form/newParser/scripts/tools";
import formItemMinix from "@/custom-component/form/newParser/scripts/formItemMinix";

export default {
  name: "WidgetMoney",
  components: {
    'el-input': Input,
    'el-tag': Tag
  },
  mixins: [formItemMinix],
  props: ['element', 'disabled', 'displayData'],
  data() {
    return {}
  },
  computed: {
    moneyToCny() {
      return function (money) {
        let unit = this.element.config.__slot__.append
        if (unit === '万' || unit === '万元') {
          money = parseFloat(money) * 10000
        }
        return numToCny(money)
      }
    },
  },
  methods: {
    formatPrecision(val) {
      if (this.element.config.precision) {
        let precision = this.element.config.precision;
        let value = Number(val).toFixed(precision);
        this.element.value = value;
      }
    },
  },
}
</script>

<style scoped>

</style>
