let now = new Date() // 当前日期
let nowDayOfWeek = now.getDay() // 今天本周的第几天
let nowDay = now.getDate() // 当前日
let nowMonth = now.getMonth() // 当前月
let nowYear = now.getYear() // 当前年
nowYear += (nowYear < 2000) ? 1900 : 0 //

let lastMonthDate = new Date() // 上月日期
lastMonthDate.setDate(1)
lastMonthDate.setMonth(lastMonthDate.getMonth() - 1)
// let lastYear = lastMonthDate.getYear()
// let lastMonth = lastMonthDate.getMonth()

// 格局化日期：yyyy-MM-dd
function formatDate (date) {
	if(!date) return
  let myyear = date.getFullYear()
  let mymonth = date.getMonth() + 1
  let myweekday = date.getDate()

  if (mymonth < 10) {
    mymonth = '0' + mymonth
  }
  if (myweekday < 10) {
    myweekday = '0' + myweekday
  }
  return (myyear + '-' + mymonth + '-' + myweekday)
}

// 获得某月的天数
function getMonthDays (myMonth) {
  let monthStartDate = new Date(nowYear, myMonth, 1)
  let monthEndDate = new Date(nowYear, myMonth + 1, 1)
  let days = (monthEndDate - monthStartDate) / (1000 * 60 * 60 * 24)
  return days
}

// 获得本季度的开端月份
// function getQuarterStartMonth () {
//   let quarterStartMonth = 0
//   if (nowMonth < 3) {
//     quarterStartMonth = 0
//   }
//   if (nowMonth > 2 && nowMonth < 6) {
//     quarterStartMonth = 3
//   }
//   if (nowMonth > 5 && nowMonth < 9) {
//     quarterStartMonth = 6
//   }
//   if (nowMonth > 8) {
//     quarterStartMonth = 9
//   }
//   return quarterStartMonth
// }

// 获得本周的开端日期
function getWeekStartDate () {
  let weekStartDate = new Date(nowYear, nowMonth, nowDay - nowDayOfWeek)
  return formatDate(weekStartDate)
}

// 获得本周的停止日期
function getWeekEndDate () {
  let weekEndDate = new Date(nowYear, nowMonth, nowDay + (6 - nowDayOfWeek))
  return formatDate(weekEndDate)
}

// 获得本月的开端日期
function getMonthStartDate () {
  let monthStartDate = new Date(nowYear, nowMonth, 1)
  return formatDate(monthStartDate)
}

// 获得本月的停止日期
function getMonthEndDate () {
  let monthEndDate = new Date(nowYear, nowMonth, getMonthDays(nowMonth))
  return formatDate(monthEndDate)
}

// 获得本年的开始日期
function getYearStartDate () {
  let currentYear = now.getFullYear()
  let currentYearFirstDate = new Date(currentYear, 0, 1)
  return formatDate(currentYearFirstDate)
}

// 获得本年的结束日期
function getYearEndDate () {
  let currentYear = now.getFullYear()
  let currentYearLastDate = new Date(currentYear, 11, 31)
  return formatDate(currentYearLastDate)
}

// function dateParse (dateString) {
//   let SEPARATOR_BAR = '-'
//   let SEPARATOR_SLASH = '/'
//   let SEPARATOR_DOT = '.'
//   let dateArray
//   if (dateString.indexOf(SEPARATOR_BAR) > -1) {
//     dateArray = dateString.split(SEPARATOR_BAR)
//   } else if (dateString.indexOf(SEPARATOR_SLASH) > -1) {
//     dateArray = dateString.split(SEPARATOR_SLASH)
//   } else {
//     dateArray = dateString.split(SEPARATOR_DOT)
//   }
//   return new Date(dateArray[0], dateArray[1] - 1, dateArray[2])
// }

export const dateHelper = () => {
  return {
    weekStartDate: getWeekStartDate(),
    weekEndDate: getWeekEndDate(),
    monthStartDate: getMonthStartDate(),
    monthEndDate: getMonthEndDate()
  }
}

export const containWeekDate = (startDate, endDate) => {
  // if(X < B && A < Y)  {  ...说明有交集    }
  const weekStartDate = new Date(getWeekStartDate())
  const weekEndDate = new Date(getWeekEndDate())
  if (weekStartDate <= endDate && weekEndDate >= startDate) return true
  return false
}

export const containMonthDate = (startDate, endDate) => {
  // if(X < B && A < Y)  {  ...说明有交集    }
  const monthStartDate = new Date(getMonthStartDate())
  const monthEndDate = new Date(getMonthEndDate())
  if (monthStartDate <= endDate && monthEndDate >= startDate) return true
  return false
}

export const containYearDate = (startDate, endDate) => {
  // if(X < B && A < Y)  {  ...说明有交集    }
  const yearStartDate = new Date(getYearStartDate())
  const yearEndDate = new Date(getYearEndDate())
  if (yearStartDate <= endDate && yearEndDate >= startDate) return true
  return false
}
