<!--
    @name: widget-checkbox
    @description：widget-checkbox
    @author: ZengWei
    @date: 2022-03-25 09:37
-->
<template>
  <el-checkbox-group
      v-if="!(disabled || element.config.disabled)"
      v-model="element.value"
      :disabled="disabled || element.config.disabled"
    >
      <el-checkbox
        v-for="(val, key) in element.config.__slot__.options"
        :label="'' + val.value"
        :key="key"
      >{{ val.label }}
      </el-checkbox>
    </el-checkbox-group>
    <p class="reshow-text" v-else>{{ reShowVal || '-' }}</p>
</template>

<script>
import formItemMinix from "@/custom-component/form/newParser/scripts/formItemMinix";
import {CheckboxGroup,Checkbox} from "element-ui";

export default {
  name: "WidgetCheckbox",
  components: {
    'el-checkbox-group':CheckboxGroup,
    'el-checkbox':Checkbox,
  },
  mixins: [formItemMinix],
  props: ['element', 'disabled', 'displayData'],
  data() {
    return {}
  },
  methods: {},
}
</script>

<style scoped>

</style>
