<!--
 * @LastEditTime: 2022-12-01 14:52:15
 * @Description: 条件引擎
 * @FilePath: /dataview-next/src/views/objects/components/treeForm.vue
 * @Date: 2021-10-07 12:59:46
 * @Author: lisushuang
 * @LastEditors: lisushuang
-->

<template>
  <div class="custom-tree-container">
    <p class="type-label-before-blue red-star" v-if="showTitle">{{ title }}</p>
    <el-tree
      :data="data"
      node-key="id"
      v-if="show"
      class="py-5"
      style="background: #f5f5f5;text-align: left;"
      default-expand-all
      :expand-on-click-node="false"
    >
      <span class="custom-tree-node" slot-scope="{ node, data }">
        <span v-if="!data.operator && data.id !== 0">
          <el-popover
            popper-class="pop-value-form scroller"
            :placement="formData == null ? 'left' : 'right'"
            width="500"
            trigger="click"
          >
            <ValueForm
              ref="leftValue"
              title="左侧取值"
              realTime
              @change-data="changeData($event, 'leftValue', data, node)"
              :obuuid="obuuid"
              :dictionaryList="dictionaryList"
              :fieldList="fieldList"
              :relationshipList="relationshipList"
              :formData="data.leftValue ? data.leftValue : null"
            />
            <p class="type-label-before-blue mt-5">比较方式</p>
            <el-form-item class="required-star" label="比较方式">
              <el-select
                clearable
                class="w-full mb-5"
                v-model="data.ruleType"
                placeholder="请选择比较值"
              >
                <el-option
                  v-for="item in filterType"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <ValueForm
              ref="rightValue"
              title="右侧取值"
              realTime
              @change-data="changeData($event, 'rightValue', data, node)"
              :obuuid="obuuid"
              :dictionaryList="dictionaryList"
              :fieldList="fieldList"
              :relationshipList="relationshipList"
              :formData="data.rightValue ? data.rightValue : null"
            />
            <el-form-item v-if="messageField" label="验证失败提示">
              <el-input clearable v-model="data.message"></el-input>
            </el-form-item>
            <div class="w-full" slot="reference">
              <span v-if="data.leftValue == null" class="col-theme"
                >左侧取值</span
              >
              <span v-else>
                  <span
                  v-if="
                    data &&
                    data.leftValue &&
                    data.leftValue.class &&
                    data.leftValue.payload &&
                    data.leftValue.class == 'ConstValue'
                  "
                >
                  {{ data.leftValue.payload.const_value }}
                </span>
                <span v-else class="col-theme">左侧值</span>
              </span>
              <span v-if="data.ruleType == ''" class="col-theme mx-5"
                >比较值</span
              >
              <span
                v-else
                v-html="
                  filterType.filter((v) => v.value == data.ruleType)[0].name
                "
                class="mx-5"
              ></span>
              <span
                v-if="
                  data &&
                  data.rightValue &&
                  data.rightValue.class &&
                  data.rightValue.payload &&
                  data.rightValue.class == 'ConstValue'
                "
              >
                {{ data.rightValue.payload.const_value }}
              </span>
              <span v-else class="col-theme">右侧取值</span>
            </div>
          </el-popover>
        </span>
        <span v-else>{{ node.label }}</span>
        <span>
          <el-popconfirm
            v-if="data.operator == ''"
            confirm-button-text="且：全部成立才可"
            cancel-button-text="或：有一个成立即可"
            icon="el-icon-info"
            icon-color="red"
            @confirm="append(data, 'and')"
            @cancel="append(data, 'or')"
            title="请选择逻辑"
          >
            <span slot="reference">
              <el-button type="primary" v-if="data.id == 0" size="mini"
                >新增</el-button
              >
              <i v-if="data.id != 0" class="el-icon-plus"></i>
              <i
                v-if="data.id != 0"
                @click="remove(node, data)"
                class="el-icon-delete ml-2"
              ></i>
            </span>
          </el-popconfirm>
          <span v-else>
            <span v-if="data.id == 0">
              <el-button
                type="primary"
                slot="reference"
                @click="append(data, data.operator)"
                size="mini"
                >新增</el-button
              >
            </span>
            <span v-else>
              <i
                @click="append(data, data.operator)"
                v-if="data.operator != ''"
                class="el-icon-plus"
              ></i>
              <i @click="remove(node, data)" class="el-icon-delete ml-2"></i>
            </span>
          </span>
        </span>
      </span>
    </el-tree>
  </div>
</template>
<script>
/* eslint-disable */
import object from "@/api/objects";
import ValueForm from "./valueForm.vue";
import { Tree,Popconfirm,Popover } from "element-ui";
let id = 1000;
export default {
  name: "TreeForm",
  data() {
    const data = [
      {
        id: 0,
        label: "默认",
        operator: "",
        children: [],
      },
    ];
    return {
      data: JSON.parse(JSON.stringify(data)),
      filterType: object.filterType, //筛选方式
      show: true,
    };
  },
  components: { 
    ValueForm,
    'el-tree': Tree,
    'el-popconfirm': Popconfirm,
    'el-popover': Popover,
    },
  watch: {
    data: {
      handler(val) {
        if (val && val[0] !== undefined) {
          this.$emit("input", val[0]);
        } else {
          this.$emit("input", "");
        }
      },
      deep: true,
    },
    formData: {
      handler(val, val_old) {
        if (val_old == null && val) {
          this.data = JSON.parse(JSON.stringify(this.formData));
        }
      },
      immediate: true,
    },
  },
  props: {
    fieldList: {
      type: Array,
      default: () => [],
    },
    formData: {
      type: Array | Object,
      default: null,
    },
    value: {
      type: Array | Object,
      default: null,
    },
    obuuid: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "触发条件",
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    messageField: {
      //验证集树形有个message字段
      type: Boolean,
      default: false,
    },
    dictionaryList: {
      type: Array,
      default: () => [],
    },
    relationshipList: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    append(data, type) {
      if (data.operator == "") {
        data.operator = type;
      }
      let name = type == "and" ? "且：全部成立才可" : "或：有一个成立即可";
      if (data.label == "默认") {
        data.label = name;
      }
      let sid = Math.random().toString(36).substring(3);
      const newChild = {
        id: sid,
        label: "默认",
        children: [],
        operator: "",
        ruleType: "",
        leftValue: null,
        rightValue: null,
      };
      if (this.messageField) {
        newChild.message = "";
      }
      if (!data.children) {
        this.$set(data, "children", []);
      }
      data.children.push(newChild);
    },

    remove(node, data) {
      const parent = node.parent;
      const children = parent.data.children || parent.data;
      const index = children.findIndex((d) => d.id === data.id);
      children.splice(index, 1);
      if (parent.data.children && parent.data.children.length == 0) {
        parent.data.label = "默认";
        parent.data.operator = "";
      }
    },
    changeData(e, name, data, node) {
      const parent = node.parent;
      const children = parent.data.children || parent.data;
      const index = children.findIndex((d) => d.id === data.id);
      children[index][name] = e;
    },
  },
  created() {},
};
</script>

<style >
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
.pop-value-form {
  max-height: 530px;
  overflow-y: auto;
}
</style>