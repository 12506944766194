/*
 * @LastEditTime: 2022-10-12 11:31:35
 * @Description: 元数据搜索接口
 * @FilePath: /dwh_front/src/api/objectSearch.js
 * @Date: 2022-01-06 17:58:37
 * @Author: lisushuang
 * @LastEditors: zx
 */

import http from "../global/http"

export default {

  /**
   * @description: 查询uuid来源
   * @param {String} uuid uuid
   * @return {*}
   */  
  searchUuid : (uuid) => {
    return http({
      url : '/api/metadata/search/getDataByUUid',
      params:{uuid:uuid}
    })
  },

  /**
   * @description: 查询uuid使用情况
   * @param {String} uuid uuid
   * @return {*}
   */  
  getUuidUses : (uuid) => {
    return http({
      url : 'https://saas-api.bimcc.net/api/metadata/search/uuidUseInfo',
      params:{uuid:uuid}
    })
  },

  /**
   * @description: 取值类使用情况
   * @param {String} name 取值类全名
   * @return {*}
   */  
  valueClassUses:(name) => {
    return http({
      url : '/api/metadata/search/valueUseInfo',
      params:{name:name}
    })
  },

  /**
   * @description: 行为类使用情况
   * @param {String} name 行为类全名 
   * @return {*}
   */  
  behaviorClassUses:(name) => {
    return http({
      url : '/api/metadata/search/behaviorUseInfo',
      params:{name:name}
    })
  }
}
