import { Graph,Shape } from '@antv/x6';
import '@antv/x6-vue-shape'
import TaskNode from "@/custom-component/task-flow/components/TaskNode";

// 连接桩
export const ports = {
	groups: {
		top: {
			position: 'top',
			attrs: {
				circle: {
					r: 4,
					magnet: true,
					stroke: '#5F95FF',
					strokeWidth: 1,
					fill: '#fff',
					style: {
						visibility: 'hidden',
					},
				},
			},
		},
		right: {
			position: 'right',
			attrs: {
				circle: {
					r: 4,
					magnet: true,
					stroke: '#5F95FF',
					strokeWidth: 1,
					fill: '#fff',
					style: {
						visibility: 'hidden',
					},
				},
			},
		},
		bottom: {
			position: 'bottom',
			attrs: {
				circle: {
					r: 4,
					magnet: true,
					stroke: '#5F95FF',
					strokeWidth: 1,
					fill: '#fff',
					style: {
						visibility: 'hidden',
					},
				},
			},
		},
		left: {
			position: 'left',
			attrs: {
				circle: {
					r: 4,
					magnet: true,
					stroke: '#5F95FF',
					strokeWidth: 1,
					fill: '#fff',
					style: {
						visibility: 'hidden',
					},
				},
			},
		},
	},
	items: [
		{
			group: 'top',
		},
		{
			group: 'right',
		},
		{
			group: 'bottom',
		},
		{
			group: 'left',
		},
	],
}

Graph.registerNode('start', {
		inherit: 'circle',
		attrs: {
			body: {
				strokeWidth: 2,
				stroke: '#7E8595',
				fill: '#FFF',
			},
			label: {
				text: '开始',
				fontSize: 16,
				fill: '#7E8595',
			},
		},
		ports: { ...ports },
	},
	true,
)

Graph.registerNode('end', {
		inherit: 'circle',
		attrs: {
			body: {
				strokeWidth: 4,
				stroke: '#7E8595',
				fill: '#FFF',
			},
			label: {
				text: '结束',
				fontSize: 16,
				fill: '#7E8595',
			},
		},
		ports: { ...ports },
	},
	true,
)

Graph.registerNode('gateway', {
		inherit: 'polygon',
		attrs: {
			body: {
				refPoints: '0,10 10,0 20,10 10,20',
				strokeWidth: 2,
				stroke: '#7E8595',
				fill: '#EFF4FF',
			},
			label: {
				text: '+',
				fontSize: 50,
				fill: '#7E8595',
			},
		},
		ports: { ...ports },
	},
	true,
)

Graph.registerNode("task", {
	inherit: "vue-shape",
	component: {
		template: `<task-node />`,
		components: {
			'task-node': TaskNode,
		},
	},
	ports: { ...ports },
});


export const graphInitial = (container)=>{
	const graph = new Graph({
		container: container,
		autoResize: true,
		mousewheel: {
			enabled: true,
			zoomAtMousePosition: true,
			modifiers: 'ctrl',
			minScale: 0.5,
			maxScale: 3,
		},
		panning: {
			enabled: true,
			modifiers: 'ctrl',
		},
		connecting: {
			router: {
				name: 'manhattan',
				args: {
					padding: 1,
				},
			},
			connector: {
				name: 'rounded',
				args: {
					radius: 8,
				},
			},
			anchor: 'center',
			connectionPoint: 'anchor',
			allowBlank: false,
			snap: {
				radius: 20,
			},
			createEdge() {
				return new Shape.Edge({
					attrs: {
						line: {
							stroke: '#A2B1C3',
							strokeWidth: 2,
							targetMarker: {
								name: 'block',
								width: 12,
								height: 8,
							},
						},
					},
					data: {},
					zIndex: 0,
				})
			},
			validateConnection({ targetMagnet }) {
				return !!targetMagnet
			},
		},
		highlighting: {
			magnetAdsorbed: {
				name: 'stroke',
				args: {
					attrs: {
						fill: '#5F95FF',
						stroke: '#5F95FF',
					},
				},
			},
		},
		resizing: true,
		rotating: true,
		selecting: {
			enabled: true,
			rubberband: true,
			showNodeSelectionBox: true,
			showEdgeSelectionBox: true,
		},
		snapline: {
			enabled: true,
			className: 'snapline',
			clean: false,
		},
		keyboard: true,
	});

	// 显示隐藏连接桩
	const showPorts = (ports,show) => {
		for (let i = 0, len = ports.length; i < len; i = i + 1) {
			ports[i].style.visibility = show ? 'visible' : 'hidden'
		}
	}
	graph.on('node:mouseenter', () => {
		const ports = container.querySelectorAll('.x6-port-body')
		showPorts(ports, true)
	})
	graph.on('node:mouseleave', () => {
		const ports = container.querySelectorAll('.x6-port-body')
		showPorts(ports, false)
	})

	//delete
	graph.bindKey('backspace', () => {
		const cells = graph.getSelectedCells()
		if (cells.length) {
			graph.removeCells(cells)
		}
	})
	graph.bindKey('delete', () => {
		const cells = graph.getSelectedCells()
		if (cells.length) {
			graph.removeCells(cells)
		}
	})
	graph.on('change:open', (data) => {
		console.log(data, 555555)
	})


	/*graph.on('node:added', ({ node }) => {
		const { x, y } = node.position()
		console.log(x,y)
	})*/

	return graph
}

export const recoverInitial = (container)=>{
	const graph = new Graph({
		container: container,
		mousewheel: {
			enabled: true,
			zoomAtMousePosition: true,
			modifiers: 'ctrl',
			minScale: 0.5,
			maxScale: 3,
		},
		connecting: {
			router: {
				name: 'manhattan',
				args: {
					padding: 1,
				},
			},
			connector: {
				name: 'rounded',
				args: {
					radius: 8,
				},
			},
			anchor: 'center',
			connectionPoint: 'anchor',
			allowBlank: false,
			snap: {
				radius: 20,
			},
			createEdge() {
				return new Shape.Edge({
					attrs: {
						line: {
							stroke: '#A2B1C3',
							strokeWidth: 2,
							targetMarker: {
								name: 'block',
								width: 12,
								height: 8,
							},
						},
					},
					data: {},
					zIndex: 0,
				})
			},
			validateConnection({ targetMagnet }) {
				return !!targetMagnet
			},
		},
		keyboard: true,
	});

	return graph
}
