<!--
 * @Author: 吴绍鹏 542278473@qq.com
 * @Date: 2022-12-05 09:12:57
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2023-01-04 15:55:22
 * @FilePath: \dataview-next\src\custom-component\pano\Pano.vue
 * @Description: 全景组件
-->
<template>
  <div class="pano-container" v-loading="loading">
    <div class="pano-header">
      <div :class="['pano-header-btn', isCompare ? 'isCompare' : 'noCompare']" @click="handleComparePano">
        {{ isCompare ? "全景查看" : "全景对比" }}	
        <i class="iconxiangyou-copy iconfont"></i>
      </div>
      <div class="dataview-header-select">
        <el-select class="custom-select" v-model="leftPanoVal" filterable @change="handleLeftPanoChange" placeholder="请选择">
          <el-option
            v-for="item in panoList"
            :key="item.id"
            :label="item.pano_name + ' ' +  item.pano_time"
            :value="item.id">
          </el-option>
        </el-select>
      </div>
      <div v-if="isCompare" class="dataview-header-select pano-header-right-select">
        <el-select class="custom-select" v-model="rightPanoVal" filterable @change="handleRightPanoChange" placeholder="请选择">
          <el-option
            v-for="item in panoList"
            :key="item.id"
            :label="item.pano_name + ' ' +  item.pano_time"
            :value="item.id">
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="pano-content">
      <iframe v-if="Array.isArray(panoList) && panoList.length" :src="isCompare ? comparePath : panoPath_left" frameborder="0" style="width: 100%; height: 100%"></iframe>
      <el-empty v-else description="暂无内容"></el-empty>
    </div>
  </div>
</template>

<script>
import { dataInterface } from '@/apis/data';
import eventBus from '@/plugins/eventBus';
import CustomComponentMixin from '@/custom-component/mixins/CustomComponentMixin.js';
import { getComponentById, initParams } from '@/utils/tools';
import { Empty } from 'element-ui'
/**
 * @description 全景对比组件
 */
export default {
  name: "PanoCom",
  components: { 'el-empty': Empty },
  mixins: [CustomComponentMixin],
  data() {
    return {
      isCompare: false, // 是否对比
      panoList: [],
      panoPath_left: "",
      path_left: "",
      path_right: "",
      leftPanoVal: "",
      rightPanoVal: "",
      panoMap: {},
      loading: false
    };
  },
  computed: {
    /**
     * @description: 获取播放全景的url
     * @return {String} 播放全景的url
     */    
    comparePath() {
      return `https://pano.bimcc.net/splitscreen?pano_1=${this.path_left}&pano_2=${this.path_right}`;
    },
    // 参数配置
    paramsConfig() {
      return this.element?.paramsConfig || [];
    },
    // 数据仓库配置
		database() {
			return this.element && this.element.database;
		},
  },
  methods: {
    /**
		 * @desc: 获取渲染列表
		 * @param {Object} database 数据配置对象
		 * @param {Array} search 搜索
		 */
		getList(database, search = [], params = {}) {
			this.search = Array.isArray(search) ? search : [];
			this.sourceData = {};
			if (!this.validDatabase(database)) return;
			this.loading = true;
			const paramsObj = {
				...params
			};
			// console.log('获取渲染列表______表格容器', params, paramsObj);
			// 配置
			let __method_name__ = 'dataList';
			const mapping = database.mapping;
			let configObj = null;
			if (mapping === 'object') {
				configObj = {
					__method_name__,
					object_uuid: database.objectData.uuid,
					view_uuid: database.viewData.uuid,
					transcode: 0,
					...paramsObj,
					search
				};
			} else if (mapping === 'relation') {
				__method_name__ = 'relationList';
				configObj = {
					__method_name__: 'relationList',
					object_uuid: database.objectData.uuid,
					relationship_uuid: database.relationData.uuid,
					...paramsObj,
					search
				};
			}
			// 获取表格数据
			dataInterface(configObj)
				.then((res) => {
					if (res && res.status === 200) {
            // 验证数据格式
						if (res.data && res.data.data && Array.isArray(res.data.data.data)) {
							this.isCompare = false;
              this.path_left = '';
              this.path_right = '';
              this.panoPath_left = '';
              this.leftPanoVal = '';
              this.rightPanoVal = '';
              res.data.data.data.map((el) => {
                this.panoMap[el.id] = {
                  ...el
                }
              })
              this.handlePanoData(res.data.data.data);
						}
          }
				})
				.catch((err) => {
					console.log(err);
				}).finally(() => {
          this.loading = false
        });
		},
    /**
		 * @desc: 验证
		 * @param {Object} database 数据仓库的绑定
		 */
		validDatabase(database) {
			if (!database || typeof database !== 'object') return false;
			if (!database.objectData) return false;
			if (!database.viewData && !database.relationData) return false;
			return true;
		},
    /**
		 * @desc: 判断是否存在依赖其他组件的取值
		 */
		hasComponentParam() {
			if (!this.database.paramsConfig || !this.database.paramsConfig.length) {
				return false;
			}
			for (let i = 0; i < this.database.paramsConfig.length; i++) {
				const { componentId = '', key = '', sourceType = '' } = this.database.paramsConfig[i];
				if ((key !== 'search' || !componentId.includes('CommonForm')) && sourceType !== 'url') {
					// componentId.includes('CommonTableContainer')兼容跨页请求的动态判定
					// 补充不同架构问题
					if (!componentId.includes('CommonTableContainer') && this.paramsSameArchi(componentId)) {
						return true;
					}
				}
			}
			return false;
		},
    /**
		 * @desc: 判断依赖的参数是否在当前架构下启用(@凌志华树形图架构限制)
		 * @param {String} componentId
		 * @return {Boolean}
		 */
		paramsSameArchi(componentId) {
			const comp = getComponentById(this.componentData, componentId);
			if (!comp) return false;
			const targetArchi = this.$GetTargetArchi('archiType');
			if (comp && comp.archiLimit && comp.archiLimit.length && comp.archiLimit.includes(targetArchi)) {
				return true;
			}
			return false;
		},
    /**
     * 处理全景数据，自动播放第一个全景
     */
    handlePanoData(resultData = []) {
      if(Array.isArray(resultData) && resultData.length > 0) {
        const { pano_path, pano_name, pano_time, id } = resultData[0]
        if (pano_path.indexOf("https") > -1) {
          this.panoPath_left = pano_path;
        } else {
          this.panoPath_left = `https://pano.bimcc.net/pc/preView?id=${pano_path}`;
          this.path_left = `${pano_path}`;
        }
        this.leftPanoVal = id;
        this.compareLeft_placeholder = pano_time ? pano_name + " " + pano_time : pano_name;
      }
      this.panoList = resultData;
    },
    /**
     * 左侧全景切换
     */
    handleLeftPanoChange(v) {
      const selectData = this.panoMap[v];
      if (selectData.pano_path.indexOf("https") > -1) {
        this.panoPath_left = selectData.pano_path;
      } else {
        this.panoPath_left = `https://pano.bimcc.net/pc/preView?id=${selectData.pano_path}`;
        this.path_left = `${selectData.pano_path}`;
      }
    },
    /**
     * 右侧全景切换
     */
    handleRightPanoChange(v) {
      const selectData = this.panoMap[v];
      this.path_right = `${selectData.pano_path}`;
    },
    /**
     * 切换查看模式
     */
    handleComparePano() {
      this.isCompare = !this.isCompare;
      if (!this.path_right) {
        let temp = this.panoList.filter(
          (item) => item.pano_path !== this.path_left
        );
        if (Array.isArray(temp) && temp.length > 0) {
          const { pano_path, id } = temp[0];
          this.path_right = pano_path;
          this.rightPanoVal = id;
        } else {
          this.path_right = this.path_left;
          this.rightPanoVal = this.leftPanoVal;
        }
      }
    },
  },
  created() {
    if (this.database && !this.hasComponentParam()) {
			const { search = [], param = {}, canPost } = initParams(this.element?.database?.paramsConfig || [], this.isGroup, this.componentData, this.groupComponents);
			if (!canPost) return;
			this.getList(this.database, search, param);
		}
		this.metadata = this.element.metadata || [];
  },
  mounted() {
    // 监听请求
		// 配置关联参数的容器才需要监听
    const databaseTrigger = {
      [this.element.id]: data => {
        if (data?.parentId !== this.element.id) return false;
        // 配置时候触发请求
        if (data.componentId === this.element.id && data.isInit) {
          const {
            search = [],
            param = {},
            canPost
          } = initParams(
            this.element?.database?.paramsConfig || [],
            this.isGroup,
            this.componentData,
            this.groupComponents
          );
          if (!canPost) return;
          this.getList(this.element.database, search, param);
          return;
        }
        // 点击操作时候不更新数据
        if (data.noUpdate) return;
        const { paramsConfig } = this.element.database;
        if (!paramsConfig || !paramsConfig.length) return;
        // 以下步骤是为了避免有多个来源的search需要进行differ 避免检索结果错误情况
        let {
          search = [],
          param = {},
          canPost
        } = initParams(
          this.element?.database?.paramsConfig || [],
          this.isGroup,
          this.componentData,
          this.groupComponents
        );
        if (!canPost) return;
        let isTarget = false;
        paramsConfig.forEach(ele => {
          if (ele.componentId === data.componentId) {
            isTarget = true;
          }
        });
        if (!isTarget && !data.isUpdate) return;
        this.param = param;
        this.getList(this.element.database, search, param);
      }
    }
    eventBus.$on('databaseTrigger', databaseTrigger[this.element.id]);
  },
};
</script>

<style lang="less" scoped>
.pano-container {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 10px 0 0 0;
  box-sizing: border-box;
  text-align: left;
  .pano-header {
    position: absolute;
    width: 100%;
    height: 40px;
    top: 20px;
    padding: 0 20px;
    box-sizing: border-box;
    .dataview-header-select {
      display: inline-block;
      width: 200px;
      height: 40px;
      line-height: 40px;
      color: #FFFFFF;
      background: url(//static.bimcc.com/images/saiDiPandect/quanjing_kuang.png) no-repeat center;
      background-size: 100% 100%;
      background-color: rgba(2, 122, 180, 0.2);
      :deep(.custom-select) {
        .el-input__inner{
          border: none;
          color: #fff;
          background: none;
        }
      }
    }
    .pano-header-right-select {
      margin-left: calc(~"50% - 290px");
    }
    .pano-header-btn {
      display: inline-block;
      width: 90px;
      height: 40px;
      line-height: 40px;
      margin-right: 10px;
      vertical-align: bottom;
      text-align: center;
      color: #ffffff;
      background-color: rgba(2, 122, 180, 0.2) !important;
      cursor: pointer;
			font-size:13px;
			i{ 
				font-size:15px;
			}

    }
    .isCompare {
      background: url(//static.bimcc.com/images/saiDiPandect/quanjing_btn_pre.png) no-repeat center;
      background-size: 100% 100%;
    }
    .noCompare {
      background: url(//static.bimcc.com/images/saiDiPandect/quanjing_btn.png) no-repeat center;
      background-size: 100% 100%;
    }
  }
  .pano-content {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
}
</style>