<template>
  <section class="dictionaries-box">
    <header class="theme">
      <h4>字典</h4>
    </header>
    <div class="total">
      全部字典
      <div class="tol">{{ total??0 }}</div>
    </div>
    <div class="dictionaries-echarts" id="dictionaries"></div>
  </section>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "Dictionaries",
  components: {},
  props: {
    // 统计数据
    statisticsList: {
      type: [Object, null],
      required: true,
      default: () => null,
    },
  },
  data() {
    return {
      itemClass: null,
      notItemClass: null,
      total: null,
    };
  },
  watch: {
    statisticsList(newVal) {
      this.newData = newVal;
      this.histogram();
    },
  },
  created() {},
  mounted() {},
  methods: {
    //根据value值 从大到小排序
    compare(property) {
      return function (a, b) {
        let value1 = a[property];
        let value2 = b[property];
        return value1 - value2;
      };
    },
    //柱状图
    histogram() {
      (this.statisticsList.notGlobalDicItem && this.statisticsList.GlobalDicItem && this.statisticsList.temDictItem) ?(this.itemClass = this.statisticsList.notGlobalDicItem + this.statisticsList.GlobalDicItem + this.statisticsList.temDictItem): 0,
      (this.statisticsList.notGlobalDic && this.statisticsList.GlobalDic && this.statisticsList.temDict) ?(this.notItemClass = this.statisticsList.notGlobalDic + this.statisticsList.GlobalDic + this.statisticsList.temDict) : 0,
      (this.itemClass && this.notItemClass) ? (this.total = this.itemClass + this.notItemClass) : 0;
      this.chartDom = document.getElementById("dictionaries");
      this.myChartHistogram = echarts.init(this.chartDom);
      let pieChartData = [
        {
          name: "项类",
          value: this.itemClass??0,
        },
        {
          name: "非项类",
          value: this.notItemClass??0,
        },
      ];
      let dataSort = [];
      let names = [];
      let data = [
        {
          value: this.statisticsList.allobject??0,
          name: "对象",
        },
        {
          value: this.statisticsList.countRelatd??0,
          name: "关联关系",
        },
        {
          value: this.statisticsList.countBluePrints??0,
          name: "蓝图",
        },
        {
          value: this.statisticsList.allViews??0,
          name: "视图",
        },
        {
          value: this.statisticsList.allDictionaries??0,
          name: "页面",
        },
        {
          value: this.statisticsList.GlobalDic??0,
          name: "字典",
        },
        {
          value:
            (this.statisticsList.formNotHistory +
            this.statisticsList.formHistory)??0,
          name: "表单",
        },
      ];
      dataSort = data.sort(this.compare("value"));
      dataSort.forEach((item) => {
        names.push(item.name);
      });
      this.option = {
        tooltip: {
          left: "left",
          trigger: "axis",
        },
        legend: {
          itemWidth: 14,
          itemHeight: 14,
          itemGap: 43.5,
          selectedMode: true,
          top: "20%",
          left: "2%",
          orient: "vertical",
          formatter: (name) => {
            let data = pieChartData;
            let target;
            for (let i = 0, l = data.length; i < l; i++) {
              if (data[i].name == name) {
                target = data[i].value;
              }
            }
            let arr = ["{a|" + name + "}", "{b|" + target + "}"];
            return arr.join("\n");
          },
          textStyle: {
            rich: {
              a: {
                fontSize: 14,
                lineHeight: 24,
                fontWeight: 400,
                fontStyle: "normal",
                fontFamily: "Source Han Sans CN",
                color: "#73767A",
                padding: [25, 0, 0, 8],
              },
              b: {
                color: "#000000",
                fontFamily: "Source Han Sans CN",
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: 18,
                lineHeight: 24,
                padding: [28, 0, 0, 8],
              },
            },
          },
        },
        grid: {
          left: "40%",
          right: "0",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: ["全局模板字典", "全局字典", "非全局字典"],
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#ADD4F9",
              },
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#333333",
                fontFamily: "Source Han Sans CN",
                fontStyle: "normal",
                fontWeight: 400,
                fontsize: 14,
                lineHeight: 24,
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "项类",
            type: "bar",
            barWidth: 12,
            barGap: "60%",
            barCategoryGap: "50%",
            itemStyle: {
              color: "#C6E2FF",
              barBorderRadius: [5, 5, 5, 5],
            },
            label: {
              normal: {
                show: true,
                position: "top",
              },
            },
            data: [
              this.statisticsList.notGlobalDicItem??0,
              this.statisticsList.GlobalDicItem??0,
              this.statisticsList.temDictItem??0,
            ],
          },
          {
            name: "非项类",
            type: "bar",
            barWidth: 12,
            itemStyle: {
              color: "#409EFF",
              barBorderRadius: [5, 5, 5, 5],
            },
            label: {
              normal: {
                show: true,
                position: "top",
              },
            },
            data: [
              this.statisticsList.notGlobalDic??0,
              this.statisticsList.GlobalDic??0,
              this.statisticsList.temDict??0,
            ],
          },
        ],
      };
      this.option && this.myChartHistogram.setOption(this.option);
    },
  },
};
</script>

<style lang="less" scoped>
.dictionaries-box {
  position: relative;
  width: 39.9%;
  height: 100%;
  padding: 16px 0 0 20px;
  box-sizing: border-box;
  background: #fff;
  .theme {
    position: absolute;
    top: 20px;
    left: 16px;
    color: rgb(68, 151, 254);
    font-size: 17px;
    font-weight: bold;
    line-height: 22px;
    text-align: left;
    h4 {
      padding-left: 10px;
      box-sizing: border-box;
    }
  }
  .dictionaries-echarts {
    width: 100%;
    height: 100%;
  }
  .total {
    position: absolute;
    top: 98.5px;
    left: 25%;
    color: #666666;
    font-family: "Source Han Sans CN";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    .tol {
      color: #95d475;
      font-family: "Source Han Sans CN";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
    }
  }
}
</style>
