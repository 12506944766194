<!--
 * @Description: 弹窗
 * @Author: luocheng
 * @Date: 2021-09-28 09:59:15
 * @LastEditors: 吴绍鹏 542278473@qq.com
 * @LastEditTime: 2022-12-15 13:48:04
-->
<template>
	<div>
		<!-- @廖总 默认点击遮罩层不关闭 -->
			<!-- :close-on-click-modal="statusConfig.closeOnClickModal" -->
			<!-- :wrapperClosable="statusConfig.closeOnClickModal" -->
		<component
			:is="'el-' + componentType"
			:title="statusConfig.title || '提示'"
			:visible.sync="statusConfig.isShow"
			:custom-class="customClass + ' custom-form-parser'"
			:width="fullDialogWidth || statusConfig.width || '400px'"
			:size="drawerWidth || 400"
			:fullscreen="statusConfig.fullscreen || (statusConfig.showExcel && preferredType === 'excel')"
			:top="componentType === 'dialog' ? statusConfig.top : ''"
			:modal="statusConfig.modal"
			:close-on-click-modal="false"
			:close-on-press-escape="statusConfig.closeOnPressEscape"
			:show-close="statusConfig.showClose"
			:center="statusConfig.center"
			:destroy-on-close="true"
			:lock-scroll="true"
			:wrapperClosable="false"
			append-to-body
		>

			<!-- <header class="model-header" slot="title">
				<h3>{{ statusConfig.title || '提示' }}</h3>
				<i class="iconfont icondaping-quanping" v-if="
				!statusConfig.showExcel || (statusConfig.showExcel && !['onlyForm', 'excel', 'onlyExcel'].includes(preferredType))" @click="onToggleFullScreen"></i>
				<i class="iconfont toggle-preferred" v-if="statusConfig.showExcel && !['onlyForm', 'onlyExcel'].includes(preferredType)"
					:class="{
						'iconzidingyibiaodan': preferredType === 'excel',
						'iconsaidi-daohang-biaoge': preferredType === 'form'
					}"
					@click="onTogglePreferredType"
				></i>
				<i class="iconfont iconbim_Xzhou" @click="onToggleComponentType" v-if="!['onlyForm', 'onlyExcel'].includes(preferredType)"></i>
			</header> -->
			<!-- 自定义标题头 -->
      <template #title>
        <div class="custom-dialog-header">
          <div>
            {{ statusConfig.title || '提示' }}
          </div>
          <div class="action-row">
            <i class="iconfont icondaping-quanping action-item" v-if="
				!statusConfig.showExcel || (statusConfig.showExcel && !['onlyForm', 'excel', 'onlyExcel'].includes(preferredType))" @click="onToggleFullScreen"/>
            <!-- <i v-show="isFull" class="iconfont icondaping-feiquanping action-item" @click.stop="isFull = !isFull"/> -->
						<template v-if="componentType === 'dialog'">
							<i v-show="sideShow" class="iconfont iconyouzhedie action-item" @click.stop="sideShow = !sideShow" />
							<i v-show="!sideShow" class="iconfont iconzuozhedie action-item" @click.stop="sideShow = !sideShow" />
						</template>
						<i class="iconfont iconbim_Xzhou action-item" @click="onToggleComponentType" v-if="!['onlyForm', 'onlyExcel'].includes(preferredType)"></i>
          </div>
        </div>
      </template>
			<article
				class="dialog-main"
				:class="[componentType === 'drawer' ? 'drawer-main' : 'dialog-main']"
				v-loading="loading"
			>
				<!-- 外部表单 -->
				<template v-if="contentConfig && ['outForm', 'luckySheet'].includes(contentConfig.contentType) && formDesignData">
          <PcParser
            ref="dialogForm"
						v-show="preferredType === 'form' || preferredType === 'onlyForm'"
						@submit="onFormSubmit"
						@on-close="onEndFlow"
						:form-data="formDesignData"
						:displayData="displayData"
						:editFields="editFields"
						:disabled="formDisabled"
						:needFlow="needFlow"
						:moduleUuid="contentConfig.formUUID || contentConfig.objectUUID"
						:needParentFlow="needParentFlow"
						:formMode="requestType"
						:nowArchiType="nowArchiType"
						:fillData="fillData"
						:excelMetaData="excelMetaData"
						:contentConfig="contentConfig"
					>
					</PcParser>
					<!-- luckSheet 新增数据无 excelMetaData 与 excelFormData 信息  -->
					<Entry v-show="statusConfig.showExcel && (preferredType === 'excel' || preferredType === 'onlyExcel')"
						loadType="preload"
						:type="excelModel"
						ref="dialogExcel"
						:excelUuid="contentConfig.excelTemplate"
						:objectUuid="contentConfig.objectUUID"
						:viewUuid="contentConfig.viewUUID"
						:formUuid="contentConfig.formUUID || contentConfig.objectUUID"
						:dataIds="excelIds"
						:excelMetaData="excelMetaData"
						:excelFormData="excelFormData"
						:show="preferredType === 'excel' || preferredType === 'onlyExcel'"
						:needFlow="needFlow"
						:displayData="displayData"
						@submit="onFormSubmit"
						@receive="doCacheFormData"
					></Entry>
				</template>
				<!-- 外部页面 -->
				<!-- :pageId="contentConfig.pageId"
					:pageData="pageData"
					:pageType="pageType"
					:pageConfig="pageConfig" -->
				<PageParser
					v-else-if="contentConfig && contentConfig.contentType === 'configPage' && initEnd"
					:pageUUID="contentConfig.pageId"
				></PageParser>
				<!-- 手动开发的页面 -->
				<template v-if="contentConfig && contentConfig.contentType === 'developPage' && requestType !== 'detail'">
					<OutPage :link="developLink"></OutPage>
				</template>
			</article>
			<!-- 操作 -->
			<!-- 抽屉底部 -->
			<div
				class="dialog-footer"
				v-if="componentType === 'drawer' && (statusConfig.showConfirm || statusConfig.showClose) && requestType !== 'detail'"
			>
				<el-button
					v-if="statusConfig.showCancel"
					:type="statusConfig.cancelType"
					@click="onCancel"
				>
					{{ statusConfig.cancelText }}
				</el-button>
				<el-button
					v-if="statusConfig.showConfirm"
					:type="statusConfig.confirmType"
					@click="onConfirm(false)"
				>
					{{ statusConfig.confirmText }}
				</el-button>
				<el-button
					v-for="(btn, bIndex) in actionConfig.filter((ele) => ele.canCustom)"
					:key="bIndex"
					:type="btn.buttonType"
					@click="onButton(btn)"
				>
					{{ btn.text }}
				</el-button>
				<!-- 缓存表单数据 -->
				<el-button type="" @click="onConfirm(true)" v-if="statusConfig.useCache">保存</el-button>
			</div>
			<!-- 弹窗 操作 -->
			<div
				slot="footer"
				class="dialog-footer"
				v-if="componentType === 'dialog' && (statusConfig.showConfirm || statusConfig.showClose)"
			>
				<el-button
					v-if="statusConfig.showCancel"
					:type="statusConfig.cancelType"
					@click="onCancel"
				>
					{{ statusConfig.cancelText }}
				</el-button>
				<el-button
					v-if="statusConfig.showConfirm"
					:type="statusConfig.confirmType"
					@click="onConfirm(false)"
				>
					{{ statusConfig.confirmText }}
				</el-button>
				<el-button
					v-for="(btn, bIndex) in actionConfig.filter((ele) => ele.canCustom)"
					:key="bIndex"
					:type="btn.buttonType"
					@click="onButton(btn)"
				>
					{{ btn.text }}
				</el-button>
				<!-- 缓存表单数据 -->
				<el-button type="" @click="onConfirm(true)" v-if="statusConfig.useCache">保存</el-button>
			</div>
		</component>
	</div>
</template>

<script>
import { transFieldsUUid, dataInterface } from '@/apis/data/index';
import PcParser from '@/custom-component/form/newParser/PcParserNew.vue';
// import PageParser from './parser/Index';
// import PageParser from '@/components/parser/Index';
import { getComponentById, getQueryValue, doEEActionHandle, getLinkByTemplate } from '@/utils/tools';
import { mapState } from 'vuex';
import eventBus from '@/plugins/eventBus';
import { isJSONStr } from '@/utils/tools';
import Entry from '@/custom-component/common/Entry';
import OutPage from './outPage/Index';

export default {
	name: 'CommonDialog',
	components: {
		PcParser,
		// PageParser,
		Entry,
		OutPage
	},
	props: {
		element: {
			type: Object,
			default: () => {},
			required: true
		},
    // 是否在组合内
    isGroup: {
			type: Boolean,
		},
    // 组合内组件列表
		groupComponents: {
			type: Array,
			default: () => []
		},
    componentList: {
      default: null
    }
	},
	data() {
		return {
			// 自定义表单相关
			objectUUID: null,
			// 表单配置数据
			formDesignData: null,
			displayData: {},
      // 流程中展示字段
      editFields:{},
			// 表单是否disable
			formDisabled: false,
			// 页面数据
			pageData: [],
			// 页面类型
			pageType: '',
			// 页面样式配置
			pageConfig: {
				height: 600,
				width: 500
			},
			loading: false,
			// 流程
			needFlow: false,
			needParentFlow: false,
			// 组件类型
			componentType: 'dialog',
			// 显示类型 form 表单  excel 图表
			preferredType: 'form',
			// 切换全屏
			fullScreen: false,
			// 全屏弹窗宽度
			fullDialogWidth: 0,
			// excel数据
			excelMetaData: null,
			excelFormData: null,
			// 缓存
			isCacheFormData: false,
			// 外部页面链接
			developLink: '',
			developHost: '',
			// 当前数据携带的archi_type
			nowArchiType: '',
			sideFlag: true,
			// 当前渲染的表单ID
			currentFormUUID: window.sessionStorage.getItem('currentFormUUID'),
			// 表单填充数据
			fillData: {},
			// 页面参数初始化完毕
			initEnd: false
		};
	},
	computed: {
		...mapState([
			'componentData',
			'_PageCustomStatus',
			'_APPCustomStatus'
		]),
		sideShow: {
			set(v) {
				this.sideFlag = v
			},
			get() {
				return this.componentType === 'drawer' || this.sideFlag
			}
		},
		customClass() {
			if (this.componentType === 'dialog' && ((this.statusConfig.showExcel && ['onlyExcel', 'excel'].includes(this.preferredType)) || this.fullScreen)) {
				return 'common-excel-dialog common-dialog'
			}
			return this.componentType === 'dialog' ? 'common-dialog' : 'common-drawer';
		},
		// 特殊样式
		statusConfig() {
			return this.element && this.element.statusConfig;
		},
		// 内容配置
		contentConfig() {
			return this.element && this.element.contentConfig;
		},
		// 操作配置
		actionConfig() {
			return this.element && this.element.actionConfig;
		},
		// 请求类型
		requestType() {
			const types = {
				createData: 'add',
				updateData: 'edit',
				dataInfo: 'detail'
			};
			return types[this.element?.sourceConfig?.[0]?.type || 'createData'];
		},
		// 抽屉宽度
		drawerWidth() {
			if (this.fullScreen || (this.statusConfig.showExcel && ['onlyExcel', 'excel'].includes(this.preferredType))) {
				return '100vw'
			}
			if (!this.statusConfig) return 400;
			if (isNaN(this.statusConfig.drawerWidth)) {
				return this.statusConfig.drawerWidth;
			}
			return +this.statusConfig.drawerWidth || 400;
		},
		// 表格模式
		excelModel() {
			if (!this.element.sourceConfig || !this.element.sourceConfig.length) return 'add';
			const sourceType = this.element.sourceConfig[0].type;
			if (sourceType === 'updateData') {
				return 'edit'
			}
			if (sourceType === 'dataInfo') {
				return 'info'
			}
			return 'add';
		},
		// 表格的id
		excelIds() {
			const params = this.getParams();
			return params.id || params.ids || params.data_id;
		},
		// 更新规则
		updateRulesConfig() {
			return this.element?.updateRulesConfig || {
				pattern: '',
				rules: []
			}
		}
	},
	created() {
		this.componentType = this.statusConfig?.firstType || 'dialog';
		this.preferredType = this.statusConfig?.preferredType || 'form';
		this.fullScreen = this.statusConfig.fullscreen || false;
	},
	watch: {
		contentConfig: {
			handler() {
				if (!this.contentConfig) return;
				this.fillData = this.getFillData();
				const configResult = this.getFormContentConfigs(this.contentConfig);
				const { contentType } = this.contentConfig;
				if (contentType === 'outForm') {
					// 来至自定义表单@曾伟
					this.objectUUID = null;
					// 表单填充值
					this.initFormDesign(configResult);
				} else if (contentType === 'luckySheet') {
					// excel 表格
				} else if (contentType === 'configPage') {
					// 来自外部配置页面
					// 获取部分参数配置
					const params = this.getParams();
					sessionStorage.setItem(
						`dialogRelationParams_${configResult.pageId}`,
						JSON.stringify(params)
					);
					this.initEnd = true;
				} else if (this.contentConfig.contentType === 'developPage') {
					// 外部首页页面
					// this.developHost = process.env.VUE_APP_V3_HOST || window.location.origin || 'https://dev.bimcc.net';
					const params = this.getParams();
					let paramsString = '';
					for (let key in params) {
						paramsString += `${key}=${params[key] ?? ''}&`;
					}
					this.developHost = process.env.VUE_APP_BASE_URL;
					this.developLink = `${this.developHost}${configResult.developPageRoute}?${paramsString}`
				}
			},
			deep: true,
			immediate: true
		},
		// 切换显示修复层级问题
		'statusConfig.isShow': {
			handler() {
				this.$nextTick(() => {
					try {
						const modalBox = document.querySelector('.v-modal');
						if (modalBox && modalBox.style) {
							modalBox.style.zIndex = '3000'
						}
					} catch (err) {
						console.log(err, '切换显示修复层级问题--');
					}
				});
			},
			immediate: true,
			deep: true
		}
	},
	methods: {
		/**
		 * @desc: 切换全屏
		 */
		onToggleFullScreen() {
			this.fullScreen = !this.fullScreen;
			if (this.fullScreen) {
				this.fullDialogWidth = '100vw';
			} else {
				this.fullDialogWidth = '';
			}
		},
		/**
		 * @desc: 切换表格和表单模式
		 */
		onTogglePreferredType() {
			const toggleType = {
				form: 'excel',
				excel: 'form'
			}
			this.preferredType = toggleType[this.preferredType];
		},
		/**
		 * @desc: 切换组件显示类型
		 */
		onToggleComponentType() {
			if (this.componentType === 'dialog') {
				this.componentType = 'drawer';
			} else {
				this.componentType = 'dialog';
			}
      console.log(this.element)
			// 特殊处理
			this.$nextTick(() => {
				const { sourceConfig } = this.element;
				const isInfo = sourceConfig[0].type === 'dataInfo';
				if (isInfo) {
					const drawer = document.querySelector('.common-drawer');
					if (drawer) {
						const drawerBox = drawer.parentNode?.parentNode;
						if (drawerBox) {
							drawerBox.classList.add('Editor-drawer__wrapper');
						}
					}
				}
			});
		},
		/**
		 * @desc: 流程处理结束回调处理
		 */
		onEndFlow() {
			this.beforeClose();
			this.statusConfig.isShow = false;
		},
		/**
		 * @desc: 确认按钮
		 */
		onConfirm(isCacheFormData = false) {
			this.isCacheFormData = isCacheFormData;
			if (this.contentConfig && this.contentConfig.contentType === 'outForm') {
				if (this.$refs && this.$refs.dialogForm && ['onlyForm', 'form'].includes(this.preferredType)) {
					if (isCacheFormData) {
						this.doCacheFormData(this.$refs.dialogForm.getFormData());
					} else {
						this.$refs.dialogForm.handleSubmit();
					}
				} else if (this.$refs && this.$refs.dialogExcel && ['onlyExcel', 'excel'].includes(this.preferredType)) {
					if (isCacheFormData) {
						this.$refs.dialogExcel.getFormData();
					} else {
						this.$refs.dialogExcel.triggerSubmit();
					}
				}
			}
			if (!isCacheFormData) {
				this.onCustomEvent('confirm');
			}
		},
		/**
		 * @desc: 初始化表单
		 */
		initFormDesign(contentConfig) {
			if (!contentConfig) return;
			this.loading = true;
			const params = this.getParams();
			sessionStorage.setItem('currentFormUUID', contentConfig.formUUID || contentConfig.objectUUID);
			transFieldsUUid(contentConfig.objectUUID, contentConfig.formUUID, params).then((res) => {
				if (res.data.code == 200) {
					const { sourceConfig } = this.element;
					this.formDisabled = sourceConfig[0]?.type === 'dataInfo';
					this.needFlow = !!res?.data?.data?.need_flow;
					this.needParentFlow = !!res?.data?.data?.need_parent_flow;
					this.nowArchiType = res?.data?.data?.archi_type || '';
					// 编辑或详情
					if (
           sourceConfig && sourceConfig.length &&
						(sourceConfig[0].type === 'updateData' ||
						sourceConfig[0].type === 'dataInfo' ||
						this.statusConfig.createWithDetails)
					) {
						// 编辑
						// 获取已经有的数据进行绑定
						let config = res.data.data;
						let ruuids = [];
						// #1 查找子表单，存在嵌套，故使用递归，
						const handle = (data) => {
							data.map(el => {
								if(el.__config__.tagIcon == 'form') {
									ruuids.push({ relationship_uuid: el.__vModel__ });
								}
								// 目前已知的只有tabs
								if(Array.isArray(el.__tabs__) && el.__tabs__.length) {
									el.__tabs__.map(ele => {
										if(Array.isArray(ele.children) && ele.children.length) {
											handle(ele.children);
										}
									})
								}
							})
						}
						handle(config.fields)
						// #1 查找子表单旧代码
						// for (let i = 0; i < config.fields.length; i++) {
						// 	let item = config.fields[i];
						// 	if (item.__config__.tagIcon == 'form') {
						// 		ruuids.push({ relationship_uuid: item.__vModel__ });
						// 	}
						// }
						dataInterface({
							...params,
							object_uuid: contentConfig.objectUUID,
							view_uuid:
							['dataInfo', 'updateData'].includes(sourceConfig[0].type)
									? contentConfig.viewUUID
									: '',
							ruuids,
							__method_name__: 'dataInfo'
						}).then((res1) => {
							let data = res1.data.data;
							this.formDesignData = res.data.data;
							this.excelFormData = {
								[contentConfig.objectUUID]: JSON.parse(JSON.stringify(data))
							};
							this.excelMetaData = JSON.parse(JSON.stringify(res1.data.metadata));
							this.displayData = data;
							if (data.editFields instanceof Array || this.requestType === 'edit') {
								this.editFields = {};
							} else {
								this.editFields = data.editFields || {};
							}
							this.loading = false;
						});
					} else {
						if (this.statusConfig.useCache && sourceConfig[0].type === 'createData' && res?.data?.data?.cache_data) {
							// 新增
							this.$confirm('是否使用缓存数据填充？', '提示').then(() => {
								try {
									this.formDesignData = res.data.data;
									this.loading = false;
									this.displayData = JSON.parse(res?.data?.data?.cache_data) || {};
								} catch(cacheErr) {
									console.log(cacheErr)
								}
							}).catch(() => {
								this.formDesignData = res.data.data;
								this.loading = false;
							});
						} else {
							this.formDesignData = res.data.data;
							this.loading = false;
						}
					}
				}
			}).catch((err) => {
				console.log(err);
				this.loading = false;
			});
		},
		/**
		 * @desc: 获取配置
		 * @param {Object} contentConfig
		 */
		getFormContentConfigs(contentConfig) {
			const { configType = '', dynamicComponent = '' } = contentConfig;
			if (!configType) {
				return contentConfig;
			}
			// 动态配置 dynamic
			let result = {};
			const originData = this.getOutput(dynamicComponent);
			for (let key in contentConfig) {
				const fieldUUID = contentConfig[key];
				result[key] = originData[fieldUUID]
			}
			return result;
		},
		/**
		 * @desc: 获取表单填充值
		 */
		getFillData() {
			const { formFillConfig = [] } = this.element;
			let result = {}
			formFillConfig.forEach(ele => {
				const {
					fieldUUID = '',
					sourceType = 'component',
					componentId = '', // 组件ID
					componentField = '', // 组件取值字段key
					systemKey = '', // 系统取值对象
					systemCode = '', // 系统取值参数
					urlParamsKey = '', // 链接请求参数key
					fixedValue = '', // 固定值
					statusCode = '' // 页面状态字段
				} = ele;
				if (fieldUUID) {
					let value = '';
					if (sourceType === 'component') {
						// 组件来源
						const resolveData = this.getOutput(componentId);
						value = resolveData?.[componentField];
					} else if (sourceType === 'url') {
						// 链接query
						value = getQueryValue(urlParamsKey);
					} else if (sourceType === 'system') {
						// 系统参数
						if (systemKey === 'userInfo') {
							value = this.$GetUserInfo(systemCode);
						} else if (ele.systemKey === 'targetArchi') {
							value = this.$GetTargetArchi(systemCode);
						}
					} else if (sourceType === 'pageStatus') {
						// 页面状态
						value = this._PageCustomStatus[statusCode] || this._APPCustomStatus[statusCode];
					} else if (sourceType === 'fixed') {
						// 固定值
						value = fixedValue;
					}
					result[fieldUUID] = value;
				}
			});
			return result;
		},
		/**
		 * @desc: 表单提交
		 * @param {Object} formData 自定义表单中提交的数据
		 */
		onFormSubmit(formData) {
			if (this.isCacheFormData) {
				this.doCacheFormData(formData);
				return false;
			}
			const { actionConfig } = this.element;
			const btnConfig = actionConfig.find((ele) => ele.key === 'confirm');
			if (!btnConfig || !btnConfig.effect) {
				this.statusConfig.isShow = false;
				return;
			}
			// 附加作用一般是发送请求
			if (btnConfig.effectType === 'postData') {
				let params = {};
				// 发送请求 整理参数
				const sourceParams = this.getParams();
				params = {
					__method_name__: btnConfig.postType,
					object_uuid: btnConfig.objectUUID,
					...sourceParams,
					// 表单的字段
					...formData
				};
				this.$loading();
				// 发送请求
				dataInterface(params)
					.then((res) => {
						this.$loading().close();
						if (res.code || (res.status && res.status === 200)) {
							this.beforeClose();
						}
					})
					.catch((err) => {
						console.log(err);
						this.$loading().close();
					});
			}
		},
		/**
		 * @desc: 缓存表单数据
		 * @param {Object} formData
		 */
		doCacheFormData(value) {
			if (!value) {
				this.$message.warning('暂无可保存数据!');
				return false;
			}
			const loading = this.$loading();
			dataInterface({
				__method_name__: 'createData',
				object_uuid: 'object61bbffbdabbbe',
				user_id: this.$GetUserInfo('id'),
				key: `formcache-${this.contentConfig.formUUID || this.contentConfig.objectUUID}`,
				value
			}).then(res => {
				if (res.status === 200) {
					this.$message.success('操作成功！');
				}
				loading && loading.close();
			}).catch(err => {
				console.log(err);
				loading && loading.close();
			})
		},
		/**
		 * @desc: 关闭前需要将组件的暴露数据清空
		 */
		beforeClose() {
			this.statusConfig.isShow = false;
			// 如果是对表格中的数据进行编辑需要对数据进行更新
			// 对于参照相关参数的需要进行获取
			// 更新规则 pattern: '' 默认规则, rules 强规则， compatible 兼容
			const { pattern = '', rules = [] } = this.updateRulesConfig;
			if (!pattern || pattern === 'compatible') {
				// 默认或兼容模式
				const sourceConfig = this.element.sourceConfig || [];
				for (let i = 0; i < sourceConfig.length; i++) {
					if (
						sourceConfig[i] &&
						!sourceConfig[i].originType &&
						sourceConfig[i].componentId
					) {
						eventBus.$emit('databaseTrigger', {
							action: 'dataList',
							componentId: sourceConfig[i].componentId,
							isInit: sourceConfig[i].componentId.indexOf('CommonTree-') > -1,
							isUpdate: true,
							output:
								sourceConfig[i].componentId.indexOf('CommonTabs-') > -1
									? this.getOutput(sourceConfig[i].componentId)
									: ''
						});
					}
				}
			}
			if (pattern === 'rules' || pattern === 'compatible') {
				rules.forEach(ele => {
					eventBus.$emit('databaseTrigger', {
						action: 'dataList',
						componentId: ele.componentId,
						isInit: ele.componentId.indexOf('CommonTree-') > -1,
						isUpdate: true,
						output: ele.componentId.indexOf('CommonTabs-') > -1 ? this.getOutput(ele.componentId) : ''
					});
				})
			}
		},
		/**
		 * @desc: 获取数据数据
		 */
		getOutput(comId) {
			let componentItem = getComponentById(this.componentData, comId);
			if (!componentItem && this.isGroup && this.groupComponents.length) {
				componentItem = getComponentById(this.groupComponents, comId);
			}
			return componentItem?.resolveData || '';
		},
		/**
		 * @desc: 点击按钮
		 */
		onButton(btnConfig) {
			this.onCustomEvent(btnConfig.key);
			if (btnConfig && btnConfig.effectType === 'eeAction') {
				const { objectUUID, viewUUID, eventName } = btnConfig;
				if (!objectUUID || !viewUUID || !eventName) {
					this.$message.error('请正确配置事件!');
					return false;
				}
				const data_id = this.getParams()?.data_id;
				if (!data_id) {
					this.$message.error('事件未配置触发对象!');
					return false;
				}
				this.loading = true;
				// 触发后端事件
				dataInterface({
					__method_name__: 'customEventCall',
					object_uuid: btnConfig.objectUUID,
					view_uuid: btnConfig.viewUUID,
					data_id,
					event: btnConfig.eventName
				}).then((res) => {
					if (res.status === 200) {
						this.$message.success('操作成功！');
						// 后续操作
						doEEActionHandle(res.data?.__adds__);
					}
					this.loading = false;
				}).catch((err) => {
					console.log(err, '00000后端事件错误');
					this.loading = false;
				});
			}
		},
		/**
		 * @desc: 获取绑定参数
		 */
		getParams() {
			const sourceConfig = this.element.sourceConfig || [];
			const sourceParams = {};
			for (let i = 0; i < sourceConfig.length; i++) {
				const {
					componentId,
					field,
					key,
					originType = '',
					urlParamKey = '',
					statusCode = '',
					fixedValue = ''
				} = sourceConfig[i];
				if (originType === 'pageStatus' && statusCode) {
					const result = this._PageCustomStatus[statusCode] || this._APPCustomStatus[statusCode]
					this.$set(sourceParams, key, result);
				} else if (originType === 'url' && urlParamKey) {
					// 从url获取参数
					const result = getQueryValue(urlParamKey);
					this.$set(sourceParams, key, result);
				} else if (componentId && field && key) {
					// 普通从组件获取
					let sourceComponent = getComponentById(this.componentData, componentId);
					if (!sourceComponent && this.isGroup && this.groupComponents.length) {
						sourceComponent = getComponentById(this.groupComponents, componentId);
					}
					const result = sourceComponent?.resolveData ? sourceComponent.resolveData[field] : '';
					this.$set(sourceParams, key, result);
				} else if (originType === 'fixed') {
					// 固定参数
					this.$set(sourceParams, key, fixedValue);
				}
			}
			return sourceParams;
		},
		/**
		 * @desc: 页面
		 */
		initPageDesign(contentConfig) {
			dataInterface({
				__method_name__: 'dataInfo',
				object_uuid: 'a4f016d6-c602-4492-8874-f088c3c0b3b9',
				view_uuid: '175c6fe7-c8a2-4530-99be-80a8f1113f3c',
				data_id: contentConfig.pageId
			})
				.then((res) => {
					this.loading = false;
					if (
						res.status === 200 &&
						res.data &&
						res.data.code === 200 &&
						res.data.data
					) {
						const pageData =
							res.data.data['f708a9c6-2514-47ad-9056-3c3a1c37496f'];
						let { componentData, editorConfig, editorType } = isJSONStr(pageData) ? JSON.parse(pageData) : pageData;
						this.pageData = componentData;
						this.pageType = editorType;
						this.pageConfig = editorConfig;
						this.initEnd = true;
					}
				})
				.catch(() => {
					this.loading = false;
				});
		},
		/**
		 * @desc: 取消显示
		 */
		onCancel() {
			this.onCustomEvent('cancel');
			this.statusConfig.isShow = false;
		},
		/**
		 * @desc: 触发自定义事件
		 */
		onCustomEvent(eventKey) {
			const config = this.actionConfig.find(ele => ele.key === eventKey);
			if (!config || !config.eventList || !Array.isArray(config.eventList) || !config.eventList.length) return;
			const comEvents = config.eventList || [];
			for (let i = 0; i < comEvents.length; i++) {
				const { pattern, eventList = [], specialEventList = [] } = comEvents[i];
				if (pattern === undefined) {
					this.doActionItem(comEvents[i]);
					break;
				}
				const result = pattern === 'special' ? specialEventList : eventList
				result.forEach((ele) => {
					this.doActionItem(ele);
				});
			}
		},
		/**
		 * @desc: 触发事件
		 * @param {Object} ele
		 */
		doActionItem(ele) {
			if (ele.key === 'click') {
				// 跳转页面
				if (ele.actionType === 'jumpPage') {
					if (ele.linkType === 'projectPage') {
						const query = {};
						ele.queryList && ele.queryList.forEach((queryItem) => {
							let component = getComponentById(this.componentList || this.componentData, queryItem.componentId);
							if (!component && this.isGroup && this.groupComponents.length) {
								component = getComponentById(this.groupComponents, queryItem.componentId);
							}
							this.$set(
								query,
								queryItem.key,
								component.resolveData[queryItem.feild]
							);
						});
						this.$router.push({
							path: ele.pageId,
							query
						});
						return;
					} else if (ele.linkType === 'outPage') {
						window.open(ele.linkUrl);
					} else if (ele.linkType === 'custom') {
						const customLink = getLinkByTemplate(ele.linkTemplate)
						window.open(customLink);
					}
				} else if (ele.actionType === 'eeAction') {
					// 触发后端事件
					// console.log(ele, '0000001111111111', output);
					// this.doEEAction(ele);
					// 搜集参数
				} else {
					// 页面事件
					ele.effects.forEach((effect) => {
						this.$store.commit('triggerEvents', {
							config: {
								...ele,
								...effect
							},
							element: this.element
						});
					});
				}
			}
			// 导出事件(只能导出当前行的数据)
			if (ele.actionType === 'export') {
				eventBus.$emit('exportData', ele);
			}
		},
	},
	beforeDestroy() {
		sessionStorage.removeItem(
			`dialogRelationParams_${this.contentConfig.pageId}`
		);
		sessionStorage.removeItem('currentFormUUID')
	}
};
</script>

<style lang="less" scoped>
:deep(.common-dialog) {
	height: auto;
	max-height: calc(100vh - 50px);
	overflow-y: hidden;
	// 弹窗
	.dialog-main {
		overflow-x: auto;
		display: flex;
		flex-direction: column;
	}
	.el-dialog__body {
		max-height: calc(100vh - 155px);
		// min-height: 300px; //  @凌志华
		padding: 0;
		overflow: hidden;
		display: flex;
		box-sizing: border-box;
		// padding: 15px 16px;
		flex-direction: column;
		.dialog-footer {
			box-sizing: border-box;
			padding-top: 20px;
			text-align: right;
		}
	}
	.el-dialog__header {
		border-bottom: 1px solid #f2f3f5;
	}
	.el-dialog__footer {
		padding: 15px 10px;
		border-top: 1px solid #f2f3f5;
	}
	.model-header {
		display: flex;
		box-sizing: border-box;
		padding-right: 30px;
		cursor: pointer;
		h3 {
			flex: 1;
		}
		.iconfont {
			font-size: 16px;
			box-sizing: border-box;
			padding: 0 5px;
			&+.iconfont{
				margin-left: 5px;
			}
		}
	}
}
:deep(.el-dialog) {
	border-radius: 8px 8px 4px 4px;
}
:deep(.custom-form-parser) {
	.el-drawer__header{
		padding: 0 !important;
		background: transparent;
		.el-drawer__close-btn{
			position: absolute;
			top: 14px;
			right: 24px;
			.el-dialog__close{
				color: #fff;
				font-size: 20px;
			}
		}
		.custom-dialog-header{
			border-radius: 0px 0px 0px 0px;
		}
	}
	.el-dialog__header{
		padding: 0 !important;
		background: transparent;
		border-radius: 8px 8px 0px 0px;
		.custom-dialog-header{
			border-radius: 4px 4px 0px 0px;
		}
	}
	.custom-dialog-header{
		height: 48px;
    background: linear-gradient(90deg, #6292FF 0%, #DE93FF 84%, #CE92FF 91%, #C568FF 100%);
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 68px 0 24px;
		color: #fff;
		.action-row{
			display: flex;
			.action-item{
				color: #fff;
				font-size: 20px;
				margin-right: 10px;
				& + .action-item{
					margin-left: 24px;
				}
			}
		}
	}
	.el-dialog__headerbtn{
		top: 14px;
		right: 24px;
		.el-dialog__close{
			color: #fff;
				font-size: 20px;
		}
	}
}
.el-dialog__wrapper{
	overflow: hidden;
}
:deep(.common-excel-dialog) {
	height: 100vh!important;
	max-height: 100vh!important;
	.el-dialog__body {
		min-height: calc(100vh - 115px)!important;
		max-height: calc(100vh - 115px)!important;
		height: calc(100vh - 115px)!important;
		.dialog-main{
			height: 100%;
			width: 100%;
		}
	}
}
:deep(.common-drawer) {
	height: 100vh !important;
	overflow: hidden;
	.model-header {
		display: flex;
		box-sizing: border-box;
		padding-right: 10px;
		cursor: pointer;
		h3 {
			flex: 1;
		}
		.iconfont {
			font-size: 16px;
			box-sizing: border-box;
			padding: 0 5px;
			&+.iconfont{
				margin-left: 5px;
			}
		}
	}
	// 抽屉
	.el-drawer__header {
		margin-bottom: 0;
		border-bottom: 1px solid #f2f3f5;
		padding-bottom: 20px;
	}
	.el-drawer__body {
		box-sizing: border-box;
		// padding: 15px 16px;
		display: flex;
		flex-direction: column;
		.drawer-main {
			flex: 1;
			height: 10px;
			width: 100%;
			overflow: hidden;
			overflow-y: auto;
			// 穿透parse
			.form-parser-container{
				.form-parser-warp{
					width: 100%;
					flex-direction: column;
					overflow: auto;
					.left-sider{
						width: 100%;
						overflow: unset;
					}
					.right-sider{
						width: 100%;
						margin-left: 0;
						.right-content{
							overflow: unset;
							.flow-designer{
								height: 300px;
							}
						}
					}
				}
			}
		}
		.dialog-footer {
			padding: 15px;
			text-align: right;
			border-top: 1px solid #f2f3f5;
		}
	}
}
	.Editor-drawer__wrapper{
		width: 100vw;
	}
</style>
