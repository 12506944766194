<!--
 * @Author: 朱廷果 1028509503@qq.com
 * @Date: 2022-09-07 14:31:45
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2022-10-08 14:11:04
 * @FilePath: \dataview-viewer-test\src\views\newSecond\components\todoCom\WarringDialog.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <el-dialog
      title="预警信息"
      :visible.sync="dialogVisible"
      width="50%"
      append-to-body
      :before-close="onClose">
      <div class="type-label">警告等级：
      </div>
      <span class="status" :class="flterStatus(warringData.alert_level).class">{{ flterStatus(warringData.alert_level).text}}</span>
      <div class="type-label">警告类型：
      </div>
      <span class="status">【{{ warringData.alert_type || '其他' }}预警】</span>
      <div class="type-label">警告内容：
      </div>
      <div class="title">{{ warringData.alert_text }}</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onClose">取 消</el-button>
        <el-button type="primary" @click="onSave">已 读</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { dataInterface } from '@/apis/data'
export default {
  props: {
    dialogVisible:{
      type:Boolean,
      default:false,
    },
    warringData:{
      type: Object,
      default: ()=>{}
    }
  },
  methods: {
    onClose(){
      this.$emit('onClose');
    },
    onSave(){
      //TODO 等待已读接口
      let params = {
        __method_name__: 'createData',
        transcode: 0,
        read_alarms: this.warringData.id,
        object_uuid:'object631860d8b7c71',
        view_uuid:'view631860dfe5010',
      }
      dataInterface(params).then(()=>{
        this.$emit('onSave');
      })
    },
    flterStatus(text = '') {
        if(text.length === 0) {
            return {
            text: '-',
            class: ''
            }
        }
        const str = text[0]
        const classHash = {
            '黄': 'y-status',
            '橙': 'o-status',
            '蓝': 'b-status',
            '红': 'r-status'
        }
        return {
            text: classHash[str] ? `${str}色预警` : '其他预警',
            class: classHash[str] || 'other-status'
        }
    },
  },
};
</script>
<style lang="less" scoped>
.type-label{
    border-left: 3px solid #007FE0 !important;
    padding: 0 10px;
    line-height: 20px;
    font-size: 14px;
    margin: 15px 0 !important;
    font-weight: 600;
    text-align: left;
}
.r-status{
    background: #FFE6E6;
    color: #FF5C5C;
}
.o-status{
    background: #FFE7D4;
    color: #FF8826;
}
.b-status{
    background: #E0E8FF;
    color: #5C87FF;
}
.y-status{
    background: hsla(40, 100%, 50%, .2);
    color: #FFAA00;
}
.other-status{
    background: rgba(77, 77, 77, .2);
    color: #777;
}
// .title{
//     flex-grow: 1;
//     width: 10px;
//     margin-left: 2px;
//     height: 22px;
//     font-size: 14px;
//     font-family: PingFangSC-Regular, PingFang SC;
//     font-weight: 400;
//     color: #2A2F3D;
//     line-height: 22px;
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
// }
</style>