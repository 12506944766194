export default class CoordinateEvents{
    constructor(){

    }

    static getEvents(viewer, comp){
        return {
            "ViewerGIS.getCanvasByWgs":  (eid, position, cb)=>{
                if(eid !== comp.element.id) return;

                let canvas = viewer.Coordinate.getCanvasByWgs(position.lng, position.lat, position.height);
                cb && cb(canvas);
            },
        }
    }
}
