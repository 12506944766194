var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.editorMenuShow),expression:"editorMenuShow"}],staticClass:"editor-menu",style:({
      left: _vm.editorMenuPosition.left + 'px',
      top: _vm.editorMenuPosition.top + 'px'
    })},[_c('ul',{on:{"mouseup":_vm.onMouseUp}},[(_vm.curComponent)?[(!_vm.curComponent.isLock)?[_c('li',{on:{"click":_vm.onCopy}},[_vm._v("复制")]),_c('li',{on:{"click":_vm.onPaste}},[_vm._v("粘贴")]),_c('li',{on:{"click":_vm.onCut}},[_vm._v("剪切")]),_c('li',{staticClass:"remove",on:{"click":_vm.onDelete}},[_vm._v("删除")]),(_vm.editorType === 'dataview' || _vm.editorType === 'console')?[_c('li',{on:{"click":function($event){return _vm.onToggleLock(true)}}},[_vm._v("锁定")]),(_vm.componentData.length > 1)?[_c('li',{class:{
									'disable-btn': _vm.componentData.length < 2
								},on:{"click":_vm.onTopComponent}},[_vm._v("置顶")]),_c('li',{class:{
									'disable-btn': _vm.componentData.length < 2
								},on:{"click":_vm.onBottomComponent}},[_vm._v("置底")]),_c('li',{class:{
									'disable-btn': _vm.componentData.length < 2
								},on:{"click":_vm.onUpComponent}},[_vm._v("上移一层")]),_c('li',{class:{
									'disable-btn': _vm.componentData.length < 2
								},on:{"click":_vm.onDownComponent}},[_vm._v("下移一层")])]:_vm._e()]:_vm._e()]:(_vm.editorType === 'dataview' || _vm.editorType === 'console')?_c('li',{on:{"click":function($event){return _vm.onToggleLock(false)}}},[_vm._v("解锁")]):_vm._e()]:_c('li',{on:{"click":_vm.onPaste}},[_vm._v("粘贴")])],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }