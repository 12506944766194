<!--
 * @Author: your name
 * @Date: 2021-12-02 15:32:01
 * @LastEditTime: 2022-10-28 14:33:39
 * @LastEditors: Shiltin 18580045074@163.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \nanhu-micro\src\views\components\bindModel\index.vue
-->
<template>
  <div
    class="model-choose-cont left-list-hide"
  >
    <div class="list relative">
      <el-page-header
        class="page-header"
        content="GIS绑定"
        @back="back"
      />
      <el-button
        class="absolute"
        size="mini"
        style="top: 8px; right: 10px"
        type="primary"
        @click="commit()"
      >
        确定
      </el-button>
    </div>
    <preview
      v-if="previewShow"
      ref="preview"
			:appId="appId" :appSecret="appSecret" 
      class="preview-cont"
      :dom-id="domId"
      :model-data="modelData"
      @set-data="setData"
    />
  </div>
</template>

<script>
import Preview from './preview.vue'
import { PageHeader, Button } from "element-ui";
export default {
  name: 'BindGisIndex',
  components: {
			Preview,
			"el-button": Button,
			"el-page-header": PageHeader,
	},
  props: {
    modelData: {
      type: Object,
      default:null
    },
    // 动态id
    domId: {
      type: String,
      default: 'bindGis'
    },
		appId: {
			type: String,
			default: ""
		},
		appSecret: {
			type: String,
			default: ""
		}
  },
	data() {
		return {
			previewShow: true,
      selectInfo: null,
			// data:{
			// 	models: [{modelId:'7828e618b2ec4daa8d89f8caa5923d93',modelName:'xxx'}],
      //   position: { lng: 116.43296885, lat: 39.91597081, height: 10.61 }
			// }
		};
	},
	methods: {
		back() {
			this.$emit("back");
		},
		setData (data) {
      this.selectInfo = data
    },
		commit (){
			if (this.selectInfo !== null) {
				console.log(this.selectInfo)
        this.$emit('set-data', this.selectInfo)
      } else {
        this.$message.warning('请选择模型或位置！')
      }
		}
	}
}
</script>
<style lang="less" scoped>
.model-choose-cont {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    background-color: #fff;
    overflow: hidden;
    .list {
        width: 300px;
        height: 100%;
        z-index: 120;
        border-right: 1px solid var(--el-border-color-base);
        .page-header {
            line-height: 45px;
            padding-left: 15px;
            border-bottom: 1px solid var(--el-border-color-base);
        }
        .list-cont {
            height: calc(100% - 45px);
        }
    }
    .preview-cont {
        height: 100%;
        flex: 1;
    }
    .other-btn {
        position: absolute;
        top: 10px;
        left: 310px;
        height: 20px;
        z-index: 100;
        i {
            font-size: 18px;
        }
    }
}
.left-list-hide {
    flex-direction: column !important;
    .list {
        width: 100%;
        height: 46px;
    }
    .other-btn {
        left: 15px !important;
        top: 50px !important;
    }
}
</style>
