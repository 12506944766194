/*
 * @Description: 数据仓库
 * @Author: luocheng
 * @Date: 2021-09-06 18:21:38
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2022-12-05 15:19:17
 */
import { getObjects, getFields } from '@/apis/data/index';
import { Loading } from 'element-ui';
import store from '@/store';

export default {
	state: {
		// 公共数据仓库对象集合
		objectList: [],
		// 对象分页
		objectPager: {
			page: 1,
			size: 15,
			total: 0
		},
		// 基础数据对象
		baseDataObj: null,
		// 字段列表
		fieldList: []
	},
	mutations: {
		/**
		 * @desc: 设置 公共数据仓库对象集合
		 * @param {Array} objectList 公共数据仓库对象集合（第一页， 默认每页15条）
		 */
		setObjectList(state, { objectList, pager }) {
			state.objectList = objectList;
			state.objectPager = pager;
			sessionStorage.setItem(
				'objectList',
				JSON.stringify({
					objectList,
					pager
				})
			);
		},
		/**
		 * @desc: 设置数据对象
		 */
		setDataObject(state, dataObj) {
			state.baseDataObj = dataObj;
			sessionStorage.setItem('baseDataObj', JSON.stringify(dataObj));
		},
		/**
		 * @desc: 保存字段列表
		 * @param {Array} fieldList 字段列表
		 * @param {page} pager 字段分页
		 */
		setFieldList(state, { fieldList }) {
			if (!state.curComponent) return;
			state.curComponent.database.fieldList = fieldList;
			store.commit('modifyComponent', {
				component: state.curComponent,
				containerId: null,
				isModify: true
			});
		},
		/**
		 * @desc: 设置当前对象的数据对象
		 * @param {Object} objectData 数据对象
		 */
		setDatabaseObject({ curComponent }, objectData) {
			if (!curComponent) return;
			curComponent.database.objectData = objectData;
			if (!objectData) {
				curComponent.database.viewData = null;
			}
			store.commit('modifyComponent', {
				component: curComponent,
				containerId: null,
				isModify: true
			});
		},
		/**
		 * @desc: 移除当前对象的数据对象及视图
		 */
		removeDatabaseObject({ curComponent }) {
			if (!curComponent || !curComponent.database) return;
			curComponent.database.objectData = null;
			curComponent.database.viewData = null;
			curComponent.database.relationData = null;
			store.commit('modifyComponent', {
				component: curComponent,
				containerId: null,
				isModify: true
			});
		},
		/**
		 * @desc: 设置视图
		 * @param {Object} viewData 视图
		 */
		setDatabaseView({ curComponent }, viewData) {
			curComponent.database.viewData = viewData;
		},
		/**
		 * @desc: 移除视图
		 */
		removeDatabaseView({ curComponent }) {
			if (!curComponent || !curComponent.database) return;
			curComponent.database.viewData = null;
		},
		/**
		 * @desc: 设置视表单
		 * @param {Object} formData 视图
		 */
		setDatabaseForm({ curComponent }, formData) {
			curComponent.database.formData = formData;
		},
		/**
		 * @desc: 移除视图
		 */
		removeDatabaseForm({ curComponent }) {
			if (!curComponent || !curComponent.database) return;
			curComponent.database.formData = null;
		},
		/**
		 * @desc: 配置当前组件的数据源
		 * @param {Object} config 配置
		 */
		setComponentDatabase(state, config) {
			const fieldList = config.fieldList || [];
			let metadata = {};
			if (Array.isArray(fieldList)) {
				fieldList.forEach(ele => {
					metadata[ele.uuid] = ele.name;
				});
			}
			store.commit('modifyComponent', {
				component: {
					...state.curComponent,
					database: config,
					metadata
				},
				containerId: null,
				isModify: true
			});
		},
		/**
		 * @desc: 请求类型
		 * @param {String} type 类型
		 */
		setDatabaseRequestType({ curComponent }, requestType) {
			if (!curComponent) return;
			curComponent.database.requestType = requestType;
		},
		/**
		 * @desc: 请求参数配置
		 * @param {Object} paramsConfig
		 */
		setDatabaseParams({ curComponent }, paramsConfig) {
			if (!curComponent) return;
			curComponent.database.paramsConfig = paramsConfig;
		},
		/**
		 * @desc: 配置mock数据
		 * @param {Array} mockConfig
		 * @return {*}
		 */
		setDatabaseMock({ curComponent }, mockConfig) {
			if (!curComponent) return;
			curComponent.database.mockData = mockConfig;
		},
		/**
		 * @desc: 注入外部参数
		 * @param {Object} paramsConfig
		 */
		setDatabaseOutParams({ curComponent }, toggle) {
			curComponent.database.userOutParams = toggle;
		},
		/**
		 * @desc: 设置关联关系
		 * @param {Object} relationConfig
		 */
		setDatabaseMapping({ curComponent }, mapping) {
			if (!curComponent) return;
			curComponent.database.mapping = mapping;
			if (mapping === 'relation') {
				curComponent.database.requestType = 'dataInfo';
			}
			store.commit('modifyComponent', {
				component: curComponent,
				containerId: null,
				isModify: true
			});
		},
		/**
		 * @desc: 设置关联关系
		 * @param {Object} relationConfig
		 */
		setDatabaseRelation({ curComponent }, relationData) {
			if (!curComponent) return;
			curComponent.database.relationData = relationData;
		},
		/**
		 * @desc: 移除当前对象的关联关系
		 */
		removeDatabaseRelation({ curComponent }) {
			if (!curComponent || !curComponent.database) return;
			curComponent.database.relationData = null;
		}
	},
	actions: {
		getDatabaseField(context, { needField, objData }) {
			context.commit('setDatabaseObject', objData);
			if (!needField) return;
			// 获取字段
			const loading = Loading.service();
			getFields({
				object_uuid: objData.uuid,
				size: 1000,
				page: 1
			})
				.then((res) => {
					if (res.status === 200 && res.data.code === 200 && res.data.data) {
						const data = res.data.data;
						context.commit('setFieldList', {
							fieldList: (data.data || []).map(ele => {
								return {
									id: ele.id || '',
									name: ele.name || '',
									code: ele.code || '',
									uuid: ele.uuid || '',
									// 字段配置
									viewConfig: {
										name: ele.name || '',
										code: ele.code || '',
										uuid: ele.uuid || '',
										showInFilter: false, // 是否显示在过滤组件
										type: 'input', // 表单类型
										optionOrign: 'fixed', // 值来源 tabs 下拉框  radio 
										isRadius: false,
										options: [
											{
												label: '',
												value: '',
												widthStatistics: false,
												defaultSelect: false,
												statisticsConfig: [
													{
														key: 'count', // 统计字段
														view_uuid: '', // 视图
														field_uuid: '', // 字段UUID 可以不填
														aggregate: 4 // 统计函数
													}
												]
											}
										], // 备选项
										optionConfig: {
											objectUUID: '',
											viewUUID: '',
											labelCode: '',
											valueCode: '',
											widthStatistics: false,
											defaultSelect: false,
											popAll: false,
											statisticsConfig: [
												{
													key: 'count', // 统计字段
													view_uuid: '', // 视图
													field_uuid: '', // 字段UUID 可以不填
													aggregate: 4 // 统计函数
												}
											]
										},
										dateType: '', // 时间类型
										dateFormat: '', //  时间格式化类型
										ruleType: '', // 逻辑判断(后端字段)
										defaultDate: '', // 默认时间
									}
								}
							})
						});
					}
					loading.close();
				})
				.catch((err) => {
					loading.close();
					console.log(err);
				});
		},
		/**
		 * @desc: 获取对象列表
		 * @param {Object} params
		 */
		getObjectList(context, params) {
			getObjects(params)
				.then((res) => {
					if (res && res.data && res.data.data) {
						const data = res.data.data;
						context.commit('setObjectList', {
							objectList: data.data || [],
							pager: {
								page: data.current_page,
								size: data.per_page,
								total: data.total
							}
						});
					}
				})
				.catch((err) => {
					console.log(err);
				});
		},
		/**
		 * @desc: 获取数据对象对应字段
		 * @param {Object} { component } 组件
		 */
		getFieldList(context, { component }) {
			if (!component || !component.database || !component.database.viewData) return;
			const objectData = component.database.objectData;
			const loading = Loading.service();
			getFields({
				object_uuid: objectData.uuid,
				size: 1000,
				page: 1
			})
				.then((res) => {
					if (res.status === 200 && res.data.code === 200 && res.data.data) {
						const data = res.data.data;
						context.commit('setFieldList', {
							fieldList: data.data || []
						});
					}
					loading.close();
				})
				.catch((err) => {
					loading.close();
					console.log(err);
				});
		}
	},
	getter: {
		/**
		 * @desc: 获取对象列表
		 */
		getObjectList(state) {
			if (state.objectList.length) {
				return state.objectList;
			}
			const objectList = sessionStorage.getItem('objectList');
			if (objectList) {
				return JSON.parse(state.objectList);
			}
			return null;
		}
	}
};
