<!--
 * @LastEditTime: 2022-10-27 16:59:36
 * @Description: 对象选择器
 * @FilePath: /dataview-next/src/components/metaSelect/ObjectSelect.vue
 * @Date: 2021-12-20 20:20:46
 * @Author: lisushuang
 * @LastEditors: zx
-->
<template>
  <div>
    <el-tag
      disable-transitions
      v-show="showTag && !showSelect"
      style="cursor: pointer; height: 32px; line-height: 32px"
      @click="
        showSelect = true;
        nowNumber = 20;
        nowSelectData = selectData.slice(0, 20);
        $nextTick(() => $refs.obselect.focus());
      "
    >
      {{ showName }}
    </el-tag>

    <el-select
      v-if="!showTag || showSelect"
      :multiple="multiple"
      ref="obselect"
      filterable
      :filter-method="customFilter"
      allow-create
      v-model="selectValue"
      style="width: 100%"
      v-loading="selectLoading"
      default-first-option
      @focus="onSelectFoucus = true"
      element-loading-spinner="el-icon-loading"
      @blur="SelectInstanceBlur()"
      v-el-select-loadmore="
        () => {
          nowNumber = nowNumber + 20;
          getNowSelectData();
        }
      "
    >
      <el-option
        v-for="(item) in nowSelectData"
        :key="item.uuid"
        :label="item && item.enabled == 2 ? '[聚合] ' + item.name : item.name"
        :value="item.uuid"
        :disabled="item && item.enabled == 2"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
import objects from "@/api/objects";
import collect from "collect.js";
import { Tag } from "element-ui";

export default {
  name: "ObjectSelect",
  components:{'el-tag':Tag},
  props: {
    value: {
      type: String,
      default: "",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    effect: {
      type: [Array , Object],
      default() {
        return {};
      },
    },
    objectsData: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  watch: {
    object_uuid() {
      this.getSelectData();
    },
    effect(val, oldVal) {
      if (val.value == oldVal.value) {
        return;
      }
      this.getSelectData();
    },
    value(val, oldVal) {
      if (val && !oldVal && !this.selectValue) {
        this.selectValue = val;
      }
    },
    selectValue() {
      this.$emit("input", this.selectValue);
      this.$nextTick(() => {
        this.onSelectFoucus = false;
        if (this.$refs.obselect) {
          this.$refs.obselect.blur();
          this.nowNumber = 20;
          this.searchValue = '';
        }
        this.$emit("on-change", this.selectValue);
      });
    },
  },
  computed: {
    showName() {
      if (!this.selectData.length) {
        return "数据加载中...";
      }
      if (!this.selectValue) {
        return "请选择数据对象";
      }
      let find = collect(this.selectData)
        .where("uuid", this.selectValue)
        .first();
      if (!find) return this.selectValue;
      return find.name;
    },
    /**
     * @description: 实际展示的选择数据，在没有focus时只显示唯一的那一条
     * @param {*}
     * @return {*}
     */
    realSelectData() {
      if (this.onSelectFoucus) {
        return this.selectData;
      } else {
        let one = collect(this.selectData).where("uuid", this.value).first();
        if (one) return [one];
        return [];
      }
    },
  },
  data() {
    return {
      showTag: true,
      selectData: [],
      selectValue: "",
      selectLoading: true,
      onSelectFoucus: false,
      showSelect: false,
      nowNumber: 20,
      nowSelectData: [],
      searchValue:''
    };
  },
  methods: {
    SelectInstanceBlur() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.showSelect = false;
          this.nowNumber = 20;
          this.searchValue = '';
          this.nowSelectData = this.selectData.slice(0, this.nowNumber);
        }, 100);
      });
    },

    customFilter(value){
      this.searchValue = value
      this.nowNumber = 20
      this.getNowSelectData()
    },

    getNowSelectData(){
      let value = this.searchValue
      if(!value) this.nowSelectData = this.selectData.slice(0,this.nowNumber);
      this.nowSelectData = this.selectData.filter(item => {
        return item.name.indexOf(value) > -1;
      }).slice(0,this.nowNumber);
    },


    getSelectData() {
      if (this.objectsData.length) {
        this.selectLoading = false;
        this.selectData = this.objectsData;
        return;
      }
      this.selectLoading = true;

      // 重新请求视图列表数据
      objects
        .getAll(1, 10000, "", 0)
        .then((res) => {
          this.selectLoading = false;
          if (res.data.code == 200) {
            this.selectData = res.data.data.data;
          } else {
            this.selectData = [];
          }
        })
        .catch(() => {
          this.selectLoading = false;
          this.selectData = [];
        });
    },
  },
  directives: {
    "el-select-loadmore": (el, binding) => {
      // 获取element-ui定义好的scroll盒子
      const SELECTWRAP_DOM = el.querySelector(
        ".el-select-dropdown .el-select-dropdown__wrap"
      );
      if (SELECTWRAP_DOM) {
        SELECTWRAP_DOM.addEventListener("scroll", function () {
          /**
           * scrollHeight 获取元素内容高度(只读)
           * scrollTop 获取或者设置元素的偏移值,
           *  常用于:计算滚动条的位置, 当一个元素的容器没有产生垂直方向的滚动条, 那它的scrollTop的值默认为0.
           * clientHeight 读取元素的可见高度(只读)
           * 如果元素滚动到底, 下面等式返回true, 没有则返回false:
           * ele.scrollHeight - ele.scrollTop === ele.clientHeight;
           */
          const condition =
            this.scrollHeight - this.scrollTop <= this.clientHeight + 10;
          if (condition && this.clientHeight > 200) binding.value();
        });
      }
    },
  },
  mounted() {
    this.getSelectData();
    this.selectValue = this.value;
  },
};
</script>
