<!--
 * @Description: 进度内容展示
 * @components:  swiper, swiperSlide,
 * @componentDesc: 轮播  轮播滑动
 * @props: prop参数
-->

<template>
	<div class="gantt-milepost">
		<div class="flex-between">
			<p class="second-title">
				{{ statusConfig.showTitle ? title : '' }}
			</p>
			<div style="display: flex">
				<p style="display: flex; align-items: center">
					<span class="small-cicle" /><span style="font-size: 14px">正常</span>
				</p>
				<p style="display: flex; align-items: center; margin: 0 10px">
					<span
						class="small-cicle"
						style="background: #ff9300"
					/><span style="font-size: 14px">超期</span>
				</p>
				<p style="display: flex; align-items: center">
					<span
						class="small-cicle"
						style="background: #a1a5ad"
					/><span style="font-size: 14px">其他</span>
				</p>
			</div>
		</div>
		<div class="line" />
		<div
			class="no-scroll-bar w-full relative"
			style="height: 70%; overflow-x: auto"
			@mousedown="mousedown($event)"
		>
			<div class="begin-line" />
			<div
				v-if="blockData.length != 0"
				class="h-full inner-cont"
				:class="is_moving ? 'pointer-none' : ''"
				:style="{ transform: `translate(${totalDistance}px, 0px)` }"
			>
				<div
					v-for="(child, index) in blockData"
					:key="index"
					:data="child.id"
					style="
                    width: 150px;
                    height: calc(100% - 8px);
                    display: inline-block;
                    margin: 10px;
                    vertical-align: top;
                "
					:style="{ transform: `translate(${distance}px, 0px)` }"
				>
					<div
						class="block no-user-select"
						:style="getStyle(child)"
					>
						<div
							class="block-before"
							:style="getStyleBefore(child)"
						/>
						<div v-if="child.today_date">
							<p
								class="font-style text-center"
								style="margin-top: 11px"
							>
								{{ child.name }}
							</p>
							<p
								class="font-style text-center"
								style="margin-bottom: 11px"
							>
								{{ child.today_date }}
							</p>
							<div class="inline">
								<div
									class="inline-cicle"
									style="background: #a1a5ad"
								/>
							</div>
						</div>
						<div v-else>
							<p class="font-style">
								{{ child.name }}
							</p>
							<p
								class="font-style"
								style="opacity: 0.6"
							>
								<span>计划：</span><span v-html="
                    child.plan_date &&
                      child.plan_date != '0000-00-00 00:00:00'
                      ? child.plan_date.slice(0, 10)
                      : '-'
                  " />
							</p>
							<p
								class="font-style"
								style="opacity: 0.6"
							>
								<span>实际：</span><span v-html="
                    child.actual_date &&
                      child.actual_date !=
                      '0000-00-00 00:00:00'
                      ? child.actual_date.slice(0, 10)
                      : '-'
                  " />
							</p>

							<!-- 延后 -->
							<div
								v-if="
                  child.actual_date !=
                    '0000-00-00 00:00:00' &&
                    child.actual_date > child.plan_date
                "
								class="inline"
								style="background: rgba(255, 147, 0, 0.5)"
							>
								<div
									class="inline-cicle"
									style="background: #ff9300"
								/>
							</div>
							<!-- 正常 -->
							<div
								v-else-if="
                  !child.actual_date ||
                    child.actual_date == '0000-00-00 00:00:00'
                "
								class="inline"
								style="background: rgb(161, 165, 173, 0.5)"
							>
								<div
									class="inline-cicle"
									style="background: #a1a5ad"
								/>
							</div>
							<!-- 进行中 -->
							<div
								v-else-if="
                  child.actual_date !=
                    '0000-00-00 00:00:00' &&
                    (child.actual_date < child.plan_date ||
                      child.actual_date.slice(0, 10) ==
                      child.plan_date.slice(0, 10))
                "
								class="inline"
								style="background: rgb(46, 191, 118, 0.5)"
							>
								<div
									class="inline-cicle"
									style="background: #2ebf76"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { dataInterface } from '@/apis/data';
import eventBus from '@/plugins/eventBus';
import CustomComponentMixin from '@/custom-component/mixins/CustomComponentMixin.js';

export default {
	props: {
		element: {
			type: Object,
			required: true,
			default: () => {}
		}
	},
  mixins: [CustomComponentMixin],
	components: {},
	data() {
		return {
			blockData: [],
			is_moving: false,
			distance: 0, // 拖动距离
			sb_bkx: 0,
			totalDistance: 0,
			title: '里程碑节点',
			data: [],
			loading: false
		};
	},
	mounted() {
		this.getData();
		const databaseTrigger = {
			[this.element.id]: config => {
				if (!config || !this.paramsConfig.length || !this.paramsConfig.find(ele => ele.componentId === config.componentId)) {
					return;
				}
				this.getData();
			}
		}
    eventBus.$on('databaseTrigger', databaseTrigger[this.element.id]);
	},
	computed: {
    // 组件属性
		statusConfig() {
			return this.element?.statusConfig;
		},
    // 参数配置
    paramsConfig() {
      return this.element?.paramsConfig || [];
    }
	},
	methods: {
    /**
     * @desc: 获取数据
     */
		getData() {
      const { search = [], param = {}, canPost } = this.initParams(this.paramsConfig || []);
      if (!canPost) return false;
			const reqData = {
				object_uuid: 'object61b2a6c0edf0c',
				view_uuid: 'view61b2a79f99b39',
				__method_name__: 'dataList',
        ...param,
        search,
				transcode: 0
			};
			this.loading = true;
			dataInterface(reqData)
				.then((res) => {
					if (res.data.code === 200 && res.data.data && res.data.data.data) {
						this.data = res.data.data.data;
						this.init();
					}
				})
				.finally(() => {
					this.loading = false;
				});
		},
		init() {
			const arr = this.data;
			const now = new Date();
			var year = now.getFullYear();
			var month = now.getMonth() + 1;
			var date = now.getDate();
			arr.push({
				name: '今日',
				today_date: year + '-' + month + '-' + date,
				plan_date: year + '-' + month + '-' + date
			});
			arr.sort(function (a, b) {
				return b.plan_date > a.plan_date ? 1 : -1;
			});
			this.blockData = arr;
		},
		mousedown(event) {
			var startx = event.x;
			this.sb_bkx = startx - event.target.offsetLeft;
			this.is_moving = true;
			document.onmousemove = (ev) => {
				var endx = ev.x - this.sb_bkx;
				if (this.is_moving) {
					if (
						this.totalDistance + endx > 0 ||
						Math.abs(this.totalDistance + endx) > this.blockData.length * 170
					) {
						// 只能拖动指定长度
						this.totalDistance = 0;
						this.distance = 0;
					} else {
						this.distance = endx;
					}
				}
			};
			// eslint-disable-next-line
			document.onmouseup = (ev) => {
				const mo = Math.floor(Math.abs(this.distance) / 170) + 1;
				if (mo < this.blockData.length) {
					this.totalDistance = this.totalDistance + this.distance;
					this.distance = 0;
				}
				this.is_moving = false;
			};
		},
		/**
		 * @desc: 获取样式
		 * @param: item 需要获取样式的数据
		 * @return: null
		 */
		getStyle(item) {
			if (item.today_date != undefined) {
				return 'background: transparent;';
			} else {
				if (item.actual_date && item.actual_date != '0000-00-00 00:00:00') {
					if (item.actual_date > item.plan_date) {
						return 'background:rgba(255,147,0,0.5)';
					} else {
						return 'background:rgba(46,191,118,0.5)';
					}
				} else {
					return 'background:rgba(125,129,138,0.5)';
				}
			}
		},
		/**
		 * @desc: 获取样式
		 * @param: 需要获取样式的数据
		 * @return: null
		 */
		getStyleBefore(item) {
			if (item.today_date != undefined) {
				return 'background: #fff;';
			} else {
				if (item.actual_date && item.actual_date != '0000-00-00 00:00:00') {
					if (item.actual_date > item.plan_date) {
						return 'background:#ff9300';
					} else {
						return 'background:#2EBF76';
					}
				} else {
					return 'background:#7D818A';
				}
			}
		}
	},
};
</script>

<style lang="less" scoped>
.gantt-milepost {
	height: 155px;
	padding: 16px;
	padding-bottom: 0;
	box-sizing: border-box;
	.no-scroll-bar::-webkit-scrollbar {
		display: none; /* Chrome Safari */
	}
	.begin-line {
		width: 4px;
		height: 102px;
		position: absolute;
		background-color: #409eff;
		left: 0;
		z-index: 100;
	}
	.inner-cont {
		width: auto;
		display: block;
		word-break: keep-all;
		text-overflow: ellipsis;
		text-align: left;
		white-space: nowrap;
		transform: translate(0px, 0px);
	}
	.block {
		width: 140px;
		height: 70%;
		background: rgb(234, 248, 241);
		position: relative;
		padding: 4px;
		cursor: pointer;
	}

	.block-before {
		position: absolute;
		content: '';
		width: 100%;
		height: 4px;
		opacity: 1;
		background: #2ebf76;
		top: 0;
		left: 0;
	}

	.small-cicle {
		display: inline-block;
		width: 12px;
		height: 12px;
		opacity: 1;
		background: #2ebf76;
		border-radius: 50%;
		margin-right: 5px;
	}

	.second-title {
		font-size: 16px;
		font-weight: 600;
		line-height: 24px;
	}

	.flex-between {
		display: flex;
		justify-content: space-between;
		height: 24px;
	}

	.line {
		height: 1px;
		width: 100%;
		background: #eaebed;
		margin-top: 8px;
	}
	.pointer-none {
		pointer-events: none;
	}
	.inline {
		width: 170px;
		height: 4px;
		opacity: 1;
		background: rgba(46, 191, 118, 0.5);
		// border-radius: 2px;
		// position: relative;
		position: absolute;
		top: 100%;
		left: -10px;
		// margin: 5px 0;
		margin-top: 10px;

		.inline-cicle {
			position: absolute;
			width: 8px;
			height: 8px;
			opacity: 1;
			// background: #2ebf76;
			// border: 2px solid #2ebf76;
			border-radius: 50%;
			left: calc(50% - 4px);
			top: -2px;
			z-index: 1000;
		}
	}
	.font-style {
		line-height: 22px;
		font-size: 14px;
		font-weight: 600;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		text-align: left;
	}
	.text-center {
		text-align: center;
	}
}
</style>
