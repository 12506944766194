<!--
 * @Author: your name
 * @Date: 2021-12-02 15:32:01
 * @LastEditTime: 2023-01-04 15:44:08
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \nanhu-micro\src\views\components\bindModel\index.vue
-->
<template>
  <div class="gantt-box">
		<el-checkbox v-if="ganttConfig?.checkbox" class="check-all" @change="checkAll" v-model="checkedAll">全选</el-checkbox>
		<div
			v-if="tasks.data&&tasks.data.length!=0"
			:id="ganttId"
			ref="gantt"
			class="gantt gantt-cont"
		/>
		<el-empty v-else description="暂无数据" />
	</div>
</template>

<script>
/*eslint-disable */
import './dhtmlx-gantt/dhtmlxgantt.js'
import './dhtmlx-gantt/api.js'
import './dhtmlx-gantt/ext/dhtmlxgantt_fullscreen.js'
import './dhtmlx-gantt/ext/dhtmlxgantt_tooltip.js'
import './dhtmlx-gantt/ext/dhtmlxgantt_multiselect.js'
import './dhtmlx-gantt/ext/dhtmlxgantt_auto_scheduling.js'
import './dhtmlx-gantt/ext/dhtmlxgantt_drag_timeline.js'
import './dhtmlx-gantt/ext/dhtmlxgantt_undo.js'
import './dhtmlx-gantt/ext/dhtmlxgantt_critical_path.js'
import './dhtmlx-gantt/ext/dhtmlxgantt_overlay.js'
import './dhtmlx-gantt/ext/dhtmlxgantt_marker.js'
import './dhtmlx-gantt/ext/dhtmlxgantt_grouping.js'
import './dhtmlx-gantt/ext/Chart.min.js'
import './dhtmlx-gantt/locale/locale_cn.js'
import { Empty } from 'element-ui';
import { actions, cascadeAction, singularAction } from './dhtmlx-gantt/ganttObj'
import ganttMixin from './dhtmlx-gantt/ganttMixins'
import { dataInterface } from '@/apis/data/index';
import { mapState } from 'vuex';
import eventBus from '@/plugins/eventBus';
import { initParams } from '@/utils/tools';
export default {
  name: 'CommonGantt',
	mixins: [ganttMixin],
	props: {
    element: {
      type: Object,
      required: true,
      default: () => {}
    },
		// 是否为预览
		isPreview: {
			type: Boolean,
			required: false,
			default: true
		},
  },
  components: {
		'el-empty': Empty,
	},
	computed: {
		...mapState([
			'componentData',
			'_PageCustomStatus',
			'_APPCustomStatus'
		]),
		objectUuid(){
			return this.element?.database?.objectData?.uuid || '';
		},
		viewUuid(){
			return this.element?.database?.viewData?.uuid || '';
		},
		paramsConfig() {
			if (!this.element || !this.element.database || !this.element.database.paramsConfig) return [];
			return this.element.database.paramsConfig;
		},
		requestType(){
			return this.element.database.requestType || "dataList";
		},
		//显示字段
		fieldConfig(){
			return this.element.fieldConfig || []
		},
	},	
	data() {
		return {
			tasks:{
				data:[]
			},
      hackReset: false, // 强制刷新组件
      filterValue: '',
      filterText: '',
      filterOptionType: '', // 分部分项
      localWeek: false,
      localMonth: false,
      localYear: false,
      isPlay: false,
      ganttId: '',
      taskId: '',
      showFilterView: false,
      zoomName: '月',
      isZoomFit: false,
      checkedTasks: [],
      viewFilter: [
        {
          value: 'all',
          label: '全部'
        },
        {
          value: 'wbs',
          label: '仅WBS'
        },
        {
          value: 'gantt',
          label: '仅甘特图'
        }
      ],
      todayMarker: null,
      testName: '',
      loading: false,
      timer: null, // 播放的计时器
      optionName: '',
      checkedField: ['wbs', 'name', 'add', 'options'],
      rowInfo: null, // 点击已绑模型的数据
      bindModelShow: false,
      scaleData: null, // 缩放后的数据
      // 以下按钮选中的状态
      contrastMode: false, // 进度对比
      overLineMode: false, // 前锋线模式
      editMode: false, // 编辑模式
			modelUse:1,//新建1 拆除2
			min_date:'',//最小日期
			max_date:'',//最大的日期
			showedTasksId:[],//已经显示播放或拖拽显示完的任务id
			searchDate:'',
			//前锋线拖拽相关
			overLineData:new Date(),
			isGanttInited:false,//是否已经初始化了gantt
      clicktimer: null,
			checkedAll:false, //全选
    }
	},
	methods: {
		//全选计划
		checkAll(bol){
			gantt.eachTask((task) => {
				task.check = bol;
				gantt.updateTask(task.id, task)
			})
			gantt.render()
			if(bol){
				this.triggerEvents('checkTask',this.tasks.data);
				console.log(this.tasks.data,'勾选数据');
			} else {
				this.triggerEvents('checkTask',this.tasks.data);
				console.log([],'勾选数据');
			}
		},
		//查找子集是否绑定模型
		childBindModel(data){ 
			let modelArr=[]
			if(data?.length){
				data.forEach((item)=>{
					if(item.model_info && item.model_info != null &&item.model_info.length !== 0){
						modelArr.push(item.model_info)
					}
					if(this.isActual){
						if(item.children){
							this.childBindModel(item.children)
						}
					} else {
						if(item.childrenMain){
							this.childBindModel(item.childrenMain)
						}
					}
					
				})
					
			}
			return modelArr
		},
		//改变前锋线时间
		changeOverLineData(){
			if(this.overLineData==null){
				gantt.deleteMarker(this.todayMarker);
				this.overLineMode = false
        gantt.config.readonly = false
        gantt.ext.overlay.hideOverlay(this.frontLine)
        gantt.$root.classList.remove('overlay_visible')
			}else{
				gantt.deleteMarker(this.todayMarker);
				this.todayMarker = gantt.addMarker({
					start_date: this.overLineData,
					css: 'today',
					text: this.isOverLineMode?'前锋线':'今天',
				})
				this.overLineMode = true
        gantt.config.readonly = true
        gantt.ext.overlay.showOverlay(this.frontLine)
        gantt.$root.classList.add('overlay_visible')
				
			}
		},
    // 清除gantt
    clearGantt () {
      this.onReset()
      this.filterOptionType = ''
      gantt.clearAll()
    },
    // 筛选分部分项
    handleChange (data) {
			if(this.overLineMode&&data.first==undefined){
				this.toggleOverlay()
			}
      this.$refs.dropdown.visible = false
      this.optionName = data.value
      this.filterOptionType = data.code
      gantt.render()
    },
    // 重置数据
    onReset (type) {
			gantt.config.show_chart = true;
			gantt.config.show_grid = true;
      this.filterOptionType = ''
      this.checkedTasks = []
      this.optionName = ''
			this.modelUse=1
			this.searchDate=''
			this.showedTasksId = []
      gantt.eachTask((task) => {
        task.check = false
      })
      this.isPlay = false
      this.editMode = false
      this.overLineMode = false
      this.contrastMode = false
      this.initTasks()
			this.$nextTick(() => {
				this.filterDate()
			})
      clearInterval(this.timer)
			this.showFilterView = false
      this.filterValue = ['', '']
      this.filterText = ''
      this.localWeek = false
      this.localMonth = false
      this.localYear = false
      gantt.render()
    },
    // 隐藏勾选得得task
    onHideTask () {
      // eslint-disable-next-line no-array-constructor
			this.checkedTasks = new Array()
			gantt.eachTask((task) => {
				if (task.check) {
					this.checkedTasks.push(
						JSON.parse(JSON.stringify(task))
					)
				}
			})
			if (this.checkedTasks?.length < 1) {
				return this.$message.warning('请勾选任务')
			}
			this.checkedTasks.forEach((item) => {
				if (item && item.id) {
					gantt.deleteTask(item.id)
				}
			})
    },
    onSrcollToGantt (task) {
      // gantt滚动到相应位置
			if(task.task_id){
				const position = gantt.getTaskPosition(gantt.getTask(task.task_id))
				if (position) {
					gantt.scrollTo(position.left, position.top)
				}
			}
      
    },
    downFile () {
      window.location.href = baseURL + '/wordtemp/4.0总进度计划详情wbs导出模板.xlsx'
    },
    modelAction (taskId) {
      const task = gantt.getTask(taskId)
      this.rowInfo = task
      if (this.isPlayAble) {
        // 实际进度定位本模型的构建
        if (this.modelInfo.length > 0) {
          const data = JSON.parse(
            JSON.stringify(gantt.getTask(taskId))
          )
					data.model_use=this.modelUse
          data.data_id = data.id
          data.original_id = data.id
          data.object_uuid = 'object61a9c36ed3431'
          data.__method_name__ = 'updateData'
          data.model_info = this.modelInfo
          dataInterface(data).then((res) => {
            if (res.code === 200) {
              const task = gantt.getTask(taskId)
              task.model_info = this.modelInfo
							if (task.model_info?.length) {
								let n = 0
								task.model_info.forEach((item) => {
									n += item.dbids.length
								})
								task.componentNum = n
							} else {
								task.componentNum = 0
							}

              gantt.render()
            }
          })
        } else {
          this.$message.warning('请先选中构件')
        }
      } else {
        this.bindModelShow = true
      }
    },
    //仅显示table
    onOnlyTable () {
			gantt.config.show_chart = false;
			gantt.config.show_grid = true;
      gantt.render()
    },
		//仅显示gantt
    onOnlyGantt () {
      gantt.config.show_grid = false;
			gantt.config.show_chart = true;
      gantt.render()
    },
    actionTasks (actionName) {
      const action = actions[actionName]
      if (!action) return
      if (singularAction[actionName]) {
        action()
        return
      }
      gantt.batchUpdate(function () {
        const indexes = {}
        const siblings = {}
        gantt.eachSelectedTask(function (task_id) {
          gantt.ext.undo.saveState(task_id, 'task')
          indexes[task_id] = gantt.getTaskIndex(task_id)
          siblings[task_id] = {
            first: null
          }

          let currentId = task_id
          while (
            gantt.isTaskExists(gantt.getPrevSibling(currentId)) &&
                        gantt.isSelectedTask(gantt.getPrevSibling(currentId))
          ) {
            currentId = gantt.getPrevSibling(currentId)
          }
          siblings[task_id].first = currentId
        })
        const updated = {}
        gantt.eachSelectedTask(function (task_id) {
          if (cascadeAction[actionName]) {
            if (!updated[gantt.getParent(task_id)]) {
              const updated_id = action(
                task_id,
                indexes,
                siblings
              )
              updated[updated_id] = true
            } else {
              updated[task_id] = true
            }
          } else {
            action(task_id, indexes)
          }
        })
      })
    },
		//过滤缩放后的时间数据,
		filterDate(){
			this.scaleData = JSON.parse(JSON.stringify(gantt.getScale()))
				if(this.scaleData && this.scaleData.trace_x){
					const halfIndex = Math.ceil(this.scaleData.trace_x.length/2)
					let newTraceX=[]
					this.scaleData.trace_x.forEach((item, i) => {
						const itemDate = item.substring(0, 10)
						if (i < halfIndex) {
							const nextDate = this.scaleData.trace_x[i + 1].substring(0, 10)
							if (itemDate >= this.min_date) {
								newTraceX.push({ date: item.substring(0, 10) })
								
							}
							else if((itemDate <= this.min_date && this.min_date <= nextDate)){
								newTraceX.push({ date: item.substring(0, 10) })
							}
						} else {
							const lastDate = this.scaleData.trace_x[i - 1].substring(0, 10)
							if (this.max_date >= itemDate) {
								newTraceX.push({ date: item.substring(0, 10) })
							}else if(this.max_date>=lastDate && this.max_date <= itemDate) {
								newTraceX.push({ date: item.substring(0, 10) })
							}
						}
					})
					this.scaleData.trace_x=newTraceX
				}
		},
    onZoomOut () {
			if (this.isOverLineMode || this.overLineMode) {
				gantt.config.readonly = false
        gantt.ext.overlay.hideOverlay(this.frontLine)
        gantt.$root.classList.remove('overlay_visible')
				setTimeout(() => {
					this.toggleOverlay()
				},500)
			}
      gantt.ext.zoom.zoomOut()
			this.showedTasksId = []
			this.$emit('init-model')
			if(this.isPlay){
				clearInterval(this.timer)
				this.isPlay=false
			}
      this.$nextTick(() => {
				this.filterDate()
      })
      gantt.render()
    },
    onZoomIn () {
			if (this.isOverLineMode || this.overLineMode) {
				gantt.config.readonly = false
        gantt.ext.overlay.hideOverlay(this.frontLine)
        gantt.$root.classList.remove('overlay_visible')
				setTimeout(() => {
					this.toggleOverlay()
				},500)
			}
      gantt.ext.zoom.zoomIn()
			this.showedTasksId = []
			this.$emit('init-model')
			if(this.isPlay){
				clearInterval(this.timer)
				this.isPlay=false
			}
      this.$nextTick(() => {
				this.filterDate()
			})
      gantt.render()
    },
    onZoomFit () {
      this.isZoomFit = !this.isZoomFit
      gantt.config.readonly = false
      gantt.ext.overlay.hideOverlay(this.lineOverlay)
      gantt.ext.overlay.hideOverlay(this.frontLine)
      gantt.$root.classList.remove('overlay_visible')

      if (this.isZoomFit) {
        const project = gantt.getSubtaskDates()
        const areaWidth = gantt.$task.offsetWidth
        const scaleConfigs = [
          // minutes
          {
            scales: [
              {
                subscale_unit: 'minute',
                unit: 'hour',
                step: 1,
                format: '%H'
              },
              { unit: 'minute', step: 1, format: '%H:%i' }
            ]
          },
          // hours
          {
            scales: [
              {
                subscale_unit: 'hour',
                unit: 'day',
                step: 1,
                format: '%j %M'
              },
              { unit: 'hour', step: 1, format: '%H:%i' }
            ]
          },
          // days
          {
            scales: [
              {
                subscale_unit: 'day',
                unit: 'month',
                step: 1,
                format: '%F'
              },
              { unit: 'day', step: 1, format: '%j' }
            ]
          },
          // weeks
          {
            scales: [
              {
                subscale_unit: 'week',
                unit: 'month',
                step: 1,
                date: '%F'
              },
              {
                unit: 'week',
                step: 1,
                template: function (date) {
                  const dateToStr =
                                        gantt.date.date_to_str('%d %M')
                  const endDate = gantt.date.add(
                    gantt.date.add(date, 1, 'week'),
                    -1,
                    'day'
                  )
                  return (
                    dateToStr(date) +
                                        ' - ' +
                                        dateToStr(endDate)
                  )
                }
              }
            ]
          },
          // months
          {
            scales: [
              {
                subscale_unit: 'month',
                unit: 'year',
                step: 1,
                format: '%Y'
              },
              { unit: 'month', step: 1, format: '%M' }
            ]
          },
          // quarters
          {
            scales: [
              {
                subscale_unit: 'month',
                unit: 'year',
                step: 3,
                format: '%Y'
              },
              {
                unit: 'month',
                step: 3,
                template: function (date) {
                  const dateToStr =
                                        gantt.date.date_to_str('%M')
                  const endDate = gantt.date.add(
                    gantt.date.add(date, 3, 'month'),
                    -1,
                    'day'
                  )
                  return (dateToStr(date) +  ' - ' +   dateToStr(endDate))        
                }
              }
            ]
          },
          // years
          {
            scales: [
              {
                subscale_unit: 'year',
                unit: 'year',
                step: 1,
                date: '%Y'
              },
              {
                unit: 'year',
                step: 5,
                template: function (date) {
                  const dateToStr = gantt.date.date_to_str('%Y')
                  const endDate = gantt.date.add(gantt.date.add(date, 5, 'year'),-1, 'day')
                  return (
                    dateToStr(date) + ' - ' + dateToStr(endDate)
                  )
                }
              }
            ]
          },
          // decades
          {
            scales: [
              {
                subscale_unit: 'year',
                unit: 'year',
                step: 10,
                template: function (date) {
                  const dateToStr = gantt.date.date_to_str('%Y')
                  const endDate = gantt.date.add( gantt.date.add(date, 10, 'year'),-1, 'day')
                  return (dateToStr(date) +' - ' + dateToStr(endDate)
                  )
                }
              },
              {
                unit: 'year',
                step: 100,
                template: function (date) {
                  const dateToStr = gantt.date.date_to_str('%Y')
                  const endDate = gantt.date.add( gantt.date.add(date, 100, 'year'),-1,'day')
                  return (dateToStr(date) +' - ' + dateToStr(endDate)
                  )
                }
              }
            ]
          }
        ]
        for (let i = 0; i < scaleConfigs.length; i++) {
          const columnCount = this.getUnitsBetween(
            project.start_date || '',
            project.end_date,
            scaleConfigs[i].scales[0].subscale_unit,
            scaleConfigs[i].scales[0].step
          )
          if ( (columnCount + 2) * gantt.config.min_column_width <= areaWidth ) {
            break
          }
					if (i == scaleConfigs.length) {
						i--
					}
					this.applyConfig(scaleConfigs[i], project)
        	gantt.render()
        }
       
      } else {
        gantt.ext.zoom.setLevel(gantt.ext.zoom.getCurrentLevel())
        gantt.render()
      }
    },
    async initTasks () {
      if (this.tasks.data.length) {
        await this.initGantt(this.tasks)
        if (this.isOverLineMode) {
          // console.log('前锋线')
          this.overLineMode = true
          this.optionName = '分项工程'
					this.handleChange({code: "sectional_works",value: "分项工程",first: true})
          setTimeout(() => {
            this.toggleOverlay()
          }, 500)
        }
      }
    },
    // 对比
    toggleContrast (type) {
      if (gantt.ext.overlay.isOverlayVisible(this.lineOverlay)) {
        this.contrastMode = false
        gantt.config.readonly = false
        gantt.ext.overlay.hideOverlay(this.lineOverlay)
        gantt.$root.classList.remove('overlay_visible')
      } else {
        this.contrastMode = true
        gantt.config.readonly = true
        gantt.ext.overlay.showOverlay(this.lineOverlay)
        gantt.$root.classList.add('overlay_visible')
      }
    },
		//开启前锋线
		onShowFrontLine(){
			clearTimeout(this.timer)
      this.timer = setTimeout(() => {
				this.overLineMode = true
				gantt.config.readonly = true
				gantt.ext.overlay.showOverlay(this.frontLine)
				gantt.$root.classList.add('overlay_visible')
			},200)
		},
		//隐藏前锋线
		onHideFrontLine(){
			this.overLineMode = false
			gantt.config.readonly = false
			gantt.ext.overlay.hideOverlay(this.frontLine)
			gantt.$root.classList.remove('overlay_visible')
		},
		//获取前锋线的数据
    getMarkerTaskPoint () {
      const marker = gantt.getMarker(this.todayMarker)
      const markerEle = document.querySelector(
        '.gantt_marker_area > div > .' + marker.css
      )
      const markerPos = this.getItemStyle(markerEle)
			if(markerPos == undefined){
				this.$message.warning('暂无可展示的前锋线数据(无分项工程数据)！')
				 return []
			}
      const curRealPos = [[markerPos.left, markerPos.top]] // 当前采集点的task节点
      const taskBarCollect = document.querySelectorAll(
        '.gantt_bars_area > .gantt_task_line.fenxianggongcheng'
      )
      taskBarCollect.forEach((item) => {
				if(!item) return
        const itemPos = this.getItemStyle(item)
        const itemStartX = itemPos.left;
        const itemEndX = itemPos.left + itemPos.width;
        if(markerPos.left>=itemStartX && markerPos.left<=itemEndX){
					const todayEle = document.getElementsByClassName('today')[0].offsetLeft
					let taskId = item.getAttribute('task_id');
					let taskWidth = item.offsetWidth;
					let taskLeft = item.offsetLeft;
					let taskRight = item.offsetLeft + taskWidth;
					let taskDuration = gantt.getTask(taskId).duration;
					//获取滞后工期值
					let lagging=gantt.getTask(taskId).lagging
					let realPos = null
					if( lagging ){
						let n = ((taskWidth/taskDuration)*-lagging).toFixed(2)
						if(todayEle + Number(n) < taskLeft || todayEle + Number(n) > taskRight){
							if(Number(n) < 0){
								realPos = [
									taskLeft,
									itemPos.top + 12
								]
							}
							if(Number(n) >= 0){
								realPos = [
									taskRight,
									itemPos.top + 12
								]
							}
						}else{
							realPos = [
								todayEle + Number(n),
								itemPos.top  + 12
							]
						}
						curRealPos.push(realPos)
					} 
					
        }
      })
      return curRealPos
    },
    getItemStyle (element) {
			if(!element) return
      const { offsetTop, offsetLeft, clientWidth, clientHeight } = element
      return {
        top: offsetTop,
        left: offsetLeft,
        width: clientWidth,
        height: clientHeight
      }
    },
    checkChildren (id, check) {
      const task = gantt.getTask(id)
      // console.log(task,'task')
      task.check = check
      gantt.updateTask(id, task)
      gantt.render()
      if (task.children?.length) {
        task.children.forEach((item) => {
          this.checkChildren(item.id, task.check)
        })
      }
    },
    checkParents (id, type) {
      let bol = type
      const task = gantt.getTask(id)
      if (task?.children?.length) {
        let n = 0
        let l = 0
        task.children.forEach((item) => {
          const child = gantt.getTask(item.id)
          if (child.check == true) {
            if (!bol) {
              bol = true
            }
            n++
          }
          l++
        })
        if (l == task.children?.length) {
          if (n == task.children.length) {
            task.check = true
            gantt.updateTask(id, task)
            gantt.render()
            this.checkParents(task.parent, bol)
          } else {
            if (bol) {
              task.check = 'indeterminate'
            } else {
              task.check = false
            }
            gantt.updateTask(id, task)
            gantt.render()
            this.checkParents(task.parent, bol)
          }
        }
      }
    },
    getChildren (task) {
      task.children = new Array()
      gantt.getChildren(task.id).forEach((childId) => {
        const childTask = JSON.parse(
          JSON.stringify(gantt.getTask(childId))
        )
        if (childTask.check) {
          task.children.push(childTask)
          this.getChildren(childTask)
        } else {
          childTask.children = new Array()
        }
      })
    },
		onGanttAction(funName,id){
			let task = gantt.getTask(id)
			this.cancelBubble()
			this[funName](task)
		},
		triggerEvents(funName,data){
			const eventList = this.element.actionConfig[funName]?.eventList || [];
			if (eventList && eventList.length) {
				eventList.forEach((el) => {
					el.eventList.forEach(ele => {
						if (ele.key === 'click') {
							//resolveData参数传入
							this.$store.commit('modifyComponent', {
								component: {
									...this.element,
									resolveSelectData: data
								},
								containerId: null,
								isModify: true
							});
							ele.effects.forEach((effect) => {
								this.$store.commit('triggerEvents', {
									config: {
										...ele,
									...effect
									},
									element: this.element
								});
							});
							// 触发组件行为
							const { behaviors } = ele;
								behaviors.forEach(behavior => {
									this.$store.commit('triggerEvents', {
										config: {
											behavior,
											isBehavior: true
										},
										element: this.element
									});
								});
						}
					})
				});
			}		
		},
		//勾选任务
		checkTask (task) {
			let id = task.id
      // 点击事件触发2次
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        const task = gantt.getTask(id)
        task.check = !task.check
        gantt.updateTask(id, task)
        gantt.render()
        if (task.children?.length) {
          // 中间
          task.children.forEach((item) => {
            this.checkChildren(item.id, task.check)
          })
        }
        const taskParent = gantt.getTask(task.parent)
        if (taskParent) {
          this.checkParents(task.parent, task.check)
        }
				let arr = new Array()
        gantt.eachTask((task) => {
          if (task.check) {
            arr.push(
              JSON.parse(JSON.stringify(task))
            )
          }
        })
				this.triggerEvents('checkTask',arr)
				console.log(arr,'勾选数据')
      }, 200)
    },
		//点击任务
		clickTask(task){
			this.triggerEvents('clickTask',task)
			console.log(task,'点击任务')
		},
		//新增任务
		async addTask (task) {
			console.log(task,'新增任务')
      if (task.$new) {
				gantt.deleteTask(task.id)
				gantt.hideLightbox()
      }
      this.hackReset = false // 强制刷新组件
      this.$nextTick(() => {
        this.hackReset = true
      })
			this.triggerEvents('addTask',task)
    },
		//编辑任务
		editTask(task){
			console.log(task,'编辑任务')
			this.triggerEvents('editTask',task)
		},
		//任务详情
		detailTask(task){
			console.log(task,'任务详情')
			this.triggerEvents('detailTask',task)
		},
		//删除任务
		deleteTask (task) {
      this.$confirm('确认删除此任务？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
				gantt.deleteTask(task.id)
				this.hackReset = false // 强制刷新组件
        console.log(task,'删除任务')
				this.triggerEvents('deleteTask',task)
      })
    },
		//筛选名称
		onFilterName (data) {
			if(data.name){
				this.filterText = data.name
      	gantt.render()
			}
    },
		//筛选时间段
		onFilterDate(data){
			if(!data) return this.$message.warning('请选择时间段！')
			this.filterValue = [data.start_date?data.start_date:'', data.end_date?data.end_date:'']
			gantt.render()
		},
		//阻止任务点击事件穿透
		cancelBubble (e) {
			var evt = e ? e : window.event;
			if(evt.stopPropagation) { //W3C 
					evt.stopPropagation();
			} else { //IE      
					evt.cancelBubble = true;
			}
		},
		//格式化计划任务数据
		breakTree (arr)  {
      for (let i = 0; i < arr.length; i++) {
        const it = arr[i]
				it.start_date = '-';
				it.end_date = '-';
				if(this.element.ganttConfig?.startDateField){
					it[this.element.ganttConfig?.startDateField] = it[this.element.ganttConfig?.startDateField] ? it[this.element.ganttConfig?.startDateField].substring(0,10) : '-';
					it.start_date = it[this.element.ganttConfig?.startDateField];
				}
				if(this.element.ganttConfig?.endDateField){
					it[this.element.ganttConfig?.endDateField] = it[this.element.ganttConfig?.endDateField] ? it[this.element.ganttConfig?.endDateField].substring(0,10) : '-';
					it.end_date = it[this.element.ganttConfig?.endDateField];
				}
        if(this.element.ganttConfig?.bimField){
					it.selection = it[this.element.ganttConfig?.bimField] || [];
				}
        if(this.element.ganttConfig?.gisField){
					it.gisInfo = it[this.element.ganttConfig?.gisField];
				}
        if(this.element.ganttConfig?.taskColField){
					it.taskColor = it[this.element.ganttConfig?.taskColField];
				}
        this.tasks.data.push(it)
      }
		},
		getData(search = [], params = {}) {
      clearTimeout(this.clicktimer)
      this.clicktimer = setTimeout(() => {
        if(!this.objectUuid || !this.viewUuid) return 
        this.tasks.data = []
        dataInterface({
          object_uuid: this.element.database.objectData?.uuid,
          view_uuid: this.element.database.viewData?.uuid,
          __method_name__: this.requestType,
          search:search,
          ...params
        }).then(async(res) => {
          if (res.data.code === 200 && res.data.data) {
            this.metadata = res.data.metadata;
            this.$store.commit('modifyComponent', {
              component: {
                ...this.element,
                metadata:res.data.metadata
              },
              containerId: null,
              isModify: true
            });
            await this.breakTree(res.data.data)
            this.initTasks()
          }
        })
      },300)  
		}
	},
	mounted () {
    this.getData()
		// 监听行为
    const doComponentBehavior = {
      [this.element.id]: config => {
        const { component, list = [] } = config;
        let componentName = component.split('-')[0]
        if (!this.element.id.includes(componentName)) return false;
        list.forEach(ele => {
          const { behaviors, params } = ele;
          const { param = {}, canPost } = initParams(params, false, this.componentData, []);
          if (canPost) {
            // 调用行为方法
            behaviors.forEach(funName => {
              try {
                eval(this[funName])(param)
              } catch (err) {
                console.log(err);
              }
            });
          }
        });
      }
    }
		eventBus.$on('doComponentBehavior', doComponentBehavior[this.element.id])
    const databaseTrigger = {
      [this.element.id]: (data) => {
        // console.log('配置关联参数的容器才需要监听', data);
        const { isSearch = false } = data;
        if (!data || !this.paramsConfig.length || !this.paramsConfig.find(ele => ele.componentId === data.componentId)) {
          return;
        }
        // 配置时候触发请求
        if (data.componentId === this.element.id && data.isInit) {
          const { search = [], param = {}, canPost } = initParams(this.element?.database?.paramsConfig || [], this.isGroup, this.componentList || this.componentData, this.groupComponents);
          // console.log("配置时候触发请求-------", canPost, search, param);
          if (!canPost) return;
          if (isSearch) {
            this.pager.current_page = 1;
          }
          this.getData(search, param);
          return;
        }
        // 点击操作时候不更新数据
        if (data.noUpdate) return;
        const { paramsConfig } = this.element.database;
        if (!paramsConfig || !paramsConfig.length) {
          if (isSearch) {
            this.pager.current_page = 1;
          }
          this.getData();
          return;
        }
        // 以下步骤是为了避免有多个来源的search需要进行differ 避免检索结果错误情况
        let { search = [], param = {}, canPost } = initParams(this.element?.database?.paramsConfig || [], this.isGroup, this.componentList || this.componentData, this.groupComponents);
        // console.log("以下步骤是为了避免有多个来源的search需要进行differ-------", canPost, search, param);
        if (!canPost) return;
        let isTarget = false;
        paramsConfig.forEach((ele) => {
          if (ele.componentId === data.componentId) {
            isTarget = true;
          }
        });
        if (!isTarget && !data.isUpdate) return;
        this.param = param;
        this.getData(search, param);
      }
    }
    eventBus.$on('databaseTrigger', databaseTrigger[this.element.id]);
  },
	created() {
		window.onGanttAction = this.onGanttAction // 挂载事件在window上
		this.setColumns(this.element)
	},
	beforeDestroy() {
		if(this.timer) {
      clearInterval(this.timer); //关闭
		}
		gantt.clearAll() 
		gantt.destructor()
    if (!this.EDITOR_pageUUID) {
      removeEventBus(['databaseTrigger', 'doComponentBehavior'], this.element.id);
    }
  },
}
</script>
<style lang="less" scoped>
	@import "~./dhtmlx-gantt/skins/dhtmlxgantt_material.css";
	.gantt-box {
		width: 100%;
		height: 100%;
		position:relative;
		.check-all{
			position:absolute;
			top:8px;
			left:15px;
			z-index: 10;
		}
		.gantt-cont{
			width: 100%;
			height: 100%;
		}
	}   
</style>
