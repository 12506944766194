<!--
 * @Description: 按钮
 * @Author: luocheng
 * @Date: 2021-08-23 11:42:01
 * @LastEditors: Shiltin 18580045074@163.com
 * @LastEditTime: 2022-10-26 15:32:31
-->
<template>
  <van-button 
    :type="statusConfig.type" 
    class="common-button"
    :size="statusConfig.size"
    :plain="statusConfig.plain"
    :round="statusConfig.round"
    :circle="statusConfig.circle"
    :loading="statusConfig.loading"
    :disabled="statusConfig.disabled"
    :icon="statusConfig.icon"
    :autofocus="statusConfig.autofocus"
    :class="{
      'just-icon': !element.propValue
    }"
    @click.stop="buttonClick"
  >{{ element.propValue || '' }}</van-button>
</template>

<script>
import eventBus from '@/plugins/eventBus';
import { initParams, getComponentById, judgingEventLogic } from '@/utils/tools';
import { dataInterface } from '@/apis/data/index';
import { Button } from 'vant'
import 'vant/lib/index.css'
import STORE from '@/store';
import { Message } from 'element-ui';
export default {
  name: 'CommonButton',
  components: {
    'van-button': Button
  },
  props: {
    // 组件对象
    element: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  computed: {
		componentData() {
			return STORE.state['componentData']
		},
    // 组件配置
    statusConfig() {
      return this.element.statusConfig || {}
    }
  },
	data() {
		return {
			configList: []
		}
	},
	methods: {
		//同步进度计划模型
		onSyncPlanModel(data){
			let obj = {
				"__method_name__": "globalFunctionCall",
				"typeName": "PublicFunc",
				"type": "behavior",
				"funcName": "SpecialPurposeBehavior",
				"payload": {
						"function_name":"TaskToModelComponent"        
				},
				"schedule_id":data.schedule_id
			}
			dataInterface(obj).then((res) => {
				if(res.data.code === 200){
					console.log(res,'同步计划模型成功！')
				}
			}).catch(err => {
				console.log(err, '-----');
			});
		},
    buttonClick() {
      const item = this.element
			// 特殊：无须触发点击的组件
			const excludesEvents = ['CommonDateButton'];
			if (
				excludesEvents.includes(item.component) ||
				!item.events ||
				!item.events.click ||
				!Array.isArray(item.events.click.effects)
			) {
				return false
			}
			const click = item.events.click || {};
			const { pattern, effects = [], specialEventList = []} = click;
			if (!pattern) {
				const { actionType, linkType, queryList = [], linkUrl = '', pageId } = click;
				if (actionType === 'pageStatus') {
					// 修改页面状态
					this.updatePageStatus(click.stateMachine || []);
				} else if (actionType === 'jumpPage') {
					if (linkType === 'projectPage') {
						const query = {};
						queryList.forEach((queryItem) => {
							let component = getComponentById(this.componentData, queryItem.componentId);
							this.$set(
								query,
								queryItem.key,
								component?.resolveData[queryItem.feild]
							);
						});
						this.$router.push({
							path: pageId,
							query
						});
						return;
					} else if (linkType === 'outPage') {
						window.open(linkUrl);
					} else if (linkType === 'custom') {
						// 暂未处理
						// const customLink = getLinkByTemplate(linkTemplate, output)
						// window.open(customLink);
					} else if (linkType === 'customForm') {
						const params = {}
						if(click.customFormLink) {
							if(click.customFormLink && Array.isArray(click.customFormLink.params)) {
								click.customFormLink.params.map(param => {
									if (param.component) {
										let component = getComponentById(this.componentData, param.component);
										params[param.key] = component.resolveData[param.value]
									}
								})
							}
							const { object_uuid, view_uuid, detailData, editData, title, type } = click.customFormLink
							if ( !object_uuid || !view_uuid || !title ) {
								Message.error('表单参数配置缺失！');
								return
							}
							eventBus.$emit('openPage', {
								type,
								params, 
								object_uuid,
								view_uuid,
								detailData,
								editData,
								title
							});
						}
					} else if (click.linkType === 'pageH5') {
						const params = {}
						/* 跳转h5页面携带参数  用于列表组件  取当前点击数据的值  这里用不到 */
						// console.log(click.paramsList, this.configList, 'paramsList')
						// if (click.paramsList.length) {
						// 	click.paramsList.forEach(item => {
						// 		params[item.key] = item.uuid
						// 	});
						// }
						// console.log(params, 'params')
						this.$router.push({
							name: 'ModifyPage',
							query: {
								...params,
								pageUUID: click.page_uuid
							}
						});
						this.$router.go(0)
					}



				} else if (actionType === 'eeAction') {
					// 触发后端事件 暂未处理
					// console.log(ele, '0000001111111111', output);
					// this.doEEAction(element, output, type);
					// 搜集参数
				} else {
					effects.forEach((ele) => {
						let logicBoolean = true
						if (ele.logics.length) {
							logicBoolean = judgingEventLogic(ele.logics, this.componentData || []);
						}
						if (logicBoolean) {
							this.$store.commit('triggerEvents', {
								config: ele,
								element: item
							});
						}
					});
				}
			} else if (pattern === 'special') {
				specialEventList.forEach(ele => {
					const { actionType, linkType, queryList = [], linkUrl = '', pageId } = ele;
					if (actionType === 'pageStatus') {
						// 修改页面状态
						this.updatePageStatus(ele.stateMachine || []);
					}else if (actionType === 'jumpPage') {
					if (linkType === 'projectPage') {
						const query = {};
						queryList.forEach((queryItem) => {
							let component = getComponentById(this.componentData, queryItem.componentId);
							this.$set(
								query,
								queryItem.key,
								component?.resolveData[queryItem.feild]
							);
						});
						this.$router.push({
							path: pageId,
							query
						});
						return;
					} else if (linkType === 'outPage') {
						window.open(linkUrl);
					} else if (linkType === 'custom') {
						// 暂未处理
						// const customLink = getLinkByTemplate(linkTemplate, output)
						// window.open(customLink);
					}
				} else if (actionType === 'eeAction') {
					// 触发后端事件 暂未处理
					// console.log(ele, '0000001111111111', output);
					// this.doEEAction(element, output, type);
					// 搜集参数
				} else {
						ele.effects.forEach(effect => {
							let logicBoolean = true
							if (effect.logics.length) {
								logicBoolean = judgingEventLogic(effect.logics, this.componentData || []);
							}
							if (logicBoolean) {
								this.$store.commit('triggerEvents', {
									config: effect,
									element: item
								});
							}
						})
					}
				});
			}
		},
	},
	mounted() {
		//监听行为
		eventBus.$on('doComponentBehavior', config => {
			const { component, list = [] } = config;
			let componentName = component.split('-')[0]
			if (!this.element.id.includes(componentName)) return false;
			list.forEach(ele => {
				const { behaviors, params } = ele;
				const { param = {}, canPost } = initParams(params, false, this.componentData, []);
				if (canPost) {
					// 调用行为方法
					behaviors.forEach(funName => {
						try {
							eval(this[funName])(param)
						} catch (err) {
							console.log(err);
						}
					});
				}
			});
		})
	},
	unmounted() {
		eventBus.$off('doComponentBehavior');
	}
}
</script>
<style lang="less">
button {
  div {
    width: 100%;
  }
}
.el-button{
  &.just-icon{
    border: none;
    span{
      margin-left: 0!important;
    }
  }
}
</style>