<!--
    @name: FlowOperate
    @description：FlowOperate
    @author: ZengWei
    @date: 2021-10-27 12:06
-->
<template>
  <div class="flow-operate">
    <div class="operate-container" v-if="editData.btn && editData.btn.length !== 0">
      <div class="default-action" v-if="viewStatus === 'default'">
        <div class="switch-icon" @click.stop="handleApproval">
          <i class="iconfont iconjijia_xiangshang" />
        </div>
        <el-button type="primary" style="padding: 9px 41px" @click.stop="handleApproval">审批</el-button>
      </div>
      <div class="approval-action-warp" v-if="viewStatus === 'approval'">
        <div class="switch-icon" @click.stop="viewStatus = 'default'">
          <i class="iconfont iconjijia_xiangxia" />
        </div>
        <el-form ref="baseForm" label-position="top" class="approval-form" :model="content">
          <div class="approval-warp" v-if="editData.btn && editData.btn.length !== 0">
            <el-radio-group v-model="btnRadio" @change="btnChange">
              <template v-for="(item, index) in editData.btn">
                <el-radio
                  v-if="item.show"
                  :label="item.status"
                  :key="index">
                  {{ item.btn }}
                </el-radio>
              </template>
            </el-radio-group>
          </div>
          <div class="operate-content">
            <div v-if="buttonStatus > 0 && buttonStatus !== 4">
              <FlowPicker
                :data-id="dataId"
                :module="module"
                :turn="buttonStatus === 2 ? true : false"
                type="next"
                :flow-edit="flowEdit"
                @checked-users="dealWithPicker"
                @selected-step="getSelectStep"
              />
            </div>
            <div v-if="buttonStatus === -1">
              <el-form-item label="退回类型" required>
                <el-radio-group v-model="back_type">
                  <el-radio-button :label="1">退回发起人</el-radio-button>
                  <el-radio-button :label="2">退回上一步</el-radio-button>
                  <el-radio-button :label="3">步骤退回</el-radio-button>
                </el-radio-group>
              </el-form-item>
              <el-form-item v-if="back_type === 3" label="步骤选择" required>
                <el-select
                  v-model="back_step"
                  placeholder="请选择退回的步骤"
                  style="width: 100%">
                  <el-option
                    v-for="(item, index) in editData.doneStep"
                    :key="index"
                    :value="item.nodeId"
                    :label="item.name">
                    {{ item.name }}
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div style="margin-bottom: 18px" v-if="buttonStatus !== 0">
              <el-form-item label-width="0px" prop="remarks" :rules="validRule(1)">
                <el-input
                  v-model="content.remarks"
                  placeholder="请输入审批意见"
                  :rows="3"
                  type="textarea" />
              </el-form-item>
            </div>
            <el-form-item  v-if="buttonStatus !== 0" label-width="0px" prop="files" :rules="validRule(2)">
              <fileUpload
                ref="fileUpload"
                :files="content.files"
                @extra-files="extraFiles"
                style="padding: 0">
              </fileUpload>
            </el-form-item>
            <PcParser
              v-if="showOtherForm && buttonStatus !== 0"
              ref="otherForm"
              :form-data="curStepNode.extendFields">
            </PcParser>
          </div>
        </el-form>
        <div class="action-row">
          <div class="file-upload">
            <i class="iconfont iconbim_fujian" @click="handleFuJianClick" />
          </div>
          <el-button :loading="submitLoading" :type="buttonStatus === -1 ? 'danger' : 'primary'" style="padding: 9px 41px" @click="flowOperate">
            <span v-if="buttonStatus === -1">确定{{btnName(-1)}}</span>
            <span v-else-if="buttonStatus === 1">确定{{btnName(1)}}</span>
            <span v-else-if="buttonStatus === 2">确定{{btnName(2)}}</span>
            <span v-else-if="buttonStatus === 3">确定{{btnName(3)}}</span>
            <span v-else-if="buttonStatus === 4">确定{{btnName(4)}}</span>
            <span v-else>审批</span>
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import fileUpload from '@/components/fileUpload/newFiles/index.vue'
import FlowPicker from './FlowPicker'
import { flowEngine } from '@/apis/data/workflow'
import {Form, FormItem, Input, Select, Option, RadioGroup, Radio, RadioButton} from 'element-ui'

export default {
  name: 'FlowOperate',
  components: {
    fileUpload,
    FlowPicker,
    'el-form': Form,
    'el-form-item': FormItem,
    'el-input': Input,
    'el-select': Select,
    'el-option': Option,
    'el-radio-group': RadioGroup,
    'el-radio-button': RadioButton,
    'el-radio': Radio,
    PcParser: () => import("@/custom-component/form/newParser/PcParser")
  },
  inject:{
    parser: {
      default: null
    }
  },
  props: {
    editData: {
      type: Object,
      default: () => {
        return {}
      },
    },
    //流程中修改的数据
    flowEdit:{
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data(){
    return{
      buttonStatus: 0,
      back_type: 1,
      back_step: '',
      module:'',
      dataId:'',
      // 审批内容
      content: {
        files: [],
        remarks: '',
      },
      curStepNode: {},
      showOtherForm: false,

      selectedUsers: [],
      checkPickerNum: 0,
      selectStep: '',

      btnRadio:'',
      submitLoading: false,

      viewStatus: 'default'
    }
  },
  computed: {
    validRule(){
      return (type)=>{
        const attachRequired = this.editData?.curStepNode?.attachRequired || false
        const suggestRequired = this.editData?.curStepNode?.suggestRequired || false
        if(type === 1 && suggestRequired){
          return [{required: true, type:'string', message: '请输入意见', trigger: 'blur'}]
        }
        if(type === 2 && attachRequired){
          return [{required: true,type:'array', message: '请上传附件', trigger: 'change'}]
        }
        return []
      }
    },
    btnName(){
      return (status)=>{
        const obj = this.editData.btn.filter(i=>i.status === status)
        return obj[0].btn
      }
    }
  },
  methods:{
    handleFuJianClick() {
      // 手动去触发
      if(this.$refs.fileUpload && this.$refs.fileUpload.$refs && this.$refs.fileUpload.$refs.uploadRef) {
        this.$refs.fileUpload.$refs.uploadRef.click()
      }
    },
    handleApproval() {
      this.viewStatus = 'approval'
    },
    formItemShow(list){
      this.$emit('input-show',list)
    },
    getSelectStep(nodeId) {
      this.selectStep = nodeId
    },
    dealWithPicker(selected, total) {
      this.selectedUsers = selected
      this.checkPickerNum = total
    },
    extraFiles(files){
      this.content.files = files
    },
    actionEvent(status){
      this.buttonStatus = status
    },
    flowOperate() {
      try {
        this.$refs.baseForm.validate((valid)=>{
          if(!valid){
            throw new Error('表单验证失败')
          }
        })
        if(this.$refs.otherForm) {
          this.$refs.otherForm.validateFormInput()
        }
      } catch (e){
        this.$message.error('审批意见必填项未填报完整')
        return
      }

      if (this.checkPickerNum > 0 && this.buttonStatus !== 4 &&
        this.checkPickerNum !== this.selectedUsers.target_users.length
      ) {
        this.$message.error('审批人未选择完整！')
        return false
      }
      let formData = {
        status: this.buttonStatus,
        module: this.module,
        ...this.content,
      }
      if(this.$refs.otherForm){
        const otherData = this.$refs.otherForm.getFormData()
        formData.extend_fields = otherData
      }
      if (this.buttonStatus === -1) {
        formData.back_type = this.back_type
        if (this.back_type === 3) formData.back_step = this.back_step
      }
      if (this.selectedUsers.target_users && this.selectedUsers.target_users.length > 0) {
        formData.target_users = this.selectedUsers.target_users
      }
      if (this.selectedUsers.send_users && this.selectedUsers.send_users.length > 0) {
        formData.send_users = this.selectedUsers.send_users
      }
      if (this.selectStep) formData.select_step = this.selectStep
      if (Object.keys(this.flowEdit).length) formData.editFields = this.flowEdit
      if (this.submitLoading) {
        return
      }
      this.submitLoading = true
      if (this.parser) {
        this.parser.formParserValid().then(valid=>{
          if(valid){
            flowEngine.flowOperate(this.dataId, formData).then((res) => {
              if (res.data.code === 200) {
                this.$message.success(res.data.msg)
              } else {
                this.$message.error(res.data.msg)
              }
              this.$emit('change-end')
              setTimeout(() => {
                this.submitLoading = false
              }, 500)
            })
          } else {
            this.$message.error('数据验证失败，请核对必填字段数据');
          }
        }).catch((error) => {
          this.$message.error(error.message);
        })
      } else {
        flowEngine.flowOperate(this.dataId, formData).then((res) => {
          if (res.data.code === 200) {
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg)
          }
          this.$emit('change-end')
          setTimeout(() => {
            this.submitLoading = false
          }, 500)
        })
      }
    },
    /* 操作按钮选择 */
    btnChange(val) {
      if(val === -1){
        this.checkPickerNum = 0;
      }
      this.actionEvent(val)
    },
    /* 隐藏操作区域 */
    hidOperate() {
      this.$emit('hid-operate')
    }
  },
  created () {
    this.module = this.editData.module
    this.dataId = this.editData.id
    const extendFields = this.editData?.curStepNode?.extendFields || {}
    if(extendFields instanceof Object && extendFields?.fields?.length){
      this.curStepNode = this.editData?.curStepNode
      this.showOtherForm = true
    }
  }
}
</script>
<style scoped>

</style>
<style lang="less" scoped>
.flow-operate{
  position: relative;
  height: 100%;
  .operate-container{
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    left: 0;
    bottom: 0;
    background: #fff;
    .default-action{
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 24px;
      box-shadow: 0 -2px 8px 8px #fff;
      border-top: 1px solid #f1f1f1;
      box-sizing: border-box;
      .switch-icon{
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #FFFFFF;
        border-radius: 16px;
        border: 1px solid #E6E9F0;
        box-sizing: border-box;
        &:hover{
          color: #409eff;
          border-color: #409eff;;
        }
      }
    }
    .approval-action-warp{
      position: absolute;
      width: 100%;
      padding: 16px 0 0 10px;
      border-top: 1px solid #f1f1f1;
      box-sizing: border-box;
      box-shadow: 0 -2px 8px 8px #fff;
      left: 0;
      bottom: 0;
      background: #fff;
      :deep(.approval-form) {
        .el-form-item__label{
          padding: 0;
        }
      }
      .approval-warp{
        padding: 12px 0;
        box-sizing: border-box;
        text-align: left
      }
      .operate-content{
        max-height: 400px;
        width: 100%;
        padding: 1px;
        box-sizing: border-box;
        overflow-x: hidden;
        overflow-y: auto;
      }
      .switch-icon{
        position: absolute;
        top: -16px;
        left: 20px;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #FFFFFF;
        border-radius: 16px;
        border: 1px solid #E6E9F0;
        box-sizing: border-box;
        &:hover{
          color: #409eff;
          border-color: #409eff;;
        }
      }
      .action-row{
        height: 64px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .file-upload{
          width: 32px;
          height: 32px;
          background: #F2F4F7;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
        }
      }
    }
  }
}
</style>
