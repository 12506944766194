/*
 * @Description: 用户信息相关
 * @Author: luocheng
 * @Date: 2021-09-06 14:50:54
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2022-10-12 16:17:08
 */

export default {
  state: {
    archi: '', // 架构
    userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {}, // 用户信息
  },
  mutations: {
    setUserInfo(state, info) {
      sessionStorage.setItem('userInfo', JSON.stringify(info));
      localStorage.setItem('userInfo', JSON.stringify(info));
      state.userInfo = info;
    },
    /**
     * @desc: 设置组织架构
     */
    setArchi (state, status) {
      state.archi = status
      localStorage.setItem('archi', status)
    },
  }
}