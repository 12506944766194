<!--
    @name: QuesParser
    @description：QuesParser
    @author: ZengWei
    @date: 2022-04-01 11:22
-->
<template>
  <div class="question-parser">
    <div class="skeleton" v-if="skeleton">
      <skeleton></skeleton>
    </div>
    <template v-else>
      <div class="paper-container">
        <div class="paper-left">
          <div class="paper-section">
            <el-card class="box-card">
              <div slot="header" style="text-align: center">
                <span>考试说明</span>
              </div>
              <div class="paper-info">
                <p class="title">名称：{{paperData.name}}</p>
                <p class="title">标题：{{paperData.title}}</p>
                <p class="title">描述：{{paperData.desc}}</p>
                <p class="title">考试时间：{{paperData.time_length}} 分钟</p>
              </div>
            </el-card>
            <div class="score" v-if="!userCanEdit">
              <div class="total-score-title">得分</div>
              <div class="total-score">{{ userScore }}</div>
            </div>
          </div>
          <div class="indicator">
            <el-card class="box-card">
              <div slot="header" style="text-align: center">
                <span>试题切换</span>
              </div>
              <div class="panel-body">
                <button
                  v-for="item in indicator"
                  :key="item.blockInd"
                  class="btn btn-simple btn-item"
                  :class="{active: activeId === item.indicator}"
                  @click="turnToQues(item.indicator,item.blockInd)"
                >
                  {{ item.indicator }}
                </button>
              </div>
            </el-card>
          </div>
        </div>
        <div class="paper-right">
          <el-form
            ref="QuesParser" size="medium"
            :model="formValidate"
            :label-position="formData.labelPosition"
          >
            <el-col
              ref="wrapperItem"
              class="ques-wrapper-item"
              v-for="(element,index) in formValidate.items"
              :key="element.config.__config__.formId"
              :span="24">
              <el-form-item
                :prop="'items.' + index + '.value'"
                :rules="element.validate"
                label-width="0px"
              >
                <QuestionItem :element="element" :number="index" />
              </el-form-item>
            </el-col>
          </el-form>
        </div>
      </div>

      <div class="paper-action-bar">
        <div class="progress-bar">
          <el-progress
            style="margin: 12px 0 0 10px"
            :text-inside="true" :stroke-width="26"
            :percentage="progress"
            :format="format">
          </el-progress>
        </div>
        <div class="action-bar" >
          <el-button v-if="userCanEdit" class="action-btn" type="primary" round @click="submitPaperResult">完成考试并提交</el-button>
          <el-button v-else class="action-btn" type="default" round>您已完成考试</el-button>
        </div>
      </div>
    </template>

    <el-dialog
      top="5vh"
      :title="outerFormTitle"
      :custom-class="'common-dialog'"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="outerFormVisible"
      width="80%"
      append-to-body
    >
      <article class="dialog-main">
        <PcParser
          ref="outerFormParser"
          v-if="outerFormData && outerFormVisible"
          :hide-btn="true"
          :form-data="outerFormData"
          :form-mode="outerFormMode"
          :need-flow="outerNeedFlow"
          :module-uuid="outerFormUuid"
          :display-data="outerFormDisplay"
          @submit="outerFormSubmit"
        ></PcParser>
      </article>
      <div slot="footer">
        <el-button type="default" @click="outerFormVisible = false" style="margin-right: 10px;">取消</el-button>
        <el-button type="primary" @click="confirmSubmit()">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {Card,Progress} from 'element-ui'
import QuesParserMethods from "@/custom-component/form/quesParser/scripts/quesParserMethods";
import QuestionItem from "@/custom-component/form/quesParser/components/QuestionItem";
import PcParser from "@/custom-component/form/newParser/PcParser";
import skeleton from "@/custom-component/form/quesParser/skeleton";

export default {
  name: "QuesParser",
  components: {
    'el-card': Card,
    'el-progress': Progress,
    skeleton,QuestionItem,PcParser
  },
  props: ['paperData','formData','paperLog'],
  data() {
    return {
      skeleton: true,
      formValidate:{
        items:[],
      },
      outerFormElement:null,
      outerAfterElement:null,
      position:2,
      afterPosition:3,
      activeId: 1,

      outerFormData:null,
      outerNeedFlow: false,
      outerFormUuid: '',
      outerObjectUuid: '',
      outerFormMode: 'add',
      outerFormDisplay: {},
      outerFormType: 1,
      outerFormShowType: 1,
      outerFormVisible:false,
      outerFormTitle: '外部表单',
      userCanEdit: true,
      userScore: 0,
      indicator:[],
    }
  },
  computed: {
    progress(){
      const number = this.activeId;
      const total = this.indicator.length
      return total === 0 ? 0 : parseInt((number/total)*100)
    },
  },
  created() {
    this.initQuesFormatData(this.formData.fields);
    if(this.paperLog && Object.keys(this.paperLog).length){
      this.userCanEdit = false;
      const score = Object.values(this.paperLog.score)
      this.userScore = eval(score.join("+"))
    }
    if(this.outerFormElement && this.position === 1) {
      this.openOuterForm(this.outerFormElement)
    }
  },
  methods: QuesParserMethods,
}
</script>

<style lang="less" scoped>
:deep(.common-dialog)  {
  .el-dialog__body{
    padding: 0;
  }
}

.dialog-main{
  padding: 0 15px;
  height: calc(100vh - 200px);
  overflow-y: auto;
}

.paper-info{
  .title{
    line-height: 26px;
  }
}

.question-parser {
  width: 100%;
  background-color: #F4F6F9;
  position: relative;

  :deep(.el-form-item__error) {
    left: 75px;
  }

  .paper-container{
    display: flex;
    height: calc(100vh - 180px);
    overflow-y: auto;

    .paper-left {
      position: fixed;
      width: 350px;

      .paper-section{
        position: relative;

        .score{
          position: absolute;
          right: 12px;
          top: 24px;
          border-radius: 4px;
          min-width: 65px;
          min-height: 65px;
          background-color: #00bf6f;

          .total-score-title{
            margin-top: 2px;
            text-align: center;
            font-weight: 500;
            font-size: 14px;
            color: #fff;
          }

          .total-score{
            text-align: center;
            font-weight: 700;
            font-size: 30px;
            color: #fff;
          }
        }
      }
    }

    .paper-right{
      margin-left: 365px;
      flex: 1;
      .ques-wrapper-item{
        background-color: #fff;
        margin-bottom: 20px;
        border-radius: 8px;
      }
    }
  }

  .paper-action-bar {
    display: flex;
    background-color: #ffffff;
    width: 100%;
    height: 50px;

    .progress-bar{
      width: 40%;
      height: 100%;
    }

    .action-bar{
      width: 60%;
      height: 100%;

      .action-btn {
        margin-top: 10px;
        margin-right:10px;
        float: right
      }
    }
  }

  .indicator{
    background-color: #ffffff;
    border-radius: 5px;

    .panel-body {
      .btn{
        font-weight: 400;
        font-size: .8571em;
        line-height: 1.35em;
        margin: 5px 3px;
        border-radius: 0.1875rem;
        cursor: pointer;
        display: inline-block;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        user-select: none;
        outline: 0;
      }
      .btn-simple {
        border: 1px solid #6572b8;
        padding: 10px 22px;
        background-color: transparent;
      }
      .active {
        background: url('../../../assets/images/angle_icon.png') no-repeat bottom right !important;
      }
    }

  }

  .paper-section{
    background-color: #ffffff;
    border-radius: 5px;
    margin-bottom: 20px;
  }

}
</style>
