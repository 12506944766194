<!--
 * @Author: your name
 * @Date: 2021-12-02 15:32:01
 * @LastEditTime: 2022-10-28 14:34:04
 * @LastEditors: Shiltin 18580045074@163.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \nanhu-micro\src\views\components\bindModel\index.vue
-->
<template>
  <!-- 绑定模型 -->
  <div>
    <slot name="showType"></slot>
    <el-drawer
      custom-class="no-padding"
      :visible.sync="show"
      size="100%"
      append-to-body
      :with-header="false"
    >
      <BindModel
        :model-data="data"
				:appId="appId" :appSecret="appSecret" 
				:choose-type="chooseType"
        :search="search"
        @back="show = false"
        @set-data="setData"
        v-if="show"
        :modelData="modelData"
      />
    </el-drawer>
  </div>
</template>

<script>
import BindModel from "@/components/bindModel/index.vue";
import {Drawer} from "element-ui";

export default {
  name: "ButtonBind",
  components: {
    BindModel,
    'el-drawer': Drawer,
  },
  props: {
    modelData: {
      type: [Array,String],
      default: () => [],
    },
		//模型和视点model/view
		chooseType:{
			type:String,
			default:'model'
		},
		appId: {
			type: String,
			default: ""
		},
		appSecret: {
			type: String,
			default: ""
		},
    search: {
        type: Array,
        default: () => [],
    }
  },
  data() {
    return {
      show: false,
      data: [], //{model_id:'1831e7f1eb214babba7c7e2cd5958cd8',dbids: [477]}
    };
  },
  methods: {
    setData(data) {
      this.$emit("set-data", data);
      this.show = false;
    },
  },
  mounted() {
  }
};
</script>
<style lang="less">
.no-padding {
  .el-drawer__body {
    padding: 0 !important;
  }

}
</style>
