<!--
 * @Author: Shiltin
 * @Date: 2021-10-20 17:52:57
 * @LastEditors: Shiltin 18580045074@163.com
 * @LastEditTime: 2022-06-21 17:47:43
 * @Description:
-->
<template>
  <div style="height:100%;width:100%;">
    <!-- 从零选择 -->
    <el-select class="new-select-style" style="display: block;" :disabled="disabled" multiple :value="seatValue">
      <template #prefix>
        <div
          v-if="positionName && checkbox"
          class="show-value"
          :style="disabled ? 'background:#F5F7FA;color:#c0c4cc' : ''"
        >
          {{ positionName }}
        </div>
        <div
          v-else-if="showValue && !checkbox"
          class="show-value"
          :style="disabled ? 'background:#F5F7FA;color:#c0c4cc' : ''"
        >
          {{ showValue }}
        </div>
        <div
          v-else
          class="show-value"
          style="color: #999; cursor: pointer"
          :style="disabled ? 'background:#F5F7FA;color:#c0c4cc' : ''"
        >
          请选择部位
        </div>
      </template>
      <template #empty>
        <div class="pover">
          <el-checkbox style="margin:10px 0 0 10px" v-model="checkbox" @change="checkboxChange">
            手动填写
          </el-checkbox>
          <div v-if="checkbox" class="position-name">
            <el-input v-model="positionName" placeholder="请输入部位名字" @change="inputChange" />
          </div>
          <div
            v-if="!checkbox"
            v-loading="showTree"
            class="pover-content"
            :style="'height:' + maxHeight + 'px'"
          >
            <div class="content-left">
              <div class="content-left-search">
                <el-input v-model="searchValue" placeholder="请输入部位名字" />
              </div>
              <div class="content-left-tree scroller">
                <el-tree
                  v-if="!showTree"
                  ref="treeRef"
                  :data="dataInfo"
                  :default-checked-keys="showTreeKeys"
                  :default-expand-all="defaultExpand"
                  :filter-node-method="filterNode"
                  node-key="nodeKey"
                  :props="{
                    label: 'name',
                    children: 'children',
                    disabled: disabled ? 'id' : false,
                  }"
                  :show-checkbox="isCheckType"
                  @check-change="handleCheckChange"
                  @node-click="handleNodeClick"
                >
                  <template #default="{ data }">
                    <span class="custom-tree-node">
                      <el-tooltip
                        v-if="data.name && (data.name + '').length > 8"
                        class="item"
                        :content="data.name"
                        effect="dark"
                        placement="top-start"
                      >
                        <span>{{ data.name }}</span>
                      </el-tooltip>
                      <span v-else>{{ data.name }}</span>
                    </span>
                  </template>
                </el-tree>
              </div>
            </div>
            <div class="content-right">
              <div class="content-right-top">
                <span>
                  已选中部位数
                  <span class="content-right-top-number">
                    {{ selectUserList.length }}
                  </span>
                </span>
                <span
                  style="float: right; color: #0076ff; cursor: pointer"
                  @click="clearChoose(1)"
                >
                  清空
                </span>
              </div>
              <div class="content-right-choose scroller">
                <div
                  v-for="(item, index) in selectUserList"
                  :key="index"
                  class="content-show"
                >
                  <div class="content-show-name">
                    <el-tooltip
                      class="item"
                      :content="item.name"
                      effect="dark"
                      placement="top-start"
                    >
                      <p>{{ item.name }}</p>
                    </el-tooltip>
                  </div>
                  <div class="content-show-delete">
                    <i
                      class="iconfont iconbimgis_guanbi"
                      style="cursor: pointer"
                      @click.stop="deleteClick(item)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </el-select>
    <!-- <el-popover
      :disabled="disabled"
      :placement="positionType"
      popper-class="pover"
      trigger="click"
      :width="width"
    >
      <el-checkbox v-model="checkbox" @change="checkboxChange">
        手动填写
      </el-checkbox>
      <div v-if="checkbox" class="position-name">
        <el-input v-model="positionName" placeholder="请输入部位名字" @change="inputChange" />
      </div>
      <div
        v-if="!checkbox"
        v-loading="showTree"
        class="pover-content"
        :style="'height:' + maxHeight + 'px'"
      >
        <div class="content-left">
          <div class="content-left-search">
            <el-input v-model="searchValue" placeholder="请输入部位名字" />
          </div>
          <div class="content-left-tree scroller">
            <el-tree
              v-if="!showTree"
              ref="treeRef"
              :data="dataInfo"
              :default-checked-keys="showTreeKeys"
              :default-expand-all="defaultExpand"
              :filter-node-method="filterNode"
              node-key="nodeKey"
              :props="{
                label: 'name',
                children: 'children',
                disabled: disabled ? 'id' : false,
              }"
              :show-checkbox="isCheckType"
              @check-change="handleCheckChange"
              @node-click="handleNodeClick"
            >
              <template #default="{ data }">
                <span class="custom-tree-node">
                  <el-tooltip
                    v-if="data.name && (data.name + '').length > 8"
                    class="item"
                    :content="data.name"
                    effect="dark"
                    placement="top-start"
                  >
                    <span>{{ data.name }}</span>
                  </el-tooltip>
                  <span v-else>{{ data.name }}</span>
                </span>
              </template>
            </el-tree>
          </div>
        </div>
        <div class="content-right">
          <div class="content-right-top">
            <span>
              已选中部位数
              <span class="content-right-top-number">
                {{ selectUserList.length }}
              </span>
            </span>
            <span
              style="float: right; color: #0076ff; cursor: pointer"
              @click="clearChoose(1)"
            >
              清空
            </span>
          </div>
          <div class="content-right-choose scroller">
            <div
              v-for="(item, index) in selectUserList"
              :key="index"
              class="content-show"
            >
              <div class="content-show-name">
                <el-tooltip
                  class="item"
                  :content="item.name"
                  effect="dark"
                  placement="top-start"
                >
                  <p>{{ item.name }}</p>
                </el-tooltip>
              </div>
              <div class="content-show-delete">
                <i
                  class="iconfont iconbimgis_guanbi"
                  style="cursor: pointer"
                  @click.stop="deleteClick(item)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <template #reference>
        <div
          v-if="positionName && checkbox"
          class="show-value"
          :style="disabled ? 'background:#F5F7FA;color:#c0c4cc' : ''"
        >
          {{ positionName }}
        </div>
        <div
          v-else-if="showValue && !checkbox"
          class="show-value"
          :style="disabled ? 'background:#F5F7FA;color:#c0c4cc' : ''"
        >
          {{ showValue }}
        </div>
        <div
          v-else
          class="show-value"
          style="color: #999; cursor: pointer"
          :style="disabled ? 'background:#F5F7FA;color:#c0c4cc' : ''"
        >
          请选择部位
        </div>
      </template>
    </el-popover> -->
  </div>
</template>
<script>
import {
  Tree, Select, Input, Tooltip, Checkbox
} from 'element-ui';
export default {
  name: '',
  components: {
		'el-tree': Tree,
    'el-select': Select,
    'el-input': Input,
    'el-tooltip': Tooltip,
    'el-checkbox': Checkbox,
  },
  props: {
    dataInfo: {
      type: Array,
      default: () => []
    },
    showList: {
      type: Array,
      default: () => []
    },
    // cascaderCheck 级联单选
    // cascaderCheckbox 级联多选
    isCheckType: {
      type: Boolean,
      default: true
    },
    positionType: {
      type: String,
      default: 'bottom-start'
    },
    /* 弹出框宽度 */
    width: {
      type: Number,
      default: 450
    },
    /* 弹出框最大高度 */
    maxHeight: {
      type: Number,
      default: 300
    },
    /* 默认展开 */
    defaultExpand: {
      type: Boolean,
      default: true
    },
    /* 展示内容 */
    showTree: {
      type: Boolean,
      default: true
    },
    /* 禁用选择 */
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      seatValue: '',
      treeRef: null,
      showTreeKeys: [],
      selectUserList: [],
      searchValue: '',
      firsetIn: false,
      checkbox: false,
      positionName: ''
    }
  },
  computed: {
    /* 计算当前选择的人员回显 */
    showValue () {
      return this.getChooseName()
    }
  },
  watch: {
    searchValue: {
      handler (val) {
        this.$refs.treeRef.filter(val)
      }
    },
    dataInfo: {
      handler () {
        this.firsetIn = false
        this.showTreeInfo()
      }
    },
    showList: {
      handler () {
        this.showTreeInfo()
      },
      deep: true
    }
  },
  mounted () {
    this.showTreeInfo()
  },
  methods: {
    checkboxChange () {
      if (this.checkbox) {
        this.$emit('on-select-change', [])
      }
    },
    inputChange () {
      this.$emit('onSelectChange', [{
        name: this.positionName,
        checkbox: true,
        abbreviatedName: this.positionName
      }])
    },
    /* 当前选择的nodekey数组 */
    handleCheckChange () {
      /* 级联单选 */
      if (!this.isCheckType) {
        this.returnShowTreeInfo(this.showTreeKeys)
        return
      }
      // const treeRef = ref(this.treeRef)
      const keys = this.$refs.treeRef.getCheckedKeys()
      let arr = []
      if (keys && keys.length) {
        arr = keys.filter((item) => item > 0)
        this.showTreeKeys = arr
      } else {
        this.showTreeKeys = []
      }
      this.returnShowTreeInfo(arr)
    },
    /* 级联单选 */
    handleNodeClick (data) {
      if (this.isCheckType) {
        return
      }
      if (data.nodeKey) {
        this.showTreeKeys = []
        this.showTreeKeys = [data.nodeKey]
      }
      this.returnShowTreeInfo(this.showTreeKeys)
    },
    /* 当前选择的人员数组数据（item包含所有信息） */
    returnShowTreeInfo (list) {
      const selectArr = []
      list.forEach((element) => {
        const getKey = (array) => {
          array.forEach((itemy) => {
            if (itemy.nodeKey === element) {
              selectArr.push(itemy)
            }
            if (itemy.children && itemy.children.length) {
              getKey(itemy.children)
            }
          })
        }
        getKey(this.dataInfo)
      })
      this.selectUserList = selectArr
      if (selectArr && selectArr.length) {
        selectArr.forEach(item => {
          item.checkbox = false
          item.abbreviatedName = this.getChooseName()
        })
      }
      // this.calculation()
      this.$emit('onSelectChange', selectArr)
    },
    /* 传入回显的showList 获取勾选数组  进行默认回显 */
    showTreeInfo () {
      if (this.showList && this.showList.length && this.showList[0] && this.showList[0].checkbox) {
        this.checkbox = true
        this.positionName = this.showList[0].abbreviatedName
        this.firsetIn = true
        return
      }
      if (!this.showList || this.showList.length === 0 || !this.dataInfo || this.dataInfo.length === 0 || this.firsetIn) {
        return
      }
      this.firsetIn = true
      let showArr = []
      const showTree = (arr) => {
        arr.forEach((item) => {
          this.showList.forEach((element) => {
            if (item.id === element.id) {
              showArr.push(item)
            }
            if (item.children && item.children.length) {
              showTree(item.children)
            }
          })
        })
      }
      showTree(this.dataInfo)

      const unique = (arr, attrName) => {
        const res = new Map()
        return arr.filter(
          (a) => !res.has(a[attrName]) && res.set(a[attrName], 1)
        )
      }
      showArr = unique(showArr, 'id')
      const arr = []
      showArr.forEach((element) => {
        arr.push(element.nodeKey)
      })
      this.showTreeKeys = arr
      this.returnShowTreeInfo(this.showTreeKeys)
      // this.calculation()
    },
    /* 删除单个勾选 */
    deleteClick (data) {
      for (let index = 0; index < this.showTreeKeys.length; index++) {
        if (this.showTreeKeys[index] === data.nodeKey) {
          this.showTreeKeys.splice(index, 1)
        }
      }
      this.returnShowTreeInfo(this.showTreeKeys)
      // const treeRef = ref(this.treeRef)
      this.$refs.treeRef.setCheckedKeys(this.showTreeKeys)
    },
    /* 清空所有勾选 */
    clearChoose () {
      if (!this.isCheckType) {
        // const treeRef = ref(this.treeRef)
        this.$refs.treeRef.setCurrentKey(null)
        this.showTreeKeys = []
        this.returnShowTreeInfo(this.showTreeKeys)
        return
      }
      // const treeRef = ref(this.treeRef)
      this.$refs.treeRef.setCheckedKeys([])
    },
    /* tree组件的过滤规则 */
    filterNode (value, data) {
      if (!value) return true
      return !!(data.name && data.name.indexOf(value) !== -1)
    },
    /* 计算选择进度 */
    calculation () {
      this.dataInfo.forEach((itemy) => {
        let choose = 0
        const total = itemy?.children?.length
        itemy.children.forEach((item) => {
          this.showTreeKeys.forEach((element) => {
            if (element === item.nodeKey) {
              choose += 1
            }
          })
        })
        itemy.label = itemy.name + '【' + choose + '/' + total + '】'
      })
    },
    /* 处理当前选择的合并名称 */
    getChooseName () {
      const currentList = JSON.parse(JSON.stringify(this.selectUserList))
      let str = ''
      const showArr = []
      if (this.selectUserList && this.selectUserList.length) {
        const obj = {}
        currentList.forEach(element => {
          if (!obj || !obj[element.type_id]) {
            obj[element.type_id] = [element]
          } else {
            obj[element.type_id].push(element)
          }
        })
        for (const key in obj) {
          let element = obj[key]
          if (key === '1' || key === '5' || key === '6') {
            element.forEach(item => {
              showArr.push(item.name)
            })
          } else if (key === '2') {
            // 构件
            const cateObj = {}
            element = this.unique(element, 'name')
            element.forEach(item => {
              const splitArr = item.name.split('_')
              if (!cateObj || !cateObj[splitArr[0]]) {
                cateObj[splitArr[0]] = [item]
              } else {
                cateObj[splitArr[0]].push(item)
              }
            })
            for (const i in cateObj) {
              if (cateObj[i] && cateObj[i].length) {
                const name = cateObj[i][0].name.split('-')[0]
                let arr = []
                cateObj[i].forEach(element => {
                  arr.push(Number(element.name.split('-')[1]))
                })
                arr = [...new Set(arr)]
                const list = this.fn(arr, 1)
                list.forEach(element => {
                  if (element.length > 1) {
                    showArr.push(name + '_' + element[0] + '-' + element[element.length - 1])
                  } else {
                    showArr.push(name + '_' + element[0])
                  }
                })
              }
            }
          } else if (key === '3') {
            // 标高
            const cateObj = {}
            element = this.unique(element, 'name')
            element.forEach(item => {
              const splitArr = item.name.split('-')
              if (!cateObj || !cateObj[splitArr[0]]) {
                cateObj[splitArr[0]] = [item]
              } else {
                cateObj[splitArr[0]].push(item)
              }
            })
            for (const i in cateObj) {
              if (cateObj[i] && cateObj[i].length) {
                const name = cateObj[i][0].name.split('-')[0]
                let arr = []
                cateObj[i].forEach(element => {
                  const str = element.name.split('-')[1]
                  arr.push(Number(str.slice(0, str.length - 1)))
                })
                arr = [...new Set(arr)]
                const list = this.fn(arr, cateObj[i][0].option_config.minUnit)
                list.forEach(element => {
                  if (element.length > 1) {
                    showArr.push(name + '_' + element[0] + '-' + element[element.length - 1] + '米')
                  } else {
                    showArr.push(name + '_' + element[0] + '米')
                  }
                })
              }
            }
          } else if (key === '4') {
            // 里程
            const cateObj = {}
            element = this.unique(element, 'name')
            element.forEach(item => {
              const str = item.option_config.name + item.start_mile + item.end_mile
              if (!cateObj || !cateObj[str]) {
                cateObj[str] = [item]
              } else {
                cateObj[str].push(item)
              }
            })
            for (const i in cateObj) {
              if (cateObj[i] && cateObj[i].length) {
                const arr = []
                cateObj[i].forEach(item => {
                  const num1 = item.name.split('-')[0].split('+')[0].replace(/[^0-9]/ig, '')
                  const start = parseFloat(num1) * 1000 + parseFloat(item.name.split('-')[0].split('+')[1])
                  arr.push(start)
                })
                const list = this.fn(arr, cateObj[i][0].option_config.minUnit)
                const name = cateObj[i][0].name.split('-')[0].split('+')[0].replace(/\d+/g, '')
                const minUnit = cateObj[i][0].option_config.minUnit
                list.forEach(element => {
                  let max = 0
                  let maxNum = 0
                  let num = element[element.length - 1] % 1000
                  if (num < 10) {
                    num = '00' + num
                  } else if (num >= 10 && num < 100) {
                    num = '0' + num
                  }
                  const maxSatrt = name + Math.floor(element[element.length - 1] / 1000) + '+' + num
                  /* 找到当前合并或者单个的显示对应的最大里程整数和单独零数 */
                  cateObj[i].forEach(ite => {
                    if (ite.name.split('-')[0] === maxSatrt) {
                      max = ite.name.split('-')[1].split('+')[1].replace(/[^0-9]/ig, '')
                      maxNum = ite.name.split('-')[1].split('+')[0].replace(/[^0-9]/ig, '')
                    }
                  })
                  if (element.length > 1) {
                    const intNum1 = Math.floor(element[0] / 1000)
                    let remainderNum1 = element[0] % 1000
                    const intNum2 = element[element.length - 1] + parseFloat(minUnit) > (maxNum * 1000 + parseFloat(max))
                      ? Math.floor((maxNum * 1000 + parseFloat(max)) / 1000)
                      : Math.floor((element[element.length - 1] + parseFloat(minUnit)) / 1000)
                    let remainderNum2 = element[element.length - 1] + parseFloat(minUnit) > max
                      ? max
                      : (element[element.length - 1] + parseFloat(minUnit)) % 1000
                    if (remainderNum1 < 10) {
                      remainderNum1 = '00' + remainderNum1
                    } else if (remainderNum1 >= 10 && remainderNum1 < 100) {
                      remainderNum1 = '0' + remainderNum1
                    }
                    if (remainderNum2 < 10) {
                      remainderNum2 = '00' + remainderNum2
                    } else if (remainderNum2 >= 10 && remainderNum2 < 100) {
                      remainderNum2 = '0' + remainderNum2
                    }
                    showArr.push(name + intNum1 + '+' + remainderNum1 + '-' + name + intNum2 + '+' + (remainderNum2 <= max ? remainderNum2 : max))
                  } else {
                    const intNum1 = Math.floor(element[0] / 1000)
                    let remainderNum1 = element[0] % 1000
                    const intNum2 = element[element.length - 1] + parseFloat(minUnit) > (maxNum * 1000 + parseFloat(max))
                      ? Math.floor((maxNum * 1000 + parseFloat(max)) / 1000)
                      : Math.floor((element[element.length - 1] + parseFloat(minUnit)) / 1000)
                    let remainderNum2 = element[element.length - 1] + parseFloat(minUnit) > max
                      ? max
                      : (element[element.length - 1] + parseFloat(minUnit)) % 1000
                    if (remainderNum1 < 10) {
                      remainderNum1 = '00' + remainderNum1
                    } else if (remainderNum1 >= 10 && remainderNum1 < 100) {
                      remainderNum1 = '0' + remainderNum1
                    }
                    if (remainderNum2 < 10) {
                      remainderNum2 = '00' + remainderNum2
                    } else if (remainderNum2 >= 10 && remainderNum2 < 100) {
                      remainderNum2 = '0' + remainderNum2
                    }
                    showArr.push(name + intNum1 + '+' + remainderNum1 + '-' + name + intNum2 + '+' + (remainderNum2 <= max ? remainderNum2 : max))
                  }
                })
              }
            }
          }
        }
      }
      if (showArr && showArr.length) {
        showArr.forEach(element => {
          if (!str) {
            str += element
          } else {
            str += (', ' + element)
          }
        })
      }
      return str
    },
    /* 获取数组连续的1.2.3二维数组 */
    fn (arr, interval) {
      var result = []
      var i = 0
      result[i] = [arr[0]]
      arr.reduce(function (prev, cur) {
        parseFloat((parseFloat(cur) - parseFloat(prev)).toFixed(5)) <= parseFloat(parseFloat(interval).toFixed(5)) ? result[i].push(cur) : result[++i] = [cur]
        return cur
      })
      return result
    },
    unique (arr, attrName) {
      const res = new Map()
      return arr.filter(
        (a) => !res.has(a[attrName]) && res.set(a[attrName], 1)
      )
    }
  }
}
</script>
<style lang="less">
/* 修改select组件样式 */
.new-select-style {
  width: 100%;
  height: 36px;
  .el-input__suffix {
    display: none !important;
  }
  .el-input__inner {
    display: none !important;
  }
  .el-input__prefix {
    width: 100% !important;
    display: inherit !important;
    height: auto !important;
    position: inherit !important;
    padding: 0 !important;
    box-sizing: border-box !important;
  }
  .el-input--suffix {
    width: 100% !important;
    display: inherit !important;
    height: auto !important;
    position: inherit !important;
    box-sizing: border-box !important;
  }
}
</style>
<style lang="less" scoped>
.position-name {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
}
.show-value {
  width: 100%;
  line-height: 32px;
  color: #606266;
  border: 1px solid #dcdfe6;
  border-radius: 2px;
  padding: 0 16px;
  text-align: left;
  height: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
}
.show-value-input {
  // height: 32px;
  // overflow: hidden;
  // text-overflow: ellipsis;
  // white-space: nowrap;
}
.pover {
  width: 428px;
  height: 100%;
  .pover-content {
    width: 100%;
    height: 100%;
    display: flex;
    overflow-y: auto;
    .content-left {
      width: 60%;
      height: 100%;
      display: flex;
      flex-direction: column;
      .content-left-search {
        height: 50px;
        padding: 5px 10px;
      }
      .content-left-tree {
        padding: 0 10px;
        flex: 1;
        overflow-y: auto;
				overflow-x: hidden;
      }
    }
    .content-right {
      width: 40%;
      height: 100%;
      display: flex;
      flex-direction: column;
      .content-right-top {
        height: 50px;
        line-height: 40px;
        padding: 0 10px;
        .content-right-top-number {
          color: #0076ff;
        }
      }
      .content-right-choose {
        flex: 1;
        padding: 0 10px;
        overflow-y: auto;
        .content-show {
          width: 100%;
          height: 30px;
          display: flex;
          .content-show-name {
            width: 88%;
            height: 30px;
            display: flex;
            flex-direction: column;
            p {
              height: 30px;
              line-height: 30px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            p:nth-child(2) {
              font-size: 12px;
              color: #999;
            }
          }
          .content-show-delete {
            width: 10%;
            height: 30px;
            line-height: 30px;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
