<!--
 * @Author: yaoling 1871883713@qq.com
 * @Date: 2022-10-28 13:55:13
 * @LastEditors: yaoling 1871883713@qq.com
 * @LastEditTime: 2022-11-06 10:19:18
 * @FilePath: \dataview-next\src\views\index\statisticalChart\HeaderStatistics.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="header-statistics">
    <ul class="top-content">
      <li
        class="total-number-box"
        :style="{ backgroundImage: 'url(' + item.backgroundImage + ')' }"
        v-for="item in totalList"
        :key="item.id"
      >
        <span class="total">{{ item.number }}</span>
        <span class="total-title">{{ item.title }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "HeaderStatistics",
  components: {},
  props: {
    // 统计数据
    totalList: {
      type: [Array, null],
      required: true,
      default: () => null,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.header-statistics {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 5px 5px 0 5px;
  box-sizing: border-box;
  background-color: #ffffff;
  .top-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 146px;
    .total-number-box {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 24.4%;
      height: 100%;
      padding: 35.24px 0 41.45px 1.5%;
      box-sizing: border-box;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      text-align: left;
      .total {
        color: #2a2f3d;
        font-family: "Source Han Sans CN";
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 24px;
      }
      .total-title {
        color: #73767a;
        font-family: "Source Han Sans CN";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
      }
    }
  }
}
</style>
