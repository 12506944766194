<!--
 * @Author: zx
 * @Date: 2022-09-26 16:32:01
 * @LastEditTime: 2022-12-30 14:50:54
 * @Description:
 * @LastEditors: zx
-->
<template>
  <div class="cascader" :style="showChooseButton ? 'height: auto' : ''">
      <el-select class="new-select-style" :style="showChooseButton ? 'height: auto' : ''" :value="seatValue" :disabled="disabled" multiple>
        <template #prefix>
          <div
            v-if="showValue && !showChooseButton"
            class="show-value"
            :style="disabled ? 'background:#F5F7FA;color:#c0c4cc' : ''"
          >
            {{ showValue }}
          </div>
          <div
            v-if="!showValue && !showChooseButton"
            class="show-value"
            :style="disabled ? 'background:#F5F7FA;color:#c0c4cc' : ''"
          >
            请选择人员
          </div>
          <div v-if="showChooseButton" class="choose-button">
            <span>添加人员</span>
            <i class="iconfont iconkuaisuxinzeng" style="font-size:18px"></i>
          </div>
          <div class="choose-list" v-if="showChooseButton" >
            <div
              v-for="(item, index) in selectUserList"
              :key="index"
              class="choose-list-item"
            >
              <div class="choose-list-label">
                {{ item.name }}
              </div>
              <div class="choose-list-icon">
                <i
                  class="iconfont iconbimgis_guanbi"
                  @click.stop="deleteClick(item)"
                />
              </div>
            </div>
          </div>
        </template>
        <template #empty>
          <div class="pover">
            <div
              v-loading="loading"
              class="pover-content"
            >
              <div class="content-left">
                <div class="content-left-search">
                  <el-input v-model="searchValue" placeholder="请输入人员名字" />
                </div>
                <div class="content-left-tree scroller">
                  <el-tree
                    ref="treeRef"
                    :data="dataList"
                    :default-checked-keys="showTreeKeys"
                    :default-expand-all="true"
                    :filter-node-method="filterNode"
                    node-key="id"
                    :props="{
                      label: 'name',
                      children: 'children'
                    }"
                    :show-checkbox="isCheckType"
                    @check="handleCheckChange"
                  >
                    <template #default="{ node, data }">
                      <div class="archi-label">
                        <span class="archi-label-svg">
                          <svg aria-hidden="true" class="color-icon">
                            <use :xlink:href="getIcon(data)" />
                          </svg>
                          <!-- v-if="node && data.name.length > 12" -->
                          <el-tooltip
                            :content="node ? data.name : ''"
                            effect="dark"
                            :open-delay="500"
                            placement="top"
                          >
                            <span>{{ node ? data.name : '' }}</span>
                          </el-tooltip>
                          <!-- <span v-else>{{ node ? data.name : '' }}</span> -->
                        </span>
                      </div>
                    </template>
                  </el-tree>
                </div>
              </div>
              <div class="content-right">
                <div class="content-right-top">
                  <span>
                    已选人员（{{ selectUserList.length }}）
                  </span>
                  <span
                    style="float: right; color: #0076ff; cursor: pointer"
                    @click="clearChoose(1)"
                  >
                    清空
                  </span>
                </div>
                <div class="content-right-choose scroller">
                  <div
                    v-for="(item, index) in selectUserList"
                    :key="index"
                    class="content-show"
                  >
                    <div class="content-show-img">
                      <img v-if="item.wechat_avatar || item.avatar" :src="item.avatar ? item.avatar : item.wechat_avatar">
                      <div v-else class="content-show-seat" />
                    </div>
                    <div class="content-show-name">
                      <el-tooltip
                        class="item"
                        effect="dark"
                        placement="top-start"
                        :open-delay="1000"
                      >
                        <template #content>
                          {{ item.name }}
                        </template>
                        <p>{{ item.name }}</p>
                      </el-tooltip>
                      <p>
                        {{ item.parentInfo.name }}
                      </p>
                    </div>
                    <div class="content-show-delete">
                      <i
                        class="iconfont iconwangpan-qingchu1x"
                        style="color: #A9ADB8"
                        @click.stop="deleteClick(item)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </el-select>
  </div>
</template>
<script>
  import {
    Tree, Select, Input, Tooltip
  } from 'element-ui';
  export default {
    components: {
      'el-tree': Tree,
      'el-select': Select,
      'el-input': Input,
      'el-tooltip': Tooltip
    },
    props:{
      userData: {
        type: Array,
        default: () => []
      },
      /* 禁用选择 */
      disabled: {
        type: Boolean,
        default: false
      },
      /* 展示按钮的选择人员 */
      showChooseButton: {
        type: Boolean,
        default: false
      },
      /* 回显数据 */
      showList: {
        type: Array,
        default: () => []
      },
      // false 级联单选
      // true 级联多选
      isCheckType: {
        type: Boolean,
        default: true
      },
      loading: {
        type: Boolean,
        default: true
      },
    },
    data() {
      return {
        seatValue: '', // 占位数据，无实际意义，用于select组件
        selectUserList: [], // 选择人员数组
        searchValue: '', // 搜索数组件数据
        dataList: [], // tree组件展示数据
        showTreeKeys: [], // 默认选中数组
      }
    },
    computed:{
      /* 计算当前选择的人员回显 */
      showValue(){
        let str = ''
        this.selectUserList.forEach((item) => {
          if (!str) {
            str += item.name
          } else {
            str += ',' + item.name
          }
        })
        return str
      },
    },
    watch: {
      searchValue:{
        handler(val){
          this.$refs.treeRef?.filter(val)
        }
      },
      userData:{
        handler(val){
          this.dataList = val
          this.showTreeInfo(this.showList)
        },
        deep:true,
        immediate: true
      },
      showList:{
        handler(val){
          this.showTreeInfo(val)
        },
        deep:true,
        immediate: true
      },
    },
    mounted() {
    },
    methods:{
      /* tree组件的过滤规则 */
      filterNode(value, data){
        if (!value) return true
        return !!(data.name && data.name.indexOf(value) !== -1)
      },
      /* 勾选数据狗触发 */
      handleCheckChange() {
        let keys = this.$refs.treeRef?.getCheckedKeys()
        if (keys && keys.length) {
          keys = keys.filter(item => item)
          if (keys && keys.length) {
            this.getSelectUsers(keys)
          }
          this.showTreeKeys = keys
        } else {
          this.selectUserList = []
          this.showTreeKeys = []
          this.$nextTick(() => {
            this.$refs.treeRef?.setCheckedKeys(this.showTreeKeys)
          })
        }
      },
      /* 根据key获取人员 */
      getSelectUsers(keys) {
        let arr = []
        const loopTree = (array, key) => {
          for (let index = 0; index < array.length; index++) {
            if (array[index] && array[index].id && +array[index].id === +key && array[index].parentInfo) {
              arr.push(array[index])
              break;
            }
            if (array[index]?.children?.length) {
              loopTree(array[index].children, key)
            }
          }
        }
        keys.forEach(element => {
          loopTree(this.dataList, element)
        });
        this.selectUserList = arr
        this.$emit('on-select-change', this.selectUserList)
      },
      /* 根据架构类型返回不同图标字符串 */
      getIcon(data) {
        const group = [1, 2]
        const company = [3, 4]
        const project = [5, 6]
        const section = [7]
        if (group.indexOf(+data.type) !== -1 ) {
          return '#iconjituan'
        } else if (company.indexOf(+data.type) !== -1 ) {
          return '#icongongsi'
        } else if (project.indexOf(+data.type) !== -1 ) {
          return '#iconxiangmu'
        } else if (section.indexOf(+data.type) !== -1 ) {
          return '#iconbumen'
        }
        return '#iconrenyuan1'
      },
      /* 删除单个勾选 */
      deleteClick(data){
        for (let index = 0; index < this.showTreeKeys.length; index++) {
          if (+this.showTreeKeys[index] === +data.id) {
            this.showTreeKeys.splice(index, 1)
          }
        }
        this.getSelectUsers(this.showTreeKeys)
        this.$refs.treeRef?.setCheckedKeys(this.showTreeKeys)
      },
      /* 回显数据处理 */
      showTreeInfo(arr) {
        if (arr && arr.length && this.dataList && this.dataList.length) {
          let keys = []
          this.selectUserList = []
          this.showTreeKeys = []
          arr.forEach(element => {
            const showTree = (arr) => {
              for(var i = 0;i<arr.length;i++){
                if (+arr[i].id === +element.id && arr[i].parentInfo) {
                  keys.push(arr[i].id)
                  this.selectUserList.push(arr[i])
                  break
                } else if (arr[i].children && arr[i].children.length) {
                  showTree(arr[i].children)
                }
              }
            }
            showTree(this.dataList)
          });
          this.showTreeKeys = keys
          this.$nextTick(() => {
            this.$refs.treeRef?.setCheckedKeys(this.showTreeKeys)
          })
        } else {
          this.selectUserList = []
          this.$nextTick(() => {
            this.$refs.treeRef?.setCheckedKeys(this.showTreeKeys)
          })
        }
      },
      /* 清除当前所有选中数据及状态 */
      clearChoose() {
        this.selectUserList = []
        this.showTreeKeys = []
        this.$nextTick(() => {
          this.$refs.treeRef?.setCheckedKeys(this.showTreeKeys)
        })
      }
    }
  }
</script>
<style lang='less' scoped>
  .custom-tree-label {
    display: flex;
    align-items: center;
    height: 20px;
    .custom-tree-svg {
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 4px;
      width: 20px;
      height: 20px;
      svg {
        width: 20px;
        height: 20px;
      }
      img {
        width: 20px;
        height: 20px;
      }
      .user-img {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #0076ff;
        line-height: 200px;
        text-align: center;
        color: #fff;
      }
    }
  }
  .cascader {
    width: 100%;
    height: 36px;
    /* 修改select组件样式 */
    .new-select-style {
      width: 100%;
      height: 36px;
      display: block;
      :deep(.el-input__suffix)  {
        display: none !important;
      }
      :deep(.el-input__inner)  {
        display: none !important;
      }
      :deep(.el-input__prefix)  {
        left: 0;
        width: 100% !important;
        display: inherit !important;
        height: auto !important;
        position: inherit !important;
        box-sizing: border-box !important;
      }
      :deep(.el-input--suffix)  {
        width: 100% !important;
        display: inherit !important;
        height: auto !important;
        position: inherit !important;
        box-sizing: border-box !important;
      }
      .show-value {
        height: 32px;
        line-height: 32px;
        border: 1px solid #dcdfe6;
        border-radius: 2px;
        padding: 0 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #999;
        cursor: pointer;
      }
      .choose-list {
        width: 100%;
        height: auto;
        .choose-list-item {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 8px 0;
          padding: 0 10px;
          box-sizing: border-box;
          height: 24px;
          .choose-list-label {
            color: #000;
          }
          .choose-list-icon {
            width: 14px;
            height: 14px;
            line-height: 14px;
            margin-top: 7px;
            border-radius: 50%;
            overflow: hidden;
            text-align: center;
            background-color: #c1c7cd;
            color: #fff;
            i {
              font-size: 12px;
              cursor: pointer;
            }
          }
        }
      }
      .choose-button {
        width: 100%;
        height: 32px;
        background-color: #E8F0FE;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 0 10px;
        border-radius: 4px;
        line-height: 32px;
        font-weight: regular;
        color: #2A2F3D;
        cursor: pointer;
        margin: 10px 0;
      }
    }
  }
  /* 弹出样式 */
  .pover {
    width: 100%;
    height: 100%;
    font-size: 14px;
    min-width: 460px;
    .pover-content {
      width: 100%;
      height: 100%;
      display: flex;
      overflow-y: auto;
      padding: 10px;
      box-sizing: border-box;
      .content-left {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        .content-left-search {
          height: 50px;
          padding: 5px 10px;
          box-sizing: border-box;
        }
        .content-left-tree {
          padding: 0 10px;
          flex: 1;
          overflow-y: auto;
          overflow-x: hidden;
          .archi-label {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            overflow: hidden;
            -moz-user-select:none; /* Firefox私有属性 */
            -webkit-user-select:none; /* WebKit内核私有属性 */
            -ms-user-select:none; /* IE私有属性(IE10及以后) */
            -khtml-user-select:none; /* KHTML内核私有属性 */
            -o-user-select:none; /* Opera私有属性 */
            user-select:none; /* CSS3属性 */
            .archi-label-svg {
              width: 100%;
              display: flex;
              align-items: center;
              overflow: hidden;
              span {
                flex: 1;
                overflow: hidden;
                padding-right: 6px;
                box-sizing: border-box;
              }
              svg {
                width: 16px;
                height: 16px;
                margin-right: 6px;
              }
            }
          }
        }
      }
      .content-right {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        .content-right-top {
          height: 50px;
          line-height: 40px;
          padding: 0 10px;
          box-sizing: border-box;
          .content-right-top-number {
            color: #0076ff;
          }
        }
        .content-right-choose {
          flex: 1;
          padding: 0 10px;
          overflow-y: auto;
          .content-show {
            width: 100%;
            height: 50px;
            display: flex;
            overflow: hidden;
            .content-show-img {
              width: 50px;
              height: 50px;
              box-sizing: border-box;
              padding: 6px;
              .content-show-seat {
                background: #eee;
                width: 100%;
                height:100%;
                border-radius: 4px;
                overflow: hidden;
              }
              img {
                width: 100%;
                height: 100%;
                border-radius: 4px;
                overflow: hidden;
              }
            }
            .content-show-name {
              flex: 1;
              height: 50px;
              overflow: hidden;
              display: flex;
              flex-direction: column;
              p {
                height: 25px;
                line-height: 25px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              p:nth-child(2) {
                font-size: 12px;
                color: #999;
              }
            }
            .content-show-delete {
              width: 10%;
              height: 50px;
              line-height: 50px;
              text-align: center;
            }
          }
        }
      }
    }
  }
</style>
