<!--
    @name: widget-text
    @description：widget-text
    @author: ZengWei
    @date: 2022-03-25 09:45
-->
<template>
  <div>
    <div v-if="element.config.__config__.isTitle" class="type-label">{{ element.config.__config__.label }}</div>
    <div
      v-else
      :class="className"
      :style="{ textIndent: element.config.__config__.labelWidth + 'px' }"
    >{{ element.config.__config__.label }}
    </div>
  </div>
</template>

<script>
export default {
  name: "WidgetText",
  components: {},
  props: ['element', 'disabled'],
  data() {
    return {}
  },
  computed: {
    className() {
      const className = {
        'normal-label': true,
        'text-left': this.element.config.__config__.align === 'left',
        'text-center': this.element.config.__config__.align === 'center',
        'text-right': this.element.config.__config__.align === 'right'
      }
      return className
    }
  },
  methods: {},
}
</script>

<style scoped>
.type-label {
  /* border-left: 3px solid #007FE0 !important;
  padding: 0 10px;
  line-height: 20px;
  font-size: 14px;
  margin: 15px 0 !important;
  font-weight: 600;
  text-align: left; */
  border-left: 8px solid #7FAAF5 !important;
  border-top: 8px solid transparent;
  height: 40px;
  background: linear-gradient(270deg, rgba(232,240,254,0) 0%, #E8F0FE 100%);
  background-clip: padding-box;
  padding: 5px 16px;
  box-sizing: border-box;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #5891F5;
  line-height: 22px;
  margin-bottom: 10px;
}

.normal-label {
  font-size: 14px;
  color: #606266;
  line-height: 40px;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}
</style>
