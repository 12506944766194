/*eslint-disable */
import { uniqid } from '@/utils/index.js'
import {
  containWeekDate,
  containMonthDate,
  containYearDate
} from './dateHelper.js'

const ganttMixin = {
  data () {
    return {
      lineOverlay: {}, // 进度对比
      frontLine: {}, // 前锋线
      scalePaddings: {},
      userList: [], // 人员列表
			ganttConfig:null,
			columns:[],
    }
  },
  methods: {
    setColumns(data){
			if(data.ganttConfig){
				this.ganttConfig = data.ganttConfig
			}
			if(data.fieldConfig?.length){
				data.fieldConfig.forEach((item)=>{
					if(item.show){
						this.columns.push(
							{
								name: item.uuid,
								label: item.label,
								align: item.align,
								width: item.width || 130,
								resize: true,
							},
						)
					}
				})
			}	
				//选择框显示
			if(data.ganttConfig?.checkbox){
				this.columns.unshift({
					name: 'select',
					label: '',
					width: 60,
					min_width: 60,
					max_width: 60,
					align: 'center',
					template: (task) => {
						return `<label  class="el-checkbox"><span onclick="window.onGanttAction('checkTask',${task.id})" class="el-checkbox__input  ${
							task.check == 'indeterminate' ? 'is-indeterminate' : task.check ? 'is-checked' : ''
						}" >
						<span class="el-checkbox__inner"  ></span>
						<input  type="checkbox" class="el-checkbox__original" value="" />
						</span></label>`
					}
				})
			}
			
			// this.columns.push({ name: 'add', label: '', width: 26 },)
			//新增编辑删除显示
			if(data.actionConfig && data.actionConfig.needAction){
				this.columns.push({
					name: 'options',
					label: '操作',
					width: 160,
					max_width: 250,
					min_width: 80,
					align: 'center',
					template: function (task) {
						let actionBtn = ''
						if(data.actionConfig){
							for(let key in data.actionConfig){
								if(data.actionConfig[key].use){
									actionBtn += `<span style="color:${data.actionConfig[key].color};cursor: pointer;font-size:12px;margin-left:10px" onclick="window.onGanttAction(${data.actionConfig[key].type},${task.id})">${data.actionConfig[key].name}</span>`
								}
							}
						}
						return actionBtn
					}
				})
			}
		},
    initGantt (tasks) {
      const _self = this
      this.ganttId = uniqid() // 唯一key
      gantt.config.sort = true // 按钮排序
      gantt.config.drag_move = false // 禁止左右拖动
      gantt.config.drag_resize = false // 禁止拖动工期
      gantt.config.drag_links = false // 禁止拖动连接
      gantt.config.drag_progress = false // 禁止拖动进度
      // gantt.config.show_progress = true;
      gantt.config.xml_date = '%Y-%m-%d'
      gantt.config.task_date = '%Y-%m-%d'
      gantt.config.duration_unit = 'day'
      gantt.config.fit_tasks = true
      gantt.config.scale_height = 35 // default: 35
      gantt.config.auto_scheduling = true
      gantt.config.auto_scheduling_strict = false
      // gantt.config.show_slack = false;
      gantt.config.task_height = 20 // default: null
      gantt.config.row_height = 35 // default: 35
      const zoomConfig = {
        levels: [
          { name: 'day', scales: [{ unit: 'day', step: 1, format: '%M%d ' }] },
          {
            name: 'week',
            scale_height: 50,
            min_column_width: 50,
            scales: [
              {
                unit: 'week',
                step: 1,
                format: function (date) {
                  const dateToStr = gantt.date.date_to_str('%d %M')
                  const endDate = gantt.date.add(date, -6, 'day')
                  const weekNum = gantt.date.date_to_str('%W')(date)
                  return '#' + weekNum + ', ' + dateToStr(date) + ' - ' + dateToStr(endDate)
                }
              }
            ]
          },
          { name: 'month', scales: [{ unit: 'month', format: '%F, %Y' }] },
          {
            name: 'quarter',
            scales: [{ unit: 'quarter', step: 1, format: '%M' }]
          },
          { name: 'year', scales: [{ unit: 'year', step: 1, format: '%Y' }] }
        ]
      }
      gantt.ext.zoom.init(zoomConfig)
      gantt.ext.zoom.setLevel(2)
      gantt.config.columns = this.columns
      gantt.templates.leftside_text = function (start, end, task) {
        // let priority = '正常'
        // if (Number(task.lagging) > 0) priority = '滞后'
        // if (Number(task.lagging) < 0) priority = '提前'
        // return priority
      }
      gantt.templates.progress_text = function (start, end, task) {
        return (task.progress * 100).toFixed(1) + '%'
      }
      gantt.templates.task_text = function (start, end, task) {
        return task[_self.ganttConfig.textField]
      }
      gantt.templates.task_class = function (start, end, task) {
        let priority = ''
        if (task.option?.length && task.option[0].type_id === 'sectional_works') priority = 'fenxianggongcheng'
        return priority
      }
      gantt.templates.tooltip_text = function (start, end, task) {
        if (task.start_date != null && task.end_date != undefined) {
					const dateStr = gantt.date.date_to_str('%Y-%m-%d')
					const start_date = dateStr(task.start_date)
					const end_date = dateStr(task.end_date)
          return '<b>计划开工：</b> ' + start_date + '<br/><b>计划完工：</b> ' + end_date
        }
      }

      gantt.attachEvent('onTaskLoading', function (task) {
        if (task.actual_start_date) {
          task.actual_start_date_copy = gantt.date.parseDate(task.actual_start_date, 'xml_date')
        } else {
          task.actual_start_date_copy = null
        }
        if (task.actual_end_date) {
          task.actual_end_date = gantt.date.parseDate(task.actual_end_date, 'xml_date')
        }
        return true
      })
			//选中任务
			gantt.attachEvent("onTaskClick", function(id){
				//any custom logic here
				_self.onGanttAction('clickTask',id)
				return true;
			});
      gantt.templates.rightside_text = function (start, end, task) {
        // if (task.actual_end_date) {
        //   if (end.valueOf() > task.actual_end_date.valueOf()) {
        //     const overdue = Math.ceil(
        //       Math.abs((end.getTime() - task.actual_end_date.getTime()) / (24 * 60 * 60 * 1000))
        //     )
        //     const text = '<b>提前: ' + overdue + ' 天</b>'
        //     return text
        //   } else {
        //     const overdue = Math.ceil(
        //       Math.abs((task.actual_end_date.getTime() - end.getTime()) / (24 * 60 * 60 * 1000))
        //     )
        //     const text = '<b>超期: ' + overdue + ' 天</b>'
        //     return text
        //   }
        // }
      }
      gantt.templates.grid_row_class = function (start, end, task) {
        if (task.actual_start_date && task.actual_start_date != null) {
          if (!task.actual_end_date || task.actual_end_date == null) {
            return 'orange'
          } else {
            return 'green'
          }
        } else {
          return 'grey'
        }
      }

      gantt.init(this.$refs.gantt) // 初始化
      gantt.clearAll() // 清楚原有数据
      gantt.parse(tasks) // 数据解析
      gantt.performAction = function (actionName) {
        _self.$emit('actionTasks', actionName)
      }
			let subtaskDates = gantt.getSubtaskDates(), dateformat = gantt.templates.task_date
      _self.min_date = dateformat(subtaskDates.start_date)
      _self.max_date = dateformat(subtaskDates.end_date)
			_self.totalDate = (Date.parse(_self.max_date) - Date.parse(_self.min_date))/(1*24*60*60*1000);
      gantt.attachEvent('onBeforeLightbox', function (id,e) {
				var evt = e ? e : window.event;
				if(evt.stopPropagation) { //W3C 
						evt.stopPropagation();
				} else { //IE      
						evt.cancelBubble = true;
				}
        const task = gantt.getTask(id)
        _self.taskId = id
        _self.taskItem = task
				_self.onGanttAction('addTask',id)
        return false
      })
			//解决前锋线滚动位置不变的问题
			gantt.attachEvent("onGanttScroll", function (left, top) {
				if(_self.overLineMode){
					let getScrollState = gantt.getScrollState()
					let ids = gantt.ext.overlay.getOverlaysIds();
					if(ids && ids.length){
							gantt.ext.overlay.showOverlay(ids[1])
							gantt.ext.overlay.refreshOverlay(ids[1])
					}
					if(document.getElementsByClassName('gantt_overlay')[1]?.style){
						document.getElementsByClassName('gantt_overlay')[1].style.top = -(getScrollState.y) +'px'
						document.getElementsByClassName('gantt_overlay')[1].style.left = 0+'px'
					}
				}
			})
      gantt.attachEvent('onBeforeTaskDisplay', function (id, task) {
        let status1 = true
        let status2 = true
        let status3 = true
        let status4 = true
        let status5 = true
        let isFilter = false

        if (_self.filterText) {
          isFilter = true
          status1 = task.name.indexOf(_self.filterText) > -1
        }
        // 分部分项筛选
        if (_self.filterOptionType) {
          isFilter = true
          status5 = task.option_type == _self.filterOptionType
        }

        if (_self.filterValue[0] && _self.filterValue[1]) {
          isFilter = true
          const curStartDate = new Date(_self.filterValue[0])
          const curEndDate = new Date(_self.filterValue[1])
          if (curStartDate >= task.start_date && curStartDate <= task.end_date) {
            status2 = true
          } else if (curEndDate <= task.end_date && curStartDate >= task.start_date) {
            status2 = true
          }
        } else if (_self.localWeek) {
          isFilter = true
          status2 = containWeekDate(task.start_date, task.end_date)
        } else if (_self.localMonth) {
          isFilter = true
          status2 = containMonthDate(task.start_date, task.end_date)
        } else if (_self.localYear) {
          isFilter = true
          status2 = containYearDate(task.start_date, task.end_date)
        }

        if (!isNaN(_self.filterStage) && _self.filterStage > 0) {
          isFilter = true
          status3 = task.gantt_tasks_stage_id == _self.filterStage
        }

        if (_self.filterTaskStatus === 0 || _self.filterTaskStatus === 1) {
          isFilter = true
          status4 = task.is_fulfill == _self.filterTaskStatus
        }

        if (isFilter) {
          return status1 && status2 && status3 && status4 && status5
        } else {
          return true
        }
      })

      gantt.ext.zoom.attachEvent('onAfterZoom', (level, config) => {
        this.scalePaddings = this.getScalePaddings()
        switch (level) {
          case 0:
            this.zoomName = '日'
            break
          case 1:
            this.zoomName = '周'
            break
          case 2:
            this.zoomName = '月'
            break
          case 3:
            this.zoomName = '季'
            break
          case 4:
            this.zoomName = '年'
            break
        }
      })

      let dateToStr = gantt.date.date_to_str('%F %j, %Y')
      let today = new Date()
      const endDate = this.tasks.data[this.tasks.data.length - 1].end_date
      this.todayMarker = gantt.addMarker({
        start_date: _self.overLineData,
        css: 'today',
        text: _self.isOverLineMode?'今天':'今天',
      })
      // let projectEnd = new Date(endDate)
      // gantt.addMarker({
      //   start_date: projectEnd,
      //   text: '计划完成',
      //   title: '计划完成: ' + dateToStr(today)
      // })

      gantt.config.open_tree_initially = true
      // 进度对比
      this.lineOverlay = gantt.ext.overlay.addOverlay((container) => {
        let scaleLabels = []
        let chartScale = this.getChartScaleRange()
        chartScale.forEach(function (date) {
          scaleLabels.push(dateToStr(date))
        })
        let values = this.getProgressLine(today)
        let canvas = document.createElement('canvas')
        container.appendChild(canvas)
        canvas.style.height = container.offsetHeight + 'px'
        canvas.style.width = container.offsetWidth + 'px'
        let ctx = canvas.getContext('2d')
        let _self = this
        let myChart = new Chart(ctx, {
          type: 'line',
          data: {
            datasets: [
              {
                label: '计划进度',
                backgroundColor: '#001eff',
                borderColor: '#001eff',
                data: values.planned,
                fill: false,
                cubicInterpolationMode: 'monotone'
              },
              {
                label: '实际进度',
                backgroundColor: '#ff5454',
                borderColor: '#ff5454',
                data: values.real,
                fill: false,
                cubicInterpolationMode: 'monotone'
              },
              {
                label: '实际进度（预计）',
                backgroundColor: '#ff5454',
                borderColor: '#ff5454',
                data: values.predicted,
                borderDash: [5, 10],
                fill: false,
                cubicInterpolationMode: 'monotone'
              }
            ]
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            layout: {
              padding: _self.scalePaddings
            },
            onResize: (chart, newSize) => {
              let dataRange = gantt.getSubtaskDates()
              if (dataRange.start_date) {
                // align chart with the scale range
                chart.options.layout.padding = _self.scalePaddings
              }
            },
            legend: {
              display: false
            },
            tooltips: {
              mode: 'index',
              intersect: false,
              callbacks: {
                label: function (tooltipItem, data) {
                  let dataset = data.datasets[tooltipItem.datasetIndex]
                  return dataset.label + ': ' + dataset.data[tooltipItem.index] + '%'
                }
              }
            },
            hover: {
              mode: 'nearest',
              intersect: true
            },
            scales: {
              xAxes: [
                {
                  labels: scaleLabels,
                  gridLines: {
                    display: false
                  },
                  ticks: {
                    display: false
                  }
                },
                {
                  position: 'top',
                  labels: scaleLabels,
                  gridLines: {
                    display: false
                  },
                  ticks: {
                    display: false
                  }
                }
              ],
              yAxes: [
                {
                  display: true,
                  gridLines: {
                    display: false
                  },
                  ticks: {
                    display: true,
                    min: 0,
                    max: 100,
                    stepSize: 5,
                    callback: function (current) {
                      if (current > 100) {
                        return ''
                      }
                      return current + '%'
                    }
                  }
                },
                {
                  display: true,
                  position: 'right',
                  gridLines: {
                    display: false
                  },
                  ticks: {
                    display: true,
                    min: 0,
                    max: 100,
                    stepSize: 5,
                    callback: function (current) {
                      if (current > 100) {
                        return ''
                      }
                      return current + '%'
                    }
                  }
                }
              ]
            }
          }
        })
        return canvas
      })
      // 前锋线

      this.frontLine = gantt.ext.overlay.addOverlay((container) => {
        const canvas = document.createElement('canvas')
        container.appendChild(canvas)
        const ratio = Math.max(window.devicePixelRatio || 1, 1)
        canvas.height = document.getElementsByClassName('gantt_task_bg')[0].offsetHeight * ratio
        canvas.width = container.offsetWidth * ratio

        const ctx = canvas.getContext('2d')
        const arr = this.getMarkerTaskPoint()
				const pointArr = arr.sort((a,b) => a[1] - b[1])
        if (pointArr.length != 0) {
          pointArr.forEach((item, index) => {
            if (index === 0) {
              ctx.moveTo(item[0], item[1])
            } else {
              ctx.lineTo(item[0], item[1])
            }
            ctx.lineWidth = 1
            ctx.strokeStyle = '#DF0015'
            ctx.stroke()
          })
          return canvas
        }
      })
    },
    getChartScaleRange () {
      let tasksRange = gantt.getSubtaskDates()
      let cells = []
      let scale = gantt.getScale()
      if (!tasksRange.start_date) {
        return scale.trace_x
      }

      scale.trace_x.forEach(function (date) {
        if (date >= tasksRange.start_date && date <= tasksRange.end_date) {
          cells.push(date)
        }
      })
      return cells
    },
    getProgressLine (today) {
      let tasks = gantt.getTaskByTime()
      let scale = gantt.getScale()
      let step = scale.unit

      let timegrid = {}

      let totalDuration = 0

      gantt.eachTask(function (task) {
        if (gantt.isSummaryTask(task)) {
          return
        }
        if (!task.duration) {
          return
        }

        let currDate = gantt.date[scale.unit + '_start'](new Date(task.start_date))
        while (currDate < task.end_date) {
          let date = currDate
          currDate = gantt.date.add(currDate, 1, step)

          if (!gantt.isWorkTime({ date: date, task: task, unit: step })) {
            continue
          }

          let timestamp = currDate.valueOf()
          if (!timegrid[timestamp]) {
            timegrid[timestamp] = {
              planned: 0,
              real: 0
            }
          }

          timegrid[timestamp].planned += 1
          if (date <= today) {
            const progress = task.progress == '' ? 0 : task.progress / 100
            timegrid[timestamp].real += 1 * progress
          }

          totalDuration += 1
        }
      })

      let cumulativePlannedDurations = []
      let cumulativeRealDurations = []
      let cumulativePredictedDurations = []
      let totalPlanned = 0
      let totalReal = 0

      let chartScale = this.getChartScaleRange()
      let dailyRealProgress = -1
      let totalPredictedProgress = 0
      for (let i = 0; i < chartScale.length; i++) {
        let start = new Date(chartScale[i])
        let cell = timegrid[start.valueOf()] || { planned: 0, real: 0 }
        // console.log(cell)
        totalPlanned = cell.planned + totalPlanned

        cumulativePlannedDurations.push(totalPlanned)
        if (start <= today) {
          totalReal = (cell.real || 0) + totalReal
          cumulativeRealDurations.push(totalReal)
          cumulativePredictedDurations.push(null)
        } else {
          if (dailyRealProgress < 0) {
            dailyRealProgress = totalReal / cumulativeRealDurations.length
            totalPredictedProgress = totalReal
            cumulativePredictedDurations.pop()
            cumulativePredictedDurations.push(totalPredictedProgress)
          }
          totalPredictedProgress += dailyRealProgress
          cumulativePredictedDurations.push(totalPredictedProgress)
        }
      }

      for (let i = 0; i < cumulativePlannedDurations.length; i++) {
        cumulativePlannedDurations[i] = Math.round(
          (cumulativePlannedDurations[i] / totalPlanned) * 100
        )
        if (cumulativeRealDurations[i] !== undefined) {
          cumulativeRealDurations[i] = Math.round(
            (cumulativeRealDurations[i] / totalPlanned) * 100
          )
        }

        if (cumulativePredictedDurations[i] !== null) {
          cumulativePredictedDurations[i] = Math.round(
            (cumulativePredictedDurations[i] / totalPlanned) * 100
          )
        }
      }
      return {
        planned: cumulativePlannedDurations,
        real: cumulativeRealDurations,
        predicted: cumulativePredictedDurations
      }
    },
    getScalePaddings () {
      let scale = gantt.getScale()
      let dataRange = gantt.getSubtaskDates()

      let padding = {
        left: 0,
        right: 0
      }

      if (dataRange.start_date) {
        let yScaleLabelsWidth = 48
        padding.left = gantt.posFromDate(dataRange.start_date) - yScaleLabelsWidth
        padding.right =
          scale.full_width - gantt.posFromDate(dataRange.end_date) - yScaleLabelsWidth
        padding.top = gantt.config.row_height - 12
        padding.bottom = gantt.config.row_height - 12
      }
      return padding
    },
  }
}

export default ganttMixin
