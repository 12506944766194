<!--
 * @Author: zx
 * @Date: 2022-08-30 14:48:01
 * @LastEditTime: 2022-09-30 17:31:08
 * @Description: 
 * @LastEditors: zx
-->
<template>
	<div class="group-item" :style="{ ...styleObj}">
		<h4 class="title">{{ title }}</h4>
		<div class="item-content">
			<template v-if="dataList && dataList.length">
				<section
					class="item"
					v-for="item in dataList"
					:key="item.values.id"
					@click="onItem(item.values)"
					:class="{
						'is-active': cacheArchi ? +cacheArchi.id === +item.values.id : saasTargetArchi && +saasTargetArchi.id === +item.values.id
					}"
				>
					<img src="@/assets/images/logo1.png">
					<p class="label">{{ item.values.short_name || item.values.name }}</p>
					<i class="iconfont iconselect-duigou" v-if="item.values.id === current"></i>
					<i v-if="saasTargetArchi && +saasTargetArchi.id === +item.values.id" class="iconfont iconselect-duigou"></i>
				</section>
			</template>
			<el-empty description="暂无数据" v-else :image-size="90"></el-empty>
		</div>
	</div>
</template>

<script>
import { Empty } from 'element-ui';
import { mapState } from 'vuex';
export default {
	name: 'GroupItem',
	components: {
		'el-empty': Empty
	},
	props: {
		// 标题
		title: {
			type: String,
			required: true,
			default: ''
		},
		// 样式
		styleObj: {
			type: Object,
			require: false,
			default: () => {}
		},
		// 数据列表
		dataList: {
			type: Array,
			required: true,
			default: () => []
		},
		// 当前选中
		current: {
			type: [String, Number],
			required: false
		}
	},
	data() {
		return {
		};
	},
	computed: {
		...mapState(['cacheArchi', 'saasTargetArchi'])
	},
	created() {
		this.$store.commit('setCacheArchi', '')
	},
	methods: {
		/**
		 * @desc: 点击架构
		 * @param {Object} data
		 */
		onItem(data) {
      this.$store.commit('setCacheArchi', data)
		}
	}
};
</script>

<style lang="less" scoped>
@height: 40px;
.group-item {
	height: 100%;
	width: 100%;
	background: #fff;
		position: relative;
	border-radius: 4px;
	.title {
		box-sizing: border-box;
		padding: 10px 16px;
		width: 100%;
		font-size: 12px;
		font-family: PingFangSC, PingFangSC-Medium;
		font-weight: 500;
		text-align: left;
		color: #445e96;
		line-height: 20px;
	}
	.item-content {
		.item {
			display: flex;
			height: @height;
			width: 100%;
			overflow: hidden;
			box-sizing: border-box;
			padding: 10px 16px;
			cursor: pointer;
			&:hover {
				background: #d0e2ff;
			}
			&.is-active {
				background: #d0e2ff;
			}
			img {
				height: 20px;
				width: 20px;
				border-radius: 2px;
				overflow: hidden;
			}
			.label {
				flex: 1;
				box-sizing: border-box;
				padding: 0 8px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				font-size: 14px;
				font-family: PingFangSC, PingFangSC-Regular;
				font-weight: 400;
				text-align: left;
				color: #161616;
				line-height: 20px;
			}
			.iconfont {
				font-size: 14px;
				color: #161616;
				line-height: 20px;
			}
		}
		.el-empty {
			padding: 0;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
}
</style>