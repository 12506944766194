/*
 * @LastEditTime: 2021-12-02 21:28:02
 * @Description: please input some description
 * @FilePath: /dwh_front/src/views/listViewer/actions/baseAction.js
 * @Date: 2021-12-01 21:43:33
 * @Author: lisushuang
 * @LastEditors: lisushuang
 */
/* eslint-disable */
import { Loading } from 'element-ui';

export default class baseAction {

  constructor(){
    this.instance = null
    this.props = null

    this.refreshTag = '__refreshTag__'
  }

  /**
   * @description: 代理调用我的vue实例
   * @param {*} instance 调用我的vue实例
   * @return {*} this
   */  
  bind(instance){
    this.instance = instance
    return this
  }

  /**
   * @description: 有状态行为需要把状态值传进来
   * @param {*} props 有状态行为的状态值
   * @return {*} this
   */  
  data(props){
    this.props = props
    return this
  }


  /**
   * @description: 展示一个全局loading
   * @param {*} title
   * @return {*} void
   */  
  getLoading(title){
    this.loading = Loading.service({ fullscreen: true, text: title ? title : "数据准备中，请稍后" })
  }

  /**
   * @description: 切换路由上的刷新参数，导致监听该标识的组件刷新
   * @param {*} resetPage 是否重置页码
   * 
   * @return {*} void
   */  
  changeRefreshTag(resetPage=false,stayOther=true){
    let query = this.instance.$route.query
    query = JSON.parse(JSON.stringify(query))
    let tag = query.__refresh__
    if(tag == undefined || tag == 'false'){
      tag = true
    }else{
      tag = !tag
    }
    query.__refresh__ = tag
    if(resetPage){
      query.page = 1
    }
    this.instance.$router.push({query:query})
  }
}