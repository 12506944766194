<!--
 * @Description: CommonTableContainer 表格容器（简化配置）
 * @Author: luocheng
 * @Date: 2021-09-22 10:34:11
 * @LastEditors: luocheng
 * @LastEditTime: 2022-10-28 17:50:39
-->
<template>
	<!-- v-loading="loading" -->
	<div
		class="table-container"
		:id="boxId"
		style="flex-direction: column"
	>
		<el-table
			v-if="statusConfig && (!statusConfig.isTreeTable || (statusConfig.isTreeTable && statusConfig.rowKey))"
			:data="tableData"
			:height="height"
			style="width: 100%; border: 1px solid #f2f3f5"
			class="table-box"
			:row-key="getRowKey"
			v-loading="statusConfig.loading"
			:stripe="statusConfig.stripe"
			:border="statusConfig.border"
			:size="statusConfig.size"
			:fit="statusConfig.fit"
			:show-header="statusConfig.showHeader"
			:highlight-current-row="statusConfig.highlightCurrentRow"
			:empty-text="statusConfig.emptyText"
			@selection-change="handleSelectionChange"
			:tree-props="treeProps"
			@row-click="onRowClick"
		>
			<!-- eslint-disable -->
			<el-table-column
				type="selection"
				v-if="statusConfig.canSelect"
				width="55"
			></el-table-column>
			<el-table-column
				v-if="statusConfig.showOrder"
				label="序号"
				type="index"
				width="50"
			></el-table-column>
			<el-table-column
				:label="item.label"
				v-for="(item, index) in fieldConfig"
				:key="item.uuid"
				:align="statusConfig.align"
				:header-align="statusConfig.headerAlign"
				v-if="item.show && validArchiType(item.archiType)"
				:width="item.width"
			>
				<span
					slot-scope="{ row }"
					:style="{ color: row[item.uuid] ? item.color : '#bbb' }"
				>
					<!-- 图片 -->
					<template v-if="item.type === 2">
						<el-image
							:src="row[item.uuid] && row[item.uuid].indexOf('http') > -1 ? row[item.uuid] : defaultImage"
							:previewSrcList="[row[item.uuid] ? (row[item.uuid].toString().replace('_thumb', '')) : defaultImage ]"
							lazy
							fit="cover"
							alt="图片"
							class="image"
							:style="{
								height: (item.imgConfig.height || 60) + 'px',
								width: (item.imgConfig.height || 60) + 'px',
							}"
						></el-image>
					</template>
					<!-- 状态 -->
					<span
						v-else-if="item.type === 3 && row[item.uuid]"
						:style="{
							color:
								filterStatus(row[item.uuid], item.statusOptions)
									.color || item.color,
						}"
					>
						{{
							filterStatus(row[item.uuid], item.statusOptions)
								.label
						}}
					</span>
					<!-- 时间 -->
					<template v-else-if="item.type === 4 && row[item.uuid]">{{ filterTime(row[item.uuid], item.timeFormat, item.connector) }}</template>
					<!-- 富文本 -->
					<template v-else-if="item.type === 5 && row[item.uuid]">
						<div
							class="rich-text"
							v-html="row[item.uuid]"
						></div>
					</template>
					<!-- 富文本 -->
					<template v-else-if="item.type === 6 && row[item.uuid]">
						<a
							:href="row[item.uuid]"
							class="link"
							alt="链接"
						>
							{{
								row[item.uuid]
							}}
						</a>
					</template>
					<!-- switch 开关 -->
					<template v-else-if="item.type === 7">
						<el-switch
							v-model="row[item.uuid]"
							@change="onSwitch(item, row[item.uuid], row)"
						></el-switch>
					</template>
					<!-- 常规显示 -->
					<template v-else>{{ row[item.uuid] || '/' }}</template>
				</span>
			</el-table-column>
			<el-table-column
				v-if="actionConfig && actionConfig.needAction"
				:width="actionConfig.width || ''"
				:align="statusConfig.align"
				:header-align="statusConfig.headerAlign"
				key="actions"
				label="操作"
			>
				<template slot-scope="scope">
					<span
						v-for="(action, key) in actionConfig"
						:key="key"
					>
						<el-button
							v-if="
								typeof action === 'object' &&
								actionConfig[key].use && logicAction(actionConfig[key], scope.row)
							"
							:type="actionConfig[key].btnType"
							:size="actionConfig[key].size"
							:style="{
								color: actionConfig[key].color,
								marginLeft: actionConfig[key].margin + 'px',
							}"
							@click="onAction(key, scope.row, $event)"
						>{{ actionConfig[key].name }}</el-button>
					</span>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination
			v-if="statusConfig.pagination"
			@size-change="onSize"
			@current-change="onPage"
			:current-page="+pager.current_page"
			:page-sizes="[15, 30, 50, 100]"
			:page-size="+pager.per_page"
			layout="total, sizes, prev, pager, next, jumper"
			:total="+pager.total"
		></el-pagination>
		<!-- 导出 -->
		<Spreadsheet
			v-if="showSheet && sheetFormData"
			:formData="sheetFormData"
			:id="exportConfig && exportConfig.exportTemplate"
			:isSave="false"
			:isHidden="true"
			@exportSuccess="onExportSuccess"
			:autoDownLoad="'excel'"
		></Spreadsheet>
	</div>
</template>

<script>
import { dataInterface } from '@/apis/data/index';
import eventBus from '@/plugins/eventBus';
import { $, getComponentById, getQueryValue, getLinkByTemplate } from '@/utils/tools';
import { Image, Pagination, Switch, Table, TableColumn } from 'element-ui';
import { mapState } from 'vuex';
import Spreadsheet from '@/custom-component/common/Spreadsheet';

export default {
	name: 'CommonTableContainer',
	props: {
		element: {
			type: Object,
			required: true,
			default: () => {}
		},
		// 是否为分组
		isGroup: {
			type: Boolean,
		},
		// 当前分组的组件数据
		groupComponents: {
			type: Array,
			default: () => []
		},
    componentList: {
			default: null
    }
	},
	inject: ['EDITOR_pageUUID'],
	components: {
		'el-table': Table,
		'el-table-column': TableColumn,
		'el-pagination': Pagination,
		'el-image': Image,
		'el-switch': Switch,
		Spreadsheet
	},
	data() {
		return {
			// 当前组件唯一Key
			boxId: `table-container-${new Date().getTime()}`,
			// 分页
			pager: {
				current_page: 1,
				per_page: 15,
				total: 0
			},
			// 表格数据
			tableData: [],
			// 表格高度
			height: 250,
			// 配置数据
			metadata: [],
			loading: false,
			// 缓存的搜索条件
			search: [],
			// 周几
			weekDays: ['周天', '周一', '周二', '周三', '周四', '周五', '周六'],
			// 默认图片
			defaultImage: require('@/assets/images/logo1.png'),
			param: {},
			// 树形图
			treeProps: {},
			rowKey: '',
			// 表格弹窗
			showSheet: false,
			// 表格数据
			sheetFormData: null,
			exportConfig: null
		};
	},
	created() {
		if (this.database && !this.hasComponentParam()) {
			const { search = [], param = {} } = this.initParams();
			this.getList(this.database, search, param);
		}
		this.metadata = this.element.metadata || [];
	},
	computed: {
		...mapState(['componentData']),
		// 数据仓库配置
		database() {
			return this.element && this.element.database;
		},
		// 操作类型
		actionConfig() {
			return this.element && this.element.actionConfig;
		},
		// 配置
		statusConfig() {
			return this.element && this.element.statusConfig;
		},
		// 字段配置
		fieldConfig() {
			const configs = this.element && this.element.fieldConfig;
			if (!configs) return [];
			if (!configs && !configs.length) return this.metadata || [];
			const result = [];
			configs.forEach((ele) => {
				if (ele && ele.show) {
					result.push(ele);
				}
			});
			return result;
		}
	},
	mounted() {
		this.$nextTick(() => {
			const box = $(`#${this.boxId}`);
			if (box && box.offsetHeight) {
				this.height = box.offsetHeight - 42;
			}
		});
		// 导出
		eventBus.$on('exportData', (exportConfig) => {
			if (!exportConfig || exportConfig.componentId !== this.element.id) return;
			this.exportConfig = exportConfig;
			this.doExport(exportConfig);
		});
		// 监听请求
		// 配置关联参数的容器才需要监听
		eventBus.$on('databaseTrigger', (data) => {
			if (data.parentId && data.parentId !== this.element.id) return false;
			// if (this.loading) return;
			// 配置时候触发请求
			if (data.componentId === this.element.id && data.isInit) {
				const { search = [], param = {} } = this.initParams();
				this.getList(this.element.database, search, param);
				return;
			}
			// 点击操作时候不更新数据
			if (data.noUpdate) return;
			const { paramsConfig } = this.element.database;
			if (!paramsConfig || !paramsConfig.length) return;
			// 以下步骤是为了避免有多个来源的search需要进行differ 避免检索结果错误情况
			let { search = [], param = {} } = this.initParams();
			// let search =
			// 	this.search && Array.isArray(this.search)
			// 		? JSON.parse(JSON.stringify(this.search))
			// 		: [];
			// const param =
			// 	JSON.parse(JSON.stringify(this.param)) !== '{}' ? this.param : {};
			let isTarget = false;
			paramsConfig.forEach((ele) => {
				if (ele.componentId === data.componentId) {
					// const outputSearch = data.output?.search || data.output || [];
					// if (ele.key !== 'search') {
					// 	if (
					// 		Array.isArray(outputSearch) &&
					// 		!outputSearch.find((out) => out.field_uuid === ele.paramKey)
					// 	) {
					// 		isTarget = false;
					// 		return;
					// 	}
					// }
					// if (outputSearch && outputSearch.length) {
					// 	// 将当前search 中的数据替换为新的筛选项内容
					// 	search = this.formatSearch(this.search || [], outputSearch);
					// }
					// // 树形 fix 待修改
					// if (typeof outputSearch === 'object') {
					// 	this.$set(param, ele.key, outputSearch[ele.paramKey]);
					// } else {
					// 	const componentObj = getComponentById(
					// 		this.componentData,
					// 		ele.componentId
					// 	);
					// 	this.$set(
					// 		param,
					// 		ele.key,
					// 		componentObj && componentObj.resolveData
					// 			? componentObj.resolveData[ele.paramKey]
					// 			: ''
					// 	);
					// }
					isTarget = true;
				}
			});
			if (!isTarget && !data.isUpdate) return;
			this.param = param;
			this.getList(this.element.database, search, param);
		});
	},
	methods: {
		/**
		 * @desc: 根据配置逻辑判断操作是否显示
		 * @param {Object} actionConfig
		 */
		logicAction(actionConfig, row) {
			if (
				!actionConfig.useDepend ||
				!actionConfig ||
				!actionConfig.dependLogic ||
				!actionConfig.dependLogic.length
			)
				return true;
			const dependLogic = actionConfig.dependLogic;
			const logicList = [];
			for (let i = 0, len = dependLogic.length; i < len; i++) {
				const { field, fieldAttr, fieldLogic, itemLogic, value, valueType } =
					dependLogic[i];
				let logicValue = null;
				if (fieldAttr === 'length') {
					logicValue = row[field].length;
				} else {
					logicValue = row[field];
				}
				// 当前项判断条件
				// 字段关系 大于>  小于 < 等于 === 大于等于>=  小于等于 <= 不等于 !=
				let itemBoolean = true;
				if (fieldLogic === '===') {
					if (valueType === 'string') {
						itemBoolean = logicValue === value;
					} else if (valueType === 'number') {
						itemBoolean = +logicValue === +value;
					} else if (valueType === 'date') {
						itemBoolean = new Date(logicValue).getTime() === value;
					}
				}
				if (fieldLogic === '!=') {
					if (valueType === 'string') {
						itemBoolean = logicValue !== value;
					} else if (valueType === 'number') {
						itemBoolean = logicValue !== +value;
					} else if (valueType === 'date') {
						itemBoolean = new Date(logicValue).getTime() !== value;
					}
				} else if (fieldLogic === '>') {
					if (valueType === 'number') {
						itemBoolean = logicValue > +value;
					} else if (valueType === 'date') {
						itemBoolean = new Date(logicValue).getTime() > value;
					}
				} else if (fieldLogic === '<') {
					if (valueType === 'number') {
						itemBoolean = logicValue < +value;
					} else if (valueType === 'date') {
						itemBoolean = new Date(logicValue).getTime() < value;
					}
				} else if (fieldLogic === '<=') {
					if (valueType === 'number') {
						itemBoolean = logicValue <= +value;
					} else if (valueType === 'date') {
						itemBoolean = new Date(logicValue).getTime() <= value;
					}
				} else if (fieldLogic === '>=') {
					if (valueType === 'number') {
						itemBoolean = logicValue >= +value;
					} else if (valueType === 'date') {
						itemBoolean = new Date(logicValue).getTime() >= value;
					}
				}
				logicList.push({
					itemLogic,
					itemBoolean
				});
			}
			if (logicList.length === 1) {
				return logicList[0].itemBoolean;
			}
			// 循环逻辑关系
			let prevLogicBoolean = null;
			for (let j = 1; j < logicList.length; j++) {
				const prev = logicList[j - 1];
				const prevItemLogic = prev.itemLogic;
				if (prevItemLogic === 'and') {
					if (j === 1) {
						prevLogicBoolean = prev.itemBoolean && logicList[j].itemBoolean
					} else {
						prevLogicBoolean = prevLogicBoolean && logicList[j].itemBoolean;
					}
					if (!prevLogicBoolean) {
						return false;
					}
				} else if (prevItemLogic === 'or') {
					if (j === 1) {
						prevLogicBoolean = prev.itemBoolean || logicList[j].itemBoolean
					} else {
						prevLogicBoolean = prevLogicBoolean || logicList[j].itemBoolean
					}
					if (!prevLogicBoolean) {
						return false;
					}
				}
			}
			return true;
		},
		/**
		 * @desc: 判断是否存在依赖其他组件的取值
		 */
		hasComponentParam() {
			if (!this.database.paramsConfig || !this.database.paramsConfig.length)
				return false;
			for (let i = 0; i < this.database.paramsConfig.length; i++) {
				const {
					componentId = '',
					key = '',
					sourceType = ''
				} = this.database.paramsConfig[i];
				if (
					(key !== 'search' || !componentId.includes('CommonForm')) &&
					sourceType !== 'url'
				) {
					// componentId.includes('CommonTableContainer')兼容跨页请求的动态判定
					if (!componentId.includes('CommonTableContainer')) {
						return true;
					}
				}
			}
			return false;
		},
		/**
		 * @desc: 初始化获取参数
		 */
		initParams() {
			const { paramsConfig } = this.element.database;
			if (!paramsConfig || !paramsConfig.length) return {};
			let search = [];
			const param = {};
			paramsConfig.forEach((ele) => {
				// 暂时归类了param ,特殊的search暂时无法处理？？？
				let originComponent = getComponentById(this.componentList || this.componentData, ele.component);
        if (!originComponent && this.isGroup && this.groupComponents.length) {
					originComponent = getComponentById(this.groupComponents, ele.componentId);
				}
				if (ele.key !== 'search') {
					if (ele.sourceType === 'url') {
						// console.log(ele, 'eleeleele');
						// url参数
						this.$set(param, ele.key, getQueryValue(ele.urlParamsKey));
					} else if (originComponent && originComponent.resolveData) {
						this.$set(
							param,
							ele.key,
							originComponent.resolveData[ele.paramKey]
						);
					}
				} else {
					if (
						originComponent.resolveData &&
						Array.isArray(originComponent.resolveData)
					) {
						search = search.concat(originComponent.resolveData);
					}
				}
			});
			// console.log(search, '0000000', param)
			return {
				search,
				param
			};
		},
		/**
		 * @desc: 获取行key
		 */
		getRowKey(row) {
			return row[this.rowKey];
		},
		/**
		 * @desc: 获取渲染列表
		 * @param {Object} database 数据配置对象
		 * @param {Array} search 搜索
		 */
		getList(database, search = [], params = {}) {
			// console.log(database, search, params, '88888888888')
			this.search = Array.isArray(search) ? search : [];
			this.tableData = [];
			if (!this.validDatabase(database)) return;
			this.loading = true;
			const paramsObj = {
				...params,
				page: this.pager.current_page || 1,
				size: this.statusConfig.pagination ? this.pager.per_page || 15 : 15
			};
			// console.log('获取渲染列表______表格容器', params, paramsObj);
			// 配置
			let __method_name__ = 'dataList';
			const mapping = database.mapping;
			let configObj = null;
			if (mapping === 'object') {
				configObj = {
					__method_name__,
					object_uuid: database.objectData.uuid,
					view_uuid: database.viewData.uuid,
					...paramsObj,
					search
				};
			} else if (mapping === 'relation') {
				__method_name__ = 'relationList';
				configObj = {
					__method_name__: 'relationList',
					object_uuid: database.objectData.uuid,
					relationship_uuid: database.relationData.uuid,
					...paramsObj,
					search
				};
			}
			// 获取表格数据
			dataInterface(configObj)
				.then((res) => {
					if (res && res.status === 200) {
						if (!this.metadata || !this.metadata.length) {
							this.metadata = res.data.metadata;
						}
						let tableData = [];
						if (
							__method_name__ === 'dataList' ||
							__method_name__ === 'relationList'
						) {
							// 列表
							// 列表数据
							tableData = this.getListFromRes(res, true) || [];
						}
						for (let i = 0; i < this.fieldConfig.length; i++) {
							const item = this.fieldConfig[i];
							if (item.type === 7) {
								// switch 编辑
								tableData = tableData.map((ele) => {
									if (item.switchOptions.type === 'number') {
										// 数字
										ele[item.uuid] =
											+ele[item.uuid] === +item.switchOptions.trueValue;
									} else if (item.switchOptions.type === 'boolean') {
										if (ele[item.uuid] === 'true') {
											ele[item.uuid] = true;
										} else if (ele[item.uuid] === 'false') {
											ele[item.uuid] = false;
										}
									} else if (item.switchOptions.type === 'string') {
										ele[item.uuid] =
											ele[item.uuid] === item.switchOptions.trueValue;
									}
									return ele;
								});
							}
						}
						// 树形表格
						if (this.statusConfig.isTreeTable) {
							let children = '';
							for (let key in this.metadata) {
								if (this.metadata[key] === this.statusConfig.children) {
									children = key;
								}
								if (this.metadata[key] === 'id') {
									this.rowKey = key;
								}
							}
							tableData = tableData.map((dataItem) => {
								return {
									...dataItem,
									children: dataItem[children] || []
								};
							});
							this.treeProps = {
								children,
								hasChildren: 'hasChildren'
							};
						}
						this.tableData = tableData;
						// 完全数据 暂时保存
						this.fullData = res.data.data;
						// 保存容器数据
						this.$store.commit('modifyComponent', {
							component: {
								...this.element,
								containerData: this.tableData,
								fullData: this.fullData,
								metadata: res.data.metadata,
								database: {
									...this.element.database,
									fieldList: this.getFieldList(res.data.metadata)
								}
							},
							containerId: null,
							isModify: true,
							pageUUID: this.EDITOR_pageUUID
						});
					}
					this.loading = false;
				})
				.catch((err) => {
					console.log(err);
					this.loading = false;
				});
		},
		getFieldList(obj) {
			const arr = [];
			if (obj && typeof obj === 'object') {
				for (let key in obj) {
					arr.push({
						name: obj[key],
						uuid: key
					});
				}
			}
			return arr;
		},
		/**
		 * @desc: 切换switch
		 * @param {Object} item 配置
		 * @param {Boolean} data 当前切换的布尔值
		 * @param {Object} row 当前行
		 */
		onSwitch(item, data, row) {
			this.$loading();
			let idUUID = '';
			for (let key in this.element.metadata) {
				if (this.element.metadata[key] === 'id') {
					idUUID = key;
				}
			}
			// 需要将boolean还原为后端允许的值
			const params = {
				__method_name__: 'updateData',
				object_uuid: this.element.database.objectData.uuid,
				data_id: row[idUUID],
				...row
			};
			const value = data
				? item.switchOptions.trueValue
				: item.switchOptions.falseValue;
			this.$set(params, item.uuid, value);
			dataInterface(params)
				.then((res) => {
					console.log(res);
					this.$loading().close();
					this.onPage(this.pager.current_page);
				})
				.catch((err) => {
					console.log(err);
					this.$loading().close();
				});
		},
		/**
		 * @desc: 选择表格
		 * @param {Array} selectList
		 */
		handleSelectionChange(selectList) {
			this.element.cacheData = selectList || [];
		},
		/**
		 * @desc: 格式化比对收缩内容
		 * @param {Array} search 当前组件中的搜索内容
		 * @param {Array} outputSearch 外部传入的搜索项目
		 */
		formatSearch(search, outputSearch) {
			if (!search.length) return outputSearch;
			let result = search || [];
			for (let i = 0; i < outputSearch.length; i++) {
				const item = outputSearch[i];
				const index = search.findIndex(
					(ele) => ele.field_uuid === item.field_uuid
				);
				if (index === -1) {
					result.push(item);
				} else {
					result.splice(index, 1, item);
				}
			}
			return result;
		},
		/**
		 * @desc: 验证
		 * @param {Object} database 数据仓库的绑定
		 */
		validDatabase(database) {
			if (!database || typeof database !== 'object') return false;
			if (!database.objectData) return false;
			if (!database.viewData && !database.relationData) return false;
			return true;
		},
		/**
		 * @desc: 根据请求返回获取列表结构
		 * @param {Object} res 请求返回的数据
		 * @param {Boolean} withPager 是否需要重置pager
		 */
		getListFromRes(res, withPager = true) {
			if (res.data && Array.isArray(res.data)) {
				if (withPager) {
					this.pager = res;
				}
				return res.data;
			}
			if (res.data && typeof res === 'object') {
				return this.getListFromRes(res.data, withPager);
			}
			return [];
		},
		/**
		 * @desc: 点击行展开详情操作（@廖总）
		 * @param {Object} row 行数据
		 * @param {Object} column
		 */
		onRowClick(row) {
			if (
				!this.actionConfig ||
				!this.actionConfig.detail ||
				!this.statusConfig.rowShowDetails
			)
				return;
			const { detail } = this.actionConfig;
			if (!detail || !detail.eventList || !detail.eventList.length) return;
			// const { eventList = [] } = detail;
			const comEvents = detail.eventList || [];
			for (let i = 0; i < comEvents.length; i++) {
				const { pattern, eventList = [], specialEventList = [] } = comEvents[i];
				if (pattern === undefined) {
					console.log('老版本----', comEvents[i])
					this.onRowClickItem(comEvents[i], row);
					break;
				}
				const result = pattern === 'special' ? specialEventList : eventList
				result.forEach((ele) => {
					this.onRowClickItem(ele, row);
				});
			}
			// for (let i = 0; i < eventList.length; i++) {
			// 	const eventItem = eventList[i];
			// 	this.onRowClickItem(eventItem);
			// }
		},
		onRowClickItem(eventItem, row) {
			const { effects } = eventItem;
				if (effects && effects.length) {
					for (let j = 0; j < effects.length; j++) {
						const effectItem = effects[j];
						const { actions = [], targets = [] } = effectItem;
						if (actions.length && targets.length && actions.includes('show')) {
							targets.forEach((ele) => {
								if (ele.toString().indexOf('CommonDialog-') === 0) {
									this.element.resolveData = row;
									this.$store.commit('triggerEvents', {
										config: {
										...eventItem,
										...effectItem,
										actions: ['hidden']
									},
									element: this.element
									});
									this.$nextTick(() => {
										this.$store.commit('triggerEvents', {
											config: {
												...eventItem,
											...effectItem
											},
											element: this.element
										});
										this.$nextTick(() => {
											const interval = setInterval(() => {
												const drawer = document.querySelector('.common-drawer');
												if (drawer) {
													clearInterval(interval);
													const drawerBox = drawer.parentNode?.parentNode;
													if (drawerBox) {
														drawerBox.classList.add('Editor-drawer__wrapper');
													}
												}
											}, 100);
										});
									});
								}
							});
						}
					}
				}
		},
		/**
		 * @desc: 操作
		 * @param {String} action 操作
		 * @param {Object} output 输出的数据
		 */
		onAction(action, output, e) {
			// console.log(e, '88888888888888', action);
			e.preventDefault();
			e.stopPropagation();
			// 是否为删除
			if (action === 'delete') {
				this.doDelete(output);
				return;
			}
			if (action === 'export') {
				// 导出
				const exportConfig = this.element.exportConfig;
				this.exportConfig = exportConfig;
				this.doExport(exportConfig, output);
				return;
			}
			this.element.resolveData = output;
			// 详情
			if (action === 'detail' && this.statusConfig?.rowShowDetails) {
				this.onRowClick(output);
				return;
			}
			// 关闭所有弹窗及抽屉
			this.removeModel();
			// 事件
			// console.log(this.element.actionConfig, 'this.element.actionConfig');
			const eventList = this.element.actionConfig[action].eventList;
			if (eventList && eventList.length) {
				eventList.forEach((ele) => {
					if (ele.key === 'click') {
						// 跳转页面
						if (ele.actionType === 'jumpPage') {
							if (ele.linkType === 'projectPage') {
								const query = {};
								ele.queryList.forEach((queryItem) => {
									let component = getComponentById(this.componentList || this.componentData, queryItem.componentId);
									if (!component && this.isGroup && this.groupComponents.length) {
										component = getComponentById(this.groupComponents, queryItem.componentId);
									}
									this.$set(
										query,
										queryItem.key,
										component.resolveData[queryItem.feild]
									);
								});
								// this.$router.push({
								//   path: ele.routePath,
								//   query
								// });
							} else if (ele.linkType === 'outPage') {
								window.open(ele.linkUrl);
							} else if (ele.linkType === 'custom') {
								const customLink = getLinkByTemplate(ele.linkTemplate, output)
								window.open(customLink);
							}
						} else {
							ele.effects.forEach((effect) => {
								this.$store.commit('triggerEvents', {
									config: {
										...ele,
									...effect
									},
									element: this.element
								});
							});
						}
					}
				});
			}
			this.$nextTick(() => {
				eventBus.$emit('databaseTrigger', {
					componentId: this.element.id,
					action,
					output,
					isInit: false,
					noUpdate: true
				});
			});
			return false;
		},
		/**
		 * @desc: 移除所有显示弹窗
		 */
		removeModel() {
			if (!this.actionConfig || !this.actionConfig.detail) return;
			const { detail } = this.actionConfig;
			if (!detail || !detail.eventList || !detail.eventList.length) return;
			const { eventList = [] } = detail;
			for (let i = 0; i < eventList.length; i++) {
				const eventItem = eventList[i];
				const { effects } = eventItem;
				if (effects && effects.length) {
					for (let j = 0; j < effects.length; j++) {
						const effectItem = effects[j];
						const { actions = [], targets = [] } = effectItem;
						if (actions.length && targets.length && actions.includes('show')) {
							targets.forEach((ele) => {
								if (ele.toString().indexOf('CommonDialog-') === 0) {
									// console.log(ele, '移除， 888888')
									this.$store.commit('triggerEvents', {
										config: {
											...eventItem,
											...effectItem,
											actions: ['hidden']
										},
										element: this.element
									});
								}
							});
						}
					}
				}
			}
		},
		/**
		 * @desc: 删除
		 * @param {Object} data 删除行的数据
		 */
		doDelete(data) {
			this.$confirm('是否确认删除?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
				center: true
			})
				.then(() => {
					const metadata = this.element.metadata || {};
					let data_id = '';
					for (let key in metadata) {
						if (metadata[key] === 'id') {
							data_id = data[key];
							break;
						}
					}
					this.$loading();
					dataInterface({
						__method_name__: 'deleteData',
						object_uuid: this.element.database.objectData.uuid,
						data_id
					})
						.then((res) => {
							this.$loading().close();
							// 获取数据
							this.onPage(1);
							console.log(res, '---res');
						})
						.catch((err) => {
							this.$loading().close();
							console.log(err, '---err');
						});
				})
				.catch(() => {});
		},
		/**
		 * @desc: 切换页码
		 * @param {Number} current_page 当前页码
		 */
		onPage(current_page) {
			this.pager = {
				...this.pager,
				current_page
			};
			this.getList(this.database, this.search, this.param || {});
		},
		/**
		 * @desc: 切换分页参数
		 * @param {Number} per_page 跳转的页码
		 */
		onSize(per_page) {
			this.pager = {
				...this.pager,
				current_page: 1,
				per_page
			};
			this.getList(this.database, this.search, this.param || {});
		},
		/**
		 * @desc: 获取状态值
		 * @param {any普通类型} value 值
		 * @param {Array} statusOptions 状态选项
		 * @return {Object} 用于状态显示的对象  label value color
		 */
		filterStatus(value, statusOptions = []) {
			if (!statusOptions || !Array.isArray(statusOptions)) {
				return {
					label: value
				};
			}
			for (let i = 0; i < statusOptions.length; i++) {
				if (
					(isNaN(value) && statusOptions[i].value === value) ||
					+statusOptions[i].value === +value
				) {
					return statusOptions[i];
				}
			}
			return {
				label: value
			};
		},
		/**
		 * @desc: 格式化时间
		 * @param {String/Object/Number} value 时间值 兼容时间戳、时间字符串、时间对象
		 * @param {String} timeFormat 格式化
		 * @param {String} connector 连接符
		 */
		filterTime(value, timeFormat, connector = '') {
			if (!value) return value;
			let dateObj = null;
			if (value instanceof Date) {
				dateObj = value;
			} else if (isNaN(value)) {
				dateObj = new Date(value);
			} else {
				dateObj = new Date(value * 1000);
			}
			if (!(dateObj instanceof Date)) return value;
			const year = dateObj.getFullYear();
			let month = this.addZero(dateObj.getMonth() + 1);
			const day = this.addZero(dateObj.getDate());
			const hour = this.addZero(dateObj.getHours());
			const minute = this.addZero(dateObj.getMinutes());
			const second = this.addZero(dateObj.getSeconds());
			let connectorStr = '-';
			if (connector === '/') {
				connectorStr = '/';
			} else if (connector === 'cn') {
				connectorStr = connector;
			}
			const defaultStr = `${year}${
						connectorStr === 'cn' ? '年' : connectorStr
					}${month}${connectorStr === 'cn' ? '月' : connectorStr}${day}${
						connectorStr === 'cn' ? '日' : ''
					} ${hour}${connectorStr === 'cn' ? '时' : ':'}${minute}${
						connectorStr === 'cn' ? '分' : ':'
					}${second}${connectorStr === 'cn' ? '秒' : ''}`;
			switch (timeFormat) {
				case 'YMDhms':
					return defaultStr;
				case 'YMDhm':
					return `${year}${
						connectorStr === 'cn' ? '年' : connectorStr
					}${month}${connectorStr === 'cn' ? '月' : connectorStr}${day}${
						connectorStr === 'cn' ? '日' : ''
					} ${hour}${connectorStr === 'cn' ? '时' : ':'}${minute}${
						connectorStr === 'cn' ? '分' : ''
					}`;
				case 'YMDh':
					return `${year}${
						connectorStr === 'cn' ? '年' : connectorStr
					}${month}${connectorStr === 'cn' ? '月' : connectorStr}${day}${
						connectorStr === 'cn' ? '日' : ''
					} ${hour}${connectorStr === 'cn' ? '时' : ''}`;
				case 'YMD':
					return `${year}${
						connectorStr === 'cn' ? '年' : connectorStr
					}${month}${connectorStr === 'cn' ? '月' : connectorStr}${day}${
						connectorStr === 'cn' ? '日' : ''
					}`;
				case 'YM':
					return `${year}${
						connectorStr === 'cn' ? '年' : connectorStr
					}${month}${connectorStr === 'cn' ? '月' : connectorStr}`;
				case 'MD':
					return `${month}${connectorStr === 'cn' ? '月' : connectorStr}${day}${
						connectorStr === 'cn' ? '日' : ''
					}`;
				case 'hms':
					return `${hour}${connectorStr === 'cn' ? '时' : ':'}${minute}${
						connectorStr === 'cn' ? '分' :  ':'
					}${second}${connectorStr === 'cn' ? '秒' : ''}`;
				case 'hm':
					return `${hour}${connectorStr === 'cn' ? '时' :  ':'}${minute}${
						connectorStr === 'cn' ? '分' : ''
					}`;
				case 'yyyy':
					return `${year}${connectorStr === 'cn' ? '年' : ''}`;
				case 'mm':
					return `${month}${connectorStr === 'cn' ? '月' : ''}`;
				case 'dd':
					return `${day}${connectorStr === 'cn' ? '日' : ''}`;
				case 'weekDay':
					return this.weekDays[dateObj.getDay()];
				case 'week':
					return `第${this.getWeek(dateObj)}周`;
				default:
					return defaultStr;
			}
		},
		/**
		 * @desc: 补零
		 * @param {Number} val 需要补零的值
		 */
		addZero(val) {
			if (isNaN(val) || val < 0 || val >= 10) return val;
			return `0${val}`;
		},
		/**
		 * @desc: 获取当前日期对象为全年第几周
		 * @param {Object<Date>} dateObj 时间对象
		 * @return {Number} 第几周
		 */
		getWeek(dateObj) {
			const firstDay = new Date(`${dateObj.getFullYear()}-1-1`);
			const timeBetween = dateObj - firstDay;
			const between = timeBetween / (7 * 24 * 60 * 60 * 1000);
			return Math.ceil(between);
		},
		/**
		 * @desc: 验证架构类型
		 * @param {Array, String} types 类型 group company project
		 * @return {*}
		 */
		validArchiType(types) {
			if (!types || !Array.isArray(types) || !types.length) return true;
			if (this.$GetUserInfo('name') === 'admin') return true;
			const archiType = this.$GetTargetArchi('archiType');
			if (archiType && types.includes(archiType)) {
				return true;
			}
			return false;
		},
		/**
		 * @desc: 导出
		 * @param {Object} config 配置
		 */
		async doExport(config, rowData = null) {
			const loading = this.$loading({
				text: '导出中....'
			});
			const { exportType = 'all' } = config;
			let idUUID = '';
			for (let key in this.metadata) {
				if (this.metadata[key] === 'id') {
					idUUID = key;
				}
			}
			if (!idUUID) {
				loading.close();
				this.$message.error('导出失败！数据验证错误。');
				return;
			}
			/* eslint-disable */
			// exportType 导出配置 all 全部， targetPage 本页，checked 当前选中
			// 最终导出数据
			let exportData = [];
			// 导出全部
			let param = {};
			let search = [];
			const exportIds = [];
			if (exportType === 'targetPage') {
				if (!this.tableData || !this.tableData.length) {
					loading.close();
					this.$message.error('暂无数据可供导出！');
					return;
				}
				// 本页
				this.tableData.forEach((ele) => {
					exportIds.push(ele[idUUID]);
				});
			} else if (exportType === 'checked') {
				if (!this.element.cacheData || !this.element.cacheData.length) {
					loading.close();
					this.$message.error('请选择需要导出的数据！');
					return;
				}
				// 当前选中
				this.element.cacheData.forEach((ele) => {
					exportIds.push(ele[idUUID]);
				});
			}
			if (exportIds.length) {
				search.push({
					field_uuid: idUUID,
					ruleType: 'in',
					value: exportIds
				});
			}
			// 带查询参数的全体数据
			if (exportType === 'searchAll') {
				const queryData = this.initParams();
				param = queryData.param || {};
				search = search.concat(queryData.search || []);
			} else if (exportType === 'targetRow') {
				param = {
					data_id: rowData?.[idUUID]
				};
			}
			// console.log(param, '0000000000', search, exportType);
			// console.log( 'idUUID' ,idUUID, rowData, 'this.metadata', this.metadata, '99999999999999')
			exportData = await this.getExportData(
				this.database,
				param,
				search,
				config
			);
			// console.log('导出的数据', '-----', exportData);
			if (!exportData || (Array.isArray(exportData) && !exportData.length)) {
				loading.close();
				this.$message.error('暂无数据可供导出！');
				return;
			}
			this.showSheet = true;
			this.sheetFormData = {
				[config.objectUUID]: exportData
			};
			// console.log(this.sheetFormData, '-sheetFormData');
		},
		/**
		 * @desc: 获取导出数据
		 */
		getExportData(
			database,
			params,
			search,
			{ objectUUID, viewUUID, exportRequest }
		) {
			return new Promise((resolve, reject) => {
				if (!this.validDatabase(database)) return;
				const paramsObj = {
					...params,
					page: 1,
					size: 9999
				};
				// console.log('获取渲染列表______表格容器', params, paramsObj);
				// 配置
				let __method_name__ = exportRequest || 'dataList';
				let configObj = {
					__method_name__,
					object_uuid: objectUUID,
					view_uuid: viewUUID,
					...paramsObj,
					search
				};
				// 获取表格数据
				dataInterface(configObj)
					.then((res) => {
						if (res && res.status === 200) {
							let tableData = [];
							if (
								__method_name__ === 'dataList' ||
								__method_name__ === 'relationList'
							) {
								// 列表
								// 列表数据
								tableData = this.getListFromRes(res, false) || [];
								for (let i = 0; i < this.fieldConfig.length; i++) {
									const item = this.fieldConfig[i];
									if (item.type === 7) {
										// switch 编辑
										tableData = tableData.map((ele) => {
											if (item.switchOptions.type === 'number') {
												// 数字
												ele[item.uuid] =
													+ele[item.uuid] === +item.switchOptions.trueValue;
											} else if (item.switchOptions.type === 'boolean') {
												if (ele[item.uuid] === 'true') {
													ele[item.uuid] = true;
												} else if (ele[item.uuid] === 'false') {
													ele[item.uuid] = false;
												}
											} else if (item.switchOptions.type === 'string') {
												ele[item.uuid] =
													ele[item.uuid] === item.switchOptions.trueValue;
											}
											return ele;
										});
									}
								}
								// 树形表格
								if (this.statusConfig.isTreeTable) {
									let children = '';
									for (let key in this.metadata) {
										if (this.metadata[key] === this.statusConfig.children) {
											children = key;
										}
									}
									tableData = tableData.map((dataItem) => {
										return {
											...dataItem,
											children: dataItem[children] || []
										};
									});
								}
							} else if (__method_name__ === 'dataInfo') {
								// 详情
								tableData = res.data.data || {};
							}
							resolve(tableData);
						}
					})
					.catch((err) => {
						console.log(err);
						reject([]);
					});
			});
		},
		/**
		 * @desc: 导出成功
		 */
		onExportSuccess() {
			this.$loading().close();
			this.showSheet = false;
			this.$message.success('导出成功！');
			this.exportParam = {};
		}
	},
	beforeDestroy() {
		eventBus.$off('databaseTrigger');
		eventBus.$off('exportData');
	}
};
</script>

<style lang="less" scoped>
.table-container {
	height: 100%;
	width: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	.el-table {
		flex: 1;
	}
	.el-pagination {
		box-sizing: border-box;
		padding: 5px 10px;
		text-align: left;
	}
	.image {
		height: 60px;
		width: 60px;
		margin: 0 auto;
		overflow: hidden;
	}
}
</style>
