/*
 * @Description: 容器组件
 * @Author: luocheng
 * @Date: 2021-10-15 14:50:51
 * @LastEditors: zx
 * @LastEditTime: 2022-07-26 16:01:23
 */
// 容器
const containers = {
	type: 'container',
	title: '容器组件',
	show: true,
	componentList: [
		{
			component: 'MobileCommonContainer',
			type: 'container',
			name: '基础容器',
			icon: 'icona-kanbantongjifill',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			span: 24,
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
				borderTopShow: true,
				borderTopWidth: 0,
				borderTopType: '',
				borderTopColor: '#ccc',
				borderRightShow: true,
				borderRightWidth: 0,
				borderRightType: '',
				borderRightColor: '#ccc',
				borderBottomShow: true,
				borderBottomWidth: 0,
				borderBottomType: '',
				borderBottomColor: '#ccc',
				borderLeftShow: true,
				borderLeftWidth: 0,
				borderLeftType: '',
				borderLeftColor: '#ccc',
			},
			propValue: '',
			style: {
				minHeight: '',
				width: '100%',
				height: '100%',
				display: 'flex',
				flexWrap: 'wrap',
				alignItems: 'flex-start',
				alignContent: 'flex-start',
				overflowX: 'hidden',
				overflowY: 'auto'
			},
			// 用于直接渲染的数据
			containerData: null,
			specialStyle: {
				isCollector: false, // 是否设为收集者 flex 1
				flexDirection: 'row', // flex 主轴方向
				flexWrap: false, // 是否换行
				isAbsolute: false, // 灵活布局
				absoluteTop: 0,
				absoluteLeft: 0,
				zIndex: 10
			}
		}
	]
};
export default containers;