<!--
    @name: TaskRecover
    @description：TaskRecover
    @author: ZengWei
    @date: 2022-05-25 16:12
-->
<template>
  <div class="task-flow-container">
    <div class="indictor-todo">
      <b></b> 待处理
    </div>
    <div class="indictor-complete">
      <b></b> 已完成
    </div>
    <div ref="container" class="task-block"></div>

    <el-dialog
      :title="modalTitle"
      :visible.sync="dialogVisible"
      width="60%"
      custom-class="common-dialog"
      append-to-body
    >
      <div class="dialog-main">
        <PcParser
          v-if="dialogVisible && formDesignData"
          ref="dialogForm"
          :form-data="formDesignData"
          :displayData="displayData"
          :editFields="editFields"
          :disabled="formDisabled"
          :needFlow="needFlow"
          :moduleUuid="moduleUuid"
          :formMode="formMode"
          @submit="onFormSubmit"
          @on-close="onEndFlow"
        ></PcParser>
      </div>
      <div slot="footer" v-if="['add','edit'].includes(formMode)" style="text-align: right">
        <el-button type="default" @click="dialogVisible = false" style="margin-right: 10px;">取消</el-button>
        <el-button type="primary" @click="triggerSubmit">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { formDesign } from '@/apis/data/workflow';
import {recoverInitial} from "@/custom-component/task-flow/components/TaskInitializer";
import {dataInterface} from "@/apis/data";
import PcParser from "@/custom-component/form/newParser/PcParser";

export default {
  name: "TaskRecover",
  components: {
    PcParser
  },
  props: {
    taskRecoverId:{
      type: Number,
      required: true
    },
    workOrderId:{
      type: Number,
      required: true
    },
  },
  data() {
    return {
      graph: null,
      taskEngineId: this.taskRecoverId,
      taskTodoId: 0,
      taskTodo:[],
      stepNode:[],
      stepNodeComp:[],
      modalTitle: '',
      dialogVisible: false,
      formDesignData: null,
      formDisabled: false,
      displayData: {},
      editFields: {},
      needFlow: false,
      moduleUuid: '',
      formMode: 'add',
    }
  },
  created() {
    // const self = this
    window.addEventListener('message',(e)=>{
      if(e.data.event === 'open-task-form'){
        console.log(e.data.task, "任务表单")
      } else if(e.data.event === 'open-attach') {
        console.log(e.data.attach, "任务附件")
      }
    })
  },
  mounted() {
    if(this.taskEngineId){
      this.getTaskDetail()
    }
  },
  methods: {
    initCanvas(){
      const self = this
      this.$nextTick(()=>{
        const container = this.$refs.container
        const graph = recoverInitial(container)

        graph.on('node:click', ({node})=>{
          const nodeData = node.getData()
          if(self.stepNode.includes(node.id)){
            self.openFormParser(nodeData,node.id)
          } else {
            self.$message.error('此任务没有权限处理')
          }
        })

        this.graph = graph
        if(this.taskEngineId){
          this.getTaskEngine()
        }
      })
    },
    getTaskEngine(){
      const param = {
        __method_name__: 'dataInfo',
        object_uuid: 'object628ae2a79d46c',
        data_id: this.taskEngineId,
        transcode: 0
      }
      dataInterface(param).then(res=>{
        if(res.data.code === 200){
          const {name,desc,interval_open,interval,json} = res.data.data
          this.configData = {name,desc,interval_open,interval}

          const start = json.cells.filter(item => item.shape === 'start')
          this.stepNodeComp.unshift(start[0].id)
          for (let item of json.cells){
            if(item.shape === 'task' && this.stepNode.includes(item.id)){
              item.data.color = '#539FE6'
            } else if (item.shape === 'task' && this.stepNodeComp.includes(item.id)){
              item.data.color = '#40B67C'
            } else if (item.shape === 'edge' && this.stepNodeComp.includes(item.source.cell)){
              item.attrs.line.stroke = '#40B67C'
            } else if (item.shape === 'start'){
              // item.attrs.stroke = '#40B67C'
            }
          }
          if(this.graph){
            this.graph.fromJSON(json)
            this.graph.freeze()
            this.graph.centerContent()
          }
        }
      })
    },
    getTaskDetail(){
      const param = {
        task_engine_id: this.taskEngineId,
        object_data_id: this.workOrderId,
      }
      dataInterface(param,'api/taskengine/tasktodo','GET').then(res=>{
        if(res.data.code === 200){
          const data = res.data.data;
          const userInfo = this.$GetUserInfo()
          this.taskTodo = data.filter(item => {
            const userId = Array.from(item.user_task,ele => ele.user_id)
            return item.status === 0 && userId.includes(userInfo.id)
          })
          this.completeTodo = data.filter(item => item.status === 1)
          const nodeId = Array.from(this.taskTodo,item => item.step_node)
          const completeNodeId = Array.from(this.completeTodo,item => item.step_node)
          this.stepNode = nodeId
          this.stepNodeComp = completeNodeId
          this.initCanvas()
        }
      })
    },
    openFormParser(node,nodeId){
      this.modalTitle = node.name
      const data = this.taskTodo.filter((item)=>{
        return item.step_node === nodeId
      })
      this.taskTodoId = data[0].task_log_id;
      if(node.form_type === 1){
        formDesign.formShow(node.func_form).then(res=>{
          if(res.data.code === 200){
            const formData = res.data.data
            this.formDesignData = JSON.parse(formData.json)
            this.needFlow = !!formData.need_flow
            this.moduleUuid = formData.module_uuid
            this.dialogVisible = true
          }
        });
      } /*else if(node.form_type === 2) {

      } else {

      }*/
    },
    triggerSubmit(){
      this.$refs.dialogForm.handleSubmit()
    },
    onFormSubmit(formData){
      formData.task_log_id = this.taskTodoId
      formData.module_uuid = this.moduleUuid

      console.log(JSON.stringify(formData), 222333)

      /*formDesign.formDataStore(formData).then((res) => {
        if (res.data.code === 200) {
          this.$message.success(res.data.msg);
          this.searchData();
          this.dialogVisible = false;
        } else {
          this.$message.success(res.data.msg);
        }
      });*/
    },
    onEndFlow(){},
  },
}
</script>

<style lang="less" scoped>
:deep(.common-dialog)  {
  .el-dialog__body{
    padding: 0;
  }
}

.dialog-main{
  padding: 0 15px;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}


.task-flow-container{
  position: relative;
  width: 100%;
  height: 100%;

  .indictor-todo{
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 10;
    padding-left: 25px;
    b{
      position: absolute;
      top:0;
      left: 0;
      display: inline-block;
      width: 20px;
      height: 20px;
      background-color: #539FE6;
      border-radius: 3px;
    }
  }

  .indictor-complete{
    position: absolute;
    top: 15px;
    left: 100px;
    z-index: 10;
    padding-left: 25px;
    b{
      position: absolute;
      top:0;
      left: 0;
      display: inline-block;
      width: 20px;
      height: 20px;
      background-color: #40B67C;
      border-radius: 3px;
    }
  }

  .task-block{
    width: 100%;
    height: 100%;
    background-color: #F7F9FF;
  }
}
</style>
