/**
 * @description 格式化时间
 * @param time
 * @param cFormat
 * @returns {string|null}
 */
 export function parseTime(time, cFormat) {
    if (arguments.length === 0) {
      return null
    }
    const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
    let date
    if (typeof time === 'object') {
      date = time
    } else {
      if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
        time = parseInt(time)
      }
      if (typeof time === 'number' && time.toString().length === 10) {
        time = time * 1000
      }
      date = new Date(time)
    }
    const formatObj = {
      y: date.getFullYear(),
      m: date.getMonth() + 1,
      d: date.getDate(),
      h: date.getHours(),
      i: date.getMinutes(),
      s: date.getSeconds(),
      a: date.getDay(),
    }
    return format.replace(/{([ymdhisa])+}/g, (result, key) => {
      let value = formatObj[key]
      if (key === 'a') {
        return ['日', '一', '二', '三', '四', '五', '六'][value]
      }
      if (result.length > 0 && value < 10) {
        value = '0' + value
      }
      return value || 0
    })
  }
  
  /**
   * @description 格式化时间
   * @param time
   * @param option
   * @returns {string}
   */
  export function formatTime(time, option) {
    if (('' + time).length === 10) {
      time = parseInt(time) * 1000
    } else {
      time = +time
    }
    const d = new Date(time)
    const now = Date.now()
  
    const diff = (now - d) / 1000
  
    if (diff < 30) {
      return '刚刚'
    } else if (diff < 3600) {
      // less 1 hour
      return Math.ceil(diff / 60) + '分钟前'
    } else if (diff < 3600 * 24) {
      return Math.ceil(diff / 3600) + '小时前'
    } else if (diff < 3600 * 24 * 2) {
      return '1天前'
    }
    if (option) {
      return parseTime(time, option)
    } else {
      return (
        d.getMonth() +
        1 +
        '月' +
        d.getDate() +
        '日' +
        d.getHours() +
        '时' +
        d.getMinutes() +
        '分'
      )
    }
  }
  
  /**
   * @description 将url请求参数转为json格式
   * @param url
   * @returns {{}|any}
   */
  export function paramObj(url) {
    const search = url.split('?')[1]
    if (!search) {
      return {}
    }
    return JSON.parse(
      '{"' +
        decodeURIComponent(search)
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"')
          .replace(/\+/g, ' ') +
        '"}'
    )
  }
  
  /**
   * @description 父子关系的数组转换成树形结构数据
   * @param data
   * @returns {*}
   */
  export function translateDataToTree(data) {
    const parent = data.filter(
      (value) => value.parentId === 'undefined' || value.parentId === null
    )
    const children = data.filter(
      (value) => value.parentId !== 'undefined' && value.parentId !== null
    )
    const translator = (parent, children) => {
      parent.forEach((parent) => {
        children.forEach((current, index) => {
          if (current.parentId === parent.id) {
            const temp = JSON.parse(JSON.stringify(children))
            temp.splice(index, 1)
            translator([current], temp)
            typeof parent.children !== 'undefined'
              ? parent.children.push(current)
              : (parent.children = [current])
          }
        })
      })
    }
    translator(parent, children)
    return parent
  }
  
  /**
   * @description 树形结构数据转换成父子关系的数组
   * @param data
   * @returns {[]}
   */
  export function translateTreeToData(data) {
    const result = []
    data.forEach((item) => {
      const loop = (data) => {
        result.push({
          id: data.id,
          name: data.name,
          parentId: data.parentId,
        })
        const child = data.children
        if (child) {
          for (let i = 0; i < child.length; i++) {
            loop(child[i])
          }
        }
      }
      loop(item)
    })
    return result
  }
  
  /**
   * @description 10位时间戳转换
   * @param time
   * @returns {string}
   */
  export function tenBitTimestamp(time) {
    const date = new Date(time * 1000)
    const y = date.getFullYear()
    let m = date.getMonth() + 1
    m = m < 10 ? '' + m : m
    let d = date.getDate()
    d = d < 10 ? '' + d : d
    let h = date.getHours()
    h = h < 10 ? '0' + h : h
    let minute = date.getMinutes()
    let second = date.getSeconds()
    minute = minute < 10 ? '0' + minute : minute
    second = second < 10 ? '0' + second : second
    return y + '年' + m + '月' + d + '日 ' + h + ':' + minute + ':' + second //组合
  }
  
  /**
   * @description 13位时间戳转换
   * @param time
   * @returns {string}
   */
  export function thirteenBitTimestamp(time) {
    const date = new Date(time / 1)
    const y = date.getFullYear()
    let m = date.getMonth() + 1
    m = m < 10 ? '' + m : m
    let d = date.getDate()
    d = d < 10 ? '' + d : d
    let h = date.getHours()
    h = h < 10 ? '0' + h : h
    let minute = date.getMinutes()
    let second = date.getSeconds()
    minute = minute < 10 ? '0' + minute : minute
    second = second < 10 ? '0' + second : second
    return y + '年' + m + '月' + d + '日 ' + h + ':' + minute + ':' + second //组合
  }
  
  /**
   * @description 获取随机id
   * @param length
   * @returns {string}
   */
  export function uuid(length = 32) {
    const num = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'
    let str = ''
    for (let i = 0; i < length; i++) {
      str += num.charAt(Math.floor(Math.random() * num.length))
    }
    return str
  }
  
  /**
   * @description m到n的随机数
   * @param m
   * @param n
   * @returns {number}
   */
  export function random(m, n) {
    return Math.floor(Math.random() * (m - n) + n)
  }
  
  /**
   * @description addEventListener
   * @type {function(...[*]=)}
   */
  export const on = (function () {
    return function (element, event, handler, useCapture = false) {
      if (element && event && handler) {
        element.addEventListener(event, handler, useCapture)
      }
    }
  })()
  
  /**
   * @description removeEventListener
   * @type {function(...[*]=)}
   */
  export const off = (function () {
    return function (element, event, handler, useCapture = false) {
      if (element && event) {
        element.removeEventListener(event, handler, useCapture)
      }
    }
  })()
  
  /**
   * @description 数组打乱
   * @param array
   * @returns {*}
   */
  export function shuffle(array) {
    let m = array.length,
      t,
      i
    while (m) {
      i = Math.floor(Math.random() * m--)
      t = array[m]
      array[m] = array[i]
      array[i] = t
    }
    return array
  }
  
  /**
   * @desc: 获取当前登录的组织架构
   * @param {String} key 需要获取的子属性
   * @return {Object|String|Any}
   */
  export const getTargetArchi = (key = '') => {
    let targetArchi = localStorage.getItem('targetArchi')
    if (!targetArchi) return ''
    targetArchi = JSON.parse(targetArchi)
    if (key && typeof key === 'string' && typeof targetArchi === 'object') {
      return targetArchi[key] || undefined
    }
    return targetArchi
  }
  
  
  /**
   * @desc: 获取用户信息
   * @param {String} key 需要获取的子属性
   * @return {Object|String|Any}
   */
  export const getUserInfo = (key = '') => {
    let userInfo = localStorage.getItem('userInfo')
    if (!userInfo) return ''
    userInfo = JSON.parse(userInfo)
    if (key && typeof key === 'string' && typeof userInfo === 'object') {
      return userInfo[key] || undefined
    }
    return userInfo
  }
  
  /**
   * @desc: 得到一个唯一的id 用于重绘组件
   */
  export const uniqid = () => {
      var d = new Date().getTime();
      if (window.performance && typeof window.performance.now === "function") {
              d += performance.now(); //use high-precision timer if available
      }
      var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
              var r = (d + Math.random() * 16) % 16 | 0;    // d是随机种子
              d = Math.floor(d / 16);
              return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
      });
      return uuid;
  }
	/**
 * @description 转换数据大小
 * @retrun 数据大小
 */
export const bytesToSize = (limit) => {
  var size = ''
  if (limit < 0.1 * 1024) { // 如果小于0.1KB转化成B
    size = limit.toFixed(2) + 'B'
  } else if (limit < 0.1 * 1024 * 1024) { // 如果小于0.1MB转化成KB
    size = (limit / 1024).toFixed(2) + 'KB'
  } else if (limit < 0.1 * 1024 * 1024 * 1024) { // 如果小于0.1GB转化成MB
    size = (limit / (1024 * 1024)).toFixed(2) + 'MB'
  } else { // 其他转化成GB
    size = (limit / (1024 * 1024 * 1024)).toFixed(2) + 'GB'
  }
  var sizeStr = size + ''
	console.log(sizeStr,'sizeStr')
  var len = sizeStr.indexOf('/.')
  var dec = sizeStr.substr(len + 1, 2)
  if (dec === '00') { // 当小数点后为00时 去掉小数部分
    return sizeStr.substring(0, len) + sizeStr.substr(len + 3, 2)
  }
  return sizeStr
}