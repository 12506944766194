<!--
 * @Author: zx
 * @Date: 2022-09-01 16:01:06
 * @LastEditTime: 2022-11-11 15:41:48
 * @Description:
 * @LastEditors: zx
-->
<template>
  <div class="add">
    <div class="add-container">
      <p>创建分组</p>
      <div class="add-group" @click="createApp(groupInfo)">
        <div class="add-group-left">
          <div class="add-group-container">
            <div class="add-group-name">
              菜单分组
            </div>
            <div class="add-group-desc">
              分组管理我的应用
            </div>
          </div>
          <div class="add-group-button">
            <i class="iconfont icona-xinzengyuanjiao"></i>
            <span>创建分组</span>
          </div>
        </div>
        <div class="add-group-right">
          <i class="iconfont iconwangpan-wenjianjia21x"></i>
        </div>
      </div>
      <p class="second-p">创建功能</p>
      <div class="add-fun">
        <div class="add-type-container" v-for="(item, index) in filterTypeList(typeList)" :key="index" :style="index%2 === 0 ? '' : 'margin-right: 0'">
          <div class="add-group" :style="'background:' + item.backgroundColor" @click="createApp(item)">
            <div class="add-group-left">
              <div class="add-group-container">
                <div class="add-group-name">
                  {{item.name}}
                </div>
                <div class="add-group-desc">
                  {{item.desc}}
                </div>
              </div>
              <div class="add-group-button">
                <i class="iconfont icona-xinzengyuanjiao" :style="'color:' + item.color"></i>
                <span :style="'color:' + item.color">{{item.operation}}</span>
              </div>
            </div>
            <div class="add-group-right">
              <i :class="'iconfont ' + item.icon"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="more-application" @click="jumpToTemplate()">
      <span>查看更多应用</span>
      <i class="iconfont iconlujingjiantou" ></i>
    </div>
    <el-dialog
     :title="appTitle"
      :visible.sync="dialogVisible"
      width="600px"
      append-to-body
      custom-class="create-app-dialog"
    >
      <div class="common-body">
        <el-form
          v-if="dialogVisible"
          ref="form"
          :model="appFormData"
          :rules="rules"
          label-position="top"
          label-width="80px"
        >
          <div class="wrapper">
            <el-form-item
              v-if="+appFormData.menu_type !== 6 && +appFormData.menu_type !== 1"
              class="form-item-height"
              label="应用图标"
              prop="icon"
            >
              <el-select :value="other" style="width: 344px" placeholder="" class="select-icon">
                <template slot="prefix">
                  <div
                    class="header-img">
                    <i
                      :style="'color: ' + appFormData.color"
                      :class="
                        appFormData.icon
                          ? 'iconfont ' + appFormData.icon
                          : 'iconfont icon-a-1-1zhuye'
                      "
                    ></i>
                  </div>
                </template>
                <template #empty>
                  <div class="choose-color" style="width: 344px; padding: 8px;height: 384px">
                    <p>选择类型</p>
                    <div class="color-container">
                      <div
                        class="color-item"
                        style="background: #F2F4F7;border-radius: 4px;width: 40px;height: 32px;"
                        :style="currentChooseType.font_class === item.font_class ? 'border: 2px solid #D0E2FF;background: #fff' : ''"
                        v-for="(item, index) in iconList"
                        :key="index"
                        @click="currentChooseType = item"
                      >
                        <i
                          :style="'color: ' + item.color"
                          class="iconfont"
                          :class="item.font_class"
                        ></i>
                      </div>
                    </div>
                    <p>选择图形</p>
                    <div
                      class="color-container"
                      style="flex: 1; overflow-y: auto"
                    >
                      <div
                        class="color-item"
                        v-for="(icon, index) in currentChooseType.children"
                        :key="index"
                        :style="
                          appFormData.icon === icon.font_class
                            ? 'background: #337EFF;border: none;color:#fff': ''
                        "
                        @click="appFormData.icon = icon.font_class, appFormData.color = currentChooseType.color"
                      >
                        <i
                          class="iconfont "
                          :style="
                            appFormData.icon === icon.font_class
                              ? 'color:#fff'
                              : ''
                          "
                          :class="icon.font_class"
                        ></i>
                      </div>
                    </div>
                  </div>
                </template>
              </el-select>
            </el-form-item>
            <el-form-item class="form-item-height" style="margin-bottom: 14px;" label="名称" prop="name">
              <el-input
                v-model="appFormData.name"
                clearable
                placeholder="请输入名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="form-item-height" style="margin-bottom: 14px;" label="描述"
            >
              <el-input
                v-model="appFormData.remarks"
                clearable
                placeholder="请输入描述"
              ></el-input>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button :loading="submitLoading" type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
     :title="templateInfo.name"
      :visible.sync="showTemplateVisible"
      :fullscreen="true"
      append-to-body
      custom-class="create-app-dialog"
    >
      <div class="common-body" style="height: calc(100vh - 48px);overflow-y: auto;">
        <SafeModule v-if="showTemplateVisible" :id="templateInfo.id" :name="templateInfo.name" @close="closeTempDiolog" />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { MessageBox } from 'element-ui';
import { getToken } from '@/utils/tools';
import { baseUrl } from '@/libs/util';
import { funcIcons } from './icons';
import { createdApps } from '@/api/myApps';
import SafeModule from '@/website-views/views/templateStore/childPages/SafeModule';
import { mapiRequest } from '@/apis/http/saasMapiRequest'
import { mapState } from 'vuex';

export default {
  components: {
    SafeModule
  },
  props: {
    childInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      iconList: funcIcons,
      dialogVisible: false,
      appTitle: '',
      appFormData: {
        name: '',
        remarks: '',
        icon: 'icon-a-1-1zhuye',
        color: '#fb9337',
        background: '#ffebdb',
        type: 1,
      },
      rules: {
        name: [
          { required: true, message: '请输入名称', trigger: 'blur' }
        ],
        remarks: [
          { required: true, message: '请输入描述', trigger: 'blur' }
        ],
        icon: [
          { required: true, message: '请选择图标', trigger: 'change' }
        ],
      },
      typeList: [
        {
          name: '表单设计',
          operation: '创建表单',
          icon: 'icona-fangangongdanrenwufill',
          backgroundColor: 'linear-gradient(315deg, #C1ACFF 0%, #AE79FC 100%)',
          color: '#AF7DFD',
          desc: '多种数据类型创建表单',
          type: 7,
        },
        {
          name: '页面设计',
          operation: '创建页面',
          icon: 'icona-jisuanceliangfill',
          backgroundColor: 'linear-gradient(315deg, #38D7A2 0%, #15C2B6 100%)',
          color: '#17C3B5',
          desc: '创建自定义页面',
          type: 2,
        },
        {
          name: '门户报表',
          operation: '创建门户',
          icon: 'icona-yingyongxuanzhong',
          backgroundColor: 'linear-gradient(135deg, #FF8D72 0%, #FFB97B 100%)',
          color: '#FF8F72',
          desc: '组件搭建个人门户',
          type: 8,
        },
        {
          name: '可视化大屏',
          operation: '创建大屏',
          icon: 'icona-kanbantongjifill',
          backgroundColor: 'linear-gradient(315deg, #99C9FF 0%, #70A0FF 100%)',
          color: '#79A8FF',
          desc: '自定义数据驾驶舱',
          type: 3,
        },
        {
          name: 'BIMGIS数字孪生',
          operation: '创建功能',
          icon: 'icona-diqiufill',
          backgroundColor: 'linear-gradient(315deg, #99C9FF 0%, #70A0FF 100%)',
          color: '#79A8FF',
          desc: '创建GIM&GIS功能',
          type: 4,
        },
        {
          name: 'H5-APP设计',
          operation: '创建页面',
          icon: 'icona-fangankanchashejifill',
          backgroundColor: 'linear-gradient(315deg, #38D7A2 0%, #15C2B6 100%)',
          color: '#17C3B5',
          desc: '自定义移动端页面',
          type: 5,
        },
      ],
      groupInfo: {
        name: '菜单分组',
        icon: 'iconbimgis_goujianzu',
        color: '#268bfb',
        desc: '可创建菜单分组',
        type: 6,
      },
      recommendList: [
        {
          type: 'test',
          name: '值得一试',
          children: []
        },
        {
          type: 'new',
          name: '最新上架',
          children: []
        },
      ],
      baseURL: baseUrl(),
      metadataToken: process.env.VUE_APP_METATOKEN,
      token: getToken(),
      submitLoading: false,
      other: '',
      /* 模板安装弹窗 */
      showTemplateVisible: false,
      templateInfo: {},
      currentChooseType: funcIcons[0]
    }
  },
  computed: {
    ...mapState(['saasVersion']),
  },
  mounted() {
    /*if(this.saasVersion === 1){
      this.typeList = this.typeList.filter(i=>[6,7,8].includes(i.type))
    }*/
    this.gettemplateList()
  },
  methods: {
    gettemplateList() {
      let params = {
        __method_name__: "dataList",
        object_uuid: "object622eefb3ebecc",
        view_uuid: "view6347e37ba5cda",
        transcode: 0,
      };
      mapiRequest(params).then(res=>{
        if (res.status === 200 && res.data.code === 200) {
          const resList = res.data.data
          let attemptList = []
          let newList = []
          if (resList && resList.length) {
            resList.forEach(element => {
              element.desc = element.describe
              element.icon = ''
              if (+element.is_attempt === 1) {
                attemptList.push(element)
              } else {
                newList.push(element)
              }
            });
          }
          if (attemptList?.length && attemptList?.length > 4) {
            attemptList = attemptList.splice(0, 4)
          }
          if (attemptList?.length && attemptList?.length > 0) {
            for (let index = 0; index < attemptList.length; index++) {
              if ((index%2) === 0) {
                attemptList[index].icon = '#iconguanlizhongxin1'
              } else {
                attemptList[index].icon = '#iconzhihuigongdi'
              }
            }
          }
          if (newList?.length && newList?.length > 4) {
            newList = newList.splice(0, 4)
          }
          if (newList?.length && newList?.length > 0) {
            for (let index = 0; index < newList.length; index++) {
              if ((index%2) === 0) {
                newList[index].icon = '#iconguanlizhongxin1'
              } else {
                newList[index].icon = '#iconzhihuigongdi'
              }
            }
          }
          this.recommendList[0].children = attemptList
          this.recommendList[1].children = newList
        }
      })
    },
    createApp(item) {
      if(this.saasVersion === 1 && ![6,7,8].includes(item.type)){
        MessageBox( {
          title: '提示',
          message: '请升级企业版后使用',
          type: 'error',
          confirmButtonText: '确定',
          customClass: 'saas-version-verify',
          callback: () => {}
        });
        return ;
      }

      this.appFormData = {
        name: '',
        remarks: '',
        icon: 'icon-a-1-1zhuye',
        color: this.currentChooseType.color,
        background: '#ffebdb',
        menu_type: item.type,
        menu_id: this.childInfo.id,
        company_id: localStorage.getItem('company_id'),
        type: item.type,
      }
      this.appTitle = '新建' + item.name
      this.dialogVisible = true
    },
    beforeUpload() {
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submitLoading = true
          if (+this.appFormData.menu_type !== 1) {
            delete this.appFormData.code
          }
          createdApps(this.appFormData).then((res) => {
            this.submitLoading = false
            if (res.status === 200 && res.data.code === 200) {
              this.$message.success('新增成功')
              this.dialogVisible = false
              this.$emit('closeAddDrawer', true)
              const resp = res.data.data
              if (resp?.id) {
                const pageEditNumber = [2,3,4,5]
                if (+resp.menu_type === 7) {
                  this.$router.push({
                    path: '/application/my-application',
                    query: {
                      formUuid: resp.form_module_uuid,
                      appMenuId: resp.id,
                      add: true
                    }
                  })
                } else if (pageEditNumber.indexOf(resp.menu_type) !== -1) {
                  const typeList = ['page', 'dataview', 'console', 'mobile']
                  // 暂存打开新路由的方法
                  // this.$router.push({ path: '/modify-page',
                  //   query: {
                  //     pageUUID: resp.page_uuid,
                  //     id: resp.current_data_id,
                  //     type: typeList[pageEditNumber.indexOf(+resp.menu_type)],
                  //   }
                  // })
                  const query = {
                    pageUUID: resp.page_uuid,
                    id: resp.current_data_id,
                    type: typeList[pageEditNumber.indexOf(+resp.menu_type)],
                    isAdd: true
                  }
                  let path = '/full-page-edit'
                  if(Object.keys(query).length) {
                    let queryStr = ''
                    for (const key in query) {
                      if (Object.hasOwnProperty.call(query, key)) {
                        if(query[key]) {
                          if(queryStr) {
                            queryStr += '&'
                          }
                          queryStr += `${key}=${query[key]}`
                        }
                      }
                    }
                    path = path + '?' + queryStr
                  }
                  path = encodeURI(path)
                  window.open(path)
                  return
                } else if(+resp.menu_type === 8) { // 门户报表
                  this.$router.push({ name: 'BulletinBoard',
                    query: {
                      chart_uuid: resp.chart_uuid,
                      id: resp.current_data_id,
                      name: resp.name
                    }
                  })
                }
                let navHistoryList = JSON.parse(sessionStorage.getItem('navHistoryList'))
                if (navHistoryList && navHistoryList.length) {
                  let boo = false
                  navHistoryList.forEach(element => {
                    if (+element.id === +resp.id) {
                      boo = true
                    }
                  });
                  if (!boo) {
                    navHistoryList.push(resp)
                  }
                } else {
                  navHistoryList = [resp]
                }
                sessionStorage.setItem('navHistoryList', JSON.stringify(navHistoryList))
                this.$store.commit('setCurrentMenus', resp)
              }
            }
          }).catch(() => {
            this.submitLoading = false
          })
        }
      });
    },
    /* 跳转到模板市场 */
    jumpToTemplate() {
      this.$router.push({ path: '/web-page/templates'})
    },
    toDetail(item){
      this.templateInfo = item
      this.showTemplateVisible = true
      // if (!item.id) {
      //   return
      // }
      // sessionStorage.setItem("indexCurrentTab", 7)
      // this.$router.push({ path: '/web-page/templates',query:{id:item.id,name:item.name} })
    },
    /* 关闭模板详情弹窗 */
    closeTempDiolog(boo) {
      if(boo) {
        this.$emit('updateMenuList', true)
      }
      this.templateInfo = {}
      this.showTemplateVisible = false
    },
    /* 根据企业版个人版过滤应用类型 */
    filterTypeList(arr) {
      if(+this.saasVersion === 2 || +this.saasVersion === 1) {
        return arr.filter((item) => item.type === 7 )
      } else {
        return arr
      }
    }
  }
}
</script>
<style lang='less'>
  .saas-version-verify{
    border: none;
    border-radius: 8px;
    .el-message-box__header{
      background: linear-gradient(90deg, #6292FF 0%, #DE93FF 84%, #CE92FF 91%, #C568FF 100%);
      .el-message-box__title{
        color: #ffffff;
      }
      .el-message-box__close{
        color: #ffffff;
      }
    }
  }
</style>
<style lang='less' scoped>
:deep(.select-icon) {
  .el-input {
    width: 48px;
    height: 48px;
    background: #FFFFFF;
    border-radius: 4px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;

    input {
      display: none;
    }
    .el-input__suffix {
      display: none;
    }
    .el-input__prefix {
      width: 48px;
      height: 48px;
      i {
        font-size: 18px;
        color: #000;
      }
    }
  }
}
.common-body{
  height: 350px;
}
:deep(.el-select-dropdown) {
  // 控制图标下拉选择的宽度  不然会超出宽度
  width: 100px !important;
}
:deep(.avatar-uploader) {
  .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    &:hover {
      border-color: #409EFF;
    }
  }
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}

.avatar {
  width: 80px;
  height: 80px;
  display: block;
}
.full-width {
  width: 100%;
}

.add {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-top: 1px solid #F2F4F7;
  box-sizing: border-box;
  .add-container {
    flex: 1;
    overflow-y: auto;
    padding: 24px 32px 0;
    p {
      height: 26px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 400;
      color: #2A2F3D;
      line-height: 26px;
    }
    .second-p {
      margin-top: 40px;
    }
    .add-group {
      height: 144px;
      background: linear-gradient(135deg, #FF6F76 0%, #FF9DA3 100%);
      border-radius: 16px;
      box-sizing: border-box;
      padding: 20px 16px 20px 24px;
      margin-top: 12px;
      overflow: hidden;
      display: flex;
      transition-duration: 0.2s;
      .add-group-left {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .add-group-container {
          display: flex;
          flex-direction: column;
          height: 46px;
          .add-group-name {
            height: 24px;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 24px;
          }
          .add-group-desc {
            height: 22px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(255,255,255,0.8);
            line-height: 22px;
            white-space: nowrap;
          }
        }
        .add-group-button {
          width: 120px;
          height: 32px;
          background: rgba(255,255,255,0.8);
          border-radius: 16px;
          padding: 5px 20px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          transition-duration: 0.2s;
          i {
            font-size: 12px;
            color: #FF7178;
            margin-right: 4px;
            font-weight: 600;
          }
          span {
            height: 22px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FF7178;
            line-height: 22px;
          }
        }
      }
      .add-group-right {
        width: 98px;
        height: 98px;
        padding: 10px;
        box-sizing: border-box;
        transform: translateX(37px) rotate(-15deg);
        transition-duration: 0.2s;
        i {
          width: 80px;
          height: 80px;
          font-size: 80px;
          color: rgba(255, 255, 255, 0.2);
        }
      }
    }
    .add-group:hover {
      transform: translateY(-4px);
      .add-group-left {
        .add-group-button {
          background: rgba(255,255,255,1);
        }
      }
      .add-group-right {
        transform: translateX(45px) rotate(0deg);
      }
    }
    .add-fun {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      .add-type-container {
        flex: 0.5;
        margin-right: 21px;
        box-sizing: border-box;
        .add-group {
          margin-top: 21px;
        }
      }
    }
  }
  .more-application {
    width: 100%;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #F2F4F7;
    box-sizing: border-box;
    span {
      height: 24px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #6EA3FF;
      line-height: 24px;
      margin-right: 8px;
    }
    i {
      width: 16px;
      height: 16px;
      font-size: 16px;
      color: #6EA3FF;
    }
  }
}

/* 头像显示 */
.header-img {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  border-radius: 4px;
  border: 2px solid #D0E2FF;
  i {
    font-size: 26px;
  }
}
/* 选择颜色图标 */
.choose-color {
  width: 460px;
  height: 300px;
  display: flex;
  flex-direction: column;
  padding: 10px 0 10px 10px;
  box-sizing: border-box;
  p {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: #121315;
    margin: 16px 0;
  }
  .color-container {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    .color-item {
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 12px 12px 0;
      box-sizing: border-box;
      width: 40px;
      height: 40px;
      background: #FFFFFF;
      border-radius: 4px;
      border: 1px solid #F2F4F7;
      .color-item-i {
        color: #606266
      }
    }
  }
}
</style>
<style lang="less" scoped>
@import "../../../../../css/manageAdd.less";
</style>
