/*
 * @LastEditTime: 2022-08-05 15:17:18
 * @Description: 全局 Ctrl+S 搜索
 * @FilePath: /dataview-next/src/global/globalSearch.js
 * @Date: 2022-01-07 09:33:25
 * @Author: lisushuang
 * @LastEditors: lisushuang
 */
import obSearch from "@/api/objectSearch"
import { Loading, Message, MessageBox, Notification } from "element-ui";
import router from "../router";
import showMetaQoutes from "./actions/showMetaQoutes";

/**
 * @description: 通过uuid查询来源信息
 * @param {String} uuid 可以为null，此时将弹出prompt填入
 * @return {*}
 */
export const searchInfo = async (uuid = null) => {
  let value = uuid

  if (!value) {
    value = await MessageBox.prompt("请输入查询的uuid或关键字：")
    if (value && value.value) {
      value = value.value
    } else {
      return;
    }
  }
  if (!value) {
    MessageBox.alert("未查询到任何信息！");
    return;
  }
  let loading = Loading.service({
    fullscreen: true,
    text: "数据准备中，请稍后",
  });

  let res = await obSearch.searchUuid(value)
  const data = res.data.data
  loading.close()

  if (!data) {
    // 开始查询引用
    Message.info("未查询到任何信息，跳转引用面板");

    new showMetaQoutes().bind().handler(value)

    // Message.error("未查询到任何信息！");
    return
  }
  Message({ type: 'info', message: "查询到 " + data.name });
  try {
    let url = checkUrlFromSearchData(data)
    if (url) {
      router.push({ path: '/dwh/objects/transit_out', query: { url: url } })
    }
    loading.close()
  } catch (e) {
    loading.close()
    console.error(e);
  }
}

/**
 * @description: 根据查询到的来源信息进行跳转
 * @param {Object} data 查询到的对象信息
 * @return {*}
 */
export const checkUrlFromSearchData = (data) => {
  // origin data 
  // {
  //   "data_type": "metadata_views",
  //   "name": "所有数据",
  //   "object_code": "Mix_parallel_test_child",
  //   "object_name": "配合比平行试验子表",
  //   "object_uuid": "object61d6866c8798e",
  //   "uuid": "view61d689240907e, // 搜索的uuid
  // }

  let solveArray = [
    'metadata_views', 'metadata_object_fields', 'metadata_relationships', 'metadata_objects'
  ];

  if (solveArray.indexOf(data.data_type) == -1) {
    // let error = await new Message({ type: 'error', message: `暂不支持 ${data.data_type} 类型的来源跳转！` })
    Notification.error({ title: '错误', message: `暂不支持 ${data.data_type} 类型的来源跳转！`, offset: 0 })
    return ''
  }
  let url = `/dwh/objects/detail?obuuid=${data.object_uuid}&obname=${data.object_name}&obcode=${data.object_code}`
  // 视图
  if (data.data_type == 'metadata_views') {
    url = `${url}&type=view&view_uuid=${data.uuid}`
  }
  // 字段
  if (data.data_type == 'metadata_object_fields') {
    url = `${url}&type=field&field_uuid=${data.uuid}`
  }
  // 关联关系
  if (data.data_type == 'metadata_relationships') {
    url = `${url}&type=relation&relationship_uuid=${data.uuid}`
  }
  // 对象
  if (data.data_type == 'metadata_objects') {
    url = `/dwh/objects/detail?obuuid=${data.uuid}&obname=${data.name}&obcode=${data.code}&type=field`
  }
  if(data.object_enabled){
    url  = url + '&enabled='+data.object_enabled
  }
  return url 
}

export default () => {
  document.onkeydown = function (e) {
    let key = window.event.keyCode;
    if (key == 83 && e.ctrlKey) {
      //== 83 && event.ctrlKey
      window.event.preventDefault(); //关闭浏览器快捷键
      searchInfo()
    }
  };
}
