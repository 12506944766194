<!--
 * @Description: 图片组件 v0.0.1
 * @Author: luocheng
 * @Date: 2021-08-09 17:09:06
 * @LastEditors: luocheng
 * @LastEditTime: 2022-10-17 11:08:44
-->
<template>
	<div class="common-image">
		<!-- 固定 -->
		<template v-if="element.valueOrign === 'fixed'">
			<img :src="imagePath(element.propValue)" alt="图片" :style="imgStyle(imagePath(element.propValue))" />
		</template>
		<!-- 来源于其他组件 -->
		<template v-if="element.valueOrign === 'component'">
			<!-- 暂未完成 -->
			<img :src="imagePath(element.propValue)" alt="图片" :style="imgStyle(imagePath(element.propValue))" />
		</template>
		<!-- 数据仓库 -->
		<template v-if="element.valueOrign === 'database'">
			<template v-if="dataObj && bindUUID">
				<!-- 绑定值 -->
				<template v-if="databaseType === 'value'">
					<img :src="imagePath(dataObj)" alt="图片" :style="imgStyle(imagePath(element.propValue))" />
				</template>
				<!-- 数据字典(描述文字) -->
				<template v-else-if="databaseType === 'wordbook' && metaData">
					{{ metaData.name }}
				</template>
			</template>
		</template>
		<!-- 循环容器数据映射 -->
		<template v-if="element.valueOrign === 'valueMap'">
			<img :src="imagePath(mapPath)" alt="图片" :style="imgStyle(imagePath(mapPath))" />
		</template>
	</div>
</template>

<script>
import { getComponentById } from '@/utils/tools';
import STORE from '@/store';
import { baseUrl } from '@/apis/http/request';
export default {
	name: 'CommonImage',
	components: {},
	props: {
		// 绑定的值
		element: {
			type: Object,
			default: () => {},
			required: true
		},
		// 是否在组合内
		isGroup: {
			type: Boolean
		},
		// 组合内组件列表
		groupComponents: {
			type: Array,
			default: () => []
		},
		componentList: {
			default: null
		},
		// 循环映射值
		mapData: {
			type: Object,
			default: () => {}
		}
	},
	data() {
		return {
			defaultImage: 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/b3f6ef3ac265d34a6da4b95e300f4a30.png'
		};
	},
	computed: {
		componentData() {
			return STORE.state['componentData']
		},
		// 数据仓库
		database() {
			return this.element.database;
		},
		// 字段对象
		dataObj() {
			if (!this.database || !this.database.containerKey) return [];
			let container = getComponentById(this.componentList || this.componentData, this.database.containerKey);
			if (!container && this.isGroup && this.groupComponents.length) {
				container = getComponentById(this.groupComponents, this.database.containerKey);
			}
			if (!container || !container.containerData) return null;
			return container.containerData;
		},
		// 绑定的uuid
		bindUUID() {
			if (!this.element || !this.element.database) return '';
			return this.element.database.bindUUID;
		},
		// 用于获取绑定的数据字典(描述文字)
		metaData() {
			if (!this.element || !this.element.database) return null;
			const { fieldList, bindUUID } = this.element.database;
			return fieldList.find((ele) => ele.uuid === bindUUID);
		},
		// 数据绑定类型
		databaseType() {
			if (!this.element || !this.element.database) return 'value';
			return this.element.database.databaseType || 'value';
		},
		// 图片样式
		imgStyle() {
			return function (src) {
				if (src.includes('/empty-light')) {
					return { 'object-fit': 'scale-down' };
				} else {
					const { objectFit } = this.element.statusConfig;
					if (objectFit) {
						return { 'object-fit': objectFit };
					}
					const editorType = sessionStorage.getItem('editorType');
					if (editorType === 'dataview' || editorType === 'console') {
						return { 'object-fit': 'fill' };
					}
					return { 'object-fit': 'cover' };
				}
			};
		},
		/**
		 * @desc: 映射的路径
		 */
		mapPath() {
			const { bindField } = this.element?.loopMapConfig || {};
			if (!bindField) return '/';
			const { renderData } = this.mapData || {};
			if (!renderData) return '/';
			return renderData?.[bindField] || '/';
		}
	},
	methods: {
		//数据仓库图片的路径地址
		imagePath(obj) {
			if (this.element.valueOrign === 'database') {
				if (obj.length && obj[0][this.bindUUID]) {
					if (obj[0][this.bindUUID].includes('http')) {
						return obj[0][this.bindUUID];
					} else {
						if (obj[0][this.bindUUID].includes('jpg') || obj[0][this.bindUUID].includes('png') || obj[0][this.bindUUID].includes('jpeg')) {
							return `${baseUrl}${obj[0][this.bindUUID]}`;
						} else {
							return this.defaultImage
						}
					}
				} else {
					if (obj?.[this.bindUUID] && obj?.[this.bindUUID].includes('http')) {
						return obj?.[this.bindUUID];
					} else if (obj?.[this.bindUUID] && (obj[this.bindUUID].includes('jpg') || obj[this.bindUUID].includes('png') || obj[this.bindUUID].includes('jpeg'))) {
						return `${baseUrl}${obj[this.bindUUID]}`;
					}
					return this.defaultImage;
				}
			} else {
				if (obj) {
					return obj;
				} else {
					return this.defaultImage;
				}
			}
		},
	}
};
</script>

<style lang="less" scoped>
.common-image {
	height: 100%;
	width: 100%;
	overflow: hidden;
	img {
		display: block;
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
}
</style>
