<!--
 * @Description: v
 * @Author: luocheng
 * @Date: 2022-06-17 19:20:39
 * @LastEditors: zx
 * @LastEditTime: 2022-09-30 17:06:38
-->
<template>
  <div class="second-main">
    <CommonGridContainer @toAppUrl='toAppUrl'></CommonGridContainer>
  </div>
</template>

<script>
import CommonGridContainer from './components/GridComponent'

export default {
  name: 'SecondMain',
  components: {
    CommonGridContainer,
  },
  data() {
    return {
      archiID: ''
    }
  },
  created() {
    this.archiID = localStorage.getItem('saasTargetArchi') ? JSON.parse(localStorage.getItem('saasTargetArchi')).id : ''
  },
  methods:{
    toAppUrl(item){
      this.$emit('toAppUrl',item)
    },
  }
}
</script>

<style lang="less" scoped>
.second-main{
  height: 100%;
  width: 100%;
  display: flex;
  .left{
    flex-grow: 1;
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    .part{
      width: 50%;
      box-sizing: border-box;
      padding: 0 8px 8px 0;
      height: 50%;
      border-radius: 8px;
      overflow: hidden;
      &:nth-of-type(2n) {
        padding-right: 0;
      }
      &:nth-of-type(3),&:nth-of-type(4) {
        padding-bottom: 0;
      }
      .space{
        height: 100%;
        width: 100%;
        background: #fff;
        border-radius: 8px;
        overflow: hidden;
      }
    }
  }
  .right{
    flex-shrink: 0;
    width: 29%;
    height: 100%;
    min-width: 300px;
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
  }
}
</style>