/*
 * @Description: 配置应用
 * @Author: luocheng
 * @Date: 2022-03-15 14:15:35
 * @LastEditors: luocheng
 * @LastEditTime: 2022-03-19 11:16:03
 */
/*
 * @Description: 复制 剪切  粘贴
 * @Author: luocheng
 * @Date: 2021-08-12 14:06:29
 * @LastEditors: luocheng
 * @LastEditTime: 2021-08-26 10:25:45
 */
// import store from './index';
// import toast from '@/utils/toast';
// import { getComponentId, deepCopy } from '@/utils/tools';
// import { setParseData } from '@/utils/editor';

export default {
	state: {
		// 应用信息
		applicationInfo: null,
		activeMenu: null,
		// 一二级菜单
		currentMenu: null,
		// 三级菜单
		currentMenuThree: null,
		// 是否显示四级菜单
		showMenuList: false,
		// 应用菜单顺序
		appMenuSortList: []
	},
	mutations: {
		/**
		 * @desc: 复制或剪切组件
		 */
		setApplicationInfo(state, appData) {
			state.applicationInfo = {
				...appData
			};
		},
		/**
		 * @desc: 当前操作的菜单
		 */
		setCurrentMenu(state, config) {
			if (!config) {
				state.currentMenu = null;
				state.currentMenuThree = null;
				state.activeMenu = null;
				return;
			}
			const { level, menuData } = config;
			if (level < 3) {
				state.currentMenu = menuData;
			} else if (level === 3) {
				state.currentMenuThree = menuData;
			}
			state.activeMenu = menuData;
		},
		/**
		 * @desc: 切换四五级菜单显示
		 */
		toggleMenuListShow(state, status) {
      state.showMenuList = status !== undefined ? status : !state.showMenuList;
    },
		/**
		 * @desc: 暂存排序
		 * @param {Array} arr
		 */
		setAppMenuSortList(state, arr) {
			arr.forEach(ele => {
				const index = state.appMenuSortList.findIndex(item => item.id === ele.id)
				if (index === -1) {
					state.appMenuSortList.push(ele);
				} else {
					state.appMenuSortList.splice(index, 1, ele);
				}
			})
		},
		/**
		 * @desc: 缓存当前配置
		 */
		cacheCurrentConfig(state, config) {
			console.log(state, config);
		}
	}
};
