<!--
 * @LastEditTime: 2022-08-04 14:59:11
 * @Description: 关系选择器
 * @FilePath: /dwh_front/src/components/metaSelect/RelationSelect.vue
 * @Date: 2021-12-20 20:20:46
 * @Author: lisushuang
 * @LastEditors: ttheitao
-->
<template>
  <el-select
    :multiple="multiple"
    filterable
    allow-create
    v-model="selectValue"
    style="width: 100%"
    v-loading="selectLoading"
    default-first-option
    element-loading-spinner="el-icon-loading"
  >
    <el-option
      v-for="(item, index) in selectData"
      :key="index"
      :label="item.name"
      :value="item.uuid"
    >
    </el-option>
    <template slot="prefix">
      <MetaTraceIcon method="ShowRelationDetail" :uuid="selectValue" style="margin-right:5px" />
    </template>
    
  </el-select>
</template>

<script>
/* eslint-disable */
import objects from "@/api/objects";
import MetaTraceIcon from "./MetaTraceIcon.vue"

export default {
  name: "RelationSelect",
  components:{
    MetaTraceIcon
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    object_uuid: {
      type: String,
      default: "",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    effect: {
      type: Array | Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    object_uuid(val) {
      this.getSelectData();
    },
    effect(val, oldVal) {
      if (val.value == oldVal.value) {
        return;
      }
      this.getSelectData();
    },
    value(val, oldVal) {
      if (val && !oldVal && !this.selectValue) {
        this.selectValue = val;
      }
    },
    selectValue() {
      this.$emit("input", this.selectValue);
    },
  },
  data() {
    return {
      selectData: [],
      selectValue: "",
      selectLoading: true,
    };
  },
  methods: {
    getSelectData() {
      this.selectLoading = true;
      let object_uuid = this.object_uuid;
      if (this.effect && this.effect.type == "object" && this.effect.value) {
        object_uuid = this.effect.value;
      }
      // 重新请求视图列表数据
      objects
        .getMyRelations(object_uuid)
        .then((res) => {
          this.selectLoading = false;
          if (res.data.code == 200) {
            this.selectData = res.data.data.data;
          } else {
            this.selectData = [];
          }
        })
        .catch((res) => {
          this.selectLoading = false;
          this.selectData = [];
        });
    },
  },
  mounted() {
    this.getSelectData();
    this.selectValue = this.value

  },
};
</script>
