/*
 * @LastEditTime: 2022-08-05 15:15:53
 * @Description: 显示元数据引用信息的行为
 * @FilePath: /dataview-next/src/global/actions/showMetaQoutes.js
 * @Date: 2022-01-12 14:10:39
 * @Author: lisushuang
 * @LastEditors: lisushuang
 */
/* eslint-disable */
import { Message, Table, Dialog, TableColumn, Button, Tag, Input, TimeSelect } from "element-ui";
import baseAction from "./baseAction";
import objectSearch from "@/api/objectSearch"
import Vue from "vue";
import { searchInfo } from "../globalSearch";
import HightlightText from  "@/components/HightlightText"

export default class showMetaQoutes extends baseAction {

  info = new Vue({
    data(){
      return {
        allData: [],
        originData:[],
        showData: [],
        nowPage:1,
        pageSize:15,
        loading: false,
        search: ''
      }
    }
  })

  constructor() {
    super()
  }

  handler(value = null, inType = 'uuid') {
    console.log('showMetaQoutes-props:', this.props)
    let search = null
    let name = null
    let type = null
    if (!value) {

      if (this.props.uuid !== undefined) {
        search = this.props.uuid
        name = this.props.name
        type = 'uuid'
      } else if (this.props.class !== undefined) {
        search = this.props.class
        name = this.props.desc
        type = this.props.type
      }

      if (!search || !type) {
        Message.error('未找到有效的查询标识！')
        return
      }
    } else {
      type = inType
      search = value
      name = value
    }
    this.getUsesInfo(type, search).then(res => {
      this.loading.close()
      this.renderList(res, name)
    })
  }

  /**
   * @description: 在 messagebox 中渲染一个 table 
   * @param {Array} res 数据
   * @param {String} name 查询的对象名称
   * @return {*}
   */
  renderList(res, name) {
    // h 由外面的对象传入使用
    let _this = this
    let count = JSON.parse(JSON.stringify(res)).length
    let title = '【' + (name ? name : '未知') + '】引用查询 共' + count + '条'
    this.info.originData = JSON.parse(JSON.stringify(res))
    this.info.allData = res
    this.info.showData = this.info.allData.slice(0,this.info.pageSize)
    this.showPopDialog(this.content, title,this.footer)
  }

  /**
   * @description: 弹窗渲染主体
   */  
  content = () => {
    let _this = this
    let h = this.mountInstance.$createElement
    return (
      <div style="max-height:65vh">
        <Table
          data={this.info.showData}
          border
          height="calc(65vh - 80px)"
          style="width: 100%;"
        >
          <TableColumn label="对象名称" align="center" prop="object_name" scopedSlots={{
            default: (scope) => {
              return (
                <HightlightText text={scope.row.data_type == 'sys_pages' ? '页面数仓引用' : scope.row.object_name} keyword={this.info.search} />
                // <span>{scope.row.data_type == 'sys_pages' ? '页面数仓引用' : scope.row.object_name}</span>
              )
            }
          }}>
          </TableColumn>
          <TableColumn label="对象uuid" align="center" prop="object_uuid" scopedSlots={{
            default: (scope) => {
              return (
                <HightlightText text={scope.row.data_type == 'sys_pages' ? '页面:' + scope.row.uuid : scope.row.object_uuid} keyword={this.info.search} />
                // <span>{scope.row.data_type == 'sys_pages' ? '页面:' + scope.row.uuid : scope.row.object_uuid}</span>
              )
            }
          }}></TableColumn>
          <TableColumn label="引用类型" align="center" scopedSlots={{
            default: (scope) => {
              // 参数路径没写全，渲染时会直接给 undefined
              return (
                <Tag effect="dark" type="success">
                  {_this ? _this._checkDataTypeName(scope.row.data_type) : scope.row.data_type}
                </Tag>
              )
            }
          }}>

          </TableColumn>
          <TableColumn label="引用名称" align="center" scopedSlots={{
            default: (scope) => {
              return (
                <HightlightText text={scope.row.name} keyword={this.info.search} />
              )
            }
          }}></TableColumn>
          <TableColumn label="操作" align="center" scopedSlots={{
            default: (scope) => {
              return (
                <Button type="primary" onClick={() => { searchInfo(scope.row.uuid ? scope.row.uuid : scope.row.object_uuid).then(() => _this.closeDialog()) }}>跳转引用</Button>
              )
            }
          }}></TableColumn>
        </Table>
      </div>
    )
  }

  /**
   * @description: 弹窗底部，简单实现一个分页即可
   */  
  footer = () => {
    let h = this.mountInstance.$createElement
    return (
      <div>
        搜索：<Input value={this.info.search} onInput={(value)=>{this.info.search = value;this.changePage(1)}} style={{display:"inline-block",width:"200px",marginRight:"20px"}}></Input>
        <Button onClick={()=>this.changePage(this.info.nowPage - 1)}>上一页</Button>
        <Button>{this.info.nowPage} / {parseInt(this.info.allData.length / this.info.pageSize) + 1}</Button>
        <Button onClick={()=>this.changePage(this.info.nowPage + 1)}>下一页</Button>
      </div>
    )
  }

  /**
   * @description: 页码变化并更新数据
   * @param {Integer} page 页码
   */  
  changePage(page = 1) {
    if(page < 1 || page > parseInt(this.info.allData.length / this.info.pageSize) + 1) {
      return
    }
    // 清空显示数据
    this.info.showData = []

    // 如果有搜索值，那么重建 alldata
    if(this.info.search){
      this.info.allData = this.info.originData.filter((item) => {
        return  ( item.name &&item.name.indexOf(this.info.search) > -1 )
        || (item.object_name && item.object_name.indexOf(this.info.search) > -1)
        || (item.object_uuid && item.object_uuid.indexOf(this.info.search) > -1)
        // || (item.uuid && item.uuid.indexOf(this.info.search) > -1)
        // || (item.object_code && item.object_code.indexOf(this.info.search) > -1)
      })
    }else{
      this.info.allData = this.info.originData
    }

    // 处理显示数据
    this.info.nowPage = page
    this.info.$nextTick(() => {
      this.info.showData = this.info.allData.slice(this.info.pageSize * (page - 1),this.info.pageSize * page)
    })
  }

  /**
   * @description: 获取引用数据
   * @param {String} type 获取的类型 [behavior value uuid]
   * @param {String} search 查询的值 
   * @return {*}
   */
  getUsesInfo(type = null, search) {
    this.getLoading('数据请求中，稍后...')

    return new Promise((resolve, reject) => {
      let func = ''
      if (type == 'uuid') {
        func = 'getUuidUses'
      }
      if (type == 'behavior') {
        func = 'behaviorClassUses'
      }
      if (type == 'value') {
        func = 'valueClassUses'
      }
      if (!func) {
        this.loading.close()
        Message.error('无效的元数据引用查询类型！')
        reject()
      }

      objectSearch[func](search).then(res => {
        if (res.data.code == 200) {
          resolve(res.data.data)
        } else {
          this.loading.close()
          Message.error('查询引用失败！')
          reject()
        }
      }).catch(res => {
        this.loading.close()
        Message.error('查询引用失败！')
        reject()
      })

    })
  }

  /**
   * @description: 把 data_type(表名)换成名称
   * @param {*} data_type 数据来源表名
   * @return {*}
   */
  _checkDataTypeName(data_type = '') {
    let dicts = {
      'metadata_behaviors': '行为',
      'metadata_dictionary': '字典',
      'metadata_dictionary_items': '字典字段',
      'metadata_events': '事件',
      'metadata_object_fields': '对象字段',
      'metadata_object_integrated_api': '对象API集成',
      'metadata_objects_design': '对象表单设计',
      'metadata_views': '对象视图',
      'sys_pages': '页面设计',
      'metadata_objects': '对象',
      'metadata_relationships': '对象关联关系',
      'metadata_validates': '对象验证集'
    }

    let keys = Object.keys(dicts)
    if (keys.indexOf(data_type) == -1) {
      return data_type
    } else {
      return dicts[data_type]
    }
  }

  /**
   * @description: 引用详情跳转
   * @param {Object} item 点击的列表数据
   * @return {void}
   */
  _solveQouteNavigate(item) {
    // 直接调用全局搜索行为即可
  }
}