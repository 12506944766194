var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-container",staticStyle:{"flex-direction":"column"},attrs:{"id":_vm.boxId}},[(_vm.statusConfig && (!_vm.statusConfig.isTreeTable || (_vm.statusConfig.isTreeTable && _vm.statusConfig.rowKey)))?_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.statusConfig.loading),expression:"statusConfig.loading"}],staticClass:"table-box",staticStyle:{"width":"100%","border":"1px solid #f2f3f5"},attrs:{"data":_vm.tableData,"height":_vm.height,"row-key":_vm.getRowKey,"stripe":_vm.statusConfig.stripe,"border":_vm.statusConfig.border,"size":_vm.statusConfig.size,"fit":_vm.statusConfig.fit,"show-header":_vm.statusConfig.showHeader,"highlight-current-row":_vm.statusConfig.highlightCurrentRow,"empty-text":_vm.statusConfig.emptyText,"tree-props":_vm.treeProps},on:{"selection-change":_vm.handleSelectionChange,"row-click":_vm.onRowClick}},[(_vm.statusConfig.canSelect)?_c('el-table-column',{attrs:{"type":"selection","width":"55"}}):_vm._e(),(_vm.statusConfig.showOrder)?_c('el-table-column',{attrs:{"label":"序号","type":"index","width":"50"}}):_vm._e(),_vm._l((_vm.fieldConfig),function(item,index){return (item.show && _vm.validArchiType(item.archiType))?_c('el-table-column',{key:item.uuid,attrs:{"label":item.label,"align":_vm.statusConfig.align,"header-align":_vm.statusConfig.headerAlign,"width":item.width},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return _c('span',{style:({ color: row[item.uuid] ? item.color : '#bbb' })},[(item.type === 2)?[_c('el-image',{staticClass:"image",style:({
							height: (item.imgConfig.height || 60) + 'px',
							width: (item.imgConfig.height || 60) + 'px',
						}),attrs:{"src":row[item.uuid] && row[item.uuid].indexOf('http') > -1 ? row[item.uuid] : _vm.defaultImage,"previewSrcList":[row[item.uuid] ? (row[item.uuid].toString().replace('_thumb', '')) : _vm.defaultImage ],"lazy":"","fit":"cover","alt":"图片"}})]:(item.type === 3 && row[item.uuid])?_c('span',{style:({
						color:
							_vm.filterStatus(row[item.uuid], item.statusOptions)
								.color || item.color,
					})},[_vm._v(" "+_vm._s(_vm.filterStatus(row[item.uuid], item.statusOptions) .label)+" ")]):(item.type === 4 && row[item.uuid])?[_vm._v(_vm._s(_vm.filterTime(row[item.uuid], item.timeFormat, item.connector)))]:(item.type === 5 && row[item.uuid])?[_c('div',{staticClass:"rich-text",domProps:{"innerHTML":_vm._s(row[item.uuid])}})]:(item.type === 6 && row[item.uuid])?[_c('a',{staticClass:"link",attrs:{"href":row[item.uuid],"alt":"链接"}},[_vm._v(" "+_vm._s(row[item.uuid])+" ")])]:(item.type === 7)?[_c('el-switch',{on:{"change":function($event){return _vm.onSwitch(item, row[item.uuid], row)}},model:{value:(row[item.uuid]),callback:function ($$v) {_vm.$set(row, item.uuid, $$v)},expression:"row[item.uuid]"}})]:[_vm._v(_vm._s(row[item.uuid] || '/'))]],2)}}],null,true)}):_vm._e()}),(_vm.actionConfig && _vm.actionConfig.needAction)?_c('el-table-column',{key:"actions",attrs:{"width":_vm.actionConfig.width || '',"align":_vm.statusConfig.align,"header-align":_vm.statusConfig.headerAlign,"label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return _vm._l((_vm.actionConfig),function(action,key){return _c('span',{key:key},[(
							typeof action === 'object' &&
							_vm.actionConfig[key].use && _vm.logicAction(_vm.actionConfig[key], scope.row)
						)?_c('el-button',{style:({
							color: _vm.actionConfig[key].color,
							marginLeft: _vm.actionConfig[key].margin + 'px',
						}),attrs:{"type":_vm.actionConfig[key].btnType,"size":_vm.actionConfig[key].size},on:{"click":function($event){return _vm.onAction(key, scope.row, $event)}}},[_vm._v(_vm._s(_vm.actionConfig[key].name))]):_vm._e()],1)})}}],null,false,2472395090)}):_vm._e()],2):_vm._e(),(_vm.statusConfig.pagination)?_c('el-pagination',{attrs:{"current-page":+_vm.pager.current_page,"page-sizes":[15, 30, 50, 100],"page-size":+_vm.pager.per_page,"layout":"total, sizes, prev, pager, next, jumper","total":+_vm.pager.total},on:{"size-change":_vm.onSize,"current-change":_vm.onPage}}):_vm._e(),(_vm.showSheet && _vm.sheetFormData)?_c('Spreadsheet',{attrs:{"formData":_vm.sheetFormData,"id":_vm.exportConfig && _vm.exportConfig.exportTemplate,"isSave":false,"isHidden":true,"autoDownLoad":'excel'},on:{"exportSuccess":_vm.onExportSuccess}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }