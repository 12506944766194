<template>
  <div class="todo-com-container" v-loading="loading">
    <div class="todo-com-header">
      <div class="todo-tabs">
        <div class="item" @click.stop="activeTab = 'tab1'">
          <span class="text" :class="activeTab === 'tab1' ? 'active' : ''">待办事项</span>
          <span class="num-label">{{todoListTotal}}</span>
        </div>
        <div class="item" @click.stop="activeTab = 'tab2'">
          <span class="text" :class="activeTab === 'tab2' ? 'active' : ''">抄送给我</span>
          <span class="num-label">{{warringTotal}}</span>
        </div>
      </div>
    </div>
    <section class="todo-main-warp">
      <TodoList v-show="activeTab === 'tab1'" :list="todoList" :loading="todoLoading" @rePageSize='changeSize'
        @showDialog="showGlobalDialog" />
      <WarringList v-show="activeTab === 'tab2'" :list="warringList" :loading="warringLoading" @showDialog="showGlobalDialog" 
        @refresh="getWarringList" />
    </section>

    <GlobalDialog v-if="showDialog" tag="all" :title="dialogData.title" :data_id="dialogData.data_id"
      :form_uuid="dialogData.form_uuid" :archi_type="dialogData.archi_type" @close="closeFormDialog" />

  </div>
</template>

<script>
import TodoList from './TodoList.vue'
import WarringList from './NewWarringList.vue'
import { getNewHomeList } from '@/saas-apis/home.js';
import eventBus from '@/plugins/eventBus';
import GlobalDialog from "@/global/components/FormDialog"

export default {
  components: { TodoList, WarringList, GlobalDialog },
  data() {
    return {
      activeTab: 'tab1',
      todoList: [],
      todoListTotal: 0,
      warringList: [],
      warringPage: 0,
      warringTotal: 0,
      todoLoading: false,
      warringLoading: false,
      warringListIsEnd: false,
      loading: false,
      todoListSize: 20,

      showDialog: false,
      dialogData: {}
    }
  },
  created() {
    this.getTodoList()
    this.getWarringList()
  },
  mounted() {
    /**
     * @desc: 操作完毕关闭弹窗
     */
    eventBus.$on('updateTodoList', () => {
      this.getTodoList();
    })
  },
  methods: {

    /**
     * @description: 显示全局消息表单
     * @param {Object} data
     */
    showGlobalDialog(data) {
      this.dialogData = data
      this.$nextTick(() => {
        this.showDialog = true
      })
    },

    /**
     * @description: 关闭全局消息表单弹窗
     */
    closeFormDialog() {
      this.showDialog = false;
      this.$nextTick(() => {
        this.dialogData = {}
      })
    },

    changeSize(data) {
      this.todoListSize += data
      this.getTodoList()
    },
    getTodoList() {
      this.loading = true
      const data = {
        msg_type: 1,
        is_page: 1,
        size: this.todoListSize
      }
      getNewHomeList(data).then(res => {
        this.loading = false
        if (res.data.code === 200) {
          this.todoListTotal = res.data.data.total
          this.todoList = res.data.data.data
        }
      }).catch(() => this.loading = false)
    },
    getWarringList() {
      this.warringLoading = true
      const data = {
        msg_type: 3,
        is_page: 1,
        size: this.todoListSize
      }
      getNewHomeList(data).then(res => {
        this.warringLoading = false
        if (res.data.code === 200) {
          this.warringTotal = res.data.data.total
          this.warringList = res.data.data.data
        }
      }).catch(() => this.warringLoading = false)
    }
  },
}
</script>
<style lang="less" scoped>
.todo-com-container {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: #fff;
  padding: 12px 10px;
  box-sizing: border-box;

  .todo-com-header {
    height: 32px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 10px;
    box-sizing: border-box;

    .todo-tabs {
      height: 100%;
      display: flex;
      align-items: center;

      .item {
        display: flex;
        align-items: center;
        cursor: pointer;

        .text {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          color: #6D768F;
          line-height: 24px;
          letter-spacing: 1px;

          &:hover {
            color: #409eff;
          }
        }

        .active {
          color: #2A2F3D;
        }

        .num-label {
          margin-left: 8px;
          height: 18px;
          line-height: 18px;
          display: inline-block;
          padding: 0 8px;
          border-radius: 10px;
          background: #33BBFF;
          color: #fff;
        }

        &+.item {
          margin-left: 24px;
        }
      }
    }
  }

  .todo-main-warp {
    height: calc(100% - 32px);

    .todo-list-warp {
      width: 100%;
      height: 100%;
      overflow-y: auto;

      .list-item {
        width: 100%;
        display: flex;
        padding: 12px 0px;
        justify-content: space-between;
        align-items: flex-end;

        .info-warp {
          display: flex;

          .info-icon {
            height: 36px;
            width: 36px;
            line-height: 36px;
            text-align: center;
            border-radius: 6px;
            font-size: 22px;
            font-family: YouSheBiaoTiHei;
          }

          .q-icon {
            background: rgba(90, 204, 120, .2);
            color: #5ACC78;
          }

          .s-icon {
            background: rgba(255, 170, 74, .2);
            color: #FFAA4A;
          }

          .p-icon {
            background: rgba(51, 187, 255, .2);
            color: #33BBFF;
          }

          .info-text-warp {
            margin-left: 12px;

            .line-1 {
              display: flex;
              align-items: center;

              .info-title {
                height: 22px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 500;
                color: #292F3D;
                line-height: 22px;
              }

              .status {
                margin-left: 8px;
                height: 20px;
                padding: 0 8px;
                box-sizing: border-box;
                border-radius: 12px;
                line-height: 20px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
              }

              .w-status {
                background: #FFF1F1;
                color: #E0452D;
              }

              .s-status {
                background: #E1F5E7;
                color: #21A35D;
              }

              .p-status {
                color: #0088CC;
                background: #E0F4FF;
              }
            }

            .line-2 {
              height: 18px;
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #6D768F;
              line-height: 18px;
            }
          }
        }

        .time-status {
          height: 100%;
          white-space: nowrap;
          height: 18px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #6D788F;
          line-height: 18px;
          margin-right: 5px;
        }
      }
    }
  }
}
</style>
