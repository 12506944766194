/* eslint-disable */

import axios from 'axios'
import {Loading, Message} from 'element-ui';
import store from "../store"
import { getToken, setToken, setDevToken } from "../utils/tools";
import eventBus from '@/plugins/eventBus';


let baseUrl, originURl, metadatatoken
let projectInfo = JSON.parse(localStorage.getItem('projectInfo'))

const needTokenKeyWords = [
    'eventsTriggerAll',
    'eventsTriggerOne',
    'behaviorTriggerAll',
    'behaviorTriggerOne'
]

if (projectInfo) {
    baseUrl = projectInfo.originURl + '/api/saas/metadata/'
    originURl = projectInfo.originURl
    metadatatoken = projectInfo.metadatatoken
} else {
  baseUrl = "https://saas-api.bimcc.net/api/saas/metadata/"
  originURl = "https://saas-api.bimcc.net"

    // baseUrl = "http://www.test.com/api/metadata/"
    // originURl = "http://www.test.com"
    metadatatoken = 'aba62ca1-c2ff-42af-9d3a-bbe5b02f7b06'
}

// let baseUrl = "http://www.test.com/api/metadata/"
// let originURl = "http://www.test.com"

// let baseUrl = "https://crt15.bimcc.net/api/metadata/"
// let originURl = "https://crt15.bimcc.net"

let contentType = 'application/json;charset=UTF-8'
let timeout = 60000

let instance = axios.create({
  baseUrl,
  timeout: timeout,
  headers: {
    'Content-Type': contentType,
    'metadatatoken': metadatatoken,
    'bimcctoken': getToken()
  },
})
// 请求拦截
instance.interceptors.request.use((config) => {
  config.headers.bimcctoken = getToken()
  let needToken = false
  for (let i = 0; i < needTokenKeyWords.length; i++) {
    const keyword = needTokenKeyWords[i]
    config.url.indexOf(keyword) > -1 && (needToken = true)
  }
  let info = JSON.parse(localStorage.getItem('projectInfo'))
  // 防抖节流函数应该在此
  let is_real = false
  if (config.url.indexOf('://') > -1) {
    is_real = true
    config.url = config.url
  } else if (config.url.indexOf('/') == 0) {
    is_real = false
    config.url = originURl + config.url
  } else {
    config.url = baseUrl + config.url
  }
  if (info && info != null) {
    let arr = config.url.split('/api/saas/metadata/')
    if (config.url.includes('api/form4')) {
      let mockUser = localStorage.getItem('mockUserInfo')
      if (mockUser && JSON.parse(mockUser)) {
        mockUser = JSON.parse(mockUser)
        if (mockUser.token) {
          config.headers.bimcctoken = mockUser.token
        }
      }
      if (!is_real) {
        arr = config.url.split('api/form4/')
        config.url = info.originURl + '/api/form4/' + arr[1]
      }
      config.headers.metadatatoken = info.metadatatoken
    } else if (config.url.includes('api/flow4')) {
      let mockUser = localStorage.getItem('mockUserInfo')
      if (mockUser && JSON.parse(mockUser)) {
        mockUser = JSON.parse(mockUser)
        if (mockUser.token) {
          config.headers.bimcctoken = mockUser.token
        }
      }
      if (!is_real) {
        arr = config.url.split('api/flow4/')
        config.url = info.originURl + '/api/flow4/' + arr[1]
      }
      config.headers.metadatatoken = info.metadatatoken
    } else if (config.url.includes('api/mapi')) {
      let mockUser = localStorage.getItem('mockUserInfo')
      if (mockUser && JSON.parse(mockUser)) {
        mockUser = JSON.parse(mockUser)
        if (mockUser.token) {
          config.headers.bimcctoken = mockUser.token
        }
      }

      if (!is_real) {
        config.url = info.originURl + '/api/mapi'
      }
      config.headers.metadatatoken = info.metadatatoken
    } else if (needToken || config.url.includes('api/etl')) {
      let mockUser = localStorage.getItem('mockUserInfo')
      if (mockUser && JSON.parse(mockUser)) {
        mockUser = JSON.parse(mockUser)
        if (mockUser.token) {
          config.headers.bimcctoken = mockUser.token
        }
      }
    } else {
      if (!is_real) {
        config.url = info.originURl + '/api/saas/metadata/' + arr[1]
      } else {
        config.url = config.url
      }
      config.headers.metadatatoken = info.metadatatoken
    }

  }
  if (config.metadatatoken) {
    config.headers.metadatatoken = config.metadatatoken
  }
  return config
},
  (error) => {
    return Promise.reject(error)
  }
)

// 响应拦截
instance.interceptors.response.use((res) => {
    let {data} = res
    if (data.code != 200 && data.msg) {
        if (data.code === 401) {
            eventBus.$emit("showChangeArchi");
            sessionStorage.clear();
            localStorage.clear();
            setToken('')
            window.location.href = '/login'
        } else if (data.code === 402) {
            // 页面编辑器 管理员登录异常处理
            // 判断当前所在仓库是否测试服务器
            if (originURl == 'https://dwh_api.bimcc.net') {
                // 测试服务器跳转登录页
                Message.error({message: data.msg});
                sessionStorage.clear();
                localStorage.removeItem('devUserInfo');
                localStorage.removeItem('projectInfo');
                localStorage.removeItem('userInfo');
                localStorage.removeItem('userRoles');
                localStorage.removeItem('targetArchi');
                localStorage.removeItem('mockUserInfo');
                setDevToken('')
                setToken('');
                console.log('----111--测试服务器跳转登录页----Global');
                window.location.href = '/login'
            } else {
                // 非测试服务器报错
                Message.error({message: '非测试服务器禁止增删改操作'});
            }
        } else {
            Message.error({message: data.msg});
        }
    }
    return res
})
export default instance
