import request from '@/apis/http/saasRequest.js'

/**
 * @desc: 删除search参数中的value为空的筛选
 */
const removeNullSearch = data => {
	for (let key in data) {
		if (key === 'search' && Array.isArray(data[key]) && data[key].length) {
			let search = [];
			for (let i = 0; i < data[key].length; i++) {
				if (data[key][i].value) {
					search.push(data[key][i]);
				}
			}
			return {
				...data,
				search
			}
		}
	}
	return data;
}

export const getUsers = async (params) => {
    let url = 'api/plan/transferUser'
    if (params) {
        url += JSON.stringify(params)
    }
    // 判断是否存在，是否过期
    if (data[url] && data[url].exp > new Date().getTime() - 1000 * 60) {
        return new Promise((resolve) => {
            resolve(JSON.parse(JSON.stringify(data[url].data)))
        })
    } else {
        let flag = true
        let res = await request({
            url: 'api/plan/transferUser',
            method: 'get',
            params
        })
        if (res && res.data.code === 200) {
            data[url] = {
                data: JSON.parse(JSON.stringify(res)),
                exp: new Date().getTime()
            }
        } else {
            flag = false
        }
        return new Promise((resolve, reject) => {
            if (flag)  {
                resolve(res)
            } else {
                reject(res)
            }
        })
    }
}

const data = {}
export const getSections = async (params) => {
    let url = 'api/sections'
    if (params) {
        url += JSON.stringify(params)
    }
    // 判断是否存在，是否过期
    if (data[url] && data[url].exp > new Date().getTime() - 1000 * 60) {
        return new Promise((resolve) => {
            resolve(JSON.parse(JSON.stringify(data[url].data)))
        })
    } else {
        let flag = true
        let res = await request({
            url: 'api/sections',
            method: 'get',
            params
        })
        if (res && res.data.code === 200) {
            res.data.data.map(el => {
                el.label = el.name
                el.value = el.id
            })
            data[url] = {
                data: JSON.parse(JSON.stringify(res)),
                exp: new Date().getTime()
            }
        } else {
            flag = false
        }
        return new Promise((resolve, reject) => {
            if (flag)  {
                resolve(res)
            } else {
                reject(res)
            }
        })
    }
}

// 操作工作计划
export const operateWorlPlan = (id, data) => {
    return request({
        url: `api/assignments/${id}/operate`,
        method: 'put',
        data
    })
}

// 工作计划详情
export const workPlanDetlies = (id) => {
    return request({
        url: 'api/assignments/' + id,
        method: 'get'
    })
}

/**
 * @desc: 获取实际渲染的数据
 * @param {Object} data
 * __method_name__   类型：dataList —— 列表
 * object_uuid       对象ID
 * view_uuid         视图ID
 * search            筛选项(数组)
 */
export const getRenderData = (data) => {
	return request({
		url: 'api/mapi',
		data: removeNullSearch(data),
		method: 'POST'
	});
};


//表单渲染器
export const formRequest = (method,url,data = {}) => {
  if(method === 'get' || method === 'GET'){
    return request({
      url: url,
      method: method,
      params: data
    })
  } else {
    return request({
      url: url,
      method: method,
      data: data
    })
  }
};


// 待办
export const waitToDo = () => {
  return request({
    url: "api/backlog/produce",
    method: 'GET'
  })
}

// 提醒
export const remindInfo = () => {
  return request({
    url: "api/backlog/remind",
    method: "GET"
  })
}

// 已读 
export const isRead = (data) => {
  return request({
    url: "api/msg/read",
    data,
    method: "POST"
  })
}

// 已完成
export const finish = () => {
  return request({
    url: "api/backlog/finsh",
    method: "GET"
  })
}
// 获取任务详情
export const assignmentsDetailApi = (id) => {
  return request({
    url: "api/assignments/"+id,
    method: "GET"
  })
}



// 新首页列表
export const getNewHomeList = (params) => {
  return request({
    url: 'api/home/list',
    method: 'get',
    params
  })
}

//新首页已完成列表
export const getFinishedList = (params) => {
  return request({
    url: 'api/home/finish',
    method: 'get',
    params
  })
}
// 新首页标记抄送已读
export const newReadCC = (data) => {
  return request({
    url: 'api/msg/read',
    method: 'post',
    data
  })
}

//任务流程操作
export const taskFlowOperat = (data) => {
  return request({
    url: 'api/flowlog/operate',
    method: 'post',
    data
  })
}

//新增任务
export const addTaskApi = (data) => {
  return request({
    url: 'api/assignments',
    method: 'post',
    data
  })
}

//删除任务
export const deleteTaskApi = (id) => {
  return request({
    url: `api/assignments/${id}`,
    method: 'delete'
  })
}

export const editorTaskApi = (id, data) => {
  return request({
    url: `api/assignments/${id}`,
    method: 'put',
    data
  })
}

export const addTaskTempApi = (data) => {
  return request({
    url: 'api/assignmentTemplates',
    method: 'post',
    data
  })
}

export const getTaskTempDetailsApi = (id) => {
  return request({
    url: `api/assignmentTemplates/${id}`,
    method: 'get'
  })
}

export const modifTaskTemplateApi = (id, data) => {
  return request({
    url: `api/assignmentTemplates/${id}`,
    method: 'put',
    data
  })
}

// 获取模块名
export const getModulNames = () => {
  return request({
    url: 'api/home/getModuleList',
    method: 'get'
  })
}


export const OverheadApi = (data) => {
  return request({
    url: '/api/home/top',
    method: 'post',
    data
  })
}


export const cancelOverheadApi = (data) => {
  return request({
    url: '/api/home/top',
    method: 'delete',
    data
  })
}