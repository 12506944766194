<template>
    <div class="wrapPanel">
        <div class="content-wrap" v-show="!isEdit">
            <div class="btnsUl horizontal-flex">
                <el-tooltip v-for="item in viewPointBtns" :key="item.id" :content="item.name" placement="bottom">
                    <a :class="['iconfont', item.icon]" @click="onClickBtn(item)"></a>
                </el-tooltip>
                <el-select v-model="viewPointFilterSelect"
                           :popper-append-to-body="false"
                           class="filter-wrap"
                           placeholder="请选择"
                           size="small"
                           @change="refreshViewStateList"
                >
                    <el-option v-for="obj in viewPointFilter" :key="obj.id" :value="obj.id" :label="obj.name"></el-option>
                </el-select>
            </div>

            <div class="viewPointTree-wrap">
                <el-collapse>
                    <el-collapse-item v-for="item in viewPointList" :key="item.id" :name="item.id">
                        <template #title>
                            <div class="horizontal-flex tree-group-wrap">
                                <div class="tree-group-wrap__title">{{item.name}}</div>
                                <el-dropdown trigger="click"
                                             hide-on-click
                                             class="tree-group-wrap__btns"
                                             @command="onClickDropdown($event, item)"
                                >
                                    <a class="iconfont iconbimgis_gengduo" @click.stop></a>
                                    <el-dropdown-menu slot="dropdown">
                                        <!-- <el-dropdown-item command="renameGroup">重命名</el-dropdown-item> -->
                                        <el-dropdown-item command="deleteGroup">删除组</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>
                        </template>

                        <div v-for="child in item.children" :key="child.id" class="horizontal-flex tree-obj-wrap">
                            <img class="tree-obj-wrap__img" :src="child.picture" @click="onClickViewPoint(child)">
                            <div class="vertical-flex tree-obj-wrap__content">
                                <span class="tree-obj-wrap__title">{{ child.name }}</span>

                                <div class="horizontal-flex">
                                    <div class="tree-obj-wrap__tip">{{ child.created_at.substring(0, 10) }}</div>

                                    <el-tooltip content="涂鸦绑定" placement="bottom">
                                        <a class="icona-qianzhenghuabi iconfont tree-obj-wrap__btns"
                                           :style="{ color : ((child?.graffiti )? '#0076FF' : 'inherit')}"
                                           @click="onClickGraffiti( child )"
                                        ></a>
                                    </el-tooltip>

                                    <el-tooltip content="绑定构件触发" placement="bottom">
                                        <a class="icongis_bangding iconfont tree-obj-wrap__btns"
                                           :style="{ color : ((child?.bind_info?.selection )? '#0076FF' : 'inherit')}"
                                           @click="onClickDropdown('bindSelection' , child)"
                                        ></a>
                                    </el-tooltip>

                                    <el-tooltip content="更新视点" placement="bottom">
                                        <a class="tree-obj-wrap__btns iconfont icongis_huifuchushi" @click="onClickDropdown('refresh', child)"></a>
                                    </el-tooltip>

                                    <el-dropdown trigger="click"
                                                 hide-on-click
                                                 class="tree-obj-wrap__btns"
                                                 @command="onClickDropdown($event, child)"
                                    >
                                        <a class="iconfont iconbimgis_gengduo" @click.stop></a>
                                        <el-dropdown-menu slot="dropdown">
                                            <!-- <el-dropdown-item command="default">设为默认</el-dropdown-item> -->
                                            <!-- <el-dropdown-item command="tuya">视点涂鸦</el-dropdown-item> -->
                                            <!-- <el-dropdown-item command="share">分享视点</el-dropdown-item> -->
                                            <el-dropdown-item command="view">查看视点</el-dropdown-item>
                                            <el-dropdown-item command="delete">删除视点</el-dropdown-item>
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </div>
                            </div>
                        </div>
                    </el-collapse-item>
                </el-collapse>
            </div>
        </div>

        <viewerBIMEditor
                v-show="isEdit"
                class="editor-wrap"
                :infoList="viewPointEditor"
                :cancelCallback="onCancelObj"
                :confirmCallback="onConfirmObj"
        ></viewerBIMEditor>
    </div>
</template>

<script>
    import { Tooltip, Dropdown, DropdownMenu, DropdownItem, } from "element-ui";
    import viewerBIMEditor from "./viewerBIMEditor";
    import { dataInterface } from '@/apis/data/index';
    import eventBus from '@/plugins/eventBus';
    const DataBaseInfo = {
        ViewState : {
            object_uuid:'object63a01147947d3',
            view_uuid:'view63a011e3c186b'
        },
        Group : {
            object_uuid:'object63a29f41efd4d',
            view_uuid:'view63a2a0ac6dd6e',
        },
        MyViewState : {
            object_uuid:'object63a01147947d3',
            view_uuid:'view63ad36b11fa8f',
        },
        ProjectInViewState : {
            object_uuid:'object63a01147947d3',
            view_uuid:'view63ad36b4ce82e',
        }
    }


    export default {
        name: "viewerBIMScene",
        components: {
            "el-tooltip" : Tooltip,
            "el-dropdown" : Dropdown,
            "el-dropdown-menu" : DropdownMenu,
            "el-dropdown-item" : DropdownItem,
            viewerBIMEditor
        },
        data(){
            return {
                isEdit: false,
                viewPointBtns: [
                    {id: 'addGroup', name: '添加分组', icon: 'iconxinzengfenlei'},
                    {id: 'addObj', name: '添加视点', icon: 'iconbim_huidaojiaodian'}
                ],
                viewPointFilter: [{
                    id : 'All',
                    name : '全部数据',
                },{
                    id : 'My' ,
                    name : '由我创建',
                },{
                    id : 'ProjectIn',
                    name : '当前项目'
                }],
                viewPointFilterSelect: 'All',
                viewPointList: [],
                viewPointEditor: [],
                viewPointMap :{}, //根据id来索引视点
            }
        },
        props : {
            element: {
                type: Object,
                required: true,
                default: () => {
                    return {
                    }
                }
            },
            bindViewerId :{
                type : String,
            },
            bindDataMainId:{
                type : String,
            },
            // 是否在组合内
            isGroup: {
                type: Boolean,
            },
            // 组合内组件列表
            groupComponents: {
                type: Array,
                default: () => []
            }
        },
        methods: {
            getGroupOptions(){
                let res = [];
                for (let item of this.viewPointList){
                    res.push({id: item.id, name: item.name});
                }
                return res;
            },
            async createViewPointGroup(){
                this.$prompt("请输入分组名称：").then( async ( {value} )=>{

                    const res = await dataInterface({
                        __method_name__ : "createData",
                        object_uuid: DataBaseInfo.Group.object_uuid,
                        view_uuid: DataBaseInfo.Group.view_uuid,
                        data_main_id : this.bindDataMainId,
                        type : "ViewState",
                        name : value,
                    });

                    if(!res?.data?.data  || res.status !== 200) return console.warn(`[BIM]创建视点组失败`);
                    const data = res?.data?.data;

                    this.viewPointList.push({
                        id : `group_${data.id}`,
                        name : value,
                        children : [],
                    });

                }).catch(()=>{
                });
            },
            createViewPointObj(){
                let group = this.viewPointList.length > 0 ? this.viewPointList[0].id : '';
                this.viewPointEditor = [
                    {id: 'name', name: '标题', type: 'input', value: '视点'},
                    {id: 'tooltip', name: '描述', type: 'input', value: ''},
                    {id: 'group', name: '视图组', type: 'selector', options: this.getGroupOptions(), value: group},
                ];
                this.isEdit = true;
            },
            onClickBtn(item){
                switch (item.id) {
                    case 'addGroup'://添加组
                        this.createViewPointGroup();
                        break;
                    case 'addObj'://添加对象
                        this.createViewPointObj();
                        break;
                }
            },
            onCancelObj(){
              this.isEdit = false;
            },
            onClickDropdown(type = '', item){
                switch (type) {
                    case 'renameGroup'://重命名

                        break;
                    case 'deleteGroup'://删除组
                        this.deleteGroup(item);
                        break;
                    case 'refresh'://更新视点
                            this.updateViewState(item);
                            break;
                    case 'default'://设为默认

                        break;
                    case 'tuya'://视点涂鸦

                        break;
                    case 'share'://分享视点

                        break;
                    case 'view'://查看视点
                        this.showViewState(item);
                        break;
                    case 'delete'://删除视点
                        this.deleteViewState(item);
                        break;
                    case 'bindSelection'://绑定选中
                        this.bindSelection(item);
                        break;
                }
            },
            onClickViewPoint(item){
                this.showViewState(item);
            },
            /**
             * 刷新视点列表
             */
            async refreshViewStateList(){
                const dataInfo = {
                    object_uuid: DataBaseInfo.ViewState.object_uuid,
                    view_uuid: DataBaseInfo.ViewState.view_uuid,
                }
                const user_id = this.$GetUserInfo('id');
                const archiType = this.$GetTargetArchi('archiType');

                switch(this.viewPointFilterSelect){
                    case 'All' :
                        dataInfo['search'] =  [{
                            code: 'data_main_id',
                            ruleType: 'eq',
                            value: this.bindDataMainId
                        }];
                        break;
                    case 'My':
                        dataInfo['object_uuid'] = DataBaseInfo.MyViewState.object_uuid;
                        dataInfo['view_uuid'] = DataBaseInfo.MyViewState.view_uuid;
                        dataInfo['user_id'] = user_id;
                        break;
                    case 'ProjectIn':
                        dataInfo['object_uuid'] = DataBaseInfo.ProjectInViewState.object_uuid;
                        dataInfo['view_uuid'] = DataBaseInfo.ProjectInViewState.view_uuid;
                        dataInfo['archiType'] = archiType;
                        break;
                }

                const resdata = await dataInterface(Object.assign({
                    __method_name__ : "dataList",
                    transcode: 0,
                },dataInfo));

                if(!resdata?.data?.data  || resdata.status !== 200) return console.warn(`[BIM]获取视点数据失败 id${this.bindDataMainId}`);

                const viewStateList = resdata?.data?.data?.data;

                const resgroup = await  dataInterface({
                    __method_name__ : "dataList",
                    object_uuid: DataBaseInfo.Group.object_uuid,
                    view_uuid: DataBaseInfo.Group.view_uuid,
                    search : [{
                        code: 'data_main_id',
                        ruleType: 'eq',
                        value: this.bindDataMainId
                    },{
                        code: 'type',
                        ruleType: 'eq',
                        value: "ViewState"
                    }],
                    transcode: 0,
                });

                let viewStateMap = {
                    default : {
                        id: 'default',
                        name: '默认分组',
                        children:[]
                    }
                }

                if(resgroup?.data?.data  && resgroup.status === 200){
                    const groupList = resgroup?.data?.data?.data;

                    for(let { id , name } of groupList){
                        const gid = `group_${id}`
                        viewStateMap[ gid ] = {
                            id : gid,
                            name,
                            children:[]
                        }
                    }
                }

                for(let { name , id , group_id, picture , state , created_at , bind_info , graffiti} of viewStateList){
                    const gid = `${group_id}`;
                    const vid = `vs_${id}`;

                    const obj = {
                        id : vid ,
                        name ,
                        picture,
                        created_at,
                        state,
                        graffiti,
                        bind_info,
                    }

                    if(viewStateMap[gid]){
                        viewStateMap[gid].children.push( obj );
                    }else{
                        viewStateMap['default'].children.push( obj );
                    }

                    this.viewPointMap[id] = obj;
                }

                this.viewPointList = Object.values(viewStateMap);
            },
            //外层更新绑定的viewer或者初始化时会刷新数据id触发这个方法
            onChangeDataMainId(){
                this.refreshViewStateList();
            },
            //删除分组
            async deleteGroup( item ){
                const id = item.id.split('group_')[1];

                await dataInterface({
                    __method_name__ : "deleteData",
                    object_uuid: DataBaseInfo.Group.object_uuid,
                    view_uuid: DataBaseInfo.Group.view_uuid,
                    data_id : id,
                });

                const list = [];
                for(let obj of this.viewPointList){
                    if(obj.id !== item.id ){
                        list.push(obj);
                    }

                    if(obj.id === 'default'){
                        obj.children = item.children.concat(obj.children);
                    }
                }

                this.viewPointList = list;
            },
            //添加视点事件
            onConfirmObj(list){
                let view = {};
                for (let item of list){
                    view[item.id] = item.value;
                }

                if(!view['group']) return  this.$message('请选择一个分组！');

                if( !this.bindViewerId ) return console.warn('[BIM]未绑定viewer获取state');

                eventBus.$emit("ViewerBIM.getState" , this.bindViewerId ,async ( state )=>{
                    const group_id =  view['group'] === 'default' ? "": view['group'];

                    const pic = await this.getScreenShotBuffer();

                    let graffiti = null;

                    if( await this.checkGraffitiActive()){
                        graffiti = await this.getGraffitiData();

                        eventBus.$emit('ViewerBIM.Graffiti.close' , this.bindViewerId);
                    }

                    const data = {
                        state : JSON.stringify(state),
                        picture : pic,
                        name : view['name'],
                        group_id ,
                        graffiti ,
                    }

                    const res = await dataInterface(Object.assign({
                        __method_name__ : "createData",
                        object_uuid: DataBaseInfo.ViewState.object_uuid,
                        view_uuid: DataBaseInfo.ViewState.view_uuid,
                        data_main_id : this.bindDataMainId,
                    },data));

                    if(res.status !== 200) return;
                    this.isEdit = false;
                    this.refreshViewStateList();

                });

            },
            //删除视点
            async deleteViewState( item ){
                const vid = item.id.split('vs_')[1];

                await dataInterface({
                    __method_name__ : "deleteData",
                    object_uuid: DataBaseInfo.ViewState.object_uuid,
                    view_uuid: DataBaseInfo.ViewState.view_uuid,
                    data_id : vid,
                });

                this.refreshViewStateList();
            },
            //显示视点
            showViewState(item){
                if(!this.bindViewerId) return;
                eventBus.$emit("ViewerBIM.restoreState", this.bindViewerId , item.state);
            },
            //更新视点
            async updateViewState(item){
                const vid = item.id.split('vs_')[1];
                if( !this.bindViewerId ) return console.warn('[BIM]未绑定viewer获取state');


                eventBus.$emit("ViewerBIM.getState" , this.bindViewerId ,async ( state )=>{

                    const pic = await this.getScreenShotBuffer();

                    const savedata = {
                        state : JSON.stringify(state),
                        picture : pic,
                    }

                    if( await this.checkGraffitiActive()){
                        const graffiti = await this.getGraffitiData();

                        eventBus.$emit('ViewerBIM.Graffiti.close' , this.bindViewerId);

                        savedata['graffiti'] = graffiti;
                    }

                    await dataInterface(Object.assign({
                        __method_name__ : "updateData",
                        object_uuid: DataBaseInfo.ViewState.object_uuid,
                        view_uuid: DataBaseInfo.ViewState.view_uuid,
                        data_id : vid,
                    },savedata));

                    this.$message.success('更新成功！');
                    this.refreshViewStateList();
                });
            },
            //获取截图
            getScreenShotBuffer(){
                if(!this.bindViewerId) return Promise.resolve(null);
                return new Promise( (rol )=>{
                    eventBus.$emit("ViewerBIM.getScreenShotBuffer" , this.bindViewerId , (data)=>{
                        rol(data);
                    })
                });
            },
            /**
             * 绑定选中构件
             * @param {} item
             */
            bindSelection(item){
                eventBus.$emit('ViewerBIM.getSelection' , this.bindViewerId , async (selection)=>{
                    if(selection.length === 0) return this.$message.warning('绑定失败，未选中构件');
                    const vid = item.id.split('vs_')[1];

                    let info = {}
                    if(item.bind_info){
                        info = item.bind_info;
                    }

                    info.selection = selection;

                    await dataInterface({
                        __method_name__ : "updateData",
                        object_uuid: DataBaseInfo.ViewState.object_uuid,
                        view_uuid: DataBaseInfo.ViewState.view_uuid,
                        data_id : vid,
                        bind_info : JSON.stringify(info),
                    });

                    this.$message.success('绑定成功');
                    this.refreshViewStateList();
                });
            },
            initBehaviors(){
                this.$emit('registerBehaviors',[{
                    label : "[视点]根据id展示视点",
                    desc : `
                        id=>标记id
                    `,
                    value : "BIMModule-VS-ShowByid",
                    func : (param)=>{
                        if(!param.id) return;
                        const viewObj = this.viewPointMap[param.id];
                        if(!viewObj) return;

                        this.showViewState(viewObj);
                    },
                }]);
            },
            /**
             * 点击涂鸦按钮
             */
            onClickGraffiti( item ){
                if(item.graffiti){
                    this.openGraffiti();
                    this.setGraffitiData(item.graffiti);
                }
            },
            checkGraffitiActive(){
                return new Promise(( rol )=>{
                    eventBus.$emit('ViewerBIM.Graffiti.isActive' , this.bindViewerId , val=> rol(val));
                }) ;
            },
            getGraffitiData(){
                return new Promise((rol)=>{
                    eventBus.$emit('ViewerBIM.Graffiti.getData' , this.bindViewerId , val=>rol(val));
                });
            },
            setGraffitiData(data){
                eventBus.$emit('ViewerBIM.Graffiti.setData', this.bindViewerId , data );
            },
            openGraffiti( mode = 'view' ){
                eventBus.$emit('ViewerBIM.Graffiti.open' , this.bindViewerId , mode );
            }
        },
        mounted(){
            eventBus.$on("Viewer.onViewerSelect",  ( eid , selection)=>{
                if(eid != this.bindViewerId) return;
                if(selection.length === 0 ) return;
                const selectionMap = {};
                for(let {model_id , dbids } of selection){
                    selectionMap[model_id] = dbids;
                }

                //整个分组列表
                for(let {children} of this.viewPointList){
                    //单个分组
                    for(let item of children){
                        let showViewFlag = false;//是否显示该视点
                        const bind_sel = item?.bind_info?.selection;
                        if(bind_sel && bind_sel.length > 0){
                            //单个绑定视点
                            for(let {model_id , dbids} of bind_sel){
                                if(selectionMap[model_id]){

                                    for(let dbid of dbids){
                                        if(selectionMap[model_id].indexOf(dbid) != -1){
                                            showViewFlag = true;
                                            break;
                                        }
                                    }

                                    if(showViewFlag){
                                        break;
                                    }
                                }
                            }

                            if(showViewFlag){

                                delete item.state.objectSet
                                this.showViewState(item);
                                break;
                            }
                        }
                    }
                }
            })
        },
        destroyed(){
        },
    }
</script>

<style lang="less" scoped>
    .horizontal-flex{
        display: inline-flex;
        flex-direction: row;
        line-height: initial;
        vertical-align: middle;
    }

    .vertical-flex{
        display: inline-flex;
        flex-direction: column;
        line-height: initial;
        vertical-align: middle;
    }

    .wrapPanel{
        float: left;
        padding: 0 !important;
        overflow-y: hidden;
        background-color: inherit;
        color: inherit;

        .content-wrap{
            width: 100%;
            height: 100%;
            background-color: inherit;
            color: inherit;
        }

        .btnsUl{
            width: 100%;
            height: 40px;
            line-height: 40px;
            box-sizing: border-box;
            border-bottom: 1px solid rgba(255,255,255,0.3);
        }

        .btnsUl a{
            padding-left: 10px;
            width: 30px;
            line-height: 40px;
            font-size: 18px;
        }

        .btnsUl a:hover{
            color: #0076FF;
        }

        .filter-wrap{
            width: 150px;
            margin-left: 15px;

            :deep(.el-input--small .el-input__inner){
                height: 25px;
                line-height: 25px;
            }
        }

        .viewPoint-wrap {
            width: 100%;
            height: calc(100% - 40px);
        }

        .editor-wrap{
            width: 100%;
            height: 100%;
        }
    }

    .viewPointTree-wrap{
        background-color: transparent !important;
        color: inherit !important;
        overflow-y: auto;
        height: calc(100% - 40px);

        :deep(.el-collapse){
            border-top: none !important;
            border-bottom: none !important;
        }

        :deep(.el-collapse-item__header){
            padding-left: 10px;
            height: 40px;
            line-height: 40px;
            border-bottom: none;
            border-bottom: 1px solid rgba(255,255,255,0.3);
            background-color: transparent;
            color: inherit;
        }

        :deep(.el-collapse-item__wrap){
            border-bottom: 1px solid rgba(255,255,255,0.3);
            background-color: transparent;
            color: inherit;
        }

        :deep(.el-collapse-item__content){
            color: inherit;
        }

        .tree-group-wrap{
            width: 100%;
            height: 40px;
            line-height: 40px;
        }

        .tree-group-wrap__title{
            width: calc(100% - 20px);
            text-align: left;
        }

        .tree-group-wrap__btns{
            font-size: 18px;
            color: inherit;
        }

        .tree-group-wrap__btns:hover{
            color: #0076FF;
        }

        .tree-obj-wrap{
            width: 100%;
            height: 90px;
            color: inherit;
        }

        .tree-obj-wrap:hover{
            background-color: #838383;
        }

        .tree-obj-wrap__img{
            margin-left: 10px;
            margin-top: 10px;
            width: 100px;
            height: 68px;
            background-color: #909399;
            object-fit: fill;
            border: 1px solid rgba(255,255,255,0.2);
        }

        .tree-obj-wrap__content{
            margin-left: 10px;
            width: 100%;
            color: inherit;
        }

        .tree-obj-wrap__title{
            padding-top: 12px;
            height: 50px;
            text-align: left;
            color: inherit;
        }

        .tree-obj-wrap__tip{
            width: 100%;
            height: 20px;
            line-height: 20px;
            text-align: left;
            color: inherit;
        }

        .tree-obj-wrap__btns{
            float: right;
            padding-right: 5px;
            height: 20px;
            line-height: 20px;
            font-size: 18px;
            color: inherit;
        }

        .tree-obj-wrap__btns:hover{
            color: #0076FF;
        }
    }
</style>
