/**
 * @description 通用组件相关配置
 */

// 通用组件小项配置
const currencyComponentsList = [
  {
    name: '通用文本',
    component: 'TextCom',
    type: 'currency',
    icon: 'icon-wenbenbiaoqian',
    needCommonStyle: true,
    statusConfig: {
      displayShow: true,
      isShow: true, // 是否显示
      showEffect: '',
      bold: false,
      italic: false,
      // fontFamily: 'DIN Next LT Pro',
    },
    style: {
      height: 32,
      fontSize: 16,
      textAlign: 'center',
      lineHeight: 32
    }
  },
  {
    name: '日期',
    component: 'DateCom',
    icon: 'icon-riqishijian',
    statusConfig: {
      displayShow: true,
      isShow: true, // 是否显示
      bold: false,
      italic: false,
      // fontFamily: 'DIN Next LT Pro',
      zeroFilling: true,
      timeFormat: 'YYYY-MM-DD',
      timeFormatString: '公元${YYYY}年${MM}月${DD}号',
      formatChinese: false
    },
    needCommonStyle: true,
    style: {
      height: 32,
      fontSize: 16,
      textAlign: 'center',
      lineHeight: 32
    }
  },
  {
    name: '星期几',
    types: ['page', 'dataview', 'template', 'second'],
    component: 'DayCom',
    icon: 'icon-zhou',
    statusConfig: {
      displayShow: true,
      isShow: true, // 是否显示
      bold: false,
      italic: false,
      // fontFamily: 'DIN Next LT Pro',
      dayShowType: 'chinese',
      dayFormatString: '周${day}'
    },
    needCommonStyle: true,
    style: {
      height: 32,
      fontSize: 16,
      textAlign: 'center',
      lineHeight: 32
    }
  },
  {
    name: '时间',
    types: ['page', 'dataview', 'template', 'second'],
    component: 'TimeCom',
    icon: 'icon-riqishijian',
    statusConfig: {
      displayShow: true,
      isShow: true, // 是否显示
      bold: false,
      italic: false,
      // fontFamily: 'DIN Next LT Pro',
    },
    needCommonStyle: true,
    style: {
      height: 32,
      fontSize: 16,
      textAlign: 'center',
      lineHeight: 32
    }
  },
  {
    name: '超链接',
    types: ['page', 'dataview', 'template', 'second'],
    component: 'Hyperlinks',
    icon: 'icon-icon-',
    statusConfig: {
      displayShow: true,
      isShow: true, // 是否显示
      hrefValue: 'http://www.baidu.com',
      target: '_blank'
    },
    style: {
      height: 32,
      fontSize: 16,
      textAlign: 'center',
    }
  },
  {
    name: '渐变背景',
    types: ['page', 'dataview', 'template', 'second'],
    component: 'GradientBackground',
    icon: 'icon-beijingtouming',
    style: {
      width: 400,
      height: 300
    },
    statusConfig: {
      displayShow: true,
      isShow: true, // 是否显示
      mainColor: '#666',
      secondaryColor: '#aaa',
      direction: ''
    }
  }
]
// 初始化组件列表
const initComponentsList = (list) => {
  // 公共配置
  const publicConfig = {
    type: 'currency',
    icon: 'icona-kanbantongjifill',
    isLock: false, // 是否锁定
    needCommonStyle: true,
    propValue: '',
    span: -1,
    style: {
      height: '100%',
      width: '',
      rotate: 0,
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0
    },
    modelConfig: {
      appId: '',
      appSecret: '',
      modelId: {
        type: '',
        value: ''
      }
    }
  }
  // 返回融合后的配置
  return list.map(el => {
    return Object.assign({}, publicConfig, el)
  })
}
const currency = {
  type: 'currency',
  title: '通用组件',
  show: true,
	order: 5,
  componentList: initComponentsList(currencyComponentsList)
}
export default currency