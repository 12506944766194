<template>
  <div class="h-full">
    <template v-if="pageUUID">
      <PageParser :pageUUID="pageUUID" :defaultTableSelectData="defaultSelect"></PageParser>
    </template>
    <template v-else>
      <el-table
        ref="multipleTable"
        v-loading="tableLoading"
        :data="tableData"
        border
        class="w-full"
        :height="tableHeight"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="40">
        </el-table-column>
        <!-- <el-table-column label="ID" width="80" prop="id"></el-table-column> -->
        <template v-if="Object.keys(dicts).length">
          <el-table-column
            v-for="(item, name, index) in dicts"
            :key="index"
            :label="item"
            :prop="name"
          >
            <template slot-scope="scope">
              <div v-if="judgeImg(scope.row[name])">
                <el-image
                  style="width: 60px; height: 60px"
                  :src="scope.row[name]"
                  fit="contain"
                  :preview-src-list="[scope.row[name]]"></el-image>
              </div>
              <div v-else>
                {{scope.row[name]}}
              </div>
            </template>
          </el-table-column>
        </template>

        <template v-else>
          <el-table-column label="暂无数据项"></el-table-column>
        </template>

      </el-table>
      <el-pagination
        style="float: right; margin-top: 8px"
        v-if="total !== null"
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :current-page="currentPage"
        :page-size="size"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </template>
  </div>
</template>

<script>
/* eslint-disable */
import { formRequest } from "@/apis/data/form";
import { Pagination,Image } from "element-ui";

export default {
  name: "list",
  components:{
    PageParser: () => import('@/components/parser/Index'),
    'el-pagination':Pagination,
    'el-image':Image,
  },
  props: {
    pageUUID: {
      type: String,
      default: '',
    },
    needPage: {
      type: Boolean,
      default: true,
    },
    filterRule: {
      type: Array,
      default: () => {
        return [];
      }
    },
    relateData: {
      type: Array,
      default: () => []
    },
    relateConfig: {
      type: Object,
      default: () => {}
    },
  },
  computed: {
    judgeImg(){
      return function (data){
        if(data){
          data += ''
          if(
            data.indexOf('.jpg') > -1  || data.indexOf('.png') > -1 ||
            data.indexOf('.gif') > -1 || data.indexOf('.jpeg') > -1
          ){
            return true
          }
        }
        return false
      }
    },
    tableHeight() {
      if (this.total === null) {
        return "100%";
      } else {
        return "calc(100% - 44px)";
      }
    },
  },
  data() {
    return {
      defaultSelect: this.relateData,
      tableData: [],
      currentPage: 1,
      tableLoading: false,
      total: null,
      size: 14,
      timer: null,
      dicts: {},
      selectMultiple:{},
      multiSelected:[],
    };
  },
  watch: {
    filterRule:{
      handler(){
        if(!this.pageUUID) this.getDatas();
      },
      deep:true
    },
  },
  methods: {
    getSelection(){
      let idKey;
      let viewType = this.relateConfig.__config__.viewType
      if(viewType === 'object'){
        let object = this.dicts; //查找主键索引key
        idKey = Object.keys(object).find(key => object[key] === 'id');
      } else if(viewType === 'formview'){
        idKey = 'id'
      }
      return {idKey:idKey,rows: this.multiSelected}
    },
    handleSelectionChange(val){
      this.selectMultiple[this.currentPage] = val;
      let multiSelected = Object.values(this.selectMultiple).flat();
      this.multiSelected = multiSelected;
      // this.$emit('select-relate',idKey,multiSelected)
    },
    handleCurrentChange(page) {
      this.currentPage = page;
      this.getDatas();
    },
    handleSizeChange(size) {
      this.size = size;
      this.getDatas();
    },
    getDatas() {
      let searchData = []; //过滤条件
      if(this.filterRule instanceof Array){
        for (let item of this.filterRule){
          searchData.push(item)
        }
      }
      this.tableLoading = true;
      let viewType = this.relateConfig.__config__.viewType
      let objectUuid = this.relateConfig.__config__.objectUuid
      let viewUuid = this.relateConfig.__config__.viewUuid
      let url, method, params;
      if(viewType === 'object'){
        url = '/api/mapi'
        method = 'POST'
        params = {
          object_uuid: objectUuid,
          view_uuid: viewUuid,
          search: searchData,
          page: this.currentPage,
          size: this.size,
          __method_name__: "dataList"
        }
      } else if (viewType === 'formview') {
        url= '/api/form4/view-data-list';
        method= 'GET'
        params = {viewUuid: viewUuid};
        if(viewUuid === 'default'){
          url= '/api/form4/defaultlist';
          params = {module_uuid: objectUuid}
        }
      }
      if(url && params){
        formRequest(method,url,params).then(res=>{
          if (res.data.data.data) {
            this.tableData = res.data.data.data;
          } else {
            this.tableData = res.data.data || [];
          }
          if(viewType === 'object'){
            this.dealWithTableData(res)
          } else if(viewType === 'formview'){
            this.dealWithFormData(res)
          }
        })
      }
    },
    dealWithTableData(res){
      let dicts = {};
      this.tableData.forEach((item, index) => {
        let keys = Object.keys(item);
        keys.forEach((key) => {
          if (index == 0) {
            // 过滤metadata	没在接口数据中出现的字段不予显示
            if(!['创建日期','更新日期','删除日期'].includes(res.data.metadata[key])){
              dicts[key] = res.data.metadata[key];
            }
          }
          if (item[key] instanceof Array) {
            item[key] = "共" + item[key].length + "条";
          }
        });
      });
      this.dicts = dicts;
      this.tableLoading = false;
      if (res.data.data.total) {
        this.total = res.data.data.total;
      }
      this.$nextTick(res=>{
        let idKey = Object.keys(dicts).find(key => dicts[key] === 'id');
        this.tableData.forEach(item=>{
          if(this.relateData.includes(item[idKey])){
            this.$refs.multipleTable.toggleRowSelection(item);
          }
        })
      })
    },
    dealWithFormData(res){
      let dicts = {};
      const columns = res.data.data.columns
      for (let item of columns){
        dicts[item.key] = item.title
      }
      this.dicts = dicts;
      this.tableLoading = false;
      if (res.data.data.total) {
        this.total = res.data.data.total;
      }
      this.$nextTick(res=>{
        this.tableData.forEach(item=>{
          if(this.relateData.includes(item.id)){
            this.$refs.multipleTable.toggleRowSelection(item);
          }
        })
      })
    },
  },
  mounted() {
    if(!this.pageUUID) this.getDatas();
  },
};
</script>
<style scoped>
.h-full {
  height: 100%;
}
</style>
