/*
 * @Author: zx
 * @Date: 2022-08-25 14:58:46
 * @LastEditTime: 2022-08-25 15:01:54
 * @Description: 
 * @LastEditors: zx
 */
import request from '@/apis/http/saasRequest.js'

/**
 * @desc: 删除search参数中的value为空的筛选
 */
 const removeNullSearch = data => {
	for (let key in data) {
		if (key === 'search' && Array.isArray(data[key]) && data[key].length) {
			let search = [];
			for (let i = 0; i < data[key].length; i++) {
				if (data[key][i].value) {
					search.push(data[key][i]);
				}
			}
			return {
				...data,
				search
			}
		}
	}
	return data;
}
/**
 * @desc: 用户数据接口
 */
 export const dataInterface = (data, url, method = 'POST') => {
	// // 分服务器环境请求
	// const pathname = window.location.pathname;
	let requestUrl = url;
	// 	console.log('0000000000', data, url )
	// if (pathname === '/modify-page' || pathname === '/page-view') {
	// 	if (!url || (url && url.indexOf('http') === -1)) {
	// 		try {
	// 			const targetServer = JSON.parse(localStorage.getItem('targetServer'));
	// 			console.log(targetServer, '----targetServer------')
	// 			requestUrl = `${targetServer?.originURl || baseUrl}/${requestUrl ? requestUrl : 'api/mapi'}`;
	// 		} catch(err) {
	// 			console.log(err)
	// 		}
	// 	}
	// }
	if (method && method === 'GET') {
		return request({
			url: requestUrl || 'api/mapi',
			params: removeNullSearch(data),
			method
		});
	}
	return request({
		url: requestUrl || 'api/mapi',
		data: removeNullSearch(data),
		method: 'POST'
	});
}
