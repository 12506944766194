<!--
 * @Author: zqy
 * @Date: 2022-06-17 10:31:36
 * @LastEditTime: 2022-12-06 11:43:14
 * @Description: 
 * @LastEditors: zx
-->
<template>
  <div class="add-drawer">
    <iframe 
      id="mobileiDetailframe"
      :src="iframeSrc" 
      frameborder="0"
      style="width:100%;height:100%;"
    >
    </iframe>
  </div>
</template>
<script>
import { defineComponent, nextTick, onMounted, onUnmounted, reactive, toRefs } from 'vue'
import eventBus from '@/plugins/eventBus'
import { getToken } from '@/utils/tools';

export default defineComponent({
  props:{
    detailConfig:{
      type:Object,
      default:{}
    },
  },
  setup(props) {
    const state = reactive({
      iframeSrc:'https://nh.bimcc.net/h5/formRender/detail'
    })
    const close = (flag)=>{
      console.log('移动端关闭');
      eventBus.$emit('closeDetailFormDrawer',flag);
    }
    const iframePostMessage = (e)=>{
      if (e.data && e.data.type == 'mobileDetailForm') {
        const params = Object.assign({},{
          type: 'mobileiDetailframe',
          token: getToken(),
          userInfo: localStorage.getItem('userInfo'),
          targetArchi: localStorage.getItem('targetArchi'),
          myCompany: localStorage.getItem('myCompany'),
          myProjects: localStorage.getItem('myProjects'),
        },props.detailConfig)
        if (props.detailConfig.type === 'detail') {
          params.type = 'mobileiDetailframe'
        }
        if (typeof params.params === 'object') {
          params.params = JSON.stringify(params.params)
        }
        document.getElementById('mobileiDetailframe').contentWindow.postMessage(params,'*');
      }else if(e.data && e.data.type == 'closeDetailDrawer'){
        close(e.data.flag)
      }
    }
    onMounted(()=>{
      nextTick(()=>{
        window.addEventListener('message', iframePostMessage) 
      })
    })
    onUnmounted(()=>{
      window.removeEventListener("message",iframePostMessage); // 移除
    })
    return {
      close,
      ...toRefs(state)
    }
  }
})
</script>
<style lang="less" scoped>
::-webkit-scrollbar { width: 0 !important }
.add-drawer{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 100;
}
</style>
