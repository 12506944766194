import { mapState } from 'vuex';
import eventBus from '@/plugins/eventBus';
import { initParams } from '@/utils/tools';
import GISToolbar from "./GISToolbar";
import CustomComponentMixin from '@/custom-component/mixins/CustomComponentMixin.js';

import Viewer from "./Viewer";
import SceneEvents from "./Events/SceneEvents";
import ModelEvents from "./Events/ModelEvents";
import MouseCustomEvents from "./Events/MouseCustomEvents";
import CoordinateEvents from "./Events/CoordinateEvents";
import ModelBehaviors from "./Behaviors/ModelBehaviors";

const defaultClientConfig = {
    local: {
        host : '192.168.2.235',
        port : '3333',
        appId : '0905019c65ee4266b95d1b6858cd2f6b',
        appSecret : "0mvBMkb00QJE",
        layerId : '7c31555ae1824d548704b4f8f4f00400',
    },
    online : {
        host : 'models.bimcc.net',
        isHttps : true,
        appId : 'bdafce12a2d9465d89821ec14214a382',
        appSecret : "3EYcdAjbnWxP",
        name : '主应用',
    }
};

export default class VueConfig {
    constructor() {
        this.name = "ViewerGIS";

        this.viewer = null;
    }

    generate(){
        let data = {
            modelId : '',
            modelPosition : '',
            sourceConfig : {
                host : '',
                port : '',
                isHttps : '',
                appId : '',
                appSecret : '',
            },
            setting : {
                showMask : true,
                defaultViewState : null,
                imageryList: [
                    {id: 'TianDiTu', name : '天地图影像'},
                    {id: 'AMapSatellite', name : '高德影像'},
                    {id: 'AMapStreets', name : '高德街道'},
                    {id: 'ArcGIS', name : 'ArcGIS暗黑'},
                ],
                imageryIndex: 0,
                mouseControllerList: [
                    {id: 'bim', name : 'BIM操作模式'},
                    {id: 'gis', name : 'GIS操作模式'},
                ],
                mouseControllerIndex: 1,
                terrain: false,
                depthTest: false,
                shadows: false,
                backgroundColor : '#000000',
            },

            _EventBus  : {},
            behaviorHandler : {},
            toolbarList : [],

            preload : null,  //参数设置-模型动态数据源
        };

        let watch = {
            "element.GISSourceConfig" (val = {}, oldval = {}){
                let flag = false;
                const list = ['host', 'port', 'isHttp', 'appId', 'appSecret', 'modelId', 'modelPosition', 'isDynamicSource'];

                for(let name of list) {
                    if(val[name] !== oldval[name]){
                        flag = true;
                        break;
                    }
                }

                if(flag){
                    this.refresh(val);
                }
            },
            "element.GISSetting" (val = {} , old){
                if(JSON.stringify(val) !== JSON.stringify(old)){
                    this.setSetting(val);
                }
            }
        };

        let methods = {
            initSourceConfig(props){
                const SourceConfig = props ? props : this.element.GISSourceConfig;

                let opt = {
                    config: {
                        host: defaultClientConfig.online.host,
                        port: defaultClientConfig.online.port,
                        isHttps: defaultClientConfig.online.isHttps,
                        appId: defaultClientConfig.online.appId,
                        appSecret: defaultClientConfig.online.appSecret,
                    },
                    modelList: []
                };

                if(SourceConfig){
                    opt.config = Object.assign(opt.config, {
                        host : SourceConfig.host,
                        port : SourceConfig.port,
                        isHttps : SourceConfig.isHttps,
                        appId : SourceConfig.appId,
                        appSecret : SourceConfig.appSecret,
                    });

                    if(!SourceConfig.isDynamicSource && SourceConfig.modelId) {
                        opt.modelList = [SourceConfig.modelId];

                        if(SourceConfig.modelPosition !== '' && typeof SourceConfig.modelPosition === 'string'){
                            opt.modelPositions = {
                                [SourceConfig.modelId]: JSON.parse(SourceConfig.modelPosition)
                            };
                        }
                    }

                    if(this.preload && SourceConfig.isDynamicSource){
                        if(this.preload.modelId){
                            opt.modelList = [this.preload.modelId];

                            if(this.preload.modelPosition) {
                                opt.modelPositions = {
                                    [this.preload.modelId]: this.preload.modelPosition
                                };
                            }
                        }

                        if(this.preload.modelList && this.preload.modelList.length > 0){
                            const modelList = this.preload.modelList;
                            opt.modelList = modelList;

                            if(this.preload.modelPositions) {
                                opt.modelPositions = this.preload.modelPositions;
                            }
                        }
                    }
                }

                return opt;
            },
            async initViewer(opt){
                const viewerShell = new Viewer(this.$refs.viewerGISDom, opt.config);
                const viewer = this.viewer = await viewerShell.init();

                this.sourceConfig = Object.assign({}, opt.config);
                const Setting = this.element.GISSetting;
                if(Setting) this.setSetting(Setting);

                if(opt.modelList.length > 0) await viewer.Model.createModelList(opt.modelList, opt.modelPositions);
                return viewer;
            },
            initEvent(viewer, comp){
                const baseEvents = {
                    "doComponentBehavior" :({ component , list}) => {
                        if(component === this.element.id){
                            for(let {behaviors , params} of list){
                                for(let bid of behaviors){
                                    if(this.behaviorHandler[bid]){
                                        const { param = {} } = initParams(params, this.isGroup, this.componentData, this.groupComponents);

                                        this.behaviorHandler[bid](param);
                                    }
                                }
                            }
                        }
                    }
                };

                this._EventBus = Object.assign(
                    baseEvents,
                    SceneEvents.getEvents(viewer, comp),
                    ModelEvents.getEvents(viewer, comp),
                    MouseCustomEvents.getEvents(viewer, comp),
                    CoordinateEvents.getEvents(viewer, comp)
                );

                for( let id in this._EventBus){
                    eventBus.$on(id , this._EventBus[id]);
                }
            },
            initBehaviors(viewer, comp){
                this.element.behaviors = [];

                new ModelBehaviors(viewer, comp);
            },
            initToolbar(viewer){
                this.toolbarList = {
                    "keyboardRoam" : {
                        id : "keyboardRoam",
                        icon : 'iconfont iconbim_diyirencheng',
                        name : "第一人称",
                        needActive : true,
                        func : (val)=>{
                            if(!viewer) return;
                            viewer.KeyboardController.isKeyboardRoam = val;
                        }
                    },
                    "intersectSelect" : {
                        id : "intersectSelect",
                        icon : 'iconfont iconbimgis_jiaoxuan',
                        name : "交选",
                        func : ()=>{
                            if(!viewer) return;
                            this.viewer.Model.intersectSelect();
                        }
                    },
                    "boxesSelect" : {
                        id : "boxesSelect",
                        icon : 'iconfont iconbimgis_kuangxuan',
                        name : "框选",
                        func : ()=>{
                            if(!viewer) return;
                            this.viewer.Model.boxesSelect();
                        }
                    },
                    "shadow" : {
                        id : "shadow",
                        icon : 'iconfont iconbim_yinying',
                        name : "显示阴影",
                        needActive : true,
                        func : (val)=>{
                            if(!viewer) return;
                            viewer.Model.setShadows(val);
                        }
                    },
                    "show" : {
                        id : "show",
                        icon : 'iconfont iconbimgis_xianshi',
                        name : "全显",
                        func : ()=>{
                            if(!viewer) return;
                            viewer.Model.showAllModelFeature();
                        }
                    },
                    "hide" : {
                        id : "hide",
                        icon : 'iconfont iconbimgis_yincang',
                        name : "隐藏",
                        func : ()=>{
                            if(!viewer) return;
                            viewer.Model.hideTileBySelect();
                        }
                    },
                    "insulate" : {
                        id : "insulate",
                        icon : 'iconfont iconbimgis_geli',
                        name : "隔离",
                        func : ()=>{
                            if(!viewer) return;
                            viewer.Model.insulateTileBySelect();
                        }
                    },
                };

                this.$refs.toolbar.refresh(this.toolbarList, true);
            },
            async refresh(opt){
                for( let node of this.$refs.viewerGISDom.childNodes){
                    node.remove();
                }

                const sourceConfig = this.initSourceConfig(opt);
                const viewer = await this.initViewer(sourceConfig);
                this.initEvent(viewer, this);
                this.initBehaviors(viewer, this);
                this.initToolbar(viewer);
            },
            setSetting(opt){
                this.setting.showMask = !!opt.showMask;

                if(opt.defaultViewState){
                    const state = Object.assign({} , opt.defaultViewState);

                    this.setting.defaultViewState = state;
                    this.viewer.Scene.setCameraView(state);
                }

                this.setting.imageryList = opt.imageryList;
                this.setting.imageryIndex = opt.imageryIndex;
                this.viewer.Scene.setImageryLayer(opt.imageryList[opt.imageryIndex].id);

                this.setting.mouseControllerList = opt.mouseControllerList;
                this.setting.mouseControllerIndex = opt.mouseControllerIndex;
                if(opt.mouseControllerList[opt.mouseControllerIndex].id === 'bim'){
                    this.viewer.MouseController.mouseStyleByBIM();
                }else{
                    this.viewer.MouseController.mouseStyleByGIS();
                }

                this.setting.terrain = !!opt.terrain;
                this.setting.depthTest = !!opt.depthTest;
                this.setting.shadows = !!opt.shadows;
                this.viewer.Scene.setTerrain(opt.terrain);
                this.viewer.Scene.setDepthTest(opt.depthTest);
                this.viewer.Model.setShadows(opt.shadows);

                if(opt.backgroundColor){
                    this.setting.backgroundColor = opt.backgroundColor;

                    this.viewer.Scene.setBackgroundColor(opt.backgroundColor);
                }
            },
            getParseParam(params){
                return params.split(",").map(( str )=>{
                    return str;
                });
            }
        };

        const state = {
            name : this.name,
            props :{
                element: {
                    type: Object,
                    required: true,
                    default: () => {
                        return {}
                    }
                },
                // 是否在组合内
                isGroup: {
                    type: Boolean,
                },
                // 组合内组件列表
                groupComponents: {
                    type: Array,
                    default: () => []
                }
            },
            data() {
                return data;
            },
            components : {
                "gis-toolbar" : GISToolbar
            },
            computed: {
                ...mapState(['componentData']),
            },
            watch,
            mixins : [CustomComponentMixin],
            created(){
                const element = this.element;
                const { param } = this.initParams(element.paramsConfig, element.isGroup, element.componentData, element.groupComponents);

                // 开启模型动态源且预设模型id
                if (element.GISSourceConfig && element.GISSourceConfig.isDynamicSource){
                    if(param && param.modelId){
                        this.preload = {
                            modelId : param.modelId,
                            appId : param.appId ? param.appId : process.env.VUE_APP_MODEL_APPID,
                            appSecret : param.appSecret ? param.appSecret : process.env.VUE_APP_MODEL_APPSECRET,
                        };

                        if(param.modelPosition && typeof param.modelPosition === 'string'){
                            this.preload['modelPosition'] = JSON.parse(param.modelPosition);
                        }
                    }

                    if(param && param.modelList){
                        if(!this.preload) this.preload = {};

                        if(typeof param.modelList === 'string'){
                            this.preload['modelList'] = this.getParseParam(param.modelList);
                        }else {
                            this.preload['modelList'] = param.modelList.concat();
                        }

                        if(param.modelPositions && typeof param.modelPositions === 'string'){
                            this.preload['modelPositions'] = JSON.parse(param.modelPositions);
                        }
                    }
                }
            },
            async mounted() {
                const sourceConfig = this.initSourceConfig();
                const viewer = await this.initViewer(sourceConfig);
                this.initEvent(viewer, this);
                this.initBehaviors(viewer, this);
                this.initToolbar(viewer);
            },
            methods,
            destroyed(){
                for( let id in this._EventBus){
                    eventBus.$off(id , this._EventBus[id]);
                }
            }
        };

        return  state;
    }
}
