/**
 * @description 装饰相关配置
 */

// 装饰组件小项配置
const decorateComponentsList = [
	{
		name: '装饰1',
		types: ['dataview'],
		component: 'Decoration1',
		statusConfig: {
			displayShow: true,
			isShow: true, // 是否显示
			mainColor: '',
			secondaryColor: '',
		}
	},
	{
		name: '装饰2',
		types: ['dataview'],
		component: 'Decoration2',
		statusConfig: {
			displayShow: true,
			isShow: true, // 是否显示
			mainColor: '',
			secondaryColor: '',
			dur: 6,
			reverse: false
		}
	},
	{
		name: '装饰3',
		types: ['dataview'],
		component: 'Decoration3',
		statusConfig: {
			displayShow: true,
			isShow: true, // 是否显示
			mainColor: '',
			secondaryColor: '',
		}
	},
	{
		name: '装饰4',
		types: ['dataview'],
		component: 'Decoration4',
		statusConfig: {
			displayShow: true,
			isShow: true, // 是否显示
			mainColor: '',
			secondaryColor: '',
			dur: 3,
			reverse: false
		}
	},
	{
		name: '装饰5',
		types: ['dataview'],
		component: 'Decoration5',
		statusConfig: {
			displayShow: true,
			isShow: true, // 是否显示
			mainColor: '',
			secondaryColor: '',
			dur: 1.2
		}
	},
	{
		name: '装饰6',
		types: ['dataview'],
		component: 'Decoration6',
		statusConfig: {
			displayShow: true,
			isShow: true, // 是否显示
			mainColor: '',
			secondaryColor: '',
		}
	},
	{
		name: '装饰7',
		types: ['dataview'],
		component: 'Decoration7',
		statusConfig: {
			displayShow: true,
			isShow: true, // 是否显示
			mainColor: '',
			secondaryColor: '',
			textContent: '',
			textColor: '',
			textSize: '',
			textWeight: ''
		}
	},
	{
		name: '装饰8',
		types: ['dataview'],
		component: 'Decoration8',
		statusConfig: {
			displayShow: true,
			isShow: true, // 是否显示
			mainColor: '',
			secondaryColor: '',
			reverse: false
		}
	},
	{
		name: '装饰9',
		types: ['dataview'],
		component: 'Decoration9',
		statusConfig: {
			displayShow: true,
			isShow: true, // 是否显示
			mainColor: '',
			secondaryColor: '',
			dur: 3,
			textContent: '',
			textColor: '',
			textSize: '',
			textWeight: ''
		}
	},
	{
		name: '装饰10',
		types: ['dataview'],
		component: 'Decoration10',
		statusConfig: {
			displayShow: true,
			isShow: true, // 是否显示
			mainColor: '',
			secondaryColor: '',
		}
	},
	{
		name: '装饰11',
		types: ['dataview'],
		component: 'Decoration11',
		statusConfig: {
			displayShow: true,
			isShow: true, // 是否显示
			mainColor: '',
			secondaryColor: '',
			textContent: '',
			textColor: '',
			textSize: '',
			textWeight: ''
		}
	},
	{
		name: '装饰12',
		types: ['dataview'],
		component: 'Decoration12',
		statusConfig: {
			displayShow: true,
			isShow: true, // 是否显示
			mainColor: '',
			secondaryColor: '',
			scanDur: 3,
			haloDur: 2
		}
	},
	{
		name: '装饰13',
		types: ['dataview'],
		component: 'Decoration13',
		statusConfig: {
			displayShow: true,
			isShow: true, // 是否显示
			mainColor: '',
			secondaryColor: ''
		},
		style: {
			width: 576,
			height: 33
		}
	},
	{
		name: '边框1',
		types: ['dataview'],
		component: 'BorderBox1',
		statusConfig: {
			displayShow: true,
			isShow: true, // 是否显示
			mainColor: '',
			secondaryColor: '',
			textContent: '',
			textColor: '',
			textSize: '',
			textWeight: ''
		}
	},
	{
		name: '边框2',
		types: ['dataview'],
		component: 'BorderBox2',
		statusConfig: {
			isShow: true, // 是否显示
			mainColor: '',
			secondaryColor: '',
			textContent: '',
			textColor: '',
			textSize: '',
			textWeight: ''
		}
	},
	{
		name: '边框3',
		types: ['dataview'],
		component: 'BorderBox3',
		statusConfig: {
			displayShow: true,
			isShow: true, // 是否显示
			mainColor: '',
			secondaryColor: '',
			textContent: '',
			textColor: '',
			textSize: '',
			textWeight: ''
		}
	},
	{
		name: '边框4',
		types: ['dataview'],
		component: 'BorderBox4',
		statusConfig: {
			displayShow: true,
			isShow: true, // 是否显示
			mainColor: '',
			secondaryColor: '',
			textContent: '',
			textColor: '',
			textSize: '',
			textWeight: '',
			reverse: false
		}
	},
	{
		name: '边框5',
		types: ['dataview'],
		component: 'BorderBox5',
		statusConfig: {
			displayShow: true,
			isShow: true, // 是否显示
			mainColor: '',
			secondaryColor: '',
			textContent: '',
			textColor: '',
			textSize: '',
			textWeight: '',
			reverse: false
		}
	},
	{
		name: '边框6',
		types: ['dataview'],
		component: 'BorderBox6',
		statusConfig: {
			displayShow: true,
			isShow: true, // 是否显示
			mainColor: '',
			secondaryColor: '',
			textContent: '',
			textColor: '',
			textWeight: '',
			textSize: ''
		}
	},
	{
		name: '边框7',
		types: ['dataview'],
		component: 'BorderBox7',
		statusConfig: {
			displayShow: true,
			isShow: true, // 是否显示
			mainColor: '',
			secondaryColor: '',
			textContent: '',
			textColor: '',
			textSize: '',
			textWeight: ''
		}
	},
	{
		name: '边框8',
		types: ['dataview'],
		component: 'BorderBox8',
		statusConfig: {
			displayShow: true,
			isShow: true, // 是否显示
			mainColor: '',
			secondaryColor: '',
			dur: 3,
			reverse: false,
			textContent: '',
			textColor: '',
			textWeight: '',
			textSize: ''
		}
	},
	{
		name: '边框9',
		types: ['dataview'],
		component: 'BorderBox9',
		statusConfig: {
			displayShow: true,
			isShow: true, // 是否显示
			mainColor: '',
			secondaryColor: '',
			textContent: '',
			textColor: '',
			textSize: '',
			textWeight: ''
		}
	},
	{
		name: '边框10',
		types: ['dataview'],
		component: 'BorderBox10',
		statusConfig: {
			displayShow: true,
			isShow: true, // 是否显示
			mainColor: '',
			secondaryColor: '',
			textContent: '',
			textColor: '',
			textSize: '',
			textWeight: ''
		}
	},
	{
		name: '边框11',
		types: ['dataview'],
		component: 'BorderBox11',
		statusConfig: {
			displayShow: true,
			isShow: true, // 是否显示
			mainColor: '',
			secondaryColor: '',
			title: '',
			titleWidth: '',
			textContent: '',
			textColor: '',
			textSize: '',
			textWeight: ''
		}
	},
	{
		name: '边框12',
		types: ['dataview'],
		component: 'BorderBox12',
		statusConfig: {
			displayShow: true,
			isShow: true, // 是否显示
			mainColor: '',
			secondaryColor: '',
			textContent: '',
			textColor: '',
			textSize: '',
			textWeight: ''
		}
	},
	{
		name: '边框13',
		types: ['dataview'],
		component: 'BorderBox13',
		statusConfig: {
			displayShow: true,
			isShow: true, // 是否显示
			mainColor: '',
			secondaryColor: '',
			textContent: '',
			textColor: '',
			textSize: '',
			textWeight: ''
		}
	},
	{
		name: '边框14',
		types: ['dataview'],
		component: 'BorderBox14',
		statusConfig: {
			displayShow: true,
			isShow: true, // 是否显示
			mainColor: '',
			secondaryColor: '',
			title: '',
			titleWidth: '250',
			textContent: '',
			textColor: '',
			textSize: '',
			textWeight: '',
			blur: 6
		}
	},
	{
		name: '边框15',
		component: 'BorderBox15',
		statusConfig: {
			displayShow: true,
			isShow: true, // 是否显示
			backgroundColor: '',
			blur: 6
		}
	},
]
// 初始化组件列表
const initComponentsList = (list) => {
	// 公共配置
	const publicConfig = {
		type: 'decoration',
		icon: 'icon-zhuangshiqi',
		isLock: false, // 是否锁定
		needCommonStyle: true,
		propValue: '',
		span: -1,
		style: {
			height: '100%',
			width: '',
			rotate: 0,
			paddingTop: 0,
			paddingBottom: 0,
			paddingLeft: 0,
			paddingRight: 0
		},
		modelConfig: {
			appId: '',
			appSecret: '',
			modelId: {
				type: '',
				value: ''
			}
		}
	}
	// 返回融合后的配置
	return list.map(el => {
		return Object.assign({}, publicConfig, el)
	})
}
const decorate = {
	type: 'decorate',
	title: '装饰组件',
	show: true,
	order: 11,
	componentList: initComponentsList(decorateComponentsList)
}
export default decorate