/* 获取项目下的人员处理 */
const projectListHandle = ($list, $type, companyInfo) => {
    const externalList = []
    const data = JSON.parse(JSON.stringify($list))
    let usersList = []
    data.forEach((element) => {
      const edit = ($obj) => {
        /* 项目处理函数 */
        const project_name = $obj.name
        const project_id = $obj.id
        if ($obj.external_company && $obj.external_company.length) {
          $obj.external_company.forEach((item) => {
            let users = []
            const external_name = item.name
            const external_id = item.id
            if (item.sections && item.sections.length) {
              item.sections.forEach((section) => {
                if (section.users && section.users.length) {
                  if (companyInfo && companyInfo.company_name) {
                    section.users.forEach((user) => {
                      user.company_name = companyInfo.company_name
                      user.company_id = companyInfo.company_id
                    })
                  }
                  section.users.forEach((user) => {
                    user.external_name = external_name
                    user.external_id = external_id
                    user.project_name = project_name
                    user.project_id = project_id
                  })
                  users = users.concat(section.users)
                  usersList = usersList.concat(section.users)
                }
              })
              delete item.sections
            }
            item.children = users
          })
          $obj.children = $obj.external_company
          $obj.children = $obj.children.filter((itemz) => itemz.children.length > 0)
          delete $obj.external_company
          if ($obj.children && $obj.children.length) {
            externalList.push($obj)
          }
        }
        if ($obj.project && $obj.project.length) {
          $obj.project.forEach((project) => {
            edit(project)
          })
        }
      }
      if (element.external_company && element.external_company.length) {
        edit(element)
      }
      const externalEdit = ($list) => {
        if ($list && $list.length) {
          $list.forEach(($element) => {
            edit($element)
          })
        }
      }
      if (element.project && element.project.length) {
        externalEdit(element.project)
      }
    })
    if ($type === 'users') {
      /* 返回所有人的数组 */
      return usersList
    } else if ($type === 'section') {
      /* 返回部门的数组（部门下是人员） */
      return externalList
    }
  }
  /* 处理公司下的人员数据 */
  const companyListHandle = ($list, $type) => {
    let sectionList = []
    const data = JSON.parse(JSON.stringify($list))
    let usersList = []
    if (data && data.length) {
      data.forEach((dataItem) => {
        const companyEdit = ($dataItem) => {
          /* 公司本级部门处理 */
          const company_name = $dataItem.name
          const company_id = $dataItem.id
          if ($dataItem.sections && $dataItem.sections.length) {
            $dataItem.sections.forEach((element) => {
              if (element.users.length) {
                element.children = element.users
                element.children.forEach((element) => {
                  element.company_name = company_name
                  element.company_id = company_id
                })
                delete element.users
                sectionList.push(element)
                usersList = usersList.concat(element.children)
              }
            })
          }
          /* 公司项目处理 */
          if ($dataItem.project && $dataItem.project.length) {
            $dataItem.project.forEach((element) => {
              if ($type === 'users') {
                usersList = usersList.concat(
                  projectListHandle([element], $type, { company_name, company_id })
                )
              } else {
                sectionList = sectionList.concat(
                  projectListHandle([element], $type, { company_name, company_id })
                )
              }
            })
          }
          /* 公司下面的子公司处理 */
          if ($dataItem.company && $dataItem.company.length) {
            /* 处理公司数据函数 */
            const companyChildEdit = (list) => {
              let newList = []
              const reChildConcat = (array) => {
                if (array && array.length) {
                  array.forEach((element) => {
                    element.children = []
                    if (element.sections && element.sections.length) {
                      element.sections.forEach((item) => {
                        if (item.users && item.users.length) {
                          item.users.forEach((user) => {
                            user.company_name = element.name
                            user.company_id = element.id
                          })
                          item.children = item.users
                          usersList = usersList.concat(item.children)
                        }
                      })
                      element.children = element.sections
                    }
                    if (element.project && element.project.length) {
                      const arr = projectListHandle(element.project, $type, {
                        company_name: element.name,
                        company_id: element.id
                      })
                      element.children = element.children.concat(arr)
                      if ($type === 'users') {
                        usersList = usersList.concat(arr)
                      }
                    }
                    if (element.company && element.company.length) {
                      reChildConcat(element.company)
                    }
                    if (element.children.length > 0) {
                      newList.push(element)
                    }
                  })
                }
              }
              reChildConcat(list)
              newList = newList.filter((item) => item.children.length > 0)
              return newList
            }
            sectionList = sectionList.concat(companyChildEdit($dataItem.company))
          }
        }
        companyEdit(dataItem)
      })
    }
    if ($type === 'section') {
      return sectionList
    } else if ($type === 'users') {
      return usersList
    }
  }
  /*
   $list:接口获取的原始数据
  $archiType:数据的类型(company公司，project项目)
  $type(返回数据结构（section：部门为最小级（参考人员选择组件），users：直接返回所有人员数组）)

  console.log(getUserList(list, "company", "section"));
   */
  const getUserList = ($list, $archiType, $type) => {
    /* 项目人员接口数据 */
    if ($archiType === 'project' && $list && $list.length > 0) {
      return projectListHandle($list, $type)
    } else if ($archiType === 'company' && $list && $list.length > 0) {
      return companyListHandle($list, $type)
    }
  }
  export { getUserList }
