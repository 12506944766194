<!--
 * @Author: your name
 * @Date: 2022-04-18 14:26:36
 * @LastEditTime: 2022-09-22 10:47:01
 * @LastEditors: lisushuang
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /dataview-next/src/custom-component/consoleBim/components/player.vue
 萤石云文档地址：http://open.ys7.com/doc/zh/uikit/uikit_javascript.html
-->
<template>
  <div  class="player-container">
		<div class="logo-cont">
				<img src="@/assets/images/gzb_logo.png" alt="">
		</div>
		<div class="video-cont">
			<div v-if="playerType === 'lechange'" id="lcPlayer" class="w-full h-full" />
			<div v-else-if="playerType === 'ys7'" :id="'ysPlayer'+playerItem.id" class="w-full h-full" />
			<div v-else-if="playerType === 'other'" id="video_detail" class="w-full h-full" />
			<i class="iconfont iconc-close absolute close" @click="closeMonitor" ></i>
			<i class="iconfont icondaping-quanping full-screen absolute" @click="player.fullScreen()"></i>
		</div>
  </div>
</template>
<script>
import  EZUIKit from '@/libs/ezuikit.js';
export default {
	name:'player',
  props: {
    playerType: {
      type: String,
      default: ''
    },
    playerItem: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
			player: null,
    }
  },
  beforeUnmount () {
		if(this.player!=null){
			this.player.stop('ysPlayer'+this.playerItem.id)
		}
  },
  mounted () {
		if(this.playerItem!==null){
			if (this.$props.playerType === 'other') {
				this.otherVideo(this.playerItem)
			} else if (this.$props.playerType === 'lechange' || this.$props.playerType === 'ys7') {
				this.initPlayer()
			} 
		}
  },
  methods: {
    initPlayer () {
			if(this.$props.playerType === 'lechange'){
				this.$nextTick(() => {
					const height = document.getElementsByClassName('player-container')[0].clientHeight
					const width = document.getElementsByClassName('player-container')[0].clientWidth
					const { deviceId, channelId, type, streamId, kitToken, monitor_img } = this.playerItem
					if (deviceId) {
						// this.player = new ImouPlayer('#lcPlayer')
						this.player.setup({
							src: [
								{
									url: `imou://open.lechange.com/${deviceId}/${channelId}/${type}?streamId=${streamId}`,
									kitToken: kitToken // 播放Token
								}
							], // 播放地址
							width: width, // 播放器宽度
							height: height, // 播放器高度
							poster: monitor_img, // 封面图url
							autoplay: false, // 是否自动播放
							controls: true // 是否展示控制栏
						})
					}
				})
			}else{
				let { device_serial, channel_id, token } = this.playerItem
				this.player = new EZUIKit.EZUIKitPlayer({
					id: 'ysPlayer'+this.playerItem.id, // 视频容器ID
					accessToken: token,
					url: `ezopen://open.ys7.com/${device_serial}/${channel_id}.live`,
					templete: 'standard',
					width: 208,
					height: 128
				})
			}
    },
    otherVideo (val) {
      const { monitor_path, id } = val
      this.$nextTick(() => {
        document.getElementById('video_detail').innerHTML = '<video id="player_' + id + '" poster="" autoplay="autoplay" controls playsInline webkit-playsinline style="width: 100%; height: 100%;"><source src="' + monitor_path + '" type="application/x-mpegURL"></video>'
        this.player = new EZUIKit.EZUIPlayer('player_' + id)
      })
    },
		closeMonitor(){
			this.$emit('closeMonitor')
		}
  },
	destroyed: function () {
		console.log(this.player,'player')
    this.player.stop()
  }
}
</script>

<style lang="less"  scoped>
.player-container {
  height: 186px;
  width: 228px;
	position: absolute;
	top: -200px;
	left: -100px;
	border: 2px solid #0068B7;
	background: rgba(0, 0, 0, 0.4);
	.logo-cont{
		height: 22px;
		text-align: left;
		position:relative;
		padding:8px;
		line-height: 22px;
		border-bottom: 4px solid #0068B7;
		img{
			width: 60px;
			height: 100%;
		}
	}
	.logo-cont:after{
		content: "";
		height: 4px;
		width: 88px;
		position: absolute;
		bottom:-4px;
		left: 0;
		background-color: #E80013;
	}
	.video-cont{
		position:relative;
		margin:8px;
		.close{
			position:absolute;
			top: 2px;
			right: 5px;
			font-size: 22px;
			color:#fff;
		}
		.full-screen{
			position:absolute;
			top: 5px;
			right:30px;
			color:#fff;
		}
	}
	
	:deep(.iframe-btn-container){
		padding:0!important;
		svg{
			width: 16px!important;
			height: 16px!important;
		}
	}
}
</style>
