<!--
 * @Description: 基础容器
 * @Author: luocheng
 * @Date: 2021-08-18 13:48:12
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-01-09 11:25:33
-->
<template>
  <div
    class="common-container"
    :data-id="element.id"
  >
    <!-- v-loading="loading" -->
    <ComponentBox
      v-for="child in element.children"
      :isPreview="isPreview"
      :key="child.id"
      :containerData="renderData"
      :fullData="fullData"
      @click.native.stop="onClickBox(child)"
      :element="child"
      :pageId="pageId"
      :isGroup="isGroup"
      :groupComponents="groupComponents"
      :defaultTableSelectData="defaultTableSelectData"
      :componentList="componentList"
      :mapData="mapData"
    >
    </ComponentBox>
    <!-- 默认应当导出pdf 当 任存在问题，先默认为pdf -->
    <Spreadsheet
      v-if="showSheet"
      :type="'export'"
      :excelUuid="exportConfig.exportTemplate"
      :objectUuid="exportConfig.objectUUID"
      :viewUuid="exportConfig.viewUUID"
      :dataIds="'all'"
      :exportType="exportConfig.exportTypeSheet || 'pdf'"
      @exportSuccess="onExportSuccess"
      @exportError="onExportError"
      :extraParams="exportParam"
    ></Spreadsheet>
  </div>
</template>

<script>
import { dataInterface } from '@/apis/data/index';
import { mapState } from 'vuex';
import eventBus from '@/plugins/eventBus';
import { getComponentById, getQueryValue, initParams, doEEActionHandle } from '@/utils/tools';
import Spreadsheet from '@/custom-component/common/Entry';
import mixin from './mixins';

export default {
  name: 'CommonContainer',
  mixins: [mixin],
  props: {
    // 是否为预览
    isPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    // 弹窗页面配置的ID
    pageId: {
      type: Number,
      required: false
    },
    // 循环映射值
    mapData: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  components: {
    Spreadsheet
  },
  data() {
    return {
      // 渲染数据
      renderData: [],
      // 完全渲染数据未格式化
      fullData: null,
      loading: false,
      // 导出配置
      exportConfig: {},
      // 导出
      showSheet: false,
      exportParam: {},
      // 定时请求
      intervalObj: null,
      intervalTimes: 0
    };
  },
  computed: {
    ...mapState(['componentData', '_PageCustomStatus', '_APPCustomStatus']),
    // 值来源
    valueOrigin() {
      return this.element && this.element.valueOrign;
    }
  },
  created() {
    this.doInterface();
    // 定时器
    this.setInterval();
  },
  watch: {
    valueOrigin(newV) {
      // 清空值
      // 数据源
      if (newV === 'database') {
        const { objectData, viewData } = this.element.database;
				if (objectData && viewData) {
					this.getRenderData(this.element?.database || {});
				}
      }
    }
  },
  mounted() {
    // 配置关联参数的容器才需要监听
     const databaseTrigger = {
      [this.element.id]: data => {
        // 测试代码
        // if (this.element.name.indexOf('树容器') > -1) {
        // 	console.log(data, '----参数变化触发请求', this.element.name);
        // }
        // 测试结束
        // if (this.element.id !== data.componentId) return;
        let { paramsConfig, requestType } = this.element.database;
        if (!Array.isArray(paramsConfig)) return;
        // console.log(data, '--配置关联参数的容器才需要监听--');
        // 是否当前组件响应
        let isTarget = false;
        let configItem = null;
        let params = {};
        for (let i = 0; i < paramsConfig.length; i++) {
          if (
            (paramsConfig[i].componentId === data.componentId && this.isSameAction(requestType, data.action)) ||
            (data.componentId.indexOf('CommonTree') > -1 && data.parentId == this.element.id && data.isSearch)
          ) {
            isTarget = true;
            configItem = paramsConfig[i];
            params = {
              ...params,
              ...this.getParams(configItem, data)
            };
          }
        }
        if (!isTarget && !data.isInit && !data.isUpdate) return;
        const {
          search = [],
          param = {},
          canPost
        } = initParams(
          this.element?.database?.paramsConfig || [],
          this.isGroup,
          this.componentList || this.componentData,
          this.groupComponents
        );
        if (!canPost) return;
        this.getRenderData(this.element.database, param, search, data.isInit);
      }
    };
    // 导出
    const exportData = {
      [this.element.id]: exportConfig => {
        if (!exportConfig || exportConfig.componentId !== this.element.id) return;
        this.exportConfig = exportConfig;
        this.doExport(exportConfig);
      }
    }
    eventBus.$on('databaseTrigger', databaseTrigger[this.element.id]);
    eventBus.$on('exportData', exportData[this.element.id]);
  },
  methods: {
    /**
     * @description: 执行请求
     */
    doInterface() {
			const { objectData, viewData } = this.element?.database;
			if (objectData && viewData) {
        const {
          search = [],
          param = {},
          canPost
        } = initParams(
          this.element?.database?.paramsConfig || [],
          this.isGroup,
          this.componentList || this.componentData,
          this.groupComponents
        );
        if (canPost) {
          this.getRenderData(this.element.database, param, search);
        }
      } else if (this.element.database?.requestType === 'moreAction') {
        const { statisticalConfig } = this.element.database;
        if (statisticalConfig && statisticalConfig.length) {
          this.getRenderData(this.element.database, {}, []);
        }
      }
    },
    /**
     * @desc: 触发点击事件
     * @param {Object} item 点击的组件(可能需要兼容)
     * 触发方式实质就是在改变指定的组件状态
     * 目前覆盖点击事件
     */
    onClickBox(item) {
      eventBus.$emit('EDITOR_loopResolve', item.loopData);
      const excludesEvents = ['CommonDateButton'];
      //  || !Array.isArray(item?.events?.click?.effects)
      if (excludesEvents.includes(item.component)) {
        return false;
      }
      if (!item.events || Array.isArray(item.events)) return;
      const {
        actionType,
        effects = [],
        behaviors,
        specialEventList = [],
        pattern,
        linkType,
        queryList = [],
        linkUrl = '',
        pageId
      } = item.events?.click || {};
      if (actionType === 'eeAction') {
        // 触发后端事件
        const { objectUUID, viewUUID, eventName } = item.events.click;
        if (!objectUUID || !viewUUID || !eventName) {
          this.$message.error('事件配置错误！');
          return false;
        }
        const data_id = this.getBindParams(item)?.data_id;
        dataInterface({
          __method_name__: 'customEventCall',
          object_uuid: objectUUID,
          view_uuid: viewUUID,
          data_id, // 参数配置
          event: eventName
        })
          .then(res => {
            if (res.status === 200 && res.data.code === 200) {
              this.$message.success('操作成功！');
              doEEActionHandle(res.data?.__adds__);
            }
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
        return false;
      } else if (actionType === 'jumpPage') {
        if (linkType === 'projectPage') {
          const query = {};
          queryList.forEach(queryItem => {
            let component = getComponentById(this.componentData, queryItem.componentId);
            this.$set(query, queryItem.key, component?.resolveData[queryItem.feild]);
          });
          this.$router.push({
            path: pageId,
            query
          });
          return;
        } else if (linkType === 'outPage') {
          window.open(linkUrl);
        } else if (linkType === 'custom') {
          // 暂未处理
          // const customLink = getLinkByTemplate(linkTemplate, output)
          // window.open(customLink);
        }
      } else if (actionType === 'export') {
        // 导出
				this.onExport(item?.events?.click);
			}else {
        // 普通事件
        if (!pattern) {
          effects.forEach(ele => {
            this.$store.commit('triggerEvents', {
              config: ele,
              element: item
            });
          });
        } else if (pattern === 'special') {
          specialEventList.forEach(ele => {
            ele.effects.forEach(effect => {
              this.$store.commit('triggerEvents', {
                config: effect,
                element: item
              });
            });
            if (ele.behaviors?.length) {
              for (let i = 0; i < ele.behaviors.length; i++) {
                this.$store.commit('triggerEvents', {
                  config: {
                    behavior: ele.behaviors[i],
                    isBehavior: true
                  }
                });
              }
            }
          });
        }
      }
      // 行为
      if (behaviors && Array.isArray(behaviors)) {
        if (behaviors.length === 1) {
          const { component, list } = behaviors[0];
          if (!component) {
            return;
          }
          if (list.length === 1) {
            const behaviorsList = list[0].behaviors;
            if (!behaviorsList.length) {
              return;
            }
          }
        }
        behaviors.forEach(behavior => {
          this.$store.commit('triggerEvents', {
            config: {
              behavior,
              isBehavior: true
            },
            element: item
          });
        });
      }
    },
    /**
		 * @desc: 触发导出事件
		 */
		onExport(config) {
			if (!config) return false;
      eventBus.$emit('exportData', {
        ...config,
        componentId: config.exportTarget,
        action: 'export'
      });
		},
    /**
     * @desc: 获取数据渲染时候的数据
     */
    getRenderData(database, params, search = [], isInit = false) {
      this.loading = true;
      if (database && database.requestType === 'moreAction') {
        // 数据统计逻辑
        this.getStaticsData(database, isInit);
        this.loading = false;
        return;
      }
      // 注入的参数
      let outParams = {};
      if (this.element.database.userOutParams) {
        outParams = sessionStorage.getItem(
          `dialogRelationParams_${this.EDITOR_pageUUID || this.$route.query.pageUUID}`
        );
        outParams = outParams ? JSON.parse(outParams) : {};
      }
      // 请求方式  dataList dataInfo updateData createData deleteData relationList moreAction
      if (!database.objectData) {
        this.loading = false;
        return;
      }
      if (!database.viewData && !database.relationData) {
        this.loading = false;
        return;
      }
      let __method_name__ = database.requestType;
      const mapping = database.mapping;
      let paramObj = null;
      if (mapping === 'object') {
        paramObj = {
          __method_name__,
          object_uuid: database.objectData.uuid,
          view_uuid: database.viewData.uuid,
          search,
          ...params,
          ...outParams
        };
      } else if (mapping === 'relation') {
        __method_name__ = 'relationList';
        paramObj = {
          __method_name__: 'relationList',
          object_uuid: database.objectData.uuid,
          relationship_uuid: database.relationData.uuid,
          search,
          ...params,
          ...outParams
        };
      }
      //标记取值详情ID
      if (__method_name__ === 'dataInfo' && this.element.id.includes('-view')) {
        let arr = this.element.id.split('-');
        let idLastIndex = arr.findIndex(v => v.includes('view'));
        if (idLastIndex !== arr.length - 1) {
          paramObj.data_id = arr[idLastIndex + 1];
        }
      }
      //标记取值详情ID结束
      dataInterface(paramObj)
        .then(res => {
          if (res.status === 200) {
            if (['dataList', 'relationList'].includes(__method_name__)) {
              // 列表
              this.renderData = this.getListFromRes(res) || [];
            } else if (__method_name__ === 'dataInfo') {
              // 详情
              this.renderData = res.data.data || {};
            }
            this.element.containerData = this.renderData;
            // 暴露单一对象
            if (this.statusConfig?.resolveObject && Array.isArray(this.renderData)) {
              this.element.resolveData = this.renderData?.[0];
            } else {
              this.element.resolveData = this.renderData;
            }
            setTimeout(() => {
              eventBus.$emit('databaseTrigger', {
                componentId: this.element.id,
                isSearch: true,
                action: 'any',
                output: this.renderData
              });
            }, 0)
            const fieldObj = res.data?.metadata;
            // 完全数据
            this.fullData = res.data.data || {};
            // 保存容器数据
            const fieldList = [];
            for (let key in fieldObj) {
              fieldList.push({
                name: fieldObj[key],
                uuid: key
              });
            }
            this.$store.commit('modifyComponent', {
              component: {
                ...this.element,
                containerData: this.renderData,
                fullData: this.fullData,
                metadata: res.data.metadata,
                database: {
                  ...this.element.database,
                  fieldList
                }
              },
              containerId: null,
              isModify: true,
              pageUUID: this.EDITOR_pageUUID
            });
            if (
              isInit &&
              this.element.database.userOutParams &&
              outParams &&
              this.$route.path === '/modify-page' &&
              !this.pageId
            ) {
              // 详情页面需要初始化
              this.$store.commit('initDetailsPage', {
                targetContainer: this.element,
                fullData: this.fullData,
                containerData: this.renderData,
                metadata: res.data.metadata
              });
            }
          }
          this.loading = false;
        })
        .catch(err => {
          console.log(err, '-----err');
          this.loading = false;
        });
    },
    /**
     * @desc: 获取统计结果
     * @param {array} database 数据仓库配置
     * @param {boolean} isInit 是否为初始化
     */
    getStaticsData(database, isInit = false) {
      const { statisticalConfig } = database;
      const {
        search = [],
        param = {},
        canPost
      } = initParams(
        this.element?.database?.paramsConfig || [],
        this.isGroup,
        this.componentList || this.componentData,
        this.groupComponents
      );
      if (!canPost) return;
      if (!Array.isArray(statisticalConfig)) {
        this.$message.error('获取统计数据失败！');
        return;
      }
      const data = {};
      const metadata = {};
      statisticalConfig.forEach(ele => {
        this.$set(data, ele.key, {
          __method_name__: 'globalFunctionCall',
          typeName: 'PublicFunc',
          type: 'value',
          funcName: 'ViewAggregate',
          payload: {
            field_uuid: ele.field_uuid,
            view_uuid: ele.view_uuid,
            aggregate: ele.aggregate
          },
          search,
          ...param
        });
        this.$set(metadata, ele.key, ele.key);
      });
      dataInterface({
        __method_name__: 'moreAction',
        data
      })
        .then(res => {
          if (res.status === 200) {
            this.renderData = res?.data?.data || {};
            // 完全数据
            this.fullData = this.renderData || {};
            // 保存容器数据
            this.$store.commit('modifyComponent', {
              component: {
                ...this.element,
                containerData: this.renderData,
                fullData: this.fullData,
                metadata: isInit ? metadata : this.element.metadata
              },
              containerId: null,
              isModify: true,
              pageUUID: this.EDITOR_pageUUID
            });
          }
        })
        .catch(err => {
          console.log(err, '----统计失败！！！！');
        });
    },
    /**
     * @desc: 获取请求参数
     * @param {Array} config 请求参数配置
     */
    getParams(config, data) {
      if (!config || !data?.output) return {};
      let result = {};
      for (let key in data.output) {
        if (key === config.paramKey) {
          result[config.key] = data.output[key];
        }
      }
      return result;
    },
    /**
     * @desc: 根据请求返回获取列表结构
     * @param {Object} res 请求返回的数据
     */
    getListFromRes(res) {
      if (Array.isArray(res?.data)) {
        return res.data;
      }
      if (typeof res === 'object' && res.data) {
        return this.getListFromRes(res.data);
      }
      return [];
    },
    /**
     * @desc: 判断前后端操作标识是否已知
     * @param {String} beAction 后端标识
     * @param {String} feAction 前端标识
     * @return {Boolean}
     */
    isSameAction(beAction, feAction) {
      if (feAction === 'any') return true;
      const feToBe = {
        list: 'dataList',
        add: 'createData',
        detail: 'dataInfo',
        modify: 'updateDate',
        delete: 'deleteData'
      };
      return beAction === feAction || beAction === feToBe[feAction];
    },
    /**
     * @desc: 获取绑定参数
     */
    /**
     * @desc: 后端事件获取绑定参数
     * @param {Object} comp 组件数据
     * @return {Object}
     */
    getBindParams(comp) {
      if (!comp) return {};
      const sourceConfig = comp.sourceConfig || [];
      const sourceParams = {};
      for (let i = 0; i < sourceConfig.length; i++) {
        const {
          componentId,
          field,
          key,
          originType = '',
          urlParamKey = '',
          systemKey = '',
          systemCode = '',
          fixedValue = '',
          statusCode = ''
        } = sourceConfig[i];
        if (originType === 'url' && urlParamKey) {
          // 从url获取参数
          const result = getQueryValue(urlParamKey);
          this.$set(sourceParams, key, result);
        } else if (originType === 'system') {
          // 系统参数
          try {
            let obj = sessionStorage.getItem(systemKey);
            if (!obj) {
              obj = localStorage.getItem(systemKey);
            }
            if (!obj) break;
            const result = JSON.parse(obj);
            if (result && Object.prototype.toString.call(result) === '[object Object]') {
              const queryVal = result[systemCode];
              this.$set(sourceParams, key, queryVal);
            }
          } catch (err) {
            console.log(err, '99999999');
          }
        } else if (originType === 'fixed') {
          // 固定值
          this.$set(sourceParams, key, fixedValue);
        } else if (originType === 'pageStatus') {
          // 页面状态
          const statusCodeValue = this._PageCustomStatus[statusCode] || this._APPCustomStatus[statusCode];
          this.$set(sourceParams, key, statusCodeValue || '');
        } else if (componentId && field && key) {
          // 普通从组件获取
          let sourceComponent = getComponentById(this.componentList || this.componentData, componentId);
          if (!sourceComponent && this.isGroup && this.groupComponents.length) {
            sourceComponent = getComponentById(this.groupComponents, componentId);
          }
					const result = sourceComponent?.resolveData?.[field] || '';
          this.$set(sourceParams, key, result);
        }
      }
      return sourceParams;
    },
    /**
     * @desc: 导出
     * @param {Object} config 配置
     */
    async doExport(config) {
      // 参数
      if (config?.params) {
        const { param = {} } = initParams(
          config.params,
          this.isGroup,
          this.componentList || this.componentData,
          this.groupComponents
        );
        this.exportParam = param;
      }
      // console.log("导出中--------");
      // this.showSheet = true;
      // this.$loading({
      // 	text: '导出中....'
      // });
      // const { exportType = 'all' } = config;
      // let idUUID = this.statusConfig.idUUID || '';
      // if (!idUUID) {
      // 	for (let key in this.metadata) {
      // 		if (this.metadata[key] === 'id') {
      // 			idUUID = key;
      // 		}
      // 	}
      // }
      // if (!idUUID) {
      // 	loading.close();
      // 	this.$message.error('导出失败！数据验证错误。');
      // 	return;
      // }
      /* eslint-disable */
      // exportType 导出配置 all 全部， targetPage 本页，checked 当前选中
      // 最终导出数据
      // 导出全部
      // this.exportViewParams = queryData.param || {};
      // this.exportViewSearch = queryData.search;
      // // console.log(this.sheetFormData, '---11', exportType)
      // if (!this.sheetFormData) {
      // 	loading.close();
      // 	this.$message.error('导出配置错误！');
      // 	return false;
      // }
      // this.showSheet = true;
    },
    /**
     * @desc: 导出成功
     */
    onExportSuccess() {
      this.$loading().close();
      this.showSheet = false;
      this.$message.success('导出成功！');
      this.exportParam = {};
    },
    /**
     * @desc: 导出错误
     * @param {String} msg 错误信息
     */
    onExportError(msg) {
      this.$loading().close();
      this.showSheet = false;
      this.$message.error(msg || '导出失败！');
      this.exportParam = {};
    }
  }
};
</script>

<style lang="less" scoped>
.common-container {
  height: 100%;
  width: 100%;
}
</style>
