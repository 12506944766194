<!--
    @name: widget-database
    @description：widget-database
    @author: ZengWei
    @date: 2022-03-25 09:44
-->
<template>
  <div>
    <el-table ref="multipleTable" :data="relateData" border>
      <el-table-column align="center" width="65">
        <template slot="header">
          <el-button
            v-if="!disabled" type="primary" size="mini"
            icon="el-icon-notebook-2" @click="openSelectRelate"
          ></el-button>
        </template>
        <template slot-scope="scope">{{ scope.$index + 1 }}</template>
      </el-table-column>
      <el-table-column
        v-for="(item,index) in element.config.__slot__.options"
        :key="index"
        :label="item.label"
        :prop="item.value"
      ></el-table-column>
      <el-table-column
        v-if="!disabled" label="操作" align="center"
        :width="element.config.__config__.detailShow ? 130 : 60">
        <template slot-scope="scope">
          <div style="display: flex;justify-content: space-around">
            <div v-if="element.config.__config__.detailShow">
              <el-button type="text" size="mini" icon="el-icon-view">详情</el-button>
            </div>
            <div><i class="el-icon-remove-outline remove" @click="relateData.splice(scope.$index,1)"></i></div>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      title="选择关联数据"
      :visible.sync="relateVisible"
      top="5vh"
      width="80%"
      custom-class="relate-dialog"
      append-to-body>
      <div class="domain-wrapper" v-if="relateConfig">
        <relateData
          ref="relateRef"
          :filter-rule="relateFilterRule"
          :relate-data="relateData"
          :relate-config="relateConfig"
          :pageUUID="relatePageUUID"
        ></relateData>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="relateVisible = false">取 消</el-button>
        <el-button type="primary" @click="getRelateResult">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import relateData from "@/custom-component/form/newParser/relateData";
import formItemMinix from "@/custom-component/form/newParser/scripts/formItemMinix";
import {formRequest} from "@/apis/data/form";
import eventBus from "@/plugins/eventBus";

export default {
  name: "WidgetDatabase",
  components: {relateData},
  mixins: [formItemMinix],
  inject: ["parser"],
  props: ['element', 'disabled', 'displayData'],
  data() {
    return {
      relateVisible: false,
      relateData: [],
      relateConfig: null,
      relateFilterRule:[],
      relatePageUUID:'',
      relatePageData: null
    }
  },
  created() {
    if(this.element.value instanceof Array && this.element.value.length){
      this.recoverRelateData()
    } else {
      const autoFill = this.element.config?.__config__?.autoFill || false;
      if(autoFill){
        this.autoFillRelateData()
      }
    }
  },
  mounted() {
    eventBus.$on('EDITOR_setTableSelectData',(data)=>{
      this.relatePageData = data
    })
  },
  methods: {
    async recoverRelateData(){
      const viewType = this.element.config.__config__?.viewType || 'object';
      let response = await this.requestData();
      let responseData;
      if (response.data.data.data) {
        responseData = response.data.data.data
      } else {
        responseData = response.data.data
      }
      let idKey = 'id';
      if(viewType === 'object'){
        const metadata = response.data.metadata
        for (let key in metadata){
          if(metadata[key] === 'id'){
            idKey = key; break;
          }
        }
      }
      this.relateData = responseData.filter(item => {
        return this.element.value.includes(item[idKey]) || this.element.value.includes(+item[idKey])
      })
    },
    async autoFillRelateData(){
      const viewType = this.element.config.__config__?.viewType || 'object';
      let response = await this.requestData();
      let responseData;
      if (response.data.data.data) {
        responseData = response.data.data.data
      } else {
        responseData = response.data.data
      }
      this.relateData = responseData
      let idKey = 'id';
      if(viewType === 'object'){
        const metadata = response.data.metadata
        for (let key in metadata){
          if(metadata[key] === 'id'){
            idKey = key; break;
          }
        }
      }
      const relateId = Array.from(responseData,item=>item[idKey])
      this.element.value = relateId;
    },
    async requestData(){
      const viewType = this.element.config.__config__?.viewType || 'object';
      const objectUuid = this.element.config.__config__.objectUuid || '';
      const viewUuid = this.element.config.__config__.viewUuid || '';
      const openFilter = this.element.config.__config__.openFilter || false;
      const relateFilterRule = this.element.config.__config__.filterRule || [];
      let searchData = []; //过滤条件
      if (openFilter) {
        for (let item of relateFilterRule) {
          let open = item?.open || false  //自动恢复时不启用参数
          if(!open){
            let searchItem = {ruleType: item.ruleType, value: item.value};
            if (item.valueType === 2) {
              const formIdValue = this.parser.getFormIdData()
              const value = formIdValue[item.value] || ''
              if (['in', 'notin'].includes(item.ruleType) && !(value instanceof Array)) {
                searchItem.value = [value]
              } else {
                searchItem.value = value
              }
            }
            if (item.code) searchItem.code = item.code
            if (item.field_uuid) searchItem.code = item.field_uuid
            searchData.push(searchItem)
          }
        }
      }
      let url, method, params;
      if(viewType === 'object') {
        url = '/api/mapi';
        method = 'POST';
        params = {
          __method_name__: 'dataList',
          object_uuid: objectUuid,
          view_uuid: viewUuid,
          search: searchData,
          __now_archi_type: this.parser.nowArchiType,
          // transcode: 0,
        };
      } else if (viewType === 'formview') {
        url= '/api/form4/view-data-list';
        method= 'GET';
        params = {viewUuid: viewUuid, per_page:99999};
        if(viewUuid === 'default'){
          url= '/api/form4/defaultlist';
          params = {module_uuid: objectUuid, per_page:99999}
        }
      }
      let response = await formRequest(method, url, params);
      return response
    },
    openSelectRelate() {
      const element = this.element
      const configData = element.config
      let searchData = [];
      if(
        configData.__config__.openFilter &&
        configData.__config__.filterRule &&
        configData.__config__.filterRule instanceof Array
      ){
        for (let item of configData.__config__.filterRule){
          let itemData = { ruleType: item.ruleType };
          if(item.paramType === 2){
            itemData.field_uuid = item.field_uuid;
          }else{
            itemData.code = item.code;
          }
          if(item.valueType === 2){
            const formIdValue = this.parser.getFormIdData()
            const value = formIdValue[item.value] || ''
            if (['in', 'notin'].includes(item.ruleType) && !(value instanceof Array)) {
              itemData.value = [value]
            } else {
              itemData.value = value
            }
          }else{
            itemData.value = item.value
          }
          searchData.push(itemData)
        }
      }
      this.relateData = element.value; //回显数据
      this.relateFilterRule = searchData; // 组装筛选条件
      this.relateConfig = configData;
      this.relatePageUUID = configData.__config__?.pageUuid || ''
      this.relateVisible = true
    },
    getRelateResult(){
      if(this.relatePageUUID){
        const dataIdUUid = this.relatePageData?.idUUID || ''
        const selectedList = this.relatePageData?.data || []
        if(dataIdUUid){
          this.selectRelateResult(dataIdUUid,selectedList)
          this.relateVisible = false
        }
      } else {
        this.$nextTick(()=>{
          const selection = this.$refs.relateRef.getSelection()
          this.selectRelateResult(selection.idKey,selection.rows)
          this.relateVisible = false
        })
      }
    },
    selectRelateResult(idKey, rows) {
      this.relateData = rows;
      const relateId = Array.from(this.relateData, item => item[idKey]);
      this.element.value = relateId;
    },
  },
  beforeDestroy() {
    eventBus.$off('EDITOR_setTableSelectData');
  }
}
</script>

<style lang="less" scoped>
  :deep(.relate-dialog){
    .el-dialog__body{
      padding: 0 15px;
    }
  }
  .domain-wrapper{
    height: calc(100vh - 220px);
  }
  .remove {
    color: #e83525;
    cursor: pointer;
    font-size: 23px;
  }
</style>
