<template>
  <!--  -->
  <van-swipe 
    class="mobile-swiper" 
    :height="isNaN(statusConfig.height) ? statusConfig.height : statusConfig.height"
    :autoplay="statusConfig.interval || 3000"
    :initial-swipe="statusConfig.initialIndex || 0"
    :loop="statusConfig.loop"
    :vertical="statusConfig.vertical"
    :show-indicators="statusConfig.showIndicators"
    :touchable="statusConfig.touchable"
    :indicator-color="statusConfig.indicatorColor"
    @change="onChange"
  >
    <van-swipe-item v-for="(item, index) in swiperOptions" :key="index">
      <img :src="item.img || defaultImg" :alt="item.text || ''" @click="onItem(item, index)">
      <p class="desc" :style="`color:${statusConfig.descColor}`" v-if="statusConfig.showText && item.text" @click="onItem(item, index)">{{ item.text || ''}}</p>
    </van-swipe-item>
    <!-- <template #indicator>
      <div class="custom-indicator">{{ current + 1 }}/{{ swiperOptions.length }}</div>
    </template> -->
  </van-swipe>
</template>
<script>
import { defineComponent, reactive, toRefs, watch } from 'vue'
import { Swipe, SwipeItem, Notify } from 'vant'
import { dataInterface } from '@/apis/data';

export default defineComponent({
  components: {
    'van-swipe': Swipe,
    'van-swipe-item': SwipeItem
  },
  props: {
    element: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  setup(props){
    let state = reactive({
      swiperOptions: [],
      defaultImg: require('@/assets/images/logo1.png'),
      statusConfig: {},
      current: 0
    })
    const onChange = (index) => {
      state.current = index + 1
    }
    watch(()=>props.element,(element)=>{
      // console.log(element,'element');
      if(element && element.statusConfig) {
        state.statusConfig = element.statusConfig
      }
      if(element && element.swiperConfig) {
        state.swiperConfig = element.swiperConfig
      }
      state.current = element.statusConfig.initialIndex + 1
    },{
      immediate:true,
      deep:true
    })
    // const statusConfig = computed(() => {
    //   return props.element && props.element.statusConfig;
    // })
    // const swiperConfig = computed(() => {
    //   return this.props.element?.swiperConfig;
    // })
    
    /**
     * @desc: 重置轮播图取值配置
     */
    const resetSwiper = ()=> {
      if (!state.swiperConfig) {
        return false;
      }
      const { valueType, fixedConfig, databaseConfig } = state.swiperConfig;
      if (valueType === 'fixed') {
        // 固定
        formatFixed(fixedConfig);
      } else if (valueType === 'database') {
        // 动态取值
        getDynamic(databaseConfig);
      }
    }
    /**
     * @desc: 固定配置
     */
    const formatFixed = (fixedConfig)=> {
      if (!fixedConfig || !Array.isArray(fixedConfig)) {
         return false
      }
      state.swiperOptions = fixedConfig || [];
    }
    /**
     * @desc: 动态取值配置
     */
    const getDynamic = (config)=> {
      const {
        objectUUID,
        viewUUID,
        imgUUID,
        textUUID,
        hrefUUID,
        hrefTypeUUID
      } = config;
      if (!objectUUID || !viewUUID) {
        Notify({ type: 'danger', message: '请确认配置项完整！' });
        return;
      }
      // this.loading = true;
      dataInterface({
        __method_name__: 'dataList',
        object_uuid: objectUUID,
        view_uuid: viewUUID
      }).then(res => {
        let result = null;
        if (Array.isArray(res?.data?.data)) {
          result = res?.data?.data
        } else {
          result = res?.data?.data?.data || []
        }
        state.swiperOptions = result.map(ele => {
          // 兼容多张图类型
          let img = ele?.[imgUUID];
          if (Array.isArray(img)) {
            img = img?.[0]?.path || '';
          }
          return {
            img,
            text: ele?.[textUUID],
            href: ele?.[hrefUUID],
            hrefTypeUUID: ele?.[hrefTypeUUID]
          }
        });
        // this.loading = false;
      }).catch(err => {
        console.log(err);
        // this.loading = false;
      });
    }
    /**
     * @desc: 点击
     * @param {Object} item
     * @param {Number} index
     */
    const onItem = (item, index) => {
      if (!state.statusConfig.clickLink) return;
      console.log(index, '----index0000-----');
      const { href, hrefType } = item;
      // const { path } = this.$route;
      // if (path === '/modify-page' || path === '/page-view') {
      //   this.$message.info(`跳转${href}---方式${hrefType}`)
      //   return;
      // }
      if (hrefType === 'link') {
        window.open(href);
      } else if (hrefType === 'route') {
        // router.push({
        //   path: href
        // });
      }
    }
    watch(()=>state.swiperConfig,() => {
      state.swiperOptions = [];
      resetSwiper();
    },{
      immediate:true,
      deep:true
    })

    return {
      ...toRefs(state),
      onItem, onChange
    }
  }
})
</script>
<style lang="less" scoped>
.mobile-swiper{
  width: 100%;
  overflow: hidden;
  background: transparent;
  .van-swipe-item{
    background: transparent;
    img{
      display: block;
      margin: 0 auto;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    .desc{
      position: absolute;
      bottom: 0;
      left: 0;
      height: 20px;
      width: 100%;
      background: rgba(0, 0, 0, .6);
      font-size: 14px;
      line-height: 20px;
      box-sizing: border-box;
      padding: 0 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: left;
    }
  }
  .custom-indicator {
    position: absolute;
    right: 5px;
    bottom: 5px;
    padding: 2px 5px;
    font-size: 12px;
    background: rgba(0, 0, 0, 0.1);
  }
}
</style>