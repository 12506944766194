<!--
 * @Description: 基础表格组件
 * @Author: luocheng
 * @Date: 2021-09-08 10:16:36
 * @LastEditors: luocheng
 * @LastEditTime: 2022-06-09 17:46:16
-->
<template>
  <div class="common-table" :id="element.id" ref="contentBox">
    <el-table
      v-if="database"
      :height="statusConfig && statusConfig.height ? statusConfig.height : this.tableHeight"
      v-loading="statusConfig.loading"
      :max-height="statusConfig && statusConfig['max-height'] ? statusConfig['max-height'] : this.tableHeight"
      :stripe="statusConfig.stripe"
      :border="statusConfig.border"
      :size="statusConfig.size"
      :fit="statusConfig.fit"
      :show-header="statusConfig['show-header']"
      :highlight-current-row="statusConfig['highlight-current-row']"
      :empty-text="statusConfig['empty-text']"
      :data="tableData">
      <el-table-column
        v-for="field in fieldList"
        :key="field.id"
        :label="field.name"
        :align="statusConfig.align"
        :header-align="statusConfig['header-align']"
        :width="field.renderConfig && field.renderConfig.width > 0 ? field.renderConfig.width : ''">
        <template slot-scope="{ row }">
          <!-- 区分类型 -->
          <!-- 图片 -->
          <template v-if="field.renderConfig.type === 2">
            <img :src="row[field.uuid] || defaultImage" alt="图片" class="image"/>
          </template>
          <!-- 状态值, 根据状态的值不同进行相关的类型编辑 -->
          <template v-else-if="field.renderConfig.type === 3">
            <p class="status">{{ row[field.uuid] }}</p>
          </template>
          <!-- 时间 -->
          <template v-else-if="field.renderConfig.type === 4">
            {{ row[field.uuid] }}
          </template>
          <!-- 富文本 -->
          <template v-else-if="field.renderConfig.type === 5">
            <div class="rich-text" v-html="row[field.uuid]"></div>
          </template>
          <!-- 富文本 -->
          <template v-else-if="field.renderConfig.type === 6">
            <a :href="row[field.uuid]" class="link" alt="链接">{{ row[field.uuid] }}</a>
          </template>
          <!-- 非状态覆盖范围按文本显示 -->
          <template v-else>{{ row[field.uuid] }}</template>
        </template>
      </el-table-column>
      <el-table-column
        v-if="configAction.needAction"
        :width="configAction.width || ''"
        :align="statusConfig.align"
        :header-align="statusConfig['header-align']"
        key="actions"
        label="操作">
        <template slot-scope="scope">
          <span v-for="(action, key) in configAction" :key="key">
            <el-button v-if="typeof action === 'object' && configAction[key].use"
              :type="configAction[key].btnType"
              :size="configAction[key].size"
              :style="{
                color: configAction[key].color,
                marginLeft: configAction[key].margin + 'px'
              }"
              @click="onAction(key, scope.row)"
            >
              {{ configAction[key].name }}
            </el-button>
          </span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { Table, TableColumn } from 'element-ui';
// import eventBus from '@/plugins/eventBus';
import { getComponentById } from '@/utils/tools';
import { mapState } from 'vuex';

export default {
  name: 'CommonTable',
  props: {
    // 组件对象
    element: {
      type: Object,
      required: true,
      default: () => {}
    },
    // 渲染的数据
    containerData: {
      type: [Array, Object],
      required: true,
      default: () => []
    },
    // 是否在组合内
    isGroup: {
			type: Boolean,
		},
    // 组合内组件列表
		groupComponents: {
			type: Array,
			default: () => []
		}
  },
  components: {
    'el-table': Table,
    'el-table-column': TableColumn
  },
  data() {
    return {
      // 字段类型
			typeList: [
				{
					value: 1,
					label: '普通文本'
				},
				{
					value: 2,
					label: '图片'
				},
				{
					value: 3,
					label: '状态'
				},
				{
					value: 4,
					label: '时间'
				},
				{
					value: 5,
					label: '富文本'
				},
				{
					value: 6,
					label: '超链接'
				}
			],
      // 默认图片
      defaultImage: require('@/assets/images/logo1.png'),
      tableHeight: 'auto'
    }
  },
  computed: {
    ...mapState([
      'componentData',
    ]),
    // 数据源配置
    database() {
      return this.element && this.element.database;
    },
    // 字段列表
    fieldList() {
      const list = this.element && this.element.database && this.element.database.fieldList;
      if (!list || !Array.isArray(list) || !list.length) return [];
      const result = [];
      list.forEach(ele => {
        if (ele.renderConfig && ele.renderConfig.forView) {
          result.push(ele);
        }
      })
      return result;
    },
    // 配置操作栏
    configAction() {
      return this.element && this.element.statusConfig.action;
    },
    // 组件配置
    statusConfig() {
      return this.element ? this.element.statusConfig || {} : {};
    },
    // 表格数据
    tableData() {
      if (!this.database || !this.database.containerKey) return [];
      let container = getComponentById(this.componentData, this.database.containerKey);
      if (!container && this.isGroup && this.groupComponents.length) {
				container = getComponentById(this.groupComponents, this.database.containerKey);
			}
      if (!container || !container.containerData || !Array.isArray(container.containerData)) return [];
      return container.containerData;
    }
  },
  mounted() {
    this.$nextTick(() => {
      const box = document.getElementById(`${this.element.id}`);
      this.tableHeight = box.parentNode.offsetHeight + 'px';
    })
  },
  methods: {
    /**
     * @desc: 点击操作
     * @param {String} action 操作乐行
     * @param {Object} output 输出的数据
     */
    onAction(action, output) {
      console.log(action, output, '----弃用----');
      // 事件
    //   const eventList = this.element.statusConfig.action[action].eventList;
    //   if (eventList && eventList.length) {
    //     eventList.forEach(ele => {
    //       if (ele.key === 'click') {
    //         ele.effects.forEach(effect => {
    //           this.$store.commit('triggerEvents', {
    //             config: {
    //               ...ele,
    //             ...effect
    //             },
    //             element: this.element
    //           });
    //         })
    //       }
    //     })
    //   }
    //   this.$nextTick(() => {
    //     eventBus.$emit('databaseTrigger', {
    //       componentId: this.element.id,
    //       action,
    //       output
    //     });
    //   })
    }
  }
}
</script>

<style lang="less" scoped>
.common-table{
  width: 100%;
  .link{
    color: rgb(31, 135, 238);
    &:hover{
      color: #409EFF;
      text-decoration: underline;
    }
  }
  .image{
    display: block;
    height: 60px;
    width: 60px;
    margin: 0 auto;
  }
  .rich-text{
    line-height: 22px;
  }
}
</style>