<template>
  <p class="currency-component-container" :class="showEffect" :style="style">
    <!-- 固定 -->
		<template v-if="element.valueOrign === 'fixed'">{{ element.propValue || '/' }}</template>
		<!-- 来源于其他组件 -->
		<template v-if="element.valueOrign === 'component'">
			{{ element.componentValueConfig | getValueFromComponent(that) }}
		</template>
		<!-- 数据仓库 -->
		<template v-if="element.valueOrign === 'database'">
			<template v-if="dataObj && bindUUID">
				<!-- 绑定值 -->
				<template v-if="databaseType === 'value'">
					{{ dataObj[bindUUID] || '/' }}
				</template>
        <!-- 数据字典(描述文字) -->
				<template v-else-if="databaseType === 'wordbook' && metaData">
          {{ metaData.name || '/' }}
        </template>
				<template v-else-if="databaseType === 'custom'">
					{{getCustomValue()}}
        </template>
			</template>
		</template>
  </p>
</template>
<script>
import { getComponentById } from '@/utils/tools';
import { mapState } from 'vuex';
import Minin from './mixin'
export default {
	name: 'TextCom',
  mixins: [Minin],
	props: {
		// 绑定的值
		element: {
			type: Object,
			default: () => {},
			required: true
		},
		fullData: {
			// type: Object
		},
    // 是否在组合内
    isGroup: {
			type: Boolean,
		},
    // 组合内组件列表
		groupComponents: {
			type: Array,
			default: () => []
		},
    componentList: {
			default: null
    }
	},
	data() {
		return {
			that: this
		};
	},
	computed: {
		...mapState(['componentData']),
		// 数据仓库
		database() {
			return this.element.database;
		},
		// 字段对象
		dataObj() {
			if (!this.database || !this.database.containerKey) return {};
			let container = getComponentById(this.componentList || this.componentData, this.database.containerKey);
			if(!container && this.isGroup && this.groupComponents.length) {
				container = getComponentById(this.groupComponents, this.database.containerKey);
			}
			// console.log(container.containerData, '---container.containerData----')
			if ((!container || !container.containerData) && !this.fullData) return {};
			// 列表情况默认返回一个
			if (Array.isArray(container.containerData) && container.containerData.length) {
				return container.containerData?.[0] || {};
			}
			// 列表有分页和不分页情况
			if (this.fullData) {
				if (Array.isArray(this.fullData)) {
					// 分页
					return this.fullData[0] || {};
				} else if (Array.isArray(this.fullData?.data)) {
					// 不分页
					return this.fullData?.data?.[0] || {};
				}
				return this.fullData;
			}
			const result = (container && container.containerData) || this.fullData;
      // if(this.element.id === "TextCom-1672052365044"){
        // console.log(result,'result')
      // }
			return result;
		},
		// 绑定的uuid
		bindUUID() {
			if (!this.element || !this.element.database) return '';
			return this.element.database.bindUUID;
		},
		// 用于获取绑定的数据字典(描述文字)
		metaData() {
			if (!this.element || !this.element.database) return null;
			const { fieldList, bindUUID } = this.element.database;
			return fieldList.find((ele) => ele.uuid === bindUUID);
		},
		// 数据绑定类型
		databaseType() {
			if (!this.element || !this.element.database) return 'value';
			return this.element.database.databaseType || 'value';
		},
    style() {
      return {
        fontSize: this.fontSize,
        textAlign: this.textAlign || '',
        lineHeight: this.lineHeight || '',
        fontWeight: this.bold,
        fontStyle: this.italic,
        // fontFamily: this.fontFamily,
        '-webkit-line-clamp': this.showEffect === 'multilineEllipsis' ? this.element.style.height / Math.round(Number(this.lineHeight.replace('px', ''))) : ''
      }
    },
		//获取自定义字段的值
		getCustomValue(){
			return function(){
				if(this.element.database?.customData?.length){
					let filterData = this.element.database.customData.filter(v=>v.show)
					if(filterData.length){
						if(+filterData[0].type === 3){
							if(filterData[0].statusOptions?.length){
								let findData = filterData[0].statusOptions.filter( v =>+v.value === +this.dataObj[filterData[0].uuid])
								if(findData.length){
									return filterData[0].label+': '+findData[0].label
								} else {
									return '-'
								}
							} else {
								return '-'
							}
						} else {
							return filterData[0].label+': '+ this.dataObj[filterData[0].uuid]
						}
					}
				}else{
					return '-'
				}
			}
		}
	},
	updated() {
		this.toResolveData();
	},
	methods: {
		/**
		 * @desc: 暴露数据
		 */
		toResolveData() {
			const { valueOrign = 'fixed', componentValueConfig = {} } = this.element;
			if (valueOrign === 'fixed') {
				// 固定值
				this.element.resolveData = {
					value: this.element.propValue || ''
				};
			} else if (valueOrign === 'component') {
				// 其他组件
				this.element.resolveData = {
					value: this._getValueFromComponent(componentValueConfig)
				};
			} else if (valueOrign === 'database') {
				// 数据仓库
				this.element.resolveData = {
					value: this._getDatabaseData()
				};
			}
      // console.log(this.element.resolveData)
		},
		/**
		 * @desc: 获取数仓数据
		 */
		_getDatabaseData() {
			if (!this.dataObj || !this.bindUUID) return '';
			if (this.databaseType === 'value') {
				// 绑定值
				return this.dataObj[this.bindUUID] || this.nullText;
			}
			if (this.databaseType === 'wordbook' && this.metaData) {
				// 数据字典(描述文字)
				return this.metaData.name || '';
			}
			return '';
		},
		/**
		 * @desc: 获取组件来源绑定值
		 * @param {Object} config
		 */
		_getValueFromComponent(config) {
			if (!config || !config.component || !config.field || (!this.componentData && !this.componentList)) return '';
			let component = getComponentById(this.componentList || this.componentData, config.component);
			if(!component && this.isGroup && this.groupComponents.length) {
				component = getComponentById(this.groupComponents, config.component);
			}
			if (!component) return '';
			if (config.field === 'propValue') {
				return component.propValue;
			}
			if (!component.customStatus) return '';
			return component.customStatus[config.field];
		},
		/**
		 * @desc: 获取映射值
		 */
		_getValueFromMap() {
			const { bindField } = this.element?.loopMapConfig || {};
			if (!bindField) return this.nullText;
			const { renderData } = this.mapData || {};
			if (!renderData) return this.nullText;
			return renderData?.[bindField] || this.nullText;
		}
	},
	filters: {
		// 来源于组件的值
		getValueFromComponent(config, _this) {
			if (!config || !config.component || !config.field || (!_this.componentData && !_this.componentList)) return '';
			let component = getComponentById(_this.componentList || _this.componentData, config.component);
			if (!component && _this.isGroup && _this.groupComponents.length) {
				component = getComponentById(_this.groupComponents, config.component);
			}
			if (!component) return '';
			if (config.field === 'propValue') {
				return component.propValue;
			}
			if (!component.customStatus) return '';
			return component.customStatus[config.field];
		}
	}
}
</script>
<style lang="less" scoped>
  .currency-component-container{
    width: 100%;
    height: 100%;
  }
  .hidden{
    overflow: hidden;
  }
  .ellipsis{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 32px;
  }
  .multilineEllipsis{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
</style>
