/*
 * @Description: toast 提示
 * @Author: luocheng
 * @Date: 2021-08-12 14:10:28
 * @LastEditors: luocheng
 * @LastEditTime: 2021-08-12 14:10:36
 */
import { Message } from 'element-ui'

export default function toast(message = '', type = 'error', duration = 1500) {
    Message({
        message,
        type,
        duration,
    })
}