/*
 * @Description: 注入
 * @Author: luocheng
 * @Date: 2022-08-01 17:13:41
 * @LastEditors: luocheng
 * @LastEditTime: 2022-08-16 14:05:52
 */
import { Empty } from 'element-ui';
import { dataInterface } from '@/apis/data/index';

export default {
  data(){
    return {
      numberList:[]
    }
  },
  watch:{
    dateList(val){
      if(val.length){
        // console.log(val, 'val')
        this.$nextTick(()=>{
          this.getNumbers()
        })
      }
    },
    yearList(val){
      if(val.length){
        this.$nextTick(()=> {
          this.getNumbers(val)
        })
      }
    },

    "element.countConfig":{
      handler(){
        this.getNumbers()
      }
    }
  },
  components: {
    'el-empty': Empty
  },
  props: {
    element: {
      type: Object,
      required: true
    }
  },
  computed: {
    statusConfig() {
      return this.element?.statusConfig;
    },
    // 标志位类型, 周日在后 sundayEnd， 周日在前 sundayStart 
    headerFormat() {
      return this.statusConfig?.headerFormat;
    },
    // 类型， 基础类型 base， 符合数据类型(带统计) withDatabase
    calendarType() {
      return this.statusConfig?.calendarType;
    },
    showDateList() {
      if(this.yearList && this.yearList!== undefined){
        return this.computedNumberToDateList(this.yearList)
      }else{
        return this.computedNumberToDateList(this.dateList)
      }
    }
  },
  methods: {
    /**
     * @desc: 补零
     * @param {Number/Number} val 补零
     * @return {String/Number}
     */
    addZero(val) {
      if (isNaN(+val)) return val;
      if (val >= 0 && val < 10) {
        return `0${+val}`;
      }
      return val;
    },
    /**
     * @desc: 获取当前月有多少天
     * @param {Number} year 年
     * @param {Number} month 月
     * @return {Number} count 天数
     */
    getMonthDayCount(year, month) {
      const largeMonth = [1, 3, 5, 7, 8, 10, 12];
      const lessMonth = [4, 6, 9, 11];
      if (largeMonth.includes(month)) {
        return 31;
      }
      if (lessMonth.includes(month)) {
        return 30;
      }
      // 2月 需要判断平闰年
      if ((year % 4 === 0 && year & 100 !== 0) || year % 400 === 0) {
        return 29;
      }
      return 28;
    },
    /**
     * @description: 生成请求数据
     * @param{Array} dateList 日期数组
     * @return {Object} 全局moreAction请求数据
     */
    generateNumberData(dateList = []) {
      if(dateList.length == 0){
        dateList = this.dateList ?? this.yearList
      }
      let data = [];
      dateList.forEach(item => {
        if (item.type == 'target' || item.type === undefined) {
          let tmp = {
            "__method_name__": 'globalFunctionCall',
            "funcName": "ViewAggregate",
            "payload": {
              "view_uuid": this.element.countConfig?.view_uuid,
              "aggregate": "4",
              "_is_cache": false,
              "need_search" : true,
            },
            "typeName": "PublicFunc",
            "type": "value"
          }
          if(this.element.countConfig?.field_uuid){
            tmp.search = [
              { field_uuid: this.element.countConfig?.field_uuid, ruleType: 'between', value: item.result },
            ]
          }else{
            tmp.search = [
              { code: 'created_at', ruleType: 'between', value: item.result },
            ]
          }
          data.push(tmp)
          tmp = JSON.parse(JSON.stringify(tmp));
          tmp.__show_pre__ = 1
          data.push(tmp)
        }
      })
      let params = {
        __method_name__: 'moreAction',
        data: data
      }
      return params;
    },

    /**
     * @description: 获取日历相关的数字数据 
     * @return {Void}
     */    
    getNumbers(){
      if(this.element.calendarType == 'base'){
        return
      }
      // 没选视图，直接pass
      if(!this.element.countConfig?.view_uuid){
        return 
      }
      let params = this.generateNumberData();
      dataInterface(params).then(res => {
        if(res.data.code == 200){
          this.numberList = res.data.data;
        }
      })
    },
    
    /**
     * @description: 将数字归入到日历数据中 
     * @param{Array} dateList 日期数组
     * @return {Void}
     */    
    computedNumberToDateList(dateList){
      if(!this.numberList.length || !dateList.length){
        return dateList
      }
      let index = 0;
      dateList.forEach(item => {
        if(item.type == 'target' || item.type === undefined){
          item.totalCount = this.numberList[index]
          index++
          item.finishCount = this.numberList[index]
          index++
        }
      })

      return dateList
    }
  },
}