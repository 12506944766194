<!--
 * @Description: 组件盒子
 * @Author: luocheng
 * @Date: 2021-08-18 11:57:07
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-01-03 10:50:05
-->
<template>
	<!-- <el-col
		class="component-box"
		:style="getBoxStyle(element)"
		:data-id="element.id"
		:span="element.span !== undefined ? +element.span : -1"
		:class="{ active: isActive }"
		@mousedown.native="onMouseDown"
		v-if="element.statusConfig && element.statusConfig.isShow && archiLimit"
		v-auth="element.statusConfig && element.statusConfig.list"
	> -->
		<div
			class="component-box"
			:style="getBoxStyle(element)"
			:data-id="element.id"
			:span="element.span !== undefined ? +element.span : -1"
			:class="['component-box-span-' + (+element.span), isActive ?  'active' : '']"
			@mousedown="onMouseDown"
			v-if="element.statusConfig && element.statusConfig.isShow
			&& judgingArchiAPermission(element.archiLimit, element.authConfig && element.authConfig.list)
			"
			v-show="judgingUILogic(element.UILogic, componentList, mapData)"
		>
		<!-- v-show="element.statusConfig
			&& (element.statusConfig.displayShow || element.statusConfig.displayShow === undefined)
		" -->
		<template v-if="!isPreview">
			<div class="border border-top"></div>
			<div class="border border-bottom"></div>
			<div class="border border-left"></div>
			<div class="border border-right"></div>
		</template>
		<component
			:is="element.component"
			:element="element"
      :isPreview="isPreview"
			:containerData="containerData"
			:fullData="fullData"
			:style="getComStyle(element)"
			@mousedown="onMouseDown"
			:isGroup="isGroup"
			:groupComponents="groupComponents"
			:defaultTableSelectData="defaultTableSelectData"
			:componentList="componentList"
			:mapData="mapData"
		>
		</component>
		<!-- 操作 -->
		<section class="actions" v-if="!isPreview && curComponent && curComponent.id === element.id">
			<i class="iconfont iconshangyi" @click="onMove('forward')"></i>
			<i class="iconfont iconxiayi" @click="onMove('backward')"></i>
			<!-- <i class="iconfont icon-shanchu" @click="onDelete"></i> -->
		</section>
		</div>
	<!-- </el-col> -->
</template>

<script>
import { Col } from 'element-ui';
import { getStyle } from '@/utils/style';
import { mapState } from 'vuex';
import { judgingArchiAPermission, judgingUILogic } from '@/utils/tools';

// 注册组件
const componentsList = require.context('@/custom-component', true, /\.+vue$/);
const componentsConsole = require.context('@/console-components', true, /\.+vue$/);
const customComponents = {};
componentsList.keys().forEach((fileName) => {
	let name = fileName.replace(/.vue/, '');
	name = name.replace(/\.\//, '');
	name = name.split('/')[1];
  if(name !== 'CommonGridContainer') {
    customComponents[name] = componentsList(fileName).default;
	}
});
//中控台组件注册
componentsConsole.keys().forEach((fileName) => {
	let name = fileName.replace(/.vue/, '');
	name = name.replace(/\.\//, '');
	name = name.split('/')[1];
	customComponents[name] = componentsConsole(fileName).default;
});
export default {
	name: 'ComponentBox',
	props: {
		// 组件对象
		element: {
			type: Object,
			default: () => {},
			required: true
		},
		// 容器数据
		containerData: {
			type: [Object, Array],
			required: false
		},
		// 完整数据
		fullData: {
			type: [Object, Array],
			required: false
		},
		// 是否为预览
		isPreview: {
			type: Boolean,
			required: false,
			default: false
		},
		// 是否为分组
		isGroup: {
			type: Boolean,
		},
		// 当前分组的组件数据
		groupComponents: {
			type: Array,
			default: () => []
		},
		// 表格默认选中数据
		defaultTableSelectData: {
			type: Array,
			default: () => []
		},
		// 组件数据
		componentList: {
			type: Array,
			default: () => null
		},
		// 循环映射值
		mapData: {
			type: Object,
			default: () => {}
		}
	},
	components: {
		...customComponents,
		'el-col': Col
	},
	computed: {
		...mapState([
			'curComponent', // 当前组件
			'componentData'
		]),
		// 是否为当前操作组件
		isActive() {
			return this.curComponent && this.curComponent.id === this.element.id;
		},
		// 是否符合架构限制
		archiLimit() {
			const limits = this.element.archiLimit || [];
			if (limits && Array.isArray(limits) && limits.length) {
				const archiType = this.$GetTargetArchi('archiType') || '';
				return limits.includes(archiType)
			}
			return true;
		}
	},
	methods: {
		judgingArchiAPermission,
		judgingUILogic,
		/**
		 * @desc: 组件上落下鼠标
		 */
		onMouseDown(e) {
			if (this.isPreview) return;
			this.$store.commit('setClickComponentStatus', true);
			e.stopPropagation();
			e.preventDefault();
			const domId = e.target.dataset.id;
			if (domId?.toLowerCase()?.indexOf('container') === -1 && this.element?.children?.length) {
				// 根据在子节点中获取组件
				const component = this.getChildComponent(this.element.children, domId);
				this.$store.commit('setCurComponent', {
					component,
					index: this.index
				});
			} else {
				this.$store.commit('setCurComponent', {
					component: this.element,
					index: this.index
				});
			}
		},
		/**
		 * @desc: 根据ID在数组中获取组件
		 * @param {Array} arr 组件列表
		 * @param {String} id 组件ID
		 * @return {Object} 组件对象
		 */
		getChildComponent(arr, id) {
			if (!arr || !Array.isArray(arr) || !arr.length) return null;
			let result = null;
			for (let i = 0; i < arr.length; i++) {
				if (arr[i].id === id) {
					result = arr[i];
					break;
				}
				if (arr[i].children && arr[i].children.length) {
					result = this.getChildComponent(arr[i].children, id);
					if (result) {
						break;
					}
				}
			}
			return result;
		},
		/**
		 * @desc: 组件盒型样式
		 * @param {Object} element 组件对象
		 */
		getBoxStyle(element) {
			// if (element.component === 'CommonModel' || element.component==='ConsoleBimModel') return;
			const { style, specialStyle } = element;
			if (!style) return {};
			let excludesArr = [
				'top',
				'left',
				'rotate',
				'overflowY',
				'overflowX'
			];
			let result = getStyle(style, excludesArr);
			// 兼容flex 不居中表格横向滚动失效
			if ((this.element.span !== undefined && +this.element.span !== -1)
				|| (this.element.component === 'CommonTableContainer'
					&& result.width
					&& result.width.toString().indexOf('calc') === -1
				)
			) {
				result.width = '';
			}
			// 特殊样式
			if (specialStyle.isCollector) {
				// 收集者
				result.flex = 1;
			}
			if (specialStyle.isAbsolute) {
				result.position = 'absolute';
				const { absoluteTop = 0, absoluteLeft = 0, zIndex = 10 } = specialStyle;
				result.top = isNaN(+absoluteTop) ? absoluteTop : `${absoluteTop}px` ;
				result.left = isNaN(+absoluteLeft) ? absoluteLeft : `${absoluteLeft}px`;
				result.zIndex = isNaN(+zIndex) ? 10 : zIndex;
			}
			return result;
		},
		/**
		 * @desc: 组件样式
		 * @param {Object} element 组件对象
		 */
		getComStyle(element) {
			// if (element.component === 'CommonModel' || element.component==='ConsoleBimModel') return;
			const { style } = element;
			// 基础样式
			if (!style) return {};
			let excludesArr = [
				'height',
				'width',
				'top',
				'left',
				'rotate',
				'paddingLeft',
				'paddingRight',
				'paddingTop',
				'paddingBottom',
				'marginTop',
				'marginBottom',
				'marginLeft',
				'marginRight',
				'borderWidth',
				'borderStyle',
				'borderColor',
			];
			let result = getStyle(style, excludesArr);
			if ((this.element.span !== undefined && +this.element.span !== -1)
				|| (this.element.component === 'CommonTableContainer' && result.width && result.width.toString().indexOf('calc') > -1)) {
				result.width = '100%';
			}
			if (element.specialStyle.flexDirection) {
				// 轴方向
				result.flexDirection = element.specialStyle.flexDirection;
			}
      result.flexWrap = element.specialStyle.flexWrap ? 'wrap' : 'nowrap';
			result.justifyContent =  element.specialStyle.justifyContent;
			// 特殊样式
			// if (specialStyle.isCollector) {
			//   // 收集者
			//   result.flex = 1;
			// }
			result.height = '100%';
			result.width = '100%';
			return result;
		},
		/**
		 * @desc: 向前移动
		 * @param {String} action 动作 forward 向前 backward 向后
		 */
		onMove(action) {
			this.$store.commit('moveComponentOrder', {
				action
			});
		},
		/**
		 * @desc: 删除组件
		 */
		onDelete() {
			this.$confirm('是否确认删除组件?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$store.commit('deleteComponent');
			});
		}
	}
};
</script>

<style lang="less" scoped>
.component-box {
	box-sizing: border-box;
	cursor: pointer;
	position: relative;
	.border {
		position: absolute;
		border: 1px dashed #ccc;
		z-index: 1000;
		&.border-top {
			height: 0px;
			width: calc(100% - 2px);
			top: 0;
			left: 0;
		}
		&.border-bottom {
			height: 0px;
			width: calc(100% - 2px);
			bottom: 0;
			left: 0;
		}
		&.border-left {
			height: calc(100% - 2px);
			width: 0px;
			top: 0;
			left: 0;
		}
		&.border-right {
			height: calc(100% - 2px);
			width: 0px;
			top: 0;
			right: 0;
		}
	}
	&.active {
		// border: 2px solid @theme;
		> .border {
			border: 1px solid @theme;
		}
		&:before {
			border: 2px solid @theme;
		}
	}
	.actions {
		position: absolute;
		left: 0;
		top: 0;
		display: flex;
		background: rgba(30, 178, 254, 0.6);
		border-bottom-right-radius: 2px;
		z-index: 1000;
		i {
			font-size: 12px;
			line-height: 14px;
			color: #fff;
			box-sizing: border-box;
			padding: 2px 2px 2px 5px;
			cursor: pointer;
			&.icon-shanchu {
				color: @dangerColor;
			}
			&:hover{
				font-weight: bold;
			}
		}
	}
	&.component-box-span-1{
		width: 4.17%;
	}
	&.component-box-span-2{
		width: 8.33%;
	}
	&.component-box-span-3{
		width: 12.5%;
	}
	&.component-box-span-4{
		width: 16.67%;
	}
	&.component-box-span-5{
		width: 20.83%;
	}
	&.component-box-span-6{
		width: 25%;
	}
	&.component-box-span-7{
		width: 29.17%;
	}
	&.component-box-span-8{
		width: 33.33%;
	}
	&.component-box-span-9{
		width: 37.5%;
	}
	&.component-box-span-10{
		width: 41.67%;
	}
	&.component-box-span-6{
		width: 45.83%;
	}
	&.component-box-span-12{
		width: 50%;
	}
	&.component-box-span-13{
		width: 54.16%;
	}
	&.component-box-span-14{
		width: 58.33%;
	}
	&.component-box-span-15{
		width: 62.5%;
	}
	&.component-box-span-16{
		width: 66.67%;
	}
	&.component-box-span-17{
		width: 70.83%;
	}
	&.component-box-span-18{
		width: 75%;
	}
	&.component-box-span-19{
		width: 79.17%;
	}
	&.component-box-span-20{
		width: 83.33%;
	}
	&.component-box-span-21{
		width: 87.5%;
	}
	&.component-box-span-22{
		width: 91.67%;
	}
	&.component-box-span-23{
		width: 95.83%;
	}
	&.component-box-span-24{
		width: 100%;
	}
}
</style>
