<!--
 * @Author: wsp 542278473@qq.com
 * @Date: 2022-11-30 14:17:40
 * @LastEditors: wsp 542278473@qq.com
 * @LastEditTime: 2022-12-02 15:08:31
 * @FilePath: \dataview-next\src\custom-component\login\components\LoginByCode.vue
 * @Description: 二维码登录，暂无接口，单纯的静态页面
-->
<template>
  <div class="login-wrap">
    <div v-if="methodConfig.usePasswordLogin || methodConfig.useCodeLogin" class="code-model">
      <div class="code" @click.stop="changeModel">
        <svg t="1669606977767" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="76454" width="100%" height="100%"><path d="M981.333333 85.333333a42.666667 42.666667 0 0 1 42.666667 42.666667v554.666667a42.666667 42.666667 0 0 1-42.666667 42.666666H554.666667v128h149.333333a21.333333 21.333333 0 0 1 21.333333 21.333334v42.666666a21.333333 21.333333 0 0 1-21.333333 21.333334h-384a21.333333 21.333333 0 0 1-21.333333-21.333334v-42.666666a21.333333 21.333333 0 0 1 21.333333-21.333334H469.333333v-128H42.666667a42.666667 42.666667 0 0 1-42.666667-42.666666V128a42.666667 42.666667 0 0 1 42.666667-42.666667h938.666666z m-42.666666 85.333334H85.333333v469.333333h853.333334V170.666667z" p-id="76455" fill="#409eff"></path></svg>
      </div>
      <div class="model"></div>
    </div>
    <div class="login-main">
      <div class="code-wrap">
        <p class="title">手机扫码，安全登录</p>
        <div class="code">
          <div class="code-img" v-loading="getingCode"></div>
        </div>
        <p v-if="showErrorMessage" class="message-info" :class="`${errorMessageType}-message`">
          <i :class="errorMessageType === 'error' ? 'el-icon-warning' : 'el-icon-success'"></i>
          <span class="message">{{errorMessage}}</span>
        </p>
        <p v-else class="message-info">
          打开微信 | 企业微信扫一扫  快速登录/注册
        </p>
      </div>
      <div class="footer">免费注册</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    methodConfig: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      codeImgSrc: '',
      getingCode: false,
      showErrorMessage: false,
      errorMessage: '',
      errorMessageType: '',
    }
  },
  created() {
    this.getCode()
  },
  beforeDestroy() {
    if(this.timer) {
      clearTimeout(this.timer)
    }
  },
  methods: {
    getCode() {
      const ps = new Promise((reslove) => {
        setTimeout(() => {
          reslove()
        }, 1000)
      })
      this.getingCode = true
      ps.then(() => {
        this.codeStatusPolling()
      }).finally(() => {
        this.getingCode = false
      })
    },
    codeStatusPolling() {
      this.timer = setTimeout(() => {
        // doSomeReqest
        if(this.timer === 5) {
          this.errorMessage = '扫码成功请在微信中轻触同意即可登录'
          this.errorMessageType = 'success'
          this.changeShowErrorMessage()
        }
        if(this.timer >= 10) {
          this.closeCodeStatusPolling()
          this.errorMessage = '你已取消此次登录'
          this.errorMessageType = 'error'
          this.changeShowErrorMessage()
        }
      }, 2000)
    },
    closeCodeStatusPolling() {
      clearTimeout(this.timer)
      this.timer = null
    },
    changeShowErrorMessage() {
      if(this.showErrorMessage && this.timer) {
        clearTimeout(this.timer)
      }
      this.showErrorMessage = true
      this.timer = setTimeout(() => {
        this.showErrorMessage = false
        this.timer = null
      }, 3000)
    },
    changeModel() {
      this.$emit('changeModel', 'input')
    }
  }
}
</script>
<style lang="less" scoped>
.login-wrap{
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 4px;
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left;
  .code-model{
    @seiz: 50px;
    position: absolute;
    right: 4px;
    top: 4px;
    width: @seiz;
    height: @seiz;
    &:hover{
      cursor: pointer;
      .code{
        transform: all .3s;
        z-index: 1;
      }
    }
    .code{
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
    .model{
      position: absolute;
      right: 0;
      top: 6px;
      width: 0;
      height: 0;
      box-sizing: border-box;
      border:  @seiz/2 solid transparent;
      border-left: @seiz/2 solid #fff;
      border-bottom: @seiz/2 solid #fff;
    }
  }
  .login-main{
    flex-grow: 1;
    height: 10px;
    display: flex;
    flex-direction: column;
    padding-top: 58px;
    box-sizing: border-box;
    .code-wrap{
      width: 100%;
      flex-grow: 1;
      height: 10px;
      padding: 0 24px;
      padding-bottom: 16px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .title{
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #3C3C3C;
      }
      .code{
        display: flex;
        justify-content: center;
        .code-img{
          width: 128px;
          height: 128px;
          background: pink;
        }
      }
    }
    .message-info{
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      padding: 8px 0;
      box-sizing: border-box;
      text-align: center;
      color: #A9ADB8;
      .message{
        padding-left: 8px;
      }
    }
    .error-message{
      color: #E2A045;
    }
    .success-message{
      color: #70C241;
    }
    .footer{
      padding: 8px 24px;
      box-sizing: border-box;
      text-align: right;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #A9ADB8;
    }
  }
}
</style>