/*
 * @LastEditTime: 2022-06-16 16:25:14
 * @Description: api 请求缓存
 * @FilePath: /dwh_front/src/api/apiStore.js
 * @Date: 2022-02-15 11:44:54
 * @Author: lisushuang
 * @LastEditors: lisushuang
 */
import http from "../global/http"
import { collect } from 'collect.js';

class apiStore {
  // 缓存数据
  static apiPromiseStore = []

  // 增量数据缓存
  static apiIncrementData = []

  // axios options 
  // options.time  Int 单位：s  等于0时永久缓存，直至刷新页面
  static cacheHttp (options){
    // 缓存 key
    let pKey = JSON.stringify(options)
    // 缓存时间 秒
    let time = options.time !== undefined ? options.time : 10
    if(!apiStore.apiPromiseStore[pKey]){
      // 产生缓存
      apiStore.apiPromiseStore[pKey] = http(options)
      // 规定时间后清除缓存
      if(time){
        setTimeout(() => {
          delete apiStore.apiPromiseStore[pKey]
        }, parseInt(time) * 1000);
      }
    }
    return apiStore.apiPromiseStore[pKey]
  }

  
  /**
   * @description: 增量请求的API，针对大接口，第一次请求全量数据，后续所有的请求只请求id大于本次请求的id的数据
   * @param {*} options
   * @return {*}
   */  
  static incrementHttp (options) {
    let key = JSON.stringify(options)
    return new Promise((resolve,reject) => {
      // 先查找缓存中是否已经有本请求的数据了
      if(apiStore.apiIncrementData[key]){
        // 有，则拿出最新的id
        let maxId = apiStore.apiIncrementData[key].maxId
        let storeData = apiStore.apiIncrementData[key].data
        let idField = apiStore.apiIncrementData[key].idField || 'id'
        options.data.search.push({code: 'id', ruleType:"gt", value: maxId})

        // 重新请求
        http(options).then(res => {
          // 不判断分页了，分页的都没缓存
          if(res.data.data.length){
            maxId = collect(res.data.data).max(idField)
            storeData.data.data = storeData.data.data.concat(res.data.data)
            apiStore.apiIncrementData[key].maxId = maxId
            console.log('命中增量并刷新缓存：',apiStore.apiIncrementData[key].data.data.data.length,storeData.data.data.length)
            apiStore.apiIncrementData[key].data = storeData
            resolve(storeData)
          }else{
            console.log("请求命中原始缓存：",storeData.data.data.length)
            resolve(storeData)
          }
        }).catch(() => {
          resolve(storeData)
        })
      }else{
        // 没有则进行全量请求，找出最大的id，并缓存
        http(options).then(res => {
          // 如果有分页信息，不缓存
          if(res.data.data.data !== undefined && options.url.indexOf('/api/mapi') == -1){
            resolve(res)
          }else{
            // 没有分页信息，可以进行增量数据缓存
            // 找出最大的id
            // 如果有数据字典，则使用字典的id，否则使用id
            let idField = 'id'
            let maxId = 0
            const data = res.data.data
            if(data.length < 0){
              resolve(res)
            }
            // 找到id字段名
            if(res.data.metadata.length > 0){
              for(key in res.data.metadata){
                if(res.data.metadata[key] == 'id'){
                  idField = key
                  break;
                }
              }
            }
            
            maxId = collect(data).max(idField)
            // 缓存
            apiStore.apiIncrementData[key] = {maxId: maxId, data: res,idField: idField}
            resolve(res)
          }
        }).catch(res => {
          reject(res)
        })
      }
    })
  }
}
export {apiStore}
export default apiStore.cacheHttp