<template>
  <div class="screen-tabs-container" @click.stop="doTabClick">
    <img v-if="defaultImage" :src="defaultImage" class="back-img" :style="{objectFit, visibility: isActive ? 'hidden': 'visible'}" alt="">
    <img v-if="activeImage" :src="activeImage" class="back-img" :style="{objectFit, visibility: isActive ? 'visible': 'hidden'}" alt="">
    <span :style="boxStyle">
      {{ text }}
    </span>
  </div>
</template>
<script>
import { flatTree, unique } from '@/utils/tools';
import { mapState } from 'vuex';
export default {
  name: 'ScreenTabs',
  props: {
    // 绑定的值
		element: {
			type: Object,
			default: () => {},
			required: true
		},
    // 是否在组合内
    isGroup: {
      type: Boolean,
    },
    // 组合内组件列表
    groupComponents: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      oldValue: '' // 状态旧值
    }
  },
  computed: {
		...mapState([
			'componentData', // 组件列表
			'originComponentData',
			'sContainer'
		]),
    // 其他的tabs组件
    tabsList () {
      let data = []
      if (this.sContainer) {
        data = flatTree(this.originComponentData || [], 'children', null, []);
      }
      data = unique(data.concat(flatTree(this.componentData, 'children', null, [])));
      console.log(data)
      return data.filter((el) => el.component === 'ScreenTabs' && el.id !== this.element.id)
    },
    // 是否活动
    isActive() {
      const v = this.element.statusConfig.isActive
      if(this.oldValue !== v) {
        this.doStatusChange(v)
      }
      return v
    },
    // 字体
    fontSize() {
      const v = this.element?.statusConfig?.fontSize || '16'
      if(!isNaN(v)) {
        return `${v}px`
      } else {
        return v
      }
    },
    // 填充
    objectFit() {
      return this.element?.statusConfig?.objectFit || 'cover'
    },
    // tabs文本
    text() {
      const text = this.isActive ? this.element?.statusConfig?.activeText : this.element?.statusConfig?.defaultText
      return text || ''
    },
    // 默认图片
    defaultImage() {
      return this.element?.statusConfig?.defaultImage || ''
    },
    // 活动图片
    activeImage() {
      return this.element?.statusConfig?.activeImage || ''
    },
    // 盒子的样式
    boxStyle() {
      const color = this.isActive ? this.element?.statusConfig?.activeColor : this.element?.statusConfig?.defaultColor
      const style = {
        fontSize: this.fontSize,
        color,
        zIndex: '1'
      }
      return style
    }
  },
  methods: {
    // tabs点击事件
    doTabClick() {
      this.setTabsInactive()
      const effects = {
        actions: ['active'],
        logics: [],
        targets: [this.element.id]
      }
      this.$store.commit('triggerEvents', {
        config: effects,
        element: this.element
      });
      
    },
    /**
     @desc 状态更改后置处理方法
     @param { Boolean } n 状态的值 isActive
     */
    doStatusChange(n) {
      this.oldValue = n
      const displayEffects = {
        actions: (n ? ['displayShow'] : ['displayHidden']),
        logics: [],
        targets: this.element.tabConfig.displayList.map(el => el)
      }
      // 触发事件系统
      this.$store.commit('triggerEvents', {
        config: displayEffects,
        element: this.element
      });
      const showEffects = {
        actions: (n ? ['show'] : ['hidden']),
        logics: [],
        targets: this.element.tabConfig.showList.map(el => el)
      }
      // 触发事件系统
      this.$store.commit('triggerEvents', {
        config: showEffects,
        element: this.element
      })
    },
    /**
     @desc 将其他的tabs设置为不活动
     */
    setTabsInactive() {
      const effects = {
        actions: ['inactive'],
        logics: [],
        targets: this.tabsList.map(el => el.id)
      }
      console.log(effects)
      this.$store.commit('triggerEvents', {
        config: effects,
        element: this.element
      });
    }
  }
}
</script>
<style lang="less" scoped>
  .screen-tabs-container{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .back-img{
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 0;
    }
  }
</style>